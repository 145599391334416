import { configureStore } from "@reduxjs/toolkit";

import auth from "./auth";
import admin from "./admin";

export default configureStore({
  reducer: {
    auth,
    admin,
  },
});
