import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function AdminViews() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4">Views</Typography>
    </Box>
  );
}
