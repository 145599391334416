import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../contexts/AuthContext";
import { Helmet } from 'react-helmet';

const Unauthorized = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={location.state?.from || "/dashboard"} replace />;
  }

  return <>
    <Helmet>
      <title>Log-in - trunk8.io</title>
      <meta name="description" content="Simplify data interfaces with trunk8.io" />
    </Helmet>
    <Outlet />
  </>;
};

export default Unauthorized;
