import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

import Header from "../Header";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

const drawerWidth = 240;
const leftNavs = [
  {
    title: "Dashbard",
    link: "/dashboard/admin",
    icon: <DashboardIcon />,
  },
  {
    title: "Organizations",
    link: "/dashboard/orgs",
    icon: <WorkspacesIcon />,
  },
  {
    title: "Users",
    link: "/dashboard/users",
    icon: <PeopleAltIcon />,
  },
  {
    title: "Views",
    link: "/dashboard/views",
    icon: <ViewModuleIcon />,
  },
];

function Layout({ title = null }) {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const handleLeftNavClick = (link) => {
    navigate(link);
  };

  return (
    <Box sx={{ display: auth && auth.user.role === "admin" ? "flex" : "block" }}>
      <Helmet>
        <title> {title || "Reimbursement Calculator - trunk8.io"}</title>
        <meta name="description" content="Simplify data interfaces with trunk8.io" />
      </Helmet>
      <Header title={title} />
      {auth && auth.user.role === "admin" && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          <Divider />
          <List>
            {leftNavs.map((item) => (
              <ListItem
                key={item.title}
                disablePadding
                onClick={() => handleLeftNavClick(item.link)}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{
                      color:
                        location.pathname === item.link ? "#59c419" : "white",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3, width: "100%" }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      {auth && auth.user.role !== "admin" && <Footer />}
    </Box>
  );
}

export default Layout;
