import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Backdrop,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { getUsers, removeUser } from "../../store/admin";
import UserModal from "../../components/UserModal";
import ConfirmModal from "../../components/ConfirmModal";

function AdminUsers() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [removingUser, setRemovingUser] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editUserInfo, setEditUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "user",
    notes: "",
    organizationId: "",
    organizationName: "",
  });
  const adminStore = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (!adminStore.isLoading && openEditModal && !adminStore.error) {
      setOpenEditModal(false);
    }
  }, [adminStore]);

  const handleEditUser = (user) => {
    setEditUserInfo(user);
    setCreateModalOpen(false);
    setOpenEditModal(true);
  };

  const handleRemoveUser = () => {
    dispatch(
      removeUser({
        id: removingUser.id,
      })
    );
    handleConfirmModalClose();
  };

  const handleCreateNew = () => {
    setEditUserInfo({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "user",
      notes: "",
      organizationId: "",
      organizationName: "",
    });
    setCreateModalOpen(true);
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleEditModalInputChange = (event, mode, orgName) => {
    console.log(event.target.name, mode, orgName);
    setEditUserInfo({
      ...editUserInfo,
      [event.target.name]: event.target.value,
      organizationName: mode === 'org' ? orgName : editUserInfo.organizationName || ''
    });
  };

  const handleConfirmModal = (user) => {
    setRemovingUser(user);
    setOpenConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        alignContent="center"
        justifyContent="space-between"
        sx={{ marginBottom: "20px" }}
      >
        <Typography variant="h4">
          {adminStore.users.length > 0 ? "Users" : "No User"}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleCreateNew()}
        >
          Add
        </Button>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={adminStore.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {adminStore.users.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>TimeZone</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.users.map((user, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`${user.firstName} ${user.lastName}`}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user?.phone}</TableCell>
                  <TableCell>{user?.timezone?.timeZone}</TableCell>
                  <TableCell>{user?.organizationName}</TableCell>
                  <TableCell>{user.notes}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex" }}>
                      {user.id !== auth.user.id && (
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditUser(user)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      {user.id !== auth.user.id && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleConfirmModal(user)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <UserModal
        open={openEditModal}
        onModalClose={handleEditModalClose}
        data={editUserInfo}
        handleDataChange={handleEditModalInputChange}
        isLoading={adminStore.isLoading}
        createModal={createModalOpen}
      />
      <ConfirmModal
        open={openConfirmModal}
        onModalConfirm={handleRemoveUser}
        onModalClose={handleConfirmModalClose}
        askingContent={`Remove ${removingUser?.firstName} ${removingUser?.lastName}?`}
        isLoading={adminStore.isLoading}
      />
    </Box>
  );
}

export default AdminUsers;
