import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function NoAccess() {
  return (
    <Box sx={{ height: 900, width: "100%" }}>
      <Typography variant="h4">
        This data is unavailable for your organization.
      </Typography>
    </Box>
  );
}
