import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
    Typography,
    TextField,
    Box,
    InputAdornment,
    IconButton,
    Paper,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Button
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Shower, Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import useAuth from "../../contexts/AuthContext";
import { API } from "../../services/api";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loginContainer: {
        maxWidth: "450px",
        "& .MuiGrid-root": {
            width: "auto",
        },
    },
    button: {
        textDecoration: "none",
    },
    forgotPassLink: {
        color: "#000000",
        border: "none",
        background: "none",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
}));
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Verify() {
    const navigate = useNavigate();
    const styles = useStyles();
    const auth = useSelector((state) => state.auth);
    let query = useQuery();
    const [showPassword, setShowPassword] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [userData, setUserData] = useState({
        email: "",
        password: "",
    });

    const uid = query.get("uid")
    const email = query.get("email")

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    React.useEffect(() => {
        setUserData({ ...userData, email: email });
    }, [email])

    return (
        <div className={styles.root}>
            <Box
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setShowErr(false)
                    API.post("/account/updatePassword", { uid, password: userData.password }).then(res => {
                        setShowErr(false);
                        navigate("/login")
                    }
                    ).catch(err => setShowErr(true))
                }}
                zIndex="2"
            >
                <Paper>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        className={styles.loginContainer}
                        p={{ xs: "32px" }}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h4" mt={1} mb={3}>
                                Set your own password
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <TextField
                                required
                                variant="outlined"
                                value={userData.email}
                                name="email"
                                type="email"
                                label="Email"
                                fullWidth
                                disabled
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <TextField
                                required
                                variant="outlined"
                                name="password"
                                value={userData.password}
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                onChange={handleChange}
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </Grid>

                        {showErr && <Grid item xs={12}>
                            <p style={{ color: "red" }}>{"Link is invalid or expired"}</p>
                        </Grid>}
                        <Grid
                            container
                            item
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                        >
                            <Button disableElevation disabled={auth.isLoading} fullWidth size="large" type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
}

export default Verify;
