import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { editUser, createUser } from "../../store/admin";
import TextMaskCustom from "../TextMaskCustom";
import { getOrganizations } from "../../store/admin";

function UserModal({
  createModal,
  open,
  onModalClose,
  data,
  handleDataChange,
  isLoading,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const adminStore = useSelector((store) => store.admin);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (createModal) {
      dispatch(createUser({ ...data, password: 'Test123!@#' }));
    } else {
      dispatch(editUser(data));
    }
    setShowPassword(false);
  };

  const handleModalClose = () => {
    setShowPassword(false);
    onModalClose();
  };

  useEffect(() => {
    if (adminStore.organizations.length === 0) {
      dispatch(getOrganizations());
    }
  }, [adminStore]);

  return (
    <Dialog open={open} onClose={handleModalClose}>
      <DialogTitle>{createModal ? "Add a new user" : "Edit user"}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container justifyContent="center" spacing={2} mt={1}>
            {createModal && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  name="email"
                  type="email"
                  value={data.email}
                  onChange={handleDataChange}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="First Name"
                name="firstName"
                value={data.firstName}
                onChange={handleDataChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Last Name"
                name="lastName"
                value={data.lastName}
                onChange={handleDataChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={data.phone}
                onChange={handleDataChange}
                name="phone"
                label="Phone Number"
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />
            </Grid>
            {adminStore.organizations.length > 0 && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Organization
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.organizationId || ""}
                    label="Organization"
                    name="organizationId"
                    onChange={(e) =>
                      handleDataChange(
                        e,
                        "org",
                        adminStore.organizations.find(
                          (item) => item.id === e.target.value
                        ).companyName
                      )
                    }
                  >
                    {adminStore.organizations.map((org, index) => (
                      <MenuItem value={org.id} key={index}>
                        {org.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                required
                multiline
                label="Notes"
                name="notes"
                maxRows={4}
                minRows={3}
                value={data?.notes}
                onChange={handleDataChange}
              />
            </Grid>
            <Grid item xs={12} justifyContent="flex-end" display="flex">
              <LoadingButton
                variant="contained"
                sx={{ marginRight: "10px" }}
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Save
              </LoadingButton>
              <Button variant="outlined" onClick={() => handleModalClose()}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UserModal;
