import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
    Button,
    TextField,
    Box,
    InputAdornment,
    IconButton,
    Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// import TextMaskCustom from "../../components/TextMaskCustom";
import { getUserInfo } from "../../store/auth";
import { getUserId } from "../../utils/cookie";
import { getCurrentUserInfo, updateUserPassword } from "../../plugins/firebase";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        width: "100%",
        minHeight: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loginContainer: {
        maxWidth: "500px",
        "& .MuiGrid-root": {
            width: "auto",
        },
    },
    button: {
        textDecoration: "none",
    },
    forgotPassLink: {
        color: "#000000",
        border: "none",
        background: "none",
        "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        },
    },
}));

export default function MyAccount() {
    const styles = useStyles();
    const auth = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const dispatch = useDispatch();

    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: '',
    });
    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        const load = async () => {
            const id = getUserId();
            const res = await getCurrentUserInfo(id);
            if (res.success) {
                const { timestamp, timezone, ...user } = res.user
                setUserData({
                    ...userData, ...user
                })
            }
        }
        load()

    }, []);

    return <div className={styles.root}>
        <Box
            component="form"
            onSubmit={async (e) => {
                e.preventDefault();
                await updateUserPassword(userData.password);
            }}
            zIndex="2"
        >
            <Grid
                container
                item
                justifyContent="center"
                className={styles.loginContainer}
                p={{ xs: "15px" }}
                spacing={2}
            >
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        variant="outlined"
                        name="firstName"
                        type="text"
                        label="First Name"
                        fullWidth
                        onChange={handleChange}
                        value={userData.firstName}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        variant="outlined"
                        name="lastName"
                        type="text"
                        label="Last Name"
                        fullWidth
                        onChange={handleChange}
                        value={userData.lastName}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        value={userData.email}
                        variant="outlined"
                        name="email"
                        type="email"
                        label="Email"
                        fullWidth
                        onChange={handleChange}
                        disabled
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={userData.phone}
                        onChange={handleChange}
                        name="phone"
                        label="Phone Number"
                    InputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                        required
                        variant="outlined"
                        name="password"
                        value={userData.password}
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        onChange={handleChange}
                        InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="right"
                    xs={12}
                >
                    <Button disableElevation disabled={auth.isLoading} fullWidth size="large" type="submit" variant="contained" color="primary">
                        Update
                    </Button>
                    {/* <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={auth.isLoading}
                    >
                        Update
                    </LoadingButton> */}

                </Grid>
            </Grid>
        </Box>
    </div>
}
