import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

function ConfirmModal({
  open,
  onModalClose,
  onModalConfirm,
  askingContent,
  isLoading,
}) {
  return (
    <Dialog open={open} onClose={onModalClose}>
      <DialogTitle id="alert-dialog-title">{askingContent}</DialogTitle>
      <DialogActions>
        <Button onClick={onModalClose}>No</Button>
        <Button
          variant="contained"
          color="success"
          autoFocus
          onClick={onModalConfirm}
          disabled={isLoading}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
