
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Details from "./details";
import Pending from "../Pending";

import { selectUser } from "../../store/auth";
import ROLES from "../../roles";

import "./style.scss";
import { useEffect, useState } from "react";
import { firebase } from "../../plugins";
import NoAccess from "../NoAccess";

export default function Dashboard() {
    const user = useSelector(selectUser);
    const dataClass = "MMG National"
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        const load = async () => {
            const res = await firebase.gerOrganizationByID(user.organizationId)
            const dataset = res.organization.dataset;
            setDataset(dataset)
        }
        load()
    }, [user])


    if (user?.role === ROLES.ADMIN) {
        return <Outlet />;
    }

    if (!user?.organizationId) {
        return <Pending />;
    }

    if (!dataset || dataset.length === 0 || dataset.indexOf(dataClass) === -1) {
        return <NoAccess />;
    }

    return <Details />;
}
