import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Typography,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import useAuth from "../../contexts/AuthContext";
import TextMaskCustom from "../../components/TextMaskCustom";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginContainer: {
    maxWidth: "500px",
    "& .MuiGrid-root": {
      width: "auto",
    },
  },
  button: {
    textDecoration: "none",
  },
  forgotPassLink: {
    color: "#000000",
    border: "none",
    background: "none",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

function SignUp() {
  const styles = useStyles();
  const { onSignUp } = useAuth();
  const auth = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: '',
  });

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  return (
    <div className={styles.root}>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          let customData = {
            ...userData,
            role: "user",
          };
          onSignUp(customData);
        }}
        zIndex="2"
      >
        <Grid
          container
          item
          justifyContent="center"
          className={styles.loginContainer}
          p={{ xs: "15px" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4" mt={3} mb={3}>
              Register
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              name="firstName"
              type="text"
              label="First Name"
              fullWidth
              onChange={handleChange}
              value={userData.firstName}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              name="lastName"
              type="text"
              label="Last Name"
              fullWidth
              onChange={handleChange}
              value={userData.lastName}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={userData.email}
              variant="outlined"
              name="email"
              type="email"
              label="Email"
              fullWidth
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={userData.phone}
              onChange={handleChange}
              name="phone"
              label="Phone Number"
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              name="password"
              value={userData.password}
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              onChange={handleChange}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
          {auth.error && (
            <Grid item xs={12}>
              <p style={{ color: "red" }}>{auth.error}</p>
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
            xs={12}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={auth.isLoading}
            >
              Sign Up
            </LoadingButton>
            <Link to="/login" style={{ color: "white" }}>
              Already having an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default SignUp;
