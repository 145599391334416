export const VISIBLE_FIELDS = [
    "state",
    "provider",
    "group",
    "procedure",
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
];

export const VISIBLE_FIELDS_EXTENDED = [
    "provider",
    "group",
    "procedure",
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
];

export const SortOrder = ["Detox", "RTC", "PHP", "IOP", "OP"];

export const air_token = 'patGvbfNJugJ5Bc66.a1f624794a28ec7d1cf89fa79500d1b4d16004aac9c99be9ac37e6f93333f9ff';
