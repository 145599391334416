import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Details from "../Details";
import Pending from "../Pending";
import { selectUser } from "../../store/auth";
import ROLES from "../../roles";
import { useEffect, useState } from "react";
import { firebase } from "../../plugins";
import NoAccess from "../NoAccess";

import "./style.scss";

export default function Dashboard() {
  const user = useSelector(selectUser);
  const dataClass = "MMG Main"
  const [dataset, setDataset] = useState([])

  useEffect(() => {
    const load = async () => {
      const res = await firebase.gerOrganizationByID(user.organizationId)
      const dataset = res.organization.dataset;
      setDataset(dataset)
    }
    load()
  }, [user])

  if (user?.role === ROLES.ADMIN) {
    return <Outlet />;
  }

  if (!dataset || dataset.length === 0 || dataset.indexOf(dataClass) === -1) {
    return <NoAccess />;
  }

  if (!user?.organizationId) {
    return <Pending />;
  }

  return <Details />;
}
