export const tableData = [
    {
        "2020": 1198,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recexXnP5sF8LRWIz",
        "row": 2219,
        "state": "ARIZONA",
        "group": "08662980100001",
        "group_num": [
            "recjm1oAyUjK38nV1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 244,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receDnwlq1d0HKHrX",
        "row": 2251,
        "state": "ARIZONA",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 461,
        "2021": 461,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receDZI7YXhvPFhZy",
        "row": 2288,
        "state": "ARIZONA",
        "group": "014388801400001",
        "group_num": [
            "rec67LcNGulgbewlI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receGxp6cM1OYqrLJ",
        "row": 2202,
        "state": "ARIZONA",
        "group": "0697478",
        "group_num": [
            "recBkBh3uru4YRcjr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 344,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recen8Ak881WlJgBk",
        "row": 2216,
        "state": "ARIZONA",
        "group": "1495540100000",
        "group_num": [
            "recGjsPSMV9xtgnLj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1311,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfcRRVEe6A4lDEm",
        "row": 2221,
        "state": "ARIZONA",
        "group": "10918102400012",
        "group_num": [
            "rechmRquepZl0ula8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1529,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfeKPcvfyrGrn1z",
        "row": 2286,
        "state": "ARIZONA",
        "group": "014281901200001",
        "group_num": [
            "rec4R5H4NazAGgwu5"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1138,
        "2022": 0,
        "2023": 1232,
        "2024": 0,
        "id": "recgbxnDaGNxlIAiv",
        "row": 2284,
        "state": "ARIZONA",
        "group": "014197401000006",
        "group_num": [
            "recrYSMm0n6EOxhf5"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recirD96nSihTPCFi",
        "row": 2199,
        "state": "ARIZONA",
        "group": "285731",
        "group_num": [
            "recYQlnqfOxeNElfu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 590,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjymV2LWzJO26ml",
        "row": 2229,
        "state": "ARIZONA",
        "group": "87709304400210",
        "group_num": [
            "recWc0ruggBHSRZ7j"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckk5E5rUITyp4fo",
        "row": 2262,
        "state": "ARIZONA",
        "group": "011523902000101",
        "group_num": [
            "rechT0BHQKA13HNy1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 899,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclZ8OM2arzuOhyW",
        "row": 2276,
        "state": "ARIZONA",
        "group": "013608401000701",
        "group_num": [
            "recskyh0iP5IXZMKx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2705,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recljGxv7nlTX5vTW",
        "row": 2264,
        "state": "ARIZONA",
        "group": "011524001200108",
        "group_num": [
            "recurrrJBxaPzlo12"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 245,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmuz9sUCdAutkTu",
        "row": 2232,
        "state": "ARIZONA",
        "group": "001502502200002",
        "group_num": [
            "recURnEMhuDb5twdr"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1093,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmz8lLeLduAaOj7",
        "row": 2272,
        "state": "ARIZONA",
        "group": "012057801200001",
        "group_num": [
            "recRGHxOiGjLATR9k"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 718,
        "2023": 597,
        "2024": 0,
        "id": "recnukjDRtfMbLXPH",
        "row": 2240,
        "state": "ARIZONA",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 645,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOwa0NDbv7qL9Nc",
        "row": 2278,
        "state": "ARIZONA",
        "group": "014194801200001",
        "group_num": [
            "recDwoDQphm0orKh9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 1763,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recOGFz9b9HHPITXM",
        "row": 2248,
        "state": "ARIZONA",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 418,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOb6CdHxGnscNUX",
        "row": 2201,
        "state": "ARIZONA",
        "group": "0476687",
        "group_num": [
            "reckHMkpq3DZPCOh1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1093,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOi0P1tZAFKwOzW",
        "row": 2222,
        "state": "ARIZONA",
        "group": "16022401000001",
        "group_num": [
            "recRmkc7M2nBCxvO7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 994,
        "2022": 1690,
        "2023": 0,
        "2024": 0,
        "id": "recPYYYmabF41LJfw",
        "row": 2287,
        "state": "ARIZONA",
        "group": "014370501000001",
        "group_num": [
            "recMCTvVm8VynvlJu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 286,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPNXiulbAz6LSkb",
        "row": 2289,
        "state": "ARIZONA",
        "group": "014388801400001",
        "group_num": [
            "rec67LcNGulgbewlI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3403,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP6fPyKE0RDLn69",
        "row": 2218,
        "state": "ARIZONA",
        "group": "07794090420050",
        "group_num": [
            "recNPMhuxlYfVRfx9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1494,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQsdhebZWRSs8yk",
        "row": 2282,
        "state": "ARIZONA",
        "group": "014197401000004",
        "group_num": [
            "recahYjQfIaI8LRDu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 84,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRYZHq4h6K567dU",
        "row": 2198,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 272,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR33lqktVEimMRL",
        "row": 2294,
        "state": "ARIZONA",
        "group": "014419701400118",
        "group_num": [
            "recnCMOBv7AtcmanF"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 413,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSH8ubFTdG09QVQ",
        "row": 2234,
        "state": "ARIZONA",
        "group": "010029002000001",
        "group_num": [
            "recLpax7Vu4FMY0rI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1341,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT7XcM8x3zCBfO3",
        "row": 2259,
        "state": "ARIZONA",
        "group": "011515901300001",
        "group_num": [
            "recbCNduoj5L1uWar"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 246,
        "2023": 0,
        "2024": 0,
        "id": "recWF2JyoeSQ9u8oz",
        "row": 2226,
        "state": "ARIZONA",
        "group": "75538501000725",
        "group_num": [
            "recP74meVjqTqAh2c"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 950,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXAOGzWY3H7cOTE",
        "row": 2213,
        "state": "ARIZONA",
        "group": "0863886010000",
        "group_num": [
            "recKqPpm2a0yRjeCc"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 1198,
        "2021": 1651,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY3vN6dpGMo6Sde",
        "row": 2254,
        "state": "ARIZONA",
        "group": "010914301100101",
        "group_num": [
            "recs9MLDq6YbDc0Nt"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1683,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYqfRpzFdo5AQj9",
        "row": 2227,
        "state": "ARIZONA",
        "group": "81004001100260",
        "group_num": [
            "recLBYFNbyipKQUWl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "rec0uNfUvaBvFZOd1",
        "row": 2203,
        "state": "ARIZONA",
        "group": "0767732",
        "group_num": [
            "rec257Rs2qWuE76MQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 576,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1YhnHYFFCgw1HA",
        "row": 2233,
        "state": "ARIZONA",
        "group": "010029002000001",
        "group_num": [
            "recLpax7Vu4FMY0rI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1457,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec16LvKQQX75Wgy1",
        "row": 2212,
        "state": "ARIZONA",
        "group": "0863886010000",
        "group_num": [
            "recKqPpm2a0yRjeCc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1167,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1c5eWhhuBpEosP",
        "row": 2206,
        "state": "ARIZONA",
        "group": "0885594",
        "group_num": [
            "reclHEXXZiaUkNFLn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "2023": 0,
        "2024": 0,
        "id": "rec1jvb5tMhiPQ3TW",
        "row": 2236,
        "state": "ARIZONA",
        "group": "010029003300001",
        "group_num": [
            "recLg9li1OUKYUeWd"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3x6sKc7bcf3s2t",
        "row": 2217,
        "state": "ARIZONA",
        "group": "2318030140000",
        "group_num": [
            "recaiqxLfnPrAB3c0"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 120,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3jOpnZnQAXvWb2",
        "row": 2290,
        "state": "ARIZONA",
        "group": "014388801400001",
        "group_num": [
            "rec67LcNGulgbewlI"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 1811,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4orcHppYEFv0y3",
        "row": 2246,
        "state": "ARIZONA",
        "group": "010903401100003",
        "group_num": [
            "recGMRHxxl1WZ1fnj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4rssn9TCV8ad5P",
        "row": 2207,
        "state": "ARIZONA",
        "group": "086545001",
        "group_num": [
            "recBt7mJGj42mZUVq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "2023": 0,
        "2024": 0,
        "id": "rec5NrJ29lfmIJFbj",
        "row": 2281,
        "state": "ARIZONA",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "rec6gC5tC1ULXIhKc",
        "row": 2275,
        "state": "ARIZONA",
        "group": "013250701000709",
        "group_num": [
            "recHzOdxbarn4PolP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1163,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7Cg3g7dMo0lNC2",
        "row": 2257,
        "state": "ARIZONA",
        "group": "010927601100002",
        "group_num": [
            "recPc612JUIgZylJE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 633,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7DZ8ZnyS75jpu9",
        "row": 2293,
        "state": "ARIZONA",
        "group": "014419701400118",
        "group_num": [
            "recnCMOBv7AtcmanF"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "2023": 0,
        "2024": 0,
        "id": "rec8FBvSPK8WUidYl",
        "row": 2241,
        "state": "ARIZONA",
        "group": "010198703000110",
        "group_num": [
            "recDGrbUtx0x9r6Sp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1187,
        "2023": 0,
        "2024": 0,
        "id": "rec8Ru9a11a0nb25q",
        "row": 2291,
        "state": "ARIZONA",
        "group": "014389901300001",
        "group_num": [
            "recKKyfm8xVl1nvhy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9OQ1ufW7Rz7gMV",
        "row": 2255,
        "state": "ARIZONA",
        "group": "010914301100101",
        "group_num": [
            "recs9MLDq6YbDc0Nt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 838,
        "2022": 163,
        "2023": 0,
        "2024": 0,
        "id": "rec9dIcofmLocNWes",
        "row": 2235,
        "state": "ARIZONA",
        "group": "010029002000001",
        "group_num": [
            "recLpax7Vu4FMY0rI"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca6A3jZHMakn22g",
        "row": 2292,
        "state": "ARIZONA",
        "group": "014419701400118",
        "group_num": [
            "recnCMOBv7AtcmanF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1837,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaoCQy5xjjkTGoQ",
        "row": 2271,
        "state": "ARIZONA",
        "group": "012057801200001",
        "group_num": [
            "recRGHxOiGjLATR9k"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1957,
        "2023": 0,
        "2024": 0,
        "id": "recbvFbU2erFJorTl",
        "row": 2266,
        "state": "ARIZONA",
        "group": "011916501100001",
        "group_num": [
            "recE76qxH1K7jCvrw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 966,
        "2023": 0,
        "2024": 0,
        "id": "recboAATIc6BXW7up",
        "row": 2267,
        "state": "ARIZONA",
        "group": "011916501100001",
        "group_num": [
            "recE76qxH1K7jCvrw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 300,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccS0KKGReRDLNYY",
        "row": 2214,
        "state": "ARIZONA",
        "group": "0863886010000",
        "group_num": [
            "recKqPpm2a0yRjeCc"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdFNs2PURcpFsPB",
        "row": 2253,
        "state": "ARIZONA",
        "group": "010914301100001",
        "group_num": [
            "recUC7senCQ48M895"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 914,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoSzLV0tEd9I8O9",
        "row": 2279,
        "state": "ARIZONA",
        "group": "014197401000002",
        "group_num": [
            "recKJBXjgkf6rWogW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "2023": 0,
        "2024": 0,
        "id": "recodSjzJDMmaS67R",
        "row": 2225,
        "state": "ARIZONA",
        "group": "75538501000725",
        "group_num": [
            "recP74meVjqTqAh2c"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpWPdyCkuantBJY",
        "row": 2245,
        "state": "ARIZONA",
        "group": "010903401100003",
        "group_num": [
            "recGMRHxxl1WZ1fnj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1451,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpzoXfWJ1kWEDpL",
        "row": 2283,
        "state": "ARIZONA",
        "group": "014197401000005",
        "group_num": [
            "rec6Ek7afvM7iHE1H"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 726,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpR4fdcIRvsmToz",
        "row": 2265,
        "state": "ARIZONA",
        "group": "011781901000701",
        "group_num": [
            "reclztFk50ZD5GqTB"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1279,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpdxKo6jX21vlhz",
        "row": 2209,
        "state": "ARIZONA",
        "group": "0141902010000",
        "group_num": [
            "rec2EO3A9icT7QEFW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 691,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqIE3veOvTye2yw",
        "row": 2297,
        "state": "ARIZONA",
        "group": "014637901000700",
        "group_num": [
            "recCHQcwL8SQG5xJC"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 651,
        "2023": 0,
        "2024": 0,
        "id": "recqP24dxcqO7ZpQN",
        "row": 2250,
        "state": "ARIZONA",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 3900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq4xs05Ck0VNYwc",
        "row": 2296,
        "state": "ARIZONA",
        "group": "014637901000700",
        "group_num": [
            "recCHQcwL8SQG5xJC"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq5FUbxwVPgiYYv",
        "row": 2208,
        "state": "ARIZONA",
        "group": "088352801",
        "group_num": [
            "recjfTtbHv0QNfZNA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recq9ne5TJWAEIilp",
        "row": 2277,
        "state": "ARIZONA",
        "group": "013837201600001",
        "group_num": [
            "recJBJeUazq5EZdL1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1649,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrLQCNxu702Jype",
        "row": 2274,
        "state": "ARIZONA",
        "group": "012057801200001",
        "group_num": [
            "recRGHxOiGjLATR9k"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1625,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrPL2jNRYHJWKZg",
        "row": 2205,
        "state": "ARIZONA",
        "group": "0868798",
        "group_num": [
            "reci9AEfZN1b8Eqwv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1230,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrmcso24LvtknsV",
        "row": 2220,
        "state": "ARIZONA",
        "group": "08687380230000",
        "group_num": [
            "receXhngGpVl8JWKT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 867,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsp5hkEKwpZtKiw",
        "row": 2204,
        "state": "ARIZONA",
        "group": "0776208",
        "group_num": [
            "recNbe3KZp12bpqm7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 896,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectQExqXo9eWsTi7",
        "row": 2273,
        "state": "ARIZONA",
        "group": "012057801200001",
        "group_num": [
            "recRGHxOiGjLATR9k"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "2023": 0,
        "2024": 0,
        "id": "recuXYSKCFUmTCGKZ",
        "row": 2239,
        "state": "ARIZONA",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuRpYxjhZyL7q0N",
        "row": 2243,
        "state": "ARIZONA",
        "group": "010544601000001",
        "group_num": [
            "recZFhABCjoJ51gXr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvB0qTZg2qgUChT",
        "row": 2258,
        "state": "ARIZONA",
        "group": "010928601000001",
        "group_num": [
            "recODmsC91HbrGGek"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recvKT0UcBOSnOyQw",
        "row": 2252,
        "state": "ARIZONA",
        "group": "010914301100001",
        "group_num": [
            "recUC7senCQ48M895"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2922,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvqoONp2SNLXlQq",
        "row": 2210,
        "state": "ARIZONA",
        "group": "0141980010000",
        "group_num": [
            "recwxRclCu928FDRJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 2460,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvroTBD4flxtCOp",
        "row": 2242,
        "state": "ARIZONA",
        "group": "010198703100012",
        "group_num": [
            "rec65u4nNbmyG0h6U"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwVn4QqyjgEP6Sr",
        "row": 2231,
        "state": "ARIZONA",
        "group": "88595001000003",
        "group_num": [
            "recGUgCFdG8m7mcrV"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1302,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczZIS1QnWQbG0Gf",
        "row": 2200,
        "state": "ARIZONA",
        "group": "804195",
        "group_num": [
            "recOOa5RKDkOEDtlP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1291,
        "2023": 1160,
        "2024": 0,
        "id": "reczPro9L5HRh5nsm",
        "row": 2237,
        "state": "ARIZONA",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 3900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBSXNo1gCu8P80h",
        "row": 2211,
        "state": "ARIZONA",
        "group": "0839202000001",
        "group_num": [
            "recSQdTcDYgUhqBRU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 385,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBYEwSHKjFg4Hot",
        "row": 2269,
        "state": "ARIZONA",
        "group": "012035801000001",
        "group_num": [
            "recBPKP5FTtyVK8TM"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 551,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCGGeVoX6YIaxpb",
        "row": 2268,
        "state": "ARIZONA",
        "group": "012035801000001",
        "group_num": [
            "recBPKP5FTtyVK8TM"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 761,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDGseZa8GduLJ6Y",
        "row": 2263,
        "state": "ARIZONA",
        "group": "011523902000101",
        "group_num": [
            "rechT0BHQKA13HNy1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "2023": 0,
        "2024": 0,
        "id": "recDPHKBb7dNbuGsh",
        "row": 2238,
        "state": "ARIZONA",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 462,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFfIngHfDNMI3ne",
        "row": 2244,
        "state": "ARIZONA",
        "group": "010898801100004",
        "group_num": [
            "recbrBBpJPvV4qnFw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recG4wE19GZBhhitz",
        "row": 2260,
        "state": "ARIZONA",
        "group": "011515901300001",
        "group_num": [
            "recbCNduoj5L1uWar"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 2031,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHWIxPD3NrqSz8n",
        "row": 2224,
        "state": "ARIZONA",
        "group": "28573104000001",
        "group_num": [
            "rectORreoEIs1948I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2655,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHmgS61ViFypjxO",
        "row": 2256,
        "state": "ARIZONA",
        "group": "010914301100101",
        "group_num": [
            "recs9MLDq6YbDc0Nt"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 365,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIm6VztSy35BG8G",
        "row": 2223,
        "state": "ARIZONA",
        "group": "23168601700001",
        "group_num": [
            "recJH46p1B3wUOiY7"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 752,
        "2021": 0,
        "2022": 1625,
        "2023": 0,
        "2024": 0,
        "id": "recKLaxvLKvxc9Izf",
        "row": 2280,
        "state": "ARIZONA",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 673,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKMYmOlRTevKlFA",
        "row": 2215,
        "state": "ARIZONA",
        "group": "1495540100000",
        "group_num": [
            "recGjsPSMV9xtgnLj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 822,
        "2022": 871,
        "2023": 0,
        "2024": 0,
        "id": "recK9oMvJDQp90YWf",
        "row": 2249,
        "state": "ARIZONA",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1313,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recL8CpqJzGliAZ9K",
        "row": 2285,
        "state": "ARIZONA",
        "group": "014197401000006",
        "group_num": [
            "recrYSMm0n6EOxhf5"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNzfdMEARrSjgCS",
        "row": 2228,
        "state": "ARIZONA",
        "group": "84680302000008",
        "group_num": [
            "recjj6wC805asanID"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1436,
        "2023": 0,
        "2024": 0,
        "id": "recNg7FHFd8c1pAST",
        "row": 2230,
        "state": "ARIZONA",
        "group": "87715701000001",
        "group_num": [
            "rechkCv13lQHAED6m"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 490,
        "2021": 516,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece0HbyyyU5XUNR4",
        "row": 2390,
        "state": "ARIZONA",
        "group": "060452001400101",
        "group_num": [
            "rec6zVMx8xdrQgOoT"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 1530,
        "2024": 0,
        "id": "recePV8Ktmuw7gVCG",
        "row": 2330,
        "state": "ARIZONA",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 180,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recephJeP0IyGyFYT",
        "row": 2324,
        "state": "ARIZONA",
        "group": "023180301500001",
        "group_num": [
            "recjXwoASpyygslHQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgwhNG4nolAxC5y",
        "row": 2397,
        "state": "ARIZONA",
        "group": "065872005500002",
        "group_num": [
            "rec0UHaMtDQOvoa8L"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1890,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgqeZH5v7GZ4Xyg",
        "row": 2375,
        "state": "ARIZONA",
        "group": "047926001300001",
        "group_num": [
            "rec7tiyGOvU5u02Y0"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1097,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechYJN4e9UgqF44o",
        "row": 2379,
        "state": "ARIZONA",
        "group": "048681201000001",
        "group_num": [
            "recKsVrQ8I41NtwgT"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2590,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjYAcUiY1xScYlu",
        "row": 2372,
        "state": "ARIZONA",
        "group": "047757601100001",
        "group_num": [
            "recK32zHIVCrzFn99"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 3325,
        "2021": 645,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck20ISdw47g6gIs",
        "row": 2338,
        "state": "ARIZONA",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 1620,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl3PHsdd4ZaSzaz",
        "row": 2366,
        "state": "ARIZONA",
        "group": "047668701100004",
        "group_num": [
            "recu8MTf3rfZeMy5m"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 389,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm6bGYszlP2fZOu",
        "row": 2395,
        "state": "ARIZONA",
        "group": "065748701200506",
        "group_num": [
            "recIeMCUSFukLxeaL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1870,
        "2021": 1365,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmbw75DSrfQqFhy",
        "row": 2370,
        "state": "ARIZONA",
        "group": "047668701200004",
        "group_num": [
            "recNoRIk1qBZCK30n"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 901,
        "2023": 0,
        "2024": 0,
        "id": "recmprUBn277iQY13",
        "row": 2318,
        "state": "ARIZONA",
        "group": "023173101400021",
        "group_num": [
            "recDOQ1rPJ3szPXFm"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3243,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnANaGODIQ0Raa9",
        "row": 2357,
        "state": "ARIZONA",
        "group": "036962303200100",
        "group_num": [
            "recgPACOKh72RGK6Y"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 543,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOHZNfJbwPejoDX",
        "row": 2298,
        "state": "ARIZONA",
        "group": "014637901000700",
        "group_num": [
            "recCHQcwL8SQG5xJC"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1890,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOlTdLZUQB9UToG",
        "row": 2354,
        "state": "ARIZONA",
        "group": "031777401400002",
        "group_num": [
            "recnXe5nggqiaV9r1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 3800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPsLsPwS3KHNkkg",
        "row": 2321,
        "state": "ARIZONA",
        "group": "023180301400001",
        "group_num": [
            "recdrrf3q2zPYedHo"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPBNjUDmdbI2wFH",
        "row": 2368,
        "state": "ARIZONA",
        "group": "047668701200004",
        "group_num": [
            "recNoRIk1qBZCK30n"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1420,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPkf9TNs02vmXy7",
        "row": 2313,
        "state": "ARIZONA",
        "group": "016970101100001",
        "group_num": [
            "recGt64WfBgnUEpRl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 702,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQsjsc3hMLhhVva",
        "row": 2344,
        "state": "ARIZONA",
        "group": "028725101000002",
        "group_num": [
            "recVA78cc874JaXdc"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQxX7yiFAF7sf1i",
        "row": 2350,
        "state": "ARIZONA",
        "group": "030716301200050",
        "group_num": [
            "recdjWYwYWHkoKeho"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3161,
        "2023": 0,
        "2024": 0,
        "id": "recQeOzOGQXMB0Nhr",
        "row": 2299,
        "state": "ARIZONA",
        "group": "014683701000001",
        "group_num": [
            "reciFG2JgcidFVb3t"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRpUxIHcSXmCBfg",
        "row": 2362,
        "state": "ARIZONA",
        "group": "046974301600101",
        "group_num": [
            "recWLurKkJJKWbNwb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1749,
        "2023": 0,
        "2024": 0,
        "id": "recSTjRQBGBxzzi0k",
        "row": 2358,
        "state": "ARIZONA",
        "group": "039363001900011",
        "group_num": [
            "rec0Chf1ZClUkoFMJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1622,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSK33P86sd4jfGv",
        "row": 2308,
        "state": "ARIZONA",
        "group": "016949701100004",
        "group_num": [
            "recwhyPjba89VyrJm"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1628,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS7O7l9BEH67PuN",
        "row": 2305,
        "state": "ARIZONA",
        "group": "016696601000700",
        "group_num": [
            "recGaNY3uHbAbytqt"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 448,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTSqSAOdMFR5Ibs",
        "row": 2341,
        "state": "ARIZONA",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2871,
        "2023": 0,
        "2024": 0,
        "id": "recTOScK6OUF5v5ab",
        "row": 2312,
        "state": "ARIZONA",
        "group": "016964501000001",
        "group_num": [
            "recWMxgffyObEk0O2"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 539,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTlQOryS7ioejad",
        "row": 2345,
        "state": "ARIZONA",
        "group": "028725101000002",
        "group_num": [
            "recVA78cc874JaXdc"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 783,
        "2021": 1101,
        "2022": 966,
        "2023": 0,
        "2024": 0,
        "id": "recUQWV0Wd6a1q5iA",
        "row": 2339,
        "state": "ARIZONA",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 620,
        "2023": 0,
        "2024": 0,
        "id": "recU7GwTzZ5aGUBRq",
        "row": 2348,
        "state": "ARIZONA",
        "group": "030349501000709",
        "group_num": [
            "recKU8CDWJjGEnJjl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 121,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVVKARd89BAttnX",
        "row": 2309,
        "state": "ARIZONA",
        "group": "016949701100004",
        "group_num": [
            "recwhyPjba89VyrJm"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 724,
        "2021": 509,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWD54V8cTZQV4lb",
        "row": 2340,
        "state": "ARIZONA",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWHMxnIAJd7zRYY",
        "row": 2326,
        "state": "ARIZONA",
        "group": "028390701000001",
        "group_num": [
            "recYVUvWBUvQnzSnJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 543,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX2WrgDKQDcNcbU",
        "row": 2323,
        "state": "ARIZONA",
        "group": "023180301400001",
        "group_num": [
            "recdrrf3q2zPYedHo"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 0,
        "2024": 0,
        "id": "recX8mE5vV7F9HbQR",
        "row": 2352,
        "state": "ARIZONA",
        "group": "031483203300101",
        "group_num": [
            "rec3vku7V7Tmq2vjB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1277,
        "2023": 0,
        "2024": 0,
        "id": "recYsOkNZp7rKxXUq",
        "row": 2314,
        "state": "ARIZONA",
        "group": "017206301000700",
        "group_num": [
            "recp6ol7LLG4vfB8V"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0xVTGhfEJmPz8o",
        "row": 2359,
        "state": "ARIZONA",
        "group": "046859201100103",
        "group_num": [
            "reclcu7Po2K6CXjb6"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1694,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec17UxKoZYkLKYXf",
        "row": 2378,
        "state": "ARIZONA",
        "group": "048681201000001",
        "group_num": [
            "recKsVrQ8I41NtwgT"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 3800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec18QnJWYKQC2HWi",
        "row": 2369,
        "state": "ARIZONA",
        "group": "047668701200004",
        "group_num": [
            "recNoRIk1qBZCK30n"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec36UAIdUlxTA0g3",
        "row": 2311,
        "state": "ARIZONA",
        "group": "016951001000001",
        "group_num": [
            "recBXsn9NSc9NXYqW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1815,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5V0ranImwhGTFm",
        "row": 2365,
        "state": "ARIZONA",
        "group": "047668701100004",
        "group_num": [
            "recu8MTf3rfZeMy5m"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2700,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "rec6yZnMib76ubsWq",
        "row": 2374,
        "state": "ARIZONA",
        "group": "047926001300001",
        "group_num": [
            "rec7tiyGOvU5u02Y0"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "rec7Rv8Z3hugFzvh6",
        "row": 2388,
        "state": "ARIZONA",
        "group": "057922501700002",
        "group_num": [
            "recftolgD0cYlvZIq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 1030,
        "2024": 0,
        "id": "rec8P35ZDeE0DxuYb",
        "row": 2319,
        "state": "ARIZONA",
        "group": "023173101500021",
        "group_num": [
            "recz7gJxbuVW4B0SY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1016,
        "2023": 0,
        "2024": 0,
        "id": "rec85TBzjwKIx5ZuM",
        "row": 2364,
        "state": "ARIZONA",
        "group": "047500604100001",
        "group_num": [
            "recHLMcT9eHxic9MP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "2023": 0,
        "2024": 0,
        "id": "rec9quIHhMzwpd91y",
        "row": 2385,
        "state": "ARIZONA",
        "group": "053155401000005",
        "group_num": [
            "rec2XPI7amIHfted7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 883,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recatKDAAwNepEPcv",
        "row": 2329,
        "state": "ARIZONA",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 650,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recadS6lBPgQFhsYn",
        "row": 2304,
        "state": "ARIZONA",
        "group": "016669101000700",
        "group_num": [
            "recmS9uKS3Xu1oYE3"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 385,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbxGJj7teNNl7N8",
        "row": 2382,
        "state": "ARIZONA",
        "group": "050761001000706",
        "group_num": [
            "recCjaDhEnqEplLZO"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbIbr7a6Au42KXU",
        "row": 2334,
        "state": "ARIZONA",
        "group": "028573102000068",
        "group_num": [
            "rectr441ciknFyX15"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 691,
        "2021": 677,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc5o6lWez0kmdth",
        "row": 2322,
        "state": "ARIZONA",
        "group": "023180301400001",
        "group_num": [
            "recdrrf3q2zPYedHo"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1391,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdeD947F57ereZU",
        "row": 2394,
        "state": "ARIZONA",
        "group": "062021001000202",
        "group_num": [
            "recDKHHU3T4l6cr7w"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 556,
        "2024": 0,
        "id": "recpraemohDxQwmNy",
        "row": 2332,
        "state": "ARIZONA",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 1749,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqX2QL19tA9WlXR",
        "row": 2361,
        "state": "ARIZONA",
        "group": "046859201100113",
        "group_num": [
            "recNcWjKrSzgzNGSO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 967,
        "2021": 3375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsw4Ss6B5RERTXt",
        "row": 2336,
        "state": "ARIZONA",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "2023": 0,
        "2024": 0,
        "id": "recs7gdaA6qMD4bSr",
        "row": 2307,
        "state": "ARIZONA",
        "group": "016947502400005",
        "group_num": [
            "rec4hDBeIn9A4fGKg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 193,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recsdHvdgtkWuQGZF",
        "row": 2335,
        "state": "ARIZONA",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 792,
        "2021": 792,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsdTCrrq2iFStR8",
        "row": 2376,
        "state": "ARIZONA",
        "group": "047926001300001",
        "group_num": [
            "rec7tiyGOvU5u02Y0"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "2023": 0,
        "2024": 0,
        "id": "recsgWkPqcsg65YSp",
        "row": 2327,
        "state": "ARIZONA",
        "group": "028390701600001",
        "group_num": [
            "recb1RGSM4w4kPnfG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 4025,
        "2021": 1841,
        "2022": 1933,
        "2023": 0,
        "2024": 0,
        "id": "rectz8XasFS7d8r4H",
        "row": 2337,
        "state": "ARIZONA",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 3800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuvvpBVKds6k41h",
        "row": 2302,
        "state": "ARIZONA",
        "group": "015986201600004",
        "group_num": [
            "recVYmFIEj3KOK5WY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 564,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu25mnMoRc5k4dc",
        "row": 2381,
        "state": "ARIZONA",
        "group": "050761001000706",
        "group_num": [
            "recCjaDhEnqEplLZO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2140,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu3V6tAfzBnoOa4",
        "row": 2356,
        "state": "ARIZONA",
        "group": "032647901200001",
        "group_num": [
            "recSC7rJT3I2BiAi8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recubLI7F6TZlSoj9",
        "row": 2392,
        "state": "ARIZONA",
        "group": "060751602700004",
        "group_num": [
            "rec5r6orohihAjKIn"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 899,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvHvBft3tHWseVK",
        "row": 2301,
        "state": "ARIZONA",
        "group": "015986201600004",
        "group_num": [
            "recVYmFIEj3KOK5WY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 691,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy6zts2ZZEH4SBN",
        "row": 2300,
        "state": "ARIZONA",
        "group": "014846201200001",
        "group_num": [
            "reciPsUB4vZOMQolF"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 0,
        "2024": 0,
        "id": "recybeYxXO6EsntFF",
        "row": 2386,
        "state": "ARIZONA",
        "group": "053155401000005",
        "group_num": [
            "rec2XPI7amIHfted7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 889,
        "2023": 0,
        "2024": 0,
        "id": "recylRy5YWD9kbyaK",
        "row": 2391,
        "state": "ARIZONA",
        "group": "060751602700004",
        "group_num": [
            "rec5r6orohihAjKIn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1625,
        "2023": 0,
        "2024": 0,
        "id": "recz25z3q0y9ddNRc",
        "row": 2363,
        "state": "ARIZONA",
        "group": "047500604100001",
        "group_num": [
            "recHLMcT9eHxic9MP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 845,
        "2023": 763,
        "2024": 0,
        "id": "recAzewyjPtVTarFO",
        "row": 2331,
        "state": "ARIZONA",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 690,
        "2022": 712,
        "2023": 0,
        "2024": 0,
        "id": "recAzWFXxdGgNm6li",
        "row": 2384,
        "state": "ARIZONA",
        "group": "053155401000005",
        "group_num": [
            "rec2XPI7amIHfted7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1194,
        "2023": 0,
        "2024": 0,
        "id": "recABLsE8pL86IWMj",
        "row": 2315,
        "state": "ARIZONA",
        "group": "017574801000100",
        "group_num": [
            "recJxQoDze5GqHPc9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 687,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAJsvRMa4I0b7nv",
        "row": 2389,
        "state": "ARIZONA",
        "group": "060452001400101",
        "group_num": [
            "rec6zVMx8xdrQgOoT"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recANn7pR1uURqmQ7",
        "row": 2343,
        "state": "ARIZONA",
        "group": "028573105600001",
        "group_num": [
            "rec5WrpOv4zxcfHOT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBkWqykf0ZDsl6Z",
        "row": 2325,
        "state": "ARIZONA",
        "group": "023181801000001",
        "group_num": [
            "rec1r7926ywXyNW9I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 308,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recE0HJhC2nhTi3Hm",
        "row": 2351,
        "state": "ARIZONA",
        "group": "030716301200050",
        "group_num": [
            "recdjWYwYWHkoKeho"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 919,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEEEu0iWGPLnizQ",
        "row": 2360,
        "state": "ARIZONA",
        "group": "046859201100103",
        "group_num": [
            "reclcu7Po2K6CXjb6"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1451,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEfXP9o22twMu35",
        "row": 2396,
        "state": "ARIZONA",
        "group": "065872002800004",
        "group_num": [
            "recvgrmY6AHYyWLlR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFFdYMOiOzw0KZg",
        "row": 2353,
        "state": "ARIZONA",
        "group": "031777401400002",
        "group_num": [
            "recnXe5nggqiaV9r1"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 639,
        "2023": 0,
        "2024": 0,
        "id": "recGKWiTUQ2kd2Y9X",
        "row": 2347,
        "state": "ARIZONA",
        "group": "030349501000709",
        "group_num": [
            "recKU8CDWJjGEnJjl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHT7E6usaESkeqB",
        "row": 2328,
        "state": "ARIZONA",
        "group": "028563001100101",
        "group_num": [
            "rec4jp0mDRx0xHd6T"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 3843,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH98UX0FYiAZrka",
        "row": 2387,
        "state": "ARIZONA",
        "group": "053446301100004",
        "group_num": [
            "recZw9oDPlixj6PHk"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 0,
        "2024": 0,
        "id": "recHpvFWhnSjAG50P",
        "row": 2349,
        "state": "ARIZONA",
        "group": "030349501000709",
        "group_num": [
            "recKU8CDWJjGEnJjl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recI5mvDIKv6cEgl3",
        "row": 2317,
        "state": "ARIZONA",
        "group": "023173101400001",
        "group_num": [
            "recUez4KhXJuKOL1O"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 1051,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJ9Ne3AJFmjKLQb",
        "row": 2320,
        "state": "ARIZONA",
        "group": "023180301400001",
        "group_num": [
            "recdrrf3q2zPYedHo"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 559,
        "2023": 690,
        "2024": 0,
        "id": "recKvJH4fTwJ3KvAc",
        "row": 2316,
        "state": "ARIZONA",
        "group": "017574801000100",
        "group_num": [
            "recJxQoDze5GqHPc9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1160,
        "2022": 1089,
        "2023": 0,
        "2024": 0,
        "id": "recKhuDn1iSJHnuIN",
        "row": 2306,
        "state": "ARIZONA",
        "group": "016947502400005",
        "group_num": [
            "rec4hDBeIn9A4fGKg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1054,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLj0CebcC1PlMAy",
        "row": 2303,
        "state": "ARIZONA",
        "group": "016669101000700",
        "group_num": [
            "recmS9uKS3Xu1oYE3"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNsQAHt66BWx1Ax",
        "row": 2373,
        "state": "ARIZONA",
        "group": "047926001300001",
        "group_num": [
            "rec7tiyGOvU5u02Y0"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1515,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNr17WvKkUYVLoM",
        "row": 2371,
        "state": "ARIZONA",
        "group": "047668701200004",
        "group_num": [
            "recNoRIk1qBZCK30n"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "2023": 0,
        "2024": 0,
        "id": "recftDBT4ywL6h5Lf",
        "row": 2441,
        "state": "ARIZONA",
        "group": "077379701000700",
        "group_num": [
            "recC55gAM5fbflf4H"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfALkHMi5jU7Fq2",
        "row": 2424,
        "state": "ARIZONA",
        "group": "071998202300001",
        "group_num": [
            "recrtpFuAyHg88qpM"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 213,
        "2021": 260,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfbFfbtEfzjos4E",
        "row": 2431,
        "state": "ARIZONA",
        "group": "072781301100001",
        "group_num": [
            "recPdNOsEmnWKFhgJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2977,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgE5auvY6LyJZ5t",
        "row": 2446,
        "state": "ARIZONA",
        "group": "077940904000501",
        "group_num": [
            "recIZxq6TFlJGAvgD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1149,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgLWAfi2jRWeJd9",
        "row": 2411,
        "state": "ARIZONA",
        "group": "070109301100002",
        "group_num": [
            "recb8swuyTP06s4qv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechZp3cIoq6by5tk",
        "row": 2433,
        "state": "ARIZONA",
        "group": "072781301100800",
        "group_num": [
            "reci2zt2nwuNRQ2eI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1094,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckQXQEdi57Ko2lF",
        "row": 2419,
        "state": "ARIZONA",
        "group": "070874201000707",
        "group_num": [
            "recJmXWBNXTx1zbNm"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1041,
        "2023": 0,
        "2024": 0,
        "id": "recmvxqODNOEijJU5",
        "row": 2403,
        "state": "ARIZONA",
        "group": "069747801500001",
        "group_num": [
            "recEtXAesEs2pu5Ix"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 543,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmHe0sRbs57iP57",
        "row": 2413,
        "state": "ARIZONA",
        "group": "070109301100002",
        "group_num": [
            "recb8swuyTP06s4qv"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 691,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn1dZmohUGjmRVp",
        "row": 2412,
        "state": "ARIZONA",
        "group": "070109301100002",
        "group_num": [
            "recb8swuyTP06s4qv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2140,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPCY0AZa6Ldjguq",
        "row": 2428,
        "state": "ARIZONA",
        "group": "072498101800002",
        "group_num": [
            "recLrGlX99rv6Zf2P"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 639,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP53Yd6q48uGxdW",
        "row": 2430,
        "state": "ARIZONA",
        "group": "072781301100001",
        "group_num": [
            "recPdNOsEmnWKFhgJ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 1933,
        "2023": 0,
        "2024": 0,
        "id": "recPj46xim1S8zoVf",
        "row": 2405,
        "state": "ARIZONA",
        "group": "069747803800001",
        "group_num": [
            "recxzh93EYtrtnPqT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recRu1SBi5Kzbb3LF",
        "row": 2422,
        "state": "ARIZONA",
        "group": "071871501700003",
        "group_num": [
            "recceKNPHcyg2e3Wa"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 1310,
        "2021": 1451,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRAUNFrhoAfqqe6",
        "row": 2425,
        "state": "ARIZONA",
        "group": "072465504400001",
        "group_num": [
            "recoNRa6Nw07sAP97"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1176,
        "2021": 906,
        "2022": 903,
        "2023": 0,
        "2024": 0,
        "id": "recRL6Sj8iQzKYWhr",
        "row": 2429,
        "state": "ARIZONA",
        "group": "072781301100001",
        "group_num": [
            "recPdNOsEmnWKFhgJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 980,
        "2023": 0,
        "2024": 0,
        "id": "recSu46V9pYwqqFzJ",
        "row": 2439,
        "state": "ARIZONA",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 3900,
        "2021": 1964,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT4fZDIanFrPOJl",
        "row": 2404,
        "state": "ARIZONA",
        "group": "069747803200003",
        "group_num": [
            "recFuaIRtHXrT57in"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1381,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWCCQeJrU6pgY6w",
        "row": 2401,
        "state": "ARIZONA",
        "group": "069744302900101",
        "group_num": [
            "recFzmgUDSwccDgKb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYswsNgs1cN5JJs",
        "row": 2409,
        "state": "ARIZONA",
        "group": "070108002600001",
        "group_num": [
            "rec6JWvXdfTt3msDL"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 0,
        "2024": 0,
        "id": "rec1pPhSXjNxnqsuE",
        "row": 2443,
        "state": "ARIZONA",
        "group": "077379701000700",
        "group_num": [
            "recC55gAM5fbflf4H"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 0,
        "2024": 0,
        "id": "rec375sQoQAkqL6Pw",
        "row": 2442,
        "state": "ARIZONA",
        "group": "077379701000700",
        "group_num": [
            "recC55gAM5fbflf4H"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 794,
        "2021": 1403,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec505vDczr36U6Sv",
        "row": 2434,
        "state": "ARIZONA",
        "group": "072781301200001",
        "group_num": [
            "rec9mO0RGavkeXOJ4"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5BSnaQjfAIjD9d",
        "row": 2410,
        "state": "ARIZONA",
        "group": "070109301100002",
        "group_num": [
            "recb8swuyTP06s4qv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5jJ8nThIxMnuPE",
        "row": 2427,
        "state": "ARIZONA",
        "group": "072470701600002",
        "group_num": [
            "recBOQ1vEuKMofB0I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 504,
        "2023": 0,
        "2024": 0,
        "id": "rec6Ik83K99u6Kjdj",
        "row": 2415,
        "state": "ARIZONA",
        "group": "070109301100803",
        "group_num": [
            "recpve2RVJ4meOSRX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbHcDoC3xH5Kddj",
        "row": 2408,
        "state": "ARIZONA",
        "group": "069825801300001",
        "group_num": [
            "rec9Lv2g9ffjp0Yiy"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 50,
        "2021": 50,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp5vdaQr4zrDFgG",
        "row": 2437,
        "state": "ARIZONA",
        "group": "072781301200001",
        "group_num": [
            "rec9mO0RGavkeXOJ4"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1381,
        "2023": 0,
        "2024": 0,
        "id": "recrcmMzeDNXRQyGQ",
        "row": 2421,
        "state": "ARIZONA",
        "group": "071871501700003",
        "group_num": [
            "recceKNPHcyg2e3Wa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 568,
        "2023": 294,
        "2024": 0,
        "id": "recsKRBcL5FAIOhkz",
        "row": 2416,
        "state": "ARIZONA",
        "group": "070109301100803",
        "group_num": [
            "recpve2RVJ4meOSRX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 624,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv4SFisupyyVZXw",
        "row": 2435,
        "state": "ARIZONA",
        "group": "072781301200001",
        "group_num": [
            "rec9mO0RGavkeXOJ4"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvdwwhc9R7jG7B6",
        "row": 2444,
        "state": "ARIZONA",
        "group": "077748001000702",
        "group_num": [
            "recLAYyHgBEZLNGJF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1092,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczzwQgFVvlbcllx",
        "row": 2447,
        "state": "ARIZONA",
        "group": "077940904000501",
        "group_num": [
            "recIZxq6TFlJGAvgD"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1102,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAxQCUDwOiwX5Eu",
        "row": 2398,
        "state": "ARIZONA",
        "group": "065874203100005",
        "group_num": [
            "recgVbO1Viv7zfQQt"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2136,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBMg4tsXPy22IAq",
        "row": 2440,
        "state": "ARIZONA",
        "group": "073735801000203",
        "group_num": [
            "recanSl4XKvpADDh8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBmeK2JChqlQE1g",
        "row": 2420,
        "state": "ARIZONA",
        "group": "070874201000707",
        "group_num": [
            "recJmXWBNXTx1zbNm"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDPBCoyMz7iz7XM",
        "row": 2407,
        "state": "ARIZONA",
        "group": "069825801300001",
        "group_num": [
            "rec9Lv2g9ffjp0Yiy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2502,
        "2023": 0,
        "2024": 0,
        "id": "recEmN994mMLt8xtK",
        "row": 2417,
        "state": "ARIZONA",
        "group": "070142601400001",
        "group_num": [
            "rec2xyKjIHLEuD4eb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFWpbJ2Qchy2XVf",
        "row": 2423,
        "state": "ARIZONA",
        "group": "071998201300002",
        "group_num": [
            "reci8btBOf41hemeL"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 875,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFAieNPcH3fDUQd",
        "row": 2402,
        "state": "ARIZONA",
        "group": "069744302900101",
        "group_num": [
            "recFzmgUDSwccDgKb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1062,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recG6EnzdPfhkHMV6",
        "row": 2399,
        "state": "ARIZONA",
        "group": "065874203100005",
        "group_num": [
            "recgVbO1Viv7zfQQt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 931,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGeiccFQ7XvCVIp",
        "row": 2406,
        "state": "ARIZONA",
        "group": "069747803800003",
        "group_num": [
            "recFKpHJRxeBeOxBw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "2023": 0,
        "2024": 0,
        "id": "recGn2mRDJvgacSvM",
        "row": 2418,
        "state": "ARIZONA",
        "group": "070142601400001",
        "group_num": [
            "rec2xyKjIHLEuD4eb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 687,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJpth1uJQprjzCg",
        "row": 2426,
        "state": "ARIZONA",
        "group": "072465504400001",
        "group_num": [
            "recoNRa6Nw07sAP97"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 289,
        "2021": 403,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKBBUE6k99NgzQ6",
        "row": 2436,
        "state": "ARIZONA",
        "group": "072781301200001",
        "group_num": [
            "rec9mO0RGavkeXOJ4"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1149,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKqIyzC6Y6gSMhz",
        "row": 2445,
        "state": "ARIZONA",
        "group": "077748001000702",
        "group_num": [
            "recLAYyHgBEZLNGJF"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 760,
        "2023": 0,
        "2024": 0,
        "id": "rechysOpxNCNt6LIP",
        "row": 2489,
        "state": "ARIZONA",
        "group": "086530705200001",
        "group_num": [
            "recjcij1LhUCLSnaW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 373,
        "2023": 0,
        "2024": 0,
        "id": "reciPRqeBGZaqWYWP",
        "row": 2477,
        "state": "ARIZONA",
        "group": "086364601200044",
        "group_num": [
            "recAk5b2Ymc0Y9VCJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1828,
        "2021": 914,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjtwanol9QMTSYW",
        "row": 2539,
        "state": "ARIZONA",
        "group": "086873802300001",
        "group_num": [
            "recTEBMVpZu3Yhuuq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 678,
        "2021": 691,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjaremOZiF6LJ7J",
        "row": 2462,
        "state": "ARIZONA",
        "group": "080631401000002",
        "group_num": [
            "recyXDldFyd4Sp9dO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 550,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjfKv6n8gK31cq9",
        "row": 2504,
        "state": "ARIZONA",
        "group": "086629801000002",
        "group_num": [
            "recc3SqXO9YoqZpum"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjkzj56hWz0bQEt",
        "row": 2467,
        "state": "ARIZONA",
        "group": "083585501000001",
        "group_num": [
            "rec68J1nXto7ocuGE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 441,
        "2021": 1449,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckvwS80tcE3koqC",
        "row": 2452,
        "state": "ARIZONA",
        "group": "077940904200501",
        "group_num": [
            "recH7diBrpdEIga2K"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1451,
        "2021": 1255,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckdOBlFzwGZ6Ywm",
        "row": 2503,
        "state": "ARIZONA",
        "group": "086629801000002",
        "group_num": [
            "recc3SqXO9YoqZpum"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2739,
        "2021": 1249,
        "2022": 1657,
        "2023": 1127,
        "2024": 0,
        "id": "recmbVRhvj7yn1tf8",
        "row": 2499,
        "state": "ARIZONA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1381,
        "2024": 0,
        "id": "recODB1YgY3Wp8cnm",
        "row": 2459,
        "state": "ARIZONA",
        "group": "080020104200100",
        "group_num": [
            "recDJzIo2QxZMDGfA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 550,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOcK9ox05ZIDqAf",
        "row": 2523,
        "state": "ARIZONA",
        "group": "086859802900001",
        "group_num": [
            "recPFCinVEkBiOf1b"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 193,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOjk468Jdy3QmaH",
        "row": 2473,
        "state": "ARIZONA",
        "group": "084701201600001",
        "group_num": [
            "rec4YgqMq9SyNzDhd"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "2023": 0,
        "2024": 0,
        "id": "recPtJWkcB2dCgTPi",
        "row": 2465,
        "state": "ARIZONA",
        "group": "081898201000001",
        "group_num": [
            "recnrPoMHjwadxjxl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 215,
        "2022": 0,
        "2023": 430,
        "2024": 0,
        "id": "recPzWYeev930gWLk",
        "row": 2536,
        "state": "ARIZONA",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recQXvslSEub12YOd",
        "row": 2475,
        "state": "ARIZONA",
        "group": "084728902000001",
        "group_num": [
            "recCXYap80QwW7pFE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 962,
        "2022": 1023,
        "2023": 0,
        "2024": 0,
        "id": "recQdVKMECYVeYetC",
        "row": 2520,
        "state": "ARIZONA",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 3800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQlWAC1PReOjiFG",
        "row": 2483,
        "state": "ARIZONA",
        "group": "086388501000001",
        "group_num": [
            "recjlcM8zTUdIw5xp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRuiUZThXNmc0RO",
        "row": 2498,
        "state": "ARIZONA",
        "group": "086621903100001",
        "group_num": [
            "recT0dk3eMGG1wtIL"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 773,
        "2024": 0,
        "id": "recStxyR9jt7fOOab",
        "row": 2521,
        "state": "ARIZONA",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "2023": 0,
        "2024": 0,
        "id": "recS6wb43eYbK5Kur",
        "row": 2487,
        "state": "ARIZONA",
        "group": "086530705200001",
        "group_num": [
            "recjcij1LhUCLSnaW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 836,
        "2023": 0,
        "2024": 0,
        "id": "recSfEmXbptGHdEH5",
        "row": 2466,
        "state": "ARIZONA",
        "group": "081898201000001",
        "group_num": [
            "recnrPoMHjwadxjxl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1157,
        "2021": 476,
        "2022": 1093,
        "2023": 0,
        "2024": 0,
        "id": "recT5LhcVZAKZRK0E",
        "row": 2540,
        "state": "ARIZONA",
        "group": "086873802300001",
        "group_num": [
            "recTEBMVpZu3Yhuuq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVGmcSYXNeaetKu",
        "row": 2481,
        "state": "ARIZONA",
        "group": "086388301000001",
        "group_num": [
            "recVUPSS5v8WGo6aO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 874,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWERcUylyOXo97v",
        "row": 2547,
        "state": "ARIZONA",
        "group": "086884401100002",
        "group_num": [
            "recg1pYaXStKKeMja"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1329,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWF8XqBO0vz0HK7",
        "row": 2461,
        "state": "ARIZONA",
        "group": "080631401000002",
        "group_num": [
            "recyXDldFyd4Sp9dO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 874,
        "2023": 0,
        "2024": 0,
        "id": "recX4uz3aytCKzAI9",
        "row": 2488,
        "state": "ARIZONA",
        "group": "086530705200001",
        "group_num": [
            "recjcij1LhUCLSnaW"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX44QvW7P8zfrOH",
        "row": 2518,
        "state": "ARIZONA",
        "group": "086840401200001",
        "group_num": [
            "recBi2Z0G3Ni3pZmY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 945,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1lMABtXKHmVYnf",
        "row": 2528,
        "state": "ARIZONA",
        "group": "086869601000021",
        "group_num": [
            "recBnJb1XYZlRWJfO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 3900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4C8Cy213x3zKWI",
        "row": 2541,
        "state": "ARIZONA",
        "group": "086874201300001",
        "group_num": [
            "rec96fcaR9Sr14lEM"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2700,
        "2021": 0,
        "2022": 0,
        "2023": 690,
        "2024": 0,
        "id": "rec5X9NxdPnCuTZwC",
        "row": 2495,
        "state": "ARIZONA",
        "group": "086621903000001",
        "group_num": [
            "recpPnRtMngsn2Aef"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 243,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5GYXgLsC5jwwNv",
        "row": 2471,
        "state": "ARIZONA",
        "group": "083920201100002",
        "group_num": [
            "recUuGJquW2ynBPyO"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 205,
        "2023": 186,
        "2024": 0,
        "id": "rec5fIUgYWhGU5Xoe",
        "row": 2478,
        "state": "ARIZONA",
        "group": "086364601200044",
        "group_num": [
            "recAk5b2Ymc0Y9VCJ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 4527,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6T0M3ujY1tIwO9",
        "row": 2450,
        "state": "ARIZONA",
        "group": "077940904200501",
        "group_num": [
            "recH7diBrpdEIga2K"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 188,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6Kh9eSwiLzyHO0",
        "row": 2510,
        "state": "ARIZONA",
        "group": "086794302400092",
        "group_num": [
            "recDJyTB2BLj0tAHs"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2031,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6mwGRH7krSe791",
        "row": 2464,
        "state": "ARIZONA",
        "group": "080931602300005",
        "group_num": [
            "rec4b58RCiFK8JSw1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 487,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7Vi3L9yPS70QDJ",
        "row": 2463,
        "state": "ARIZONA",
        "group": "080631401000002",
        "group_num": [
            "recyXDldFyd4Sp9dO"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 675,
        "2023": 0,
        "2024": 0,
        "id": "rec73efoFTLcXsfCc",
        "row": 2476,
        "state": "ARIZONA",
        "group": "086364601200044",
        "group_num": [
            "recAk5b2Ymc0Y9VCJ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1194,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8BtIzTAdwqhF3q",
        "row": 2532,
        "state": "ARIZONA",
        "group": "086869702900009",
        "group_num": [
            "recJkD6TTltyCKzNh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 385,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec91fjzhVn19jFCc",
        "row": 2505,
        "state": "ARIZONA",
        "group": "086629801000002",
        "group_num": [
            "recc3SqXO9YoqZpum"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9JTlIuMvfk8PRk",
        "row": 2460,
        "state": "ARIZONA",
        "group": "080045202300029",
        "group_num": [
            "rec8WQTpqP7SwqeBK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1111,
        "2023": 0,
        "2024": 0,
        "id": "rec9a3TJBCtbPznbD",
        "row": 2516,
        "state": "ARIZONA",
        "group": "086828102000004",
        "group_num": [
            "recgtbLhaU6QrPicH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 687,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaIEzrDrAIhqnG6",
        "row": 2514,
        "state": "ARIZONA",
        "group": "086810901700003",
        "group_num": [
            "recTlJwGyEyl2rLBY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 953,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recahTmbaOzBQjxHf",
        "row": 2511,
        "state": "ARIZONA",
        "group": "086798101100006",
        "group_num": [
            "rechOMagKo7sPQLNG"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbfAKfN1S9JkieF",
        "row": 2486,
        "state": "ARIZONA",
        "group": "086525002700040",
        "group_num": [
            "recM9XHdln1Js5u7R"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 0,
        "2024": 0,
        "id": "recdNFXvQFqpevRB4",
        "row": 2534,
        "state": "ARIZONA",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoSg7KDilIV5VNH",
        "row": 2526,
        "state": "ARIZONA",
        "group": "086861301200659",
        "group_num": [
            "recHkPuxwBWWCvUUS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1890,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoVCai4gRxPrl60",
        "row": 2496,
        "state": "ARIZONA",
        "group": "086621903000001",
        "group_num": [
            "recpPnRtMngsn2Aef"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 458,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoHgntBoJhUYZWL",
        "row": 2522,
        "state": "ARIZONA",
        "group": "086859801000003",
        "group_num": [
            "recoYJs8wiDQ5KMUb"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 556,
        "2024": 0,
        "id": "recpc9cvuAwvCKr0A",
        "row": 2501,
        "state": "ARIZONA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "recq5EK5K9Kk1JpNy",
        "row": 2538,
        "state": "ARIZONA",
        "group": "086873802100001",
        "group_num": [
            "recc6kGz9qrYFqEAk"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1127,
        "2023": 0,
        "2024": 0,
        "id": "recq5bywILn2dZUKR",
        "row": 2531,
        "state": "ARIZONA",
        "group": "086869601600006",
        "group_num": [
            "recBp2s5FIqJnXLAK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1483,
        "2021": 1623,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs2vlW7IoZYKgBc",
        "row": 2494,
        "state": "ARIZONA",
        "group": "086621202200248",
        "group_num": [
            "rec1oWA0MxaDFIEQw"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 627,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs3RH6IfhmVWJer",
        "row": 2517,
        "state": "ARIZONA",
        "group": "086833101100001",
        "group_num": [
            "reczpXD991HTmNqDZ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 0,
        "2024": 0,
        "id": "rectXgWVctQIfskK0",
        "row": 2507,
        "state": "ARIZONA",
        "group": "086640405000010",
        "group_num": [
            "recbPKLUY7iHXDH00"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectH4xGjfTiUpQo9",
        "row": 2544,
        "state": "ARIZONA",
        "group": "086880601100122",
        "group_num": [
            "recXNKLDhsW6rISdg"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectOiRLD4bmWixuS",
        "row": 2490,
        "state": "ARIZONA",
        "group": "086538601100001",
        "group_num": [
            "recJ1hakuKtIlgtRx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectmtHtNTbwtuOWf",
        "row": 2470,
        "state": "ARIZONA",
        "group": "083920201100002",
        "group_num": [
            "recUuGJquW2ynBPyO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1245,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuvx7LfnY5liBUV",
        "row": 2469,
        "state": "ARIZONA",
        "group": "083920201100002",
        "group_num": [
            "recUuGJquW2ynBPyO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1000,
        "2021": 1278,
        "2022": 616,
        "2023": 0,
        "2024": 0,
        "id": "recvX0fw1kOG2lrPQ",
        "row": 2533,
        "state": "ARIZONA",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvxHISYc8qPaY54",
        "row": 2529,
        "state": "ARIZONA",
        "group": "086869601000021",
        "group_num": [
            "recBnJb1XYZlRWJfO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvlEqCimMzNVvrj",
        "row": 2525,
        "state": "ARIZONA",
        "group": "086860001100030",
        "group_num": [
            "recHULdth0YywcJah"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 911,
        "2023": 966,
        "2024": 0,
        "id": "recvrbS3lbewZsoRs",
        "row": 2535,
        "state": "ARIZONA",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 892,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwULYJcX7Ggh1KA",
        "row": 2542,
        "state": "ARIZONA",
        "group": "086874201300001",
        "group_num": [
            "rec96fcaR9Sr14lEM"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2031,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxu8AtSmbcql3wI",
        "row": 2519,
        "state": "ARIZONA",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1082,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyjlDlv5wz8kZRm",
        "row": 2493,
        "state": "ARIZONA",
        "group": "086621202200248",
        "group_num": [
            "rec1oWA0MxaDFIEQw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 696,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyn8x2R4WFYmUx3",
        "row": 2448,
        "state": "ARIZONA",
        "group": "077940904000501",
        "group_num": [
            "recIZxq6TFlJGAvgD"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1916,
        "2023": 2178,
        "2024": 0,
        "id": "recziti84Et5Omjcy",
        "row": 2491,
        "state": "ARIZONA",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1094,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAyQvO7aajTbFfh",
        "row": 2527,
        "state": "ARIZONA",
        "group": "086865602400118",
        "group_num": [
            "rec58eyUYFlqraED8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 613,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAjFOnb4sTPh77I",
        "row": 2474,
        "state": "ARIZONA",
        "group": "084701201600001",
        "group_num": [
            "rec4YgqMq9SyNzDhd"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 564,
        "2021": 215,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAnN3Xan7x8pGgW",
        "row": 2543,
        "state": "ARIZONA",
        "group": "086874201300001",
        "group_num": [
            "rec96fcaR9Sr14lEM"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBqDv8PLyOAXdrP",
        "row": 2530,
        "state": "ARIZONA",
        "group": "086869601000021",
        "group_num": [
            "recBnJb1XYZlRWJfO"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1451,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBr4dqqoQh1jcgF",
        "row": 2513,
        "state": "ARIZONA",
        "group": "086810901700003",
        "group_num": [
            "recTlJwGyEyl2rLBY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1529,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDhPZP9L2mWItOb",
        "row": 2515,
        "state": "ARIZONA",
        "group": "086826702400118",
        "group_num": [
            "recjENuahw4g58qpU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 585,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEYKKKLngo4twzX",
        "row": 2479,
        "state": "ARIZONA",
        "group": "086379902000070",
        "group_num": [
            "recFWjVEidP55SYPS"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 712,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFeJjGPamGAXcRN",
        "row": 2485,
        "state": "ARIZONA",
        "group": "086388601000001",
        "group_num": [
            "recCIuFT66zGyeS3t"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1663,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGSyJzjbgwO9Sh4",
        "row": 2451,
        "state": "ARIZONA",
        "group": "077940904200501",
        "group_num": [
            "recH7diBrpdEIga2K"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 275,
        "2022": 0,
        "2023": 695,
        "2024": 0,
        "id": "recGZsrCPNymzRpbs",
        "row": 2500,
        "state": "ARIZONA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 413,
        "2021": 404,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGkqew7XJnvarN8",
        "row": 2509,
        "state": "ARIZONA",
        "group": "086794302400092",
        "group_num": [
            "recDJyTB2BLj0tAHs"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 645,
        "2021": 1387,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH1a2agTJvpnCow",
        "row": 2508,
        "state": "ARIZONA",
        "group": "086794302400092",
        "group_num": [
            "recDJyTB2BLj0tAHs"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH9LGeDfh3ToNs1",
        "row": 2456,
        "state": "ARIZONA",
        "group": "080010003000001",
        "group_num": [
            "recWkFowM0DXmUmgQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3900,
        "2022": 3900,
        "2023": 0,
        "2024": 0,
        "id": "recJbUqu4rufgsilo",
        "row": 2480,
        "state": "ARIZONA",
        "group": "086387501000001",
        "group_num": [
            "reckIR6JMWin7XYQ1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1577,
        "2024": 0,
        "id": "recKKzU6QLNLKziY2",
        "row": 2492,
        "state": "ARIZONA",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKpPIUQhn1MVkkH",
        "row": 2455,
        "state": "ARIZONA",
        "group": "077940905700601",
        "group_num": [
            "recFNoaW8RU3fxRb0"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLf6IYAtJuXWCIZ",
        "row": 2468,
        "state": "ARIZONA",
        "group": "083722201300002",
        "group_num": [
            "recO3ilQAs5auX3dh"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLjYgKtsoBpYKgf",
        "row": 2482,
        "state": "ARIZONA",
        "group": "086388501000001",
        "group_num": [
            "recjlcM8zTUdIw5xp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2083,
        "2024": 0,
        "id": "recLnO3noPMkRylKY",
        "row": 2546,
        "state": "ARIZONA",
        "group": "086880802400011",
        "group_num": [
            "rectftZwL7DzQkWjF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1552,
        "2023": 0,
        "2024": 0,
        "id": "recLro1keuYCTqr5m",
        "row": 2458,
        "state": "ARIZONA",
        "group": "080010003000001",
        "group_num": [
            "recWkFowM0DXmUmgQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 3362,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMvcn0osznUPZmX",
        "row": 2484,
        "state": "ARIZONA",
        "group": "086388501000001",
        "group_num": [
            "recjlcM8zTUdIw5xp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 3900,
        "2021": 3900,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNLssABfbhzDDJJ",
        "row": 2454,
        "state": "ARIZONA",
        "group": "077940904800511",
        "group_num": [
            "recxMZ74COHpzgNFN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 757,
        "2023": 0,
        "2024": 0,
        "id": "recNqLV2i6cYPG7th",
        "row": 2457,
        "state": "ARIZONA",
        "group": "080010003000001",
        "group_num": [
            "recWkFowM0DXmUmgQ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 979,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfxxfgKbGYpX7H1",
        "row": 2550,
        "state": "ARIZONA",
        "group": "086886701100001",
        "group_num": [
            "rec9wDst3yfyD6d9i"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 892,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfiVJmFfrVIzU3s",
        "row": 2548,
        "state": "ARIZONA",
        "group": "086884401100002",
        "group_num": [
            "recg1pYaXStKKeMja"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 912,
        "2023": 0,
        "2024": 0,
        "id": "recjm1kxdOK3VN7kU",
        "row": 2567,
        "state": "ARIZONA",
        "group": "THEVANGUARDGROUP",
        "group_num": [
            "recR9O1iKXopeUAb2",
            "recicyKlqVdBzfbbl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 687,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV3RxjgcMqbd0h3",
        "row": 2564,
        "state": "ARIZONA",
        "group": "089553001000304",
        "group_num": [
            "recGVkkV2vY89Hyap"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 174,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4BPIobKSJHLFFd",
        "row": 2549,
        "state": "ARIZONA",
        "group": "086884401100002",
        "group_num": [
            "recg1pYaXStKKeMja"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 2700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7YZb8CrB3BgnNL",
        "row": 2552,
        "state": "ARIZONA",
        "group": "087010505000101",
        "group_num": [
            "recKQ6T5HIm4LevKt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 476,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoMwMAeOJeA5Cuz",
        "row": 2557,
        "state": "ARIZONA",
        "group": "087715702000002",
        "group_num": [
            "rec7fFl1wVtKAFpiZ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 1933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco5X9rg7jVjBWPf",
        "row": 2556,
        "state": "ARIZONA",
        "group": "087715701000001",
        "group_num": [
            "rec3lhzr7xQwn0FBU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1381,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrm0MA7c7rnVN4z",
        "row": 2563,
        "state": "ARIZONA",
        "group": "089553001000304",
        "group_num": [
            "recGVkkV2vY89Hyap"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsFOAo1c5H07c5S",
        "row": 2561,
        "state": "ARIZONA",
        "group": "089553001000204",
        "group_num": [
            "rec0uqivpIAGGJAVL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1954,
        "2023": 0,
        "2024": 0,
        "id": "rectXnqab728Mq5cI",
        "row": 2558,
        "state": "ARIZONA",
        "group": "088553601700005",
        "group_num": [
            "recNRTcwb3WfXItBw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 193,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx9SNddHg3odUpm",
        "row": 2566,
        "state": "ARIZONA",
        "group": "CARVANA",
        "group_num": [
            "recJoJgQVCfdsYE6Z"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 4800,
        "2021": 1407,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyGumaDwycSlizR",
        "row": 2559,
        "state": "ARIZONA",
        "group": "088595001000003",
        "group_num": [
            "recUYYwFCLjHTv7HB"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1381,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczFbAaTwvUb6wkq",
        "row": 2551,
        "state": "ARIZONA",
        "group": "086985103300018",
        "group_num": [
            "recGWmTDofRBd5H7c"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1530,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczMTL00HNcmfx56",
        "row": 2555,
        "state": "ARIZONA",
        "group": "087709304500210",
        "group_num": [
            "recp0ozj6djU99Oio"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 418,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBEzWED5EzC7lJg",
        "row": 2565,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1933,
        "2022": 0,
        "2023": 2190,
        "2024": 0,
        "id": "recNscpicFrJiZybk",
        "row": 2554,
        "state": "ARIZONA",
        "group": "087709304500210",
        "group_num": [
            "recp0ozj6djU99Oio"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 665,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNpXl7ly7UFoWQj",
        "row": 2560,
        "state": "ARIZONA",
        "group": "089553001000204",
        "group_num": [
            "rec0uqivpIAGGJAVL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 614,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHcOyXDBHZ0knKx",
        "row": 2568,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "AHCCCS"
    },
    {
        "2020": 1188,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVVB3lZZ12MoKLA",
        "row": 2570,
        "state": "ARIZONA",
        "group": "A15205",
        "group_num": [
            "recDdgl2YtkGhGOO8"
        ],
        "procedure": "RTC",
        "provider": "ALICARE"
    },
    {
        "2020": 1874,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHidXeq2V1eyr4Y",
        "row": 2569,
        "state": "ARIZONA",
        "group": "A15205",
        "group_num": [
            "recDdgl2YtkGhGOO8"
        ],
        "procedure": "Detox",
        "provider": "ALICARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receVe4Yu4aW6vkW4",
        "row": 2571,
        "state": "ARIZONA",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 436,
        "2021": 800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receQ6bPsyKzJPR81",
        "row": 2578,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 918,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "reckmDu1Z0kNNKYj8",
        "row": 2575,
        "state": "ARIZONA",
        "group": "5400024585",
        "group_num": [
            "recDl1GIgWOn24n2f"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recRWgKZeCIcJ7kei",
        "row": 2572,
        "state": "ARIZONA",
        "group": "5400019412",
        "group_num": [
            "recyjHpT2rHk5WYaJ"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRuQezarbdYuVKv",
        "row": 2579,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "rec5KoI67jxpM1cgf",
        "row": 2576,
        "state": "ARIZONA",
        "group": "5400030123",
        "group_num": [
            "recAwCz0T581AhZa7"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 2375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectWYbYB7ps08y2g",
        "row": 2574,
        "state": "ARIZONA",
        "group": "5400022833",
        "group_num": [
            "rec8LRv9ZE5SNtUo6"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu6vpdF0j2SuYFk",
        "row": 2573,
        "state": "ARIZONA",
        "group": "5400022833",
        "group_num": [
            "rec8LRv9ZE5SNtUo6"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFTwNgbDbgLBgAs",
        "row": 2577,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 417,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSH1eBvsi2zQdCq",
        "row": 2580,
        "state": "ARIZONA",
        "group": "A15205",
        "group_num": [
            "recDdgl2YtkGhGOO8"
        ],
        "procedure": "PHP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 593,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "rece4relhizCqtW4Y",
        "row": 2582,
        "state": "ARIZONA",
        "group": "008150001",
        "group_num": [
            "recvTEu2rDjKm49l7"
        ],
        "procedure": "OP",
        "provider": "AMBETTER"
    },
    {
        "2020": 0,
        "2021": 114,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj6Q9jOtPwUKIyC",
        "row": 2587,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "PHP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 433,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmFPoZGuPnkq9q7",
        "row": 2583,
        "state": "ARIZONA",
        "group": "0103003",
        "group_num": [
            "recGnGTTicodaFdrN"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 1540,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmb0tUORFCcRyQJ",
        "row": 2589,
        "state": "ARIZONA",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "PHP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 1260,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmh9XwqwzbItbKB",
        "row": 2586,
        "state": "ARIZONA",
        "group": "1007019",
        "group_num": [
            "recVG4MiTVpahgVVn"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1329,
        "2023": 0,
        "2024": 0,
        "id": "recPKXaRJLIOKamPy",
        "row": 2585,
        "state": "ARIZONA",
        "group": "0712010",
        "group_num": [
            "recooTo1hPrMi9yXY"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 1063,
        "2022": 1831,
        "2023": 0,
        "2024": 0,
        "id": "rec8NHnsO6fqKHnl3",
        "row": 2590,
        "state": "ARIZONA",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "IOP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 2135,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu2qD4pnBblbRLr",
        "row": 2592,
        "state": "ARIZONA",
        "group": "YRC0011",
        "group_num": [
            "recZADFHHs1yzoDjy"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3429,
        "2023": 0,
        "2024": 0,
        "id": "recv1kZI03L673Jj9",
        "row": 2584,
        "state": "ARIZONA",
        "group": "0113004",
        "group_num": [
            "rec1R3txB7v9JQtsb"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 1979,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnhBbBZsQS9cfLk",
        "row": 2593,
        "state": "ARIZONA",
        "group": "ACNCC016",
        "group_num": [
            "recrrNiHq1Ouej9GM"
        ],
        "procedure": "PHP",
        "provider": "ARIZONA CARE NETWORK"
    },
    {
        "2020": 188,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclurG4o6VjFesNO",
        "row": 2595,
        "state": "ARIZONA",
        "group": "BRGI765",
        "group_num": [
            "recJn70hItkrfYkG2"
        ],
        "procedure": "IOP",
        "provider": "ARIZONA FOUNDATION FOR MEDICAL CARE"
    },
    {
        "2020": 395,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnx6R9lyk82eteb",
        "row": 2594,
        "state": "ARIZONA",
        "group": "BRGI765",
        "group_num": [
            "recJn70hItkrfYkG2"
        ],
        "procedure": "PHP",
        "provider": "ARIZONA FOUNDATION FOR MEDICAL CARE"
    },
    {
        "2020": 907,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsjGo7hPfbZzBoI",
        "row": 2597,
        "state": "ARIZONA",
        "group": "ALLEN",
        "group_num": [
            "recm592pKnBPV2sZd"
        ],
        "procedure": "PHP",
        "provider": "AUTOMATED BENEFIT SERVICES"
    },
    {
        "2020": 326,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQkE6qYgtoqFAPb",
        "row": 2598,
        "state": "ARIZONA",
        "group": "ALLEN",
        "group_num": [
            "recm592pKnBPV2sZd"
        ],
        "procedure": "IOP",
        "provider": "AUTOMATED BENEFIT SERVICES"
    },
    {
        "2020": 87,
        "2021": 309,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3uHu0kH6sq41TY",
        "row": 2599,
        "state": "ARIZONA",
        "group": "ALLEN",
        "group_num": [
            "recm592pKnBPV2sZd"
        ],
        "procedure": "OP",
        "provider": "AUTOMATED BENEFIT SERVICES"
    },
    {
        "2020": 2833,
        "2021": 2486,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recekYFLAPwPTM9pq",
        "row": 5345,
        "state": "ARIZONA",
        "group": "7UW842",
        "group_num": [
            "recAntySNGQusNUQ1"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 158,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfzW59imQofiM0B",
        "row": 5367,
        "state": "ARIZONA",
        "group": "002349525",
        "group_num": [
            "recxIoAarRtvGmtBU"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1335,
        "2021": 1359,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechb7tgLoOrFQOti",
        "row": 5282,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjEYoAnIsYFMe2G",
        "row": 5294,
        "state": "ARIZONA",
        "group": "YAN001",
        "group_num": [
            "recqRV16ojT6POhmk"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 751,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recksaZxTcAIqVRr7",
        "row": 5275,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 5703,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recktBXYUz6Ulx5kV",
        "row": 5363,
        "state": "ARIZONA",
        "group": "002347823",
        "group_num": [
            "rec2TxsOY7tzGiScK"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 674,
        "2023": 0,
        "2024": 0,
        "id": "reckb4dNy8XBZe898",
        "row": 5342,
        "state": "ARIZONA",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 750,
        "2022": 750,
        "2023": 0,
        "2024": 0,
        "id": "reclSWia9IwVdNpxm",
        "row": 5292,
        "state": "ARIZONA",
        "group": "YAN001",
        "group_num": [
            "recqRV16ojT6POhmk"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclTkX7E7dy3E7K4",
        "row": 5293,
        "state": "ARIZONA",
        "group": "YAN001",
        "group_num": [
            "recqRV16ojT6POhmk"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 192,
        "2021": 1245,
        "2022": 2510,
        "2023": 0,
        "2024": 0,
        "id": "recmxRRsNiTLHEqT7",
        "row": 5319,
        "state": "ARIZONA",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2013,
        "2023": 0,
        "2024": 0,
        "id": "recnIvZEhH7IP6wAc",
        "row": 5340,
        "state": "ARIZONA",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 3007,
        "2021": 0,
        "2022": 3037,
        "2023": 0,
        "2024": 0,
        "id": "recP0lunSVFc9FpGo",
        "row": 5298,
        "state": "ARIZONA",
        "group": "EMH222",
        "group_num": [
            "recRZ4rx0GeH1I6ZX"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1795,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPfMO2QVR8NlEUG",
        "row": 5336,
        "state": "ARIZONA",
        "group": "003320016DEHG00",
        "group_num": [
            "rec1U77DZz0bYSgHE"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 5867,
        "2021": 0,
        "2022": 893,
        "2023": 0,
        "2024": 0,
        "id": "recPnqW6Ms7wgCHCq",
        "row": 5325,
        "state": "ARIZONA",
        "group": "239465",
        "group_num": [
            "recjKgSeOe2vla8zW"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "2023": 0,
        "2024": 0,
        "id": "recQVBHZpE7I25fM2",
        "row": 5278,
        "state": "ARIZONA",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1854,
        "2023": 0,
        "2024": 0,
        "id": "recQZA1i7QanDOvpz",
        "row": 5313,
        "state": "ARIZONA",
        "group": "275620",
        "group_num": [
            "reclml0al9jrmPbFN"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 266,
        "2021": 253,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRT478mUcANi5SI",
        "row": 5358,
        "state": "ARIZONA",
        "group": "002317868",
        "group_num": [
            "recNzN22IKEmd4z7j"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRv6GWVdOcjlTcg",
        "row": 5337,
        "state": "ARIZONA",
        "group": "776359",
        "group_num": [
            "rec1BZugtLazrDSyZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1335,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS8O3Vg4eXPz2HI",
        "row": 5283,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUAk1QXQzEqayuY",
        "row": 5351,
        "state": "ARIZONA",
        "group": "7NUW60",
        "group_num": [
            "recdUUd307ivPu3SM"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1865,
        "2023": 0,
        "2024": 0,
        "id": "recUMbcIUCux2P0OX",
        "row": 5330,
        "state": "ARIZONA",
        "group": "214012M3A1",
        "group_num": [
            "reck8RQYmCA9LbBnm"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1443,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWLwpLIMSyDun65",
        "row": 5338,
        "state": "ARIZONA",
        "group": "776359",
        "group_num": [
            "rec1BZugtLazrDSyZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 637,
        "2022": 585,
        "2023": 0,
        "2024": 0,
        "id": "recWOMpkblwtNzZzN",
        "row": 5300,
        "state": "ARIZONA",
        "group": "FMC001",
        "group_num": [
            "recDqK3CyZEre6Tkr"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 2119,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXYZ7u7Kp1pl1OU",
        "row": 5366,
        "state": "ARIZONA",
        "group": "002349525",
        "group_num": [
            "recxIoAarRtvGmtBU"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1710,
        "2024": 0,
        "id": "recYuSdMEvOHvn472",
        "row": 5349,
        "state": "ARIZONA",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1743,
        "2022": 1814,
        "2023": 2200,
        "2024": 0,
        "id": "recYyFeDze42eKu2I",
        "row": 5299,
        "state": "ARIZONA",
        "group": "FMC001",
        "group_num": [
            "recDqK3CyZEre6Tkr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1050,
        "2021": 1260,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY6WBcj1zMBoYju",
        "row": 5289,
        "state": "ARIZONA",
        "group": "AKC001",
        "group_num": [
            "recCAGGlHcU29jMEY"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1112,
        "2021": 1166,
        "2022": 2604,
        "2023": 0,
        "2024": 0,
        "id": "rec0jtlNUUk7BrCAR",
        "row": 5320,
        "state": "ARIZONA",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "rec0lVAwESZv5hTfO",
        "row": 5362,
        "state": "ARIZONA",
        "group": "P12709",
        "group_num": [
            "rec7CKMH8ORwFJkUW"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1360,
        "2021": 1219,
        "2022": 1435,
        "2023": 0,
        "2024": 0,
        "id": "rec1hjAYXmmLc4gal",
        "row": 5318,
        "state": "ARIZONA",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 960,
        "2022": 960,
        "2023": 0,
        "2024": 0,
        "id": "rec2vDH2qfgJHWqBr",
        "row": 5352,
        "state": "ARIZONA",
        "group": "7NUW60",
        "group_num": [
            "recdUUd307ivPu3SM"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3QZSkLl2IwuuXL",
        "row": 5303,
        "state": "ARIZONA",
        "group": "NAC001",
        "group_num": [
            "rech3xjManfFybMnY"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 4731,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3hWUrMSPUP5j7Y",
        "row": 5344,
        "state": "ARIZONA",
        "group": "7UW842",
        "group_num": [
            "recAntySNGQusNUQ1"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 71,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3mJf7MQNGNMHfP",
        "row": 5285,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 211,
        "2021": 627,
        "2022": 2523,
        "2023": 0,
        "2024": 0,
        "id": "rec6gKNtxW2xI4iOS",
        "row": 5321,
        "state": "ARIZONA",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec926a1cedt7Vmi1",
        "row": 5306,
        "state": "ARIZONA",
        "group": "GIC001",
        "group_num": [
            "recsbNGYllUw5NNkX"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recazEnwXeJOsWPlv",
        "row": 5322,
        "state": "ARIZONA",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1795,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recazSGu49T7k1gdY",
        "row": 5326,
        "state": "ARIZONA",
        "group": "003329993AAAB00",
        "group_num": [
            "recf5LDSIgAAIRSaa"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca9IndLsLQWcCzX",
        "row": 5335,
        "state": "ARIZONA",
        "group": "003320016DEHG00",
        "group_num": [
            "rec1U77DZz0bYSgHE"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 875,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recadm7q4uIQ62YlX",
        "row": 5316,
        "state": "ARIZONA",
        "group": "120369",
        "group_num": [
            "recHjSCDVXJKsNew8"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 3750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdHTrtnbc72ULYR",
        "row": 5350,
        "state": "ARIZONA",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1764,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdhZzyxPufbgD6M",
        "row": 5286,
        "state": "ARIZONA",
        "group": "AKC001",
        "group_num": [
            "recCAGGlHcU29jMEY"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 2408,
        "2021": 2685,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoVBiuUEOARXQ86",
        "row": 5288,
        "state": "ARIZONA",
        "group": "AKC001",
        "group_num": [
            "recCAGGlHcU29jMEY"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 329,
        "2021": 300,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recotW8trSTutwtx3",
        "row": 5332,
        "state": "ARIZONA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recpPzHKekKt8mVhx",
        "row": 5347,
        "state": "ARIZONA",
        "group": "7NUS00",
        "group_num": [
            "recw79VUR257lLbuw"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 230,
        "2024": 0,
        "id": "recqIBP6mY6d1it06",
        "row": 5339,
        "state": "ARIZONA",
        "group": "800426M002",
        "group_num": [
            "recqd9Ojbf1DvAdef"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1053,
        "2023": 0,
        "2024": 0,
        "id": "recq6iiMZ6OgcBlWm",
        "row": 5341,
        "state": "ARIZONA",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1399,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrSb7hQv0W0RpZn",
        "row": 5361,
        "state": "ARIZONA",
        "group": "00762010010",
        "group_num": [
            "recQ8rch5pmjJfeS7"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 167,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsSCqDa9nbaWgse",
        "row": 5296,
        "state": "ARIZONA",
        "group": "SCH001",
        "group_num": [
            "rec2HdF6ZhemWBkxI"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 170,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs0e5xXabIWjuQb",
        "row": 5357,
        "state": "ARIZONA",
        "group": "002317868",
        "group_num": [
            "recNzN22IKEmd4z7j"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 161,
        "2022": 675,
        "2023": 0,
        "2024": 0,
        "id": "rectjcNyhyxpk2St9",
        "row": 5353,
        "state": "ARIZONA",
        "group": "7NUW60",
        "group_num": [
            "recdUUd307ivPu3SM"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu4BSkmajOqkKaX",
        "row": 5297,
        "state": "ARIZONA",
        "group": "SCH001",
        "group_num": [
            "rec2HdF6ZhemWBkxI"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recvUwBCodhR1CaBT",
        "row": 5355,
        "state": "ARIZONA",
        "group": "L01221M002",
        "group_num": [
            "rec6wWVAepIR9c1OM"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 284,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvILGWMlEiJO6Gx",
        "row": 5309,
        "state": "ARIZONA",
        "group": "NTH001",
        "group_num": [
            "recGG3cu37mbN0FPH"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 275,
        "2021": 71,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwuvEzYmBSqVUKC",
        "row": 5276,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwPCEOEvcFZa8nS",
        "row": 5272,
        "state": "ARIZONA",
        "group": "WHC001",
        "group_num": [
            "rec0dDE3L5fttfXqT"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 3735,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxuphvgAJ86Jx2f",
        "row": 5368,
        "state": "ARIZONA",
        "group": "669105",
        "group_num": [
            "recMaDprPAQlI0ilF"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxaudP4ZarZJ2us",
        "row": 5279,
        "state": "ARIZONA",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1272,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxoPqHCIMfeeAof",
        "row": 5324,
        "state": "ARIZONA",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1032,
        "2021": 1032,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recysgcSirXDQq3tn",
        "row": 5284,
        "state": "ARIZONA",
        "group": "AMT001",
        "group_num": [
            "rect7tXjg9vBqFS0x"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 830,
        "2022": 1843,
        "2023": 0,
        "2024": 0,
        "id": "recynX71s3vu8Kcc5",
        "row": 5356,
        "state": "ARIZONA",
        "group": "271454",
        "group_num": [
            "recBAxrzjJ9w9C5TJ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 2750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recypeBSVfAAUKqCj",
        "row": 5305,
        "state": "ARIZONA",
        "group": "GIC001",
        "group_num": [
            "recsbNGYllUw5NNkX"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 773,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz8l68c68oPpYLk",
        "row": 5317,
        "state": "ARIZONA",
        "group": "120369",
        "group_num": [
            "recHjSCDVXJKsNew8"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczoPJRPP4D6BJtz",
        "row": 5328,
        "state": "ARIZONA",
        "group": "057678",
        "group_num": [
            "recINvuvGESJ02Yi3"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 422,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAC1pmhybSWawFe",
        "row": 5311,
        "state": "ARIZONA",
        "group": "275619",
        "group_num": [
            "recJYDvg09gvsxkd2"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 34,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recADUPh0IDUC3DAg",
        "row": 5333,
        "state": "ARIZONA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 514,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAf1MZhiPswCJtD",
        "row": 5280,
        "state": "ARIZONA",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 560,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDX0c3GCef2lRcy",
        "row": 5308,
        "state": "ARIZONA",
        "group": "NTH001",
        "group_num": [
            "recGG3cu37mbN0FPH"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 2323,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDLa8WqExzDR1rX",
        "row": 5354,
        "state": "ARIZONA",
        "group": "652345",
        "group_num": [
            "rec5Hd8rtkAvxjJtB"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 159,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEA3sKfSoZXIbOs",
        "row": 5359,
        "state": "ARIZONA",
        "group": "002317868",
        "group_num": [
            "recNzN22IKEmd4z7j"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 170,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFxGozQVZauT5Gn",
        "row": 5364,
        "state": "ARIZONA",
        "group": "002347823",
        "group_num": [
            "rec2TxsOY7tzGiScK"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1425,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recF3MQvEQdKFLtWf",
        "row": 5287,
        "state": "ARIZONA",
        "group": "AKC001",
        "group_num": [
            "recCAGGlHcU29jMEY"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1414,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFrOmRlNQhJf7ie",
        "row": 5314,
        "state": "ARIZONA",
        "group": "120369",
        "group_num": [
            "recHjSCDVXJKsNew8"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGwA7YDpuqK0UqL",
        "row": 5369,
        "state": "ARIZONA",
        "group": "90750",
        "group_num": [
            "rec5tpdpzYT5rnBGf"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGqgwl96TTKKgBv",
        "row": 5370,
        "state": "ARIZONA",
        "group": "90750",
        "group_num": [
            "rec5tpdpzYT5rnBGf"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 6000,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJJRZuf9tmgOcg5",
        "row": 5343,
        "state": "ARIZONA",
        "group": "7UW842",
        "group_num": [
            "recAntySNGQusNUQ1"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 0,
        "2024": 0,
        "id": "recJedws8V5Mm3deZ",
        "row": 5331,
        "state": "ARIZONA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 569,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJfTH66Icqb6vPr",
        "row": 5273,
        "state": "ARIZONA",
        "group": "WHC001",
        "group_num": [
            "rec0dDE3L5fttfXqT"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 0,
        "2024": 0,
        "id": "recKBw1IqEze4Mywo",
        "row": 5295,
        "state": "ARIZONA",
        "group": "RBT001",
        "group_num": [
            "recl2HgtraICNqm7Z"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 761,
        "2022": 550,
        "2023": 0,
        "2024": 0,
        "id": "recK5JenUtramVfPf",
        "row": 5301,
        "state": "ARIZONA",
        "group": "FMC001",
        "group_num": [
            "recDqK3CyZEre6Tkr"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 519,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLFqSiYscX8UjQw",
        "row": 5274,
        "state": "ARIZONA",
        "group": "WHC001",
        "group_num": [
            "rec0dDE3L5fttfXqT"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1540,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLJNLTu18u3VAAR",
        "row": 5327,
        "state": "ARIZONA",
        "group": "057678",
        "group_num": [
            "recINvuvGESJ02Yi3"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 2347,
        "2023": 3600,
        "2024": 0,
        "id": "recM4OKlaXUZYPnl7",
        "row": 5348,
        "state": "ARIZONA",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1106,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNv9qfYM3SuHI4X",
        "row": 5315,
        "state": "ARIZONA",
        "group": "120369",
        "group_num": [
            "recHjSCDVXJKsNew8"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNckUrL1NdKHUDQ",
        "row": 5371,
        "state": "ARIZONA",
        "group": "90750",
        "group_num": [
            "rec5tpdpzYT5rnBGf"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 2523,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece9jZiDYRtCyyFL",
        "row": 5381,
        "state": "ARIZONA",
        "group": "189421",
        "group_num": [
            "recj77sd6f8qHn6Jh"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 868,
        "2023": 360,
        "2024": 0,
        "id": "recg10LCrqdRVhfDd",
        "row": 5383,
        "state": "ARIZONA",
        "group": "014407",
        "group_num": [
            "recJPvxWEbNjQDTJv"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgBUiys3iaAs54a",
        "row": 5401,
        "state": "ARIZONA",
        "group": "9515SESTESWA",
        "group_num": [
            "recBSZEu4w6id8OUL"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 247,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjumh2QSEnIOGba",
        "row": 5400,
        "state": "ARIZONA",
        "group": "01AP003",
        "group_num": [
            "rec3A5NMaiuhh0THU"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 144,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmsHJwBqBUYFqOK",
        "row": 5378,
        "state": "ARIZONA",
        "group": "4000002",
        "group_num": [
            "recAzEGEopzFEIAYC"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 285,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP344SPuz4WG6S7",
        "row": 5377,
        "state": "ARIZONA",
        "group": "4000002",
        "group_num": [
            "recAzEGEopzFEIAYC"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 2734,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP6dlw8rvyHiiZM",
        "row": 5420,
        "state": "ARIZONA",
        "group": "720902MU1A",
        "group_num": [
            "recpS0SNqLqgdAZY8"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 143,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ31eimwE8ASPqW",
        "row": 5373,
        "state": "ARIZONA",
        "group": "CB030X",
        "group_num": [
            "recBktXlHH1TG8jME"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSkhkHzbcJ8X48D",
        "row": 5410,
        "state": "ARIZONA",
        "group": "276785M010",
        "group_num": [
            "recPZCdUDlo0VhTNf"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1962,
        "2023": 0,
        "2024": 0,
        "id": "recT3y9EEWbJnv0SS",
        "row": 5403,
        "state": "ARIZONA",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTggZBu2MzOjvbH",
        "row": 5411,
        "state": "ARIZONA",
        "group": "000EGN936000100",
        "group_num": [
            "reclHz3Xa79KWIB0t"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1869,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWBV371Q8p6uV4G",
        "row": 5392,
        "state": "ARIZONA",
        "group": "10195321",
        "group_num": [
            "recqeLth2GlAQXoir"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 993,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXPxOh1Lv4RnriO",
        "row": 5394,
        "state": "ARIZONA",
        "group": "153509",
        "group_num": [
            "recGuVLudg1zrF0HG"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1961,
        "2023": 0,
        "2024": 0,
        "id": "recZzS8gy36pvJBtV",
        "row": 5391,
        "state": "ARIZONA",
        "group": "212010M5A3",
        "group_num": [
            "recULCUHVMRUyBXB7"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1882,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2QXjLTYt2M2PR1",
        "row": 5386,
        "state": "ARIZONA",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 312,
        "2023": 0,
        "2024": 0,
        "id": "rec2lzROzfbkGoLis",
        "row": 5404,
        "state": "ARIZONA",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3Pc7Id9xL6sKfi",
        "row": 5376,
        "state": "ARIZONA",
        "group": "85000008",
        "group_num": [
            "recZnRXwWMfjIEh67"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 274,
        "2022": 360,
        "2023": 0,
        "2024": 0,
        "id": "rec5uDJuolkjjxx3P",
        "row": 5415,
        "state": "ARIZONA",
        "group": "000EMB834AACP00",
        "group_num": [
            "recEqTienx96lZhEs"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1000,
        "2024": 0,
        "id": "recaz66AWrNtqCWj0",
        "row": 5416,
        "state": "ARIZONA",
        "group": "000EMB834AACP00",
        "group_num": [
            "recEqTienx96lZhEs"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 203,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaa8sErw1Iu0g04",
        "row": 5407,
        "state": "ARIZONA",
        "group": "276785M008",
        "group_num": [
            "recCdsPat8m8QIaVW"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccu9tK2iD7TQzZL",
        "row": 5409,
        "state": "ARIZONA",
        "group": "276785M010",
        "group_num": [
            "recPZCdUDlo0VhTNf"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "reccau1qLFu8PngVp",
        "row": 5419,
        "state": "ARIZONA",
        "group": "0BPM03",
        "group_num": [
            "reci0IUUK6S0qdJxD"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1073,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recciKRy6kBS1WGIK",
        "row": 5395,
        "state": "ARIZONA",
        "group": "153509",
        "group_num": [
            "recGuVLudg1zrF0HG"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "2023": 0,
        "2024": 0,
        "id": "recdTtPFRUpRtZntR",
        "row": 5389,
        "state": "ARIZONA",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "2023": 0,
        "2024": 0,
        "id": "reco3xO0eH6SAUjhf",
        "row": 5418,
        "state": "ARIZONA",
        "group": "0BPM03",
        "group_num": [
            "reci0IUUK6S0qdJxD"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 453,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqEErOeG7fgBynH",
        "row": 5412,
        "state": "ARIZONA",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1398,
        "2023": 0,
        "2024": 0,
        "id": "recv0KyP3qov69Bh6",
        "row": 5405,
        "state": "ARIZONA",
        "group": "174287M004",
        "group_num": [
            "rec8Sw5whI4nPhhou"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 127,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwtD7YgXZrtbfm7",
        "row": 5421,
        "state": "ARIZONA",
        "group": "720902MU1A",
        "group_num": [
            "recpS0SNqLqgdAZY8"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 0,
        "2024": 0,
        "id": "recxgRjvqJMYP5gHp",
        "row": 5397,
        "state": "ARIZONA",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 80,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyAja48eFXzXYOk",
        "row": 5379,
        "state": "ARIZONA",
        "group": "4000002",
        "group_num": [
            "recAzEGEopzFEIAYC"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recynWmotb4CLaVb4",
        "row": 5406,
        "state": "ARIZONA",
        "group": "276785M008",
        "group_num": [
            "recCdsPat8m8QIaVW"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recABGnCUAoQshAAO",
        "row": 5372,
        "state": "ARIZONA",
        "group": "CB030X",
        "group_num": [
            "recBktXlHH1TG8jME"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1762,
        "2023": 0,
        "2024": 0,
        "id": "recB2CDEfqoCL2TBN",
        "row": 5402,
        "state": "ARIZONA",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 310,
        "2023": 0,
        "2024": 0,
        "id": "recB8DDwTki2H1dYy",
        "row": 5387,
        "state": "ARIZONA",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1208,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCjmlvROkAZqjbO",
        "row": 5393,
        "state": "ARIZONA",
        "group": "053222",
        "group_num": [
            "rectW7FKo8EUPV3Pu"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1869,
        "2023": 0,
        "2024": 0,
        "id": "recEAoIItitbEWKeA",
        "row": 5390,
        "state": "ARIZONA",
        "group": "212010M5A3",
        "group_num": [
            "recULCUHVMRUyBXB7"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2409,
        "2023": 0,
        "2024": 0,
        "id": "recEkh7qGL9iXcL0e",
        "row": 5396,
        "state": "ARIZONA",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1735,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEmD21AreOMHGZk",
        "row": 5385,
        "state": "ARIZONA",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 3750,
        "2024": 0,
        "id": "recFj0laHmVv8T7V5",
        "row": 5384,
        "state": "ARIZONA",
        "group": "014407",
        "group_num": [
            "recJPvxWEbNjQDTJv"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recGR99SWSrkctgKE",
        "row": 5417,
        "state": "ARIZONA",
        "group": "0BPM03",
        "group_num": [
            "reci0IUUK6S0qdJxD"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1790,
        "2023": 0,
        "2024": 0,
        "id": "recH1OAKEcF3yuO1r",
        "row": 5398,
        "state": "ARIZONA",
        "group": "050689",
        "group_num": [
            "recfZqwP1r2ZuZxdT"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1796,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHzpHHkB6l0kMKZ",
        "row": 5382,
        "state": "ARIZONA",
        "group": "189421",
        "group_num": [
            "recj77sd6f8qHn6Jh"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHpVqDJHRTlSfuI",
        "row": 5408,
        "state": "ARIZONA",
        "group": "276785M010",
        "group_num": [
            "recPZCdUDlo0VhTNf"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 191,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK0GkvKb4hPKEsN",
        "row": 5399,
        "state": "ARIZONA",
        "group": "01AP003",
        "group_num": [
            "rec3A5NMaiuhh0THU"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 4409,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKQEP39oxE0LZ2V",
        "row": 5380,
        "state": "ARIZONA",
        "group": "189421",
        "group_num": [
            "recj77sd6f8qHn6Jh"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 38,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKfCgSX1lkTn5V1",
        "row": 5374,
        "state": "ARIZONA",
        "group": "CB030X",
        "group_num": [
            "recBktXlHH1TG8jME"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 312,
        "2023": 0,
        "2024": 0,
        "id": "recNN1cK3e3qpsL00",
        "row": 5388,
        "state": "ARIZONA",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 266,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN9yP2w2xGhjNkP",
        "row": 5413,
        "state": "ARIZONA",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece7v1GwiFHXVHLO",
        "row": 5464,
        "state": "ARIZONA",
        "group": "020403",
        "group_num": [
            "recNC7mna2dZ5aHav"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 3317,
        "2023": 3000,
        "2024": 0,
        "id": "recfQYQ4iGR2jULBH",
        "row": 5434,
        "state": "ARIZONA",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recg4kKdsvLWBHS3J",
        "row": 5518,
        "state": "ARIZONA",
        "group": "10261236",
        "group_num": [
            "recKbI2O9e1ZvwFmy"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 188,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechQgN9BvzNxsd9T",
        "row": 5473,
        "state": "ARIZONA",
        "group": "270083PGIA",
        "group_num": [
            "recpLmZONDBNO4mxI"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1756,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciuvSocMHoB42N9",
        "row": 5501,
        "state": "ARIZONA",
        "group": "003324107064A16",
        "group_num": [
            "recGphFT377o6e7EQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 663,
        "2021": 663,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "reciv3Q7qXG1YqESO",
        "row": 5438,
        "state": "ARIZONA",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1870,
        "2022": 1870,
        "2023": 0,
        "2024": 0,
        "id": "reciAyPlbZpNvmzn8",
        "row": 5429,
        "state": "ARIZONA",
        "group": "270092M2NA",
        "group_num": [
            "recUtXvA2Je870Nk3"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjCSaES4wlnRd88",
        "row": 5520,
        "state": "ARIZONA",
        "group": "10520134",
        "group_num": [
            "receAJWa2xLPXUHBM"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 2486,
        "2021": 713,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recjILesaLj5dsj4U",
        "row": 5475,
        "state": "ARIZONA",
        "group": "099198",
        "group_num": [
            "recOlhVE8bI3rZzgR"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "2023": 0,
        "2024": 0,
        "id": "reclsiaNbVyNzFiq6",
        "row": 5469,
        "state": "ARIZONA",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 667,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnWBX95QbO5X4r9",
        "row": 5462,
        "state": "ARIZONA",
        "group": "121982",
        "group_num": [
            "recwd1nRTKgPaDWNr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1394,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnRf9IN6HuLYwnw",
        "row": 5453,
        "state": "ARIZONA",
        "group": "212178",
        "group_num": [
            "recdGBuNAjkzhsPhY"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1147,
        "2021": 1441,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recPF8CENUdgrLIhm",
        "row": 5437,
        "state": "ARIZONA",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 142,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQG8c5nRU5DRwLH",
        "row": 5443,
        "state": "ARIZONA",
        "group": "716076152",
        "group_num": [
            "recXKISehPDHFdzu2"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 818,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recREpGzlDlzd6558",
        "row": 5458,
        "state": "ARIZONA",
        "group": "000HCY834AAAP01",
        "group_num": [
            "recKYCHJR2GqykY6h"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1813,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRbgIATTAfbxcaa",
        "row": 5457,
        "state": "ARIZONA",
        "group": "003320850",
        "group_num": [
            "recUkDLHncCbfpDg4"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRikwYWhENMrvyW",
        "row": 5460,
        "state": "ARIZONA",
        "group": "W0069151",
        "group_num": [
            "recc4pnI2AFuueM4E"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 3027,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recSxzpPHciS4ivqU",
        "row": 5451,
        "state": "ARIZONA",
        "group": "169146",
        "group_num": [
            "recfrJRY72kdjV9Zy"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 37,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTS1m69HeKCpqkS",
        "row": 5465,
        "state": "ARIZONA",
        "group": "020403",
        "group_num": [
            "recNC7mna2dZ5aHav"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 998,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTsURMMYyIhAK5J",
        "row": 5511,
        "state": "ARIZONA",
        "group": "476487",
        "group_num": [
            "recqgc1deFP26vimh"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "2023": 0,
        "2024": 0,
        "id": "recUXp6iUeDmdwmQO",
        "row": 5488,
        "state": "ARIZONA",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUuBlCObnN9Add6",
        "row": 5422,
        "state": "ARIZONA",
        "group": "000050",
        "group_num": [
            "recBmnvqTKjWIwuO7"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV5MDTR85B4yGL6",
        "row": 5480,
        "state": "ARIZONA",
        "group": "174056M5AZ",
        "group_num": [
            "recz3MCeldk5kqJxg"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1826,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWtY178JuUGmymD",
        "row": 5482,
        "state": "ARIZONA",
        "group": "174056M5FM",
        "group_num": [
            "recwdu6R6Fbnguv0n"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recWbHf445iJ4LEkh",
        "row": 5500,
        "state": "ARIZONA",
        "group": "C22681M2A2",
        "group_num": [
            "rec6qWbNU08QTNKc0"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1901,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXZ4bg4Urf8fIMD",
        "row": 5492,
        "state": "ARIZONA",
        "group": "004007210NNRP00",
        "group_num": [
            "recQAVPIf1eKXS4QI"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 38,
        "2022": 91,
        "2023": 0,
        "2024": 0,
        "id": "recXx9P3KiR8SH8JS",
        "row": 5516,
        "state": "ARIZONA",
        "group": "A53206",
        "group_num": [
            "recWcAOQfexnPRM5y"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXbEvBQlVAg7klS",
        "row": 5452,
        "state": "ARIZONA",
        "group": "WI2303M001",
        "group_num": [
            "recRddS8Q5wo5NhK2"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 270,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY4U83rl0vil02n",
        "row": 5427,
        "state": "ARIZONA",
        "group": "00761910008",
        "group_num": [
            "recxUFLQph25t00OR"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recYni2685m1eyr7X",
        "row": 5432,
        "state": "ARIZONA",
        "group": "OH2860M001",
        "group_num": [
            "recHTM4w4rga0OLKK"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZKRGhrMRsFfVYt",
        "row": 5455,
        "state": "ARIZONA",
        "group": "212178",
        "group_num": [
            "recdGBuNAjkzhsPhY"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Z25Z32EqU1ETk",
        "row": 5509,
        "state": "ARIZONA",
        "group": "W42851MC02",
        "group_num": [
            "recqzk8FSneU7Y0ZC"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec01mZPTkongfBr4",
        "row": 5507,
        "state": "ARIZONA",
        "group": "165103M027",
        "group_num": [
            "rec8EFhZUBLEpAWu1"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1867,
        "2024": 0,
        "id": "rec0h6hZT15mOPvYm",
        "row": 5487,
        "state": "ARIZONA",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 216,
        "2021": 161,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec26fimAXBdSwEqp",
        "row": 5503,
        "state": "ARIZONA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 115,
        "2021": 117,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec34T7fpJKgpvGBe",
        "row": 5450,
        "state": "ARIZONA",
        "group": "00045253",
        "group_num": [
            "recZ1YOSrwNeOQBNq"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3iRu9Mt5AbwhhB",
        "row": 5466,
        "state": "ARIZONA",
        "group": "NY45490C2C",
        "group_num": [
            "rec7l7RseXj5C4DY2"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 302,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec41dIqOsTKkgv4e",
        "row": 5477,
        "state": "ARIZONA",
        "group": "174056M2C1",
        "group_num": [
            "recU21N9eVtxh8yAJ"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5FZL45K7Gvx6qf",
        "row": 5514,
        "state": "ARIZONA",
        "group": "A53206",
        "group_num": [
            "recWcAOQfexnPRM5y"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1773,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5KJswfyPQdJaCq",
        "row": 5423,
        "state": "ARIZONA",
        "group": "174141M1A1",
        "group_num": [
            "recb4ST1NbP4GFwXG"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "rec7FOubVXqrW7ddG",
        "row": 5468,
        "state": "ARIZONA",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1875,
        "2021": 1875,
        "2022": 1140,
        "2023": 230,
        "2024": 0,
        "id": "rec741FHFYW2gfNz0",
        "row": 5436,
        "state": "ARIZONA",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 2375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9yWKKeRzs4BdK5",
        "row": 5441,
        "state": "ARIZONA",
        "group": "174549M131",
        "group_num": [
            "rec4UNRrw3OKEHAuY"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9Lc7OAQH300116",
        "row": 5508,
        "state": "ARIZONA",
        "group": "165103M027",
        "group_num": [
            "rec8EFhZUBLEpAWu1"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 54,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9Lg1RnFb7lPWKj",
        "row": 5476,
        "state": "ARIZONA",
        "group": "099198",
        "group_num": [
            "recOlhVE8bI3rZzgR"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 584,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9O4X0BnUY0kFIS",
        "row": 5471,
        "state": "ARIZONA",
        "group": "270083PGIA",
        "group_num": [
            "recpLmZONDBNO4mxI"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1991,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbp676SqyyvMV8c",
        "row": 5502,
        "state": "ARIZONA",
        "group": "003324107064A16",
        "group_num": [
            "recGphFT377o6e7EQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1972,
        "2022": 2981,
        "2023": 0,
        "2024": 0,
        "id": "recczOSUkp1ECat5X",
        "row": 5474,
        "state": "ARIZONA",
        "group": "099198",
        "group_num": [
            "recOlhVE8bI3rZzgR"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1813,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccNpgT8tMJ9x9Mb",
        "row": 5479,
        "state": "ARIZONA",
        "group": "174056M5AZ",
        "group_num": [
            "recz3MCeldk5kqJxg"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccPbVeq04S18ap6",
        "row": 5517,
        "state": "ARIZONA",
        "group": "00251699",
        "group_num": [
            "recNElOu2oCup2UJI"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 174,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdh4reyzKpja51D",
        "row": 5483,
        "state": "ARIZONA",
        "group": "174056M5FM",
        "group_num": [
            "recwdu6R6Fbnguv0n"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 74,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdnsDG5xnAODKTJ",
        "row": 5493,
        "state": "ARIZONA",
        "group": "000ISA834AAAP00",
        "group_num": [
            "recZl13rZzxZIqZ7m"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recozOfEN5xgrQkQF",
        "row": 5521,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 3615,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpyF8OScIWKygo5",
        "row": 5481,
        "state": "ARIZONA",
        "group": "174056M5FM",
        "group_num": [
            "recwdu6R6Fbnguv0n"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqwoK8yMmdVavGC",
        "row": 5513,
        "state": "ARIZONA",
        "group": "A03743",
        "group_num": [
            "recUXbMjG9onHzSJ9"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 32,
        "2024": 0,
        "id": "recrv22ThfmVlknK5",
        "row": 5470,
        "state": "ARIZONA",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1195,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr380wVbTTGEYGF",
        "row": 5430,
        "state": "ARIZONA",
        "group": "270092M2NA",
        "group_num": [
            "recUtXvA2Je870Nk3"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 453,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsp0HJ00twvsZGZ",
        "row": 5472,
        "state": "ARIZONA",
        "group": "270083PGIA",
        "group_num": [
            "recpLmZONDBNO4mxI"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 255,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect2Uiihxwj79o9a",
        "row": 5496,
        "state": "ARIZONA",
        "group": "C22681M1A2",
        "group_num": [
            "recgNs0XUw0WhAYVN"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1449,
        "2023": 0,
        "2024": 0,
        "id": "rectbGgYYs0FUpSMf",
        "row": 5499,
        "state": "ARIZONA",
        "group": "C22681M2A2",
        "group_num": [
            "rec6qWbNU08QTNKc0"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuiEj17yM0GKVpP",
        "row": 5519,
        "state": "ARIZONA",
        "group": "10317522",
        "group_num": [
            "rectUhIcDtL71LAsy"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1941,
        "2024": 0,
        "id": "recvxCwBW5KOI0jiU",
        "row": 5445,
        "state": "ARIZONA",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 340,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvCRn10j0tJ85UW",
        "row": 5504,
        "state": "ARIZONA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 159,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv4XDuczNdIOeVX",
        "row": 5485,
        "state": "ARIZONA",
        "group": "174056M5FM",
        "group_num": [
            "recwdu6R6Fbnguv0n"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recwEtQI6Xm3mxzEq",
        "row": 5431,
        "state": "ARIZONA",
        "group": "OH2860M001",
        "group_num": [
            "recHTM4w4rga0OLKK"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1095,
        "2023": 0,
        "2024": 0,
        "id": "recxTaqcnoXdhWdMj",
        "row": 5498,
        "state": "ARIZONA",
        "group": "C22681M2A2",
        "group_num": [
            "rec6qWbNU08QTNKc0"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxknuCGf4o2Xaka",
        "row": 5463,
        "state": "ARIZONA",
        "group": "121982",
        "group_num": [
            "recwd1nRTKgPaDWNr"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recywt8wmSX331uiO",
        "row": 5442,
        "state": "ARIZONA",
        "group": "174549M211",
        "group_num": [
            "recIaU9MdfrATIP3T"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1016,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recycyB0boDVCGrDb",
        "row": 5454,
        "state": "ARIZONA",
        "group": "212178",
        "group_num": [
            "recdGBuNAjkzhsPhY"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyqRFyJruPGxQLc",
        "row": 5491,
        "state": "ARIZONA",
        "group": "71544",
        "group_num": [
            "recSfJ7dEpd6PURu7"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 141,
        "2024": 0,
        "id": "reczjybmql7W6VDKo",
        "row": 5489,
        "state": "ARIZONA",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recADBMB8Bmu9tLNy",
        "row": 5449,
        "state": "ARIZONA",
        "group": "L03882M002",
        "group_num": [
            "recvcT3WE9V5qJG2K"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 302,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBAHyscGLimgi28",
        "row": 5510,
        "state": "ARIZONA",
        "group": "476487",
        "group_num": [
            "recqgc1deFP26vimh"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2815,
        "2023": 0,
        "2024": 0,
        "id": "recB8H3AUi2JmOPWe",
        "row": 5486,
        "state": "ARIZONA",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 4714,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCpV2eOgA9nKNEY",
        "row": 5440,
        "state": "ARIZONA",
        "group": "174549M131",
        "group_num": [
            "rec4UNRrw3OKEHAuY"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDeT1g0cELVtHJL",
        "row": 5490,
        "state": "ARIZONA",
        "group": "716085133",
        "group_num": [
            "rec5bzgpQhfzSo45I"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 2375,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recEAtx9ZAHoRRzin",
        "row": 5435,
        "state": "ARIZONA",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEDV2Ld0oDFEpvV",
        "row": 5424,
        "state": "ARIZONA",
        "group": "174141M1A1",
        "group_num": [
            "recb4ST1NbP4GFwXG"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 540,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFVA53PRwX0AtaI",
        "row": 5426,
        "state": "ARIZONA",
        "group": "00761910008",
        "group_num": [
            "recxUFLQph25t00OR"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 33,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFWv0v1iUaPIwi6",
        "row": 5461,
        "state": "ARIZONA",
        "group": "W0069151",
        "group_num": [
            "recc4pnI2AFuueM4E"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1883,
        "2023": 0,
        "2024": 0,
        "id": "recFGDb3UYv7LpjFA",
        "row": 5446,
        "state": "ARIZONA",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 203,
        "2021": 159,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGTGtQC8OP8CxSa",
        "row": 5497,
        "state": "ARIZONA",
        "group": "C22681M1A2",
        "group_num": [
            "recgNs0XUw0WhAYVN"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 268,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGMc1NnTWTO2TSO",
        "row": 5484,
        "state": "ARIZONA",
        "group": "174056M5FM",
        "group_num": [
            "recwdu6R6Fbnguv0n"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1962,
        "2024": 0,
        "id": "recGbsWOblpAMe4Ff",
        "row": 5433,
        "state": "ARIZONA",
        "group": "210043M1A4",
        "group_num": [
            "rechd8hu5BDHSjsfM"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHvcLOwiEojKmVb",
        "row": 5506,
        "state": "ARIZONA",
        "group": "165103M027",
        "group_num": [
            "rec8EFhZUBLEpAWu1"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 205,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHhwCEaeUm4cSa2",
        "row": 5495,
        "state": "ARIZONA",
        "group": "C22681M1A2",
        "group_num": [
            "recgNs0XUw0WhAYVN"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 297,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHmbQ1EX9rg1gx1",
        "row": 5515,
        "state": "ARIZONA",
        "group": "A53206",
        "group_num": [
            "recWcAOQfexnPRM5y"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recJfONu9u7xkld6B",
        "row": 5447,
        "state": "ARIZONA",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 413,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLwAx06dOX3q0HU",
        "row": 5448,
        "state": "ARIZONA",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 293,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLxlQp8brYfLG9A",
        "row": 5505,
        "state": "ARIZONA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 71,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNSMQZx8k5nQbOx",
        "row": 5444,
        "state": "ARIZONA",
        "group": "716076152",
        "group_num": [
            "recXKISehPDHFdzu2"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 5000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNpF9mQAwEeFM6u",
        "row": 5467,
        "state": "ARIZONA",
        "group": "NY45490C2M",
        "group_num": [
            "recqXkr70fbPFKT8O"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 306,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfkCAOrDRQ3Ajdn",
        "row": 5581,
        "state": "ARIZONA",
        "group": "003330118MCAP00",
        "group_num": [
            "rec8Ua4OdDsLJ122Z"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgxvIo73n5IkFoo",
        "row": 5610,
        "state": "ARIZONA",
        "group": "00761081141",
        "group_num": [
            "recuX7GZItSMLggNp"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recgFfhuTf1Rse4mb",
        "row": 5598,
        "state": "ARIZONA",
        "group": "P38825",
        "group_num": [
            "recvNEWQPx3inbWeV"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 595,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechYCHTtSbcLyqm2",
        "row": 5620,
        "state": "ARIZONA",
        "group": "190423",
        "group_num": [
            "recJjv8LJ1mUbBUmK"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1209,
        "2021": 450,
        "2022": 825,
        "2023": 0,
        "2024": 0,
        "id": "rechzXClGqVdERTI9",
        "row": 5544,
        "state": "ARIZONA",
        "group": "256916",
        "group_num": [
            "recGsOIB6KinEYszZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 78,
        "2021": 0,
        "2022": 76,
        "2023": 0,
        "2024": 0,
        "id": "reck1QEM03Aeja8uG",
        "row": 5550,
        "state": "ARIZONA",
        "group": "256917",
        "group_num": [
            "recqWtQIYCw4DqD4s"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 195,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckpAbqLMxMGY35D",
        "row": 5532,
        "state": "ARIZONA",
        "group": "174565MGA1",
        "group_num": [
            "recru6bsiJv7l6QuX"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclu1b47i80mBCLK",
        "row": 5583,
        "state": "ARIZONA",
        "group": "04605403",
        "group_num": [
            "recqkqguLIWChjpWt"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmGkzkGLwJpkcsm",
        "row": 5543,
        "state": "ARIZONA",
        "group": "033104",
        "group_num": [
            "recomf769Lc9Xy5IK"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 108,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmlUHMqdzU3PV8M",
        "row": 5616,
        "state": "ARIZONA",
        "group": "190422",
        "group_num": [
            "reckfdvCCj1YB55wK"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 79,
        "2021": 78,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnFsiq6eqYNBrHE",
        "row": 5555,
        "state": "ARIZONA",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnbXHZQ4lkuevLj",
        "row": 5597,
        "state": "ARIZONA",
        "group": "037461",
        "group_num": [
            "recrrFZK8WbvhAPDg"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1176,
        "2021": 0,
        "2022": 553,
        "2023": 0,
        "2024": 0,
        "id": "recON6qRxzgVDgXtC",
        "row": 5552,
        "state": "ARIZONA",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 954,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPSY1n7uCrGlJv5",
        "row": 5619,
        "state": "ARIZONA",
        "group": "190423",
        "group_num": [
            "recJjv8LJ1mUbBUmK"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1868,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPsvJXxaeNzLSzK",
        "row": 5559,
        "state": "ARIZONA",
        "group": "003320490AZXP00",
        "group_num": [
            "rechVUX6YzTHzgn73"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1940,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP9kkION5AX4Ps4",
        "row": 5580,
        "state": "ARIZONA",
        "group": "10195485",
        "group_num": [
            "recObhvJaIOcdrs5I"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1059,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQsw7rWvfoy2hod",
        "row": 5572,
        "state": "ARIZONA",
        "group": "053256",
        "group_num": [
            "rec7cZaQ0goVSIob2"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 170,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQFF3I57D4twxj4",
        "row": 5577,
        "state": "ARIZONA",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 219,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRELMYyJlBEhx07",
        "row": 5601,
        "state": "ARIZONA",
        "group": "716040906",
        "group_num": [
            "recP2LGxxsAnfoEwG"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 278,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRGr78R4EC5O3k1",
        "row": 5562,
        "state": "ARIZONA",
        "group": "247647",
        "group_num": [
            "recQPiiTvvU0QEA80"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recRrhoXKmrT6PFna",
        "row": 5536,
        "state": "ARIZONA",
        "group": "1875HE",
        "group_num": [
            "rec8pqzcJu2siqsPu"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1208,
        "2023": 0,
        "2024": 0,
        "id": "recSyIHvn8R2Kz7Np",
        "row": 5561,
        "state": "ARIZONA",
        "group": "002343173",
        "group_num": [
            "reclN4nQKx3x0WTML"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recSD3gFE4mZdpmlE",
        "row": 5538,
        "state": "ARIZONA",
        "group": "212107M3A2",
        "group_num": [
            "recMoid7jkus4BtOX"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1813,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTPCIzU2IiYqdyk",
        "row": 5526,
        "state": "ARIZONA",
        "group": "003320026FFAG0",
        "group_num": [
            "recEFd64zRvvJ8IHL"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 610,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUTtup7Ghm10feE",
        "row": 5566,
        "state": "ARIZONA",
        "group": "MO2212M003",
        "group_num": [
            "recjms3zazSMovkNq"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 170,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWhtERLcD0uba3Z",
        "row": 5594,
        "state": "ARIZONA",
        "group": "174592M3NA",
        "group_num": [
            "recEmBjyt0S65Kyqg"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1914,
        "2023": 0,
        "2024": 0,
        "id": "recWh0zpafldmIQ9K",
        "row": 5525,
        "state": "ARIZONA",
        "group": "213051M1GB",
        "group_num": [
            "rec5CeP0jZ5DnKG6j"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1939,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXFiDx7YRUZv10m",
        "row": 5527,
        "state": "ARIZONA",
        "group": "003320026FFAG0",
        "group_num": [
            "recEFd64zRvvJ8IHL"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXHemFzYQ7RW2Pl",
        "row": 5533,
        "state": "ARIZONA",
        "group": "174565MGA1",
        "group_num": [
            "recru6bsiJv7l6QuX"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recX5G3cjpsJPZvaE",
        "row": 5600,
        "state": "ARIZONA",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 630,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXeAQFxt3RG0p6r",
        "row": 5608,
        "state": "ARIZONA",
        "group": "00761051131",
        "group_num": [
            "rec3gfhPq7c6Cw4X8"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 163,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYtMWOzxiL0jbC0",
        "row": 5587,
        "state": "ARIZONA",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1716,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYLt6sZ1uSjRp5E",
        "row": 5545,
        "state": "ARIZONA",
        "group": "256916",
        "group_num": [
            "recGsOIB6KinEYszZ"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1409,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYdAWCBhayCklzo",
        "row": 5586,
        "state": "ARIZONA",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 152,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZeRVrfqhDFrrEc",
        "row": 5605,
        "state": "ARIZONA",
        "group": "174022MHA8",
        "group_num": [
            "rec49ydMWRVp3y8gx"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0CtXRQPPHrTFga",
        "row": 5570,
        "state": "ARIZONA",
        "group": "88058977",
        "group_num": [
            "recGfIGlAMqAIoro9"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 2600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0JGM48n06Y4Bn0",
        "row": 5614,
        "state": "ARIZONA",
        "group": "00925000012",
        "group_num": [
            "recC6APjmEgvBx1po"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1564,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1SZQ6N4Ih4LQkQ",
        "row": 5584,
        "state": "ARIZONA",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1UQ9eNmduiAVvO",
        "row": 5590,
        "state": "ARIZONA",
        "group": "1874LL",
        "group_num": [
            "rec1incmtXd7NlM9B"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 79,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1gwbfFwHxLtrx5",
        "row": 5546,
        "state": "ARIZONA",
        "group": "256916",
        "group_num": [
            "recGsOIB6KinEYszZ"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "rec2YwKZrMrnqjwd7",
        "row": 5523,
        "state": "ARIZONA",
        "group": "213051M1FA",
        "group_num": [
            "recdOaGNm5EYluoMv"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 231,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3BkvoL5O7gsooh",
        "row": 5578,
        "state": "ARIZONA",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 300,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4OSr4FxiIScoiY",
        "row": 5609,
        "state": "ARIZONA",
        "group": "00761051131",
        "group_num": [
            "rec3gfhPq7c6Cw4X8"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 330,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4qzr22LZNE0AyF",
        "row": 5529,
        "state": "ARIZONA",
        "group": "003320026FFAG00",
        "group_num": [
            "recROHrkMDq2hmm1K"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 303,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5WLcGB12vEDZGH",
        "row": 5582,
        "state": "ARIZONA",
        "group": "003330118MCAP00",
        "group_num": [
            "rec8Ua4OdDsLJ122Z"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7WHnS1WHkz7GF7",
        "row": 5595,
        "state": "ARIZONA",
        "group": "174592M3NA",
        "group_num": [
            "recEmBjyt0S65Kyqg"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 230,
        "2024": 0,
        "id": "rec7jo6vjCa98mnJ1",
        "row": 5607,
        "state": "ARIZONA",
        "group": "174022MJAF",
        "group_num": [
            "recrQX1yp8CqRbDTb"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "rec8W8qFBFqZZ9Ndp",
        "row": 5621,
        "state": "ARIZONA",
        "group": "190427",
        "group_num": [
            "rec0WSAB624mv7ym0"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1788,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9JSBw4KhgVrMpN",
        "row": 5571,
        "state": "ARIZONA",
        "group": "003330150MAAG00",
        "group_num": [
            "reclu6ixFGKjOcabN"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "2023": 0,
        "2024": 0,
        "id": "recb0F4IUuXqmtwGo",
        "row": 5576,
        "state": "ARIZONA",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 201,
        "2023": 0,
        "2024": 0,
        "id": "recbPiJGILxSdHIKl",
        "row": 5596,
        "state": "ARIZONA",
        "group": "020224",
        "group_num": [
            "rechBUvLQw19SXoc6"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 306,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb4kh00sXJVvoTi",
        "row": 5540,
        "state": "ARIZONA",
        "group": "000LBN834",
        "group_num": [
            "recVmnErNG2efndGn"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 319,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccsj09BBRpb0iF2",
        "row": 5612,
        "state": "ARIZONA",
        "group": "00761081141",
        "group_num": [
            "recuX7GZItSMLggNp"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccvZgllSwecLvx1",
        "row": 5558,
        "state": "ARIZONA",
        "group": "10012785",
        "group_num": [
            "rec9UWGceM5ehBEDR"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 1904,
        "2024": 0,
        "id": "reccOF7JeM5k9DEPM",
        "row": 5606,
        "state": "ARIZONA",
        "group": "174022MJAF",
        "group_num": [
            "recrQX1yp8CqRbDTb"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc9eQ4gRlcIT4xy",
        "row": 5534,
        "state": "ARIZONA",
        "group": "174565MGA1",
        "group_num": [
            "recru6bsiJv7l6QuX"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 112,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdVDoen7CvAzcA2",
        "row": 5531,
        "state": "ARIZONA",
        "group": "003320026FFAP00",
        "group_num": [
            "recs39IszVvNlqWQ6"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 735,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoFYzmM5uF2UG8t",
        "row": 5611,
        "state": "ARIZONA",
        "group": "00761081141",
        "group_num": [
            "recuX7GZItSMLggNp"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoINKvKsdpydRdQ",
        "row": 5560,
        "state": "ARIZONA",
        "group": "002340148",
        "group_num": [
            "rechgSpF2QsOTQ6Ha"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 159,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoOIb4NyOgDZvPE",
        "row": 5564,
        "state": "ARIZONA",
        "group": "247647",
        "group_num": [
            "recQPiiTvvU0QEA80"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 185,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpMgBQSZ9Z688Mr",
        "row": 5563,
        "state": "ARIZONA",
        "group": "247647",
        "group_num": [
            "recQPiiTvvU0QEA80"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpmH6BmXWyIRlnt",
        "row": 5603,
        "state": "ARIZONA",
        "group": "174022MHA8",
        "group_num": [
            "rec49ydMWRVp3y8gx"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 190,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq1fThAXIKIExaT",
        "row": 5591,
        "state": "ARIZONA",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "2024": 0,
        "id": "recsCNwx2BfJH08dt",
        "row": 5592,
        "state": "ARIZONA",
        "group": "716030204",
        "group_num": [
            "recBj2gQdpJ8pkCNz"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 336,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectSKvu4IvG6EsR3",
        "row": 5585,
        "state": "ARIZONA",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 228,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuBE6kDWRlBNLO1",
        "row": 5604,
        "state": "ARIZONA",
        "group": "174022MHA8",
        "group_num": [
            "rec49ydMWRVp3y8gx"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuR71w8qyXwLPEo",
        "row": 5618,
        "state": "ARIZONA",
        "group": "190423",
        "group_num": [
            "recJjv8LJ1mUbBUmK"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4290,
        "2023": 0,
        "2024": 0,
        "id": "recu6xM2030jeXHgZ",
        "row": 5613,
        "state": "ARIZONA",
        "group": "00925000012",
        "group_num": [
            "recC6APjmEgvBx1po"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 152,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvXY4oL0CbU2QMu",
        "row": 5568,
        "state": "ARIZONA",
        "group": "MO2212M003",
        "group_num": [
            "recjms3zazSMovkNq"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1155,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvn9x2bRfRwenPA",
        "row": 5553,
        "state": "ARIZONA",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwTm4VRUsUnCDny",
        "row": 5542,
        "state": "ARIZONA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxx0J292ApKJwwG",
        "row": 5539,
        "state": "ARIZONA",
        "group": "P15412",
        "group_num": [
            "recWmwc3EcBwmZyje"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1050,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx8v0gBPhnqGwiS",
        "row": 5554,
        "state": "ARIZONA",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 650,
        "2021": 306,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyXj3v7LApjHWnb",
        "row": 5615,
        "state": "ARIZONA",
        "group": "00935000002",
        "group_num": [
            "reciHjlxH7oKI1YMh"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAEOqpnbKf7ETyW",
        "row": 5589,
        "state": "ARIZONA",
        "group": "1874LL",
        "group_num": [
            "rec1incmtXd7NlM9B"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recAMmBb1UpzCbC95",
        "row": 5537,
        "state": "ARIZONA",
        "group": "1875HE",
        "group_num": [
            "rec8pqzcJu2siqsPu"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAfdsOA8u8FZ09p",
        "row": 5602,
        "state": "ARIZONA",
        "group": "002335448",
        "group_num": [
            "reccKgb7n8fGmxfGl"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBB4ulTMsM08Qpk",
        "row": 5573,
        "state": "ARIZONA",
        "group": "053256",
        "group_num": [
            "rec7cZaQ0goVSIob2"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1074,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC9GqHNRWlUpEt9",
        "row": 5565,
        "state": "ARIZONA",
        "group": "032244",
        "group_num": [
            "recouNeWg1CYGvFLh"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 310,
        "2023": 0,
        "2024": 0,
        "id": "recDEukisldkF8HgR",
        "row": 5528,
        "state": "ARIZONA",
        "group": "003320026FFAG00",
        "group_num": [
            "recROHrkMDq2hmm1K"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2950,
        "2024": 0,
        "id": "recExkL1oGuEE7N4q",
        "row": 5557,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "2023": 0,
        "2024": 0,
        "id": "recEmZcMR3rsS3HXI",
        "row": 5593,
        "state": "ARIZONA",
        "group": "716030204",
        "group_num": [
            "recBj2gQdpJ8pkCNz"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "recF8iWHhzyplPq3N",
        "row": 5522,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFcpnsgkLkAYNpy",
        "row": 5541,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "2023": 0,
        "2024": 0,
        "id": "recFd8pnN4c3GHXRM",
        "row": 5575,
        "state": "ARIZONA",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 75,
        "2022": 535,
        "2023": 0,
        "2024": 0,
        "id": "recGLr6hjHcvaR2Vm",
        "row": 5574,
        "state": "ARIZONA",
        "group": "053256",
        "group_num": [
            "rec7cZaQ0goVSIob2"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2107,
        "2023": 0,
        "2024": 0,
        "id": "recISWNpq5iFuB7LA",
        "row": 5524,
        "state": "ARIZONA",
        "group": "213051M1FB",
        "group_num": [
            "recM33VV1afrDr4JZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 225,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIuBTeRcyqf0oI2",
        "row": 5567,
        "state": "ARIZONA",
        "group": "MO2212M003",
        "group_num": [
            "recjms3zazSMovkNq"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1576,
        "2023": 0,
        "2024": 0,
        "id": "recIEhUjj6wMgVztW",
        "row": 5599,
        "state": "ARIZONA",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1419,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJMBbyzitYLfuOy",
        "row": 5548,
        "state": "ARIZONA",
        "group": "256917",
        "group_num": [
            "recqWtQIYCw4DqD4s"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1720,
        "2021": 931,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLa8auBlv0jWhI8",
        "row": 5617,
        "state": "ARIZONA",
        "group": "190423",
        "group_num": [
            "recJjv8LJ1mUbBUmK"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1466,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMtbKPPNTHZToR4",
        "row": 5549,
        "state": "ARIZONA",
        "group": "256917",
        "group_num": [
            "recqWtQIYCw4DqD4s"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 2304,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMz7hVfywzlbYZk",
        "row": 5530,
        "state": "ARIZONA",
        "group": "003320026FFAP00",
        "group_num": [
            "recs39IszVvNlqWQ6"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3616,
        "2023": 0,
        "2024": 0,
        "id": "receSYziMso7H0LnQ",
        "row": 5654,
        "state": "ARIZONA",
        "group": "116444",
        "group_num": [
            "recd5pvvGJApxBNSc"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1044,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recedZa92k03muINd",
        "row": 5650,
        "state": "ARIZONA",
        "group": "247732",
        "group_num": [
            "recvQ2Ot1RzjHTOPR"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 125,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfdaVNGanT0OE89",
        "row": 5646,
        "state": "ARIZONA",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 73,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciCylAhzsyUbsFr",
        "row": 5633,
        "state": "ARIZONA",
        "group": "0076118001",
        "group_num": [
            "recOxTAM6ZnS4ISPp"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 0,
        "2024": 0,
        "id": "reciHumaPZrBNY3HD",
        "row": 5671,
        "state": "ARIZONA",
        "group": "10584000",
        "group_num": [
            "reca1EZScsUm3xfkM"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 3750,
        "2024": 0,
        "id": "recijV3qAB0fBHdtD",
        "row": 5622,
        "state": "ARIZONA",
        "group": "324237",
        "group_num": [
            "recLGsOWaoxOXbIoa"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1913,
        "2021": 1431,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckiDIE7uh5PoD4t",
        "row": 5638,
        "state": "ARIZONA",
        "group": "380001",
        "group_num": [
            "recb280zpDaN7LbBs"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1889,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclwiisq6Igjh4LF",
        "row": 5661,
        "state": "ARIZONA",
        "group": "10205584",
        "group_num": [
            "recLWiTHYhs6d6TO0"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2013,
        "2024": 0,
        "id": "reclykkRmHwHLurA6",
        "row": 5648,
        "state": "ARIZONA",
        "group": "270099M2AA",
        "group_num": [
            "reczZPCiGmfnU1y8r"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "2023": 0,
        "2024": 0,
        "id": "reclCJP0hJigqhE0s",
        "row": 5660,
        "state": "ARIZONA",
        "group": "W8000019",
        "group_num": [
            "recDuL5OzBzSlzeJ1"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1689,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recO4kDYewiDvdT7V",
        "row": 5657,
        "state": "ARIZONA",
        "group": "169500",
        "group_num": [
            "recTPu0RDmUsdHXTL"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "2024": 0,
        "id": "recUUuvyFlLt7xqQ0",
        "row": 5623,
        "state": "ARIZONA",
        "group": "324237",
        "group_num": [
            "recLGsOWaoxOXbIoa"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 960,
        "2023": 0,
        "2024": 0,
        "id": "recVUkJNQnMuT7Thi",
        "row": 5635,
        "state": "ARIZONA",
        "group": "00761180001",
        "group_num": [
            "rec8Jn6AySCUaqrTf"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 75,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVwuptIa6D4ogAD",
        "row": 5640,
        "state": "ARIZONA",
        "group": "380001",
        "group_num": [
            "recb280zpDaN7LbBs"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 332,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYieQJayXpQTt94",
        "row": 5662,
        "state": "ARIZONA",
        "group": "10205584",
        "group_num": [
            "recLWiTHYhs6d6TO0"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 27,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ1ofooT0Q9oRhf",
        "row": 5647,
        "state": "ARIZONA",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 383,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "rec0QLsfWqmjXEcvS",
        "row": 5637,
        "state": "ARIZONA",
        "group": "380001",
        "group_num": [
            "recb280zpDaN7LbBs"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1475,
        "2021": 1475,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0cFHfjxp2N47It",
        "row": 5639,
        "state": "ARIZONA",
        "group": "380001",
        "group_num": [
            "recb280zpDaN7LbBs"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rec0ltmaAbgmjvtqt",
        "row": 5668,
        "state": "ARIZONA",
        "group": "0000CITY",
        "group_num": [
            "rec02RMUO3vArTWPj"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0oIUcQMtvDE8gA",
        "row": 5628,
        "state": "ARIZONA",
        "group": "11443422",
        "group_num": [
            "recWk8gzoNBgC7pJ0"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 868,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2lBPHTEHvXRu39",
        "row": 5632,
        "state": "ARIZONA",
        "group": "0076118001",
        "group_num": [
            "recOxTAM6ZnS4ISPp"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1851,
        "2023": 0,
        "2024": 0,
        "id": "rec8Z9I5WnNyXKhMO",
        "row": 5669,
        "state": "ARIZONA",
        "group": "174205M5A2",
        "group_num": [
            "recLw1fwrwMcbqdp1"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 36,
        "2021": 28,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9yMlEmhmjXp6pA",
        "row": 5627,
        "state": "ARIZONA",
        "group": "000002",
        "group_num": [
            "recEWQ5LGOYFYq53K"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 307,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccjsT0dNcJBdGNo",
        "row": 5625,
        "state": "ARIZONA",
        "group": "000002",
        "group_num": [
            "recEWQ5LGOYFYq53K"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1295,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd9kMe28TDtDWY3",
        "row": 5631,
        "state": "ARIZONA",
        "group": "0076118001",
        "group_num": [
            "recOxTAM6ZnS4ISPp"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco91DQKtq3IFq5w",
        "row": 5642,
        "state": "ARIZONA",
        "group": "130445",
        "group_num": [
            "recNxAmsliJE6dd34"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqEJNeqUH3yDYGl",
        "row": 5667,
        "state": "ARIZONA",
        "group": "0033200234ABP02",
        "group_num": [
            "recsluAPEyP6rVtMn"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 61,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr7YtG0qpZVzMQo",
        "row": 5652,
        "state": "ARIZONA",
        "group": "247732",
        "group_num": [
            "recvQ2Ot1RzjHTOPR"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs4HF7SywqCBJXL",
        "row": 5664,
        "state": "ARIZONA",
        "group": "888587",
        "group_num": [
            "recShTk4p95VDIx8O"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2340,
        "2023": 0,
        "2024": 0,
        "id": "rectSsiveBlTQuFCx",
        "row": 5653,
        "state": "ARIZONA",
        "group": "116444",
        "group_num": [
            "recd5pvvGJApxBNSc"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 666,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectQnc7ybDKgJHEl",
        "row": 5651,
        "state": "ARIZONA",
        "group": "247732",
        "group_num": [
            "recvQ2Ot1RzjHTOPR"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvevjBbUKXvVhh9",
        "row": 5629,
        "state": "ARIZONA",
        "group": "11443422",
        "group_num": [
            "recWk8gzoNBgC7pJ0"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxNVzxmFd2HmOyd",
        "row": 5665,
        "state": "ARIZONA",
        "group": "888587",
        "group_num": [
            "recShTk4p95VDIx8O"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 297,
        "2024": 0,
        "id": "reczQ0KzLrPFMf7BN",
        "row": 5655,
        "state": "ARIZONA",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 315,
        "2023": 0,
        "2024": 0,
        "id": "recByqKenGzxsMbjd",
        "row": 5663,
        "state": "ARIZONA",
        "group": "888587",
        "group_num": [
            "recShTk4p95VDIx8O"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 450,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEdcsGDNaTmameh",
        "row": 5656,
        "state": "ARIZONA",
        "group": "169500",
        "group_num": [
            "recTPu0RDmUsdHXTL"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "recEnsaXuGQXgof6V",
        "row": 5659,
        "state": "ARIZONA",
        "group": "W8000019",
        "group_num": [
            "recDuL5OzBzSlzeJ1"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFkQsZ5tm9CnupO",
        "row": 5624,
        "state": "ARIZONA",
        "group": "0TN006",
        "group_num": [
            "recMr3VznkpC9viii"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGTHPvLsbpht3Rj",
        "row": 5644,
        "state": "ARIZONA",
        "group": "15920013",
        "group_num": [
            "rec7ad302smC4ZKPJ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1188,
        "2023": 0,
        "2024": 0,
        "id": "recGn2cnRWgDoR4gy",
        "row": 5641,
        "state": "ARIZONA",
        "group": "41672613590",
        "group_num": [
            "rechot92CPlMOYZD2"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 911,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH1PT3L3S0FqzAc",
        "row": 5636,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1885,
        "2023": 0,
        "2024": 0,
        "id": "recIhRUFArMyjqlJH",
        "row": 5670,
        "state": "ARIZONA",
        "group": "174205M5A2",
        "group_num": [
            "recLw1fwrwMcbqdp1"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1748,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK8WrqEo9ewUDG2",
        "row": 5649,
        "state": "ARIZONA",
        "group": "201051M6A5",
        "group_num": [
            "rec84L2JY71r9YYP3"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMI8ivSHCP4n36o",
        "row": 5626,
        "state": "ARIZONA",
        "group": "000002",
        "group_num": [
            "recEWQ5LGOYFYq53K"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMgD4Bc8jgbc7XP",
        "row": 5645,
        "state": "ARIZONA",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1121,
        "2022": 553,
        "2023": 0,
        "2024": 0,
        "id": "recfDZjZZ7EEaVWqk",
        "row": 5680,
        "state": "ARIZONA",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1049,
        "2023": 0,
        "2024": 0,
        "id": "recfJMtXP7tpsQhc6",
        "row": 5683,
        "state": "ARIZONA",
        "group": "0EV767",
        "group_num": [
            "rec1vwNiyhQglMYsH"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg8CLzQFvhLiAzA",
        "row": 5715,
        "state": "ARIZONA",
        "group": "P15385",
        "group_num": [
            "rechp4XEvIytY2rcl"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "2023": 0,
        "2024": 0,
        "id": "rechWpvUJYNv3htdy",
        "row": 5672,
        "state": "ARIZONA",
        "group": "10584000",
        "group_num": [
            "reca1EZScsUm3xfkM"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 310,
        "2023": 0,
        "2024": 0,
        "id": "rechE2bm6VQb7k6cg",
        "row": 5712,
        "state": "ARIZONA",
        "group": "800312M004",
        "group_num": [
            "recRAvoKzjfBMCzdD"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rechhhdfnnIiWWlJX",
        "row": 5695,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 4117,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjfWcqIla1Pvgqi",
        "row": 5691,
        "state": "ARIZONA",
        "group": "99K1",
        "group_num": [
            "rec7cekqMFmH11Xgr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckhJkLLrnIQCqpR",
        "row": 5714,
        "state": "ARIZONA",
        "group": "P15385",
        "group_num": [
            "rechp4XEvIytY2rcl"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "reclNDksNnHCW2nf9",
        "row": 5693,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 25,
        "2022": 281,
        "2023": 158,
        "2024": 0,
        "id": "recmZA3YCHCeN1p2H",
        "row": 5682,
        "state": "ARIZONA",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recnZ51cj2MRFIJVU",
        "row": 5702,
        "state": "ARIZONA",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOeYlAcG5DrtLIu",
        "row": 5675,
        "state": "ARIZONA",
        "group": "GA9211M002",
        "group_num": [
            "reckzBsG3jrrpTkgy"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recPsuYsRoSBYuFK9",
        "row": 5692,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 314,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUXqVwpElFsr4hq",
        "row": 5700,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1475,
        "2021": 1475,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUybNwWlQC7Sc45",
        "row": 5718,
        "state": "ARIZONA",
        "group": "165758",
        "group_num": [
            "recfh9upvrUXg3bSu"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 38,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUPXhqox6rao0cu",
        "row": 5713,
        "state": "ARIZONA",
        "group": "800312M004",
        "group_num": [
            "recRAvoKzjfBMCzdD"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 330,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recVuq4PImpIt2Ni1",
        "row": 5711,
        "state": "ARIZONA",
        "group": "230039MSAU",
        "group_num": [
            "rec2uVvulq7d3IljT"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1801,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVuLHnr0CoqS8VR",
        "row": 5703,
        "state": "ARIZONA",
        "group": "174063M201",
        "group_num": [
            "receBdZTtgZN5oseT"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1662,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWfRyMGYLcuncWI",
        "row": 5717,
        "state": "ARIZONA",
        "group": "165758",
        "group_num": [
            "recfh9upvrUXg3bSu"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 293,
        "2021": 0,
        "2022": 310,
        "2023": 0,
        "2024": 0,
        "id": "recXEnMBiEOSDHG8e",
        "row": 5696,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec00VSR6MTwT6dyk",
        "row": 5707,
        "state": "ARIZONA",
        "group": "130468",
        "group_num": [
            "recT004sWeeSOilgS"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2HHVrPWHgHnXIV",
        "row": 5708,
        "state": "ARIZONA",
        "group": "130468",
        "group_num": [
            "recT004sWeeSOilgS"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 175,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6MmZttNdLwFThx",
        "row": 5705,
        "state": "ARIZONA",
        "group": "130468",
        "group_num": [
            "recT004sWeeSOilgS"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec722wuvyQS4EraH",
        "row": 5684,
        "state": "ARIZONA",
        "group": "282211M019",
        "group_num": [
            "recxSP2MWzv0VjhaO"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "rec7r5Kkr2MgJTZ2j",
        "row": 5673,
        "state": "ARIZONA",
        "group": "10584000",
        "group_num": [
            "reca1EZScsUm3xfkM"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 35,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8c4NDwsSrXkD1j",
        "row": 5698,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9fmoqGMfiOQV7I",
        "row": 5685,
        "state": "ARIZONA",
        "group": "282211M019",
        "group_num": [
            "recxSP2MWzv0VjhaO"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaUgLIro1gHwmfX",
        "row": 5706,
        "state": "ARIZONA",
        "group": "130468",
        "group_num": [
            "recT004sWeeSOilgS"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 78,
        "2023": 0,
        "2024": 0,
        "id": "recaszO8d19SRPhXq",
        "row": 5690,
        "state": "ARIZONA",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "recaAZ0to4PLY5PWM",
        "row": 5694,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1732,
        "2021": 935,
        "2022": 2480,
        "2023": 0,
        "2024": 0,
        "id": "recoHgdNUl2DkWD2W",
        "row": 5721,
        "state": "ARIZONA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 375,
        "2023": 0,
        "2024": 0,
        "id": "recodfrhLtiyvz82U",
        "row": 5710,
        "state": "ARIZONA",
        "group": "230039MSAU",
        "group_num": [
            "rec2uVvulq7d3IljT"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 18,
        "2021": 53,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqtG4dK2aXfhgkM",
        "row": 5679,
        "state": "ARIZONA",
        "group": "IG1801",
        "group_num": [
            "rec93JKjWI4OcGX8H"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr0kfJ6a7E2rWDN",
        "row": 5704,
        "state": "ARIZONA",
        "group": "130468",
        "group_num": [
            "recT004sWeeSOilgS"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsQVG9z2p8tMiI3",
        "row": 5674,
        "state": "ARIZONA",
        "group": "GA9211M002",
        "group_num": [
            "reckzBsG3jrrpTkgy"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 623,
        "2022": 109,
        "2023": 0,
        "2024": 0,
        "id": "recur0no2AE6OHy4v",
        "row": 5676,
        "state": "ARIZONA",
        "group": "IB2804",
        "group_num": [
            "recWZK3TxZ0875FNx"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 605,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvRQPOix7fmgLnW",
        "row": 5681,
        "state": "ARIZONA",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvqDACHejwGMtS1",
        "row": 5716,
        "state": "ARIZONA",
        "group": "P15385",
        "group_num": [
            "rechp4XEvIytY2rcl"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 450,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy1vg6ArWV3obzJ",
        "row": 5720,
        "state": "ARIZONA",
        "group": "165758",
        "group_num": [
            "recfh9upvrUXg3bSu"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 913,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA7yMf8UmgJQzTq",
        "row": 5677,
        "state": "ARIZONA",
        "group": "IG1801",
        "group_num": [
            "rec93JKjWI4OcGX8H"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBvr8zfN7VPJ3hy",
        "row": 5699,
        "state": "ARIZONA",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 360,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCXbiWDtRgMSPQ8",
        "row": 5719,
        "state": "ARIZONA",
        "group": "165758",
        "group_num": [
            "recfh9upvrUXg3bSu"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 146,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDYOthQiILpZpAt",
        "row": 5697,
        "state": "ARIZONA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 153,
        "2023": 0,
        "2024": 0,
        "id": "recFYIYZUoRn93WgV",
        "row": 5689,
        "state": "ARIZONA",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1844,
        "2022": 1799,
        "2023": 0,
        "2024": 0,
        "id": "recGP6CMD4d6gEhZX",
        "row": 5686,
        "state": "ARIZONA",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 436,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recG6PpDzPR3aI7gn",
        "row": 5678,
        "state": "ARIZONA",
        "group": "IG1801",
        "group_num": [
            "rec93JKjWI4OcGX8H"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1751,
        "2023": 0,
        "2024": 0,
        "id": "recJz9Immz1eRngwE",
        "row": 5687,
        "state": "ARIZONA",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 349,
        "2023": 0,
        "2024": 0,
        "id": "recKBXyPQ53xUMFD1",
        "row": 5688,
        "state": "ARIZONA",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "receZMQV7WPHf2IzJ",
        "row": 5772,
        "state": "ARIZONA",
        "group": "854328",
        "group_num": [
            "recrPpUAzYKKqQaPA"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recf3SJJDzOzcrIJH",
        "row": 5780,
        "state": "ARIZONA",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 1402,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgaJoNgiLbx01A3",
        "row": 5741,
        "state": "ARIZONA",
        "group": "834017430",
        "group_num": [
            "recSBRvqH9rt53scI"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 542,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgrfMnQnuDZKOiU",
        "row": 5793,
        "state": "ARIZONA",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 233,
        "2021": 212,
        "2022": 620,
        "2023": 0,
        "2024": 0,
        "id": "rech1FT2syMhWMZkA",
        "row": 5723,
        "state": "ARIZONA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 236,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechM0pD4mdz2w8wN",
        "row": 5778,
        "state": "ARIZONA",
        "group": "912345",
        "group_num": [
            "recXrux1XWIgb4OCx"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "rech6eyESR0viXjTr",
        "row": 5747,
        "state": "ARIZONA",
        "group": "277727M716",
        "group_num": [
            "recn4BqXa7Ql8jaGz"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1877,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechqmAq5dUD1Pkbo",
        "row": 5786,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 243,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciLlJfTYNLz8FdW",
        "row": 5794,
        "state": "ARIZONA",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2209,
        "2023": 0,
        "2024": 0,
        "id": "reci77GkgDxgJMChK",
        "row": 5816,
        "state": "ARIZONA",
        "group": "L04077M001",
        "group_num": [
            "rec8eI49FltikV8aZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 580,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recieg2BLVMqz8O3m",
        "row": 5728,
        "state": "ARIZONA",
        "group": "40828A",
        "group_num": [
            "recgFS92JDd0luxMM"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 3135,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjtdZCxtI7K7lmR",
        "row": 5744,
        "state": "ARIZONA",
        "group": "277727M356",
        "group_num": [
            "recf0jMtJCxgxH86q"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1769,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckSpyW3nc2b3BEB",
        "row": 5777,
        "state": "ARIZONA",
        "group": "248606",
        "group_num": [
            "reciJM1ctcQQUktcj"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckNwyoCgOMKAhpH",
        "row": 5803,
        "state": "ARIZONA",
        "group": "696978",
        "group_num": [
            "recUB12GkAVTQSU5w"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1651,
        "2022": 1585,
        "2023": 0,
        "2024": 0,
        "id": "recljdPdWcVAWl7yf",
        "row": 5750,
        "state": "ARIZONA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1376,
        "2021": 1362,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmR2duFVm4Ihb6I",
        "row": 5757,
        "state": "ARIZONA",
        "group": "152342",
        "group_num": [
            "rec1Lzaw4Ww0ftEx4"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmfp0EeYj0Aseds",
        "row": 5730,
        "state": "ARIZONA",
        "group": "004007870HBAG00",
        "group_num": [
            "recQSEwZx8cls9jqQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 580,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOHbRbOMsoj6ABq",
        "row": 5727,
        "state": "ARIZONA",
        "group": "40828A",
        "group_num": [
            "recgFS92JDd0luxMM"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 29,
        "2021": 19,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOmZUD9jZJHR4FU",
        "row": 5809,
        "state": "ARIZONA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1941,
        "2024": 0,
        "id": "recQwULUVcKOEYOsw",
        "row": 5820,
        "state": "ARIZONA",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1831,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQI91Af1zJB5ltU",
        "row": 5738,
        "state": "ARIZONA",
        "group": "834017300",
        "group_num": [
            "recHlRrTaELB9Dinb"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQNb2Et6N7bvCbF",
        "row": 5745,
        "state": "ARIZONA",
        "group": "277727M650",
        "group_num": [
            "reck4IbO1irv0Fk8S"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 74,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSBtQ8P7fpZCbrQ",
        "row": 5759,
        "state": "ARIZONA",
        "group": "152342",
        "group_num": [
            "rec1Lzaw4Ww0ftEx4"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 97,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTmlzHtHaITw8UX",
        "row": 5736,
        "state": "ARIZONA",
        "group": "1000073",
        "group_num": [
            "rec6k1quFcGTPyinC"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1246,
        "2023": 0,
        "2024": 0,
        "id": "recUbmmLVgYST6iXP",
        "row": 5784,
        "state": "ARIZONA",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 218,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX1f9Fprq9QBvQU",
        "row": 5797,
        "state": "ARIZONA",
        "group": "019802",
        "group_num": [
            "recdewhV02Vbq6i3G"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recZDSLdZkoiht1yz",
        "row": 5773,
        "state": "ARIZONA",
        "group": "854328",
        "group_num": [
            "recrPpUAzYKKqQaPA"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "2023": 0,
        "2024": 0,
        "id": "recZoF3eL10NXeqON",
        "row": 5781,
        "state": "ARIZONA",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0AgVvBya4ulxyA",
        "row": 5770,
        "state": "ARIZONA",
        "group": "081893",
        "group_num": [
            "recQ0msGr8ggiQs4Z"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec050tYAcB5k3Ldu",
        "row": 5790,
        "state": "ARIZONA",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1325,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0jE8HTqFi66HJQ",
        "row": 5765,
        "state": "ARIZONA",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rec1FWOoyiWHff5ed",
        "row": 5779,
        "state": "ARIZONA",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1354,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1l5yHK8u0K2o01",
        "row": 5804,
        "state": "ARIZONA",
        "group": "696978",
        "group_num": [
            "recUB12GkAVTQSU5w"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 455,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec21PdxYoXljv639",
        "row": 5752,
        "state": "ARIZONA",
        "group": "169289",
        "group_num": [
            "recjS7mTUh1B872Cp"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2iWmWXTgcoQr8W",
        "row": 5789,
        "state": "ARIZONA",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 375,
        "2023": 0,
        "2024": 0,
        "id": "rec42fqNoTvvQHECE",
        "row": 5782,
        "state": "ARIZONA",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1803,
        "2023": 0,
        "2024": 0,
        "id": "rec5VtaCxEEVWIhaj",
        "row": 5802,
        "state": "ARIZONA",
        "group": "270213OFM7",
        "group_num": [
            "recIJoisaNJEihlZN"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec51IRYtqQ8jgZmQ",
        "row": 5761,
        "state": "ARIZONA",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 205,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5aG4rTeYAxnome",
        "row": 5796,
        "state": "ARIZONA",
        "group": "019802",
        "group_num": [
            "recdewhV02Vbq6i3G"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6lD9vw8a1teAXS",
        "row": 5798,
        "state": "ARIZONA",
        "group": "019802",
        "group_num": [
            "recdewhV02Vbq6i3G"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1942,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7rqkOXLrgPJG2P",
        "row": 5742,
        "state": "ARIZONA",
        "group": "212069M3AF",
        "group_num": [
            "recxGqHusBP8hjAbr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1778,
        "2023": 857,
        "2024": 0,
        "id": "rec8uUWEaxmq9HDNl",
        "row": 5818,
        "state": "ARIZONA",
        "group": "759645",
        "group_num": [
            "rec2Zft8phm738MOQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec8khd37NXmezp67",
        "row": 5726,
        "state": "ARIZONA",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "rec8rCqW3HSifR60l",
        "row": 5806,
        "state": "ARIZONA",
        "group": "000USX834AAAO101",
        "group_num": [
            "recpBV3m4V8bEgaaH"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 695,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec98LTGGpUiaSq2n",
        "row": 5764,
        "state": "ARIZONA",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 167,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9bqla8LPH6Isuj",
        "row": 5775,
        "state": "ARIZONA",
        "group": "213026M1FA",
        "group_num": [
            "recOCkh23mJAYVVlt"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rec9pfDnjAlYJXaTG",
        "row": 5767,
        "state": "ARIZONA",
        "group": "10261545",
        "group_num": [
            "recJIOAspCPpIGpdL"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 876,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbnBvdWNuubRnR6",
        "row": 5751,
        "state": "ARIZONA",
        "group": "169289",
        "group_num": [
            "recjS7mTUh1B872Cp"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2138,
        "2023": 1499,
        "2024": 0,
        "id": "recc1FZxqTNTWGz97",
        "row": 5819,
        "state": "ARIZONA",
        "group": "759645",
        "group_num": [
            "rec2Zft8phm738MOQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1802,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc6tlxBxnhWNp9j",
        "row": 5733,
        "state": "ARIZONA",
        "group": "003330153",
        "group_num": [
            "rec97gK4l5nJLPaTv"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recda4617qkUkqibp",
        "row": 5785,
        "state": "ARIZONA",
        "group": "147399041",
        "group_num": [
            "recllr3LGUfHBPkbc"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 376,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recomiLqR6cKxV8Kn",
        "row": 5792,
        "state": "ARIZONA",
        "group": "4002747",
        "group_num": [
            "recoYHldKILflZT2r"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1257,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpPL7uDzFLooLuE",
        "row": 5801,
        "state": "ARIZONA",
        "group": "012801",
        "group_num": [
            "recciYyRd3SLqJG5h"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 140,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpkeCKPX4jf2kFc",
        "row": 5808,
        "state": "ARIZONA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 80,
        "2022": 51,
        "2023": 0,
        "2024": 0,
        "id": "recpooieIezcBReo9",
        "row": 5737,
        "state": "ARIZONA",
        "group": "1000073",
        "group_num": [
            "rec6k1quFcGTPyinC"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 159,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqyKVV9lVcybkEi",
        "row": 5739,
        "state": "ARIZONA",
        "group": "834017300",
        "group_num": [
            "recHlRrTaELB9Dinb"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2889,
        "2023": 0,
        "2024": 0,
        "id": "recqJYgDi4gjsCOnb",
        "row": 5725,
        "state": "ARIZONA",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 0,
        "2024": 0,
        "id": "recqbXFYpcPUBmi3j",
        "row": 5812,
        "state": "ARIZONA",
        "group": "021166",
        "group_num": [
            "recNWgJcgBSvC2zmN"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 790,
        "2022": 272,
        "2023": 0,
        "2024": 0,
        "id": "recrWJZm0CpSiu1gF",
        "row": 5729,
        "state": "ARIZONA",
        "group": "40828A",
        "group_num": [
            "recgFS92JDd0luxMM"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recraqT6Fkm2j2VYv",
        "row": 5768,
        "state": "ARIZONA",
        "group": "10261545",
        "group_num": [
            "recJIOAspCPpIGpdL"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "recsU55IKkR9ZSwKS",
        "row": 5769,
        "state": "ARIZONA",
        "group": "10261545",
        "group_num": [
            "recJIOAspCPpIGpdL"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 747,
        "2021": 640,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsDiIm5VQpicSEr",
        "row": 5722,
        "state": "ARIZONA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1938,
        "2023": 0,
        "2024": 0,
        "id": "recspYtzvF6cqfWkT",
        "row": 5799,
        "state": "ARIZONA",
        "group": "C20879M6A1",
        "group_num": [
            "recJoqBZeLp1W9Ddm"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1764,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectdcSH4FASxGBjR",
        "row": 5788,
        "state": "ARIZONA",
        "group": "003320332A1HP033",
        "group_num": [
            "reciY3VrjNwoZlKdS"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 3579,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu4MokV4hE1HabX",
        "row": 5817,
        "state": "ARIZONA",
        "group": "210276",
        "group_num": [
            "recQCAR9QsgmdT9ah"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 470,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvq8oWu4UmLrmaK",
        "row": 5821,
        "state": "ARIZONA",
        "group": "10006146",
        "group_num": [
            "recc7BZEWY6ok4hhF"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 133,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwsh56Bru7yPGTH",
        "row": 5795,
        "state": "ARIZONA",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1286,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recw7gN9OW6UsshXB",
        "row": 5756,
        "state": "ARIZONA",
        "group": "152342",
        "group_num": [
            "rec1Lzaw4Ww0ftEx4"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 487,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxd1RFKPQjFMY1C",
        "row": 5749,
        "state": "ARIZONA",
        "group": "192635",
        "group_num": [
            "recC1CuxbB1GbRTId"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 1486,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyPPol72pVw3N5q",
        "row": 5787,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4350,
        "2024": 0,
        "id": "recz4esczm1gA8sN6",
        "row": 5743,
        "state": "ARIZONA",
        "group": "014228",
        "group_num": [
            "recEh0j45yIUFXwOc"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 259,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczaidR83uFxTOQ2",
        "row": 5735,
        "state": "ARIZONA",
        "group": "1000073",
        "group_num": [
            "rec6k1quFcGTPyinC"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1831,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczoggwgnM4X2DRV",
        "row": 5774,
        "state": "ARIZONA",
        "group": "213026M1FA",
        "group_num": [
            "recOCkh23mJAYVVlt"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 644,
        "2023": 0,
        "2024": 0,
        "id": "recAyghMoKH8yppBI",
        "row": 5810,
        "state": "ARIZONA",
        "group": "9524533",
        "group_num": [
            "recL8fJY0kplPoSnC"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recArC5BTD7d4KAt6",
        "row": 5762,
        "state": "ARIZONA",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recBYnl6RSu0p4p4m",
        "row": 5731,
        "state": "ARIZONA",
        "group": "W24174M109",
        "group_num": [
            "rech1TTdnEHbtNjnv"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 270,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBNrelqlKztKrs8",
        "row": 5811,
        "state": "ARIZONA",
        "group": "10012581",
        "group_num": [
            "recMSQIjEPMyTjshX"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1186,
        "2023": 0,
        "2024": 0,
        "id": "recDjA85Ik5PQQe3G",
        "row": 5783,
        "state": "ARIZONA",
        "group": "107319042",
        "group_num": [
            "recMsa4bcZ65HoYaS"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "recGsfYH5Mq4kKfwE",
        "row": 5746,
        "state": "ARIZONA",
        "group": "277727M716",
        "group_num": [
            "recn4BqXa7Ql8jaGz"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 390,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGjepwkRDwYBlXT",
        "row": 5771,
        "state": "ARIZONA",
        "group": "854325",
        "group_num": [
            "rec8qbsuDOSRmoUxF"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 490,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHwoCRBWMYKaY2U",
        "row": 5791,
        "state": "ARIZONA",
        "group": "4002747",
        "group_num": [
            "recoYHldKILflZT2r"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHqhjiVVRGE1xew",
        "row": 5732,
        "state": "ARIZONA",
        "group": "P88833",
        "group_num": [
            "recQeeAunDQBLbNZB"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1510,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIYwYc92zwxDrAB",
        "row": 5815,
        "state": "ARIZONA",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 27,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIvuqcClNTA0wQP",
        "row": 5753,
        "state": "ARIZONA",
        "group": "169289",
        "group_num": [
            "recjS7mTUh1B872Cp"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 270,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recI8jqtimut68ceW",
        "row": 5748,
        "state": "ARIZONA",
        "group": "277727P292",
        "group_num": [
            "recPwmdqvM3efwdXL"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1050,
        "2021": 1005,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJTaIfBCg1x7bm7",
        "row": 5758,
        "state": "ARIZONA",
        "group": "152342",
        "group_num": [
            "rec1Lzaw4Ww0ftEx4"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJ5ZcC3hEvJQpVJ",
        "row": 5734,
        "state": "ARIZONA",
        "group": "003330153",
        "group_num": [
            "rec97gK4l5nJLPaTv"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJeeeaUqO7YcC9z",
        "row": 5763,
        "state": "ARIZONA",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 170,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLJeQWOjBG2u4CS",
        "row": 5813,
        "state": "ARIZONA",
        "group": "213025M2AP",
        "group_num": [
            "recGzsTxL59Z2VX3G"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 198,
        "2021": 105,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recMvYxJHF40YVjCh",
        "row": 5807,
        "state": "ARIZONA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1041,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNJ8t49cvul4D60",
        "row": 5800,
        "state": "ARIZONA",
        "group": "012801",
        "group_num": [
            "recciYyRd3SLqJG5h"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1204,
        "2023": 0,
        "2024": 0,
        "id": "recNmvvKDNLpZCOl7",
        "row": 5755,
        "state": "ARIZONA",
        "group": "178860",
        "group_num": [
            "reciLST5MJfJXgr9Z"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "receQqJH4lIUlzTk6",
        "row": 5863,
        "state": "ARIZONA",
        "group": "W0070120",
        "group_num": [
            "recjhE70wbCReFM2j"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recebnveePOIrlWwF",
        "row": 5833,
        "state": "ARIZONA",
        "group": "034416",
        "group_num": [
            "recjqgz6BdyfYASaH"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "2023": 0,
        "2024": 0,
        "id": "recfnZqEQApLxaNCV",
        "row": 5832,
        "state": "ARIZONA",
        "group": "036436",
        "group_num": [
            "rec00VQjXqaaosjBD"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgXt0eCIGcpQYMp",
        "row": 5837,
        "state": "ARIZONA",
        "group": "0HS006",
        "group_num": [
            "recv1HnqK2xJAVlAJ"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci3n37yFUMsnGmY",
        "row": 5846,
        "state": "ARIZONA",
        "group": "039912",
        "group_num": [
            "recW9zLRbAgDrj4Jb"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjH0fAortEWtuAK",
        "row": 5825,
        "state": "ARIZONA",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 2500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckB3jtLo0wlpvdu",
        "row": 5824,
        "state": "ARIZONA",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "recknmUdV1Us8TxT3",
        "row": 5855,
        "state": "ARIZONA",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 182,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclTlAoZWAf6nLgz",
        "row": 5866,
        "state": "ARIZONA",
        "group": "W0093718",
        "group_num": [
            "rec8KWEotxnoDhrX7"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 178,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnApQ3kiwwjGxSd",
        "row": 5851,
        "state": "ARIZONA",
        "group": "175075M165",
        "group_num": [
            "recwWRDeqCSnYu3cA"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 299,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnDIXbtGaP1jNAv",
        "row": 5869,
        "state": "ARIZONA",
        "group": "20YY",
        "group_num": [
            "rec1xgH8q7CXuEELH"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPnhCi7xv6YnQwh",
        "row": 5829,
        "state": "ARIZONA",
        "group": "031574",
        "group_num": [
            "recnflj5WxFHJlnaE"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQWzT51u3gOvng6",
        "row": 5828,
        "state": "ARIZONA",
        "group": "030408",
        "group_num": [
            "rec2HV9gaAfHDBEVO"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 146,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR7r7oAwmww2ZPA",
        "row": 5868,
        "state": "ARIZONA",
        "group": "20YY",
        "group_num": [
            "rec1xgH8q7CXuEELH"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 350,
        "2024": 0,
        "id": "recUrt7iRkiHj9QWG",
        "row": 5849,
        "state": "ARIZONA",
        "group": "10421505",
        "group_num": [
            "recgI7uaU06Gwcveq"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 692,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recW5zwFL7xtOqYdV",
        "row": 5853,
        "state": "ARIZONA",
        "group": "281267M004",
        "group_num": [
            "recU8O5gHKWEyLmCi"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXHu8Pv1J9h7j4Q",
        "row": 5845,
        "state": "ARIZONA",
        "group": "039912",
        "group_num": [
            "recW9zLRbAgDrj4Jb"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 742,
        "2023": 0,
        "2024": 0,
        "id": "recYDrVtRCdAskqn7",
        "row": 5826,
        "state": "ARIZONA",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Ae1eMUyAGb8Vl",
        "row": 5861,
        "state": "ARIZONA",
        "group": "W0039873",
        "group_num": [
            "recX0UF30Y59FRLXh"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 37,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7BllMUTA0pu8sE",
        "row": 5862,
        "state": "ARIZONA",
        "group": "W0044145",
        "group_num": [
            "recsIoEQGxAoFzocv"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7FOHe0ThDcrrEn",
        "row": 5841,
        "state": "ARIZONA",
        "group": "028746",
        "group_num": [
            "recNrjWaBKSoYwmSw"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 36,
        "2021": 27,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec78rhmQO5d6IfYE",
        "row": 5836,
        "state": "ARIZONA",
        "group": "0ESS60",
        "group_num": [
            "recbUwvw222uGqsFP"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8tQuvzAWe0QPGq",
        "row": 5850,
        "state": "ARIZONA",
        "group": "175075M165",
        "group_num": [
            "recwWRDeqCSnYu3cA"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recaqn933FH31yQa9",
        "row": 5857,
        "state": "ARIZONA",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb9rdHhF8OYJyxa",
        "row": 5867,
        "state": "ARIZONA",
        "group": "W0096758",
        "group_num": [
            "recs191w1hNUQ4AVU"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 261,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccWPGle10754mM3",
        "row": 5840,
        "state": "ARIZONA",
        "group": "028746",
        "group_num": [
            "recNrjWaBKSoYwmSw"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd0gEj3UdDNSqaP",
        "row": 5834,
        "state": "ARIZONA",
        "group": "0ESS60",
        "group_num": [
            "recbUwvw222uGqsFP"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 141,
        "2024": 0,
        "id": "recpgjEZ1qgRaN50F",
        "row": 5858,
        "state": "ARIZONA",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recqar2eKxfGkeqMh",
        "row": 5864,
        "state": "ARIZONA",
        "group": "W0070120",
        "group_num": [
            "recjhE70wbCReFM2j"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrKLrfmVi9E4Wj7",
        "row": 5848,
        "state": "ARIZONA",
        "group": "OVAL22",
        "group_num": [
            "recnJVtWHj64cGqtD"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr5XoXiTdHulAX3",
        "row": 5865,
        "state": "ARIZONA",
        "group": "W0093718",
        "group_num": [
            "rec8KWEotxnoDhrX7"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recsKoIEEqyiUoZNi",
        "row": 5843,
        "state": "ARIZONA",
        "group": "034873",
        "group_num": [
            "recpJy9He11B00Afx"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 88,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsgErX2gOqGc4TA",
        "row": 5823,
        "state": "ARIZONA",
        "group": "10006146",
        "group_num": [
            "recc7BZEWY6ok4hhF"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "2023": 0,
        "2024": 0,
        "id": "recvOEotzyPYQvwzK",
        "row": 5859,
        "state": "ARIZONA",
        "group": "282229M001",
        "group_num": [
            "recYRNfRcMKKK9Zig"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recwtw4TNBNqnbw5m",
        "row": 5856,
        "state": "ARIZONA",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxDcqELHMxx3zXv",
        "row": 5830,
        "state": "ARIZONA",
        "group": "031574",
        "group_num": [
            "recnflj5WxFHJlnaE"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 304,
        "2021": 388,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczmyDtXY9Fz8MMX",
        "row": 5822,
        "state": "ARIZONA",
        "group": "10006146",
        "group_num": [
            "recc7BZEWY6ok4hhF"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 154,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczoclfLHDI7rdB4",
        "row": 5835,
        "state": "ARIZONA",
        "group": "0ESS60",
        "group_num": [
            "recbUwvw222uGqsFP"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCrtEtnQrRlmNLN",
        "row": 5838,
        "state": "ARIZONA",
        "group": "0HS006",
        "group_num": [
            "recv1HnqK2xJAVlAJ"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recDXNG03wKH0eGR1",
        "row": 5860,
        "state": "ARIZONA",
        "group": "282229M001",
        "group_num": [
            "recYRNfRcMKKK9Zig"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1824,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDDeN7VhNafSYTg",
        "row": 5854,
        "state": "ARIZONA",
        "group": "281267M004",
        "group_num": [
            "recU8O5gHKWEyLmCi"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKg01Yyhl6sQqVn",
        "row": 5871,
        "state": "ARIZONA",
        "group": "A07848",
        "group_num": [
            "rectGSAMf1dabAuu7"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1260,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMyXPHsD2ExSV7T",
        "row": 5827,
        "state": "ARIZONA",
        "group": "10279511",
        "group_num": [
            "recyYSpJsvRfUnUlj"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recMecissTLvtuZfq",
        "row": 5831,
        "state": "ARIZONA",
        "group": "036436",
        "group_num": [
            "rec00VQjXqaaosjBD"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNS8z4OAPjDmjiX",
        "row": 5852,
        "state": "ARIZONA",
        "group": "175075M165",
        "group_num": [
            "recwWRDeqCSnYu3cA"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNjlszKTrGIM6KK",
        "row": 5844,
        "state": "ARIZONA",
        "group": "039488",
        "group_num": [
            "recFbPqdJwhnZbkLj"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf89IhfZRo9HHW2",
        "row": 5918,
        "state": "ARIZONA",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 285,
        "2024": 0,
        "id": "recfj8jwuiR9QDHKP",
        "row": 5873,
        "state": "ARIZONA",
        "group": "9976721",
        "group_num": [
            "recCa0sKC4JgQOeJp"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1366,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgti7qIxE54PbUQ",
        "row": 5908,
        "state": "ARIZONA",
        "group": "PB4307",
        "group_num": [
            "rec9OKrClLUQ1mVNF"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 78,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech21a9AyeFIHjGS",
        "row": 5879,
        "state": "ARIZONA",
        "group": "096451",
        "group_num": [
            "recMPKv3YZBaQbJrP"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rechaqWhz3YC9WGj5",
        "row": 5958,
        "state": "ARIZONA",
        "group": "L03775M001",
        "group_num": [
            "recb8Jeqpp6DPnBis"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "reciVUoxwF7tWXVHt",
        "row": 5933,
        "state": "ARIZONA",
        "group": "138690",
        "group_num": [
            "recHmTFyFb2z4buVT"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "recj0HUQyS2BqRDLN",
        "row": 5925,
        "state": "ARIZONA",
        "group": "035106",
        "group_num": [
            "rec7VP4n5zPOCUXoH"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 127,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjMs5ceXVALqM0y",
        "row": 5920,
        "state": "ARIZONA",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckNeL3oqycn6hC2",
        "row": 5967,
        "state": "ARIZONA",
        "group": "134017",
        "group_num": [
            "recaPNcgvRuPtIyuk"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1827,
        "2021": 1166,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckb2DUgSbBUAGp8",
        "row": 5914,
        "state": "ARIZONA",
        "group": "265402",
        "group_num": [
            "recdK83rw2owlCFWo"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 1082,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclHuNlmqrBX8ueS",
        "row": 5902,
        "state": "ARIZONA",
        "group": "P95278",
        "group_num": [
            "recFLkxSJUHX4lygk"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmfrCAzD873jxoi",
        "row": 5934,
        "state": "ARIZONA",
        "group": "10353824",
        "group_num": [
            "recFGR0wt8U1GZjlp"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmpCJG2jraPW9aW",
        "row": 5953,
        "state": "ARIZONA",
        "group": "A55461",
        "group_num": [
            "recXmkSjlaM28FKE4"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnaYCEpsg911sWn",
        "row": 5889,
        "state": "ARIZONA",
        "group": "P49678",
        "group_num": [
            "recCTT8nIhcfw9Qq2"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP7tanFUZpWxeIP",
        "row": 5952,
        "state": "ARIZONA",
        "group": "A55461",
        "group_num": [
            "recXmkSjlaM28FKE4"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 38,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQvj1E7OSuIasUy",
        "row": 5956,
        "state": "ARIZONA",
        "group": "819501",
        "group_num": [
            "recQdbwmfaNFWLV4d"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 719,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQMSMuPQeH60NDt",
        "row": 5942,
        "state": "ARIZONA",
        "group": "199661",
        "group_num": [
            "recOgFpZS4GdIVAQg"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 584,
        "2023": 0,
        "2024": 0,
        "id": "recREkhTKu4kyAIbU",
        "row": 5886,
        "state": "ARIZONA",
        "group": "289804",
        "group_num": [
            "recGu8PME55zU1V1I"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "recRgtPhisS7M5ZB1",
        "row": 5946,
        "state": "ARIZONA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTJgd5u30mrrtTK",
        "row": 5965,
        "state": "ARIZONA",
        "group": "931891992",
        "group_num": [
            "receGcIwEQBPRPg1H"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 153,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTQMo9iyXAn7YcI",
        "row": 5875,
        "state": "ARIZONA",
        "group": "183532",
        "group_num": [
            "recHXmEN0NBhQr9N0"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "2024": 0,
        "id": "recV4fZRfRFa2Jo15",
        "row": 5872,
        "state": "ARIZONA",
        "group": "9976721",
        "group_num": [
            "recCa0sKC4JgQOeJp"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recWlVEz58QMueX0w",
        "row": 5932,
        "state": "ARIZONA",
        "group": "138690",
        "group_num": [
            "recHmTFyFb2z4buVT"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 698,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX5jg13EooT0ZTa",
        "row": 5877,
        "state": "ARIZONA",
        "group": "096451",
        "group_num": [
            "recMPKv3YZBaQbJrP"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1733,
        "2022": 1875,
        "2023": 0,
        "2024": 0,
        "id": "recYuMrqsnCtbiWQ2",
        "row": 5915,
        "state": "ARIZONA",
        "group": "265402",
        "group_num": [
            "recdK83rw2owlCFWo"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYEos9yodlXp337",
        "row": 5931,
        "state": "ARIZONA",
        "group": "198003",
        "group_num": [
            "recuM7GGqzXe4LltU"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 1809,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0VcWr366X9SFqh",
        "row": 5957,
        "state": "ARIZONA",
        "group": "W12600M001",
        "group_num": [
            "recRxGiPHwFE07WFr"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec19zf6VjwEvaMTj",
        "row": 5936,
        "state": "ARIZONA",
        "group": "00538N80002",
        "group_num": [
            "rec3O3tACRPUy3Mse"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1250,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1qaBQpcDu6U8k6",
        "row": 5884,
        "state": "ARIZONA",
        "group": "253473",
        "group_num": [
            "recXsWa0qaJz9Utsz"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 196,
        "2023": 0,
        "2024": 0,
        "id": "rec3FpwsLkq4BP1y0",
        "row": 5944,
        "state": "ARIZONA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3FvPd42zDtCr12",
        "row": 5970,
        "state": "ARIZONA",
        "group": "101955",
        "group_num": [
            "recvK881tFwuqmP8F"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 113,
        "2023": 0,
        "2024": 0,
        "id": "rec4VFXviQX4lGh4A",
        "row": 5945,
        "state": "ARIZONA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 893,
        "2021": 1096,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5Yf13npgmTyX6i",
        "row": 5903,
        "state": "ARIZONA",
        "group": "P95278",
        "group_num": [
            "recFLkxSJUHX4lygk"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 4687,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec51UPMPR2wPJoMA",
        "row": 5950,
        "state": "ARIZONA",
        "group": "10348744",
        "group_num": [
            "recAyUy2AGcFkUV9s"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 1754,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5CkBtlCYwShadl",
        "row": 5905,
        "state": "ARIZONA",
        "group": "PA0437",
        "group_num": [
            "rechBNkFF20v6kjG1"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 354,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5lMSvjIAlu6Ene",
        "row": 5910,
        "state": "ARIZONA",
        "group": "PG0846",
        "group_num": [
            "rec4G7UooYQdd3Iu4"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 150,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7GKuU3vhIuBUFW",
        "row": 5894,
        "state": "ARIZONA",
        "group": "P56630",
        "group_num": [
            "recEoAsKzsXPgTuGr"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1509,
        "2023": 0,
        "2024": 0,
        "id": "rec7O6rieDl4IvN5d",
        "row": 5892,
        "state": "ARIZONA",
        "group": "P56630",
        "group_num": [
            "recEoAsKzsXPgTuGr"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 38,
        "2022": 38,
        "2023": 0,
        "2024": 0,
        "id": "rec7q6g3Bikck32uv",
        "row": 5929,
        "state": "ARIZONA",
        "group": "004009959EEAG00",
        "group_num": [
            "recVyY1lx6udMsnxj"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 800,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9JtcCL0vahciwz",
        "row": 5900,
        "state": "ARIZONA",
        "group": "P86125",
        "group_num": [
            "recc3kYJBLOuueNxg"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaDQmg6b5T0s3ZX",
        "row": 5878,
        "state": "ARIZONA",
        "group": "096451",
        "group_num": [
            "recMPKv3YZBaQbJrP"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recdUBEG7XhGhedPX",
        "row": 5962,
        "state": "ARIZONA",
        "group": "002030610",
        "group_num": [
            "rec5smU1jHB6b77uE"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 444,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco6YGam93QhEP1S",
        "row": 5969,
        "state": "ARIZONA",
        "group": "101955",
        "group_num": [
            "recvK881tFwuqmP8F"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 309,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqaTHyjH9SCVBs6",
        "row": 5874,
        "state": "ARIZONA",
        "group": "183532",
        "group_num": [
            "recHXmEN0NBhQr9N0"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "recqqfRNgLTk9Wnet",
        "row": 5959,
        "state": "ARIZONA",
        "group": "L03775M011",
        "group_num": [
            "recbxk086jOv9rpH4"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr0KuptIYWRR6BR",
        "row": 5937,
        "state": "ARIZONA",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrtajKvxPgTVgVH",
        "row": 5919,
        "state": "ARIZONA",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 0,
        "2024": 0,
        "id": "recrMhzgQXh9M2ASg",
        "row": 5883,
        "state": "ARIZONA",
        "group": "231189",
        "group_num": [
            "rec4m6NNfqbrSc54D"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 132,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrh9QuF2hTxmYwI",
        "row": 5939,
        "state": "ARIZONA",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "rect0o6ZVpk6am8NG",
        "row": 5916,
        "state": "ARIZONA",
        "group": "299102",
        "group_num": [
            "recWiKR1dSemsz9jS"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "2024": 0,
        "id": "rects9Y9aZBi0Pa0h",
        "row": 5896,
        "state": "ARIZONA",
        "group": "P58472",
        "group_num": [
            "recMyAo6mVcJU63tt"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1179,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectyFw79n3Oiid37",
        "row": 5935,
        "state": "ARIZONA",
        "group": "00538N800",
        "group_num": [
            "recY14cNpnZqjNSOe"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 478,
        "2023": 0,
        "2024": 0,
        "id": "rectMPq7CQCgZ5xR4",
        "row": 5881,
        "state": "ARIZONA",
        "group": "231189",
        "group_num": [
            "rec4m6NNfqbrSc54D"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "2024": 0,
        "id": "rectet3vQQky2URwS",
        "row": 5922,
        "state": "ARIZONA",
        "group": "00281551",
        "group_num": [
            "rec9k4XBMkWrLtOKq"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 317,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuydcT7aQzi1z9C",
        "row": 5906,
        "state": "ARIZONA",
        "group": "PA3052",
        "group_num": [
            "recirM7uTLhONTUMs"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1124,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuBPV06x0QIldpl",
        "row": 5912,
        "state": "ARIZONA",
        "group": "0M7014",
        "group_num": [
            "recKyN6xfi8uCda1K"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 151,
        "2023": 0,
        "2024": 0,
        "id": "recuPcsQygk2OPpk9",
        "row": 5923,
        "state": "ARIZONA",
        "group": "00281551",
        "group_num": [
            "rec9k4XBMkWrLtOKq"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 182,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu3OrEmnVLCT71z",
        "row": 5907,
        "state": "ARIZONA",
        "group": "PB4307",
        "group_num": [
            "rec9OKrClLUQ1mVNF"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 678,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvz1mKsxViJR8pm",
        "row": 5890,
        "state": "ARIZONA",
        "group": "P49678",
        "group_num": [
            "recCTT8nIhcfw9Qq2"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwBrMoEn6NYGQO2",
        "row": 5948,
        "state": "ARIZONA",
        "group": "082131",
        "group_num": [
            "recBs3Uj8z8M9tYiO"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recySni4xAamWTPfN",
        "row": 5930,
        "state": "ARIZONA",
        "group": "198003",
        "group_num": [
            "recuM7GGqzXe4LltU"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 275,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz463VkOfPDd7kF",
        "row": 5885,
        "state": "ARIZONA",
        "group": "253473",
        "group_num": [
            "recXsWa0qaJz9Utsz"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBO2evnC8GR2EWo",
        "row": 5964,
        "state": "ARIZONA",
        "group": "931891992",
        "group_num": [
            "receGcIwEQBPRPg1H"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recB2ctfTEBhYgrGJ",
        "row": 5924,
        "state": "ARIZONA",
        "group": "035106",
        "group_num": [
            "rec7VP4n5zPOCUXoH"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recC0U0t6MHJ75q2R",
        "row": 5895,
        "state": "ARIZONA",
        "group": "P58472",
        "group_num": [
            "recMyAo6mVcJU63tt"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 795,
        "2024": 0,
        "id": "recCGziMZYTKwxq1V",
        "row": 5897,
        "state": "ARIZONA",
        "group": "P58472",
        "group_num": [
            "recMyAo6mVcJU63tt"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1074,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC6A0m79Trg952r",
        "row": 5911,
        "state": "ARIZONA",
        "group": "0M7014",
        "group_num": [
            "recKyN6xfi8uCda1K"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 0,
        "2024": 0,
        "id": "recCgGKt8CXxCUXZn",
        "row": 5960,
        "state": "ARIZONA",
        "group": "L03775M011",
        "group_num": [
            "recbxk086jOv9rpH4"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEeI4h6sIFNUply",
        "row": 5947,
        "state": "ARIZONA",
        "group": "082131",
        "group_num": [
            "recBs3Uj8z8M9tYiO"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 78,
        "2021": 69,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEk71CCIfjwbpCR",
        "row": 5943,
        "state": "ARIZONA",
        "group": "199661",
        "group_num": [
            "recOgFpZS4GdIVAQg"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 141,
        "2024": 0,
        "id": "recFoRqisYHFLoVHU",
        "row": 5961,
        "state": "ARIZONA",
        "group": "L03775M011",
        "group_num": [
            "recbxk086jOv9rpH4"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 1112,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGOhEsUYtAtw5HC",
        "row": 5941,
        "state": "ARIZONA",
        "group": "199661",
        "group_num": [
            "recOgFpZS4GdIVAQg"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 157,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGgmOyy1IN0vZbu",
        "row": 5928,
        "state": "ARIZONA",
        "group": "004009959EEAG00",
        "group_num": [
            "recVyY1lx6udMsnxj"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 291,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHKadLzHhwO5Aqa",
        "row": 5938,
        "state": "ARIZONA",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 101,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHMINLGDPzONlWJ",
        "row": 5971,
        "state": "ARIZONA",
        "group": "026391",
        "group_num": [
            "rec1aQu1NYtCmmRLX"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 219,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH5mVdOoMaDlShM",
        "row": 5917,
        "state": "ARIZONA",
        "group": "96301",
        "group_num": [
            "recO0vVEjVxnlmNvJ"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 490,
        "2023": 0,
        "2024": 0,
        "id": "recHa8gGOdkyRCHXs",
        "row": 5887,
        "state": "ARIZONA",
        "group": "P31479",
        "group_num": [
            "recXohoAmVbzl3A5o"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recHn2kv1VryJhGBt",
        "row": 5882,
        "state": "ARIZONA",
        "group": "231189",
        "group_num": [
            "rec4m6NNfqbrSc54D"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIckrGbZDQElPUb",
        "row": 5951,
        "state": "ARIZONA",
        "group": "A55461",
        "group_num": [
            "recXmkSjlaM28FKE4"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recJwd4gSN7idBbJK",
        "row": 5888,
        "state": "ARIZONA",
        "group": "P31479",
        "group_num": [
            "recXohoAmVbzl3A5o"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 387,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJctg00d7VET7ng",
        "row": 5963,
        "state": "ARIZONA",
        "group": "002030832",
        "group_num": [
            "reckqxVRvOcZIuKwS"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 38,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKVaXZQqXEGLZxJ",
        "row": 5940,
        "state": "ARIZONA",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 297,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKX9nm27Erm4tqx",
        "row": 5927,
        "state": "ARIZONA",
        "group": "004009959EEAG00",
        "group_num": [
            "recVyY1lx6udMsnxj"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 1048,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLCqxYFXdbb8UdU",
        "row": 5899,
        "state": "ARIZONA",
        "group": "P86125",
        "group_num": [
            "recc3kYJBLOuueNxg"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "recMTbt49XFrwglvq",
        "row": 5926,
        "state": "ARIZONA",
        "group": "035106",
        "group_num": [
            "rec7VP4n5zPOCUXoH"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 165,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMOPbrY92CyPBON",
        "row": 5955,
        "state": "ARIZONA",
        "group": "819501",
        "group_num": [
            "recQdbwmfaNFWLV4d"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 275,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNZKMgQACFzgkMS",
        "row": 5893,
        "state": "ARIZONA",
        "group": "P56630",
        "group_num": [
            "recEoAsKzsXPgTuGr"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 36,
        "2021": 79,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNvJr6B7qonx4Kv",
        "row": 5901,
        "state": "ARIZONA",
        "group": "P86125",
        "group_num": [
            "recc3kYJBLOuueNxg"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 360,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNEd7WyusYeSatm",
        "row": 5898,
        "state": "ARIZONA",
        "group": "P65298",
        "group_num": [
            "recjyT5tWumYIHeEj"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 1827,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNOjT4HPLEyRydq",
        "row": 5913,
        "state": "ARIZONA",
        "group": "265402",
        "group_num": [
            "recdK83rw2owlCFWo"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "receugIUXgHB9UFgN",
        "row": 6001,
        "state": "ARIZONA",
        "group": "212044M1DA",
        "group_num": [
            "rec4kUD4xl9FYHeQ0"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "reci75D3SBlgVgepn",
        "row": 6002,
        "state": "ARIZONA",
        "group": "212044M1DA",
        "group_num": [
            "rec4kUD4xl9FYHeQ0"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "2024": 0,
        "id": "recil1NYQhfK9504N",
        "row": 5997,
        "state": "ARIZONA",
        "group": "A05621",
        "group_num": [
            "reckWUQLtmk4affuf"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 732,
        "2023": 1000,
        "2024": 0,
        "id": "recj6dFLN84u14bVY",
        "row": 5978,
        "state": "ARIZONA",
        "group": "249572",
        "group_num": [
            "recOeZFSggL7pNbod"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 175,
        "2022": 784,
        "2023": 0,
        "2024": 0,
        "id": "reck2p1MxgYJnZ4MO",
        "row": 5980,
        "state": "ARIZONA",
        "group": "256462",
        "group_num": [
            "rec8JjkUCfxvv22AP"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "reclp3d8QWVMmn6qE",
        "row": 5999,
        "state": "ARIZONA",
        "group": "A05621",
        "group_num": [
            "reckWUQLtmk4affuf"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmysz8uUTj47E5K",
        "row": 6000,
        "state": "ARIZONA",
        "group": "00970849",
        "group_num": [
            "recYsgylt5qbG96XB"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS8XTFi8mZ1JFEm",
        "row": 6006,
        "state": "ARIZONA",
        "group": "6025351000",
        "group_num": [
            "recQs7qFbS3Eckyq7"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 0,
        "2024": 0,
        "id": "rec2BpVmokGvcpWsc",
        "row": 5977,
        "state": "ARIZONA",
        "group": "249572",
        "group_num": [
            "recOeZFSggL7pNbod"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 230,
        "2024": 0,
        "id": "rec2iP9F53S5T2XOG",
        "row": 6003,
        "state": "ARIZONA",
        "group": "4024378",
        "group_num": [
            "recmTK6FsmoeeHhvs"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 141,
        "2024": 0,
        "id": "rec4J9InQMXFjm5I7",
        "row": 6004,
        "state": "ARIZONA",
        "group": "4024378",
        "group_num": [
            "recmTK6FsmoeeHhvs"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 183,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec73HKFaIrVp1VGD",
        "row": 5992,
        "state": "ARIZONA",
        "group": "201062M1A1",
        "group_num": [
            "recdYCSCVetrn1hQ6"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 788,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9KZzuLud32bOmd",
        "row": 5979,
        "state": "ARIZONA",
        "group": "256462",
        "group_num": [
            "rec8JjkUCfxvv22AP"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recajUZSKBfxiA8h6",
        "row": 5994,
        "state": "ARIZONA",
        "group": "201062M1A1",
        "group_num": [
            "recdYCSCVetrn1hQ6"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recans39Jzs1Hnz0t",
        "row": 6008,
        "state": "ARIZONA",
        "group": "27008581OA",
        "group_num": [
            "recRngiM8xecRslVC"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "2023": 0,
        "2024": 0,
        "id": "recbukbO7mT564G9o",
        "row": 5996,
        "state": "ARIZONA",
        "group": "10295703",
        "group_num": [
            "recVSSVzmRU5T9cK2"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbJ7g6PdMUaELjt",
        "row": 5995,
        "state": "ARIZONA",
        "group": "09099452",
        "group_num": [
            "rec4TJbhch0jIqtgE"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "2023": 0,
        "2024": 0,
        "id": "reccV7TEh1hJA1Kgj",
        "row": 5976,
        "state": "ARIZONA",
        "group": "249572",
        "group_num": [
            "recOeZFSggL7pNbod"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccPjk1v87mC3R4o",
        "row": 6005,
        "state": "ARIZONA",
        "group": "A81537",
        "group_num": [
            "recNq8aG8PhPkpK2T"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2022,
        "2023": 0,
        "2024": 0,
        "id": "recoVUi45ecAEyR2U",
        "row": 5982,
        "state": "ARIZONA",
        "group": "320088",
        "group_num": [
            "recLALLj59uvsbM92"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recptlbB0wtwdyrFV",
        "row": 5990,
        "state": "ARIZONA",
        "group": "10018742",
        "group_num": [
            "recxfPIOFWaAqQlyR"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "recrD950gr34UAaks",
        "row": 5984,
        "state": "ARIZONA",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 495,
        "2023": 0,
        "2024": 0,
        "id": "recrjelBT7ZNWaiyN",
        "row": 5987,
        "state": "ARIZONA",
        "group": "328754",
        "group_num": [
            "recxkyA1RYrmct5QT"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsNMaENuWT4VWTQ",
        "row": 5989,
        "state": "ARIZONA",
        "group": "10010410",
        "group_num": [
            "recxq2EfG7utohghu"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 154,
        "2021": 108,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectIEeIeIlai4wi5",
        "row": 5972,
        "state": "ARIZONA",
        "group": "026391",
        "group_num": [
            "rec1aQu1NYtCmmRLX"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 964,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy7pwRNCDGTJLM6",
        "row": 6007,
        "state": "ARIZONA",
        "group": "27008581OA",
        "group_num": [
            "recRngiM8xecRslVC"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBj9QZkItwTS14t",
        "row": 5974,
        "state": "ARIZONA",
        "group": "108423",
        "group_num": [
            "rect3o4bGIe5WV7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDCeBEYTjwyl9ET",
        "row": 5988,
        "state": "ARIZONA",
        "group": "4018477",
        "group_num": [
            "recaRQQa3lHKZGx29"
        ],
        "procedure": "RTC",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 0,
        "2024": 0,
        "id": "recD7apPiwKKEgcdh",
        "row": 5998,
        "state": "ARIZONA",
        "group": "A05621",
        "group_num": [
            "reckWUQLtmk4affuf"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 0,
        "2024": 0,
        "id": "recFiDW2qyeq2GT5r",
        "row": 5985,
        "state": "ARIZONA",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 2136,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKgujNlIl9x2zOo",
        "row": 5981,
        "state": "ARIZONA",
        "group": "279761",
        "group_num": [
            "recfopGTdA472QKA6"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 228,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKm1dNgnnCOx3j9",
        "row": 5993,
        "state": "ARIZONA",
        "group": "201062M1A1",
        "group_num": [
            "recdYCSCVetrn1hQ6"
        ],
        "procedure": "IOP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1861,
        "2023": 0,
        "2024": 0,
        "id": "recLD3EIONPCOjSwT",
        "row": 5983,
        "state": "ARIZONA",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "Detox",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1325,
        "2023": 0,
        "2024": 0,
        "id": "recMJHlMqcgdTZgbt",
        "row": 5986,
        "state": "ARIZONA",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "OP",
        "provider": "BCBS"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMRzqzkYlwgCdhX",
        "row": 5991,
        "state": "ARIZONA",
        "group": "10018742",
        "group_num": [
            "recxfPIOFWaAqQlyR"
        ],
        "procedure": "PHP",
        "provider": "BCBS"
    },
    {
        "2020": 589,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece1Ym9Xwn5O0lQr",
        "row": 2628,
        "state": "ARIZONA",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 233,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receuHOXyQEgBOFUe",
        "row": 2654,
        "state": "ARIZONA",
        "group": "Y00146",
        "group_num": [
            "recUreBA7ookG2NP7"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 139,
        "2023": 139,
        "2024": 0,
        "id": "recfmLgkntCXz50Fc",
        "row": 2634,
        "state": "ARIZONA",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1369,
        "2023": 0,
        "2024": 0,
        "id": "recgD2TB7Z59SP7Fk",
        "row": 2606,
        "state": "ARIZONA",
        "group": "100346",
        "group_num": [
            "recN0g31CgoKRgFd6"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rechngDkQCzHTld4X",
        "row": 2604,
        "state": "ARIZONA",
        "group": "054632",
        "group_num": [
            "recLCOQ9hxhhsW1kf"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 487,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciGsuWSNy60uaSs",
        "row": 2616,
        "state": "ARIZONA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 450,
        "2021": 450,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recjJgIn3Kebtk1Au",
        "row": 2657,
        "state": "ARIZONA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "2023": 0,
        "2024": 0,
        "id": "recjPObWcI7MOb6zN",
        "row": 2658,
        "state": "ARIZONA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 250,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckZi7lPq21aXB7W",
        "row": 2608,
        "state": "ARIZONA",
        "group": "1102367",
        "group_num": [
            "rec5six7ns8594Atj"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckLmNXMVXvhwQmN",
        "row": 2625,
        "state": "ARIZONA",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 3304,
        "2021": 2581,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOdggxdFFWhj5YN",
        "row": 2626,
        "state": "ARIZONA",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 675,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPW6cIoxKGae9eV",
        "row": 2623,
        "state": "ARIZONA",
        "group": "ESG001",
        "group_num": [
            "rec9vpo0qctdBvgbV"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 295,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPc5xVH31Epw4bB",
        "row": 2643,
        "state": "ARIZONA",
        "group": "SH11",
        "group_num": [
            "reckcXcBtYjEpLVW0"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 3750,
        "2024": 0,
        "id": "recRvws2V1LfgdQZC",
        "row": 2632,
        "state": "ARIZONA",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 829,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR8Z5odcqqtwrmT",
        "row": 2642,
        "state": "ARIZONA",
        "group": "SH11",
        "group_num": [
            "reckcXcBtYjEpLVW0"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 1080,
        "2021": 1200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSs14hK9C5LhwaQ",
        "row": 2611,
        "state": "ARIZONA",
        "group": "3328406",
        "group_num": [
            "recRKA6FGm1MuVwcN"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1967,
        "2022": 2950,
        "2023": 0,
        "2024": 0,
        "id": "recSu5nxwwWc9dMlY",
        "row": 2619,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 5684,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUtYyfgnu4hPklN",
        "row": 2624,
        "state": "ARIZONA",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "recUF0qsi3xXRDsjm",
        "row": 2600,
        "state": "ARIZONA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 2200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUKBrNtUsVfkeyP",
        "row": 2651,
        "state": "ARIZONA",
        "group": "Y00001",
        "group_num": [
            "recZ029sqcbZgTV3M"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 105,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWLrNT3rSV5JGFm",
        "row": 2609,
        "state": "ARIZONA",
        "group": "1102367",
        "group_num": [
            "rec5six7ns8594Atj"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 38,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWkgtRO5dKTzXQB",
        "row": 2650,
        "state": "ARIZONA",
        "group": "TWR002",
        "group_num": [
            "rechMjddY2zuUOclp"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 65,
        "2022": 52,
        "2023": 0,
        "2024": 0,
        "id": "recWnOkdSAjq9x5CE",
        "row": 2644,
        "state": "ARIZONA",
        "group": "SH11",
        "group_num": [
            "reckcXcBtYjEpLVW0"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXDbOfE35TGdNJo",
        "row": 2638,
        "state": "ARIZONA",
        "group": "P03586",
        "group_num": [
            "recJFIMdzMSPBbXwQ"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2895,
        "2023": 0,
        "2024": 0,
        "id": "recYu33FPhaQu3bB9",
        "row": 2629,
        "state": "ARIZONA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 1299,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYkDncyu5s3ZaOZ",
        "row": 2647,
        "state": "ARIZONA",
        "group": "TWR002",
        "group_num": [
            "rechMjddY2zuUOclp"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0gPhVYkALvCYAM",
        "row": 2641,
        "state": "ARIZONA",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1583,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec210f8Uu8BEV81D",
        "row": 2617,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3193,
        "2023": 0,
        "2024": 0,
        "id": "rec3extjf0e0K142r",
        "row": 2633,
        "state": "ARIZONA",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 4750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4lPDAxpMVqwbmN",
        "row": 2639,
        "state": "ARIZONA",
        "group": "P03586",
        "group_num": [
            "recJFIMdzMSPBbXwQ"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 712,
        "2021": 295,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5eLFbiawxrCrAN",
        "row": 2646,
        "state": "ARIZONA",
        "group": "SHL001",
        "group_num": [
            "reck8bm8HvTKUKaID"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 274,
        "2021": 166,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5i7E8DsjTpe8k7",
        "row": 2612,
        "state": "ARIZONA",
        "group": "3328406",
        "group_num": [
            "recRKA6FGm1MuVwcN"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 826,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6aZGO8ipxt4ezO",
        "row": 2615,
        "state": "ARIZONA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 139,
        "2024": 0,
        "id": "rec7YMIDRks1gLURn",
        "row": 2637,
        "state": "ARIZONA",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 60,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7xl0UxABjDyp7u",
        "row": 2656,
        "state": "ARIZONA",
        "group": "Y00146",
        "group_num": [
            "recUreBA7ookG2NP7"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 0,
        "2024": 0,
        "id": "rec8SJdq6HyVQr5qO",
        "row": 2635,
        "state": "ARIZONA",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 540,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9QK5LB8G4UIMt2",
        "row": 2649,
        "state": "ARIZONA",
        "group": "TWR002",
        "group_num": [
            "rechMjddY2zuUOclp"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 4750,
        "2024": 0,
        "id": "reccqSMF1FgLxO3b6",
        "row": 2631,
        "state": "ARIZONA",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq0mCwuM3YW8cRv",
        "row": 2653,
        "state": "ARIZONA",
        "group": "Y00001",
        "group_num": [
            "recZ029sqcbZgTV3M"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 600,
        "2024": 0,
        "id": "recqOKCfLDM0y04k4",
        "row": 2603,
        "state": "ARIZONA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 2950,
        "2021": 0,
        "2022": 1600,
        "2023": 0,
        "2024": 0,
        "id": "recrmgKyGx9wwqaaO",
        "row": 2602,
        "state": "ARIZONA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rectwV6dQFQxJSQLe",
        "row": 2640,
        "state": "ARIZONA",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1165,
        "2023": 0,
        "2024": 0,
        "id": "rectNTe81Ny3dFFOh",
        "row": 2605,
        "state": "ARIZONA",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 3016,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recusPtm4Dl7hfopA",
        "row": 2620,
        "state": "ARIZONA",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 1839,
        "2021": 940,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu6TauLW7dfACU7",
        "row": 2621,
        "state": "ARIZONA",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu8H8rZq4Iakwi9",
        "row": 2652,
        "state": "ARIZONA",
        "group": "Y00001",
        "group_num": [
            "recZ029sqcbZgTV3M"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recxMayyNZmoXShvH",
        "row": 2659,
        "state": "ARIZONA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 2578,
        "2021": 1568,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczUW8Ru8DiNLyMx",
        "row": 2627,
        "state": "ARIZONA",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 328,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczV4xVEo06vdusA",
        "row": 2648,
        "state": "ARIZONA",
        "group": "TWR002",
        "group_num": [
            "rechMjddY2zuUOclp"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 1583,
        "2021": 1046,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz4rKAPzrZyrziN",
        "row": 2645,
        "state": "ARIZONA",
        "group": "SHL001",
        "group_num": [
            "reck8bm8HvTKUKaID"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 0,
        "2024": 0,
        "id": "reczcVZt82eH8Kgdo",
        "row": 2630,
        "state": "ARIZONA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 3750,
        "2021": 0,
        "2022": 2500,
        "2023": 0,
        "2024": 0,
        "id": "recAyUAEml3A6Gskx",
        "row": 2601,
        "state": "ARIZONA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 65,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCElRj7V7R2Bypp",
        "row": 2622,
        "state": "ARIZONA",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 105,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEv7fU1x5EqwEYf",
        "row": 2655,
        "state": "ARIZONA",
        "group": "Y00146",
        "group_num": [
            "recUreBA7ookG2NP7"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 60,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEwwBhFllNex9xO",
        "row": 2610,
        "state": "ARIZONA",
        "group": "1102367",
        "group_num": [
            "rec5six7ns8594Atj"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 1299,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recE2zBvv4tuBZkA5",
        "row": 2614,
        "state": "ARIZONA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 124,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJSst5pJnglCYdZ",
        "row": 2613,
        "state": "ARIZONA",
        "group": "3328406",
        "group_num": [
            "recRKA6FGm1MuVwcN"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "2024": 0,
        "id": "recJTNPTDQF3HzTg0",
        "row": 2636,
        "state": "ARIZONA",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 305,
        "2021": 244,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKrc39QRlywcuNJ",
        "row": 2607,
        "state": "ARIZONA",
        "group": "1102367",
        "group_num": [
            "rec5six7ns8594Atj"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 2171,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMNIblMNuLDshWi",
        "row": 2618,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1875,
        "2022": 1875,
        "2023": 0,
        "2024": 0,
        "id": "recJFgQLLKXWuGY5Y",
        "row": 2660,
        "state": "ARIZONA",
        "group": "ACNCC016",
        "group_num": [
            "recrrNiHq1Ouej9GM"
        ],
        "procedure": "PHP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 634,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3VpozNGyNPtrEo",
        "row": 2661,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "CARE1ST HEALTH PLAN OF ARIZONA"
    },
    {
        "2020": 575,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHi7hV1Z7Zl3Zw8",
        "row": 2662,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "CARE1ST HEALTH PLAN OF ARIZONA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recS6Jbv5f48AJvvA",
        "row": 2664,
        "state": "ARIZONA",
        "group": "NFMEX",
        "group_num": [
            "recqWIIuA8LmSVdMi"
        ],
        "procedure": "Detox",
        "provider": "CENTIVO"
    },
    {
        "2020": 244,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg1e43EboIeUmIc",
        "row": 2667,
        "state": "ARIZONA",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1984,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnFxiQ6ZoU6EiQ0",
        "row": 2686,
        "state": "ARIZONA",
        "group": "2471918",
        "group_num": [
            "rec9mfsvAT4XNgOuj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnmOqFsoYLzhSnb",
        "row": 2685,
        "state": "ARIZONA",
        "group": "2471918",
        "group_num": [
            "rec9mfsvAT4XNgOuj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 973,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRJ90ntZiYuyPYX",
        "row": 2697,
        "state": "ARIZONA",
        "group": "2499272",
        "group_num": [
            "recGwvg9J3cmjKhzV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "2023": 0,
        "2024": 0,
        "id": "recTGLueS2FUXvHJB",
        "row": 2684,
        "state": "ARIZONA",
        "group": "2001063",
        "group_num": [
            "recTQnuNNP2thRLit"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "recTL9pgerY0Q6Iyl",
        "row": 2689,
        "state": "ARIZONA",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 669,
        "2021": 698,
        "2022": 271,
        "2023": 172,
        "2024": 0,
        "id": "recTi4ccch723sxLL",
        "row": 2692,
        "state": "ARIZONA",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 4416,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVh2W0qv1NiJ645",
        "row": 2674,
        "state": "ARIZONA",
        "group": "0197027",
        "group_num": [
            "recnP0NBNDdrPPR2H"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWHHsKbmACDqVlU",
        "row": 2666,
        "state": "ARIZONA",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1163,
        "2023": 0,
        "2024": 0,
        "id": "recWeflGiXa1r7shB",
        "row": 2669,
        "state": "ARIZONA",
        "group": "100431",
        "group_num": [
            "recnx7txA5bSjyPet"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1032,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXYTmn6DLl4OCg3",
        "row": 2676,
        "state": "ARIZONA",
        "group": "0197027",
        "group_num": [
            "recnP0NBNDdrPPR2H"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 381,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX9Z2meLzMThThL",
        "row": 2677,
        "state": "ARIZONA",
        "group": "0198862",
        "group_num": [
            "recyTjsg2kvwpHdPz"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 88,
        "2021": 144,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4CYFturLuMnvdQ",
        "row": 2693,
        "state": "ARIZONA",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5MXaoUZvpb89Cw",
        "row": 2668,
        "state": "ARIZONA",
        "group": "56465",
        "group_num": [
            "recLv0qCDOARDQO1p"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "reccKSVu4IGmgDjQs",
        "row": 2695,
        "state": "ARIZONA",
        "group": "2498728",
        "group_num": [
            "recCae4MVTmBkfBWN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1440,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdTTJkiaFyRCzhb",
        "row": 2671,
        "state": "ARIZONA",
        "group": "116417",
        "group_num": [
            "rec7vMpBEuE6IFkVD"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 170,
        "2022": 582,
        "2023": 0,
        "2024": 0,
        "id": "recoi7RNdir4i2p1O",
        "row": 2691,
        "state": "ARIZONA",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 630,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrUTz3AYRv5DwFw",
        "row": 2673,
        "state": "ARIZONA",
        "group": "0196727",
        "group_num": [
            "recOZkVY0otBYFMfM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 798,
        "2022": 798,
        "2023": 0,
        "2024": 0,
        "id": "recsXbRx5WcAMZn8X",
        "row": 2681,
        "state": "ARIZONA",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5486,
        "2023": 0,
        "2024": 0,
        "id": "recu9qUBnm1vauxGk",
        "row": 2682,
        "state": "ARIZONA",
        "group": "2001063",
        "group_num": [
            "recTQnuNNP2thRLit"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recu90H1sGmghQtoM",
        "row": 2679,
        "state": "ARIZONA",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 927,
        "2021": 900,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxS5Lz5npCNoVlm",
        "row": 2672,
        "state": "ARIZONA",
        "group": "116417",
        "group_num": [
            "rec7vMpBEuE6IFkVD"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 4038,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx57rf4BcZQ4eMf",
        "row": 2675,
        "state": "ARIZONA",
        "group": "0197027",
        "group_num": [
            "recnP0NBNDdrPPR2H"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "2023": 172,
        "2024": 0,
        "id": "recyvrXvcoYGia912",
        "row": 2688,
        "state": "ARIZONA",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1882,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGwcs62UVOPk2Eu",
        "row": 2696,
        "state": "ARIZONA",
        "group": "2498956",
        "group_num": [
            "recP5n0y7Wi5SjQiO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recHpZYV65BpMphhv",
        "row": 2690,
        "state": "ARIZONA",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 413,
        "2023": 0,
        "2024": 0,
        "id": "recI3uSwEZNCHmCnJ",
        "row": 2687,
        "state": "ARIZONA",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 798,
        "2022": 798,
        "2023": 0,
        "2024": 0,
        "id": "recIdVhosIMW6nlNc",
        "row": 2680,
        "state": "ARIZONA",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1163,
        "2023": 0,
        "2024": 0,
        "id": "recNQ4ox7Ujk5t1iX",
        "row": 2670,
        "state": "ARIZONA",
        "group": "100431",
        "group_num": [
            "recnx7txA5bSjyPet"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "2023": 0,
        "2024": 0,
        "id": "recNfOoKi8pk96f27",
        "row": 2683,
        "state": "ARIZONA",
        "group": "2001063",
        "group_num": [
            "recTQnuNNP2thRLit"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 2050,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recey7QvxspS1lODt",
        "row": 2746,
        "state": "ARIZONA",
        "group": "3212272",
        "group_num": [
            "rec0jkIRIFFxvAsLT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 4048,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfus1J4irbr79MW",
        "row": 2781,
        "state": "ARIZONA",
        "group": "3333634",
        "group_num": [
            "recOe2Wf7kyYREqDC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfa6wGWLYQSi9mY",
        "row": 2728,
        "state": "ARIZONA",
        "group": "3176426",
        "group_num": [
            "recwud0eaYs8fiswX"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfeKQUjvjEckR1h",
        "row": 2736,
        "state": "ARIZONA",
        "group": "3207608",
        "group_num": [
            "recJAZ8eYmKOLQEwk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfkuYMeZktDVVDA",
        "row": 2786,
        "state": "ARIZONA",
        "group": "3335018",
        "group_num": [
            "recqCulw8BaXVVCj9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4333,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgFNXiqdiA2f2FH",
        "row": 2734,
        "state": "ARIZONA",
        "group": "3191624",
        "group_num": [
            "rec6ptjl1iDbM7FxS"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1868,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechHRgpbGfkZPl2V",
        "row": 2727,
        "state": "ARIZONA",
        "group": "3176426",
        "group_num": [
            "recwud0eaYs8fiswX"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 655,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recipu4TIaQxmgwql",
        "row": 2790,
        "state": "ARIZONA",
        "group": "3335066",
        "group_num": [
            "recQ16SomVb1m8WvW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1127,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjZrOZktUv5GCUm",
        "row": 2797,
        "state": "ARIZONA",
        "group": "3337186",
        "group_num": [
            "rec5SGx5GZPT6OKjN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4691,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjaw5naL7aRqZ79",
        "row": 2750,
        "state": "ARIZONA",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 907,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckc3qceQxqAQC5o",
        "row": 2776,
        "state": "ARIZONA",
        "group": "3332343",
        "group_num": [
            "recAdy3JThvYonBSj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1127,
        "2021": 1576,
        "2022": 1104,
        "2023": 0,
        "2024": 0,
        "id": "recl61lw9azSKV5vs",
        "row": 2737,
        "state": "ARIZONA",
        "group": "3209240",
        "group_num": [
            "recFvBfmipH5XKt1g"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recmXcCbK8STHR1Rg",
        "row": 2730,
        "state": "ARIZONA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 585,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm7ECn5NZugdtGG",
        "row": 2775,
        "state": "ARIZONA",
        "group": "3332343",
        "group_num": [
            "recAdy3JThvYonBSj"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recm7EUyW8Iw3vVQe",
        "row": 2794,
        "state": "ARIZONA",
        "group": "3335355",
        "group_num": [
            "recpEFNL98rjgrYAZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnlQacblvCEoW3n",
        "row": 2713,
        "state": "ARIZONA",
        "group": "3170360",
        "group_num": [
            "recwJvX6uNUm69SDk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3370,
        "2021": 2350,
        "2022": 2650,
        "2023": 0,
        "2024": 0,
        "id": "recOBXSzizqBQDiMI",
        "row": 2722,
        "state": "ARIZONA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recOksJSyqpGAsNZo",
        "row": 2712,
        "state": "ARIZONA",
        "group": "3141424",
        "group_num": [
            "recSjtLHOkSUUFxjJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPwPwshftQtWN7A",
        "row": 2702,
        "state": "ARIZONA",
        "group": "2500184",
        "group_num": [
            "recMPdGErwVg5KnkJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 112,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPGt4ZGAUoQBrdv",
        "row": 2733,
        "state": "ARIZONA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 2688,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recPcWBCTHJigVrRV",
        "row": 2774,
        "state": "ARIZONA",
        "group": "3332343",
        "group_num": [
            "recAdy3JThvYonBSj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1946,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRDIYhMrqNlX2KK",
        "row": 2708,
        "state": "ARIZONA",
        "group": "2501059",
        "group_num": [
            "recxcfrTI1LsB1jRk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 285,
        "2022": 293,
        "2023": 0,
        "2024": 0,
        "id": "recR7l0dgUAxoiz7n",
        "row": 2719,
        "state": "ARIZONA",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSEBvY6DEIZL2HW",
        "row": 2723,
        "state": "ARIZONA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3399,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recShxWL1FtFtYp37",
        "row": 2707,
        "state": "ARIZONA",
        "group": "2501059",
        "group_num": [
            "recxcfrTI1LsB1jRk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4019,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recTuGFmtj83ORCQc",
        "row": 2756,
        "state": "ARIZONA",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "2024": 0,
        "id": "recTPYdHs4d505S5K",
        "row": 2763,
        "state": "ARIZONA",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1435,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUeeBBb7b3l3PNr",
        "row": 2724,
        "state": "ARIZONA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 31,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWjv09BcLgYuyI7",
        "row": 2698,
        "state": "ARIZONA",
        "group": "2499811",
        "group_num": [
            "rec2cjluZbD5ORuMg"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 1924,
        "2021": 1939,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXeoTGIMAEOODmp",
        "row": 2765,
        "state": "ARIZONA",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recXqX8GJgbtDqC7u",
        "row": 2796,
        "state": "ARIZONA",
        "group": "3335838",
        "group_num": [
            "recheufQo0c2bF00o"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recYTnxLsP1NyvfBx",
        "row": 2764,
        "state": "ARIZONA",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 95,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYTHqq97nMd8HEx",
        "row": 2740,
        "state": "ARIZONA",
        "group": "3209240",
        "group_num": [
            "recFvBfmipH5XKt1g"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 675,
        "2024": 0,
        "id": "recYwyMlXjOuBdsD6",
        "row": 2717,
        "state": "ARIZONA",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recY5XPUlCtDjr0jb",
        "row": 2768,
        "state": "ARIZONA",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "2023": 0,
        "2024": 0,
        "id": "recZJQIk11n6J8m82",
        "row": 2795,
        "state": "ARIZONA",
        "group": "3335355",
        "group_num": [
            "recpEFNL98rjgrYAZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 2050,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0fvhT7sXlEklJb",
        "row": 2760,
        "state": "ARIZONA",
        "group": "3327654",
        "group_num": [
            "recjjtT3gHn8eyeZ6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1sbm1DwY64wPCK",
        "row": 2793,
        "state": "ARIZONA",
        "group": "3335320",
        "group_num": [
            "rec18VSD7okHRdJe3"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1AsoDzKGX5tpL3",
        "row": 2752,
        "state": "ARIZONA",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1fMRUxXAYOgmpJ",
        "row": 2780,
        "state": "ARIZONA",
        "group": "3333286",
        "group_num": [
            "recoUj1GA34nkOnKW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1qeIhNDhEhUw8q",
        "row": 2783,
        "state": "ARIZONA",
        "group": "3333770",
        "group_num": [
            "reclom1FbBi6CFtre"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "2024": 0,
        "id": "rec2JRWsXg3jitBzx",
        "row": 2706,
        "state": "ARIZONA",
        "group": "2500791",
        "group_num": [
            "recO2jcds1LAAmneO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3685,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3qYOiCOykPF2dk",
        "row": 2759,
        "state": "ARIZONA",
        "group": "3327654",
        "group_num": [
            "recjjtT3gHn8eyeZ6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "2024": 0,
        "id": "rec4ZDe35Wy1Z2UH1",
        "row": 2703,
        "state": "ARIZONA",
        "group": "2500547",
        "group_num": [
            "recX1o2dB6HUe9G4w"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 950,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec41Rfbf1cBqqbRr",
        "row": 2715,
        "state": "ARIZONA",
        "group": "3170360",
        "group_num": [
            "recwJvX6uNUm69SDk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5TBRhnqEyR2GnZ",
        "row": 2767,
        "state": "ARIZONA",
        "group": "3329774",
        "group_num": [
            "recA3g8KYh7kQrlOt"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 89,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5z11oYeJC33SOV",
        "row": 2792,
        "state": "ARIZONA",
        "group": "3335115",
        "group_num": [
            "recp5bIpyVGHTDVGy"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "rec68dabVc2gAEnU0",
        "row": 2758,
        "state": "ARIZONA",
        "group": "3327596",
        "group_num": [
            "rec13pT2eiBX759WR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3936,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7v5o85GrRKehdJ",
        "row": 2729,
        "state": "ARIZONA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 318,
        "2023": 0,
        "2024": 0,
        "id": "rec8bvfeB63LKTVmy",
        "row": 2779,
        "state": "ARIZONA",
        "group": "3332740",
        "group_num": [
            "recQXxGR8cRushzhk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1005,
        "2021": 0,
        "2022": 461,
        "2023": 0,
        "2024": 0,
        "id": "rec9gYvap7O7u2T1M",
        "row": 2744,
        "state": "ARIZONA",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbczNJJzSAmSDi5",
        "row": 2766,
        "state": "ARIZONA",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recblf0Z6RrgWGLHl",
        "row": 2754,
        "state": "ARIZONA",
        "group": "3309644",
        "group_num": [
            "rec5nIxvdWj2swuXF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "reccteM8BNMIyzdri",
        "row": 2705,
        "state": "ARIZONA",
        "group": "2500706",
        "group_num": [
            "reczz4j75RxqcTdeG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recd4buqBe0HDda5Y",
        "row": 2721,
        "state": "ARIZONA",
        "group": "3173152",
        "group_num": [
            "recbLn9gejzu4Q294"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 907,
        "2021": 570,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoJ2SrAQsM8Y9YD",
        "row": 2710,
        "state": "ARIZONA",
        "group": "2501059",
        "group_num": [
            "recxcfrTI1LsB1jRk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 950,
        "2021": 0,
        "2022": 582,
        "2023": 0,
        "2024": 0,
        "id": "recorRs4507PtIICN",
        "row": 2731,
        "state": "ARIZONA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 4582,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqEHBm9VgHZ07Zu",
        "row": 2755,
        "state": "ARIZONA",
        "group": "3316316",
        "group_num": [
            "recHPVVRhgUSuOdXm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recrivLUtlG1owotf",
        "row": 2773,
        "state": "ARIZONA",
        "group": "3332133",
        "group_num": [
            "recwYiFjJ5cYEqudr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 88,
        "2021": 144,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectHx4i1QBkob4cC",
        "row": 2700,
        "state": "ARIZONA",
        "group": "2499832",
        "group_num": [
            "recq8fi2yEltr3GGP"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuUcRYNQJtkuPyF",
        "row": 2788,
        "state": "ARIZONA",
        "group": "3335066",
        "group_num": [
            "recQ16SomVb1m8WvW"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 769,
        "2021": 0,
        "2022": 324,
        "2023": 0,
        "2024": 0,
        "id": "recvcTIxYdZkDjXCT",
        "row": 2732,
        "state": "ARIZONA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recwfcCPSvTga9fVy",
        "row": 2784,
        "state": "ARIZONA",
        "group": "3333787",
        "group_num": [
            "rec7PiTXbHHW4dRcz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1942,
        "2023": 0,
        "2024": 0,
        "id": "recxvHLxMSNJ4O6oZ",
        "row": 2701,
        "state": "ARIZONA",
        "group": "2499851",
        "group_num": [
            "recOynnGPWAXuowMa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1958,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxAwEKNwBeV26fm",
        "row": 2772,
        "state": "ARIZONA",
        "group": "3330754",
        "group_num": [
            "recncRzIzW4KwgAkU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 432,
        "2022": 413,
        "2023": 0,
        "2024": 0,
        "id": "recyF2Sw7Jx1Q4SDN",
        "row": 2762,
        "state": "ARIZONA",
        "group": "3328317",
        "group_num": [
            "recg31PzLjp9XlEwh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 273,
        "2022": 293,
        "2023": 0,
        "2024": 0,
        "id": "recyh7bLVqtD1oVAq",
        "row": 2718,
        "state": "ARIZONA",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczK6XTQuJvRotFA",
        "row": 2761,
        "state": "ARIZONA",
        "group": "3328317",
        "group_num": [
            "recg31PzLjp9XlEwh"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 605,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz4f48UBgJKP7U1",
        "row": 2789,
        "state": "ARIZONA",
        "group": "3335066",
        "group_num": [
            "recQ16SomVb1m8WvW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3367,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAusnjD5AfdrgQ3",
        "row": 2787,
        "state": "ARIZONA",
        "group": "3335066",
        "group_num": [
            "recQ16SomVb1m8WvW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1079,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAGs7IPVLj1qaHz",
        "row": 2751,
        "state": "ARIZONA",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 361,
        "2023": 0,
        "2024": 0,
        "id": "recBWJPr7ud9PuTS5",
        "row": 2778,
        "state": "ARIZONA",
        "group": "3332740",
        "group_num": [
            "recQXxGR8cRushzhk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 836,
        "2021": 353,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCOcSNjUOIXBH2W",
        "row": 2769,
        "state": "ARIZONA",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1459,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDFB1Aa9kdAaSPL",
        "row": 2785,
        "state": "ARIZONA",
        "group": "3334628",
        "group_num": [
            "recTLXUcfMLBGWhX8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1091,
        "2021": 1070,
        "2022": 1104,
        "2023": 1125,
        "2024": 0,
        "id": "recD8Nc6izGC8aaiT",
        "row": 2716,
        "state": "ARIZONA",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 284,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEYKiR9tHAu1akt",
        "row": 2738,
        "state": "ARIZONA",
        "group": "3209240",
        "group_num": [
            "recFvBfmipH5XKt1g"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recFicq4bwfHS202v",
        "row": 2742,
        "state": "ARIZONA",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 845,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGSR6J5u1Qd4aiU",
        "row": 2748,
        "state": "ARIZONA",
        "group": "3212272",
        "group_num": [
            "rec0jkIRIFFxvAsLT"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1975,
        "2024": 0,
        "id": "recGTANqkoImiDtbK",
        "row": 2704,
        "state": "ARIZONA",
        "group": "2500547",
        "group_num": [
            "recX1o2dB6HUe9G4w"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGVUREfybOb4ddM",
        "row": 2747,
        "state": "ARIZONA",
        "group": "3212272",
        "group_num": [
            "rec0jkIRIFFxvAsLT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGK4tDhZdA6mx7v",
        "row": 2782,
        "state": "ARIZONA",
        "group": "3333635",
        "group_num": [
            "recdU9cSnx1kXsKjU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "2023": 0,
        "2024": 0,
        "id": "recG9qbpnf5z8VKJu",
        "row": 2757,
        "state": "ARIZONA",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 696,
        "2023": 0,
        "2024": 0,
        "id": "recGo44wq2khhSVCI",
        "row": 2743,
        "state": "ARIZONA",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 983,
        "2021": 537,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIgF7LcuEjH4a7D",
        "row": 2709,
        "state": "ARIZONA",
        "group": "2501059",
        "group_num": [
            "recxcfrTI1LsB1jRk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 829,
        "2021": 312,
        "2022": 471,
        "2023": 0,
        "2024": 0,
        "id": "recJpLuvPyKogrpQH",
        "row": 2770,
        "state": "ARIZONA",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 472,
        "2021": 268,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLoE8AFPn1QQCNT",
        "row": 2739,
        "state": "ARIZONA",
        "group": "3209240",
        "group_num": [
            "recFvBfmipH5XKt1g"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2364,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recMYcfnBqh4k9Tas",
        "row": 2726,
        "state": "ARIZONA",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMsXcn6nBxC34KP",
        "row": 2714,
        "state": "ARIZONA",
        "group": "3170360",
        "group_num": [
            "recwJvX6uNUm69SDk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMdrkANu7mZKHfE",
        "row": 2735,
        "state": "ARIZONA",
        "group": "3207016",
        "group_num": [
            "recIEv52upcncQGzE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recfXZo6OjBgOeG4a",
        "row": 2892,
        "state": "ARIZONA",
        "group": "3344505",
        "group_num": [
            "recNWKdXMVsNG8kA5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4112,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recf9PAaE3mPeAoDX",
        "row": 2836,
        "state": "ARIZONA",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 353,
        "2022": 353,
        "2023": 0,
        "2024": 0,
        "id": "recgxlZoYHg6UPIHT",
        "row": 2818,
        "state": "ARIZONA",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgOOu3EwlcuvF5T",
        "row": 2820,
        "state": "ARIZONA",
        "group": "3339902",
        "group_num": [
            "rec9r4pMVJ62jdigA"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "rechAaXZ2puiRohs7",
        "row": 2802,
        "state": "ARIZONA",
        "group": "3338372",
        "group_num": [
            "recBDbwV2MCmWaKFp"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "rechiAxRdgPcDSNQ2",
        "row": 2815,
        "state": "ARIZONA",
        "group": "3339486",
        "group_num": [
            "recKiGvjUJ8dtl774"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci3UbdyVPyM7UXZ",
        "row": 2845,
        "state": "ARIZONA",
        "group": "3341410",
        "group_num": [
            "recYdxiLkggtYy7Uo"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1116,
        "2021": 0,
        "2022": 0,
        "2023": 1642,
        "2024": 0,
        "id": "reci6XKJjZA4QBgai",
        "row": 2823,
        "state": "ARIZONA",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "2023": 0,
        "2024": 0,
        "id": "reckyiI4rjXi2Bx9z",
        "row": 2896,
        "state": "ARIZONA",
        "group": "3344824",
        "group_num": [
            "recciAYH8gufII9nq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3396,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "reclMfyIN7G9Gea8W",
        "row": 2877,
        "state": "ARIZONA",
        "group": "3342952",
        "group_num": [
            "rec4pXG0ZyWE3u4oE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm0AaeJlKdiuale",
        "row": 2842,
        "state": "ARIZONA",
        "group": "3341242",
        "group_num": [
            "recwwKK95a3HPcGqW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 349,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmDIC7Ulgr1n7md",
        "row": 2810,
        "state": "ARIZONA",
        "group": "3338797",
        "group_num": [
            "recCpEczeEm6ifMLZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recmRjLrWZmDDYxuo",
        "row": 2814,
        "state": "ARIZONA",
        "group": "3339317",
        "group_num": [
            "recwRY83X3diR3Nw6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1000,
        "2024": 0,
        "id": "recnJMx6hKW47jEyB",
        "row": 2857,
        "state": "ARIZONA",
        "group": "3341974",
        "group_num": [
            "rec6XUPhRDOPDVfA1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 106,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOMMD0myxQyzrHr",
        "row": 2832,
        "state": "ARIZONA",
        "group": "3340282",
        "group_num": [
            "recLwizlAdfNibOfs"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recOOomCDcJTBrGlq",
        "row": 2806,
        "state": "ARIZONA",
        "group": "3338477",
        "group_num": [
            "recZtLMjeQxzrcXNb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOpK4jwBXZkvfBY",
        "row": 2879,
        "state": "ARIZONA",
        "group": "3342986",
        "group_num": [
            "rec3HvLVzaTT0JsS0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPg7GcY6JpHjyN8",
        "row": 2844,
        "state": "ARIZONA",
        "group": "3341246",
        "group_num": [
            "recTQkIuxdEaUU5u0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1521,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQXZvNaM3fQfMH1",
        "row": 2881,
        "state": "ARIZONA",
        "group": "3343058",
        "group_num": [
            "recZnJzzaIyQxckoV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 2050,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQlVJWhjJZ7Hd59",
        "row": 2821,
        "state": "ARIZONA",
        "group": "3339902",
        "group_num": [
            "rec9r4pMVJ62jdigA"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recS0XYRsmDYDCC7K",
        "row": 2871,
        "state": "ARIZONA",
        "group": "3342837",
        "group_num": [
            "recUlqitKiQAuxe16"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recSQO0xLlvB0sQAp",
        "row": 2834,
        "state": "ARIZONA",
        "group": "3341050",
        "group_num": [
            "recMd5mvZaaqcwksL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2757,
        "2021": 1459,
        "2022": 1509,
        "2023": 1597,
        "2024": 0,
        "id": "recTSXPzVCi1vuddW",
        "row": 2822,
        "state": "ARIZONA",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTAzrlN13mKFpQi",
        "row": 2882,
        "state": "ARIZONA",
        "group": "3343123",
        "group_num": [
            "recbZYK1JUbVZbtqa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 264,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUXMf4POizN8WcV",
        "row": 2812,
        "state": "ARIZONA",
        "group": "3339131",
        "group_num": [
            "recVToIzEhXtKfpkl"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2615,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVsylUluad4ZLzQ",
        "row": 2807,
        "state": "ARIZONA",
        "group": "3338771",
        "group_num": [
            "recJGXnpPYKluaSLv"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVRERNembQN4ndG",
        "row": 2858,
        "state": "ARIZONA",
        "group": "3341998",
        "group_num": [
            "recp8LTnq0XBC3w0f"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1942,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recW8whAOhbvzUZPD",
        "row": 2859,
        "state": "ARIZONA",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recXp8G2tv2XlRbmu",
        "row": 2884,
        "state": "ARIZONA",
        "group": "3343339",
        "group_num": [
            "recjWkeKJAVE5uWZR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 738,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYuZhfhjtq6GPlM",
        "row": 2829,
        "state": "ARIZONA",
        "group": "3340167",
        "group_num": [
            "rects8TIzhpBg8vND"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2050,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYf8GtXAykQgfk2",
        "row": 2863,
        "state": "ARIZONA",
        "group": "3342311",
        "group_num": [
            "recvM9GR8QHmPAGnZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 76,
        "2023": 0,
        "2024": 0,
        "id": "rec0Y6AvauZiyDDPj",
        "row": 2833,
        "state": "ARIZONA",
        "group": "3340951",
        "group_num": [
            "recAEcHk4pcIfO6wv"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 4028,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec00rWKXbiCTM5m8",
        "row": 2827,
        "state": "ARIZONA",
        "group": "3340167",
        "group_num": [
            "rects8TIzhpBg8vND"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0a6ep8OtwzN8DC",
        "row": 2813,
        "state": "ARIZONA",
        "group": "3339131",
        "group_num": [
            "recVToIzEhXtKfpkl"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec14CUDCpnDGH6eU",
        "row": 2872,
        "state": "ARIZONA",
        "group": "3342837",
        "group_num": [
            "recUlqitKiQAuxe16"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec16Z1cS7pkECznU",
        "row": 2851,
        "state": "ARIZONA",
        "group": "3341833",
        "group_num": [
            "recwDH9O0Ctcxx7YV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec17P8RnvuCFzzSX",
        "row": 2831,
        "state": "ARIZONA",
        "group": "3340282",
        "group_num": [
            "recLwizlAdfNibOfs"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1h5diTCeg8M1kW",
        "row": 2891,
        "state": "ARIZONA",
        "group": "3344371",
        "group_num": [
            "rec7YvyKMiZAkyRB4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4EuoohJMCZXrRC",
        "row": 2803,
        "state": "ARIZONA",
        "group": "3338372",
        "group_num": [
            "recBDbwV2MCmWaKFp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "rec4ImtfwmBOZM2P0",
        "row": 2883,
        "state": "ARIZONA",
        "group": "3343277",
        "group_num": [
            "recjEwY7ECARe5Dl6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3891,
        "2024": 0,
        "id": "rec4pHCSwPKSD2HVV",
        "row": 2840,
        "state": "ARIZONA",
        "group": "3341106",
        "group_num": [
            "recbMisNEiPlKqDVf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "2023": 0,
        "2024": 0,
        "id": "rec5jYGnR2FLNIorT",
        "row": 2897,
        "state": "ARIZONA",
        "group": "3344824",
        "group_num": [
            "recciAYH8gufII9nq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "2023": 1125,
        "2024": 0,
        "id": "rec61OWfzPDnccBdX",
        "row": 2893,
        "state": "ARIZONA",
        "group": "3344535",
        "group_num": [
            "recCzcBhi5mHHL7ay"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 148,
        "2021": 202,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec76CIdpz4clzasU",
        "row": 2878,
        "state": "ARIZONA",
        "group": "3342965",
        "group_num": [
            "recYyMeGETTEWYwpt"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1148,
        "2023": 0,
        "2024": 0,
        "id": "rec8F9gAmdOzONkXJ",
        "row": 2870,
        "state": "ARIZONA",
        "group": "3342799",
        "group_num": [
            "recnk49OMOqii0u9T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaLbabm7HWP5Q79",
        "row": 2864,
        "state": "ARIZONA",
        "group": "3342311",
        "group_num": [
            "recvM9GR8QHmPAGnZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "2023": 0,
        "2024": 0,
        "id": "recadxMcvn4X6Y7Zn",
        "row": 2819,
        "state": "ARIZONA",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbhH77Q8xOvjTvq",
        "row": 2828,
        "state": "ARIZONA",
        "group": "3340167",
        "group_num": [
            "rects8TIzhpBg8vND"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 257,
        "2024": 0,
        "id": "recc1ovWkcq6TA9se",
        "row": 2847,
        "state": "ARIZONA",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 3862,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "reccsNlnW5pAvCour",
        "row": 2887,
        "state": "ARIZONA",
        "group": "3343498",
        "group_num": [
            "rec7RkcFexmJWLmwT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 489,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccwdct0sQOYRie0",
        "row": 2869,
        "state": "ARIZONA",
        "group": "3342723",
        "group_num": [
            "rec4qfvo0aJDhPzhi"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 323,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccCLphnRr9bqrxm",
        "row": 2839,
        "state": "ARIZONA",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 148,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccr4LNocpb9Ylo7",
        "row": 2875,
        "state": "ARIZONA",
        "group": "3342950",
        "group_num": [
            "recKGWogCu9L1GpZr"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 344,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdS6sjsy6UhsU6e",
        "row": 2843,
        "state": "ARIZONA",
        "group": "3341242",
        "group_num": [
            "recwwKK95a3HPcGqW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdpCZ184FLqjAIk",
        "row": 2885,
        "state": "ARIZONA",
        "group": "3343392",
        "group_num": [
            "reciVo0WT4ejyVQKC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoUL9VLhxsskXxJ",
        "row": 2868,
        "state": "ARIZONA",
        "group": "3342723",
        "group_num": [
            "rec4qfvo0aJDhPzhi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoWGUypTPNYpnvb",
        "row": 2853,
        "state": "ARIZONA",
        "group": "3341833",
        "group_num": [
            "recwDH9O0Ctcxx7YV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1127,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco4DHBwq45cEiq2",
        "row": 2825,
        "state": "ARIZONA",
        "group": "3340025",
        "group_num": [
            "recAoz8OcBaIKjIiu"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 331,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco9ukArGOgOPnX3",
        "row": 2809,
        "state": "ARIZONA",
        "group": "3338797",
        "group_num": [
            "recCpEczeEm6ifMLZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 378,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpztDmeFNoWhTLK",
        "row": 2888,
        "state": "ARIZONA",
        "group": "3343498",
        "group_num": [
            "rec7RkcFexmJWLmwT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 330,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpJ5IlhluWpyVLq",
        "row": 2804,
        "state": "ARIZONA",
        "group": "3338372",
        "group_num": [
            "recBDbwV2MCmWaKFp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp9azyvMhSrB9L2",
        "row": 2890,
        "state": "ARIZONA",
        "group": "3343712",
        "group_num": [
            "recZ9zjtDD28jBpQK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recpdIL20HkJE90Lo",
        "row": 2801,
        "state": "ARIZONA",
        "group": "3338293",
        "group_num": [
            "recE6BiXkUfZWOo0D"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2655,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs0BsidMkzu2awu",
        "row": 2874,
        "state": "ARIZONA",
        "group": "3342950",
        "group_num": [
            "recKGWogCu9L1GpZr"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs0bQvm6iYotAcV",
        "row": 2873,
        "state": "ARIZONA",
        "group": "3342869",
        "group_num": [
            "rec4T5FZAkvgsXquN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuKkw3okA7rrtfi",
        "row": 2849,
        "state": "ARIZONA",
        "group": "3341767",
        "group_num": [
            "recpWhYHKvu2Cey4a"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuN9lapr7HR43BC",
        "row": 2862,
        "state": "ARIZONA",
        "group": "3342311",
        "group_num": [
            "recvM9GR8QHmPAGnZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvTADaKw4AwhnXz",
        "row": 2860,
        "state": "ARIZONA",
        "group": "3342089",
        "group_num": [
            "recM1jsfQ2aUSHmyt"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 318,
        "2023": 244,
        "2024": 0,
        "id": "recvintdPGn5pZBAI",
        "row": 2838,
        "state": "ARIZONA",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxu4U6VEPNwUNnL",
        "row": 2861,
        "state": "ARIZONA",
        "group": "3342294",
        "group_num": [
            "recjM618fcJAzf9yw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxJMd70w7dasgyV",
        "row": 2841,
        "state": "ARIZONA",
        "group": "3341150",
        "group_num": [
            "recG9jowAVgYB1TWB"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1975,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recx5mQS8k7px4Q1F",
        "row": 2846,
        "state": "ARIZONA",
        "group": "3341726",
        "group_num": [
            "rec3k87FRdukFSWor"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2496,
        "2021": 2500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recywPWpbcEeQi21Q",
        "row": 2800,
        "state": "ARIZONA",
        "group": "3338211",
        "group_num": [
            "recPeNcpS0f3PjHJo"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyfGfiRmi72z2MP",
        "row": 2830,
        "state": "ARIZONA",
        "group": "3340178",
        "group_num": [
            "recAiHuSqkCjXO5dL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3804,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "reczN8vsjBf6NL3RP",
        "row": 2799,
        "state": "ARIZONA",
        "group": "3337752",
        "group_num": [
            "recT7z6uPj4iTTMfh"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3985,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczn8HVdQpqVWrLC",
        "row": 2886,
        "state": "ARIZONA",
        "group": "3343439",
        "group_num": [
            "recdY7bPDn3hylacF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 235,
        "2022": 328,
        "2023": 0,
        "2024": 0,
        "id": "recA7gmV1sH49bteD",
        "row": 2854,
        "state": "ARIZONA",
        "group": "3341833",
        "group_num": [
            "recwDH9O0Ctcxx7YV"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3833,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recAkms4UzgDWl7JZ",
        "row": 2826,
        "state": "ARIZONA",
        "group": "3340056",
        "group_num": [
            "recBy4kBKj1tTd2rD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAl9WxWrCkoiMSW",
        "row": 2852,
        "state": "ARIZONA",
        "group": "3341833",
        "group_num": [
            "recwDH9O0Ctcxx7YV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAobrKew920ukaY",
        "row": 2880,
        "state": "ARIZONA",
        "group": "3343058",
        "group_num": [
            "recZnJzzaIyQxckoV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBaVdfMUn2v69JA",
        "row": 2867,
        "state": "ARIZONA",
        "group": "3342612",
        "group_num": [
            "recJcY5qEJDltvAfY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 295,
        "2024": 0,
        "id": "recBnScqaZoFU68oh",
        "row": 2894,
        "state": "ARIZONA",
        "group": "3344535",
        "group_num": [
            "recCzcBhi5mHHL7ay"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 769,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCMuDNgYUsryC8J",
        "row": 2835,
        "state": "ARIZONA",
        "group": "3341050",
        "group_num": [
            "recMd5mvZaaqcwksL"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "2024": 0,
        "id": "recCMGiUjLDopIWe6",
        "row": 2798,
        "state": "ARIZONA",
        "group": "3337288",
        "group_num": [
            "rec8CJ1v3D1yU78vG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1870,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCbCHZPmf8PC4OY",
        "row": 2817,
        "state": "ARIZONA",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recDFifYrQo8g98QJ",
        "row": 2895,
        "state": "ARIZONA",
        "group": "3344824",
        "group_num": [
            "recciAYH8gufII9nq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDLWRqFoz7lwFtI",
        "row": 2856,
        "state": "ARIZONA",
        "group": "3341974",
        "group_num": [
            "rec6XUPhRDOPDVfA1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 535,
        "2022": 535,
        "2023": 0,
        "2024": 0,
        "id": "recGYvTmi5KNgpHsO",
        "row": 2855,
        "state": "ARIZONA",
        "group": "3341944",
        "group_num": [
            "recaYnJDG9JXSrqS9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1568,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHBWxzXa7EcB4ZA",
        "row": 2808,
        "state": "ARIZONA",
        "group": "3338797",
        "group_num": [
            "recCpEczeEm6ifMLZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1942,
        "2023": 0,
        "2024": 0,
        "id": "recI5nFLkouZ4Fnqe",
        "row": 2866,
        "state": "ARIZONA",
        "group": "3342391",
        "group_num": [
            "recFVeVnLOpbnBdvc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "recKpBdlrmyOslyjU",
        "row": 2816,
        "state": "ARIZONA",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 399,
        "2023": 0,
        "2024": 0,
        "id": "recLEJGLNFrGSaiae",
        "row": 2824,
        "state": "ARIZONA",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 172,
        "2024": 0,
        "id": "recNFvfIOH0VFsEnB",
        "row": 2848,
        "state": "ARIZONA",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 298,
        "2021": 0,
        "2022": 593,
        "2023": 0,
        "2024": 0,
        "id": "recNIr33d2BIW3QYc",
        "row": 2837,
        "state": "ARIZONA",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece4QiD9oeGzf5gq",
        "row": 2917,
        "state": "ARIZONA",
        "group": "00609059",
        "group_num": [
            "recdl0iCzbEwBpUjX"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recekYcCKMXg10IKd",
        "row": 2925,
        "state": "ARIZONA",
        "group": "00613106",
        "group_num": [
            "recGguJaj3gpgytGr"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 1127,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recem4MjK5StZfxcD",
        "row": 2922,
        "state": "ARIZONA",
        "group": "00612062",
        "group_num": [
            "recvRY2O4qRIkrPTQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 935,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfaqeTiL5BwBlKL",
        "row": 2920,
        "state": "ARIZONA",
        "group": "00610715",
        "group_num": [
            "recZEyzOMPW6WoHrd"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1148,
        "2023": 0,
        "2024": 0,
        "id": "recgUyxnopgS7PR0T",
        "row": 2913,
        "state": "ARIZONA",
        "group": "00608587",
        "group_num": [
            "rec2Xm9IUfs3Wh5zY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 930,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg6YENR3pg7YsV1",
        "row": 2945,
        "state": "ARIZONA",
        "group": "00622846",
        "group_num": [
            "recCVhWWsMAzW8JS1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechTUorBAmCmZb7O",
        "row": 2899,
        "state": "ARIZONA",
        "group": "4006803",
        "group_num": [
            "recEHaDDCyTjiGUlH"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 307,
        "2021": 263,
        "2022": 268,
        "2023": 0,
        "2024": 0,
        "id": "rechiNIzdzI2d3E7e",
        "row": 2929,
        "state": "ARIZONA",
        "group": "00613576",
        "group_num": [
            "recY0hrLVhDh50LIW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2185,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj8lob0Y1yRAE8d",
        "row": 2944,
        "state": "ARIZONA",
        "group": "00622846",
        "group_num": [
            "recCVhWWsMAzW8JS1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recjctW46GKKgMWsY",
        "row": 2900,
        "state": "ARIZONA",
        "group": "4657901",
        "group_num": [
            "recTbeRcj8bJQeyzT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 894,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl9J4Tx3qU3FmF5",
        "row": 2901,
        "state": "ARIZONA",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 778,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recntXUhJtioWFP9r",
        "row": 2924,
        "state": "ARIZONA",
        "group": "00612626",
        "group_num": [
            "recqkQbeJw88yrO0w"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 456,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPvopYJcnpUZ8ZQ",
        "row": 2942,
        "state": "ARIZONA",
        "group": "00622074",
        "group_num": [
            "recSd6F1txGwOHOiQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 163,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQswegspBlK66cX",
        "row": 2943,
        "state": "ARIZONA",
        "group": "00622074",
        "group_num": [
            "recSd6F1txGwOHOiQ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 1167,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSTLXFuZmPKSu9r",
        "row": 2923,
        "state": "ARIZONA",
        "group": "00612626",
        "group_num": [
            "recqkQbeJw88yrO0w"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 300,
        "2021": 300,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT5AfkVVaijS4FH",
        "row": 2906,
        "state": "ARIZONA",
        "group": "00050231",
        "group_num": [
            "recaBDCz02PKcuG8G"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 0,
        "2024": 0,
        "id": "recWyLjJQIioXA0Fq",
        "row": 2916,
        "state": "ARIZONA",
        "group": "00608699",
        "group_num": [
            "recANzolTFXsxf2kz"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXVTX3PJxsDgfOb",
        "row": 2912,
        "state": "ARIZONA",
        "group": "00607746",
        "group_num": [
            "recNTXujznEtqjKm2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 737,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYOqtEWQJr84xBp",
        "row": 2904,
        "state": "ARIZONA",
        "group": "00050231",
        "group_num": [
            "recaBDCz02PKcuG8G"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 938,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZuEoAIsICTjV1P",
        "row": 2902,
        "state": "ARIZONA",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1022,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0kKffOoa9TXLlZ",
        "row": 2908,
        "state": "ARIZONA",
        "group": "00183329",
        "group_num": [
            "recHtBuWKMy0gBFnM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 263,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2QTcgttL5aNm0i",
        "row": 2934,
        "state": "ARIZONA",
        "group": "00619497",
        "group_num": [
            "recn9cUFFriRIukSt"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4YqYdCquOuyQte",
        "row": 2938,
        "state": "ARIZONA",
        "group": "00621359",
        "group_num": [
            "recLwchdNAxm33WUo"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4418,
        "2023": 0,
        "2024": 0,
        "id": "rec58HAleaG41T2a0",
        "row": 2910,
        "state": "ARIZONA",
        "group": "00183586",
        "group_num": [
            "recP4IEuWUwVTOPEL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 907,
        "2021": 767,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6YSm312ZyWTJKH",
        "row": 2946,
        "state": "ARIZONA",
        "group": "00622846",
        "group_num": [
            "recCVhWWsMAzW8JS1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 667,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaxMZfwQFerKB89",
        "row": 2939,
        "state": "ARIZONA",
        "group": "00621359",
        "group_num": [
            "recLwchdNAxm33WUo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 73,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca6dKbEKUSihoNT",
        "row": 2940,
        "state": "ARIZONA",
        "group": "00621359",
        "group_num": [
            "recLwchdNAxm33WUo"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 795,
        "2021": 263,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccbIoEWDrH8YIi0",
        "row": 2928,
        "state": "ARIZONA",
        "group": "00613576",
        "group_num": [
            "recY0hrLVhDh50LIW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpq3tBoUBfAX5aC",
        "row": 2918,
        "state": "ARIZONA",
        "group": "00610715",
        "group_num": [
            "recZEyzOMPW6WoHrd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 940,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvoqfDlPkHy2eWG",
        "row": 2919,
        "state": "ARIZONA",
        "group": "00610715",
        "group_num": [
            "recZEyzOMPW6WoHrd"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 596,
        "2024": 0,
        "id": "recyGFqfJqb0ldXlX",
        "row": 2898,
        "state": "ARIZONA",
        "group": "3345322",
        "group_num": [
            "rec4JsfDQrUAIwmAJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyGKfoyF8BrbcdM",
        "row": 2933,
        "state": "ARIZONA",
        "group": "00616735",
        "group_num": [
            "reckmC6D9EjjvfHmf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAlkIiQFhQNtnCz",
        "row": 2911,
        "state": "ARIZONA",
        "group": "00607378",
        "group_num": [
            "recLKBhL229JAAd9p"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 880,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDu8x2az2Kx8Mrx",
        "row": 2905,
        "state": "ARIZONA",
        "group": "00050231",
        "group_num": [
            "recaBDCz02PKcuG8G"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 549,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDxaRGq7mxuLmuj",
        "row": 2931,
        "state": "ARIZONA",
        "group": "00615741",
        "group_num": [
            "recofcJl6JPbfhIX8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 0,
        "2024": 0,
        "id": "recEFS4AOpJivbYPf",
        "row": 2915,
        "state": "ARIZONA",
        "group": "00608699",
        "group_num": [
            "recANzolTFXsxf2kz"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 282,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGOsafB5uH8LZT8",
        "row": 2935,
        "state": "ARIZONA",
        "group": "00619497",
        "group_num": [
            "recn9cUFFriRIukSt"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 546,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIORaHeop5dbbLO",
        "row": 2927,
        "state": "ARIZONA",
        "group": "00613465",
        "group_num": [
            "recqVH9x3lKLduG08"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 610,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recI9qLFd3qvKlQa3",
        "row": 2937,
        "state": "ARIZONA",
        "group": "00619876",
        "group_num": [
            "recurhoPs0ngqH9Ut"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "2023": 0,
        "2024": 0,
        "id": "recMFvzrpmxdTC2bY",
        "row": 2914,
        "state": "ARIZONA",
        "group": "00608699",
        "group_num": [
            "recANzolTFXsxf2kz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "2023": 0,
        "2024": 0,
        "id": "recfoUHbKhMfVcviK",
        "row": 2983,
        "state": "ARIZONA",
        "group": "00632582",
        "group_num": [
            "rec73JmE6TAABhX5V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1103,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfpDp5c6va2APyV",
        "row": 2973,
        "state": "ARIZONA",
        "group": "00629118",
        "group_num": [
            "recWHtBowr1T3OE1m"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1336,
        "2021": 1353,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjUsKcwF5X4rnT7",
        "row": 2996,
        "state": "ARIZONA",
        "group": "E79",
        "group_num": [
            "recROIdZLXcor5ESf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1091,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclDeQX3dulIpx7B",
        "row": 2990,
        "state": "ARIZONA",
        "group": "33442973",
        "group_num": [
            "rec48TmwxlRQhX4dL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 803,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmJdCA66yF0mb0c",
        "row": 2953,
        "state": "ARIZONA",
        "group": "00624351",
        "group_num": [
            "recak0mA6vM8Q7TYb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 243,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOvNYvHsd2HI3tG",
        "row": 2966,
        "state": "ARIZONA",
        "group": "00626718",
        "group_num": [
            "recKJEYJ1nAjuM9rT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 1139,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPXendNCuVFKDGe",
        "row": 2964,
        "state": "ARIZONA",
        "group": "00626389",
        "group_num": [
            "rec5lFbP1Mxd7U3yG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 728,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPNDZImc3rhoD2e",
        "row": 2961,
        "state": "ARIZONA",
        "group": "00626307",
        "group_num": [
            "rec5sG3rIdl9I5qPW"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 4099,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQClO8gv061TtHS",
        "row": 2949,
        "state": "ARIZONA",
        "group": "00623778",
        "group_num": [
            "recvxLEUNyhPVTn2p"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 287,
        "2023": 0,
        "2024": 0,
        "id": "recRDSGILLi0My3XR",
        "row": 2981,
        "state": "ARIZONA",
        "group": "00631831",
        "group_num": [
            "recS54MIlPgdV5VNV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "2023": 0,
        "2024": 0,
        "id": "recRckEut7gY9zX09",
        "row": 2982,
        "state": "ARIZONA",
        "group": "00631831",
        "group_num": [
            "recS54MIlPgdV5VNV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTChFbSB6D38MGu",
        "row": 2951,
        "state": "ARIZONA",
        "group": "00623941",
        "group_num": [
            "rec92QSxdMCRe4HBD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 903,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT8OC2tREKwqQ3l",
        "row": 2998,
        "state": "ARIZONA",
        "group": "L200583",
        "group_num": [
            "recAws9vcskOxoYZd"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1200,
        "2024": 0,
        "id": "recUMnwXOBPOccfRE",
        "row": 2993,
        "state": "ARIZONA",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 710,
        "2021": 710,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWGIZvSuxSYdouf",
        "row": 2977,
        "state": "ARIZONA",
        "group": "00629629",
        "group_num": [
            "recW3JXxvbLC6QEFA"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXEDDb2heL3W7q2",
        "row": 2972,
        "state": "ARIZONA",
        "group": "00628878",
        "group_num": [
            "recJieGAp6wY1ij2J"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "2023": 0,
        "2024": 0,
        "id": "recXEF98yRNl21Nbx",
        "row": 2989,
        "state": "ARIZONA",
        "group": "21587245",
        "group_num": [
            "recdSayejbMBmSCQT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 275,
        "2021": 246,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0NOssWt6wNRztP",
        "row": 2979,
        "state": "ARIZONA",
        "group": "00629629",
        "group_num": [
            "recW3JXxvbLC6QEFA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 293,
        "2024": 0,
        "id": "rec3qZIaQ5ctMcPeI",
        "row": 2963,
        "state": "ARIZONA",
        "group": "00626307",
        "group_num": [
            "rec5sG3rIdl9I5qPW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4DRidG2m62shyj",
        "row": 2991,
        "state": "ARIZONA",
        "group": "33442973",
        "group_num": [
            "rec48TmwxlRQhX4dL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 950,
        "2024": 0,
        "id": "rec527vM4Msb8SStq",
        "row": 2994,
        "state": "ARIZONA",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7SG3NANe1JZk9a",
        "row": 2956,
        "state": "ARIZONA",
        "group": "00625919",
        "group_num": [
            "recs55mSM1pG4O3N5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1092,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7c5G7rrXdFFWi2",
        "row": 2960,
        "state": "ARIZONA",
        "group": "00626307",
        "group_num": [
            "rec5sG3rIdl9I5qPW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1070,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8bXF8FOfO7W4Q0",
        "row": 2959,
        "state": "ARIZONA",
        "group": "00626209",
        "group_num": [
            "recw6EprfeYFb81f8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recamRx8y0jsJpPfH",
        "row": 2954,
        "state": "ARIZONA",
        "group": "00625775",
        "group_num": [
            "rec1czq6jqEO81E00"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1091,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbseqU8W74PJM47",
        "row": 2955,
        "state": "ARIZONA",
        "group": "00625919",
        "group_num": [
            "recs55mSM1pG4O3N5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 420,
        "2024": 0,
        "id": "recceW4A2z1IOTIZe",
        "row": 2988,
        "state": "ARIZONA",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 759,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd5St78SfqFwXq9",
        "row": 2965,
        "state": "ARIZONA",
        "group": "00626389",
        "group_num": [
            "rec5lFbP1Mxd7U3yG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1006,
        "2021": 515,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco0AND8bFLlpxD1",
        "row": 2978,
        "state": "ARIZONA",
        "group": "00629629",
        "group_num": [
            "recW3JXxvbLC6QEFA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoxAtd3xwjlzbhu",
        "row": 2974,
        "state": "ARIZONA",
        "group": "00629118",
        "group_num": [
            "recWHtBowr1T3OE1m"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recqF5C52iOsh6vHO",
        "row": 2986,
        "state": "ARIZONA",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1145,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuw5pRbnUG7KWgR",
        "row": 2969,
        "state": "ARIZONA",
        "group": "00628857",
        "group_num": [
            "rec2sOIeBGIrVuK0j"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1070,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyKcoVUEYyg3l8U",
        "row": 2952,
        "state": "ARIZONA",
        "group": "00624351",
        "group_num": [
            "recak0mA6vM8Q7TYb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1070,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyhnB46O8OHqJQ8",
        "row": 2968,
        "state": "ARIZONA",
        "group": "00628824",
        "group_num": [
            "recA6pX8xkeBfHKaP"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 0,
        "2024": 0,
        "id": "reczGNvwodRAfB7lS",
        "row": 2985,
        "state": "ARIZONA",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4155,
        "2023": 0,
        "2024": 0,
        "id": "reczLGCldeU0TMq1T",
        "row": 2984,
        "state": "ARIZONA",
        "group": "00633364",
        "group_num": [
            "rechaFWDfdSPA8IH8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 4863,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAuegMXRCklMShd",
        "row": 2997,
        "state": "ARIZONA",
        "group": "L200583",
        "group_num": [
            "recAws9vcskOxoYZd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2350,
        "2024": 0,
        "id": "recAfGBi1PH2gtVvV",
        "row": 2950,
        "state": "ARIZONA",
        "group": "00623894",
        "group_num": [
            "rec0e9GlB57YeGstX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 0,
        "2024": 0,
        "id": "recCI3SbjUXpBCW2n",
        "row": 2962,
        "state": "ARIZONA",
        "group": "00626307",
        "group_num": [
            "rec5sG3rIdl9I5qPW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 254,
        "2023": 718,
        "2024": 0,
        "id": "recFA2FdbdjvQaIcR",
        "row": 2987,
        "state": "ARIZONA",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFcveLcSK9kW07G",
        "row": 2992,
        "state": "ARIZONA",
        "group": "33442973",
        "group_num": [
            "rec48TmwxlRQhX4dL"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGRU34JSEG9Fvt4",
        "row": 2967,
        "state": "ARIZONA",
        "group": "00627440",
        "group_num": [
            "recEfsALcfOgh4AGG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "recG7nWFA210mpgjV",
        "row": 2980,
        "state": "ARIZONA",
        "group": "00631504",
        "group_num": [
            "rec2JYnDBtxx9lzdK"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 714,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHz7IUTSu4vZ5X9",
        "row": 2970,
        "state": "ARIZONA",
        "group": "00628878",
        "group_num": [
            "recJieGAp6wY1ij2J"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 287,
        "2023": 0,
        "2024": 0,
        "id": "recHqqHOE5yEgzjNd",
        "row": 2971,
        "state": "ARIZONA",
        "group": "00628878",
        "group_num": [
            "recJieGAp6wY1ij2J"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1216,
        "2023": 0,
        "2024": 0,
        "id": "recJNkXMJs1xdhsgJ",
        "row": 2948,
        "state": "ARIZONA",
        "group": "00623276",
        "group_num": [
            "receARClfsMCHiQ9M"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKx1wnxlulNAATN",
        "row": 2957,
        "state": "ARIZONA",
        "group": "00625919",
        "group_num": [
            "recs55mSM1pG4O3N5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3891,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLA9wcYf7w2SvOT",
        "row": 2976,
        "state": "ARIZONA",
        "group": "00629260",
        "group_num": [
            "rec2sqZ27wSggkkJO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1260,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv8Xs6KtsLVAxBe",
        "row": 3000,
        "state": "ARIZONA",
        "group": "C02059SLO2",
        "group_num": [
            "recIWscgrwmfNo52V"
        ],
        "procedure": "IOP",
        "provider": "COMMUNITY CARE"
    },
    {
        "2020": 1800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFA4jHekvkXHQKq",
        "row": 2999,
        "state": "ARIZONA",
        "group": "C02059SLO2",
        "group_num": [
            "recIWscgrwmfNo52V"
        ],
        "procedure": "PHP",
        "provider": "COMMUNITY CARE"
    },
    {
        "2020": 0,
        "2021": 796,
        "2022": 796,
        "2023": 0,
        "2024": 0,
        "id": "recjcRB6ZjjfsjMRy",
        "row": 3005,
        "state": "ARIZONA",
        "group": "S2612",
        "group_num": [
            "rect04aF8JvxOqmnX"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 796,
        "2022": 2014,
        "2023": 0,
        "2024": 0,
        "id": "recS2ItUqLSMbMK3z",
        "row": 3004,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 367,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Yli34hCvdtEkm",
        "row": 3002,
        "state": "ARIZONA",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "PHP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 796,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJG9NKWSSV9LuuM",
        "row": 3003,
        "state": "ARIZONA",
        "group": "10107212",
        "group_num": [
            "recOMck6JtJEJGjuL"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 1176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2OK3gZSyfW8WTL",
        "row": 3006,
        "state": "ARIZONA",
        "group": "CB740",
        "group_num": [
            "recbJ99hFiiDUo2jB"
        ],
        "procedure": "Detox",
        "provider": "CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 1142,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recybp131wXOry0D0",
        "row": 3007,
        "state": "ARIZONA",
        "group": "PR010",
        "group_num": [
            "recEAXRjZWiMPnLsl"
        ],
        "procedure": "Detox",
        "provider": "CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 3037,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9pxjCdFcBe15yv",
        "row": 3008,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "EMI HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recpX0W3TrYKOqIAt",
        "row": 3009,
        "state": "ARIZONA",
        "group": "00276",
        "group_num": [
            "recwkMWfKop55cy3T"
        ],
        "procedure": "Detox",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 1250,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recG6yslBLGoHW8zO",
        "row": 3010,
        "state": "ARIZONA",
        "group": "0000225",
        "group_num": [
            "recavhIGqGP3OHkH3"
        ],
        "procedure": "Detox",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIELc7HArjoa4dQ",
        "row": 3011,
        "state": "ARIZONA",
        "group": "0000225",
        "group_num": [
            "recavhIGqGP3OHkH3"
        ],
        "procedure": "RTC",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 549,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg7K9lhcFSV4N6H",
        "row": 3014,
        "state": "ARIZONA",
        "group": "A9120",
        "group_num": [
            "recCbZLWy0Z89Y6rZ"
        ],
        "procedure": "IOP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 686,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8DsmRtfrnvYBUM",
        "row": 3013,
        "state": "ARIZONA",
        "group": "A9120",
        "group_num": [
            "recCbZLWy0Z89Y6rZ"
        ],
        "procedure": "PHP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "2024": 0,
        "id": "recLe7Y54FM0hZMAZ",
        "row": 3012,
        "state": "ARIZONA",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "IOP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 4038,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjucSPeSBPGW3IL",
        "row": 3020,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckFmxbwt54TI9y7",
        "row": 3018,
        "state": "ARIZONA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "PHP",
        "provider": "GEHA"
    },
    {
        "2020": 3375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4tNwIPAL1gRY20",
        "row": 3021,
        "state": "ARIZONA",
        "group": "GEHA",
        "group_num": [
            "rec3Gkb5mZ7CYB61b"
        ],
        "procedure": "PHP",
        "provider": "GEHA"
    },
    {
        "2020": 950,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8XdgzL25SoaWOP",
        "row": 3017,
        "state": "ARIZONA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "GEHA"
    },
    {
        "2020": 4038,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbzvWxDvhTrEjON",
        "row": 3023,
        "state": "ARIZONA",
        "group": "RX1412",
        "group_num": [
            "recK1vZW5LUQiZ2x0"
        ],
        "procedure": "RTC",
        "provider": "GEHA"
    },
    {
        "2020": 2456,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCfJVPLOaz71hwd",
        "row": 3019,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "GEHA"
    },
    {
        "2020": 3450,
        "2021": 5007,
        "2022": 5100,
        "2023": 0,
        "2024": 0,
        "id": "recD2Ab6gAdoB1aQB",
        "row": 3016,
        "state": "ARIZONA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "Detox",
        "provider": "GEHA"
    },
    {
        "2020": 520,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclnyu4t69fZEiPx",
        "row": 3028,
        "state": "ARIZONA",
        "group": "Y00002636",
        "group_num": [
            "rec89uNHR728ncVUC"
        ],
        "procedure": "Detox",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 1219,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQCvHJ9fA5CBTfU",
        "row": 3025,
        "state": "ARIZONA",
        "group": "060000",
        "group_num": [
            "rec2DGoH2G1z0NU6l"
        ],
        "procedure": "PHP",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 1001,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpV4PVlAHNWyfFA",
        "row": 3024,
        "state": "ARIZONA",
        "group": "00000Q74",
        "group_num": [
            "recK8JZc7sos2kOnN"
        ],
        "procedure": "RTC",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 105,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxE886NiNLtTt43",
        "row": 3026,
        "state": "ARIZONA",
        "group": "060000",
        "group_num": [
            "rec2DGoH2G1z0NU6l"
        ],
        "procedure": "IOP",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfY7aks5tS0dLq2",
        "row": 3035,
        "state": "ARIZONA",
        "group": "S2502",
        "group_num": [
            "recKOPZ3WrqnIT4gt"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 0,
        "2024": 0,
        "id": "recnemQvopOUoFQLR",
        "row": 3036,
        "state": "ARIZONA",
        "group": "S5015",
        "group_num": [
            "reclT8WYWM3NbfnMm"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 1600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXA728J0ui756wc",
        "row": 3029,
        "state": "ARIZONA",
        "group": "07205",
        "group_num": [
            "recUa1GplCx2WhG9R"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 1761,
        "2021": 900,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5t8j1uDsjSauQk",
        "row": 3034,
        "state": "ARIZONA",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "PHP",
        "provider": "GILSBAR"
    },
    {
        "2020": 2375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvh9laWB8ZO6d1h",
        "row": 3033,
        "state": "ARIZONA",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "RTC",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3325,
        "2023": 0,
        "2024": 0,
        "id": "recxIRxqZbwuAz6XK",
        "row": 3030,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 508,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCtlAULXUBA2d6H",
        "row": 3031,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "GILSBAR"
    },
    {
        "2020": 2770,
        "2021": 1857,
        "2022": 2155,
        "2023": 0,
        "2024": 0,
        "id": "recHszCleDdPVjns9",
        "row": 3032,
        "state": "ARIZONA",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 4500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1wIL4mHEHVYIm4",
        "row": 3037,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "GOLDEN RULE"
    },
    {
        "2020": 967,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciCAkY3AL8qL26t",
        "row": 3044,
        "state": "ARIZONA",
        "group": "H880048",
        "group_num": [
            "recwfucbTK1ngn4sL"
        ],
        "procedure": "Detox",
        "provider": "GROUP AND PENSION ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 296,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnUr1e8LtzKZh7u",
        "row": 3042,
        "state": "ARIZONA",
        "group": "H870941",
        "group_num": [
            "recNEdq6z9ZQ0aHRi"
        ],
        "procedure": "IOP",
        "provider": "GROUP AND PENSION ADMINISTRATORS"
    },
    {
        "2020": 274,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9iNiPcIj07ATUX",
        "row": 3039,
        "state": "ARIZONA",
        "group": "H870488",
        "group_num": [
            "recjbSyoIPjvA74gK"
        ],
        "procedure": "IOP",
        "provider": "GROUP AND PENSION ADMINISTRATORS"
    },
    {
        "2020": 277,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqkfYvUaRpxlvKk",
        "row": 3038,
        "state": "ARIZONA",
        "group": "H870488",
        "group_num": [
            "recjbSyoIPjvA74gK"
        ],
        "procedure": "PHP",
        "provider": "GROUP AND PENSION ADMINISTRATORS"
    },
    {
        "2020": 277,
        "2021": 305,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGSUIYZ28FCuHW0",
        "row": 3041,
        "state": "ARIZONA",
        "group": "H870941",
        "group_num": [
            "recNEdq6z9ZQ0aHRi"
        ],
        "procedure": "PHP",
        "provider": "GROUP AND PENSION ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "2023": 0,
        "2024": 0,
        "id": "recC5RlkK49o9SqtX",
        "row": 3045,
        "state": "ARIZONA",
        "group": "0002",
        "group_num": [
            "recuXJnKfbB2DyCrJ"
        ],
        "procedure": "RTC",
        "provider": "HALCYON BEHAVIORAL HEALTH"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPh5UZprLcGl4s9",
        "row": 3046,
        "state": "ARIZONA",
        "group": "76070055",
        "group_num": [
            "recmWBV7ZAXlmbakJ"
        ],
        "procedure": "Detox",
        "provider": "HARMONY HEALTH CARE"
    },
    {
        "2020": 2375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDDRs4U3ZQ6mJie",
        "row": 3047,
        "state": "ARIZONA",
        "group": "76070055",
        "group_num": [
            "recmWBV7ZAXlmbakJ"
        ],
        "procedure": "RTC",
        "provider": "HARMONY HEALTH CARE"
    },
    {
        "2020": 3592,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recskwdT46QcWlqzF",
        "row": 3048,
        "state": "ARIZONA",
        "group": "76070055",
        "group_num": [
            "recmWBV7ZAXlmbakJ"
        ],
        "procedure": "PHP",
        "provider": "HARMONY HEALTH CARE"
    },
    {
        "2020": 775,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechHse4prLnXvNE1",
        "row": 3049,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "HCIC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 94,
        "2023": 0,
        "2024": 0,
        "id": "reciZwYFJsBcCmkWw",
        "row": 3052,
        "state": "ARIZONA",
        "group": "HCOK",
        "group_num": [
            "recBelDlWIqgVTSwm"
        ],
        "procedure": "OP",
        "provider": "HEALTH CHOICE OF OK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "2023": 0,
        "2024": 0,
        "id": "recQOJpdSRnXCcrHG",
        "row": 3051,
        "state": "ARIZONA",
        "group": "HCOK",
        "group_num": [
            "recBelDlWIqgVTSwm"
        ],
        "procedure": "IOP",
        "provider": "HEALTH CHOICE OF OK"
    },
    {
        "2020": 0,
        "2021": 944,
        "2022": 944,
        "2023": 0,
        "2024": 0,
        "id": "rectdur2F6O7LFjp5",
        "row": 3050,
        "state": "ARIZONA",
        "group": "HCOK",
        "group_num": [
            "recBelDlWIqgVTSwm"
        ],
        "procedure": "PHP",
        "provider": "HEALTH CHOICE OF OK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "2023": 0,
        "2024": 0,
        "id": "recfkeAmz8FsjYkS6",
        "row": 3054,
        "state": "ARIZONA",
        "group": "0046",
        "group_num": [
            "recjY65dZ2yk408me"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 406,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck2vd3EDOkiHFT3",
        "row": 3055,
        "state": "ARIZONA",
        "group": "2026",
        "group_num": [
            "rec9oMzdAOptDY2mD"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "2023": 0,
        "2024": 0,
        "id": "recN5TOuXQkPC2D7B",
        "row": 3053,
        "state": "ARIZONA",
        "group": "0046",
        "group_num": [
            "recjY65dZ2yk408me"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "rec9l2q4jzXJjheZJ",
        "row": 3057,
        "state": "ARIZONA",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4176,
        "2023": 0,
        "2024": 0,
        "id": "recvMfUMldoXq71uN",
        "row": 3056,
        "state": "ARIZONA",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 392,
        "2023": 0,
        "2024": 0,
        "id": "recJKYraY76Q4BZu3",
        "row": 3058,
        "state": "ARIZONA",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 392,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMbGPJjq48tc0C4",
        "row": 3059,
        "state": "ARIZONA",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 2250,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRzcRuEUiFsCzZi",
        "row": 3062,
        "state": "ARIZONA",
        "group": "7066100",
        "group_num": [
            "recBJFRDfuonQqtle"
        ],
        "procedure": "PHP",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb1aPOrkpC3yGeW",
        "row": 3061,
        "state": "ARIZONA",
        "group": "7066100",
        "group_num": [
            "recBJFRDfuonQqtle"
        ],
        "procedure": "Detox",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfuuJxcuKn7N6F9",
        "row": 3063,
        "state": "ARIZONA",
        "group": "ACNCC016",
        "group_num": [
            "recrrNiHq1Ouej9GM"
        ],
        "procedure": "Detox",
        "provider": "HEALTHLINK"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgZZD5kXfyb6OjS",
        "row": 3065,
        "state": "ARIZONA",
        "group": "MCW",
        "group_num": [
            "recqoCOMCD5lcsu16"
        ],
        "procedure": "Detox",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 3800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmEkoGX8FEbtkPr",
        "row": 3066,
        "state": "ARIZONA",
        "group": "MCW",
        "group_num": [
            "recqoCOMCD5lcsu16"
        ],
        "procedure": "RTC",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 3223,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2D68lNPw2v2W6X",
        "row": 3068,
        "state": "ARIZONA",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "RTC",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 1045,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyWDfh7VoSahZ5q",
        "row": 3069,
        "state": "ARIZONA",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "PHP",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 3051,
        "2021": 2062,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLtphkoovnUpSqW",
        "row": 3067,
        "state": "ARIZONA",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "Detox",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3qoVuOlZXPsK0r",
        "row": 3070,
        "state": "ARIZONA",
        "group": "020429",
        "group_num": [
            "recB3wuwIPzSuy97o"
        ],
        "procedure": "Detox",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 1325,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQI6VPkkwUpFEWu",
        "row": 3072,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 925,
        "2021": 925,
        "2022": 925,
        "2023": 0,
        "2024": 0,
        "id": "recZq4VEVRKjtgGAW",
        "row": 3074,
        "state": "ARIZONA",
        "group": "UFC001",
        "group_num": [
            "recJYYg5P2tLl8unS"
        ],
        "procedure": "PHP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 709,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccLHbB6ZS3kIqPr",
        "row": 3075,
        "state": "ARIZONA",
        "group": "UFC001",
        "group_num": [
            "recJYYg5P2tLl8unS"
        ],
        "procedure": "IOP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 1500,
        "2021": 1500,
        "2022": 1227,
        "2023": 0,
        "2024": 0,
        "id": "recpH48oPdNivMLcF",
        "row": 3073,
        "state": "ARIZONA",
        "group": "UFC001",
        "group_num": [
            "recJYYg5P2tLl8unS"
        ],
        "procedure": "Detox",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 1500,
        "2023": 0,
        "2024": 0,
        "id": "recp8A5gttYKefYwF",
        "row": 3071,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 17,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHpWewr3KAQzyC9",
        "row": 3076,
        "state": "ARIZONA",
        "group": "UFC001",
        "group_num": [
            "recJYYg5P2tLl8unS"
        ],
        "procedure": "OP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 1160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWVnFKczDUmLcPd",
        "row": 3077,
        "state": "ARIZONA",
        "group": "0113012",
        "group_num": [
            "recbYWyDzxl2LZMk2"
        ],
        "procedure": "Detox",
        "provider": "HOLMAN GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 675,
        "2023": 675,
        "2024": 0,
        "id": "recJIbpFnM6c31YKP",
        "row": 3078,
        "state": "ARIZONA",
        "group": "400143",
        "group_num": [
            "recX4jdA5XnkxL2PM"
        ],
        "procedure": "PHP",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 2029,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQdICsyXLVjXN1r",
        "row": 3083,
        "state": "ARIZONA",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 2029,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "rec78eLwwpTX7dzVw",
        "row": 3081,
        "state": "ARIZONA",
        "group": "840881",
        "group_num": [
            "recY48WqWZIWpXhYt"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 2029,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd0XJOlN82n42qO",
        "row": 3080,
        "state": "ARIZONA",
        "group": "808902",
        "group_num": [
            "recOBkd1L2CQePvaP"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "2023": 0,
        "2024": 0,
        "id": "recGuxiMAfqjuSLsU",
        "row": 3082,
        "state": "ARIZONA",
        "group": "840881",
        "group_num": [
            "recY48WqWZIWpXhYt"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recKjtfR157v39iO5",
        "row": 3079,
        "state": "ARIZONA",
        "group": "619668",
        "group_num": [
            "recltlmqKCxBTxvVc"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 957,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrNcvDUzQqTJYuz",
        "row": 3084,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "INSURANCE BENEFIT ADMINISTRATORS"
    },
    {
        "2020": 76,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyhzOyWMLjdGOq4",
        "row": 3085,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "INSURANCE BENEFIT ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 4000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgeZnW4ZRIHyqLB",
        "row": 3087,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "INSURANCE MANAGEMENT SERVICES"
    },
    {
        "2020": 1125,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckWVVkSbenucO4f",
        "row": 3089,
        "state": "ARIZONA",
        "group": "8193500",
        "group_num": [
            "recW3Xt3rGOK0rzdP"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 2136,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4Y1lnkM4DxWjwR",
        "row": 3088,
        "state": "ARIZONA",
        "group": "8193500",
        "group_num": [
            "recW3Xt3rGOK0rzdP"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 4157,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckRNXEb9xuIQVRJ",
        "row": 3091,
        "state": "ARIZONA",
        "group": "S93",
        "group_num": [
            "recEbXo5H5vNasc7R"
        ],
        "procedure": "Detox",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 906,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0P04gzqvoISSQE",
        "row": 3092,
        "state": "ARIZONA",
        "group": "S93",
        "group_num": [
            "recEbXo5H5vNasc7R"
        ],
        "procedure": "RTC",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 1100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckUoyuQd2EkLmux",
        "row": 3098,
        "state": "ARIZONA",
        "group": "0070005620030004",
        "group_num": [
            "recCEiEeS3Fv9Yjr5"
        ],
        "procedure": "PHP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPIu7TgNalogGwg",
        "row": 3097,
        "state": "ARIZONA",
        "group": "028565801000003",
        "group_num": [
            "receONJk51hckQoVw"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recQvS67LUIpQ8SjG",
        "row": 3095,
        "state": "ARIZONA",
        "group": "000740803A",
        "group_num": [
            "recnPIZo8YcVyx0f3"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 321,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2ZsAa2SQ3tAT2g",
        "row": 3093,
        "state": "ARIZONA",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaV4W97uQh75kau",
        "row": 3096,
        "state": "ARIZONA",
        "group": "526692189",
        "group_num": [
            "recXd0ub15mTstmVE"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbxrIoTQAUA3voE",
        "row": 3094,
        "state": "ARIZONA",
        "group": "76413978",
        "group_num": [
            "rec1boXh7jciGWClv"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 2375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLBYU2V4fiKxURv",
        "row": 3099,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 761,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJkB3TAoHXcowYy",
        "row": 3100,
        "state": "ARIZONA",
        "group": "3716",
        "group_num": [
            "rec6fvXFiA1fR1PZI"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN COMPLETE CARE"
    },
    {
        "2020": 666,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKvV9bBhB2cWr0W",
        "row": 3101,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN COMPLETE CARE"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1Ub3V4cRJDlE6O",
        "row": 3102,
        "state": "ARIZONA",
        "group": "2214",
        "group_num": [
            "recmTBzk99rjqDGzi"
        ],
        "procedure": "Detox",
        "provider": "MAGNACARE"
    },
    {
        "2020": 2950,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpToITac5PEQxyH",
        "row": 3104,
        "state": "ARIZONA",
        "group": "2214",
        "group_num": [
            "recmTBzk99rjqDGzi"
        ],
        "procedure": "IOP",
        "provider": "MAGNACARE"
    },
    {
        "2020": 3686,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJzc040M2R4H19I",
        "row": 3103,
        "state": "ARIZONA",
        "group": "2214",
        "group_num": [
            "recmTBzk99rjqDGzi"
        ],
        "procedure": "PHP",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 0,
        "2024": 0,
        "id": "recdKZWooA2q8mOk4",
        "row": 3106,
        "state": "ARIZONA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MAYO MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recT4LK9rtmJOg6yR",
        "row": 3109,
        "state": "ARIZONA",
        "group": "P14479",
        "group_num": [
            "recLdQkdbuKla26BF"
        ],
        "procedure": "OP",
        "provider": "MEDCARE TEAMESTERS LOCAL 731"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "2023": 0,
        "2024": 0,
        "id": "reczx04ErxXWWgzLV",
        "row": 3108,
        "state": "ARIZONA",
        "group": "P14479",
        "group_num": [
            "recLdQkdbuKla26BF"
        ],
        "procedure": "IOP",
        "provider": "MEDCARE TEAMESTERS LOCAL 731"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recLwmCCIyVJwr3UW",
        "row": 3107,
        "state": "ARIZONA",
        "group": "P14479",
        "group_num": [
            "recLdQkdbuKla26BF"
        ],
        "procedure": "PHP",
        "provider": "MEDCARE TEAMESTERS LOCAL 731"
    },
    {
        "2020": 0,
        "2021": 1200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpaUr6G9UIeeAaj",
        "row": 3111,
        "state": "ARIZONA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "PHP",
        "provider": "MEDICA"
    },
    {
        "2020": 3643,
        "2021": 2400,
        "2022": 2400,
        "2023": 0,
        "2024": 0,
        "id": "recJeMYETlV0F2YVJ",
        "row": 3110,
        "state": "ARIZONA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 1724,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEo5wE96snLeoD6",
        "row": 3113,
        "state": "ARIZONA",
        "group": "594836023",
        "group_num": [
            "recVulDQJqbIrwgsh"
        ],
        "procedure": "Detox",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 774,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfgAXgIyDcP2Ipw",
        "row": 3120,
        "state": "ARIZONA",
        "group": "14450",
        "group_num": [
            "recBBNnEtEXxq1qTZ"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1381,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechTJEk54OVnmMnv",
        "row": 3117,
        "state": "ARIZONA",
        "group": "14375",
        "group_num": [
            "recaVwqQZGZFdvZx8"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1093,
        "2022": 1093,
        "2023": 0,
        "2024": 0,
        "id": "recl99XeeUZDE263k",
        "row": 3125,
        "state": "ARIZONA",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1381,
        "2023": 0,
        "2024": 0,
        "id": "recmvNlzcqGH6Jagx",
        "row": 3134,
        "state": "ARIZONA",
        "group": "KC143",
        "group_num": [
            "rec8Un7dNo9Sydt5v"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 550,
        "2022": 690,
        "2023": 0,
        "2024": 0,
        "id": "recSKZFYOy6OmKxow",
        "row": 3126,
        "state": "ARIZONA",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 2639,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTJoktgTivOiF0W",
        "row": 3129,
        "state": "ARIZONA",
        "group": "AK067",
        "group_num": [
            "reciVgjrLcZUo2Vap"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1741,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVPz94jF4rbwP4l",
        "row": 3122,
        "state": "ARIZONA",
        "group": "15808",
        "group_num": [
            "recqp0tyD3XzlqekK"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1016,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZh2jqhyMInxn76",
        "row": 3133,
        "state": "ARIZONA",
        "group": "B5011",
        "group_num": [
            "recymN0rrIYLGwemI"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 1759,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1x6CoHiJd7IfRC",
        "row": 3136,
        "state": "ARIZONA",
        "group": "W7572",
        "group_num": [
            "recXtZnlTrumPU3xf"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 841,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1AJylqqNlKgnuo",
        "row": 3123,
        "state": "ARIZONA",
        "group": "16280",
        "group_num": [
            "recj9EkM5Bz6rSDAW"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 796,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2Obyi52pGHjzXZ",
        "row": 3130,
        "state": "ARIZONA",
        "group": "AK067",
        "group_num": [
            "reciVgjrLcZUo2Vap"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1451,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec45nC3fiKueti9Z",
        "row": 3116,
        "state": "ARIZONA",
        "group": "13778",
        "group_num": [
            "recz9CPIGTpAgYBkM"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 2002,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9OLhLMcDUv7tOE",
        "row": 3135,
        "state": "ARIZONA",
        "group": "W7572",
        "group_num": [
            "recXtZnlTrumPU3xf"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 1741,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9g46mYvcy9vZTC",
        "row": 3121,
        "state": "ARIZONA",
        "group": "15776",
        "group_num": [
            "recbdIqCn1YG6sBlD"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1381,
        "2022": 1381,
        "2023": 0,
        "2024": 0,
        "id": "reccYbTe8OYN8NpIP",
        "row": 3124,
        "state": "ARIZONA",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1381,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqClDfIKXGzL4wL",
        "row": 3128,
        "state": "ARIZONA",
        "group": "AK067",
        "group_num": [
            "reciVgjrLcZUo2Vap"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 669,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwQvI7Lg82GL18B",
        "row": 3114,
        "state": "ARIZONA",
        "group": "13626",
        "group_num": [
            "recwrTWdG4GaKun0z"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 558,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyfQe452rXZo4wY",
        "row": 3118,
        "state": "ARIZONA",
        "group": "14375",
        "group_num": [
            "recaVwqQZGZFdvZx8"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1381,
        "2023": 0,
        "2024": 0,
        "id": "recFw7kWS8zkamNKN",
        "row": 3115,
        "state": "ARIZONA",
        "group": "13737",
        "group_num": [
            "recSdK2wQha1Y2fgW"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1741,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIO8v3dkvPdzZhP",
        "row": 3132,
        "state": "ARIZONA",
        "group": "B5011",
        "group_num": [
            "recymN0rrIYLGwemI"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1127,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK5uFWOGKu5NzdB",
        "row": 3127,
        "state": "ARIZONA",
        "group": "91016",
        "group_num": [
            "rec09jieASuLSFD7I"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recObnaqACYd1OmwV",
        "row": 3138,
        "state": "ARIZONA",
        "group": "CL400",
        "group_num": [
            "reczCimjaCSMBSOry"
        ],
        "procedure": "Detox",
        "provider": "MINES AND ASSOCIATES"
    },
    {
        "2020": 310,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFB2EvYLzhtxoGZ",
        "row": 3139,
        "state": "ARIZONA",
        "group": "CL400",
        "group_num": [
            "reczCimjaCSMBSOry"
        ],
        "procedure": "PHP",
        "provider": "MINES AND ASSOCIATES"
    },
    {
        "2020": 3375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIYsU4vL4a6l7tl",
        "row": 3140,
        "state": "ARIZONA",
        "group": "76410425",
        "group_num": [
            "recaKUK6WvpriPfy2"
        ],
        "procedure": "PHP",
        "provider": "MISSOURI CONSOLIDATED HEALTH"
    },
    {
        "2020": 2281,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPYGEeUhLVaYnAk",
        "row": 3141,
        "state": "ARIZONA",
        "group": "10004763",
        "group_num": [
            "recR4fiBdBcUSoT3Y"
        ],
        "procedure": "Detox",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 565,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5hdbWmL5zhE5bj",
        "row": 3143,
        "state": "ARIZONA",
        "group": "10004763",
        "group_num": [
            "recR4fiBdBcUSoT3Y"
        ],
        "procedure": "PHP",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 2281,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDguijGkLIIAlYZ",
        "row": 3142,
        "state": "ARIZONA",
        "group": "10004763",
        "group_num": [
            "recR4fiBdBcUSoT3Y"
        ],
        "procedure": "RTC",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 593,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRs7kOZr9GZlwl3",
        "row": 3145,
        "state": "ARIZONA",
        "group": "WELINK2SN",
        "group_num": [
            "recqqasPOkyxDEgEn"
        ],
        "procedure": "Detox",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 33,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgGXoAuFxoTN3VB",
        "row": 3146,
        "state": "ARIZONA",
        "group": "1641810247",
        "group_num": [
            "rectbznSZOXMww2lb"
        ],
        "procedure": "OP",
        "provider": "MOUNTAIN HEALTH CO-OP"
    },
    {
        "2020": 5100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbbGN4YMUsE9xAF",
        "row": 3147,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 1398,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl2VsYyNjSwKsqL",
        "row": 3148,
        "state": "ARIZONA",
        "group": "2001796",
        "group_num": [
            "recJMKbnKNnbFBEah"
        ],
        "procedure": "Detox",
        "provider": "NETWORK HEALTH"
    },
    {
        "2020": 217,
        "2021": 232,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVD1OLUMi3avu7e",
        "row": 3149,
        "state": "ARIZONA",
        "group": "2001796",
        "group_num": [
            "recJMKbnKNnbFBEah"
        ],
        "procedure": "PHP",
        "provider": "NETWORK HEALTH"
    },
    {
        "2020": 0,
        "2021": 242,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7MmSH33uyj2Lne",
        "row": 3150,
        "state": "ARIZONA",
        "group": "2001796",
        "group_num": [
            "recJMKbnKNnbFBEah"
        ],
        "procedure": "IOP",
        "provider": "NETWORK HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "2023": 0,
        "2024": 0,
        "id": "recWDJYbwmtGXF79C",
        "row": 3151,
        "state": "ARIZONA",
        "group": "N70700",
        "group_num": [
            "recN5BAWGHWkm5EFf"
        ],
        "procedure": "RTC",
        "provider": "NIPPON LIFE INSURANCE COMPANY"
    },
    {
        "2020": 256,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvKsaGGsKEkggXi",
        "row": 3154,
        "state": "ARIZONA",
        "group": "OET001",
        "group_num": [
            "recvNB1edFb2Y9qSB"
        ],
        "procedure": "OP",
        "provider": "OPERATING ENGINEERS"
    },
    {
        "2020": 740,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxx5agWQeRYxZD6",
        "row": 3152,
        "state": "ARIZONA",
        "group": "OET001",
        "group_num": [
            "recvNB1edFb2Y9qSB"
        ],
        "procedure": "Detox",
        "provider": "OPERATING ENGINEERS"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKJaOqYQlHJZMCI",
        "row": 3153,
        "state": "ARIZONA",
        "group": "OET001",
        "group_num": [
            "recvNB1edFb2Y9qSB"
        ],
        "procedure": "PHP",
        "provider": "OPERATING ENGINEERS"
    },
    {
        "2020": 35,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjpF5Q7q2V4MFWQ",
        "row": 3156,
        "state": "ARIZONA",
        "group": "3422",
        "group_num": [
            "recIwrinQ4eA2O3Em"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3596,
        "2023": 0,
        "2024": 0,
        "id": "recVwvmh1cbJX92x0",
        "row": 3159,
        "state": "ARIZONA",
        "group": "1330921",
        "group_num": [
            "recZlZqLMk5hGPEIC"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 1084,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXTWaH2o0EOczAj",
        "row": 3162,
        "state": "ARIZONA",
        "group": "AT2285",
        "group_num": [
            "recU1v7SdhKovg8ar"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "rec0R0JUwpWmKojtW",
        "row": 3157,
        "state": "ARIZONA",
        "group": "1270239",
        "group_num": [
            "recE1HYSF0Wtcxuba"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 4800,
        "2023": 0,
        "2024": 0,
        "id": "recaQ2cwl5zSFkgjr",
        "row": 3158,
        "state": "ARIZONA",
        "group": "1330921",
        "group_num": [
            "recZlZqLMk5hGPEIC"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1168,
        "2024": 0,
        "id": "recKVs345qjswRa4c",
        "row": 3161,
        "state": "ARIZONA",
        "group": "1340984",
        "group_num": [
            "recdmRWcDIBweB0KF"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "2023": 0,
        "2024": 0,
        "id": "recM2kKVYAPaGHwoZ",
        "row": 3160,
        "state": "ARIZONA",
        "group": "1330921",
        "group_num": [
            "recZlZqLMk5hGPEIC"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec16T6L2o2lSQQes",
        "row": 3163,
        "state": "ARIZONA",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 45,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQucSYgCfqpjz5z",
        "row": 3168,
        "state": "ARIZONA",
        "group": "PKA20291",
        "group_num": [
            "recMbzmggtXE2zom5"
        ],
        "procedure": "OP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 625,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ5xZeIUz8PhocW",
        "row": 3167,
        "state": "ARIZONA",
        "group": "PKA20074",
        "group_num": [
            "recEWi7Hu2wxMrrMB"
        ],
        "procedure": "PHP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 1620,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY78lYtY92fuM5c",
        "row": 3169,
        "state": "ARIZONA",
        "group": "PKA20412",
        "group_num": [
            "recK9vSWLtwOavy6x"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recdHAkLtdlVjvLgx",
        "row": 3166,
        "state": "ARIZONA",
        "group": "GRV10758",
        "group_num": [
            "recp13Yic2otTVpZJ"
        ],
        "procedure": "IOP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 825,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuFLKnUNTUZ8sgW",
        "row": 3170,
        "state": "ARIZONA",
        "group": "PKA20412",
        "group_num": [
            "recK9vSWLtwOavy6x"
        ],
        "procedure": "PHP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recAd9ckKBzGpFxLy",
        "row": 3165,
        "state": "ARIZONA",
        "group": "GRV10758",
        "group_num": [
            "recp13Yic2otTVpZJ"
        ],
        "procedure": "PHP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 1290,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclaVV1hcRuLVhDr",
        "row": 3173,
        "state": "ARIZONA",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 2760,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1aX46p9JObcTHt",
        "row": 3175,
        "state": "ARIZONA",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "Detox",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 3420,
        "2022": 3420,
        "2023": 0,
        "2024": 0,
        "id": "recqjzZSED5tumLJq",
        "row": 3172,
        "state": "ARIZONA",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "Detox",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 520,
        "2021": 662,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrYGC2nzeCrTNfM",
        "row": 3174,
        "state": "ARIZONA",
        "group": "2255182",
        "group_num": [
            "rec4NAl31tW59ncWt"
        ],
        "procedure": "OP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciJd1Nns8u9bsxD",
        "row": 3178,
        "state": "ARIZONA",
        "group": "44172",
        "group_num": [
            "recvy9uGczofqNEuT"
        ],
        "procedure": "OP",
        "provider": "QUICKTRIP"
    },
    {
        "2020": 450,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1NVYrfnCgCyHYO",
        "row": 3177,
        "state": "ARIZONA",
        "group": "44172",
        "group_num": [
            "recvy9uGczofqNEuT"
        ],
        "procedure": "IOP",
        "provider": "QUICKTRIP"
    },
    {
        "2020": 120,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLMsiuqWQCYBAWH",
        "row": 3176,
        "state": "ARIZONA",
        "group": "44172",
        "group_num": [
            "recvy9uGczofqNEuT"
        ],
        "procedure": "PHP",
        "provider": "QUICKTRIP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 0,
        "2024": 0,
        "id": "reciwrOcgUlbLAQSM",
        "row": 3181,
        "state": "ARIZONA",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "RTC",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "2023": 0,
        "2024": 0,
        "id": "rec5iqwCTy5VZGHsu",
        "row": 3182,
        "state": "ARIZONA",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "PHP",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyBtuCP7Kmp30P2",
        "row": 3179,
        "state": "ARIZONA",
        "group": "0004980002",
        "group_num": [
            "recr8PbpZOMzO05sS"
        ],
        "procedure": "PHP",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 2500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7qxDyzyMWMMOzL",
        "row": 3183,
        "state": "ARIZONA",
        "group": "GRC",
        "group_num": [
            "rechffATsYZsmXzvk"
        ],
        "procedure": "Detox",
        "provider": "SHASTA"
    },
    {
        "2020": 0,
        "2021": 76,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciXJ8fNMSONAsQS",
        "row": 3185,
        "state": "ARIZONA",
        "group": "000SFV834WU1P05",
        "group_num": [
            "recok87HydgRp2VN3"
        ],
        "procedure": "IOP",
        "provider": "SMITHFIELD FOOD CLAIMS"
    },
    {
        "2020": 0,
        "2021": 119,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRc0IeFxSpnufY8",
        "row": 3184,
        "state": "ARIZONA",
        "group": "000SFV834WU1P05",
        "group_num": [
            "recok87HydgRp2VN3"
        ],
        "procedure": "PHP",
        "provider": "SMITHFIELD FOOD CLAIMS"
    },
    {
        "2020": 0,
        "2021": 685,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxBZvheZaZ6j2xV",
        "row": 3186,
        "state": "ARIZONA",
        "group": "ASM001",
        "group_num": [
            "recGrLGQrjHdPtbmC"
        ],
        "procedure": "Detox",
        "provider": "SOUTHWEST ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 261,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receAuLXRngQLC7Me",
        "row": 3187,
        "state": "ARIZONA",
        "group": "264",
        "group_num": [
            "recJrlR69gXEFlUaI"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receoyXSza3gyqutW",
        "row": 3197,
        "state": "ARIZONA",
        "group": "GHC001",
        "group_num": [
            "recxWvGKeUc4qiyhg"
        ],
        "procedure": "IOP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 227,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj3v8N4vPVi2QPS",
        "row": 3196,
        "state": "ARIZONA",
        "group": "GHC001",
        "group_num": [
            "recxWvGKeUc4qiyhg"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 686,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclEPOhBk7fEcXlr",
        "row": 3195,
        "state": "ARIZONA",
        "group": "GHC001",
        "group_num": [
            "recxWvGKeUc4qiyhg"
        ],
        "procedure": "RTC",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 337,
        "2022": 261,
        "2023": 0,
        "2024": 0,
        "id": "recRcyrnlGsRofmUw",
        "row": 3190,
        "state": "ARIZONA",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 436,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWtlEvwLJh6zT7s",
        "row": 3199,
        "state": "ARIZONA",
        "group": "NVS001",
        "group_num": [
            "recYEkfdMCTT7pG3V"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 1200,
        "2023": 0,
        "2024": 0,
        "id": "recY8AJoGicluwUyo",
        "row": 3192,
        "state": "ARIZONA",
        "group": "2003410",
        "group_num": [
            "recd1OvxHPJsyPwZB"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 1650,
        "2022": 690,
        "2023": 0,
        "2024": 0,
        "id": "recYh7LftLVL7NB6w",
        "row": 3193,
        "state": "ARIZONA",
        "group": "2003410",
        "group_num": [
            "recd1OvxHPJsyPwZB"
        ],
        "procedure": "RTC",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 502,
        "2023": 0,
        "2024": 0,
        "id": "rec2J5gVgUANJ4oHG",
        "row": 3194,
        "state": "ARIZONA",
        "group": "GHC001",
        "group_num": [
            "recxWvGKeUc4qiyhg"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 227,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6eD6yHlb7z1KwC",
        "row": 3188,
        "state": "ARIZONA",
        "group": "264",
        "group_num": [
            "recJrlR69gXEFlUaI"
        ],
        "procedure": "IOP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 261,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdOkLvEWIUqNghl",
        "row": 3202,
        "state": "ARIZONA",
        "group": "YCT001",
        "group_num": [
            "recFlhFmS10xalCZ0"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 467,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvnjatxiUqkS3Gg",
        "row": 3200,
        "state": "ARIZONA",
        "group": "NVS001",
        "group_num": [
            "recYEkfdMCTT7pG3V"
        ],
        "procedure": "IOP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 947,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIzfUcpOD0GVVDM",
        "row": 3191,
        "state": "ARIZONA",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "IOP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 412,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNTedoYoHlOlfm3",
        "row": 3203,
        "state": "ARIZONA",
        "group": "YCT001",
        "group_num": [
            "recFlhFmS10xalCZ0"
        ],
        "procedure": "OP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recbFXIoeDd93B5Bw",
        "row": 3204,
        "state": "ARIZONA",
        "group": "SFITS",
        "group_num": [
            "recspfg9JoW1dsQ8a"
        ],
        "procedure": "Detox",
        "provider": "TALL TREE ADMINISTRATORS"
    },
    {
        "2020": 306,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTgRaxz33ZkA5Rf",
        "row": 3205,
        "state": "ARIZONA",
        "group": "2406",
        "group_num": [
            "recRgiBxCGRCxXYZ9"
        ],
        "procedure": "PHP",
        "provider": "TRANSWESTERN/HEALTH COMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "2023": 461,
        "2024": 0,
        "id": "recfWXB88bwuYu7tV",
        "row": 3207,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "TRICARE EAST"
    },
    {
        "2020": 0,
        "2021": 21,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOaDt1XTWAiEFQb",
        "row": 3209,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "TRICARE EAST"
    },
    {
        "2020": 0,
        "2021": 337,
        "2022": 337,
        "2023": 0,
        "2024": 0,
        "id": "recpoSEmzxrAudwg5",
        "row": 3208,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "TRICARE EAST"
    },
    {
        "2020": 823,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgZkX6sdUzpgjub",
        "row": 3210,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 658,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjYYt25w4dRbP6Z",
        "row": 3211,
        "state": "ARIZONA",
        "group": "CNP",
        "group_num": [
            "rec5KiOF36qC8wIqC"
        ],
        "procedure": "Detox",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 2759,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfHzYlQhkw6ioJs",
        "row": 3223,
        "state": "ARIZONA",
        "group": "76410082",
        "group_num": [
            "recS7s6UU24XMDG96"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2925,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechDnTHTfpjJGGlp",
        "row": 3222,
        "state": "ARIZONA",
        "group": "76070716",
        "group_num": [
            "recxAijfgnlsVcyH1"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2677,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciY1rrwPXmWDqbX",
        "row": 3214,
        "state": "ARIZONA",
        "group": "07067559",
        "group_num": [
            "recBu9WnXDyf8hsSD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjmj1b9WK1NKG8z",
        "row": 3215,
        "state": "ARIZONA",
        "group": "07067559",
        "group_num": [
            "recBu9WnXDyf8hsSD"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 3375,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckQzPgkFklWi0CS",
        "row": 3216,
        "state": "ARIZONA",
        "group": "07067559",
        "group_num": [
            "recBu9WnXDyf8hsSD"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 1100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclmqgKNHGuIU1uH",
        "row": 3240,
        "state": "ARIZONA",
        "group": "76411365",
        "group_num": [
            "recnMfJ0ytJilr5g3"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2615,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnfvamAxcns0XKo",
        "row": 3228,
        "state": "ARIZONA",
        "group": "76410183",
        "group_num": [
            "reczqlE4VVtJpt7bf"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS4Nb5nXpM3mhkS",
        "row": 3238,
        "state": "ARIZONA",
        "group": "76411331",
        "group_num": [
            "rec6rCt7AD48x4YWJ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2767,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUDv7u3q3Hrrk0K",
        "row": 3235,
        "state": "ARIZONA",
        "group": "76411292",
        "group_num": [
            "recq8n5PQ9DuZUuBp"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVYWPwL2xCu7Cxv",
        "row": 3244,
        "state": "ARIZONA",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 990,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWOqckDLZcsxctU",
        "row": 3241,
        "state": "ARIZONA",
        "group": "76411365",
        "group_num": [
            "recnMfJ0ytJilr5g3"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1902,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXFbEXWgoVpviol",
        "row": 3247,
        "state": "ARIZONA",
        "group": "76411449",
        "group_num": [
            "recIFHFnhpXznbVO3"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 653,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYQ3W80qSyEoaYY",
        "row": 3229,
        "state": "ARIZONA",
        "group": "76410595",
        "group_num": [
            "recWkk2Rbx2MgON1p"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZVmR3q6ecukzVs",
        "row": 3217,
        "state": "ARIZONA",
        "group": "07067559",
        "group_num": [
            "recBu9WnXDyf8hsSD"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3600,
        "2022": 2880,
        "2023": 0,
        "2024": 0,
        "id": "recZjxz8XkALpFjzr",
        "row": 3221,
        "state": "ARIZONA",
        "group": "76070085",
        "group_num": [
            "rec1bH3K7BedYbSK9"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4nLxKEWBsUn8PZ",
        "row": 3233,
        "state": "ARIZONA",
        "group": "76411219",
        "group_num": [
            "recclpJhQqUKXvdPP"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 750,
        "2021": 750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5L6AUhvoBkBjde",
        "row": 3234,
        "state": "ARIZONA",
        "group": "76411219",
        "group_num": [
            "recclpJhQqUKXvdPP"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 2500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec626Z6RPjIzmkZU",
        "row": 3237,
        "state": "ARIZONA",
        "group": "76411331",
        "group_num": [
            "rec6rCt7AD48x4YWJ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec978euqG2YFfcdG",
        "row": 3236,
        "state": "ARIZONA",
        "group": "76411323",
        "group_num": [
            "recne9s1SNt9mTvi4"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 3375,
        "2021": 2936,
        "2022": 3375,
        "2023": 0,
        "2024": 0,
        "id": "reccekgwjBa1NxR6O",
        "row": 3219,
        "state": "ARIZONA",
        "group": "76030038",
        "group_num": [
            "recMNdzBMyP2POHEB"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 846,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdQtTg2iDwIMngv",
        "row": 3226,
        "state": "ARIZONA",
        "group": "76410087",
        "group_num": [
            "rec8RLZyk99b23wOK"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 0,
        "2024": 0,
        "id": "recpfgeKV4tc2xNQG",
        "row": 3213,
        "state": "ARIZONA",
        "group": "7412547",
        "group_num": [
            "recZuVRabY0UB4KzD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 416,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvzr3UyDdj3hUDn",
        "row": 3239,
        "state": "ARIZONA",
        "group": "76411331",
        "group_num": [
            "rec6rCt7AD48x4YWJ"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 2700,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwSj9LNUETCmtFF",
        "row": 3230,
        "state": "ARIZONA",
        "group": "76410631",
        "group_num": [
            "recd2kK76brWxz7Jn"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 3300,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxIih8YukUMLDWh",
        "row": 3218,
        "state": "ARIZONA",
        "group": "76030038",
        "group_num": [
            "recMNdzBMyP2POHEB"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 4625,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCVenPTM0wUo4Lh",
        "row": 3227,
        "state": "ARIZONA",
        "group": "76410164",
        "group_num": [
            "recjr8aJEeY7gKw2J"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFBbT10w9c9KGxz",
        "row": 3246,
        "state": "ARIZONA",
        "group": "76411449",
        "group_num": [
            "recIFHFnhpXznbVO3"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 152,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHJJ78JHezpZ8dA",
        "row": 3242,
        "state": "ARIZONA",
        "group": "76411365",
        "group_num": [
            "recnMfJ0ytJilr5g3"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recJCYhjsJxLtgSle",
        "row": 3220,
        "state": "ARIZONA",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2850,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJgyo3wWRjKga2H",
        "row": 3224,
        "state": "ARIZONA",
        "group": "76410087",
        "group_num": [
            "rec8RLZyk99b23wOK"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKHBqhwjN8M6CmY",
        "row": 3243,
        "state": "ARIZONA",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 266,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLxaR538gprT1E5",
        "row": 3231,
        "state": "ARIZONA",
        "group": "76410631",
        "group_num": [
            "recd2kK76brWxz7Jn"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM2WacLfCDShih5",
        "row": 3232,
        "state": "ARIZONA",
        "group": "76411219",
        "group_num": [
            "recclpJhQqUKXvdPP"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2655,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNX5gBohGsH9C4A",
        "row": 3225,
        "state": "ARIZONA",
        "group": "76410087",
        "group_num": [
            "rec8RLZyk99b23wOK"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1899,
        "2021": 2325,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receorQXvtIqPp3sy",
        "row": 3264,
        "state": "ARIZONA",
        "group": "76411966",
        "group_num": [
            "recHl6rDGXRQWnOkE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 1890,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgc4cWP0DK1WBsd",
        "row": 3284,
        "state": "ARIZONA",
        "group": "76413116",
        "group_num": [
            "recmqGxpb1QFrIS2Z"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2438,
        "2023": 0,
        "2024": 0,
        "id": "recha09G8gRB4VIlZ",
        "row": 3251,
        "state": "ARIZONA",
        "group": "76411658",
        "group_num": [
            "recrEia0fqXAQdbB5"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 1161,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciDF4Goe7BuIh9q",
        "row": 3291,
        "state": "ARIZONA",
        "group": "76414102",
        "group_num": [
            "rec5lKcy1MUnFlTOV"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 3800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recinLb2cw9cIH88H",
        "row": 3282,
        "state": "ARIZONA",
        "group": "76413116",
        "group_num": [
            "recmqGxpb1QFrIS2Z"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 3375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl1Yng8Q58OuhrP",
        "row": 3267,
        "state": "ARIZONA",
        "group": "76412071",
        "group_num": [
            "recE95yrfL3fPdx4B"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3020,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmDDITvOENrqOAY",
        "row": 3263,
        "state": "ARIZONA",
        "group": "76411966",
        "group_num": [
            "recHl6rDGXRQWnOkE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2653,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm7CQTVjzTO4XJL",
        "row": 3270,
        "state": "ARIZONA",
        "group": "76412231",
        "group_num": [
            "receUQlkTEfpdQikp"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2739,
        "2021": 0,
        "2022": 4800,
        "2023": 0,
        "2024": 0,
        "id": "recn4uV4KBYUKGj0c",
        "row": 3262,
        "state": "ARIZONA",
        "group": "76411920",
        "group_num": [
            "recDVtNVLMw5AkF58"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 2864,
        "2024": 0,
        "id": "recPjyzpVIOi8kEie",
        "row": 3256,
        "state": "ARIZONA",
        "group": "76411829",
        "group_num": [
            "recfBgJzTIarmnMHD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 1475,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUQ5w8jdH51O8lK",
        "row": 3248,
        "state": "ARIZONA",
        "group": "76411449",
        "group_num": [
            "recIFHFnhpXznbVO3"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 3800,
        "2021": 0,
        "2022": 3800,
        "2023": 0,
        "2024": 0,
        "id": "recV1c1xVHooSiYWT",
        "row": 3253,
        "state": "ARIZONA",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ1OpBUkhywmdUH",
        "row": 3278,
        "state": "ARIZONA",
        "group": "76412547",
        "group_num": [
            "recZ5Uc9s0yDgAqRP"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 3375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZmAPjrYnJIIZh0",
        "row": 3292,
        "state": "ARIZONA",
        "group": "76414153",
        "group_num": [
            "recPiHeiYtmdk7tHI"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0vmeYYOSjRRI4Y",
        "row": 3250,
        "state": "ARIZONA",
        "group": "76411650",
        "group_num": [
            "recN83gVtsH5OkwlR"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0xjn7Wef4zZv6G",
        "row": 3260,
        "state": "ARIZONA",
        "group": "76411858",
        "group_num": [
            "recIeacIIXiURM5Wi"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "2023": 0,
        "2024": 0,
        "id": "rec1TgnZx3kvPj9tS",
        "row": 3254,
        "state": "ARIZONA",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec11UMtH1X7xd6bR",
        "row": 3249,
        "state": "ARIZONA",
        "group": "76411520",
        "group_num": [
            "recLqVSMPd7UszFF5"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3gkkEsaDlztjl2",
        "row": 3286,
        "state": "ARIZONA",
        "group": "76413572",
        "group_num": [
            "reconMSNaIhRlmpdc"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 3773,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4EsE1Iy6JIWKVS",
        "row": 3266,
        "state": "ARIZONA",
        "group": "76412071",
        "group_num": [
            "recE95yrfL3fPdx4B"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3055,
        "2024": 0,
        "id": "rec5xFlKR70PpSdNO",
        "row": 3257,
        "state": "ARIZONA",
        "group": "76411829",
        "group_num": [
            "recfBgJzTIarmnMHD"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3720,
        "2023": 0,
        "2024": 0,
        "id": "rec7cg85No32pRv95",
        "row": 3272,
        "state": "ARIZONA",
        "group": "76412431",
        "group_num": [
            "recTumNUZEomaix9C"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "rec8zDzYJR47aAH6O",
        "row": 3295,
        "state": "ARIZONA",
        "group": "76414159",
        "group_num": [
            "recWwVrDuqjWVaAzE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9xrHv1gBonirVh",
        "row": 3277,
        "state": "ARIZONA",
        "group": "76412547",
        "group_num": [
            "recZ5Uc9s0yDgAqRP"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recamIMK9kzdQUXJy",
        "row": 3274,
        "state": "ARIZONA",
        "group": "76412505",
        "group_num": [
            "recrFJaduPKagxgHL"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2880,
        "2023": 0,
        "2024": 0,
        "id": "recbXZbMtc9FKLISd",
        "row": 3289,
        "state": "ARIZONA",
        "group": "76414011",
        "group_num": [
            "recKxZQqRZh4MHtIL"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb95I0ncnjRt06b",
        "row": 3259,
        "state": "ARIZONA",
        "group": "76411858",
        "group_num": [
            "recIeacIIXiURM5Wi"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2680,
        "2021": 3600,
        "2022": 2700,
        "2023": 0,
        "2024": 0,
        "id": "reccW2EEBZTxd41Ew",
        "row": 3281,
        "state": "ARIZONA",
        "group": "76413116",
        "group_num": [
            "recmqGxpb1QFrIS2Z"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdyFR2EMtWhlfVP",
        "row": 3290,
        "state": "ARIZONA",
        "group": "76414102",
        "group_num": [
            "rec5lKcy1MUnFlTOV"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 4200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdzoeEvBzhiRp73",
        "row": 3297,
        "state": "ARIZONA",
        "group": "76414305",
        "group_num": [
            "recHbJnERryNN7dTA"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1890,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrXPUUr4b7yl3Kg",
        "row": 3261,
        "state": "ARIZONA",
        "group": "76411858",
        "group_num": [
            "recIeacIIXiURM5Wi"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "2023": 0,
        "2024": 0,
        "id": "recrFxBgMHD8KwIOi",
        "row": 3255,
        "state": "ARIZONA",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 4788,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs4zc07fvOQjnET",
        "row": 3269,
        "state": "ARIZONA",
        "group": "76412222",
        "group_num": [
            "recHLvOrCKn2HnctK"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 1982,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvwYqLmkOdUxQH7",
        "row": 3276,
        "state": "ARIZONA",
        "group": "76412533",
        "group_num": [
            "recAHcC678L0SXVtG"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvRYz2rPgU7MFdc",
        "row": 3287,
        "state": "ARIZONA",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxtmVJ3HZXxThui",
        "row": 3296,
        "state": "ARIZONA",
        "group": "76414159",
        "group_num": [
            "recWwVrDuqjWVaAzE"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1868,
        "2023": 0,
        "2024": 0,
        "id": "recy5xXwNRNv5Sb8S",
        "row": 3288,
        "state": "ARIZONA",
        "group": "76413917",
        "group_num": [
            "recIiAunBdhZHn1lm"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 2655,
        "2021": 2655,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAszxSXPEKmMfbK",
        "row": 3293,
        "state": "ARIZONA",
        "group": "76414153",
        "group_num": [
            "recPiHeiYtmdk7tHI"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3364,
        "2023": 0,
        "2024": 0,
        "id": "recCBfJ9BS9XnzMhg",
        "row": 3258,
        "state": "ARIZONA",
        "group": "76411829",
        "group_num": [
            "recfBgJzTIarmnMHD"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "2023": 2051,
        "2024": 0,
        "id": "recDc5R68RXO7thXb",
        "row": 3273,
        "state": "ARIZONA",
        "group": "76412447",
        "group_num": [
            "recUyTxgPBDXSLD2X"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2700,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGDg2vNOmW8GmZP",
        "row": 3283,
        "state": "ARIZONA",
        "group": "76413116",
        "group_num": [
            "recmqGxpb1QFrIS2Z"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1136,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHTUHZbJ6W4BzEV",
        "row": 3280,
        "state": "ARIZONA",
        "group": "76412547",
        "group_num": [
            "recZ5Uc9s0yDgAqRP"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recHdkRxMG11P8Ue4",
        "row": 3271,
        "state": "ARIZONA",
        "group": "76412271",
        "group_num": [
            "recJTqRID1MEYRTTa"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIlEUgq82wVqfMw",
        "row": 3279,
        "state": "ARIZONA",
        "group": "76412547",
        "group_num": [
            "recZ5Uc9s0yDgAqRP"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "2023": 0,
        "2024": 0,
        "id": "recJckrMSpOpdZr0N",
        "row": 3265,
        "state": "ARIZONA",
        "group": "76412023",
        "group_num": [
            "recyiVYcpDokRBXkl"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 4800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJeMu9T3CCFeQDQ",
        "row": 3275,
        "state": "ARIZONA",
        "group": "76412524",
        "group_num": [
            "recjNtVhXnCVfswYR"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1733,
        "2022": 2700,
        "2023": 0,
        "2024": 0,
        "id": "recKS5eDR4AnKLyuF",
        "row": 3268,
        "state": "ARIZONA",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2677,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recKXhQvdODNbReXk",
        "row": 3294,
        "state": "ARIZONA",
        "group": "76414159",
        "group_num": [
            "recWwVrDuqjWVaAzE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "2023": 0,
        "2024": 0,
        "id": "recM88eNVxTa0Kg1D",
        "row": 3252,
        "state": "ARIZONA",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 3296,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN7F2FQ1DHxuS9a",
        "row": 3285,
        "state": "ARIZONA",
        "group": "76413564",
        "group_num": [
            "rec95Uy9CkNex0qw0"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "2023": 0,
        "2024": 0,
        "id": "recgtAvxjXoLNW2JH",
        "row": 3308,
        "state": "ARIZONA",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2655,
        "2022": 2655,
        "2023": 0,
        "2024": 0,
        "id": "reclubRWAMA2jw1dE",
        "row": 3299,
        "state": "ARIZONA",
        "group": "76414351",
        "group_num": [
            "recp7nKPttDb1eAnC"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2042,
        "2023": 2100,
        "2024": 0,
        "id": "recm7MCZlPi2dWWRh",
        "row": 3311,
        "state": "ARIZONA",
        "group": "76414886",
        "group_num": [
            "rec257uphBr6MP4yE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 3359,
        "2023": 0,
        "2024": 0,
        "id": "recQIkZF3NqmhdAPL",
        "row": 3298,
        "state": "ARIZONA",
        "group": "76414351",
        "group_num": [
            "recp7nKPttDb1eAnC"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 152,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS3oGSu48QyE0HO",
        "row": 3315,
        "state": "ARIZONA",
        "group": "76510041",
        "group_num": [
            "recT7LpW2PSDd7EVk"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2047,
        "2022": 2675,
        "2023": 0,
        "2024": 0,
        "id": "recXd3z4hHgiRQEIy",
        "row": 3306,
        "state": "ARIZONA",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "2023": 3375,
        "2024": 0,
        "id": "recXqmmD5m6d4xlr5",
        "row": 3301,
        "state": "ARIZONA",
        "group": "76414452",
        "group_num": [
            "rec2HCi3Fg6PN47MV"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2700,
        "2022": 2600,
        "2023": 0,
        "2024": 0,
        "id": "rec0XhdChvOcag2h1",
        "row": 3304,
        "state": "ARIZONA",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec5uXm8YOVKXC8Ua",
        "row": 3312,
        "state": "ARIZONA",
        "group": "76414886",
        "group_num": [
            "rec257uphBr6MP4yE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5oYREBtVmp5SRW",
        "row": 3302,
        "state": "ARIZONA",
        "group": "76414496",
        "group_num": [
            "recFiHJVQnnleUaG1"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "reconsYvMCSzzbtxN",
        "row": 3300,
        "state": "ARIZONA",
        "group": "76414452",
        "group_num": [
            "rec2HCi3Fg6PN47MV"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2361,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectOL45kLH3d7QWW",
        "row": 3305,
        "state": "ARIZONA",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2236,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA6nMUoWTkHIMzH",
        "row": 3303,
        "state": "ARIZONA",
        "group": "76414571",
        "group_num": [
            "recgok2g5duxFRyEZ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2138,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recExVpriigwxCgii",
        "row": 3310,
        "state": "ARIZONA",
        "group": "76414872",
        "group_num": [
            "rec00WIFxr7VgKp1D"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 215,
        "2024": 0,
        "id": "recKTRNKTrIsJqBGc",
        "row": 3314,
        "state": "ARIZONA",
        "group": "76415637",
        "group_num": [
            "recmmQLqwU4gZOk7u"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3563,
        "2023": 0,
        "2024": 0,
        "id": "recLgXhLdci50CUoy",
        "row": 3313,
        "state": "ARIZONA",
        "group": "76415162",
        "group_num": [
            "recZfJ3xkp8H3uDSc"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1552,
        "2022": 1808,
        "2023": 0,
        "2024": 0,
        "id": "recLrBcjH2bQB4Zi0",
        "row": 3307,
        "state": "ARIZONA",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2598,
        "2022": 3000,
        "2023": 6000,
        "2024": 0,
        "id": "recN86qU6hqIYJl5k",
        "row": 3309,
        "state": "ARIZONA",
        "group": "76414872",
        "group_num": [
            "rec00WIFxr7VgKp1D"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 548,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recL1JCPxkEGP1JEf",
        "row": 3316,
        "state": "ARIZONA",
        "group": "131192M177",
        "group_num": [
            "reckG0cq7WUl75svn"
        ],
        "procedure": "Detox",
        "provider": "UNICARE"
    },
    {
        "2020": 718,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf0QybhhNrXMq2Y",
        "row": 3341,
        "state": "ARIZONA",
        "group": "5S3381",
        "group_num": [
            "rec8EaZ5vvB8DuXag"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1736,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgvDGjh6k09LsiB",
        "row": 3397,
        "state": "ARIZONA",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 867,
        "2023": 0,
        "2024": 0,
        "id": "rechtgkq5QfkleU3R",
        "row": 3357,
        "state": "ARIZONA",
        "group": "9L4485",
        "group_num": [
            "recoIGDkJ01ytp5Gz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 440,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech8AQHhVdwAA4rn",
        "row": 3333,
        "state": "ARIZONA",
        "group": "3F2857",
        "group_num": [
            "rec65aZlJTnZE6yJI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4257,
        "2021": 893,
        "2022": 349,
        "2023": 954,
        "2024": 0,
        "id": "reciT3aMqiv5djz0u",
        "row": 3385,
        "state": "ARIZONA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recivcoihHBck5ExP",
        "row": 3359,
        "state": "ARIZONA",
        "group": "09W8501",
        "group_num": [
            "recI3E3RNZlLfILDq"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj2W3S3G6cAklQu",
        "row": 3330,
        "state": "ARIZONA",
        "group": "2L4996",
        "group_num": [
            "rec0IlXNSMnkhyJzj"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2520,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjgfi6nXGc8iIgq",
        "row": 3374,
        "state": "ARIZONA",
        "group": "16247",
        "group_num": [
            "recEsPNWCCSboWf7e"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 260,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjqoKMvYxcs7rjF",
        "row": 3387,
        "state": "ARIZONA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1084,
        "2021": 1105,
        "2022": 1168,
        "2023": 0,
        "2024": 0,
        "id": "reckV8iwaLKwXIkfp",
        "row": 3395,
        "state": "ARIZONA",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4186,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckAS12ECCT8vTAd",
        "row": 3380,
        "state": "ARIZONA",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckJGAUQloLCuplX",
        "row": 3323,
        "state": "ARIZONA",
        "group": "1U3312",
        "group_num": [
            "recoHCeqwRKQbM2oO"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 584,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckaG3MlAigmRt7q",
        "row": 3360,
        "state": "ARIZONA",
        "group": "09W8501",
        "group_num": [
            "recI3E3RNZlLfILDq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnMMAzAfNIbQ8Nb",
        "row": 3362,
        "state": "ARIZONA",
        "group": "09W8501",
        "group_num": [
            "recI3E3RNZlLfILDq"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2660,
        "2024": 0,
        "id": "recOAj6oHwGALz1Ol",
        "row": 3373,
        "state": "ARIZONA",
        "group": "15457",
        "group_num": [
            "recErZfnfE7ZopeRw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 0,
        "2024": 0,
        "id": "recQoTrcVapDD3qg5",
        "row": 3317,
        "state": "ARIZONA",
        "group": "1F0963",
        "group_num": [
            "rectr7OIaNuoP5P0H"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 145,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSAb6I3P5sK46b5",
        "row": 3388,
        "state": "ARIZONA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT0smi1uSQsBZuE",
        "row": 3320,
        "state": "ARIZONA",
        "group": "1L6087",
        "group_num": [
            "recgSR2ABZmP2r6jc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVC4npc34GBvioE",
        "row": 3322,
        "state": "ARIZONA",
        "group": "1L6087",
        "group_num": [
            "recgSR2ABZmP2r6jc"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "2023": 0,
        "2024": 0,
        "id": "recWNMA0CBKvYaOfG",
        "row": 3354,
        "state": "ARIZONA",
        "group": "8X5451",
        "group_num": [
            "rec21S149sUfxlS1t"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXIbdj4hTnjtNoA",
        "row": 3326,
        "state": "ARIZONA",
        "group": "1X8798",
        "group_num": [
            "reclviRjv6o3isItN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXbcKs3xUfQMUt2",
        "row": 3352,
        "state": "ARIZONA",
        "group": "8U9536",
        "group_num": [
            "recktvVzrkAEoRVmV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYYghwNJFSLqSN4",
        "row": 3335,
        "state": "ARIZONA",
        "group": "3W7032",
        "group_num": [
            "recz92NZLt7nmfBo1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYaWMywCttyBZIj",
        "row": 3329,
        "state": "ARIZONA",
        "group": "2L4996",
        "group_num": [
            "rec0IlXNSMnkhyJzj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYbijWaBB3FEHcP",
        "row": 3327,
        "state": "ARIZONA",
        "group": "2L4996",
        "group_num": [
            "rec0IlXNSMnkhyJzj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYmGA6JvrN6AGXw",
        "row": 3365,
        "state": "ARIZONA",
        "group": "32",
        "group_num": [
            "receBAB5aTOAo2vJf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 5100,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recZ1tT972xVUahnh",
        "row": 3391,
        "state": "ARIZONA",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4019,
        "2021": 852,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "recZJ85Kp1LSZkaj8",
        "row": 3378,
        "state": "ARIZONA",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Z2My4ys8fOCoL",
        "row": 3344,
        "state": "ARIZONA",
        "group": "6L7133",
        "group_num": [
            "recJBjRetJrqCou1k"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec01yd6EdWZTJ5hb",
        "row": 3345,
        "state": "ARIZONA",
        "group": "6L7133",
        "group_num": [
            "recJBjRetJrqCou1k"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4lur5mFg7YgK9F",
        "row": 3343,
        "state": "ARIZONA",
        "group": "6L7133",
        "group_num": [
            "recJBjRetJrqCou1k"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6sDDi0gqf5r9Lu",
        "row": 3339,
        "state": "ARIZONA",
        "group": "5S3381",
        "group_num": [
            "rec8EaZ5vvB8DuXag"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6M7ESTWl5b8X3L",
        "row": 3331,
        "state": "ARIZONA",
        "group": "2X9350",
        "group_num": [
            "recosA8GUHPe3yjTi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3180,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7Y6KgP7laltaGW",
        "row": 3370,
        "state": "ARIZONA",
        "group": "15337",
        "group_num": [
            "recrb2AdKhEf0R4hM"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2655,
        "2022": 2655,
        "2023": 2655,
        "2024": 0,
        "id": "rec7gOLCfNyt7AOEf",
        "row": 3393,
        "state": "ARIZONA",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7iiEq1korA06O8",
        "row": 3349,
        "state": "ARIZONA",
        "group": "7Y0375",
        "group_num": [
            "reciAyv6AW35nYOo1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8KNBoPilw9FHUa",
        "row": 3328,
        "state": "ARIZONA",
        "group": "2L4996",
        "group_num": [
            "rec0IlXNSMnkhyJzj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2940,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8c0HCH0jsEqnzl",
        "row": 3369,
        "state": "ARIZONA",
        "group": "15106",
        "group_num": [
            "recoLObWHW6MzMHE6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 151,
        "2023": 0,
        "2024": 0,
        "id": "rec9XS1mUJ4PL0HAW",
        "row": 3318,
        "state": "ARIZONA",
        "group": "1F0963",
        "group_num": [
            "rectr7OIaNuoP5P0H"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9xcKPByPAY4bJE",
        "row": 3364,
        "state": "ARIZONA",
        "group": "09Y6759",
        "group_num": [
            "recPtMTckEzbaAITz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 606,
        "2023": 606,
        "2024": 0,
        "id": "rec9Dx6AgnPufnN0N",
        "row": 3386,
        "state": "ARIZONA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 911,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9KhP8rPCH3L694",
        "row": 3340,
        "state": "ARIZONA",
        "group": "5S3381",
        "group_num": [
            "rec8EaZ5vvB8DuXag"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 770,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaKRLVqwyjEGAvp",
        "row": 3376,
        "state": "ARIZONA",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaipHt9ICHTesru",
        "row": 3321,
        "state": "ARIZONA",
        "group": "1L6087",
        "group_num": [
            "recgSR2ABZmP2r6jc"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccYMHHMn5CZTbpR",
        "row": 3348,
        "state": "ARIZONA",
        "group": "7X9074",
        "group_num": [
            "recIOi6NvTT0QDXPB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdlyjtjwOR8siUn",
        "row": 3384,
        "state": "ARIZONA",
        "group": "189416",
        "group_num": [
            "recl75xmbCprJvNrf"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4223,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoio4qyMSmErQEL",
        "row": 3377,
        "state": "ARIZONA",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 294,
        "2023": 0,
        "2024": 0,
        "id": "recpfAejJT9VL5xi8",
        "row": 3353,
        "state": "ARIZONA",
        "group": "8X5451",
        "group_num": [
            "rec21S149sUfxlS1t"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpqNVV0BcGiYRou",
        "row": 3351,
        "state": "ARIZONA",
        "group": "8M7508",
        "group_num": [
            "recSQcrhgFsCLkLjN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqORcH1IuyIBiJm",
        "row": 3367,
        "state": "ARIZONA",
        "group": "32",
        "group_num": [
            "receBAB5aTOAo2vJf"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "2023": 0,
        "2024": 0,
        "id": "recrSPQ2WRXzGPf1E",
        "row": 3347,
        "state": "ARIZONA",
        "group": "7S2832",
        "group_num": [
            "recWM7jvKygHR5n1p"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3360,
        "2023": 3360,
        "2024": 0,
        "id": "recrzfInHyOw3QNF4",
        "row": 3372,
        "state": "ARIZONA",
        "group": "15457",
        "group_num": [
            "recErZfnfE7ZopeRw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4201,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrOVOUEBRPqOQ24",
        "row": 3375,
        "state": "ARIZONA",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 5700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recroguDmjzzFZQPr",
        "row": 3371,
        "state": "ARIZONA",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs7OYB3GM1G8lTD",
        "row": 3356,
        "state": "ARIZONA",
        "group": "9L2772",
        "group_num": [
            "recBon3eYHSJHa4Ej"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3375,
        "2022": 3375,
        "2023": 0,
        "2024": 0,
        "id": "recsaCrbhruEicQrF",
        "row": 3392,
        "state": "ARIZONA",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "rectqbeoSCb7T90ms",
        "row": 3355,
        "state": "ARIZONA",
        "group": "8X5451",
        "group_num": [
            "rec21S149sUfxlS1t"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuKDjsRw8f6Curb",
        "row": 3324,
        "state": "ARIZONA",
        "group": "1U3313",
        "group_num": [
            "recEo8xg7oTb3CnxL"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 420,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recumhajIXWjXS9eH",
        "row": 3346,
        "state": "ARIZONA",
        "group": "6X6196",
        "group_num": [
            "recnm9Qq5i3Vmp4Ry"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxWbFexUjYSYbjF",
        "row": 3336,
        "state": "ARIZONA",
        "group": "4L1108",
        "group_num": [
            "recLeqzMysKIX2GJC"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 756,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxKrtlF1sR4xUNz",
        "row": 3358,
        "state": "ARIZONA",
        "group": "9U5888",
        "group_num": [
            "recdAJ38naUmhMTnf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4271,
        "2021": 4404,
        "2022": 0,
        "2023": 954,
        "2024": 0,
        "id": "recxitUXHcbh2ppxN",
        "row": 3381,
        "state": "ARIZONA",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyv0X88CR7N1O2Z",
        "row": 3334,
        "state": "ARIZONA",
        "group": "3L3587",
        "group_num": [
            "recQWlIQlXSj4Rz9y"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3180,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAb52hixwNrQuUl",
        "row": 3368,
        "state": "ARIZONA",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBHSPfxVnpTsrUk",
        "row": 3363,
        "state": "ARIZONA",
        "group": "09W8501",
        "group_num": [
            "recI3E3RNZlLfILDq"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 21,
        "2024": 0,
        "id": "recCAVLrgE7gUuWv8",
        "row": 3319,
        "state": "ARIZONA",
        "group": "1F0963",
        "group_num": [
            "rectr7OIaNuoP5P0H"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 264,
        "2023": 264,
        "2024": 0,
        "id": "recEMxJGYXK8FIqKN",
        "row": 3325,
        "state": "ARIZONA",
        "group": "1X3785",
        "group_num": [
            "recqv5FlwlC7uuRUt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recFYUflPFZT8akyr",
        "row": 3379,
        "state": "ARIZONA",
        "group": "174189PDA7",
        "group_num": [
            "recq2OsV8SZByG4mk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHCnMMkfD8lcXXS",
        "row": 3332,
        "state": "ARIZONA",
        "group": "2Y0500",
        "group_num": [
            "recYO95WsLpvgiQjf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "2023": 0,
        "2024": 0,
        "id": "recH7IEMiOLBhegs2",
        "row": 3394,
        "state": "ARIZONA",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 772,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHeQ4eOuYtE1426",
        "row": 3390,
        "state": "ARIZONA",
        "group": "196819",
        "group_num": [
            "recKJN0MJSKGJKYNW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIRZj1mUs6L2stJ",
        "row": 3350,
        "state": "ARIZONA",
        "group": "7Y8183",
        "group_num": [
            "recsTjOXHkrqSpQnb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 35,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIpY5kQwjlwnTWP",
        "row": 3342,
        "state": "ARIZONA",
        "group": "5S3381",
        "group_num": [
            "rec8EaZ5vvB8DuXag"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 546,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJUkmQT4vBnpFHY",
        "row": 3366,
        "state": "ARIZONA",
        "group": "32",
        "group_num": [
            "receBAB5aTOAo2vJf"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4092,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJNewvfpeNltmhU",
        "row": 3389,
        "state": "ARIZONA",
        "group": "196819",
        "group_num": [
            "recKJN0MJSKGJKYNW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "recJoTioVTpzbN7sc",
        "row": 3338,
        "state": "ARIZONA",
        "group": "5F3433",
        "group_num": [
            "rec6eofyVWDxW77Jw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 588,
        "2021": 363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLzbUk3xq6RxmZp",
        "row": 3383,
        "state": "ARIZONA",
        "group": "189416",
        "group_num": [
            "recl75xmbCprJvNrf"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "recLqeyeOUa1P8Hbk",
        "row": 3337,
        "state": "ARIZONA",
        "group": "5F3433",
        "group_num": [
            "rec6eofyVWDxW77Jw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMZfwu9YntmNXzY",
        "row": 3361,
        "state": "ARIZONA",
        "group": "09W8501",
        "group_num": [
            "recI3E3RNZlLfILDq"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1117,
        "2021": 838,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMFPQ2gYT080YZk",
        "row": 3382,
        "state": "ARIZONA",
        "group": "189416",
        "group_num": [
            "recl75xmbCprJvNrf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2704,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNYAVtVklMrspKb",
        "row": 3396,
        "state": "ARIZONA",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 954,
        "2024": 0,
        "id": "recfli81AL5eDsLkF",
        "row": 3453,
        "state": "ARIZONA",
        "group": "705298",
        "group_num": [
            "recGW41bo8XWBFfcz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1613,
        "2022": 1196,
        "2023": 2375,
        "2024": 0,
        "id": "recgDXeOb1f9teiH2",
        "row": 3455,
        "state": "ARIZONA",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 284,
        "2023": 0,
        "2024": 0,
        "id": "recgNlKxdQjhdgbDy",
        "row": 3483,
        "state": "ARIZONA",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2520,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgPFJYG9Q8nSKD9",
        "row": 3407,
        "state": "ARIZONA",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1623,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg9gI90WrOWwncX",
        "row": 3448,
        "state": "ARIZONA",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1425,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recgo9ZLYZut6cqNV",
        "row": 3463,
        "state": "ARIZONA",
        "group": "709155",
        "group_num": [
            "recBJrC4dGMqA0eBF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 0,
        "2024": 0,
        "id": "recgoZDusgXZQOgCu",
        "row": 3429,
        "state": "ARIZONA",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechWDmSVpCGqPIdV",
        "row": 3443,
        "state": "ARIZONA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3879,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechlbiEVqcTAifPm",
        "row": 3427,
        "state": "ARIZONA",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 606,
        "2023": 606,
        "2024": 0,
        "id": "reci75TigSLbkd6Uw",
        "row": 3486,
        "state": "ARIZONA",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci9dE2yC4M9OSqa",
        "row": 3460,
        "state": "ARIZONA",
        "group": "707556",
        "group_num": [
            "recpmBdsfoBkwNFQf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 344,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjCMC9HVNJRDQRO",
        "row": 3442,
        "state": "ARIZONA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 261,
        "2022": 1475,
        "2023": 0,
        "2024": 0,
        "id": "recke1Dy44uOVBpBC",
        "row": 3456,
        "state": "ARIZONA",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1195,
        "2022": 1074,
        "2023": 0,
        "2024": 0,
        "id": "reckpz5EkYD1b5Pzn",
        "row": 3468,
        "state": "ARIZONA",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 961,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclPwAA2e8fe2s0t",
        "row": 3416,
        "state": "ARIZONA",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3528,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recleVHdx8aMcYgW2",
        "row": 3470,
        "state": "ARIZONA",
        "group": "712852",
        "group_num": [
            "rec0QHCrwqDU1I8nl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1756,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmmTlYORSgwFICW",
        "row": 3435,
        "state": "ARIZONA",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 290,
        "2023": 0,
        "2024": 0,
        "id": "recmqH9mD9T4vCwUR",
        "row": 3487,
        "state": "ARIZONA",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recnUoMbBxdboTTFp",
        "row": 3438,
        "state": "ARIZONA",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2950,
        "2021": 2348,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recngLToQc1QHOqrw",
        "row": 3476,
        "state": "ARIZONA",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1837,
        "2021": 1848,
        "2022": 954,
        "2023": 954,
        "2024": 0,
        "id": "recRUDLpV6YZPpTLS",
        "row": 3485,
        "state": "ARIZONA",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3228,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRWMX91IzbQdbRa",
        "row": 3446,
        "state": "ARIZONA",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4492,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR1zuxEz5VvWui4",
        "row": 3400,
        "state": "ARIZONA",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4265,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRy9FZYruKGyw4F",
        "row": 3402,
        "state": "ARIZONA",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2393,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRj277ql08O4J7b",
        "row": 3398,
        "state": "ARIZONA",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSW3meGxXZQgTz7",
        "row": 3493,
        "state": "ARIZONA",
        "group": "717649",
        "group_num": [
            "recYn1sovse6Fk6px"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSAj2P6O9qA6kE8",
        "row": 3422,
        "state": "ARIZONA",
        "group": "277163M307",
        "group_num": [
            "rec1FYcBBwgmBLv6M"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 954,
        "2024": 0,
        "id": "recTASXJze0faTtAj",
        "row": 3461,
        "state": "ARIZONA",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 772,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWtsBPAq8T2nOor",
        "row": 3495,
        "state": "ARIZONA",
        "group": "717879",
        "group_num": [
            "recLmbRmbhgONu71o"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWuA4R3K6l8Mv4h",
        "row": 3449,
        "state": "ARIZONA",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4154,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWRQhCh2848J3ba",
        "row": 3466,
        "state": "ARIZONA",
        "group": "710664",
        "group_num": [
            "reckgLj5kD9NcR52n"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 882,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recW76b75ipLneCMj",
        "row": 3401,
        "state": "ARIZONA",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1842,
        "2023": 0,
        "2024": 0,
        "id": "recXyNJRoNcNCrKEu",
        "row": 3472,
        "state": "ARIZONA",
        "group": "712863",
        "group_num": [
            "recpWkLEWnQ4Rxsx8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1269,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXohVLShHmR61XO",
        "row": 3478,
        "state": "ARIZONA",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1755,
        "2021": 852,
        "2022": 936,
        "2023": 0,
        "2024": 0,
        "id": "recYlzWPyFl5fpcvN",
        "row": 3410,
        "state": "ARIZONA",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "2023": 0,
        "2024": 0,
        "id": "recYlf9G0KdG3icr8",
        "row": 3496,
        "state": "ARIZONA",
        "group": "730578",
        "group_num": [
            "recYtbfUnt4OFyI9h"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 708,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0B8hrMC7MZXrNy",
        "row": 3423,
        "state": "ARIZONA",
        "group": "277163M307",
        "group_num": [
            "rec1FYcBBwgmBLv6M"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 812,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Jubqur2wWmPDC",
        "row": 3417,
        "state": "ARIZONA",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 606,
        "2023": 0,
        "2024": 0,
        "id": "rec1Q86wYtRd4jbCE",
        "row": 3428,
        "state": "ARIZONA",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1cNI2RbpukZlPx",
        "row": 3450,
        "state": "ARIZONA",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2940,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2TFQ5td1D3pSyV",
        "row": 3421,
        "state": "ARIZONA",
        "group": "277163M307",
        "group_num": [
            "rec1FYcBBwgmBLv6M"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1875,
        "2023": 1124,
        "2024": 0,
        "id": "rec2R34rPCbmd65gc",
        "row": 3408,
        "state": "ARIZONA",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "2023": 0,
        "2024": 0,
        "id": "rec26zPqEBb4POoRn",
        "row": 3430,
        "state": "ARIZONA",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "rec3WFY0Tzu2HQDci",
        "row": 3414,
        "state": "ARIZONA",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1330,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3GdLiOk4Kfz3k1",
        "row": 3479,
        "state": "ARIZONA",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 6000,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4q0FfWq28900Pl",
        "row": 3473,
        "state": "ARIZONA",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4750,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6UXn8eT74xyLFE",
        "row": 3474,
        "state": "ARIZONA",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 730,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec70sJSGG0PbrjmN",
        "row": 3439,
        "state": "ARIZONA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2360,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec78f9XpqHnP5UkM",
        "row": 3447,
        "state": "ARIZONA",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4222,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8tWI51hz350bex",
        "row": 3432,
        "state": "ARIZONA",
        "group": "701139",
        "group_num": [
            "reco4pyUYTwwBsK0W"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2080,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8QUsC2E1kOHjAV",
        "row": 3490,
        "state": "ARIZONA",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1835,
        "2021": 174,
        "2022": 145,
        "2023": 0,
        "2024": 0,
        "id": "rec8l7UYXwxmv2iIl",
        "row": 3413,
        "state": "ARIZONA",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2446,
        "2021": 597,
        "2022": 485,
        "2023": 0,
        "2024": 0,
        "id": "rec9QTQFxN7OhsdN2",
        "row": 3411,
        "state": "ARIZONA",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9riEGvyqymwAST",
        "row": 3440,
        "state": "ARIZONA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4137,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbZ9gHOfKq1vi8H",
        "row": 3424,
        "state": "ARIZONA",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 918,
        "2022": 931,
        "2023": 0,
        "2024": 0,
        "id": "reccm8AuO78L1kF56",
        "row": 3481,
        "state": "ARIZONA",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "2023": 0,
        "2024": 0,
        "id": "reccnlHn8N8KAaOx4",
        "row": 3467,
        "state": "ARIZONA",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 248,
        "2022": 694,
        "2023": 0,
        "2024": 0,
        "id": "recoWeDCtTznhYH7Z",
        "row": 3457,
        "state": "ARIZONA",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2206,
        "2021": 825,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recov6ycxwaBXoJ5y",
        "row": 3412,
        "state": "ARIZONA",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 18,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoLntZZmkklpy2L",
        "row": 3497,
        "state": "ARIZONA",
        "group": "730578",
        "group_num": [
            "recYtbfUnt4OFyI9h"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2857,
        "2022": 2857,
        "2023": 0,
        "2024": 0,
        "id": "recpQUOlOU4az6Jlt",
        "row": 3471,
        "state": "ARIZONA",
        "group": "712863",
        "group_num": [
            "recpWkLEWnQ4Rxsx8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpr3NDVZhv8x4E3",
        "row": 3415,
        "state": "ARIZONA",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4295,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqWZYpRHAfylCRK",
        "row": 3420,
        "state": "ARIZONA",
        "group": "229050",
        "group_num": [
            "recNotInlf4GmwlOJ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 132,
        "2021": 159,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqtGCmOEGUVrlSP",
        "row": 3405,
        "state": "ARIZONA",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq6WFqVZUAf2Qi0",
        "row": 3494,
        "state": "ARIZONA",
        "group": "717879",
        "group_num": [
            "recLmbRmbhgONu71o"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 762,
        "2021": 371,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr0LSW6N0qoHhDe",
        "row": 3404,
        "state": "ARIZONA",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 585,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsH72k1GKUVpnaD",
        "row": 3489,
        "state": "ARIZONA",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "rectp9HzFLwu4Iojo",
        "row": 3469,
        "state": "ARIZONA",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3750,
        "2021": 3750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuzIfM1mylKEu2l",
        "row": 3475,
        "state": "ARIZONA",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 132,
        "2021": 132,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu2R0FtxUwQcmnL",
        "row": 3477,
        "state": "ARIZONA",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4324,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwSPrGpaYUiy2wa",
        "row": 3480,
        "state": "ARIZONA",
        "group": "715385",
        "group_num": [
            "rec4EfqhqOeebsY9U"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4459,
        "2023": 0,
        "2024": 0,
        "id": "recwJ1gsi5kJvfAw9",
        "row": 3462,
        "state": "ARIZONA",
        "group": "709078",
        "group_num": [
            "recRyhSLfG0a4AXeZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 156,
        "2023": 0,
        "2024": 0,
        "id": "recyEatdCnrd5Xk5Y",
        "row": 3437,
        "state": "ARIZONA",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 146,
        "2023": 0,
        "2024": 0,
        "id": "reczKl8Vg3uv1kIvq",
        "row": 3484,
        "state": "ARIZONA",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 68,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "recA87iJKqoxEjcvT",
        "row": 3458,
        "state": "ARIZONA",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1200,
        "2024": 0,
        "id": "recAecnZzJkDh3vAW",
        "row": 3409,
        "state": "ARIZONA",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAlvEmYFBbOxOvP",
        "row": 3399,
        "state": "ARIZONA",
        "group": "204781",
        "group_num": [
            "recI4MaSQ9VLASTK5"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3325,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recApBRxjt1whGsJu",
        "row": 3452,
        "state": "ARIZONA",
        "group": "703997",
        "group_num": [
            "recRpKB6qrtJD9U5T"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 585,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBXF0grVrwvR8mY",
        "row": 3418,
        "state": "ARIZONA",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "2023": 0,
        "2024": 0,
        "id": "recBhhrySdkBKR4Ke",
        "row": 3436,
        "state": "ARIZONA",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4247,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCWDarJxerhHtxH",
        "row": 3464,
        "state": "ARIZONA",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 937,
        "2024": 0,
        "id": "recCuZoz0iVMZEmsy",
        "row": 3431,
        "state": "ARIZONA",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4402,
        "2022": 4324,
        "2023": 0,
        "2024": 0,
        "id": "recC57T0XfoK545gQ",
        "row": 3465,
        "state": "ARIZONA",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD7OBox8TrDTudY",
        "row": 3492,
        "state": "ARIZONA",
        "group": "717649",
        "group_num": [
            "recYn1sovse6Fk6px"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1067,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDerbwGdj7rwFS3",
        "row": 3425,
        "state": "ARIZONA",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFx9n0cB4gFU1Yk",
        "row": 3459,
        "state": "ARIZONA",
        "group": "706717",
        "group_num": [
            "recBRWZ7Styc6RBNY"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIuBKoVegXoLyxZ",
        "row": 3491,
        "state": "ARIZONA",
        "group": "717649",
        "group_num": [
            "recYn1sovse6Fk6px"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 772,
        "2021": 0,
        "2022": 149,
        "2023": 0,
        "2024": 0,
        "id": "recIwuEEu2WfhUR8T",
        "row": 3488,
        "state": "ARIZONA",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 5181,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJs78zz4332goOP",
        "row": 3406,
        "state": "ARIZONA",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3156,
        "2021": 2691,
        "2022": 2478,
        "2023": 954,
        "2024": 0,
        "id": "recJNkGPYyoWfsc9t",
        "row": 3454,
        "state": "ARIZONA",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJnU8eyMKfa1knh",
        "row": 3451,
        "state": "ARIZONA",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 0,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "recLXxCA3dp1B7Pzi",
        "row": 3482,
        "state": "ARIZONA",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1198,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLgD1fFc0WOkb87",
        "row": 3444,
        "state": "ARIZONA",
        "group": "702525",
        "group_num": [
            "recTYpUOUxIW7Dhbv"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 439,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLie4XMDVoKfIQy",
        "row": 3426,
        "state": "ARIZONA",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 848,
        "2021": 474,
        "2022": 290,
        "2023": 0,
        "2024": 0,
        "id": "recMUiq3EQpOuRbfk",
        "row": 3403,
        "state": "ARIZONA",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recMYeFlM67tag9nP",
        "row": 3433,
        "state": "ARIZONA",
        "group": "701274",
        "group_num": [
            "recPSY5SNGzz1BfG1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNCMT50yj4jHDzV",
        "row": 3441,
        "state": "ARIZONA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recetWSzv0YymlV9H",
        "row": 3527,
        "state": "ARIZONA",
        "group": "902838",
        "group_num": [
            "recKiBYREsKVK5Cpm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 323,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf7q9Ca3Unr3f1U",
        "row": 3519,
        "state": "ARIZONA",
        "group": "902158",
        "group_num": [
            "recZAiC6VfCOjIaZw"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci3u3gz6gwvTqYJ",
        "row": 3503,
        "state": "ARIZONA",
        "group": "743065",
        "group_num": [
            "rectfBsQGU4L1L7xL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "2023": 0,
        "2024": 0,
        "id": "recjgDMrvYUdsH1Az",
        "row": 3499,
        "state": "ARIZONA",
        "group": "730920",
        "group_num": [
            "recWoLw1GtURirAeH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 675,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclQbvZuaKGGCNSp",
        "row": 3506,
        "state": "ARIZONA",
        "group": "751992",
        "group_num": [
            "recmpITkZo2AB0BIB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmLTBFf3vp1V1V9",
        "row": 3517,
        "state": "ARIZONA",
        "group": "902158",
        "group_num": [
            "recZAiC6VfCOjIaZw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 679,
        "2021": 679,
        "2022": 2135,
        "2023": 0,
        "2024": 0,
        "id": "recnFSYI4tiEASHsd",
        "row": 3510,
        "state": "ARIZONA",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 132,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnqPtVzLvlF9MjY",
        "row": 3542,
        "state": "ARIZONA",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 52,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP27XYyKa1wwWQz",
        "row": 3546,
        "state": "ARIZONA",
        "group": "905754",
        "group_num": [
            "rechSDY0XkC7qY5an"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 282,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPfi1r7Q9X6whYy",
        "row": 3524,
        "state": "ARIZONA",
        "group": "902494",
        "group_num": [
            "rec0bmMkBMPYow4P4"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 35,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recROSaGw4VmCSdYR",
        "row": 3525,
        "state": "ARIZONA",
        "group": "902494",
        "group_num": [
            "rec0bmMkBMPYow4P4"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1162,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTY7UGU0XqY3xRA",
        "row": 3533,
        "state": "ARIZONA",
        "group": "905224",
        "group_num": [
            "reczXx1zipCNqNcjj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 346,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT1LoAXYFsFK7Ak",
        "row": 3534,
        "state": "ARIZONA",
        "group": "905224",
        "group_num": [
            "reczXx1zipCNqNcjj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 288,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recU44EONNbizxFoe",
        "row": 3523,
        "state": "ARIZONA",
        "group": "902494",
        "group_num": [
            "rec0bmMkBMPYow4P4"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX3nPcDNgic4wfR",
        "row": 3526,
        "state": "ARIZONA",
        "group": "902838",
        "group_num": [
            "recKiBYREsKVK5Cpm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2043,
        "2021": 1631,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2iawXVkTwtE8nM",
        "row": 3518,
        "state": "ARIZONA",
        "group": "902158",
        "group_num": [
            "recZAiC6VfCOjIaZw"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 294,
        "2022": 284,
        "2023": 0,
        "2024": 0,
        "id": "rec33FjACblHTRMTA",
        "row": 3498,
        "state": "ARIZONA",
        "group": "730920",
        "group_num": [
            "recWoLw1GtURirAeH"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4019,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3hMRd6blUmyz2i",
        "row": 3529,
        "state": "ARIZONA",
        "group": "903320",
        "group_num": [
            "recOvfCSspJjwqGlZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1224,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec42VI96DfAv3TZZ",
        "row": 3544,
        "state": "ARIZONA",
        "group": "905754",
        "group_num": [
            "rechSDY0XkC7qY5an"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5uusjFaJgWR2wt",
        "row": 3513,
        "state": "ARIZONA",
        "group": "814107",
        "group_num": [
            "recw6JNihGNSCWpto"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaR5FbOnO2409NQ",
        "row": 3537,
        "state": "ARIZONA",
        "group": "905522",
        "group_num": [
            "recHrKIQRLQtCz3yP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recadrM9KC2Srk8ed",
        "row": 3520,
        "state": "ARIZONA",
        "group": "902247",
        "group_num": [
            "rec56X2y6z7YJ0SW5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbmcy0jak9TUsWa",
        "row": 3514,
        "state": "ARIZONA",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 760,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc45PUVyM1pAlIg",
        "row": 3528,
        "state": "ARIZONA",
        "group": "902996",
        "group_num": [
            "recx9OhpWi3rHnD7U"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 632,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdv02TfHXenRXM6",
        "row": 3541,
        "state": "ARIZONA",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recpxycsJuXcwyMbO",
        "row": 3530,
        "state": "ARIZONA",
        "group": "904975",
        "group_num": [
            "recLifBjYCOu6uJP8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs10kIZlE3cug93",
        "row": 3516,
        "state": "ARIZONA",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsxj5rjVXgOnm9a",
        "row": 3540,
        "state": "ARIZONA",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recta8BCiQsjibleX",
        "row": 3547,
        "state": "ARIZONA",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recumKMxJMDIIa5GB",
        "row": 3515,
        "state": "ARIZONA",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4106,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvb2Fu4RAo0BfJd",
        "row": 3512,
        "state": "ARIZONA",
        "group": "755875",
        "group_num": [
            "rectSsQBSeAmNsJPN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4222,
        "2021": 4335,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwJtZQkiQfw3iTg",
        "row": 3538,
        "state": "ARIZONA",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 22,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwpIflPOehJAn8v",
        "row": 3504,
        "state": "ARIZONA",
        "group": "743065",
        "group_num": [
            "rectfBsQGU4L1L7xL"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 813,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxMpZLrLjwSGpDE",
        "row": 3545,
        "state": "ARIZONA",
        "group": "905754",
        "group_num": [
            "rechSDY0XkC7qY5an"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1084,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx2Lirz3viu6pw0",
        "row": 3501,
        "state": "ARIZONA",
        "group": "730992",
        "group_num": [
            "recSletlMIiNYBI0N"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1928,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recxihtPU0d1aRQVp",
        "row": 3535,
        "state": "ARIZONA",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2601,
        "2023": 0,
        "2024": 0,
        "id": "recDEdE2kAzikUkAU",
        "row": 3508,
        "state": "ARIZONA",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 288,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDLl62aVGadjOKG",
        "row": 3511,
        "state": "ARIZONA",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4367,
        "2021": 0,
        "2022": 606,
        "2023": 0,
        "2024": 0,
        "id": "recEkVUQJu3QE8dEW",
        "row": 3536,
        "state": "ARIZONA",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4756,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGR0o7Z4MWB8DVv",
        "row": 3522,
        "state": "ARIZONA",
        "group": "902494",
        "group_num": [
            "rec0bmMkBMPYow4P4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 968,
        "2021": 968,
        "2022": 1832,
        "2023": 874,
        "2024": 0,
        "id": "recGlEpxjxp3dD7PI",
        "row": 3509,
        "state": "ARIZONA",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "2023": 0,
        "2024": 0,
        "id": "recHqwdOGO3Gbq4ew",
        "row": 3531,
        "state": "ARIZONA",
        "group": "905005",
        "group_num": [
            "recNH43sQ20RXWv1N"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIZXglcEqZSB7HU",
        "row": 3502,
        "state": "ARIZONA",
        "group": "743065",
        "group_num": [
            "rectfBsQGU4L1L7xL"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recICfYiKKeOY5Ypu",
        "row": 3521,
        "state": "ARIZONA",
        "group": "902247",
        "group_num": [
            "rec56X2y6z7YJ0SW5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 918,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIOiQCDO6vOIYRM",
        "row": 3505,
        "state": "ARIZONA",
        "group": "743545",
        "group_num": [
            "rech80WxMCHLHJkMj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 35,
        "2023": 0,
        "2024": 0,
        "id": "recI7wdwIgfIxtqsR",
        "row": 3500,
        "state": "ARIZONA",
        "group": "730920",
        "group_num": [
            "recWoLw1GtURirAeH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 436,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLyDZ1JFja3wkhX",
        "row": 3507,
        "state": "ARIZONA",
        "group": "751992",
        "group_num": [
            "recmpITkZo2AB0BIB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4206,
        "2021": 4089,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLk7vBAv1WrnL65",
        "row": 3539,
        "state": "ARIZONA",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3325,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN9R2PW4ANkoeZN",
        "row": 3532,
        "state": "ARIZONA",
        "group": "905005",
        "group_num": [
            "recNH43sQ20RXWv1N"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 909,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgfItY3129HMtme",
        "row": 3587,
        "state": "ARIZONA",
        "group": "918961",
        "group_num": [
            "recrmcQgCNkOFPJfn"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 675,
        "2023": 0,
        "2024": 0,
        "id": "rechzDDT0LjC7MtmK",
        "row": 3572,
        "state": "ARIZONA",
        "group": "914355",
        "group_num": [
            "recDLpHj56m2VEJpf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 0,
        "2024": 0,
        "id": "rechFpb4ZF1ijJnaz",
        "row": 3548,
        "state": "ARIZONA",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 900,
        "2022": 750,
        "2023": 0,
        "2024": 0,
        "id": "rech74RFIjVaFLVja",
        "row": 3605,
        "state": "ARIZONA",
        "group": "2255182",
        "group_num": [
            "rec4NAl31tW59ncWt"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 850,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci0Y6ncE6NoafJ2",
        "row": 3583,
        "state": "ARIZONA",
        "group": "918804",
        "group_num": [
            "recbbYQAu9GY5cO7a"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjvme6985RtT0jX",
        "row": 3555,
        "state": "ARIZONA",
        "group": "908292",
        "group_num": [
            "recxOQSBSWglqS8JY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckPl0FvbNvxJSfr",
        "row": 3582,
        "state": "ARIZONA",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "2023": 0,
        "2024": 0,
        "id": "recnZkt2CMSrtpr4A",
        "row": 3589,
        "state": "ARIZONA",
        "group": "920106",
        "group_num": [
            "recQmIhKRJK3sWEcP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 273,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recO5fhsuO2daNJKL",
        "row": 3552,
        "state": "ARIZONA",
        "group": "906902",
        "group_num": [
            "recjfJG1qdtqiF2Ag"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 432,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recObBFKfWNp68jyD",
        "row": 3563,
        "state": "ARIZONA",
        "group": "909027",
        "group_num": [
            "recDmMri8WF2ou96n"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 23,
        "2023": 0,
        "2024": 0,
        "id": "recOg2hc0u66XuLrA",
        "row": 3575,
        "state": "ARIZONA",
        "group": "915275",
        "group_num": [
            "recCZWvyVhAcWh5jX"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 551,
        "2023": 551,
        "2024": 0,
        "id": "recPdMyjsIovkd9MZ",
        "row": 3598,
        "state": "ARIZONA",
        "group": "927611",
        "group_num": [
            "recYFnPMjH84mie8v"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3098,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ4hrFpcQl1pShZ",
        "row": 3622,
        "state": "ARIZONA",
        "group": "047917803600001",
        "group_num": [
            "rec5bVwrt6UWgJQUd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1843,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQnVVX76o0kH7aE",
        "row": 3606,
        "state": "ARIZONA",
        "group": "2277943",
        "group_num": [
            "rechpoAorHcb4Q12A"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 560,
        "2023": 0,
        "2024": 0,
        "id": "recRtTFQSqMyxS6oB",
        "row": 3617,
        "state": "ARIZONA",
        "group": "78300003",
        "group_num": [
            "recNZuQWVfR0GS2z4"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3102,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRQ6iwY1c1eycA3",
        "row": 3580,
        "state": "ARIZONA",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 368,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS13o9mJGK4qzo7",
        "row": 3551,
        "state": "ARIZONA",
        "group": "906902",
        "group_num": [
            "recjfJG1qdtqiF2Ag"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 511,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSu2fVercJflIO8",
        "row": 3634,
        "state": "ARIZONA",
        "group": "WELLSFARGO",
        "group_num": [
            "rec6i4tueUqwFp8BC"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSB4SyLQKAFrtdG",
        "row": 3556,
        "state": "ARIZONA",
        "group": "908292",
        "group_num": [
            "recxOQSBSWglqS8JY"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSC6JU2DsbDUfOM",
        "row": 3569,
        "state": "ARIZONA",
        "group": "911860",
        "group_num": [
            "recL91IoY3IFosaf6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 623,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUNFGQH6q5kH4Gg",
        "row": 3599,
        "state": "ARIZONA",
        "group": "0703995",
        "group_num": [
            "recihmfaR6sWsBnij"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2242,
        "2023": 0,
        "2024": 0,
        "id": "recUkWa2xUeesZjdL",
        "row": 3566,
        "state": "ARIZONA",
        "group": "909262",
        "group_num": [
            "rec03iyodc6xerJrN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 27,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUmrqTgnSsX2R56",
        "row": 3553,
        "state": "ARIZONA",
        "group": "906902",
        "group_num": [
            "recjfJG1qdtqiF2Ag"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3325,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXX8pw3Hj3EWqWO",
        "row": 3601,
        "state": "ARIZONA",
        "group": "0902494",
        "group_num": [
            "recsNeanLFGpzrTWF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recXGQBES1iUXQ5OY",
        "row": 3596,
        "state": "ARIZONA",
        "group": "924755",
        "group_num": [
            "rec3udQymRdeoSh8q"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1627,
        "2023": 0,
        "2024": 0,
        "id": "recXhLM5FTcHysxuR",
        "row": 3564,
        "state": "ARIZONA",
        "group": "909166",
        "group_num": [
            "recXgZCi84UdHUHVD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "2023": 0,
        "2024": 0,
        "id": "recXraMfq7pyNhB7v",
        "row": 3549,
        "state": "ARIZONA",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 875,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYxzXNmmlwm8dzA",
        "row": 3573,
        "state": "ARIZONA",
        "group": "915275",
        "group_num": [
            "recCZWvyVhAcWh5jX"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 288,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZTARdqZckgT1vC",
        "row": 3603,
        "state": "ARIZONA",
        "group": "0902494",
        "group_num": [
            "recsNeanLFGpzrTWF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZLYTfde00WKJPj",
        "row": 3581,
        "state": "ARIZONA",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZO9scGG0PbTO7o",
        "row": 3576,
        "state": "ARIZONA",
        "group": "915324",
        "group_num": [
            "rec2t7NHga0ReVVXp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 35,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0wPqX6yhsWVbkq",
        "row": 3625,
        "state": "ARIZONA",
        "group": "047917803600001",
        "group_num": [
            "rec5bVwrt6UWgJQUd"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 170,
        "2022": 170,
        "2023": 0,
        "2024": 0,
        "id": "rec06gQUf63Ne14xp",
        "row": 3620,
        "state": "ARIZONA",
        "group": "78800218",
        "group_num": [
            "recFD5IwtXx8XPkjt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4169,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1BL9GS7uxoz31x",
        "row": 3631,
        "state": "ARIZONA",
        "group": "JONESLANGLASA",
        "group_num": [
            "reclXyieuftuGOjIR"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "rec1Lxsaq3FYlJlil",
        "row": 3590,
        "state": "ARIZONA",
        "group": "920859",
        "group_num": [
            "recVPYA2aLn6vcTB7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 610,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2JUODEYZeXtfql",
        "row": 3607,
        "state": "ARIZONA",
        "group": "2277943",
        "group_num": [
            "rechpoAorHcb4Q12A"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 507,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2KGeVkWgNNISrE",
        "row": 3600,
        "state": "ARIZONA",
        "group": "0703995",
        "group_num": [
            "recihmfaR6sWsBnij"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2393,
        "2023": 0,
        "2024": 0,
        "id": "rec27tMf8uNDDdORk",
        "row": 3619,
        "state": "ARIZONA",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 0,
        "2024": 0,
        "id": "rec2eejWZD55DyGnI",
        "row": 3565,
        "state": "ARIZONA",
        "group": "909166",
        "group_num": [
            "recXgZCi84UdHUHVD"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 217,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3KoWIUTBKkuojv",
        "row": 3595,
        "state": "ARIZONA",
        "group": "922755",
        "group_num": [
            "rec4hu3CDzi8l8TSE"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4546,
        "2021": 4139,
        "2022": 4414,
        "2023": 0,
        "2024": 0,
        "id": "rec3L5Tg80sSwAvir",
        "row": 3559,
        "state": "ARIZONA",
        "group": "909006",
        "group_num": [
            "recdGM4oiCZI8w11w"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3325,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec41uQyo6B9OAEPq",
        "row": 3561,
        "state": "ARIZONA",
        "group": "909027",
        "group_num": [
            "recDmMri8WF2ou96n"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4hR0t9tF3MhqVv",
        "row": 3614,
        "state": "ARIZONA",
        "group": "2499811",
        "group_num": [
            "rec2cjluZbD5ORuMg"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 464,
        "2021": 464,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5caJgdP05dJQcX",
        "row": 3628,
        "state": "ARIZONA",
        "group": "HI027210",
        "group_num": [
            "recskr7kl4p8HRTCq"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6ikgawjOlaaitX",
        "row": 3558,
        "state": "ARIZONA",
        "group": "908962",
        "group_num": [
            "recI529xitRyH1pvf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7724YmDqeLQ8C5",
        "row": 3630,
        "state": "ARIZONA",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 132,
        "2021": 132,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8SAZvPy7gfIRrm",
        "row": 3588,
        "state": "ARIZONA",
        "group": "918961",
        "group_num": [
            "recrmcQgCNkOFPJfn"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8IgXXW3aW96XA5",
        "row": 3592,
        "state": "ARIZONA",
        "group": "922520",
        "group_num": [
            "recuy0G6LMtSmBMPV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8fOWZwGsYXIvC4",
        "row": 3629,
        "state": "ARIZONA",
        "group": "HI027210",
        "group_num": [
            "recskr7kl4p8HRTCq"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1106,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbwWvGiewl3nvMO",
        "row": 3586,
        "state": "ARIZONA",
        "group": "918961",
        "group_num": [
            "recrmcQgCNkOFPJfn"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1834,
        "2021": 1833,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "recbzNOgWh3GL8M7L",
        "row": 3626,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1886,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbiwjz6sj7JLiSM",
        "row": 3567,
        "state": "ARIZONA",
        "group": "911404",
        "group_num": [
            "recEvDC2JDI6Tr4Zq"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 330,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccVGGO9CVxLlgek",
        "row": 3621,
        "state": "ARIZONA",
        "group": "78800218",
        "group_num": [
            "recFD5IwtXx8XPkjt"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "2023": 0,
        "2024": 0,
        "id": "reccFBjyETisd8Vj7",
        "row": 3550,
        "state": "ARIZONA",
        "group": "905985",
        "group_num": [
            "recubIHPK6jMZ57wB"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccbLWE2CejDk8dc",
        "row": 3612,
        "state": "ARIZONA",
        "group": "2499811",
        "group_num": [
            "rec2cjluZbD5ORuMg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1612,
        "2021": 0,
        "2022": 347,
        "2023": 0,
        "2024": 0,
        "id": "reccghPrLvP6FkpIw",
        "row": 3624,
        "state": "ARIZONA",
        "group": "047917803600001",
        "group_num": [
            "rec5bVwrt6UWgJQUd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2802,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccgrckgawkDXxzh",
        "row": 3610,
        "state": "ARIZONA",
        "group": "2459482",
        "group_num": [
            "recMl9wkjwFkVLuFx"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdsS0kws4oLneh5",
        "row": 3584,
        "state": "ARIZONA",
        "group": "918961",
        "group_num": [
            "recrmcQgCNkOFPJfn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recohEWvYpOiUlJ0T",
        "row": 3568,
        "state": "ARIZONA",
        "group": "911404",
        "group_num": [
            "recEvDC2JDI6Tr4Zq"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3120,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp35Qk3RIwWPuik",
        "row": 3604,
        "state": "ARIZONA",
        "group": "1532655",
        "group_num": [
            "recHqwvinDz4AnPr3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq6VWuLWOhsaDwy",
        "row": 3570,
        "state": "ARIZONA",
        "group": "911860",
        "group_num": [
            "recL91IoY3IFosaf6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 854,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqasw5tsonNuflV",
        "row": 3562,
        "state": "ARIZONA",
        "group": "909027",
        "group_num": [
            "recDmMri8WF2ou96n"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 850,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrdVNlynRwo7v0e",
        "row": 3578,
        "state": "ARIZONA",
        "group": "915494",
        "group_num": [
            "recp5APUPcU7yW043"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsWEp8LwX3XtqZm",
        "row": 3579,
        "state": "ARIZONA",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3956,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectMQiMeI7j6XmgF",
        "row": 3632,
        "state": "ARIZONA",
        "group": "RAYTHEONCOMPAN",
        "group_num": [
            "recq28nstSy8cjYKB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1533,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect3kjwIgVkV7i3a",
        "row": 3627,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectjspjsnyyAEY1m",
        "row": 3577,
        "state": "ARIZONA",
        "group": "915324",
        "group_num": [
            "rec2t7NHga0ReVVXp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2213,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu09VZdpIj7YwTD",
        "row": 3623,
        "state": "ARIZONA",
        "group": "047917803600001",
        "group_num": [
            "rec5bVwrt6UWgJQUd"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 5700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvUAMEmJ7YApDB2",
        "row": 3608,
        "state": "ARIZONA",
        "group": "2459482",
        "group_num": [
            "recMl9wkjwFkVLuFx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2185,
        "2023": 0,
        "2024": 0,
        "id": "recv32MA6TF483lYr",
        "row": 3616,
        "state": "ARIZONA",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 945,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwqy9cCtz70Kfzx",
        "row": 3613,
        "state": "ARIZONA",
        "group": "2499811",
        "group_num": [
            "rec2cjluZbD5ORuMg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1060,
        "2023": 0,
        "2024": 0,
        "id": "recxQofdXJkNCquHT",
        "row": 3615,
        "state": "ARIZONA",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz8jBdRfTeNSk8H",
        "row": 3571,
        "state": "ARIZONA",
        "group": "914355",
        "group_num": [
            "recDLpHj56m2VEJpf"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4696,
        "2023": 0,
        "2024": 0,
        "id": "recALEG3ltLxFFMjh",
        "row": 3560,
        "state": "ARIZONA",
        "group": "909006",
        "group_num": [
            "recdGM4oiCZI8w11w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recA5wZ6scjWlgyAH",
        "row": 3597,
        "state": "ARIZONA",
        "group": "925681",
        "group_num": [
            "recJolFGVw04jwKe3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBYXMNHo5OApozJ",
        "row": 3557,
        "state": "ARIZONA",
        "group": "908292",
        "group_num": [
            "recxOQSBSWglqS8JY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBc3mRQD4w4i5en",
        "row": 3594,
        "state": "ARIZONA",
        "group": "922755",
        "group_num": [
            "rec4hu3CDzi8l8TSE"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 470,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEe15H22RZJEVna",
        "row": 3602,
        "state": "ARIZONA",
        "group": "0902494",
        "group_num": [
            "recsNeanLFGpzrTWF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGqRi7yB5VMM1az",
        "row": 3591,
        "state": "ARIZONA",
        "group": "921356",
        "group_num": [
            "rec7Nt5jBPwASeNVb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4115,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHuJ2y3RcyPiIO8",
        "row": 3585,
        "state": "ARIZONA",
        "group": "918961",
        "group_num": [
            "recrmcQgCNkOFPJfn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHlfD66E6AaFIxL",
        "row": 3554,
        "state": "ARIZONA",
        "group": "907876",
        "group_num": [
            "recoNlpDgXTAAfh87"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 918,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIvLFF4W2iBwldM",
        "row": 3593,
        "state": "ARIZONA",
        "group": "922755",
        "group_num": [
            "rec4hu3CDzi8l8TSE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIAZKcIOBa4irge",
        "row": 3609,
        "state": "ARIZONA",
        "group": "2459482",
        "group_num": [
            "recMl9wkjwFkVLuFx"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 389,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIKu313i6twQ1Ws",
        "row": 3574,
        "state": "ARIZONA",
        "group": "915275",
        "group_num": [
            "recCZWvyVhAcWh5jX"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3264,
        "2023": 0,
        "2024": 0,
        "id": "recJcSiISsP3ujIlc",
        "row": 3618,
        "state": "ARIZONA",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2138,
        "2021": 2138,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMGHZw2d5moMFwS",
        "row": 3611,
        "state": "ARIZONA",
        "group": "2499811",
        "group_num": [
            "rec2cjluZbD5ORuMg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 84,
        "2023": 0,
        "2024": 0,
        "id": "reciegNiSSqKJa46X",
        "row": 3636,
        "state": "ARIZONA",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 0,
        "2024": 0,
        "id": "recPjDgxDPAqvt7Q9",
        "row": 3639,
        "state": "ARIZONA",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 850,
        "2024": 0,
        "id": "recRxYrnMYiJWFdZ6",
        "row": 3640,
        "state": "ARIZONA",
        "group": "78800335",
        "group_num": [
            "recqBX742RYZ8R9MF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 0,
        "2024": 0,
        "id": "rec7LlQtbkGxxmHQM",
        "row": 3635,
        "state": "ARIZONA",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5100,
        "2023": 0,
        "2024": 0,
        "id": "rectmq8dwdDmNdqV9",
        "row": 3638,
        "state": "ARIZONA",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 437,
        "2023": 0,
        "2024": 0,
        "id": "recBuardIkC3dFhPZ",
        "row": 3642,
        "state": "ARIZONA",
        "group": "G1026047",
        "group_num": [
            "recAHipUCPgFma5Hb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 671,
        "2023": 0,
        "2024": 0,
        "id": "recGl43c3TY55aFCZ",
        "row": 3637,
        "state": "ARIZONA",
        "group": "78800218",
        "group_num": [
            "recFD5IwtXx8XPkjt"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 832,
        "2023": 0,
        "2024": 0,
        "id": "recHuykRudJATywLD",
        "row": 3641,
        "state": "ARIZONA",
        "group": "G1026047",
        "group_num": [
            "recAHipUCPgFma5Hb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 99,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwhQC0WE2R9h5yz",
        "row": 3643,
        "state": "ARIZONA",
        "group": "705214",
        "group_num": [
            "recS81ysw30m7zA6f"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH ONE"
    },
    {
        "2020": 0,
        "2021": 1080,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWWAyMBSg5ZcrlX",
        "row": 3644,
        "state": "ARIZONA",
        "group": "30609",
        "group_num": [
            "recsr45TGEZHNX19C"
        ],
        "procedure": "Detox",
        "provider": "WEA TRUST"
    },
    {
        "2020": 3000,
        "2021": 2846,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck4KPRJItPrqNSt",
        "row": 3647,
        "state": "ARIZONA",
        "group": "SMHFJ05",
        "group_num": [
            "recmAqI4yyG2f1RPQ"
        ],
        "procedure": "PHP",
        "provider": "WEB TPA"
    },
    {
        "2020": 5100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdK6VmzTuRsyoAi",
        "row": 3645,
        "state": "ARIZONA",
        "group": "2011CHI",
        "group_num": [
            "recqcsYAh7EWY6kvR"
        ],
        "procedure": "Detox",
        "provider": "WEB TPA"
    },
    {
        "2020": 395,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEDSUscC0nUl6Bc",
        "row": 3646,
        "state": "ARIZONA",
        "group": "2020CYRA",
        "group_num": [
            "recR4XqpHQyWvjLRW"
        ],
        "procedure": "Detox",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 391,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdbcOqgbsICyAsK",
        "row": 3649,
        "state": "ARIZONA",
        "group": "WSSMC",
        "group_num": [
            "reczginBjhFrFgHe4"
        ],
        "procedure": "PHP",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 1255,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKMcW77uYsduYdS",
        "row": 3648,
        "state": "ARIZONA",
        "group": "WSSMC",
        "group_num": [
            "reczginBjhFrFgHe4"
        ],
        "procedure": "Detox",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4y0mWwWwha7Iue",
        "row": 3651,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "WORKFORCE ASSISTANCE"
    },
    {
        "2020": 950,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reconS7weD2rIEnAw",
        "row": 3650,
        "state": "ARIZONA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "WORKFORCE ASSISTANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 500,
        "2024": 0,
        "id": "recjb4OmpBVvMDJUe",
        "row": 3652,
        "state": "COLORADO",
        "group": "G0185106",
        "group_num": [
            "recfKP8K82C1dhmgq"
        ],
        "procedure": "IOP",
        "provider": "ACTIVE CARE MANAGEMENT"
    },
    {
        "2020": 593,
        "2021": 679,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgDTQcrVcrIbriE",
        "row": 3749,
        "state": "COLORADO",
        "group": "031477001600001",
        "group_num": [
            "recNkysasK2BRwpM3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 819,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechYbUrjoKTc93aK",
        "row": 3676,
        "state": "COLORADO",
        "group": "83920201500002",
        "group_num": [
            "recVgX0NFnbsm0m3j"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1712,
        "2021": 1210,
        "2022": 1035,
        "2023": 1492,
        "2024": 0,
        "id": "rechBIckF80DBrpsD",
        "row": 3698,
        "state": "COLORADO",
        "group": "014986601200001",
        "group_num": [
            "recD5jM5BNjQhKpSH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1149,
        "2023": 0,
        "2024": 0,
        "id": "rechQNxjfRXn4MYMd",
        "row": 3709,
        "state": "COLORADO",
        "group": "016954101400038",
        "group_num": [
            "reci2bqhVsbggCxsQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciLJWwXZ8csBvTh",
        "row": 3654,
        "state": "COLORADO",
        "group": "0141360",
        "group_num": [
            "recOPwhsQEFLjgF2t"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 173,
        "2024": 0,
        "id": "recjyGoWx7mK69eM2",
        "row": 3722,
        "state": "COLORADO",
        "group": "017586001000001",
        "group_num": [
            "reckJBiUYb7sMeJYZ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2616,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjhjp0I1pYXuaaJ",
        "row": 3655,
        "state": "COLORADO",
        "group": "0863881",
        "group_num": [
            "rec1Kpi8vu16IIdoN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 664,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckgG4QaeWYS2huA",
        "row": 3664,
        "state": "COLORADO",
        "group": "0865385011001",
        "group_num": [
            "recbNq7izJjkFotOr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 469,
        "2024": 0,
        "id": "recl794qV6zEIFqkp",
        "row": 3703,
        "state": "COLORADO",
        "group": "016097801000001",
        "group_num": [
            "recD60jaf2ReFOTrf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 561,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn2DuZLQxOiRROz",
        "row": 3657,
        "state": "COLORADO",
        "group": "0868493",
        "group_num": [
            "rech4gnn6V84we4Ad"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 469,
        "2022": 469,
        "2023": 0,
        "2024": 0,
        "id": "recOTvAT0YLiT0fvg",
        "row": 3728,
        "state": "COLORADO",
        "group": "028400102100001",
        "group_num": [
            "recmZea2Xb9lhNr1t"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 697,
        "2024": 0,
        "id": "recOtTlsMP8GUHHNd",
        "row": 3706,
        "state": "COLORADO",
        "group": "016108701100001",
        "group_num": [
            "recG4bg23QZ28rHKp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 819,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPX9kiCcEQnYGCd",
        "row": 3669,
        "state": "COLORADO",
        "group": "07015170240000",
        "group_num": [
            "recki3QNcWuH5iCS6"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1388,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPCepNWhw3tCExs",
        "row": 3707,
        "state": "COLORADO",
        "group": "016125001200001",
        "group_num": [
            "rec7RPcMvxSD4Qsyv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1388,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRTyYwL9vqn5fA5",
        "row": 3739,
        "state": "COLORADO",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 215,
        "2022": 444,
        "2023": 0,
        "2024": 0,
        "id": "recRO4uOEzvsWkaAn",
        "row": 3687,
        "state": "COLORADO",
        "group": "014187203200000",
        "group_num": [
            "recyOkraDA8dC4Ii3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 252,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS6A5ATXFLgp25O",
        "row": 3713,
        "state": "COLORADO",
        "group": "016954201000033",
        "group_num": [
            "recIhj5Di6Qn1oyOX"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1032,
        "2023": 0,
        "2024": 0,
        "id": "recSch6SEyALnXOML",
        "row": 3720,
        "state": "COLORADO",
        "group": "017543801500003",
        "group_num": [
            "recLYx6te8nLC2n5g"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "2023": 0,
        "2024": 0,
        "id": "recU1wlPaYkbgB0L2",
        "row": 3725,
        "state": "COLORADO",
        "group": "017656301300044",
        "group_num": [
            "recvvFPq73Qy87sQe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 485,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUbwmFgtkOCXuI7",
        "row": 3693,
        "state": "COLORADO",
        "group": "014282001600003",
        "group_num": [
            "recZi0aWYQy1wxOoY"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 367,
        "2021": 485,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUhsuiacVv8PPrJ",
        "row": 3691,
        "state": "COLORADO",
        "group": "014281601600003",
        "group_num": [
            "recLRp89EMogoHb52"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1666,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVP3BN4pJ6S8UZW",
        "row": 3745,
        "state": "COLORADO",
        "group": "028797601000716",
        "group_num": [
            "recHKrkIe6GVmUj5I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 399,
        "2023": 0,
        "2024": 0,
        "id": "recWFfVHvn3zpf5Zx",
        "row": 3721,
        "state": "COLORADO",
        "group": "017586001000001",
        "group_num": [
            "reckJBiUYb7sMeJYZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1031,
        "2024": 0,
        "id": "recWMeDpaE7fsYK6o",
        "row": 3719,
        "state": "COLORADO",
        "group": "017506701100001",
        "group_num": [
            "reco6adsX7RyQp84A"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 291,
        "2021": 307,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXnib2LDkp1LgU2",
        "row": 3665,
        "state": "COLORADO",
        "group": "0865385011001",
        "group_num": [
            "recbNq7izJjkFotOr"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "2023": 0,
        "2024": 0,
        "id": "recY3HMStIxQmz4v4",
        "row": 3747,
        "state": "COLORADO",
        "group": "030717701300201",
        "group_num": [
            "recmiPj5aJxkI7T4p"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 652,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0TZgzag8YPEDfj",
        "row": 3674,
        "state": "COLORADO",
        "group": "14187001000037",
        "group_num": [
            "recvAQtpk3pSz9oef"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 679,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1WeN4U2YKOy2Dc",
        "row": 3732,
        "state": "COLORADO",
        "group": "028555001000008",
        "group_num": [
            "recTHyCJIW1gwJXHU"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2RSwuTqOzy9ex3",
        "row": 3663,
        "state": "COLORADO",
        "group": "0719982013000",
        "group_num": [
            "recF0Hq9fv5HEJOA9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec26ImERNUdhV3we",
        "row": 3659,
        "state": "COLORADO",
        "group": "464003401",
        "group_num": [
            "rec2hsXjzwQz2LOZB"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 0,
        "2024": 0,
        "id": "rec3YIvsVxuajuP5k",
        "row": 3710,
        "state": "COLORADO",
        "group": "016954101400038",
        "group_num": [
            "reci2bqhVsbggCxsQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 775,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3ChgvsppQ6lytG",
        "row": 3716,
        "state": "COLORADO",
        "group": "016970101100001",
        "group_num": [
            "recGt64WfBgnUEpRl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2013,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3r6uhdRrYQyq3Z",
        "row": 3673,
        "state": "COLORADO",
        "group": "14187001000037",
        "group_num": [
            "recvAQtpk3pSz9oef"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 382,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5B76Dh0mDcCBD7",
        "row": 3677,
        "state": "COLORADO",
        "group": "86859801000502",
        "group_num": [
            "rec3ccarRzXMywf4i"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1099,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5QVRivwdEoKc6h",
        "row": 3740,
        "state": "COLORADO",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 248,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7bsBfwhVGrGPls",
        "row": 3694,
        "state": "COLORADO",
        "group": "014282001600003",
        "group_num": [
            "recZi0aWYQy1wxOoY"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 0,
        "2024": 0,
        "id": "rec8uknzDyYDWPbti",
        "row": 3679,
        "state": "COLORADO",
        "group": "010063505200006",
        "group_num": [
            "recVYhBVjhLSKKRHu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 853,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8x204HoBoOzNqy",
        "row": 3683,
        "state": "COLORADO",
        "group": "010917301000010",
        "group_num": [
            "recsG40QMeg6GoQVF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 242,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8MGBjF921yjrp9",
        "row": 3661,
        "state": "COLORADO",
        "group": "14188111005",
        "group_num": [
            "rec1gBuXCPSCUgMEn"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1099,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccXhhhqqvw3lwVw",
        "row": 3735,
        "state": "COLORADO",
        "group": "028555001000009",
        "group_num": [
            "recoYAH1jkt9xJcr9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccPArsyDo9n4MwZ",
        "row": 3675,
        "state": "COLORADO",
        "group": "23180301400001",
        "group_num": [
            "recCfkl2ac9MuPDTF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "2023": 0,
        "2024": 0,
        "id": "reccPEbSf4he30ojU",
        "row": 3688,
        "state": "COLORADO",
        "group": "014187203200000",
        "group_num": [
            "recyOkraDA8dC4Ii3"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 1328,
        "2024": 0,
        "id": "reccigTlxurfCdXb7",
        "row": 3718,
        "state": "COLORADO",
        "group": "017506701100001",
        "group_num": [
            "reco6adsX7RyQp84A"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 938,
        "2022": 1099,
        "2023": 0,
        "2024": 0,
        "id": "reccrM8COW6q0O0LE",
        "row": 3699,
        "state": "COLORADO",
        "group": "014986601200001",
        "group_num": [
            "recD5jM5BNjQhKpSH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 377,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd04G3upTOMSJI8",
        "row": 3660,
        "state": "COLORADO",
        "group": "14188111005",
        "group_num": [
            "rec1gBuXCPSCUgMEn"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 679,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recovQqHPVCsUqh9Z",
        "row": 3741,
        "state": "COLORADO",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 656,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco7hzP6bkHELit1",
        "row": 3751,
        "state": "COLORADO",
        "group": "032647701000145",
        "group_num": [
            "receC3KwRCWHHGeDC"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1123,
        "2023": 0,
        "2024": 0,
        "id": "recprFKVP6h4kXGC9",
        "row": 3724,
        "state": "COLORADO",
        "group": "017656301300044",
        "group_num": [
            "recvvFPq73Qy87sQe"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 819,
        "2021": 819,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqw3XEDAAcGK4Po",
        "row": 3731,
        "state": "COLORADO",
        "group": "028555001000008",
        "group_num": [
            "recTHyCJIW1gwJXHU"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs3xFkefD61ftSJ",
        "row": 3658,
        "state": "COLORADO",
        "group": "0895530",
        "group_num": [
            "recFo6IEUzS9r5oJU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1443,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs8OgJA1rqDH8P1",
        "row": 3690,
        "state": "COLORADO",
        "group": "014281601600003",
        "group_num": [
            "recLRp89EMogoHb52"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 1944,
        "2023": 0,
        "2024": 0,
        "id": "rectnUbDHkGeYa2C5",
        "row": 3730,
        "state": "COLORADO",
        "group": "028554803000001",
        "group_num": [
            "recRtJXn3ASmjkNZ2"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 0,
        "2024": 0,
        "id": "recu9ve0tIC3WBGo2",
        "row": 3704,
        "state": "COLORADO",
        "group": "016097801000001",
        "group_num": [
            "recD60jaf2ReFOTrf"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 842,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recurdMdRwlDta8E3",
        "row": 3670,
        "state": "COLORADO",
        "group": "07015170240000",
        "group_num": [
            "recki3QNcWuH5iCS6"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "2023": 0,
        "2024": 0,
        "id": "recvDp7ml7aAcfT4t",
        "row": 3680,
        "state": "COLORADO",
        "group": "010063505200006",
        "group_num": [
            "recVYhBVjhLSKKRHu"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "2023": 0,
        "2024": 0,
        "id": "recvmBoVCGvrRxMgT",
        "row": 3700,
        "state": "COLORADO",
        "group": "016014401600001",
        "group_num": [
            "rec1uwnXa8EUrrJON"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 2945,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwWZk0fhn3qZWq7",
        "row": 3678,
        "state": "COLORADO",
        "group": "010016601800020",
        "group_num": [
            "recyEiFsRl55TE24Q"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxnJ9uVuq976jEP",
        "row": 3696,
        "state": "COLORADO",
        "group": "014846201200001",
        "group_num": [
            "reciPsUB4vZOMQolF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 361,
        "2021": 431,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyjmcaZWDX6TWie",
        "row": 3712,
        "state": "COLORADO",
        "group": "016954201000033",
        "group_num": [
            "recIhj5Di6Qn1oyOX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2020,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAKoFtTqYd2ltH7",
        "row": 3748,
        "state": "COLORADO",
        "group": "031477001600001",
        "group_num": [
            "recNkysasK2BRwpM3"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1539,
        "2023": 0,
        "2024": 0,
        "id": "recAPh2pr6LfZPSJ1",
        "row": 3715,
        "state": "COLORADO",
        "group": "016961704300001",
        "group_num": [
            "recGLxZVbwj5TigJx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1423,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBBhUiXws8hY0KQ",
        "row": 3746,
        "state": "COLORADO",
        "group": "028797601000716",
        "group_num": [
            "recHKrkIe6GVmUj5I"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1134,
        "2023": 0,
        "2024": 0,
        "id": "recBDJtKfHg0t7FLC",
        "row": 3684,
        "state": "COLORADO",
        "group": "011911902000002",
        "group_num": [
            "recLqWfXRU3GWs6mo"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3219,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCAgkd458KCfs3Z",
        "row": 3736,
        "state": "COLORADO",
        "group": "028562901000004",
        "group_num": [
            "recFNgy32AfpQAJPJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1388,
        "2022": 1388,
        "2023": 0,
        "2024": 0,
        "id": "recC8C2HOgDIN1LWL",
        "row": 3750,
        "state": "COLORADO",
        "group": "032640201000004",
        "group_num": [
            "rec95FtemycKPB9Bs"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2372,
        "2023": 0,
        "2024": 0,
        "id": "recEIK4ZHG7j4Az9e",
        "row": 3737,
        "state": "COLORADO",
        "group": "028562901100006",
        "group_num": [
            "recP55oXzyQ8g9Eg9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 691,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEdTQSUuEqxA40j",
        "row": 3744,
        "state": "COLORADO",
        "group": "028725101000002",
        "group_num": [
            "recVA78cc874JaXdc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1143,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEr3IBULMpsf7we",
        "row": 3717,
        "state": "COLORADO",
        "group": "017028501900043",
        "group_num": [
            "recKOxbltrPBg9XRJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 707,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGDxm1dgGvsfumY",
        "row": 3667,
        "state": "COLORADO",
        "group": "8683200100000",
        "group_num": [
            "recpp3rJYV2JtmsZG"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 485,
        "2021": 485,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHJwBtf2sVlK7cJ",
        "row": 3726,
        "state": "COLORADO",
        "group": "023174301600002",
        "group_num": [
            "recoCmqlpYypbZlI2"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 955,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHmjQ1Mctz3by1y",
        "row": 3708,
        "state": "COLORADO",
        "group": "016152401200001",
        "group_num": [
            "reccKJ8lkIXr4Eqzc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2020,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHpPuGZkz0wXHOm",
        "row": 3672,
        "state": "COLORADO",
        "group": "08364060120001",
        "group_num": [
            "receDSKZEUjbWaoTI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1046,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIlImfNz6jKCZcK",
        "row": 3656,
        "state": "COLORADO",
        "group": "0863881",
        "group_num": [
            "rec1Kpi8vu16IIdoN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJQIeGP10ySptt5",
        "row": 3668,
        "state": "COLORADO",
        "group": "8683200100000",
        "group_num": [
            "recpp3rJYV2JtmsZG"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 1455,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKSrG5BQiJzgSQs",
        "row": 3685,
        "state": "COLORADO",
        "group": "014146601100001",
        "group_num": [
            "recFjE28FnATMv2H1"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1944,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKuRA5HrPViJ9OE",
        "row": 3734,
        "state": "COLORADO",
        "group": "028555001000009",
        "group_num": [
            "recoYAH1jkt9xJcr9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 961,
        "2024": 0,
        "id": "recLA5M0YEpUnO6Ag",
        "row": 3682,
        "state": "COLORADO",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 315,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLdnVssa8teuolQ",
        "row": 3742,
        "state": "COLORADO",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 1142,
        "2021": 1142,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNX7iozVwXYaF6g",
        "row": 3697,
        "state": "COLORADO",
        "group": "014846201200001",
        "group_num": [
            "reciPsUB4vZOMQolF"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recN89caSJT9IczXj",
        "row": 3738,
        "state": "COLORADO",
        "group": "028565801000001",
        "group_num": [
            "recJG2m2oyzicsblI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 257,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfKatJxZOctWOdF",
        "row": 3799,
        "state": "COLORADO",
        "group": "077605901000708",
        "group_num": [
            "recrNoT0Dhs2M4kSb"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 189,
        "2024": 0,
        "id": "recf92cOR4XBaxkP3",
        "row": 3760,
        "state": "COLORADO",
        "group": "046605601800015",
        "group_num": [
            "recVQCjTGkVrD3fRu"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 2020,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfmHUxr66l7wIcc",
        "row": 3789,
        "state": "COLORADO",
        "group": "066697302400212",
        "group_num": [
            "rec1A9C2MOpjw3dLj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "2023": 0,
        "2024": 0,
        "id": "recigAUGjKh0KMUjX",
        "row": 3759,
        "state": "COLORADO",
        "group": "046605601800015",
        "group_num": [
            "recVQCjTGkVrD3fRu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 679,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmiwHUdL19hmxwW",
        "row": 3781,
        "state": "COLORADO",
        "group": "066037701000006",
        "group_num": [
            "recAwV9GHXKM1Jhd3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1113,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmlMCoewiZgO3RW",
        "row": 3775,
        "state": "COLORADO",
        "group": "061937901000003",
        "group_num": [
            "rec2xQlDHEBIPlBUS"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 339,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnccgNBaKGsJvP5",
        "row": 3782,
        "state": "COLORADO",
        "group": "066037701000006",
        "group_num": [
            "recAwV9GHXKM1Jhd3"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1150,
        "2024": 0,
        "id": "recOt9leZII0dCsjk",
        "row": 3770,
        "state": "COLORADO",
        "group": "048686104800002",
        "group_num": [
            "reciG5ebUwZfdjH1g"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 0,
        "2024": 0,
        "id": "recOKKJqLc4SaSbcZ",
        "row": 3778,
        "state": "COLORADO",
        "group": "062026101800101",
        "group_num": [
            "recfh9HI6IP7sbPBZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2002,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recO6vYNaaWdBc7Dp",
        "row": 3788,
        "state": "COLORADO",
        "group": "066081901800001",
        "group_num": [
            "recLJxMANPYneR8kY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 48,
        "2024": 0,
        "id": "recQQcN49jiRxTqzs",
        "row": 3762,
        "state": "COLORADO",
        "group": "046605601800023",
        "group_num": [
            "rec8nI3qyoWLDrCqu"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 3900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTf5Lt0YUzRijq8",
        "row": 3780,
        "state": "COLORADO",
        "group": "065875902100009",
        "group_num": [
            "recPPSi7bI1hX0pr7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1706,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUWYliGvBcoK8xu",
        "row": 3763,
        "state": "COLORADO",
        "group": "046859201200119",
        "group_num": [
            "rec9gsNnuswukBjyk"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVSySca0oTg7mJA",
        "row": 3758,
        "state": "COLORADO",
        "group": "046601202100301",
        "group_num": [
            "rec0IWtZ4GZU8GMwC"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recYhho19q22mcW0c",
        "row": 3774,
        "state": "COLORADO",
        "group": "048687501000101",
        "group_num": [
            "recAaVWr4tOr2vO7M"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 51,
        "2024": 0,
        "id": "recZn1inCUkj23w8L",
        "row": 3769,
        "state": "COLORADO",
        "group": "047676101100012",
        "group_num": [
            "recqQscaBkgZZvXch"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 708,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2Ir62oAzzSWc9f",
        "row": 3764,
        "state": "COLORADO",
        "group": "046859201200119",
        "group_num": [
            "rec9gsNnuswukBjyk"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 723,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8OEFXCc6CdTkoI",
        "row": 3755,
        "state": "COLORADO",
        "group": "039736605300150",
        "group_num": [
            "rec5JZu03akLhPwC1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 4921,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9F73LQ2P1U5JSi",
        "row": 3791,
        "state": "COLORADO",
        "group": "069868504000101",
        "group_num": [
            "recS8CS1nkFSeOK3T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3243,
        "2023": 0,
        "2024": 0,
        "id": "reccO2hU6xBwNhze9",
        "row": 3801,
        "state": "COLORADO",
        "group": "077940904200601",
        "group_num": [
            "recL2O5h4AXwjdjRr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 4921,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd4amDklWljSOdY",
        "row": 3773,
        "state": "COLORADO",
        "group": "048687101200002",
        "group_num": [
            "recDxNGc7YMLDbdqI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 2143,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoBmP0pY2knpqo1",
        "row": 3784,
        "state": "COLORADO",
        "group": "066037701100006",
        "group_num": [
            "rec7jd4MF5yiKubOC"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1120,
        "2021": 892,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpB8ljzXoXD11s8",
        "row": 3798,
        "state": "COLORADO",
        "group": "077346701000002",
        "group_num": [
            "recn9Af4VuMJxfqPh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "2023": 0,
        "2024": 0,
        "id": "recqvfnLBfHkxf9Jj",
        "row": 3797,
        "state": "COLORADO",
        "group": "072781301500001",
        "group_num": [
            "recvyvt7ZPJ7OE3pP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 797,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqO8aTpLHlEbPY7",
        "row": 3766,
        "state": "COLORADO",
        "group": "047490802900101",
        "group_num": [
            "recVlpy9PnQqY6vY7"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 578,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqlUnnxyEuro8FM",
        "row": 3753,
        "state": "COLORADO",
        "group": "039363001900005",
        "group_num": [
            "recUy3SHrIHcIUXya"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1574,
        "2023": 0,
        "2024": 0,
        "id": "rect9IKASkRyxsFJY",
        "row": 3790,
        "state": "COLORADO",
        "group": "069802102400001",
        "group_num": [
            "reciPb9QC4YHKHkT9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 389,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwCNL5qhhYsaOca",
        "row": 3792,
        "state": "COLORADO",
        "group": "070644301000505",
        "group_num": [
            "recgjBG6Vu1R8vOXx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 561,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwHFrVzJMbEsNVy",
        "row": 3756,
        "state": "COLORADO",
        "group": "039736605300150",
        "group_num": [
            "rec5JZu03akLhPwC1"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1194,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyxKjGPuZujm7LO",
        "row": 3772,
        "state": "COLORADO",
        "group": "048687001200001",
        "group_num": [
            "receyEkK4s6XGJUo2"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2721,
        "2023": 0,
        "2024": 0,
        "id": "reczZSduQpzPTigLR",
        "row": 3794,
        "state": "COLORADO",
        "group": "071998201300020",
        "group_num": [
            "recKDuRDTNfA7pBSn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 679,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHUsF3bVVrXerpW",
        "row": 3785,
        "state": "COLORADO",
        "group": "066065701000001",
        "group_num": [
            "reco2bVsbBCGxEtLv"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1259,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHZzPWbW6gE9pUx",
        "row": 3796,
        "state": "COLORADO",
        "group": "072781301200000",
        "group_num": [
            "recvDpCcOovEPrzwu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 1150,
        "2024": 0,
        "id": "recIR2J9BzzawtbMb",
        "row": 3768,
        "state": "COLORADO",
        "group": "047676101100012",
        "group_num": [
            "recqQscaBkgZZvXch"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1205,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLp0Jr2ygSGgJPp",
        "row": 3795,
        "state": "COLORADO",
        "group": "072781301100001",
        "group_num": [
            "recPdNOsEmnWKFhgJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 554,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM0kFj3XBDDRsrK",
        "row": 3776,
        "state": "COLORADO",
        "group": "061937901000003",
        "group_num": [
            "rec2xQlDHEBIPlBUS"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 362,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN5klSf85EZ7C5n",
        "row": 3754,
        "state": "COLORADO",
        "group": "039363001900005",
        "group_num": [
            "recUy3SHrIHcIUXya"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 193,
        "2024": 0,
        "id": "receBvah5VirduL1p",
        "row": 3819,
        "state": "COLORADO",
        "group": "084782101000001",
        "group_num": [
            "recyvRyTG5wuLUVNh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 0,
        "2024": 0,
        "id": "rechGqKMtdUI89Qv4",
        "row": 3830,
        "state": "COLORADO",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2567,
        "2023": 0,
        "2024": 0,
        "id": "reci5pcjpXAynC4KU",
        "row": 3802,
        "state": "COLORADO",
        "group": "077940904200601",
        "group_num": [
            "recL2O5h4AXwjdjRr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3900,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjJe47jsIdmOI9F",
        "row": 3804,
        "state": "COLORADO",
        "group": "077940905700601",
        "group_num": [
            "recFNoaW8RU3fxRb0"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 679,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnGIMjLRx04LwBQ",
        "row": 3836,
        "state": "COLORADO",
        "group": "086859801100001",
        "group_num": [
            "recE7zW4tDoW4PcWK"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1345,
        "2023": 0,
        "2024": 0,
        "id": "recn4Osdiys3hnEGg",
        "row": 3805,
        "state": "COLORADO",
        "group": "080010001700001",
        "group_num": [
            "rech8qfFMDAr0bAqX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 2020,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnlP472ourf4XKi",
        "row": 3828,
        "state": "COLORADO",
        "group": "086621903100001",
        "group_num": [
            "recT0dk3eMGG1wtIL"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2095,
        "2022": 1944,
        "2023": 0,
        "2024": 0,
        "id": "recOGkhfdQWhoMPCZ",
        "row": 3831,
        "state": "COLORADO",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2714,
        "2023": 0,
        "2024": 0,
        "id": "recPH3oMFxebwczzW",
        "row": 3826,
        "state": "COLORADO",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 485,
        "2021": 446,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRAwsNjACnmgsrt",
        "row": 3813,
        "state": "COLORADO",
        "group": "084011001000001",
        "group_num": [
            "rectTJGVr6MttdBYk"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1443,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRBItGgZVSh0yKT",
        "row": 3810,
        "state": "COLORADO",
        "group": "083552201100001",
        "group_num": [
            "recOoBiHGmiPB6BNx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 656,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRljJorxOK45xfS",
        "row": 3821,
        "state": "COLORADO",
        "group": "086142501400005",
        "group_num": [
            "recQE3ySe4G18RAR2"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 870,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSXCP5hHEpyWzhr",
        "row": 3816,
        "state": "COLORADO",
        "group": "084287704000401",
        "group_num": [
            "recHEoj5F8pjSHVLN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 234,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUKXj8Lae5Y2rqT",
        "row": 3814,
        "state": "COLORADO",
        "group": "084011001000001",
        "group_num": [
            "rectTJGVr6MttdBYk"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1031,
        "2024": 0,
        "id": "recVerkiWddVyaEBa",
        "row": 3820,
        "state": "COLORADO",
        "group": "084782101000001",
        "group_num": [
            "recyvRyTG5wuLUVNh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 627,
        "2022": 1150,
        "2023": 0,
        "2024": 0,
        "id": "recXwxl2eajhntcLM",
        "row": 3806,
        "state": "COLORADO",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3088,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX2DtZ3ATeDy8C7",
        "row": 3803,
        "state": "COLORADO",
        "group": "077940904800111",
        "group_num": [
            "recLi2NJLhWC8tmeR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 50,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY1h1NEhyz5SVrA",
        "row": 3860,
        "state": "COLORADO",
        "group": "OREPACHOLDING",
        "group_num": [
            "rect4CUU8RnhRz8LO"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 632,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYt15h0PPDjFBYp",
        "row": 3841,
        "state": "COLORADO",
        "group": "086881001300001",
        "group_num": [
            "recML3VasWhJPKNiK"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 280,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZLFSwQcOkjMwpz",
        "row": 3856,
        "state": "COLORADO",
        "group": "088551503100003",
        "group_num": [
            "recukO06vavsmsc4S"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 819,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2x18BYZQJlnKVq",
        "row": 3835,
        "state": "COLORADO",
        "group": "086859801100001",
        "group_num": [
            "recE7zW4tDoW4PcWK"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 0,
        "2024": 0,
        "id": "rec3JMnSP7DUhWsTG",
        "row": 3852,
        "state": "COLORADO",
        "group": "088275801000213",
        "group_num": [
            "recnG760DS9Vha6Iu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1443,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5nLwzK9YxgBzDV",
        "row": 3851,
        "state": "COLORADO",
        "group": "088199903100106",
        "group_num": [
            "recuTmRwmuAMmqA00"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 600,
        "2021": 585,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6Ve4ZCKImaDia0",
        "row": 3849,
        "state": "COLORADO",
        "group": "088170902200008",
        "group_num": [
            "recH1i6qyqFE8G8Hp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 221,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec75Y9jFm6z8Jn8q",
        "row": 3842,
        "state": "COLORADO",
        "group": "086881001300001",
        "group_num": [
            "recML3VasWhJPKNiK"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2020,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaHnj6fEwrpVH1W",
        "row": 3846,
        "state": "COLORADO",
        "group": "087709304500210",
        "group_num": [
            "recp0ozj6djU99Oio"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 328,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recalCZug1etZdEwM",
        "row": 3822,
        "state": "COLORADO",
        "group": "086142501400005",
        "group_num": [
            "recQE3ySe4G18RAR2"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 0,
        "2024": 0,
        "id": "recb7mq3Ea19HrEWe",
        "row": 3853,
        "state": "COLORADO",
        "group": "088352801900001",
        "group_num": [
            "recQuRY0Ihfc619mE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "2024": 0,
        "id": "recc49HHScZmEzjto",
        "row": 3833,
        "state": "COLORADO",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 919,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recccAiVGuy7XjvXT",
        "row": 3840,
        "state": "COLORADO",
        "group": "086869601700013",
        "group_num": [
            "recdQfqFDyMqg4sZi"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 478,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco70pfrjBRLIGcB",
        "row": 3844,
        "state": "COLORADO",
        "group": "086989302000001",
        "group_num": [
            "recf8iTwENquhH1dx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "2023": 0,
        "2024": 0,
        "id": "recqWhRGRJr1ZQdbY",
        "row": 3854,
        "state": "COLORADO",
        "group": "088352801900001",
        "group_num": [
            "recQuRY0Ihfc619mE"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recqQF9W7GJWJA6u1",
        "row": 3825,
        "state": "COLORADO",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1474,
        "2023": 0,
        "2024": 0,
        "id": "recrSFhG0rHKVl3ql",
        "row": 3848,
        "state": "COLORADO",
        "group": "087715701600001",
        "group_num": [
            "recmRtGsy4gS209rS"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 448,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recryqKlwQF6jX7AT",
        "row": 3811,
        "state": "COLORADO",
        "group": "083552201100001",
        "group_num": [
            "recOoBiHGmiPB6BNx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 729,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvT5tgYSVJI1mZd",
        "row": 3857,
        "state": "COLORADO",
        "group": "088559401000001",
        "group_num": [
            "reczm0T0JotcV5cGp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 1150,
        "2024": 0,
        "id": "recvE00BvMERvqoz9",
        "row": 3838,
        "state": "COLORADO",
        "group": "086869601200020",
        "group_num": [
            "recAqD7u5Nzl9Ar0j"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 1142,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvKrN1o9F368JuF",
        "row": 3859,
        "state": "COLORADO",
        "group": "089553001000304",
        "group_num": [
            "recGVkkV2vY89Hyap"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recxH4N2fFW0qYjFo",
        "row": 3858,
        "state": "COLORADO",
        "group": "088759901800009",
        "group_num": [
            "reco0k630XQKqlQmv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczT0OhvSxYd4RvK",
        "row": 3818,
        "state": "COLORADO",
        "group": "084718801100002",
        "group_num": [
            "rec4M6jPKbDW4fAPw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1539,
        "2022": 1648,
        "2023": 0,
        "2024": 0,
        "id": "recBrococ2agCsxbj",
        "row": 3832,
        "state": "COLORADO",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 0,
        "2024": 0,
        "id": "recCvnKVZznZxzosh",
        "row": 3847,
        "state": "COLORADO",
        "group": "087715701600001",
        "group_num": [
            "recmRtGsy4gS209rS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 2020,
        "2021": 1443,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDPeZrJCV0nqbJQ",
        "row": 3829,
        "state": "COLORADO",
        "group": "086840301200001",
        "group_num": [
            "recC09LGcyQafWhUU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 564,
        "2021": 1196,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHCKx3J9h9nHjKG",
        "row": 3824,
        "state": "COLORADO",
        "group": "086621102200102",
        "group_num": [
            "recJ5XJNTNo9LpxGW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 193,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJdJriy33k5aEvi",
        "row": 3827,
        "state": "COLORADO",
        "group": "086621202200248",
        "group_num": [
            "rec1oWA0MxaDFIEQw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 165,
        "2022": 61,
        "2023": 85,
        "2024": 0,
        "id": "recMRHhopoa8j7Ybs",
        "row": 3807,
        "state": "COLORADO",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "2023": 0,
        "2024": 0,
        "id": "recVtb5Sin8jyFbfT",
        "row": 3863,
        "state": "COLORADO",
        "group": "5400004955",
        "group_num": [
            "recNzVeVonpzMf8wC"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 143,
        "2023": 0,
        "2024": 0,
        "id": "rec1HmtwrUvWyHAGU",
        "row": 3866,
        "state": "COLORADO",
        "group": "5400010490",
        "group_num": [
            "recvlJkLQ0WpVXq6H"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recalFIfMsrMcR5Mz",
        "row": 3869,
        "state": "COLORADO",
        "group": "5400017672",
        "group_num": [
            "recKcbSCkkBVGqA1t"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "2023": 0,
        "2024": 0,
        "id": "recooxQQ2CL8aoxF7",
        "row": 3862,
        "state": "COLORADO",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recuwo8gOVGvbQCZD",
        "row": 3867,
        "state": "COLORADO",
        "group": "5400010490",
        "group_num": [
            "recvlJkLQ0WpVXq6H"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 28,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczaRU3KPV5WBKSS",
        "row": 3865,
        "state": "COLORADO",
        "group": "5400007974",
        "group_num": [
            "recMA0n6QznLZb8DT"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 938,
        "2022": 959,
        "2023": 959,
        "2024": 0,
        "id": "recBfIdRKkpO9MvZe",
        "row": 3873,
        "state": "COLORADO",
        "group": "5400030631",
        "group_num": [
            "recSOkFCTHWbaHqjY"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 307,
        "2023": 407,
        "2024": 0,
        "id": "recEec6F8M13pCHWU",
        "row": 3871,
        "state": "COLORADO",
        "group": "5400018698",
        "group_num": [
            "recYqtcFRef5of0x7"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 611,
        "2024": 0,
        "id": "recMhYcZLAYeqjCVC",
        "row": 3874,
        "state": "COLORADO",
        "group": "5400030631",
        "group_num": [
            "recSOkFCTHWbaHqjY"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 0,
        "2024": 0,
        "id": "recnIHNAIUpQM9AsD",
        "row": 3875,
        "state": "COLORADO",
        "group": "A07121",
        "group_num": [
            "recTqFTwW3jD5wvtz"
        ],
        "procedure": "Detox",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 37,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receZBef36XvdlJJ9",
        "row": 3877,
        "state": "COLORADO",
        "group": "0816008",
        "group_num": [
            "recPpap5q5qmMIkr5"
        ],
        "procedure": "OP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 515,
        "2021": 1105,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTFJ2njkvdnOn9t",
        "row": 3876,
        "state": "COLORADO",
        "group": "0816008",
        "group_num": [
            "recPpap5q5qmMIkr5"
        ],
        "procedure": "IOP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 1800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receRyTwhTmmCP4kc",
        "row": 6013,
        "state": "COLORADO",
        "group": "287824",
        "group_num": [
            "reck4A4P1xMl4RTK4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recfQtRqOyrtCt4kI",
        "row": 6039,
        "state": "COLORADO",
        "group": "020431",
        "group_num": [
            "recgzXuxwSnOXsfHs"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 3700,
        "2021": 0,
        "2022": 1284,
        "2023": 0,
        "2024": 0,
        "id": "recgMRs52OXGD2Yxt",
        "row": 6046,
        "state": "COLORADO",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1030,
        "2024": 0,
        "id": "recgog64nvxIUCBMT",
        "row": 6047,
        "state": "COLORADO",
        "group": "888540010",
        "group_num": [
            "rec2YCgRnywk4GXUO"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 969,
        "2021": 1016,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechyEldFYsCP9f7N",
        "row": 6070,
        "state": "COLORADO",
        "group": "C23701M3A1",
        "group_num": [
            "rec0sKHq2gWlNkDLy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 265,
        "2024": 0,
        "id": "recjuYInh4SBs97ND",
        "row": 6023,
        "state": "COLORADO",
        "group": "L03495M002",
        "group_num": [
            "recLOvYbcr0OsHhWy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjLzPKZH6032alQ",
        "row": 6092,
        "state": "COLORADO",
        "group": "092682",
        "group_num": [
            "recTmUNi1VJUMEM3p"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1033,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmt2I9wkhpsrPxz",
        "row": 6021,
        "state": "COLORADO",
        "group": "174097M6AA",
        "group_num": [
            "reckZ4lhpT6639Tva"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 244,
        "2022": 257,
        "2023": 0,
        "2024": 0,
        "id": "recnaC8PIAZ3a7LH4",
        "row": 6052,
        "state": "COLORADO",
        "group": "L00003M101",
        "group_num": [
            "recMz42jkTYexuQnK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 913,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnnSGYgMRZuzU2v",
        "row": 6026,
        "state": "COLORADO",
        "group": "L01733M001",
        "group_num": [
            "recDs7PfLf2gBbLED"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOO8TGWSNy4WOvC",
        "row": 6094,
        "state": "COLORADO",
        "group": "092682",
        "group_num": [
            "recTmUNi1VJUMEM3p"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 34,
        "2022": 44,
        "2023": 0,
        "2024": 0,
        "id": "recPsRKs9tjXaUud2",
        "row": 6053,
        "state": "COLORADO",
        "group": "L00003M101",
        "group_num": [
            "recMz42jkTYexuQnK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPt8AW70VqyxhIm",
        "row": 6088,
        "state": "COLORADO",
        "group": "10195191",
        "group_num": [
            "recUYKLibvLjOJE2S"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recQNeFLtyAmyfpTC",
        "row": 6061,
        "state": "COLORADO",
        "group": "959647",
        "group_num": [
            "recgKzBbzGXkvZ543"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTwTOi36qyRwapH",
        "row": 6031,
        "state": "COLORADO",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 102,
        "2023": 0,
        "2024": 0,
        "id": "recVTA82BaWZVTS02",
        "row": 6033,
        "state": "COLORADO",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVKkSPAAYH3xEgv",
        "row": 6103,
        "state": "COLORADO",
        "group": "W0053534",
        "group_num": [
            "rec1ZJuslNMIW8Cnq"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWXHXf19vJRl7YM",
        "row": 6091,
        "state": "COLORADO",
        "group": "092682",
        "group_num": [
            "recTmUNi1VJUMEM3p"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1925,
        "2021": 1381,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWcSPSvuKi62xX5",
        "row": 6044,
        "state": "COLORADO",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWdALNYMEB7ZwFk",
        "row": 6045,
        "state": "COLORADO",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXWtP6io3eaeA4L",
        "row": 6050,
        "state": "COLORADO",
        "group": "L00003M001",
        "group_num": [
            "recXqehiUTw1TvU4N"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 325,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXijXvYZrukl7hE",
        "row": 6020,
        "state": "COLORADO",
        "group": "174097M6AA",
        "group_num": [
            "reckZ4lhpT6639Tva"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 181,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXkUpW9W8ANYdo0",
        "row": 6086,
        "state": "COLORADO",
        "group": "300325005M",
        "group_num": [
            "recAqDqNqHoe7Q78J"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 766,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYNS3zAjgAKRuVy",
        "row": 6041,
        "state": "COLORADO",
        "group": "10055500",
        "group_num": [
            "recXg0TZW8mp7s6MA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 194,
        "2021": 0,
        "2022": 0,
        "2023": 240,
        "2024": 0,
        "id": "rec0tbT3M60gl8mt7",
        "row": 6036,
        "state": "COLORADO",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1305,
        "2023": 0,
        "2024": 0,
        "id": "rec0uHyRyeEyvyrQG",
        "row": 6082,
        "state": "COLORADO",
        "group": "197248X081",
        "group_num": [
            "recXx5C0AeMXjnotb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1290,
        "2021": 1854,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Op618T4GJh7zz",
        "row": 6081,
        "state": "COLORADO",
        "group": "197248X041",
        "group_num": [
            "recV05jB6FPPZlcZb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 896,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0d9ccETFEwk8RD",
        "row": 6018,
        "state": "COLORADO",
        "group": "174097M5AA",
        "group_num": [
            "rec7PueQA4p8VNkM0"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 955,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1MZETvVzMmQbRO",
        "row": 6108,
        "state": "COLORADO",
        "group": "270167P1TA",
        "group_num": [
            "recHlv5zWb9JEwLnv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 260,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2fGKPfQiC5qEx4",
        "row": 6076,
        "state": "COLORADO",
        "group": "03325000AAAG019",
        "group_num": [
            "recJXhT1wgOcDi4aw"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec39hZvOaMfFqkwi",
        "row": 6068,
        "state": "COLORADO",
        "group": "7812030000",
        "group_num": [
            "reccRQFMiZ2iiKMeH"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "rec3dVRuCZ3gQZnnO",
        "row": 6062,
        "state": "COLORADO",
        "group": "959647",
        "group_num": [
            "recgKzBbzGXkvZ543"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec5JcAswMurBqDUf",
        "row": 6079,
        "state": "COLORADO",
        "group": "197248D084",
        "group_num": [
            "recyca8qu1fKBE0Jo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 67,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6vS4A3H99BUGl3",
        "row": 6083,
        "state": "COLORADO",
        "group": "10107218",
        "group_num": [
            "recjGRWf2O0wsuIoy"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 145,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7h0VjCY8Q5uWJU",
        "row": 6099,
        "state": "COLORADO",
        "group": "SB250H",
        "group_num": [
            "recTF0L1h498H2AaP"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8LGwVDJktTU6Y8",
        "row": 6087,
        "state": "COLORADO",
        "group": "10195191",
        "group_num": [
            "recUYKLibvLjOJE2S"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8qyhbFL2C7KJEj",
        "row": 6073,
        "state": "COLORADO",
        "group": "223963",
        "group_num": [
            "rec9GBs3gmduTJrCJ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 265,
        "2024": 0,
        "id": "rec9ER3jQ7qIQNXzO",
        "row": 6056,
        "state": "COLORADO",
        "group": "L00003MP03",
        "group_num": [
            "recCPwjvNu1rjbQRr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdTFEAHGjBRtyyM",
        "row": 6034,
        "state": "COLORADO",
        "group": "120369",
        "group_num": [
            "recHjSCDVXJKsNew8"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 997,
        "2023": 0,
        "2024": 0,
        "id": "recdj1uVV3vS7snr4",
        "row": 6055,
        "state": "COLORADO",
        "group": "L00003MM01",
        "group_num": [
            "rec77YXtvk8zHEUHX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoV3j90SoyhqXJQ",
        "row": 6107,
        "state": "COLORADO",
        "group": "270167P1TA",
        "group_num": [
            "recHlv5zWb9JEwLnv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "2023": 0,
        "2024": 0,
        "id": "recosHZliQjostZPI",
        "row": 6025,
        "state": "COLORADO",
        "group": "10484071",
        "group_num": [
            "recfUymmNTKWitXBv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "reco7cKZ8N6EngqwS",
        "row": 6009,
        "state": "COLORADO",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1410,
        "2023": 0,
        "2024": 0,
        "id": "reconFsTDi80zxhLF",
        "row": 6102,
        "state": "COLORADO",
        "group": "195500",
        "group_num": [
            "rec2LfvgFprxJtFbG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1464,
        "2023": 0,
        "2024": 0,
        "id": "recpCSUzdYEH8OLQ3",
        "row": 6058,
        "state": "COLORADO",
        "group": "959607",
        "group_num": [
            "recpWA2EyVt3dfsuw"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 761,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpKdXUnEiLrOXzj",
        "row": 6071,
        "state": "COLORADO",
        "group": "C23701M3A1",
        "group_num": [
            "rec0sKHq2gWlNkDLy"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpR5zIaubzK10iA",
        "row": 6011,
        "state": "COLORADO",
        "group": "005802609DC1000",
        "group_num": [
            "recjdLD1pbrs0tS6R"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqXK4rfx2A179rP",
        "row": 6080,
        "state": "COLORADO",
        "group": "197248D084",
        "group_num": [
            "recyca8qu1fKBE0Jo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1009,
        "2022": 950,
        "2023": 0,
        "2024": 0,
        "id": "recqI1WIuElv9wSig",
        "row": 6015,
        "state": "COLORADO",
        "group": "015331",
        "group_num": [
            "recXeM24ZGZjKAeNi"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 229,
        "2021": 200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrUGVkK21reyuCS",
        "row": 6098,
        "state": "COLORADO",
        "group": "SB250H",
        "group_num": [
            "recTF0L1h498H2AaP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 230,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrtDtZeQxFEOBX9",
        "row": 6014,
        "state": "COLORADO",
        "group": "287835",
        "group_num": [
            "rec5SeisofxdWL623"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 800,
        "2024": 0,
        "id": "recsvbuPGYn0gHh4u",
        "row": 6067,
        "state": "COLORADO",
        "group": "YN9744",
        "group_num": [
            "recnN4Ga1uktFIZdT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1733,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectl8jIF73av4ojZ",
        "row": 6065,
        "state": "COLORADO",
        "group": "444904",
        "group_num": [
            "recuZ4VDfGgn46mNm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuG8NE31vHHkTK6",
        "row": 6106,
        "state": "COLORADO",
        "group": "270167P1TA",
        "group_num": [
            "recHlv5zWb9JEwLnv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuGlrRM1dXa3MZY",
        "row": 6016,
        "state": "COLORADO",
        "group": "246682",
        "group_num": [
            "recKX2sTOtijrHexY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 898,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuJplr1hoWZ5DUx",
        "row": 6074,
        "state": "COLORADO",
        "group": "022258",
        "group_num": [
            "recP9k8SUgjdF8jrD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 93,
        "2022": 222,
        "2023": 0,
        "2024": 0,
        "id": "recu7GFPXhsJIVgcp",
        "row": 6037,
        "state": "COLORADO",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 840,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxymudkL4K0avu6",
        "row": 6030,
        "state": "COLORADO",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxec1x7dIJqXGUh",
        "row": 6043,
        "state": "COLORADO",
        "group": "10495561",
        "group_num": [
            "rec4N15Ao27SkwPKm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxiy2QLqGvPxrw0",
        "row": 6051,
        "state": "COLORADO",
        "group": "L00003M002",
        "group_num": [
            "recIfpJLex2tqxYR5"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1323,
        "2022": 1580,
        "2023": 320,
        "2024": 0,
        "id": "recyMJcZfWJcmFrqu",
        "row": 6012,
        "state": "COLORADO",
        "group": "271103",
        "group_num": [
            "recEAY3lAModCzPjf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 325,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczIktv1EyFUeITJ",
        "row": 6097,
        "state": "COLORADO",
        "group": "00041503",
        "group_num": [
            "recrhQ7fA6xwhPNsY"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczfXx7tBkxA3fjD",
        "row": 6075,
        "state": "COLORADO",
        "group": "03325000AAAG019",
        "group_num": [
            "recJXhT1wgOcDi4aw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1030,
        "2024": 0,
        "id": "recAmdGaYNU7gfdEb",
        "row": 6066,
        "state": "COLORADO",
        "group": "YN9744",
        "group_num": [
            "recnN4Ga1uktFIZdT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBSaMA5pqJCtLPB",
        "row": 6049,
        "state": "COLORADO",
        "group": "L00003M001",
        "group_num": [
            "recXqehiUTw1TvU4N"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "recDc3qkPRDdeEaqq",
        "row": 6089,
        "state": "COLORADO",
        "group": "002349525",
        "group_num": [
            "recxIoAarRtvGmtBU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDe9OCzVPoXKy9k",
        "row": 6063,
        "state": "COLORADO",
        "group": "959650",
        "group_num": [
            "recUaQzVHC65yBImT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recE9OwaCJd35qRRT",
        "row": 6017,
        "state": "COLORADO",
        "group": "246682",
        "group_num": [
            "recKX2sTOtijrHexY"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 468,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEao9NbokyW99K5",
        "row": 6096,
        "state": "COLORADO",
        "group": "00041503",
        "group_num": [
            "recrhQ7fA6xwhPNsY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recEpDr0Wc95CpOFS",
        "row": 6024,
        "state": "COLORADO",
        "group": "10484071",
        "group_num": [
            "recfUymmNTKWitXBv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEpjeByJkvTGGiu",
        "row": 6035,
        "state": "COLORADO",
        "group": "079485",
        "group_num": [
            "recdgWEWs36FLEW4U"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recF4dr3IuEMW6n1B",
        "row": 6090,
        "state": "COLORADO",
        "group": "092682",
        "group_num": [
            "recTmUNi1VJUMEM3p"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGy481QHrE6yDWy",
        "row": 6010,
        "state": "COLORADO",
        "group": "10452601",
        "group_num": [
            "recFoCYJs4qfchIfL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 185,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGbj4hrBKbaTvRA",
        "row": 6028,
        "state": "COLORADO",
        "group": "7370000",
        "group_num": [
            "recxauBy5Yp18VdsY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHB837GNg0hdejq",
        "row": 6048,
        "state": "COLORADO",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1313,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIulg0V5uDKJwoC",
        "row": 6059,
        "state": "COLORADO",
        "group": "959607",
        "group_num": [
            "recpWA2EyVt3dfsuw"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIMt1BG78MAjEZZ",
        "row": 6077,
        "state": "COLORADO",
        "group": "197248D033",
        "group_num": [
            "recBqee1EGiuo83uW"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recImrwpZQsnjUQX6",
        "row": 6104,
        "state": "COLORADO",
        "group": "W0053534",
        "group_num": [
            "rec1ZJuslNMIW8Cnq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recKxTOinq1VZ8kUz",
        "row": 6040,
        "state": "COLORADO",
        "group": "020431",
        "group_num": [
            "recgzXuxwSnOXsfHs"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 2939,
        "2023": 3000,
        "2024": 0,
        "id": "recK7ZGFtFqgpgFmC",
        "row": 6078,
        "state": "COLORADO",
        "group": "197248D084",
        "group_num": [
            "recyca8qu1fKBE0Jo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 400,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLTQzhPRrBWhwQI",
        "row": 6101,
        "state": "COLORADO",
        "group": "85000008",
        "group_num": [
            "recZnRXwWMfjIEh67"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLvmYDACZ5bAkco",
        "row": 6064,
        "state": "COLORADO",
        "group": "959650",
        "group_num": [
            "recUaQzVHC65yBImT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMMt38CrTLI48lz",
        "row": 6057,
        "state": "COLORADO",
        "group": "229321",
        "group_num": [
            "recCNgpgwZKKpd2VO"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 125,
        "2022": 125,
        "2023": 0,
        "2024": 0,
        "id": "recMpemFR0YosTmrr",
        "row": 6032,
        "state": "COLORADO",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 193,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMqUTbRrt5zMVno",
        "row": 6093,
        "state": "COLORADO",
        "group": "092682",
        "group_num": [
            "recTmUNi1VJUMEM3p"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 771,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recggZdxcKE4cpweF",
        "row": 6145,
        "state": "COLORADO",
        "group": "003330005NVAP00",
        "group_num": [
            "recWhUyoFh1VcjzOK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 187,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjDrXGE6htsdnuF",
        "row": 6150,
        "state": "COLORADO",
        "group": "37729E02",
        "group_num": [
            "reczdOaNqn5IsfMgu"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1183,
        "2023": 0,
        "2024": 0,
        "id": "recjEUTHxPqYosNP9",
        "row": 6113,
        "state": "COLORADO",
        "group": "212083MCCA",
        "group_num": [
            "recfRdmk3rDjwgvzm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckTKFv7qwsneTa1",
        "row": 6119,
        "state": "COLORADO",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recktsp6MZfuf4KsF",
        "row": 6139,
        "state": "COLORADO",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 261,
        "2022": 1238,
        "2023": 0,
        "2024": 0,
        "id": "reckMFUJd2riQT4ef",
        "row": 6122,
        "state": "COLORADO",
        "group": "76180",
        "group_num": [
            "recfjjkWNT8p5kfiK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2909,
        "2023": 0,
        "2024": 0,
        "id": "reck560hxEq6Zfe2W",
        "row": 6131,
        "state": "COLORADO",
        "group": "JNT015MB01",
        "group_num": [
            "reclqY3F8HLzwJHRF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 612,
        "2023": 0,
        "2024": 0,
        "id": "reclPhP4iuekgzaEd",
        "row": 6133,
        "state": "COLORADO",
        "group": "10106282",
        "group_num": [
            "rec2iUXFrcK9ggRV2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1099,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmHvH9j8QCa7gP3",
        "row": 6148,
        "state": "COLORADO",
        "group": "0023690045",
        "group_num": [
            "receoNuj9n1yhb9nl"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 178,
        "2021": 176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmik13F70JyVVyp",
        "row": 6136,
        "state": "COLORADO",
        "group": "169076",
        "group_num": [
            "recKieWZEyN0imcCk"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQZfK8Bjj7xNBqn",
        "row": 6141,
        "state": "COLORADO",
        "group": "270094M1WA",
        "group_num": [
            "recKBT8BQnZ17cVys"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQe82XFEmaLYAHS",
        "row": 6111,
        "state": "COLORADO",
        "group": "082084301M",
        "group_num": [
            "recXi9NL2D2wp3kUQ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1374,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRxn7Ue4LAXXrwM",
        "row": 6118,
        "state": "COLORADO",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 216,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT942XDtpnDt1Li",
        "row": 6137,
        "state": "COLORADO",
        "group": "169076",
        "group_num": [
            "recKieWZEyN0imcCk"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTphgE1wU7dM5yP",
        "row": 6134,
        "state": "COLORADO",
        "group": "10053168",
        "group_num": [
            "recLMB9L00rhSbRqs"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2397,
        "2023": 0,
        "2024": 0,
        "id": "recWr7gFzG6cbesaS",
        "row": 6114,
        "state": "COLORADO",
        "group": "212083MCCA",
        "group_num": [
            "recfRdmk3rDjwgvzm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 142,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3MrDyr2TsaRVyz",
        "row": 6151,
        "state": "COLORADO",
        "group": "37729E02",
        "group_num": [
            "reczdOaNqn5IsfMgu"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec37yToUJwGYgZOd",
        "row": 6158,
        "state": "COLORADO",
        "group": "40799634209",
        "group_num": [
            "recqirTIFLRTviFIu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 262,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9U9FVDaPiFnxHi",
        "row": 6109,
        "state": "COLORADO",
        "group": "00R189000110106",
        "group_num": [
            "recofy69fWPRkAO6O"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 935,
        "2023": 0,
        "2024": 0,
        "id": "rec9XOJ2B7VtZ9doC",
        "row": 6115,
        "state": "COLORADO",
        "group": "212083MCCA",
        "group_num": [
            "recfRdmk3rDjwgvzm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 479,
        "2022": 940,
        "2023": 0,
        "2024": 0,
        "id": "recbWQz0chvvimmsu",
        "row": 6156,
        "state": "COLORADO",
        "group": "W51001M001",
        "group_num": [
            "recSZewMnYeKOLLqq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "recc5oztLhrk7diFn",
        "row": 6127,
        "state": "COLORADO",
        "group": "716071908",
        "group_num": [
            "recptBOYAPnSQfWSd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 437,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdTz33CDtBQI4cD",
        "row": 6149,
        "state": "COLORADO",
        "group": "37729E02",
        "group_num": [
            "reczdOaNqn5IsfMgu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1734,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdUmG6VSW21XBBh",
        "row": 6153,
        "state": "COLORADO",
        "group": "W51001M001",
        "group_num": [
            "recSZewMnYeKOLLqq"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 155,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpFFHWADygnM2De",
        "row": 6140,
        "state": "COLORADO",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2405,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr8p9DvYCFRT5PM",
        "row": 6112,
        "state": "COLORADO",
        "group": "082084301M",
        "group_num": [
            "recXi9NL2D2wp3kUQ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrdR6ZqRyiUeRYf",
        "row": 6125,
        "state": "COLORADO",
        "group": "716071908",
        "group_num": [
            "recptBOYAPnSQfWSd"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recrfjD2fnbGH4Aku",
        "row": 6129,
        "state": "COLORADO",
        "group": "10350478",
        "group_num": [
            "recFZ2Yd87yDQ4cND"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "recvMTRpaUldMnfEn",
        "row": 6128,
        "state": "COLORADO",
        "group": "716071917",
        "group_num": [
            "recH1UrPzJbfsyD2i"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1635,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvOlXaxAEQzz3Cc",
        "row": 6142,
        "state": "COLORADO",
        "group": "270094M1WA",
        "group_num": [
            "recKBT8BQnZ17cVys"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwG8eWsIp9e019i",
        "row": 6154,
        "state": "COLORADO",
        "group": "W51001M001",
        "group_num": [
            "recSZewMnYeKOLLqq"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwrBH4Zf1HqoATT",
        "row": 6138,
        "state": "COLORADO",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 769,
        "2023": 0,
        "2024": 0,
        "id": "recy1gV1PrMlcNQyo",
        "row": 6132,
        "state": "COLORADO",
        "group": "JNT015MB01",
        "group_num": [
            "reclqY3F8HLzwJHRF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAoBawlCM1YIJfO",
        "row": 6147,
        "state": "COLORADO",
        "group": "270128M2AA",
        "group_num": [
            "recb96xXLb7yPw8VZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recB56IMfgCOqKFDB",
        "row": 6144,
        "state": "COLORADO",
        "group": "003330005NVAP00",
        "group_num": [
            "recWhUyoFh1VcjzOK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDmY1AfyWDzWlVE",
        "row": 6126,
        "state": "COLORADO",
        "group": "716071908",
        "group_num": [
            "recptBOYAPnSQfWSd"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 910,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHk5IqUxuQKVNco",
        "row": 6155,
        "state": "COLORADO",
        "group": "W51001M001",
        "group_num": [
            "recSZewMnYeKOLLqq"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 29,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJW6ihnl3XDsdGR",
        "row": 6135,
        "state": "COLORADO",
        "group": "10053168",
        "group_num": [
            "recLMB9L00rhSbRqs"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKYC8V1H3kthmsR",
        "row": 6123,
        "state": "COLORADO",
        "group": "169400",
        "group_num": [
            "rec8CzaZNjE1Ztpoj"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 769,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKE7dv83FiiNSZq",
        "row": 6130,
        "state": "COLORADO",
        "group": "000DUS83405GP00",
        "group_num": [
            "rec6AqvCYgn7HJFXS"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 761,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKfu1vG2K6joyyS",
        "row": 6116,
        "state": "COLORADO",
        "group": "003327000",
        "group_num": [
            "rectjjAeUJinXXNu8"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1030,
        "2024": 0,
        "id": "recf1W6aducv38YKU",
        "row": 6160,
        "state": "COLORADO",
        "group": "100724002",
        "group_num": [
            "recAti7LSYdo8tSBB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1083,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciq9xcoCcEWwBFn",
        "row": 6206,
        "state": "COLORADO",
        "group": "YNS023",
        "group_num": [
            "rec9Emaj9FvDXdQ9d"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "reckScr57huGjrvw7",
        "row": 6196,
        "state": "COLORADO",
        "group": "174549M211",
        "group_num": [
            "recIaU9MdfrATIP3T"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckUxNzzp92bmfOx",
        "row": 6185,
        "state": "COLORADO",
        "group": "OH2860M001",
        "group_num": [
            "recHTM4w4rga0OLKK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "reckyZpvb5Ai3AYv7",
        "row": 6165,
        "state": "COLORADO",
        "group": "022729",
        "group_num": [
            "recjgprP7dJuFUVmj"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmNxvbd7ZLbGpEh",
        "row": 6161,
        "state": "COLORADO",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 845,
        "2023": 0,
        "2024": 0,
        "id": "recn78Jfx69KP7wA5",
        "row": 6163,
        "state": "COLORADO",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOTbsVTkPv5w37U",
        "row": 6175,
        "state": "COLORADO",
        "group": "0009196400",
        "group_num": [
            "receg0w7J0v8qxvTX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPpcwOdiUeoKxGF",
        "row": 6184,
        "state": "COLORADO",
        "group": "001900602OA1001",
        "group_num": [
            "recFevlRlZF1QxCZi"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recTW1CYfr4SL5JDq",
        "row": 6197,
        "state": "COLORADO",
        "group": "174549M211",
        "group_num": [
            "recIaU9MdfrATIP3T"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 560,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT1aTrdTVYaECnp",
        "row": 6162,
        "state": "COLORADO",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recTzkZgr40tcDaGF",
        "row": 6191,
        "state": "COLORADO",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1033,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUNUpRjgfGqehZZ",
        "row": 6168,
        "state": "COLORADO",
        "group": "000EMB834AAAP00",
        "group_num": [
            "rec1G3KwS9d16R3ME"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1663,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUOY25OaZyG5a0D",
        "row": 6199,
        "state": "COLORADO",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUoLXY587RcqvFi",
        "row": 6174,
        "state": "COLORADO",
        "group": "0009196400",
        "group_num": [
            "receg0w7J0v8qxvTX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 116,
        "2021": 104,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWvqDYZl0dxR7IL",
        "row": 6208,
        "state": "COLORADO",
        "group": "10018638",
        "group_num": [
            "recVl0TUJSg6JPLfA"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1635,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYiFVQOUh6PlFZl",
        "row": 6183,
        "state": "COLORADO",
        "group": "003320403CCAG07",
        "group_num": [
            "recpHpu9cwXwNH8l0"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 748,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ4vP6lVcwga36D",
        "row": 6180,
        "state": "COLORADO",
        "group": "W0053801",
        "group_num": [
            "recZKjmZxBMNEKGmw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 117,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec14VeH9VlOXSUM3",
        "row": 6207,
        "state": "COLORADO",
        "group": "10018638",
        "group_num": [
            "recVl0TUJSg6JPLfA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1658,
        "2023": 0,
        "2024": 0,
        "id": "rec3W5t4FfWI2ILxV",
        "row": 6202,
        "state": "COLORADO",
        "group": "191276",
        "group_num": [
            "rec0nF8AIk6VJDrIx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4b5LThqiDsDak5",
        "row": 6186,
        "state": "COLORADO",
        "group": "OH2860M001",
        "group_num": [
            "recHTM4w4rga0OLKK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec4nqUFcxa4TnL7y",
        "row": 6192,
        "state": "COLORADO",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2100,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recacNTr2ZKtS7cEL",
        "row": 6198,
        "state": "COLORADO",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 196,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbOAG6K4OhXRurW",
        "row": 6188,
        "state": "COLORADO",
        "group": "716071106",
        "group_num": [
            "recr6JW0qg2QrbHd0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccTlcGpiGcsx3fQ",
        "row": 6173,
        "state": "COLORADO",
        "group": "003330225ECAP01",
        "group_num": [
            "recDIppnhSToM7ilX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 863,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recc11epe8ObV6NCO",
        "row": 6200,
        "state": "COLORADO",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1066,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recduAkfO1OxGl8D6",
        "row": 6205,
        "state": "COLORADO",
        "group": "716035408",
        "group_num": [
            "recKSUwTsFqUUlFfX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 89,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoSU6MR7YrImr7b",
        "row": 6189,
        "state": "COLORADO",
        "group": "716071106",
        "group_num": [
            "recr6JW0qg2QrbHd0"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5422,
        "2023": 0,
        "2024": 0,
        "id": "reco783pwzXpuq2pi",
        "row": 6178,
        "state": "COLORADO",
        "group": "L04372M001",
        "group_num": [
            "recyS0qU5SRckGReW"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recpWEYpDh4yiDkAx",
        "row": 6164,
        "state": "COLORADO",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 530,
        "2023": 0,
        "2024": 0,
        "id": "recpCuFAcnogZLF47",
        "row": 6176,
        "state": "COLORADO",
        "group": "0009196400",
        "group_num": [
            "receg0w7J0v8qxvTX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 437,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqBFh06a4IbdLlS",
        "row": 6159,
        "state": "COLORADO",
        "group": "202048",
        "group_num": [
            "rechDwYh6VTCKWr42"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "2023": 0,
        "2024": 0,
        "id": "recql6pV1MG5uWvb4",
        "row": 6166,
        "state": "COLORADO",
        "group": "022729",
        "group_num": [
            "recjgprP7dJuFUVmj"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recsS91j12CJ6cUi0",
        "row": 6179,
        "state": "COLORADO",
        "group": "L04372M001",
        "group_num": [
            "recyS0qU5SRckGReW"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuTOrrg8syK6tDg",
        "row": 6167,
        "state": "COLORADO",
        "group": "000EMB834AAAP00",
        "group_num": [
            "rec1G3KwS9d16R3ME"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 736,
        "2023": 0,
        "2024": 0,
        "id": "recvd87MC8ccq4C9A",
        "row": 6193,
        "state": "COLORADO",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 414,
        "2021": 359,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwRsrffggBrqZUQ",
        "row": 6181,
        "state": "COLORADO",
        "group": "W0053801",
        "group_num": [
            "recZKjmZxBMNEKGmw"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 180,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxE8hejKuWNlcCZ",
        "row": 6182,
        "state": "COLORADO",
        "group": "SN0784M001",
        "group_num": [
            "recASugJLhKPOgW1j"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 95,
        "2023": 732,
        "2024": 0,
        "id": "recySxeW0Hyqu5AaD",
        "row": 6194,
        "state": "COLORADO",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 653,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAVON1Le758xfFW",
        "row": 6204,
        "state": "COLORADO",
        "group": "191276",
        "group_num": [
            "rec0nF8AIk6VJDrIx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA762P1Z01DerjY",
        "row": 6187,
        "state": "COLORADO",
        "group": "716071106",
        "group_num": [
            "recr6JW0qg2QrbHd0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 805,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH4dXuLVoxXotNr",
        "row": 6170,
        "state": "COLORADO",
        "group": "01791903",
        "group_num": [
            "rec0bjPdjbuOjQ1a3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 994,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recItKdkVCBoneEZs",
        "row": 6203,
        "state": "COLORADO",
        "group": "191276",
        "group_num": [
            "rec0nF8AIk6VJDrIx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 61,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJegibyunRxXYRN",
        "row": 6171,
        "state": "COLORADO",
        "group": "01791903",
        "group_num": [
            "rec0bjPdjbuOjQ1a3"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 979,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfyD1wriNxIWS0z",
        "row": 6280,
        "state": "COLORADO",
        "group": "003320047BVBG00",
        "group_num": [
            "recGEawjbZ9k5dVXO"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 240,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recfEbSIM4bSQNgxZ",
        "row": 6302,
        "state": "COLORADO",
        "group": "000071549130011",
        "group_num": [
            "rec7wg9rczT73cmsa"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recg023vzEmfbwF1K",
        "row": 6218,
        "state": "COLORADO",
        "group": "196674M293",
        "group_num": [
            "recVXWXCrskZCKFkF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "rechPoCpj23bJZ8nJ",
        "row": 6250,
        "state": "COLORADO",
        "group": "00010040",
        "group_num": [
            "recHnM5iBsskgdv1i"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechlZe4okAHELjrr",
        "row": 6276,
        "state": "COLORADO",
        "group": "005802258DC1000",
        "group_num": [
            "recUCt8QSDY5w4JP6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 34,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciBPtSgJvhJrdAt",
        "row": 6305,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 536,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjo021UmO6RPfoV",
        "row": 6221,
        "state": "COLORADO",
        "group": "196674M481",
        "group_num": [
            "recdGGj1yU6lhW9E2"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 177,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclJrwUaZQUmNnIh",
        "row": 6225,
        "state": "COLORADO",
        "group": "036527",
        "group_num": [
            "rec8FYemavtcltdTE"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "2023": 0,
        "2024": 0,
        "id": "recmckPY4hZQposYi",
        "row": 6223,
        "state": "COLORADO",
        "group": "L04339M067",
        "group_num": [
            "recthAUrQlV8O99wc"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1740,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmhsPkYfw45JwRa",
        "row": 6270,
        "state": "COLORADO",
        "group": "W0069782",
        "group_num": [
            "recfhkrYxH7EbTPMU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 693,
        "2023": 0,
        "2024": 0,
        "id": "recmmQ19p1srOcDiY",
        "row": 6246,
        "state": "COLORADO",
        "group": "4006792",
        "group_num": [
            "reclX0qptmD1ny7xV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 773,
        "2023": 0,
        "2024": 0,
        "id": "recmpgabrFDLUzmR1",
        "row": 6269,
        "state": "COLORADO",
        "group": "300385002M",
        "group_num": [
            "recWOYMidXHMOJmyx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recOmuD0bliy85Yqz",
        "row": 6292,
        "state": "COLORADO",
        "group": "J34360",
        "group_num": [
            "reciy4T3RTV29CjNR"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQuKhayIQQAtKZw",
        "row": 6220,
        "state": "COLORADO",
        "group": "196674M481",
        "group_num": [
            "recdGGj1yU6lhW9E2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQv422fTe8p5im3",
        "row": 6298,
        "state": "COLORADO",
        "group": "37765003CM",
        "group_num": [
            "recBtB4exdsx2oLMZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recRV2Sb1qmTkAKWR",
        "row": 6293,
        "state": "COLORADO",
        "group": "E015580005",
        "group_num": [
            "recIxvGvIEq7ZRAVf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRxl27asJoq7sC7",
        "row": 6248,
        "state": "COLORADO",
        "group": "715394847",
        "group_num": [
            "rec7Sh423WGajFQLp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRpQ2DiESZ0AsPm",
        "row": 6279,
        "state": "COLORADO",
        "group": "25W9",
        "group_num": [
            "rec1LpGV132Dn3Nmt"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 187,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVUtrlfsQTwTdHn",
        "row": 6286,
        "state": "COLORADO",
        "group": "358689",
        "group_num": [
            "recnKoAhx5cM4TVG6"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2031,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWBAdqthuP5kLdr",
        "row": 6256,
        "state": "COLORADO",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXVtlKbfULAdiiX",
        "row": 6290,
        "state": "COLORADO",
        "group": "J29770",
        "group_num": [
            "rec0M9avQ1CNKHsmB"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 889,
        "2022": 927,
        "2023": 0,
        "2024": 0,
        "id": "recXn9zj7eoTskMLM",
        "row": 6242,
        "state": "COLORADO",
        "group": "P41122",
        "group_num": [
            "recFF06q6UFVFQxut"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1856,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0TSn1qF2SO0LW2",
        "row": 6213,
        "state": "COLORADO",
        "group": "174614G1GN",
        "group_num": [
            "recJYKis58AvU39iT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3279,
        "2023": 0,
        "2024": 0,
        "id": "rec0KczY4BXl0qyXO",
        "row": 6264,
        "state": "COLORADO",
        "group": "201064CP4L",
        "group_num": [
            "recig7Oym8VcJyxlC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec14JsPbyH0ExSlv",
        "row": 6263,
        "state": "COLORADO",
        "group": "201064CP4L",
        "group_num": [
            "recig7Oym8VcJyxlC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 400,
        "2021": 400,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec17CJW7qHrgAL1s",
        "row": 6235,
        "state": "COLORADO",
        "group": "002313719",
        "group_num": [
            "reckfLWNct8iqStcs"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec2TjuQkGKd9EmjM",
        "row": 6281,
        "state": "COLORADO",
        "group": "W41499M001",
        "group_num": [
            "recnE4s1K0zkPaTmQ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2aMb9vDCBpt7YH",
        "row": 6282,
        "state": "COLORADO",
        "group": "W50101M001",
        "group_num": [
            "recXwy73DH7SEJMX7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1652,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2dS0coODhA6tj6",
        "row": 6257,
        "state": "COLORADO",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2nN5tRzZ4ShSFD",
        "row": 6308,
        "state": "COLORADO",
        "group": "212107M1A1",
        "group_num": [
            "rec9BCRsyOHylu9e0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1602,
        "2021": 2066,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2qD5U6oo42i90u",
        "row": 6229,
        "state": "COLORADO",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3LhB1pfiOcAlNd",
        "row": 6296,
        "state": "COLORADO",
        "group": "042159",
        "group_num": [
            "rec79VMxdd27KNAnY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5Hw8GEYuK7NBE2",
        "row": 6240,
        "state": "COLORADO",
        "group": "00759650015",
        "group_num": [
            "recAEcR5PM6XiE7YN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 2175,
        "2024": 0,
        "id": "rec5JGHrdglu0uxiV",
        "row": 6212,
        "state": "COLORADO",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 172,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec57EGWHBdbGonOi",
        "row": 6252,
        "state": "COLORADO",
        "group": "W0067701",
        "group_num": [
            "recXHt0NFD6lTWBOm"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec60tJEHTCLSMMYb",
        "row": 6297,
        "state": "COLORADO",
        "group": "37765003CM",
        "group_num": [
            "recBtB4exdsx2oLMZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "rec6bx3z0aS0gAHQ3",
        "row": 6291,
        "state": "COLORADO",
        "group": "J34360",
        "group_num": [
            "reciy4T3RTV29CjNR"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6nK6wMz4nOokXH",
        "row": 6219,
        "state": "COLORADO",
        "group": "196674M481",
        "group_num": [
            "recdGGj1yU6lhW9E2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 88,
        "2023": 0,
        "2024": 0,
        "id": "rec7iRHPzUQ47pMoa",
        "row": 6245,
        "state": "COLORADO",
        "group": "4006792",
        "group_num": [
            "reclX0qptmD1ny7xV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8Vl6udSKYxQ3MI",
        "row": 6268,
        "state": "COLORADO",
        "group": "003330094",
        "group_num": [
            "rec7NzEB8aetv0fU0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "2023": 0,
        "2024": 0,
        "id": "rec8uN17tqpJooGck",
        "row": 6241,
        "state": "COLORADO",
        "group": "10109083",
        "group_num": [
            "recrDEWO258h67sKL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1214,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8fUdGK7qUDsCdh",
        "row": 6273,
        "state": "COLORADO",
        "group": "002374257",
        "group_num": [
            "recpqxJmBTpF9xr1O"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8p2MRkIRkQjeGS",
        "row": 6226,
        "state": "COLORADO",
        "group": "036527",
        "group_num": [
            "rec8FYemavtcltdTE"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 240,
        "2024": 0,
        "id": "rec9FkfMqXhBViCTe",
        "row": 6300,
        "state": "COLORADO",
        "group": "1038793",
        "group_num": [
            "recbBnBM2kglNdjWc"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9qp2CH1Oxo3jRz",
        "row": 6294,
        "state": "COLORADO",
        "group": "10271094",
        "group_num": [
            "recPgv5M75PXd1nkQ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recauCFsvEf6bkiDp",
        "row": 6278,
        "state": "COLORADO",
        "group": "6C32",
        "group_num": [
            "recCebSkAdeq0kO80"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 230,
        "2021": 212,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaM8gE3ivuOS5f2",
        "row": 6233,
        "state": "COLORADO",
        "group": "08407400",
        "group_num": [
            "recZivvOED5swyqBf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaMoQIQfCkb8APV",
        "row": 6266,
        "state": "COLORADO",
        "group": "C22681M1A2",
        "group_num": [
            "recgNs0XUw0WhAYVN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 316,
        "2023": 0,
        "2024": 0,
        "id": "recab92scejYUYxD4",
        "row": 6275,
        "state": "COLORADO",
        "group": "002374257",
        "group_num": [
            "recpqxJmBTpF9xr1O"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recbM1HoWH2ei6AUN",
        "row": 6304,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 585,
        "2023": 0,
        "2024": 0,
        "id": "reccNVChOhdIhpKKF",
        "row": 6274,
        "state": "COLORADO",
        "group": "002374257",
        "group_num": [
            "recpqxJmBTpF9xr1O"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 633,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccfrxov33V69DNp",
        "row": 6255,
        "state": "COLORADO",
        "group": "196586MAH5",
        "group_num": [
            "rec6KPrE5M1jSEyux"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1042,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd4bzfPznP2ip2n",
        "row": 6231,
        "state": "COLORADO",
        "group": "10333143",
        "group_num": [
            "rec700z5vtrZ4GMPX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1196,
        "2023": 0,
        "2024": 0,
        "id": "recoRp59nYw3mNozF",
        "row": 6301,
        "state": "COLORADO",
        "group": "0000715491310",
        "group_num": [
            "recAYb32ogZHu8wf8"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "rect0yWtpVqM2PmFH",
        "row": 6214,
        "state": "COLORADO",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1081,
        "2023": 0,
        "2024": 0,
        "id": "rectFOU49lMdWCVFT",
        "row": 6211,
        "state": "COLORADO",
        "group": "117630",
        "group_num": [
            "rec6YTatyW1leg8RS"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1663,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recwRHxzfOLvydTKk",
        "row": 6267,
        "state": "COLORADO",
        "group": "C22681M1A2",
        "group_num": [
            "recgNs0XUw0WhAYVN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1396,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recw5eynDfLtpwF5L",
        "row": 6234,
        "state": "COLORADO",
        "group": "002313719",
        "group_num": [
            "reckfLWNct8iqStcs"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 865,
        "2023": 0,
        "2024": 0,
        "id": "recxSSRrDMqsgOqiL",
        "row": 6222,
        "state": "COLORADO",
        "group": "L04339M067",
        "group_num": [
            "recthAUrQlV8O99wc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxCz0wzPg9nE1vX",
        "row": 6210,
        "state": "COLORADO",
        "group": "007047583000700",
        "group_num": [
            "recfXCGcug6DcciGQ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyiWXiOfAHzvV4L",
        "row": 6295,
        "state": "COLORADO",
        "group": "10288412",
        "group_num": [
            "rec9HDs27Rw0WYs3T"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 878,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczuRbrZ8it3d1KL",
        "row": 6271,
        "state": "COLORADO",
        "group": "W0069782",
        "group_num": [
            "recfhkrYxH7EbTPMU"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczR4H9d1B2EH2VJ",
        "row": 6299,
        "state": "COLORADO",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczopQSK1nNIqBRS",
        "row": 6239,
        "state": "COLORADO",
        "group": "00759650015",
        "group_num": [
            "recAEcR5PM6XiE7YN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 994,
        "2023": 0,
        "2024": 0,
        "id": "recAubTrWaOFewXKB",
        "row": 6217,
        "state": "COLORADO",
        "group": "376011",
        "group_num": [
            "recLqMgSgSZqCFt4b"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recAC2zMLo3njM7wM",
        "row": 6228,
        "state": "COLORADO",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1634,
        "2022": 2730,
        "2023": 0,
        "2024": 0,
        "id": "recBU0xvgvxUIbDDZ",
        "row": 6215,
        "state": "COLORADO",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 207,
        "2021": 175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBwrauaDvEaCXg1",
        "row": 6251,
        "state": "COLORADO",
        "group": "W0067701",
        "group_num": [
            "recXHt0NFD6lTWBOm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2003,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBzU2kMu3XCL1ig",
        "row": 6260,
        "state": "COLORADO",
        "group": "196586MASS",
        "group_num": [
            "rec1hvFQmoUHhZ0Js"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBLaVYVR6GffE6L",
        "row": 6288,
        "state": "COLORADO",
        "group": "J05070",
        "group_num": [
            "recWfFqSiKSGfGzMP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 945,
        "2022": 316,
        "2023": 0,
        "2024": 0,
        "id": "recCiU79UwJOvLDij",
        "row": 6216,
        "state": "COLORADO",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 126,
        "2023": 0,
        "2024": 0,
        "id": "recCmv02l3bfd6OAZ",
        "row": 6243,
        "state": "COLORADO",
        "group": "P41122",
        "group_num": [
            "recFF06q6UFVFQxut"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDV5g3qtNM6AYyc",
        "row": 6285,
        "state": "COLORADO",
        "group": "476497",
        "group_num": [
            "recRBVyLhhR2jsoqD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDvimGa6UodTFhN",
        "row": 6237,
        "state": "COLORADO",
        "group": "10479710",
        "group_num": [
            "recFQeO4g6NUXgp5D"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1964,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDv4Dj6I8dDiOMi",
        "row": 6261,
        "state": "COLORADO",
        "group": "196586MASS",
        "group_num": [
            "rec1hvFQmoUHhZ0Js"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 807,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDeF9WtIldo3rIM",
        "row": 6238,
        "state": "COLORADO",
        "group": "10479720",
        "group_num": [
            "reccD9WVKd4nM2OKw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recFc0YHbC9E1c3FD",
        "row": 6232,
        "state": "COLORADO",
        "group": "B0000003",
        "group_num": [
            "receTlBNQch9hE8gN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGGd59KZCwHHV11",
        "row": 6283,
        "state": "COLORADO",
        "group": "W50101M001",
        "group_num": [
            "recXwy73DH7SEJMX7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 675,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHyDXvDsobJoUki",
        "row": 6277,
        "state": "COLORADO",
        "group": "005802258DC1000",
        "group_num": [
            "recUCt8QSDY5w4JP6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 708,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recISfsBQoKFwmZJa",
        "row": 6262,
        "state": "COLORADO",
        "group": "196586MASS",
        "group_num": [
            "rec1hvFQmoUHhZ0Js"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1050,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIS0m8v3xCTvxrV",
        "row": 6230,
        "state": "COLORADO",
        "group": "10333143",
        "group_num": [
            "rec700z5vtrZ4GMPX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 187,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIkXj3QN8NZRKl6",
        "row": 6289,
        "state": "COLORADO",
        "group": "J05070",
        "group_num": [
            "recWfFqSiKSGfGzMP"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 791,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJNXCCXrelxJPmS",
        "row": 6258,
        "state": "COLORADO",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 281,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK6bUDzobP0Y1fX",
        "row": 6224,
        "state": "COLORADO",
        "group": "036527",
        "group_num": [
            "rec8FYemavtcltdTE"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recNXDmfT9OY0ZiAC",
        "row": 6249,
        "state": "COLORADO",
        "group": "121982",
        "group_num": [
            "recwd1nRTKgPaDWNr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recNuBcEAqgdxx8Te",
        "row": 6307,
        "state": "COLORADO",
        "group": "W29649M001",
        "group_num": [
            "recijfBWKuNlVmGfq"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1582,
        "2021": 2066,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNJqj6riuGMMAck",
        "row": 6254,
        "state": "COLORADO",
        "group": "196586MAH5",
        "group_num": [
            "rec6KPrE5M1jSEyux"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 65,
        "2021": 2440,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfRaKEr93cXoZD5",
        "row": 6312,
        "state": "COLORADO",
        "group": "00332410705PA13",
        "group_num": [
            "recL0ri4GDYmkuz10"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 795,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgSSy1V9xWFFfRI",
        "row": 6316,
        "state": "COLORADO",
        "group": "101832001",
        "group_num": [
            "rectnIxWkgAB9v7po"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 554,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechkeBnzkiutA6DO",
        "row": 6351,
        "state": "COLORADO",
        "group": "737248",
        "group_num": [
            "recHGo6qFOXgZXzSg"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "reciNbMznc6yc2v41",
        "row": 6336,
        "state": "COLORADO",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 828,
        "2021": 818,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckQy8bDD4GBwbsW",
        "row": 6355,
        "state": "COLORADO",
        "group": "10416509",
        "group_num": [
            "recLstMEI8tBTda5y"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1020,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmsC7hWcbzSuKA7",
        "row": 6310,
        "state": "COLORADO",
        "group": "00332410701PA15",
        "group_num": [
            "rec97WnqFTt7ac7pg"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 472,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOeIBYg0cFWpZsp",
        "row": 6332,
        "state": "COLORADO",
        "group": "715990262",
        "group_num": [
            "recTXY5Kyy7bTdKjK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 203,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRCErOZSr2tZe9x",
        "row": 6319,
        "state": "COLORADO",
        "group": "GA9877M001",
        "group_num": [
            "recoehDhGdB3YZGOQ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWL6ilEmNUlvclb",
        "row": 6349,
        "state": "COLORADO",
        "group": "196140M012",
        "group_num": [
            "recF9CljyzwXWSgkw"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recWpfI2dTf0N9wyl",
        "row": 6338,
        "state": "COLORADO",
        "group": "10007186",
        "group_num": [
            "rec8q2fNL1uDEClcG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ98zz9pcmKMp6Y",
        "row": 6317,
        "state": "COLORADO",
        "group": "101832001",
        "group_num": [
            "rectnIxWkgAB9v7po"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec00vBeleDJobcNE",
        "row": 6330,
        "state": "COLORADO",
        "group": "715990262",
        "group_num": [
            "recTXY5Kyy7bTdKjK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 821,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0GdgD1JGMLxExM",
        "row": 6321,
        "state": "COLORADO",
        "group": "287833",
        "group_num": [
            "recRNT5HOvRaAkXQp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2009,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1S3azpxT5Rllss",
        "row": 6325,
        "state": "COLORADO",
        "group": "10261655",
        "group_num": [
            "recyrHx5NZvhFMPc6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec13zaecIeFtqxAF",
        "row": 6334,
        "state": "COLORADO",
        "group": "N13794",
        "group_num": [
            "rec0Cfm3buVdFQ19Q"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "rec16BqzvgmTlOfve",
        "row": 6342,
        "state": "COLORADO",
        "group": "01484200",
        "group_num": [
            "recYQ4zfcEDv9hlWL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1364,
        "2023": 1500,
        "2024": 0,
        "id": "rec2xoskYK5VZkWnp",
        "row": 6347,
        "state": "COLORADO",
        "group": "L01072M026",
        "group_num": [
            "rec3hkf2mgtmyB7Ks"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4fSm0gKzlzjS0H",
        "row": 6326,
        "state": "COLORADO",
        "group": "07453",
        "group_num": [
            "recAC3JA6rPX3jE1s"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5NBkXMuy0MEnHg",
        "row": 6340,
        "state": "COLORADO",
        "group": "174773",
        "group_num": [
            "recfbMKTKjiGbuR4p"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca8KcopNLE5e6RY",
        "row": 6329,
        "state": "COLORADO",
        "group": "0007500001",
        "group_num": [
            "recSlh8XWwSTzsroP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbtvvg4Q2Q0g2lM",
        "row": 6337,
        "state": "COLORADO",
        "group": "362240004",
        "group_num": [
            "reczt2zjJUlXlTvNv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccXriCyvoLCH4eo",
        "row": 6315,
        "state": "COLORADO",
        "group": "101832001",
        "group_num": [
            "rectnIxWkgAB9v7po"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccz83YENuGcxEPe",
        "row": 6352,
        "state": "COLORADO",
        "group": "737248",
        "group_num": [
            "recHGo6qFOXgZXzSg"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccDYrLSYYIPDEUw",
        "row": 6328,
        "state": "COLORADO",
        "group": "0007500001",
        "group_num": [
            "recSlh8XWwSTzsroP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrOKkApOay5aKhN",
        "row": 6354,
        "state": "COLORADO",
        "group": "10014978",
        "group_num": [
            "recOxBaIL5GJF633S"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsKN01q7LqJ2RWf",
        "row": 6331,
        "state": "COLORADO",
        "group": "715990262",
        "group_num": [
            "recTXY5Kyy7bTdKjK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv6E75WQOt27kKE",
        "row": 6309,
        "state": "COLORADO",
        "group": "212107M1A1",
        "group_num": [
            "rec9BCRsyOHylu9e0"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "recv8UhqKKl6tixHN",
        "row": 6358,
        "state": "COLORADO",
        "group": "10567864",
        "group_num": [
            "rec3cIvXnvY0BICfk"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwQSdFQ7F4GI0Dz",
        "row": 6320,
        "state": "COLORADO",
        "group": "GA9877M001",
        "group_num": [
            "recoehDhGdB3YZGOQ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyDERvevrMMPTxQ",
        "row": 6350,
        "state": "COLORADO",
        "group": "196140M012",
        "group_num": [
            "recF9CljyzwXWSgkw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1447,
        "2021": 1504,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczSfHTZqymUTGCU",
        "row": 6313,
        "state": "COLORADO",
        "group": "00332410705PA13",
        "group_num": [
            "recL0ri4GDYmkuz10"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1159,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz77C2JDl4G8Dmq",
        "row": 6356,
        "state": "COLORADO",
        "group": "004007910OOAP00",
        "group_num": [
            "recANV423OGYVZ6nV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "reczfpuIpYkvi9URn",
        "row": 6335,
        "state": "COLORADO",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 223,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAAmoppp7JkUDPW",
        "row": 6322,
        "state": "COLORADO",
        "group": "287833",
        "group_num": [
            "recRNT5HOvRaAkXQp"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recBLvAQ7dnFt60ef",
        "row": 6345,
        "state": "COLORADO",
        "group": "L01072M026",
        "group_num": [
            "rec3hkf2mgtmyB7Ks"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recE7KRwgvjty78Vb",
        "row": 6339,
        "state": "COLORADO",
        "group": "10007186",
        "group_num": [
            "rec8q2fNL1uDEClcG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEbYZ2JgWLXCYUw",
        "row": 6324,
        "state": "COLORADO",
        "group": "10261655",
        "group_num": [
            "recyrHx5NZvhFMPc6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recIKqro3U4ke1qRf",
        "row": 6346,
        "state": "COLORADO",
        "group": "L01072M026",
        "group_num": [
            "rec3hkf2mgtmyB7Ks"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 262,
        "2023": 0,
        "2024": 0,
        "id": "recN2r8r8RBospap9",
        "row": 6343,
        "state": "COLORADO",
        "group": "01484200",
        "group_num": [
            "recYQ4zfcEDv9hlWL"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receJsp1n171VH0Wo",
        "row": 6360,
        "state": "COLORADO",
        "group": "197237M002",
        "group_num": [
            "rec0nZGaPhPItKHvW"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 201,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfQCzcJbi3hqHXC",
        "row": 6449,
        "state": "COLORADO",
        "group": "1408800S",
        "group_num": [
            "recCNJBreDEOv2UTV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 437,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgMOrReKsBV90Wy",
        "row": 6427,
        "state": "COLORADO",
        "group": "C10223M002",
        "group_num": [
            "recvhj9zp832xLGe1"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg5pCNlkZmhXWy9",
        "row": 6372,
        "state": "COLORADO",
        "group": "005801171DC1000",
        "group_num": [
            "recGS7ZkAXKfqagdI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgdcgrrjZsY2jkM",
        "row": 6422,
        "state": "COLORADO",
        "group": "10349702",
        "group_num": [
            "recT02jMY8eedcCzv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 207,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgmIeEGegjMet1Z",
        "row": 6428,
        "state": "COLORADO",
        "group": "C10223M002",
        "group_num": [
            "recvhj9zp832xLGe1"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recizzTbHLun4wDIg",
        "row": 6371,
        "state": "COLORADO",
        "group": "005801171DC1000",
        "group_num": [
            "recGS7ZkAXKfqagdI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1439,
        "2023": 0,
        "2024": 0,
        "id": "recjJa6hIMeV1nf9i",
        "row": 6388,
        "state": "COLORADO",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 265,
        "2024": 0,
        "id": "reclFqUxw4nr2rFDH",
        "row": 6425,
        "state": "COLORADO",
        "group": "10036302",
        "group_num": [
            "recLvfoqM0jUQa7bE"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1645,
        "2023": 0,
        "2024": 0,
        "id": "reclq3hyHGHg4apFY",
        "row": 6406,
        "state": "COLORADO",
        "group": "380011",
        "group_num": [
            "recNEimVvZe3Th9Dz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmpjJfBQ1h2dF8p",
        "row": 6359,
        "state": "COLORADO",
        "group": "197237M002",
        "group_num": [
            "rec0nZGaPhPItKHvW"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 466,
        "2022": 466,
        "2023": 0,
        "2024": 0,
        "id": "recn2WoG6UXK8WNfr",
        "row": 6423,
        "state": "COLORADO",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1989,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recniv9u2AJbSXYzN",
        "row": 6379,
        "state": "COLORADO",
        "group": "213001NE1Y",
        "group_num": [
            "recLk6YeyZEXjyuCl"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2084,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOZ2HRTMLuNd3et",
        "row": 6387,
        "state": "COLORADO",
        "group": "002347084",
        "group_num": [
            "recHZF7SMBXe6Lh3p"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPVA2HgYy4dPM9W",
        "row": 6410,
        "state": "COLORADO",
        "group": "174210M1A2",
        "group_num": [
            "recOcecLSbuRaffJR"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 97,
        "2024": 0,
        "id": "recPJqcI1p4B6Njvh",
        "row": 6375,
        "state": "COLORADO",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP6MfkhxNWxPwlu",
        "row": 6411,
        "state": "COLORADO",
        "group": "174210M1A2",
        "group_num": [
            "recOcecLSbuRaffJR"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 858,
        "2021": 484,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSxZlv29e70VAsO",
        "row": 6431,
        "state": "COLORADO",
        "group": "W0051210",
        "group_num": [
            "recem5rsz7keGhTtT"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2389,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTaCIK6ZbWGmyWP",
        "row": 6418,
        "state": "COLORADO",
        "group": "082084401M",
        "group_num": [
            "recO5b7gJummJ6csU"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUDAHWrUXNjOSIT",
        "row": 6366,
        "state": "COLORADO",
        "group": "10020338",
        "group_num": [
            "recFVVyWy3cHXoc0V"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUbIRuzADgaFBnY",
        "row": 6442,
        "state": "COLORADO",
        "group": "195096MP15",
        "group_num": [
            "recDWSPUPi5nhHPAz"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2548,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVB0EzNbT0HQ09p",
        "row": 6386,
        "state": "COLORADO",
        "group": "002347084",
        "group_num": [
            "recHZF7SMBXe6Lh3p"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2400,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVMHDsXnQAlVaXl",
        "row": 6397,
        "state": "COLORADO",
        "group": "00905000000",
        "group_num": [
            "reckkQPxPAkN6aOvo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recWQt5G6dxmbtQO8",
        "row": 6369,
        "state": "COLORADO",
        "group": "10020590",
        "group_num": [
            "recoLfG97EOikfB7A"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1305,
        "2021": 1297,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWmoXn3F657Rb9k",
        "row": 6384,
        "state": "COLORADO",
        "group": "174592M3NA",
        "group_num": [
            "recEmBjyt0S65Kyqg"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recYAr1jOWeQ3pBBX",
        "row": 6448,
        "state": "COLORADO",
        "group": "212012P3AP",
        "group_num": [
            "rec0uU78I8fHW4ZiC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 231,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYjOS2jfqyRtxWV",
        "row": 6450,
        "state": "COLORADO",
        "group": "1408800S",
        "group_num": [
            "recCNJBreDEOv2UTV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 829,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0LgjgHppkDQEsN",
        "row": 6381,
        "state": "COLORADO",
        "group": "009727",
        "group_num": [
            "recWCRQVFaS91oa5R"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 995,
        "2021": 1153,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec08J8y6ywfTFZ22",
        "row": 6415,
        "state": "COLORADO",
        "group": "174102MI1D",
        "group_num": [
            "recOR2BCTWbzBTh88"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1592,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1XrPVErAJC9WV6",
        "row": 6404,
        "state": "COLORADO",
        "group": "00761180001",
        "group_num": [
            "rec8Jn6AySCUaqrTf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "rec2CSL2cCjIIPa1S",
        "row": 6370,
        "state": "COLORADO",
        "group": "10020590",
        "group_num": [
            "recoLfG97EOikfB7A"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2363,
        "2021": 2363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4wGH99CFP4KneD",
        "row": 6444,
        "state": "COLORADO",
        "group": "000PED83401AP00",
        "group_num": [
            "recGBzIXXgpLjQDHl"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 829,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec4DzjFQfv194W2b",
        "row": 6382,
        "state": "COLORADO",
        "group": "14162996",
        "group_num": [
            "recsxq2chDbTHu1bh"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 761,
        "2021": 762,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6bmjZuZb1sk562",
        "row": 6416,
        "state": "COLORADO",
        "group": "174102MI1D",
        "group_num": [
            "recOR2BCTWbzBTh88"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6d8mVeWKEdiBvG",
        "row": 6421,
        "state": "COLORADO",
        "group": "10349698",
        "group_num": [
            "recjUAuztnqCPte8C"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 3972,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec71a1fNmHSaxrAu",
        "row": 6446,
        "state": "COLORADO",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "2023": 1125,
        "2024": 0,
        "id": "rec7umiXqQ1U6Ggim",
        "row": 6364,
        "state": "COLORADO",
        "group": "10289562",
        "group_num": [
            "recUvev7xOT3sfsug"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec7JmQJjT7D2f4kX",
        "row": 6441,
        "state": "COLORADO",
        "group": "C22715A1A3",
        "group_num": [
            "recruehVned6iIPXi"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 333,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec7KM5WFqRFu8zp0",
        "row": 6434,
        "state": "COLORADO",
        "group": "5L9W00",
        "group_num": [
            "rec3HH1D11qcQ505z"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1992,
        "2021": 1648,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7hOxsf3u1GrHhn",
        "row": 6361,
        "state": "COLORADO",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec7k3bNX9ZkjAoz3",
        "row": 6437,
        "state": "COLORADO",
        "group": "C22715A2A4",
        "group_num": [
            "recAf4VKeSFCxRDQf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1776,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8Qea9I7hBpMpB4",
        "row": 6380,
        "state": "COLORADO",
        "group": "213001NE1Y",
        "group_num": [
            "recLk6YeyZEXjyuCl"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 99,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca1x06V4J2fFm5D",
        "row": 6439,
        "state": "COLORADO",
        "group": "205534",
        "group_num": [
            "recO8FF5eMj32MfU1"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdVNbPc7a2YnAh1",
        "row": 6389,
        "state": "COLORADO",
        "group": "348632000",
        "group_num": [
            "recq8KqHSLGSkzpa9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "recoyk92Qt8tpWkJ1",
        "row": 6414,
        "state": "COLORADO",
        "group": "814130000",
        "group_num": [
            "recYDUVtQNZ7lciK9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 932,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp20yWDOl0j99D6",
        "row": 6402,
        "state": "COLORADO",
        "group": "195295M001",
        "group_num": [
            "recc7dEcRsDQZlq5A"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1510,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpoQLnqaCCCBAYb",
        "row": 6445,
        "state": "COLORADO",
        "group": "000PED83401AP00",
        "group_num": [
            "recGBzIXXgpLjQDHl"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 628,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq3GwO7aSOp8hxE",
        "row": 6394,
        "state": "COLORADO",
        "group": "300325001M",
        "group_num": [
            "reczXfWy6u1obik5k"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 754,
        "2021": 718,
        "2022": 948,
        "2023": 0,
        "2024": 0,
        "id": "recq7EctxTyfN0btE",
        "row": 6407,
        "state": "COLORADO",
        "group": "380011",
        "group_num": [
            "recNEimVvZe3Th9Dz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 968,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqn5vbrfjR5bIV0",
        "row": 6378,
        "state": "COLORADO",
        "group": "201052M4A1",
        "group_num": [
            "recqjfn6XtMNKbLs2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 759,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrZuYmU76Py1LSF",
        "row": 6401,
        "state": "COLORADO",
        "group": "190423",
        "group_num": [
            "recJjv8LJ1mUbBUmK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr3mOMF2ebHolzr",
        "row": 6390,
        "state": "COLORADO",
        "group": "348632000",
        "group_num": [
            "recq8KqHSLGSkzpa9"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 887,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsrcJRzarfZGudt",
        "row": 6457,
        "state": "COLORADO",
        "group": "214023M5UA",
        "group_num": [
            "recPhpudmolUUXoXH"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 355,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectckncKUyulLZWd",
        "row": 6455,
        "state": "COLORADO",
        "group": "8857",
        "group_num": [
            "recAYNdZVI3qBMqcS"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1863,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectfJR8iBjaZfCxW",
        "row": 6413,
        "state": "COLORADO",
        "group": "174210M1C2",
        "group_num": [
            "reccdRBLIdmrx18YN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 448,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvyJv3Q0pnHNWzS",
        "row": 6367,
        "state": "COLORADO",
        "group": "716037213",
        "group_num": [
            "rec01LlWi9yRS9cFW"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 67,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv3VBgCYxkBrV9o",
        "row": 6392,
        "state": "COLORADO",
        "group": "300314003M",
        "group_num": [
            "recnuwH1yXTpto2aW"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2798,
        "2022": 2777,
        "2023": 0,
        "2024": 0,
        "id": "recw6Sn4a0ISK9EJj",
        "row": 6435,
        "state": "COLORADO",
        "group": "002352773",
        "group_num": [
            "rec2r92MDHVQqR2bI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwarS6pX3hgOFm3",
        "row": 6420,
        "state": "COLORADO",
        "group": "174097N1AB",
        "group_num": [
            "rece2oAKMaUNx882P"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2632,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyUfvV4NKi50I1d",
        "row": 6419,
        "state": "COLORADO",
        "group": "082084401M",
        "group_num": [
            "recO5b7gJummJ6csU"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyt6CrfMwdUSYj3",
        "row": 6456,
        "state": "COLORADO",
        "group": "012465",
        "group_num": [
            "reciYkCjThSsYJhGk"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 73,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyQ99H2X0e0v0hz",
        "row": 6395,
        "state": "COLORADO",
        "group": "300325001M",
        "group_num": [
            "reczXfWy6u1obik5k"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1762,
        "2023": 0,
        "2024": 0,
        "id": "recygoZADhFMEFeIm",
        "row": 6363,
        "state": "COLORADO",
        "group": "10289561",
        "group_num": [
            "recX9hzj67ZnOnCE7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recyhd3JpqcQEh3yL",
        "row": 6362,
        "state": "COLORADO",
        "group": "10289561",
        "group_num": [
            "recX9hzj67ZnOnCE7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczOIQvmtx3znXmo",
        "row": 6412,
        "state": "COLORADO",
        "group": "174210M1C2",
        "group_num": [
            "reccdRBLIdmrx18YN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2125,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczQv4HqIECIvu6r",
        "row": 6447,
        "state": "COLORADO",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "reczhdTn3hMyEFvkD",
        "row": 6436,
        "state": "COLORADO",
        "group": "C22715A2A4",
        "group_num": [
            "recAf4VKeSFCxRDQf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 203,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBueAvxV8QMdor4",
        "row": 6452,
        "state": "COLORADO",
        "group": "20039000",
        "group_num": [
            "recEJQp54SJ9kCWYZ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1523,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCtzhQ8Jh8TzNuw",
        "row": 6400,
        "state": "COLORADO",
        "group": "00955030000",
        "group_num": [
            "recag5gLDXAjv72Md"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 420,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCur1kZiGBncIYk",
        "row": 6398,
        "state": "COLORADO",
        "group": "00935000050",
        "group_num": [
            "recxlk6EUSt008rIj"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 44,
        "2021": 72,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDazPSjUcvtejxf",
        "row": 6376,
        "state": "COLORADO",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 795,
        "2022": 795,
        "2023": 0,
        "2024": 0,
        "id": "recFSzPeBtsQLA8m5",
        "row": 6383,
        "state": "COLORADO",
        "group": "14162996",
        "group_num": [
            "recsxq2chDbTHu1bh"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGzvwvWdPMe8qVE",
        "row": 6426,
        "state": "COLORADO",
        "group": "C10223M002",
        "group_num": [
            "recvhj9zp832xLGe1"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4629,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGDkB0OVN6ubVBJ",
        "row": 6373,
        "state": "COLORADO",
        "group": "270146M1MA",
        "group_num": [
            "receOsKeH5ou8hH7I"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1333,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recG93eXPa65untXT",
        "row": 6433,
        "state": "COLORADO",
        "group": "5L9B00",
        "group_num": [
            "recL76w8hpy0Bbc6c"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recIGL481nc1R5nQw",
        "row": 6374,
        "state": "COLORADO",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 77,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recI4lJV5BMj2csLb",
        "row": 6408,
        "state": "COLORADO",
        "group": "380011",
        "group_num": [
            "recNEimVvZe3Th9Dz"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJyVdQViGzuaYBo",
        "row": 6377,
        "state": "COLORADO",
        "group": "201052M4A1",
        "group_num": [
            "recqjfn6XtMNKbLs2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 914,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJAFAoikhKbkkSP",
        "row": 6405,
        "state": "COLORADO",
        "group": "00761180001",
        "group_num": [
            "rec8Jn6AySCUaqrTf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 370,
        "2022": 370,
        "2023": 0,
        "2024": 0,
        "id": "recLW7kfWBeF3by1H",
        "row": 6424,
        "state": "COLORADO",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 563,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLhGAaMVRvJraam",
        "row": 6454,
        "state": "COLORADO",
        "group": "0077969000",
        "group_num": [
            "recneVaSdo1fzpcFI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 875,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLkY6evprja47LQ",
        "row": 6438,
        "state": "COLORADO",
        "group": "205534",
        "group_num": [
            "recO8FF5eMj32MfU1"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM0mTOGK8U8LzY9",
        "row": 6429,
        "state": "COLORADO",
        "group": "C10223M002",
        "group_num": [
            "recvhj9zp832xLGe1"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 761,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMprxxXkXSxYkyC",
        "row": 6385,
        "state": "COLORADO",
        "group": "174592M3NA",
        "group_num": [
            "recEmBjyt0S65Kyqg"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 1248,
        "2023": 670,
        "2024": 0,
        "id": "receEVReaxspUZBEv",
        "row": 6506,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receeLFqNgGVx69qR",
        "row": 6478,
        "state": "COLORADO",
        "group": "000QQA83409AR00",
        "group_num": [
            "recm1gBbcJUozjzhn"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1577,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receoApbuBukTRPoM",
        "row": 6501,
        "state": "COLORADO",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 610,
        "2021": 269,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgy23BtpZuTUV0y",
        "row": 6502,
        "state": "COLORADO",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2283,
        "2023": 0,
        "2024": 0,
        "id": "recgQAjZnO246mmZ1",
        "row": 6489,
        "state": "COLORADO",
        "group": "L03984M006",
        "group_num": [
            "recOYtZ8ON6K1LW1A"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 393,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechZymwMy21KKpp1",
        "row": 6477,
        "state": "COLORADO",
        "group": "196519M001",
        "group_num": [
            "rec4GSjsiC8HLFdKJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 196,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recimPy4gMTHZKzRT",
        "row": 6490,
        "state": "COLORADO",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 550,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck3AphtGBc7ORIr",
        "row": 6487,
        "state": "COLORADO",
        "group": "99K1",
        "group_num": [
            "rec7cekqMFmH11Xgr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "2023": 172,
        "2024": 0,
        "id": "recm8M0lS0nafrwoY",
        "row": 6498,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 4160,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOtUOP1fdH2L8CT",
        "row": 6500,
        "state": "COLORADO",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 4750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPmXm4owmealtdr",
        "row": 6492,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQZqmsmJbsQ7eiK",
        "row": 6475,
        "state": "COLORADO",
        "group": "196519M001",
        "group_num": [
            "rec4GSjsiC8HLFdKJ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQvzbwYDwQl0vGi",
        "row": 6476,
        "state": "COLORADO",
        "group": "196519M001",
        "group_num": [
            "rec4GSjsiC8HLFdKJ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSu4kZfUSOknPBk",
        "row": 6483,
        "state": "COLORADO",
        "group": "174228M1CZ",
        "group_num": [
            "reccVnX6uNPnObjbo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 694,
        "2023": 0,
        "2024": 0,
        "id": "recUpG8jBjoc892hj",
        "row": 6465,
        "state": "COLORADO",
        "group": "174295M2L2",
        "group_num": [
            "recRfnm0WDi12fv29"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 172,
        "2024": 0,
        "id": "recVZnjLb9ky1QyxW",
        "row": 6504,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recXTqMgRBKAZQmT1",
        "row": 6461,
        "state": "COLORADO",
        "group": "174205M6A6",
        "group_num": [
            "recIOgKOoPj6TMdbx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2033,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXIMDLFFICvZ859",
        "row": 6484,
        "state": "COLORADO",
        "group": "174228M1CZ",
        "group_num": [
            "reccVnX6uNPnObjbo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1807,
        "2023": 0,
        "2024": 0,
        "id": "recYidEEpGUINy9En",
        "row": 6494,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0hZ5BUo8lVDW8e",
        "row": 6481,
        "state": "COLORADO",
        "group": "174288M4A1",
        "group_num": [
            "recmFw5pNWHu5uwJ8"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "rec0puJh5W2wiMfwT",
        "row": 6505,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 581,
        "2023": 863,
        "2024": 0,
        "id": "rec1oAxh393hZCK9j",
        "row": 6480,
        "state": "COLORADO",
        "group": "174226M5A2",
        "group_num": [
            "recipnfrtWD11oW7S"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6dcI6nKODaSyuv",
        "row": 6474,
        "state": "COLORADO",
        "group": "196519M001",
        "group_num": [
            "rec4GSjsiC8HLFdKJ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1635,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9QlMe6dSNpEG3e",
        "row": 6468,
        "state": "COLORADO",
        "group": "174223M1A1",
        "group_num": [
            "recwsNJK3NeTBLBbY"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recasGA2epPAIWsma",
        "row": 6466,
        "state": "COLORADO",
        "group": "L00943M002",
        "group_num": [
            "recnyDBMIGsYfDDM7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdCAqPBBzj4fU18",
        "row": 6467,
        "state": "COLORADO",
        "group": "174223M1A1",
        "group_num": [
            "recwsNJK3NeTBLBbY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "recdDmb24BI1LfpUG",
        "row": 6471,
        "state": "COLORADO",
        "group": "721273015M",
        "group_num": [
            "recCbGTbvp3tX51E5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdFqm6RRx1nxQid",
        "row": 6491,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpZaYXkfWTb9FIt",
        "row": 6469,
        "state": "COLORADO",
        "group": "GA9274M016",
        "group_num": [
            "rec8YtNfcESSrbmwp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 979,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpqF3uUtmOoGJyo",
        "row": 6485,
        "state": "COLORADO",
        "group": "174208M2A6",
        "group_num": [
            "rec950i32qS4uWPpp"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 520,
        "2021": 1002,
        "2022": 1088,
        "2023": 0,
        "2024": 0,
        "id": "recqe2ZFUATHUmuLb",
        "row": 6472,
        "state": "COLORADO",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2363,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrAZUglpXX657Bz",
        "row": 6497,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1826,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectXrWMH9uygNCy2",
        "row": 6459,
        "state": "COLORADO",
        "group": "174205M5A2",
        "group_num": [
            "recLw1fwrwMcbqdp1"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1900,
        "2021": 1599,
        "2022": 1450,
        "2023": 1805,
        "2024": 0,
        "id": "recucleB6nyIFjf64",
        "row": 6496,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwKL5U8SHu78r5s",
        "row": 6508,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDPvIQiaXzo0UsK",
        "row": 6463,
        "state": "COLORADO",
        "group": "F35856",
        "group_num": [
            "rectB2HKRCdsASLSr"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2673,
        "2023": 0,
        "2024": 0,
        "id": "recEd6d58rGnBB21Q",
        "row": 6482,
        "state": "COLORADO",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFL40rOpyTDdUvI",
        "row": 6464,
        "state": "COLORADO",
        "group": "F35856",
        "group_num": [
            "rectB2HKRCdsASLSr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2353,
        "2021": 1691,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recF9KwlVcDEdumED",
        "row": 6495,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 979,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFmfaWQBk2gobBe",
        "row": 6460,
        "state": "COLORADO",
        "group": "174205M5A2",
        "group_num": [
            "recLw1fwrwMcbqdp1"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recHL2NqlJ4sPxMIM",
        "row": 6488,
        "state": "COLORADO",
        "group": "231398",
        "group_num": [
            "recTHgt9RypgM4UvR"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 720,
        "2023": 0,
        "2024": 0,
        "id": "recIcDIOYkiEjlmJA",
        "row": 6493,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIh3QQuUmpE6VV4",
        "row": 6462,
        "state": "COLORADO",
        "group": "F35856",
        "group_num": [
            "rectB2HKRCdsASLSr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 795,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLn5DUgdiGlstqJ",
        "row": 6470,
        "state": "COLORADO",
        "group": "GA9274M016",
        "group_num": [
            "rec8YtNfcESSrbmwp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1175,
        "2022": 1175,
        "2023": 0,
        "2024": 0,
        "id": "recNJiExRbdHDYevw",
        "row": 6507,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgfI1kkWcwJXkgW",
        "row": 6552,
        "state": "COLORADO",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 892,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechDrh8ywDct5tMk",
        "row": 6536,
        "state": "COLORADO",
        "group": "00761760022",
        "group_num": [
            "recUG0UwAw6EkjmOI"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 670,
        "2023": 1399,
        "2024": 0,
        "id": "rech9x4pc3Zi3frBJ",
        "row": 6512,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechivDmoQcQf4bqk",
        "row": 6556,
        "state": "COLORADO",
        "group": "270104M2MA",
        "group_num": [
            "recLBuFtZ3ktnImoY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciW8vW3y67bDmms",
        "row": 6553,
        "state": "COLORADO",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recibxMoU9iSmHyC3",
        "row": 6537,
        "state": "COLORADO",
        "group": "78180151",
        "group_num": [
            "recd7IU72scZHl9Ab"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "2023": 172,
        "2024": 0,
        "id": "recj7jGEoKEE62BqJ",
        "row": 6509,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjozOyGXhXlHSm6",
        "row": 6520,
        "state": "COLORADO",
        "group": "113",
        "group_num": [
            "recVeuA5JyTCBwjDZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1320,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckVxlFC4UpPb0lH",
        "row": 6534,
        "state": "COLORADO",
        "group": "10201351",
        "group_num": [
            "rec0vYlp01aSBx7Yr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1084,
        "2023": 0,
        "2024": 0,
        "id": "recklLDb2TgHheJj5",
        "row": 6548,
        "state": "COLORADO",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1673,
        "2023": 870,
        "2024": 0,
        "id": "reckoscR7euAU09hf",
        "row": 6558,
        "state": "COLORADO",
        "group": "213010P13Y",
        "group_num": [
            "recXyQ9IcBGYpHkAQ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclKGRjA8JZ1NwfG",
        "row": 6511,
        "state": "COLORADO",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 191,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclQOLh9HpRjAs6k",
        "row": 6543,
        "state": "COLORADO",
        "group": "02117",
        "group_num": [
            "rec8SVbrnxi1rhKdE"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 417,
        "2022": 432,
        "2023": 0,
        "2024": 0,
        "id": "recmduikCqrsFQUR9",
        "row": 6540,
        "state": "COLORADO",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recOD6WoowYQmTL0R",
        "row": 6527,
        "state": "COLORADO",
        "group": "14168500",
        "group_num": [
            "recpgdaOQt2dT5TxD"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXS8MCrGsE0MOsS",
        "row": 6514,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYsRRTRbxO9KvVS",
        "row": 6551,
        "state": "COLORADO",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2175,
        "2024": 0,
        "id": "recYtUm7s94Wnm1AO",
        "row": 6533,
        "state": "COLORADO",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZdvsgxgXxsWtVE",
        "row": 6554,
        "state": "COLORADO",
        "group": "CS0149006H",
        "group_num": [
            "rec27GRtghDvf07Ot"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recZnK2ZcVih6GY6Z",
        "row": 6526,
        "state": "COLORADO",
        "group": "14168500",
        "group_num": [
            "recpgdaOQt2dT5TxD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 344,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0usrlSBEih8f0g",
        "row": 6521,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec18eRosgllycqw6",
        "row": 6531,
        "state": "COLORADO",
        "group": "715446800",
        "group_num": [
            "rec3Sp4xOVxdkj7nm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4lhn9T1nH6SpVq",
        "row": 6532,
        "state": "COLORADO",
        "group": "715446800",
        "group_num": [
            "rec3Sp4xOVxdkj7nm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 109,
        "2023": 0,
        "2024": 0,
        "id": "rec8eNjfhI2c4XjNM",
        "row": 6546,
        "state": "COLORADO",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 453,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9T9jgjLZZAgU6S",
        "row": 6524,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 99,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca74AC9kE6zAjCx",
        "row": 6522,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recadBbBcP7pspGPZ",
        "row": 6529,
        "state": "COLORADO",
        "group": "174581M2RA",
        "group_num": [
            "rechOeV5Xa1mPN0HK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 869,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbe0OIZ9nP0ikNI",
        "row": 6539,
        "state": "COLORADO",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2165,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsxongj3drCddzP",
        "row": 6518,
        "state": "COLORADO",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 769,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsqq47I0hsnBwr2",
        "row": 6550,
        "state": "COLORADO",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1005,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu2pZkDbdVyLECU",
        "row": 6542,
        "state": "COLORADO",
        "group": "02117",
        "group_num": [
            "rec8SVbrnxi1rhKdE"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "2023": 0,
        "2024": 0,
        "id": "recv4j2MFuiw8WNhW",
        "row": 6519,
        "state": "COLORADO",
        "group": "113",
        "group_num": [
            "recVeuA5JyTCBwjDZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 995,
        "2021": 872,
        "2022": 1327,
        "2023": 0,
        "2024": 0,
        "id": "recvhpUpeWN6E3Zoj",
        "row": 6545,
        "state": "COLORADO",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczQdrOSxDrRRHi7",
        "row": 6538,
        "state": "COLORADO",
        "group": "271465",
        "group_num": [
            "recMbIw1ii7Pp5sD1"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recBiEyPRQssJkOL2",
        "row": 6516,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recEaQMMRfTgnCeRG",
        "row": 6523,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFI1zVLX1fH9DAV",
        "row": 6528,
        "state": "COLORADO",
        "group": "174168MA25",
        "group_num": [
            "reclUoQJVxZ4F2HHb"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 467,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFnMzH3EKITGmYR",
        "row": 6557,
        "state": "COLORADO",
        "group": "270104M2SA",
        "group_num": [
            "recXc7vV6VacAJpBR"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 108,
        "2024": 0,
        "id": "recHFsyN8Js1dZisz",
        "row": 6510,
        "state": "COLORADO",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1635,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHcb8SEq7khUILX",
        "row": 6530,
        "state": "COLORADO",
        "group": "174581M2RA",
        "group_num": [
            "rechOeV5Xa1mPN0HK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 265,
        "2024": 0,
        "id": "recIw0KUjGuXWHmMG",
        "row": 6555,
        "state": "COLORADO",
        "group": "L03459M005",
        "group_num": [
            "recuApFYLlK6SJNwf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1772,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLKQnCi2XfgaKxj",
        "row": 6513,
        "state": "COLORADO",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece4CPa8zxZVxyes",
        "row": 6567,
        "state": "COLORADO",
        "group": "145094",
        "group_num": [
            "recwFV1P7noKMJvHT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1208,
        "2023": 0,
        "2024": 0,
        "id": "recfL0J42u9km3Mkc",
        "row": 6603,
        "state": "COLORADO",
        "group": "002355673",
        "group_num": [
            "recNYeqsonKBBcPLC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "rechMZqsKALJftJKL",
        "row": 6622,
        "state": "COLORADO",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1911,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechRXnTa55bcI5Tr",
        "row": 6617,
        "state": "COLORADO",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 550,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck3KxzFt3lSCGfh",
        "row": 6608,
        "state": "COLORADO",
        "group": "195610D011",
        "group_num": [
            "recvkguZ1YBJCyldV"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "reclDhWOzYnUfFZ9z",
        "row": 6639,
        "state": "COLORADO",
        "group": "696962",
        "group_num": [
            "recJfmUTFnwA6tjMs"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1414,
        "2023": 0,
        "2024": 0,
        "id": "reclfG3cihWXfa6Qx",
        "row": 6606,
        "state": "COLORADO",
        "group": "287399041",
        "group_num": [
            "recXkNb6R7DULNtjl"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 90,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclkJyqU1I3q8TZF",
        "row": 6569,
        "state": "COLORADO",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1400,
        "2023": 0,
        "2024": 0,
        "id": "recmOOhqDdF03ECGP",
        "row": 6638,
        "state": "COLORADO",
        "group": "696962",
        "group_num": [
            "recJfmUTFnwA6tjMs"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 198,
        "2021": 253,
        "2022": 247,
        "2023": 0,
        "2024": 0,
        "id": "recmmFZ934sx6fTVv",
        "row": 6609,
        "state": "COLORADO",
        "group": "195610D011",
        "group_num": [
            "recvkguZ1YBJCyldV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1033,
        "2021": 1033,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn7aKNqfLyOGJQ6",
        "row": 6618,
        "state": "COLORADO",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "2023": 0,
        "2024": 0,
        "id": "recP8jVtbGslKQURr",
        "row": 6648,
        "state": "COLORADO",
        "group": "V64578M001",
        "group_num": [
            "recWJ0yqF8SmVnl6E"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRQEi5uPQrh3Xrh",
        "row": 6650,
        "state": "COLORADO",
        "group": "4CR100",
        "group_num": [
            "recsfRMGTL4NSAWYI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSK1PdFHViAgPgj",
        "row": 6592,
        "state": "COLORADO",
        "group": "0054395000",
        "group_num": [
            "recLDv2OpWnzjWQaM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1948,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSjHIa1cFlhPcrm",
        "row": 6601,
        "state": "COLORADO",
        "group": "003330110EEAP00",
        "group_num": [
            "recHvJJkzZuoKqJfe"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "2023": 0,
        "2024": 0,
        "id": "recTH9YqRzpvEgVll",
        "row": 6646,
        "state": "COLORADO",
        "group": "282543M002",
        "group_num": [
            "recpQ3FLbnCpOxiKZ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 73,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTPxhejXrUDEk5Q",
        "row": 6581,
        "state": "COLORADO",
        "group": "009601",
        "group_num": [
            "rect4uQDdIzO9sgJK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 530,
        "2023": 0,
        "2024": 0,
        "id": "recUd3Fh8WK4Y0tqO",
        "row": 6623,
        "state": "COLORADO",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 112,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUihfSfFhrmmVIS",
        "row": 6590,
        "state": "COLORADO",
        "group": "W0051486",
        "group_num": [
            "reclqbmSM4sA7lhJm"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1720,
        "2021": 1561,
        "2022": 788,
        "2023": 0,
        "2024": 0,
        "id": "recVUHctKrLILaGif",
        "row": 6585,
        "state": "COLORADO",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1018,
        "2023": 0,
        "2024": 0,
        "id": "recVXJeYNXHzE8OfV",
        "row": 6640,
        "state": "COLORADO",
        "group": "696978",
        "group_num": [
            "recUB12GkAVTQSU5w"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "2023": 0,
        "2024": 0,
        "id": "recVIluolaonXLUpr",
        "row": 6559,
        "state": "COLORADO",
        "group": "213010P13Y",
        "group_num": [
            "recXyQ9IcBGYpHkAQ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWANIB8fyyX2Rmm",
        "row": 6634,
        "state": "COLORADO",
        "group": "000UMQ834AAAP00",
        "group_num": [
            "recphhd14asZxtu4K"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1220,
        "2024": 0,
        "id": "recWrE9gOOJ6aOqk2",
        "row": 6589,
        "state": "COLORADO",
        "group": "W0051486",
        "group_num": [
            "reclqbmSM4sA7lhJm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXzd7pQjKm05gXj",
        "row": 6630,
        "state": "COLORADO",
        "group": "002290848",
        "group_num": [
            "recGo32UZGSwbE5B6"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1980,
        "2023": 2375,
        "2024": 0,
        "id": "recXz30AgMBZepCtl",
        "row": 6620,
        "state": "COLORADO",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1969,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXFaMnvMsyblrub",
        "row": 6563,
        "state": "COLORADO",
        "group": "174153A2A1",
        "group_num": [
            "recfUXUBAgrRzur4m"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0wjFhVzg1AyJVV",
        "row": 6598,
        "state": "COLORADO",
        "group": "004009931LWAP00",
        "group_num": [
            "recD2xZXfat4wiOvi"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1414,
        "2023": 0,
        "2024": 0,
        "id": "rec07L4jam0QKU1oA",
        "row": 6604,
        "state": "COLORADO",
        "group": "002359187",
        "group_num": [
            "recNgUOmnyCWBHdQo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "rec2s52LBrov7Hd4P",
        "row": 6643,
        "state": "COLORADO",
        "group": "000USX834AAAO101",
        "group_num": [
            "recpBV3m4V8bEgaaH"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 955,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "rec2BnhNAxB6I0Puy",
        "row": 6568,
        "state": "COLORADO",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2EO224I5Xd90ww",
        "row": 6566,
        "state": "COLORADO",
        "group": "145094",
        "group_num": [
            "recwFV1P7noKMJvHT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 333,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec40nAOTaJ8LL6e6",
        "row": 6651,
        "state": "COLORADO",
        "group": "5JQY00",
        "group_num": [
            "recjIUmhKIY1HmczO"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 850,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5nU17ttpoxnvu2",
        "row": 6654,
        "state": "COLORADO",
        "group": "5JR500",
        "group_num": [
            "recdqYl8pmaH5TFae"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7sK8XsPJqGs5fd",
        "row": 6635,
        "state": "COLORADO",
        "group": "000UMQ834AAAP00",
        "group_num": [
            "recphhd14asZxtu4K"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 896,
        "2023": 0,
        "2024": 0,
        "id": "rec7a2ajmqet2vJeG",
        "row": 6621,
        "state": "COLORADO",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 548,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8NJQTys76juGqn",
        "row": 6652,
        "state": "COLORADO",
        "group": "5JQY00",
        "group_num": [
            "recjIUmhKIY1HmczO"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 672,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8RNch8k0SyDhXY",
        "row": 6655,
        "state": "COLORADO",
        "group": "5JR500",
        "group_num": [
            "recdqYl8pmaH5TFae"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec8pf499KofE1sHR",
        "row": 6596,
        "state": "COLORADO",
        "group": "214021M2NA",
        "group_num": [
            "recFAwT1UnHovBTzo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2100,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9ibj4l7X0AIVaW",
        "row": 6600,
        "state": "COLORADO",
        "group": "003330110EEAP00",
        "group_num": [
            "recHvJJkzZuoKqJfe"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recb9mZ9sxedFpWs8",
        "row": 6578,
        "state": "COLORADO",
        "group": "485000",
        "group_num": [
            "recbMV3onuXQg6lXV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccS2N4LnjYyZqVb",
        "row": 6574,
        "state": "COLORADO",
        "group": "174277M3AT",
        "group_num": [
            "recU4uh49kq22UBHM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 533,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd1i7qDBwizRytQ",
        "row": 6626,
        "state": "COLORADO",
        "group": "C125170001",
        "group_num": [
            "rechfnowmQJv8V546"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 203,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdP4lWY06dPcDxd",
        "row": 6612,
        "state": "COLORADO",
        "group": "195610D043",
        "group_num": [
            "reco3j407rmh9Zhzx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "2023": 0,
        "2024": 0,
        "id": "recq5aOm3KcyHOSAo",
        "row": 6582,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 439,
        "2023": 486,
        "2024": 0,
        "id": "recqdsU3WQhWmqAKB",
        "row": 6583,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrtBAKmzN5wtSMh",
        "row": 6616,
        "state": "COLORADO",
        "group": "195610P011",
        "group_num": [
            "recXFl2oNJUse52CS"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 464,
        "2022": 472,
        "2023": 0,
        "2024": 0,
        "id": "recr5zM6oJopRnFV6",
        "row": 6645,
        "state": "COLORADO",
        "group": "282543M002",
        "group_num": [
            "recpQ3FLbnCpOxiKZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "2023": 0,
        "2024": 0,
        "id": "recsaSc7332jwXCl0",
        "row": 6658,
        "state": "COLORADO",
        "group": "65KP00",
        "group_num": [
            "rectDJ1WbDaREAK6V"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2420,
        "2023": 3000,
        "2024": 0,
        "id": "rectCiG1VSBATDyLG",
        "row": 6619,
        "state": "COLORADO",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recuPsrW2xBzfuxhG",
        "row": 6595,
        "state": "COLORADO",
        "group": "214021M2NA",
        "group_num": [
            "recFAwT1UnHovBTzo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1718,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvXTw4S1XqRQtti",
        "row": 6629,
        "state": "COLORADO",
        "group": "002290848",
        "group_num": [
            "recGo32UZGSwbE5B6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 418,
        "2023": 0,
        "2024": 0,
        "id": "recvAMO8cx4y09jmx",
        "row": 6571,
        "state": "COLORADO",
        "group": "277727M353",
        "group_num": [
            "recMtF8tF8elmHbGF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1664,
        "2021": 1623,
        "2022": 538,
        "2023": 578,
        "2024": 0,
        "id": "recvPZQT0P8JxLL47",
        "row": 6584,
        "state": "COLORADO",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvhZDtFXN9KULCS",
        "row": 6564,
        "state": "COLORADO",
        "group": "174153A2A1",
        "group_num": [
            "recfUXUBAgrRzur4m"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4712,
        "2022": 3547,
        "2023": 0,
        "2024": 0,
        "id": "recwjraif61ZLOj4z",
        "row": 6642,
        "state": "COLORADO",
        "group": "000USX834AAAO10",
        "group_num": [
            "recV7MmTYTv6EszWL"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recxFXIaxnFfqkt1k",
        "row": 6624,
        "state": "COLORADO",
        "group": "B4829002",
        "group_num": [
            "recPhoPb3xGuzr6rC"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 103,
        "2023": 0,
        "2024": 0,
        "id": "recxOqCy1a5TaJ0Cp",
        "row": 6649,
        "state": "COLORADO",
        "group": "V64578MC01",
        "group_num": [
            "recaD9O0JjtfpGmb9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 403,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx6RUcxLwcV8t9l",
        "row": 6560,
        "state": "COLORADO",
        "group": "W0066541",
        "group_num": [
            "reca3V5aM26HcgjaR"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 828,
        "2022": 390,
        "2023": 0,
        "2024": 0,
        "id": "recxiwD79iECK1vOJ",
        "row": 6653,
        "state": "COLORADO",
        "group": "5JQY00",
        "group_num": [
            "recjIUmhKIY1HmczO"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 261,
        "2021": 304,
        "2022": 1175,
        "2023": 0,
        "2024": 0,
        "id": "recysqLog5FUSRCjr",
        "row": 6586,
        "state": "COLORADO",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy9oRKGvOknbwqn",
        "row": 6610,
        "state": "COLORADO",
        "group": "195610D011",
        "group_num": [
            "recvkguZ1YBJCyldV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "2023": 0,
        "2024": 0,
        "id": "reczP03gy4bZvZxtw",
        "row": 6605,
        "state": "COLORADO",
        "group": "002359187",
        "group_num": [
            "recNgUOmnyCWBHdQo"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAvESxqwSuHCMzL",
        "row": 6614,
        "state": "COLORADO",
        "group": "195610P011",
        "group_num": [
            "recXFl2oNJUse52CS"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recA7t6p3usWL9YHO",
        "row": 6657,
        "state": "COLORADO",
        "group": "65KP00",
        "group_num": [
            "rectDJ1WbDaREAK6V"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1033,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBNsOl383MH9xZj",
        "row": 6636,
        "state": "COLORADO",
        "group": "C20879M5A1",
        "group_num": [
            "recXTo8PFm9ntQYay"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 548,
        "2022": 548,
        "2023": 0,
        "2024": 0,
        "id": "recBQngaNT1LM6TJL",
        "row": 6656,
        "state": "COLORADO",
        "group": "5JR500",
        "group_num": [
            "recdqYl8pmaH5TFae"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recG0cMcTsXl5aOR0",
        "row": 6573,
        "state": "COLORADO",
        "group": "174277M3AT",
        "group_num": [
            "recU4uh49kq22UBHM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHrflB6RSxsx3NV",
        "row": 6565,
        "state": "COLORADO",
        "group": "00101339",
        "group_num": [
            "recOugZPAS4n5rS7i"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2931,
        "2023": 0,
        "2024": 0,
        "id": "recI7sN2n27fTeBDy",
        "row": 6641,
        "state": "COLORADO",
        "group": "000USX834AAAO10",
        "group_num": [
            "recV7MmTYTv6EszWL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJJk1IyoQuwUB8Z",
        "row": 6593,
        "state": "COLORADO",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJ8N5QQJuygF8Zc",
        "row": 6579,
        "state": "COLORADO",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 175,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK2NjOa1pNPigja",
        "row": 6627,
        "state": "COLORADO",
        "group": "004007725AAAP00",
        "group_num": [
            "recDhqPZpZpyWFNtY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK4tGKAX1mTZS6v",
        "row": 6625,
        "state": "COLORADO",
        "group": "C125170001",
        "group_num": [
            "rechfnowmQJv8V546"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKpD7NYl50pIMCw",
        "row": 6575,
        "state": "COLORADO",
        "group": "174277M3AT",
        "group_num": [
            "recU4uh49kq22UBHM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLwsrMZjGY8dmIC",
        "row": 6561,
        "state": "COLORADO",
        "group": "W0066541",
        "group_num": [
            "reca3V5aM26HcgjaR"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1177,
        "2023": 0,
        "2024": 0,
        "id": "recLpku68aa1U8AQi",
        "row": 6597,
        "state": "COLORADO",
        "group": "214021M2NA",
        "group_num": [
            "recFAwT1UnHovBTzo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recMU1jmAXJ9G9sT8",
        "row": 6580,
        "state": "COLORADO",
        "group": "000071569200010",
        "group_num": [
            "recIOGsFL2MQW0OqA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 320,
        "2024": 0,
        "id": "recMzccKlRS552f8k",
        "row": 6572,
        "state": "COLORADO",
        "group": "277727P591",
        "group_num": [
            "rec0C3kTKEF2IbXiw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMAhhuwlVX9rbFm",
        "row": 6615,
        "state": "COLORADO",
        "group": "195610P011",
        "group_num": [
            "recXFl2oNJUse52CS"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 46,
        "2023": 0,
        "2024": 0,
        "id": "recMK01HhZnnhcvIf",
        "row": 6587,
        "state": "COLORADO",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 495,
        "2023": 0,
        "2024": 0,
        "id": "recMmWiThRjT1sWDb",
        "row": 6576,
        "state": "COLORADO",
        "group": "174277M3AT",
        "group_num": [
            "recU4uh49kq22UBHM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recNxtDmuZZXDpUo2",
        "row": 6632,
        "state": "COLORADO",
        "group": "000UMQ834",
        "group_num": [
            "rechpdVZtSpQCn5ZJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recetFwRmdSALfQoe",
        "row": 6704,
        "state": "COLORADO",
        "group": "J25907",
        "group_num": [
            "recHv8VvtWhpYnmDt"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgk3GCXQnFosrEH",
        "row": 6667,
        "state": "COLORADO",
        "group": "5JQ000",
        "group_num": [
            "recIrNIbTCeE5Rhzt"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjGa6LIfqBH5Yeq",
        "row": 6683,
        "state": "COLORADO",
        "group": "A17159",
        "group_num": [
            "recSMbBZZ8kImS2jc"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck3l5jv6KmEaalm",
        "row": 6664,
        "state": "COLORADO",
        "group": "4CPY00",
        "group_num": [
            "recCMaSsCzRXlM3GJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 563,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclJ4ZhBViAvlWEc",
        "row": 6663,
        "state": "COLORADO",
        "group": "4CPY00",
        "group_num": [
            "recCMaSsCzRXlM3GJ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclcRbm2u5m8trIL",
        "row": 6661,
        "state": "COLORADO",
        "group": "4CPV00",
        "group_num": [
            "recIXXh9UdDg8iJpZ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 827,
        "2023": 0,
        "2024": 0,
        "id": "recmkC9tXQVJOAicn",
        "row": 6693,
        "state": "COLORADO",
        "group": "G62968",
        "group_num": [
            "recm8i2wt8QBTSd4n"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 437,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnZFck9iZgg9oHL",
        "row": 6706,
        "state": "COLORADO",
        "group": "J41947",
        "group_num": [
            "receMEH1BN0SEhFV9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1909,
        "2021": 2175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPj52VcgC0kX7pC",
        "row": 6662,
        "state": "COLORADO",
        "group": "4CPV00",
        "group_num": [
            "recIXXh9UdDg8iJpZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQx9BiCkJ2m9ytv",
        "row": 6666,
        "state": "COLORADO",
        "group": "5JQ000",
        "group_num": [
            "recIrNIbTCeE5Rhzt"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRT3LFEr592dNlS",
        "row": 6671,
        "state": "COLORADO",
        "group": "5JQE00",
        "group_num": [
            "recnMoRBXdBvvsO8e"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTdwYCCEvus48M9",
        "row": 6681,
        "state": "COLORADO",
        "group": "498433",
        "group_num": [
            "recdnsN6c3LiLzn2s"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 204,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVwKDAe04wNu6Ic",
        "row": 6675,
        "state": "COLORADO",
        "group": "403865",
        "group_num": [
            "recvIz0jRaHMcWFcK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 197,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVIpRhydw7bHPq9",
        "row": 6690,
        "state": "COLORADO",
        "group": "F36483",
        "group_num": [
            "rec6oYFxwwN6m2THX"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 827,
        "2023": 0,
        "2024": 0,
        "id": "recV87mErMexkCnHL",
        "row": 6684,
        "state": "COLORADO",
        "group": "A40973",
        "group_num": [
            "recIGGnGXMHlniseV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 166,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYpiYEZU8f6y4Db",
        "row": 6696,
        "state": "COLORADO",
        "group": "G71342",
        "group_num": [
            "rec0D16Yokmui07cy"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYqdmWPNw1lvebQ",
        "row": 6680,
        "state": "COLORADO",
        "group": "498433",
        "group_num": [
            "recdnsN6c3LiLzn2s"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZBueWAIsTzwJhx",
        "row": 6674,
        "state": "COLORADO",
        "group": "388267",
        "group_num": [
            "recz7mDFBBK4UZHbP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 34,
        "2024": 0,
        "id": "rec0lDmWQTyYxwHGg",
        "row": 6688,
        "state": "COLORADO",
        "group": "F28434",
        "group_num": [
            "recFilFakR9zAaglV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1U1E46RWuXlh6Z",
        "row": 6686,
        "state": "COLORADO",
        "group": "F12430",
        "group_num": [
            "recUojehgcz0YQeTm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 203,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec17Yd2mskzHRor1",
        "row": 6677,
        "state": "COLORADO",
        "group": "415716",
        "group_num": [
            "recaNHCGozn9HJf00"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4W0cWgqEOm8Tw0",
        "row": 6702,
        "state": "COLORADO",
        "group": "J21382",
        "group_num": [
            "recKc5b9gH4YjzpeK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 979,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5bdLSTwt8UPSmd",
        "row": 6708,
        "state": "COLORADO",
        "group": "J55072",
        "group_num": [
            "recGyGLJjThjJ14Xf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 814,
        "2023": 0,
        "2024": 0,
        "id": "reca7toT0CU3Nk5WO",
        "row": 6659,
        "state": "COLORADO",
        "group": "65KS00",
        "group_num": [
            "recDFnlcmd1gbGx3I"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recanmMB7KeoH1goB",
        "row": 6673,
        "state": "COLORADO",
        "group": "5JQE00",
        "group_num": [
            "recnMoRBXdBvvsO8e"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco5DIjtXGESG3za",
        "row": 6668,
        "state": "COLORADO",
        "group": "5JQ000",
        "group_num": [
            "recIrNIbTCeE5Rhzt"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recryOoiIdP353RKj",
        "row": 6665,
        "state": "COLORADO",
        "group": "4CQ100",
        "group_num": [
            "recOxPJkDZZzpPB4M"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr6oiYbLGrrEI20",
        "row": 6669,
        "state": "COLORADO",
        "group": "5JQ000",
        "group_num": [
            "recIrNIbTCeE5Rhzt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsQhGYL3H2joV7o",
        "row": 6700,
        "state": "COLORADO",
        "group": "J20216",
        "group_num": [
            "recF7rtfvD9gZ0Ukq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reculFXY8QMeOFY7X",
        "row": 6670,
        "state": "COLORADO",
        "group": "5JQ200",
        "group_num": [
            "recxu33uPK9NVsgJf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuowazbi6dFZ3IQ",
        "row": 6692,
        "state": "COLORADO",
        "group": "G59383",
        "group_num": [
            "recb6iitKKfLCEGo7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvvhp66pPT5nydZ",
        "row": 6689,
        "state": "COLORADO",
        "group": "F36483",
        "group_num": [
            "rec6oYFxwwN6m2THX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recwXccND6U4ZzCPc",
        "row": 6679,
        "state": "COLORADO",
        "group": "453865",
        "group_num": [
            "recPrdwd8MD0B0Zsj"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwuOCngAojCGD8t",
        "row": 6695,
        "state": "COLORADO",
        "group": "G71342",
        "group_num": [
            "rec0D16Yokmui07cy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recw2CD4EJa9FC406",
        "row": 6694,
        "state": "COLORADO",
        "group": "G62968",
        "group_num": [
            "recm8i2wt8QBTSd4n"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 491,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczYA05EOBPkiLsH",
        "row": 6672,
        "state": "COLORADO",
        "group": "5JQE00",
        "group_num": [
            "recnMoRBXdBvvsO8e"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczJh7mXwai10FFc",
        "row": 6705,
        "state": "COLORADO",
        "group": "J39453",
        "group_num": [
            "recNKuSRCLN1zriJE"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAUPTnAmlxl6Xlc",
        "row": 6682,
        "state": "COLORADO",
        "group": "A17159",
        "group_num": [
            "recSMbBZZ8kImS2jc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "2023": 0,
        "2024": 0,
        "id": "recB4v2SXX6fii6GD",
        "row": 6697,
        "state": "COLORADO",
        "group": "H36538",
        "group_num": [
            "recAymJ5cdfdTyRT7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 34,
        "2024": 0,
        "id": "recF50c9qxGYvnqxi",
        "row": 6685,
        "state": "COLORADO",
        "group": "A40973",
        "group_num": [
            "recIGGnGXMHlniseV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "2023": 385,
        "2024": 0,
        "id": "recH6VUs78mMSit0K",
        "row": 6660,
        "state": "COLORADO",
        "group": "65KS00",
        "group_num": [
            "recDFnlcmd1gbGx3I"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK3n7P2SlBh6Qq3",
        "row": 6701,
        "state": "COLORADO",
        "group": "J20510",
        "group_num": [
            "rec2hLajcIpLonRUw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 34,
        "2024": 0,
        "id": "recK5vg2HEfc8YxE0",
        "row": 6698,
        "state": "COLORADO",
        "group": "H36538",
        "group_num": [
            "recAymJ5cdfdTyRT7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMOTgISotOdg8r8",
        "row": 6703,
        "state": "COLORADO",
        "group": "J25907",
        "group_num": [
            "recHv8VvtWhpYnmDt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recNZoRuCBf96eBS1",
        "row": 6699,
        "state": "COLORADO",
        "group": "H50106",
        "group_num": [
            "recGIXJERC9qrNhJX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 948,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recegKBvvyCIThXUk",
        "row": 6758,
        "state": "COLORADO",
        "group": "276748M811",
        "group_num": [
            "recuHmyxrEaK9SSJP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfPtrqqHqAIzCVK",
        "row": 6746,
        "state": "COLORADO",
        "group": "267405",
        "group_num": [
            "rec6nEoMB6mAASEoM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 227,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgZphhFVHH5USbP",
        "row": 6734,
        "state": "COLORADO",
        "group": "00062763",
        "group_num": [
            "recKTmO4bs7xIPhcb"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 228,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgiGHSVmvLP9gbp",
        "row": 6735,
        "state": "COLORADO",
        "group": "00062763",
        "group_num": [
            "recKTmO4bs7xIPhcb"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclkfY4cFGs2hhnU",
        "row": 6731,
        "state": "COLORADO",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 431,
        "2021": 289,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclqoiFhCVZRAbBz",
        "row": 6709,
        "state": "COLORADO",
        "group": "J55072",
        "group_num": [
            "recGyGLJjThjJ14Xf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 383,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnqHpswhobiFlbJ",
        "row": 6725,
        "state": "COLORADO",
        "group": "191689",
        "group_num": [
            "rech7Eo1lv4ubhCU7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOu8dwHliJSFJbb",
        "row": 6710,
        "state": "COLORADO",
        "group": "J55439",
        "group_num": [
            "recW7JbBpJVdU3DSF"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1088,
        "2022": 970,
        "2023": 0,
        "2024": 0,
        "id": "recPTTuP9EsIfnrvC",
        "row": 6712,
        "state": "COLORADO",
        "group": "4CQ500",
        "group_num": [
            "recaozkT88aJw1D2E"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSmVerdf9A27Hih",
        "row": 6748,
        "state": "COLORADO",
        "group": "267405",
        "group_num": [
            "rec6nEoMB6mAASEoM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTEny67heY9GLFO",
        "row": 6744,
        "state": "COLORADO",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 828,
        "2021": 1195,
        "2022": 2175,
        "2023": 1674,
        "2024": 0,
        "id": "recTEHvG1FZe4MzNI",
        "row": 6711,
        "state": "COLORADO",
        "group": "4CQ500",
        "group_num": [
            "recaozkT88aJw1D2E"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 150,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV1sQ3kqrP4xoDK",
        "row": 6756,
        "state": "COLORADO",
        "group": "60021265",
        "group_num": [
            "recmdB5Yjx6GsNHeL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1516,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVBs5IGQUX2wvwG",
        "row": 6740,
        "state": "COLORADO",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 212,
        "2021": 225,
        "2022": 176,
        "2023": 2175,
        "2024": 0,
        "id": "recVlJzngP3fmDIit",
        "row": 6743,
        "state": "COLORADO",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1650,
        "2023": 0,
        "2024": 0,
        "id": "recWRZIXdpEdzH3IQ",
        "row": 6728,
        "state": "COLORADO",
        "group": "281845M006",
        "group_num": [
            "recQiCXPdFDco7ADu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXu7CmZkRY4as0m",
        "row": 6736,
        "state": "COLORADO",
        "group": "00036749",
        "group_num": [
            "rec7fMKw4fVSHS7TL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXxXcrXiP6B60UW",
        "row": 6714,
        "state": "COLORADO",
        "group": "4CQW00",
        "group_num": [
            "recgawbJxYcFlcLGH"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3333,
        "2023": 0,
        "2024": 0,
        "id": "rec2eYCf1DkP8HBOd",
        "row": 6726,
        "state": "COLORADO",
        "group": "281845M006",
        "group_num": [
            "recQiCXPdFDco7ADu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 755,
        "2024": 0,
        "id": "rec3uAOLmorp7jcvB",
        "row": 6713,
        "state": "COLORADO",
        "group": "6RVZ00",
        "group_num": [
            "recKjw772xPybIqLR"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec37I5vtdbUpOGFg",
        "row": 6755,
        "state": "COLORADO",
        "group": "0802130001",
        "group_num": [
            "rec187QDq5Jh4aTe2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5BHKLBkcoJCy16",
        "row": 6750,
        "state": "COLORADO",
        "group": "261288",
        "group_num": [
            "rectrc3BKf9DQPPjC"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 161,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec663KSwBiOs1Psy",
        "row": 6754,
        "state": "COLORADO",
        "group": "080926",
        "group_num": [
            "recUsJf5PkXqiUzKr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 140,
        "2023": 0,
        "2024": 0,
        "id": "rec7YAgWdEBdfJG7u",
        "row": 6757,
        "state": "COLORADO",
        "group": "276748M600",
        "group_num": [
            "recSSsft62YC3beF3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9QQImvCRY6bx4m",
        "row": 6724,
        "state": "COLORADO",
        "group": "4F9800",
        "group_num": [
            "recC7XBeRSdFqtGQx"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 3800,
        "2021": 3800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaYiMLEBMkHBzDp",
        "row": 6737,
        "state": "COLORADO",
        "group": "00036749",
        "group_num": [
            "rec7fMKw4fVSHS7TL"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recaxzwVko0XjtPSk",
        "row": 6729,
        "state": "COLORADO",
        "group": "99999U5K",
        "group_num": [
            "recIWAyIF2FErYCPP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 86,
        "2022": 216,
        "2023": 0,
        "2024": 0,
        "id": "reccWDTsaFInWYJBv",
        "row": 6742,
        "state": "COLORADO",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recozlK8fs6MUUi3J",
        "row": 6730,
        "state": "COLORADO",
        "group": "99999UCX",
        "group_num": [
            "recDtaeMfg76Bcscz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 3261,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqsE4eL4QcaJytG",
        "row": 6739,
        "state": "COLORADO",
        "group": "00063240",
        "group_num": [
            "recfECgiyLeJYYfYc"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 522,
        "2023": 0,
        "2024": 0,
        "id": "recuWco6B01oHzSlT",
        "row": 6733,
        "state": "COLORADO",
        "group": "00062720",
        "group_num": [
            "rec5psCuaAcgwNKAR"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recv8nefsmJAwXg6v",
        "row": 6719,
        "state": "COLORADO",
        "group": "000641",
        "group_num": [
            "rec5fJlh6RvetBSBp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 459,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recw17TlctVZXUHDE",
        "row": 6753,
        "state": "COLORADO",
        "group": "080926",
        "group_num": [
            "recUsJf5PkXqiUzKr"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxDJvy0RCIDaa7x",
        "row": 6747,
        "state": "COLORADO",
        "group": "267405",
        "group_num": [
            "rec6nEoMB6mAASEoM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 181,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recysmUlfbY1qfRzG",
        "row": 6715,
        "state": "COLORADO",
        "group": "4CQW00",
        "group_num": [
            "recgawbJxYcFlcLGH"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyHIx6ccXI8Ffvx",
        "row": 6732,
        "state": "COLORADO",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCkeU1eJjrynGT8",
        "row": 6749,
        "state": "COLORADO",
        "group": "267405",
        "group_num": [
            "rec6nEoMB6mAASEoM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1882,
        "2021": 527,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDHCUawlIDtgVv3",
        "row": 6741,
        "state": "COLORADO",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEKgS7VURYDSkOH",
        "row": 6717,
        "state": "COLORADO",
        "group": "762070166",
        "group_num": [
            "recKFIMLQZdhFY063"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 4700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGiWCgpt4dy0BFz",
        "row": 6738,
        "state": "COLORADO",
        "group": "00063240",
        "group_num": [
            "recfECgiyLeJYYfYc"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 184,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGmCJ8TcL0t5OdQ",
        "row": 6751,
        "state": "COLORADO",
        "group": "261288",
        "group_num": [
            "rectrc3BKf9DQPPjC"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 216,
        "2023": 0,
        "2024": 0,
        "id": "recHHsqqHzI8RgvDm",
        "row": 6720,
        "state": "COLORADO",
        "group": "000641",
        "group_num": [
            "rec5fJlh6RvetBSBp"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 428,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJsp2G3z3UbDjLq",
        "row": 6721,
        "state": "COLORADO",
        "group": "1965437612",
        "group_num": [
            "recGcWTKffrxiuVGi"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2613,
        "2023": 0,
        "2024": 0,
        "id": "recJgv5ugF6SfP9Fp",
        "row": 6727,
        "state": "COLORADO",
        "group": "281845M006",
        "group_num": [
            "recQiCXPdFDco7ADu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLoPD38YhyIqYAK",
        "row": 6723,
        "state": "COLORADO",
        "group": "4F9800",
        "group_num": [
            "recC7XBeRSdFqtGQx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "recMV7oUGn6lKR0GU",
        "row": 6718,
        "state": "COLORADO",
        "group": "000641",
        "group_num": [
            "rec5fJlh6RvetBSBp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recewXPkxaiUTXVke",
        "row": 6797,
        "state": "COLORADO",
        "group": "W0071937",
        "group_num": [
            "recYklTQYoc1XwTas"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgVMwz3BfoXM7NO",
        "row": 6786,
        "state": "COLORADO",
        "group": "H06200",
        "group_num": [
            "recEhTeDwRbmCarAD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "2023": 0,
        "2024": 0,
        "id": "recgGhFgZfcmN5nDT",
        "row": 6789,
        "state": "COLORADO",
        "group": "1855RD",
        "group_num": [
            "recz8oRWtBsrOOHm4"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgf9lEbnLggfGSi",
        "row": 6767,
        "state": "COLORADO",
        "group": "5L9P00",
        "group_num": [
            "reccBkWLkX8GhfDXK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "rechGuaYnwOZCyB0O",
        "row": 6777,
        "state": "COLORADO",
        "group": "030906",
        "group_num": [
            "recqqxBJR8GKVLaCJ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recit0d7cBGhgGhJU",
        "row": 6806,
        "state": "COLORADO",
        "group": "DY3700",
        "group_num": [
            "reczOZOA0xA9Jbgna"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 297,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recinjiEBoQs7NPZm",
        "row": 6796,
        "state": "COLORADO",
        "group": "L01394M004",
        "group_num": [
            "recKdMh6sLBiUE4u3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 502,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjI7dlUjM2GLzNB",
        "row": 6768,
        "state": "COLORADO",
        "group": "5L9P00",
        "group_num": [
            "reccBkWLkX8GhfDXK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjjoy1pnFDbMHRk",
        "row": 6784,
        "state": "COLORADO",
        "group": "038037",
        "group_num": [
            "recmH9CZ1lrnndITh"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckXhrgY6hyvj5nS",
        "row": 6792,
        "state": "COLORADO",
        "group": "282551M007",
        "group_num": [
            "recbgH0SHIgAEEbeN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclf71RhKDrkZON6",
        "row": 6803,
        "state": "COLORADO",
        "group": "451148",
        "group_num": [
            "recropbhw9dUbqWnQ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 420,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm1Kne3GlD07L8s",
        "row": 6774,
        "state": "COLORADO",
        "group": "5LA800",
        "group_num": [
            "recHUrrwmCypIdMd3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 877,
        "2023": 0,
        "2024": 0,
        "id": "recUTdVv81vKVxU4l",
        "row": 6800,
        "state": "COLORADO",
        "group": "W0079105",
        "group_num": [
            "recHrEK7hujw8RYlK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 766,
        "2023": 800,
        "2024": 0,
        "id": "recV3257b5qD5XsVn",
        "row": 6778,
        "state": "COLORADO",
        "group": "030906",
        "group_num": [
            "recqqxBJR8GKVLaCJ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recVeGXsAPf30z6g9",
        "row": 6782,
        "state": "COLORADO",
        "group": "038023",
        "group_num": [
            "recbcovalY01Mt1kD"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recWfH7AhdjKEInf0",
        "row": 6798,
        "state": "COLORADO",
        "group": "W0071937",
        "group_num": [
            "recYklTQYoc1XwTas"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3k1Vt67VPJvy0o",
        "row": 6787,
        "state": "COLORADO",
        "group": "H06200",
        "group_num": [
            "recEhTeDwRbmCarAD"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9ZlSzxKRjl9WU9",
        "row": 6773,
        "state": "COLORADO",
        "group": "5LA800",
        "group_num": [
            "recHUrrwmCypIdMd3"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec90JHGvV5I3x9am",
        "row": 6788,
        "state": "COLORADO",
        "group": "1855RD",
        "group_num": [
            "recz8oRWtBsrOOHm4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 2175,
        "2024": 0,
        "id": "recaQgK6QZI3a1U1S",
        "row": 6762,
        "state": "COLORADO",
        "group": "L06419M002",
        "group_num": [
            "reckxZjjq6L8Vhy5o"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 837,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbG9BY5crtNOXQn",
        "row": 6780,
        "state": "COLORADO",
        "group": "036278",
        "group_num": [
            "recWKv4MiEw0h98ou"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 183,
        "2023": 0,
        "2024": 0,
        "id": "recbGWP7kf1jUKJkt",
        "row": 6776,
        "state": "COLORADO",
        "group": "65JB00",
        "group_num": [
            "recM4HNUQdFvVUTIn"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recczDEIifrwL0O3T",
        "row": 6766,
        "state": "COLORADO",
        "group": "5L9K00",
        "group_num": [
            "rectgHp6ER7yA6Ual"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccB05aqKIF45zEf",
        "row": 6783,
        "state": "COLORADO",
        "group": "038037",
        "group_num": [
            "recmH9CZ1lrnndITh"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1553,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccGPHD1J4TnqC90",
        "row": 6772,
        "state": "COLORADO",
        "group": "5LA800",
        "group_num": [
            "recHUrrwmCypIdMd3"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recouXPT5NI5KjRs9",
        "row": 6790,
        "state": "COLORADO",
        "group": "279733M001",
        "group_num": [
            "reckP9xiL4I312xnA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 654,
        "2022": 2340,
        "2023": 0,
        "2024": 0,
        "id": "recp0oWtYmdW0rsGl",
        "row": 6764,
        "state": "COLORADO",
        "group": "5L9K00",
        "group_num": [
            "rectgHp6ER7yA6Ual"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 527,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrXflInWdCJCgPs",
        "row": 6765,
        "state": "COLORADO",
        "group": "5L9K00",
        "group_num": [
            "rectgHp6ER7yA6Ual"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 406,
        "2023": 0,
        "2024": 0,
        "id": "recrRXUsA5yfpZC2Q",
        "row": 6775,
        "state": "COLORADO",
        "group": "65HK00",
        "group_num": [
            "recjvkIaGZv5gJ9O5"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 1528,
        "2023": 0,
        "2024": 0,
        "id": "recsMFlfZnK3vBpvE",
        "row": 6802,
        "state": "COLORADO",
        "group": "L00994H001",
        "group_num": [
            "recyjjwJ8JgDfSwKk"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 530,
        "2023": 0,
        "2024": 0,
        "id": "recth7p8fYoJgF8QW",
        "row": 6793,
        "state": "COLORADO",
        "group": "282745M004",
        "group_num": [
            "rec6Q9niZ93jSWSEC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 224,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu7qrzzbADdhLQp",
        "row": 6781,
        "state": "COLORADO",
        "group": "036278",
        "group_num": [
            "recWKv4MiEw0h98ou"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recuqgkFXdegs0sQW",
        "row": 6807,
        "state": "COLORADO",
        "group": "DY3700",
        "group_num": [
            "reczOZOA0xA9Jbgna"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvMDyj0RaOLoy7u",
        "row": 6794,
        "state": "COLORADO",
        "group": "282792M010",
        "group_num": [
            "recN2B1CrcFiyD4g9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 216,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAjKhqU7DwYrv2u",
        "row": 6760,
        "state": "COLORADO",
        "group": "245623",
        "group_num": [
            "reczREFvv0AUc3pxV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 316,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBr8hMxju9Yo5uN",
        "row": 6804,
        "state": "COLORADO",
        "group": "470955",
        "group_num": [
            "recAHNjl3MsPjeWkd"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD1U5QSAEmoURC6",
        "row": 6770,
        "state": "COLORADO",
        "group": "5LA300",
        "group_num": [
            "receBSyHzhua7QQ8M"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDHyok3OEpZTOcn",
        "row": 6771,
        "state": "COLORADO",
        "group": "5LA800",
        "group_num": [
            "recHUrrwmCypIdMd3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 176,
        "2021": 176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFjwoyqPTx53ava",
        "row": 6759,
        "state": "COLORADO",
        "group": "245623",
        "group_num": [
            "reczREFvv0AUc3pxV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "recGTmEyQd6zLrpew",
        "row": 6785,
        "state": "COLORADO",
        "group": "038865",
        "group_num": [
            "recD4aH8f2hXrkvyq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 246,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHwDjakIv7svC9e",
        "row": 6769,
        "state": "COLORADO",
        "group": "5L9P00",
        "group_num": [
            "reccBkWLkX8GhfDXK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH7taREu18ppZz7",
        "row": 6779,
        "state": "COLORADO",
        "group": "18670",
        "group_num": [
            "recn852NFWx5XjKzE"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 814,
        "2023": 0,
        "2024": 0,
        "id": "recJZIwhGZ2ec7Piu",
        "row": 6763,
        "state": "COLORADO",
        "group": "5L9K00",
        "group_num": [
            "rectgHp6ER7yA6Ual"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 795,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKFBuMMTXCLsZN4",
        "row": 6801,
        "state": "COLORADO",
        "group": "W0082560",
        "group_num": [
            "recmNEVTBv7VDBvSI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 437,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKp39dUn1KZIMdd",
        "row": 6808,
        "state": "COLORADO",
        "group": "EK6600",
        "group_num": [
            "recGyWcLnTEmwN2lV"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "receI016BjVn91i5I",
        "row": 6838,
        "state": "COLORADO",
        "group": "L05594M001",
        "group_num": [
            "recYeOExFfqIqKBYz"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recfBjNBesh3pf1Nz",
        "row": 6853,
        "state": "COLORADO",
        "group": "B0619004",
        "group_num": [
            "recYfX6bB19jbmSPi"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recfFtCMzlWHwIPDM",
        "row": 6811,
        "state": "COLORADO",
        "group": "195575M130",
        "group_num": [
            "reco8LFeyAd2iQ69q"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "recgISu22n7xJQE0a",
        "row": 6831,
        "state": "COLORADO",
        "group": "E016560003",
        "group_num": [
            "recdNVos8ydnLzJYU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "2023": 0,
        "2024": 0,
        "id": "recg6e00nwxIuGrbU",
        "row": 6847,
        "state": "COLORADO",
        "group": "C083710010",
        "group_num": [
            "recEblEsiqILFYZYJ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgoJUiizeIhca5Q",
        "row": 6842,
        "state": "COLORADO",
        "group": "C16663H002",
        "group_num": [
            "recuQcNCgI05lRG4q"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 34,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciuiuqXEFliyOLv",
        "row": 6835,
        "state": "COLORADO",
        "group": "L02846M013",
        "group_num": [
            "recosh5Pgxe2U1VRH"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci8EjVSQRVXE9Vb",
        "row": 6856,
        "state": "COLORADO",
        "group": "99999ZRC",
        "group_num": [
            "recctHlv92U9yJXa7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1063,
        "2022": 528,
        "2023": 0,
        "2024": 0,
        "id": "reckX8c0SunvjWetD",
        "row": 6845,
        "state": "COLORADO",
        "group": "C23218H010",
        "group_num": [
            "recUUW7oEwhh6qNQy"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckixciGtEvW7ULg",
        "row": 6818,
        "state": "COLORADO",
        "group": "196102M618",
        "group_num": [
            "rec3llBNBUemLNFMn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclYuSUmsxbqqemL",
        "row": 6813,
        "state": "COLORADO",
        "group": "195962M001",
        "group_num": [
            "rec7Jn4i5JxSMrU9E"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1538,
        "2023": 265,
        "2024": 0,
        "id": "recmx74l01irlVgpk",
        "row": 6828,
        "state": "COLORADO",
        "group": "C066360G10",
        "group_num": [
            "recmOjeIzXeGwHx2Y"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOILfYB9NQukyOd",
        "row": 6844,
        "state": "COLORADO",
        "group": "C16663H002",
        "group_num": [
            "recuQcNCgI05lRG4q"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRIyNSaxnAnLGEa",
        "row": 6850,
        "state": "COLORADO",
        "group": "26JP",
        "group_num": [
            "recLWC5zwo06NcXuN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 316,
        "2022": 941,
        "2023": 0,
        "2024": 0,
        "id": "recStrVmIu8zcjw3S",
        "row": 6815,
        "state": "COLORADO",
        "group": "196102M019",
        "group_num": [
            "recm10T7UocqkZmPP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1046,
        "2023": 0,
        "2024": 0,
        "id": "recUrDH6mXGXN98b6",
        "row": 6851,
        "state": "COLORADO",
        "group": "26VZ",
        "group_num": [
            "rec0kNCH61oNvwVl0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1030,
        "2024": 0,
        "id": "recVthYPfwrAGuYkS",
        "row": 6836,
        "state": "COLORADO",
        "group": "L03887M002",
        "group_num": [
            "recg2ZCXDyTasGHtY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 653,
        "2022": 1881,
        "2023": 0,
        "2024": 0,
        "id": "recVKIeXl31d9fSl6",
        "row": 6846,
        "state": "COLORADO",
        "group": "C083710010",
        "group_num": [
            "recEblEsiqILFYZYJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 240,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWFwXIgEDIAaGrj",
        "row": 6848,
        "state": "COLORADO",
        "group": "C083716603",
        "group_num": [
            "recpWAsFdeWcl4MdU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recY3tjtNcoFhToZB",
        "row": 6810,
        "state": "COLORADO",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3pGS0HoAUpjSfa",
        "row": 6839,
        "state": "COLORADO",
        "group": "C04646P001",
        "group_num": [
            "recWVCf9KXfaRF40v"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 805,
        "2023": 875,
        "2024": 0,
        "id": "rec4FUq2lvm7FP9fS",
        "row": 6830,
        "state": "COLORADO",
        "group": "C232543001",
        "group_num": [
            "recVxyLf0O92oijU0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "2023": 0,
        "2024": 0,
        "id": "rec7rLMCsbbai394R",
        "row": 6824,
        "state": "COLORADO",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "rec82Xba8EV2vPn6E",
        "row": 6823,
        "state": "COLORADO",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 34,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recbQD2pblkyoheGJ",
        "row": 6833,
        "state": "COLORADO",
        "group": "G232994002",
        "group_num": [
            "recdollVuOnDwmaav"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 241,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccct7KMdaSJIHgi",
        "row": 6814,
        "state": "COLORADO",
        "group": "195962M001",
        "group_num": [
            "rec7Jn4i5JxSMrU9E"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 265,
        "2024": 0,
        "id": "recoVmIYVsUHRj1HY",
        "row": 6837,
        "state": "COLORADO",
        "group": "L05594M001",
        "group_num": [
            "recYeOExFfqIqKBYz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 239,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recooHxjocwapDn50",
        "row": 6820,
        "state": "COLORADO",
        "group": "196309M001",
        "group_num": [
            "recT28x9gwLhcmMzc"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 124,
        "2023": 0,
        "2024": 0,
        "id": "recqiubq47obJYTaD",
        "row": 6829,
        "state": "COLORADO",
        "group": "C066360G10",
        "group_num": [
            "recmOjeIzXeGwHx2Y"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectx1swxECNd6tXV",
        "row": 6812,
        "state": "COLORADO",
        "group": "195962M001",
        "group_num": [
            "rec7Jn4i5JxSMrU9E"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 203,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvDsLt0zOaLlahM",
        "row": 6840,
        "state": "COLORADO",
        "group": "C04657P001",
        "group_num": [
            "recRKDd1VigOcHU18"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 878,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvefqz88fdwyok8",
        "row": 6832,
        "state": "COLORADO",
        "group": "G232994002",
        "group_num": [
            "recdollVuOnDwmaav"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "2023": 0,
        "2024": 0,
        "id": "recxaG6orU85Akd4b",
        "row": 6852,
        "state": "COLORADO",
        "group": "26VZ",
        "group_num": [
            "rec0kNCH61oNvwVl0"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1197,
        "2023": 265,
        "2024": 0,
        "id": "recyZxFSnds0pWOVw",
        "row": 6825,
        "state": "COLORADO",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczuYIymWc7oBMkS",
        "row": 6821,
        "state": "COLORADO",
        "group": "196732M002",
        "group_num": [
            "rec1CcgNVsyussEoW"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 0,
        "2024": 0,
        "id": "recAL8J0osAfNl1dH",
        "row": 6854,
        "state": "COLORADO",
        "group": "B0619004",
        "group_num": [
            "recYfX6bB19jbmSPi"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 15,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCDGLaOrrVGpduJ",
        "row": 6849,
        "state": "COLORADO",
        "group": "C083716603",
        "group_num": [
            "recpWAsFdeWcl4MdU"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 203,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCfyKUml4dhPBln",
        "row": 6857,
        "state": "COLORADO",
        "group": "99999ZRC",
        "group_num": [
            "recctHlv92U9yJXa7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1958,
        "2021": 1043,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCpbNfKKUfzQ15u",
        "row": 6826,
        "state": "COLORADO",
        "group": "C23218H046",
        "group_num": [
            "recxlgTpcToXsedYF"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 43,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recEXB0EErJRREtRJ",
        "row": 6827,
        "state": "COLORADO",
        "group": "C23218H046",
        "group_num": [
            "recxlgTpcToXsedYF"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 4050,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIQ7LaGGY2xqvM4",
        "row": 6817,
        "state": "COLORADO",
        "group": "196102M618",
        "group_num": [
            "rec3llBNBUemLNFMn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMYN6iagrPFwBzU",
        "row": 6843,
        "state": "COLORADO",
        "group": "C16663H002",
        "group_num": [
            "recuQcNCgI05lRG4q"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1140,
        "2021": 4240,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receX0DCIOQplZVK2",
        "row": 6876,
        "state": "COLORADO",
        "group": "P41996",
        "group_num": [
            "recG7mSbAtGOj65ue"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "receaE7PtFuvzL1dV",
        "row": 6905,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfHH3Gex8F8g5Ud",
        "row": 6866,
        "state": "COLORADO",
        "group": "10033993",
        "group_num": [
            "recE6uyPjWfn6VNi6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 28,
        "2022": 32,
        "2023": 0,
        "2024": 0,
        "id": "recfPru6t1eUNFcAx",
        "row": 6891,
        "state": "COLORADO",
        "group": "PC1533",
        "group_num": [
            "recUBVL4UN8amp6Jv"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recfqusO4XY9mSRT9",
        "row": 6863,
        "state": "COLORADO",
        "group": "828691",
        "group_num": [
            "reclQWHNdvZOFBJcu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "2023": 0,
        "2024": 0,
        "id": "recj2kIVdQDhsxAdU",
        "row": 6865,
        "state": "COLORADO",
        "group": "240591",
        "group_num": [
            "rec3D62tQLHro1qzw"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 44,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckNzSOTA0sCHVwO",
        "row": 6881,
        "state": "COLORADO",
        "group": "P66703",
        "group_num": [
            "recMHK156AymNg57S"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckQs9JTgvIu5noN",
        "row": 6885,
        "state": "COLORADO",
        "group": "P77987",
        "group_num": [
            "recnI2qaHa4J6LUIp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 745,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP8RJMYNw6Theu7",
        "row": 6887,
        "state": "COLORADO",
        "group": "P96085",
        "group_num": [
            "rec3z7bvVGX95zsiV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 875,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQuPtfITjtEVfIG",
        "row": 6878,
        "state": "COLORADO",
        "group": "P58813",
        "group_num": [
            "reckaU7zs4W5PTkSb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQKQqJOV8GTIvMt",
        "row": 6895,
        "state": "COLORADO",
        "group": "PJ2620",
        "group_num": [
            "recXu7rdSxpOaluBZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2121,
        "2023": 0,
        "2024": 0,
        "id": "recStjRSA1lYjmqGD",
        "row": 6903,
        "state": "COLORADO",
        "group": "201060M1A1",
        "group_num": [
            "recGmiWryl7lCTSEI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1021,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUkHBKI9OqSHwXN",
        "row": 6883,
        "state": "COLORADO",
        "group": "P74233",
        "group_num": [
            "recjATHR3BYvUVX6Y"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "2023": 0,
        "2024": 0,
        "id": "recWTb1jsCOKRDTwg",
        "row": 6864,
        "state": "COLORADO",
        "group": "828691",
        "group_num": [
            "reclQWHNdvZOFBJcu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 972,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWJXDFHw6X9kSfZ",
        "row": 6868,
        "state": "COLORADO",
        "group": "P12707",
        "group_num": [
            "recZZiwcIBtWzwF3i"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 700,
        "2023": 0,
        "2024": 0,
        "id": "recYo0aJ3cQo91WHU",
        "row": 6899,
        "state": "COLORADO",
        "group": "214248",
        "group_num": [
            "recniLF61uRz9UPeE"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1057,
        "2023": 0,
        "2024": 0,
        "id": "recZBnfq2gX7WXG7L",
        "row": 6873,
        "state": "COLORADO",
        "group": "P35581",
        "group_num": [
            "rec5aZMXrHQ0o1PZq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 0,
        "2024": 0,
        "id": "rec0ET9xn5KIlc3IW",
        "row": 6906,
        "state": "COLORADO",
        "group": "195610X011",
        "group_num": [
            "recqfIlC0F8vaNtGX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 525,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1y69YViTS9DSHv",
        "row": 6872,
        "state": "COLORADO",
        "group": "P25130",
        "group_num": [
            "recBnZ4HC2Ry0c6JA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "2023": 27,
        "2024": 0,
        "id": "rec1JdRUz8OrwJjwb",
        "row": 6871,
        "state": "COLORADO",
        "group": "P16325",
        "group_num": [
            "rec2N4jsvggHhLjDc"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 338,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4tZDQVQ3vJWIps",
        "row": 6893,
        "state": "COLORADO",
        "group": "PJ1901",
        "group_num": [
            "recaffsKfZC2JONt4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1102,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5dAb57vcxA18Vr",
        "row": 6908,
        "state": "COLORADO",
        "group": "195610X061",
        "group_num": [
            "recUsx7uiEZuymlmA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1085,
        "2023": 1088,
        "2024": 0,
        "id": "rec6OKcUc5C54oohj",
        "row": 6901,
        "state": "COLORADO",
        "group": "255259",
        "group_num": [
            "rec5mJERib5zWmd7n"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "rec7ETFcq0ZPYzBsX",
        "row": 6862,
        "state": "COLORADO",
        "group": "A07848",
        "group_num": [
            "rectGSAMf1dabAuu7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1381,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7eEq2HuXs8JGfU",
        "row": 6884,
        "state": "COLORADO",
        "group": "P74233",
        "group_num": [
            "recjATHR3BYvUVX6Y"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9to6tAERRo2sKq",
        "row": 6860,
        "state": "COLORADO",
        "group": "L00718M002",
        "group_num": [
            "recdsQh53sYGYg47a"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recaWPb2BYD05n3Ss",
        "row": 6902,
        "state": "COLORADO",
        "group": "288892",
        "group_num": [
            "recXezeh7dOCs69L4"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 241,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbs0npGzrV3nf7I",
        "row": 6861,
        "state": "COLORADO",
        "group": "P88047",
        "group_num": [
            "recYJPiEu39vJABaO"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "2023": 0,
        "2024": 0,
        "id": "reccNSptzFZpvPKAj",
        "row": 6870,
        "state": "COLORADO",
        "group": "P16325",
        "group_num": [
            "rec2N4jsvggHhLjDc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1037,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqsDn3C94YAU92T",
        "row": 6890,
        "state": "COLORADO",
        "group": "PC1533",
        "group_num": [
            "recUBVL4UN8amp6Jv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "2023": 0,
        "2024": 0,
        "id": "recrnKjdiD6QbL7mq",
        "row": 6874,
        "state": "COLORADO",
        "group": "P35581",
        "group_num": [
            "rec5aZMXrHQ0o1PZq"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1133,
        "2023": 0,
        "2024": 0,
        "id": "recsf2rSDlpHXVIsn",
        "row": 6904,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 950,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectD3YXdUhqrXka7",
        "row": 6900,
        "state": "COLORADO",
        "group": "214248",
        "group_num": [
            "recniLF61uRz9UPeE"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 725,
        "2021": 938,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect3fUbD66obRAzW",
        "row": 6880,
        "state": "COLORADO",
        "group": "P66703",
        "group_num": [
            "recMHK156AymNg57S"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1085,
        "2023": 1088,
        "2024": 0,
        "id": "recDDpl93xlKtofFz",
        "row": 6886,
        "state": "COLORADO",
        "group": "P91150",
        "group_num": [
            "recWg1ee6TclZvVE8"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2175,
        "2024": 0,
        "id": "recFBdgscRANKwlsA",
        "row": 6898,
        "state": "COLORADO",
        "group": "PL1119",
        "group_num": [
            "recroAQpO3WVRRT9c"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 244,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGa9pYXnsdokJgy",
        "row": 6894,
        "state": "COLORADO",
        "group": "PJ1901",
        "group_num": [
            "recaffsKfZC2JONt4"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 795,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHfUCYyMMG8sMJ7",
        "row": 6867,
        "state": "COLORADO",
        "group": "10033993",
        "group_num": [
            "recE6uyPjWfn6VNi6"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "2023": 0,
        "2024": 0,
        "id": "recJNPFyrlon5z8Gb",
        "row": 6907,
        "state": "COLORADO",
        "group": "195610X011",
        "group_num": [
            "recqfIlC0F8vaNtGX"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1077,
        "2022": 1088,
        "2023": 0,
        "2024": 0,
        "id": "recNVAf923NrcZjXH",
        "row": 6896,
        "state": "COLORADO",
        "group": "PJ2620",
        "group_num": [
            "recXu7rdSxpOaluBZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 71,
        "2021": 75,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNt0lYjrsfuWNGD",
        "row": 6888,
        "state": "COLORADO",
        "group": "P96085",
        "group_num": [
            "rec3z7bvVGX95zsiV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 207,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receAz4qns6kjo5jo",
        "row": 6970,
        "state": "COLORADO",
        "group": "10441114",
        "group_num": [
            "recB1ue6o2lzu7A4Q"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfe8v7WgzHZ2umS",
        "row": 6930,
        "state": "COLORADO",
        "group": "007043678000000",
        "group_num": [
            "recmHWfpJ03RFZQDc"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1088,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechgE4FB1ddsJqiE",
        "row": 6909,
        "state": "COLORADO",
        "group": "195610X061",
        "group_num": [
            "recUsx7uiEZuymlmA"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 318,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechrPvBQT4qpCMe5",
        "row": 6955,
        "state": "COLORADO",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjWbMtZ2is7CfnS",
        "row": 6939,
        "state": "COLORADO",
        "group": "X79731",
        "group_num": [
            "rec7skDIVekR3EGul"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjNePik1dmO6qJD",
        "row": 6973,
        "state": "COLORADO",
        "group": "10450942",
        "group_num": [
            "rec9sLYXEHombn6fd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1588,
        "2024": 0,
        "id": "reckEW6hYXBB0VJTP",
        "row": 6932,
        "state": "COLORADO",
        "group": "130461",
        "group_num": [
            "reciKkxGm23NSsYIA"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclIXVDILyYLlMot",
        "row": 6987,
        "state": "COLORADO",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4178,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmJy4iCrJIOZTui",
        "row": 6912,
        "state": "COLORADO",
        "group": "9672045",
        "group_num": [
            "recIWDqaE3oNNczV5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 538,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnMMJBqHolMgZvX",
        "row": 6998,
        "state": "COLORADO",
        "group": "10494766",
        "group_num": [
            "recM849b6FHteaJSq"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnpHwn0nZtHUu2G",
        "row": 6959,
        "state": "COLORADO",
        "group": "14161364",
        "group_num": [
            "recvqtvmcs59OBh9t"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 75,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPZzvMpPB3kgnUS",
        "row": 6978,
        "state": "COLORADO",
        "group": "195920",
        "group_num": [
            "reckqbdG287ePqm8m"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPChlaPK6XpI1qa",
        "row": 6957,
        "state": "COLORADO",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 206,
        "2021": 206,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPnC96MjST0LVyk",
        "row": 6992,
        "state": "COLORADO",
        "group": "10453370",
        "group_num": [
            "recH8QUjgSOxHKeHi"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQTjoGp7bGH195i",
        "row": 6940,
        "state": "COLORADO",
        "group": "X79731",
        "group_num": [
            "rec7skDIVekR3EGul"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQUJfbro0X4GXbo",
        "row": 6948,
        "state": "COLORADO",
        "group": "218385",
        "group_num": [
            "recKEH8iuzGzXW6gj"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 623,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ0gVChnHeZbF0d",
        "row": 6994,
        "state": "COLORADO",
        "group": "10523112",
        "group_num": [
            "reckiXoTbBNifdBtF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 437,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRC3Pv6acdsfEnj",
        "row": 6969,
        "state": "COLORADO",
        "group": "10441114",
        "group_num": [
            "recB1ue6o2lzu7A4Q"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "2023": 0,
        "2024": 0,
        "id": "recScQ9Mw6hoJYY5g",
        "row": 6910,
        "state": "COLORADO",
        "group": "9627055",
        "group_num": [
            "recOIRHpxpuZvKbbW"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 183,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSh9IP7U5sytwWc",
        "row": 6919,
        "state": "COLORADO",
        "group": "85176FF2",
        "group_num": [
            "recPT7PthkXJk9L3v"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 214,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUzTSSkbySIYUFW",
        "row": 6954,
        "state": "COLORADO",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 607,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUeo85aOoARFhhW",
        "row": 6990,
        "state": "COLORADO",
        "group": "OS2902",
        "group_num": [
            "recaxHdJYYfSn2V5X"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 249,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVXVcxVKjgsrKY1",
        "row": 6995,
        "state": "COLORADO",
        "group": "01224603",
        "group_num": [
            "rec1IjmRg0dU4kUyr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recVbsI2Let4oxEx4",
        "row": 6925,
        "state": "COLORADO",
        "group": "002368389",
        "group_num": [
            "reccu2ZgD2ydMEM8b"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recWVuqEJzOWbCSZB",
        "row": 6950,
        "state": "COLORADO",
        "group": "201052A2A1",
        "group_num": [
            "reciVUuuMe9NC9JKJ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 187,
        "2021": 200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWsEQAhZrZe2TQJ",
        "row": 6938,
        "state": "COLORADO",
        "group": "X03320",
        "group_num": [
            "recLUv1et6wYobjCI"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYTSXvFDTMTHHhr",
        "row": 6975,
        "state": "COLORADO",
        "group": "10353796",
        "group_num": [
            "recITvE8P1LUX6g5d"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZY3TdqLIEW15yv",
        "row": 6966,
        "state": "COLORADO",
        "group": "10349031",
        "group_num": [
            "recpgjOkLYXehx0Jl"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZIzaMIU8zYIfOG",
        "row": 6964,
        "state": "COLORADO",
        "group": "10348949",
        "group_num": [
            "rectIsIag9GkGRdfn"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recZL7Sx20uugsEWJ",
        "row": 7007,
        "state": "COLORADO",
        "group": "01002693",
        "group_num": [
            "rec4anJsSCXuzTa9P"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 689,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZQqejTOX7dmciQ",
        "row": 6962,
        "state": "COLORADO",
        "group": "10425044",
        "group_num": [
            "recQH9NSDLyZO88pM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 525,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0ydWTfRSyvFFlQ",
        "row": 6974,
        "state": "COLORADO",
        "group": "10353796",
        "group_num": [
            "recITvE8P1LUX6g5d"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 305,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1VlCscs9tuHAem",
        "row": 6936,
        "state": "COLORADO",
        "group": "196606M001",
        "group_num": [
            "reckTmTCoBmJaL8MD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1paw5yTSJT2ur0",
        "row": 6941,
        "state": "COLORADO",
        "group": "247872",
        "group_num": [
            "rec6mebxG7CAnbeww"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec21Ru87o7ns049c",
        "row": 6996,
        "state": "COLORADO",
        "group": "01224603",
        "group_num": [
            "rec1IjmRg0dU4kUyr"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3ItmNL7a1Lwe9l",
        "row": 6949,
        "state": "COLORADO",
        "group": "003128D0003",
        "group_num": [
            "recXUKPZME2qJXb93"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 63,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3NDzDtLfQ4ggpj",
        "row": 6920,
        "state": "COLORADO",
        "group": "85176FF2",
        "group_num": [
            "recPT7PthkXJk9L3v"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4wgMgiVS3UJZDd",
        "row": 6928,
        "state": "COLORADO",
        "group": "006829868",
        "group_num": [
            "recHTGp3XiFpVZhYV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4AYXH6VCIr7IWb",
        "row": 6947,
        "state": "COLORADO",
        "group": "000426",
        "group_num": [
            "recaKnVA7XkpGMI2p"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 892,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6efRyBFSPPjDCA",
        "row": 6945,
        "state": "COLORADO",
        "group": "00859730002",
        "group_num": [
            "reczQ9SgpLPplykNx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 202,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8VoK8QOIyVzM6F",
        "row": 6988,
        "state": "COLORADO",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4922,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec81dIH15od5wCIQ",
        "row": 7008,
        "state": "COLORADO",
        "group": "174241MNA6",
        "group_num": [
            "recZNGn45hwL4GBq3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1381,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9QU8Z7wziqxPCX",
        "row": 6924,
        "state": "COLORADO",
        "group": "002368312",
        "group_num": [
            "recEkeSHBUZkubFus"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaSAvwOhq2eFUeB",
        "row": 6968,
        "state": "COLORADO",
        "group": "10367734",
        "group_num": [
            "recdleZjwfJqrfMhc"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaEGLMk3IvT5bjS",
        "row": 6983,
        "state": "COLORADO",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb3GmxJabkCeu5o",
        "row": 6922,
        "state": "COLORADO",
        "group": "002299487",
        "group_num": [
            "recrfYCXYxdBnJ2vG"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccbsqCKWH47Uu1n",
        "row": 6942,
        "state": "COLORADO",
        "group": "10353799",
        "group_num": [
            "recRhDqz4bIHihe47"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 875,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccca2fKIRfiiVFB",
        "row": 7002,
        "state": "COLORADO",
        "group": "W11619M001",
        "group_num": [
            "recmz9bZCj1xQ8jZ2"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2796,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccmScfyLetXuhiB",
        "row": 6911,
        "state": "COLORADO",
        "group": "9672045",
        "group_num": [
            "recIWDqaE3oNNczV5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1225,
        "2023": 0,
        "2024": 0,
        "id": "reco5OeKJqFVn2okZ",
        "row": 6926,
        "state": "COLORADO",
        "group": "002368604",
        "group_num": [
            "recAHJVIIHZEyLwni"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpHQjZEVrHWS8AG",
        "row": 6986,
        "state": "COLORADO",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpboVF5EXBKSh52",
        "row": 6981,
        "state": "COLORADO",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqU1AJDALKuzdat",
        "row": 6953,
        "state": "COLORADO",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqXhu71HhkZk9p7",
        "row": 6961,
        "state": "COLORADO",
        "group": "10425044",
        "group_num": [
            "recQH9NSDLyZO88pM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 636,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrtV1S0NOztCELB",
        "row": 6993,
        "state": "COLORADO",
        "group": "10523112",
        "group_num": [
            "reckiXoTbBNifdBtF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrJf8xgLFqagkYJ",
        "row": 6937,
        "state": "COLORADO",
        "group": "X03320",
        "group_num": [
            "recLUv1et6wYobjCI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 187,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrgKsEbJjmx5KKx",
        "row": 6917,
        "state": "COLORADO",
        "group": "83372FF2",
        "group_num": [
            "recV24TXZZbr02BH2"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recss7G6RhK2r8EhV",
        "row": 6980,
        "state": "COLORADO",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 166,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectNAm7tJv1tQh9Y",
        "row": 6977,
        "state": "COLORADO",
        "group": "195920",
        "group_num": [
            "reckqbdG287ePqm8m"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1154,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recthMtuoXuygUg1f",
        "row": 6946,
        "state": "COLORADO",
        "group": "00804G10000",
        "group_num": [
            "rectCWnP2P5K38UbW"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 0,
        "2024": 0,
        "id": "recuGN2j8NvIvyGhY",
        "row": 6933,
        "state": "COLORADO",
        "group": "29389000",
        "group_num": [
            "recH1XCnDqjJH3CQZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 450,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuOAfceWUeZQ0Tb",
        "row": 6944,
        "state": "COLORADO",
        "group": "073909",
        "group_num": [
            "recICMXZXLAtcjZV9"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 669,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recubvLDHFrGeB0ms",
        "row": 6951,
        "state": "COLORADO",
        "group": "199661",
        "group_num": [
            "recOgFpZS4GdIVAQg"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 829,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwtznidK5VX9bAk",
        "row": 6958,
        "state": "COLORADO",
        "group": "",
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 246,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwQRXwhWJUs1T2V",
        "row": 6913,
        "state": "COLORADO",
        "group": "",
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxJkRHWs7GB6Kd2",
        "row": 6976,
        "state": "COLORADO",
        "group": "",
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 700,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczJ8Jx1Auz7Ul49",
        "row": 6935,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczog7dDQQKRqRyB",
        "row": 6985,
        "state": "COLORADO",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recAXHYLiCOp0YdFz",
        "row": 6991,
        "state": "COLORADO",
        "group": "00514618",
        "group_num": [
            "recUj39esS4RbKe11"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 532,
        "2023": 0,
        "2024": 0,
        "id": "recA4PNVDLbXPXnqZ",
        "row": 7004,
        "state": "COLORADO",
        "group": "W12600M002",
        "group_num": [
            "recOcLCA5VYTspOtK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBI3nV9EoSNtLfC",
        "row": 6931,
        "state": "COLORADO",
        "group": "007043678000000",
        "group_num": [
            "recmHWfpJ03RFZQDc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBPA77kZpE4OfKV",
        "row": 6982,
        "state": "COLORADO",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 0,
        "2024": 0,
        "id": "recBRHmDb04abWlRD",
        "row": 7005,
        "state": "COLORADO",
        "group": "W12600M002",
        "group_num": [
            "recOcLCA5VYTspOtK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCPpEdSQxeZsg7w",
        "row": 6984,
        "state": "COLORADO",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 676,
        "2023": 0,
        "2024": 0,
        "id": "recDz5eGUTX9VUvL6",
        "row": 7006,
        "state": "COLORADO",
        "group": "01002693",
        "group_num": [
            "rec4anJsSCXuzTa9P"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 185,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDGoq3ozv9gdT7W",
        "row": 6915,
        "state": "COLORADO",
        "group": "82549FF2",
        "group_num": [
            "recIdSg9x4bO855D8"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDH3RdQWUb6FLwk",
        "row": 6999,
        "state": "COLORADO",
        "group": "10494766",
        "group_num": [
            "recM849b6FHteaJSq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 0,
        "2024": 0,
        "id": "recFi0PrZFTLJJZhU",
        "row": 6960,
        "state": "COLORADO",
        "group": "14171437",
        "group_num": [
            "recUi5bTq6apcVbbH"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGBXr2UfKr7Ligt",
        "row": 6972,
        "state": "COLORADO",
        "group": "10450942",
        "group_num": [
            "rec9sLYXEHombn6fd"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGrS8aYX0zjW6RR",
        "row": 6989,
        "state": "COLORADO",
        "group": "OS2902",
        "group_num": [
            "recaxHdJYYfSn2V5X"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 803,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHRHauuWIyjRStv",
        "row": 6923,
        "state": "COLORADO",
        "group": "002302296",
        "group_num": [
            "recAJ520MG3CpuGGC"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJeokLKBxotCDsd",
        "row": 6927,
        "state": "COLORADO",
        "group": "006829868",
        "group_num": [
            "recHTGp3XiFpVZhYV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 265,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKseXyeFBDhLIcv",
        "row": 6963,
        "state": "COLORADO",
        "group": "10496393",
        "group_num": [
            "recGUjWq9vFZdRrTH"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLC3Dw9zdCY0nZ8",
        "row": 6943,
        "state": "COLORADO",
        "group": "10353824",
        "group_num": [
            "recFGR0wt8U1GZjlp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 1897,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNVAas2l6JhTw4z",
        "row": 7001,
        "state": "COLORADO",
        "group": "W11619M001",
        "group_num": [
            "recmz9bZCj1xQ8jZ2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 73,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNZ2DCBHemIkPNv",
        "row": 7000,
        "state": "COLORADO",
        "group": "10494766",
        "group_num": [
            "recM849b6FHteaJSq"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 2033,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receQdlcUrMDsxUg9",
        "row": 7038,
        "state": "COLORADO",
        "group": "274653",
        "group_num": [
            "reckJLXe7EL46lLKC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechXgYcubyXicslT",
        "row": 7050,
        "state": "COLORADO",
        "group": "10016187",
        "group_num": [
            "rec1056kpYqoKo0mc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechhKQZyjvkGIg6n",
        "row": 7013,
        "state": "COLORADO",
        "group": "109127",
        "group_num": [
            "rec7QKNMkIRFRfjMm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciJUMeSyJlDQxPz",
        "row": 7022,
        "state": "COLORADO",
        "group": "123645",
        "group_num": [
            "recpuDMqxm1y05MA7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recir21oILToXFEyk",
        "row": 7049,
        "state": "COLORADO",
        "group": "10016187",
        "group_num": [
            "rec1056kpYqoKo0mc"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1717,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjf7GDkg8QoCJeC",
        "row": 7046,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 176,
        "2021": 141,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl7O92nJijjv7DF",
        "row": 7025,
        "state": "COLORADO",
        "group": "152577",
        "group_num": [
            "recwZ2ATPN9oyOqV8"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recljuGZtKHjKpvfs",
        "row": 7039,
        "state": "COLORADO",
        "group": "283019",
        "group_num": [
            "recE36zfdf6Uu9pLF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnEUPUawPSwKGJR",
        "row": 7053,
        "state": "COLORADO",
        "group": "10357109",
        "group_num": [
            "recI64wxvgIGHPiBd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOTm5xfqAxpztMS",
        "row": 7047,
        "state": "COLORADO",
        "group": "10030310",
        "group_num": [
            "recoQCBg7pspyuzT7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 259,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPNeLQPpvKmiN30",
        "row": 7011,
        "state": "COLORADO",
        "group": "174241MNA6",
        "group_num": [
            "recZNGn45hwL4GBq3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "2023": 0,
        "2024": 0,
        "id": "recRNWTXWzKcANxrI",
        "row": 7040,
        "state": "COLORADO",
        "group": "283019",
        "group_num": [
            "recE36zfdf6Uu9pLF"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRN2um4VMCk6ZWF",
        "row": 7015,
        "state": "COLORADO",
        "group": "077091",
        "group_num": [
            "recsH4R74ZfD2lyDw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "2023": 0,
        "2024": 0,
        "id": "recStcuC7gjkHIAhA",
        "row": 7044,
        "state": "COLORADO",
        "group": "320088",
        "group_num": [
            "recLALLj59uvsbM92"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUWV8WENSxGaA1R",
        "row": 7058,
        "state": "COLORADO",
        "group": "821650000",
        "group_num": [
            "recatnV5Qwyo5vN0p"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 295,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV6tQrGue6t6Mkd",
        "row": 7018,
        "state": "COLORADO",
        "group": "023536",
        "group_num": [
            "recmS2xP7uHPyWFg5"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXpY8LsXCcYuDJi",
        "row": 7052,
        "state": "COLORADO",
        "group": "10357109",
        "group_num": [
            "recI64wxvgIGHPiBd"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1739,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY8gkzK45J1qHij",
        "row": 7042,
        "state": "COLORADO",
        "group": "302461",
        "group_num": [
            "recOSMFRK5fJe6Pzn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1035,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0S6n46hOygrvGA",
        "row": 7034,
        "state": "COLORADO",
        "group": "251531",
        "group_num": [
            "recRxIWPDvaPUqwGp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 176,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0bdgTvqpoT4VL2",
        "row": 7019,
        "state": "COLORADO",
        "group": "093709",
        "group_num": [
            "recbIIPAyn0Gwdpl0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 960,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5SHETiKmzE1HIJ",
        "row": 7033,
        "state": "COLORADO",
        "group": "222358",
        "group_num": [
            "recYHzsusmmwT3AyC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 783,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8zSPBnPdtKCxbC",
        "row": 7037,
        "state": "COLORADO",
        "group": "256697",
        "group_num": [
            "recvOpDqIROrvSCaS"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recakYLe8P8yvv0fy",
        "row": 7030,
        "state": "COLORADO",
        "group": "196458",
        "group_num": [
            "rec0em1t8M5B2IMQU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 177,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbovUsAHthhMAlb",
        "row": 7031,
        "state": "COLORADO",
        "group": "196458",
        "group_num": [
            "rec0em1t8M5B2IMQU"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 217,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccS4wch5AfsZWrH",
        "row": 7016,
        "state": "COLORADO",
        "group": "077091",
        "group_num": [
            "recsH4R74ZfD2lyDw"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccu299D5or4VtZy",
        "row": 7026,
        "state": "COLORADO",
        "group": "152577",
        "group_num": [
            "recwZ2ATPN9oyOqV8"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 176,
        "2022": 172,
        "2023": 0,
        "2024": 0,
        "id": "reco619Vci7lahnrB",
        "row": 7020,
        "state": "COLORADO",
        "group": "104892",
        "group_num": [
            "rece4tjxOCaOYKm5t"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 4921,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqY87RcBULGz9F6",
        "row": 7009,
        "state": "COLORADO",
        "group": "174241MNA6",
        "group_num": [
            "recZNGn45hwL4GBq3"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsY7zn7WS1cZbLv",
        "row": 7036,
        "state": "COLORADO",
        "group": "255187",
        "group_num": [
            "recX2QUflPt2xcY3K"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1473,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect97Ri6CWEEvefy",
        "row": 7041,
        "state": "COLORADO",
        "group": "296021",
        "group_num": [
            "recm1rwNN6fGoVDbk"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 0,
        "2024": 0,
        "id": "recwQXKowU7XnShyf",
        "row": 7051,
        "state": "COLORADO",
        "group": "10047886",
        "group_num": [
            "rec9TwlUPsFNmavAf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 737,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recynXeffUOZ4nLNd",
        "row": 7056,
        "state": "COLORADO",
        "group": "40002301",
        "group_num": [
            "rec4lRD35OlE2XhdE"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1782,
        "2023": 0,
        "2024": 0,
        "id": "recz8FQbLwQyi8jF6",
        "row": 7043,
        "state": "COLORADO",
        "group": "305381",
        "group_num": [
            "rec0zb2JvR0JgTG16"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 412,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczn8Of0tgABMXex",
        "row": 7055,
        "state": "COLORADO",
        "group": "40002301",
        "group_num": [
            "rec4lRD35OlE2XhdE"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2067,
        "2023": 0,
        "2024": 0,
        "id": "recApFevQv517TsLO",
        "row": 7045,
        "state": "COLORADO",
        "group": "326456",
        "group_num": [
            "recOund0AhzKgZFWL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 73,
        "2023": 0,
        "2024": 0,
        "id": "recCCBzbUsoNnTlwi",
        "row": 7048,
        "state": "COLORADO",
        "group": "4015918",
        "group_num": [
            "recCeXShswOlnAzzt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1140,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDVrCXuVZavrWH6",
        "row": 7032,
        "state": "COLORADO",
        "group": "222358",
        "group_num": [
            "recYHzsusmmwT3AyC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDycq1AjSZ2DWna",
        "row": 7057,
        "state": "COLORADO",
        "group": "821650000",
        "group_num": [
            "recatnV5Qwyo5vN0p"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFQcHGv4lY7Eu2L",
        "row": 7035,
        "state": "COLORADO",
        "group": "251531",
        "group_num": [
            "recRxIWPDvaPUqwGp"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFfuYvuvvFemPhk",
        "row": 7023,
        "state": "COLORADO",
        "group": "123645",
        "group_num": [
            "recpuDMqxm1y05MA7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 378,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGj8Sp4F6pJvNmP",
        "row": 7028,
        "state": "COLORADO",
        "group": "174878",
        "group_num": [
            "recDMHQsxBrSATkZK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIzp7cIn4fEAmlz",
        "row": 7012,
        "state": "COLORADO",
        "group": "123681",
        "group_num": [
            "recuoWSEaHGkmhMdp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKpvfcbUtSeTPwE",
        "row": 7010,
        "state": "COLORADO",
        "group": "174241MNA6",
        "group_num": [
            "recZNGn45hwL4GBq3"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOVxyqD5xKKAqlP",
        "row": 7064,
        "state": "COLORADO",
        "group": "868661000",
        "group_num": [
            "rec6Ui3u3uNKNeTKh"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5618,
        "2023": 0,
        "2024": 0,
        "id": "recZFTTahQOHbIHg4",
        "row": 7065,
        "state": "COLORADO",
        "group": "1100019",
        "group_num": [
            "recuWgwQQoszOdUrq"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 193,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec69Vipbtxq8ESkx",
        "row": 7060,
        "state": "COLORADO",
        "group": "A67626",
        "group_num": [
            "recC7FWiNNEEfRTvd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "2023": 0,
        "2024": 0,
        "id": "recocviHj7dQyRvv7",
        "row": 7059,
        "state": "COLORADO",
        "group": "72076200US",
        "group_num": [
            "recyaEy8TNXG3TOTr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 522,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxozjDuExVTadiP",
        "row": 7063,
        "state": "COLORADO",
        "group": "868661000",
        "group_num": [
            "rec6Ui3u3uNKNeTKh"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH6FvsBCTak1J9D",
        "row": 7061,
        "state": "COLORADO",
        "group": "A67626",
        "group_num": [
            "recC7FWiNNEEfRTvd"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CO"
    },
    {
        "2020": 0,
        "2021": 1000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT6WSILuXBpYMQM",
        "row": 7066,
        "state": "COLORADO",
        "group": "275958P600",
        "group_num": [
            "recwsQSp1ACvNwC70"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNyn5vUJRLLjjME",
        "row": 7067,
        "state": "COLORADO",
        "group": "275958P600",
        "group_num": [
            "recwsQSp1ACvNwC70"
        ],
        "procedure": "",
        "provider": "ANTHEM BCBS OF CA"
    },
    {
        "2020": 61,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOUaJCdVoc2G8vU",
        "row": 3882,
        "state": "COLORADO",
        "group": "NEEN2680",
        "group_num": [
            "recT9ke2bTbFJhWmj"
        ],
        "procedure": "OP",
        "provider": "APOSTROPHE"
    },
    {
        "2020": 797,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd9Xnl3b7kHtdyk",
        "row": 3879,
        "state": "COLORADO",
        "group": "NAVAJ04235",
        "group_num": [
            "recSTSi6dY1HJGwgC"
        ],
        "procedure": "IOP",
        "provider": "APOSTROPHE"
    },
    {
        "2020": 542,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recohbLdVh8Qhlz7Q",
        "row": 3881,
        "state": "COLORADO",
        "group": "NEEN2680",
        "group_num": [
            "recT9ke2bTbFJhWmj"
        ],
        "procedure": "IOP",
        "provider": "APOSTROPHE"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec0r9ymATfpDVLaH",
        "row": 3884,
        "state": "COLORADO",
        "group": "1060",
        "group_num": [
            "recAbjuBizJCKIbbJ"
        ],
        "procedure": "Detox",
        "provider": "ASR HEALTH BENEFITS"
    },
    {
        "2020": 1238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfmtssaUYRly2GM",
        "row": 3897,
        "state": "COLORADO",
        "group": "600427",
        "group_num": [
            "reclsN2AEvg2z3d5b"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciO3PmwA4fnYoke",
        "row": 3890,
        "state": "COLORADO",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1390,
        "2023": 0,
        "2024": 0,
        "id": "recR2UH7H7ZavIlRI",
        "row": 3896,
        "state": "COLORADO",
        "group": "113110",
        "group_num": [
            "reccJzFOqCIFYLCrM"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "2023": 0,
        "2024": 0,
        "id": "recSFKZlnhGy42Rxs",
        "row": 3894,
        "state": "COLORADO",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recYp3rpcavFcXNIo",
        "row": 3892,
        "state": "COLORADO",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 825,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec54gSMOeHwklJaL",
        "row": 3886,
        "state": "COLORADO",
        "group": "35147",
        "group_num": [
            "recoIXoHxXmOgby1w"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2051,
        "2023": 0,
        "2024": 0,
        "id": "recorK0DrZiEl1zdq",
        "row": 3893,
        "state": "COLORADO",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 37,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx6qsF2LIut168B",
        "row": 3889,
        "state": "COLORADO",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 1021,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyeDBLM5tiImc71",
        "row": 3885,
        "state": "COLORADO",
        "group": "35147",
        "group_num": [
            "recoIXoHxXmOgby1w"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 4750,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "recBa6tSCBzFDzAw8",
        "row": 3891,
        "state": "COLORADO",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1238,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFWXWMrR7sA6auN",
        "row": 3900,
        "state": "COLORADO",
        "group": "5496670",
        "group_num": [
            "reciayySM8jaIVh9B"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1238,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFyXRlpS8Oj3FUt",
        "row": 3899,
        "state": "COLORADO",
        "group": "5436131",
        "group_num": [
            "rec6ymmcFHshpgLfP"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 1238,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGuyA8vUrPJMySO",
        "row": 3901,
        "state": "COLORADO",
        "group": "02129010",
        "group_num": [
            "recAwbvBaziw74l5x"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLwCybfvjPdz3SB",
        "row": 3888,
        "state": "COLORADO",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1312,
        "2021": 1312,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recL7gsNosHNLAqXh",
        "row": 3887,
        "state": "COLORADO",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "2023": 0,
        "2024": 0,
        "id": "recNDz1MbUYDeCvHB",
        "row": 3895,
        "state": "COLORADO",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 2331,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgWSTOCB2MYuwJK",
        "row": 3912,
        "state": "COLORADO",
        "group": "046967702500002",
        "group_num": [
            "recSo4gFe1pUGHAjY"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 2397,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgloudSZNIMcVlD",
        "row": 3913,
        "state": "COLORADO",
        "group": "046967702500002",
        "group_num": [
            "recSo4gFe1pUGHAjY"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "2023": 0,
        "2024": 0,
        "id": "recjelEIheJ6xytvm",
        "row": 3936,
        "state": "COLORADO",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 305,
        "2021": 305,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnK7p3FaFgpQY3a",
        "row": 3934,
        "state": "COLORADO",
        "group": "Y00146",
        "group_num": [
            "recUreBA7ookG2NP7"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "recOUwf43wuOQvyqb",
        "row": 3921,
        "state": "COLORADO",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "2023": 0,
        "2024": 0,
        "id": "recOWY12fb8DzeT97",
        "row": 3916,
        "state": "COLORADO",
        "group": "KA1535",
        "group_num": [
            "rechu6N1LuUCSCjKi"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 2250,
        "2024": 0,
        "id": "recO5kXXi6uN8FdWp",
        "row": 3923,
        "state": "COLORADO",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1064,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQhfuyzWB41wYTb",
        "row": 3933,
        "state": "COLORADO",
        "group": "SUF065",
        "group_num": [
            "recVihUdoIykod3rx"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 135,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRkMb3OEqLGfXRG",
        "row": 3931,
        "state": "COLORADO",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 128,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSXQWw1BQ3X4obM",
        "row": 3906,
        "state": "COLORADO",
        "group": "47023013",
        "group_num": [
            "recC1MlzfpkvyTG3h"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 1216,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSdcoKVxBcL97f6",
        "row": 3909,
        "state": "COLORADO",
        "group": "80001332",
        "group_num": [
            "recRmDCoRztltn0bM"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUTvtJ6PqMw74I7",
        "row": 3930,
        "state": "COLORADO",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 2769,
        "2023": 0,
        "2024": 0,
        "id": "recWBzzZ4vJyOHk6o",
        "row": 3927,
        "state": "COLORADO",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recW6hqruPA1RAGcZ",
        "row": 3922,
        "state": "COLORADO",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 300,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recY0w3lt3YtGs1Ih",
        "row": 3917,
        "state": "COLORADO",
        "group": "KN0009",
        "group_num": [
            "recfJhEQGwsp1yEKz"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 65,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYxcirhaphpNYpp",
        "row": 3908,
        "state": "COLORADO",
        "group": "80000465",
        "group_num": [
            "recQKOP9TXW9a0yC1"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec2qMmE1nv1kZ55P",
        "row": 3924,
        "state": "COLORADO",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "rec4S3ZBJyFOqYbzz",
        "row": 3925,
        "state": "COLORADO",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6Z2zWmwjFVIn4r",
        "row": 3929,
        "state": "COLORADO",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 288,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6B549ABbFsN83n",
        "row": 3905,
        "state": "COLORADO",
        "group": "47023013",
        "group_num": [
            "recC1MlzfpkvyTG3h"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdeYCCuXAdEWSTS",
        "row": 3911,
        "state": "COLORADO",
        "group": "80001495",
        "group_num": [
            "recWpZlQmOq2vLrzL"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 46,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqNPUEJFVFrcVhv",
        "row": 3902,
        "state": "COLORADO",
        "group": "02129010",
        "group_num": [
            "recAwbvBaziw74l5x"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recqleU7yCIs08Lcd",
        "row": 3926,
        "state": "COLORADO",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recsUBWHtg63sPPZP",
        "row": 3920,
        "state": "COLORADO",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxWnnke0d0KrCLQ",
        "row": 3915,
        "state": "COLORADO",
        "group": "ESG001",
        "group_num": [
            "rec9vpo0qctdBvgbV"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczKNHDLZuSoG39H",
        "row": 3907,
        "state": "COLORADO",
        "group": "80000465",
        "group_num": [
            "recQKOP9TXW9a0yC1"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "2023": 0,
        "2024": 0,
        "id": "recBJOubPtrhiCKF6",
        "row": 3918,
        "state": "COLORADO",
        "group": "KN0009",
        "group_num": [
            "recfJhEQGwsp1yEKz"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 1303,
        "2021": 1313,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBO20SixMPjR58T",
        "row": 3910,
        "state": "COLORADO",
        "group": "80001495",
        "group_num": [
            "recWpZlQmOq2vLrzL"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 1238,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCbFHoms5lLtJIk",
        "row": 3919,
        "state": "COLORADO",
        "group": "KN0546",
        "group_num": [
            "rec3mcg5yZjz7w1Yb"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEc6ago9RjVBAgf",
        "row": 3928,
        "state": "COLORADO",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recFGlqhCbhPAFBIe",
        "row": 3935,
        "state": "COLORADO",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIcIz8hCxwv6AmB",
        "row": 3903,
        "state": "COLORADO",
        "group": "35069008",
        "group_num": [
            "recVjbEODeXsSyNkq"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1238,
        "2021": 1237,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKuzVoPV4Zl55Nx",
        "row": 3904,
        "state": "COLORADO",
        "group": "47023013",
        "group_num": [
            "recC1MlzfpkvyTG3h"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 300,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recN6XPE4DYZQuQOr",
        "row": 3914,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 350,
        "2024": 0,
        "id": "rec9n3ili9DrMxM21",
        "row": 3937,
        "state": "COLORADO",
        "group": "0074694002",
        "group_num": [
            "reci53fRsKWgYexy1"
        ],
        "procedure": "IOP",
        "provider": "BEHAVIORAL HEALTH SYSTEMS"
    },
    {
        "2020": 2161,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUf0cnycZj780ew",
        "row": 3938,
        "state": "COLORADO",
        "group": "10000",
        "group_num": [
            "recqYgZl0Rc09VtkM"
        ],
        "procedure": "PHP",
        "provider": "CERNER HEALTH"
    },
    {
        "2020": 1389,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl10Pz8QBD4jVVl",
        "row": 3941,
        "state": "COLORADO",
        "group": "1000",
        "group_num": [
            "rec78yU06gxctpGes"
        ],
        "procedure": "PHP",
        "provider": "CIELOSTAR"
    },
    {
        "2020": 0,
        "2021": 1200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV5JyDeNpE3SG5Q",
        "row": 3940,
        "state": "COLORADO",
        "group": "1000",
        "group_num": [
            "rec78yU06gxctpGes"
        ],
        "procedure": "Detox",
        "provider": "CIELOSTAR"
    },
    {
        "2020": 1103,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNgBo6YWVzypkHg",
        "row": 3942,
        "state": "COLORADO",
        "group": "1000",
        "group_num": [
            "rec78yU06gxctpGes"
        ],
        "procedure": "IOP",
        "provider": "CIELOSTAR"
    },
    {
        "2020": 0,
        "2021": 1954,
        "2022": 2244,
        "2023": 0,
        "2024": 0,
        "id": "recfkqVLPa4VwV5BG",
        "row": 3967,
        "state": "COLORADO",
        "group": "2443694",
        "group_num": [
            "recpXY77EuZYleaGD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 461,
        "2024": 0,
        "id": "recfmQFQAqbzDrcgn",
        "row": 3985,
        "state": "COLORADO",
        "group": "2500142",
        "group_num": [
            "recavefFc88NO2q7H"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgd1SrJ3Jxde0Ph",
        "row": 3978,
        "state": "COLORADO",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "2023": 0,
        "2024": 0,
        "id": "rech9PI0Cw46QfUYW",
        "row": 3996,
        "state": "COLORADO",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "2023": 0,
        "2024": 0,
        "id": "recj5J7xCiqur2xa3",
        "row": 3992,
        "state": "COLORADO",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1075,
        "2023": 0,
        "2024": 0,
        "id": "reck91kWItZCzNqs4",
        "row": 3945,
        "state": "COLORADO",
        "group": "118605",
        "group_num": [
            "recnqEc0nxbLsBn8V"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclDm5IuZYjjPKiR",
        "row": 3969,
        "state": "COLORADO",
        "group": "2445654",
        "group_num": [
            "rec3O6mxB7aJaU3uf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1526,
        "2022": 1005,
        "2023": 0,
        "2024": 0,
        "id": "recO719qoJaDkwWDB",
        "row": 3994,
        "state": "COLORADO",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 387,
        "2023": 0,
        "2024": 0,
        "id": "recOag1Oifyp91aVx",
        "row": 3955,
        "state": "COLORADO",
        "group": "0226126",
        "group_num": [
            "recBQzfLeQSjEOof1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1953,
        "2023": 0,
        "2024": 0,
        "id": "recP0RULSp730NV43",
        "row": 3968,
        "state": "COLORADO",
        "group": "2443694",
        "group_num": [
            "recpXY77EuZYleaGD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1147,
        "2023": 0,
        "2024": 0,
        "id": "recQjTfwQqZYuLgAV",
        "row": 3944,
        "state": "COLORADO",
        "group": "118605",
        "group_num": [
            "recnqEc0nxbLsBn8V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 798,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRnbKlnRXtjXeIS",
        "row": 3964,
        "state": "COLORADO",
        "group": "2001095",
        "group_num": [
            "rec2HHW6mjoWngiGO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recSZPPNMddFVtl8t",
        "row": 3997,
        "state": "COLORADO",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2042,
        "2021": 0,
        "2022": 0,
        "2023": 2055,
        "2024": 0,
        "id": "recTt6beMSIxoGKmK",
        "row": 3980,
        "state": "COLORADO",
        "group": "2498956",
        "group_num": [
            "recP5n0y7Wi5SjQiO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1550,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWHDLLBNzm90mlg",
        "row": 3957,
        "state": "COLORADO",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 290,
        "2024": 0,
        "id": "rec0u8er5pGAFOMls",
        "row": 3976,
        "state": "COLORADO",
        "group": "2465054",
        "group_num": [
            "recAUHkD8tSohECVC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 4157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1ep7MOtMsCb7OJ",
        "row": 3990,
        "state": "COLORADO",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 948,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2i7iv61ElDSGXy",
        "row": 3958,
        "state": "COLORADO",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1088,
        "2024": 0,
        "id": "rec3SLCMQKom4vVEI",
        "row": 3999,
        "state": "COLORADO",
        "group": "2501097",
        "group_num": [
            "recZUdZoUuGZtJSOg"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 440,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7ZsOdYxuX8hKjh",
        "row": 3949,
        "state": "COLORADO",
        "group": "0196755",
        "group_num": [
            "recRpEKjC7IXaewVv"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "rec7mzjqInDXiGXIg",
        "row": 3998,
        "state": "COLORADO",
        "group": "2501097",
        "group_num": [
            "recZUdZoUuGZtJSOg"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 4283,
        "2021": 5185,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8FKVeYrkCGtLI8",
        "row": 3989,
        "state": "COLORADO",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec9KpMW6zdouVuKl",
        "row": 3961,
        "state": "COLORADO",
        "group": "2001051",
        "group_num": [
            "reclEDLo52y3N1iTk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 155,
        "2021": 152,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca2fBlkkwOOGSN0",
        "row": 3947,
        "state": "COLORADO",
        "group": "336239",
        "group_num": [
            "recvv3ZGBPcwm2Zh0"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recac2xSVcvzLPzV9",
        "row": 4001,
        "state": "COLORADO",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1963,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recakp58MGAsfnl7h",
        "row": 3983,
        "state": "COLORADO",
        "group": "2499272",
        "group_num": [
            "recGwvg9J3cmjKhzV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 266,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recckZSuFR8zbKuf0",
        "row": 3981,
        "state": "COLORADO",
        "group": "2499051",
        "group_num": [
            "recXf2kh2JzgXdE9U"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 798,
        "2021": 798,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdInUQHFw3TtcWu",
        "row": 3965,
        "state": "COLORADO",
        "group": "2001095",
        "group_num": [
            "rec2HHW6mjoWngiGO"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3606,
        "2022": 1005,
        "2023": 0,
        "2024": 0,
        "id": "recqdXztbJKSv1XM1",
        "row": 3995,
        "state": "COLORADO",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 550,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvsil5riFhcPn6Q",
        "row": 3951,
        "state": "COLORADO",
        "group": "0196755",
        "group_num": [
            "recRpEKjC7IXaewVv"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 630,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxzfJOjEBPyGmSa",
        "row": 3950,
        "state": "COLORADO",
        "group": "0196755",
        "group_num": [
            "recRpEKjC7IXaewVv"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 395,
        "2024": 0,
        "id": "recxMksbdl2i1oFYz",
        "row": 3984,
        "state": "COLORADO",
        "group": "2499331",
        "group_num": [
            "recCzJjOWDbN41CZf"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "2023": 0,
        "2024": 0,
        "id": "recx4xS8ikq4EOIM8",
        "row": 3991,
        "state": "COLORADO",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recx60GJFqnW7FOP4",
        "row": 3956,
        "state": "COLORADO",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2042,
        "2021": 1924,
        "2022": 0,
        "2023": 2104,
        "2024": 0,
        "id": "recy3sixsGrZ11vZr",
        "row": 3979,
        "state": "COLORADO",
        "group": "2498956",
        "group_num": [
            "recP5n0y7Wi5SjQiO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 711,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recziR9Pw2f8k7YtJ",
        "row": 3971,
        "state": "COLORADO",
        "group": "2458462",
        "group_num": [
            "recyvifOa4CE7Cogi"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGSvYkt6HNQjNNF",
        "row": 3953,
        "state": "COLORADO",
        "group": "0217892",
        "group_num": [
            "recyVA8aKVlX5wxcn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 641,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHdBlgVbz9YkYii",
        "row": 3946,
        "state": "COLORADO",
        "group": "336239",
        "group_num": [
            "recvv3ZGBPcwm2Zh0"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIqKz5H0ciRCwlc",
        "row": 3970,
        "state": "COLORADO",
        "group": "2458462",
        "group_num": [
            "recyvifOa4CE7Cogi"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1870,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJPTiJoqQ50UFLv",
        "row": 3975,
        "state": "COLORADO",
        "group": "2462110",
        "group_num": [
            "recBySQuHBR6SAkOa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "2024": 0,
        "id": "recKWE6srp6pXAaxA",
        "row": 3962,
        "state": "COLORADO",
        "group": "2001062",
        "group_num": [
            "recC9kVAN9k39mtf3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1895,
        "2021": 1895,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKaWBLs5QwsD9Cz",
        "row": 3987,
        "state": "COLORADO",
        "group": "2500547",
        "group_num": [
            "recX1o2dB6HUe9G4w"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 798,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLlJct7XOvsgs8N",
        "row": 3959,
        "state": "COLORADO",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recfrWdAjCIIOcWgP",
        "row": 4046,
        "state": "COLORADO",
        "group": "3215304",
        "group_num": [
            "recJpAnNopIdv4Z8T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj0SfyRDwp8ngz8",
        "row": 4036,
        "state": "COLORADO",
        "group": "3213120",
        "group_num": [
            "rec2IHTUnV577EPim"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 259,
        "2021": 240,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckAALI6v1js9QSH",
        "row": 4022,
        "state": "COLORADO",
        "group": "3208468",
        "group_num": [
            "rec2IrGwadIhkkctW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 689,
        "2021": 522,
        "2022": 345,
        "2023": 0,
        "2024": 0,
        "id": "reckR0rCSzfDte4N8",
        "row": 4017,
        "state": "COLORADO",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 157,
        "2023": 0,
        "2024": 0,
        "id": "recknipPkCaxPsljr",
        "row": 4009,
        "state": "COLORADO",
        "group": "3157440",
        "group_num": [
            "recjPNr4uDEwI6Mux"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 236,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclYb134QyVnyxtu",
        "row": 4014,
        "state": "COLORADO",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl0jWsRtDCw7YlE",
        "row": 4038,
        "state": "COLORADO",
        "group": "3213188",
        "group_num": [
            "recwT07I4XDYecgX6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl2oLqo3EJWIign",
        "row": 4024,
        "state": "COLORADO",
        "group": "3208540",
        "group_num": [
            "recZ4X2hgr4GjaYbW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "recPWW5xKJkPdygcO",
        "row": 4047,
        "state": "COLORADO",
        "group": "3215304",
        "group_num": [
            "recJpAnNopIdv4Z8T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 636,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSuM5lL7Ed5Oiu0",
        "row": 4049,
        "state": "COLORADO",
        "group": "3216276",
        "group_num": [
            "rec3CLWEumKWCK1r4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSDlxos7MBAbNdo",
        "row": 4037,
        "state": "COLORADO",
        "group": "3213120",
        "group_num": [
            "rec2IHTUnV577EPim"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1882,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTLkautdHMgP0t2",
        "row": 4042,
        "state": "COLORADO",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "2024": 0,
        "id": "rec1jXRmdbo6mFXTe",
        "row": 4030,
        "state": "COLORADO",
        "group": "3209708",
        "group_num": [
            "recW4kdtLUjvkdEC6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1943,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec30IHj3brr0tfyb",
        "row": 4020,
        "state": "COLORADO",
        "group": "3208468",
        "group_num": [
            "rec2IrGwadIhkkctW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4TDESsqzukIvmz",
        "row": 4034,
        "state": "COLORADO",
        "group": "3210632",
        "group_num": [
            "recLSkB2BHLHW8Vff"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 813,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbnPwI8KZrJJizc",
        "row": 4040,
        "state": "COLORADO",
        "group": "3213188",
        "group_num": [
            "recwT07I4XDYecgX6"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 594,
        "2021": 813,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpzHculZFGx1ZZs",
        "row": 4011,
        "state": "COLORADO",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 259,
        "2021": 212,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recpdX7fSlsBwswvR",
        "row": 4043,
        "state": "COLORADO",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1890,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqMMeEMn6MLyzk3",
        "row": 4025,
        "state": "COLORADO",
        "group": "3208540",
        "group_num": [
            "recZ4X2hgr4GjaYbW"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recqNAHmNWf2IBQyo",
        "row": 4048,
        "state": "COLORADO",
        "group": "3215304",
        "group_num": [
            "recJpAnNopIdv4Z8T"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrwjtEYQv3aiq4W",
        "row": 4041,
        "state": "COLORADO",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 166,
        "2022": 168,
        "2023": 0,
        "2024": 0,
        "id": "recsbbxVQn11tobik",
        "row": 4044,
        "state": "COLORADO",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1954,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "rectvUSTS1TUyM9uV",
        "row": 4016,
        "state": "COLORADO",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 797,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv7jh1Ykjk5PBbY",
        "row": 4005,
        "state": "COLORADO",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1894,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwMCe02xmoaxzne",
        "row": 4021,
        "state": "COLORADO",
        "group": "3208468",
        "group_num": [
            "rec2IrGwadIhkkctW"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 165,
        "2021": 0,
        "2022": 252,
        "2023": 0,
        "2024": 0,
        "id": "recxhN7jjDFHuRIgp",
        "row": 4018,
        "state": "COLORADO",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAsL0gJrBmjXkJm",
        "row": 4002,
        "state": "COLORADO",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 412,
        "2022": 668,
        "2023": 0,
        "2024": 0,
        "id": "recDyD65hYz1lk6nu",
        "row": 4008,
        "state": "COLORADO",
        "group": "3157440",
        "group_num": [
            "recjPNr4uDEwI6Mux"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD47439chAhXUEe",
        "row": 4050,
        "state": "COLORADO",
        "group": "3216276",
        "group_num": [
            "rec3CLWEumKWCK1r4"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1105,
        "2023": 290,
        "2024": 0,
        "id": "recEQ8Xaa4lho4Sxf",
        "row": 4032,
        "state": "COLORADO",
        "group": "3210392",
        "group_num": [
            "reclBRS5SteeexMYT"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 658,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGznp0PsuTlhePJ",
        "row": 4013,
        "state": "COLORADO",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3606,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHWlaoz6U06UWMI",
        "row": 4039,
        "state": "COLORADO",
        "group": "3213188",
        "group_num": [
            "recwT07I4XDYecgX6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 619,
        "2021": 0,
        "2022": 289,
        "2023": 287,
        "2024": 0,
        "id": "recHAmNS1cTA5W2gx",
        "row": 4006,
        "state": "COLORADO",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJR57hTgeqihN0d",
        "row": 4035,
        "state": "COLORADO",
        "group": "3210632",
        "group_num": [
            "recLSkB2BHLHW8Vff"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1886,
        "2022": 1956,
        "2023": 2023,
        "2024": 0,
        "id": "recJ2CgP55Bo07FNG",
        "row": 4004,
        "state": "COLORADO",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2058,
        "2022": 1977,
        "2023": 2023,
        "2024": 0,
        "id": "recLBwqJwrRbvmvZV",
        "row": 4003,
        "state": "COLORADO",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 99,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN1AaZACyxCYwF1",
        "row": 4029,
        "state": "COLORADO",
        "group": "3209420",
        "group_num": [
            "recvGIsh9hE6uz9br"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1875,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNI2hWOTw3ezULs",
        "row": 4028,
        "state": "COLORADO",
        "group": "3209420",
        "group_num": [
            "recvGIsh9hE6uz9br"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNd19D1Bm4y5hzX",
        "row": 4026,
        "state": "COLORADO",
        "group": "3209240",
        "group_num": [
            "recFvBfmipH5XKt1g"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 429,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recekQ60zXRHaXew5",
        "row": 4128,
        "state": "COLORADO",
        "group": "3335306",
        "group_num": [
            "recOeYTHaZyUuEJVp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 238,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfLVcOtpAo5Bnrr",
        "row": 4056,
        "state": "COLORADO",
        "group": "3309644",
        "group_num": [
            "rec5nIxvdWj2swuXF"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 1872,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechUr0cyP7mGKN9g",
        "row": 4117,
        "state": "COLORADO",
        "group": "3333634",
        "group_num": [
            "recOe2Wf7kyYREqDC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 807,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechWbMJJTVWvpzWA",
        "row": 4136,
        "state": "COLORADO",
        "group": "3336422",
        "group_num": [
            "rec72a8eYUuiJJduA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 552,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechrXvdfkNPPZ2Zc",
        "row": 4070,
        "state": "COLORADO",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjUK0oNVMV4CQWO",
        "row": 4066,
        "state": "COLORADO",
        "group": "3327595",
        "group_num": [
            "rec3FhvBPg4PuJVwF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 2853,
        "2022": 1975,
        "2023": 1975,
        "2024": 0,
        "id": "recjWSyBqrNFnOcky",
        "row": 4059,
        "state": "COLORADO",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "recjIuvO7PDA0tAjW",
        "row": 4065,
        "state": "COLORADO",
        "group": "3320608",
        "group_num": [
            "recRSB5fyyI8fUVHb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 724,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckS59wxmvemomAV",
        "row": 4113,
        "state": "COLORADO",
        "group": "3333488",
        "group_num": [
            "recBaVZ18zZ1lan86"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1954,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "reck7I6kWCvRrHHBn",
        "row": 4091,
        "state": "COLORADO",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl1yOOI8StBHoKA",
        "row": 4129,
        "state": "COLORADO",
        "group": "3335553",
        "group_num": [
            "recBW1sLUJzpNN5Wf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 183,
        "2021": 337,
        "2022": 60,
        "2023": 0,
        "2024": 0,
        "id": "reclNvzv33Dh88qUv",
        "row": 4061,
        "state": "COLORADO",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 688,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclagGNy6v5ILvjJ",
        "row": 4097,
        "state": "COLORADO",
        "group": "3331930",
        "group_num": [
            "recqIUXgse1LxlN6V"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmXMyEyxSGWqrzm",
        "row": 4130,
        "state": "COLORADO",
        "group": "3335748",
        "group_num": [
            "recwhSIY7w37iPvwP"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmA4mS07i2acrpk",
        "row": 4135,
        "state": "COLORADO",
        "group": "3336236",
        "group_num": [
            "recaUrA6gVaxbwn0G"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1712,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmEODNFNpMVbbOC",
        "row": 4148,
        "state": "COLORADO",
        "group": "3337752",
        "group_num": [
            "recT7z6uPj4iTTMfh"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1899,
        "2021": 1887,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmrJD9g4yLFbFPX",
        "row": 4096,
        "state": "COLORADO",
        "group": "3331930",
        "group_num": [
            "recqIUXgse1LxlN6V"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 948,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOWl5gtrLpdSAqs",
        "row": 4149,
        "state": "COLORADO",
        "group": "3337752",
        "group_num": [
            "recT7z6uPj4iTTMfh"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 952,
        "2021": 447,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOxN7BqOKmWdG0S",
        "row": 4060,
        "state": "COLORADO",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 280,
        "2024": 0,
        "id": "recQyvTjyKmD12aer",
        "row": 4107,
        "state": "COLORADO",
        "group": "3332693",
        "group_num": [
            "recfGyvReFZAnxnt6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1943,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRycuOEN4yY6aJX",
        "row": 4103,
        "state": "COLORADO",
        "group": "3332286",
        "group_num": [
            "reckp7DnFO2JjRbUv"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 351,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR7mFIyMvfeJ6LZ",
        "row": 4111,
        "state": "COLORADO",
        "group": "3333247",
        "group_num": [
            "recEECrQbqGQ13ru2"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 594,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRePfTsNDy9qYfo",
        "row": 4118,
        "state": "COLORADO",
        "group": "3333634",
        "group_num": [
            "recOe2Wf7kyYREqDC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 994,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSPWYdk8iUgCTn2",
        "row": 4114,
        "state": "COLORADO",
        "group": "3333488",
        "group_num": [
            "recBaVZ18zZ1lan86"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recU6VPJFfozj24oW",
        "row": 4126,
        "state": "COLORADO",
        "group": "3335115",
        "group_num": [
            "recp5bIpyVGHTDVGy"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 42,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWbVrSbwfgJGQEI",
        "row": 4085,
        "state": "COLORADO",
        "group": "3329705",
        "group_num": [
            "recXZHVLv1PvLDQRA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX2hm5gcTvFZ8Kf",
        "row": 4052,
        "state": "COLORADO",
        "group": "3216828",
        "group_num": [
            "recdVOu4XrB1npz9Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recXgdRi4Ff6e7T3F",
        "row": 4100,
        "state": "COLORADO",
        "group": "3332105",
        "group_num": [
            "recK7j1Nuf3EyTIWs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 149,
        "2021": 154,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXk9WSDh26zVK6a",
        "row": 4072,
        "state": "COLORADO",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recXr2UBl3ztcsf81",
        "row": 4078,
        "state": "COLORADO",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 4411,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYAPXwI2nrovSH9",
        "row": 4147,
        "state": "COLORADO",
        "group": "3337752",
        "group_num": [
            "recT7z6uPj4iTTMfh"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYrdfzT6fuo965B",
        "row": 4084,
        "state": "COLORADO",
        "group": "3329705",
        "group_num": [
            "recXZHVLv1PvLDQRA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 950,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec03AY7pnkyMpATE",
        "row": 4144,
        "state": "COLORADO",
        "group": "3337358",
        "group_num": [
            "recCUmVWQFlYWquco"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "rec09K6GKTuTe1XaD",
        "row": 4094,
        "state": "COLORADO",
        "group": "3331790",
        "group_num": [
            "recjKXOBADHMSsO6Y"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1YTPwI2HTeU3LK",
        "row": 4069,
        "state": "COLORADO",
        "group": "3327654",
        "group_num": [
            "recjjtT3gHn8eyeZ6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1940,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1giQNUMZffDN82",
        "row": 4102,
        "state": "COLORADO",
        "group": "3332262",
        "group_num": [
            "recdmQkaNTijUrf0R"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "rec2AiDIYoAWCPcHf",
        "row": 4074,
        "state": "COLORADO",
        "group": "3328645",
        "group_num": [
            "reckd8F1VpT7dN8Hx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4199,
        "2023": 4723,
        "2024": 0,
        "id": "rec3wmElLU91tgf1I",
        "row": 4121,
        "state": "COLORADO",
        "group": "3333857",
        "group_num": [
            "recQxzIflr8Z9HxrT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 119,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5Y0Dta8pVQq0oS",
        "row": 4112,
        "state": "COLORADO",
        "group": "3333247",
        "group_num": [
            "recEECrQbqGQ13ru2"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1954,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec60UN1wUouKJvAb",
        "row": 4093,
        "state": "COLORADO",
        "group": "3331000",
        "group_num": [
            "recuoq0ViHUqk4UxK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 0,
        "2024": 0,
        "id": "rec6nI6jwAU1WIzFw",
        "row": 4104,
        "state": "COLORADO",
        "group": "3332473",
        "group_num": [
            "recge03GAa6Lw29Cm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 944,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9h2yEtHGs3Ik3o",
        "row": 4082,
        "state": "COLORADO",
        "group": "3329645",
        "group_num": [
            "recea1srC7GxTJDAO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca2OBz3XGzCUXVS",
        "row": 4063,
        "state": "COLORADO",
        "group": "3319768",
        "group_num": [
            "recF43o14kVABoJ7T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbxqxKiVcqW12Pd",
        "row": 4143,
        "state": "COLORADO",
        "group": "3337298",
        "group_num": [
            "recO3A9kPTchVmjeK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbPQln7kFu4YDRZ",
        "row": 4116,
        "state": "COLORADO",
        "group": "3333634",
        "group_num": [
            "recOe2Wf7kyYREqDC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1954,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recoBOvRhIbsTeJJF",
        "row": 4064,
        "state": "COLORADO",
        "group": "3320608",
        "group_num": [
            "recRSB5fyyI8fUVHb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1927,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpCL54C8SqzDkpp",
        "row": 4077,
        "state": "COLORADO",
        "group": "3329195",
        "group_num": [
            "recn5K6DJ9QqXCqL8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3744,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpOvNVdVq6BuosB",
        "row": 4140,
        "state": "COLORADO",
        "group": "3337141",
        "group_num": [
            "recurXb92rf3R0eKm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1904,
        "2022": 1565,
        "2023": 0,
        "2024": 0,
        "id": "recp3j9yxeIEB3w5R",
        "row": 4092,
        "state": "COLORADO",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 920,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recquLkSMVsf5cML4",
        "row": 4151,
        "state": "COLORADO",
        "group": "3338196",
        "group_num": [
            "recumOdrpl8qO7b90"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 3740,
        "2024": 0,
        "id": "recqE23fruwVwOZP3",
        "row": 4095,
        "state": "COLORADO",
        "group": "3331930",
        "group_num": [
            "recqIUXgse1LxlN6V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsINiv3kGyfz7op",
        "row": 4141,
        "state": "COLORADO",
        "group": "3337141",
        "group_num": [
            "recurXb92rf3R0eKm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectV3wzlfg5kO75F",
        "row": 4067,
        "state": "COLORADO",
        "group": "3327596",
        "group_num": [
            "rec13pT2eiBX759WR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 296,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu4gNQaaAsUE5FN",
        "row": 4131,
        "state": "COLORADO",
        "group": "3335748",
        "group_num": [
            "recwhSIY7w37iPvwP"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu7Nrkqiye3uTHC",
        "row": 4123,
        "state": "COLORADO",
        "group": "3335018",
        "group_num": [
            "recqCulw8BaXVVCj9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 270,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxFxrYX1ZNRE5kZ",
        "row": 4137,
        "state": "COLORADO",
        "group": "3336422",
        "group_num": [
            "rec72a8eYUuiJJduA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 1950,
        "2021": 116,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx4P5zKJ3VfwT9p",
        "row": 4088,
        "state": "COLORADO",
        "group": "3329766",
        "group_num": [
            "recvipCg2R9TPrw1C"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 594,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyaI9uUwRsEGn4s",
        "row": 4125,
        "state": "COLORADO",
        "group": "3335018",
        "group_num": [
            "recqCulw8BaXVVCj9"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 60,
        "2024": 0,
        "id": "reczKDfSxm8dqyksZ",
        "row": 4105,
        "state": "COLORADO",
        "group": "3332473",
        "group_num": [
            "recge03GAa6Lw29Cm"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 290,
        "2024": 0,
        "id": "recANeyn48jUGJIFA",
        "row": 4142,
        "state": "COLORADO",
        "group": "3337241",
        "group_num": [
            "rechtoX1WUYBRBWEm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC0m5faZwT0NsPe",
        "row": 4087,
        "state": "COLORADO",
        "group": "3329766",
        "group_num": [
            "recvipCg2R9TPrw1C"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 172,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCJDBvjoYzC1J05",
        "row": 4089,
        "state": "COLORADO",
        "group": "3329766",
        "group_num": [
            "recvipCg2R9TPrw1C"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDum18JAeL63m3f",
        "row": 4124,
        "state": "COLORADO",
        "group": "3335018",
        "group_num": [
            "recqCulw8BaXVVCj9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1965,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDPTwki2ViZMjo9",
        "row": 4076,
        "state": "COLORADO",
        "group": "3329195",
        "group_num": [
            "recn5K6DJ9QqXCqL8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recDr5vG3lvYYFNu6",
        "row": 4075,
        "state": "COLORADO",
        "group": "3328645",
        "group_num": [
            "reckd8F1VpT7dN8Hx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 277,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEyE1zBQ6eorwKG",
        "row": 4109,
        "state": "COLORADO",
        "group": "3332753",
        "group_num": [
            "recThKwykgn05Seln"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3742,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFjkVzt04SlpVEa",
        "row": 4057,
        "state": "COLORADO",
        "group": "3311716",
        "group_num": [
            "rec5HbJsshtKsc3fn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 433,
        "2021": 0,
        "2022": 277,
        "2023": 0,
        "2024": 0,
        "id": "recGS1e1JsPk4wIeM",
        "row": 4053,
        "state": "COLORADO",
        "group": "3216828",
        "group_num": [
            "recdVOu4XrB1npz9Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3226,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recGv0LsFQBP8leJs",
        "row": 4058,
        "state": "COLORADO",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 150,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGPbPmhRO6FlXow",
        "row": 4098,
        "state": "COLORADO",
        "group": "3331930",
        "group_num": [
            "recqIUXgse1LxlN6V"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 758,
        "2021": 769,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHgxPlCXIwEaN1i",
        "row": 4133,
        "state": "COLORADO",
        "group": "3335974",
        "group_num": [
            "rec85Lb4xArv6f9hJ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 84,
        "2021": 80,
        "2022": 59,
        "2023": 87,
        "2024": 0,
        "id": "recIFycQoNrTMlmNe",
        "row": 4054,
        "state": "COLORADO",
        "group": "3216828",
        "group_num": [
            "recdVOu4XrB1npz9Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIMxwwg33GQkqOh",
        "row": 4068,
        "state": "COLORADO",
        "group": "3327654",
        "group_num": [
            "recjjtT3gHn8eyeZ6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "2023": 589,
        "2024": 0,
        "id": "recJjznYnkSpu3KYr",
        "row": 4080,
        "state": "COLORADO",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "recJksu4wOdOwp9u6",
        "row": 4079,
        "state": "COLORADO",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 330,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKxwR9WV6NlZhTG",
        "row": 4119,
        "state": "COLORADO",
        "group": "3333634",
        "group_num": [
            "recOe2Wf7kyYREqDC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3743,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKDF2XeBc7deZbl",
        "row": 4150,
        "state": "COLORADO",
        "group": "3338102",
        "group_num": [
            "recmwyfzWDgqnp7bn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 955,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLUTrn2E3dOSkvh",
        "row": 4139,
        "state": "COLORADO",
        "group": "3336952",
        "group_num": [
            "recgT8lQick56OaLo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMTApj1yXeOvUui",
        "row": 4122,
        "state": "COLORADO",
        "group": "3333868",
        "group_num": [
            "recDjHsggxYvT2Uqs"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 155,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMA8HI2Gs5f8WSx",
        "row": 4145,
        "state": "COLORADO",
        "group": "3337358",
        "group_num": [
            "recCUmVWQFlYWquco"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 815,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN7eC5sZHuEDG80",
        "row": 4071,
        "state": "COLORADO",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "receU9tPbHei5LmCH",
        "row": 4158,
        "state": "COLORADO",
        "group": "3338899",
        "group_num": [
            "rec00IxbdIaLGc8o1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 2135,
        "2024": 0,
        "id": "receyBcTXZwcxW4mQ",
        "row": 4154,
        "state": "COLORADO",
        "group": "3338371",
        "group_num": [
            "rec8WLjFARVDr1oVq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgxrz9eRYyczJNG",
        "row": 4194,
        "state": "COLORADO",
        "group": "3341389",
        "group_num": [
            "reccxjCuiFaKiB2VX"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 259,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech3NQKHMbPFWzrb",
        "row": 4195,
        "state": "COLORADO",
        "group": "3341428",
        "group_num": [
            "recEDiByprUKMEVNQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjztdsqTTvcm06y",
        "row": 4160,
        "state": "COLORADO",
        "group": "3339306",
        "group_num": [
            "recdnAfugVk2k6MzQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckhdx9s2FjZ860I",
        "row": 4166,
        "state": "COLORADO",
        "group": "3339493",
        "group_num": [
            "reckrtLOfjFcgiZZD"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 926,
        "2021": 611,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclMty13y0AukpNv",
        "row": 4171,
        "state": "COLORADO",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQAIVW8fmDEqqxy",
        "row": 4159,
        "state": "COLORADO",
        "group": "3339218",
        "group_num": [
            "recBato0LLKxh6052"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 594,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY1QrlwKSDfIhMO",
        "row": 4199,
        "state": "COLORADO",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2368,
        "2022": 1933,
        "2023": 1964,
        "2024": 0,
        "id": "recYzfYePR83WHt97",
        "row": 4198,
        "state": "COLORADO",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZkSCcLuRCPdgCA",
        "row": 4173,
        "state": "COLORADO",
        "group": "3339838",
        "group_num": [
            "recbnAW98eCKwZfwI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 155,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0gLNHulx1ushfm",
        "row": 4185,
        "state": "COLORADO",
        "group": "3340292",
        "group_num": [
            "recXaKMVFEJg1mOXV"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 11,
        "2022": 24,
        "2023": 0,
        "2024": 0,
        "id": "rec11I7dhpFJTIHXL",
        "row": 4201,
        "state": "COLORADO",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1AvOZE3UwB3H04",
        "row": 4164,
        "state": "COLORADO",
        "group": "3339373",
        "group_num": [
            "recEFmByT4Yky9qUc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3740,
        "2024": 0,
        "id": "rec52dOhMQuv7AzCl",
        "row": 4190,
        "state": "COLORADO",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 182,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6stL5zX4uhXGUv",
        "row": 4192,
        "state": "COLORADO",
        "group": "3341147",
        "group_num": [
            "recg7fJNu2JgeLOra"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8XlPlwPN7bBRFL",
        "row": 4184,
        "state": "COLORADO",
        "group": "3340258",
        "group_num": [
            "recMdZYw0GgjYbFUm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8hMfXLXqsUfxrs",
        "row": 4169,
        "state": "COLORADO",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1414,
        "2023": 1493,
        "2024": 0,
        "id": "rec9YSFfDpMBfvjAl",
        "row": 4174,
        "state": "COLORADO",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9fCU6jV8z6q0AP",
        "row": 4183,
        "state": "COLORADO",
        "group": "3340258",
        "group_num": [
            "recMdZYw0GgjYbFUm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recb8AnMLfxQxO1yv",
        "row": 4156,
        "state": "COLORADO",
        "group": "3338626",
        "group_num": [
            "recVC7Aj57lm64QkX"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccEdX1nvr6oFbKl",
        "row": 4179,
        "state": "COLORADO",
        "group": "3340154",
        "group_num": [
            "recAu7KjKU50XTT9f"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recdAHxn748UFuCAA",
        "row": 4162,
        "state": "COLORADO",
        "group": "3339317",
        "group_num": [
            "recwRY83X3diR3Nw6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 261,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpEOWMnrHqrTsKL",
        "row": 4167,
        "state": "COLORADO",
        "group": "3339493",
        "group_num": [
            "reckrtLOfjFcgiZZD"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 85,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq3bM9XLCLfHTAa",
        "row": 4186,
        "state": "COLORADO",
        "group": "3340874",
        "group_num": [
            "recf4Rzb1GyBNwrGi"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2491,
        "2022": 1989,
        "2023": 0,
        "2024": 0,
        "id": "recsebUMKDgrDrZSy",
        "row": 4197,
        "state": "COLORADO",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recuCJJu1tt61UQj7",
        "row": 4157,
        "state": "COLORADO",
        "group": "3338899",
        "group_num": [
            "rec00IxbdIaLGc8o1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 426,
        "2023": 0,
        "2024": 0,
        "id": "recwT7yGGV8w5bsb4",
        "row": 4175,
        "state": "COLORADO",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 290,
        "2024": 0,
        "id": "recwIVsZ17kWx9I0m",
        "row": 4155,
        "state": "COLORADO",
        "group": "3338371",
        "group_num": [
            "rec8WLjFARVDr1oVq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 713,
        "2021": 222,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwc30PBOuCTi1bJ",
        "row": 4187,
        "state": "COLORADO",
        "group": "3340975",
        "group_num": [
            "recuGR2YsSegF255v"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczJnPlnipstzJAQ",
        "row": 4170,
        "state": "COLORADO",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 886,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recze9v3wUsebf8vX",
        "row": 4191,
        "state": "COLORADO",
        "group": "3341147",
        "group_num": [
            "recg7fJNu2JgeLOra"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA1ZoriishoOUqV",
        "row": 4161,
        "state": "COLORADO",
        "group": "3339306",
        "group_num": [
            "recdnAfugVk2k6MzQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 698,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBMPJEODITCmuhj",
        "row": 4177,
        "state": "COLORADO",
        "group": "3340034",
        "group_num": [
            "recyPzCdK8pcbuYh7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 168,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEYXIPFQTKjZIRd",
        "row": 4188,
        "state": "COLORADO",
        "group": "3340975",
        "group_num": [
            "recuGR2YsSegF255v"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recFEwh4vmIIIi9O4",
        "row": 4165,
        "state": "COLORADO",
        "group": "3339400",
        "group_num": [
            "reca586JSJ8hV3VgP"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGG3WAfUdw42KIv",
        "row": 4181,
        "state": "COLORADO",
        "group": "3340177",
        "group_num": [
            "recZbh7mJDNrtd2FQ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 219,
        "2022": 0,
        "2023": 280,
        "2024": 0,
        "id": "recHskqKt7bcwU76r",
        "row": 4200,
        "state": "COLORADO",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 507,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKSUkDnrLSV61sk",
        "row": 4180,
        "state": "COLORADO",
        "group": "3340177",
        "group_num": [
            "recZbh7mJDNrtd2FQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 3740,
        "2024": 0,
        "id": "recLafLvk7QmcV9lv",
        "row": 4153,
        "state": "COLORADO",
        "group": "3338371",
        "group_num": [
            "rec8WLjFARVDr1oVq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMDO0Qdymonjk2I",
        "row": 4152,
        "state": "COLORADO",
        "group": "3338271",
        "group_num": [
            "recaNuHHoB4isCHZY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece8lc4YkYpZe6aL",
        "row": 4243,
        "state": "COLORADO",
        "group": "3343884",
        "group_num": [
            "recit2uqAi6tcT9N2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfuiIGM2otE6QDC",
        "row": 4210,
        "state": "COLORADO",
        "group": "3342201",
        "group_num": [
            "recyBZLHY3ZQtv0Wh"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "2023": 0,
        "2024": 0,
        "id": "recguGmwBC6y5Z5gv",
        "row": 4282,
        "state": "COLORADO",
        "group": "00358543",
        "group_num": [
            "recX1tHdnEFM8HVRA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1687,
        "2023": 0,
        "2024": 0,
        "id": "recgGIVsLsSdXSFQu",
        "row": 4281,
        "state": "COLORADO",
        "group": "00358543",
        "group_num": [
            "recX1tHdnEFM8HVRA"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recgbi6UQDgTBnuSC",
        "row": 4286,
        "state": "COLORADO",
        "group": "00358844",
        "group_num": [
            "recCJOqB1ecSOpdxk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 290,
        "2024": 0,
        "id": "reciKTM04NkJAImO3",
        "row": 4204,
        "state": "COLORADO",
        "group": "3342042",
        "group_num": [
            "recjSPzog5ckfo7gM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1873,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recke0OnFU3TN9CB3",
        "row": 4298,
        "state": "COLORADO",
        "group": "00605727",
        "group_num": [
            "recZbev2AkY7ucaIq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 720,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclZGsxHxu7H8EKH",
        "row": 4216,
        "state": "COLORADO",
        "group": "3342629",
        "group_num": [
            "recNGQr88LhDdIwVL"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 155,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclvRGWKpGFQ9DFp",
        "row": 4223,
        "state": "COLORADO",
        "group": "3342950",
        "group_num": [
            "recKGWogCu9L1GpZr"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 895,
        "2021": 895,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmzTdmKCyhFfarB",
        "row": 4277,
        "state": "COLORADO",
        "group": "00183329",
        "group_num": [
            "recHtBuWKMy0gBFnM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 218,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnW0cTFXiHLJiDd",
        "row": 4213,
        "state": "COLORADO",
        "group": "3342552",
        "group_num": [
            "reczqdoSQkOkydwDC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 60,
        "2023": 74,
        "2024": 0,
        "id": "recnKd03ty3STUQIQ",
        "row": 4233,
        "state": "COLORADO",
        "group": "3343452",
        "group_num": [
            "rec7dwYfvNYyUajH5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3740,
        "2024": 0,
        "id": "recPE5SoCpnJdMNve",
        "row": 4250,
        "state": "COLORADO",
        "group": "3344443",
        "group_num": [
            "reckkBMebntcXW3wm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPG6lQROxEfAQjW",
        "row": 4280,
        "state": "COLORADO",
        "group": "00357318",
        "group_num": [
            "recLtxVh33SMw20gF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 290,
        "2024": 0,
        "id": "recQQzcj2BDbiuQkt",
        "row": 4264,
        "state": "COLORADO",
        "group": "3345508",
        "group_num": [
            "reczxpNp0QMtKf80G"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "recQiromyKSVM2lao",
        "row": 4300,
        "state": "COLORADO",
        "group": "00606338",
        "group_num": [
            "rect0wjK6fDc81W6q"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 753,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "recRaK3TUHPxTs7YV",
        "row": 4284,
        "state": "COLORADO",
        "group": "00358728",
        "group_num": [
            "recN6vkvn3DmbLWvi"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recRopJ4c2S3tfERL",
        "row": 4251,
        "state": "COLORADO",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSUAYHK8jbJfiRi",
        "row": 4294,
        "state": "COLORADO",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 319,
        "2022": 0,
        "2023": 22,
        "2024": 0,
        "id": "recSEHwOehVsDk4ez",
        "row": 4268,
        "state": "COLORADO",
        "group": "00052520",
        "group_num": [
            "recZYYgHhNw7NofoA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSbqQlW36sjvzWO",
        "row": 4211,
        "state": "COLORADO",
        "group": "3342552",
        "group_num": [
            "reczqdoSQkOkydwDC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSdQJkh4A5sMAOn",
        "row": 4242,
        "state": "COLORADO",
        "group": "3343824",
        "group_num": [
            "recqtBIbQUgqZpr7T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 894,
        "2022": 890,
        "2023": 0,
        "2024": 0,
        "id": "recTIItjrhGHDqmGC",
        "row": 4287,
        "state": "COLORADO",
        "group": "00358992",
        "group_num": [
            "receEbNEC64D9DPnx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1976,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTQP02tkkfJm2z4",
        "row": 4241,
        "state": "COLORADO",
        "group": "3343752",
        "group_num": [
            "recLr1jxc0tOZKTTj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1990,
        "2023": 0,
        "2024": 0,
        "id": "recTdDqKBxTLpD2tB",
        "row": 4228,
        "state": "COLORADO",
        "group": "3343064",
        "group_num": [
            "rect67Sy26eo7bKiI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVvcMiLYlaX9ikY",
        "row": 4219,
        "state": "COLORADO",
        "group": "3342723",
        "group_num": [
            "rec4qfvo0aJDhPzhi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "recXnwJrFshRk1ZL2",
        "row": 4256,
        "state": "COLORADO",
        "group": "3344521",
        "group_num": [
            "recBx1KLfdFsO7Jpo"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 263,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZSeNwpjyzGEV3Y",
        "row": 4290,
        "state": "COLORADO",
        "group": "00359053",
        "group_num": [
            "recg5jqt2Ny5TYlrk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 747,
        "2023": 0,
        "2024": 0,
        "id": "recZxlRIrv6jvUftJ",
        "row": 4299,
        "state": "COLORADO",
        "group": "00606338",
        "group_num": [
            "rect0wjK6fDc81W6q"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2QEjUIRLIy9GjY",
        "row": 4215,
        "state": "COLORADO",
        "group": "3342591",
        "group_num": [
            "recbwOqcSYx13Gd9d"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 2927,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "rec3YjqD4QfhqCLQx",
        "row": 4231,
        "state": "COLORADO",
        "group": "3343452",
        "group_num": [
            "rec7dwYfvNYyUajH5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3417,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3gZNfKibc4sroB",
        "row": 4206,
        "state": "COLORADO",
        "group": "3342083",
        "group_num": [
            "recfbtl4tQeWa3b1I"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 165,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3ho10K6jHvRPqx",
        "row": 4248,
        "state": "COLORADO",
        "group": "3343925",
        "group_num": [
            "recwTnKGvs1s125BL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3740,
        "2024": 0,
        "id": "rec3iHOjXufBdauYj",
        "row": 4270,
        "state": "COLORADO",
        "group": "00052665",
        "group_num": [
            "recy4e9N2ZypEecyL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "rec7AGdQDv6eU8ohn",
        "row": 4293,
        "state": "COLORADO",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 3986,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec76bOscoCUFUR1h",
        "row": 4297,
        "state": "COLORADO",
        "group": "00605129",
        "group_num": [
            "recx3vAcyEipseVQV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1885,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7i2Y0xi3Tk0Ztl",
        "row": 4221,
        "state": "COLORADO",
        "group": "3342906",
        "group_num": [
            "recCygS5EAsZm2gCD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 870,
        "2022": 387,
        "2023": 290,
        "2024": 0,
        "id": "rec7l3M9tNZOyjlrw",
        "row": 4267,
        "state": "COLORADO",
        "group": "00052520",
        "group_num": [
            "recZYYgHhNw7NofoA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8GaS5GUoI5yEhu",
        "row": 4240,
        "state": "COLORADO",
        "group": "3343752",
        "group_num": [
            "recLr1jxc0tOZKTTj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3740,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recaiSWA8TJP8tgE7",
        "row": 4292,
        "state": "COLORADO",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "reccpub4hdQPbGRlz",
        "row": 4252,
        "state": "COLORADO",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 429,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd8UUbIW5IKXc6O",
        "row": 4245,
        "state": "COLORADO",
        "group": "3343917",
        "group_num": [
            "recBQPMsdp3eF8uoB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 280,
        "2024": 0,
        "id": "recdoZawibPvjeaU0",
        "row": 4263,
        "state": "COLORADO",
        "group": "3345143",
        "group_num": [
            "recxDFkmAgbKOc88k"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recppFXPk5y2X2eUn",
        "row": 4276,
        "state": "COLORADO",
        "group": "00183329",
        "group_num": [
            "recHtBuWKMy0gBFnM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 565,
        "2021": 908,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqbBGJeLZj2ZE7O",
        "row": 4274,
        "state": "COLORADO",
        "group": "00166072",
        "group_num": [
            "recBZSuo04DgBumAh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recrS9mHi8kBMEm06",
        "row": 4209,
        "state": "COLORADO",
        "group": "3342187",
        "group_num": [
            "recteE5Mo7SMpTGRC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 477,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr1BfOxzmI5Ya8W",
        "row": 4271,
        "state": "COLORADO",
        "group": "00052665",
        "group_num": [
            "recy4e9N2ZypEecyL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 0,
        "2024": 0,
        "id": "recrP6mECpwGD3PUF",
        "row": 4260,
        "state": "COLORADO",
        "group": "3344685",
        "group_num": [
            "recqrWAbHhTksuMf3"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrnB5KNDNRke096",
        "row": 4212,
        "state": "COLORADO",
        "group": "3342552",
        "group_num": [
            "reczqdoSQkOkydwDC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsWj0BeUkPyXTsR",
        "row": 4235,
        "state": "COLORADO",
        "group": "3343495",
        "group_num": [
            "recHWLb40CDiLUN8e"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs9xRI3qGgCIn2z",
        "row": 4220,
        "state": "COLORADO",
        "group": "3342906",
        "group_num": [
            "recCygS5EAsZm2gCD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recsn8vIsBAhpw9v9",
        "row": 4255,
        "state": "COLORADO",
        "group": "3344521",
        "group_num": [
            "recBx1KLfdFsO7Jpo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectKNvq06hk7gLhm",
        "row": 4265,
        "state": "COLORADO",
        "group": "00052520",
        "group_num": [
            "recZYYgHhNw7NofoA"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1158,
        "2023": 0,
        "2024": 0,
        "id": "rect5Y8UNVzQ8Csvd",
        "row": 4229,
        "state": "COLORADO",
        "group": "3343064",
        "group_num": [
            "rect67Sy26eo7bKiI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu0ZMqAD1iW1gFH",
        "row": 4236,
        "state": "COLORADO",
        "group": "3343495",
        "group_num": [
            "recHWLb40CDiLUN8e"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1887,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "recujF88vB8qxASAU",
        "row": 4247,
        "state": "COLORADO",
        "group": "3343925",
        "group_num": [
            "recwTnKGvs1s125BL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 581,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recukFKn0Q2FUaX1B",
        "row": 4203,
        "state": "COLORADO",
        "group": "3342033",
        "group_num": [
            "recOX9emawNXKaQpj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recvU8dMrEO28ewow",
        "row": 4246,
        "state": "COLORADO",
        "group": "3343925",
        "group_num": [
            "recwTnKGvs1s125BL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 836,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvFMC6ypBmZybgp",
        "row": 4207,
        "state": "COLORADO",
        "group": "3342159",
        "group_num": [
            "rec51Zhr4LKc1v0uO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwZDyOwVhGLsNwx",
        "row": 4278,
        "state": "COLORADO",
        "group": "00183329",
        "group_num": [
            "recHtBuWKMy0gBFnM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 280,
        "2024": 0,
        "id": "recwJzTwFcsDXGuEW",
        "row": 4249,
        "state": "COLORADO",
        "group": "3344413",
        "group_num": [
            "recDsTStVM1cIoN7C"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3740,
        "2024": 0,
        "id": "recwLWaRcm0JKQMU1",
        "row": 4258,
        "state": "COLORADO",
        "group": "3344673",
        "group_num": [
            "recsZ8Yw5iRafqJ9h"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2840,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxVyhhNk493l8tI",
        "row": 4289,
        "state": "COLORADO",
        "group": "00359053",
        "group_num": [
            "recg5jqt2Ny5TYlrk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recxrCMg0eBXxaJTG",
        "row": 4262,
        "state": "COLORADO",
        "group": "3344739",
        "group_num": [
            "recGRps4B57CSwSEG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 905,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyxeWY1u6L5eBo2",
        "row": 4222,
        "state": "COLORADO",
        "group": "3342950",
        "group_num": [
            "recKGWogCu9L1GpZr"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 64,
        "2023": 66,
        "2024": 0,
        "id": "recy4yM4VPT4oUxLM",
        "row": 4253,
        "state": "COLORADO",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1881,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recze4J2JKa96fepY",
        "row": 4244,
        "state": "COLORADO",
        "group": "3343884",
        "group_num": [
            "recit2uqAi6tcT9N2"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1262,
        "2021": 1948,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA0TpQmsCvBLZOX",
        "row": 4230,
        "state": "COLORADO",
        "group": "3343348",
        "group_num": [
            "rec9qJ8vhxN7NQ59Y"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA6GlCiFhXMHMH5",
        "row": 4237,
        "state": "COLORADO",
        "group": "3343495",
        "group_num": [
            "recHWLb40CDiLUN8e"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2454,
        "2021": 3775,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recArnmBvItQdkSYm",
        "row": 4239,
        "state": "COLORADO",
        "group": "3343498",
        "group_num": [
            "rec7RkcFexmJWLmwT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 2050,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDlAFWRWiAaKaDZ",
        "row": 4266,
        "state": "COLORADO",
        "group": "00052520",
        "group_num": [
            "recZYYgHhNw7NofoA"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 936,
        "2021": 143,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recFlFaOgmQWxQDsW",
        "row": 4295,
        "state": "COLORADO",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 458,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGu7z27fJvE3Xhb",
        "row": 4272,
        "state": "COLORADO",
        "group": "00052665",
        "group_num": [
            "recy4e9N2ZypEecyL"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 212,
        "2022": 281,
        "2023": 280,
        "2024": 0,
        "id": "recIWY057onrQGgtS",
        "row": 4232,
        "state": "COLORADO",
        "group": "3343452",
        "group_num": [
            "rec7dwYfvNYyUajH5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recILarBPIYfdnwSU",
        "row": 4225,
        "state": "COLORADO",
        "group": "3342993",
        "group_num": [
            "recLnZ3CpkR8ZlDbK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 0,
        "2024": 0,
        "id": "recIhyk73vOrnttac",
        "row": 4259,
        "state": "COLORADO",
        "group": "3344685",
        "group_num": [
            "recqrWAbHhTksuMf3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 135,
        "2021": 147,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKeZVniYwtiRnje",
        "row": 4217,
        "state": "COLORADO",
        "group": "3342629",
        "group_num": [
            "recNGQr88LhDdIwVL"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 302,
        "2023": 0,
        "2024": 0,
        "id": "recLs5vcGWy9KWkJv",
        "row": 4226,
        "state": "COLORADO",
        "group": "3343052",
        "group_num": [
            "recSD4G0E4TBy9X0z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece5zDQiugWdsGUn",
        "row": 4349,
        "state": "COLORADO",
        "group": "00616360",
        "group_num": [
            "rechOja9nqfe1KSrK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rece8y0IfEW0LgVEP",
        "row": 4326,
        "state": "COLORADO",
        "group": "00612341",
        "group_num": [
            "recFrbLG8G4Q5KLOO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 392,
        "2023": 0,
        "2024": 0,
        "id": "recfCvOyOFDw5OsFR",
        "row": 4314,
        "state": "COLORADO",
        "group": "00609947",
        "group_num": [
            "rec47TLf65bheuYLZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci2vmp3fV5z8R0W",
        "row": 4330,
        "state": "COLORADO",
        "group": "00612393",
        "group_num": [
            "recfDN85PhTlrIOH9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 60,
        "2024": 0,
        "id": "reci5JJvPl1iILILh",
        "row": 4328,
        "state": "COLORADO",
        "group": "00612341",
        "group_num": [
            "recFrbLG8G4Q5KLOO"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1080,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj3feHGmRiWtv0Z",
        "row": 4347,
        "state": "COLORADO",
        "group": "00615209",
        "group_num": [
            "rec0ZJdY5Kp6ZNPfU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 870,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclGBLXNYACgC8rl",
        "row": 4344,
        "state": "COLORADO",
        "group": "00614407",
        "group_num": [
            "recyPKUpdSL1xEekt"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 665,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmYdPumw6wp8kX0",
        "row": 4319,
        "state": "COLORADO",
        "group": "00611875",
        "group_num": [
            "reckn5fb5wWA2dGng"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 274,
        "2021": 207,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOVHO1v6k8nZEZZ",
        "row": 4306,
        "state": "COLORADO",
        "group": "00606939",
        "group_num": [
            "reciADbUeAJflQ1Eb"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 20,
        "2023": 22,
        "2024": 0,
        "id": "recRlAU4OF4VgqBho",
        "row": 4322,
        "state": "COLORADO",
        "group": "00611913",
        "group_num": [
            "recAAM9JSk2lyUpuA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 717,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUi7FmoQ7salSpC",
        "row": 4310,
        "state": "COLORADO",
        "group": "00607494",
        "group_num": [
            "recl8gxEJkmaUChAh"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 4283,
        "2021": 1982,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWaffpLD0swA5Hm",
        "row": 4334,
        "state": "COLORADO",
        "group": "00612683",
        "group_num": [
            "recsJy8KuQP0ld8zv"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recZ1R64LQzoGEwuZ",
        "row": 4339,
        "state": "COLORADO",
        "group": "00613215",
        "group_num": [
            "recKi1Jaj8PXsqMxm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3784,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZE16V5W0sHcZyB",
        "row": 4324,
        "state": "COLORADO",
        "group": "00612052",
        "group_num": [
            "rec59NKfLZqlrSVsS"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 870,
        "2022": 742,
        "2023": 0,
        "2024": 0,
        "id": "rec5CM3OrqLSoaW20",
        "row": 4308,
        "state": "COLORADO",
        "group": "00607117",
        "group_num": [
            "recZXK4W4dKBZYp4c"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 823,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7V02LzDh8E3fXb",
        "row": 4311,
        "state": "COLORADO",
        "group": "00607494",
        "group_num": [
            "recl8gxEJkmaUChAh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3191,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7ucd1BVR0Hqnmi",
        "row": 4335,
        "state": "COLORADO",
        "group": "00612683",
        "group_num": [
            "recsJy8KuQP0ld8zv"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 22,
        "2024": 0,
        "id": "rec8WHvnTDA0eqdZD",
        "row": 4315,
        "state": "COLORADO",
        "group": "00609947",
        "group_num": [
            "rec47TLf65bheuYLZ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 273,
        "2023": 0,
        "2024": 0,
        "id": "rec9whdmnr6rbiF63",
        "row": 4346,
        "state": "COLORADO",
        "group": "00614770",
        "group_num": [
            "recq3Rz3YIE000504"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 192,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaVipGTyO6BxE0K",
        "row": 4350,
        "state": "COLORADO",
        "group": "00616360",
        "group_num": [
            "rechOja9nqfe1KSrK"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "recax7yhN2XUbb1d8",
        "row": 4304,
        "state": "COLORADO",
        "group": "00606450",
        "group_num": [
            "recXYDk0qoN3xypMy"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 649,
        "2023": 0,
        "2024": 0,
        "id": "recoTGqLRQbSIsamC",
        "row": 4321,
        "state": "COLORADO",
        "group": "00611913",
        "group_num": [
            "recAAM9JSk2lyUpuA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 571,
        "2024": 0,
        "id": "reco6MkT67fTd0MEe",
        "row": 4340,
        "state": "COLORADO",
        "group": "00613215",
        "group_num": [
            "recKi1Jaj8PXsqMxm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 759,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqIbnmGjzXd3uvr",
        "row": 4302,
        "state": "COLORADO",
        "group": "00606420",
        "group_num": [
            "recNZVqN2gRPXmejP"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recq9RuHArzvXGceS",
        "row": 4327,
        "state": "COLORADO",
        "group": "00612341",
        "group_num": [
            "recFrbLG8G4Q5KLOO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recqmFnD0AjWrfSu7",
        "row": 4336,
        "state": "COLORADO",
        "group": "00612890",
        "group_num": [
            "recY6tkZ2rupU8qkY"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuCZXcE6weT5QqO",
        "row": 4348,
        "state": "COLORADO",
        "group": "00615741",
        "group_num": [
            "recofcJl6JPbfhIX8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 747,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvTHol6hI0qQ3z1",
        "row": 4317,
        "state": "COLORADO",
        "group": "00610077",
        "group_num": [
            "recJRPUjxmSNskUZJ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recvhQ2Er7lpl9rnN",
        "row": 4345,
        "state": "COLORADO",
        "group": "00614770",
        "group_num": [
            "recq3Rz3YIE000504"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 594,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recww5n99emF3MxWw",
        "row": 4331,
        "state": "COLORADO",
        "group": "00612393",
        "group_num": [
            "recfDN85PhTlrIOH9"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recCFC2mtD6gckLEP",
        "row": 4337,
        "state": "COLORADO",
        "group": "00613147",
        "group_num": [
            "recuVfEgtjUOKcF83"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 758,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recErgGOcKfriGXL1",
        "row": 4341,
        "state": "COLORADO",
        "group": "00614149",
        "group_num": [
            "recC8uyqwQNzqJp8v"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recGabik6F1NzMNXK",
        "row": 4313,
        "state": "COLORADO",
        "group": "00608338",
        "group_num": [
            "reczdcH8QPeB4swfd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recHJ65qqddp8gcdm",
        "row": 4343,
        "state": "COLORADO",
        "group": "00614180",
        "group_num": [
            "recSPY7kmGgenEtXb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1931,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH2Iz2ELOCMQbwQ",
        "row": 4325,
        "state": "COLORADO",
        "group": "00612052",
        "group_num": [
            "rec59NKfLZqlrSVsS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 955,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM5kKaWJ0bVCZYx",
        "row": 4332,
        "state": "COLORADO",
        "group": "00612393",
        "group_num": [
            "recfDN85PhTlrIOH9"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1108,
        "2024": 0,
        "id": "receiPWV2sS9DbKRW",
        "row": 4352,
        "state": "COLORADO",
        "group": "00616734",
        "group_num": [
            "recNNwqUgEwu5VASf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1090,
        "2022": 1090,
        "2023": 0,
        "2024": 0,
        "id": "recekU8jPivRoMqt1",
        "row": 4389,
        "state": "COLORADO",
        "group": "00623570",
        "group_num": [
            "reczSsaXWPdzYlMJf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "recfGsIvd0kMLBlgk",
        "row": 4418,
        "state": "COLORADO",
        "group": "00627448",
        "group_num": [
            "reckpIJgWqCkpaZoL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 594,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgJQQj7PClpXzft",
        "row": 4448,
        "state": "COLORADO",
        "group": "00631255",
        "group_num": [
            "recqFCga4O9UztEa4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 310,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recizeHGFmjLsX2XV",
        "row": 4356,
        "state": "COLORADO",
        "group": "00618114",
        "group_num": [
            "recHDCLIKMlqcnI66"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 256,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjyca7CX51OSZtr",
        "row": 4405,
        "state": "COLORADO",
        "group": "00626041",
        "group_num": [
            "recbicOdIu0W7gAov"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 4154,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjKZCZ5BoOMzlb0",
        "row": 4446,
        "state": "COLORADO",
        "group": "00631255",
        "group_num": [
            "recqFCga4O9UztEa4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 1975,
        "2023": 0,
        "2024": 0,
        "id": "recjM0tD92jaTVTPq",
        "row": 4437,
        "state": "COLORADO",
        "group": "00630674",
        "group_num": [
            "reci7EzvBojy7s7rr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "reckShDYWNCH3N2iI",
        "row": 4436,
        "state": "COLORADO",
        "group": "00630674",
        "group_num": [
            "reci7EzvBojy7s7rr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "2023": 0,
        "2024": 0,
        "id": "recklTnb5PeY22diq",
        "row": 4411,
        "state": "COLORADO",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 3740,
        "2024": 0,
        "id": "recl41D5JbPxCq6wR",
        "row": 4451,
        "state": "COLORADO",
        "group": "00631683",
        "group_num": [
            "recKsNuLI24qki8qG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmugzJu0cWMeM5O",
        "row": 4402,
        "state": "COLORADO",
        "group": "00625629",
        "group_num": [
            "recwQRP62x2HL4sVl"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmNzXMBjhpFUumx",
        "row": 4384,
        "state": "COLORADO",
        "group": "00622722",
        "group_num": [
            "recXLVUHnIotAdeaF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1962,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmj5hO4coO2jkcv",
        "row": 4450,
        "state": "COLORADO",
        "group": "00631529",
        "group_num": [
            "recA3uydNbzWLu7CE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recnc3avV1JeYN2dr",
        "row": 4443,
        "state": "COLORADO",
        "group": "00631032",
        "group_num": [
            "recHJ0M0XLPnfE0XV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 0,
        "2024": 0,
        "id": "recRnXn1SX6RDk63o",
        "row": 4440,
        "state": "COLORADO",
        "group": "00630845",
        "group_num": [
            "recIkBudHCPKzsGxM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1903,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT5799GajeKQhN5",
        "row": 4388,
        "state": "COLORADO",
        "group": "00623493",
        "group_num": [
            "recw1ZqKyAmsl0Hhs"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3621,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVoCUjUyxNtOlWc",
        "row": 4390,
        "state": "COLORADO",
        "group": "00623570",
        "group_num": [
            "reczSsaXWPdzYlMJf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recWICfXrXid9Bzn6",
        "row": 4404,
        "state": "COLORADO",
        "group": "00626028",
        "group_num": [
            "recx58GUrtyewKWeI"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 901,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXug6O9ZXoxLHLR",
        "row": 4359,
        "state": "COLORADO",
        "group": "00619013",
        "group_num": [
            "rechXuldAuX9GWk5c"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 2053,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recXHNcjaqQ6Wg3AL",
        "row": 4395,
        "state": "COLORADO",
        "group": "00624449",
        "group_num": [
            "recJNQgKJbj99mAYO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2053,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYcGGIb2XhviQnV",
        "row": 4401,
        "state": "COLORADO",
        "group": "00625265",
        "group_num": [
            "recaLstJYpMqnRcQO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYqySalgD5h0Wlv",
        "row": 4386,
        "state": "COLORADO",
        "group": "00623221",
        "group_num": [
            "recvdTbjHaLFOFR4Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZSgfw6x7X6RQxf",
        "row": 4398,
        "state": "COLORADO",
        "group": "00624847",
        "group_num": [
            "reciHZNiskN0YhzB7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 531,
        "2023": 0,
        "2024": 0,
        "id": "recZfE1QrJBYtYiED",
        "row": 4376,
        "state": "COLORADO",
        "group": "00622445",
        "group_num": [
            "recRsuol5KIkAg99Q"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 554,
        "2024": 0,
        "id": "rec0xplxca7gwru5o",
        "row": 4410,
        "state": "COLORADO",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 924,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0NxV4Dd8UUFfPU",
        "row": 4373,
        "state": "COLORADO",
        "group": "00621030",
        "group_num": [
            "reciVFcYbi4cn50zK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "2024": 0,
        "id": "rec08uanlpZ3SY4Fl",
        "row": 4353,
        "state": "COLORADO",
        "group": "00617593",
        "group_num": [
            "recgDrNJqUMPV7wC8"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "2024": 0,
        "id": "rec1ApEKPkUjSUt9K",
        "row": 4369,
        "state": "COLORADO",
        "group": "00620879",
        "group_num": [
            "recylSdKsYHA9GmrH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 827,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "rec2KJNbtMSQ5EsIS",
        "row": 4420,
        "state": "COLORADO",
        "group": "00627856",
        "group_num": [
            "recWBQCgFRoE9jii6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 263,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2lEnJZj6qGmxHz",
        "row": 4428,
        "state": "COLORADO",
        "group": "00628651",
        "group_num": [
            "recbg9nI5NjglqC67"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 296,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3ltNr1cBFx26rq",
        "row": 4432,
        "state": "COLORADO",
        "group": "00629966",
        "group_num": [
            "recq71O7sWeuZTd1M"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6rPPew5mTyCy0W",
        "row": 4387,
        "state": "COLORADO",
        "group": "00623493",
        "group_num": [
            "recw1ZqKyAmsl0Hhs"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7xn0zuK2NaLlS0",
        "row": 4444,
        "state": "COLORADO",
        "group": "00631166",
        "group_num": [
            "rec89H3dLPAIu9Nmc"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 928,
        "2021": 1477,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7oVlS1pnwtXtju",
        "row": 4431,
        "state": "COLORADO",
        "group": "00629966",
        "group_num": [
            "recq71O7sWeuZTd1M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 890,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8ygNXkuVO7tYho",
        "row": 4419,
        "state": "COLORADO",
        "group": "00627578",
        "group_num": [
            "recwfgpy0dZ4wv1su"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 137,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8HWh1j2euxOuVk",
        "row": 4429,
        "state": "COLORADO",
        "group": "00628651",
        "group_num": [
            "recbg9nI5NjglqC67"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "rec8JJGNTtBC6S3LN",
        "row": 4412,
        "state": "COLORADO",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8RIUM3HbDY77pk",
        "row": 4424,
        "state": "COLORADO",
        "group": "00628342",
        "group_num": [
            "rec7Sc0mucGWXxpjj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 835,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec858FFN44aYFWpd",
        "row": 4400,
        "state": "COLORADO",
        "group": "00624865",
        "group_num": [
            "recXLhFEMgDqt5Rm5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 165,
        "2021": 761,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaKcdqRnLnfLwVX",
        "row": 4414,
        "state": "COLORADO",
        "group": "00627306",
        "group_num": [
            "recebREpB6g9wgpW0"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "2023": 0,
        "2024": 0,
        "id": "recaNkwsOcyZeq4I9",
        "row": 4377,
        "state": "COLORADO",
        "group": "00622445",
        "group_num": [
            "recRsuol5KIkAg99Q"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 895,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recagSbiPXu0bwCxS",
        "row": 4379,
        "state": "COLORADO",
        "group": "00622549",
        "group_num": [
            "recsBB4UXN6YE8H3b"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbtAY9J4pfHXzt8",
        "row": 4423,
        "state": "COLORADO",
        "group": "00628320",
        "group_num": [
            "recTm2fSqeycANP5Y"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbykSAP953FK5gY",
        "row": 4447,
        "state": "COLORADO",
        "group": "00631255",
        "group_num": [
            "recqFCga4O9UztEa4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 155,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccp85ngccKXEu1g",
        "row": 4393,
        "state": "COLORADO",
        "group": "00623571",
        "group_num": [
            "recpP7HsdGQAornZw"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 1898,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdZeL4uowNnT79M",
        "row": 4396,
        "state": "COLORADO",
        "group": "00624449",
        "group_num": [
            "recJNQgKJbj99mAYO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoguBf5yf4hWx8j",
        "row": 4360,
        "state": "COLORADO",
        "group": "00619013",
        "group_num": [
            "rechXuldAuX9GWk5c"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpdnYFRhsoGAhsG",
        "row": 4365,
        "state": "COLORADO",
        "group": "00620270",
        "group_num": [
            "recu6fQE5dW7q4dL7"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 886,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpowwCkX0dw70EW",
        "row": 4392,
        "state": "COLORADO",
        "group": "00623571",
        "group_num": [
            "recpP7HsdGQAornZw"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 0,
        "2024": 0,
        "id": "recqKIM2ctUIwZWaN",
        "row": 4426,
        "state": "COLORADO",
        "group": "00628342",
        "group_num": [
            "rec7Sc0mucGWXxpjj"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 792,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqOcVYfY1T6cc67",
        "row": 4434,
        "state": "COLORADO",
        "group": "00630035",
        "group_num": [
            "recxVWM02cgcSFv3r"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 666,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr3FSw55TaIZn8X",
        "row": 4397,
        "state": "COLORADO",
        "group": "00624449",
        "group_num": [
            "recJNQgKJbj99mAYO"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 550,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr4cewctJtKVg3L",
        "row": 4408,
        "state": "COLORADO",
        "group": "00626382",
        "group_num": [
            "reca1ZoYEqa4G581x"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recr5Kh1TG4EgVBJ6",
        "row": 4425,
        "state": "COLORADO",
        "group": "00628342",
        "group_num": [
            "rec7Sc0mucGWXxpjj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "2024": 0,
        "id": "rectEmKdrJdnPZh42",
        "row": 4371,
        "state": "COLORADO",
        "group": "00620921",
        "group_num": [
            "recf3JSUtjOasKlUO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recuy9eBbjEwJTsSe",
        "row": 4409,
        "state": "COLORADO",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 180,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recufReMGpMeIcYwr",
        "row": 4415,
        "state": "COLORADO",
        "group": "00627306",
        "group_num": [
            "recebREpB6g9wgpW0"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recun6FHGUbIecpvx",
        "row": 4417,
        "state": "COLORADO",
        "group": "00627448",
        "group_num": [
            "reckpIJgWqCkpaZoL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 3746,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvDec4vixEjHdut",
        "row": 4382,
        "state": "COLORADO",
        "group": "00622669",
        "group_num": [
            "recxB9uwXtP23XC17"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 133,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvHfZy49WWwINu1",
        "row": 4374,
        "state": "COLORADO",
        "group": "00621030",
        "group_num": [
            "reciVFcYbi4cn50zK"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 85,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwHv87GDeXAm5ZN",
        "row": 4449,
        "state": "COLORADO",
        "group": "00631477",
        "group_num": [
            "reco8zAuCuPDMf1m0"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1131,
        "2023": 0,
        "2024": 0,
        "id": "recxWQLNFVU9NkWAg",
        "row": 4427,
        "state": "COLORADO",
        "group": "00628511",
        "group_num": [
            "recUAfZQTHVVr3tBc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 816,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBudhcubyZ81gbm",
        "row": 4355,
        "state": "COLORADO",
        "group": "00618114",
        "group_num": [
            "recHDCLIKMlqcnI66"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recB3C4H6BNatp7be",
        "row": 4362,
        "state": "COLORADO",
        "group": "00619731",
        "group_num": [
            "recXQEILlu9TCdWlq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 60,
        "2024": 0,
        "id": "recCAa3asNwY989PX",
        "row": 4441,
        "state": "COLORADO",
        "group": "00630845",
        "group_num": [
            "recIkBudHCPKzsGxM"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 839,
        "2021": 510,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC80SX5vDbKTQun",
        "row": 4380,
        "state": "COLORADO",
        "group": "00622549",
        "group_num": [
            "recsBB4UXN6YE8H3b"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCkpVSByWeCN2Nz",
        "row": 4383,
        "state": "COLORADO",
        "group": "00622722",
        "group_num": [
            "recXLVUHnIotAdeaF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 962,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD6sDtzfOw0Ixib",
        "row": 4406,
        "state": "COLORADO",
        "group": "00626156",
        "group_num": [
            "recs4WRYXaKdHGAzt"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 203,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEBtkMUbt9ZV1jB",
        "row": 4435,
        "state": "COLORADO",
        "group": "00630035",
        "group_num": [
            "recxVWM02cgcSFv3r"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 338,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recF1gnxIb5HINnOY",
        "row": 4366,
        "state": "COLORADO",
        "group": "00620270",
        "group_num": [
            "recu6fQE5dW7q4dL7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "2023": 0,
        "2024": 0,
        "id": "recHLDpTWyzMqCtxE",
        "row": 4421,
        "state": "COLORADO",
        "group": "00627856",
        "group_num": [
            "recWBQCgFRoE9jii6"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recIknCKgQ7zSu5lu",
        "row": 4391,
        "state": "COLORADO",
        "group": "00623571",
        "group_num": [
            "recpP7HsdGQAornZw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2215,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK5r1Cf1p7o6LhY",
        "row": 4364,
        "state": "COLORADO",
        "group": "00619905",
        "group_num": [
            "recM0tmSlFZ1rdMvM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1843,
        "2024": 0,
        "id": "recL6tPl43gHFl6hR",
        "row": 4358,
        "state": "COLORADO",
        "group": "00618737",
        "group_num": [
            "recrNVywqP4saSYcZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1972,
        "2024": 0,
        "id": "recM8riWDLd1DALE9",
        "row": 4368,
        "state": "COLORADO",
        "group": "00620532",
        "group_num": [
            "rec0hP6pycKKOvWCx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 761,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMjaG6ZnPdeiHsh",
        "row": 4438,
        "state": "COLORADO",
        "group": "00630700",
        "group_num": [
            "recbqOp54oWqSufhI"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 0,
        "2024": 0,
        "id": "recgUv1wo8ATWXBEw",
        "row": 4483,
        "state": "COLORADO",
        "group": "CRPTEXC",
        "group_num": [
            "recunocTr5sPz9Yf9"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 933,
        "2021": 482,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck10Sp0Dx2gaKrQ",
        "row": 4470,
        "state": "COLORADO",
        "group": "33473452",
        "group_num": [
            "rec9fnitI46XyGUoe"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1934,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckkHamUaInKc7iD",
        "row": 4453,
        "state": "COLORADO",
        "group": "00631873",
        "group_num": [
            "recVRq7Oz22UQQ3lU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 893,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclgubutBT1T4Xya",
        "row": 4477,
        "state": "COLORADO",
        "group": "ALVC341",
        "group_num": [
            "recRwD31n9SVF4gSl"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 1895,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmGwDN6CYniFjlo",
        "row": 4492,
        "state": "COLORADO",
        "group": "SH404",
        "group_num": [
            "rec4Pb8Xgew7yfHg4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 852,
        "2023": 0,
        "2024": 0,
        "id": "recOnmFoCkR1V6LWm",
        "row": 4460,
        "state": "COLORADO",
        "group": "00634446",
        "group_num": [
            "recy1eXq2LKZhqBn2"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2625,
        "2021": 0,
        "2022": 589,
        "2023": 0,
        "2024": 0,
        "id": "recQSmk5LTKKjVfQQ",
        "row": 4473,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 22,
        "2024": 0,
        "id": "recS1OKlPNEuzfqxp",
        "row": 4464,
        "state": "COLORADO",
        "group": "00636468",
        "group_num": [
            "rec69rRuQfK77kwPP"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 701,
        "2022": 306,
        "2023": 283,
        "2024": 0,
        "id": "recSpVUrN3wwBPKm9",
        "row": 4466,
        "state": "COLORADO",
        "group": "00880800",
        "group_num": [
            "recHOrSdwtSyOLPCu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "2024": 0,
        "id": "recTKSmAJ6oy2emdQ",
        "row": 4458,
        "state": "COLORADO",
        "group": "00634311",
        "group_num": [
            "recnEYG2iUATH5u8X"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 2175,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVWOVPvnVJ4r10h",
        "row": 4485,
        "state": "COLORADO",
        "group": "H870685",
        "group_num": [
            "recBR5uI0moOlzZc3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1056,
        "2021": 847,
        "2022": 1305,
        "2023": 0,
        "2024": 0,
        "id": "recVvDez4yZ4UBQzn",
        "row": 4489,
        "state": "COLORADO",
        "group": "S2860",
        "group_num": [
            "recQMl5Q9iQbEGwJz"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 653,
        "2021": 825,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVJ1JcsAlGOJnhK",
        "row": 4490,
        "state": "COLORADO",
        "group": "S2860",
        "group_num": [
            "recQMl5Q9iQbEGwJz"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXIyR7QZQaCe8sW",
        "row": 4481,
        "state": "COLORADO",
        "group": "C434426",
        "group_num": [
            "recR68x5PV9OR2eWd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "recYQR2nWd3b0CshR",
        "row": 4461,
        "state": "COLORADO",
        "group": "00634446",
        "group_num": [
            "recy1eXq2LKZhqBn2"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 941,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ2vZ6kBA8torLX",
        "row": 4475,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 645,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1TYR5cv9nJE0Jx",
        "row": 4478,
        "state": "COLORADO",
        "group": "ALVC341",
        "group_num": [
            "recRwD31n9SVF4gSl"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 419,
        "2023": 290,
        "2024": 0,
        "id": "rec2Tz3t9AIUhSzgm",
        "row": 4482,
        "state": "COLORADO",
        "group": "CRPTEXC",
        "group_num": [
            "recunocTr5sPz9Yf9"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3889,
        "2022": 394,
        "2023": 0,
        "2024": 0,
        "id": "rec7CBD5hxxA3GIIz",
        "row": 4479,
        "state": "COLORADO",
        "group": "APWU00",
        "group_num": [
            "rec8BBDrRb038Hloa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3733,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8URcu7F25CqoRA",
        "row": 4495,
        "state": "COLORADO",
        "group": "WBP",
        "group_num": [
            "recZg55lGwr240OYy"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 4670,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoBQM368naWAwY9",
        "row": 4480,
        "state": "COLORADO",
        "group": "APWU00",
        "group_num": [
            "rec8BBDrRb038Hloa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "recqvbrQ8KYw55Rj3",
        "row": 4455,
        "state": "COLORADO",
        "group": "00632673",
        "group_num": [
            "recLSGchoEWS8djuK"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 590,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvptDg0oaz7ohNb",
        "row": 4469,
        "state": "COLORADO",
        "group": "33473452",
        "group_num": [
            "rec9fnitI46XyGUoe"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 355,
        "2021": 70,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyNloJdTIyrHgqe",
        "row": 4493,
        "state": "COLORADO",
        "group": "SH404",
        "group_num": [
            "rec4Pb8Xgew7yfHg4"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 1571,
        "2021": 165,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recAPAHKYwbu56rLS",
        "row": 4474,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA41vGlAds0u28F",
        "row": 4452,
        "state": "COLORADO",
        "group": "00631873",
        "group_num": [
            "recVRq7Oz22UQQ3lU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recAfRkgvDXxESNs2",
        "row": 4462,
        "state": "COLORADO",
        "group": "00635449",
        "group_num": [
            "recsAuJsTw2A3zI0K"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2342,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEdJQnnhzrKQA5l",
        "row": 4487,
        "state": "COLORADO",
        "group": "P24",
        "group_num": [
            "recbWx8EwOsrfLC0X"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 621,
        "2023": 0,
        "2024": 0,
        "id": "recF15pJqnYIXw4sq",
        "row": 4454,
        "state": "COLORADO",
        "group": "00632673",
        "group_num": [
            "recLSGchoEWS8djuK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 555,
        "2023": 0,
        "2024": 0,
        "id": "recFvI8Sypzoa2XML",
        "row": 4463,
        "state": "COLORADO",
        "group": "00636468",
        "group_num": [
            "rec69rRuQfK77kwPP"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1993,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recKxDP2dpCRomjF2",
        "row": 4472,
        "state": "COLORADO",
        "group": "006311365",
        "group_num": [
            "recyuXUU9gbgO0ki2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 0,
        "2024": 0,
        "id": "recKPYqSGSxdpqx1U",
        "row": 4457,
        "state": "COLORADO",
        "group": "00633915",
        "group_num": [
            "recDSs2D9klVUr4nz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1088,
        "2022": 59,
        "2023": 0,
        "2024": 0,
        "id": "recKnrzQZ2YA9dQHw",
        "row": 4467,
        "state": "COLORADO",
        "group": "00880800",
        "group_num": [
            "recHOrSdwtSyOLPCu"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 214,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnUOImum5pnRUWV",
        "row": 4496,
        "state": "COLORADO",
        "group": "10107218",
        "group_num": [
            "recjGRWf2O0wsuIoy"
        ],
        "procedure": "IOP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 367,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9Wh6cZ0yLl2mum",
        "row": 4499,
        "state": "COLORADO",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "PHP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 214,
        "2021": 214,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "reczJTB5QKPGqEkr9",
        "row": 4500,
        "state": "COLORADO",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "IOP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 520,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCkKTnk9Ca8fItF",
        "row": 4498,
        "state": "COLORADO",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "RTC",
        "provider": "COMPSYCH"
    },
    {
        "2020": 708,
        "2021": 796,
        "2022": 1851,
        "2023": 0,
        "2024": 0,
        "id": "recHW9FsoJZ65kjUX",
        "row": 4497,
        "state": "COLORADO",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 44,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKdykjzrSbstsxK",
        "row": 4501,
        "state": "COLORADO",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "OP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 520,
        "2023": 0,
        "2024": 0,
        "id": "recmrHt112H7yQNxi",
        "row": 4504,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 796,
        "2022": 796,
        "2023": 0,
        "2024": 0,
        "id": "recvpwa4xSDrzjEM8",
        "row": 4503,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWgBSvjs2uOFnca",
        "row": 4506,
        "state": "COLORADO",
        "group": "4005",
        "group_num": [
            "recPD9ZeVI2puWNuX"
        ],
        "procedure": "IOP",
        "provider": "CORPORATE BENEFIT SERVICES"
    },
    {
        "2020": 349,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recF8SWAPv1GE70KS",
        "row": 4505,
        "state": "COLORADO",
        "group": "4005",
        "group_num": [
            "recPD9ZeVI2puWNuX"
        ],
        "procedure": "PHP",
        "provider": "CORPORATE BENEFIT SERVICES"
    },
    {
        "2020": 0,
        "2021": 1334,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMBL3lscwYrtruA",
        "row": 4508,
        "state": "COLORADO",
        "group": "P29",
        "group_num": [
            "recROZ0zCqkFu1bia"
        ],
        "procedure": "Detox",
        "provider": "CYPRESS BENEFIT ADMINISTRATORS"
    },
    {
        "2020": 2494,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgsmVD3Brsc81tE",
        "row": 4509,
        "state": "COLORADO",
        "group": "DD10028",
        "group_num": [
            "recgZQUwA5EaHjhqX"
        ],
        "procedure": "PHP",
        "provider": "DAKOTA CARE"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOTyQmiuSpQ9jxS",
        "row": 4510,
        "state": "COLORADO",
        "group": "DD10028",
        "group_num": [
            "recgZQUwA5EaHjhqX"
        ],
        "procedure": "IOP",
        "provider": "DAKOTA CARE"
    },
    {
        "2020": 882,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAjRR0o2JMeKp8j",
        "row": 4511,
        "state": "COLORADO",
        "group": "DD10028",
        "group_num": [
            "recgZQUwA5EaHjhqX"
        ],
        "procedure": "OP",
        "provider": "DAKOTA CARE"
    },
    {
        "2020": 0,
        "2021": 319,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr4LbK2AV8q5ReZ",
        "row": 4513,
        "state": "COLORADO",
        "group": "158752",
        "group_num": [
            "recGggPXaLT8ZIzWN"
        ],
        "procedure": "IOP",
        "provider": "DELTA HEALTH SYSTEMS"
    },
    {
        "2020": 111,
        "2021": 111,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHTzb6nHwTByPKS",
        "row": 4514,
        "state": "COLORADO",
        "group": "158752",
        "group_num": [
            "recGggPXaLT8ZIzWN"
        ],
        "procedure": "OP",
        "provider": "DELTA HEALTH SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 895,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recInrPRg2z9oeHSk",
        "row": 4516,
        "state": "COLORADO",
        "group": "CIHMO21",
        "group_num": [
            "recNVxhXinSgvkrHN"
        ],
        "procedure": "IOP",
        "provider": "DENVER HEALTH MEDICAL PLAN"
    },
    {
        "2020": 843,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf1ngZ21nzxbFyO",
        "row": 4520,
        "state": "COLORADO",
        "group": "0000524",
        "group_num": [
            "recdbBHnWMtHJGNRR"
        ],
        "procedure": "PHP",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 87,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechvbFg8Ofvp3a2M",
        "row": 4519,
        "state": "COLORADO",
        "group": "0000284",
        "group_num": [
            "rec3SKnNURizOdnFx"
        ],
        "procedure": "IOP",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 893,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recw5ldBrNoSNDdP4",
        "row": 4521,
        "state": "COLORADO",
        "group": "0000524",
        "group_num": [
            "recdbBHnWMtHJGNRR"
        ],
        "procedure": "IOP",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 1523,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLn3fBg5WVqufZi",
        "row": 4518,
        "state": "COLORADO",
        "group": "0000225",
        "group_num": [
            "recavhIGqGP3OHkH3"
        ],
        "procedure": "IOP",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 1651,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclTwIBjju68kIud",
        "row": 4524,
        "state": "COLORADO",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "GEHA"
    },
    {
        "2020": 1213,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9Mwxddyw6pEG45",
        "row": 4523,
        "state": "COLORADO",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "Detox",
        "provider": "GEHA"
    },
    {
        "2020": 360,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIpXsF0MM9l13QF",
        "row": 4525,
        "state": "COLORADO",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "PHP",
        "provider": "GEHA"
    },
    {
        "2020": 900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczgPGfB5evaKos7",
        "row": 4526,
        "state": "COLORADO",
        "group": "Y00002636",
        "group_num": [
            "rec89uNHR728ncVUC"
        ],
        "procedure": "Detox",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 0,
        "2021": 2491,
        "2022": 2200,
        "2023": 0,
        "2024": 0,
        "id": "recawIkhYvB0RbLyS",
        "row": 4527,
        "state": "COLORADO",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 2095,
        "2022": 11372,
        "2023": 0,
        "2024": 0,
        "id": "recvNnzo5QoZSCQvF",
        "row": 4528,
        "state": "COLORADO",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "RTC",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 572,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGlSYFQpiPWvQmA",
        "row": 4529,
        "state": "COLORADO",
        "group": "705214",
        "group_num": [
            "recS81ysw30m7zA6f"
        ],
        "procedure": "OP",
        "provider": "GOLDEN RULE"
    },
    {
        "2020": 0,
        "2021": 549,
        "2022": 234,
        "2023": 0,
        "2024": 0,
        "id": "recK2Jic6WpNODwKP",
        "row": 4533,
        "state": "COLORADO",
        "group": "1400",
        "group_num": [
            "recu06l9T9VDdKtpN"
        ],
        "procedure": "Detox",
        "provider": "HEALTH ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 551,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck6U234B2XzY8rW",
        "row": 4534,
        "state": "COLORADO",
        "group": "60164",
        "group_num": [
            "recI5JCXsqyGihyY4"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 110,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoymbfYpioAkBNg",
        "row": 4537,
        "state": "COLORADO",
        "group": "0180961103",
        "group_num": [
            "recbe0bOLsSlW2HE2"
        ],
        "procedure": "OP",
        "provider": "HEALTH PLAN"
    },
    {
        "2020": 335,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recscQZNewgj5gNjO",
        "row": 4536,
        "state": "COLORADO",
        "group": "0180961103",
        "group_num": [
            "recbe0bOLsSlW2HE2"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfLJh3rIbkF1bBe",
        "row": 4539,
        "state": "COLORADO",
        "group": "WB9",
        "group_num": [
            "recoxik0stBGWylR6"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 66,
        "2023": 0,
        "2024": 0,
        "id": "recAFGB4ddbY8a5tk",
        "row": 4540,
        "state": "COLORADO",
        "group": "WB9",
        "group_num": [
            "recoxik0stBGWylR6"
        ],
        "procedure": "OP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 2488,
        "2022": 4143,
        "2023": 0,
        "2024": 0,
        "id": "reckrJxTFLII68Fro",
        "row": 4546,
        "state": "COLORADO",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "RTC",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 102,
        "2021": 66,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWlQWsQRBQwxNI4",
        "row": 4543,
        "state": "COLORADO",
        "group": "ALPIN",
        "group_num": [
            "recXmJ6GsZ98SKaNe"
        ],
        "procedure": "OP",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 101,
        "2021": 167,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5QG3rhYXshbEpZ",
        "row": 4542,
        "state": "COLORADO",
        "group": "ALPIN",
        "group_num": [
            "recXmJ6GsZ98SKaNe"
        ],
        "procedure": "IOP",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 3781,
        "2022": 3600,
        "2023": 0,
        "2024": 0,
        "id": "recJi6YOZWtl81Cui",
        "row": 4545,
        "state": "COLORADO",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "Detox",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 710,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmWkJRNdRHRsn6M",
        "row": 4547,
        "state": "COLORADO",
        "group": "ZCP005",
        "group_num": [
            "recn0rd4zMnXtpXpM"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 163,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9yR5slvYkXokOH",
        "row": 4548,
        "state": "COLORADO",
        "group": "ZCP005",
        "group_num": [
            "recn0rd4zMnXtpXpM"
        ],
        "procedure": "OP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "2023": 855,
        "2024": 0,
        "id": "rec84vyZcdAifF990",
        "row": 4550,
        "state": "COLORADO",
        "group": "400143",
        "group_num": [
            "recX4jdA5XnkxL2PM"
        ],
        "procedure": "RTC",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "2023": 0,
        "2024": 0,
        "id": "recg4fy1WAo3myrRy",
        "row": 4554,
        "state": "COLORADO",
        "group": "685622",
        "group_num": [
            "recENmPNCL4WwaNqt"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 358,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckcoxfFbk78ncJS",
        "row": 4581,
        "state": "COLORADO",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 702,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclGCW5lW9fuRcsY",
        "row": 4555,
        "state": "COLORADO",
        "group": "732176",
        "group_num": [
            "recTkqobpy8NlvqUf"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 926,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmZo3zjzoKl15Do",
        "row": 4576,
        "state": "COLORADO",
        "group": "842756",
        "group_num": [
            "reccFTbhzcieF0LBO"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 4566,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmJ4naR5YxzPMjs",
        "row": 4579,
        "state": "COLORADO",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 358,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recStfFkbT67J4YV5",
        "row": 4552,
        "state": "COLORADO",
        "group": "611895",
        "group_num": [
            "reccAFbW3EyiSMQwW"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "2023": 0,
        "2024": 0,
        "id": "recShyPkgD15gSe0g",
        "row": 4564,
        "state": "COLORADO",
        "group": "766721",
        "group_num": [
            "recrsyBbftjm8jsyI"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 259,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3jEG76FBxzKyE6",
        "row": 4577,
        "state": "COLORADO",
        "group": "842756",
        "group_num": [
            "reccFTbhzcieF0LBO"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 926,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6VFEFL8yRvDnjE",
        "row": 4575,
        "state": "COLORADO",
        "group": "842756",
        "group_num": [
            "reccFTbhzcieF0LBO"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 926,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6dIJayQaWd3osE",
        "row": 4566,
        "state": "COLORADO",
        "group": "789442",
        "group_num": [
            "recPpNnCfScurs6em"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 623,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7i6RD60BUkOS0r",
        "row": 4557,
        "state": "COLORADO",
        "group": "742884",
        "group_num": [
            "recpc8xBXOQBsXEUf"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 743,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7itGgWqyscwugv",
        "row": 4561,
        "state": "COLORADO",
        "group": "747945",
        "group_num": [
            "recaOkhCcMOcfYgPw"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 4922,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9lXonWuJSeRbyV",
        "row": 4578,
        "state": "COLORADO",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 358,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc5khuZyPJG9qPp",
        "row": 4562,
        "state": "COLORADO",
        "group": "747945",
        "group_num": [
            "recaOkhCcMOcfYgPw"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "2023": 1160,
        "2024": 0,
        "id": "recrPtWEhnTJuWmrj",
        "row": 4573,
        "state": "COLORADO",
        "group": "827328",
        "group_num": [
            "recjUsjv6nSuIYmUJ"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 715,
        "2021": 715,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recttOSAXmUEPl6VA",
        "row": 4551,
        "state": "COLORADO",
        "group": "611895",
        "group_num": [
            "reccAFbW3EyiSMQwW"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 715,
        "2023": 715,
        "2024": 0,
        "id": "recv2w1dTWuzFYQnI",
        "row": 4574,
        "state": "COLORADO",
        "group": "827328",
        "group_num": [
            "recjUsjv6nSuIYmUJ"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 89,
        "2022": 45,
        "2023": 126,
        "2024": 0,
        "id": "recxm56dGM8EZ8eOL",
        "row": 4569,
        "state": "COLORADO",
        "group": "808007",
        "group_num": [
            "recdKx1kjUy52UFjw"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 715,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recypon6QJCvWjtnP",
        "row": 4570,
        "state": "COLORADO",
        "group": "820388",
        "group_num": [
            "recRa7PMyYJss3IyD"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 715,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczsSmsqsmZWBiXR",
        "row": 4572,
        "state": "COLORADO",
        "group": "822652",
        "group_num": [
            "rec0DPrs1p8S0ey3i"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 715,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAykGI2uWddGZQy",
        "row": 4580,
        "state": "COLORADO",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 612,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCVzxXjHKP6L6MB",
        "row": 4560,
        "state": "COLORADO",
        "group": "747757",
        "group_num": [
            "recHh3dCZRgwl1jlg"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 132,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCyplvI8qPzXq9H",
        "row": 4558,
        "state": "COLORADO",
        "group": "742884",
        "group_num": [
            "recpc8xBXOQBsXEUf"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 926,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDKYOq4dENhzyM1",
        "row": 4565,
        "state": "COLORADO",
        "group": "789442",
        "group_num": [
            "recPpNnCfScurs6em"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 702,
        "2022": 702,
        "2023": 0,
        "2024": 0,
        "id": "recG5GtCHzW6Swfxa",
        "row": 4568,
        "state": "COLORADO",
        "group": "808007",
        "group_num": [
            "recdKx1kjUy52UFjw"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 33,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLBXBJSt4yejDX9",
        "row": 4567,
        "state": "COLORADO",
        "group": "789442",
        "group_num": [
            "recPpNnCfScurs6em"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 4000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Mh2MOYmuZ0Z7j",
        "row": 4582,
        "state": "COLORADO",
        "group": "1009",
        "group_num": [
            "recpewX46IpNrgpSX"
        ],
        "procedure": "Detox",
        "provider": "INSURANCE MANAGEMENT SERVICES"
    },
    {
        "2020": 1114,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1F6Dq4tJWgPohX",
        "row": 4584,
        "state": "COLORADO",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 1313,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4j0BwZh6VtGG82",
        "row": 4583,
        "state": "COLORADO",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "PHP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 779,
        "2023": 779,
        "2024": 0,
        "id": "recafi41mby3D6msj",
        "row": 4585,
        "state": "COLORADO",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "rec8DrAX2M4eov8tf",
        "row": 4587,
        "state": "COLORADO",
        "group": "N55",
        "group_num": [
            "recjny2lQvGLzRrnW"
        ],
        "procedure": "IOP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 653,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf7WzDHK2pRjkYT",
        "row": 4600,
        "state": "COLORADO",
        "group": "GR008044",
        "group_num": [
            "rec639o9j0hb8w9TS"
        ],
        "procedure": "OP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 1073,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf8WgEuHBukU13U",
        "row": 4596,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recR2HQZLuOgO16RK",
        "row": 4598,
        "state": "COLORADO",
        "group": "A0000031",
        "group_num": [
            "recdJSKKs0F1nKXjV"
        ],
        "procedure": "PHP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 2375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUQWjHn3sAMnrpL",
        "row": 4593,
        "state": "COLORADO",
        "group": "76413022",
        "group_num": [
            "recLLl8HaClyiQinB"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWwpeE127BECwlB",
        "row": 4590,
        "state": "COLORADO",
        "group": "10106282",
        "group_num": [
            "rec2iUXFrcK9ggRV2"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 0,
        "2024": 0,
        "id": "recZVpqlOovDxEx2U",
        "row": 4591,
        "state": "COLORADO",
        "group": "10106282",
        "group_num": [
            "rec2iUXFrcK9ggRV2"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 1595,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1AsV71nrKWKYQc",
        "row": 4599,
        "state": "COLORADO",
        "group": "GR008044",
        "group_num": [
            "rec639o9j0hb8w9TS"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2xPtma812b3qq6",
        "row": 4592,
        "state": "COLORADO",
        "group": "76413022",
        "group_num": [
            "recLLl8HaClyiQinB"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6CelsYK73ES4ur",
        "row": 4595,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 1305,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6esJjqPalCYxtt",
        "row": 4601,
        "state": "COLORADO",
        "group": "GR009412",
        "group_num": [
            "rec11VG54zcjqy2tf"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "2023": 0,
        "2024": 0,
        "id": "rec7CWuYBqK4mdrzm",
        "row": 4597,
        "state": "COLORADO",
        "group": "A0000031",
        "group_num": [
            "recdJSKKs0F1nKXjV"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectURGHf5fvNSzvH",
        "row": 4589,
        "state": "COLORADO",
        "group": "10106282",
        "group_num": [
            "rec2iUXFrcK9ggRV2"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMnXRQIayEqGJrQ",
        "row": 4594,
        "state": "COLORADO",
        "group": "0007408074",
        "group_num": [
            "recH45zdyJ5m85L8q"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck8uExHwk8qA8QN",
        "row": 4602,
        "state": "COLORADO",
        "group": "82751",
        "group_num": [
            "rectBqgsgHKoxZC9U"
        ],
        "procedure": "OP",
        "provider": "MEDICA"
    },
    {
        "2020": 2144,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd1NBUGSTnZXkV7",
        "row": 4603,
        "state": "COLORADO",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 0,
        "2024": 0,
        "id": "reczHixRXVOM1BK0C",
        "row": 4604,
        "state": "COLORADO",
        "group": "139467003",
        "group_num": [
            "rec1moJeG2Mxu8OXT"
        ],
        "procedure": "IOP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfGgHhnL65UhJg5",
        "row": 4636,
        "state": "COLORADO",
        "group": "17642",
        "group_num": [
            "recwSHyEvjlUsk1St"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 37,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgVeG7RLO5hCypm",
        "row": 4623,
        "state": "COLORADO",
        "group": "16115",
        "group_num": [
            "rec8IXCfxFkM8sJzH"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 1318,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgLD3ZqBdN4pxCj",
        "row": 4625,
        "state": "COLORADO",
        "group": "16211",
        "group_num": [
            "recdhiIZPcc0xPzS7"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1732,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech2ppW5PN055th8",
        "row": 4610,
        "state": "COLORADO",
        "group": "02249",
        "group_num": [
            "recwVNwIrzvgtfaA4"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1443,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmzLDsfmPXMZwVD",
        "row": 4637,
        "state": "COLORADO",
        "group": "B6673",
        "group_num": [
            "recUOnVuRqc1In0Ed"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 686,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRFhWCgJS6eOlku",
        "row": 4629,
        "state": "COLORADO",
        "group": "16530",
        "group_num": [
            "recgnN0CEMZRHofRE"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1142,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS29cBWAR9Fphgy",
        "row": 4627,
        "state": "COLORADO",
        "group": "16462",
        "group_num": [
            "recG2kpxNsmFlh92V"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWmUjKLstA7lBBt",
        "row": 4619,
        "state": "COLORADO",
        "group": "15319",
        "group_num": [
            "recrli6JCY4OEDQOj"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 1096,
        "2021": 1443,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY4yhj4L4GsTEJp",
        "row": 4606,
        "state": "COLORADO",
        "group": "00490",
        "group_num": [
            "recINgeFcGz72iFia"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 1732,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0VBsz5q8cufTXE",
        "row": 4622,
        "state": "COLORADO",
        "group": "15576",
        "group_num": [
            "recrsTpfA8YK09c6z"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 888,
        "2023": 0,
        "2024": 0,
        "id": "rec0wIhcL9luMhg2m",
        "row": 4611,
        "state": "COLORADO",
        "group": "02406",
        "group_num": [
            "recgBKwWwcGld3NJn"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 469,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec36Va5d8UChianW",
        "row": 4628,
        "state": "COLORADO",
        "group": "16530",
        "group_num": [
            "recgnN0CEMZRHofRE"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1007,
        "2022": 1388,
        "2023": 0,
        "2024": 0,
        "id": "rec4WHqpyTs8sHXk7",
        "row": 4633,
        "state": "COLORADO",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 813,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4clCQluiLON3gt",
        "row": 4614,
        "state": "COLORADO",
        "group": "12888",
        "group_num": [
            "reciw3eF73ixeuGaJ"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 1343,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5VhIiLxAjnsLvt",
        "row": 4626,
        "state": "COLORADO",
        "group": "16211",
        "group_num": [
            "recdhiIZPcc0xPzS7"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 953,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recacAqjZ67OnoOj8",
        "row": 4631,
        "state": "COLORADO",
        "group": "16944",
        "group_num": [
            "recXqFQp1NvWz7ktg"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 617,
        "2021": 469,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaoZb4iAP04NkW7",
        "row": 4607,
        "state": "COLORADO",
        "group": "00490",
        "group_num": [
            "recINgeFcGz72iFia"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 513,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbdSjYQQfLlKNgR",
        "row": 4616,
        "state": "COLORADO",
        "group": "14919",
        "group_num": [
            "recNYotgdfhNYR9V4"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 949,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs1REqoCsffXhG0",
        "row": 4613,
        "state": "COLORADO",
        "group": "12888",
        "group_num": [
            "reciw3eF73ixeuGaJ"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1566,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuWg3Cr60GOJTdd",
        "row": 4612,
        "state": "COLORADO",
        "group": "12245",
        "group_num": [
            "recB6VfCfQJnO0Wrr"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 561,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuHJepKb6i8KmFj",
        "row": 4620,
        "state": "COLORADO",
        "group": "15319",
        "group_num": [
            "recrli6JCY4OEDQOj"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recvSqr76r6PD2JM4",
        "row": 4634,
        "state": "COLORADO",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 761,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczKcNJqt1NUTDiZ",
        "row": 4617,
        "state": "COLORADO",
        "group": "14919",
        "group_num": [
            "recNYotgdfhNYR9V4"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 761,
        "2021": 816,
        "2022": 816,
        "2023": 0,
        "2024": 0,
        "id": "recMrltynj2rAsvfA",
        "row": 4608,
        "state": "COLORADO",
        "group": "00490",
        "group_num": [
            "recINgeFcGz72iFia"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 83,
        "2022": 176,
        "2023": 334,
        "2024": 0,
        "id": "recrG9SypVXH7sLb0",
        "row": 4639,
        "state": "COLORADO",
        "group": "008833002",
        "group_num": [
            "recpCwKHIzavCLyrC"
        ],
        "procedure": "OP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 475,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recypLHcZ8lwosG0u",
        "row": 4638,
        "state": "COLORADO",
        "group": "008833002",
        "group_num": [
            "recpCwKHIzavCLyrC"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 999,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRnpkoPj0d5aJTq",
        "row": 4640,
        "state": "COLORADO",
        "group": "1679",
        "group_num": [
            "rec597iWnB37iR4rc"
        ],
        "procedure": "PHP",
        "provider": "MIDLANDS CHOICE"
    },
    {
        "2020": 350,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckGvq2KW2hGKe2Z",
        "row": 4643,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "IOP",
        "provider": "MINES AND ASSOCIATES"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoIEFFkc9BfsqhR",
        "row": 4642,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "Detox",
        "provider": "MINES AND ASSOCIATES"
    },
    {
        "2020": 400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx9s4R8vHcBgIZz",
        "row": 4644,
        "state": "COLORADO",
        "group": "O60787",
        "group_num": [
            "rec8Gt9OlCS8PupeY"
        ],
        "procedure": "IOP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 190,
        "2021": 51,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJBmblq2tQ8Dmrp",
        "row": 4645,
        "state": "COLORADO",
        "group": "O60787",
        "group_num": [
            "rec8Gt9OlCS8PupeY"
        ],
        "procedure": "OP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 987,
        "2023": 0,
        "2024": 0,
        "id": "reciDAsE6KAaeRq7s",
        "row": 4650,
        "state": "COLORADO",
        "group": "1292230",
        "group_num": [
            "recJUN4SUV3LmiT6t"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 69,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckQ7QDIqD2uJaGK",
        "row": 4647,
        "state": "COLORADO",
        "group": "1270208",
        "group_num": [
            "recWOG2W8l7WJTH2G"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 774,
        "2023": 0,
        "2024": 0,
        "id": "recSQdUMABd2g92ue",
        "row": 4651,
        "state": "COLORADO",
        "group": "1292230",
        "group_num": [
            "recJUN4SUV3LmiT6t"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3c3GqDGup50KVm",
        "row": 4648,
        "state": "COLORADO",
        "group": "1271147",
        "group_num": [
            "recAVtz3gmQ2gIR3I"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 4187,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoLQjzeusOoLq4r",
        "row": 4655,
        "state": "COLORADO",
        "group": "ES16344",
        "group_num": [
            "recQ0YHagjsrrpuX3"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 223,
        "2023": 0,
        "2024": 0,
        "id": "recvrAiFHE94SgZKt",
        "row": 4652,
        "state": "COLORADO",
        "group": "1418050",
        "group_num": [
            "recLinUfbNZaA1Xei"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 73,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxECwdM73h6saXJ",
        "row": 4654,
        "state": "COLORADO",
        "group": "AT2285",
        "group_num": [
            "recU1v7SdhKovg8ar"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "2023": 27,
        "2024": 0,
        "id": "recziEsS87QWmAqjt",
        "row": 4653,
        "state": "COLORADO",
        "group": "1418050",
        "group_num": [
            "recLinUfbNZaA1Xei"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 95,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFCyOqSvTtgVy26",
        "row": 4656,
        "state": "COLORADO",
        "group": "G0033730",
        "group_num": [
            "recVQo6dX2GCRfjFX"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1050,
        "2024": 0,
        "id": "rec1zoYt60wsJgm3o",
        "row": 4658,
        "state": "COLORADO",
        "group": "PCH30364",
        "group_num": [
            "rectyjxmqrABCgMj2"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 59,
        "2023": 0,
        "2024": 0,
        "id": "rec3lw2hy61yLLbif",
        "row": 4661,
        "state": "COLORADO",
        "group": "PKA20006",
        "group_num": [
            "recW117txP98sMHLD"
        ],
        "procedure": "OP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 850,
        "2024": 0,
        "id": "recvsQnBPaOI0IyLw",
        "row": 4659,
        "state": "COLORADO",
        "group": "PCH30364",
        "group_num": [
            "rectyjxmqrABCgMj2"
        ],
        "procedure": "RTC",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recMWuyf9JpV4yxU2",
        "row": 4660,
        "state": "COLORADO",
        "group": "PKA20006",
        "group_num": [
            "recW117txP98sMHLD"
        ],
        "procedure": "IOP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recao661gbvyTmQZw",
        "row": 4664,
        "state": "COLORADO",
        "group": "793198",
        "group_num": [
            "recYZJKH4maFfBuBy"
        ],
        "procedure": "RTC",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 1949,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLtr9uQUouyL5Vn",
        "row": 4663,
        "state": "COLORADO",
        "group": "793198",
        "group_num": [
            "recYZJKH4maFfBuBy"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 475,
        "2021": 475,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSVmHalR9fsSAKR",
        "row": 4666,
        "state": "COLORADO",
        "group": "20108000",
        "group_num": [
            "recaxzNuQ7KGvIYft"
        ],
        "procedure": "IOP",
        "provider": "ROCKY MOUNTAIN HEALTH"
    },
    {
        "2020": 600,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0ZKReywiSN2Is0",
        "row": 4665,
        "state": "COLORADO",
        "group": "19211000",
        "group_num": [
            "reccYOgG1hnDAOAw4"
        ],
        "procedure": "IOP",
        "provider": "ROCKY MOUNTAIN HEALTH"
    },
    {
        "2020": 42,
        "2021": 42,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIojhcrO2W9y0sj",
        "row": 4667,
        "state": "COLORADO",
        "group": "20108000",
        "group_num": [
            "recaxzNuQ7KGvIYft"
        ],
        "procedure": "OP",
        "provider": "ROCKY MOUNTAIN HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3270,
        "2023": 0,
        "2024": 0,
        "id": "recIev5UDqLqcr7cJ",
        "row": 4668,
        "state": "COLORADO",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 131,
        "2021": 200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmi9qKmB1XKjEgs",
        "row": 4669,
        "state": "COLORADO",
        "group": "G1013961",
        "group_num": [
            "recqP6GcPvdxxpOyg"
        ],
        "procedure": "IOP",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 21,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNEEyDdVfu0hinY",
        "row": 4670,
        "state": "COLORADO",
        "group": "G1013961",
        "group_num": [
            "recqP6GcPvdxxpOyg"
        ],
        "procedure": "OP",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 425,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2dgJprYgKyifia",
        "row": 4673,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "SPINA BIFIDA"
    },
    {
        "2020": 25,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8NfXRDQZ8SbdrO",
        "row": 4672,
        "state": "COLORADO",
        "group": "316943332",
        "group_num": [
            "recHf8tCehe8YG9iC"
        ],
        "procedure": "OP",
        "provider": "SPINA BIFIDA"
    },
    {
        "2020": 25,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdWLcvlScL3GnWu",
        "row": 4674,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "SPINA BIFIDA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2167,
        "2023": 0,
        "2024": 0,
        "id": "recPesJyop2WOsAOG",
        "row": 4679,
        "state": "COLORADO",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1715,
        "2023": 0,
        "2024": 0,
        "id": "recQD0YXgRtKNnGzR",
        "row": 4680,
        "state": "COLORADO",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "RTC",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1183,
        "2023": 0,
        "2024": 0,
        "id": "rec9dXD7EjKDYHGHe",
        "row": 4676,
        "state": "COLORADO",
        "group": "340",
        "group_num": [
            "recWlG46O5l5gsGQD"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "2023": 0,
        "2024": 0,
        "id": "recDx9pvyIlNXQtfr",
        "row": 4677,
        "state": "COLORADO",
        "group": "340",
        "group_num": [
            "recWlG46O5l5gsGQD"
        ],
        "procedure": "IOP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 309,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3Lyr2N4UKD5dzX",
        "row": 4681,
        "state": "COLORADO",
        "group": "715491",
        "group_num": [
            "recKCb0eAWtHKQpqq"
        ],
        "procedure": "IOP",
        "provider": "TEAMSTERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1960,
        "2023": 0,
        "2024": 0,
        "id": "recUJJv4VFKvx1w4h",
        "row": 4683,
        "state": "COLORADO",
        "group": "NAVJO",
        "group_num": [
            "rec71NcuHlCz2fI4j"
        ],
        "procedure": "Detox",
        "provider": "TRIBADO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3145,
        "2023": 0,
        "2024": 0,
        "id": "recwfOEkmQzcn3Gnq",
        "row": 4684,
        "state": "COLORADO",
        "group": "NAVJO",
        "group_num": [
            "rec71NcuHlCz2fI4j"
        ],
        "procedure": "RTC",
        "provider": "TRIBADO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 354,
        "2023": 360,
        "2024": 0,
        "id": "rec1DqAW9kXXnuglc",
        "row": 4688,
        "state": "COLORADO",
        "group": "A",
        "group_num": [
            "recJ7iEUPailWvkSs"
        ],
        "procedure": "IOP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 0,
        "2021": 24,
        "2022": 24,
        "2023": 0,
        "2024": 0,
        "id": "reco4jQqbetpRzrEi",
        "row": 4686,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 332,
        "2021": 339,
        "2022": 346,
        "2023": 0,
        "2024": 0,
        "id": "recthRVd3HBUeQlY2",
        "row": 4685,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 0,
        "2021": 682,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recip4ZKyluGDjLA3",
        "row": 4692,
        "state": "COLORADO",
        "group": "45700000",
        "group_num": [
            "recuKhbFlUZwDqOlO"
        ],
        "procedure": "IOP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 518,
        "2023": 0,
        "2024": 0,
        "id": "recR3X6PbSyII6QfO",
        "row": 4690,
        "state": "COLORADO",
        "group": "48587",
        "group_num": [
            "recsAWYb9qF8CVU0n"
        ],
        "procedure": "OP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 0,
        "2024": 0,
        "id": "recXoXMm8si3a4SSI",
        "row": 4689,
        "state": "COLORADO",
        "group": "48587",
        "group_num": [
            "recsAWYb9qF8CVU0n"
        ],
        "procedure": "IOP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 192,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8L8DQnwIyncjCW",
        "row": 4693,
        "state": "COLORADO",
        "group": "45700000",
        "group_num": [
            "recuKhbFlUZwDqOlO"
        ],
        "procedure": "OP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 960,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTFYbSyiRjBtatS",
        "row": 4695,
        "state": "COLORADO",
        "group": "UCHTBDC",
        "group_num": [
            "rec6d60chjMi1yX8m"
        ],
        "procedure": "Detox",
        "provider": "UCHEALTH"
    },
    {
        "2020": 0,
        "2021": 515,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectH7Xy8lVP3IdRc",
        "row": 4696,
        "state": "COLORADO",
        "group": "UCHTBDC",
        "group_num": [
            "rec6d60chjMi1yX8m"
        ],
        "procedure": "RTC",
        "provider": "UCHEALTH"
    },
    {
        "2020": 1854,
        "2021": 1320,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recevE0L2jk3lbehO",
        "row": 4714,
        "state": "COLORADO",
        "group": "76411027",
        "group_num": [
            "recGr9I1ua9yxFiy7"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 2438,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgNJPYRgH9A5iXq",
        "row": 4731,
        "state": "COLORADO",
        "group": "76411712",
        "group_num": [
            "recGP9VwAipzBuzPq"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recghpN0noaN9V3qy",
        "row": 4736,
        "state": "COLORADO",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4529,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechYOSID68V47RJz",
        "row": 4742,
        "state": "COLORADO",
        "group": "76412444",
        "group_num": [
            "recjQ2dFnUPKWeKds"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 1523,
        "2021": 0,
        "2022": 1523,
        "2023": 0,
        "2024": 0,
        "id": "rechQ8eV6MI1XDhCl",
        "row": 4741,
        "state": "COLORADO",
        "group": "76412388",
        "group_num": [
            "recn5KFgqn1goPucr"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 143,
        "2021": 51,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechfGaEbH8bDEojm",
        "row": 4715,
        "state": "COLORADO",
        "group": "76411027",
        "group_num": [
            "recGr9I1ua9yxFiy7"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 1958,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckz1ClbV9KosnD1",
        "row": 4724,
        "state": "COLORADO",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1088,
        "2022": 1088,
        "2023": 0,
        "2024": 0,
        "id": "reckdnA1SuFX31aP3",
        "row": 4735,
        "state": "COLORADO",
        "group": "76411747",
        "group_num": [
            "recibtjxpprBSyvtB"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2375,
        "2024": 0,
        "id": "recki9XBVdqDtorm9",
        "row": 4737,
        "state": "COLORADO",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 42,
        "2022": 52,
        "2023": 0,
        "2024": 0,
        "id": "recQXTGPmv7W7AgKr",
        "row": 4704,
        "state": "COLORADO",
        "group": "76070061",
        "group_num": [
            "recPoBtfcxxgn3ecm"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRSucVqkilA1Ra9",
        "row": 4734,
        "state": "COLORADO",
        "group": "76411747",
        "group_num": [
            "recibtjxpprBSyvtB"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 5100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV93A7qtoCXoYY2",
        "row": 4716,
        "state": "COLORADO",
        "group": "76411049",
        "group_num": [
            "recqrfDrWWxmuT44M"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 430,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recW45xMBQW2vqSCj",
        "row": 4749,
        "state": "COLORADO",
        "group": "76412866",
        "group_num": [
            "recKP3YVhiYUDSksh"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 2066,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXeb5EvZAjBUXFU",
        "row": 4726,
        "state": "COLORADO",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3097,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYxGmNDXnNOW8iC",
        "row": 4743,
        "state": "COLORADO",
        "group": "76412444",
        "group_num": [
            "recjQ2dFnUPKWeKds"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 2175,
        "2021": 1958,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY62UEyUQCiJDbx",
        "row": 4718,
        "state": "COLORADO",
        "group": "76411073",
        "group_num": [
            "recAwVrF2HulEqMVu"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1573,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZoDCK2Vbyq0eBg",
        "row": 4748,
        "state": "COLORADO",
        "group": "76412866",
        "group_num": [
            "recKP3YVhiYUDSksh"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 0,
        "2024": 0,
        "id": "rec0ekNG8VGcjgprB",
        "row": 4728,
        "state": "COLORADO",
        "group": "76411465",
        "group_num": [
            "rec9bKLFLmos6Dt5U"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 948,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1K0SmAB5AJIygK",
        "row": 4727,
        "state": "COLORADO",
        "group": "76411449",
        "group_num": [
            "recIFHFnhpXznbVO3"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 49,
        "2021": 49,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec24ptDo0YNvaiMA",
        "row": 4709,
        "state": "COLORADO",
        "group": "76410082",
        "group_num": [
            "recS7s6UU24XMDG96"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 2494,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec32hAVrXcHNaf1O",
        "row": 4725,
        "state": "COLORADO",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 4091,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec368AyNHvGO4QeQ",
        "row": 4746,
        "state": "COLORADO",
        "group": "76412524",
        "group_num": [
            "recjNtVhXnCVfswYR"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2363,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3oMxmgtvshldTm",
        "row": 4729,
        "state": "COLORADO",
        "group": "76411559",
        "group_num": [
            "rec5zNZVxrAClCFt8"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 455,
        "2022": 209,
        "2023": 0,
        "2024": 0,
        "id": "rec4l1A2YgDl4MX0g",
        "row": 4702,
        "state": "COLORADO",
        "group": "76070060",
        "group_num": [
            "recQwPzKsNn1RLMYR"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1917,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5pkj0nz5f2BjQc",
        "row": 4705,
        "state": "COLORADO",
        "group": "76070085",
        "group_num": [
            "rec1bH3K7BedYbSK9"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 2053,
        "2021": 1212,
        "2022": 1096,
        "2023": 1631,
        "2024": 0,
        "id": "rec7i2NFNAAw9PFi0",
        "row": 4739,
        "state": "COLORADO",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 761,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8VURPK3sd5NV77",
        "row": 4733,
        "state": "COLORADO",
        "group": "76411712",
        "group_num": [
            "recGP9VwAipzBuzPq"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1663,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8cYVgjgjw3Ww97",
        "row": 4721,
        "state": "COLORADO",
        "group": "76411292",
        "group_num": [
            "recq8n5PQ9DuZUuBp"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 2297,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8iMCMfhfheo4TP",
        "row": 4751,
        "state": "COLORADO",
        "group": "76412882",
        "group_num": [
            "recdB9XWmUcP6qMC7"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 2625,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8mjpDkRCu4PKD3",
        "row": 4717,
        "state": "COLORADO",
        "group": "76411073",
        "group_num": [
            "recAwVrF2HulEqMVu"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 775,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb4IV86lLohd8NZ",
        "row": 4707,
        "state": "COLORADO",
        "group": "76410018",
        "group_num": [
            "recAmF8P2j3D3b0nM"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2617,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccNJShwpt2LeZQf",
        "row": 4744,
        "state": "COLORADO",
        "group": "76412498",
        "group_num": [
            "recPMVBqvWZcUplzY"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1088,
        "2022": 1088,
        "2023": 0,
        "2024": 0,
        "id": "recqU9eADmA1FrLqe",
        "row": 4713,
        "state": "COLORADO",
        "group": "76410761",
        "group_num": [
            "recWZTP39YuUNMyCW"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1958,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqC8EkeuIsWM0yX",
        "row": 4730,
        "state": "COLORADO",
        "group": "76411559",
        "group_num": [
            "rec5zNZVxrAClCFt8"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqJwLuaDh8uEvjn",
        "row": 4720,
        "state": "COLORADO",
        "group": "76411292",
        "group_num": [
            "recq8n5PQ9DuZUuBp"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 685,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrLlg9pL0DAkB5e",
        "row": 4708,
        "state": "COLORADO",
        "group": "76410018",
        "group_num": [
            "recAmF8P2j3D3b0nM"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 1958,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsYjEHn26V0SCeG",
        "row": 4732,
        "state": "COLORADO",
        "group": "76411712",
        "group_num": [
            "recGP9VwAipzBuzPq"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuJ2Qoi3j9CZ0di",
        "row": 4750,
        "state": "COLORADO",
        "group": "76412876",
        "group_num": [
            "recs7ZBlcb7HkH51S"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 1088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwXrW5M8BCd9pT1",
        "row": 4719,
        "state": "COLORADO",
        "group": "76411073",
        "group_num": [
            "recAwVrF2HulEqMVu"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3801,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxzUSLFQdcbFW8a",
        "row": 4745,
        "state": "COLORADO",
        "group": "76412505",
        "group_num": [
            "recrFJaduPKagxgHL"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "reczPttqh3OrRPvuF",
        "row": 4712,
        "state": "COLORADO",
        "group": "76410671",
        "group_num": [
            "recBV9jpFqIB5dzcv"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 151,
        "2021": 155,
        "2022": 97,
        "2023": 96,
        "2024": 0,
        "id": "recA3krjDgAHIiJrp",
        "row": 4740,
        "state": "COLORADO",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recBiif7hg4iTp57w",
        "row": 4711,
        "state": "COLORADO",
        "group": "76410569",
        "group_num": [
            "recblkypnhewxPfw0"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2174,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEmrFVPDwKfFamM",
        "row": 4706,
        "state": "COLORADO",
        "group": "76070749",
        "group_num": [
            "recQgSWVEjeYnhvHd"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2083,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFbcVW63mZZm1gj",
        "row": 4738,
        "state": "COLORADO",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 147,
        "2022": 575,
        "2023": 0,
        "2024": 0,
        "id": "recJ6rxbcnwCrYHNj",
        "row": 4703,
        "state": "COLORADO",
        "group": "76070061",
        "group_num": [
            "recPoBtfcxxgn3ecm"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 544,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJiWqfV01DUrbTY",
        "row": 4723,
        "state": "COLORADO",
        "group": "76411365",
        "group_num": [
            "recnMfJ0ytJilr5g3"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 180,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKTC82X0Grsoakt",
        "row": 4701,
        "state": "COLORADO",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 2092,
        "2021": 1427,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKutGYzCXFfwjq0",
        "row": 4700,
        "state": "COLORADO",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 2503,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLHBhkOiCCCAmx5",
        "row": 4699,
        "state": "COLORADO",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 0,
        "2024": 0,
        "id": "recLcmWy0kQgy1ngp",
        "row": 4710,
        "state": "COLORADO",
        "group": "76410531",
        "group_num": [
            "recC7kF3YtxMkjFMT"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2964,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMHV4xlIvjMhvEj",
        "row": 4722,
        "state": "COLORADO",
        "group": "76411365",
        "group_num": [
            "recnMfJ0ytJilr5g3"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "2023": 0,
        "2024": 0,
        "id": "recNSe2iSQNRb6PSY",
        "row": 4698,
        "state": "COLORADO",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3255,
        "2023": 0,
        "2024": 0,
        "id": "recN05yeYBgs7i1WZ",
        "row": 4697,
        "state": "COLORADO",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recNGQvJsXofAQq1k",
        "row": 4747,
        "state": "COLORADO",
        "group": "76412526",
        "group_num": [
            "recWAMUSdSSv6XbMI"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2175,
        "2021": 2175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receuuch5WU3kXV69",
        "row": 4780,
        "state": "COLORADO",
        "group": "76413648",
        "group_num": [
            "recHJrVrZcjhkfaX6"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 37,
        "2021": 65,
        "2022": 42,
        "2023": 0,
        "2024": 0,
        "id": "recf4IU47nqNUG7qg",
        "row": 4790,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1663,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recffsqJjzJD7KUuV",
        "row": 4808,
        "state": "COLORADO",
        "group": "76414260",
        "group_num": [
            "recg3tYE576Pv3DwT"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2454,
        "2022": 5029,
        "2023": 0,
        "2024": 0,
        "id": "recgRL1dRCLqNTtoK",
        "row": 4777,
        "state": "COLORADO",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 2175,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recggoY9Ge8pq4WoE",
        "row": 4831,
        "state": "COLORADO",
        "group": "767000412388",
        "group_num": [
            "rec5mQRHtAKbYf7VE"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 3280,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechPBd8dVkC5ZE9v",
        "row": 4822,
        "state": "COLORADO",
        "group": "76430102",
        "group_num": [
            "recXwmjH3qe0bRMu9"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 208,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recitzVue3csCRDAK",
        "row": 4753,
        "state": "COLORADO",
        "group": "76412882",
        "group_num": [
            "recdB9XWmUcP6qMC7"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjtSgQvfdgfLYZn",
        "row": 4807,
        "state": "COLORADO",
        "group": "76414260",
        "group_num": [
            "recg3tYE576Pv3DwT"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj2JJsIqe9DO4jp",
        "row": 4794,
        "state": "COLORADO",
        "group": "76413981",
        "group_num": [
            "recOwQByY0Ctq1u55"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2365,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recj3fFxDzQasQTGV",
        "row": 4764,
        "state": "COLORADO",
        "group": "76413096",
        "group_num": [
            "recWiHrprxd66szZc"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5100,
        "2023": 0,
        "2024": 0,
        "id": "reckITFXgiCQrBlnY",
        "row": 4771,
        "state": "COLORADO",
        "group": "76413549",
        "group_num": [
            "rec7KqctrvdkO4uOQ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1351,
        "2022": 1500,
        "2023": 0,
        "2024": 0,
        "id": "reclaERd2m6dbyxHK",
        "row": 4788,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1411,
        "2023": 0,
        "2024": 0,
        "id": "recleLuFCMmrmePLT",
        "row": 4815,
        "state": "COLORADO",
        "group": "76414988",
        "group_num": [
            "recRZdZde1v3hEM4z"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm1mqmePsM2LrYf",
        "row": 4811,
        "state": "COLORADO",
        "group": "76414351",
        "group_num": [
            "recp7nKPttDb1eAnC"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "2023": 0,
        "2024": 0,
        "id": "recmjAil7Bki1h3D3",
        "row": 4818,
        "state": "COLORADO",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 2363,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnA2lyGSlNdjOdc",
        "row": 4829,
        "state": "COLORADO",
        "group": "767000411027",
        "group_num": [
            "recNIBJr8ABTuddiP"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 2012,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnLNiSTxQojHoLt",
        "row": 4823,
        "state": "COLORADO",
        "group": "76510041",
        "group_num": [
            "recT7LpW2PSDd7EVk"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 498,
        "2021": 644,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnas5BUqUWLdHdV",
        "row": 4766,
        "state": "COLORADO",
        "group": "76413102",
        "group_num": [
            "recR5DcGo10qfDWqn"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1958,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOHrxISlI3GhX7c",
        "row": 4830,
        "state": "COLORADO",
        "group": "767000411027",
        "group_num": [
            "recNIBJr8ABTuddiP"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 568,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPlqaVNlr0UvEn1",
        "row": 4759,
        "state": "COLORADO",
        "group": "76412959",
        "group_num": [
            "reco0WruTR2loEMGQ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQLGCtKbLLfPSka",
        "row": 4801,
        "state": "COLORADO",
        "group": "76414141",
        "group_num": [
            "recpRGPrjmGMDMJQe"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 2001,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQjnMVFnculvmdm",
        "row": 4769,
        "state": "COLORADO",
        "group": "76413514",
        "group_num": [
            "recr5flKomhBcJh1F"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 440,
        "2021": 475,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR7Fgb5HO7Scqwm",
        "row": 4781,
        "state": "COLORADO",
        "group": "76413662",
        "group_num": [
            "recgB88dz1o9g5o9s"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTP26oZfRoHHeLn",
        "row": 4825,
        "state": "COLORADO",
        "group": "76560006",
        "group_num": [
            "recpBRAvMeZsfzZIw"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 366,
        "2021": 997,
        "2022": 1449,
        "2023": 1611,
        "2024": 0,
        "id": "recThRC50BG7qpcK7",
        "row": 4789,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1200,
        "2021": 1523,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUinM9Jgkzt8wBM",
        "row": 4776,
        "state": "COLORADO",
        "group": "76413617",
        "group_num": [
            "rec9AT8PgFff9iqfJ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 247,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVXeigz4PIIyU9a",
        "row": 4782,
        "state": "COLORADO",
        "group": "76413662",
        "group_num": [
            "recgB88dz1o9g5o9s"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 61,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWmoRUa2PsKfd7Q",
        "row": 4828,
        "state": "COLORADO",
        "group": "76590051",
        "group_num": [
            "rec0ZjgSpZcloDsjH"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 730,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXzy52QI5otCZfp",
        "row": 4785,
        "state": "COLORADO",
        "group": "76413666",
        "group_num": [
            "recUZzVxPoAQUGrsS"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 1663,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZH9DnfUf0a8Ocg",
        "row": 4805,
        "state": "COLORADO",
        "group": "76414153",
        "group_num": [
            "recPiHeiYtmdk7tHI"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ4pK1LLuNJ8sRq",
        "row": 4814,
        "state": "COLORADO",
        "group": "76414724",
        "group_num": [
            "recc4PsbHDvGUtvov"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1544,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0THygpqmT85AdE",
        "row": 4758,
        "state": "COLORADO",
        "group": "76412955",
        "group_num": [
            "reclrf7iKatfZULFp"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 625,
        "2023": 350,
        "2024": 0,
        "id": "rec1Pkm1shmd1DdeQ",
        "row": 4779,
        "state": "COLORADO",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2788,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2aEWyMmftBn2rE",
        "row": 4812,
        "state": "COLORADO",
        "group": "76414571",
        "group_num": [
            "recgok2g5duxFRyEZ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3543,
        "2023": 0,
        "2024": 0,
        "id": "rec3SW70hENxeYZjd",
        "row": 4772,
        "state": "COLORADO",
        "group": "76413549",
        "group_num": [
            "rec7KqctrvdkO4uOQ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 1088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3Im694ZHpgHvNC",
        "row": 4832,
        "state": "COLORADO",
        "group": "767000412388",
        "group_num": [
            "rec5mQRHtAKbYf7VE"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 2134,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3RK5yDqQDLy0wD",
        "row": 4761,
        "state": "COLORADO",
        "group": "76413037",
        "group_num": [
            "recONRIwwFQOgrAd9"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1523,
        "2023": 0,
        "2024": 0,
        "id": "rec4uCArALKkKPcJT",
        "row": 4820,
        "state": "COLORADO",
        "group": "76415119",
        "group_num": [
            "rece5886E8qHib6ol"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 50,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec57Hw6zZ0BRi1MX",
        "row": 4756,
        "state": "COLORADO",
        "group": "76412937",
        "group_num": [
            "recmcnVUQH3p70ZE9"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1473,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec65xzxBoLqsukPS",
        "row": 4765,
        "state": "COLORADO",
        "group": "76413096",
        "group_num": [
            "recWiHrprxd66szZc"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1521,
        "2023": 0,
        "2024": 0,
        "id": "rec69YHoh8Hx39PdJ",
        "row": 4767,
        "state": "COLORADO",
        "group": "76413280",
        "group_num": [
            "recowaTauGvUYP8Bt"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec8DMTC0pw94NzDG",
        "row": 4774,
        "state": "COLORADO",
        "group": "76413553",
        "group_num": [
            "recgPWdJ7EqhoT6vK"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 1971,
        "2021": 1622,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca4NMWxVu49dA8C",
        "row": 4752,
        "state": "COLORADO",
        "group": "76412882",
        "group_num": [
            "recdB9XWmUcP6qMC7"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recbM15EKq77TXXtM",
        "row": 4799,
        "state": "COLORADO",
        "group": "76414072",
        "group_num": [
            "recVAtCoF1XF9ltsU"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2950,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb6oIerDh6fqY5P",
        "row": 4803,
        "state": "COLORADO",
        "group": "76414144",
        "group_num": [
            "recubpWtpFsrrTDPh"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2894,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdCTjXtoaDznjw9",
        "row": 4813,
        "state": "COLORADO",
        "group": "76414571",
        "group_num": [
            "recgok2g5duxFRyEZ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 1523,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdDjvETUiaScVLV",
        "row": 4800,
        "state": "COLORADO",
        "group": "76414138",
        "group_num": [
            "recZP3jszTidcH7fk"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 2100,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdOnZn6ETgCQMp6",
        "row": 4784,
        "state": "COLORADO",
        "group": "76413666",
        "group_num": [
            "recUZzVxPoAQUGrsS"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 498,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoFI15UgeHDH2SD",
        "row": 4796,
        "state": "COLORADO",
        "group": "76413981",
        "group_num": [
            "recOwQByY0Ctq1u55"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1642,
        "2023": 0,
        "2024": 0,
        "id": "recoHHBMyIhC3D4FF",
        "row": 4773,
        "state": "COLORADO",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 49,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp4nggw3NRmCtii",
        "row": 4783,
        "state": "COLORADO",
        "group": "76413662",
        "group_num": [
            "recgB88dz1o9g5o9s"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recphPYJ9566hULSP",
        "row": 4792,
        "state": "COLORADO",
        "group": "76413721",
        "group_num": [
            "recxX1nHoZB1k1I2a"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 5100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqKj7iPGB6VMWn2",
        "row": 4760,
        "state": "COLORADO",
        "group": "76413037",
        "group_num": [
            "recONRIwwFQOgrAd9"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4038,
        "2022": 4750,
        "2023": 2375,
        "2024": 0,
        "id": "recqgqg3eimlMoSvb",
        "row": 4787,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 1088,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrOyJRUD34QyGmd",
        "row": 4762,
        "state": "COLORADO",
        "group": "76413037",
        "group_num": [
            "recONRIwwFQOgrAd9"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsUGlGZSW9L68uL",
        "row": 4809,
        "state": "COLORADO",
        "group": "76414305",
        "group_num": [
            "recHbJnERryNN7dTA"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 150,
        "2022": 175,
        "2023": 150,
        "2024": 0,
        "id": "recssGzfotZ3MvEeR",
        "row": 4802,
        "state": "COLORADO",
        "group": "76414141",
        "group_num": [
            "recpRGPrjmGMDMJQe"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "2023": 0,
        "2024": 0,
        "id": "rectxGjPXgtJIfD4B",
        "row": 4819,
        "state": "COLORADO",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 206,
        "2024": 0,
        "id": "recuJNkeQnu4wNhxv",
        "row": 4821,
        "state": "COLORADO",
        "group": "76415119",
        "group_num": [
            "rece5886E8qHib6ol"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 498,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvwR7qVlZcFwZYb",
        "row": 4795,
        "state": "COLORADO",
        "group": "76413981",
        "group_num": [
            "recOwQByY0Ctq1u55"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2686,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvyeIjcGeJpYvSC",
        "row": 4763,
        "state": "COLORADO",
        "group": "76413096",
        "group_num": [
            "recWiHrprxd66szZc"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2418,
        "2022": 4038,
        "2023": 0,
        "2024": 0,
        "id": "recw7FTrxHYUfPgYT",
        "row": 4778,
        "state": "COLORADO",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 231,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyU0JNtotUr7r8Q",
        "row": 4797,
        "state": "COLORADO",
        "group": "76413981",
        "group_num": [
            "recOwQByY0Ctq1u55"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 1523,
        "2021": 1523,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyMr3SMYZdaghNF",
        "row": 4827,
        "state": "COLORADO",
        "group": "76590051",
        "group_num": [
            "rec0ZjgSpZcloDsjH"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recyeEvXmWYnzlyBx",
        "row": 4768,
        "state": "COLORADO",
        "group": "76413489",
        "group_num": [
            "recw4yftNbkrcocKx"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2250,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczff06HTk4Q31Oj",
        "row": 4757,
        "state": "COLORADO",
        "group": "76412955",
        "group_num": [
            "reclrf7iKatfZULFp"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1297,
        "2023": 0,
        "2024": 0,
        "id": "recAVKw3WfO0rHOTq",
        "row": 4793,
        "state": "COLORADO",
        "group": "76413907",
        "group_num": [
            "rec6eckE4q4fNKNaa"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 3000,
        "2021": 3477,
        "2022": 4145,
        "2023": 3000,
        "2024": 0,
        "id": "recAcG8hM3aDf9peq",
        "row": 4786,
        "state": "COLORADO",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBbgaOuxJXBYefg",
        "row": 4754,
        "state": "COLORADO",
        "group": "76412908",
        "group_num": [
            "rec1tbZSb6jYM4lvF"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 49,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCUjdKURRupXtXv",
        "row": 4826,
        "state": "COLORADO",
        "group": "76560006",
        "group_num": [
            "recpBRAvMeZsfzZIw"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1273,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDatcchI7vW6YoD",
        "row": 4770,
        "state": "COLORADO",
        "group": "76413522",
        "group_num": [
            "recfGy1hZbygLXaRe"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFfv8rFvnWknIsd",
        "row": 4798,
        "state": "COLORADO",
        "group": "76414015",
        "group_num": [
            "recQqYJGTArBPiwNd"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1427,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGUKNGGkH1p4QMK",
        "row": 4755,
        "state": "COLORADO",
        "group": "76412937",
        "group_num": [
            "recmcnVUQH3p70ZE9"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGrB47bLhOUbq7g",
        "row": 4806,
        "state": "COLORADO",
        "group": "76414183",
        "group_num": [
            "recc2ULTEbIoiRsrl"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4704,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIRwV8LS97BYHDv",
        "row": 4775,
        "state": "COLORADO",
        "group": "76413565",
        "group_num": [
            "recdMOlh1rgOHIcYi"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 35,
        "2021": 442,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIn8TUUlNRqvQFD",
        "row": 4824,
        "state": "COLORADO",
        "group": "76510041",
        "group_num": [
            "recT7LpW2PSDd7EVk"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJt9i7fC4eH4mrE",
        "row": 4804,
        "state": "COLORADO",
        "group": "76414153",
        "group_num": [
            "recPiHeiYtmdk7tHI"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recKbhRSea3d15vsZ",
        "row": 4817,
        "state": "COLORADO",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMvHim7fMdZLjNJ",
        "row": 4810,
        "state": "COLORADO",
        "group": "76414351",
        "group_num": [
            "recp7nKPttDb1eAnC"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recNFHR6pLwIKfwiC",
        "row": 4816,
        "state": "COLORADO",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 952,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receGcmBYl8UprRoV",
        "row": 4833,
        "state": "COLORADO",
        "group": "131192M273",
        "group_num": [
            "recMM9MAV1RyzdVnA"
        ],
        "procedure": "Detox",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 952,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn320nBhTVTsx8f",
        "row": 4834,
        "state": "COLORADO",
        "group": "131192M273",
        "group_num": [
            "recMM9MAV1RyzdVnA"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 61,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receC2ZKGrkGwAvCG",
        "row": 4849,
        "state": "COLORADO",
        "group": "2L4853",
        "group_num": [
            "recB0D1t9LCA8tIAK"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 245,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfGzk6Jm2VQsdj1",
        "row": 4835,
        "state": "COLORADO",
        "group": "0F8112",
        "group_num": [
            "recB1C0sHUGYQv7lG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 147,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "reclpTpb7CowFCrzW",
        "row": 4843,
        "state": "COLORADO",
        "group": "1Y4498",
        "group_num": [
            "recGf26NJlH82t1zb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 79,
        "2023": 0,
        "2024": 0,
        "id": "recSPfo4VlF0nSPwJ",
        "row": 4847,
        "state": "COLORADO",
        "group": "2L2775",
        "group_num": [
            "recXEhXboA8J18YRe"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 232,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWjGQs8yfzuPBnq",
        "row": 4851,
        "state": "COLORADO",
        "group": "2Q3831",
        "group_num": [
            "recXUgZ1hLnZBUTXC"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1R1Y5L4uoPC6Qt",
        "row": 4844,
        "state": "COLORADO",
        "group": "2F4728",
        "group_num": [
            "rectpcL2xDaRgjBTs"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1hpNV78dSlg1Tx",
        "row": 4842,
        "state": "COLORADO",
        "group": "1Y2564",
        "group_num": [
            "recq3a5BPGUGL0bfs"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 550,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdbYeNveKvjvE93",
        "row": 4840,
        "state": "COLORADO",
        "group": "1F0847",
        "group_num": [
            "recq0tIKqVNMwyKlf"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 211,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq2MKNWmCIJGw2p",
        "row": 4846,
        "state": "COLORADO",
        "group": "2L2774",
        "group_num": [
            "recCXh0aOfTM0iBqf"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxODAuNmWAj9X77",
        "row": 4845,
        "state": "COLORADO",
        "group": "2F4728",
        "group_num": [
            "rectpcL2xDaRgjBTs"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 322,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recACwED5HpmiTqXq",
        "row": 4841,
        "state": "COLORADO",
        "group": "1F0847",
        "group_num": [
            "recq0tIKqVNMwyKlf"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recEV23hjf1z52jEA",
        "row": 4836,
        "state": "COLORADO",
        "group": "0F8112",
        "group_num": [
            "recB1C0sHUGYQv7lG"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIOWpjqlGlwSEI7",
        "row": 4837,
        "state": "COLORADO",
        "group": "0U9986",
        "group_num": [
            "rec39gZLZo4p6kMiA"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 560,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJFXIEigAeikp20",
        "row": 4838,
        "state": "COLORADO",
        "group": "0W9912",
        "group_num": [
            "recODUkx4T4WlwUG0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJQJGGFzoO3NtT5",
        "row": 4848,
        "state": "COLORADO",
        "group": "2L4853",
        "group_num": [
            "recB0D1t9LCA8tIAK"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 23,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLqndsvQoW03Aso",
        "row": 4839,
        "state": "COLORADO",
        "group": "0W9912",
        "group_num": [
            "recODUkx4T4WlwUG0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recePoSDEqRnvNcqd",
        "row": 4894,
        "state": "COLORADO",
        "group": "7L0257",
        "group_num": [
            "recuImLFeY0KSnETX"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 918,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgSzWfaobQisBqW",
        "row": 4868,
        "state": "COLORADO",
        "group": "4Y6826",
        "group_num": [
            "reckO6UOXQHijOiEV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 23,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgxsug1CNRYE5aj",
        "row": 4860,
        "state": "COLORADO",
        "group": "4L4344",
        "group_num": [
            "rece2cBtd0sWLv3OV"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 23,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechRqkbqqKjz8qsh",
        "row": 4853,
        "state": "COLORADO",
        "group": "2W0597",
        "group_num": [
            "recFvKXLctv1Qgq6J"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 147,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "reciESqq3D9szdBsw",
        "row": 4871,
        "state": "COLORADO",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 409,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclpemEfwhypKAZ5",
        "row": 4855,
        "state": "COLORADO",
        "group": "3Y1295",
        "group_num": [
            "recY8pzA0lAlyCZa2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 21,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recntFL7lPYa3Iymh",
        "row": 4883,
        "state": "COLORADO",
        "group": "6H4126",
        "group_num": [
            "reccJKZllfBBXgwCm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 500,
        "2024": 0,
        "id": "recPDppuQ8LafgXbF",
        "row": 4900,
        "state": "COLORADO",
        "group": "8W0308",
        "group_num": [
            "recrU4TpTR92UGVtd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 281,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQrCMWf5gERO5Nm",
        "row": 4898,
        "state": "COLORADO",
        "group": "8P5266",
        "group_num": [
            "rec6IOgexTx0a3Lho"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTwWsU4IEN6bXrD",
        "row": 4878,
        "state": "COLORADO",
        "group": "5F7202",
        "group_num": [
            "recVlUA44TaHSXtUJ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUqKSrkhCuYR8Wp",
        "row": 4893,
        "state": "COLORADO",
        "group": "7F8802",
        "group_num": [
            "recQqh1mInDG81w7D"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWPrm0LsPbjuUgS",
        "row": 4854,
        "state": "COLORADO",
        "group": "3M8957",
        "group_num": [
            "recBqxZQDR1r89bof"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWk46rhGmxltOud",
        "row": 4880,
        "state": "COLORADO",
        "group": "5M4757",
        "group_num": [
            "recJ2Q8008DjUZTwg"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 235,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXKPKWNJrsgqGHm",
        "row": 4856,
        "state": "COLORADO",
        "group": "3Y6136",
        "group_num": [
            "recBYAiCRKpg5hBmi"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recZowwy8j6UFAgPJ",
        "row": 4852,
        "state": "COLORADO",
        "group": "2Q3831",
        "group_num": [
            "recXUgZ1hLnZBUTXC"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Q5hEYXz6xcVqL",
        "row": 4865,
        "state": "COLORADO",
        "group": "4Y5490",
        "group_num": [
            "recWHyK6C7gowFStO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2U1eXaWp34ySt8",
        "row": 4890,
        "state": "COLORADO",
        "group": "6Y0898",
        "group_num": [
            "rec6EUdGzV5TzZxNy"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 257,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3p9qVT0jCmWSa8",
        "row": 4896,
        "state": "COLORADO",
        "group": "8L8590",
        "group_num": [
            "recTWrMS1K5wOHG7C"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "rec4T7YBC6jSsDmQE",
        "row": 4870,
        "state": "COLORADO",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec58niocsoqrQRda",
        "row": 4864,
        "state": "COLORADO",
        "group": "4X5952",
        "group_num": [
            "rec8HyiCXyZWzVAp2"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "rec8sYJH9k8vNwqVB",
        "row": 4879,
        "state": "COLORADO",
        "group": "5L5050",
        "group_num": [
            "rec167XUbyD5feACB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaX3n1Yq7tpB7ny",
        "row": 4884,
        "state": "COLORADO",
        "group": "6S2268",
        "group_num": [
            "recWoebcgMOwbl4Rp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 240,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb0GDsSlctMmGS6",
        "row": 4888,
        "state": "COLORADO",
        "group": "6X4489",
        "group_num": [
            "rec3p4b3UqNxTLYMr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 28,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccX5Z3V3sxVMXUy",
        "row": 4862,
        "state": "COLORADO",
        "group": "4U1737",
        "group_num": [
            "recgrzhpbAKphY6jE"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco1GvcjTYczVdwg",
        "row": 4892,
        "state": "COLORADO",
        "group": "6Y4311",
        "group_num": [
            "reccJiGPATnpuLIwL"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 23,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recokHknODWlmAdaO",
        "row": 4857,
        "state": "COLORADO",
        "group": "3Y6136",
        "group_num": [
            "recBYAiCRKpg5hBmi"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 474,
        "2021": 734,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpv3mFapQAdl1d0",
        "row": 4886,
        "state": "COLORADO",
        "group": "6W4395",
        "group_num": [
            "recK5kIpNbZhmLgJB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "recquwvFyiEk7umIp",
        "row": 4869,
        "state": "COLORADO",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 60,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqQ6zY5YpmNAbei",
        "row": 4899,
        "state": "COLORADO",
        "group": "8P5266",
        "group_num": [
            "rec6IOgexTx0a3Lho"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 872,
        "2023": 0,
        "2024": 0,
        "id": "rectCNwGSqJY0DM9b",
        "row": 4866,
        "state": "COLORADO",
        "group": "4Y5713",
        "group_num": [
            "recQx7T2XafxNn6ij"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 412,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectNhDSu5qA8rJ2J",
        "row": 4891,
        "state": "COLORADO",
        "group": "6Y4311",
        "group_num": [
            "reccJiGPATnpuLIwL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 598,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu4zNQ5xbDY4dBs",
        "row": 4859,
        "state": "COLORADO",
        "group": "4L4344",
        "group_num": [
            "rece2cBtd0sWLv3OV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 771,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recunrpwXWcao0qzJ",
        "row": 4863,
        "state": "COLORADO",
        "group": "4U2234",
        "group_num": [
            "rec3vaH4y2J94k5Rn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 90,
        "2023": 0,
        "2024": 0,
        "id": "recvS4va8M7YMXftL",
        "row": 4877,
        "state": "COLORADO",
        "group": "5F4148",
        "group_num": [
            "recMhLDVeEt1TAdWz"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 480,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvYbaMEMGM6jImo",
        "row": 4897,
        "state": "COLORADO",
        "group": "8P5266",
        "group_num": [
            "rec6IOgexTx0a3Lho"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvOEKcvpZWTurKU",
        "row": 4858,
        "state": "COLORADO",
        "group": "4L4344",
        "group_num": [
            "rece2cBtd0sWLv3OV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxx40JdNRz5Otip",
        "row": 4872,
        "state": "COLORADO",
        "group": "4Y8449",
        "group_num": [
            "recKcpo7bC8xhRu5C"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 23,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyekWnz1CqeoIC6",
        "row": 4889,
        "state": "COLORADO",
        "group": "6X4489",
        "group_num": [
            "rec3p4b3UqNxTLYMr"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 332,
        "2022": 152,
        "2023": 0,
        "2024": 0,
        "id": "recB6eBqq4jNY8dIi",
        "row": 4895,
        "state": "COLORADO",
        "group": "7Y2959",
        "group_num": [
            "recbhcoK4Xrt47Wqd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 395,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDsYFyNJCDtZrw4",
        "row": 4874,
        "state": "COLORADO",
        "group": "5F2886",
        "group_num": [
            "recLkzDVT2jfaeEsa"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 29,
        "2021": 23,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recF8l2D4gwrmDWc3",
        "row": 4887,
        "state": "COLORADO",
        "group": "6W4395",
        "group_num": [
            "recK5kIpNbZhmLgJB"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1751,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFn0vHuELi9bEtA",
        "row": 4901,
        "state": "COLORADO",
        "group": "8Y519",
        "group_num": [
            "rec2ZWLZvDEGcsdwy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "2023": 0,
        "2024": 0,
        "id": "recG5SlDvgxNXJyq8",
        "row": 4861,
        "state": "COLORADO",
        "group": "4Q9727",
        "group_num": [
            "recHzb0VHBxxyKwnF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 555,
        "2023": 0,
        "2024": 0,
        "id": "recGcEMAf4YMbqA4u",
        "row": 4867,
        "state": "COLORADO",
        "group": "4Y5713",
        "group_num": [
            "recQx7T2XafxNn6ij"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHMJqBhSfhCiAQv",
        "row": 4881,
        "state": "COLORADO",
        "group": "6H4126",
        "group_num": [
            "reccJKZllfBBXgwCm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 23,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "recHP1HrUQFXmJR9X",
        "row": 4885,
        "state": "COLORADO",
        "group": "6S2268",
        "group_num": [
            "recWoebcgMOwbl4Rp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 52,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIzQz7OC18jbLoi",
        "row": 4875,
        "state": "COLORADO",
        "group": "5F2886",
        "group_num": [
            "recLkzDVT2jfaeEsa"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 598,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJovqZ5KiXPPeTw",
        "row": 4882,
        "state": "COLORADO",
        "group": "6H4126",
        "group_num": [
            "reccJKZllfBBXgwCm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 478,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK2oDqTPd3KUf0G",
        "row": 4876,
        "state": "COLORADO",
        "group": "5F4148",
        "group_num": [
            "recMhLDVeEt1TAdWz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 598,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNobyJCy0Y0FMYM",
        "row": 4873,
        "state": "COLORADO",
        "group": "4Y8456",
        "group_num": [
            "recVwuP2VoHP8IE67"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 250,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receQeTIYLo5zD2Wu",
        "row": 4914,
        "state": "COLORADO",
        "group": "10375",
        "group_num": [
            "recSHj5yvRMpoxCH4"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 224,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "receqxn1Tr8aAnm7D",
        "row": 4908,
        "state": "COLORADO",
        "group": "9X5383",
        "group_num": [
            "recqofVK0WGRITw65"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 23,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfzsq2EmVUaXJhx",
        "row": 4907,
        "state": "COLORADO",
        "group": "9R9064",
        "group_num": [
            "recpHRdPcukz5kIQq"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 0,
        "2024": 0,
        "id": "recgUpT9t6KQG6B3R",
        "row": 4999,
        "state": "COLORADO",
        "group": "587767",
        "group_num": [
            "recRuo6SJcSFamQLj"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 751,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgJWmKpLyZK70vF",
        "row": 4917,
        "state": "COLORADO",
        "group": "10800",
        "group_num": [
            "recuEOpeoycpUxpyj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1092,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg4rcbDAmPxBiCu",
        "row": 4949,
        "state": "COLORADO",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg55YaQPdlk0wgy",
        "row": 4903,
        "state": "COLORADO",
        "group": "8Y3339",
        "group_num": [
            "rec7so3io9RZNvbwm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 481,
        "2021": 500,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recg6mP6LPM7hQv0r",
        "row": 4956,
        "state": "COLORADO",
        "group": "191690",
        "group_num": [
            "rec3yY2185u4qq3kY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "2023": 0,
        "2024": 0,
        "id": "recg7z17KU8JbGOOl",
        "row": 4991,
        "state": "COLORADO",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 744,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg9wOa3piKcsGxZ",
        "row": 4922,
        "state": "COLORADO",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "rechfs07T1pDY53Ax",
        "row": 4947,
        "state": "COLORADO",
        "group": "182232",
        "group_num": [
            "reciYdWmLGxc7r7dB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 85,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciC2AMwX8vKzZ3Z",
        "row": 4982,
        "state": "COLORADO",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1517,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjyMUXDKCbjDWkv",
        "row": 4995,
        "state": "COLORADO",
        "group": "472666",
        "group_num": [
            "rec5pZEQF5GYcMxow"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 566,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjPn7RyQOIZFFsH",
        "row": 4921,
        "state": "COLORADO",
        "group": "12506",
        "group_num": [
            "recTN55y7En1VUMgA"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 354,
        "2023": 361,
        "2024": 0,
        "id": "recj6rsjdTWmLSyji",
        "row": 4954,
        "state": "COLORADO",
        "group": "189897",
        "group_num": [
            "reck9JAVPbC7v6txG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 763,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckFXrcUhhjaUvbK",
        "row": 4986,
        "state": "COLORADO",
        "group": "228604",
        "group_num": [
            "rec9IrknV6oIJ9TfE"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 113,
        "2022": 113,
        "2023": 0,
        "2024": 0,
        "id": "reclkCiUiv5qNENkq",
        "row": 4919,
        "state": "COLORADO",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 772,
        "2021": 759,
        "2022": 791,
        "2023": 0,
        "2024": 0,
        "id": "recm7oNe70EH1bTbR",
        "row": 4940,
        "state": "COLORADO",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 341,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnqPo9pnLtpmFpt",
        "row": 4979,
        "state": "COLORADO",
        "group": "227037",
        "group_num": [
            "recaQ5nBdJ3PdA316"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOyDCAWjSdx5GUf",
        "row": 4904,
        "state": "COLORADO",
        "group": "9F3840",
        "group_num": [
            "recCen7sCTkU3Jrsc"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1256,
        "2021": 1420,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recORo1efPKIWLEfq",
        "row": 4981,
        "state": "COLORADO",
        "group": "228081",
        "group_num": [
            "recfX7I7ZydSgpnik"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recO9DeyWVO5MzDmy",
        "row": 4915,
        "state": "COLORADO",
        "group": "10375",
        "group_num": [
            "recSHj5yvRMpoxCH4"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1523,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPjnfF9xiK8xbiP",
        "row": 4932,
        "state": "COLORADO",
        "group": "82751",
        "group_num": [
            "rectBqgsgHKoxZC9U"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4007,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQWgu8ps15ZRDXa",
        "row": 4939,
        "state": "COLORADO",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1038,
        "2023": 0,
        "2024": 0,
        "id": "recRVLqKWaOMPl2eV",
        "row": 4990,
        "state": "COLORADO",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3420,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRMEWUkLQ1ua8Yk",
        "row": 4936,
        "state": "COLORADO",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 854,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRiBzySMB644tVN",
        "row": 4960,
        "state": "COLORADO",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 566,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSTYpLkCUbZSIyA",
        "row": 4924,
        "state": "COLORADO",
        "group": "15336",
        "group_num": [
            "recw3OfqVFhEUI2XQ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 854,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSi65HIGEiQT1G2",
        "row": 4973,
        "state": "COLORADO",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 789,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT0dTmXz8bqCsBz",
        "row": 4963,
        "state": "COLORADO",
        "group": "193843",
        "group_num": [
            "rec68rRFYyFJaCRRC"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 783,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTzOMUdvn4XhxLr",
        "row": 4948,
        "state": "COLORADO",
        "group": "182569",
        "group_num": [
            "rec9D7oQ2KR6tytiP"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1058,
        "2023": 0,
        "2024": 0,
        "id": "recTCim9kdXm9Tgii",
        "row": 4994,
        "state": "COLORADO",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 80,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTFhzfHJPSCNyJl",
        "row": 4980,
        "state": "COLORADO",
        "group": "227037",
        "group_num": [
            "recaQ5nBdJ3PdA316"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 23,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVFxLYJxaCCG4R7",
        "row": 4910,
        "state": "COLORADO",
        "group": "00F2719",
        "group_num": [
            "recqKQuzFFC6UrpnR"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 619,
        "2021": 0,
        "2022": 176,
        "2023": 500,
        "2024": 0,
        "id": "recXzCEsq6hAkWiPa",
        "row": 4961,
        "state": "COLORADO",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 119,
        "2021": 130,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYcuMmNu7Vsz8RI",
        "row": 4975,
        "state": "COLORADO",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1854,
        "2021": 1484,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYnOmfRnUbst7Jz",
        "row": 4927,
        "state": "COLORADO",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 311,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZISa6WFYLQIXIK",
        "row": 4909,
        "state": "COLORADO",
        "group": "9Y3275",
        "group_num": [
            "rec3TqFe9L6MZMeEh"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3761,
        "2023": 0,
        "2024": 0,
        "id": "rec0yetNVKQyclhUz",
        "row": 4950,
        "state": "COLORADO",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 85,
        "2021": 84,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Kq20j5AR3LRG4",
        "row": 4928,
        "state": "COLORADO",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0rBP19bYA7ny2j",
        "row": 4943,
        "state": "COLORADO",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 477,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec35nzjMhyV4XKI7",
        "row": 4911,
        "state": "COLORADO",
        "group": "04L9778",
        "group_num": [
            "rec6X9kpifhKAWWqb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 715,
        "2021": 426,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3ridlRvdPfwVdZ",
        "row": 4966,
        "state": "COLORADO",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 29,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4iSj4dtgdwYcy0",
        "row": 4912,
        "state": "COLORADO",
        "group": "04L9778",
        "group_num": [
            "rec6X9kpifhKAWWqb"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4050,
        "2022": 4260,
        "2023": 0,
        "2024": 0,
        "id": "rec50hFuw4aYKQRLc",
        "row": 4983,
        "state": "COLORADO",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1410,
        "2023": 0,
        "2024": 0,
        "id": "rec5FyXj5KXURZMMW",
        "row": 4993,
        "state": "COLORADO",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3976,
        "2021": 1194,
        "2022": 956,
        "2023": 0,
        "2024": 0,
        "id": "rec6MEP8Z11Woe8NY",
        "row": 4958,
        "state": "COLORADO",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4500,
        "2023": 0,
        "2024": 0,
        "id": "rec7wz3ti1WZnI95R",
        "row": 4977,
        "state": "COLORADO",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 28,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "rec72FcVjFI4si1eh",
        "row": 4997,
        "state": "COLORADO",
        "group": "517145",
        "group_num": [
            "recwWNaEK0B6ffJVp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3787,
        "2021": 0,
        "2022": 539,
        "2023": 611,
        "2024": 0,
        "id": "rec7bmCqBrysBV8Tt",
        "row": 4959,
        "state": "COLORADO",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9HOpD0kDLQnS2C",
        "row": 4906,
        "state": "COLORADO",
        "group": "9R9064",
        "group_num": [
            "recpHRdPcukz5kIQq"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec97kiikfzvzclwu",
        "row": 4988,
        "state": "COLORADO",
        "group": "229050",
        "group_num": [
            "recNotInlf4GmwlOJ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 344,
        "2023": 0,
        "2024": 0,
        "id": "recaWPVZc5Qeb6Wl4",
        "row": 4951,
        "state": "COLORADO",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4086,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbZNABnZiBnQwQl",
        "row": 4970,
        "state": "COLORADO",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4062,
        "2022": 4285,
        "2023": 0,
        "2024": 0,
        "id": "reccspg7EZaIVFlKE",
        "row": 4984,
        "state": "COLORADO",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc7ZmF4leJx0xSB",
        "row": 4972,
        "state": "COLORADO",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2820,
        "2023": 0,
        "2024": 0,
        "id": "reccaNvtJscvFAXG9",
        "row": 4992,
        "state": "COLORADO",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 458,
        "2021": 787,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdDg1yt5xqZUfT0",
        "row": 4953,
        "state": "COLORADO",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4220,
        "2022": 950,
        "2023": 0,
        "2024": 0,
        "id": "reco4S5C5pJKKq2Sx",
        "row": 4976,
        "state": "COLORADO",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpTmQvBMKWFv5NC",
        "row": 4913,
        "state": "COLORADO",
        "group": "10375",
        "group_num": [
            "recSHj5yvRMpoxCH4"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 337,
        "2022": 355,
        "2023": 0,
        "2024": 0,
        "id": "recpXPXHhZMfGAGl6",
        "row": 4971,
        "state": "COLORADO",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1826,
        "2023": 0,
        "2024": 0,
        "id": "recptjDtoJkx8d1FM",
        "row": 4989,
        "state": "COLORADO",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq7KlqzVh9k6XHn",
        "row": 4944,
        "state": "COLORADO",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1105,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqnnMi8GiBGpsmT",
        "row": 4902,
        "state": "COLORADO",
        "group": "8Y519",
        "group_num": [
            "rec2ZWLZvDEGcsdwy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4171,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrfnTrGXMGbIs4s",
        "row": 4930,
        "state": "COLORADO",
        "group": "23000",
        "group_num": [
            "reczhYD9tFG7WWli8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3300,
        "2023": 0,
        "2024": 0,
        "id": "recteg1ltQ8HMFGH1",
        "row": 4925,
        "state": "COLORADO",
        "group": "15388",
        "group_num": [
            "recNzDrFbikErKH5a"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2625,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recteVhMuK3OlIRUa",
        "row": 4920,
        "state": "COLORADO",
        "group": "12506",
        "group_num": [
            "recTN55y7En1VUMgA"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 105,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuHdBwjp2DA3tUi",
        "row": 4964,
        "state": "COLORADO",
        "group": "193843",
        "group_num": [
            "rec68rRFYyFJaCRRC"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1011,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxEIceWLu43HrNm",
        "row": 4923,
        "state": "COLORADO",
        "group": "15336",
        "group_num": [
            "recw3OfqVFhEUI2XQ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy10UM2XmCgZeRj",
        "row": 4942,
        "state": "COLORADO",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyyplr6aYQ2fzW3",
        "row": 4905,
        "state": "COLORADO",
        "group": "9L1611",
        "group_num": [
            "recTT5hAEEVaDVmCL"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "reczLD8sO79igXJbu",
        "row": 4996,
        "state": "COLORADO",
        "group": "517145",
        "group_num": [
            "recwWNaEK0B6ffJVp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczoXemHuvNnLjWw",
        "row": 4934,
        "state": "COLORADO",
        "group": "020051",
        "group_num": [
            "recz7gY0TyXj66MDR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4156,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAXLqZMpVnyHBXR",
        "row": 4969,
        "state": "COLORADO",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 85,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAF0YlcftRPTJiB",
        "row": 4968,
        "state": "COLORADO",
        "group": "203922",
        "group_num": [
            "rec8URZAGYuXXlDUz"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "2023": 0,
        "2024": 0,
        "id": "recBUvNeqED8DViDV",
        "row": 4998,
        "state": "COLORADO",
        "group": "587767",
        "group_num": [
            "recRuo6SJcSFamQLj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2368,
        "2021": 0,
        "2022": 959,
        "2023": 0,
        "2024": 0,
        "id": "recBVxNG9a64O45iO",
        "row": 5001,
        "state": "COLORADO",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 5782,
        "2022": 4008,
        "2023": 0,
        "2024": 0,
        "id": "recBOEtZelxohb2zb",
        "row": 4926,
        "state": "COLORADO",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 320,
        "2021": 105,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBawWNTqBOoDNjs",
        "row": 4962,
        "state": "COLORADO",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 511,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC1iGBgKcUuYxHb",
        "row": 4985,
        "state": "COLORADO",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 956,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCv6SFoajVnjVm9",
        "row": 5000,
        "state": "COLORADO",
        "group": "700228",
        "group_num": [
            "rec12geA2GEGsdeIP"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 204,
        "2024": 0,
        "id": "recD0dO3JGO7isEeP",
        "row": 4955,
        "state": "COLORADO",
        "group": "189897",
        "group_num": [
            "reck9JAVPbC7v6txG"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 769,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDiGQbUFNRiRmjl",
        "row": 4916,
        "state": "COLORADO",
        "group": "10800",
        "group_num": [
            "recuEOpeoycpUxpyj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 39,
        "2021": 38,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEk5xSO43ygt2Ig",
        "row": 4967,
        "state": "COLORADO",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 83,
        "2022": 90,
        "2023": 0,
        "2024": 0,
        "id": "recFAwpK94SoN5ZYR",
        "row": 4941,
        "state": "COLORADO",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2613,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFALqQjHrSIRv80",
        "row": 4937,
        "state": "COLORADO",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFM0UyDQO7yPJlP",
        "row": 4987,
        "state": "COLORADO",
        "group": "228733",
        "group_num": [
            "recD7slkTdkq7NBkI"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 566,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFO1qAs9etNT2sI",
        "row": 4918,
        "state": "COLORADO",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 760,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGhFg1DggiJrJSU",
        "row": 4946,
        "state": "COLORADO",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 631,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIWmBke5CNrtAEh",
        "row": 4938,
        "state": "COLORADO",
        "group": "101250",
        "group_num": [
            "rec7ir7wptt78F8Ef"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 95,
        "2022": 97,
        "2023": 0,
        "2024": 0,
        "id": "recJGl9eNR1HYa6FE",
        "row": 4957,
        "state": "COLORADO",
        "group": "191690",
        "group_num": [
            "rec3yY2185u4qq3kY"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 610,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKzXO9uguSnhrlq",
        "row": 4974,
        "state": "COLORADO",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4171,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLzlFrQAPlkU9Un",
        "row": 4931,
        "state": "COLORADO",
        "group": "23000",
        "group_num": [
            "reczhYD9tFG7WWli8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3832,
        "2022": 2498,
        "2023": 0,
        "2024": 0,
        "id": "recL9YefruYJkXysp",
        "row": 4952,
        "state": "COLORADO",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3563,
        "2023": 0,
        "2024": 0,
        "id": "recLlw1PJh0X5xFPu",
        "row": 4978,
        "state": "COLORADO",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1084,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMdx2wSXi0xgmvl",
        "row": 4965,
        "state": "COLORADO",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNCWVbNDbI7qCoo",
        "row": 4933,
        "state": "COLORADO",
        "group": "93033",
        "group_num": [
            "recvZCSYGhLJxQdGQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 795,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN6Uv9fqAR4bHtZ",
        "row": 4935,
        "state": "COLORADO",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNdkTYSSDz2xTr4",
        "row": 4945,
        "state": "COLORADO",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1908,
        "2022": 1900,
        "2023": 0,
        "2024": 0,
        "id": "recfuSsvfscEdmddU",
        "row": 5026,
        "state": "COLORADO",
        "group": "703597",
        "group_num": [
            "rec7TUVPGN8lT2wJU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1912,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "recf6TRnujumyAGW6",
        "row": 5023,
        "state": "COLORADO",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recfcKP7yr221vHg2",
        "row": 5037,
        "state": "COLORADO",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 954,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgdRyX91Y8HBWoj",
        "row": 5021,
        "state": "COLORADO",
        "group": "702603",
        "group_num": [
            "rec6CloLUVsl4GGMX"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 238,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "rech7d5CJnksGim9s",
        "row": 5013,
        "state": "COLORADO",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 606,
        "2023": 0,
        "2024": 0,
        "id": "reci4YSAbpfoCeZlC",
        "row": 5031,
        "state": "COLORADO",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2175,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recl1yKIIzuYEFXeL",
        "row": 5029,
        "state": "COLORADO",
        "group": "704088",
        "group_num": [
            "recAfzxEy8ILKcwKm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1998,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclxBzvPskZPcaxJ",
        "row": 5051,
        "state": "COLORADO",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 75,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmUpnKXw7c1xhPP",
        "row": 5022,
        "state": "COLORADO",
        "group": "702603",
        "group_num": [
            "rec6CloLUVsl4GGMX"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 40,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPWuXCHVKY2fDSN",
        "row": 5041,
        "state": "COLORADO",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 500,
        "2024": 0,
        "id": "recSuKqeXyxLppaGF",
        "row": 5049,
        "state": "COLORADO",
        "group": "705437",
        "group_num": [
            "recvyR6whAm3Ky518"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTAiIaLdE7H21ZV",
        "row": 5017,
        "state": "COLORADO",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 0,
        "2024": 0,
        "id": "recTLiYrinqj61Leo",
        "row": 5030,
        "state": "COLORADO",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUcVa03e9JjzlU8",
        "row": 5045,
        "state": "COLORADO",
        "group": "704781",
        "group_num": [
            "rec092luKJuFFE4qv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYe82oz9d7VJrXi",
        "row": 5016,
        "state": "COLORADO",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZZlD7fRhcOmtPH",
        "row": 5009,
        "state": "COLORADO",
        "group": "701529",
        "group_num": [
            "recA0owFFGwhz7u2q"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 705,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0v3zxkfkWO8xcp",
        "row": 5033,
        "state": "COLORADO",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 0,
        "2022": 611,
        "2023": 0,
        "2024": 0,
        "id": "rec1aHBp5oido3og0",
        "row": 5012,
        "state": "COLORADO",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 100,
        "2021": 30,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "rec29x9eXcM2ehYX6",
        "row": 5020,
        "state": "COLORADO",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1082,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4eLqXHjfKsZnjJ",
        "row": 5018,
        "state": "COLORADO",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 756,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec59B1allOo0HjVu",
        "row": 5039,
        "state": "COLORADO",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1152,
        "2021": 847,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6ietAGZS8xL6fo",
        "row": 5032,
        "state": "COLORADO",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9BdqKxhqHSSIGb",
        "row": 5043,
        "state": "COLORADO",
        "group": "704773",
        "group_num": [
            "recSk5aSoFatLnmVU"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 343,
        "2022": 344,
        "2023": 0,
        "2024": 0,
        "id": "recafr1UZiV3gJoJW",
        "row": 5010,
        "state": "COLORADO",
        "group": "701529",
        "group_num": [
            "recA0owFFGwhz7u2q"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 798,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbH84xiC1rL7pc6",
        "row": 5015,
        "state": "COLORADO",
        "group": "702428",
        "group_num": [
            "recJTsrh9ahbBptkk"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbk5mneKftVOfYQ",
        "row": 5014,
        "state": "COLORADO",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 75,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdN51SaOntmDB0j",
        "row": 5008,
        "state": "COLORADO",
        "group": "701254",
        "group_num": [
            "recDv7zzG1yVALFPB"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 852,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recolK6zMiEFVuQVF",
        "row": 5011,
        "state": "COLORADO",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2520,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp7Ex3ubxvwpicH",
        "row": 5050,
        "state": "COLORADO",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 381,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqGmvak5Fg5m5g2",
        "row": 5007,
        "state": "COLORADO",
        "group": "701254",
        "group_num": [
            "recDv7zzG1yVALFPB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsSPF4ikIIqjD1i",
        "row": 5042,
        "state": "COLORADO",
        "group": "704773",
        "group_num": [
            "recSk5aSoFatLnmVU"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 918,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuPTly9PtSCU5lU",
        "row": 5044,
        "state": "COLORADO",
        "group": "704781",
        "group_num": [
            "rec092luKJuFFE4qv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1663,
        "2021": 0,
        "2022": 2013,
        "2023": 0,
        "2024": 0,
        "id": "recvLpvjpMQt730Lo",
        "row": 5038,
        "state": "COLORADO",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 40,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvmYjUDHtnjaogJ",
        "row": 5048,
        "state": "COLORADO",
        "group": "705241",
        "group_num": [
            "recPruJAeC2nBRh8m"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 74,
        "2021": 75,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwotwe1h3mJ42UN",
        "row": 5006,
        "state": "COLORADO",
        "group": "701043",
        "group_num": [
            "rec6nb1Khx7jM4YoE"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 415,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxiXHr9S8OHGZ2f",
        "row": 5005,
        "state": "COLORADO",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 918,
        "2022": 930,
        "2023": 0,
        "2024": 0,
        "id": "reczP54INupsAgTs8",
        "row": 5034,
        "state": "COLORADO",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1394,
        "2022": 1483,
        "2023": 0,
        "2024": 0,
        "id": "recB6lAXjsYdmvdLQ",
        "row": 5003,
        "state": "COLORADO",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1839,
        "2021": 0,
        "2022": 593,
        "2023": 0,
        "2024": 0,
        "id": "recCErHZoep1gYXAF",
        "row": 5002,
        "state": "COLORADO",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1955,
        "2022": 596,
        "2023": 0,
        "2024": 0,
        "id": "recDsQisi2WXq3oKh",
        "row": 5024,
        "state": "COLORADO",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 0,
        "2024": 0,
        "id": "recFFly6WZnWzBDNN",
        "row": 5036,
        "state": "COLORADO",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGSnmdhM5GtvP7F",
        "row": 5028,
        "state": "COLORADO",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 772,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGTjqFV1ZwJYLRC",
        "row": 5027,
        "state": "COLORADO",
        "group": "703916",
        "group_num": [
            "reckXhDHt4qs3xgQv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 978,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGDZ0v9LHyUFDBg",
        "row": 5004,
        "state": "COLORADO",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 23,
        "2023": 0,
        "2024": 0,
        "id": "recG5YnL7OfCYxTQ9",
        "row": 5047,
        "state": "COLORADO",
        "group": "704781",
        "group_num": [
            "rec092luKJuFFE4qv"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1107,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHubztgpmrGYO9W",
        "row": 5040,
        "state": "COLORADO",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 611,
        "2024": 0,
        "id": "recIssnZPRhGx0DzX",
        "row": 5035,
        "state": "COLORADO",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 653,
        "2021": 249,
        "2022": 148,
        "2023": 0,
        "2024": 0,
        "id": "recIRljTSmvfxlAFJ",
        "row": 5019,
        "state": "COLORADO",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 147,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "recIm50Q2v4BQ4V8Y",
        "row": 5046,
        "state": "COLORADO",
        "group": "704781",
        "group_num": [
            "rec092luKJuFFE4qv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2400,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMrFXnPCIJIKAyp",
        "row": 5025,
        "state": "COLORADO",
        "group": "703597",
        "group_num": [
            "rec7TUVPGN8lT2wJU"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfMtlua4OwKiLHl",
        "row": 5076,
        "state": "COLORADO",
        "group": "714273",
        "group_num": [
            "recPfLHDEUv2J71E3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4175,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg19QrBkSeCDHTW",
        "row": 5104,
        "state": "COLORADO",
        "group": "718733",
        "group_num": [
            "recIUmB9mrmjYIX4b"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 78,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgf1Ao8eJBhhTt7",
        "row": 5146,
        "state": "COLORADO",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 240,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recghaDjiohZ3QGS1",
        "row": 5137,
        "state": "COLORADO",
        "group": "755858",
        "group_num": [
            "recMImNuDgDdXQFyf"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3711,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjAbXV8eEn5zgdK",
        "row": 5129,
        "state": "COLORADO",
        "group": "753754",
        "group_num": [
            "rec7CHWuph9SMd9PO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1523,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjgZggivfGYYLoc",
        "row": 5120,
        "state": "COLORADO",
        "group": "742864",
        "group_num": [
            "recp0x0OuJ9L6EcF0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 87,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckm99NDJkz46gYF",
        "row": 5141,
        "state": "COLORADO",
        "group": "902252",
        "group_num": [
            "rec2DZA6ZKqDLdzV1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1523,
        "2022": 1523,
        "2023": 0,
        "2024": 0,
        "id": "reclUg0GZSG33I7qq",
        "row": 5052,
        "state": "COLORADO",
        "group": "706662",
        "group_num": [
            "rec0TUXNlOK9M4Rm0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1663,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclLy2ZowQf2zkVh",
        "row": 5112,
        "state": "COLORADO",
        "group": "742139",
        "group_num": [
            "recCr8c6xysD8vUqe"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2063,
        "2023": 0,
        "2024": 0,
        "id": "recmVdxtZc4Hwpf8A",
        "row": 5128,
        "state": "COLORADO",
        "group": "752936",
        "group_num": [
            "rec83fxPYpEtAHa8p"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 21,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn98jGb31kPBM43",
        "row": 5121,
        "state": "COLORADO",
        "group": "743853",
        "group_num": [
            "rec6U57fScAcJJk0u"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 150,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnhXwbgBgfw3DOG",
        "row": 5136,
        "state": "COLORADO",
        "group": "755730",
        "group_num": [
            "rec3dL4u0hwRDtwSm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1380,
        "2023": 0,
        "2024": 0,
        "id": "recOhPb63VWVoJOic",
        "row": 5056,
        "state": "COLORADO",
        "group": "708691",
        "group_num": [
            "recy0jZVlY8vGWlpv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 32,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPJowXUhNi06ud3",
        "row": 5118,
        "state": "COLORADO",
        "group": "742857",
        "group_num": [
            "rec2EN218TELGd71y"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 23,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP3sYVBbSRtxXO1",
        "row": 5071,
        "state": "COLORADO",
        "group": "712526",
        "group_num": [
            "recX00o2HlYjhZu5o"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2175,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPi8HYIE3LJy18r",
        "row": 5107,
        "state": "COLORADO",
        "group": "729803",
        "group_num": [
            "recyLlfMRyZEkA6gt"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 53,
        "2023": 0,
        "2024": 0,
        "id": "recQUMIsaQen7xBEB",
        "row": 5053,
        "state": "COLORADO",
        "group": "706662",
        "group_num": [
            "rec0TUXNlOK9M4Rm0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 46,
        "2023": 0,
        "2024": 0,
        "id": "recQt3X2NY07z9D73",
        "row": 5096,
        "state": "COLORADO",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 833,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQwRk45hJRWsnvs",
        "row": 5116,
        "state": "COLORADO",
        "group": "742857",
        "group_num": [
            "rec2EN218TELGd71y"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 85,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR2Lox6H7LqHbxj",
        "row": 5109,
        "state": "COLORADO",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRmK4QeioI9HxCj",
        "row": 5082,
        "state": "COLORADO",
        "group": "715316",
        "group_num": [
            "recTqHf9tJrfVrMRR"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 29,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRnpKomYEXBPjG9",
        "row": 5150,
        "state": "COLORADO",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 365,
        "2021": 200,
        "2022": 99,
        "2023": 0,
        "2024": 0,
        "id": "recTvR7MNQLTLNKFb",
        "row": 5135,
        "state": "COLORADO",
        "group": "755320",
        "group_num": [
            "recscFxM6UB0sGBWw"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 611,
        "2023": 0,
        "2024": 0,
        "id": "recWLMlNBhV8r6y37",
        "row": 5055,
        "state": "COLORADO",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4234,
        "2023": 0,
        "2024": 0,
        "id": "recWf47341JRI5Okl",
        "row": 5143,
        "state": "COLORADO",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXoJ0g9rcmjh1LC",
        "row": 5065,
        "state": "COLORADO",
        "group": "709729",
        "group_num": [
            "rectBvJgycUnYpKG7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYx9NTBJ1xoaAmx",
        "row": 5074,
        "state": "COLORADO",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1442,
        "2023": 0,
        "2024": 0,
        "id": "recYeAqLfbRMQATmU",
        "row": 5122,
        "state": "COLORADO",
        "group": "744644",
        "group_num": [
            "rec9YUVxWvxVZm3hN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 77,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYeZZnovjhDDrFf",
        "row": 5127,
        "state": "COLORADO",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1324,
        "2023": 611,
        "2024": 0,
        "id": "recZxSFmhDSiegw3X",
        "row": 5102,
        "state": "COLORADO",
        "group": "717591",
        "group_num": [
            "rec8Wzp2SZIudUgsZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 40,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec24Mp5UvMUR3eDg",
        "row": 5100,
        "state": "COLORADO",
        "group": "717461",
        "group_num": [
            "rec6tjvgNuVTNXpYl"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 228,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "rec3yUohf2dtJ6OYG",
        "row": 5084,
        "state": "COLORADO",
        "group": "715316",
        "group_num": [
            "recTqHf9tJrfVrMRR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 843,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec38mP4NXSEAFR4u",
        "row": 5138,
        "state": "COLORADO",
        "group": "755875",
        "group_num": [
            "rectSsQBSeAmNsJPN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "2023": 0,
        "2024": 0,
        "id": "rec3pVbbBLhcupI5E",
        "row": 5123,
        "state": "COLORADO",
        "group": "744644",
        "group_num": [
            "rec9YUVxWvxVZm3hN"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3842,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4eiKxmb6S07knG",
        "row": 5147,
        "state": "COLORADO",
        "group": "904820",
        "group_num": [
            "rec7epBTHjhGmW8OS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 147,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8TziUERgOxgmIB",
        "row": 5059,
        "state": "COLORADO",
        "group": "709318",
        "group_num": [
            "recTtUbx8TWppdbdW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 855,
        "2021": 944,
        "2022": 219,
        "2023": 0,
        "2024": 0,
        "id": "rec8ZZB9W9XUyxRIn",
        "row": 5095,
        "state": "COLORADO",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 286,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8vJbFXmQZPosOx",
        "row": 5099,
        "state": "COLORADO",
        "group": "717461",
        "group_num": [
            "rec6tjvgNuVTNXpYl"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec86ktv2oj7Kr7Ad",
        "row": 5113,
        "state": "COLORADO",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1895,
        "2022": 1895,
        "2023": 0,
        "2024": 0,
        "id": "rec86IR1mgTnB4t6p",
        "row": 5103,
        "state": "COLORADO",
        "group": "717823",
        "group_num": [
            "recHUq7xY2OGQqSMw"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 763,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec89F2mRGXveuDqi",
        "row": 5117,
        "state": "COLORADO",
        "group": "742857",
        "group_num": [
            "rec2EN218TELGd71y"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8bzLJFkj0KDT45",
        "row": 5148,
        "state": "COLORADO",
        "group": "905000",
        "group_num": [
            "rechIzi3wtVYkD7P0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1264,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8eu8kU7GTvcFqt",
        "row": 5069,
        "state": "COLORADO",
        "group": "712526",
        "group_num": [
            "recX00o2HlYjhZu5o"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 62,
        "2022": 39,
        "2023": 0,
        "2024": 0,
        "id": "rec9Jp0iuHH70XgGO",
        "row": 5115,
        "state": "COLORADO",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbndFTj4aHl8Q0q",
        "row": 5110,
        "state": "COLORADO",
        "group": "730491",
        "group_num": [
            "recivDLYKPJ7J1as4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 510,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbqiaZfC70HN2Ey",
        "row": 5131,
        "state": "COLORADO",
        "group": "753754",
        "group_num": [
            "rec7CHWuph9SMd9PO"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4401,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccA6MQm61snopZo",
        "row": 5142,
        "state": "COLORADO",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1168,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccMNf0wDXsttGzn",
        "row": 5063,
        "state": "COLORADO",
        "group": "709715",
        "group_num": [
            "recNf9erZ6n7oShf6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1413,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdF0739sRUzSubq",
        "row": 5098,
        "state": "COLORADO",
        "group": "717461",
        "group_num": [
            "rec6tjvgNuVTNXpYl"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1734,
        "2021": 1924,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoIDlnQso43luZy",
        "row": 5094,
        "state": "COLORADO",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1271,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoiKuySGloMyOTR",
        "row": 5086,
        "state": "COLORADO",
        "group": "715894",
        "group_num": [
            "recftJZ4SJkI4UgsF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 797,
        "2021": 763,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpUOHBLqt9pKgAA",
        "row": 5140,
        "state": "COLORADO",
        "group": "902252",
        "group_num": [
            "rec2DZA6ZKqDLdzV1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 71,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp0GLr41LmRMxMK",
        "row": 5068,
        "state": "COLORADO",
        "group": "711014",
        "group_num": [
            "recBgWWuMVvMYGYmH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqMoh150HUbJzU6",
        "row": 5111,
        "state": "COLORADO",
        "group": "742139",
        "group_num": [
            "recCr8c6xysD8vUqe"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 77,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqjhiALq4fsvCN5",
        "row": 5106,
        "state": "COLORADO",
        "group": "718733",
        "group_num": [
            "recIUmB9mrmjYIX4b"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqnNjgq3KsikksN",
        "row": 5091,
        "state": "COLORADO",
        "group": "717168",
        "group_num": [
            "recRg9TfgSUgMKk2n"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1523,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recqr9MfA0Gy5IdzQ",
        "row": 5114,
        "state": "COLORADO",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recrYurMx6aFV8hVI",
        "row": 5067,
        "state": "COLORADO",
        "group": "710325",
        "group_num": [
            "recepp33M9tiXnOIu"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 611,
        "2023": 0,
        "2024": 0,
        "id": "recrfZkH6Or6AZ9TT",
        "row": 5133,
        "state": "COLORADO",
        "group": "754336",
        "group_num": [
            "recbD41yyZEgASIcZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 64,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsXd716x4GWRvb9",
        "row": 5087,
        "state": "COLORADO",
        "group": "715894",
        "group_num": [
            "recftJZ4SJkI4UgsF"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 347,
        "2023": 0,
        "2024": 0,
        "id": "recsgjnUoUAJfa7LU",
        "row": 5057,
        "state": "COLORADO",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 77,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect9Mz8JALP5pU7l",
        "row": 5092,
        "state": "COLORADO",
        "group": "717262",
        "group_num": [
            "rec0Uu5by44vDR1UL"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 760,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectje6MS3ZtGHvZo",
        "row": 5064,
        "state": "COLORADO",
        "group": "709715",
        "group_num": [
            "recNf9erZ6n7oShf6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1994,
        "2023": 2175,
        "2024": 0,
        "id": "recvSeY7WTxlDJQ86",
        "row": 5075,
        "state": "COLORADO",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4603,
        "2021": 1721,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvOXgdgapGnseJv",
        "row": 5093,
        "state": "COLORADO",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1545,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv5Nj9CpUoIu1Hf",
        "row": 5097,
        "state": "COLORADO",
        "group": "717340",
        "group_num": [
            "rec3vXd2pSHt9LLQv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1629,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwELltB7EJfViAO",
        "row": 5125,
        "state": "COLORADO",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 0,
        "2024": 0,
        "id": "recyx7LRaA7cr8grC",
        "row": 5132,
        "state": "COLORADO",
        "group": "754336",
        "group_num": [
            "recbD41yyZEgASIcZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyDb6fP6GZbKaji",
        "row": 5119,
        "state": "COLORADO",
        "group": "742864",
        "group_num": [
            "recp0x0OuJ9L6EcF0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1221,
        "2023": 0,
        "2024": 0,
        "id": "reczvxGCk3DL5XhKn",
        "row": 5080,
        "state": "COLORADO",
        "group": "715165",
        "group_num": [
            "recoRamj91oTxEJ9A"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 95,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recASJn03LVHJmyo3",
        "row": 5090,
        "state": "COLORADO",
        "group": "716642",
        "group_num": [
            "recXw4eYhCsqGRe7l"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1523,
        "2023": 0,
        "2024": 0,
        "id": "recAVa3nZM9XGmZUz",
        "row": 5054,
        "state": "COLORADO",
        "group": "706944",
        "group_num": [
            "recZAghWw67jHR9Jr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 657,
        "2021": 923,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAVR7POZUM04eYv",
        "row": 5089,
        "state": "COLORADO",
        "group": "716642",
        "group_num": [
            "recXw4eYhCsqGRe7l"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 191,
        "2023": 191,
        "2024": 0,
        "id": "recBQgpqutyTjTKXF",
        "row": 5081,
        "state": "COLORADO",
        "group": "715165",
        "group_num": [
            "recoRamj91oTxEJ9A"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCZk4BzYP1CI5BU",
        "row": 5149,
        "state": "COLORADO",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2323,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDIcLGauRKtKVDj",
        "row": 5124,
        "state": "COLORADO",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 365,
        "2021": 365,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDKRvBGZVBZed6f",
        "row": 5134,
        "state": "COLORADO",
        "group": "755320",
        "group_num": [
            "recscFxM6UB0sGBWw"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1413,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEF22ah7NKfZtH8",
        "row": 5101,
        "state": "COLORADO",
        "group": "717591",
        "group_num": [
            "rec8Wzp2SZIudUgsZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 823,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEMlksahcQpZ484",
        "row": 5126,
        "state": "COLORADO",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 0,
        "2024": 0,
        "id": "recFQ2Aw1YUh4kwan",
        "row": 5078,
        "state": "COLORADO",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 611,
        "2023": 0,
        "2024": 0,
        "id": "recG1GHGukOHeYeE2",
        "row": 5079,
        "state": "COLORADO",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 150,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGq8HG43UcGosVa",
        "row": 5062,
        "state": "COLORADO",
        "group": "709540",
        "group_num": [
            "rec4gUQmofHff01z4"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHxrSR89s3OqBZG",
        "row": 5083,
        "state": "COLORADO",
        "group": "715316",
        "group_num": [
            "recTqHf9tJrfVrMRR"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 751,
        "2021": 344,
        "2022": 540,
        "2023": 0,
        "2024": 0,
        "id": "recHDmB2ygBj9hm3a",
        "row": 5145,
        "state": "COLORADO",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 194,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHO28eKHZUDamlZ",
        "row": 5073,
        "state": "COLORADO",
        "group": "712603",
        "group_num": [
            "recq4QNZUh93MB31I"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3938,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHpVxd6AhRBi3DV",
        "row": 5060,
        "state": "COLORADO",
        "group": "709540",
        "group_num": [
            "rec4gUQmofHff01z4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recIMTl6b6VHvhDfB",
        "row": 5085,
        "state": "COLORADO",
        "group": "715316",
        "group_num": [
            "recTqHf9tJrfVrMRR"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recI6GC3IBNtHeLUM",
        "row": 5077,
        "state": "COLORADO",
        "group": "714273",
        "group_num": [
            "recPfLHDEUv2J71E3"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIj8clBd028B37V",
        "row": 5151,
        "state": "COLORADO",
        "group": "905381",
        "group_num": [
            "reciWRMEJeZEsLdel"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 854,
        "2021": 1434,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJCSKd77FxfNeid",
        "row": 5088,
        "state": "COLORADO",
        "group": "716642",
        "group_num": [
            "recXw4eYhCsqGRe7l"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2066,
        "2021": 2066,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJknjtLGxNTj4Pc",
        "row": 5108,
        "state": "COLORADO",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3625,
        "2021": 4062,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJmXadNibxEjnfH",
        "row": 5072,
        "state": "COLORADO",
        "group": "712603",
        "group_num": [
            "recq4QNZUh93MB31I"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 763,
        "2021": 770,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKmOjz9j3nTjsFW",
        "row": 5066,
        "state": "COLORADO",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 647,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKnZJY5J8PblZXF",
        "row": 5070,
        "state": "COLORADO",
        "group": "712526",
        "group_num": [
            "recX00o2HlYjhZu5o"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 764,
        "2021": 605,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLLPAZpGJRwOib8",
        "row": 5105,
        "state": "COLORADO",
        "group": "718733",
        "group_num": [
            "recIUmB9mrmjYIX4b"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 0,
        "2024": 0,
        "id": "recLOzMzIU2XFDn2k",
        "row": 5144,
        "state": "COLORADO",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 763,
        "2021": 787,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLoApecwHH2zzaW",
        "row": 5061,
        "state": "COLORADO",
        "group": "709540",
        "group_num": [
            "rec4gUQmofHff01z4"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 833,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMToikHi05Gz1UY",
        "row": 5139,
        "state": "COLORADO",
        "group": "901926",
        "group_num": [
            "recn8n5RnwQU7EhED"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3892,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNVQX3qoNL1NxeK",
        "row": 5130,
        "state": "COLORADO",
        "group": "753754",
        "group_num": [
            "rec7CHWuph9SMd9PO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 110,
        "2023": 110,
        "2024": 0,
        "id": "recNtMPoJDdbtMyZg",
        "row": 5058,
        "state": "COLORADO",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3058,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receimdn0Pt8XiotF",
        "row": 5193,
        "state": "COLORADO",
        "group": "915571",
        "group_num": [
            "reclpFt0dWCstxjNE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech5s8220ncsVJ9A",
        "row": 5189,
        "state": "COLORADO",
        "group": "915343",
        "group_num": [
            "reclhltbyul88Wduo"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1050,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recizOILIKLStiH3m",
        "row": 5201,
        "state": "COLORADO",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 734,
        "2023": 0,
        "2024": 0,
        "id": "reciPBjFgCSP5o6H4",
        "row": 5180,
        "state": "COLORADO",
        "group": "914108",
        "group_num": [
            "reckXzXuVPg6K75FH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjZFpjqpwHOxADl",
        "row": 5206,
        "state": "COLORADO",
        "group": "919238",
        "group_num": [
            "recrIZOYy1JhASqju"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 134,
        "2024": 0,
        "id": "recjQPOmtevX4nMBH",
        "row": 5229,
        "state": "COLORADO",
        "group": "1427704",
        "group_num": [
            "receI4ZtjOOr58py5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckOK1IcTlA6cKZv",
        "row": 5196,
        "state": "COLORADO",
        "group": "915651",
        "group_num": [
            "recrpog9Rxye8aip0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1663,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclJnn1hte5qqsOZ",
        "row": 5200,
        "state": "COLORADO",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1084,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm0VGiEeU16gqcu",
        "row": 5202,
        "state": "COLORADO",
        "group": "917661",
        "group_num": [
            "recLoxKAKj0KlExVn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "2023": 0,
        "2024": 0,
        "id": "recmCP6jPrKn1WDOt",
        "row": 5157,
        "state": "COLORADO",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 523,
        "2023": 0,
        "2024": 0,
        "id": "recnxGDsl5ePFqQze",
        "row": 5161,
        "state": "COLORADO",
        "group": "907728",
        "group_num": [
            "recqdoIPvdXWQwMI6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1663,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnKE5t7t7N3fCmH",
        "row": 5192,
        "state": "COLORADO",
        "group": "915377",
        "group_num": [
            "recZzpePK6V9lLhFy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOyjgDtFIHje4AD",
        "row": 5199,
        "state": "COLORADO",
        "group": "916183",
        "group_num": [
            "rec0FeqezIs9k49yD"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2233,
        "2023": 2233,
        "2024": 0,
        "id": "recO6x9dy1ooiAOrI",
        "row": 5249,
        "state": "COLORADO",
        "group": "644773836506",
        "group_num": [
            "rec1Nh7hhpa29oENF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ7AERnikKt6UnF",
        "row": 5194,
        "state": "COLORADO",
        "group": "915571",
        "group_num": [
            "reclpFt0dWCstxjNE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 956,
        "2023": 0,
        "2024": 0,
        "id": "recRv88wd5YeHZSph",
        "row": 5156,
        "state": "COLORADO",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 254,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRKQ22pWdF6wQb7",
        "row": 5198,
        "state": "COLORADO",
        "group": "916183",
        "group_num": [
            "rec0FeqezIs9k49yD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSUbQ5gG823SWw2",
        "row": 5172,
        "state": "COLORADO",
        "group": "911297",
        "group_num": [
            "recQXfRXpLV5Oe7Wt"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 242,
        "2023": 0,
        "2024": 0,
        "id": "recSiGUaVyAK8fAK0",
        "row": 5225,
        "state": "COLORADO",
        "group": "926950",
        "group_num": [
            "recJeEXW9E4DlL7kz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTs7NZaDlFUUunS",
        "row": 5164,
        "state": "COLORADO",
        "group": "908168",
        "group_num": [
            "rec1TfjrQ9rCCnaYF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1065,
        "2024": 0,
        "id": "recTwEk3QwmUMY9JC",
        "row": 5168,
        "state": "COLORADO",
        "group": "909166",
        "group_num": [
            "recXgZCi84UdHUHVD"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 745,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTn1yv9N5jOkEtl",
        "row": 5155,
        "state": "COLORADO",
        "group": "906166",
        "group_num": [
            "recO7veGhCZcjCqKV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 324,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTrNQUe9ZIU8QGx",
        "row": 5250,
        "state": "COLORADO",
        "group": "2020200181611",
        "group_num": [
            "reck3hHIKeCb6BAjL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 500,
        "2024": 0,
        "id": "recUj2nlhabJMmU2E",
        "row": 5227,
        "state": "COLORADO",
        "group": "929444",
        "group_num": [
            "recjnWUeMFTWxqVFF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4084,
        "2022": 0,
        "2023": 1099,
        "2024": 0,
        "id": "recVNwdtETsE8Lyeb",
        "row": 5174,
        "state": "COLORADO",
        "group": "911364",
        "group_num": [
            "recBEe2SuKHTWqovW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWXpxkz0ktACp9Q",
        "row": 5235,
        "state": "COLORADO",
        "group": "2277943",
        "group_num": [
            "rechpoAorHcb4Q12A"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 1377,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWzKoqBNMAAfL6R",
        "row": 5159,
        "state": "COLORADO",
        "group": "907073",
        "group_num": [
            "recvSD7qUU72vHDJe"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2555,
        "2023": 0,
        "2024": 0,
        "id": "recWGaVkSxOOQkDy4",
        "row": 5247,
        "state": "COLORADO",
        "group": "202158931",
        "group_num": [
            "rec2hgqbVqYU9xmOX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWLCTcSHd8tD6Mj",
        "row": 5210,
        "state": "COLORADO",
        "group": "921186",
        "group_num": [
            "recMlYnOw5Hi7qKME"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4865,
        "2023": 0,
        "2024": 0,
        "id": "recWjZA5lfaGfJzH6",
        "row": 5246,
        "state": "COLORADO",
        "group": "202158931",
        "group_num": [
            "rec2hgqbVqYU9xmOX"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 597,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYHNCfrAxMKEuTQ",
        "row": 5212,
        "state": "COLORADO",
        "group": "921723",
        "group_num": [
            "recm6d672nCB1RIQn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 790,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY7RJ95VjZuiDFV",
        "row": 5162,
        "state": "COLORADO",
        "group": "907728",
        "group_num": [
            "recqdoIPvdXWQwMI6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 0,
        "2024": 0,
        "id": "recZJLKpjwUFW9yVf",
        "row": 5160,
        "state": "COLORADO",
        "group": "907728",
        "group_num": [
            "recqdoIPvdXWQwMI6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2066,
        "2021": 2074,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Fij16DE0OTEOo",
        "row": 5231,
        "state": "COLORADO",
        "group": "2245220",
        "group_num": [
            "recGgTMbEoKX6apxe"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2066,
        "2021": 2066,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0KqKiZ5icrlFw4",
        "row": 5237,
        "state": "COLORADO",
        "group": "2459480",
        "group_num": [
            "recTHCWB5S5FfQeAZ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 27,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1YiLHky5l6JoeU",
        "row": 5190,
        "state": "COLORADO",
        "group": "915343",
        "group_num": [
            "reclhltbyul88Wduo"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 969,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1p9iQHkY1fsATz",
        "row": 5197,
        "state": "COLORADO",
        "group": "915671",
        "group_num": [
            "recRmd6bCdtrxY2Go"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3vymM36HHazEsn",
        "row": 5179,
        "state": "COLORADO",
        "group": "913496",
        "group_num": [
            "recxwZKQBSr3MkU1G"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1775,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3JwWPvZjcLJotK",
        "row": 5181,
        "state": "COLORADO",
        "group": "914406",
        "group_num": [
            "recEvD2hEE85QpuGm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3kCKyKfXYdYVVY",
        "row": 5240,
        "state": "COLORADO",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 83,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4Ay6EmME4L57CM",
        "row": 5232,
        "state": "COLORADO",
        "group": "2245220",
        "group_num": [
            "recGgTMbEoKX6apxe"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 80,
        "2023": 92,
        "2024": 0,
        "id": "rec7sYLJAudKGc7fo",
        "row": 5185,
        "state": "COLORADO",
        "group": "915152",
        "group_num": [
            "recUdjT0I2WAbtp6x"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4498,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8WWrWHLLjipUO7",
        "row": 5173,
        "state": "COLORADO",
        "group": "911364",
        "group_num": [
            "recBEe2SuKHTWqovW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4257,
        "2023": 4325,
        "2024": 0,
        "id": "rec8PfY16EHntEZ0H",
        "row": 5167,
        "state": "COLORADO",
        "group": "909006",
        "group_num": [
            "recdGM4oiCZI8w11w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3022,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec86wFaxThYkXtTQ",
        "row": 5213,
        "state": "COLORADO",
        "group": "922556",
        "group_num": [
            "recxIqnG9IH7HnPnw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 204,
        "2023": 0,
        "2024": 0,
        "id": "rec8mUN3O80pE4tQH",
        "row": 5218,
        "state": "COLORADO",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2760,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9WOLyutvbWHqT8",
        "row": 5234,
        "state": "COLORADO",
        "group": "2255567",
        "group_num": [
            "recv6QvdRyE2SQH3E"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9s1CWBnQS1luX2",
        "row": 5208,
        "state": "COLORADO",
        "group": "921171",
        "group_num": [
            "recRQLyH8uXZwh70i"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2820,
        "2023": 0,
        "2024": 0,
        "id": "rec9vtVX9RgrvrEvh",
        "row": 5248,
        "state": "COLORADO",
        "group": "644773836506",
        "group_num": [
            "rec1Nh7hhpa29oENF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec99zZEThNoACBZV",
        "row": 5216,
        "state": "COLORADO",
        "group": "923351",
        "group_num": [
            "recQzhQclWBPq2zVk"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaPEV00bthypvVx",
        "row": 5191,
        "state": "COLORADO",
        "group": "915377",
        "group_num": [
            "recZzpePK6V9lLhFy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "2023": 0,
        "2024": 0,
        "id": "recbTPwEpe59Laasq",
        "row": 5223,
        "state": "COLORADO",
        "group": "925342",
        "group_num": [
            "recTG7Kj2TvbqWORo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbUMupOzS4l5KIK",
        "row": 5211,
        "state": "COLORADO",
        "group": "921723",
        "group_num": [
            "recm6d672nCB1RIQn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb10xzmW09kr5Yo",
        "row": 5169,
        "state": "COLORADO",
        "group": "909847",
        "group_num": [
            "recVLNbnOiNsQPYwm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 872,
        "2023": 0,
        "2024": 0,
        "id": "recbErALNMvIMiN8U",
        "row": 5214,
        "state": "COLORADO",
        "group": "922623",
        "group_num": [
            "recnXWeOjp4rql1G6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4391,
        "2023": 0,
        "2024": 0,
        "id": "recc1ZGCiOzmEGb0c",
        "row": 5166,
        "state": "COLORADO",
        "group": "909006",
        "group_num": [
            "recdGM4oiCZI8w11w"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccChiC3vjfRLWUx",
        "row": 5241,
        "state": "COLORADO",
        "group": "10000007",
        "group_num": [
            "recDCRGP8CVH4cGZh"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccGlkqU5dAG3mB3",
        "row": 5153,
        "state": "COLORADO",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 744,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoOsxlKlBwhYKfU",
        "row": 5176,
        "state": "COLORADO",
        "group": "911813",
        "group_num": [
            "recMqS4UpSwzO18rk"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 872,
        "2024": 0,
        "id": "reco278fKCvlz6CSN",
        "row": 5163,
        "state": "COLORADO",
        "group": "908168",
        "group_num": [
            "rec1TfjrQ9rCCnaYF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 5700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recq2rO3CqatJN4kZ",
        "row": 5238,
        "state": "COLORADO",
        "group": "2459482",
        "group_num": [
            "recMl9wkjwFkVLuFx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 150,
        "2024": 0,
        "id": "recq4MpGZ7gTMdnr6",
        "row": 5226,
        "state": "COLORADO",
        "group": "928305",
        "group_num": [
            "recw3BmnUFhBqh392"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1958,
        "2021": 1977,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqnA43DHWJeD86Q",
        "row": 5243,
        "state": "COLORADO",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 5100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrxMfIxDrhbvrJm",
        "row": 5242,
        "state": "COLORADO",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4276,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrhJYpgoBL7Zt0H",
        "row": 5187,
        "state": "COLORADO",
        "group": "915187",
        "group_num": [
            "recXDUmfiGcpk7sNN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 939,
        "2023": 0,
        "2024": 0,
        "id": "recrjYKf5ETA249x6",
        "row": 5221,
        "state": "COLORADO",
        "group": "925108",
        "group_num": [
            "rec7UFuaPRohdnrSE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 37,
        "2023": 0,
        "2024": 0,
        "id": "recrkAg2E6Cggsiuh",
        "row": 5219,
        "state": "COLORADO",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 4122,
        "2021": 0,
        "2022": 959,
        "2023": 0,
        "2024": 0,
        "id": "recsCUlZsOeAuZHnv",
        "row": 5186,
        "state": "COLORADO",
        "group": "915187",
        "group_num": [
            "recXDUmfiGcpk7sNN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1305,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs3DUWqYljAI7iq",
        "row": 5177,
        "state": "COLORADO",
        "group": "912620",
        "group_num": [
            "recsEheC27t2eSp2H"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3787,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectRd5Syzg2I46mN",
        "row": 5175,
        "state": "COLORADO",
        "group": "911585",
        "group_num": [
            "rec37qckFiqlFSzvB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 919,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recw0ObOTuRZL6HdT",
        "row": 5154,
        "state": "COLORADO",
        "group": "905566",
        "group_num": [
            "recTM3SLJzUOBa3RB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 745,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwoIAyVxCZw4pEP",
        "row": 5152,
        "state": "COLORADO",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxa4jGKz3Xc5Rug",
        "row": 5171,
        "state": "COLORADO",
        "group": "911297",
        "group_num": [
            "recQXfRXpLV5Oe7Wt"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 852,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyVjAGRxYA4NyxS",
        "row": 5209,
        "state": "COLORADO",
        "group": "921186",
        "group_num": [
            "recMlYnOw5Hi7qKME"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 760,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy6r5PmtnjmyIHT",
        "row": 5203,
        "state": "COLORADO",
        "group": "917661",
        "group_num": [
            "recLoxKAKj0KlExVn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "recyeziTpDy1nibxC",
        "row": 5222,
        "state": "COLORADO",
        "group": "925108",
        "group_num": [
            "rec7UFuaPRohdnrSE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 6000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBvhl55YiBTaoXH",
        "row": 5239,
        "state": "COLORADO",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 150,
        "2024": 0,
        "id": "recBRd1kJr7PPAsBP",
        "row": 5236,
        "state": "COLORADO",
        "group": "2421265",
        "group_num": [
            "recYdqyVXhPlxAbOR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 1403,
        "2024": 0,
        "id": "recCyaluPV1BnLo79",
        "row": 5230,
        "state": "COLORADO",
        "group": "1479279",
        "group_num": [
            "recxo2rtUcyJzRbqL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 0,
        "2024": 0,
        "id": "recDUc0RDZPVZh6J2",
        "row": 5220,
        "state": "COLORADO",
        "group": "925027",
        "group_num": [
            "recSUKEugzVOYQCkA"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1221,
        "2023": 0,
        "2024": 0,
        "id": "recDb8iFARgPHMIPp",
        "row": 5217,
        "state": "COLORADO",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 0,
        "2024": 0,
        "id": "recDdFR874xP7JjIa",
        "row": 5215,
        "state": "COLORADO",
        "group": "922623",
        "group_num": [
            "recnXWeOjp4rql1G6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1523,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDfCTv3A9Nr7F0f",
        "row": 5183,
        "state": "COLORADO",
        "group": "914679",
        "group_num": [
            "recROtaKjCwVQszf1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 248,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDlVacvwGbHyifb",
        "row": 5195,
        "state": "COLORADO",
        "group": "915651",
        "group_num": [
            "recrpog9Rxye8aip0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recERWph4tHD8LMpi",
        "row": 5205,
        "state": "COLORADO",
        "group": "918981",
        "group_num": [
            "recMQ9hguslcsFJMp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1596,
        "2023": 0,
        "2024": 0,
        "id": "recFuCDtINEDurreM",
        "row": 5204,
        "state": "COLORADO",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2617,
        "2023": 0,
        "2024": 0,
        "id": "recG2oMvsiq7sBZfC",
        "row": 5251,
        "state": "COLORADO",
        "group": "016059205000122",
        "group_num": [
            "recuiW9xcEM2rak2T"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 78,
        "2021": 979,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHBQckIzt87ifvs",
        "row": 5244,
        "state": "COLORADO",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 29,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIZ2mirXN3kfuhB",
        "row": 5165,
        "state": "COLORADO",
        "group": "908168",
        "group_num": [
            "rec1TfjrQ9rCCnaYF"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1011,
        "2023": 0,
        "2024": 0,
        "id": "recID7GBjkxnbLZUD",
        "row": 5224,
        "state": "COLORADO",
        "group": "926724",
        "group_num": [
            "recNlxKSXPAS6NM7R"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 29,
        "2022": 108,
        "2023": 0,
        "2024": 0,
        "id": "recJz7DuOuTC6W0IF",
        "row": 5178,
        "state": "COLORADO",
        "group": "912620",
        "group_num": [
            "recsEheC27t2eSp2H"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 378,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJK8NmgGyEORTzx",
        "row": 5182,
        "state": "COLORADO",
        "group": "914406",
        "group_num": [
            "recEvD2hEE85QpuGm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 374,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKw97aI7Y4KdwaG",
        "row": 5184,
        "state": "COLORADO",
        "group": "914679",
        "group_num": [
            "recROtaKjCwVQszf1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2064,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLAmuSoOgGMev5w",
        "row": 5207,
        "state": "COLORADO",
        "group": "919238",
        "group_num": [
            "recrIZOYy1JhASqju"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 853,
        "2024": 0,
        "id": "recL4uCCS8NA4s3XE",
        "row": 5228,
        "state": "COLORADO",
        "group": "1427704",
        "group_num": [
            "receI4ZtjOOr58py5"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 139,
        "2024": 0,
        "id": "recLblwvzaFYxnq7u",
        "row": 5188,
        "state": "COLORADO",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1251,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLp3Hv1arUGjV86",
        "row": 5233,
        "state": "COLORADO",
        "group": "2245221",
        "group_num": [
            "recN1CMy8TVwYmufz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2000,
        "2021": 1007,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM5YZ1dW6lDOPHD",
        "row": 5158,
        "state": "COLORADO",
        "group": "907073",
        "group_num": [
            "recvSD7qUU72vHDJe"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 852,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNr61EDFmgEJkYY",
        "row": 5170,
        "state": "COLORADO",
        "group": "910570",
        "group_num": [
            "recyUmLJzO6tOAO92"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2760,
        "2023": 0,
        "2024": 0,
        "id": "recgDCsGyyoDLzBHa",
        "row": 5256,
        "state": "COLORADO",
        "group": "HP019741",
        "group_num": [
            "rec2FldzE7Lh9Mya9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciS6fy0ZQg8xEf7",
        "row": 5253,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 435,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXzRA1GWou5Jz6l",
        "row": 5259,
        "state": "COLORADO",
        "group": "OH007082",
        "group_num": [
            "recGBFRdNvqqtVLx1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 100,
        "2022": 100,
        "2023": 0,
        "2024": 0,
        "id": "rec8A8u1Z4C6CLiqZ",
        "row": 5260,
        "state": "COLORADO",
        "group": "OH007082",
        "group_num": [
            "recGBFRdNvqqtVLx1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1568,
        "2023": 0,
        "2024": 0,
        "id": "rec82ePObvwuWeUYG",
        "row": 5257,
        "state": "COLORADO",
        "group": "HP019741",
        "group_num": [
            "rec2FldzE7Lh9Mya9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 540,
        "2023": 0,
        "2024": 0,
        "id": "recabbQYewKtHLMmc",
        "row": 5258,
        "state": "COLORADO",
        "group": "HP019741",
        "group_num": [
            "rec2FldzE7Lh9Mya9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3237,
        "2024": 0,
        "id": "rectoaVmCyg0T2a56",
        "row": 5254,
        "state": "COLORADO",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 51,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwG4hT2PW2lrBxS",
        "row": 5255,
        "state": "COLORADO",
        "group": "CHARLESSCHWAB",
        "group_num": [
            "recZOEh8GKDRQiqZ1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1666,
        "2023": 0,
        "2024": 0,
        "id": "recC8iDSXv8DcmD5Q",
        "row": 5252,
        "state": "COLORADO",
        "group": "016059205000122",
        "group_num": [
            "recuiW9xcEM2rak2T"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 4038,
        "2024": 0,
        "id": "reczhEG7vpQBY9qWB",
        "row": 5262,
        "state": "COLORADO",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 325,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVXhTvSDZr1RhVp",
        "row": 5264,
        "state": "COLORADO",
        "group": "00130562",
        "group_num": [
            "recKP70aeg6UjVpaP"
        ],
        "procedure": "RTC",
        "provider": "UNIVERA  HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 455,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4gDjUGIgSFLLeu",
        "row": 5263,
        "state": "COLORADO",
        "group": "00130562",
        "group_num": [
            "recKP70aeg6UjVpaP"
        ],
        "procedure": "Detox",
        "provider": "UNIVERA  HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4860,
        "2023": 0,
        "2024": 0,
        "id": "recOxZdi8EWGuxJC8",
        "row": 5265,
        "state": "COLORADO",
        "group": "1641810629",
        "group_num": [
            "recwFDu9sIwZYwn55"
        ],
        "procedure": "Detox",
        "provider": "UNIVERSITY OF UTAH"
    },
    {
        "2020": 257,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYRBoD2BCvxV15S",
        "row": 5266,
        "state": "COLORADO",
        "group": "EXO046",
        "group_num": [
            "recpNg9UohQ919mwa"
        ],
        "procedure": "IOP",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 489,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbW8w9naFSVlYfY",
        "row": 5268,
        "state": "COLORADO",
        "group": "WSSMC",
        "group_num": [
            "reczginBjhFrFgHe4"
        ],
        "procedure": "PHP",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "2023": 0,
        "2024": 0,
        "id": "rec4bGBKZm7WWP1Ne",
        "row": 5270,
        "state": "COLORADO",
        "group": "WM260",
        "group_num": [
            "rechiSAPu0qxCnn8I"
        ],
        "procedure": "RTC",
        "provider": "WELLNET"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4hmIbhJaAtmiJ6",
        "row": 5271,
        "state": "COLORADO",
        "group": "WM260",
        "group_num": [
            "rechiSAPu0qxCnn8I"
        ],
        "procedure": "",
        "provider": "WELLNET"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "2023": 0,
        "2024": 0,
        "id": "reczB8DVyqzuBpUb6",
        "row": 5269,
        "state": "COLORADO",
        "group": "WM260",
        "group_num": [
            "rechiSAPu0qxCnn8I"
        ],
        "procedure": "Detox",
        "provider": "WELLNET"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recvQbKr8kDvUvKXg",
        "row": 7864,
        "state": "FLORIDA",
        "group": "G0185106",
        "group_num": [
            "recfKP8K82C1dhmgq"
        ],
        "procedure": "RTC",
        "provider": "ACTIVE CARE MANAGEMENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "reczKYlOqXVsDM3rf",
        "row": 12827,
        "state": "FLORIDA",
        "group": "G0185106",
        "group_num": [
            "recfKP8K82C1dhmgq"
        ],
        "procedure": "RTC",
        "provider": "ACTIVE CARE MANAGEMENT (ACTIVE CARE MANAGEMENT)"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvTYAs4fiCfVi2t",
        "row": 7865,
        "state": "FLORIDA",
        "group": "FT1000",
        "group_num": [
            "recmLHXabjHahhOlW"
        ],
        "procedure": "PHP",
        "provider": "ADVENT HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "receUTsGFA5szaiAK",
        "row": 7883,
        "state": "FLORIDA",
        "group": "014194301700200",
        "group_num": [
            "recmnhfH59aqKRLW9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "2023": 0,
        "2024": 0,
        "id": "rece1TASiIyME1W6V",
        "row": 7896,
        "state": "FLORIDA",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "receMdEj5KfC3VZpK",
        "row": 7908,
        "state": "FLORIDA",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recfPsO1GdcObCxt0",
        "row": 7873,
        "state": "FLORIDA",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 951,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechkjrsRRPPn3Slc",
        "row": 7876,
        "state": "FLORIDA",
        "group": "010902204000001",
        "group_num": [
            "recAosIxCceLpmIyX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1060,
        "2023": 0,
        "2024": 0,
        "id": "recl3EAGkFAgGiba5",
        "row": 7866,
        "state": "FLORIDA",
        "group": "0175436",
        "group_num": [
            "recJ20xtz3RUfxyFb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 466,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmCS2jCmDuTviYo",
        "row": 7894,
        "state": "FLORIDA",
        "group": "016014401600005",
        "group_num": [
            "recm5FgX87wU06fQt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1306,
        "2024": 0,
        "id": "recna0cVwfUzv63QU",
        "row": 7885,
        "state": "FLORIDA",
        "group": "014197401000002",
        "group_num": [
            "recKJBXjgkf6rWogW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 242,
        "2023": 0,
        "2024": 0,
        "id": "recOof4u0pvKcuBZt",
        "row": 7906,
        "state": "FLORIDA",
        "group": "028559301500230",
        "group_num": [
            "recExs6EyXE2HtqbR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 486,
        "2023": 0,
        "2024": 0,
        "id": "recQYszwfAHJAKtjA",
        "row": 7884,
        "state": "FLORIDA",
        "group": "014194301700200",
        "group_num": [
            "recmnhfH59aqKRLW9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1201,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQQfu0uM9JoAG0z",
        "row": 7875,
        "state": "FLORIDA",
        "group": "010902204000001",
        "group_num": [
            "recAosIxCceLpmIyX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 801,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ8nuyxjVDS3Ynk",
        "row": 7893,
        "state": "FLORIDA",
        "group": "016014401600005",
        "group_num": [
            "recm5FgX87wU06fQt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 951,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recRmPTETe562gYBC",
        "row": 7889,
        "state": "FLORIDA",
        "group": "014955401400001",
        "group_num": [
            "recM8OuwV8vOx2hqh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recTSP2BsKZcnMedz",
        "row": 7879,
        "state": "FLORIDA",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1113,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTuTmluk7Tq7CUL",
        "row": 7888,
        "state": "FLORIDA",
        "group": "014955401400001",
        "group_num": [
            "recM8OuwV8vOx2hqh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "2023": 0,
        "2024": 0,
        "id": "recT7vOTPNOOxOQuL",
        "row": 7901,
        "state": "FLORIDA",
        "group": "017830001000703",
        "group_num": [
            "recB2V9tE69UGEUOJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 193,
        "2024": 0,
        "id": "recXFFE3Qa7Y28aoB",
        "row": 7880,
        "state": "FLORIDA",
        "group": "011544501100001",
        "group_num": [
            "rec8Y26tkB2S9HDEe"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1628,
        "2023": 0,
        "2024": 0,
        "id": "recY2zIgOr2XVjxjz",
        "row": 7902,
        "state": "FLORIDA",
        "group": "018744301000001",
        "group_num": [
            "reccnXe0EDDQfXAEO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1112,
        "2023": 0,
        "2024": 0,
        "id": "recYid78QtlxeSCsq",
        "row": 7899,
        "state": "FLORIDA",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "rec2IAA31odU5xHUW",
        "row": 7871,
        "state": "FLORIDA",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 594,
        "2023": 0,
        "2024": 0,
        "id": "rec3NKKH1fXJNVPpa",
        "row": 7878,
        "state": "FLORIDA",
        "group": "010903201400012",
        "group_num": [
            "recKS7CW8zoi1LfZs"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 448,
        "2024": 0,
        "id": "rec3is11vHU5yqvDK",
        "row": 7905,
        "state": "FLORIDA",
        "group": "028400102400001",
        "group_num": [
            "recRC4WyxdK1Ozx8Z"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 308,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5uQwXSqbOrpyJd",
        "row": 7886,
        "state": "FLORIDA",
        "group": "014317801000001",
        "group_num": [
            "rec7cIsncNMXDXUsu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 430,
        "2023": 461,
        "2024": 0,
        "id": "rec5wmD6vkN1qGGQ0",
        "row": 7870,
        "state": "FLORIDA",
        "group": "17548001100046",
        "group_num": [
            "recyJojnVYEosX0ko"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 706,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5BBoiBNw4iTGRC",
        "row": 7911,
        "state": "FLORIDA",
        "group": "028622201200001",
        "group_num": [
            "recrb8D8ec64zCkls"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 193,
        "2024": 0,
        "id": "rec5mdHwh79pPpBkK",
        "row": 7903,
        "state": "FLORIDA",
        "group": "019303901100001",
        "group_num": [
            "recRCoS1SDath3wHE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 908,
        "2023": 0,
        "2024": 0,
        "id": "rec6Zz9FTSQ5yb1zr",
        "row": 7890,
        "state": "FLORIDA",
        "group": "014995001200001",
        "group_num": [
            "recDsHX8Ur2OiA2xa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 559,
        "2023": 0,
        "2024": 0,
        "id": "rec8SwuN07pvjte06",
        "row": 7907,
        "state": "FLORIDA",
        "group": "028559301500230",
        "group_num": [
            "recExs6EyXE2HtqbR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 497,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccRBLYtNSs967kf",
        "row": 7913,
        "state": "FLORIDA",
        "group": "030716301200001",
        "group_num": [
            "recmDxmj543UHZddH"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "recdfn4pBfErdYtIY",
        "row": 7900,
        "state": "FLORIDA",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "recq1vAjtf11hoINn",
        "row": 7874,
        "state": "FLORIDA",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 895,
        "2023": 0,
        "2024": 0,
        "id": "recq4yBHrvtML3KXY",
        "row": 7881,
        "state": "FLORIDA",
        "group": "011544501100001",
        "group_num": [
            "rec8Y26tkB2S9HDEe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 641,
        "2023": 0,
        "2024": 0,
        "id": "recqjXShwEMF3gE4V",
        "row": 7892,
        "state": "FLORIDA",
        "group": "016014401600002",
        "group_num": [
            "reca8ebDuL7yXGLsV"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 800,
        "2024": 0,
        "id": "recqoMXNg70xLL40q",
        "row": 7898,
        "state": "FLORIDA",
        "group": "016954201300033",
        "group_num": [
            "reczll5xXeFkIAiRF"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1121,
        "2023": 0,
        "2024": 0,
        "id": "recrt30qILfxdzy4d",
        "row": 7877,
        "state": "FLORIDA",
        "group": "010903201400012",
        "group_num": [
            "recKS7CW8zoi1LfZs"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 801,
        "2024": 0,
        "id": "recrrifq80l9qsBwh",
        "row": 7904,
        "state": "FLORIDA",
        "group": "028400102400001",
        "group_num": [
            "recRC4WyxdK1Ozx8Z"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1041,
        "2023": 0,
        "2024": 0,
        "id": "recuzDUkiGrG96e9R",
        "row": 7869,
        "state": "FLORIDA",
        "group": "17548001100046",
        "group_num": [
            "recyJojnVYEosX0ko"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "2023": 0,
        "2024": 0,
        "id": "recu7siMhucxgedZ0",
        "row": 7895,
        "state": "FLORIDA",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2215,
        "2023": 476,
        "2024": 0,
        "id": "recDzC0zUcWbn2ZHZ",
        "row": 7868,
        "state": "FLORIDA",
        "group": "14907701000001",
        "group_num": [
            "rec9WcHy3O4vMROAI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 262,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDJHcWuXDg5jSVI",
        "row": 7867,
        "state": "FLORIDA",
        "group": "86857414009",
        "group_num": [
            "recNaiFMIX5AoxWpm"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 193,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDKMPre6ZAp4A6m",
        "row": 7910,
        "state": "FLORIDA",
        "group": "028622201200001",
        "group_num": [
            "recrb8D8ec64zCkls"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "2023": 0,
        "2024": 0,
        "id": "recDcsEPlRj0eGbRk",
        "row": 7897,
        "state": "FLORIDA",
        "group": "016125001200001",
        "group_num": [
            "rec7RPcMvxSD4Qsyv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1469,
        "2023": 0,
        "2024": 0,
        "id": "recHWL5S4xXK5lUJr",
        "row": 7872,
        "state": "FLORIDA",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1192,
        "2023": 951,
        "2024": 0,
        "id": "recHE0USBi2PSOrJs",
        "row": 7891,
        "state": "FLORIDA",
        "group": "014995001200001",
        "group_num": [
            "recDsHX8Ur2OiA2xa"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 801,
        "2024": 0,
        "id": "recJUBouRWUiAG5wR",
        "row": 7882,
        "state": "FLORIDA",
        "group": "014135301200001",
        "group_num": [
            "recApSK3N38Jwmatr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 476,
        "2022": 1289,
        "2023": 0,
        "2024": 0,
        "id": "recJJRyz83G5zO3tP",
        "row": 7909,
        "state": "FLORIDA",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 845,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLuFU9Qfp9xqW1X",
        "row": 7912,
        "state": "FLORIDA",
        "group": "030716301200001",
        "group_num": [
            "recmDxmj543UHZddH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1622,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMbBqsHN2l8CDYP",
        "row": 7887,
        "state": "FLORIDA",
        "group": "014423501600719",
        "group_num": [
            "rec0iTF618JHzpYyf"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 351,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechZd9tfriN5Ebhb",
        "row": 7952,
        "state": "FLORIDA",
        "group": "084287704000401",
        "group_num": [
            "recHEoj5F8pjSHVLN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "rechysMr8afNt4Bqq",
        "row": 7919,
        "state": "FLORIDA",
        "group": "034280601800171",
        "group_num": [
            "rectLgYAqn5QrG7Xy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1451,
        "2023": 0,
        "2024": 0,
        "id": "reciT4OJ3JRl3y1KP",
        "row": 7958,
        "state": "FLORIDA",
        "group": "086545001000002",
        "group_num": [
            "reckRau9up2PM83ds"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1231,
        "2023": 0,
        "2024": 0,
        "id": "reciYVGUdYWtEOKPG",
        "row": 7960,
        "state": "FLORIDA",
        "group": "086587301300702",
        "group_num": [
            "recxN5ItJIPGNT74H"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 562,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recikahx8dNUUZVtf",
        "row": 7946,
        "state": "FLORIDA",
        "group": "078313203300002",
        "group_num": [
            "recGVnuK09qp2GsEH"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjaTL5QQzoUlJ18",
        "row": 7933,
        "state": "FLORIDA",
        "group": "069868502000003",
        "group_num": [
            "rechufwrBV9TNyCsL"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjbYT9FUpSOdZGe",
        "row": 7923,
        "state": "FLORIDA",
        "group": "047715801200006",
        "group_num": [
            "recLkwzrcRFnssVUU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 914,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckb8fO3hOsLxjRn",
        "row": 7938,
        "state": "FLORIDA",
        "group": "070149603700014",
        "group_num": [
            "recXYaDsgF73LcYxP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1560,
        "2023": 0,
        "2024": 0,
        "id": "reckmkqCYHrZc0eaa",
        "row": 7954,
        "state": "FLORIDA",
        "group": "086384401000001",
        "group_num": [
            "recZXv12iNUxJIoFu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recmtQ0nGfRcI9HBL",
        "row": 7962,
        "state": "FLORIDA",
        "group": "086630201000001",
        "group_num": [
            "recSOHvxy0g4ckAU7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2346,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnnpzQhTpw1Wo2R",
        "row": 7921,
        "state": "FLORIDA",
        "group": "036962303200100",
        "group_num": [
            "recgPACOKh72RGK6Y"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2380,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOcQMtDxRc87y4l",
        "row": 7934,
        "state": "FLORIDA",
        "group": "069868502000003",
        "group_num": [
            "rechufwrBV9TNyCsL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 951,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recQr3lZ4IBcmIgc3",
        "row": 7961,
        "state": "FLORIDA",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2393,
        "2023": 0,
        "2024": 0,
        "id": "recRhjpX4bI9lvd1m",
        "row": 7955,
        "state": "FLORIDA",
        "group": "086387701300001",
        "group_num": [
            "recjuV418qQqEUVaU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 951,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recRpAMA687rjP3F6",
        "row": 7924,
        "state": "FLORIDA",
        "group": "047715801200006",
        "group_num": [
            "recLkwzrcRFnssVUU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 650,
        "2021": 479,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTtSZUoZ79YaSFj",
        "row": 7916,
        "state": "FLORIDA",
        "group": "032635501500003",
        "group_num": [
            "rec7IyyQLYBkUCHAR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 871,
        "2023": 0,
        "2024": 0,
        "id": "recUGBiGbTJz99O5b",
        "row": 7931,
        "state": "FLORIDA",
        "group": "068615603200101",
        "group_num": [
            "rec0vhiljtUE5Vclg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "recUrqqNqjugIuaoZ",
        "row": 7926,
        "state": "FLORIDA",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1201,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXBT6zyeyfxBhWD",
        "row": 7930,
        "state": "FLORIDA",
        "group": "068615603200101",
        "group_num": [
            "rec0vhiljtUE5Vclg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1150,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXGr6n4tFp4kPUH",
        "row": 7917,
        "state": "FLORIDA",
        "group": "032648904800075",
        "group_num": [
            "recFGvkj87BnqaBDX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 742,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYFNbnXaGAuu9OM",
        "row": 7951,
        "state": "FLORIDA",
        "group": "084287704000401",
        "group_num": [
            "recHEoj5F8pjSHVLN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 801,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "recYexVwIHRT4NJ1O",
        "row": 7942,
        "state": "FLORIDA",
        "group": "077631701000001",
        "group_num": [
            "rec7FAHk3D22RGkXv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 334,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYh9ndU9nG9vmqW",
        "row": 7914,
        "state": "FLORIDA",
        "group": "032635501500003",
        "group_num": [
            "rec7IyyQLYBkUCHAR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 468,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0w4N8XDIvI9fAu",
        "row": 7945,
        "state": "FLORIDA",
        "group": "077813601000705",
        "group_num": [
            "recUdilzpeh5TUfSi"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "rec2DNaqAr2yNP6A4",
        "row": 7950,
        "state": "FLORIDA",
        "group": "080952701800001",
        "group_num": [
            "recf7agb77btdksLD"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 750,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "rec3L60BJ62HLoSmf",
        "row": 7943,
        "state": "FLORIDA",
        "group": "077631701000001",
        "group_num": [
            "rec7FAHk3D22RGkXv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1193,
        "2024": 0,
        "id": "rec3rzFT6hxEeT3Xp",
        "row": 7948,
        "state": "FLORIDA",
        "group": "080010504200001",
        "group_num": [
            "recq5s7zXYiosB4MN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 821,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4dLHLSgcmSIG3L",
        "row": 7947,
        "state": "FLORIDA",
        "group": "078313203300002",
        "group_num": [
            "recGVnuK09qp2GsEH"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 502,
        "2023": 0,
        "2024": 0,
        "id": "rec5EmMBceGVEABnF",
        "row": 7956,
        "state": "FLORIDA",
        "group": "086387701300001",
        "group_num": [
            "recjuV418qQqEUVaU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1970,
        "2023": 0,
        "2024": 0,
        "id": "rec585zEMw5SL2vQL",
        "row": 7953,
        "state": "FLORIDA",
        "group": "086384401000001",
        "group_num": [
            "recZXv12iNUxJIoFu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7h1LCPwszLhXE2",
        "row": 7920,
        "state": "FLORIDA",
        "group": "036962303200100",
        "group_num": [
            "recgPACOKh72RGK6Y"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1623,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8preP1WKtVutKL",
        "row": 7935,
        "state": "FLORIDA",
        "group": "069868502000003",
        "group_num": [
            "rechufwrBV9TNyCsL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 807,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccRLeWUPsm2RZD4",
        "row": 7944,
        "state": "FLORIDA",
        "group": "077813601000705",
        "group_num": [
            "recUdilzpeh5TUfSi"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1679,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco87dXuDkpG1qc5",
        "row": 7937,
        "state": "FLORIDA",
        "group": "070149603700014",
        "group_num": [
            "recXYaDsgF73LcYxP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 1256,
        "2024": 0,
        "id": "recqWdJ1WAdqA7mhY",
        "row": 7959,
        "state": "FLORIDA",
        "group": "086545001000002",
        "group_num": [
            "reckRau9up2PM83ds"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 193,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrm440ypKTB7GGK",
        "row": 7927,
        "state": "FLORIDA",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2461,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv6jU022J9yhVyF",
        "row": 7932,
        "state": "FLORIDA",
        "group": "069781903300809",
        "group_num": [
            "recLuZmzt9pRLNBeG"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1338,
        "2023": 0,
        "2024": 0,
        "id": "recyogGG8Tklhddst",
        "row": 7918,
        "state": "FLORIDA",
        "group": "034280601800171",
        "group_num": [
            "rectLgYAqn5QrG7Xy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 822,
        "2021": 822,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCveB8W4rYvzqde",
        "row": 7915,
        "state": "FLORIDA",
        "group": "032635501500003",
        "group_num": [
            "rec7IyyQLYBkUCHAR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1132,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDsouhQkrd5copt",
        "row": 7936,
        "state": "FLORIDA",
        "group": "069868502000003",
        "group_num": [
            "rechufwrBV9TNyCsL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 618,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recE6ceSFKPT2rauK",
        "row": 7929,
        "state": "FLORIDA",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2346,
        "2021": 2346,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHynLFscL4tnaNA",
        "row": 7922,
        "state": "FLORIDA",
        "group": "046961801000021",
        "group_num": [
            "recZS5YqtA2BSG8sf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "2023": 0,
        "2024": 0,
        "id": "recHAnDunTsfr8PKx",
        "row": 7957,
        "state": "FLORIDA",
        "group": "086523701200105",
        "group_num": [
            "recVcwXZtS1yItNVp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "recHRxfAPTdOQ2n4X",
        "row": 7963,
        "state": "FLORIDA",
        "group": "086630201000001",
        "group_num": [
            "recSOHvxy0g4ckAU7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1232,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIH6TCFKPB7k3uH",
        "row": 7928,
        "state": "FLORIDA",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1015,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJbHA07ij8y3wt3",
        "row": 7941,
        "state": "FLORIDA",
        "group": "077631701000001",
        "group_num": [
            "rec7FAHk3D22RGkXv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recKHPROiKraKslKh",
        "row": 7940,
        "state": "FLORIDA",
        "group": "071998204300020",
        "group_num": [
            "recBN7uHGUQGIzB6X"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1612,
        "2023": 0,
        "2024": 0,
        "id": "recKNWU3ZewsuQOnw",
        "row": 7925,
        "state": "FLORIDA",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLtb83IJuPM0qGW",
        "row": 7939,
        "state": "FLORIDA",
        "group": "071998202300001",
        "group_num": [
            "recrtpFuAyHg88qpM"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1201,
        "2023": 0,
        "2024": 0,
        "id": "recL99y7itsKdlakF",
        "row": 7949,
        "state": "FLORIDA",
        "group": "080952701800001",
        "group_num": [
            "recf7agb77btdksLD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 951,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech10MbaxEVWwzpH",
        "row": 7974,
        "state": "FLORIDA",
        "group": "086861301000514",
        "group_num": [
            "recO7DXbZE2uNDiMS"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2883,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckcEK81bDZaVpxJ",
        "row": 7971,
        "state": "FLORIDA",
        "group": "086858201100001",
        "group_num": [
            "rec0KtGsgKpnSKd8w"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1201,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclx7tIn8HAb6d5v",
        "row": 7973,
        "state": "FLORIDA",
        "group": "086861301000514",
        "group_num": [
            "recO7DXbZE2uNDiMS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recnkkgOwbevduYak",
        "row": 7985,
        "state": "FLORIDA",
        "group": "087600101700001",
        "group_num": [
            "rec5X9ZmVBLH0r2Lu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1184,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOlyTRo8zNXeUmb",
        "row": 7988,
        "state": "FLORIDA",
        "group": "087712102000005",
        "group_num": [
            "recCOe0YUFG7uu2s7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 4921,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPAKAruWvfc6Lqo",
        "row": 7972,
        "state": "FLORIDA",
        "group": "086859801100001",
        "group_num": [
            "recE7zW4tDoW4PcWK"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 914,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRsGaR0Kt67TaCb",
        "row": 7975,
        "state": "FLORIDA",
        "group": "086861301400158",
        "group_num": [
            "rec5c43sMidsj8tcv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1128,
        "2023": 0,
        "2024": 0,
        "id": "recR5hw7e8DOAzXNa",
        "row": 7986,
        "state": "FLORIDA",
        "group": "087600101700001",
        "group_num": [
            "rec5X9ZmVBLH0r2Lu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTck8WfsL57Ydeu",
        "row": 7987,
        "state": "FLORIDA",
        "group": "087654602500005",
        "group_num": [
            "recp8C4KUJ5J3MIqZ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 377,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTlGSvrCJJDKKcU",
        "row": 7978,
        "state": "FLORIDA",
        "group": "086864501100001",
        "group_num": [
            "rec3P5FWpTaLGW4aL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1313,
        "2023": 0,
        "2024": 0,
        "id": "recUJzrZO6vRY9PoP",
        "row": 7983,
        "state": "FLORIDA",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 1964,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXL5uO6KUyPKEAz",
        "row": 7989,
        "state": "FLORIDA",
        "group": "087712102000005",
        "group_num": [
            "recCOe0YUFG7uu2s7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1187,
        "2023": 727,
        "2024": 0,
        "id": "rec3sKcoqddQ8NR6V",
        "row": 7998,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 677,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6WxwwHn8UiFtJA",
        "row": 7980,
        "state": "FLORIDA",
        "group": "086873802300001",
        "group_num": [
            "recTEBMVpZu3Yhuuq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 652,
        "2023": 0,
        "2024": 0,
        "id": "rec8VYEE3k0csX66X",
        "row": 7968,
        "state": "FLORIDA",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "rec9PMoUfUDCZoXif",
        "row": 7996,
        "state": "FLORIDA",
        "group": "088552401000008",
        "group_num": [
            "recQv6U0bCZGqJbre"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 787,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccNNQ5I3f7jmVKm",
        "row": 7977,
        "state": "FLORIDA",
        "group": "086864501100001",
        "group_num": [
            "rec3P5FWpTaLGW4aL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "2023": 0,
        "2024": 0,
        "id": "reccn8N7IZfVlVqFv",
        "row": 7997,
        "state": "FLORIDA",
        "group": "088552401000008",
        "group_num": [
            "recQv6U0bCZGqJbre"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 1157,
        "2021": 834,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp5Ls7Csniefwnf",
        "row": 7990,
        "state": "FLORIDA",
        "group": "087712102000005",
        "group_num": [
            "recCOe0YUFG7uu2s7"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1769,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqmB2YPOaMFmfEB",
        "row": 7964,
        "state": "FLORIDA",
        "group": "086797102200408",
        "group_num": [
            "recFzIpOKusmMKqBw"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1082,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqqUc3eXRfEU75Z",
        "row": 7976,
        "state": "FLORIDA",
        "group": "086864501100001",
        "group_num": [
            "rec3P5FWpTaLGW4aL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1137,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxaHaOSBnqbJemj",
        "row": 7979,
        "state": "FLORIDA",
        "group": "086873802300001",
        "group_num": [
            "recTEBMVpZu3Yhuuq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1231,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczJyxk3D9FAaCWw",
        "row": 7965,
        "state": "FLORIDA",
        "group": "086831101200104",
        "group_num": [
            "recXxxFoEF163Wn5o"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 637,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz9MNG76WCdnlWX",
        "row": 7991,
        "state": "FLORIDA",
        "group": "087712102000005",
        "group_num": [
            "recCOe0YUFG7uu2s7"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "recAal9IMMqY2NiBe",
        "row": 7984,
        "state": "FLORIDA",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 951,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCgndbTmMEQ8ueM",
        "row": 7995,
        "state": "FLORIDA",
        "group": "088079401800004",
        "group_num": [
            "recbQYazcs8cmwXbu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 653,
        "2022": 653,
        "2023": 0,
        "2024": 0,
        "id": "recDFiMwaMEMTKruo",
        "row": 7966,
        "state": "FLORIDA",
        "group": "086831101200104",
        "group_num": [
            "recXxxFoEF163Wn5o"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEKdnQyCpWz5Scp",
        "row": 7982,
        "state": "FLORIDA",
        "group": "086887001100013",
        "group_num": [
            "recYdHzMYPcSDyg7K"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 2297,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGTRpFwh4XJtF3r",
        "row": 7981,
        "state": "FLORIDA",
        "group": "086887001100013",
        "group_num": [
            "recYdHzMYPcSDyg7K"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 2346,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGI2Zf5PNFrLYMh",
        "row": 7970,
        "state": "FLORIDA",
        "group": "086849901000570",
        "group_num": [
            "rec26VTvuuOHKqsRr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 476,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recGalA7iikquSH5g",
        "row": 7999,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHqqcCANH5m2ksA",
        "row": 7969,
        "state": "FLORIDA",
        "group": "086849901000570",
        "group_num": [
            "rec26VTvuuOHKqsRr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1121,
        "2022": 1485,
        "2023": 0,
        "2024": 0,
        "id": "recJQNNY1xW74pNvv",
        "row": 7967,
        "state": "FLORIDA",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recKR57WjzD1PvXOU",
        "row": 7992,
        "state": "FLORIDA",
        "group": "088079102600011",
        "group_num": [
            "recMsNCfDGuYiw07U"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "2023": 0,
        "2024": 0,
        "id": "recMmFZ9vIDUdD5Tl",
        "row": 7993,
        "state": "FLORIDA",
        "group": "088079102600011",
        "group_num": [
            "recMsNCfDGuYiw07U"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 1201,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN3dK4EfSwc3bFT",
        "row": 7994,
        "state": "FLORIDA",
        "group": "088079401800004",
        "group_num": [
            "recbQYazcs8cmwXbu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 13318,
        "2024": 0,
        "id": "receVz6ndaDBBurKZ",
        "row": 11448,
        "state": "FLORIDA",
        "group": "017029301300009",
        "group_num": [
            "recCeU852OOG6HlAq"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 20278,
        "2024": 0,
        "id": "recgJPqo4vWyGsVp0",
        "row": 11445,
        "state": "FLORIDA",
        "group": "017513603300104",
        "group_num": [
            "recHDugj5dIaq8Ebt"
        ],
        "procedure": "RTC",
        "provider": "AETNA STUDENT RESOURCES (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 60000,
        "2024": 0,
        "id": "recglWb4hbrFFpYVh",
        "row": 11439,
        "state": "FLORIDA",
        "group": "087712102100003",
        "group_num": [
            "recGLxjORVh3F4dMz"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 42245,
        "2024": 0,
        "id": "recla5Ry4WWIAR5P5",
        "row": 11441,
        "state": "FLORIDA",
        "group": "086869601600003",
        "group_num": [
            "rec08WnLJVfBcJPS7"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 81783,
        "2024": 0,
        "id": "recOPfdQ81uFE1vyu",
        "row": 11437,
        "state": "FLORIDA",
        "group": "034280602200001",
        "group_num": [
            "rec8IMSQ6FCEs1AqD"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 42708,
        "2024": 0,
        "id": "recXplurTpvL2adFu",
        "row": 11440,
        "state": "FLORIDA",
        "group": "086855901000001",
        "group_num": [
            "recYeL4v99MaiQwft"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 38556,
        "2024": 0,
        "id": "rec49sA2kEPIiE1pc",
        "row": 11443,
        "state": "FLORIDA",
        "group": "014421101000006",
        "group_num": [
            "recKLfWOd6Xo4Qthv"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 13600,
        "2024": 0,
        "id": "recaHeIS1lC7lMmQn",
        "row": 11447,
        "state": "FLORIDA",
        "group": "086382601800304",
        "group_num": [
            "reczOLBlmpGuvDFDx"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 42004,
        "2024": 0,
        "id": "rectv4nLyChyr3e53",
        "row": 11442,
        "state": "FLORIDA",
        "group": "047489803400101",
        "group_num": [
            "rec19Ko25GkKNseyw"
        ],
        "procedure": "RTC",
        "provider": "AETNA STUDENT RESOURCES (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 5303,
        "2024": 0,
        "id": "recxaToqvuH2BqzzJ",
        "row": 11455,
        "state": "FLORIDA",
        "group": "016954201300033",
        "group_num": [
            "reczll5xXeFkIAiRF"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 572,
        "id": "recELhFnJeFxrVOC0",
        "row": 11428,
        "state": "FLORIDA",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 33811,
        "2024": 0,
        "id": "recHQNDCEaYT5pJkg",
        "row": 11444,
        "state": "FLORIDA",
        "group": "010844801100001",
        "group_num": [
            "recw0GurQNcKEHQtO"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 14484,
        "2024": 0,
        "id": "recK76VJWNQS0rQsU",
        "row": 11446,
        "state": "FLORIDA",
        "group": "068617803300102",
        "group_num": [
            "recY2BJCcyyj95a5m"
        ],
        "procedure": "RTC",
        "provider": "AETNA STUDENT RESOURCES (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 543,
        "2024": 572,
        "id": "recMXnrY6uyYjwbjx",
        "row": 11427,
        "state": "FLORIDA",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 549,
        "2024": 559,
        "id": "recMwCe8TFMAlgrxx",
        "row": 11426,
        "state": "FLORIDA",
        "group": "014937203300021",
        "group_num": [
            "recXqCeek9WzsttwX"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3263,
        "2024": 0,
        "id": "recCJDt20dtl6Oz0h",
        "row": 11476,
        "state": "FLORIDA",
        "group": "016954201000047",
        "group_num": [
            "recqR93rPYCluxjmA"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3400,
        "2024": 0,
        "id": "recH3wtYQxG1KFxFN",
        "row": 11475,
        "state": "FLORIDA",
        "group": "086382601800304",
        "group_num": [
            "reczOLBlmpGuvDFDx"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "2023": 2732,
        "2024": 0,
        "id": "recFnAb8vGf30nNdl",
        "row": 11522,
        "state": "FLORIDA",
        "group": "032641501000014",
        "group_num": [
            "recukB9Rfpm8d6ckQ"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1928,
        "2024": 0,
        "id": "recax8EYUH0V1OKWn",
        "row": 11602,
        "state": "FLORIDA",
        "group": "039363002700008",
        "group_num": [
            "recAs5L4y6VDGTgOs"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1928,
        "2024": 0,
        "id": "reccm9klcOxdQn0GJ",
        "row": 11601,
        "state": "FLORIDA",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1526,
        "2024": 0,
        "id": "reckWOzmGuD0CcQRT",
        "row": 11666,
        "state": "FLORIDA",
        "group": "039363002700008",
        "group_num": [
            "recAs5L4y6VDGTgOs"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1526,
        "2024": 0,
        "id": "recQ1ws056Dg4DEQp",
        "row": 11665,
        "state": "FLORIDA",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1671,
        "2024": 0,
        "id": "rec6OalXZ01cNmLMk",
        "row": 11649,
        "state": "FLORIDA",
        "group": "080010504200001",
        "group_num": [
            "recq5s7zXYiosB4MN"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1660,
        "2024": 0,
        "id": "recpPsjCTqZvIviki",
        "row": 11652,
        "state": "FLORIDA",
        "group": "068617803300102",
        "group_num": [
            "recY2BJCcyyj95a5m"
        ],
        "procedure": "PHP",
        "provider": "AETNA STUDENT RESOURCES (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1323,
        "2024": 0,
        "id": "recnFxNObn2AixQYg",
        "row": 11693,
        "state": "FLORIDA",
        "group": "080010504200001",
        "group_num": [
            "recq5s7zXYiosB4MN"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1167,
        "2024": 0,
        "id": "recbvkABemPEKQVm8",
        "row": 11707,
        "state": "FLORIDA",
        "group": "016955101000003",
        "group_num": [
            "recoEFxAPYgKCDcBg"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1075,
        "2024": 0,
        "id": "recc73PYvPpPZvOhk",
        "row": 11716,
        "state": "FLORIDA",
        "group": "017029301300009",
        "group_num": [
            "recCeU852OOG6HlAq"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1377,
        "2024": 0,
        "id": "recssGcPH76yQWHQI",
        "row": 11684,
        "state": "FLORIDA",
        "group": "034280602200001",
        "group_num": [
            "rec8IMSQ6FCEs1AqD"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1306,
        "2024": 0,
        "id": "recsiCn8cqZD5qvui",
        "row": 11696,
        "state": "FLORIDA",
        "group": "014197401000002",
        "group_num": [
            "recKJBXjgkf6rWogW"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 780,
        "2024": 0,
        "id": "recjQQfywy55OIHbA",
        "row": 11857,
        "state": "FLORIDA",
        "group": "086840301800001",
        "group_num": [
            "recvEMFAyEidsji5T"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 732,
        "2024": 0,
        "id": "rec7NGhfpSsh91vgE",
        "row": 11861,
        "state": "FLORIDA",
        "group": "086859801000002",
        "group_num": [
            "recqFryrpDESgUt6W"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 831,
        "2024": 0,
        "id": "recsoNxYpuN3axRbK",
        "row": 11842,
        "state": "FLORIDA",
        "group": "018118402400003",
        "group_num": [
            "recZ8bf0uW3cKGIhV"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 760,
        "2024": 0,
        "id": "recztuv32SeFZBmJl",
        "row": 11858,
        "state": "FLORIDA",
        "group": "014421101000006",
        "group_num": [
            "recKLfWOd6Xo4Qthv"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 683,
        "2024": 0,
        "id": "recC7AtXudyiy5sIo",
        "row": 11871,
        "state": "FLORIDA",
        "group": "023510301000700",
        "group_num": [
            "rechlaCNQ0nUrXpwB"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recG6SOmOufJWqwiM",
        "row": 11827,
        "state": "FLORIDA",
        "group": "010903401100003",
        "group_num": [
            "recGMRHxxl1WZ1fnj"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 727,
        "2024": 0,
        "id": "recLdzpbkPd9wJWtO",
        "row": 11862,
        "state": "FLORIDA",
        "group": "086855901000001",
        "group_num": [
            "recYeL4v99MaiQwft"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 683,
        "2024": 0,
        "id": "recdwHI1ydOeEImrc",
        "row": 11872,
        "state": "FLORIDA",
        "group": "036009501400101",
        "group_num": [
            "recrmEE6VXQInUqkB"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 543,
        "2024": 0,
        "id": "recGcV4NxPhXCVO2O",
        "row": 11921,
        "state": "FLORIDA",
        "group": "010844801100001",
        "group_num": [
            "recw0GurQNcKEHQtO"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 531,
        "2024": 0,
        "id": "recwB9rrjl4Yi0ddg",
        "row": 11925,
        "state": "FLORIDA",
        "group": "016953901300028",
        "group_num": [
            "reciefmIkGvt3Tfmw"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 543,
        "2024": 0,
        "id": "recz2D8XvoRdhRjiE",
        "row": 11922,
        "state": "FLORIDA",
        "group": "017513603300104",
        "group_num": [
            "recHDugj5dIaq8Ebt"
        ],
        "procedure": "PHP",
        "provider": "AETNA STUDENT RESOURCES (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 459,
        "2023": 462,
        "2024": 0,
        "id": "receZF1doPNbtezzH",
        "row": 12001,
        "state": "FLORIDA",
        "group": "014135301200001",
        "group_num": [
            "recApSK3N38Jwmatr"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "recdanVtVeko5phcQ",
        "row": 12013,
        "state": "FLORIDA",
        "group": "016973901000101",
        "group_num": [
            "recRMcOMWfcEGV4cu"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "reco9Xe9hEuKumTce",
        "row": 12014,
        "state": "FLORIDA",
        "group": "032633401000001",
        "group_num": [
            "recukOlSPnnzynPS4"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "rect9gKXQmiw9Qync",
        "row": 12015,
        "state": "FLORIDA",
        "group": "088079401400004",
        "group_num": [
            "rec2pvOH1tSELBl3u"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 462,
        "2023": 430,
        "2024": 0,
        "id": "recW0AkpTwQUXvdNo",
        "row": 12036,
        "state": "FLORIDA",
        "group": "17548001100046",
        "group_num": [
            "recyJojnVYEosX0ko"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 430,
        "2024": 0,
        "id": "recXjSnHA4Fns2U4G",
        "row": 12035,
        "state": "FLORIDA",
        "group": "086970601000202",
        "group_num": [
            "recciimQG1uxLu0Si"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 447,
        "2024": 0,
        "id": "recYNyjVgVSjOH5v6",
        "row": 12025,
        "state": "FLORIDA",
        "group": "032641501000014",
        "group_num": [
            "recukB9Rfpm8d6ckQ"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "2023": 430,
        "2024": 0,
        "id": "recq0U840iFsj5PJP",
        "row": 12037,
        "state": "FLORIDA",
        "group": "028400102400001",
        "group_num": [
            "recRC4WyxdK1Ozx8Z"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 447,
        "2024": 0,
        "id": "recuNYCuthuzKXlNM",
        "row": 12026,
        "state": "FLORIDA",
        "group": "032641501200200",
        "group_num": [
            "recokH2TYfutiylnX"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 193,
        "2024": 0,
        "id": "recfG2Nwve8fBuWTj",
        "row": 12161,
        "state": "FLORIDA",
        "group": "019303901100001",
        "group_num": [
            "recRCoS1SDath3wHE"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 193,
        "2024": 0,
        "id": "recgAHDasZUPS1xkI",
        "row": 12160,
        "state": "FLORIDA",
        "group": "011544501100001",
        "group_num": [
            "rec8Y26tkB2S9HDEe"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 225,
        "2024": 0,
        "id": "reci8iXDbycumDOUG",
        "row": 12142,
        "state": "FLORIDA",
        "group": "032633401000001",
        "group_num": [
            "recukOlSPnnzynPS4"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 215,
        "2024": 0,
        "id": "recOpc3lxfKhZzoju",
        "row": 12151,
        "state": "FLORIDA",
        "group": "032641501000008",
        "group_num": [
            "recI3DeiaSO8zEjKE"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 225,
        "2024": 0,
        "id": "recaNXtLHMYTE4JrT",
        "row": 12143,
        "state": "FLORIDA",
        "group": "088079401400004",
        "group_num": [
            "rec2pvOH1tSELBl3u"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 193,
        "2024": 0,
        "id": "recAfnvo4XlFKZpjU",
        "row": 12162,
        "state": "FLORIDA",
        "group": "086970601000202",
        "group_num": [
            "recciimQG1uxLu0Si"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf4JQsHlTOqD3XY",
        "row": 12232,
        "state": "FLORIDA",
        "group": "065958501900101",
        "group_num": [
            "recYWqhM2CeRFTEDh"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 45,
        "2023": 0,
        "2024": 0,
        "id": "recjqrbqXv98wxXvj",
        "row": 12255,
        "state": "FLORIDA",
        "group": "032641501000023",
        "group_num": [
            "rec2QbQxkwoWvYWmY"
        ],
        "procedure": "OP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "2023": 0,
        "2024": 0,
        "id": "rec7QM4R04P7qIn9T",
        "row": 12268,
        "state": "FLORIDA",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 45,
        "2023": 0,
        "2024": 0,
        "id": "reccQNXHUFFwUacnj",
        "row": 12254,
        "state": "FLORIDA",
        "group": "032641501000001",
        "group_num": [
            "rechvMYX5LE0ng0AP"
        ],
        "procedure": "OP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 45,
        "2023": 0,
        "2024": 0,
        "id": "recChDiAtNee4nNom",
        "row": 12256,
        "state": "FLORIDA",
        "group": "086861602200088",
        "group_num": [
            "recSYCQeb3vYVzRPp"
        ],
        "procedure": "OP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recOlr02T0Ydw9SLd",
        "row": 12300,
        "state": "FLORIDA",
        "group": "087600101700001",
        "group_num": [
            "rec5X9ZmVBLH0r2Lu"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recUL09OS0IZ8KHbB",
        "row": 12299,
        "state": "FLORIDA",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 0,
        "2024": 0,
        "id": "rec8w5NrrKyYkgNmY",
        "row": 12304,
        "state": "FLORIDA",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "rec9uNgFkE3lwl01k",
        "row": 12298,
        "state": "FLORIDA",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 0,
        "2024": 0,
        "id": "recpOsp8w0dwkqs8e",
        "row": 12297,
        "state": "FLORIDA",
        "group": "015954701100055",
        "group_num": [
            "reclu86QOw6FYPqsd"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 225,
        "2023": 0,
        "2024": 0,
        "id": "recrFUz6OWZ2xudwP",
        "row": 12308,
        "state": "FLORIDA",
        "group": "081088101800001",
        "group_num": [
            "recaZ82wi4Em4irUd"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 225,
        "2023": 0,
        "2024": 0,
        "id": "recueL7tMOnZ5iguD",
        "row": 12307,
        "state": "FLORIDA",
        "group": "016957901500002",
        "group_num": [
            "recGhzBJGDvB1glDP"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 225,
        "2023": 0,
        "2024": 0,
        "id": "recvUqO0KoZ2nf1q5",
        "row": 12309,
        "state": "FLORIDA",
        "group": "086861602200088",
        "group_num": [
            "recSYCQeb3vYVzRPp"
        ],
        "procedure": "IOP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 242,
        "2023": 0,
        "2024": 0,
        "id": "recEGL5mWraCdFIP4",
        "row": 12315,
        "state": "FLORIDA",
        "group": "028559301500230",
        "group_num": [
            "recExs6EyXE2HtqbR"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "reciAp8F06DlAWNWh",
        "row": 12443,
        "state": "FLORIDA",
        "group": "016957901500002",
        "group_num": [
            "recGhzBJGDvB1glDP"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recVJEl9b1X3UutmG",
        "row": 12444,
        "state": "FLORIDA",
        "group": "070149604700014",
        "group_num": [
            "rec8uYBhgKiQyNRds"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 447,
        "2023": 0,
        "2024": 0,
        "id": "recZcjfJm4Etpi7zt",
        "row": 12441,
        "state": "FLORIDA",
        "group": "086828901000109",
        "group_num": [
            "recuFZbhKKVzuS72w"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 447,
        "2023": 0,
        "2024": 0,
        "id": "rec8y4cmtkb3sQW8F",
        "row": 12439,
        "state": "FLORIDA",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 447,
        "2023": 0,
        "2024": 0,
        "id": "recdwh6o2Zn6qbrQn",
        "row": 12440,
        "state": "FLORIDA",
        "group": "032641501000008",
        "group_num": [
            "recI3DeiaSO8zEjKE"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "2023": 0,
        "2024": 0,
        "id": "recJFtxq5Lu2FW3Nd",
        "row": 12459,
        "state": "FLORIDA",
        "group": "088552401000008",
        "group_num": [
            "recQv6U0bCZGqJbre"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 486,
        "2023": 0,
        "2024": 0,
        "id": "recY6DFLmdN6Y8PxP",
        "row": 12475,
        "state": "FLORIDA",
        "group": "014194301700200",
        "group_num": [
            "recmnhfH59aqKRLW9"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 559,
        "2023": 0,
        "2024": 0,
        "id": "rechU25Yor9aykeMl",
        "row": 12541,
        "state": "FLORIDA",
        "group": "010903201400012",
        "group_num": [
            "recKS7CW8zoi1LfZs"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "2023": 0,
        "2024": 0,
        "id": "recTHK7KlKWVnSUdk",
        "row": 12544,
        "state": "FLORIDA",
        "group": "086523701200105",
        "group_num": [
            "recVcwXZtS1yItNVp"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 559,
        "2023": 0,
        "2024": 0,
        "id": "recXtm8hZmkKVdFeJ",
        "row": 12542,
        "state": "FLORIDA",
        "group": "028559301500230",
        "group_num": [
            "recExs6EyXE2HtqbR"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "2023": 0,
        "2024": 0,
        "id": "recIAN17Z08pHqi6q",
        "row": 12543,
        "state": "FLORIDA",
        "group": "017830001000703",
        "group_num": [
            "recB2V9tE69UGEUOJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "2023": 0,
        "2024": 0,
        "id": "recgLXX42YByGgix4",
        "row": 12593,
        "state": "FLORIDA",
        "group": "011518401100002",
        "group_num": [
            "recwbQmaR6oPBWb3w"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "2023": 0,
        "2024": 0,
        "id": "recRzjElPMPVPoDXl",
        "row": 12592,
        "state": "FLORIDA",
        "group": "17500201000001",
        "group_num": [
            "rec7HfubAoPJqJSnU"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "2023": 0,
        "2024": 0,
        "id": "recWBrkFAvEASHhTQ",
        "row": 12594,
        "state": "FLORIDA",
        "group": "032641501000008",
        "group_num": [
            "recI3DeiaSO8zEjKE"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 736,
        "2023": 0,
        "2024": 0,
        "id": "rec6EH4CocygyqhED",
        "row": 12603,
        "state": "FLORIDA",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "recbnlYL0tQFNlMpR",
        "row": 12614,
        "state": "FLORIDA",
        "group": "088552401000008",
        "group_num": [
            "recQv6U0bCZGqJbre"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "rectrpTH6ZbQw8TzH",
        "row": 12612,
        "state": "FLORIDA",
        "group": "014194301700200",
        "group_num": [
            "recmnhfH59aqKRLW9"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "2023": 0,
        "2024": 0,
        "id": "recy0LV4vVdEO3eqh",
        "row": 12606,
        "state": "FLORIDA",
        "group": "088079102600011",
        "group_num": [
            "recMsNCfDGuYiw07U"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "recFyO5IinQvmE3Of",
        "row": 12613,
        "state": "FLORIDA",
        "group": "028400102400001",
        "group_num": [
            "recRC4WyxdK1Ozx8Z"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2023": 0,
        "2024": 0,
        "id": "recIwu1rEczRGXlcT",
        "row": 12611,
        "state": "FLORIDA",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 908,
        "2023": 0,
        "2024": 0,
        "id": "reckEUfAARa3DAI4u",
        "row": 12696,
        "state": "FLORIDA",
        "group": "0175436",
        "group_num": [
            "recJ20xtz3RUfxyFb"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 908,
        "2023": 0,
        "2024": 0,
        "id": "rec8rm3MFouXhZZyx",
        "row": 12695,
        "state": "FLORIDA",
        "group": "014995001200001",
        "group_num": [
            "recDsHX8Ur2OiA2xa"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "2023": 0,
        "2024": 0,
        "id": "recd69KqOC06R5JGJ",
        "row": 12679,
        "state": "FLORIDA",
        "group": "016125001200001",
        "group_num": [
            "rec7RPcMvxSD4Qsyv"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 876,
        "2023": 0,
        "2024": 0,
        "id": "recdjjiQOm1ack9Fu",
        "row": 12677,
        "state": "FLORIDA",
        "group": "014995001200001",
        "group_num": [
            "recDsHX8Ur2OiA2xa"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 895,
        "2023": 0,
        "2024": 0,
        "id": "recrwqfyfelY4ir1j",
        "row": 12688,
        "state": "FLORIDA",
        "group": "011544501100001",
        "group_num": [
            "rec8Y26tkB2S9HDEe"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recBZDZ1QR6LOO9sz",
        "row": 12720,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recCVerWzjMuhwk0K",
        "row": 12717,
        "state": "FLORIDA",
        "group": "080952701800001",
        "group_num": [
            "recf7agb77btdksLD"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1201,
        "2023": 0,
        "2024": 0,
        "id": "receZIlimHkva1zFx",
        "row": 12767,
        "state": "FLORIDA",
        "group": "080952701800001",
        "group_num": [
            "recf7agb77btdksLD"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1041,
        "2023": 0,
        "2024": 0,
        "id": "recgOr99LSv741ktc",
        "row": 12748,
        "state": "FLORIDA",
        "group": "17548001100046",
        "group_num": [
            "recyJojnVYEosX0ko"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1128,
        "2023": 0,
        "2024": 0,
        "id": "recqDToRzjPkgf9H1",
        "row": 12757,
        "state": "FLORIDA",
        "group": "087600101700001",
        "group_num": [
            "rec5X9ZmVBLH0r2Lu"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1143,
        "2023": 0,
        "2024": 0,
        "id": "recCBCd7Pdi1XXw0O",
        "row": 12763,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1189,
        "2023": 0,
        "2024": 0,
        "id": "recDP6s9qzArPiwWl",
        "row": 12765,
        "state": "FLORIDA",
        "group": "014135301200001",
        "group_num": [
            "recApSK3N38Jwmatr"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1112,
        "2023": 0,
        "2024": 0,
        "id": "recLIfxwONJKJLCdm",
        "row": 12756,
        "state": "FLORIDA",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1451,
        "2023": 0,
        "2024": 0,
        "id": "rechePh8ispwfAP2Q",
        "row": 12794,
        "state": "FLORIDA",
        "group": "086545001000002",
        "group_num": [
            "reckRau9up2PM83ds"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "reciJJMxBmdaCofkZ",
        "row": 12780,
        "state": "FLORIDA",
        "group": "034280601800171",
        "group_num": [
            "rectLgYAqn5QrG7Xy"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recPcU5meH9KegqFv",
        "row": 12812,
        "state": "FLORIDA",
        "group": "071998204300020",
        "group_num": [
            "recBN7uHGUQGIzB6X"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1384,
        "2023": 0,
        "2024": 0,
        "id": "recTxswNJvhX13igN",
        "row": 12790,
        "state": "FLORIDA",
        "group": "018744301000001",
        "group_num": [
            "reccnXe0EDDQfXAEO"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1289,
        "2023": 0,
        "2024": 0,
        "id": "recU71nruxw1iTKdf",
        "row": 12777,
        "state": "FLORIDA",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "rec2Ks98cswkekWpw",
        "row": 12781,
        "state": "FLORIDA",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1469,
        "2023": 0,
        "2024": 0,
        "id": "rec5g83HBHC8hqo21",
        "row": 12796,
        "state": "FLORIDA",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "rec8BM27IwAKXcpxm",
        "row": 12784,
        "state": "FLORIDA",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1231,
        "2023": 0,
        "2024": 0,
        "id": "rec9BldQvT9Q1Wc56",
        "row": 12772,
        "state": "FLORIDA",
        "group": "086587301300702",
        "group_num": [
            "recxN5ItJIPGNT74H"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1612,
        "2023": 0,
        "2024": 0,
        "id": "recqxCZOvKNlkTdw2",
        "row": 12806,
        "state": "FLORIDA",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recsY4Izlq53h8iNz",
        "row": 12811,
        "state": "FLORIDA",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recup3Lt4bc2jJ0pa",
        "row": 12814,
        "state": "FLORIDA",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1338,
        "2023": 0,
        "2024": 0,
        "id": "recy5Pa1BdA1Szvo3",
        "row": 12786,
        "state": "FLORIDA",
        "group": "034280601800171",
        "group_num": [
            "rectLgYAqn5QrG7Xy"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 0,
        "2024": 0,
        "id": "recLUQZw88lu3rluM",
        "row": 12813,
        "state": "FLORIDA",
        "group": "086630201000001",
        "group_num": [
            "recSOHvxy0g4ckAU7"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "recLWwZGkkrWJCYwz",
        "row": 12782,
        "state": "FLORIDA",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1704,
        "2023": 0,
        "2024": 0,
        "id": "recL0lORUnYEnBHLw",
        "row": 12816,
        "state": "FLORIDA",
        "group": "14907701000001",
        "group_num": [
            "rec9WcHy3O4vMROAI"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "2023": 0,
        "2024": 0,
        "id": "recLqya2rqqdEqtt7",
        "row": 12783,
        "state": "FLORIDA",
        "group": "086630201000001",
        "group_num": [
            "recSOHvxy0g4ckAU7"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1313,
        "2023": 0,
        "2024": 0,
        "id": "recMoBI3JyMADIp2J",
        "row": 12779,
        "state": "FLORIDA",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2064,
        "2023": 0,
        "2024": 0,
        "id": "recgXUM4inNmTtoRi",
        "row": 12865,
        "state": "FLORIDA",
        "group": "086545001000002",
        "group_num": [
            "reckRau9up2PM83ds"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recYc6IQM7A2a6OSj",
        "row": 12860,
        "state": "FLORIDA",
        "group": "088079102600011",
        "group_num": [
            "recMsNCfDGuYiw07U"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "recZfthCDbuOqZe62",
        "row": 8007,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 488,
        "2024": 0,
        "id": "recb9Svou4nkMyqaY",
        "row": 8003,
        "state": "FLORIDA",
        "group": "5400013043",
        "group_num": [
            "recaVmtiHejdWz8Mu"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 235,
        "2021": 235,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectmcMeZ5USXvx87",
        "row": 8006,
        "state": "FLORIDA",
        "group": "5400024555",
        "group_num": [
            "recHskLTvZN07fV5I"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 537,
        "2023": 0,
        "2024": 0,
        "id": "recA70EHd7mX6euS2",
        "row": 8002,
        "state": "FLORIDA",
        "group": "5400002249",
        "group_num": [
            "recSxRTGjl8k6jFSD"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 3076,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recG1Zz3NjTG7tMgt",
        "row": 8000,
        "state": "FLORIDA",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 31037,
        "2023": 0,
        "2024": 0,
        "id": "recG5qCOUWqiotxFa",
        "row": 8004,
        "state": "FLORIDA",
        "group": "5400020287",
        "group_num": [
            "recuVR5Lw7A2Hnhlo"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 595,
        "2022": 517,
        "2023": 0,
        "2024": 0,
        "id": "recH4AbCqLWxznYYV",
        "row": 8005,
        "state": "FLORIDA",
        "group": "5400024549",
        "group_num": [
            "recvRsCSUmXhMP6kj"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 252,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKAAzV0yQ2hkaKK",
        "row": 8001,
        "state": "FLORIDA",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 836,
        "2024": 0,
        "id": "recqPl3B2YUFFKG36",
        "row": 11838,
        "state": "FLORIDA",
        "group": "5400017978",
        "group_num": [
            "rec6J8QQNvfAAXDac"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS (ALL SAVERS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 524,
        "2024": 0,
        "id": "recNqZlQqDJLfsBhI",
        "row": 11955,
        "state": "FLORIDA",
        "group": "5400017978",
        "group_num": [
            "rec6J8QQNvfAAXDac"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS (ALL SAVERS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 488,
        "2024": 0,
        "id": "rec5AFv0HFn5ulX4G",
        "row": 11974,
        "state": "FLORIDA",
        "group": "5400013043",
        "group_num": [
            "recaVmtiHejdWz8Mu"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS (ALL SAVERS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 537,
        "2023": 0,
        "2024": 0,
        "id": "recDHbqWsAGsPNcjC",
        "row": 12533,
        "state": "FLORIDA",
        "group": "5400002249",
        "group_num": [
            "recSxRTGjl8k6jFSD"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS (ALL SAVERS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "recPThl93ZscY4uCz",
        "row": 12673,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS (ALL SAVERS)"
    },
    {
        "2020": 0,
        "2021": 580,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ5HoZMjLi5w6vS",
        "row": 8009,
        "state": "FLORIDA",
        "group": "FFMEXCHANGE",
        "group_num": [
            "reczG75oBwPwEYMwa"
        ],
        "procedure": "RTC",
        "provider": "ALLIANT HEALTH PLANS OF GA"
    },
    {
        "2020": 0,
        "2021": 660,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7O3quc2jOUWN7V",
        "row": 8008,
        "state": "FLORIDA",
        "group": "FFMEXCHANGE",
        "group_num": [
            "reczG75oBwPwEYMwa"
        ],
        "procedure": "Detox",
        "provider": "ALLIANT HEALTH PLANS OF GA"
    },
    {
        "2020": 0,
        "2021": 666,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck1xahDWTKYXVlj",
        "row": 8010,
        "state": "FLORIDA",
        "group": "A20103",
        "group_num": [
            "recVnpGyrrDCgTjlA"
        ],
        "procedure": "RTC",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2866,
        "2024": 0,
        "id": "rec3bJZne3EOO4X5r",
        "row": 11515,
        "state": "FLORIDA",
        "group": "L231076",
        "group_num": [
            "recy7xpVBT1a2ltt2"
        ],
        "procedure": "RTC",
        "provider": "ALLIED BENEFITS SYSTEM (ALLIED BENEFIT SYSTEM)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2523,
        "2024": 0,
        "id": "reclXwuzhxNv3rBMB",
        "row": 11532,
        "state": "FLORIDA",
        "group": "L231076",
        "group_num": [
            "recy7xpVBT1a2ltt2"
        ],
        "procedure": "Detox",
        "provider": "ALLIED BENEFITS SYSTEM (ALLIED BENEFIT SYSTEM)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 441,
        "2024": 0,
        "id": "recPvg59KWx0dRwDr",
        "row": 12030,
        "state": "FLORIDA",
        "group": "32201",
        "group_num": [
            "recvKwq9cTL2pJaV2"
        ],
        "procedure": "Detox",
        "provider": "AMERICAN PLAN ADMINISTRATORS (AMERICAN PLAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 305,
        "2024": 0,
        "id": "rec4M7eXjPo3dwOry",
        "row": 12101,
        "state": "FLORIDA",
        "group": "32201",
        "group_num": [
            "recvKwq9cTL2pJaV2"
        ],
        "procedure": "PHP",
        "provider": "AMERICAN PLAN ADMINISTRATORS (AMERICAN PLAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 199,
        "2024": 0,
        "id": "recQ5H3j6zevJxuCk",
        "row": 12156,
        "state": "FLORIDA",
        "group": "32201",
        "group_num": [
            "recvKwq9cTL2pJaV2"
        ],
        "procedure": "RTC",
        "provider": "AMERICAN PLAN ADMINISTRATORS (AMERICAN PLAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 364,
        "2023": 0,
        "2024": 0,
        "id": "reck8coCUauYrE8FB",
        "row": 8012,
        "state": "FLORIDA",
        "group": "I002730",
        "group_num": [
            "recx2zAtQiikp9Wq8"
        ],
        "procedure": "RTC",
        "provider": "AULTCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 625,
        "2023": 0,
        "2024": 0,
        "id": "recOJdXGyKoYY2GhR",
        "row": 8011,
        "state": "FLORIDA",
        "group": "I002730",
        "group_num": [
            "recx2zAtQiikp9Wq8"
        ],
        "procedure": "Detox",
        "provider": "AULTCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 364,
        "2023": 0,
        "2024": 0,
        "id": "recmvMDHlaPEhpg3p",
        "row": 12410,
        "state": "FLORIDA",
        "group": "I002730",
        "group_num": [
            "recx2zAtQiikp9Wq8"
        ],
        "procedure": "RTC",
        "provider": "AULTCARE (AULTCARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 625,
        "2023": 0,
        "2024": 0,
        "id": "recMa9n6fYBK9vdSV",
        "row": 12565,
        "state": "FLORIDA",
        "group": "I002730",
        "group_num": [
            "recx2zAtQiikp9Wq8"
        ],
        "procedure": "Detox",
        "provider": "AULTCARE (AULTCARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5003,
        "2023": 0,
        "2024": 0,
        "id": "receyhTeFAimUIQpz",
        "row": 8013,
        "state": "FLORIDA",
        "group": "SDMP22",
        "group_num": [
            "reciEVvs9MrRpSKHx"
        ],
        "procedure": "RTC",
        "provider": "AVERA HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 797,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjuFbMoMsKbxyul",
        "row": 7087,
        "state": "FLORIDA",
        "group": "774322",
        "group_num": [
            "recQiGTvQdvxUrgwW"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 551,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckKX6lyYrTAhqil",
        "row": 7078,
        "state": "FLORIDA",
        "group": "174264M6D2",
        "group_num": [
            "recLFB9uX7moLv37b"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 904,
        "2023": 0,
        "2024": 0,
        "id": "reclgsuh4Wk4DKDlc",
        "row": 7071,
        "state": "FLORIDA",
        "group": "005802609FL10006",
        "group_num": [
            "recIMy3eyRbYO7CmN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "reclrjZ3NjZFxokdB",
        "row": 7115,
        "state": "FLORIDA",
        "group": "269589",
        "group_num": [
            "recZQ632gxnKdDotP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3277,
        "2023": 0,
        "2024": 0,
        "id": "recmt1WlctHh5vlvn",
        "row": 7076,
        "state": "FLORIDA",
        "group": "174264M3A1",
        "group_num": [
            "recJqFnPgLeYcWN5K"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 191,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recONZpcsgf6Itj16",
        "row": 7080,
        "state": "FLORIDA",
        "group": "7370400",
        "group_num": [
            "recUCWC2Z7obRbTUh"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 818,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recO2Z7LjHCP7WOza",
        "row": 7112,
        "state": "FLORIDA",
        "group": "14163725",
        "group_num": [
            "recX4raPMz1RKcRLW"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 35,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOa1HQR1F8mPQqv",
        "row": 7086,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 830,
        "2023": 0,
        "2024": 0,
        "id": "recQXjrle3CNgQbeV",
        "row": 7091,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1320,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQ5a9EimR0YYFjK",
        "row": 7110,
        "state": "FLORIDA",
        "group": "01310307",
        "group_num": [
            "recuNznsuAvkDwyXG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recRtjQsPYZajFcYA",
        "row": 7099,
        "state": "FLORIDA",
        "group": "14163502",
        "group_num": [
            "rec8OLcSy8YE4o8rz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recRyGaeD7u847i6i",
        "row": 7096,
        "state": "FLORIDA",
        "group": "716001829",
        "group_num": [
            "recb3Io2KejzW4kuG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRlKeNbQwYJ6aIw",
        "row": 7081,
        "state": "FLORIDA",
        "group": "W60528M002",
        "group_num": [
            "recHECNES6XIUCENi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recSU1nnuhelY3KmK",
        "row": 7116,
        "state": "FLORIDA",
        "group": "269589",
        "group_num": [
            "recZQ632gxnKdDotP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2136,
        "2023": 0,
        "2024": 0,
        "id": "recTnFxks4J6C801h",
        "row": 7104,
        "state": "FLORIDA",
        "group": "14163529",
        "group_num": [
            "recMX5fy0pcUFAiVo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recULY05c7agrnDdQ",
        "row": 7111,
        "state": "FLORIDA",
        "group": "14163725",
        "group_num": [
            "recX4raPMz1RKcRLW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "recVFexeKXAEdWEJb",
        "row": 7102,
        "state": "FLORIDA",
        "group": "203456",
        "group_num": [
            "recPFYV40kNVmChD3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX65wDQ54zwwfgj",
        "row": 7082,
        "state": "FLORIDA",
        "group": "W60528M002",
        "group_num": [
            "recHECNES6XIUCENi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 828,
        "2023": 0,
        "2024": 0,
        "id": "recYX1mrI8Gi9Kno0",
        "row": 7089,
        "state": "FLORIDA",
        "group": "00C020M001",
        "group_num": [
            "recygi4ZYhzf41GXS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 0,
        "2024": 0,
        "id": "recYmobxeFY19iM6E",
        "row": 7084,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "2023": 0,
        "2024": 0,
        "id": "rec0SP4B9bOTR0omr",
        "row": 7094,
        "state": "FLORIDA",
        "group": "002321613",
        "group_num": [
            "recWLa8lrFMh2Y5VQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 718,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0AEqc6I1K1wUFe",
        "row": 7074,
        "state": "FLORIDA",
        "group": "10106255",
        "group_num": [
            "recBCg3bkDtwVGBBE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 565,
        "2024": 0,
        "id": "rec201aQ4Llhdkkax",
        "row": 7114,
        "state": "FLORIDA",
        "group": "201075MGDG",
        "group_num": [
            "recR9u7WDJGIaJY5c"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "rec3Tso5uu6dOOUIG",
        "row": 7068,
        "state": "FLORIDA",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "rec3w5phxxo421igu",
        "row": 7098,
        "state": "FLORIDA",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 670,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3bbpnWyzmhVgJX",
        "row": 7101,
        "state": "FLORIDA",
        "group": "203456",
        "group_num": [
            "recPFYV40kNVmChD3"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3r5D0d5irq032I",
        "row": 7083,
        "state": "FLORIDA",
        "group": "W60528M002",
        "group_num": [
            "recHECNES6XIUCENi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 215,
        "2021": 215,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5XJDR9KqIDBUEl",
        "row": 7079,
        "state": "FLORIDA",
        "group": "174264M6D2",
        "group_num": [
            "recLFB9uX7moLv37b"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2308,
        "2023": 0,
        "2024": 0,
        "id": "rec5dY0lVHpjMsf3o",
        "row": 7075,
        "state": "FLORIDA",
        "group": "174264M3A1",
        "group_num": [
            "recJqFnPgLeYcWN5K"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec7sdIdcaxVPUhtG",
        "row": 7073,
        "state": "FLORIDA",
        "group": "270114M1AN",
        "group_num": [
            "recmY9oSD6gAVlVHw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 900,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8cP9Oa1dJRwdcb",
        "row": 7088,
        "state": "FLORIDA",
        "group": "774322",
        "group_num": [
            "recQiGTvQdvxUrgwW"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1940,
        "2023": 0,
        "2024": 0,
        "id": "rec9iKVn6gooQE8rW",
        "row": 7093,
        "state": "FLORIDA",
        "group": "270139M1BA",
        "group_num": [
            "recRUQ9itfNWtcb4N"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3415,
        "2023": 0,
        "2024": 0,
        "id": "reccD0iqd8ZVTtG8P",
        "row": 7103,
        "state": "FLORIDA",
        "group": "14163529",
        "group_num": [
            "recMX5fy0pcUFAiVo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc72FYTTpRnTu7O",
        "row": 7117,
        "state": "FLORIDA",
        "group": "2010871CA6",
        "group_num": [
            "recWIwzUxll70jmaD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdOm8IH8myMCG7r",
        "row": 7108,
        "state": "FLORIDA",
        "group": "0080302OS1",
        "group_num": [
            "recISbTlrQ0L4v7it"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 970,
        "2023": 0,
        "2024": 0,
        "id": "recdi6IEJF2Eg3Hrf",
        "row": 7070,
        "state": "FLORIDA",
        "group": "005802609FL10006",
        "group_num": [
            "recIMy3eyRbYO7CmN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqshJT4uohAEBWc",
        "row": 7105,
        "state": "FLORIDA",
        "group": "CB250I",
        "group_num": [
            "recXW744Z1a9cvzsG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1884,
        "2023": 0,
        "2024": 0,
        "id": "recqHosOlLQsSDeHm",
        "row": 7069,
        "state": "FLORIDA",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqN2GPvyeKLn9wL",
        "row": 7107,
        "state": "FLORIDA",
        "group": "0080302OS1",
        "group_num": [
            "recISbTlrQ0L4v7it"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2309,
        "2023": 0,
        "2024": 0,
        "id": "recvC0NuVCRaBV0Ix",
        "row": 7113,
        "state": "FLORIDA",
        "group": "201075MGDG",
        "group_num": [
            "recR9u7WDJGIaJY5c"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1625,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwV7q51ZI3ecp5F",
        "row": 7100,
        "state": "FLORIDA",
        "group": "003322065APHP00",
        "group_num": [
            "recu7Urf2X98yTq0l"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1360,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recy29nUwI25lbzEJ",
        "row": 7109,
        "state": "FLORIDA",
        "group": "01310307",
        "group_num": [
            "recuNznsuAvkDwyXG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recyaUtT0DLxkc1gT",
        "row": 7092,
        "state": "FLORIDA",
        "group": "00418019",
        "group_num": [
            "recMsXdc89OQiweGs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recCdrxaAXDMuJP4j",
        "row": 7095,
        "state": "FLORIDA",
        "group": "002321613",
        "group_num": [
            "recWLa8lrFMh2Y5VQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "2023": 0,
        "2024": 0,
        "id": "recHfhSMDj5UMeEYM",
        "row": 7090,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recJkL4w4H8hgY8qk",
        "row": 7072,
        "state": "FLORIDA",
        "group": "270114M1AN",
        "group_num": [
            "recmY9oSD6gAVlVHw"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 829,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recLpslzI9XxPUzki",
        "row": 7097,
        "state": "FLORIDA",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1471,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMTxLt5BsSm514d",
        "row": 7077,
        "state": "FLORIDA",
        "group": "174264M6D2",
        "group_num": [
            "recLFB9uX7moLv37b"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 97,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNUApLMQjRmnZRv",
        "row": 7085,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNwas61ut3FLNe7",
        "row": 7106,
        "state": "FLORIDA",
        "group": "0080302OS1",
        "group_num": [
            "recISbTlrQ0L4v7it"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "2023": 0,
        "2024": 0,
        "id": "recezeqOotr7qau73",
        "row": 7129,
        "state": "FLORIDA",
        "group": "80963",
        "group_num": [
            "reczIOON3EZKygglC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 952,
        "2024": 0,
        "id": "recglqOGljJIhU12o",
        "row": 7136,
        "state": "FLORIDA",
        "group": "F6D",
        "group_num": [
            "reciqV0knLTr3XjJo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3107,
        "2023": 0,
        "2024": 0,
        "id": "recjswiqIo1zUA9O2",
        "row": 7146,
        "state": "FLORIDA",
        "group": "201028M1PA",
        "group_num": [
            "rec5quPkXyZuPMSOC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recnb1owHvo2A1lYW",
        "row": 7155,
        "state": "FLORIDA",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "2023": 0,
        "2024": 0,
        "id": "recOsk8U1b2bU16Sn",
        "row": 7149,
        "state": "FLORIDA",
        "group": "376014",
        "group_num": [
            "reczgcjsnz7bDlBNC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 250,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recPT0S6h5EHohS3l",
        "row": 7158,
        "state": "FLORIDA",
        "group": "10282137",
        "group_num": [
            "rec5EV1I6plhPgWc6"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "2023": 0,
        "2024": 0,
        "id": "recPusLOljEcCXv8i",
        "row": 7124,
        "state": "FLORIDA",
        "group": "276945M004",
        "group_num": [
            "rec4hkUQxE4q1Pby7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 151,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPkIXbBR1GZ17CW",
        "row": 7134,
        "state": "FLORIDA",
        "group": "210041A9AU",
        "group_num": [
            "recWRBWNCvLzxsoo5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 291,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recQXDE9JlLro0riu",
        "row": 7139,
        "state": "FLORIDA",
        "group": "212063M1AA",
        "group_num": [
            "recLkuH3wZ3jrLXIy"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 490,
        "2023": 0,
        "2024": 0,
        "id": "recSDUEAJwlzO3CzP",
        "row": 7121,
        "state": "FLORIDA",
        "group": "CT2002M001",
        "group_num": [
            "recfPiL7R4mMPPPMm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1487,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT4pB5Md33sxCjG",
        "row": 7165,
        "state": "FLORIDA",
        "group": "08407000",
        "group_num": [
            "recpIaasX1LV36yYr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 2746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT9VY9lGLR2aCOr",
        "row": 7132,
        "state": "FLORIDA",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 0,
        "2024": 0,
        "id": "recU0J17HU3bi0Rva",
        "row": 7154,
        "state": "FLORIDA",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 467,
        "2023": 0,
        "2024": 0,
        "id": "recULwyKkF7U73qYB",
        "row": 7127,
        "state": "FLORIDA",
        "group": "GB0192M005",
        "group_num": [
            "recdsiZKzqkIq5A8N"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV6HUSsw5jCbZRr",
        "row": 7150,
        "state": "FLORIDA",
        "group": "174611M1GA",
        "group_num": [
            "receRTlLJK8MNQijw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recVlvWmfG9MvpDpV",
        "row": 7125,
        "state": "FLORIDA",
        "group": "276945M004",
        "group_num": [
            "rec4hkUQxE4q1Pby7"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 708,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWEKKbmXkFLomVB",
        "row": 7160,
        "state": "FLORIDA",
        "group": "75824",
        "group_num": [
            "recN2kUrJmutntmPM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "recWb1TNHzzmez5gS",
        "row": 7162,
        "state": "FLORIDA",
        "group": "00759060000",
        "group_num": [
            "recs43q0lrBDCIuw5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1188,
        "2023": 0,
        "2024": 0,
        "id": "recWhF7XYKdLSelVs",
        "row": 7123,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 489,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXmv2UtNzJaIuTb",
        "row": 7138,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3167,
        "2023": 0,
        "2024": 0,
        "id": "recXqeab1TWL0AfFR",
        "row": 7156,
        "state": "FLORIDA",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2578,
        "2023": 0,
        "2024": 0,
        "id": "recYFVVOIkSVNGWqs",
        "row": 7161,
        "state": "FLORIDA",
        "group": "00759060000",
        "group_num": [
            "recs43q0lrBDCIuw5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ4jhI5QehHVTCi",
        "row": 7126,
        "state": "FLORIDA",
        "group": "270094M1WC",
        "group_num": [
            "recvWyg2lAW8WmpwC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 600,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1i4FfUdF3cbfJe",
        "row": 7157,
        "state": "FLORIDA",
        "group": "10282137",
        "group_num": [
            "rec5EV1I6plhPgWc6"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 237,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4CxUzevu8Ef2ey",
        "row": 7152,
        "state": "FLORIDA",
        "group": "00332076002AP00",
        "group_num": [
            "recdhghn3eQdvKr5H"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1726,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7AXclvs9PB7asO",
        "row": 7143,
        "state": "FLORIDA",
        "group": "009230500",
        "group_num": [
            "recVeXsVytWoORnw4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 568,
        "2023": 0,
        "2024": 0,
        "id": "rec8W1J8Waz2thNqs",
        "row": 7153,
        "state": "FLORIDA",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 164,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec84WttCmazAzmxd",
        "row": 7142,
        "state": "FLORIDA",
        "group": "182001MA06",
        "group_num": [
            "recetpvFJwSid3zlc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "rec8b5O6ypmwJ38qm",
        "row": 7148,
        "state": "FLORIDA",
        "group": "L01412M010",
        "group_num": [
            "recuKYM4mN0NdVkJv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 551,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec95aVHhz8tZTKnq",
        "row": 7144,
        "state": "FLORIDA",
        "group": "009230500",
        "group_num": [
            "recVeXsVytWoORnw4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 936,
        "2023": 938,
        "2024": 0,
        "id": "rec9m5MWVqC245aZe",
        "row": 7119,
        "state": "FLORIDA",
        "group": "001903623MD10006",
        "group_num": [
            "recYoAfJgDHuE9JHz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4144,
        "2023": 0,
        "2024": 0,
        "id": "reccCOuaxo7JTxFBC",
        "row": 7145,
        "state": "FLORIDA",
        "group": "201028M1PA",
        "group_num": [
            "rec5quPkXyZuPMSOC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 125,
        "2023": 0,
        "2024": 0,
        "id": "reccK0Q9BZNJRXJ3U",
        "row": 7147,
        "state": "FLORIDA",
        "group": "L01412M010",
        "group_num": [
            "recuKYM4mN0NdVkJv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 952,
        "2024": 0,
        "id": "recdYcNTftupEqKA2",
        "row": 7135,
        "state": "FLORIDA",
        "group": "F6D",
        "group_num": [
            "reciqV0knLTr3XjJo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1616,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recswzWA1Qu98BezO",
        "row": 7133,
        "state": "FLORIDA",
        "group": "210041A9AU",
        "group_num": [
            "recWRBWNCvLzxsoo5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 1749,
        "2023": 0,
        "2024": 0,
        "id": "recw4E0n7b6G6luOU",
        "row": 7166,
        "state": "FLORIDA",
        "group": "08407000",
        "group_num": [
            "recpIaasX1LV36yYr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 543,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxe6X1XUM6UeG8e",
        "row": 7151,
        "state": "FLORIDA",
        "group": "00332076002AP00",
        "group_num": [
            "recdhghn3eQdvKr5H"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recyuEJrXGoUZqOnQ",
        "row": 7159,
        "state": "FLORIDA",
        "group": "10567859",
        "group_num": [
            "recTzMgKXH3zlcP2q"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 783,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBIlFCFImZRnzSb",
        "row": 7130,
        "state": "FLORIDA",
        "group": "1867BE",
        "group_num": [
            "recaURq0bbN6DGVqz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1210,
        "2023": 0,
        "2024": 0,
        "id": "recBbdDSTamjVRSIK",
        "row": 7122,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCulXjjtgCNcqkx",
        "row": 7137,
        "state": "FLORIDA",
        "group": "174511M100",
        "group_num": [
            "rectxYmOWZwzNeIHr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 35,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCPJ8RAIhd9NevN",
        "row": 7164,
        "state": "FLORIDA",
        "group": "716001368",
        "group_num": [
            "recfoERDxvXVNrJCa"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 327,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDc99WOygFIBM67",
        "row": 7141,
        "state": "FLORIDA",
        "group": "182001MA06",
        "group_num": [
            "recetpvFJwSid3zlc"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 945,
        "2023": 0,
        "2024": 0,
        "id": "recDgjjoATQBlLYyu",
        "row": 7128,
        "state": "FLORIDA",
        "group": "GB0192M005",
        "group_num": [
            "recdsiZKzqkIq5A8N"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFyWDqUDJk9ucFq",
        "row": 7163,
        "state": "FLORIDA",
        "group": "716001368",
        "group_num": [
            "recfoERDxvXVNrJCa"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 2746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGUWJx0jXPd2o5f",
        "row": 7131,
        "state": "FLORIDA",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 218,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJzzvXONN4qoSlO",
        "row": 7167,
        "state": "FLORIDA",
        "group": "601722",
        "group_num": [
            "recP1e24ilMhY3GiH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJho02NxH1uKfOP",
        "row": 7120,
        "state": "FLORIDA",
        "group": "CT2002M001",
        "group_num": [
            "recfPiL7R4mMPPPMm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 225,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKGY37GA4lkHoCZ",
        "row": 7118,
        "state": "FLORIDA",
        "group": "2010871CA6",
        "group_num": [
            "recWIwzUxll70jmaD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 808,
        "2021": 818,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNKr3AiWcLKWqsN",
        "row": 7140,
        "state": "FLORIDA",
        "group": "182001MA06",
        "group_num": [
            "recetpvFJwSid3zlc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rececyryCG3zMaKF2",
        "row": 7212,
        "state": "FLORIDA",
        "group": "213051A3FA",
        "group_num": [
            "rechYPCVyUNvxNgPp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recg6UdqH23N6Zo7Z",
        "row": 7204,
        "state": "FLORIDA",
        "group": "307951",
        "group_num": [
            "recYzkSbx77Gf0MMM"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 883,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech9K6aWMp07iTRh",
        "row": 7203,
        "state": "FLORIDA",
        "group": "245796",
        "group_num": [
            "rec3vO0LEGIuDGZ0Q"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 161,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recktaj8f4ZiumMGE",
        "row": 7196,
        "state": "FLORIDA",
        "group": "001902844MD2000",
        "group_num": [
            "recVIsebNz1oXtM0b"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1759,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckEtJ0MbxTedynG",
        "row": 7171,
        "state": "FLORIDA",
        "group": "195095",
        "group_num": [
            "recMNJk8RkoD71aUD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmCwXylqCeIj5Ug",
        "row": 7183,
        "state": "FLORIDA",
        "group": "16153001",
        "group_num": [
            "rec1FFlOyYJOWmL2Z"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmn5NwbtwtOH0sW",
        "row": 7193,
        "state": "FLORIDA",
        "group": "99K1",
        "group_num": [
            "rec7cekqMFmH11Xgr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 741,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQUFHUOE5uFuqMq",
        "row": 7185,
        "state": "FLORIDA",
        "group": "43239000",
        "group_num": [
            "recYliUH7AbgUcOm4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 4236,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQuLZn8C8Rr2D8b",
        "row": 7173,
        "state": "FLORIDA",
        "group": "NY45490C2M",
        "group_num": [
            "recqXkr70fbPFKT8O"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUJjANEgr8RqYXJ",
        "row": 7187,
        "state": "FLORIDA",
        "group": "716045848",
        "group_num": [
            "receAYWvudj8iN5qO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 888,
        "2023": 0,
        "2024": 0,
        "id": "recUPyfoXAUAzcehJ",
        "row": 7217,
        "state": "FLORIDA",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recWUYixZzoFNLdlO",
        "row": 7207,
        "state": "FLORIDA",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 19,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWKfX9JRTZxSYYz",
        "row": 7208,
        "state": "FLORIDA",
        "group": "J07422",
        "group_num": [
            "recqOo3cFowRoV9bn"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3906,
        "2023": 952,
        "2024": 0,
        "id": "recZgM0KEyPlBz3lo",
        "row": 7202,
        "state": "FLORIDA",
        "group": "W42781M001",
        "group_num": [
            "reckehoDBHehBMaat"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3219,
        "2023": 0,
        "2024": 0,
        "id": "rec1tAChaNXr1xraM",
        "row": 7179,
        "state": "FLORIDA",
        "group": "201064HAFA",
        "group_num": [
            "recgZSxwypZCfNOoF"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3n5bcUCjZ78wh8",
        "row": 7176,
        "state": "FLORIDA",
        "group": "002735",
        "group_num": [
            "reclU2WbHyJjzik1N"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 84,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5OT1LmDbbKxRIS",
        "row": 7180,
        "state": "FLORIDA",
        "group": "DX1512",
        "group_num": [
            "rec4qI3frs5ryqYFa"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3156,
        "2023": 0,
        "2024": 0,
        "id": "rec7BE7oaZJE2uhSC",
        "row": 7213,
        "state": "FLORIDA",
        "group": "213051A3FA",
        "group_num": [
            "rechYPCVyUNvxNgPp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "rec7k64UyAMSd8vm0",
        "row": 7206,
        "state": "FLORIDA",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec8SmTB7a8Qn0spM",
        "row": 7181,
        "state": "FLORIDA",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9lzMeb94CvgPph",
        "row": 7215,
        "state": "FLORIDA",
        "group": "00761020003",
        "group_num": [
            "recYUfYoIbfDr9vxV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 2375,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccijKy0Fuqw4is8",
        "row": 7177,
        "state": "FLORIDA",
        "group": "002735",
        "group_num": [
            "reclU2WbHyJjzik1N"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "reco6albQ3NFoPRxo",
        "row": 7201,
        "state": "FLORIDA",
        "group": "W42781M001",
        "group_num": [
            "reckehoDBHehBMaat"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpJCtINOQV7k523",
        "row": 7186,
        "state": "FLORIDA",
        "group": "43239000",
        "group_num": [
            "recYliUH7AbgUcOm4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 789,
        "2022": 857,
        "2023": 0,
        "2024": 0,
        "id": "recrHLTM9A9f2AqxI",
        "row": 7195,
        "state": "FLORIDA",
        "group": "99EE",
        "group_num": [
            "recwmLTQBcnuuOQOU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recrR7V4vS50p5dyf",
        "row": 7198,
        "state": "FLORIDA",
        "group": "005801937FL10016",
        "group_num": [
            "recrgbXfFRnGVJCHq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 537,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsSqPRUKtXvaMzT",
        "row": 7189,
        "state": "FLORIDA",
        "group": "2700833GIA",
        "group_num": [
            "recYM9rT8kOas3x5J"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 310,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recs17IE3hvHeDq97",
        "row": 7175,
        "state": "FLORIDA",
        "group": "NY45490C2M",
        "group_num": [
            "recqXkr70fbPFKT8O"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect7sGM15VvlRBaJ",
        "row": 7190,
        "state": "FLORIDA",
        "group": "80897",
        "group_num": [
            "recqKy6Abagt4yz7e"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1467,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect835OEZ4g1wXHZ",
        "row": 7168,
        "state": "FLORIDA",
        "group": "601722",
        "group_num": [
            "recP1e24ilMhY3GiH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recuojKg91cpAiwvE",
        "row": 7216,
        "state": "FLORIDA",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recxm388ZSaCFMROy",
        "row": 7214,
        "state": "FLORIDA",
        "group": "36956",
        "group_num": [
            "recJPb3hYIT6i6oYU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recybaGs85izuCNmH",
        "row": 7200,
        "state": "FLORIDA",
        "group": "W41524M003",
        "group_num": [
            "recytMKFAaPA3ET4W"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "reczInac9upwLBdxl",
        "row": 7210,
        "state": "FLORIDA",
        "group": "00233307",
        "group_num": [
            "rechPxXocjKVGKRS2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 517,
        "2022": 383,
        "2023": 473,
        "2024": 0,
        "id": "reczQKno2c5IZwWCL",
        "row": 7191,
        "state": "FLORIDA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recAWN0ilKAACQdi9",
        "row": 7211,
        "state": "FLORIDA",
        "group": "00233307",
        "group_num": [
            "rechPxXocjKVGKRS2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recCuXwKIM1zMFarw",
        "row": 7205,
        "state": "FLORIDA",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 849,
        "2021": 818,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDOR2Oli0AxYj6e",
        "row": 7169,
        "state": "FLORIDA",
        "group": "31539001",
        "group_num": [
            "rec7mQjqdPApDNdLK"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 66,
        "2023": 0,
        "2024": 0,
        "id": "recF8lAN2WwK5GjtQ",
        "row": 7209,
        "state": "FLORIDA",
        "group": "02AP001",
        "group_num": [
            "recUKp3vxe1Vg4v4I"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1664,
        "2022": 1596,
        "2023": 0,
        "2024": 0,
        "id": "recGceHdHu9dOWPrU",
        "row": 7188,
        "state": "FLORIDA",
        "group": "2700833GIA",
        "group_num": [
            "recYM9rT8kOas3x5J"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGpJezjSW6G7B2c",
        "row": 7184,
        "state": "FLORIDA",
        "group": "43239000",
        "group_num": [
            "recYliUH7AbgUcOm4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHeIIhLhzm1LSXx",
        "row": 7170,
        "state": "FLORIDA",
        "group": "31539001",
        "group_num": [
            "rec7mQjqdPApDNdLK"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 285,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIV8BD0WpWdPiZ6",
        "row": 7174,
        "state": "FLORIDA",
        "group": "NY45490C2M",
        "group_num": [
            "recqXkr70fbPFKT8O"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1792,
        "2023": 0,
        "2024": 0,
        "id": "recIgMoe522YUmrco",
        "row": 7178,
        "state": "FLORIDA",
        "group": "201064HAFA",
        "group_num": [
            "recgZSxwypZCfNOoF"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJcNOgplPi3yZFo",
        "row": 7197,
        "state": "FLORIDA",
        "group": "001902844MD2000",
        "group_num": [
            "recVIsebNz1oXtM0b"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 525,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKTBrcyP3hY4nlP",
        "row": 7172,
        "state": "FLORIDA",
        "group": "195095",
        "group_num": [
            "recMNJk8RkoD71aUD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1023,
        "2022": 320,
        "2023": 288,
        "2024": 0,
        "id": "recLWM5HPQVAPBaq5",
        "row": 7192,
        "state": "FLORIDA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMnnmoPu3eriDi1",
        "row": 7194,
        "state": "FLORIDA",
        "group": "99N1",
        "group_num": [
            "rechRK5CNHSxIfy1K"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 167,
        "2023": 0,
        "2024": 0,
        "id": "recNSsbqizypbOum9",
        "row": 7182,
        "state": "FLORIDA",
        "group": "16153001",
        "group_num": [
            "rec1FFlOyYJOWmL2Z"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "recNp9vr9l45oy16m",
        "row": 7199,
        "state": "FLORIDA",
        "group": "W41524M003",
        "group_num": [
            "recytMKFAaPA3ET4W"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recjiitpJ7e3rwZqE",
        "row": 7237,
        "state": "FLORIDA",
        "group": "LBC361",
        "group_num": [
            "reco2vOUaWBSPuAqb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 562,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recltWF5szReqVyqV",
        "row": 7246,
        "state": "FLORIDA",
        "group": "276146M014",
        "group_num": [
            "recITIrex50F4gQsV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recleKgFYD8NKb4mW",
        "row": 7240,
        "state": "FLORIDA",
        "group": "61497002",
        "group_num": [
            "recb8gRYIlEiE0S0g"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 151,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmRj21gXl8BpU0A",
        "row": 7257,
        "state": "FLORIDA",
        "group": "001900309MD0001",
        "group_num": [
            "recMkhdiZLQRClJzK"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 562,
        "2022": 562,
        "2023": 0,
        "2024": 0,
        "id": "recmdTtJuTYwr2ZXP",
        "row": 7264,
        "state": "FLORIDA",
        "group": "170100MD31",
        "group_num": [
            "reckAxXnheAb4OhQ1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recntIxQs86YYB0a6",
        "row": 7252,
        "state": "FLORIDA",
        "group": "15921013",
        "group_num": [
            "recGirw3o5iM1QgQp"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1590,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSWH0u5SBrKKkBK",
        "row": 7245,
        "state": "FLORIDA",
        "group": "276146M014",
        "group_num": [
            "recITIrex50F4gQsV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 582,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUXSggqitBHWpnk",
        "row": 7227,
        "state": "FLORIDA",
        "group": "287831",
        "group_num": [
            "recnH8m9sxlWPLFvD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recUnvm3IaYJsVou1",
        "row": 7247,
        "state": "FLORIDA",
        "group": "101935002",
        "group_num": [
            "recyXAqc7N91z8L6F"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 575,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVUSMzX0q9MKwH3",
        "row": 7219,
        "state": "FLORIDA",
        "group": "004009986011P01",
        "group_num": [
            "recqiFROJFf6AbssX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 162,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXfzo3BP5SybNK1",
        "row": 7236,
        "state": "FLORIDA",
        "group": "SK0715M002",
        "group_num": [
            "rec04VU5xupCXxEb9"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 123,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYFgqd8WyZ4vmOt",
        "row": 7251,
        "state": "FLORIDA",
        "group": "15921013",
        "group_num": [
            "recGirw3o5iM1QgQp"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1621,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY5dI0oe8gTne68",
        "row": 7244,
        "state": "FLORIDA",
        "group": "276146M014",
        "group_num": [
            "recITIrex50F4gQsV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 84,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recY8FQRiToHnP2UO",
        "row": 7229,
        "state": "FLORIDA",
        "group": "287831",
        "group_num": [
            "recnH8m9sxlWPLFvD"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 562,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYcANSR4AFLRObD",
        "row": 7255,
        "state": "FLORIDA",
        "group": "201044M1A1",
        "group_num": [
            "recH1I5Yr0aCE46FX"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZSpOb8AEo5fTF2",
        "row": 7258,
        "state": "FLORIDA",
        "group": "001900309MD0001",
        "group_num": [
            "recMkhdiZLQRClJzK"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZQku9OiMlu8azM",
        "row": 7259,
        "state": "FLORIDA",
        "group": "001900309MD0001",
        "group_num": [
            "recMkhdiZLQRClJzK"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec4Ve584Ms1UcZdF",
        "row": 7265,
        "state": "FLORIDA",
        "group": "005801171DC10006",
        "group_num": [
            "rec7MMSeHWCoORIi9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 219,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec40otDYG3T3n0Ov",
        "row": 7222,
        "state": "FLORIDA",
        "group": "003320800M1AP00",
        "group_num": [
            "reciXuQtuJn8dnW1W"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec4AV4CH4LLzlVex",
        "row": 7242,
        "state": "FLORIDA",
        "group": "011061",
        "group_num": [
            "recVyR3sLysOG5I5c"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 272,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec73y2YD0WrHDhdU",
        "row": 7228,
        "state": "FLORIDA",
        "group": "287831",
        "group_num": [
            "recnH8m9sxlWPLFvD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 981,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec926JouJ2dGtN5P",
        "row": 7226,
        "state": "FLORIDA",
        "group": "287831",
        "group_num": [
            "recnH8m9sxlWPLFvD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recaNIxrRuhTptdIF",
        "row": 7238,
        "state": "FLORIDA",
        "group": "LBC361",
        "group_num": [
            "reco2vOUaWBSPuAqb"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1754,
        "2023": 0,
        "2024": 0,
        "id": "recaqJO6IvxxrTf8d",
        "row": 7224,
        "state": "FLORIDA",
        "group": "L03519M014",
        "group_num": [
            "rec9ig0At6JkM8Niv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 483,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbTRvUo337p05pk",
        "row": 7261,
        "state": "FLORIDA",
        "group": "01790743",
        "group_num": [
            "recoCVBTH2D6KlUXo"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3121,
        "2023": 0,
        "2024": 0,
        "id": "recbNeFzLs23kKF70",
        "row": 7225,
        "state": "FLORIDA",
        "group": "L03519M014",
        "group_num": [
            "rec9ig0At6JkM8Niv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 346,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbPqRbH5i3hI8dj",
        "row": 7262,
        "state": "FLORIDA",
        "group": "01790743",
        "group_num": [
            "recoCVBTH2D6KlUXo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1252,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdPKt7lOV6fy6Vj",
        "row": 7260,
        "state": "FLORIDA",
        "group": "01790743",
        "group_num": [
            "recoCVBTH2D6KlUXo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 136,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrE3YUCkH7Rm89p",
        "row": 7218,
        "state": "FLORIDA",
        "group": "004009986",
        "group_num": [
            "recuqwBJMcMYOOd0N"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsyPsANgkdUMTME",
        "row": 7234,
        "state": "FLORIDA",
        "group": "SK0715M002",
        "group_num": [
            "rec04VU5xupCXxEb9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recs6hTkCB6TFlQGQ",
        "row": 7239,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recsp4n7CWwbd6ZZe",
        "row": 7243,
        "state": "FLORIDA",
        "group": "011061",
        "group_num": [
            "recVyR3sLysOG5I5c"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1635,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recusXKh76KgHaOL4",
        "row": 7263,
        "state": "FLORIDA",
        "group": "170100MD31",
        "group_num": [
            "reckAxXnheAb4OhQ1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2850,
        "2024": 0,
        "id": "recwbmw7oRRbYxyQp",
        "row": 7267,
        "state": "FLORIDA",
        "group": "715984202",
        "group_num": [
            "recoTgu9bzEzPbRW7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwc02gNwfvvfvfY",
        "row": 7235,
        "state": "FLORIDA",
        "group": "SK0715M002",
        "group_num": [
            "rec04VU5xupCXxEb9"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwoY3g8qmdmZBcy",
        "row": 7256,
        "state": "FLORIDA",
        "group": "001900309MD0001",
        "group_num": [
            "recMkhdiZLQRClJzK"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 230,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxXRvcuIN0bLteS",
        "row": 7220,
        "state": "FLORIDA",
        "group": "004009986011P01",
        "group_num": [
            "recqiFROJFf6AbssX"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 115,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyVODFjazMWMBNU",
        "row": 7221,
        "state": "FLORIDA",
        "group": "004009986011P01",
        "group_num": [
            "recqiFROJFf6AbssX"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1724,
        "2024": 0,
        "id": "recyw5hujOJxCpe0j",
        "row": 7249,
        "state": "FLORIDA",
        "group": "270181G3A1",
        "group_num": [
            "recHNl4nDPROBSnnP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyNoQ0Y7n7elFya",
        "row": 7231,
        "state": "FLORIDA",
        "group": "716040604",
        "group_num": [
            "receB4QCoZMFKQxhI"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1672,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyRXlS1Jy8SQ2nD",
        "row": 7254,
        "state": "FLORIDA",
        "group": "201044M1A1",
        "group_num": [
            "recH1I5Yr0aCE46FX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1512,
        "2024": 0,
        "id": "reczE4IZKAXiSf1hU",
        "row": 7230,
        "state": "FLORIDA",
        "group": "174084M1A1",
        "group_num": [
            "recBkt5xMrE8M6DGs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1680,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recASofs0YOZLHCya",
        "row": 7253,
        "state": "FLORIDA",
        "group": "201044M1A1",
        "group_num": [
            "recH1I5Yr0aCE46FX"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2517,
        "2023": 0,
        "2024": 0,
        "id": "recAHCEZfM5Hz3H23",
        "row": 7233,
        "state": "FLORIDA",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1682,
        "2024": 0,
        "id": "recBPdjoeRlDHrnIf",
        "row": 7248,
        "state": "FLORIDA",
        "group": "270181G3A1",
        "group_num": [
            "recHNl4nDPROBSnnP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2332,
        "2023": 0,
        "2024": 0,
        "id": "recFNj9DWxgslPwid",
        "row": 7232,
        "state": "FLORIDA",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 1142,
        "2024": 0,
        "id": "recHX1SXOrdzWkzYB",
        "row": 7266,
        "state": "FLORIDA",
        "group": "005801171DC10006",
        "group_num": [
            "rec7MMSeHWCoORIi9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIftUWJ2Sp0E9eZ",
        "row": 7241,
        "state": "FLORIDA",
        "group": "49466003",
        "group_num": [
            "recj1VM8mAbHn4RuK"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKCzwAYxobu4j1R",
        "row": 7223,
        "state": "FLORIDA",
        "group": "003320800M1AP00",
        "group_num": [
            "reciXuQtuJn8dnW1W"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1549,
        "2024": 0,
        "id": "recKGJorH6mRYeuia",
        "row": 7250,
        "state": "FLORIDA",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receFnY8tFBSW2OK3",
        "row": 7317,
        "state": "FLORIDA",
        "group": "715940400",
        "group_num": [
            "recgcp5ZvEzmbLYuQ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1659,
        "2024": 0,
        "id": "recfX0YtdgoCtUFFM",
        "row": 7268,
        "state": "FLORIDA",
        "group": "JVA002M042",
        "group_num": [
            "recoZDszkShrcJv4M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 326,
        "2023": 0,
        "2024": 0,
        "id": "reciMevT9CzQfVHDX",
        "row": 7313,
        "state": "FLORIDA",
        "group": "0092851000",
        "group_num": [
            "recIFbWgBARAPMXlD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1667,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjeX3W4LiHkEFvy",
        "row": 7293,
        "state": "FLORIDA",
        "group": "212056M1A1",
        "group_num": [
            "recMPM0TtafR3zXcZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclEZtRDzsvyUX1u",
        "row": 7316,
        "state": "FLORIDA",
        "group": "000PTH83401BP00",
        "group_num": [
            "recZLdTx5CnZM4JSo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1127,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recle7ViYz3XnRgUN",
        "row": 7279,
        "state": "FLORIDA",
        "group": "716040917",
        "group_num": [
            "recvtOAW8eZqhKPar"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm4xYUt0wjRJNUJ",
        "row": 7301,
        "state": "FLORIDA",
        "group": "89513",
        "group_num": [
            "recN9FVccMcvgGsXq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "2023": 0,
        "2024": 0,
        "id": "recnVGpcZeWRRZiKZ",
        "row": 7291,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnCX6ZeL685IEOS",
        "row": 7314,
        "state": "FLORIDA",
        "group": "0092851000",
        "group_num": [
            "recIFbWgBARAPMXlD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 716,
        "2023": 0,
        "2024": 0,
        "id": "recOBTSmc60ibyYwM",
        "row": 7269,
        "state": "FLORIDA",
        "group": "YNS017",
        "group_num": [
            "recSI60q2K4jQ6ecU"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2282,
        "2023": 0,
        "2024": 0,
        "id": "recV4LeTBrOVKiZs1",
        "row": 7307,
        "state": "FLORIDA",
        "group": "001902740PF10",
        "group_num": [
            "recrqqiupvdm0DHQE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1100,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXB02LoGhzQ5Qhv",
        "row": 7289,
        "state": "FLORIDA",
        "group": "00917000003",
        "group_num": [
            "recm3dWnhy9py3ztU"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 28,
        "2021": 23,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXoQxnRBgjP8DiO",
        "row": 7280,
        "state": "FLORIDA",
        "group": "716040917",
        "group_num": [
            "recvtOAW8eZqhKPar"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 3160,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ3TWf1UVK1VLXN",
        "row": 7286,
        "state": "FLORIDA",
        "group": "0863470045",
        "group_num": [
            "rec0sw4u9UbAuAjkx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0YxPNQYmprGPCX",
        "row": 7278,
        "state": "FLORIDA",
        "group": "716040917",
        "group_num": [
            "recvtOAW8eZqhKPar"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3353,
        "2023": 0,
        "2024": 0,
        "id": "rec08tU9xZ3sXijYZ",
        "row": 7276,
        "state": "FLORIDA",
        "group": "W80533M011",
        "group_num": [
            "recki0fzlgsm2kpJs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1DDYt55xrGbBzn",
        "row": 7311,
        "state": "FLORIDA",
        "group": "0060287000",
        "group_num": [
            "recsjEPJolgUt3JTv"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 470,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1kvOwGAOfQ3Zke",
        "row": 7305,
        "state": "FLORIDA",
        "group": "300249",
        "group_num": [
            "recMJJPayBX0sE2Qn"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "rec4695gshj8vnsn8",
        "row": 7274,
        "state": "FLORIDA",
        "group": "14172597",
        "group_num": [
            "rectS52nfA9upPXoE"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6G45bq5vfcEV7J",
        "row": 7300,
        "state": "FLORIDA",
        "group": "89513",
        "group_num": [
            "recN9FVccMcvgGsXq"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec74PwdVQOPHc985",
        "row": 7290,
        "state": "FLORIDA",
        "group": "00935000000",
        "group_num": [
            "recs2lJlQqikf2G7E"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9ccZTUQgDk72Cf",
        "row": 7281,
        "state": "FLORIDA",
        "group": "004007865GAYR01",
        "group_num": [
            "reckX0lcA6F8j8ai5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 218,
        "2024": 0,
        "id": "recbO6AAEz40xqnly",
        "row": 7309,
        "state": "FLORIDA",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4958,
        "2023": 0,
        "2024": 0,
        "id": "recbPsH6ydJm82VvM",
        "row": 7275,
        "state": "FLORIDA",
        "group": "W80533M011",
        "group_num": [
            "recki0fzlgsm2kpJs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbfFLgxLzyXnFum",
        "row": 7296,
        "state": "FLORIDA",
        "group": "SI0016M001",
        "group_num": [
            "recL1rCoBgZJmO6hm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdTVqBpFM8P0SJi",
        "row": 7312,
        "state": "FLORIDA",
        "group": "0060287000",
        "group_num": [
            "recsjEPJolgUt3JTv"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recoJIkT5lObbingk",
        "row": 7270,
        "state": "FLORIDA",
        "group": "YNS017",
        "group_num": [
            "recSI60q2K4jQ6ecU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoeuqPv8OKOy7g1",
        "row": 7283,
        "state": "FLORIDA",
        "group": "2490500",
        "group_num": [
            "recko9QyoA9WRhgpj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 667,
        "2023": 0,
        "2024": 0,
        "id": "recrMuohMWbtvbiAH",
        "row": 7271,
        "state": "FLORIDA",
        "group": "300601026A",
        "group_num": [
            "recDRudbAAsTr43tm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 525,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recttslgEU4ONCO9N",
        "row": 7310,
        "state": "FLORIDA",
        "group": "119287",
        "group_num": [
            "recG5jo1zsf0Py9y9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recvK7MLq2WQIR0SR",
        "row": 7306,
        "state": "FLORIDA",
        "group": "001902740PF10",
        "group_num": [
            "recrqqiupvdm0DHQE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 167,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvj549rmWcgptm0",
        "row": 7302,
        "state": "FLORIDA",
        "group": "005800021DC1003",
        "group_num": [
            "recHRJd0r7u4TqvHE"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 240,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxuilCKEAFyA707",
        "row": 7294,
        "state": "FLORIDA",
        "group": "212056M3A1",
        "group_num": [
            "recfTVQbibAuoMoBS"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAtdug7L2DtGe2V",
        "row": 7303,
        "state": "FLORIDA",
        "group": "300249",
        "group_num": [
            "recMJJPayBX0sE2Qn"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBc4S6oj98b0LfX",
        "row": 7297,
        "state": "FLORIDA",
        "group": "SI0016M001",
        "group_num": [
            "recL1rCoBgZJmO6hm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 904,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCjYvvGkEG7sWC8",
        "row": 7277,
        "state": "FLORIDA",
        "group": "716040917",
        "group_num": [
            "recvtOAW8eZqhKPar"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recDsOnrLuuaR3sbd",
        "row": 7273,
        "state": "FLORIDA",
        "group": "14172597",
        "group_num": [
            "rectS52nfA9upPXoE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recDOskrd45uR7oBC",
        "row": 7284,
        "state": "FLORIDA",
        "group": "935000000",
        "group_num": [
            "rec7A3vvnWnZsnSWv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD3dVj1EwyQRiJc",
        "row": 7298,
        "state": "FLORIDA",
        "group": "SI0016M001",
        "group_num": [
            "recL1rCoBgZJmO6hm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 450,
        "2022": 4395,
        "2023": 0,
        "2024": 0,
        "id": "recFq75bw9LbqIytP",
        "row": 7304,
        "state": "FLORIDA",
        "group": "300249",
        "group_num": [
            "recMJJPayBX0sE2Qn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 3183,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGfKGUruW0pCSWL",
        "row": 7287,
        "state": "FLORIDA",
        "group": "0863470045",
        "group_num": [
            "rec0sw4u9UbAuAjkx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 659,
        "2023": 0,
        "2024": 0,
        "id": "recHtEFfi6kNktSwj",
        "row": 7272,
        "state": "FLORIDA",
        "group": "0009148A01",
        "group_num": [
            "recDvdLJaoredHSuM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1235,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHzdee7yjE64Pvl",
        "row": 7288,
        "state": "FLORIDA",
        "group": "00917000003",
        "group_num": [
            "recm3dWnhy9py3ztU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recJVXOZHF93s0PuY",
        "row": 7295,
        "state": "FLORIDA",
        "group": "001901078MD10247",
        "group_num": [
            "recxwJ7plmKuD4gYr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2435,
        "2023": 0,
        "2024": 0,
        "id": "recJqLzHxjXCAHNI9",
        "row": 7299,
        "state": "FLORIDA",
        "group": "002345986",
        "group_num": [
            "recD8C8ZZa0IHx0e5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recL7jCNPG0THtjgm",
        "row": 7282,
        "state": "FLORIDA",
        "group": "004007865GAYR01",
        "group_num": [
            "reckX0lcA6F8j8ai5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 140,
        "2023": 0,
        "2024": 0,
        "id": "recLeNdI9DFiECISr",
        "row": 7285,
        "state": "FLORIDA",
        "group": "935000000",
        "group_num": [
            "rec7A3vvnWnZsnSWv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 825,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLfNzSAY5IdHMFo",
        "row": 7315,
        "state": "FLORIDA",
        "group": "000PTH83401BP00",
        "group_num": [
            "recZLdTx5CnZM4JSo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recNBJLMnm8zJ4J4B",
        "row": 7308,
        "state": "FLORIDA",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recN79aWaFWQkMKmn",
        "row": 7292,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1757,
        "2024": 0,
        "id": "recfPxCapd7TCrE0e",
        "row": 7341,
        "state": "FLORIDA",
        "group": "270159F1JA",
        "group_num": [
            "rectH3OBEc8EGN8F5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 309,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclVAqhe5GvK3IuM",
        "row": 7357,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recl5Uz1CEKhJM2Yw",
        "row": 7349,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclaVSEWeIWgXLTE",
        "row": 7320,
        "state": "FLORIDA",
        "group": "715940401",
        "group_num": [
            "recg3wpTup61zHAhG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recmAb7LPG5VUoHRv",
        "row": 7334,
        "state": "FLORIDA",
        "group": "0EE555",
        "group_num": [
            "receTsENi7BxqaE3u"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 529,
        "2021": 319,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnIXzPF6ZddDfdH",
        "row": 7342,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn8x8CxA7dqq1th",
        "row": 7348,
        "state": "FLORIDA",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 151,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn9WrsjpLuNjxxK",
        "row": 7345,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQFkhiaXhzzRMzJ",
        "row": 7356,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSRj2uyq8CDspUY",
        "row": 7324,
        "state": "FLORIDA",
        "group": "715940402",
        "group_num": [
            "recIBQBuvAmlblr6Y"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recSm5gOkClTnCnGt",
        "row": 7331,
        "state": "FLORIDA",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recTt4uPWHRiZxISQ",
        "row": 7367,
        "state": "FLORIDA",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV67oYdvfTnew9S",
        "row": 7365,
        "state": "FLORIDA",
        "group": "86779200",
        "group_num": [
            "recG11vcOTFbGojie"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXROVlz9nDRbsR0",
        "row": 7355,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 525,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec00FegOv04ysPYE",
        "row": 7346,
        "state": "FLORIDA",
        "group": "13160",
        "group_num": [
            "rec2Ixi4NQV2ow6ya"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1993,
        "2023": 2078,
        "2024": 0,
        "id": "rec1AKrIFPIPGNVnG",
        "row": 7338,
        "state": "FLORIDA",
        "group": "174572BBA1",
        "group_num": [
            "rectZizwDUkziVWiL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2gysW3jADUWDBN",
        "row": 7360,
        "state": "FLORIDA",
        "group": "718560300",
        "group_num": [
            "recNy5H0ZatDlUNEb"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 388,
        "2023": 0,
        "2024": 0,
        "id": "rec2qNGfS2bKURkwq",
        "row": 7328,
        "state": "FLORIDA",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5eRPtHptjOxbZ5",
        "row": 7344,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recawqILDNoDm8Pen",
        "row": 7323,
        "state": "FLORIDA",
        "group": "715940402",
        "group_num": [
            "recIBQBuvAmlblr6Y"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recbp1AyG0i5wjOZm",
        "row": 7335,
        "state": "FLORIDA",
        "group": "0EU004",
        "group_num": [
            "recP0WBN0K5RxJDLM"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1865,
        "2023": 0,
        "2024": 0,
        "id": "recc29DI2sjb1PQzo",
        "row": 7327,
        "state": "FLORIDA",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 895,
        "2023": 0,
        "2024": 0,
        "id": "recoGL6Fv1L290qDO",
        "row": 7330,
        "state": "FLORIDA",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reco8Xz3Xq7YJqupG",
        "row": 7364,
        "state": "FLORIDA",
        "group": "86779200",
        "group_num": [
            "recG11vcOTFbGojie"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recp8pJdwwaiT0DNh",
        "row": 7322,
        "state": "FLORIDA",
        "group": "715940401",
        "group_num": [
            "recg3wpTup61zHAhG"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 732,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpmj9albN1luOc5",
        "row": 7332,
        "state": "FLORIDA",
        "group": "0ED982",
        "group_num": [
            "recw7r5Cnkl55iN1l"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2045,
        "2023": 0,
        "2024": 0,
        "id": "recpr6yluPjofjyow",
        "row": 7337,
        "state": "FLORIDA",
        "group": "174572BBA1",
        "group_num": [
            "rectZizwDUkziVWiL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 751,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqaqxSo8ITY3MTn",
        "row": 7333,
        "state": "FLORIDA",
        "group": "0ED982",
        "group_num": [
            "recw7r5Cnkl55iN1l"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 225,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recswqJs4EQX1jb8b",
        "row": 7353,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rectDsHJFFqin1O3L",
        "row": 7329,
        "state": "FLORIDA",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1812,
        "2023": 0,
        "2024": 0,
        "id": "rectKpITO5qzC9XeH",
        "row": 7326,
        "state": "FLORIDA",
        "group": "174295M2A6",
        "group_num": [
            "recDbJ0hRw5WHmIBM"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectRPm0dE5nVOqHN",
        "row": 7350,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 766,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuXFkJ4zHYmAiMI",
        "row": 7366,
        "state": "FLORIDA",
        "group": "RCCOAATHP",
        "group_num": [
            "recn7loQuMAJKtkoU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvIV3HHI2IJV8Ao",
        "row": 7358,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recvrrDo7XH2IPFIz",
        "row": 7361,
        "state": "FLORIDA",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recwwJwFUQR1JxduK",
        "row": 7347,
        "state": "FLORIDA",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 798,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwxYGT6dIL3wkWz",
        "row": 7351,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recweygECBtjIIMjQ",
        "row": 7363,
        "state": "FLORIDA",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxNFjXjUutDP2li",
        "row": 7319,
        "state": "FLORIDA",
        "group": "715940401",
        "group_num": [
            "recg3wpTup61zHAhG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 53,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyZiWXcBBgdFTUa",
        "row": 7318,
        "state": "FLORIDA",
        "group": "715940400",
        "group_num": [
            "recgcp5ZvEzmbLYuQ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4260,
        "2023": 0,
        "2024": 0,
        "id": "recBoKtRC2x6C2ZAu",
        "row": 7325,
        "state": "FLORIDA",
        "group": "174295M2A6",
        "group_num": [
            "recDbJ0hRw5WHmIBM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4149,
        "2023": 0,
        "2024": 0,
        "id": "recCVc7BBSytAnCsK",
        "row": 7339,
        "state": "FLORIDA",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 830,
        "2021": 0,
        "2022": 0,
        "2023": 294,
        "2024": 0,
        "id": "recCndYUG1E4C9yBX",
        "row": 7321,
        "state": "FLORIDA",
        "group": "715940401",
        "group_num": [
            "recg3wpTup61zHAhG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 142,
        "2021": 134,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recETyUVd66mbitLV",
        "row": 7354,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2488,
        "2023": 0,
        "2024": 0,
        "id": "recF5qYwLX1X9s1HA",
        "row": 7340,
        "state": "FLORIDA",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 320,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGZeaVlDhYhU85P",
        "row": 7352,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGCqGS80y15ss9Q",
        "row": 7359,
        "state": "FLORIDA",
        "group": "718560300",
        "group_num": [
            "recNy5H0ZatDlUNEb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 702,
        "2023": 0,
        "2024": 0,
        "id": "recH1DQrmIdwuxSnl",
        "row": 7336,
        "state": "FLORIDA",
        "group": "0EU004",
        "group_num": [
            "recP0WBN0K5RxJDLM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recLR9WMrETWn6F0x",
        "row": 7362,
        "state": "FLORIDA",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMFZVY4w9B5gfXu",
        "row": 7343,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfdCDBDxhqNT2uK",
        "row": 7370,
        "state": "FLORIDA",
        "group": "78180153",
        "group_num": [
            "recd46EtEIX1BUcmG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recgBvu35XNbc99r4",
        "row": 7403,
        "state": "FLORIDA",
        "group": "103556002",
        "group_num": [
            "recZbzewteJvLuxb9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 139,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg9tZDEXaRivItG",
        "row": 7398,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1244,
        "2024": 0,
        "id": "recj55MMlsOUJzMyp",
        "row": 7410,
        "state": "FLORIDA",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 290,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclTCtVCZSN2srfN",
        "row": 7401,
        "state": "FLORIDA",
        "group": "213026M3AG",
        "group_num": [
            "recLlYnxcCFhRECb9"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm77EqFbKwA1NOh",
        "row": 7402,
        "state": "FLORIDA",
        "group": "213026M3AG",
        "group_num": [
            "recLlYnxcCFhRECb9"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2757,
        "2024": 0,
        "id": "recn5bQVVK4Hebc16",
        "row": 7391,
        "state": "FLORIDA",
        "group": "277727M357",
        "group_num": [
            "recqmhnHvrCoXwjSg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1552,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPTtAa7d5dgoqBY",
        "row": 7405,
        "state": "FLORIDA",
        "group": "003330110",
        "group_num": [
            "recIe0oFmraAhD9eD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recQT0edTVrXwnJOp",
        "row": 7369,
        "state": "FLORIDA",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recRVHtoitpJF5ra8",
        "row": 7384,
        "state": "FLORIDA",
        "group": "10006612",
        "group_num": [
            "rec741Por2kjey7qD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 713,
        "2022": 0,
        "2023": 536,
        "2024": 0,
        "id": "recSdZoq7cBNNm08L",
        "row": 7377,
        "state": "FLORIDA",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 686,
        "2022": 0,
        "2023": 1392,
        "2024": 0,
        "id": "recTVcFV7i5a2ID5W",
        "row": 7376,
        "state": "FLORIDA",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recTAvpmYeLSLhLTl",
        "row": 7404,
        "state": "FLORIDA",
        "group": "103556002",
        "group_num": [
            "recZbzewteJvLuxb9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUb16Y7OYNTgFVl",
        "row": 7409,
        "state": "FLORIDA",
        "group": "82333",
        "group_num": [
            "recayaMyEfJpiAiZx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVcICA8TIaksO13",
        "row": 7389,
        "state": "FLORIDA",
        "group": "02507900",
        "group_num": [
            "recxwRD0t4yHWfFWb"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 467,
        "2024": 0,
        "id": "recWI20EyPZS140DM",
        "row": 7375,
        "state": "FLORIDA",
        "group": "PH3533",
        "group_num": [
            "rec0WfJq6KTD74mE4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "recWQi9XTov4Sj79O",
        "row": 7382,
        "state": "FLORIDA",
        "group": "40521B",
        "group_num": [
            "recqryEawi3TY5TmV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1408,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0BZJH6sROYPyam",
        "row": 7415,
        "state": "FLORIDA",
        "group": "10486315",
        "group_num": [
            "recQawftAtTrckpni"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 182,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0rHBV40inncIwL",
        "row": 7383,
        "state": "FLORIDA",
        "group": "10006596",
        "group_num": [
            "recghnD7IpVJGPeQC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 58,
        "2021": 67,
        "2022": 39,
        "2023": 0,
        "2024": 0,
        "id": "rec1an6CxqbgPEfOK",
        "row": 7400,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "rec4LhYzyH8vgAaBp",
        "row": 7379,
        "state": "FLORIDA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5151,
        "2023": 0,
        "2024": 0,
        "id": "rec4pA9Qu0y9u1R6T",
        "row": 7390,
        "state": "FLORIDA",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1746,
        "2023": 0,
        "2024": 0,
        "id": "rec9CIxMOXFgJMzef",
        "row": 7385,
        "state": "FLORIDA",
        "group": "10006612",
        "group_num": [
            "rec741Por2kjey7qD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2331,
        "2023": 0,
        "2024": 0,
        "id": "rec9LyhoFAfkICwvt",
        "row": 7378,
        "state": "FLORIDA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 882,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reca1VUssfMZFtJAY",
        "row": 7381,
        "state": "FLORIDA",
        "group": "10045159",
        "group_num": [
            "recx6xCmw2wxtlcY0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 240,
        "2024": 0,
        "id": "recaCb5TkIDXjf6R4",
        "row": 7394,
        "state": "FLORIDA",
        "group": "4022154",
        "group_num": [
            "recAfJRy5kcwi87iv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recbdCEJc8lUoicjX",
        "row": 7373,
        "state": "FLORIDA",
        "group": "005831",
        "group_num": [
            "rec2LDV8m3AE8c3Ui"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccCvLterhb606Jo",
        "row": 7371,
        "state": "FLORIDA",
        "group": "78180153",
        "group_num": [
            "recd46EtEIX1BUcmG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 714,
        "2024": 0,
        "id": "recd60V9eyOZBHtsh",
        "row": 7387,
        "state": "FLORIDA",
        "group": "10621286",
        "group_num": [
            "rec6OoA0p3PFeXdO2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recokH1WkRBeE2ug8",
        "row": 7417,
        "state": "FLORIDA",
        "group": "01684001",
        "group_num": [
            "rec768xEes7Gj61xz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recpqOqkPOOf0rIbz",
        "row": 7413,
        "state": "FLORIDA",
        "group": "235718",
        "group_num": [
            "reckDEWhUsi53z0VB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recqw8RxGgmldNJ3K",
        "row": 7393,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrtrE7Ck9SuEuwu",
        "row": 7372,
        "state": "FLORIDA",
        "group": "78180153",
        "group_num": [
            "recd46EtEIX1BUcmG"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 162,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrzLHodmMbl1sfT",
        "row": 7386,
        "state": "FLORIDA",
        "group": "720830011M",
        "group_num": [
            "recekPhyWTe43ERZu"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1838,
        "2024": 0,
        "id": "recr33869rxjHpAbV",
        "row": 7374,
        "state": "FLORIDA",
        "group": "PH3533",
        "group_num": [
            "rec0WfJq6KTD74mE4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 135,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuxeeB0kFr8XvBE",
        "row": 7416,
        "state": "FLORIDA",
        "group": "10486315",
        "group_num": [
            "recQawftAtTrckpni"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recvH44XRqqxSIv51",
        "row": 7368,
        "state": "FLORIDA",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recw6QIiV8hyI1Qtu",
        "row": 7414,
        "state": "FLORIDA",
        "group": "235718",
        "group_num": [
            "reckDEWhUsi53z0VB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 551,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxtf1rzju6txFUc",
        "row": 7407,
        "state": "FLORIDA",
        "group": "003330110",
        "group_num": [
            "recIe0oFmraAhD9eD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx6IEGUsfwghvaC",
        "row": 7408,
        "state": "FLORIDA",
        "group": "000TSJ83406YP00",
        "group_num": [
            "recARl37NDUsJU9lu"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1544,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczFJCRboLEOF8aI",
        "row": 7406,
        "state": "FLORIDA",
        "group": "003330110",
        "group_num": [
            "recIe0oFmraAhD9eD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD0ZqQxDbsymtaN",
        "row": 7411,
        "state": "FLORIDA",
        "group": "00762120705",
        "group_num": [
            "recdmOelxV9KA8iBw"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 218,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDCGiImJI6KxuS3",
        "row": 7399,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1383,
        "2022": 1309,
        "2023": 0,
        "2024": 0,
        "id": "recFpYSnJzcOCgGQb",
        "row": 7397,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 636,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGw06VUFeyOJ9xS",
        "row": 7380,
        "state": "FLORIDA",
        "group": "10045159",
        "group_num": [
            "recx6xCmw2wxtlcY0"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recJIJX7G0llAYSAO",
        "row": 7388,
        "state": "FLORIDA",
        "group": "10621286",
        "group_num": [
            "rec6OoA0p3PFeXdO2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 240,
        "2024": 0,
        "id": "recL7AYT8nyw9xwiW",
        "row": 7395,
        "state": "FLORIDA",
        "group": "4022154",
        "group_num": [
            "recAfJRy5kcwi87iv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 512,
        "2023": 0,
        "2024": 0,
        "id": "recLjeTW08DPWTR2z",
        "row": 7396,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "recNMd64xVL0FsTnB",
        "row": 7392,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 70,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNpwH18lOGGo9zA",
        "row": 7412,
        "state": "FLORIDA",
        "group": "00762120705",
        "group_num": [
            "recdmOelxV9KA8iBw"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 197,
        "2021": 176,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recemmpauEzdAPNuJ",
        "row": 7445,
        "state": "FLORIDA",
        "group": "000655",
        "group_num": [
            "rec76fgc6QIaIO5II"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 375,
        "2023": 0,
        "2024": 0,
        "id": "recfBfizjY9B0qASh",
        "row": 7431,
        "state": "FLORIDA",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 253,
        "2022": 253,
        "2023": 0,
        "2024": 0,
        "id": "recf31tVlqLfWAom7",
        "row": 7435,
        "state": "FLORIDA",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1133,
        "2023": 0,
        "2024": 0,
        "id": "recgvqJnEEsnfiUKA",
        "row": 7438,
        "state": "FLORIDA",
        "group": "002367544",
        "group_num": [
            "receCXgvFeMbcFeq5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgeQkgnamYSB8JZ",
        "row": 7440,
        "state": "FLORIDA",
        "group": "D08691",
        "group_num": [
            "recMfBXc4cViqfMZj"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckc4oR08dWIQK5Y",
        "row": 7454,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 330,
        "2023": 0,
        "2024": 0,
        "id": "recn0XEive7x5L12i",
        "row": 7457,
        "state": "FLORIDA",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2167,
        "2024": 0,
        "id": "recndTsBcareP5cOk",
        "row": 7433,
        "state": "FLORIDA",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPDgpPr3HECHO1u",
        "row": 7467,
        "state": "FLORIDA",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recRTQNehSzhUw2n3",
        "row": 7447,
        "state": "FLORIDA",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 815,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recXJ37ULTSn6Gs7k",
        "row": 7456,
        "state": "FLORIDA",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYC9BY2pxbZHxcP",
        "row": 7465,
        "state": "FLORIDA",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recY7WyOjFqLPVh6V",
        "row": 7420,
        "state": "FLORIDA",
        "group": "00760393731",
        "group_num": [
            "recd7VTqut291xcG7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 284,
        "2021": 333,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "rec0vY6p9MuRcELCr",
        "row": 7453,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2290,
        "2023": 0,
        "2024": 0,
        "id": "rec2i2vJxjcnyBtmf",
        "row": 7429,
        "state": "FLORIDA",
        "group": "210029M2AA",
        "group_num": [
            "rec0yKlxkxY3Yf8Qn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 783,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec37KZW5hT84cXCi",
        "row": 7451,
        "state": "FLORIDA",
        "group": "99999",
        "group_num": [
            "recPJHustsY7agZqD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "rec3l6a7LvV3nww7E",
        "row": 7448,
        "state": "FLORIDA",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1753,
        "2024": 0,
        "id": "rec4dYWDojnY469zk",
        "row": 7434,
        "state": "FLORIDA",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 149,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec584qLTHk8u6Hdm",
        "row": 7439,
        "state": "FLORIDA",
        "group": "D08691",
        "group_num": [
            "recMfBXc4cViqfMZj"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 274,
        "2022": 398,
        "2023": 0,
        "2024": 0,
        "id": "rec7PpND0l171mEB1",
        "row": 7436,
        "state": "FLORIDA",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2308,
        "2023": 0,
        "2024": 0,
        "id": "recaHAdIWLaDzieoB",
        "row": 7428,
        "state": "FLORIDA",
        "group": "210029M2AA",
        "group_num": [
            "rec0yKlxkxY3Yf8Qn"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 551,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbgZ8b8Tu8hvcms",
        "row": 7424,
        "state": "FLORIDA",
        "group": "003329200T1AG02",
        "group_num": [
            "recF3cOcWDXMjKp59"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 793,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdJzANZbHsAcu0o",
        "row": 7443,
        "state": "FLORIDA",
        "group": "000655",
        "group_num": [
            "rec76fgc6QIaIO5II"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 539,
        "2023": 0,
        "2024": 0,
        "id": "recr7zvjakvlXuK5Q",
        "row": 7422,
        "state": "FLORIDA",
        "group": "213065M3AA",
        "group_num": [
            "recA1vFmcbTZipOo1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "2023": 0,
        "2024": 0,
        "id": "recsaDwwpH41TKuAM",
        "row": 7432,
        "state": "FLORIDA",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1783,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectxkkf3JHCoHbg8",
        "row": 7423,
        "state": "FLORIDA",
        "group": "003329200T1AG02",
        "group_num": [
            "recF3cOcWDXMjKp59"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1738,
        "2023": 1673,
        "2024": 0,
        "id": "recuuXzGorWKOIgST",
        "row": 7421,
        "state": "FLORIDA",
        "group": "213065M3AA",
        "group_num": [
            "recA1vFmcbTZipOo1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recvFAMTj0hr3199Y",
        "row": 7462,
        "state": "FLORIDA",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvPUQbCVeTmZZTr",
        "row": 7455,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "recvoxaeY635MBuXg",
        "row": 7458,
        "state": "FLORIDA",
        "group": "99999U5W",
        "group_num": [
            "recb3jY1f0ck15XAZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "2023": 0,
        "2024": 0,
        "id": "recwBjiVtpfaKR9mQ",
        "row": 7418,
        "state": "FLORIDA",
        "group": "10053820",
        "group_num": [
            "rec0i4l63BNSbFHY1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwIYkNS5rRLmsHU",
        "row": 7450,
        "state": "FLORIDA",
        "group": "191689",
        "group_num": [
            "rech7Eo1lv4ubhCU7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwMGf34HiltXA0l",
        "row": 7460,
        "state": "FLORIDA",
        "group": "99999UBU",
        "group_num": [
            "recmN33L2fBTU1OBT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recyS7JIbtLOWswOy",
        "row": 7441,
        "state": "FLORIDA",
        "group": "708540402",
        "group_num": [
            "recU4PR6KC6QRVVxG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1142,
        "2023": 0,
        "2024": 0,
        "id": "recyRIHkXunNDALy2",
        "row": 7437,
        "state": "FLORIDA",
        "group": "002367544",
        "group_num": [
            "receCXgvFeMbcFeq5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 876,
        "2023": 0,
        "2024": 0,
        "id": "recybeykxPjfoVr8e",
        "row": 7419,
        "state": "FLORIDA",
        "group": "10053820",
        "group_num": [
            "rec0i4l63BNSbFHY1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz5kTdAyzFTQEgx",
        "row": 7461,
        "state": "FLORIDA",
        "group": "99999UBU",
        "group_num": [
            "recmN33L2fBTU1OBT"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recAabUJObHWHgUsS",
        "row": 7459,
        "state": "FLORIDA",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recBedJ6eKul8NMrD",
        "row": 7446,
        "state": "FLORIDA",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1633,
        "2023": 0,
        "2024": 0,
        "id": "recCYuKsiUsKrfkN9",
        "row": 7430,
        "state": "FLORIDA",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 812,
        "2021": 884,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDkdADpBQa5P3gB",
        "row": 7444,
        "state": "FLORIDA",
        "group": "000655",
        "group_num": [
            "rec76fgc6QIaIO5II"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recFajT99Z7V6nB50",
        "row": 7442,
        "state": "FLORIDA",
        "group": "708540402",
        "group_num": [
            "recU4PR6KC6QRVVxG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 254,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFjfBS7rTOYLbZ3",
        "row": 7463,
        "state": "FLORIDA",
        "group": "99999UCZ",
        "group_num": [
            "rec0XMqG5wLKrWIis"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 887,
        "2023": 0,
        "2024": 0,
        "id": "recHwvqsmASRakZss",
        "row": 7466,
        "state": "FLORIDA",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHjlrDE5Hk5Ynp2",
        "row": 7464,
        "state": "FLORIDA",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1400,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recI0l8ZZ7jV1b3kz",
        "row": 7426,
        "state": "FLORIDA",
        "group": "002656",
        "group_num": [
            "rectUnFfawbrwNUY5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1150,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIyiiu2uvjFMCQD",
        "row": 7427,
        "state": "FLORIDA",
        "group": "002656",
        "group_num": [
            "rectUnFfawbrwNUY5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIzYBm6DCO9WQHs",
        "row": 7452,
        "state": "FLORIDA",
        "group": "99999",
        "group_num": [
            "recPJHustsY7agZqD"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 68,
        "2023": 0,
        "2024": 0,
        "id": "recMqKwTPsO3rG0lc",
        "row": 7425,
        "state": "FLORIDA",
        "group": "107008",
        "group_num": [
            "rec0xw3ReK93i1YCU"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 670,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNKPXKoRxOz3o00",
        "row": 7449,
        "state": "FLORIDA",
        "group": "191689",
        "group_num": [
            "rech7Eo1lv4ubhCU7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 280,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recePEtjLZYvFvwGX",
        "row": 7505,
        "state": "FLORIDA",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 783,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rece9Zm8JHV4UrYIX",
        "row": 7506,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recf6gmI0fR73kEAW",
        "row": 7488,
        "state": "FLORIDA",
        "group": "99999",
        "group_num": [
            "recPJHustsY7agZqD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recgu2GAFAYaWYmUI",
        "row": 7502,
        "state": "FLORIDA",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recg9tyhesl0rxHAJ",
        "row": 7469,
        "state": "FLORIDA",
        "group": "99999UU3",
        "group_num": [
            "recdhsaDQH4NNda4F"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 20,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechF5yfE3qSTKtB7",
        "row": 7509,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechNhDnc8vKC1skb",
        "row": 7476,
        "state": "FLORIDA",
        "group": "99999Z5T",
        "group_num": [
            "recaC8GuHzXfEv7WN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 1503,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recicXTV5ZrT6Osga",
        "row": 7485,
        "state": "FLORIDA",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recin0gxjCWEmmwoU",
        "row": 7475,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 760,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recj1dk5GibmaNAmR",
        "row": 7471,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjFypsWQF6zbufG",
        "row": 7516,
        "state": "FLORIDA",
        "group": "99999Z6J",
        "group_num": [
            "rec1bu6tlEbnPPKsQ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recjPliiG7m4P7fJO",
        "row": 7478,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckK2XDRD3Rxsbpf",
        "row": 7517,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck7XcAZc6pb8LXj",
        "row": 7491,
        "state": "FLORIDA",
        "group": "99999",
        "group_num": [
            "recPJHustsY7agZqD"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 327,
        "2022": 390,
        "2023": 0,
        "2024": 0,
        "id": "recmo1hjDaM5uDdMM",
        "row": 7507,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPF5N1i1q11Cq1C",
        "row": 7510,
        "state": "FLORIDA",
        "group": "99999Z5F",
        "group_num": [
            "recYXWpZ7swwNhiDl"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recVbfRDjrarVeZOC",
        "row": 7473,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recXPCp90K4JHkCUB",
        "row": 7501,
        "state": "FLORIDA",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 882,
        "2023": 952,
        "2024": 0,
        "id": "rec1ddBVHhQaZ1Q5g",
        "row": 7513,
        "state": "FLORIDA",
        "group": "99999Z6J",
        "group_num": [
            "rec1bu6tlEbnPPKsQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec40eh8UmOVRRyke",
        "row": 7482,
        "state": "FLORIDA",
        "group": "99999ZF2",
        "group_num": [
            "recdL22CNhBOTlwFY"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4IiVws8Ch9DGCf",
        "row": 7483,
        "state": "FLORIDA",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6lHjgS1CNSi5x1",
        "row": 7500,
        "state": "FLORIDA",
        "group": "99999U6H",
        "group_num": [
            "recGzyD7zsfeBZWX8"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7ECqbZka5uYSKc",
        "row": 7489,
        "state": "FLORIDA",
        "group": "99999",
        "group_num": [
            "recPJHustsY7agZqD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 784,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7joXKF3KYHP3VI",
        "row": 7474,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "rec8kJVoQsRcGmAM1",
        "row": 7493,
        "state": "FLORIDA",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 96,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccWdm8H7qasUdZR",
        "row": 7511,
        "state": "FLORIDA",
        "group": "99999Z5F",
        "group_num": [
            "recYXWpZ7swwNhiDl"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 163,
        "2021": 161,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccfk310ocQyjydW",
        "row": 7508,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 791,
        "2022": 864,
        "2023": 0,
        "2024": 0,
        "id": "recoY4S3F4Tu80AYU",
        "row": 7492,
        "state": "FLORIDA",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recoB99ELFRoXkB1C",
        "row": 7477,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoEfu7BUsTu1hLX",
        "row": 7490,
        "state": "FLORIDA",
        "group": "99999",
        "group_num": [
            "recPJHustsY7agZqD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 160,
        "2022": 167,
        "2023": 0,
        "2024": 0,
        "id": "recrgDkkMHDOsLsSG",
        "row": 7472,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect1gIvDgEh6QgmZ",
        "row": 7494,
        "state": "FLORIDA",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectsHkT5Ul7KShdV",
        "row": 7484,
        "state": "FLORIDA",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recw4aXf0PvnfdNf6",
        "row": 7468,
        "state": "FLORIDA",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwi431ntsO8RBLz",
        "row": 7514,
        "state": "FLORIDA",
        "group": "99999Z6J",
        "group_num": [
            "rec1bu6tlEbnPPKsQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 160,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxQZa8EttT1WUOf",
        "row": 7515,
        "state": "FLORIDA",
        "group": "99999Z6J",
        "group_num": [
            "rec1bu6tlEbnPPKsQ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "2023": 0,
        "2024": 0,
        "id": "reczspEHRhcWdiU5q",
        "row": 7470,
        "state": "FLORIDA",
        "group": "99999UU3",
        "group_num": [
            "recdhsaDQH4NNda4F"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBezBwLhDvsj02j",
        "row": 7481,
        "state": "FLORIDA",
        "group": "99999ZF2",
        "group_num": [
            "recdL22CNhBOTlwFY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 160,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCSH3bpNLrB1dGC",
        "row": 7499,
        "state": "FLORIDA",
        "group": "99999U6H",
        "group_num": [
            "recGzyD7zsfeBZWX8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "2023": 0,
        "2024": 0,
        "id": "recCBdT6PW11RWNAs",
        "row": 7480,
        "state": "FLORIDA",
        "group": "99999ZF2",
        "group_num": [
            "recdL22CNhBOTlwFY"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 678,
        "2021": 0,
        "2022": 885,
        "2023": 0,
        "2024": 0,
        "id": "recDEARhf3iDGA1wb",
        "row": 7497,
        "state": "FLORIDA",
        "group": "99999U6H",
        "group_num": [
            "recGzyD7zsfeBZWX8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recD6g6O0yDxCbK1d",
        "row": 7487,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGDi5dSSZgfrOX6",
        "row": 7512,
        "state": "FLORIDA",
        "group": "99999Z6E",
        "group_num": [
            "recYQxaxSvleKxQ16"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 741,
        "2022": 867,
        "2023": 0,
        "2024": 0,
        "id": "recIgLzGsP3FMXRhE",
        "row": 7503,
        "state": "FLORIDA",
        "group": "99999UBU",
        "group_num": [
            "recmN33L2fBTU1OBT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 245,
        "2021": 319,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKsV1x4YrZuwShY",
        "row": 7498,
        "state": "FLORIDA",
        "group": "99999U6H",
        "group_num": [
            "recGzyD7zsfeBZWX8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK2havI0qAEr2ST",
        "row": 7495,
        "state": "FLORIDA",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKaz9bniTSiLU0S",
        "row": 7479,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLLgbzcKRtVhQyg",
        "row": 7504,
        "state": "FLORIDA",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLO8UhD1IoLbwZK",
        "row": 7486,
        "state": "FLORIDA",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "recMDzL7iYoRN1jo6",
        "row": 7496,
        "state": "FLORIDA",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 972,
        "2024": 0,
        "id": "recfZXg1vqnpTq4dJ",
        "row": 7537,
        "state": "FLORIDA",
        "group": "10652409",
        "group_num": [
            "rec9Qbe7qcA2lwg7z"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3036,
        "2023": 0,
        "2024": 0,
        "id": "recgi0rX8QTp1JSdb",
        "row": 7546,
        "state": "FLORIDA",
        "group": "174245MGVA",
        "group_num": [
            "rechrSZuXtJff7kxG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 198,
        "2022": 217,
        "2023": 0,
        "2024": 0,
        "id": "rech9l0BdM4D8yW3D",
        "row": 7540,
        "state": "FLORIDA",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechpM9J9iEdTggay",
        "row": 7560,
        "state": "FLORIDA",
        "group": "038941",
        "group_num": [
            "recfH7Gmh9sgKUt96"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 19,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclrENKlNIa8O8SW",
        "row": 7523,
        "state": "FLORIDA",
        "group": "99999ZBU",
        "group_num": [
            "rec9FnRaXs3aqrQv5"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 698,
        "2023": 0,
        "2024": 0,
        "id": "recmc8gIURChvm8gQ",
        "row": 7552,
        "state": "FLORIDA",
        "group": "277242M002",
        "group_num": [
            "recBgoZiNUwJw2UFT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 559,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnBV3DuOq61IVXQ",
        "row": 7553,
        "state": "FLORIDA",
        "group": "117623",
        "group_num": [
            "rec8cGULTIafwQAFM"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 350,
        "2022": 726,
        "2023": 0,
        "2024": 0,
        "id": "recn6XUNKNl6CO44W",
        "row": 7565,
        "state": "FLORIDA",
        "group": "HS00100009",
        "group_num": [
            "recyk8AqSDENCeNVq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "2023": 0,
        "2024": 0,
        "id": "recnpWEa384JshLyX",
        "row": 7541,
        "state": "FLORIDA",
        "group": "099967",
        "group_num": [
            "recuXM1Jl0VwVL0Nw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 329,
        "2022": 326,
        "2023": 0,
        "2024": 0,
        "id": "recOeQV93IfvXyH1m",
        "row": 7566,
        "state": "FLORIDA",
        "group": "HS00100009",
        "group_num": [
            "recyk8AqSDENCeNVq"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 336,
        "2023": 0,
        "2024": 0,
        "id": "recOn0DB45T0Aiu0H",
        "row": 7551,
        "state": "FLORIDA",
        "group": "277242M002",
        "group_num": [
            "recBgoZiNUwJw2UFT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "2024": 0,
        "id": "recPuumIH0TRRNwpa",
        "row": 7533,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 159,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPQ8aOCKYrkrZ8c",
        "row": 7522,
        "state": "FLORIDA",
        "group": "99999ZBU",
        "group_num": [
            "rec9FnRaXs3aqrQv5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1738,
        "2023": 0,
        "2024": 0,
        "id": "recP9xSXXw5Vck7Ht",
        "row": 7557,
        "state": "FLORIDA",
        "group": "716029942",
        "group_num": [
            "recxKC5wOeROZbxNe"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recQUB2yf4bIfucc8",
        "row": 7545,
        "state": "FLORIDA",
        "group": "174245MGVA",
        "group_num": [
            "rechrSZuXtJff7kxG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "2023": 0,
        "2024": 0,
        "id": "recRiO2cKfOF4WY4Z",
        "row": 7518,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSv86rCIO90fhQz",
        "row": 7563,
        "state": "FLORIDA",
        "group": "0PFL59",
        "group_num": [
            "recXDrsejSzBUESqb"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSIbaNQVO98eGOT",
        "row": 7536,
        "state": "FLORIDA",
        "group": "00017061",
        "group_num": [
            "recFurVHk4PtAYihF"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYrR2uvX89HyCZH",
        "row": 7559,
        "state": "FLORIDA",
        "group": "716029952",
        "group_num": [
            "recWnuOgD1PAXuS0m"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 781,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec11tYMzTyNcnhwo",
        "row": 7567,
        "state": "FLORIDA",
        "group": "0210000011",
        "group_num": [
            "rechKq8dmDuDhSE7T"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2552,
        "2023": 0,
        "2024": 0,
        "id": "rec3Y7LMlstGqLvxZ",
        "row": 7526,
        "state": "FLORIDA",
        "group": "VA2094M011",
        "group_num": [
            "recolXCz1Li1ju21S"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1689,
        "2023": 0,
        "2024": 0,
        "id": "rec6fhqpaUGfJbNje",
        "row": 7555,
        "state": "FLORIDA",
        "group": "1871VL",
        "group_num": [
            "recqNykDeJWy8f221"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 815,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6ory1O6J25HCjF",
        "row": 7524,
        "state": "FLORIDA",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec77R1yetjQSNyzy",
        "row": 7556,
        "state": "FLORIDA",
        "group": "BCBSOFSC",
        "group_num": [
            "recC103kjazGJsJor"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1586,
        "2023": 0,
        "2024": 0,
        "id": "rec9zw6lhIUYt4ugt",
        "row": 7532,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1732,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9nQsUoth0rQqKN",
        "row": 7550,
        "state": "FLORIDA",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 0,
        "2024": 0,
        "id": "recaPse4BFX8OaMNV",
        "row": 7544,
        "state": "FLORIDA",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "2023": 0,
        "2024": 0,
        "id": "recd9dOqTbWkkRe47",
        "row": 7534,
        "state": "FLORIDA",
        "group": "00006021",
        "group_num": [
            "recjKXEX6C7t6m2sB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1871,
        "2023": 0,
        "2024": 0,
        "id": "recosh0W3WKmF4ibX",
        "row": 7543,
        "state": "FLORIDA",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 28,
        "2021": 34,
        "2022": 40,
        "2023": 0,
        "2024": 0,
        "id": "recoPR5afTzmnIxo3",
        "row": 7554,
        "state": "FLORIDA",
        "group": "117623",
        "group_num": [
            "rec8cGULTIafwQAFM"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 603,
        "2023": 0,
        "2024": 0,
        "id": "recof0TGsx2VVw064",
        "row": 7542,
        "state": "FLORIDA",
        "group": "099967",
        "group_num": [
            "recuXM1Jl0VwVL0Nw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 587,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsYEHkDSNkeJCgq",
        "row": 7564,
        "state": "FLORIDA",
        "group": "HS00100009",
        "group_num": [
            "recyk8AqSDENCeNVq"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 199,
        "2022": 326,
        "2023": 0,
        "2024": 0,
        "id": "recsJ1xIubnHxOBBc",
        "row": 7525,
        "state": "FLORIDA",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 215,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsgbTR5LVxpllM8",
        "row": 7530,
        "state": "FLORIDA",
        "group": "1872QU",
        "group_num": [
            "reco4gbyfIl0IYjET"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 703,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectInpOC8O2NeQb3",
        "row": 7520,
        "state": "FLORIDA",
        "group": "99999ZBU",
        "group_num": [
            "rec9FnRaXs3aqrQv5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "2023": 0,
        "2024": 0,
        "id": "rectek1ehKagfDkrp",
        "row": 7561,
        "state": "FLORIDA",
        "group": "038941",
        "group_num": [
            "recfH7Gmh9sgKUt96"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1687,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuaBec7ILouSdtM",
        "row": 7549,
        "state": "FLORIDA",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recujcsBhPf9m8cqc",
        "row": 7527,
        "state": "FLORIDA",
        "group": "VA3051M001",
        "group_num": [
            "recTI3khmT5KXcIAz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 133,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvfzca525SvvBH9",
        "row": 7519,
        "state": "FLORIDA",
        "group": "99999ZBU",
        "group_num": [
            "rec9FnRaXs3aqrQv5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 670,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwHOKIswqjnPry1",
        "row": 7538,
        "state": "FLORIDA",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 815,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczcTUHgbY458RSz",
        "row": 7562,
        "state": "FLORIDA",
        "group": "0PFL59",
        "group_num": [
            "recXDrsejSzBUESqb"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 253,
        "2023": 0,
        "2024": 0,
        "id": "recAu27uTF1eoVg3m",
        "row": 7539,
        "state": "FLORIDA",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recAGxFoyOf7oFcDs",
        "row": 7548,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1981,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBIvOMTe0E7m0R8",
        "row": 7521,
        "state": "FLORIDA",
        "group": "99999ZBU",
        "group_num": [
            "rec9FnRaXs3aqrQv5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC6IeFMg3LqJaT6",
        "row": 7529,
        "state": "FLORIDA",
        "group": "280509C600",
        "group_num": [
            "recinFC1VfI89nuGB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recECW2vRvuSXglMk",
        "row": 7528,
        "state": "FLORIDA",
        "group": "VA3051M001",
        "group_num": [
            "recTI3khmT5KXcIAz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 2447,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recE4kDQuxscPjOuW",
        "row": 7558,
        "state": "FLORIDA",
        "group": "716029952",
        "group_num": [
            "recWnuOgD1PAXuS0m"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 39,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFyrlDeeEXdupXx",
        "row": 7531,
        "state": "FLORIDA",
        "group": "1872QU",
        "group_num": [
            "reco4gbyfIl0IYjET"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recGDJqE4cGmWuN0u",
        "row": 7547,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recN4wwB1NxW67Nvn",
        "row": 7535,
        "state": "FLORIDA",
        "group": "00006021",
        "group_num": [
            "recjKXEX6C7t6m2sB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receb39yB4zjAXqLR",
        "row": 7581,
        "state": "FLORIDA",
        "group": "W0065109",
        "group_num": [
            "recFdnYG2E2jvXJaa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recf4Iq5y1dO67aOo",
        "row": 7608,
        "state": "FLORIDA",
        "group": "32047003",
        "group_num": [
            "recQxc9fXsHrx58PY"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 19,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgxgyGa8q8u0lON",
        "row": 7580,
        "state": "FLORIDA",
        "group": "W0009299",
        "group_num": [
            "recwFALtoWDF4uVjz"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 153,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgITg1kxtwfaIEp",
        "row": 7599,
        "state": "FLORIDA",
        "group": "16022006",
        "group_num": [
            "recqoLQaAtZW9FIdn"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechWWIbuWZ1jVmPY",
        "row": 7593,
        "state": "FLORIDA",
        "group": "16510R04",
        "group_num": [
            "reclBYSsDtthnV3mm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 2125,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rech90uIVdirV45xL",
        "row": 7574,
        "state": "FLORIDA",
        "group": "236747",
        "group_num": [
            "recym0v2fY3SuEfKv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 916,
        "2024": 0,
        "id": "reciFKhiR7JuWkWRS",
        "row": 7588,
        "state": "FLORIDA",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckYe2BzenYD6Mn4",
        "row": 7585,
        "state": "FLORIDA",
        "group": "W0084823",
        "group_num": [
            "rec8VT9L7rxLvSc8X"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reck7F3t9jyPkRNee",
        "row": 7583,
        "state": "FLORIDA",
        "group": "W0084823",
        "group_num": [
            "rec8VT9L7rxLvSc8X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1442,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckl9rLAOcGK83Jr",
        "row": 7576,
        "state": "FLORIDA",
        "group": "281311M001",
        "group_num": [
            "rec0WHsX49enO1gyr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclqhharBbFvoYlQ",
        "row": 7575,
        "state": "FLORIDA",
        "group": "236747",
        "group_num": [
            "recym0v2fY3SuEfKv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmFz4gbP5kOO0ea",
        "row": 7609,
        "state": "FLORIDA",
        "group": "33498001",
        "group_num": [
            "recSi25sRNOPoIZ9i"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSHEtUhk0wrQ6YJ",
        "row": 7610,
        "state": "FLORIDA",
        "group": "33498001",
        "group_num": [
            "recSi25sRNOPoIZ9i"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recS2QlR6u6cocoMm",
        "row": 7589,
        "state": "FLORIDA",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 159,
        "2022": 163,
        "2023": 0,
        "2024": 0,
        "id": "recTyXPG4dPARcxko",
        "row": 7570,
        "state": "FLORIDA",
        "group": "ES00100023",
        "group_num": [
            "recBf9VzKXOUPooBA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recUR9hyIKuFtdkuH",
        "row": 7591,
        "state": "FLORIDA",
        "group": "L00296M002",
        "group_num": [
            "rec7tYKxFnL4Ajc8K"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 162,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZ2VeyiimH9biGW",
        "row": 7582,
        "state": "FLORIDA",
        "group": "W0065109",
        "group_num": [
            "recFdnYG2E2jvXJaa"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec0ERNeK4Xl1ldW0",
        "row": 7592,
        "state": "FLORIDA",
        "group": "L00296M002",
        "group_num": [
            "rec7tYKxFnL4Ajc8K"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "rec17Vc66m11dPvH2",
        "row": 7614,
        "state": "FLORIDA",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2o1HFXhKJhTug5",
        "row": 7617,
        "state": "FLORIDA",
        "group": "63765002",
        "group_num": [
            "recZlRPIrJlj8sT9M"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "rec4pssLMJqG7Q6sZ",
        "row": 7571,
        "state": "FLORIDA",
        "group": "788800",
        "group_num": [
            "recuCT5ps3qQV3f4y"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5pSNDGQAskfG7i",
        "row": 7600,
        "state": "FLORIDA",
        "group": "16022006",
        "group_num": [
            "recqoLQaAtZW9FIdn"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "rec825MEIK7iQUp5v",
        "row": 7615,
        "state": "FLORIDA",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 743,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recavZKBRdqfYfH5x",
        "row": 7569,
        "state": "FLORIDA",
        "group": "ES00100016",
        "group_num": [
            "recEAuawrKdBYMbRM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdV0NiSM1azsTHk",
        "row": 7586,
        "state": "FLORIDA",
        "group": "W0099024",
        "group_num": [
            "recz4IM7fnhPyRLHL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 431,
        "2023": 476,
        "2024": 0,
        "id": "reco9v2NvySddq5je",
        "row": 7603,
        "state": "FLORIDA",
        "group": "16425002",
        "group_num": [
            "recGlREWjuMsoBH78"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 3300,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recop94TGwHTLrlsg",
        "row": 7573,
        "state": "FLORIDA",
        "group": "236747",
        "group_num": [
            "recym0v2fY3SuEfKv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 22,
        "2021": 20,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrWWP8FRIgtvig4",
        "row": 7598,
        "state": "FLORIDA",
        "group": "15609004",
        "group_num": [
            "recO3YIABx9TviTi4"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 0,
        "2024": 0,
        "id": "recsFhS4tnaALdLgI",
        "row": 7604,
        "state": "FLORIDA",
        "group": "16425002",
        "group_num": [
            "recGlREWjuMsoBH78"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recttm2YahOqzhdI3",
        "row": 7577,
        "state": "FLORIDA",
        "group": "281311M001",
        "group_num": [
            "rec0WHsX49enO1gyr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "2023": 0,
        "2024": 0,
        "id": "recuERcA2UwXUkPIv",
        "row": 7594,
        "state": "FLORIDA",
        "group": "16510R04",
        "group_num": [
            "reclBYSsDtthnV3mm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvzYP5u6VJruvsU",
        "row": 7612,
        "state": "FLORIDA",
        "group": "40918001",
        "group_num": [
            "recKSgKmOnzdrGAZK"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 153,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvqvohuyyYgKf1i",
        "row": 7601,
        "state": "FLORIDA",
        "group": "16153003",
        "group_num": [
            "reccencxOIs8elUs0"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recws6N3x8UCKatqz",
        "row": 7616,
        "state": "FLORIDA",
        "group": "63765002",
        "group_num": [
            "recZlRPIrJlj8sT9M"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "reczTgVScowWxFRcX",
        "row": 7590,
        "state": "FLORIDA",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 834,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczGMs6ceLSfXWhH",
        "row": 7578,
        "state": "FLORIDA",
        "group": "W0009299",
        "group_num": [
            "recwFALtoWDF4uVjz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDjl9Ch2JHCUeNN",
        "row": 7611,
        "state": "FLORIDA",
        "group": "40918001",
        "group_num": [
            "recKSgKmOnzdrGAZK"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1109,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDrtmBaPmBqakzH",
        "row": 7595,
        "state": "FLORIDA",
        "group": "45380",
        "group_num": [
            "rec7P1EwObigbHdg5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recEtSUAnHH7T7Nh7",
        "row": 7607,
        "state": "FLORIDA",
        "group": "24326001",
        "group_num": [
            "recXGdeHtetDtflIH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "2023": 0,
        "2024": 0,
        "id": "recED7wZPLh5Y7UYx",
        "row": 7572,
        "state": "FLORIDA",
        "group": "788800",
        "group_num": [
            "recuCT5ps3qQV3f4y"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recElCPfnnCWDhHuo",
        "row": 7597,
        "state": "FLORIDA",
        "group": "64065",
        "group_num": [
            "rec97nF2uC6cUEaS2"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFGgdLndH3cxGvz",
        "row": 7602,
        "state": "FLORIDA",
        "group": "16153003",
        "group_num": [
            "reccencxOIs8elUs0"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "recF3VdqbGiy2XfcG",
        "row": 7613,
        "state": "FLORIDA",
        "group": "41954029",
        "group_num": [
            "recnbbmaof5cdfZ4d"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFm4GF5Q9lAXYzq",
        "row": 7587,
        "state": "FLORIDA",
        "group": "W0099024",
        "group_num": [
            "recz4IM7fnhPyRLHL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGPMYNLTfuB3BNm",
        "row": 7605,
        "state": "FLORIDA",
        "group": "19948001",
        "group_num": [
            "recLbCvUKbhab24k3"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recHkxffa8POIc5lY",
        "row": 7606,
        "state": "FLORIDA",
        "group": "24326001",
        "group_num": [
            "recXGdeHtetDtflIH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIWIbGysIf0PjnX",
        "row": 7579,
        "state": "FLORIDA",
        "group": "W0009299",
        "group_num": [
            "recwFALtoWDF4uVjz"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 320,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKo1eASXIDyQKNS",
        "row": 7568,
        "state": "FLORIDA",
        "group": "0210000011",
        "group_num": [
            "rechKq8dmDuDhSE7T"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 383,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLwCSL1dnamu6VI",
        "row": 7596,
        "state": "FLORIDA",
        "group": "45380",
        "group_num": [
            "rec7P1EwObigbHdg5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM2A5m8THfE0GG8",
        "row": 7584,
        "state": "FLORIDA",
        "group": "W0084823",
        "group_num": [
            "rec8VT9L7rxLvSc8X"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfRdsWi7pVKmaGg",
        "row": 7654,
        "state": "FLORIDA",
        "group": "S8387001",
        "group_num": [
            "recONoxJyCfuqxy2q"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgVHrH9sC6DUjZq",
        "row": 7618,
        "state": "FLORIDA",
        "group": "64065001",
        "group_num": [
            "recQPMOQkepQvnjfD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recghFZYVdwCiZiFp",
        "row": 7655,
        "state": "FLORIDA",
        "group": "9999ZSC",
        "group_num": [
            "recL7vhLqN9HOFc3F"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 43,
        "2023": 0,
        "2024": 0,
        "id": "rech9QRdRHoPnWHZm",
        "row": 7647,
        "state": "FLORIDA",
        "group": "J8758003",
        "group_num": [
            "rec66WcIojVgMtLqK"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 23,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOJ6QMVCxXePCuU",
        "row": 7640,
        "state": "FLORIDA",
        "group": "J0261001",
        "group_num": [
            "recBYEbZChXwUytnR"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recRP6mqRqiOr4J1P",
        "row": 7645,
        "state": "FLORIDA",
        "group": "J6460001",
        "group_num": [
            "reccVlxK7JR3a3IAD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRbpUMJVYPBo7WO",
        "row": 7620,
        "state": "FLORIDA",
        "group": "64065001",
        "group_num": [
            "recQPMOQkepQvnjfD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 30,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRbrE6tR42tYVf4",
        "row": 7644,
        "state": "FLORIDA",
        "group": "J1166003",
        "group_num": [
            "recAdP9Ajg38TqUv3"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 167,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSg0oxxIycykpoX",
        "row": 7625,
        "state": "FLORIDA",
        "group": "78822014",
        "group_num": [
            "rec67RuUaHY6jplM4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recT1wZIhAYwAo9xk",
        "row": 7665,
        "state": "FLORIDA",
        "group": "99999ZS1",
        "group_num": [
            "rec3FPxlBE4aPcOoD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVNCidlWU0RjHtC",
        "row": 7648,
        "state": "FLORIDA",
        "group": "68339004",
        "group_num": [
            "recyZp1IU9dEF1ApG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "2023": 0,
        "2024": 0,
        "id": "recW9LoPg8fgEla4G",
        "row": 7646,
        "state": "FLORIDA",
        "group": "J6460001",
        "group_num": [
            "reccVlxK7JR3a3IAD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYqb157kmujeqRU",
        "row": 7663,
        "state": "FLORIDA",
        "group": "99999ZS1",
        "group_num": [
            "rec3FPxlBE4aPcOoD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recZuAZOi06uWx0hl",
        "row": 7661,
        "state": "FLORIDA",
        "group": "99999ZKH",
        "group_num": [
            "recV5Axor0h1yfdZf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec2BZqpndSkvea8B",
        "row": 7630,
        "state": "FLORIDA",
        "group": "B4247002",
        "group_num": [
            "recIGXlbRaJgckr6G"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 815,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2HbUYQWfIlDEFR",
        "row": 7634,
        "state": "FLORIDA",
        "group": "B9472004",
        "group_num": [
            "recpB2Grx1Lce38oT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 815,
        "2023": 0,
        "2024": 0,
        "id": "rec2PxwnRRgyUgqpF",
        "row": 7659,
        "state": "FLORIDA",
        "group": "99999ZAM",
        "group_num": [
            "recDaPV41K1PwVn2I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 311,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2PVMuE8C9FhOME",
        "row": 7624,
        "state": "FLORIDA",
        "group": "78822014",
        "group_num": [
            "rec67RuUaHY6jplM4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec28AlzCmcsHeKLU",
        "row": 7666,
        "state": "FLORIDA",
        "group": "99999ZS1",
        "group_num": [
            "rec3FPxlBE4aPcOoD"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3qXsIqK1INGNul",
        "row": 7652,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8vEFQ1KQqxw55J",
        "row": 7633,
        "state": "FLORIDA",
        "group": "B6625002",
        "group_num": [
            "rectw0EjY45Pq1qcu"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 163,
        "2021": 160,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8wQQAa62GMNQzh",
        "row": 7656,
        "state": "FLORIDA",
        "group": "9999ZSC",
        "group_num": [
            "recL7vhLqN9HOFc3F"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 32,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8MledBv7kEp3Yp",
        "row": 7621,
        "state": "FLORIDA",
        "group": "64065004",
        "group_num": [
            "recmK7f0fJqnJayIk"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec86IyASZC03kbCS",
        "row": 7637,
        "state": "FLORIDA",
        "group": "J0261001",
        "group_num": [
            "recBYEbZChXwUytnR"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 326,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9PfwwVUOhS2Jyy",
        "row": 7635,
        "state": "FLORIDA",
        "group": "B9472004",
        "group_num": [
            "recpB2Grx1Lce38oT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 780,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recb6NZDiq84kPbLu",
        "row": 7662,
        "state": "FLORIDA",
        "group": "99999ZRD",
        "group_num": [
            "recRLK1UoZR1JpfVP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 163,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbdCxqFBDDqnPX4",
        "row": 7636,
        "state": "FLORIDA",
        "group": "B9472004",
        "group_num": [
            "recpB2Grx1Lce38oT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 82,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recbfpusy2JCzGTBH",
        "row": 7628,
        "state": "FLORIDA",
        "group": "91199004",
        "group_num": [
            "recWlgIdPxpBVbjDm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccuKnkkOxHbzciB",
        "row": 7667,
        "state": "FLORIDA",
        "group": "99999ZT1",
        "group_num": [
            "recHC380fpH6jnKDO"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccPzGPQUEKZgFaJ",
        "row": 7658,
        "state": "FLORIDA",
        "group": "99999PH3",
        "group_num": [
            "rec40fwFDVRIXrK86"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "reccnQYmPqosXUxMW",
        "row": 7642,
        "state": "FLORIDA",
        "group": "J0878001",
        "group_num": [
            "rec1D9MF0qRWe6F1f"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recobzLLvB6b0pIWS",
        "row": 7631,
        "state": "FLORIDA",
        "group": "B4247002",
        "group_num": [
            "recIGXlbRaJgckr6G"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpUo71AUiZuQE0z",
        "row": 7623,
        "state": "FLORIDA",
        "group": "78822014",
        "group_num": [
            "rec67RuUaHY6jplM4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 878,
        "2023": 0,
        "2024": 0,
        "id": "recrLduCb0TOFmzX4",
        "row": 7650,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr55325zulUINpt",
        "row": 7649,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "rectH6wU09rZSfj5P",
        "row": 7653,
        "state": "FLORIDA",
        "group": "J7829002",
        "group_num": [
            "rec0E9xQcfwhCqmCn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 282,
        "2021": 330,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv3KhHIdBt8Z1M4",
        "row": 7664,
        "state": "FLORIDA",
        "group": "99999ZS1",
        "group_num": [
            "rec3FPxlBE4aPcOoD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwX9mDaPq4AoDbj",
        "row": 7657,
        "state": "FLORIDA",
        "group": "9999ZSC",
        "group_num": [
            "recL7vhLqN9HOFc3F"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 853,
        "2023": 0,
        "2024": 0,
        "id": "recyrF6nsMNCyVZAX",
        "row": 7660,
        "state": "FLORIDA",
        "group": "99999ZKH",
        "group_num": [
            "recV5Axor0h1yfdZf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recz05bo6wU6WStnq",
        "row": 7641,
        "state": "FLORIDA",
        "group": "J0652002",
        "group_num": [
            "recc1qM1VnLtnvwuC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAqTrVxa4oFvzUS",
        "row": 7626,
        "state": "FLORIDA",
        "group": "91199004",
        "group_num": [
            "recWlgIdPxpBVbjDm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 153,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDYbK82ypfAGUvD",
        "row": 7632,
        "state": "FLORIDA",
        "group": "B6625002",
        "group_num": [
            "rectw0EjY45Pq1qcu"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 467,
        "2023": 0,
        "2024": 0,
        "id": "recDpqbpBpjYc6jZ4",
        "row": 7643,
        "state": "FLORIDA",
        "group": "J0878001",
        "group_num": [
            "rec1D9MF0qRWe6F1f"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHFK0HBbm0iMRGc",
        "row": 7619,
        "state": "FLORIDA",
        "group": "64065001",
        "group_num": [
            "recQPMOQkepQvnjfD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 279,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH32c8gDnmXxqwq",
        "row": 7638,
        "state": "FLORIDA",
        "group": "J0261001",
        "group_num": [
            "recBYEbZChXwUytnR"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIfa2xQVBx4PZtR",
        "row": 7651,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKkgEUw40GFXHEM",
        "row": 7639,
        "state": "FLORIDA",
        "group": "J0261001",
        "group_num": [
            "recBYEbZChXwUytnR"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 83,
        "2023": 0,
        "2024": 0,
        "id": "recLKHsNlDSniOPHe",
        "row": 7629,
        "state": "FLORIDA",
        "group": "98094005",
        "group_num": [
            "recwEhiUnTLgiNMen"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNC4iGOhmt0USNo",
        "row": 7627,
        "state": "FLORIDA",
        "group": "91199004",
        "group_num": [
            "recWlgIdPxpBVbjDm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 750,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNb0tqE9Z3F0s3R",
        "row": 7622,
        "state": "FLORIDA",
        "group": "76694001",
        "group_num": [
            "recptrbHFThOpMyhQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 231,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receWzwQnXfFxw4ny",
        "row": 7693,
        "state": "FLORIDA",
        "group": "214974",
        "group_num": [
            "reclxLE8ADEGGVjGq"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receiLXwks1xSvK6c",
        "row": 7712,
        "state": "FLORIDA",
        "group": "00C076000",
        "group_num": [
            "recGXpKEwkhAnjYHf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 584,
        "2021": 600,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recekKWnocyjr8ENB",
        "row": 7690,
        "state": "FLORIDA",
        "group": "208194",
        "group_num": [
            "rec8RCzDEweLQ5Ydu"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1059,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciZtECZcB64XP1k",
        "row": 7714,
        "state": "FLORIDA",
        "group": "002322218",
        "group_num": [
            "reczYXtiSAIlHeDRY"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckBnhZUTZQtBYxP",
        "row": 7670,
        "state": "FLORIDA",
        "group": "99999ZT5",
        "group_num": [
            "recUIUPzVy92qaVZQ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recObC0qHK4Qiu6lx",
        "row": 7668,
        "state": "FLORIDA",
        "group": "99999ZT1",
        "group_num": [
            "recHC380fpH6jnKDO"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recPKFtcenCeopMwP",
        "row": 7677,
        "state": "FLORIDA",
        "group": "P06574",
        "group_num": [
            "reczLzYnPIOesW7Cs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 326,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP5O3UOGA59MkW9",
        "row": 7674,
        "state": "FLORIDA",
        "group": "10090002",
        "group_num": [
            "recQK5cjppLF2oSK5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPbvJvwoeAQ690J",
        "row": 7673,
        "state": "FLORIDA",
        "group": "971561",
        "group_num": [
            "reclqUOAUT2ORDyQF"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recRy86Vg6buDk6Ol",
        "row": 7684,
        "state": "FLORIDA",
        "group": "P75819",
        "group_num": [
            "rec3LVmxZLNxmWM7H"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1179,
        "2022": 1176,
        "2023": 0,
        "2024": 0,
        "id": "recSKxGg2Ttuaapp1",
        "row": 7717,
        "state": "FLORIDA",
        "group": "002368467",
        "group_num": [
            "rec679O9c5jvftvjY"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 269,
        "2023": 0,
        "2024": 0,
        "id": "recSfyLjx5WytO73E",
        "row": 7704,
        "state": "FLORIDA",
        "group": "29D68ERC",
        "group_num": [
            "rec5r4vDb5e5qPy9I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 431,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTeyweSzvlOmV1L",
        "row": 7692,
        "state": "FLORIDA",
        "group": "214974",
        "group_num": [
            "reclxLE8ADEGGVjGq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 900,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUYIcEwMPnzIxEi",
        "row": 7682,
        "state": "FLORIDA",
        "group": "P34324",
        "group_num": [
            "recMwwAypQ6upowp0"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "2023": 0,
        "2024": 0,
        "id": "recUvZCWkpnGogFWp",
        "row": 7686,
        "state": "FLORIDA",
        "group": "PF2071",
        "group_num": [
            "recJNZZFjPO8k7gMT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 185,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUK8EY7vPS9hyx3",
        "row": 7700,
        "state": "FLORIDA",
        "group": "33W60ERC",
        "group_num": [
            "recGIu4PYmwG6bHbA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1680,
        "2023": 0,
        "2024": 0,
        "id": "rec0wv2gQqm7ok5s7",
        "row": 7671,
        "state": "FLORIDA",
        "group": "GA7186M011",
        "group_num": [
            "recNY02sWLnlNQz5n"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1657,
        "2023": 0,
        "2024": 0,
        "id": "rec1MtdA6di3XCs7I",
        "row": 7672,
        "state": "FLORIDA",
        "group": "GA7186M011",
        "group_num": [
            "recNY02sWLnlNQz5n"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "rec2EGpAQJKvLhjsG",
        "row": 7689,
        "state": "FLORIDA",
        "group": "195569",
        "group_num": [
            "recYP0vLpXQHphZax"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 370,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4VpKYCA7FnOPv8",
        "row": 7699,
        "state": "FLORIDA",
        "group": "33W60ERC",
        "group_num": [
            "recGIu4PYmwG6bHbA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 667,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4KfqTcT8oyj7ir",
        "row": 7710,
        "state": "FLORIDA",
        "group": "MAY00000",
        "group_num": [
            "recPhAkMt6wao65Z2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 525,
        "2022": 665,
        "2023": 0,
        "2024": 0,
        "id": "rec61nd9lgc9PvJKy",
        "row": 7685,
        "state": "FLORIDA",
        "group": "PF2071",
        "group_num": [
            "recJNZZFjPO8k7gMT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7iZk6Gglz7wKxA",
        "row": 7711,
        "state": "FLORIDA",
        "group": "00C076000",
        "group_num": [
            "recGXpKEwkhAnjYHf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec8wD1tRiIIAPA5h",
        "row": 7702,
        "state": "FLORIDA",
        "group": "78K40ERC",
        "group_num": [
            "recxOwQVc6l3tRkyw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 185,
        "2023": 0,
        "2024": 0,
        "id": "rec9DqtofUMkFDdGI",
        "row": 7705,
        "state": "FLORIDA",
        "group": "35F68ERC",
        "group_num": [
            "recXHwP2gvQ3YmohC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9i5HbC41Jx99MM",
        "row": 7698,
        "state": "FLORIDA",
        "group": "6045521000",
        "group_num": [
            "recHBaW6NNbVXE8E8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 495,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9r45BOez4ufcmv",
        "row": 7675,
        "state": "FLORIDA",
        "group": "10090002",
        "group_num": [
            "recQK5cjppLF2oSK5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 450,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccdTbC3mpmreynD",
        "row": 7680,
        "state": "FLORIDA",
        "group": "P32009",
        "group_num": [
            "recxwkkXdjDXo9AiB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 86,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recopopfY3X0fOn3E",
        "row": 7701,
        "state": "FLORIDA",
        "group": "33W60ERC",
        "group_num": [
            "recGIu4PYmwG6bHbA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 667,
        "2023": 0,
        "2024": 0,
        "id": "recpDdbpZkXXprcfe",
        "row": 7695,
        "state": "FLORIDA",
        "group": "226613",
        "group_num": [
            "recWeX31CaqVD8gGl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1106,
        "2023": 0,
        "2024": 0,
        "id": "recqBhoX4syLVyshB",
        "row": 7687,
        "state": "FLORIDA",
        "group": "PK4720",
        "group_num": [
            "reca90Njtp1cSaOph"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 249,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqh5TpUTN066fUj",
        "row": 7681,
        "state": "FLORIDA",
        "group": "P32009",
        "group_num": [
            "recxwkkXdjDXo9AiB"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recrbBzD0qCr9TbgY",
        "row": 7694,
        "state": "FLORIDA",
        "group": "226613",
        "group_num": [
            "recWeX31CaqVD8gGl"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 30,
        "2021": 29,
        "2022": 29,
        "2023": 0,
        "2024": 0,
        "id": "recrjnjrsw6ZPIuVr",
        "row": 7691,
        "state": "FLORIDA",
        "group": "208194",
        "group_num": [
            "rec8RCzDEweLQ5Ydu"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 927,
        "2023": 0,
        "2024": 0,
        "id": "rectA8alkDsN8YadV",
        "row": 7678,
        "state": "FLORIDA",
        "group": "P09407",
        "group_num": [
            "recjwqbnKaMFnfmbz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 466,
        "2024": 0,
        "id": "recv1ovhkIz7TkNfj",
        "row": 7706,
        "state": "FLORIDA",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 0,
        "2024": 0,
        "id": "recxyLQtC8xGI7a1Z",
        "row": 7676,
        "state": "FLORIDA",
        "group": "P06574",
        "group_num": [
            "reczLzYnPIOesW7Cs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 450,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxLtQ5xhUS16jRC",
        "row": 7679,
        "state": "FLORIDA",
        "group": "P32009",
        "group_num": [
            "recxwkkXdjDXo9AiB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxaivgt2AI6hLXg",
        "row": 7713,
        "state": "FLORIDA",
        "group": "00C076000",
        "group_num": [
            "recGXpKEwkhAnjYHf"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 489,
        "2023": 0,
        "2024": 0,
        "id": "recz2DKzfaV1IzwhO",
        "row": 7708,
        "state": "FLORIDA",
        "group": "37678FF2",
        "group_num": [
            "reckCiuzdegcSLA03"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "2023": 0,
        "2024": 0,
        "id": "recAZ0cngL9jYSSHu",
        "row": 7703,
        "state": "FLORIDA",
        "group": "29D68ERC",
        "group_num": [
            "rec5r4vDb5e5qPy9I"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 163,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBJizAkYrrVAVPI",
        "row": 7669,
        "state": "FLORIDA",
        "group": "99999ZT5",
        "group_num": [
            "recUIUPzVy92qaVZQ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBbpyWR2hbBDCd6",
        "row": 7696,
        "state": "FLORIDA",
        "group": "6045521000",
        "group_num": [
            "recHBaW6NNbVXE8E8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1183,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recE1BR6rlvc7fstM",
        "row": 7716,
        "state": "FLORIDA",
        "group": "002368467",
        "group_num": [
            "rec679O9c5jvftvjY"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1385,
        "2023": 0,
        "2024": 0,
        "id": "recEkp9CFG4eHwaY3",
        "row": 7715,
        "state": "FLORIDA",
        "group": "002365119",
        "group_num": [
            "recaJaSCpDNdtSvZL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recHWoLaofBZeGvU3",
        "row": 7683,
        "state": "FLORIDA",
        "group": "P40970",
        "group_num": [
            "recEY4JhGc6LJuwwl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recICaKnCDaCSPAjh",
        "row": 7697,
        "state": "FLORIDA",
        "group": "6045521000",
        "group_num": [
            "recHBaW6NNbVXE8E8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "2023": 185,
        "2024": 0,
        "id": "recKp2rAdC6mSKqIB",
        "row": 7709,
        "state": "FLORIDA",
        "group": "37678FF2",
        "group_num": [
            "reckCiuzdegcSLA03"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recMV7ZpXJgmLpAZs",
        "row": 7688,
        "state": "FLORIDA",
        "group": "195569",
        "group_num": [
            "recYP0vLpXQHphZax"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 280,
        "2024": 0,
        "id": "recN7xUcJlNvRcY5C",
        "row": 7707,
        "state": "FLORIDA",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recebO2VxqEjAtyfK",
        "row": 7725,
        "state": "FLORIDA",
        "group": "041665",
        "group_num": [
            "recWlJXGqEyDRcLlx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgWeYEWNTjLp0vt",
        "row": 7759,
        "state": "FLORIDA",
        "group": "14168782",
        "group_num": [
            "recCzBOI8edvNPfbS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciT1n4fpT2hEz4U",
        "row": 7814,
        "state": "FLORIDA",
        "group": "100750",
        "group_num": [
            "recT7xy3XITxaxoNT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 2533,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "reciCcOe9DWpCUkJD",
        "row": 7740,
        "state": "FLORIDA",
        "group": "002364773",
        "group_num": [
            "recK1fdXNufpxYX1e"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recio7ZYgn0GtyQMR",
        "row": 7758,
        "state": "FLORIDA",
        "group": "14166371",
        "group_num": [
            "recRKpBpuqYww4vro"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recjyeYhe34VblecP",
        "row": 7776,
        "state": "FLORIDA",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclXb2cycuiNcQf7",
        "row": 7785,
        "state": "FLORIDA",
        "group": "10442964",
        "group_num": [
            "recECn1VR3CFtTURa"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 598,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclYCwQ4MLT0DQsc",
        "row": 7732,
        "state": "FLORIDA",
        "group": "00856860066",
        "group_num": [
            "recCziP8shAvFwd0J"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 297,
        "2024": 0,
        "id": "reclsmpeBJRQIGVIN",
        "row": 7764,
        "state": "FLORIDA",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1306,
        "2023": 0,
        "2024": 0,
        "id": "reclAPLxlxDGHr7B3",
        "row": 7738,
        "state": "FLORIDA",
        "group": "00373D50078",
        "group_num": [
            "recFMp0M8r0u5tHh8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmVYFJzC1DrzGtj",
        "row": 7809,
        "state": "FLORIDA",
        "group": "112392",
        "group_num": [
            "recDAhy5ID5AePs0S"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "recmBErUVJ257pyrT",
        "row": 7781,
        "state": "FLORIDA",
        "group": "05034600",
        "group_num": [
            "recIcVGgJK4S1fYCL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 797,
        "2022": 844,
        "2023": 0,
        "2024": 0,
        "id": "recm3dL5bi3lm88Hi",
        "row": 7774,
        "state": "FLORIDA",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 986,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmpe2LXbQdubuA1",
        "row": 7801,
        "state": "FLORIDA",
        "group": "158778002",
        "group_num": [
            "recoWQgr3wBQDLIOA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1188,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnNl3LEr7QbJTBG",
        "row": 7799,
        "state": "FLORIDA",
        "group": "93BA11310",
        "group_num": [
            "rec9Lm0zLX3OIP9vc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 239,
        "2023": 0,
        "2024": 0,
        "id": "recn8kQwjdY2j9596",
        "row": 7787,
        "state": "FLORIDA",
        "group": "10416806",
        "group_num": [
            "recIzUX4gNnv2386R"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnitbzx9sZzvjgr",
        "row": 7755,
        "state": "FLORIDA",
        "group": "14162105",
        "group_num": [
            "recB6eaBnsgfbHlt1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnq1z5I8QAYFj4e",
        "row": 7771,
        "state": "FLORIDA",
        "group": "M16853",
        "group_num": [
            "recfsB6ISpaSxNVwu"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "2023": 0,
        "2024": 0,
        "id": "recOV7SE4XMoPzDFd",
        "row": 7724,
        "state": "FLORIDA",
        "group": "031936",
        "group_num": [
            "rec4IW3CfL9eInXO4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recOOJafh9fVzc10p",
        "row": 7808,
        "state": "FLORIDA",
        "group": "106149",
        "group_num": [
            "recSJzIRke938LdFa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 167,
        "2022": 167,
        "2023": 0,
        "2024": 0,
        "id": "recOdkE4MZqdDluB7",
        "row": 7747,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP4D0m7Ih825y66",
        "row": 7783,
        "state": "FLORIDA",
        "group": "01146771",
        "group_num": [
            "rechs22vurUid8qvj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recPjPrNJwKmSkMML",
        "row": 7775,
        "state": "FLORIDA",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "2024": 0,
        "id": "recPnEFw8sgrbUjV9",
        "row": 7739,
        "state": "FLORIDA",
        "group": "208921",
        "group_num": [
            "recB5P54eZvuxutWa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQJ04hv48vvQgjn",
        "row": 7727,
        "state": "FLORIDA",
        "group": "12057000",
        "group_num": [
            "rechFLKtDunitXaLU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 333,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRU3sODXvi1Gswr",
        "row": 7737,
        "state": "FLORIDA",
        "group": "11448312",
        "group_num": [
            "recInv6crQNfQRCl2"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1917,
        "2021": 2644,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRlyqjcMO8w7xdu",
        "row": 7731,
        "state": "FLORIDA",
        "group": "00856860066",
        "group_num": [
            "recCziP8shAvFwd0J"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2011,
        "2023": 6000,
        "2024": 0,
        "id": "recRmpqlb2VvwpL4Y",
        "row": 7748,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recRnwtay2suQm6kr",
        "row": 7769,
        "state": "FLORIDA",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 3084,
        "2022": 4755,
        "2023": 0,
        "2024": 0,
        "id": "recS842R6mQU7Xmzl",
        "row": 7744,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 288,
        "2024": 0,
        "id": "recS95fvZqzWMMmy8",
        "row": 7816,
        "state": "FLORIDA",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recT5gqfgDrfzmMGt",
        "row": 7721,
        "state": "FLORIDA",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUudDD4p5p3MjqB",
        "row": 7728,
        "state": "FLORIDA",
        "group": "10001000",
        "group_num": [
            "reciP2IcR8Axa7TAY"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUg41EKVaP1EDMA",
        "row": 7756,
        "state": "FLORIDA",
        "group": "14165710",
        "group_num": [
            "recqUZpW25sXT6kwa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 297,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recUiaNES9Kex15kE",
        "row": 7746,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recUqZQhQZaqIdhQt",
        "row": 7741,
        "state": "FLORIDA",
        "group": "002364773",
        "group_num": [
            "recK1fdXNufpxYX1e"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 177,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVuWGr8Soe5Anu5",
        "row": 7735,
        "state": "FLORIDA",
        "group": "000011",
        "group_num": [
            "recyxW39pwAGkchDB"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 326,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVlWWq6ret85Kjl",
        "row": 7803,
        "state": "FLORIDA",
        "group": "96214",
        "group_num": [
            "recbgmT8ubEuFNVMl"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 830,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWAvAYqEpEgVAYS",
        "row": 7751,
        "state": "FLORIDA",
        "group": "077313",
        "group_num": [
            "rec1e3HYid7lN6KjE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "recWPJTF39CfFQmiZ",
        "row": 7792,
        "state": "FLORIDA",
        "group": "66CV00",
        "group_num": [
            "recu7rOFwvWt8t17y"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1748,
        "2024": 0,
        "id": "recX3IRaxssjcp1bk",
        "row": 7782,
        "state": "FLORIDA",
        "group": "05034600",
        "group_num": [
            "recIcVGgJK4S1fYCL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 0,
        "2024": 0,
        "id": "recYQByMzzeOQX5sF",
        "row": 7777,
        "state": "FLORIDA",
        "group": "00701175",
        "group_num": [
            "rec8OXumoAQlPFDRC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 829,
        "2024": 0,
        "id": "recZSRPJVAwutVXQr",
        "row": 7763,
        "state": "FLORIDA",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "2023": 0,
        "2024": 0,
        "id": "recZsGUmJqC99e9go",
        "row": 7752,
        "state": "FLORIDA",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZwN8bvG50qZsLs",
        "row": 7734,
        "state": "FLORIDA",
        "group": "000011",
        "group_num": [
            "recyxW39pwAGkchDB"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recZA0L6MG0m9HFlN",
        "row": 7736,
        "state": "FLORIDA",
        "group": "11448312",
        "group_num": [
            "recInv6crQNfQRCl2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec1ijOJutEg2mN3z",
        "row": 7784,
        "state": "FLORIDA",
        "group": "01741300",
        "group_num": [
            "recibuGLDr2nxNV2n"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 802,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4UW5gJlxf9Vbt3",
        "row": 7802,
        "state": "FLORIDA",
        "group": "96214",
        "group_num": [
            "recbgmT8ubEuFNVMl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4uA8G7fXAExiGN",
        "row": 7793,
        "state": "FLORIDA",
        "group": "279070100",
        "group_num": [
            "recIiwWZ0vrd5KmIE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec4qcU9m5xjypwux",
        "row": 7720,
        "state": "FLORIDA",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 829,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5Y6UqJsrUTamhd",
        "row": 7760,
        "state": "FLORIDA",
        "group": "14168782",
        "group_num": [
            "recCzBOI8edvNPfbS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "rec5E1ad2hgMbQxLu",
        "row": 7765,
        "state": "FLORIDA",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5ckxFa1k46ZmEP",
        "row": 7811,
        "state": "FLORIDA",
        "group": "131083",
        "group_num": [
            "rec9pGAO4nssrILPA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 0,
        "2024": 0,
        "id": "rec6vRIGNzKE33Zro",
        "row": 7779,
        "state": "FLORIDA",
        "group": "00702644",
        "group_num": [
            "recSzhWQ5rTkp9riz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5233,
        "2023": 0,
        "2024": 0,
        "id": "rec6jgR8tNT5lGIF0",
        "row": 7750,
        "state": "FLORIDA",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 815,
        "2023": 0,
        "2024": 0,
        "id": "rec7ApWj7W3paa4FN",
        "row": 7786,
        "state": "FLORIDA",
        "group": "10442964",
        "group_num": [
            "recECn1VR3CFtTURa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec8a4sc5EI2NNype",
        "row": 7806,
        "state": "FLORIDA",
        "group": "100740",
        "group_num": [
            "recCWROII3kJIr0Qi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 320,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9tWMRwHKwUgcdf",
        "row": 7812,
        "state": "FLORIDA",
        "group": "100750",
        "group_num": [
            "recT7xy3XITxaxoNT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 879,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaHZEQYkaaU6vcJ",
        "row": 7800,
        "state": "FLORIDA",
        "group": "158778002",
        "group_num": [
            "recoWQgr3wBQDLIOA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 806,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaPr4stIYc8H8by",
        "row": 7813,
        "state": "FLORIDA",
        "group": "100750",
        "group_num": [
            "recT7xy3XITxaxoNT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 0,
        "2024": 0,
        "id": "recac0lCP2bri6qcN",
        "row": 7778,
        "state": "FLORIDA",
        "group": "00701175",
        "group_num": [
            "rec8OXumoAQlPFDRC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 1206,
        "2022": 1036,
        "2023": 0,
        "2024": 0,
        "id": "recbYFBlHd2fAgRob",
        "row": 7745,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recbApUZTRzytkpyW",
        "row": 7770,
        "state": "FLORIDA",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 0,
        "2024": 0,
        "id": "reccyPGLK9bxf3cUq",
        "row": 7780,
        "state": "FLORIDA",
        "group": "00702644",
        "group_num": [
            "recSzhWQ5rTkp9riz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 186,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdyt0Lnq5Mittrl",
        "row": 7794,
        "state": "FLORIDA",
        "group": "279070100",
        "group_num": [
            "recIiwWZ0vrd5KmIE"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 396,
        "2023": 0,
        "2024": 0,
        "id": "recdb8ub2Z2mRwmTX",
        "row": 7798,
        "state": "FLORIDA",
        "group": "002135200",
        "group_num": [
            "recNXI9Wfb4ZrBfKw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "2023": 0,
        "2024": 0,
        "id": "recoWi2pknFUTXJuW",
        "row": 7797,
        "state": "FLORIDA",
        "group": "002135200",
        "group_num": [
            "recNXI9Wfb4ZrBfKw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recprC3Z1POTPXV4w",
        "row": 7810,
        "state": "FLORIDA",
        "group": "131083",
        "group_num": [
            "rec9pGAO4nssrILPA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4520,
        "2023": 0,
        "2024": 0,
        "id": "recrZBF3I814cyCU1",
        "row": 7729,
        "state": "FLORIDA",
        "group": "27008564KA",
        "group_num": [
            "recIpCUoyGz44QghV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recsSpR5mxebCasrx",
        "row": 7762,
        "state": "FLORIDA",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 473,
        "2024": 0,
        "id": "recs5hrvhFYplTILO",
        "row": 7815,
        "state": "FLORIDA",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2266,
        "2023": 0,
        "2024": 0,
        "id": "rectbCy8sHjgngRIF",
        "row": 7796,
        "state": "FLORIDA",
        "group": "002132100",
        "group_num": [
            "recfWWW9WrjntIw9O"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuALFLwg6cEtS77",
        "row": 7788,
        "state": "FLORIDA",
        "group": "10497043",
        "group_num": [
            "recNetTcmDu99InK9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 157,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuhrN54MnMhcZUq",
        "row": 7722,
        "state": "FLORIDA",
        "group": "012388",
        "group_num": [
            "recn4N8VUi5TYn1j4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "2023": 0,
        "2024": 0,
        "id": "recvOCMZUDcpN06ud",
        "row": 7772,
        "state": "FLORIDA",
        "group": "OG1800",
        "group_num": [
            "recBky7nTdVR38skH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recwZ6OZWdJAgnRND",
        "row": 7807,
        "state": "FLORIDA",
        "group": "100740",
        "group_num": [
            "recCWROII3kJIr0Qi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 818,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwbb7VKpPh794RT",
        "row": 7773,
        "state": "FLORIDA",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 333,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recxOWCtO1eiytvF2",
        "row": 7726,
        "state": "FLORIDA",
        "group": "041665",
        "group_num": [
            "recWlJXGqEyDRcLlx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxn3ZTNZPKuXZ2n",
        "row": 7719,
        "state": "FLORIDA",
        "group": "007004636000100",
        "group_num": [
            "reckYxrYFwz1zvu3v"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1200,
        "2021": 1200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyFXg6d9fV01CQG",
        "row": 7733,
        "state": "FLORIDA",
        "group": "00862200028",
        "group_num": [
            "recBEQn6JGhH0Iv8D"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3096,
        "2023": 0,
        "2024": 0,
        "id": "recAYJ7UilUbri7O0",
        "row": 7730,
        "state": "FLORIDA",
        "group": "27008564KA",
        "group_num": [
            "recIpCUoyGz44QghV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 20,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recARxcTsvttUHI73",
        "row": 7805,
        "state": "FLORIDA",
        "group": "96214",
        "group_num": [
            "recbgmT8ubEuFNVMl"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 2247,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recCuKTYIul0118e7",
        "row": 7790,
        "state": "FLORIDA",
        "group": "W26562M003",
        "group_num": [
            "recmMRYeELOwMXDnS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recDWc9UpaV0ltrA1",
        "row": 7761,
        "state": "FLORIDA",
        "group": "14172079",
        "group_num": [
            "recBuVZJcODJkCKWY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 2800,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recD1kVHYTplTg55k",
        "row": 7718,
        "state": "FLORIDA",
        "group": "989242870",
        "group_num": [
            "recVJggBNZCMCRjvU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recEJxSU4WXO6t4Hx",
        "row": 7753,
        "state": "FLORIDA",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEc4JEKda2rPMiv",
        "row": 7804,
        "state": "FLORIDA",
        "group": "96214",
        "group_num": [
            "recbgmT8ubEuFNVMl"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 160,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFODgXbjkqOMQUr",
        "row": 7795,
        "state": "FLORIDA",
        "group": "279070100",
        "group_num": [
            "recIiwWZ0vrd5KmIE"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recFRKNFc2JhfdNEz",
        "row": 7757,
        "state": "FLORIDA",
        "group": "14166371",
        "group_num": [
            "recRKpBpuqYww4vro"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 830,
        "2023": 2476,
        "2024": 0,
        "id": "recItmRDvXZHLEk9a",
        "row": 7766,
        "state": "FLORIDA",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 153,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJ7BRDrB0jwaH9f",
        "row": 7768,
        "state": "FLORIDA",
        "group": "10349304",
        "group_num": [
            "recEyGN7DvIIWpOln"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 670,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJiWFuDGNXwton3",
        "row": 7817,
        "state": "FLORIDA",
        "group": "139094",
        "group_num": [
            "recSfN1484yzDXqa6"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKSCNxnXAgt4wki",
        "row": 7789,
        "state": "FLORIDA",
        "group": "W26562M003",
        "group_num": [
            "recmMRYeELOwMXDnS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 856,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKGc5UP4zDkWt8t",
        "row": 7767,
        "state": "FLORIDA",
        "group": "10349304",
        "group_num": [
            "recEyGN7DvIIWpOln"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recLb5pONMFW2CWzV",
        "row": 7723,
        "state": "FLORIDA",
        "group": "031936",
        "group_num": [
            "rec4IW3CfL9eInXO4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1165,
        "2023": 829,
        "2024": 0,
        "id": "recLqbLmDljoug41I",
        "row": 7749,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 306,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recM1F8bQpqk1B88Y",
        "row": 7743,
        "state": "FLORIDA",
        "group": "057783",
        "group_num": [
            "recGbQB5wLFDde8Ym"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recN5fExcfI7w5mPS",
        "row": 7754,
        "state": "FLORIDA",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 799,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNdW87M8RM4nB3J",
        "row": 7742,
        "state": "FLORIDA",
        "group": "057783",
        "group_num": [
            "recGbQB5wLFDde8Ym"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 833,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recNni4noPWdBizjS",
        "row": 7791,
        "state": "FLORIDA",
        "group": "039645",
        "group_num": [
            "recy4GUqPxvM4UdOX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "recfVOjfF4ClGum6Z",
        "row": 7837,
        "state": "FLORIDA",
        "group": "215150",
        "group_num": [
            "recAaxmkZYR43eZdy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 211,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfaAefZIwGsw5aO",
        "row": 7821,
        "state": "FLORIDA",
        "group": "151665",
        "group_num": [
            "recJRllhJt23fTrxk"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "recg0qrlOk73tnItA",
        "row": 7839,
        "state": "FLORIDA",
        "group": "266520",
        "group_num": [
            "recnDu2oW6oSXrY4X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 21,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgRYZBZUZIhtN9k",
        "row": 7822,
        "state": "FLORIDA",
        "group": "151665",
        "group_num": [
            "recJRllhJt23fTrxk"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 320,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recitOf0RElhTSD9x",
        "row": 7834,
        "state": "FLORIDA",
        "group": "200325",
        "group_num": [
            "recCSpzkSRstK77Bi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 385,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recid21kQPWMgy93J",
        "row": 7818,
        "state": "FLORIDA",
        "group": "151665",
        "group_num": [
            "recJRllhJt23fTrxk"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recjXcWpw9dKKX9nV",
        "row": 7846,
        "state": "FLORIDA",
        "group": "10044688",
        "group_num": [
            "recJesfYoUy3yfwJK"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "recjGZyeMOoMAZUAy",
        "row": 7842,
        "state": "FLORIDA",
        "group": "320728",
        "group_num": [
            "reckbRcrG97NJEbxf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 385,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckj4B5fgIQArMWu",
        "row": 7825,
        "state": "FLORIDA",
        "group": "180605",
        "group_num": [
            "rec6HCx7TnloD48bi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 790,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "reclyyZbpHCwRxkPH",
        "row": 7855,
        "state": "FLORIDA",
        "group": "5L3A00",
        "group_num": [
            "recjOMYOjn5l1nE9k"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 213,
        "2023": 198,
        "2024": 0,
        "id": "recmhiDUHCFUphzJt",
        "row": 7841,
        "state": "FLORIDA",
        "group": "289319",
        "group_num": [
            "recsjqmdAVJVE58vA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 292,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOWIVLSlZP8i74R",
        "row": 7829,
        "state": "FLORIDA",
        "group": "192418",
        "group_num": [
            "recW38rAmXrXBC4O8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "2023": 0,
        "2024": 0,
        "id": "recUEzfFL0K3NvjZC",
        "row": 7851,
        "state": "FLORIDA",
        "group": "W51014M002",
        "group_num": [
            "recNtnX6TQg1AcUzc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recV3x8K6tHctEt7Q",
        "row": 7838,
        "state": "FLORIDA",
        "group": "215150",
        "group_num": [
            "recAaxmkZYR43eZdy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 670,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recW2ouJCMwyZShXB",
        "row": 7823,
        "state": "FLORIDA",
        "group": "176050",
        "group_num": [
            "reciCYY3RByZGDy9U"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 333,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0UCpps987giG6e",
        "row": 7856,
        "state": "FLORIDA",
        "group": "5L3A00",
        "group_num": [
            "recjOMYOjn5l1nE9k"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0vPOg2qlVXktEb",
        "row": 7862,
        "state": "FLORIDA",
        "group": "818061000",
        "group_num": [
            "rec6TKaGOlcmv1Vxp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "rec1BunoN6kInxzJD",
        "row": 7843,
        "state": "FLORIDA",
        "group": "320728",
        "group_num": [
            "reckbRcrG97NJEbxf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 237,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1fpb1wWpsbcLVE",
        "row": 7830,
        "state": "FLORIDA",
        "group": "192418",
        "group_num": [
            "recW38rAmXrXBC4O8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 181,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6rFM4VQghrA6d4",
        "row": 7831,
        "state": "FLORIDA",
        "group": "192418",
        "group_num": [
            "recW38rAmXrXBC4O8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 243,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8e3uJfcSn275x9",
        "row": 7828,
        "state": "FLORIDA",
        "group": "180605",
        "group_num": [
            "rec6HCx7TnloD48bi"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 1001,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9w7lbhBJsvoBiz",
        "row": 7826,
        "state": "FLORIDA",
        "group": "180605",
        "group_num": [
            "rec6HCx7TnloD48bi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 21,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaDFdt5BFUoVV1N",
        "row": 7840,
        "state": "FLORIDA",
        "group": "277293",
        "group_num": [
            "rec0rW2A44oSqfjS5"
        ],
        "procedure": "OP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 198,
        "2024": 0,
        "id": "reccVp31iCcPM2xox",
        "row": 7836,
        "state": "FLORIDA",
        "group": "202153",
        "group_num": [
            "recw1eWkgz8H9hvbj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 172,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpBQYrdWmB7BD5Z",
        "row": 7845,
        "state": "FLORIDA",
        "group": "4004476",
        "group_num": [
            "recXYT9n5bpviOg9C"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqidlNskb9RcVhM",
        "row": 7860,
        "state": "FLORIDA",
        "group": "818061000",
        "group_num": [
            "rec6TKaGOlcmv1Vxp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsAUTbWYGUIIgi8",
        "row": 7850,
        "state": "FLORIDA",
        "group": "W51014M002",
        "group_num": [
            "recNtnX6TQg1AcUzc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 804,
        "2021": 806,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recunT36RWydDuJSH",
        "row": 7857,
        "state": "FLORIDA",
        "group": "SP0005252",
        "group_num": [
            "recE1qXFoPqMD4SEg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 923,
        "2024": 0,
        "id": "recxPqZrMMI3QAvFo",
        "row": 7835,
        "state": "FLORIDA",
        "group": "202153",
        "group_num": [
            "recw1eWkgz8H9hvbj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 320,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx79wE4dqZ1EvdJ",
        "row": 7833,
        "state": "FLORIDA",
        "group": "196441",
        "group_num": [
            "recGWvmVnMIs2qaRE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxdfZjzDAmVmEWv",
        "row": 7858,
        "state": "FLORIDA",
        "group": "SP0005469",
        "group_num": [
            "rec8SuiwxZaSCPwqk"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 309,
        "2022": 305,
        "2023": 0,
        "2024": 0,
        "id": "recyWcBj5EBbVoTt7",
        "row": 7854,
        "state": "FLORIDA",
        "group": "10358356",
        "group_num": [
            "recg3Xg5e6F68ZpEV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recye3YCksTjMCdpK",
        "row": 7859,
        "state": "FLORIDA",
        "group": "SP0005469",
        "group_num": [
            "rec8SuiwxZaSCPwqk"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5157,
        "2023": 0,
        "2024": 0,
        "id": "recz0ynnVUkyBdi9G",
        "row": 7847,
        "state": "FLORIDA",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 565,
        "2024": 0,
        "id": "recBBSoYarL1M7VaV",
        "row": 7849,
        "state": "FLORIDA",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recClEAlOQAP4aOyz",
        "row": 7853,
        "state": "FLORIDA",
        "group": "10358356",
        "group_num": [
            "recg3Xg5e6F68ZpEV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 383,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recExrt1pBFAHHWpH",
        "row": 7832,
        "state": "FLORIDA",
        "group": "196441",
        "group_num": [
            "recGWvmVnMIs2qaRE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFQWlcLHxoSkrvl",
        "row": 7824,
        "state": "FLORIDA",
        "group": "176050",
        "group_num": [
            "reciCYY3RByZGDy9U"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 708,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGzsMGAweoYAwmb",
        "row": 7852,
        "state": "FLORIDA",
        "group": "10358356",
        "group_num": [
            "recg3Xg5e6F68ZpEV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 305,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGecNtR7c9QYvnN",
        "row": 7819,
        "state": "FLORIDA",
        "group": "151665",
        "group_num": [
            "recJRllhJt23fTrxk"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recGnc8ph4iGM1dVv",
        "row": 7844,
        "state": "FLORIDA",
        "group": "SP3225M001",
        "group_num": [
            "recA6XuusqcRBElmC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 240,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIR9u0Zc3Za3jma",
        "row": 7820,
        "state": "FLORIDA",
        "group": "151665",
        "group_num": [
            "recJRllhJt23fTrxk"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 208,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recL3CrrCDaNxKC4m",
        "row": 7827,
        "state": "FLORIDA",
        "group": "180605",
        "group_num": [
            "rec6HCx7TnloD48bi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMD18jFcZdCJbZr",
        "row": 7861,
        "state": "FLORIDA",
        "group": "818061000",
        "group_num": [
            "rec6TKaGOlcmv1Vxp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2401,
        "2023": 0,
        "2024": 0,
        "id": "recMor2CldJY5ICv2",
        "row": 7848,
        "state": "FLORIDA",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF FL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "recPFgl9T7Nk2gIrY",
        "row": 11458,
        "state": "FLORIDA",
        "group": "276945M002",
        "group_num": [
            "rec0102jUeiNjdHFr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 985,
        "id": "recQcKrKYFz5WbJ4u",
        "row": 11433,
        "state": "FLORIDA",
        "group": "A43968",
        "group_num": [
            "receVPJPFJq3QXT12"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "recWZyd1l7g5uw7Qj",
        "row": 11452,
        "state": "FLORIDA",
        "group": "93BA11310",
        "group_num": [
            "rec9Lm0zLX3OIP9vc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 598,
        "2024": 598,
        "id": "rec1vLsHpTXYcTcz0",
        "row": 11429,
        "state": "FLORIDA",
        "group": "800976M006",
        "group_num": [
            "rec2wvLnvIXr4kAoz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "rec1IHh6iQYxcKZwj",
        "row": 11459,
        "state": "FLORIDA",
        "group": "2130661EIB",
        "group_num": [
            "recYWa1kWKw4siQi1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4000,
        "2024": 0,
        "id": "rec4OIHq9H4njePTb",
        "row": 11469,
        "state": "FLORIDA",
        "group": "270234M2FC",
        "group_num": [
            "recnWwfHorYsNzmLF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 849,
        "2024": 829,
        "id": "rec5PbmJOOc5HGN3E",
        "row": 11430,
        "state": "FLORIDA",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4000,
        "2024": 0,
        "id": "rec82uTg6NjcePMGw",
        "row": 11470,
        "state": "FLORIDA",
        "group": "050630",
        "group_num": [
            "rectzjy86COXoKJaY"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1435,
        "2024": 1484,
        "id": "recw0A9yOvaAjYa2Z",
        "row": 11435,
        "state": "FLORIDA",
        "group": "201045A810",
        "group_num": [
            "recum7CWwDJLBtdy0"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "recwMRpZH62ZzseyY",
        "row": 11450,
        "state": "FLORIDA",
        "group": "276945M002",
        "group_num": [
            "rec0102jUeiNjdHFr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 460,
        "2024": 460,
        "id": "recHpcxPQiZY1CD3U",
        "row": 11424,
        "state": "FLORIDA",
        "group": "10594915",
        "group_num": [
            "recLTVO0eDeztzHA1"
        ],
        "procedure": "PHP",
        "provider": "BS OF NORTHEASTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 297,
        "2024": 297,
        "id": "recIAFyYGzkw5Heo3",
        "row": 11423,
        "state": "FLORIDA",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "recJPjFyEI98m9s6h",
        "row": 11460,
        "state": "FLORIDA",
        "group": "JKY038M013",
        "group_num": [
            "recwuV0EdCQmtAOtu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "recJbUPJEm386kJN4",
        "row": 11451,
        "state": "FLORIDA",
        "group": "JKY038M013",
        "group_num": [
            "recwuV0EdCQmtAOtu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "rece1yF8Aedbu4XJY",
        "row": 11491,
        "state": "FLORIDA",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3231,
        "2024": 0,
        "id": "recjI8umuSQIGV2Mz",
        "row": 11477,
        "state": "FLORIDA",
        "group": "284932M007",
        "group_num": [
            "recEJbFvISlGKv69e"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3134,
        "2024": 0,
        "id": "reclfa77XD8cBLWPN",
        "row": 11480,
        "state": "FLORIDA",
        "group": "2130661EIB",
        "group_num": [
            "recYWa1kWKw4siQi1"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3135,
        "2024": 0,
        "id": "recO3QGK8u5sfrCkU",
        "row": 11479,
        "state": "FLORIDA",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recYxEQd2evtImNIl",
        "row": 11490,
        "state": "FLORIDA",
        "group": "276945M002",
        "group_num": [
            "rec0102jUeiNjdHFr"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2757,
        "2024": 0,
        "id": "rec3hetCmieZwgbBx",
        "row": 11520,
        "state": "FLORIDA",
        "group": "277727M357",
        "group_num": [
            "recqmhnHvrCoXwjSg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2800,
        "2024": 0,
        "id": "recpRMTIxF1XWPO2g",
        "row": 11517,
        "state": "FLORIDA",
        "group": "277727J659",
        "group_num": [
            "rec8FXjzBsliwH5nw"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2746,
        "2024": 0,
        "id": "rectklu56q6r5y7ju",
        "row": 11521,
        "state": "FLORIDA",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recfkw9gDURCbKOoM",
        "row": 11542,
        "state": "FLORIDA",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recPrjMzhm0IeS9cM",
        "row": 11543,
        "state": "FLORIDA",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2479,
        "2024": 0,
        "id": "rec2zX8mmEo2DD6T7",
        "row": 11535,
        "state": "FLORIDA",
        "group": "0095867S02",
        "group_num": [
            "recQ2SJDjzqqalTlu"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2204,
        "2024": 0,
        "id": "rec9PGG2yCmWR0Yie",
        "row": 11568,
        "state": "FLORIDA",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2269,
        "2024": 0,
        "id": "rectCBf7yPhh1fp3Z",
        "row": 11565,
        "state": "FLORIDA",
        "group": "002349912",
        "group_num": [
            "recNVqbS75uRcZvWP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3758,
        "2023": 2324,
        "2024": 0,
        "id": "recu9YYofTdCse1UQ",
        "row": 11559,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "reckq4uksftVtyb1i",
        "row": 11610,
        "state": "FLORIDA",
        "group": "10486230",
        "group_num": [
            "recveaN1NihnZlTGD"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "recQfEoexD1rXtRWE",
        "row": 11608,
        "state": "FLORIDA",
        "group": "01067400",
        "group_num": [
            "recysPjB0SjPsUYYg"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1985,
        "2024": 0,
        "id": "recRTalY9m3UShnDl",
        "row": 11596,
        "state": "FLORIDA",
        "group": "01742600",
        "group_num": [
            "rec89COjBzo3y95Oo"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "recSMho34x8qAJE7h",
        "row": 11611,
        "state": "FLORIDA",
        "group": "174566MFA1",
        "group_num": [
            "rec56EWQ77Y5PtrXU"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recUY5bvPYvb5Vu8d",
        "row": 11592,
        "state": "FLORIDA",
        "group": "005831",
        "group_num": [
            "rec2LDV8m3AE8c3Ui"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1999,
        "2024": 0,
        "id": "recUyNhsPqYI2ckby",
        "row": 11594,
        "state": "FLORIDA",
        "group": "00760530100",
        "group_num": [
            "recofsMDBNZNiQICi"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1803,
        "2024": 0,
        "id": "recVOTOXOt95aftpd",
        "row": 11618,
        "state": "FLORIDA",
        "group": "10484606",
        "group_num": [
            "recGOQLKpvF65Xqx0"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5151,
        "2023": 1869,
        "2024": 0,
        "id": "recWGjcV1zHCZjmWx",
        "row": 11605,
        "state": "FLORIDA",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1944,
        "2024": 0,
        "id": "recc6vZiWxeeTq3gG",
        "row": 11599,
        "state": "FLORIDA",
        "group": "L01359M002",
        "group_num": [
            "recFhGf0cLnyvnmeV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recrwpxIJ4VW3Uq10",
        "row": 11588,
        "state": "FLORIDA",
        "group": "174587A1AA",
        "group_num": [
            "recPhX7YNj0Wp33ps"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1907,
        "2024": 0,
        "id": "recstJodRipkb0Jsq",
        "row": 11603,
        "state": "FLORIDA",
        "group": "174539N2A1",
        "group_num": [
            "rec9jfHdhVJvBOD7D"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2167,
        "2024": 0,
        "id": "recsiuoDovxwSBrsi",
        "row": 11574,
        "state": "FLORIDA",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1888,
        "2024": 0,
        "id": "recyJgGmegx6T5l6N",
        "row": 11604,
        "state": "FLORIDA",
        "group": "212085P1F2",
        "group_num": [
            "recWiMQR2f7LeFSqC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1818,
        "2024": 0,
        "id": "recygyXswbnOVWm3e",
        "row": 11616,
        "state": "FLORIDA",
        "group": "10662838",
        "group_num": [
            "recKUTDU92zyEPrKf"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1805,
        "2024": 0,
        "id": "recC88dq59jGW3P6l",
        "row": 11617,
        "state": "FLORIDA",
        "group": "174587A1AA",
        "group_num": [
            "recPhX7YNj0Wp33ps"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1799,
        "2024": 0,
        "id": "recJYuBNtNlFQDFUL",
        "row": 11620,
        "state": "FLORIDA",
        "group": "10594915",
        "group_num": [
            "recLTVO0eDeztzHA1"
        ],
        "procedure": "RTC",
        "provider": "BS OF NORTHEASTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2060,
        "2024": 0,
        "id": "recKZ3l9KUX2EQ0vb",
        "row": 11581,
        "state": "FLORIDA",
        "group": "282505M003",
        "group_num": [
            "reckSXTwSPysXmVK4"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "recK5ohJ6xurPBlCt",
        "row": 11607,
        "state": "FLORIDA",
        "group": "OH2637M003",
        "group_num": [
            "rec7jVPHeyQ7Saftp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "recLSfElZW3FwWMjt",
        "row": 11609,
        "state": "FLORIDA",
        "group": "05034600",
        "group_num": [
            "recIcVGgJK4S1fYCL"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1838,
        "2024": 0,
        "id": "recL4dLu6pTovQSt1",
        "row": 11614,
        "state": "FLORIDA",
        "group": "PH3533",
        "group_num": [
            "rec0WfJq6KTD74mE4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2098,
        "2024": 0,
        "id": "recL8NFoE3dGDYKqr",
        "row": 11580,
        "state": "FLORIDA",
        "group": "01742600",
        "group_num": [
            "rec89COjBzo3y95Oo"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1970,
        "2024": 0,
        "id": "recMjRRwsdRnlyYY1",
        "row": 11597,
        "state": "FLORIDA",
        "group": "10594915",
        "group_num": [
            "recLTVO0eDeztzHA1"
        ],
        "procedure": "Detox",
        "provider": "BS OF NORTHEASTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1795,
        "2024": 0,
        "id": "recMrHVjef51znmJk",
        "row": 11621,
        "state": "FLORIDA",
        "group": "174539N2A1",
        "group_num": [
            "rec9jfHdhVJvBOD7D"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1869,
        "2024": 0,
        "id": "recN0rzABDkpdUNhl",
        "row": 11606,
        "state": "FLORIDA",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1697,
        "2024": 0,
        "id": "receNz07tgUy6ju5H",
        "row": 11643,
        "state": "FLORIDA",
        "group": "10486230",
        "group_num": [
            "recveaN1NihnZlTGD"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1773,
        "2024": 0,
        "id": "recfNseECzpITSOGG",
        "row": 11627,
        "state": "FLORIDA",
        "group": "0A319BM001",
        "group_num": [
            "rec5qWRNpw0ftD2bu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1517,
        "2024": 0,
        "id": "recll5eoIW8KrSGxY",
        "row": 11668,
        "state": "FLORIDA",
        "group": "W51070M001",
        "group_num": [
            "recG6PLHDVguAREQg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1516,
        "2024": 0,
        "id": "recmk6cSVnczWE5aW",
        "row": 11669,
        "state": "FLORIDA",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1682,
        "2024": 0,
        "id": "recOCcjJ5aHQ578qG",
        "row": 11646,
        "state": "FLORIDA",
        "group": "270181G3A1",
        "group_num": [
            "recHNl4nDPROBSnnP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1753,
        "2024": 0,
        "id": "recPYJCwCN1hXnx9k",
        "row": 11630,
        "state": "FLORIDA",
        "group": "10743700",
        "group_num": [
            "recMzxJoEuLY21HGc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1694,
        "2024": 0,
        "id": "recSXjRGoXQFXdCR5",
        "row": 11644,
        "state": "FLORIDA",
        "group": "OH2637M003",
        "group_num": [
            "rec7jVPHeyQ7Saftp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1727,
        "2024": 0,
        "id": "recSfvuThLODR2oW7",
        "row": 11635,
        "state": "FLORIDA",
        "group": "05034600",
        "group_num": [
            "recIcVGgJK4S1fYCL"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1562,
        "2024": 0,
        "id": "recTKiRmJ37kHlhuP",
        "row": 11660,
        "state": "FLORIDA",
        "group": "801266M118",
        "group_num": [
            "recsZgKGJJGxFxmMv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1655,
        "2024": 0,
        "id": "recUIhJRE9aqvvPN9",
        "row": 11653,
        "state": "FLORIDA",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1563,
        "2024": 0,
        "id": "recWBx9v7S9uil69h",
        "row": 11659,
        "state": "FLORIDA",
        "group": "282505M003",
        "group_num": [
            "reckSXTwSPysXmVK4"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1517,
        "2024": 0,
        "id": "recY6WL5t2dgpw2dC",
        "row": 11667,
        "state": "FLORIDA",
        "group": "GA7787M003",
        "group_num": [
            "recfGFFa6jleks3qA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1601,
        "2024": 0,
        "id": "recYdygVhvV01xk3E",
        "row": 11658,
        "state": "FLORIDA",
        "group": "01745502",
        "group_num": [
            "recVqfCaO5TalyX2A"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1666,
        "2024": 0,
        "id": "recZTrD9CzHhl83mr",
        "row": 11650,
        "state": "FLORIDA",
        "group": "174258M1AF",
        "group_num": [
            "reckRTHGT36w2oAVW"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1556,
        "2024": 0,
        "id": "rec1b8FXGim5A9dBG",
        "row": 11662,
        "state": "FLORIDA",
        "group": "174592D3NA",
        "group_num": [
            "recHGadvLA8iXMEpM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1694,
        "2024": 0,
        "id": "rec8FkfMIadxRpu6n",
        "row": 11645,
        "state": "FLORIDA",
        "group": "174022MJAD",
        "group_num": [
            "reclhMuZz1OXc0vum"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1697,
        "2024": 0,
        "id": "recclnuexn7tNOrbQ",
        "row": 11642,
        "state": "FLORIDA",
        "group": "174566MFA1",
        "group_num": [
            "rec56EWQ77Y5PtrXU"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1782,
        "2024": 0,
        "id": "recobdLbOm7NTC7DC",
        "row": 11625,
        "state": "FLORIDA",
        "group": "002349912",
        "group_num": [
            "recNVqbS75uRcZvWP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1790,
        "2024": 0,
        "id": "recpzwNqkHIgnxUCH",
        "row": 11624,
        "state": "FLORIDA",
        "group": "212085P1F2",
        "group_num": [
            "recWiMQR2f7LeFSqC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1505,
        "2024": 0,
        "id": "recqifAGiApUS2Lst",
        "row": 11671,
        "state": "FLORIDA",
        "group": "174592D3NA",
        "group_num": [
            "recHGadvLA8iXMEpM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1772,
        "2024": 0,
        "id": "rectK63aVIJiWULr7",
        "row": 11628,
        "state": "FLORIDA",
        "group": "01620502",
        "group_num": [
            "recSaZwng7YRNihZZ"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1666,
        "2024": 0,
        "id": "recuFf6bBtaFAFdhQ",
        "row": 11651,
        "state": "FLORIDA",
        "group": "005801706DC10240",
        "group_num": [
            "rec8HtXDWIYEqjIIu"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1724,
        "2024": 0,
        "id": "recuRCr113eAOMc1F",
        "row": 11636,
        "state": "FLORIDA",
        "group": "270181G3A1",
        "group_num": [
            "recHNl4nDPROBSnnP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1750,
        "2024": 0,
        "id": "recBI3XSz1qr8h9zs",
        "row": 11631,
        "state": "FLORIDA",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1556,
        "2024": 0,
        "id": "recCSjvb5Dk8HbFBz",
        "row": 11661,
        "state": "FLORIDA",
        "group": "01745502",
        "group_num": [
            "recVqfCaO5TalyX2A"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1706,
        "2024": 0,
        "id": "recFOw20mLx1stK0k",
        "row": 11639,
        "state": "FLORIDA",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1701,
        "2024": 0,
        "id": "recLCVJ7nklYbdftC",
        "row": 11641,
        "state": "FLORIDA",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1125,
        "2024": 0,
        "id": "recjKfnTfrXDVewrh",
        "row": 11713,
        "state": "FLORIDA",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1265,
        "2024": 0,
        "id": "recPaAKwNBMyufEAR",
        "row": 11698,
        "state": "FLORIDA",
        "group": "14174677",
        "group_num": [
            "recDRJjvF6a8AaKak"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1469,
        "2024": 0,
        "id": "recQpYub6IIdMcMH2",
        "row": 11679,
        "state": "FLORIDA",
        "group": "801266M118",
        "group_num": [
            "recsZgKGJJGxFxmMv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1346,
        "2024": 0,
        "id": "recSPf1hPD9MYNk6b",
        "row": 11691,
        "state": "FLORIDA",
        "group": "W51070M001",
        "group_num": [
            "recG6PLHDVguAREQg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1362,
        "2024": 0,
        "id": "recV2ViSbLAWrBP5F",
        "row": 11690,
        "state": "FLORIDA",
        "group": "01067400",
        "group_num": [
            "recysPjB0SjPsUYYg"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1500,
        "2024": 0,
        "id": "recYBPEiBaDRjIdrX",
        "row": 11674,
        "state": "FLORIDA",
        "group": "005831",
        "group_num": [
            "rec2LDV8m3AE8c3Ui"
        ],
        "procedure": "PHP",
        "provider": "INDEPENDENCE ADMINISTRATORS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1340,
        "2024": 0,
        "id": "rec1sZFaRyDnztR5S",
        "row": 11692,
        "state": "FLORIDA",
        "group": "GA7787M003",
        "group_num": [
            "recfGFFa6jleks3qA"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1375,
        "2024": 0,
        "id": "recaNd1zEtdQHw5MV",
        "row": 11685,
        "state": "FLORIDA",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1164,
        "2024": 0,
        "id": "recbMEyNjOPp3NiMd",
        "row": 11708,
        "state": "FLORIDA",
        "group": "006955578",
        "group_num": [
            "recM3ps5JoefEF4Dv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1248,
        "2024": 0,
        "id": "recbqahRiCM2WuQ1a",
        "row": 11702,
        "state": "FLORIDA",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1315,
        "2024": 0,
        "id": "recoMDTb8wdGNzVXV",
        "row": 11694,
        "state": "FLORIDA",
        "group": "201045A810",
        "group_num": [
            "recum7CWwDJLBtdy0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1374,
        "2024": 0,
        "id": "recv9OCZiT0YdnHgX",
        "row": 11687,
        "state": "FLORIDA",
        "group": "270142M1AF",
        "group_num": [
            "recTYDhtui02DJpP6"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 988,
        "2023": 1205,
        "2024": 0,
        "id": "recyxUonfgvvh6jQW",
        "row": 11704,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 873,
        "2023": 1292,
        "2024": 0,
        "id": "recyNj7BICjc24suD",
        "row": 11697,
        "state": "FLORIDA",
        "group": "F6D",
        "group_num": [
            "reciqV0knLTr3XjJo"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1309,
        "2023": 1065,
        "2024": 0,
        "id": "recykaHDEN9NelVnR",
        "row": 11717,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1374,
        "2024": 0,
        "id": "recz0RxWrmM8bY9mU",
        "row": 11688,
        "state": "FLORIDA",
        "group": "000010M018",
        "group_num": [
            "rec1NHRcBOkZsjbZ9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 830,
        "2023": 1393,
        "2024": 0,
        "id": "recCRn6kCXLyMW3qL",
        "row": 11683,
        "state": "FLORIDA",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1219,
        "2024": 0,
        "id": "recDIOOWs5YXT7rJa",
        "row": 11703,
        "state": "FLORIDA",
        "group": "GB0192M003",
        "group_num": [
            "recJxsKQ9x9qIbHCn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1374,
        "2024": 0,
        "id": "recMvf0oJoWHyBmNf",
        "row": 11686,
        "state": "FLORIDA",
        "group": "10484606",
        "group_num": [
            "recGOQLKpvF65Xqx0"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recgpQ8lJa3BczF1C",
        "row": 11783,
        "state": "FLORIDA",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rechz0wHvVnEbUjP3",
        "row": 11796,
        "state": "FLORIDA",
        "group": "99999UU1",
        "group_num": [
            "recra9SioGbReFbfe"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rechgKjxikYLaetlK",
        "row": 11780,
        "state": "FLORIDA",
        "group": "002349912",
        "group_num": [
            "recNVqbS75uRcZvWP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 952,
        "2024": 0,
        "id": "recjUTaTVsboVTzU4",
        "row": 11792,
        "state": "FLORIDA",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recjug5yyByMlLDLr",
        "row": 11769,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF SD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recjJ7P5lkbIU0YWh",
        "row": 11787,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "reckZVm8z9IdEnQ1m",
        "row": 11727,
        "state": "FLORIDA",
        "group": "10040000",
        "group_num": [
            "recXQ4zraykbMB70Q"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "reclWFm0fQGkBcy06",
        "row": 11778,
        "state": "FLORIDA",
        "group": "280509S206",
        "group_num": [
            "recHB3EXjrPbTzptg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 951,
        "2024": 0,
        "id": "recl0rEnxCRFpVdAA",
        "row": 11798,
        "state": "FLORIDA",
        "group": "93BA11310",
        "group_num": [
            "rec9Lm0zLX3OIP9vc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "reclKPZbwDBmyhRKC",
        "row": 11758,
        "state": "FLORIDA",
        "group": "280509S206",
        "group_num": [
            "recHB3EXjrPbTzptg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recmTwI2HQ2WyURfg",
        "row": 11725,
        "state": "FLORIDA",
        "group": "99999Z7F",
        "group_num": [
            "recNrzKrP44KavcLc"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recmDaA3WdnHcEFOR",
        "row": 11789,
        "state": "FLORIDA",
        "group": "10662838",
        "group_num": [
            "recKUTDU92zyEPrKf"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "recn8Rg2FdQAoTSC4",
        "row": 11752,
        "state": "FLORIDA",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recngdlh3SBOkG34c",
        "row": 11737,
        "state": "FLORIDA",
        "group": "10711510",
        "group_num": [
            "rec0UbzIRe1V54T43"
        ],
        "procedure": "RTC",
        "provider": "GEHA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recnmKzoIRNdkkyIQ",
        "row": 11765,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recOw6dqlEgLaj3FX",
        "row": 11753,
        "state": "FLORIDA",
        "group": "W32168M005",
        "group_num": [
            "recTao1MCJoMinas5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recPEkBQKjgklk5JC",
        "row": 11762,
        "state": "FLORIDA",
        "group": "0B5KC3M039",
        "group_num": [
            "reckeRR2TqJxHOUUe"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recP46Ubddbpb4mGf",
        "row": 11779,
        "state": "FLORIDA",
        "group": "GA6085M001",
        "group_num": [
            "recZhx2kLdxmYMy8j"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 964,
        "2024": 0,
        "id": "recQ4G6Gdnb878fq5",
        "row": 11743,
        "state": "FLORIDA",
        "group": "D0975002",
        "group_num": [
            "recjIhplwdRYevZvr"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recSBg7ktc6n5BfX0",
        "row": 11781,
        "state": "FLORIDA",
        "group": "018430",
        "group_num": [
            "recxAHkFRSiODwvu6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 973,
        "2024": 0,
        "id": "recTOV5iJ9TDz61pO",
        "row": 11742,
        "state": "FLORIDA",
        "group": "10353824",
        "group_num": [
            "recFGR0wt8U1GZjlp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF ND (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recU1loYTatCm1du4",
        "row": 11797,
        "state": "FLORIDA",
        "group": "50625",
        "group_num": [
            "rec0rbKIVwPhxX3UV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF VT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recUDP0bNV2RH0CuZ",
        "row": 11726,
        "state": "FLORIDA",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recUFOgiighZrbrhg",
        "row": 11790,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recUI4qL6UVmefwpr",
        "row": 11760,
        "state": "FLORIDA",
        "group": "W32168M005",
        "group_num": [
            "recTao1MCJoMinas5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recU8MAhryR90HjKc",
        "row": 11750,
        "state": "FLORIDA",
        "group": "99K1",
        "group_num": [
            "rec7cekqMFmH11Xgr"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recVwpnDwLcfM8Kkj",
        "row": 11728,
        "state": "FLORIDA",
        "group": "10711462",
        "group_num": [
            "rec2pkor5yDgxzTu5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recVkULtw44GYdtzL",
        "row": 11754,
        "state": "FLORIDA",
        "group": "130499",
        "group_num": [
            "recBI5M4GaAn73z9X"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recW9rBDG9udojPaj",
        "row": 11766,
        "state": "FLORIDA",
        "group": "131937",
        "group_num": [
            "rec94tr6uA0WSEhmK"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recXcYjhB41jOOe0z",
        "row": 11795,
        "state": "FLORIDA",
        "group": "716070718",
        "group_num": [
            "recwQJXjYUUXWZFd8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 890,
        "2023": 952,
        "2024": 0,
        "id": "recY9vhf8sKyhZ1la",
        "row": 11748,
        "state": "FLORIDA",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recZuSU3BXitEGkav",
        "row": 11788,
        "state": "FLORIDA",
        "group": "82333",
        "group_num": [
            "recayaMyEfJpiAiZx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec0oRpEMqA39hNdR",
        "row": 11791,
        "state": "FLORIDA",
        "group": "813950001",
        "group_num": [
            "recBhngXh3msh9prQ"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec1YaRri2YnI8TKR",
        "row": 11755,
        "state": "FLORIDA",
        "group": "130499",
        "group_num": [
            "recBI5M4GaAn73z9X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec2Cq9Vd95yl4Y1U",
        "row": 11763,
        "state": "FLORIDA",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec4rcPKsIdUqFBgV",
        "row": 11772,
        "state": "FLORIDA",
        "group": "T7367AMA03",
        "group_num": [
            "recNk0P61LsaE2bTI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 985,
        "2024": 0,
        "id": "rec6CK7EAtULQLIDT",
        "row": 11736,
        "state": "FLORIDA",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec7YI43VYCOBmEj8",
        "row": 11784,
        "state": "FLORIDA",
        "group": "139138",
        "group_num": [
            "recvG7kmT36zZ5CC6"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec7QQOloQmx4MVte",
        "row": 11756,
        "state": "FLORIDA",
        "group": "99999ZC1",
        "group_num": [
            "recBXyYyPv9dER1Ke"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "rec9FKO6m7Ll33i85",
        "row": 11771,
        "state": "FLORIDA",
        "group": "L00367000H",
        "group_num": [
            "recmCLDKjRY6GDCwZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "reccAcU8OkWfbp1Q5",
        "row": 11776,
        "state": "FLORIDA",
        "group": "10647445",
        "group_num": [
            "reciTnkUeX4cuW7Cy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 942,
        "2024": 0,
        "id": "recd1TG8dTVTYd3dr",
        "row": 11800,
        "state": "FLORIDA",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 962,
        "2024": 0,
        "id": "recr1tXjLWpFdbQBz",
        "row": 11744,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recrjp5mlZBVvGffY",
        "row": 11757,
        "state": "FLORIDA",
        "group": "824570000",
        "group_num": [
            "reciuB8WfWemwMZJZ"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recuV8opLr58oc8DS",
        "row": 11764,
        "state": "FLORIDA",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recwIDGQBzZO7R0xp",
        "row": 11785,
        "state": "FLORIDA",
        "group": "139138",
        "group_num": [
            "recvG7kmT36zZ5CC6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recwLYWtS9Dr42Jt1",
        "row": 11767,
        "state": "FLORIDA",
        "group": "10647445",
        "group_num": [
            "reciTnkUeX4cuW7Cy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recxS2cmp8LnbyvFZ",
        "row": 11739,
        "state": "FLORIDA",
        "group": "PC3955",
        "group_num": [
            "recdO3tndnMB60EAy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recxKuc54w9ahKNa4",
        "row": 11775,
        "state": "FLORIDA",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recym2lxpdsdOqBDy",
        "row": 11732,
        "state": "FLORIDA",
        "group": "99999ZSB",
        "group_num": [
            "recid127QSeBvRceC"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 954,
        "2024": 0,
        "id": "reczwZYSRTnGaTm5g",
        "row": 11747,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "reczhLpkXarJ8n7LW",
        "row": 11759,
        "state": "FLORIDA",
        "group": "GA6085M001",
        "group_num": [
            "recZhx2kLdxmYMy8j"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recA4EMr9S4ChoAGs",
        "row": 11777,
        "state": "FLORIDA",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 539,
        "2023": 958,
        "2024": 0,
        "id": "recA8fUaTRXP3AlXo",
        "row": 11745,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 981,
        "2024": 0,
        "id": "recCNr47wqoOJxors",
        "row": 11740,
        "state": "FLORIDA",
        "group": "284932M007",
        "group_num": [
            "recEJbFvISlGKv69e"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recCjmAutqmIsuaGi",
        "row": 11733,
        "state": "FLORIDA",
        "group": "D0459006",
        "group_num": [
            "recnCZpiCxUapizLS"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "2024": 0,
        "id": "recDWKAmFEbQMMzVu",
        "row": 11794,
        "state": "FLORIDA",
        "group": "24326001",
        "group_num": [
            "recXGdeHtetDtflIH"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recDuUPUSad8c3WZf",
        "row": 11819,
        "state": "FLORIDA",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recDLicBLFZkTo69p",
        "row": 11731,
        "state": "FLORIDA",
        "group": "300601B096",
        "group_num": [
            "receheJzSmHJcyvHG"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 985,
        "2024": 0,
        "id": "recDOZvCdTy9BHrBn",
        "row": 11734,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recEU54OtPlYrgTVQ",
        "row": 11782,
        "state": "FLORIDA",
        "group": "Y06940",
        "group_num": [
            "recqCHK79t8u2gmqU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 952,
        "2024": 0,
        "id": "recG4wmf1qY3ILzOm",
        "row": 11770,
        "state": "FLORIDA",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recIuhBkXTRKP39hk",
        "row": 11773,
        "state": "FLORIDA",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recJS7z948Rxj43qU",
        "row": 11768,
        "state": "FLORIDA",
        "group": "824570000",
        "group_num": [
            "reciuB8WfWemwMZJZ"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF SD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recJxum5aKl0Irj6K",
        "row": 11751,
        "state": "FLORIDA",
        "group": "60856003",
        "group_num": [
            "recJZFQXHxN09jmkk"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 867,
        "2023": 952,
        "2024": 0,
        "id": "recKAqq1eoeXWKhEW",
        "row": 11793,
        "state": "FLORIDA",
        "group": "99999UBU",
        "group_num": [
            "recmN33L2fBTU1OBT"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recKO8vt7LT7Chynx",
        "row": 11761,
        "state": "FLORIDA",
        "group": "0B5KC3M039",
        "group_num": [
            "reckeRR2TqJxHOUUe"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recLue0671tfdx9fp",
        "row": 11729,
        "state": "FLORIDA",
        "group": "W8002426",
        "group_num": [
            "recAAX7CCBHOq5PSc"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 613,
        "2023": 985,
        "2024": 0,
        "id": "recNVjsKhRUJA9NRf",
        "row": 11738,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recNVVEdPaXk1BUIh",
        "row": 11774,
        "state": "FLORIDA",
        "group": "82333",
        "group_num": [
            "recayaMyEfJpiAiZx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recNMAuN0WbVvLN0m",
        "row": 11749,
        "state": "FLORIDA",
        "group": "W0051486",
        "group_num": [
            "reclqbmSM4sA7lhJm"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recNNMsZJ5mOxQbM7",
        "row": 11730,
        "state": "FLORIDA",
        "group": "85000000",
        "group_num": [
            "recgzMoaz0YGSBSwM"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "2024": 0,
        "id": "recNRDg5bPUfServE",
        "row": 11735,
        "state": "FLORIDA",
        "group": "300601B096",
        "group_num": [
            "receheJzSmHJcyvHG"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 829,
        "2024": 0,
        "id": "recifNWKlCoelBehH",
        "row": 11846,
        "state": "FLORIDA",
        "group": "14174677",
        "group_num": [
            "recDRJjvF6a8AaKak"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 829,
        "2024": 0,
        "id": "recO8yYlnpUkXeuvS",
        "row": 11843,
        "state": "FLORIDA",
        "group": "336072",
        "group_num": [
            "recXGXu64vRUCzsgE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 829,
        "2024": 0,
        "id": "recVGnv26YtgxxAWy",
        "row": 11845,
        "state": "FLORIDA",
        "group": "14162738",
        "group_num": [
            "recCT3lVsti6qhoBp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 825,
        "2024": 0,
        "id": "recYKeCVTOjYE7Fhk",
        "row": 11847,
        "state": "FLORIDA",
        "group": "GB0192M003",
        "group_num": [
            "recJxsKQ9x9qIbHCn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 672,
        "2023": 874,
        "2024": 0,
        "id": "recZYbylU5DI6iezV",
        "row": 11834,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 829,
        "2024": 0,
        "id": "recZAHFHJPmQlhroe",
        "row": 11844,
        "state": "FLORIDA",
        "group": "14162444",
        "group_num": [
            "recYq9cIK1qxeLu02"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 819,
        "2024": 0,
        "id": "rec5u6ii4n3h0QkjP",
        "row": 11850,
        "state": "FLORIDA",
        "group": "85000000",
        "group_num": [
            "recgzMoaz0YGSBSwM"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 840,
        "2024": 0,
        "id": "recMZgdmMhOcqKlUY",
        "row": 11837,
        "state": "FLORIDA",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 552,
        "2024": 0,
        "id": "rech2aS5lPgyKYESm",
        "row": 11918,
        "state": "FLORIDA",
        "group": "174258M1AF",
        "group_num": [
            "reckRTHGT36w2oAVW"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 545,
        "2024": 0,
        "id": "recilOsLSp8ZoB1Mn",
        "row": 11920,
        "state": "FLORIDA",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 560,
        "2024": 0,
        "id": "recmupX5amhSAGWld",
        "row": 11909,
        "state": "FLORIDA",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 623,
        "2024": 0,
        "id": "recnaoHZ0z02aSX3J",
        "row": 11878,
        "state": "FLORIDA",
        "group": "118563",
        "group_num": [
            "recpc7QnRvCHR8wqp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 552,
        "2024": 0,
        "id": "recs3fQuNNj5GAkhu",
        "row": 11917,
        "state": "FLORIDA",
        "group": "174022MJAD",
        "group_num": [
            "reclhMuZz1OXc0vum"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 560,
        "2024": 0,
        "id": "recCMDEFzM9EJH6xf",
        "row": 11907,
        "state": "FLORIDA",
        "group": "0A319BM001",
        "group_num": [
            "rec5qWRNpw0ftD2bu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recJuKGBBp7TWsHj4",
        "row": 11891,
        "state": "FLORIDA",
        "group": "10706853",
        "group_num": [
            "recNchzbUw7t0hfHS"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "receSVIoHkDFYMWHo",
        "row": 11936,
        "state": "FLORIDA",
        "group": "282919",
        "group_num": [
            "recc7Pn3xbzUOu5IH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 493,
        "2024": 0,
        "id": "rechYTuBUSkpmIxc8",
        "row": 11970,
        "state": "FLORIDA",
        "group": "PC3955",
        "group_num": [
            "recdO3tndnMB60EAy"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "reciyw1LdYo0hspvE",
        "row": 11934,
        "state": "FLORIDA",
        "group": "221068",
        "group_num": [
            "reczroQzyv16m4eTG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "reckTjLPBCWTZAMPr",
        "row": 11935,
        "state": "FLORIDA",
        "group": "282919",
        "group_num": [
            "recc7Pn3xbzUOu5IH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recPSUpW6d6v5qCti",
        "row": 11941,
        "state": "FLORIDA",
        "group": "P44052",
        "group_num": [
            "reclCnvfERXvFESxE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recQVlr7eJ3pC0Bit",
        "row": 11938,
        "state": "FLORIDA",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 493,
        "2024": 0,
        "id": "recQB6rbADRqrTvND",
        "row": 11968,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 536,
        "2024": 0,
        "id": "recSYQLdOGF5yLZOl",
        "row": 11924,
        "state": "FLORIDA",
        "group": "270082N1ED",
        "group_num": [
            "recQGxWZHdKy6QxlB"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recWFoxeHm9K8x5gw",
        "row": 11937,
        "state": "FLORIDA",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recYXVCynHwvs6oB1",
        "row": 11939,
        "state": "FLORIDA",
        "group": "P13666",
        "group_num": [
            "rec3jfkL8qWHnz9W9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 500,
        "2024": 0,
        "id": "rec0KVuu3ItJjZXW7",
        "row": 11966,
        "state": "FLORIDA",
        "group": "PH3533",
        "group_num": [
            "rec0WfJq6KTD74mE4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "2023": 493,
        "2024": 0,
        "id": "recsLCnr19v4Cez44",
        "row": 11969,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "rectg9H6zAa6oNWo1",
        "row": 11932,
        "state": "FLORIDA",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 518,
        "2024": 0,
        "id": "recvv05py1KTeeGDT",
        "row": 11959,
        "state": "FLORIDA",
        "group": "270163P1CA",
        "group_num": [
            "recHSMdNzAocQ1fQS"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recwKYGhqnOvPB7Vp",
        "row": 11940,
        "state": "FLORIDA",
        "group": "P44052",
        "group_num": [
            "reclCnvfERXvFESxE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 512,
        "2023": 525,
        "2024": 0,
        "id": "recCaY1mibepJNlXe",
        "row": 11931,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recD7qE8tj9oovplb",
        "row": 11942,
        "state": "FLORIDA",
        "group": "P99280",
        "group_num": [
            "recbZUgledVP1JvSy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 528,
        "2024": 0,
        "id": "recG1wIHYm6tYK0US",
        "row": 11926,
        "state": "FLORIDA",
        "group": "10713600",
        "group_num": [
            "recuW6ygLW4vYoTLe"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recIKE5YlIKlvRnEL",
        "row": 11933,
        "state": "FLORIDA",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 458,
        "2024": 0,
        "id": "recfhWUvvDXMAVbRt",
        "row": 12007,
        "state": "FLORIDA",
        "group": "800976M006",
        "group_num": [
            "rec2wvLnvIXr4kAoz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "rechLxHc3IBNZATPU",
        "row": 11990,
        "state": "FLORIDA",
        "group": "0B5KC3M039",
        "group_num": [
            "reckeRR2TqJxHOUUe"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recjSHlpH2nkgDu6C",
        "row": 11987,
        "state": "FLORIDA",
        "group": "139138",
        "group_num": [
            "recvG7kmT36zZ5CC6"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 473,
        "2024": 0,
        "id": "reckA4A18VAzrgh5B",
        "row": 11995,
        "state": "FLORIDA",
        "group": "266499",
        "group_num": [
            "recuPuSBUwXkqCNrf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 467,
        "2024": 0,
        "id": "recPD3w8YyBbSVaaz",
        "row": 11998,
        "state": "FLORIDA",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 355,
        "2023": 476,
        "2024": 0,
        "id": "recPh1VxFuzpoYAGI",
        "row": 11981,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recTL06c5meGbufA4",
        "row": 11985,
        "state": "FLORIDA",
        "group": "T7367AMA03",
        "group_num": [
            "recNk0P61LsaE2bTI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recT6JeKK1kgpS4FE",
        "row": 11984,
        "state": "FLORIDA",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recWaGaN4lg1Mh9ev",
        "row": 11992,
        "state": "FLORIDA",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recYsNNzyAyr1RCRe",
        "row": 11991,
        "state": "FLORIDA",
        "group": "813950001",
        "group_num": [
            "recBhngXh3msh9prQ"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF SD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recZPQY0wOkoXJRoJ",
        "row": 11986,
        "state": "FLORIDA",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 473,
        "2024": 0,
        "id": "rec4V9k9tDGUTTzcV",
        "row": 11994,
        "state": "FLORIDA",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "rec6QqRuFClmlIySN",
        "row": 11979,
        "state": "FLORIDA",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "rec7DicQGLW90jetw",
        "row": 11988,
        "state": "FLORIDA",
        "group": "284932M007",
        "group_num": [
            "recEJbFvISlGKv69e"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 473,
        "2024": 0,
        "id": "rec7Jft6Im6b00Nlh",
        "row": 11996,
        "state": "FLORIDA",
        "group": "300368",
        "group_num": [
            "recV5AzLM1jNxXEV5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "rec9T9NtWGSLUi6zn",
        "row": 11978,
        "state": "FLORIDA",
        "group": "P13666",
        "group_num": [
            "rec3jfkL8qWHnz9W9"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 481,
        "2024": 0,
        "id": "reccNOrdnyb8o2vpP",
        "row": 11976,
        "state": "FLORIDA",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 474,
        "2024": 0,
        "id": "recd8qNd7Sgdbtvd3",
        "row": 11993,
        "state": "FLORIDA",
        "group": "01745502",
        "group_num": [
            "recVqfCaO5TalyX2A"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recqSPEJ29r1Bk375",
        "row": 11980,
        "state": "FLORIDA",
        "group": "24326001",
        "group_num": [
            "recXGdeHtetDtflIH"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 481,
        "2024": 0,
        "id": "recvRyERPmW6p26R0",
        "row": 11975,
        "state": "FLORIDA",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 480,
        "2024": 0,
        "id": "reczMnFNab2sX99gp",
        "row": 11977,
        "state": "FLORIDA",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recDl5mynI2DhbpOF",
        "row": 11982,
        "state": "FLORIDA",
        "group": "282505M003",
        "group_num": [
            "reckSXTwSPysXmVK4"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recG9NcweOBnqi99i",
        "row": 11983,
        "state": "FLORIDA",
        "group": "6VM500",
        "group_num": [
            "recxnSDRoqTuX8Qky"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 476,
        "2024": 0,
        "id": "recGavSKBMF5nOoYm",
        "row": 11989,
        "state": "FLORIDA",
        "group": "W32168M005",
        "group_num": [
            "recTao1MCJoMinas5"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 466,
        "2024": 0,
        "id": "recK6MduAqFwkfnkX",
        "row": 12000,
        "state": "FLORIDA",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 345,
        "2023": 441,
        "2024": 0,
        "id": "rechaOEdB144RLfer",
        "row": 12029,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 314,
        "2023": 325,
        "2024": 0,
        "id": "reciKJlYmYQRxy1Yr",
        "row": 12069,
        "state": "FLORIDA",
        "group": "64763009",
        "group_num": [
            "rec2R6RcV7LHx5gwB"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 419,
        "2024": 0,
        "id": "recYQIuCyc5TgrCae",
        "row": 12050,
        "state": "FLORIDA",
        "group": "81150FF2",
        "group_num": [
            "recALkvSO3u8PRYKv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 316,
        "2023": 325,
        "2024": 0,
        "id": "rec1B7zQ7CkCbffWl",
        "row": 12070,
        "state": "FLORIDA",
        "group": "99999M22",
        "group_num": [
            "rec7DWeCNNpc58gd5"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 422,
        "2024": 0,
        "id": "rec2eeDulhh1eRBCD",
        "row": 12047,
        "state": "FLORIDA",
        "group": "355421",
        "group_num": [
            "recw1RZjtHy7airM0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 435,
        "2024": 0,
        "id": "rec98vpFvNGCtkcn6",
        "row": 12031,
        "state": "FLORIDA",
        "group": "37461000DM",
        "group_num": [
            "recbSO190dp2R70C1"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 408,
        "2024": 0,
        "id": "reccbyQrsgMRFv7rL",
        "row": 12052,
        "state": "FLORIDA",
        "group": "CIK361",
        "group_num": [
            "rec75Uhl6z8I0OuQu"
        ],
        "procedure": "PHP",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 422,
        "2024": 0,
        "id": "recdUh2IeoQFd1ZyQ",
        "row": 12049,
        "state": "FLORIDA",
        "group": "276165",
        "group_num": [
            "recufSbSmfORXQcWv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 435,
        "2024": 0,
        "id": "recdW1ysHEdhoHWW2",
        "row": 12034,
        "state": "FLORIDA",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF MO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 350,
        "2024": 0,
        "id": "recst39ANHK5Hbc8c",
        "row": 12059,
        "state": "FLORIDA",
        "group": "EG01100001",
        "group_num": [
            "recga4ga0Vp6PfYGQ"
        ],
        "procedure": "RTC",
        "provider": "BLUE ADVANTAGE ADMINISTRATORS OF AR (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 373,
        "2024": 0,
        "id": "rectqorHchCkSFKjx",
        "row": 12057,
        "state": "FLORIDA",
        "group": "85311FF2",
        "group_num": [
            "rec7uaD8yZUuIewbL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 325,
        "2024": 0,
        "id": "recuR1vFMTZKwXqnu",
        "row": 12071,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 353,
        "2023": 376,
        "2024": 0,
        "id": "recvdQg2BgRnJNS9C",
        "row": 12056,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 422,
        "2024": 0,
        "id": "recwVaTHKTv7THnmA",
        "row": 12048,
        "state": "FLORIDA",
        "group": "327006",
        "group_num": [
            "recChp7oiBqnd32G3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 435,
        "2024": 0,
        "id": "reczboNTfYPAWyJOU",
        "row": 12033,
        "state": "FLORIDA",
        "group": "000010M018",
        "group_num": [
            "rec1NHRcBOkZsjbZ9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 380,
        "2024": 0,
        "id": "recA8JfDI5haTHd9u",
        "row": 12055,
        "state": "FLORIDA",
        "group": "10425741",
        "group_num": [
            "rec2U5Z6BuABKCQgY"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 447,
        "2024": 0,
        "id": "recE6lBAVCFZuiRbb",
        "row": 12027,
        "state": "FLORIDA",
        "group": "10353824",
        "group_num": [
            "recFGR0wt8U1GZjlp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF ND (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 435,
        "2024": 0,
        "id": "recHAAzycy6vdkdmP",
        "row": 12032,
        "state": "FLORIDA",
        "group": "212085P1F2",
        "group_num": [
            "recWiMQR2f7LeFSqC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recebrM1ZuRYeJz7Y",
        "row": 12082,
        "state": "FLORIDA",
        "group": "14165233",
        "group_num": [
            "recYgtR2b3zOJv0X5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 317,
        "2023": 305,
        "2024": 0,
        "id": "recgdgu8x2BzX35zn",
        "row": 12102,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 303,
        "2024": 0,
        "id": "recgoWSQgc76EPGzL",
        "row": 12105,
        "state": "FLORIDA",
        "group": "270205A2A1",
        "group_num": [
            "recSPMQTLm8UmukEJ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 288,
        "2024": 0,
        "id": "reci4ut9k0d3HG97n",
        "row": 12119,
        "state": "FLORIDA",
        "group": "266499",
        "group_num": [
            "recuPuSBUwXkqCNrf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 313,
        "2024": 0,
        "id": "reciaJRG2TPNfWwWZ",
        "row": 12099,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recj0semI6IOrIqgv",
        "row": 12081,
        "state": "FLORIDA",
        "group": "0000717204500016",
        "group_num": [
            "recLI3oHwTNO4C02Q"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 325,
        "2024": 0,
        "id": "recjKxRnAd8Xb8VmD",
        "row": 12072,
        "state": "FLORIDA",
        "group": "15921",
        "group_num": [
            "receiSy2v0oLOkR2c"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recl41fEhcjsxDwRp",
        "row": 12093,
        "state": "FLORIDA",
        "group": "B4829002",
        "group_num": [
            "recPhoPb3xGuzr6rC"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "2023": 288,
        "2024": 0,
        "id": "reclmtA8licVoHqCu",
        "row": 12121,
        "state": "FLORIDA",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 313,
        "2024": 0,
        "id": "recQNTn7eKCi8iRRS",
        "row": 12100,
        "state": "FLORIDA",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recRAgX9P3aQXTGKl",
        "row": 12087,
        "state": "FLORIDA",
        "group": "99999U7F",
        "group_num": [
            "recxDBG1YoyE0YMWf"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "recRi2R0ZDOxe2Wv9",
        "row": 12111,
        "state": "FLORIDA",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recT6pCXX4ExQoD4C",
        "row": 12083,
        "state": "FLORIDA",
        "group": "716071917",
        "group_num": [
            "recH1UrPzJbfsyD2i"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 321,
        "2024": 0,
        "id": "recW201K5fV51fjAs",
        "row": 12097,
        "state": "FLORIDA",
        "group": "118563",
        "group_num": [
            "recpc7QnRvCHR8wqp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 303,
        "2024": 0,
        "id": "rec0NMxh094AJUmgV",
        "row": 12104,
        "state": "FLORIDA",
        "group": "15827005",
        "group_num": [
            "rec7CojY6hzThIc1H"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 297,
        "2024": 0,
        "id": "rec0ozKGlnvbSD9Fj",
        "row": 12115,
        "state": "FLORIDA",
        "group": "14162444",
        "group_num": [
            "recYq9cIK1qxeLu02"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec1mqukWHwY4UyDB",
        "row": 12077,
        "state": "FLORIDA",
        "group": "99999MYG",
        "group_num": [
            "recRqDyOAXdCwejBb"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec3RHN6h26vHz71H",
        "row": 12086,
        "state": "FLORIDA",
        "group": "99999MAY",
        "group_num": [
            "rec4zIsBx3THvVntv"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 325,
        "2024": 0,
        "id": "rec5x4EDXaQGgolqL",
        "row": 12076,
        "state": "FLORIDA",
        "group": "99999M53",
        "group_num": [
            "rechBoXWoifN43YMB"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec5rEDGjzyjEfufG",
        "row": 12079,
        "state": "FLORIDA",
        "group": "209615",
        "group_num": [
            "recOmyUkIpP9hvc0s"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 303,
        "2024": 0,
        "id": "rec6I497cKs6OSMC1",
        "row": 12103,
        "state": "FLORIDA",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec628huVkz7z1CWm",
        "row": 12085,
        "state": "FLORIDA",
        "group": "99999MAS",
        "group_num": [
            "recn3OToRUczn2OVB"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec7WIrLJ9rSksws5",
        "row": 12088,
        "state": "FLORIDA",
        "group": "99999UBU",
        "group_num": [
            "recmN33L2fBTU1OBT"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec8IR1qrFPxATmrD",
        "row": 12094,
        "state": "FLORIDA",
        "group": "J4541003",
        "group_num": [
            "recE346junztY09yz"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 253,
        "2023": 288,
        "2024": 0,
        "id": "rec9xaUfPQhYHvrUy",
        "row": 12120,
        "state": "FLORIDA",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "rec9FC92pEjqwcnyy",
        "row": 12089,
        "state": "FLORIDA",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recdKORHUsSxckAy7",
        "row": 12080,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "recog3ZRt4ueaKBG2",
        "row": 12112,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 325,
        "2024": 0,
        "id": "recpTHvvhu3FeWV3E",
        "row": 12075,
        "state": "FLORIDA",
        "group": "J8642003",
        "group_num": [
            "rec0TAV3ikh1TapXI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recpJ80K28hDrlmDC",
        "row": 12096,
        "state": "FLORIDA",
        "group": "778092",
        "group_num": [
            "recGfryiE3ttVDGDK"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 325,
        "2024": 0,
        "id": "recqqPvWVoUfhcBGJ",
        "row": 12074,
        "state": "FLORIDA",
        "group": "15921017",
        "group_num": [
            "recITmYJmPZvsjcXb"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 325,
        "2024": 0,
        "id": "recsJTvidraWUPtRT",
        "row": 12073,
        "state": "FLORIDA",
        "group": "99999UU3",
        "group_num": [
            "recdhsaDQH4NNda4F"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 297,
        "2024": 0,
        "id": "recuec46JJeCHitob",
        "row": 12116,
        "state": "FLORIDA",
        "group": "14174677",
        "group_num": [
            "recDRJjvF6a8AaKak"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recvazwVVJFkbX9vA",
        "row": 12090,
        "state": "FLORIDA",
        "group": "99999UFS",
        "group_num": [
            "recOT3skBN2yKcGmF"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recy8NzOEtOzkjP1K",
        "row": 12084,
        "state": "FLORIDA",
        "group": "D33277",
        "group_num": [
            "recB5jzs8FcSaL39W"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recCLDGqjZdnzW1Zn",
        "row": 12091,
        "state": "FLORIDA",
        "group": "16153003",
        "group_num": [
            "reccencxOIs8elUs0"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recFsWuE5qVb6TIFw",
        "row": 12095,
        "state": "FLORIDA",
        "group": "99999MAS",
        "group_num": [
            "recn3OToRUczn2OVB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recHCWURCjfadsMy0",
        "row": 12078,
        "state": "FLORIDA",
        "group": "GA8039H1CD",
        "group_num": [
            "rechm9hQcOVh9qiNi"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 289,
        "2024": 0,
        "id": "recK7vBkhS4xnz97V",
        "row": 12118,
        "state": "FLORIDA",
        "group": "D0975002",
        "group_num": [
            "recjIhplwdRYevZvr"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 325,
        "2024": 0,
        "id": "recMx3ha3zNqxNiDp",
        "row": 12092,
        "state": "FLORIDA",
        "group": "60856003",
        "group_num": [
            "recJZFQXHxN09jmkk"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 288,
        "2024": 0,
        "id": "recfcke3qwhSOWlIN",
        "row": 12123,
        "state": "FLORIDA",
        "group": "300368",
        "group_num": [
            "recV5AzLM1jNxXEV5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 219,
        "2024": 0,
        "id": "rechvjJvvknT8j2XK",
        "row": 12148,
        "state": "FLORIDA",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 240,
        "2023": 240,
        "2024": 0,
        "id": "recjOAkcPZrZeSbCz",
        "row": 12133,
        "state": "FLORIDA",
        "group": "4022154",
        "group_num": [
            "recAfJRy5kcwi87iv"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF AK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 218,
        "2024": 0,
        "id": "recmNQG5hdDbidSC8",
        "row": 12149,
        "state": "FLORIDA",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 240,
        "2024": 0,
        "id": "recOUVVEqVSesW96k",
        "row": 12134,
        "state": "FLORIDA",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "2023": 288,
        "2024": 0,
        "id": "recV9FITP5fltGrzd",
        "row": 12122,
        "state": "FLORIDA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 492,
        "2023": 211,
        "2024": 0,
        "id": "recXhcsol2BV1QjRq",
        "row": 12153,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 204,
        "2023": 198,
        "2024": 0,
        "id": "rec4pb2gmbxpQtfrd",
        "row": 12157,
        "state": "FLORIDA",
        "group": "289319",
        "group_num": [
            "recsjqmdAVJVE58vA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 238,
        "2024": 0,
        "id": "recbBDMSmm3Gwzl4K",
        "row": 12136,
        "state": "FLORIDA",
        "group": "Y06940",
        "group_num": [
            "recqCHK79t8u2gmqU"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 197,
        "2024": 0,
        "id": "recceuYv8NyMZBpVg",
        "row": 12159,
        "state": "FLORIDA",
        "group": "76871FF1",
        "group_num": [
            "recamFQ3pl3BAOFa0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 218,
        "2024": 0,
        "id": "recomOEG0Q4FGNVuB",
        "row": 12150,
        "state": "FLORIDA",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 285,
        "2024": 0,
        "id": "recpblHghYp7utx4n",
        "row": 12125,
        "state": "FLORIDA",
        "group": "35X02ERC",
        "group_num": [
            "rec4bElVqSsGFlePC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 228,
        "2024": 0,
        "id": "recrE710HMr3OwWhb",
        "row": 12141,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 241,
        "2024": 0,
        "id": "recsxWc1mA1RjBTP5",
        "row": 12132,
        "state": "FLORIDA",
        "group": "81150FF2",
        "group_num": [
            "recALkvSO3u8PRYKv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 219,
        "2024": 0,
        "id": "recsNyTRhZXRGzAAa",
        "row": 12146,
        "state": "FLORIDA",
        "group": "002375556",
        "group_num": [
            "recKhKaPy5Js97D6h"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 224,
        "2024": 0,
        "id": "recyBoYDnvb00GVKI",
        "row": 12144,
        "state": "FLORIDA",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "2023": 198,
        "2024": 0,
        "id": "recKFR12IC5L0KgWC",
        "row": 12158,
        "state": "FLORIDA",
        "group": "202153",
        "group_num": [
            "recw1eWkgz8H9hvbj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 231,
        "2024": 0,
        "id": "recLZbDuEDqZ7gMD2",
        "row": 12139,
        "state": "FLORIDA",
        "group": "35X02ERC",
        "group_num": [
            "rec4bElVqSsGFlePC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 175,
        "2024": 0,
        "id": "rechOzKUSoNAVqxdV",
        "row": 12178,
        "state": "FLORIDA",
        "group": "15921017",
        "group_num": [
            "recITmYJmPZvsjcXb"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "reciU5iPSENvRyZDr",
        "row": 12194,
        "state": "FLORIDA",
        "group": "51844005",
        "group_num": [
            "reccdl84JGJWut0gs"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 111,
        "2024": 0,
        "id": "reciPlij24u1volO5",
        "row": 12211,
        "state": "FLORIDA",
        "group": "F27588",
        "group_num": [
            "recmIlWV8raEbhQZK"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF NV (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "reckz83kr3IJL6L1k",
        "row": 12186,
        "state": "FLORIDA",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recmO0Jj62F6wktra",
        "row": 12197,
        "state": "FLORIDA",
        "group": "78358020",
        "group_num": [
            "recnWg73siEYdWxB1"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recn5aF7yZZnzDM5A",
        "row": 12200,
        "state": "FLORIDA",
        "group": "778092",
        "group_num": [
            "recGfryiE3ttVDGDK"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 171,
        "2024": 0,
        "id": "recO4ecoSye4mWG1p",
        "row": 12203,
        "state": "FLORIDA",
        "group": "99999UFS",
        "group_num": [
            "recOT3skBN2yKcGmF"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 167,
        "2024": 0,
        "id": "recPgaEFVSTqfMHtx",
        "row": 12204,
        "state": "FLORIDA",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recQ8HsPMLwrJBIEm",
        "row": 12196,
        "state": "FLORIDA",
        "group": "76444101",
        "group_num": [
            "recimOB4DMrFYiTz9"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 97,
        "2024": 0,
        "id": "recT59Z3AzKzWujzD",
        "row": 12212,
        "state": "FLORIDA",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recXwX6e2i3KhgSOq",
        "row": 12189,
        "state": "FLORIDA",
        "group": "45121C02",
        "group_num": [
            "recFCgnpNhJb9glNS"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 37,
        "2023": 177,
        "2024": 0,
        "id": "recYoRBnr5iuJs3qz",
        "row": 12175,
        "state": "FLORIDA",
        "group": "15921017",
        "group_num": [
            "recITmYJmPZvsjcXb"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "rec0Y48Lz39BdBK1n",
        "row": 12192,
        "state": "FLORIDA",
        "group": "15921027",
        "group_num": [
            "recswDYoM8KEJc84U"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 97,
        "2024": 0,
        "id": "rec0PpGLGsVxZ2vsd",
        "row": 12213,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 29,
        "2024": 0,
        "id": "rec1QqBYeS2kVAFJI",
        "row": 12219,
        "state": "FLORIDA",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "rec4YRHBPH9svUZk6",
        "row": 12191,
        "state": "FLORIDA",
        "group": "15921021",
        "group_num": [
            "rec1zNN53k55DqJnE"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "rec6XeHdReQj0ITEW",
        "row": 12183,
        "state": "FLORIDA",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 175,
        "2024": 0,
        "id": "rec6kdsDGGjxdQcqE",
        "row": 12177,
        "state": "FLORIDA",
        "group": "15921",
        "group_num": [
            "receiSy2v0oLOkR2c"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "rec7yfbBweFoyVB6W",
        "row": 12198,
        "state": "FLORIDA",
        "group": "J8642003",
        "group_num": [
            "rec0TAV3ikh1TapXI"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 175,
        "2024": 0,
        "id": "rec9997egAZNTNOzB",
        "row": 12179,
        "state": "FLORIDA",
        "group": "15921043",
        "group_num": [
            "reczmGMZq8OszKOBl"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 167,
        "2024": 0,
        "id": "recdUIMzL1y2ywBfB",
        "row": 12205,
        "state": "FLORIDA",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recdVx5siv9ucMWIS",
        "row": 12185,
        "state": "FLORIDA",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recp2z8mloPxZl1CZ",
        "row": 12195,
        "state": "FLORIDA",
        "group": "60856003",
        "group_num": [
            "recJZFQXHxN09jmkk"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 173,
        "2024": 0,
        "id": "recpbC6PP9hsXO4p3",
        "row": 12202,
        "state": "FLORIDA",
        "group": "85311FF2",
        "group_num": [
            "rec7uaD8yZUuIewbL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recqxN9sfbYGL4KZI",
        "row": 12182,
        "state": "FLORIDA",
        "group": "209615",
        "group_num": [
            "recOmyUkIpP9hvc0s"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 76,
        "2024": 0,
        "id": "rect6XYH76GNlvRq8",
        "row": 12214,
        "state": "FLORIDA",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recvTqoYw8ezJq0k7",
        "row": 12190,
        "state": "FLORIDA",
        "group": "99999U6Q",
        "group_num": [
            "recYXJpjXvYI3l8EK"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 30,
        "2024": 0,
        "id": "recvtWs0bpbkQdXpY",
        "row": 12218,
        "state": "FLORIDA",
        "group": "15921",
        "group_num": [
            "receiSy2v0oLOkR2c"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "2023": 175,
        "2024": 0,
        "id": "recvCS3gpkMfHDHPk",
        "row": 12176,
        "state": "FLORIDA",
        "group": "99999UU3",
        "group_num": [
            "recdhsaDQH4NNda4F"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "reczv2qXecMqxw1mY",
        "row": 12184,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recBGEMR5dydPdUHs",
        "row": 12181,
        "state": "FLORIDA",
        "group": "GA8039H1CD",
        "group_num": [
            "rechm9hQcOVh9qiNi"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 175,
        "2024": 0,
        "id": "recKsATucZoHM68Bd",
        "row": 12180,
        "state": "FLORIDA",
        "group": "99999M22",
        "group_num": [
            "rec7DWeCNNpc58gd5"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recMUr4KIrFcMZVjN",
        "row": 12193,
        "state": "FLORIDA",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recf6Hw09K2Or9Y9h",
        "row": 12241,
        "state": "FLORIDA",
        "group": "99999UU3",
        "group_num": [
            "recdhsaDQH4NNda4F"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "recfqMbnUUMHIUmlm",
        "row": 12244,
        "state": "FLORIDA",
        "group": "240696",
        "group_num": [
            "recDC2QKjdYwbdC1B"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "reciViwmZrUTZPcio",
        "row": 12245,
        "state": "FLORIDA",
        "group": "10601501",
        "group_num": [
            "reckMniK2rtJeBIDg"
        ],
        "procedure": "OP",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 167,
        "2023": 0,
        "2024": 0,
        "id": "reclpR85h0tjQrNgR",
        "row": 12270,
        "state": "FLORIDA",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "recOE40HHdcPHm56x",
        "row": 12247,
        "state": "FLORIDA",
        "group": "64763009",
        "group_num": [
            "rec2R6RcV7LHx5gwB"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "recPD8zLh4IQa9qdB",
        "row": 12227,
        "state": "FLORIDA",
        "group": "195165",
        "group_num": [
            "recarG7gyI8jKBldW"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 43,
        "2023": 0,
        "2024": 0,
        "id": "recS8W0zGXzZHUhqP",
        "row": 12253,
        "state": "FLORIDA",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 83,
        "2023": 0,
        "2024": 0,
        "id": "recYDpqaympk37jqj",
        "row": 12260,
        "state": "FLORIDA",
        "group": "98094005",
        "group_num": [
            "recwEhiUnTLgiNMen"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recZ4gXN5ATlt3IxL",
        "row": 12239,
        "state": "FLORIDA",
        "group": "99999G6F",
        "group_num": [
            "recUI7yjnnQzTmEyn"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "recZdnxUMagb6tzTY",
        "row": 12246,
        "state": "FLORIDA",
        "group": "1JNY",
        "group_num": [
            "recq1bGa0uczMCjIv"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 68,
        "2023": 0,
        "2024": 0,
        "id": "rec0OoKdyCuBA047G",
        "row": 12259,
        "state": "FLORIDA",
        "group": "107008",
        "group_num": [
            "rec0xw3ReK93i1YCU"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "2023": 0,
        "2024": 0,
        "id": "rec0ffWQOzJnYrhPP",
        "row": 12258,
        "state": "FLORIDA",
        "group": "117623",
        "group_num": [
            "rec8cGULTIafwQAFM"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "rec1BHNL65IP1EJxc",
        "row": 12224,
        "state": "FLORIDA",
        "group": "209615",
        "group_num": [
            "recOmyUkIpP9hvc0s"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 19,
        "2024": 0,
        "id": "rec13Sm4ja2MNwsD8",
        "row": 12225,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2N1tsPo96xr9Eq",
        "row": 12233,
        "state": "FLORIDA",
        "group": "99999M53",
        "group_num": [
            "rechBoXWoifN43YMB"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 23,
        "2024": 0,
        "id": "rec3veJfVNYNBUMev",
        "row": 12223,
        "state": "FLORIDA",
        "group": "15921043",
        "group_num": [
            "reczmGMZq8OszKOBl"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "rec4i4OcTbZa7w3TE",
        "row": 12229,
        "state": "FLORIDA",
        "group": "99999M22",
        "group_num": [
            "rec7DWeCNNpc58gd5"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recb4g9ZmQX0WMbPr",
        "row": 12238,
        "state": "FLORIDA",
        "group": "16153001",
        "group_num": [
            "rec1FFlOyYJOWmL2Z"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recb4Zn66hbtDG7Qc",
        "row": 12242,
        "state": "FLORIDA",
        "group": "99999ZSB",
        "group_num": [
            "recid127QSeBvRceC"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 0,
        "2024": 0,
        "id": "recb7Ei1zbm8lqpxI",
        "row": 12251,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 24,
        "2024": 0,
        "id": "reccrfblpt2p4SA0z",
        "row": 12222,
        "state": "FLORIDA",
        "group": "15827005",
        "group_num": [
            "rec7CojY6hzThIc1H"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 167,
        "2023": 0,
        "2024": 0,
        "id": "recd65fBKjy71T2ID",
        "row": 12271,
        "state": "FLORIDA",
        "group": "16153001",
        "group_num": [
            "rec1FFlOyYJOWmL2Z"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 159,
        "2023": 0,
        "2024": 0,
        "id": "recdlfE2mJRlfRyvP",
        "row": 12267,
        "state": "FLORIDA",
        "group": "37678FF2",
        "group_num": [
            "reckCiuzdegcSLA03"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recohjHrsYaR8YzTD",
        "row": 12240,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "recuCkGz9rXuCGyqY",
        "row": 12248,
        "state": "FLORIDA",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 0,
        "2024": 0,
        "id": "recu4bgi5XVwnrOrs",
        "row": 12249,
        "state": "FLORIDA",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "recxJ9QEgNCSoTYhm",
        "row": 12226,
        "state": "FLORIDA",
        "group": "GA8039H1CD",
        "group_num": [
            "rechm9hQcOVh9qiNi"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "reczWv1LejFADzCDJ",
        "row": 12230,
        "state": "FLORIDA",
        "group": "15921027",
        "group_num": [
            "recswDYoM8KEJc84U"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recz4hckjExujhMZH",
        "row": 12243,
        "state": "FLORIDA",
        "group": "15921021",
        "group_num": [
            "rec1zNN53k55DqJnE"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "2023": 0,
        "2024": 0,
        "id": "recAJ0zrc7FzRnIiW",
        "row": 12250,
        "state": "FLORIDA",
        "group": "344340",
        "group_num": [
            "rec9QtymeAWtbeGvx"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "2023": 0,
        "2024": 0,
        "id": "recGVLUB6GqCG7C06",
        "row": 12237,
        "state": "FLORIDA",
        "group": "51818004",
        "group_num": [
            "recs0DHZN89ata5L2"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 125,
        "2023": 0,
        "2024": 0,
        "id": "recKH1GGmnW7LpcW4",
        "row": 12263,
        "state": "FLORIDA",
        "group": "L01412M010",
        "group_num": [
            "recuKYM4mN0NdVkJv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "recMbskbvGN3yUL4r",
        "row": 12231,
        "state": "FLORIDA",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "2023": 0,
        "2024": 0,
        "id": "recMpxd4A2uVTDWLw",
        "row": 12257,
        "state": "FLORIDA",
        "group": "J8758003",
        "group_num": [
            "rec66WcIojVgMtLqK"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "receb5zIjpTQTGLTW",
        "row": 12284,
        "state": "FLORIDA",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recgnJuL7ROmTRAtp",
        "row": 12287,
        "state": "FLORIDA",
        "group": "99999ZSB",
        "group_num": [
            "recid127QSeBvRceC"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "reclSpuw6kBomI7jP",
        "row": 12283,
        "state": "FLORIDA",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recRHpm8JUBfV3OL1",
        "row": 12293,
        "state": "FLORIDA",
        "group": "43106CO1",
        "group_num": [
            "recYjVur5OYqPujHQ"
        ],
        "procedure": "IOP",
        "provider": "OXFORD (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recT11ReQxcmuPs5l",
        "row": 12286,
        "state": "FLORIDA",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recTkYentzWqntsr1",
        "row": 12290,
        "state": "FLORIDA",
        "group": "64763009",
        "group_num": [
            "rec2R6RcV7LHx5gwB"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 0,
        "2024": 0,
        "id": "recUnGzUOBhhXRpD0",
        "row": 12303,
        "state": "FLORIDA",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 217,
        "2023": 0,
        "2024": 0,
        "id": "recWyiJb6xoyBCySh",
        "row": 12306,
        "state": "FLORIDA",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recXvgqesHK9MNbIU",
        "row": 12274,
        "state": "FLORIDA",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "2023": 0,
        "2024": 0,
        "id": "rec0NyQ6h73dsAS3X",
        "row": 12272,
        "state": "FLORIDA",
        "group": "1JNY",
        "group_num": [
            "recq1bGa0uczMCjIv"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "rec0phjG6ckCf02tv",
        "row": 12289,
        "state": "FLORIDA",
        "group": "51818004",
        "group_num": [
            "recs0DHZN89ata5L2"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "rec3U3jnEzb3pQJzi",
        "row": 12277,
        "state": "FLORIDA",
        "group": "10601501",
        "group_num": [
            "reckMniK2rtJeBIDg"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "rec4vhEI1QLHVEzVz",
        "row": 12273,
        "state": "FLORIDA",
        "group": "788800",
        "group_num": [
            "recuCT5ps3qQV3f4y"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "rec4cAajjkgKJBsiQ",
        "row": 12292,
        "state": "FLORIDA",
        "group": "S7851002",
        "group_num": [
            "recVAVrzmUbthAYBE"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 239,
        "2023": 0,
        "2024": 0,
        "id": "rec8UkgFM9hzflZSH",
        "row": 12313,
        "state": "FLORIDA",
        "group": "10416806",
        "group_num": [
            "recIzUX4gNnv2386R"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recabAqujExBwlfFz",
        "row": 12275,
        "state": "FLORIDA",
        "group": "300001",
        "group_num": [
            "recrWGWrfL7SNFmqb"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recbG2zslSwR1JEt3",
        "row": 12291,
        "state": "FLORIDA",
        "group": "66125002",
        "group_num": [
            "recgmNotJ7OfWMhL3"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recrQN1ZHAXHxMXNV",
        "row": 12319,
        "state": "FLORIDA",
        "group": "10282137",
        "group_num": [
            "rec5EV1I6plhPgWc6"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "rectA0XuMRpXd0jmw",
        "row": 12282,
        "state": "FLORIDA",
        "group": "99999GFY",
        "group_num": [
            "recaBmeHsOmpWPd03"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 182,
        "2023": 0,
        "2024": 0,
        "id": "recyAcX5o9dfJED3G",
        "row": 12296,
        "state": "FLORIDA",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "reczjVtJb6CtiX34N",
        "row": 12288,
        "state": "FLORIDA",
        "group": "14962001",
        "group_num": [
            "recXxVm7SjkvdjatJ"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recEzxhUvBUGBcSri",
        "row": 12276,
        "state": "FLORIDA",
        "group": "344340",
        "group_num": [
            "rec9QtymeAWtbeGvx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 240,
        "2023": 0,
        "2024": 0,
        "id": "recG4JCcaXYWmplp1",
        "row": 12314,
        "state": "FLORIDA",
        "group": "4022154",
        "group_num": [
            "recAfJRy5kcwi87iv"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF AK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recJ3FoA2pYzxLQOI",
        "row": 12285,
        "state": "FLORIDA",
        "group": "99999UFU",
        "group_num": [
            "rec7NX1BwoD08wZ2y"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recLMkXG9Vqxaqz4r",
        "row": 12281,
        "state": "FLORIDA",
        "group": "43106CO1",
        "group_num": [
            "recYjVur5OYqPujHQ"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "receFLmLcF9JAr9XG",
        "row": 12333,
        "state": "FLORIDA",
        "group": "40521B",
        "group_num": [
            "recqryEawi3TY5TmV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "2023": 0,
        "2024": 0,
        "id": "reclqNYJzSBguGl9O",
        "row": 12324,
        "state": "FLORIDA",
        "group": "00100582",
        "group_num": [
            "reclUXy5pC7UmOwwv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recnIQt34394xFFdu",
        "row": 12361,
        "state": "FLORIDA",
        "group": "215150",
        "group_num": [
            "recAaxmkZYR43eZdy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recPa7sVs5zapAfAj",
        "row": 12345,
        "state": "FLORIDA",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 311,
        "2023": 0,
        "2024": 0,
        "id": "recRwWNluifajTgtA",
        "row": 12360,
        "state": "FLORIDA",
        "group": "716066500",
        "group_num": [
            "recRMsOPcCcj6LgmO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recRGHHhl7URow0kb",
        "row": 12365,
        "state": "FLORIDA",
        "group": "041665",
        "group_num": [
            "recWlJXGqEyDRcLlx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recSVIFhJT7HXop9w",
        "row": 12347,
        "state": "FLORIDA",
        "group": "14172079",
        "group_num": [
            "recBuVZJcODJkCKWY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recTRqLrllUYQ6EbK",
        "row": 12351,
        "state": "FLORIDA",
        "group": "99999M28",
        "group_num": [
            "recUM6s3gCPp0TkGc"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recU8Ms3KbXQiaced",
        "row": 12367,
        "state": "FLORIDA",
        "group": "10601501",
        "group_num": [
            "reckMniK2rtJeBIDg"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recXnkA0ToyJ7AZuU",
        "row": 12341,
        "state": "FLORIDA",
        "group": "212063M1AA",
        "group_num": [
            "recLkuH3wZ3jrLXIy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recYVyiE5YPsgXoTK",
        "row": 12355,
        "state": "FLORIDA",
        "group": "00760393731",
        "group_num": [
            "recd7VTqut291xcG7"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "2023": 0,
        "2024": 0,
        "id": "recYwjLD2xHZb8x92",
        "row": 12330,
        "state": "FLORIDA",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 269,
        "2023": 0,
        "2024": 0,
        "id": "rec4Yeft6MgX9hL2U",
        "row": 12332,
        "state": "FLORIDA",
        "group": "29D68ERC",
        "group_num": [
            "rec5r4vDb5e5qPy9I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recaD5Ir30RVrRQEF",
        "row": 12368,
        "state": "FLORIDA",
        "group": "1JNY",
        "group_num": [
            "recq1bGa0uczMCjIv"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "reca3naj5ekn1tv2z",
        "row": 12371,
        "state": "FLORIDA",
        "group": "00006021",
        "group_num": [
            "recjKXEX6C7t6m2sB"
        ],
        "procedure": "RTC",
        "provider": "EXCELLUS BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recbXMG5jgNXlgxdT",
        "row": 12349,
        "state": "FLORIDA",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recca9lvz2qEUIawr",
        "row": 12362,
        "state": "FLORIDA",
        "group": "269589",
        "group_num": [
            "recZQ632gxnKdDotP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recdgiIYl37FoDuGI",
        "row": 12354,
        "state": "FLORIDA",
        "group": "99999MB8",
        "group_num": [
            "rec1W4IBgwPhaQ0AK"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 253,
        "2023": 0,
        "2024": 0,
        "id": "recojobVPNXGEL151",
        "row": 12325,
        "state": "FLORIDA",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recqFEaSUU7OLUsND",
        "row": 12350,
        "state": "FLORIDA",
        "group": "99999M25",
        "group_num": [
            "recSe2GZZBcrbU0Ll"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 267,
        "2023": 0,
        "2024": 0,
        "id": "recrhgEFo8OziL1qj",
        "row": 12331,
        "state": "FLORIDA",
        "group": "99EF",
        "group_num": [
            "recqIx3Yq6s8JtA90"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "rect9HkVLkqn4RvaY",
        "row": 12346,
        "state": "FLORIDA",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recunIQzMlXwDoSwA",
        "row": 12344,
        "state": "FLORIDA",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recxhf2b3EQ0HT1OX",
        "row": 12370,
        "state": "FLORIDA",
        "group": "99WE",
        "group_num": [
            "recdUwz7U6OFwaM8y"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "2023": 0,
        "2024": 0,
        "id": "recAVZtX4M4RsflNF",
        "row": 12357,
        "state": "FLORIDA",
        "group": "J6460001",
        "group_num": [
            "reccVlxK7JR3a3IAD"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 0,
        "2024": 0,
        "id": "recAeu8iFGenWfsqR",
        "row": 12363,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 294,
        "2023": 0,
        "2024": 0,
        "id": "recAnsi4ge3gSNCJz",
        "row": 12342,
        "state": "FLORIDA",
        "group": "715940401",
        "group_num": [
            "recg3wpTup61zHAhG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 0,
        "2024": 0,
        "id": "recCUglSBXdvcjc7B",
        "row": 12339,
        "state": "FLORIDA",
        "group": "1877MN",
        "group_num": [
            "recoNw1tTcZ8ScOXV"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recCcXzmBXqC5fAgG",
        "row": 12366,
        "state": "FLORIDA",
        "group": "327763",
        "group_num": [
            "recF7mlI559nuW8sR"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recGWXfdqumMSHipz",
        "row": 12353,
        "state": "FLORIDA",
        "group": "99999M72",
        "group_num": [
            "recYr25z0w19xMSW8"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 0,
        "2024": 0,
        "id": "recGFHfbM4DtUSkeR",
        "row": 12343,
        "state": "FLORIDA",
        "group": "16425002",
        "group_num": [
            "recGlREWjuMsoBH78"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 0,
        "2024": 0,
        "id": "recJEziI3qIFJzKAI",
        "row": 12348,
        "state": "FLORIDA",
        "group": "14172597",
        "group_num": [
            "rectS52nfA9upPXoE"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recKfBrKjRydegZVO",
        "row": 12352,
        "state": "FLORIDA",
        "group": "99999M53",
        "group_num": [
            "rechBoXWoifN43YMB"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recMuOH0aJfnGOWLg",
        "row": 12369,
        "state": "FLORIDA",
        "group": "2L0S",
        "group_num": [
            "recPZhmR30MyKtOhL"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recgmlxf89QtDPiil",
        "row": 12393,
        "state": "FLORIDA",
        "group": "001902307MD10006",
        "group_num": [
            "recvE1tuPgX6NzYBs"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recjSs9VOopF5DEoK",
        "row": 12386,
        "state": "FLORIDA",
        "group": "100740",
        "group_num": [
            "recCWROII3kJIr0Qi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recld7SZtvyD206yA",
        "row": 12379,
        "state": "FLORIDA",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recQtZ9OHdjYAbpHI",
        "row": 12390,
        "state": "FLORIDA",
        "group": "1871VL",
        "group_num": [
            "recqNykDeJWy8f221"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recTzyhXp2MDJWlNT",
        "row": 12376,
        "state": "FLORIDA",
        "group": "S7851002",
        "group_num": [
            "recVAVrzmUbthAYBE"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recT7M94vloH0ZuKo",
        "row": 12378,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "2023": 0,
        "2024": 0,
        "id": "recVvBWW5S5cMXhDC",
        "row": 12377,
        "state": "FLORIDA",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recV5psWeLnRPTYTj",
        "row": 12373,
        "state": "FLORIDA",
        "group": "99999ZSB",
        "group_num": [
            "recid127QSeBvRceC"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recXUHJHcBHLtyMrm",
        "row": 12374,
        "state": "FLORIDA",
        "group": "99999ZT1",
        "group_num": [
            "recHC380fpH6jnKDO"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recYxLwmKq2rFIW19",
        "row": 12406,
        "state": "FLORIDA",
        "group": "B4424002",
        "group_num": [
            "recCoWj8ORR5n4Grc"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rec1yWy3JCuBdTMJx",
        "row": 12402,
        "state": "FLORIDA",
        "group": "99999M72",
        "group_num": [
            "recYr25z0w19xMSW8"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rec1iKpfLWatq0caJ",
        "row": 12403,
        "state": "FLORIDA",
        "group": "99999MB8",
        "group_num": [
            "rec1W4IBgwPhaQ0AK"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 334,
        "2023": 0,
        "2024": 0,
        "id": "rec1rAXZp3fCxyKWg",
        "row": 12387,
        "state": "FLORIDA",
        "group": "99999UFU",
        "group_num": [
            "rec7NX1BwoD08wZ2y"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "rec2UMmZQHkI1ZwsN",
        "row": 12412,
        "state": "FLORIDA",
        "group": "215150",
        "group_num": [
            "recAaxmkZYR43eZdy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 398,
        "2023": 0,
        "2024": 0,
        "id": "rec3zhB3I08Ko5E2n",
        "row": 12419,
        "state": "FLORIDA",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "rec4nSdJ6QuqfMNA1",
        "row": 12420,
        "state": "FLORIDA",
        "group": "A57373",
        "group_num": [
            "recGo6tinLsCAruMK"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 388,
        "2023": 0,
        "2024": 0,
        "id": "rec5Em8D7FnjLJfpY",
        "row": 12416,
        "state": "FLORIDA",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "reccChEAQzeuH0K7g",
        "row": 12400,
        "state": "FLORIDA",
        "group": "99999M25",
        "group_num": [
            "recSe2GZZBcrbU0Ll"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 336,
        "2023": 0,
        "2024": 0,
        "id": "recdkXIaX7eWq9tP9",
        "row": 12388,
        "state": "FLORIDA",
        "group": "277242M002",
        "group_num": [
            "recBgoZiNUwJw2UFT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 375,
        "2023": 0,
        "2024": 0,
        "id": "recouHtMmD7FNUziY",
        "row": 12411,
        "state": "FLORIDA",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recquaiWZNuHechPA",
        "row": 12383,
        "state": "FLORIDA",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recqCrBn0zR8lasFZ",
        "row": 12375,
        "state": "FLORIDA",
        "group": "66125002",
        "group_num": [
            "recgmNotJ7OfWMhL3"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recqR05ZaP5M2jKwI",
        "row": 12384,
        "state": "FLORIDA",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "2023": 0,
        "2024": 0,
        "id": "recq3AU0JP26ci1KK",
        "row": 12389,
        "state": "FLORIDA",
        "group": "031936",
        "group_num": [
            "rec4IW3CfL9eInXO4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 396,
        "2023": 0,
        "2024": 0,
        "id": "recsXyI4XxXmQmFQL",
        "row": 12418,
        "state": "FLORIDA",
        "group": "002135200",
        "group_num": [
            "recNXI9Wfb4ZrBfKw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recsZEFBjQjVOJ5Sa",
        "row": 12392,
        "state": "FLORIDA",
        "group": "271103",
        "group_num": [
            "recEAY3lAModCzPjf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recsF2IDnUnZvjKyH",
        "row": 12401,
        "state": "FLORIDA",
        "group": "99999M28",
        "group_num": [
            "recUM6s3gCPp0TkGc"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "recsq44Jd3Fzt6Jjy",
        "row": 12413,
        "state": "FLORIDA",
        "group": "269589",
        "group_num": [
            "recZQ632gxnKdDotP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rectsuLk1FaVazZmQ",
        "row": 12404,
        "state": "FLORIDA",
        "group": "99999MBS",
        "group_num": [
            "reccLqvLGwFx1cp3u"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "rect91xR0TBnecSAE",
        "row": 12414,
        "state": "FLORIDA",
        "group": "320728",
        "group_num": [
            "reckbRcrG97NJEbxf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recv10dpL8NNwln80",
        "row": 12382,
        "state": "FLORIDA",
        "group": "307951",
        "group_num": [
            "recYzkSbx77Gf0MMM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recvxtaPCZzQZsKDm",
        "row": 12381,
        "state": "FLORIDA",
        "group": "L01412M010",
        "group_num": [
            "recuKYM4mN0NdVkJv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 0,
        "2024": 0,
        "id": "recvbIyVlyiUMkohi",
        "row": 12372,
        "state": "FLORIDA",
        "group": "43106CO1",
        "group_num": [
            "recYjVur5OYqPujHQ"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "recxiK10lkoJiAMBl",
        "row": 12385,
        "state": "FLORIDA",
        "group": "B4247002",
        "group_num": [
            "recIGXlbRaJgckr6G"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 0,
        "2024": 0,
        "id": "reczjNPySnTqnnLoT",
        "row": 12380,
        "state": "FLORIDA",
        "group": "6045521000",
        "group_num": [
            "recHBaW6NNbVXE8E8"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF IA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 0,
        "2024": 0,
        "id": "recCeMfDydbSKSwYR",
        "row": 12415,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recF0kMTUp2eqVrmv",
        "row": 12399,
        "state": "FLORIDA",
        "group": "99999M21",
        "group_num": [
            "rec4eJW8OCpY2NYeC"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recFuWIHP3biR9M1R",
        "row": 12405,
        "state": "FLORIDA",
        "group": "99999MYG",
        "group_num": [
            "recRqDyOAXdCwejBb"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recHXNlFyLkIb9gKE",
        "row": 12421,
        "state": "FLORIDA",
        "group": "017127",
        "group_num": [
            "recltm8N9HALCvbTL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recJ5YHqNwzM5X6WS",
        "row": 12398,
        "state": "FLORIDA",
        "group": "99999GFY",
        "group_num": [
            "recaBmeHsOmpWPd03"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recKaL1pRf0Gq0sEA",
        "row": 12391,
        "state": "FLORIDA",
        "group": "43239000",
        "group_num": [
            "recYliUH7AbgUcOm4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF KS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 394,
        "2023": 0,
        "2024": 0,
        "id": "recNorFttAgBDZOAa",
        "row": 12417,
        "state": "FLORIDA",
        "group": "320728",
        "group_num": [
            "reckbRcrG97NJEbxf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 467,
        "2023": 0,
        "2024": 0,
        "id": "rece1ibbzN2XKP2y7",
        "row": 12463,
        "state": "FLORIDA",
        "group": "J0878001",
        "group_num": [
            "rec1D9MF0qRWe6F1f"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recfUYCQoRwZhMHu4",
        "row": 12457,
        "state": "FLORIDA",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recfwSDDfQmG9G6NU",
        "row": 12425,
        "state": "FLORIDA",
        "group": "72570002",
        "group_num": [
            "recTGGYHNSlosekfh"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 0,
        "2024": 0,
        "id": "rechd0aGRMEcRAOed",
        "row": 12428,
        "state": "FLORIDA",
        "group": "00702644",
        "group_num": [
            "recSzhWQ5rTkp9riz"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 473,
        "2023": 0,
        "2024": 0,
        "id": "recmQxrl5ZVqtMwkm",
        "row": 12468,
        "state": "FLORIDA",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "2023": 0,
        "2024": 0,
        "id": "recRK9ZCiAbnUDun8",
        "row": 12464,
        "state": "FLORIDA",
        "group": "00006021",
        "group_num": [
            "recjKXEX6C7t6m2sB"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 0,
        "2024": 0,
        "id": "recU3ZHFhWR8G8XqC",
        "row": 12471,
        "state": "FLORIDA",
        "group": "00701175",
        "group_num": [
            "rec8OXumoAQlPFDRC"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recWDrE51XdHAsmft",
        "row": 12426,
        "state": "FLORIDA",
        "group": "B5174003",
        "group_num": [
            "recMZUw9PBpnkShGr"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recWJfOhybDAYX7ln",
        "row": 12455,
        "state": "FLORIDA",
        "group": "99999ZKH",
        "group_num": [
            "recV5Axor0h1yfdZf"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recYVsk9qi8MgMjhS",
        "row": 12424,
        "state": "FLORIDA",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "rec2tABGm4EZ5aj5P",
        "row": 12453,
        "state": "FLORIDA",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 417,
        "2023": 0,
        "2024": 0,
        "id": "rec7SU1M0Co8T66QC",
        "row": 12429,
        "state": "FLORIDA",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "rec9KiIsqba8INYhD",
        "row": 12456,
        "state": "FLORIDA",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 0,
        "2024": 0,
        "id": "recd7HN88vVk6dLaI",
        "row": 12469,
        "state": "FLORIDA",
        "group": "00701175",
        "group_num": [
            "rec8OXumoAQlPFDRC"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recvY4G8DuvVsihl5",
        "row": 12452,
        "state": "FLORIDA",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "2023": 0,
        "2024": 0,
        "id": "recCU9zyiR6oamGuw",
        "row": 12466,
        "state": "FLORIDA",
        "group": "80963",
        "group_num": [
            "reczIOON3EZKygglC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recCYgdQJOY3Q6MJl",
        "row": 12454,
        "state": "FLORIDA",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 473,
        "2023": 0,
        "2024": 0,
        "id": "recCC7LmWAysigqIl",
        "row": 12467,
        "state": "FLORIDA",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 435,
        "2023": 0,
        "2024": 0,
        "id": "recExqL3XiKJajEud",
        "row": 12437,
        "state": "FLORIDA",
        "group": "16425002",
        "group_num": [
            "recGlREWjuMsoBH78"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recGvEZ3bWYGEOlOI",
        "row": 12423,
        "state": "FLORIDA",
        "group": "716020401",
        "group_num": [
            "recjW1dpG1wcmOen3"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "2023": 0,
        "2024": 0,
        "id": "recGAXseB0l2QLaoP",
        "row": 12461,
        "state": "FLORIDA",
        "group": "29D68ERC",
        "group_num": [
            "rec5r4vDb5e5qPy9I"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 0,
        "2024": 0,
        "id": "recIrvStchh6O82rr",
        "row": 12470,
        "state": "FLORIDA",
        "group": "W41524M003",
        "group_num": [
            "recytMKFAaPA3ET4W"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "2023": 0,
        "2024": 0,
        "id": "recKSo91IIATugMzT",
        "row": 12422,
        "state": "FLORIDA",
        "group": "0000714471000053",
        "group_num": [
            "recN0S2YjGz6LHCgl"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 467,
        "2023": 0,
        "2024": 0,
        "id": "recKpf4hrCyTD4IRc",
        "row": 12462,
        "state": "FLORIDA",
        "group": "GB0192M005",
        "group_num": [
            "recdsiZKzqkIq5A8N"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 0,
        "2024": 0,
        "id": "recN0gcQsRnc4bZuM",
        "row": 12451,
        "state": "FLORIDA",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "receN7n1ZbzbZ9rYr",
        "row": 12492,
        "state": "FLORIDA",
        "group": "99999M16",
        "group_num": [
            "recvZyq7hmXrWAXQ8"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recfdqZHyxvVHD5oE",
        "row": 12495,
        "state": "FLORIDA",
        "group": "99999M25",
        "group_num": [
            "recSe2GZZBcrbU0Ll"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 490,
        "2023": 0,
        "2024": 0,
        "id": "rechlPvBSNrFfekgI",
        "row": 12478,
        "state": "FLORIDA",
        "group": "99999M53",
        "group_num": [
            "rechBoXWoifN43YMB"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "reclTOavPVSOcvmIS",
        "row": 12520,
        "state": "FLORIDA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recmnrDTctdJAHRQ3",
        "row": 12502,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recnAnWV7u8DvrnJ8",
        "row": 12503,
        "state": "FLORIDA",
        "group": "B4424002",
        "group_num": [
            "recCoWj8ORR5n4Grc"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recnglXpxIUdqBtR2",
        "row": 12488,
        "state": "FLORIDA",
        "group": "99999G7L",
        "group_num": [
            "recSNZm3KEcc9ysj2"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recPjgB6zgYN46MG1",
        "row": 12486,
        "state": "FLORIDA",
        "group": "00521906",
        "group_num": [
            "recUoLMuzwkMfB8EX"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recQXcBYlLfx8Uw1B",
        "row": 12487,
        "state": "FLORIDA",
        "group": "99999G7L",
        "group_num": [
            "recSNZm3KEcc9ysj2"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recTU7Jmcf4CzwIzs",
        "row": 12500,
        "state": "FLORIDA",
        "group": "99999UFU",
        "group_num": [
            "rec7NX1BwoD08wZ2y"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recTtaeaacVYOgr55",
        "row": 12493,
        "state": "FLORIDA",
        "group": "99999M21",
        "group_num": [
            "rec4eJW8OCpY2NYeC"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 489,
        "2023": 0,
        "2024": 0,
        "id": "recU7VmL6Bpd9BzFM",
        "row": 12477,
        "state": "FLORIDA",
        "group": "279354M010",
        "group_num": [
            "rec6H20ZbKBvxD2Ha"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recVluZi7YnBB9Ehf",
        "row": 12489,
        "state": "FLORIDA",
        "group": "99999GFY",
        "group_num": [
            "recaBmeHsOmpWPd03"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recXDYaPuPHNSPB1f",
        "row": 12484,
        "state": "FLORIDA",
        "group": "832008010",
        "group_num": [
            "recCE2SFtfKmnsvss"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "rec2plN3wHxhumbn2",
        "row": 12483,
        "state": "FLORIDA",
        "group": "43239000",
        "group_num": [
            "recYliUH7AbgUcOm4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF KS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 478,
        "2023": 0,
        "2024": 0,
        "id": "rec5fIR0bCkYsuW7J",
        "row": 12472,
        "state": "FLORIDA",
        "group": "99999GFY",
        "group_num": [
            "recaBmeHsOmpWPd03"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "rec6ib17KKLAW98AQ",
        "row": 12518,
        "state": "FLORIDA",
        "group": "0EE555",
        "group_num": [
            "receTsENi7BxqaE3u"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "rec78olTBzyTp02j0",
        "row": 12501,
        "state": "FLORIDA",
        "group": "240698",
        "group_num": [
            "recHzzTGROFFDEnRh"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recbr1xkOndwb2O2w",
        "row": 12521,
        "state": "FLORIDA",
        "group": "208921",
        "group_num": [
            "recB5P54eZvuxutWa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "reccDFA3ZgP1KKkYe",
        "row": 12496,
        "state": "FLORIDA",
        "group": "99999M28",
        "group_num": [
            "recUM6s3gCPp0TkGc"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recoFZRVDkazHxDzw",
        "row": 12491,
        "state": "FLORIDA",
        "group": "99999M13",
        "group_num": [
            "reclvu9Q6pWcCiFyJ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "rectWld6h051wNW89",
        "row": 12499,
        "state": "FLORIDA",
        "group": "99999MYG",
        "group_num": [
            "recRqDyOAXdCwejBb"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "rectgVOGeuHGga3f8",
        "row": 12480,
        "state": "FLORIDA",
        "group": "LBC361",
        "group_num": [
            "reco2vOUaWBSPuAqb"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recubAaSQPfQu4KiF",
        "row": 12490,
        "state": "FLORIDA",
        "group": "99999M13",
        "group_num": [
            "reclvu9Q6pWcCiFyJ"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recxtKmL8tMf2BG0T",
        "row": 12498,
        "state": "FLORIDA",
        "group": "99999MBS",
        "group_num": [
            "reccLqvLGwFx1cp3u"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recAZ9qqgKAXAiYg8",
        "row": 12479,
        "state": "FLORIDA",
        "group": "LBC361",
        "group_num": [
            "reco2vOUaWBSPuAqb"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recAQrIlgn0RI14ho",
        "row": 12494,
        "state": "FLORIDA",
        "group": "99999M22",
        "group_num": [
            "rec7DWeCNNpc58gd5"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recBJshyOAFDbRZf0",
        "row": 12481,
        "state": "FLORIDA",
        "group": "971561",
        "group_num": [
            "reclqUOAUT2ORDyQF"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF HI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 489,
        "2023": 0,
        "2024": 0,
        "id": "recBM2AJXF8tskO1C",
        "row": 12476,
        "state": "FLORIDA",
        "group": "37678FF2",
        "group_num": [
            "reckCiuzdegcSLA03"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recC2w7DvV34QdEDf",
        "row": 12482,
        "state": "FLORIDA",
        "group": "43239000",
        "group_num": [
            "recYliUH7AbgUcOm4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF KS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recGjZ5kSF9eBVDIy",
        "row": 12508,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recH8k8xTbSp8FCZm",
        "row": 12485,
        "state": "FLORIDA",
        "group": "271103",
        "group_num": [
            "recEAY3lAModCzPjf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recIsKKdV462I8ok3",
        "row": 12507,
        "state": "FLORIDA",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recJURIPucPFW7daD",
        "row": 12497,
        "state": "FLORIDA",
        "group": "99999M72",
        "group_num": [
            "recYr25z0w19xMSW8"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recLmzL941Evij7Rf",
        "row": 12504,
        "state": "FLORIDA",
        "group": "008505X0075",
        "group_num": [
            "recXxNNdISTnOIhAG"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recN5o61NLn5cgMeo",
        "row": 12519,
        "state": "FLORIDA",
        "group": "0EU004",
        "group_num": [
            "recP0WBN0K5RxJDLM"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 0,
        "2024": 0,
        "id": "receAm0SKLpsHiEmG",
        "row": 12548,
        "state": "FLORIDA",
        "group": "201075MGDG",
        "group_num": [
            "recR9u7WDJGIaJY5c"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recfuNdqCsQVcm7y1",
        "row": 12525,
        "state": "FLORIDA",
        "group": "P06574",
        "group_num": [
            "reczLzYnPIOesW7Cs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "2023": 0,
        "2024": 0,
        "id": "recjCnAzcL0Kz9Qna",
        "row": 12568,
        "state": "FLORIDA",
        "group": "10053820",
        "group_num": [
            "rec0i4l63BNSbFHY1"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recj7Os84JFYzZdPJ",
        "row": 12571,
        "state": "FLORIDA",
        "group": "A57373",
        "group_num": [
            "recGo6tinLsCAruMK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "rec6M9NqEvBc8gizY",
        "row": 12522,
        "state": "FLORIDA",
        "group": "226613",
        "group_num": [
            "recWeX31CaqVD8gGl"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 583,
        "2023": 0,
        "2024": 0,
        "id": "recaaEifVZwvBn07Q",
        "row": 12552,
        "state": "FLORIDA",
        "group": "99999M53",
        "group_num": [
            "rechBoXWoifN43YMB"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 539,
        "2023": 0,
        "2024": 0,
        "id": "recadJUDoLPlVxGME",
        "row": 12534,
        "state": "FLORIDA",
        "group": "213065M3AA",
        "group_num": [
            "recA1vFmcbTZipOo1"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "reccNL9ifjiZZS1lq",
        "row": 12526,
        "state": "FLORIDA",
        "group": "P75819",
        "group_num": [
            "rec3LVmxZLNxmWM7H"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 535,
        "2023": 0,
        "2024": 0,
        "id": "recr1ZnIyyAhZ6Ht5",
        "row": 12530,
        "state": "FLORIDA",
        "group": "00702644",
        "group_num": [
            "recSzhWQ5rTkp9riz"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 0,
        "2024": 0,
        "id": "recsflzHAZ2jekhy9",
        "row": 12553,
        "state": "FLORIDA",
        "group": "266520",
        "group_num": [
            "recnDu2oW6oSXrY4X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 603,
        "2023": 0,
        "2024": 0,
        "id": "recx8sNYbd1PmYTwZ",
        "row": 12563,
        "state": "FLORIDA",
        "group": "099967",
        "group_num": [
            "recuXM1Jl0VwVL0Nw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 536,
        "2023": 0,
        "2024": 0,
        "id": "recApLgldLZrE5NNc",
        "row": 12531,
        "state": "FLORIDA",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recBg6enO5SqKUZLL",
        "row": 12523,
        "state": "FLORIDA",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "2023": 0,
        "2024": 0,
        "id": "recGc95aIgWGm2Nms",
        "row": 12566,
        "state": "FLORIDA",
        "group": "376014",
        "group_num": [
            "reczgcjsnz7bDlBNC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 0,
        "2024": 0,
        "id": "recJSO0k0PiQnIVQ4",
        "row": 12547,
        "state": "FLORIDA",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 609,
        "2023": 0,
        "2024": 0,
        "id": "recLQdZ54vzzxyzfd",
        "row": 12564,
        "state": "FLORIDA",
        "group": "99999M72",
        "group_num": [
            "recYr25z0w19xMSW8"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recN4qiSW2eopqJDZ",
        "row": 12524,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 828,
        "2023": 0,
        "2024": 0,
        "id": "recejjWkrLMWwCqGt",
        "row": 12618,
        "state": "FLORIDA",
        "group": "00C020M001",
        "group_num": [
            "recygi4ZYhzf41GXS"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF ME (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 659,
        "2023": 0,
        "2024": 0,
        "id": "recfbZXlLsV4omSds",
        "row": 12587,
        "state": "FLORIDA",
        "group": "0009148A01",
        "group_num": [
            "recDvdLJaoredHSuM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "rechz6PZGTTMmiaBM",
        "row": 12572,
        "state": "FLORIDA",
        "group": "017127",
        "group_num": [
            "recltm8N9HALCvbTL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "rechGoE7Gj9zhQ3Kj",
        "row": 12580,
        "state": "FLORIDA",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 667,
        "2023": 0,
        "2024": 0,
        "id": "recmj8YErfZF0vFeJ",
        "row": 12590,
        "state": "FLORIDA",
        "group": "226613",
        "group_num": [
            "recWeX31CaqVD8gGl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recnSEkjP05mtTbpg",
        "row": 12620,
        "state": "FLORIDA",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recQmJuvCvENkgg8z",
        "row": 12574,
        "state": "FLORIDA",
        "group": "0000714471000053",
        "group_num": [
            "recN0S2YjGz6LHCgl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 816,
        "2023": 0,
        "2024": 0,
        "id": "recRFIfLjzkWVcs8g",
        "row": 12617,
        "state": "FLORIDA",
        "group": "99999M25",
        "group_num": [
            "recSe2GZZBcrbU0Ll"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 698,
        "2023": 0,
        "2024": 0,
        "id": "recTtAD867MXEZnOm",
        "row": 12597,
        "state": "FLORIDA",
        "group": "277242M002",
        "group_num": [
            "recBgoZiNUwJw2UFT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recTIL39GAlMVwUap",
        "row": 12579,
        "state": "FLORIDA",
        "group": "001902307MD10006",
        "group_num": [
            "recvE1tuPgX6NzYBs"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 667,
        "2023": 0,
        "2024": 0,
        "id": "recWFDGdXDPRyG2n9",
        "row": 12589,
        "state": "FLORIDA",
        "group": "300601026A",
        "group_num": [
            "recDRudbAAsTr43tm"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "2023": 0,
        "2024": 0,
        "id": "rec0ij4vO1LTDjG0z",
        "row": 12610,
        "state": "FLORIDA",
        "group": "002135200",
        "group_num": [
            "recNXI9Wfb4ZrBfKw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "rec6EU8azfOJGd2xw",
        "row": 12582,
        "state": "FLORIDA",
        "group": "78107004",
        "group_num": [
            "reccJFb1dhmsjysFJ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "rec6KWnH4cktJQcgo",
        "row": 12578,
        "state": "FLORIDA",
        "group": "223219",
        "group_num": [
            "rec6eSSzSS1yf3E3X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "rec9GUmBy6jnSsd3T",
        "row": 12583,
        "state": "FLORIDA",
        "group": "B5174003",
        "group_num": [
            "recMZUw9PBpnkShGr"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recbX0iJNJIUWqHHZ",
        "row": 12619,
        "state": "FLORIDA",
        "group": "14163502",
        "group_num": [
            "rec8OLcSy8YE4o8rz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 702,
        "2023": 0,
        "2024": 0,
        "id": "recbMBsTtpEMHtugD",
        "row": 12598,
        "state": "FLORIDA",
        "group": "0EU004",
        "group_num": [
            "recP0WBN0K5RxJDLM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recbgWr0u1pKoaV1R",
        "row": 12584,
        "state": "FLORIDA",
        "group": "10006507",
        "group_num": [
            "reczZraRj9yCe3sC2"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 716,
        "2023": 0,
        "2024": 0,
        "id": "recqNwFIf5NAiEKoG",
        "row": 12600,
        "state": "FLORIDA",
        "group": "YNS017",
        "group_num": [
            "recSI60q2K4jQ6ecU"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 738,
        "2023": 0,
        "2024": 0,
        "id": "recrYMgNI4nmvCidx",
        "row": 12604,
        "state": "FLORIDA",
        "group": "99999M22",
        "group_num": [
            "rec7DWeCNNpc58gd5"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recsnz9CFMK0zY0Z1",
        "row": 12576,
        "state": "FLORIDA",
        "group": "716020401",
        "group_num": [
            "recjW1dpG1wcmOen3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "rectkvWSVY5WMFVyp",
        "row": 12621,
        "state": "FLORIDA",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recurH4BUkZ6x936q",
        "row": 12577,
        "state": "FLORIDA",
        "group": "024745",
        "group_num": [
            "recSIPxIcdZFAyCyl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recEvTDxJ214z44Uv",
        "row": 12581,
        "state": "FLORIDA",
        "group": "72570002",
        "group_num": [
            "recTGGYHNSlosekfh"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 714,
        "2023": 0,
        "2024": 0,
        "id": "recGviZCjGE3owjip",
        "row": 12599,
        "state": "FLORIDA",
        "group": "10621286",
        "group_num": [
            "rec6OoA0p3PFeXdO2"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recGC4SlhiHWQX6Nf",
        "row": 12573,
        "state": "FLORIDA",
        "group": "259806",
        "group_num": [
            "recO57ouxIijHf2Y3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 664,
        "2023": 0,
        "2024": 0,
        "id": "recLyMClGiSY2jifI",
        "row": 12588,
        "state": "FLORIDA",
        "group": "202153",
        "group_num": [
            "recw1eWkgz8H9hvbj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 0,
        "2024": 0,
        "id": "recN6ojl1anVHDMpc",
        "row": 12575,
        "state": "FLORIDA",
        "group": "00000735",
        "group_num": [
            "rec0ie3h2GJBlfGJS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF RI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rece5icFv5FUFKDYj",
        "row": 12642,
        "state": "FLORIDA",
        "group": "L00296M002",
        "group_num": [
            "rec7tYKxFnL4Ajc8K"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rece78AXetjjSQRji",
        "row": 12663,
        "state": "FLORIDA",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recenWAHDdq57rhE3",
        "row": 12650,
        "state": "FLORIDA",
        "group": "235718",
        "group_num": [
            "reckDEWhUsi53z0VB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recftfXkiG9k59qHf",
        "row": 12641,
        "state": "FLORIDA",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recii8JEJQp7S4QRv",
        "row": 12647,
        "state": "FLORIDA",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "Detox",
        "provider": "BC OF ID (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recmsFJ7gKuCuhIAy",
        "row": 12649,
        "state": "FLORIDA",
        "group": "101935002",
        "group_num": [
            "recyXAqc7N91z8L6F"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recnmoE03122N43Ux",
        "row": 12634,
        "state": "FLORIDA",
        "group": "103556002",
        "group_num": [
            "recZbzewteJvLuxb9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recOILVpMxsNHeazp",
        "row": 12637,
        "state": "FLORIDA",
        "group": "14166371",
        "group_num": [
            "recRKpBpuqYww4vro"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recQWHslBPkY9xlqe",
        "row": 12629,
        "state": "FLORIDA",
        "group": "00418019",
        "group_num": [
            "recMsXdc89OQiweGs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recVa63jO8WILEM74",
        "row": 12656,
        "state": "FLORIDA",
        "group": "10044688",
        "group_num": [
            "recJesfYoUy3yfwJK"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BCBS OF UT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recVmBzVo8bhcmtwD",
        "row": 12627,
        "state": "FLORIDA",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recVq5QaRg0WANmbg",
        "row": 12644,
        "state": "FLORIDA",
        "group": "SP3225M001",
        "group_num": [
            "recA6XuusqcRBElmC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec0O5YMj3fvmXTKV",
        "row": 12655,
        "state": "FLORIDA",
        "group": "001902740PF10",
        "group_num": [
            "recrqqiupvdm0DHQE"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "rec1YXwXJtYdEto8J",
        "row": 12623,
        "state": "FLORIDA",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "2023": 0,
        "2024": 0,
        "id": "rec1iYAUEi6XPJ9WG",
        "row": 12670,
        "state": "FLORIDA",
        "group": "99999ZF2",
        "group_num": [
            "recdL22CNhBOTlwFY"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "rec5sQVfIINHg7uyP",
        "row": 12625,
        "state": "FLORIDA",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec5lc8ob5IZzQfDQ",
        "row": 12664,
        "state": "FLORIDA",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec67OrEI7XU7J9mr",
        "row": 12643,
        "state": "FLORIDA",
        "group": "L00296M002",
        "group_num": [
            "rec7tYKxFnL4Ajc8K"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec7T2xPuFGe9F2Yd",
        "row": 12628,
        "state": "FLORIDA",
        "group": "J6460001",
        "group_num": [
            "reccVlxK7JR3a3IAD"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec7AvllL1c2nww2K",
        "row": 12639,
        "state": "FLORIDA",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec9IFN1SelD0OyPo",
        "row": 12630,
        "state": "FLORIDA",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "rec9hE1HlqzwvwJvO",
        "row": 12652,
        "state": "FLORIDA",
        "group": "100740",
        "group_num": [
            "recCWROII3kJIr0Qi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recdFmkmwL3imHDW9",
        "row": 12635,
        "state": "FLORIDA",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recdoBcfQxfMrpZ13",
        "row": 12646,
        "state": "FLORIDA",
        "group": "VA3051M001",
        "group_num": [
            "recTI3khmT5KXcIAz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recpPmEzwrEONWKWl",
        "row": 12645,
        "state": "FLORIDA",
        "group": "270114M1AN",
        "group_num": [
            "recmY9oSD6gAVlVHw"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recssqQVmtZRonJCO",
        "row": 12636,
        "state": "FLORIDA",
        "group": "270114M1AN",
        "group_num": [
            "recmY9oSD6gAVlVHw"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recuqjvV9lP4bSEWl",
        "row": 12648,
        "state": "FLORIDA",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recvPkQ0t0ue8C4E3",
        "row": 12654,
        "state": "FLORIDA",
        "group": "11445077",
        "group_num": [
            "recUxAG2mIRGicLIg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recvbQr3Y5cGZxkwU",
        "row": 12632,
        "state": "FLORIDA",
        "group": "031936",
        "group_num": [
            "rec4IW3CfL9eInXO4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 864,
        "2023": 0,
        "2024": 0,
        "id": "recxNZ8mz4wdTskqk",
        "row": 12671,
        "state": "FLORIDA",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recyyD5blnhEBvf20",
        "row": 12653,
        "state": "FLORIDA",
        "group": "10652409",
        "group_num": [
            "rec9Qbe7qcA2lwg7z"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recz9hf4Lw115eUVZ",
        "row": 12657,
        "state": "FLORIDA",
        "group": "00233307",
        "group_num": [
            "rechPxXocjKVGKRS2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recADOEzI1IySNXxZ",
        "row": 12638,
        "state": "FLORIDA",
        "group": "10567859",
        "group_num": [
            "recTzMgKXH3zlcP2q"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recCWW3yVtYO3ePsH",
        "row": 12624,
        "state": "FLORIDA",
        "group": "14172597",
        "group_num": [
            "rectS52nfA9upPXoE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recChch9bz8grTyHu",
        "row": 12631,
        "state": "FLORIDA",
        "group": "36956",
        "group_num": [
            "recJPb3hYIT6i6oYU"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF IA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 0,
        "2024": 0,
        "id": "recDufZVSc1ylbPJ8",
        "row": 12622,
        "state": "FLORIDA",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recEYoDNvF9DmYWH5",
        "row": 12662,
        "state": "FLORIDA",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recFU7pnu7cAgd707",
        "row": 12659,
        "state": "FLORIDA",
        "group": "78K40ERC",
        "group_num": [
            "recxOwQVc6l3tRkyw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recGw1lE7VYAKtd3u",
        "row": 12633,
        "state": "FLORIDA",
        "group": "103556002",
        "group_num": [
            "recZbzewteJvLuxb9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recG73Waf71rB2pFE",
        "row": 12640,
        "state": "FLORIDA",
        "group": "10621286",
        "group_num": [
            "rec6OoA0p3PFeXdO2"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 853,
        "2023": 0,
        "2024": 0,
        "id": "recI56FthVMXrqpNt",
        "row": 12669,
        "state": "FLORIDA",
        "group": "99999ZKH",
        "group_num": [
            "recV5Axor0h1yfdZf"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recIgvHXNOFMBJYnA",
        "row": 12626,
        "state": "FLORIDA",
        "group": "00233307",
        "group_num": [
            "rechPxXocjKVGKRS2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF MO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recLEDmHWJnSZ9QVq",
        "row": 12660,
        "state": "FLORIDA",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MS (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recLheRNuz2QfxxYN",
        "row": 12651,
        "state": "FLORIDA",
        "group": "YNS017",
        "group_num": [
            "recSI60q2K4jQ6ecU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recM5dxk1N5k3dgHt",
        "row": 12658,
        "state": "FLORIDA",
        "group": "VA3051M001",
        "group_num": [
            "recTI3khmT5KXcIAz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 0,
        "2024": 0,
        "id": "recMkZja0v0L264D4",
        "row": 12661,
        "state": "FLORIDA",
        "group": "235718",
        "group_num": [
            "reckDEWhUsi53z0VB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 945,
        "2023": 0,
        "2024": 0,
        "id": "recjAe6HQ6lWEQm9I",
        "row": 12715,
        "state": "FLORIDA",
        "group": "GB0192M005",
        "group_num": [
            "recdsiZKzqkIq5A8N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recjQqUQdr9uIe5bc",
        "row": 12709,
        "state": "FLORIDA",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "reckcm1uoh1qrHPtg",
        "row": 12705,
        "state": "FLORIDA",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 888,
        "2023": 0,
        "2024": 0,
        "id": "recm3dnMGFRwSE9jX",
        "row": 12680,
        "state": "FLORIDA",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recmkzqmmWDJnGVeR",
        "row": 12699,
        "state": "FLORIDA",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 895,
        "2023": 0,
        "2024": 0,
        "id": "recOg1oRKQ6Hzs1pS",
        "row": 12687,
        "state": "FLORIDA",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 874,
        "2023": 0,
        "2024": 0,
        "id": "recVrLXk98ToUSa6u",
        "row": 12675,
        "state": "FLORIDA",
        "group": "14166371",
        "group_num": [
            "recRKpBpuqYww4vro"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recWVxLqyZVxe3QxY",
        "row": 12708,
        "state": "FLORIDA",
        "group": "106149",
        "group_num": [
            "recSJzIRke938LdFa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 935,
        "2023": 0,
        "2024": 0,
        "id": "rec388VTLMKFAEtfp",
        "row": 12713,
        "state": "FLORIDA",
        "group": "99999M53",
        "group_num": [
            "rechBoXWoifN43YMB"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "rec4xHsOCR8CbYByb",
        "row": 12702,
        "state": "FLORIDA",
        "group": "001901078MD10247",
        "group_num": [
            "recxwJ7plmKuD4gYr"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "2023": 0,
        "2024": 0,
        "id": "rec48ALi9hOOHJrgA",
        "row": 12676,
        "state": "FLORIDA",
        "group": "99999MBS",
        "group_num": [
            "reccLqvLGwFx1cp3u"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "rec5EgiqINRCg75Dh",
        "row": 12703,
        "state": "FLORIDA",
        "group": "005801937FL10016",
        "group_num": [
            "recrgbXfFRnGVJCHq"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "rec6SbdvHkmqGkQm5",
        "row": 12704,
        "state": "FLORIDA",
        "group": "J0878001",
        "group_num": [
            "rec1D9MF0qRWe6F1f"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "rec8qR06p6blQNNaB",
        "row": 12700,
        "state": "FLORIDA",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recpXUiDBKfkcIS7D",
        "row": 12710,
        "state": "FLORIDA",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "2023": 0,
        "2024": 0,
        "id": "recrynXbfn1wqHW4F",
        "row": 12681,
        "state": "FLORIDA",
        "group": "99999U6H",
        "group_num": [
            "recGzyD7zsfeBZWX8"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 927,
        "2023": 0,
        "2024": 0,
        "id": "recuQR9MDCsiMqu4R",
        "row": 12712,
        "state": "FLORIDA",
        "group": "P09407",
        "group_num": [
            "recjwqbnKaMFnfmbz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recw7TgQOSiCtmbqc",
        "row": 12711,
        "state": "FLORIDA",
        "group": "00373D50078",
        "group_num": [
            "recFMp0M8r0u5tHh8"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 937,
        "2023": 0,
        "2024": 0,
        "id": "recwcBvENUYWwBY46",
        "row": 12714,
        "state": "FLORIDA",
        "group": "001903623MD10006",
        "group_num": [
            "recYoAfJgDHuE9JHz"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recwmNTGb9gV8HQWg",
        "row": 12698,
        "state": "FLORIDA",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "reczPQBx64w24C4cx",
        "row": 12697,
        "state": "FLORIDA",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recAgXyA7fJ9dBmps",
        "row": 12707,
        "state": "FLORIDA",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 904,
        "2023": 0,
        "2024": 0,
        "id": "recBpQIqsAOGElW0u",
        "row": 12694,
        "state": "FLORIDA",
        "group": "005802609FL10006",
        "group_num": [
            "recIMy3eyRbYO7CmN"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 891,
        "2023": 0,
        "2024": 0,
        "id": "recDp51ij0SC2Ah9Q",
        "row": 12684,
        "state": "FLORIDA",
        "group": "99999Z6J",
        "group_num": [
            "rec1bu6tlEbnPPKsQ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "recEgTWesgx1M5OW0",
        "row": 12721,
        "state": "FLORIDA",
        "group": "J7829002",
        "group_num": [
            "rec0E9xQcfwhCqmCn"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recGwj7lxgiCR2lLU",
        "row": 12706,
        "state": "FLORIDA",
        "group": "174245MGVA",
        "group_num": [
            "rechrSZuXtJff7kxG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 893,
        "2023": 0,
        "2024": 0,
        "id": "recGg0yRxudGohkd3",
        "row": 12685,
        "state": "FLORIDA",
        "group": "F6D",
        "group_num": [
            "reciqV0knLTr3XjJo"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "2023": 0,
        "2024": 0,
        "id": "recKTuhQayDFuW1z7",
        "row": 12701,
        "state": "FLORIDA",
        "group": "716001829",
        "group_num": [
            "recb3Io2KejzW4kuG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 876,
        "2023": 0,
        "2024": 0,
        "id": "recLjt5wW0AQawqva",
        "row": 12678,
        "state": "FLORIDA",
        "group": "10053820",
        "group_num": [
            "rec0i4l63BNSbFHY1"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "receyrdzQu61MBaRw",
        "row": 12722,
        "state": "FLORIDA",
        "group": "66CV00",
        "group_num": [
            "recu7rOFwvWt8t17y"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recfqu7b753y7y4fx",
        "row": 12739,
        "state": "FLORIDA",
        "group": "99999M52",
        "group_num": [
            "reciMNW1Ks47Kg9yl"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "reciBYCcuYrqMovWb",
        "row": 12741,
        "state": "FLORIDA",
        "group": "99999ZKB",
        "group_num": [
            "recVgLEO1e0mnQTvp"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "reckSLKX1GVoipQ3c",
        "row": 12737,
        "state": "FLORIDA",
        "group": "99999M21",
        "group_num": [
            "rec4eJW8OCpY2NYeC"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "recQ0JVHUNg2z82ff",
        "row": 12725,
        "state": "FLORIDA",
        "group": "41954029",
        "group_num": [
            "recnbbmaof5cdfZ4d"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1084,
        "2023": 0,
        "2024": 0,
        "id": "recTHso8No3QT0qfC",
        "row": 12752,
        "state": "FLORIDA",
        "group": "005801171DC10006",
        "group_num": [
            "rec7MMSeHWCoORIi9"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 969,
        "2023": 0,
        "2024": 0,
        "id": "recZmd7s37Xx3I8Vz",
        "row": 12729,
        "state": "FLORIDA",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "rec1W2yeBrt06qPOl",
        "row": 12724,
        "state": "FLORIDA",
        "group": "W41524M003",
        "group_num": [
            "recytMKFAaPA3ET4W"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec11nK2y6uu7dwng",
        "row": 12743,
        "state": "FLORIDA",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec24P8B8FqRraDcu",
        "row": 12735,
        "state": "FLORIDA",
        "group": "174572FEA1",
        "group_num": [
            "recNucgeHfJs3Zl8o"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 972,
        "2023": 0,
        "2024": 0,
        "id": "rec2lxoMAK3468hW9",
        "row": 12731,
        "state": "FLORIDA",
        "group": "10652409",
        "group_num": [
            "rec9Qbe7qcA2lwg7z"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "rec3eDrO3B6n9B1Tt",
        "row": 12740,
        "state": "FLORIDA",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "rec432l2zZc6rFp6d",
        "row": 12728,
        "state": "FLORIDA",
        "group": "99999U5W",
        "group_num": [
            "recb3jY1f0ck15XAZ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "rec7weoBOmjHoEGGF",
        "row": 12726,
        "state": "FLORIDA",
        "group": "W42781M001",
        "group_num": [
            "reckehoDBHehBMaat"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "rec7eN0wM1i1dYMaI",
        "row": 12723,
        "state": "FLORIDA",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1133,
        "2023": 0,
        "2024": 0,
        "id": "rec8yQ6NM0X1SIDMe",
        "row": 12759,
        "state": "FLORIDA",
        "group": "002367544",
        "group_num": [
            "receCXgvFeMbcFeq5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1188,
        "2023": 0,
        "2024": 0,
        "id": "rec8piVLgI35UXDbK",
        "row": 12764,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1210,
        "2023": 0,
        "2024": 0,
        "id": "rec96SdkyjWKJEVOD",
        "row": 12771,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "recb08TVqu8uUoFSB",
        "row": 12754,
        "state": "FLORIDA",
        "group": "00759060000",
        "group_num": [
            "recs43q0lrBDCIuw5"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1142,
        "2023": 0,
        "2024": 0,
        "id": "recb7gAS1UtldeiSX",
        "row": 12762,
        "state": "FLORIDA",
        "group": "002367544",
        "group_num": [
            "receCXgvFeMbcFeq5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "reccIL4QoWvvZicb3",
        "row": 12742,
        "state": "FLORIDA",
        "group": "99999ZS1",
        "group_num": [
            "rec3FPxlBE4aPcOoD"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recr0gezYLJbuksCz",
        "row": 12736,
        "state": "FLORIDA",
        "group": "99999M16",
        "group_num": [
            "recvZyq7hmXrWAXQ8"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 0,
        "2024": 0,
        "id": "recspCvYWiy5fX2Nn",
        "row": 12727,
        "state": "FLORIDA",
        "group": "005801171DC10006",
        "group_num": [
            "rec7MMSeHWCoORIi9"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recuxzHkQCx8ekdFZ",
        "row": 12745,
        "state": "FLORIDA",
        "group": "B2060012",
        "group_num": [
            "recypVOjZc5cYcAps"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 970,
        "2023": 0,
        "2024": 0,
        "id": "recvgFJhvIDOy90sI",
        "row": 12730,
        "state": "FLORIDA",
        "group": "005802609FL10006",
        "group_num": [
            "recIMy3eyRbYO7CmN"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recvg8k1yTlQiiOFL",
        "row": 12744,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recIU6pHepb4U4rww",
        "row": 12738,
        "state": "FLORIDA",
        "group": "99999M28",
        "group_num": [
            "recUM6s3gCPp0TkGc"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 0,
        "2024": 0,
        "id": "recJPDVxD133vW14j",
        "row": 12746,
        "state": "FLORIDA",
        "group": "008505X0075",
        "group_num": [
            "recXxNNdISTnOIhAG"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "2023": 0,
        "2024": 0,
        "id": "recMJB1hunB8h5GKo",
        "row": 12749,
        "state": "FLORIDA",
        "group": "788800",
        "group_num": [
            "recuCT5ps3qQV3f4y"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1586,
        "2023": 0,
        "2024": 0,
        "id": "recf93srW1aTGSsHM",
        "row": 12803,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1659,
        "2023": 0,
        "2024": 0,
        "id": "recfqUjI4LxfMZxfd",
        "row": 12810,
        "state": "FLORIDA",
        "group": "JVA002M042",
        "group_num": [
            "recoZDszkShrcJv4M"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1716,
        "2023": 0,
        "2024": 0,
        "id": "recntUBySBHkDzy1t",
        "row": 12818,
        "state": "FLORIDA",
        "group": "213065M3AA",
        "group_num": [
            "recA1vFmcbTZipOo1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1738,
        "2023": 0,
        "2024": 0,
        "id": "recRZrOSfEUC2XuDH",
        "row": 12820,
        "state": "FLORIDA",
        "group": "716029942",
        "group_num": [
            "recxKC5wOeROZbxNe"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1689,
        "2023": 0,
        "2024": 0,
        "id": "rec0Wx6GKxrrb6lJS",
        "row": 12815,
        "state": "FLORIDA",
        "group": "1871VL",
        "group_num": [
            "recqNykDeJWy8f221"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1598,
        "2023": 0,
        "2024": 0,
        "id": "rec6XPdkWOLvr5iaW",
        "row": 12804,
        "state": "FLORIDA",
        "group": "174084M1A1",
        "group_num": [
            "recBkt5xMrE8M6DGs"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1644,
        "2023": 0,
        "2024": 0,
        "id": "recqz8ealrjoxFP4J",
        "row": 12809,
        "state": "FLORIDA",
        "group": "GA7186M011",
        "group_num": [
            "recNY02sWLnlNQz5n"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1392,
        "2023": 0,
        "2024": 0,
        "id": "recsHpFdlUhqXFdx7",
        "row": 12792,
        "state": "FLORIDA",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1458,
        "2023": 0,
        "2024": 0,
        "id": "rect967VndD2tipNn",
        "row": 12795,
        "state": "FLORIDA",
        "group": "10567859",
        "group_num": [
            "recTzMgKXH3zlcP2q"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1306,
        "2023": 0,
        "2024": 0,
        "id": "reczA8vF1nDB9jZRc",
        "row": 12778,
        "state": "FLORIDA",
        "group": "00373D50078",
        "group_num": [
            "recFMp0M8r0u5tHh8"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1633,
        "2023": 0,
        "2024": 0,
        "id": "recIZPhU3csCohlmr",
        "row": 12808,
        "state": "FLORIDA",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1385,
        "2023": 0,
        "2024": 0,
        "id": "recJJu7m1Bsd0VjS8",
        "row": 12791,
        "state": "FLORIDA",
        "group": "002365119",
        "group_num": [
            "recaJaSCpDNdtSvZL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1812,
        "2023": 0,
        "2024": 0,
        "id": "recgyUqu0eUzMeVQP",
        "row": 12844,
        "state": "FLORIDA",
        "group": "174295M2A6",
        "group_num": [
            "recDbJ0hRw5WHmIBM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1871,
        "2023": 0,
        "2024": 0,
        "id": "recPDmE74Ta3joRWi",
        "row": 12851,
        "state": "FLORIDA",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1884,
        "2023": 0,
        "2024": 0,
        "id": "recPl9iK7eNk7YWPC",
        "row": 12855,
        "state": "FLORIDA",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1792,
        "2023": 0,
        "2024": 0,
        "id": "rec1mYHIdI1Vdf6g6",
        "row": 12826,
        "state": "FLORIDA",
        "group": "201064HAFA",
        "group_num": [
            "recgZSxwypZCfNOoF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1865,
        "2023": 0,
        "2024": 0,
        "id": "rec21L9X4WPBTHAK1",
        "row": 12848,
        "state": "FLORIDA",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2045,
        "2023": 0,
        "2024": 0,
        "id": "rec4dGTnBoGaFEcP4",
        "row": 12864,
        "state": "FLORIDA",
        "group": "174572BBA1",
        "group_num": [
            "rectZizwDUkziVWiL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2108,
        "2023": 0,
        "2024": 0,
        "id": "recuF6gbo5A2zc2GR",
        "row": 12870,
        "state": "FLORIDA",
        "group": "W42781M001",
        "group_num": [
            "reckehoDBHehBMaat"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2136,
        "2023": 0,
        "2024": 0,
        "id": "recv9tMBELUDAAooA",
        "row": 12871,
        "state": "FLORIDA",
        "group": "14163529",
        "group_num": [
            "recMX5fy0pcUFAiVo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2025,
        "2023": 0,
        "2024": 0,
        "id": "recwattodSV6zeNcD",
        "row": 12863,
        "state": "FLORIDA",
        "group": "174572BBA1",
        "group_num": [
            "rectZizwDUkziVWiL"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1940,
        "2023": 0,
        "2024": 0,
        "id": "recwaRG6Rq0lw8cL8",
        "row": 12858,
        "state": "FLORIDA",
        "group": "270139M1BA",
        "group_num": [
            "recRUQ9itfNWtcb4N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1754,
        "2023": 0,
        "2024": 0,
        "id": "recxeCs33s8Vhe7XA",
        "row": 12824,
        "state": "FLORIDA",
        "group": "L03519M014",
        "group_num": [
            "rec9ig0At6JkM8Niv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1746,
        "2023": 0,
        "2024": 0,
        "id": "recE4RkMTS2fdbsJU",
        "row": 12822,
        "state": "FLORIDA",
        "group": "10006612",
        "group_num": [
            "rec741Por2kjey7qD"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2347,
        "2023": 0,
        "2024": 0,
        "id": "recgEFDSsXyMlj7a6",
        "row": 12881,
        "state": "FLORIDA",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2781,
        "2023": 0,
        "2024": 0,
        "id": "recjz8VL84hpZqEnN",
        "row": 12914,
        "state": "FLORIDA",
        "group": "00759060000",
        "group_num": [
            "recs43q0lrBDCIuw5"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2266,
        "2023": 0,
        "2024": 0,
        "id": "recj268AKMUHEJLcS",
        "row": 12878,
        "state": "FLORIDA",
        "group": "002132100",
        "group_num": [
            "recfWWW9WrjntIw9O"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2464,
        "2023": 0,
        "2024": 0,
        "id": "recmA0PoSov35Xkjy",
        "row": 12903,
        "state": "FLORIDA",
        "group": "210029M2AA",
        "group_num": [
            "rec0yKlxkxY3Yf8Qn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF WI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2401,
        "2023": 0,
        "2024": 0,
        "id": "recUrZiHcZUgO3vDS",
        "row": 12900,
        "state": "FLORIDA",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recVH6PFxzTT0Dx4X",
        "row": 12884,
        "state": "FLORIDA",
        "group": "011061",
        "group_num": [
            "recVyR3sLysOG5I5c"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2282,
        "2023": 0,
        "2024": 0,
        "id": "rec1cUzalOORsLAUq",
        "row": 12879,
        "state": "FLORIDA",
        "group": "001902740PF10",
        "group_num": [
            "recrqqiupvdm0DHQE"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2332,
        "2023": 0,
        "2024": 0,
        "id": "rec5wJSHXLbS4jdsA",
        "row": 12880,
        "state": "FLORIDA",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recqtbttPEs7yWyKD",
        "row": 12915,
        "state": "FLORIDA",
        "group": "989242870",
        "group_num": [
            "recVJggBNZCMCRjvU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2517,
        "2023": 0,
        "2024": 0,
        "id": "rectK77gOaCOLhmUF",
        "row": 12907,
        "state": "FLORIDA",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2552,
        "2023": 0,
        "2024": 0,
        "id": "recvxi7fl8QKksVDE",
        "row": 12909,
        "state": "FLORIDA",
        "group": "VA2094M011",
        "group_num": [
            "recolXCz1Li1ju21S"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 0,
        "2024": 0,
        "id": "recxqMuOVLitESEKB",
        "row": 12899,
        "state": "FLORIDA",
        "group": "P06574",
        "group_num": [
            "reczLzYnPIOesW7Cs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2739,
        "2023": 0,
        "2024": 0,
        "id": "recyljgxYOaJgduDg",
        "row": 12913,
        "state": "FLORIDA",
        "group": "201075MGDG",
        "group_num": [
            "recR9u7WDJGIaJY5c"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2488,
        "2023": 0,
        "2024": 0,
        "id": "recMnmgy4roaQEdz7",
        "row": 12905,
        "state": "FLORIDA",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3121,
        "2023": 0,
        "2024": 0,
        "id": "receHySpvTKGu1LxN",
        "row": 12970,
        "state": "FLORIDA",
        "group": "L03519M014",
        "group_num": [
            "rec9ig0At6JkM8Niv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3099,
        "2023": 0,
        "2024": 0,
        "id": "recl6Vr1weXUYQPLQ",
        "row": 12967,
        "state": "FLORIDA",
        "group": "270159F1JA",
        "group_num": [
            "rectH3OBEc8EGN8F5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3096,
        "2023": 0,
        "2024": 0,
        "id": "recRkOztYwHRk2h71",
        "row": 12966,
        "state": "FLORIDA",
        "group": "27008564KA",
        "group_num": [
            "recIpCUoyGz44QghV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recWMFO5ify7yWKmQ",
        "row": 12941,
        "state": "FLORIDA",
        "group": "276945M004",
        "group_num": [
            "rec4hkUQxE4q1Pby7"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recYXfZUhbQltjY12",
        "row": 12943,
        "state": "FLORIDA",
        "group": "002321613",
        "group_num": [
            "recWLa8lrFMh2Y5VQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec4yd7cM2WceLpX4",
        "row": 12942,
        "state": "FLORIDA",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3036,
        "2023": 0,
        "2024": 0,
        "id": "rec5yvnrYhdHnIDA7",
        "row": 12963,
        "state": "FLORIDA",
        "group": "174245MGVA",
        "group_num": [
            "rechrSZuXtJff7kxG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec5dlFJx7AdIfsQu",
        "row": 12945,
        "state": "FLORIDA",
        "group": "011061",
        "group_num": [
            "recVyR3sLysOG5I5c"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3107,
        "2023": 0,
        "2024": 0,
        "id": "rec7z2GRvaCx6urXV",
        "row": 12968,
        "state": "FLORIDA",
        "group": "201028M1PA",
        "group_num": [
            "rec5quPkXyZuPMSOC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "2023": 0,
        "2024": 0,
        "id": "recw05C6T8SIoUIIC",
        "row": 12937,
        "state": "FLORIDA",
        "group": "715984202",
        "group_num": [
            "recoTgu9bzEzPbRW7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recHcrfUVvp9PtME3",
        "row": 12944,
        "state": "FLORIDA",
        "group": "10006612",
        "group_num": [
            "rec741Por2kjey7qD"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF DE (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4520,
        "2023": 0,
        "2024": 0,
        "id": "receTW7wOlanAZna9",
        "row": 12998,
        "state": "FLORIDA",
        "group": "27008564KA",
        "group_num": [
            "recIpCUoyGz44QghV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3353,
        "2023": 0,
        "2024": 0,
        "id": "receJBNKqxD1xXL0g",
        "row": 12977,
        "state": "FLORIDA",
        "group": "W80533M011",
        "group_num": [
            "recki0fzlgsm2kpJs"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF WI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "2023": 0,
        "2024": 0,
        "id": "recjoRgXkQORqbCPS",
        "row": 12983,
        "state": "FLORIDA",
        "group": "276945M004",
        "group_num": [
            "rec4hkUQxE4q1Pby7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3156,
        "2023": 0,
        "2024": 0,
        "id": "recR8FZ4AXtEt1RYb",
        "row": 12972,
        "state": "FLORIDA",
        "group": "213051A3FA",
        "group_num": [
            "rechYPCVyUNvxNgPp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "2023": 0,
        "2024": 0,
        "id": "recUJ7vbptZFIFoyG",
        "row": 13006,
        "state": "FLORIDA",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4260,
        "2023": 0,
        "2024": 0,
        "id": "recZUS1uEPqVeE3GG",
        "row": 12993,
        "state": "FLORIDA",
        "group": "174295M2A6",
        "group_num": [
            "recDbJ0hRw5WHmIBM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec0z0yyB9MEOS1jw",
        "row": 13015,
        "state": "FLORIDA",
        "group": "00935000000",
        "group_num": [
            "recs2lJlQqikf2G7E"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4958,
        "2023": 0,
        "2024": 0,
        "id": "rec9EGv02VANCbKiH",
        "row": 13002,
        "state": "FLORIDA",
        "group": "W80533M011",
        "group_num": [
            "recki0fzlgsm2kpJs"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4144,
        "2023": 0,
        "2024": 0,
        "id": "recbZ19YapuIgSbhl",
        "row": 12988,
        "state": "FLORIDA",
        "group": "201028M1PA",
        "group_num": [
            "rec5quPkXyZuPMSOC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3415,
        "2023": 0,
        "2024": 0,
        "id": "recpKknBJMevN2YCb",
        "row": 12979,
        "state": "FLORIDA",
        "group": "14163529",
        "group_num": [
            "recMX5fy0pcUFAiVo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5233,
        "2023": 0,
        "2024": 0,
        "id": "recp3wxuduoWRmIiv",
        "row": 13005,
        "state": "FLORIDA",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recqcUcVFWhm7h7de",
        "row": 13014,
        "state": "FLORIDA",
        "group": "935000000",
        "group_num": [
            "rec7A3vvnWnZsnSWv"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recxukwo6o99Z5Tio",
        "row": 13010,
        "state": "FLORIDA",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recyJuMemjSYTbnre",
        "row": 13009,
        "state": "FLORIDA",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 17212,
        "2023": 0,
        "2024": 0,
        "id": "recCMfzVhttf1tu8H",
        "row": 13017,
        "state": "FLORIDA",
        "group": "935000000",
        "group_num": [
            "rec7A3vvnWnZsnSWv"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3167,
        "2023": 0,
        "2024": 0,
        "id": "recESAId4t2fRej0P",
        "row": 12973,
        "state": "FLORIDA",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recFt81Od8JTatK25",
        "row": 13008,
        "state": "FLORIDA",
        "group": "213051A3FA",
        "group_num": [
            "rechYPCVyUNvxNgPp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5157,
        "2023": 0,
        "2024": 0,
        "id": "recFLyB3aT8m0Qkvi",
        "row": 13004,
        "state": "FLORIDA",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3219,
        "2023": 0,
        "2024": 0,
        "id": "recGsb7RWCgtUyMyg",
        "row": 12974,
        "state": "FLORIDA",
        "group": "201064HAFA",
        "group_num": [
            "recgZSxwypZCfNOoF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "2023": 0,
        "2024": 0,
        "id": "recMu2GG5hF1Lh1HX",
        "row": 12984,
        "state": "FLORIDA",
        "group": "002321613",
        "group_num": [
            "recWLa8lrFMh2Y5VQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4149,
        "2023": 0,
        "2024": 0,
        "id": "recNLYTFWbwAVLerm",
        "row": 12990,
        "state": "FLORIDA",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF FL)"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "recjv5KQB7qClsOOB",
        "row": 8032,
        "state": "FLORIDA",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "reckNITAQsTgRA5xe",
        "row": 8038,
        "state": "FLORIDA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 80,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclD9sIhbIy9QVvD",
        "row": 8047,
        "state": "FLORIDA",
        "group": "P03418",
        "group_num": [
            "recMzRo5YPjyVpsb7"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "reclbM5WmsO7BEd6i",
        "row": 8050,
        "state": "FLORIDA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 1500,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recn3q7WzIMC6JZyH",
        "row": 8046,
        "state": "FLORIDA",
        "group": "P03418",
        "group_num": [
            "recMzRo5YPjyVpsb7"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPQ7l0f4YEXtRbg",
        "row": 8041,
        "state": "FLORIDA",
        "group": "NYS018",
        "group_num": [
            "recYA90msKsTqpsZc"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 2446,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPeWtNIZHyW4A4z",
        "row": 8016,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPg3nowsxhsJIPU",
        "row": 8034,
        "state": "FLORIDA",
        "group": "NYS003",
        "group_num": [
            "recTA7rPv4OxT3IuB"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1525,
        "2022": 4017,
        "2023": 0,
        "2024": 0,
        "id": "recT4AAWDkEbpDLQ1",
        "row": 8029,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1260,
        "2023": 1244,
        "2024": 0,
        "id": "recVr3NrM8XAn4Mvn",
        "row": 8021,
        "state": "FLORIDA",
        "group": "106528",
        "group_num": [
            "recke3ANugOvCT1FY"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWRBpRIKTvr84Mv",
        "row": 8049,
        "state": "FLORIDA",
        "group": "SAG101",
        "group_num": [
            "rectMvju4ONqEXNWP"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 2300,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZfFxDd3fSMqCDM",
        "row": 8035,
        "state": "FLORIDA",
        "group": "NYS003",
        "group_num": [
            "recTA7rPv4OxT3IuB"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0Dj3gOPZsNtUcE",
        "row": 8031,
        "state": "FLORIDA",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 1422,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec0M6wz7MNJqPafp",
        "row": 8030,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "rec17LDOl5YyYMKDs",
        "row": 8036,
        "state": "FLORIDA",
        "group": "NYS010",
        "group_num": [
            "rec4tZqWTgezsRyyB"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 4750,
        "2022": 4750,
        "2023": 4750,
        "2024": 0,
        "id": "rec1fcEqpunc3HrKc",
        "row": 8039,
        "state": "FLORIDA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec4Fvf5b4vXHrhrG",
        "row": 8033,
        "state": "FLORIDA",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 1830,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4ptHp8oomqQXx6",
        "row": 8017,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5DepffFXSjgx9f",
        "row": 8044,
        "state": "FLORIDA",
        "group": "P03418",
        "group_num": [
            "recMzRo5YPjyVpsb7"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 80,
        "2021": 60,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7ND7J8umZoFyOl",
        "row": 8018,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 600,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recc2Fi1wYaZCuz2M",
        "row": 8027,
        "state": "FLORIDA",
        "group": "2459482",
        "group_num": [
            "recMl9wkjwFkVLuFx"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 1200,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccpDHixrtt9C4Ka",
        "row": 8025,
        "state": "FLORIDA",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recrYmhMWrsm5cwIF",
        "row": 8053,
        "state": "FLORIDA",
        "group": "YO0731",
        "group_num": [
            "rec4SuBFHdneHaFk9"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "rectYIEBqnI8uv87y",
        "row": 8043,
        "state": "FLORIDA",
        "group": "NYSHIP",
        "group_num": [
            "recc2sfOZHzIb0rR4"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "recvnnZQxxceXkdA4",
        "row": 8037,
        "state": "FLORIDA",
        "group": "NYS010",
        "group_num": [
            "rec4tZqWTgezsRyyB"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 1125,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recx5xJQwr1sFBr8N",
        "row": 8026,
        "state": "FLORIDA",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recym3dG12xpWzTFG",
        "row": 8052,
        "state": "FLORIDA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 2550,
        "2021": 0,
        "2022": 4750,
        "2023": 4750,
        "2024": 0,
        "id": "reczfACm5SB47E5Jl",
        "row": 8015,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 2053,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recBqwDJQHgQqbT5f",
        "row": 8045,
        "state": "FLORIDA",
        "group": "P03418",
        "group_num": [
            "recMzRo5YPjyVpsb7"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 815,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHPDKkpDXxRG78X",
        "row": 8048,
        "state": "FLORIDA",
        "group": "SAG101",
        "group_num": [
            "rectMvju4ONqEXNWP"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 319,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHerrJ5tFCalCLW",
        "row": 8022,
        "state": "FLORIDA",
        "group": "170161",
        "group_num": [
            "recGXjqeeAtHkKg9g"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 6000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIUxb0ltKIIZXZA",
        "row": 8040,
        "state": "FLORIDA",
        "group": "NYS018",
        "group_num": [
            "recYA90msKsTqpsZc"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1237,
        "2023": 0,
        "2024": 0,
        "id": "recICxFUuiW4RQPOH",
        "row": 8019,
        "state": "FLORIDA",
        "group": "054632",
        "group_num": [
            "recLCOQ9hxhhsW1kf"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "2023": 0,
        "2024": 0,
        "id": "recKFJxlraRXTaJ39",
        "row": 8051,
        "state": "FLORIDA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 804,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKqISalC2Mba6BH",
        "row": 8023,
        "state": "FLORIDA",
        "group": "170161",
        "group_num": [
            "recGXjqeeAtHkKg9g"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 80,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLua8beJbiO9FaE",
        "row": 8024,
        "state": "FLORIDA",
        "group": "170161",
        "group_num": [
            "recGXjqeeAtHkKg9g"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "recfCf4HVvgLXzVV7",
        "row": 11461,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 6000,
        "2024": 0,
        "id": "recjOtOyUnU87NQAk",
        "row": 11454,
        "state": "FLORIDA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4750,
        "2024": 0,
        "id": "recueGZU0AQUZEnsr",
        "row": 11462,
        "state": "FLORIDA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "2024": 0,
        "id": "recxzShWisKRtfPG7",
        "row": 11453,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "2024": 0,
        "id": "recJP4BKuPNnI4XHc",
        "row": 11449,
        "state": "FLORIDA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 4750,
        "2024": 0,
        "id": "recMKSacuksSUmlJ2",
        "row": 11457,
        "state": "FLORIDA",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recoNv4EXhqSOPc8U",
        "row": 11497,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2500,
        "2024": 0,
        "id": "recZqvwLUggMNW5dC",
        "row": 11534,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "IOP",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 797,
        "2024": 0,
        "id": "rec1l0j9HqoqcZGjO",
        "row": 11853,
        "state": "FLORIDA",
        "group": "0000832000043",
        "group_num": [
            "recy8WrvVMAqRBDqH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 550,
        "2024": 0,
        "id": "recGGsfiMGfYDNkjG",
        "row": 11919,
        "state": "FLORIDA",
        "group": "082826",
        "group_num": [
            "rec7RMttDnioNatxB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 539,
        "2024": 0,
        "id": "recO1DnoPHaA5uPNa",
        "row": 11923,
        "state": "FLORIDA",
        "group": "0000832000043",
        "group_num": [
            "recy8WrvVMAqRBDqH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 452,
        "2024": 0,
        "id": "recWXej4YVnSLx3Zp",
        "row": 12008,
        "state": "FLORIDA",
        "group": "Y0909012",
        "group_num": [
            "recokuvgo4EIIFvv0"
        ],
        "procedure": "RTC",
        "provider": "HUMANA (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 400,
        "2024": 0,
        "id": "recdxWSo7xJ8xDECK",
        "row": 12053,
        "state": "FLORIDA",
        "group": "082826",
        "group_num": [
            "rec7RMttDnioNatxB"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "recvgwCXZSwChGWfx",
        "row": 12024,
        "state": "FLORIDA",
        "group": "X9240012",
        "group_num": [
            "recNCDEDOVoqstgqj"
        ],
        "procedure": "RTC",
        "provider": "HUMANA (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 200,
        "2024": 0,
        "id": "reclrdAqjifZfsGHW",
        "row": 12154,
        "state": "FLORIDA",
        "group": "082826",
        "group_num": [
            "rec7RMttDnioNatxB"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 250,
        "2024": 0,
        "id": "rec4v8tAJ9KF3mHGQ",
        "row": 12130,
        "state": "FLORIDA",
        "group": "FLMCR000",
        "group_num": [
            "reccgecKLfVkvUipK"
        ],
        "procedure": "PHP",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 138,
        "2024": 0,
        "id": "recPMB7ukg4f8PwzI",
        "row": 12209,
        "state": "FLORIDA",
        "group": "FLMCR000",
        "group_num": [
            "reccgecKLfVkvUipK"
        ],
        "procedure": "IOP",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 150,
        "2024": 0,
        "id": "rec0tIotMgIhKmxCH",
        "row": 12208,
        "state": "FLORIDA",
        "group": "X9000012",
        "group_num": [
            "receXX3fLRqpxHppg"
        ],
        "procedure": "IOP",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 19,
        "2024": 0,
        "id": "recYtjJdUnqSfleLh",
        "row": 12228,
        "state": "FLORIDA",
        "group": "X9000012",
        "group_num": [
            "receXX3fLRqpxHppg"
        ],
        "procedure": "OP",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recrXWmJVQvq99W9t",
        "row": 12320,
        "state": "FLORIDA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "PHP",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "2023": 0,
        "2024": 0,
        "id": "rec8s5gucOFlDU3rP",
        "row": 12358,
        "state": "FLORIDA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recgvs3jJCcATzbhh",
        "row": 12446,
        "state": "FLORIDA",
        "group": "YO0731",
        "group_num": [
            "rec4SuBFHdneHaFk9"
        ],
        "procedure": "Detox",
        "provider": "YO0731 (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recCbYaIpTYmcwDY4",
        "row": 12445,
        "state": "FLORIDA",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 775,
        "2023": 0,
        "2024": 0,
        "id": "recMU6VkzS84ZYfDu",
        "row": 12607,
        "state": "FLORIDA",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "2023": 0,
        "2024": 0,
        "id": "recrbm6p4Bbi2iqoV",
        "row": 12666,
        "state": "FLORIDA",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1210,
        "2023": 1299,
        "2024": 0,
        "id": "rec3ugvea8AapVfeV",
        "row": 12770,
        "state": "FLORIDA",
        "group": "106528",
        "group_num": [
            "recke3ANugOvCT1FY"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1599,
        "2023": 0,
        "2024": 0,
        "id": "receSdwZgExnswvfK",
        "row": 12805,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1237,
        "2023": 0,
        "2024": 0,
        "id": "reckTsgo1jCEZX1Zl",
        "row": 12773,
        "state": "FLORIDA",
        "group": "054632",
        "group_num": [
            "recLCOQ9hxhhsW1kf"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1249,
        "2023": 0,
        "2024": 0,
        "id": "recGDaPFHmH0vVRlk",
        "row": 12774,
        "state": "FLORIDA",
        "group": "106528",
        "group_num": [
            "recke3ANugOvCT1FY"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2567,
        "2023": 0,
        "2024": 0,
        "id": "recSoUpEyRhHLGYbY",
        "row": 12910,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3383,
        "2023": 0,
        "2024": 0,
        "id": "reclICwJdolp95dah",
        "row": 12978,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "recObPkSI7I1cmR2J",
        "row": 13000,
        "state": "FLORIDA",
        "group": "NYS010",
        "group_num": [
            "rec4tZqWTgezsRyyB"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "recR5pbry2zB7mcy8",
        "row": 12999,
        "state": "FLORIDA",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "rec4hMpEYcBZomY3l",
        "row": 13013,
        "state": "FLORIDA",
        "group": "NYSHIP",
        "group_num": [
            "recc2sfOZHzIb0rR4"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "rect6gRoPXCXMpjeG",
        "row": 13001,
        "state": "FLORIDA",
        "group": "NYSHIP",
        "group_num": [
            "recc2sfOZHzIb0rR4"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recGGKsGTsC2L8nNc",
        "row": 13012,
        "state": "FLORIDA",
        "group": "NYS010",
        "group_num": [
            "rec4tZqWTgezsRyyB"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 225,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPTueqDOywSIaZK",
        "row": 8056,
        "state": "FLORIDA",
        "group": "114799",
        "group_num": [
            "recUKqfto9hSUuywv"
        ],
        "procedure": "IOP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 241,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6Ctety2NLlaLhh",
        "row": 8055,
        "state": "FLORIDA",
        "group": "114799",
        "group_num": [
            "recUKqfto9hSUuywv"
        ],
        "procedure": "PHP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 83,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7RHfbupGTg2n2b",
        "row": 8057,
        "state": "FLORIDA",
        "group": "114799",
        "group_num": [
            "recUKqfto9hSUuywv"
        ],
        "procedure": "OP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 882,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsMjxcWKqr4722D",
        "row": 8054,
        "state": "FLORIDA",
        "group": "114799",
        "group_num": [
            "recUKqfto9hSUuywv"
        ],
        "procedure": "RTC",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "2023": 0,
        "2024": 0,
        "id": "rec8jnEiQrx1o1suj",
        "row": 8058,
        "state": "FLORIDA",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "RTC",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 0,
        "2024": 0,
        "id": "recbRJqYdG0ZTQu1i",
        "row": 8060,
        "state": "FLORIDA",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "IOP",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 447,
        "2023": 0,
        "2024": 0,
        "id": "recxNfsuCetCpgz7O",
        "row": 8059,
        "state": "FLORIDA",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "PHP",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 0,
        "2024": 0,
        "id": "recoUkrvwFxDFXnBt",
        "row": 12305,
        "state": "FLORIDA",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "IOP",
        "provider": "BOON CHAPMAN ADMINISTRATORS (BOON CHAPMAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 447,
        "2023": 0,
        "2024": 0,
        "id": "recdq32ZrRSmvi9VQ",
        "row": 12442,
        "state": "FLORIDA",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "PHP",
        "provider": "BOON CHAPMAN ADMINISTRATORS (BOON CHAPMAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "2023": 0,
        "2024": 0,
        "id": "recrDJhAnq2RcQ8dY",
        "row": 12595,
        "state": "FLORIDA",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "RTC",
        "provider": "BOON CHAPMAN ADMINISTRATORS (BOON CHAPMAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 847,
        "2023": 979,
        "2024": 0,
        "id": "recRrBJY4XB3En4yd",
        "row": 11741,
        "state": "FLORIDA",
        "group": "650104",
        "group_num": [
            "recTsHa84WgkeIIyV"
        ],
        "procedure": "RTC",
        "provider": "BOON CHAPMAN ADMINISTRATORS (BOON GROUP)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1837,
        "2024": 0,
        "id": "recGrYZE1cAdo7kOF",
        "row": 11615,
        "state": "FLORIDA",
        "group": "300",
        "group_num": [
            "recc9Kqe1XaS4CPdM"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST INC (CBHA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1529,
        "2024": 0,
        "id": "recoUudfqKy2Bta0e",
        "row": 11664,
        "state": "FLORIDA",
        "group": "300",
        "group_num": [
            "recc9Kqe1XaS4CPdM"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST INC (CBHA)"
    },
    {
        "2020": 2000,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receXHcb1ZUHHN5st",
        "row": 8087,
        "state": "FLORIDA",
        "group": "3175184",
        "group_num": [
            "rec78EX4fxCmcu83w"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 900,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recewPAZiGA4FapYO",
        "row": 8081,
        "state": "FLORIDA",
        "group": "2499331",
        "group_num": [
            "recCzJjOWDbN41CZf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 299,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receeSjzYl3dI34An",
        "row": 8066,
        "state": "FLORIDA",
        "group": "32130",
        "group_num": [
            "recqMqwmqp1zYTFXW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "2023": 0,
        "2024": 0,
        "id": "recjwcALUltWQHO6f",
        "row": 8062,
        "state": "FLORIDA",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "2023": 0,
        "2024": 0,
        "id": "recmFVQEMt6Jjj2cB",
        "row": 8074,
        "state": "FLORIDA",
        "group": "0233935",
        "group_num": [
            "recrGMvFyPRWAaCFM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1761,
        "2023": 0,
        "2024": 0,
        "id": "recmdswbIfw6IgdCb",
        "row": 8084,
        "state": "FLORIDA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3026,
        "2023": 0,
        "2024": 0,
        "id": "recnvwdq5Azhq1tYz",
        "row": 8072,
        "state": "FLORIDA",
        "group": "0226017",
        "group_num": [
            "rec1vB8YjBEO99Fqa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recPNwXmrDQfl7WhM",
        "row": 8111,
        "state": "FLORIDA",
        "group": "3332379",
        "group_num": [
            "rec3foQxFYJpdrJwb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 2130,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPR7S6G2VbFl0ZY",
        "row": 8103,
        "state": "FLORIDA",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recQxMVjKKJa9TTdz",
        "row": 8102,
        "state": "FLORIDA",
        "group": "3328490",
        "group_num": [
            "rec18yehSkze02KTC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1992,
        "2024": 0,
        "id": "recRspq1f2fMFH07E",
        "row": 8091,
        "state": "FLORIDA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 470,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTFZPh9jLFsI00y",
        "row": 8065,
        "state": "FLORIDA",
        "group": "32130",
        "group_num": [
            "recqMqwmqp1zYTFXW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 1015,
        "2021": 1186,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recU1k8VZmWQi0anF",
        "row": 8088,
        "state": "FLORIDA",
        "group": "3175184",
        "group_num": [
            "rec78EX4fxCmcu83w"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 695,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUat6xp27kSRRfd",
        "row": 8100,
        "state": "FLORIDA",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 438,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recX86MZMeNm7F6Sf",
        "row": 8069,
        "state": "FLORIDA",
        "group": "0196736",
        "group_num": [
            "reczrEEqV7iNOKZJT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 0,
        "2024": 0,
        "id": "recYs1uRyedJoCk93",
        "row": 8064,
        "state": "FLORIDA",
        "group": "06880A003",
        "group_num": [
            "recmM3J2bSrlSbn8J"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 2220,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3sSAzGkjtWvYQ2",
        "row": 8071,
        "state": "FLORIDA",
        "group": "0199687",
        "group_num": [
            "rec1fY5cD6Xn7LvtU"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 464,
        "2023": 0,
        "2024": 0,
        "id": "rec42vJWHU4hMvefX",
        "row": 8073,
        "state": "FLORIDA",
        "group": "0226017",
        "group_num": [
            "rec1vB8YjBEO99Fqa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2166,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6GXQHTuMlWXGHB",
        "row": 8080,
        "state": "FLORIDA",
        "group": "2499331",
        "group_num": [
            "recCzJjOWDbN41CZf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 157,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6OlOaMfdtcNQsI",
        "row": 8109,
        "state": "FLORIDA",
        "group": "3332199",
        "group_num": [
            "recYNGoiGtNH57NzH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec7Xu4VaNlDohjii",
        "row": 8096,
        "state": "FLORIDA",
        "group": "3210196",
        "group_num": [
            "recU5IBK7ugybj9cQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 234,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8FKr4C9HfPbbdJ",
        "row": 8112,
        "state": "FLORIDA",
        "group": "3333252",
        "group_num": [
            "rec91lmn8XQHmndsT"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 1940,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9YxlCVK6DkLwjK",
        "row": 8098,
        "state": "FLORIDA",
        "group": "3327595",
        "group_num": [
            "rec3FhvBPg4PuJVwF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 4000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recaddAcBvHuJiV4j",
        "row": 8093,
        "state": "FLORIDA",
        "group": "3208640",
        "group_num": [
            "recA3UKNKLN2p7bPI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 0,
        "2024": 0,
        "id": "recpMQZ5YWnBF85W2",
        "row": 8079,
        "state": "FLORIDA",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1659,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recprd2iOLVdQyfUC",
        "row": 8099,
        "state": "FLORIDA",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 192,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqoeDHJE7bb69Nt",
        "row": 8113,
        "state": "FLORIDA",
        "group": "3333252",
        "group_num": [
            "rec91lmn8XQHmndsT"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 175,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqphORJvgpKNc3P",
        "row": 8089,
        "state": "FLORIDA",
        "group": "3175184",
        "group_num": [
            "rec78EX4fxCmcu83w"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 3000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvWfaowI73JY5tn",
        "row": 8094,
        "state": "FLORIDA",
        "group": "3208640",
        "group_num": [
            "recA3UKNKLN2p7bPI"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 836,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recykrOdCTXyZw98U",
        "row": 8104,
        "state": "FLORIDA",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recBRA3pS1ObMdjC3",
        "row": 8078,
        "state": "FLORIDA",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1024,
        "2023": 0,
        "2024": 0,
        "id": "recCv1VMeGIYdrxnB",
        "row": 8085,
        "state": "FLORIDA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3121,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDysA2jCJtnjot7",
        "row": 8107,
        "state": "FLORIDA",
        "group": "3331790",
        "group_num": [
            "recjKXOBADHMSsO6Y"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 0,
        "2024": 0,
        "id": "recEny70xZWxRUy7f",
        "row": 8063,
        "state": "FLORIDA",
        "group": "06880A003",
        "group_num": [
            "recmM3J2bSrlSbn8J"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEogz03h6lFwj2Q",
        "row": 8070,
        "state": "FLORIDA",
        "group": "0199687",
        "group_num": [
            "rec1fY5cD6Xn7LvtU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 143,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEqsNol9tJkGyXG",
        "row": 8105,
        "state": "FLORIDA",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recFywQVzvmE8czvU",
        "row": 8086,
        "state": "FLORIDA",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 566,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFNnbZJSyZ0HTrb",
        "row": 8108,
        "state": "FLORIDA",
        "group": "3331790",
        "group_num": [
            "recjKXOBADHMSsO6Y"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2933,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGE1GbbmtSqFjDC",
        "row": 8092,
        "state": "FLORIDA",
        "group": "3202272",
        "group_num": [
            "recoYhYyd8RMjWK41"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGMvCgP11c5t0jk",
        "row": 8097,
        "state": "FLORIDA",
        "group": "3317248",
        "group_num": [
            "reckSwLiHF08B4c9W"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 308,
        "2022": 319,
        "2023": 0,
        "2024": 0,
        "id": "recHd2XcjcuzFftXd",
        "row": 8068,
        "state": "FLORIDA",
        "group": "0196736",
        "group_num": [
            "reczrEEqV7iNOKZJT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2146,
        "2023": 2000,
        "2024": 0,
        "id": "recMMilTfsbQ8aLsn",
        "row": 8106,
        "state": "FLORIDA",
        "group": "3330972",
        "group_num": [
            "recW7cyDTaOwWqlgT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 69,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNuJKHFswPIvuZH",
        "row": 8067,
        "state": "FLORIDA",
        "group": "38060",
        "group_num": [
            "recpLSoAhlXFWKvZ2"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recNl0wPMFkHaJCQW",
        "row": 8076,
        "state": "FLORIDA",
        "group": "2498633",
        "group_num": [
            "rec74G7OJ2iBYw0hM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1820,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfQdN7hlRQcJVoh",
        "row": 8128,
        "state": "FLORIDA",
        "group": "3339337",
        "group_num": [
            "recWIID9Sa4yISbEo"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recgaCXp7oMSywG8b",
        "row": 8114,
        "state": "FLORIDA",
        "group": "3333413",
        "group_num": [
            "recNWA3zpJqKviG05"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1800,
        "2024": 0,
        "id": "recgeLmWJqT2ETwxj",
        "row": 8125,
        "state": "FLORIDA",
        "group": "3338229",
        "group_num": [
            "recR4YhwXqVHbhHrG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnSqWP1gw9BsDwC",
        "row": 8138,
        "state": "FLORIDA",
        "group": "3339947",
        "group_num": [
            "recpSewhbzQSTufnf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 153,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPPUSRcP7nWElO3",
        "row": 8148,
        "state": "FLORIDA",
        "group": "3341289",
        "group_num": [
            "recVq9gVeCzTt2GXU"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1661,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTXAVmhOANRaYDk",
        "row": 8147,
        "state": "FLORIDA",
        "group": "3341289",
        "group_num": [
            "recVq9gVeCzTt2GXU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recTBasLh7p2xFb54",
        "row": 8142,
        "state": "FLORIDA",
        "group": "3340334",
        "group_num": [
            "reccrVYOeWZHZFcWe"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 749,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTqlrean6oT05Yl",
        "row": 8135,
        "state": "FLORIDA",
        "group": "3339907",
        "group_num": [
            "recUZCFXDESRJHBgV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1899,
        "2023": 0,
        "2024": 0,
        "id": "recU79Maeakl2CAop",
        "row": 8161,
        "state": "FLORIDA",
        "group": "3343119",
        "group_num": [
            "recvfbz61vSgmF6M8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 749,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVexXKw0kpi2h9R",
        "row": 8152,
        "state": "FLORIDA",
        "group": "3341439",
        "group_num": [
            "rec7laH4E9KWGMOT2"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 610,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWvyj17VhTX3FEz",
        "row": 8156,
        "state": "FLORIDA",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recWfho0NFJhfnTvU",
        "row": 8163,
        "state": "FLORIDA",
        "group": "3343249",
        "group_num": [
            "recM7UpyiBOOTyMFJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3086,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZzV9lM23Bslg61",
        "row": 8155,
        "state": "FLORIDA",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 732,
        "2023": 0,
        "2024": 0,
        "id": "rec0I6VpkMQPv5kY7",
        "row": 8127,
        "state": "FLORIDA",
        "group": "3338618",
        "group_num": [
            "recCh0OKvM84MUJg4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 836,
        "2021": 597,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4LI3Nfx3jutFZU",
        "row": 8129,
        "state": "FLORIDA",
        "group": "3339337",
        "group_num": [
            "recWIID9Sa4yISbEo"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 745,
        "2023": 0,
        "2024": 0,
        "id": "rec5jR2GGpj2DPhbs",
        "row": 8117,
        "state": "FLORIDA",
        "group": "3334628",
        "group_num": [
            "recTLXUcfMLBGWhX8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec6GRzii63scts8d",
        "row": 8131,
        "state": "FLORIDA",
        "group": "3339643",
        "group_num": [
            "recMaFfkUSgfVN4EF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 0,
        "2024": 0,
        "id": "rec7Vo1Ubu9dSmPNx",
        "row": 8120,
        "state": "FLORIDA",
        "group": "3336220",
        "group_num": [
            "recii4tqRWjiF5nqG"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec9KBF4cBtrY8Kll",
        "row": 8146,
        "state": "FLORIDA",
        "group": "3341246",
        "group_num": [
            "recTQkIuxdEaUU5u0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 992,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9eKT4ff4iAzIyH",
        "row": 8118,
        "state": "FLORIDA",
        "group": "3335344",
        "group_num": [
            "recMwiRMiOaLCzRx3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1864,
        "2023": 1882,
        "2024": 0,
        "id": "recdWJ31NtA9b0oTE",
        "row": 8150,
        "state": "FLORIDA",
        "group": "3341302",
        "group_num": [
            "recWn9G0RbSHAYadq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recdfC5SmM3P1zC70",
        "row": 8122,
        "state": "FLORIDA",
        "group": "3337219",
        "group_num": [
            "recYBBBsXgPkmIi0Y"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1727,
        "2023": 0,
        "2024": 0,
        "id": "reco0SEKXvi19PQCh",
        "row": 8126,
        "state": "FLORIDA",
        "group": "3338618",
        "group_num": [
            "recCh0OKvM84MUJg4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1848,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqx9JnzSRIwOXe2",
        "row": 8137,
        "state": "FLORIDA",
        "group": "3339947",
        "group_num": [
            "recpSewhbzQSTufnf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1950,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr6DUm1fkSn2V2K",
        "row": 8134,
        "state": "FLORIDA",
        "group": "3339907",
        "group_num": [
            "recUZCFXDESRJHBgV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 473,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsqF9Gxew5dVH2M",
        "row": 8159,
        "state": "FLORIDA",
        "group": "3342597",
        "group_num": [
            "recmmjrtQoF5CJXyu"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 804,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recuPPxgj1yp9IkJa",
        "row": 8158,
        "state": "FLORIDA",
        "group": "3342235",
        "group_num": [
            "rec2lSCmHTxuBpj14"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 0,
        "2024": 0,
        "id": "recwv2Ieg1EUmN3bZ",
        "row": 8144,
        "state": "FLORIDA",
        "group": "3341208",
        "group_num": [
            "recIfkJI1yeZHYNGx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 814,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAIbrheWNcH0yPp",
        "row": 8136,
        "state": "FLORIDA",
        "group": "3339907",
        "group_num": [
            "recUZCFXDESRJHBgV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1864,
        "2024": 0,
        "id": "recBw23ri0iZWJFq5",
        "row": 8133,
        "state": "FLORIDA",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 173,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFytijL4PqysudZ",
        "row": 8153,
        "state": "FLORIDA",
        "group": "3341439",
        "group_num": [
            "rec7laH4E9KWGMOT2"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recFEYiVTmQkW9h38",
        "row": 8141,
        "state": "FLORIDA",
        "group": "3340334",
        "group_num": [
            "reccrVYOeWZHZFcWe"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recGW8lvVJ6wCYuNF",
        "row": 8140,
        "state": "FLORIDA",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1830,
        "2023": 0,
        "2024": 0,
        "id": "recHRw1ZKQYAruACB",
        "row": 8119,
        "state": "FLORIDA",
        "group": "3336220",
        "group_num": [
            "recii4tqRWjiF5nqG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 792,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIWSyevXFWXjNl3",
        "row": 8157,
        "state": "FLORIDA",
        "group": "3342235",
        "group_num": [
            "rec2lSCmHTxuBpj14"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 1895,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIltqZOF9fMGM4i",
        "row": 8151,
        "state": "FLORIDA",
        "group": "3341439",
        "group_num": [
            "rec7laH4E9KWGMOT2"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recJt69Zx1iCd02Qj",
        "row": 8115,
        "state": "FLORIDA",
        "group": "3333413",
        "group_num": [
            "recNWA3zpJqKviG05"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 248,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMXNfVw1i7TZ3nY",
        "row": 8160,
        "state": "FLORIDA",
        "group": "3342597",
        "group_num": [
            "recmmjrtQoF5CJXyu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recMlSPali6YPZGXM",
        "row": 8143,
        "state": "FLORIDA",
        "group": "3341208",
        "group_num": [
            "recIfkJI1yeZHYNGx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 700,
        "2023": 0,
        "2024": 0,
        "id": "recepzcrLAtHaMv8z",
        "row": 8168,
        "state": "FLORIDA",
        "group": "3343801",
        "group_num": [
            "reczm9G3PCtwgEMe8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1803,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechWWOstvOIbSxTO",
        "row": 8170,
        "state": "FLORIDA",
        "group": "00182807",
        "group_num": [
            "rec44AqfjIagWhbUU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 157,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reci4AwAHLZdAKEWk",
        "row": 8181,
        "state": "FLORIDA",
        "group": "00621236",
        "group_num": [
            "recMpqgTe3PfVxKFt"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjhLmWYQWj0ekiu",
        "row": 8208,
        "state": "FLORIDA",
        "group": "00632529",
        "group_num": [
            "recLc3hnHDj2SdCo1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1079,
        "2023": 0,
        "2024": 0,
        "id": "recjnp9zBp8RiQz2g",
        "row": 8203,
        "state": "FLORIDA",
        "group": "00631393",
        "group_num": [
            "reck78Kfw5DKknQWI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "2023": 0,
        "2024": 0,
        "id": "reckHvwb7lY1BJKjY",
        "row": 8188,
        "state": "FLORIDA",
        "group": "00625977",
        "group_num": [
            "rec9oRdV0owIOCxEg"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recOzkzfEEypDM7ge",
        "row": 8166,
        "state": "FLORIDA",
        "group": "3343794",
        "group_num": [
            "recMx0jD79cR8zJIa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 85,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQayDtltYaOoFNb",
        "row": 8202,
        "state": "FLORIDA",
        "group": "00629491",
        "group_num": [
            "recSIReukOepEys0d"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1869,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recROztB4ZIQY1nNL",
        "row": 8184,
        "state": "FLORIDA",
        "group": "00625001",
        "group_num": [
            "recWZwrTvuHecHZPI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2200,
        "2022": 2200,
        "2023": 0,
        "2024": 0,
        "id": "recTlmUK2RkYzNWqu",
        "row": 8167,
        "state": "FLORIDA",
        "group": "3343801",
        "group_num": [
            "reczm9G3PCtwgEMe8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVnf5fQiTHuurTH",
        "row": 8183,
        "state": "FLORIDA",
        "group": "00624707",
        "group_num": [
            "recdtm7zIu4Ac9Ngv"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWzpnwNgITtiZjX",
        "row": 8191,
        "state": "FLORIDA",
        "group": "00627384",
        "group_num": [
            "recqbV9HHcwbQrJYA"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recWl32frXLzmhDek",
        "row": 8179,
        "state": "FLORIDA",
        "group": "00612690",
        "group_num": [
            "recRxYnk01cSGEPrf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 263,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXs7WZda5n6Grbk",
        "row": 8192,
        "state": "FLORIDA",
        "group": "00627384",
        "group_num": [
            "recqbV9HHcwbQrJYA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recXPP5gV5Gh42g3C",
        "row": 8178,
        "state": "FLORIDA",
        "group": "00612690",
        "group_num": [
            "recRxYnk01cSGEPrf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1070,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYfv4VYZCInFNJM",
        "row": 8207,
        "state": "FLORIDA",
        "group": "00632529",
        "group_num": [
            "recLc3hnHDj2SdCo1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 1169,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0ZoEDzJ35Qsb14",
        "row": 8173,
        "state": "FLORIDA",
        "group": "00606818",
        "group_num": [
            "recJV8aOhj4Hu7g0P"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 498,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2vc75mbBj7BZUY",
        "row": 8185,
        "state": "FLORIDA",
        "group": "00625001",
        "group_num": [
            "recWZwrTvuHecHZPI"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec28adk9GWKKGx8W",
        "row": 8198,
        "state": "FLORIDA",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1714,
        "2023": 0,
        "2024": 0,
        "id": "rec5ufQnTV7jGAK23",
        "row": 8194,
        "state": "FLORIDA",
        "group": "00627645",
        "group_num": [
            "recHfkbesJ9jijFyE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 1000,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5JjC9LB2yBXHhs",
        "row": 8172,
        "state": "FLORIDA",
        "group": "00606818",
        "group_num": [
            "recJV8aOhj4Hu7g0P"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec6EgZsKH9UAZdwp",
        "row": 8205,
        "state": "FLORIDA",
        "group": "00631484",
        "group_num": [
            "recKaJf5r46mrINaY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 219,
        "2024": 0,
        "id": "rec6nvm2vVu1ksEsl",
        "row": 8164,
        "state": "FLORIDA",
        "group": "3343249",
        "group_num": [
            "recM7UpyiBOOTyMFJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec7PnqjJp56iDNSp",
        "row": 8165,
        "state": "FLORIDA",
        "group": "3343794",
        "group_num": [
            "recMx0jD79cR8zJIa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1005,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec8lyRb86HYU7K4V",
        "row": 8195,
        "state": "FLORIDA",
        "group": "00627936",
        "group_num": [
            "recdBbddzljjUfV31"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 0,
        "2024": 0,
        "id": "rec9zXwBIcMooTXZv",
        "row": 8200,
        "state": "FLORIDA",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "reccviT3e7ihZ1CrJ",
        "row": 8206,
        "state": "FLORIDA",
        "group": "00631484",
        "group_num": [
            "recKaJf5r46mrINaY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "reccHeVacGq739a0X",
        "row": 8211,
        "state": "FLORIDA",
        "group": "00634822",
        "group_num": [
            "rec3iMjkH8QkASUyz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "2023": 0,
        "2024": 0,
        "id": "recq5yVAZ1sY4kTq7",
        "row": 8197,
        "state": "FLORIDA",
        "group": "00627936",
        "group_num": [
            "recdBbddzljjUfV31"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 157,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrP8Vb4nIGcuGbf",
        "row": 8201,
        "state": "FLORIDA",
        "group": "00629491",
        "group_num": [
            "recSIReukOepEys0d"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2800,
        "2024": 0,
        "id": "recvsM4xSqJ6n0Q2x",
        "row": 8189,
        "state": "FLORIDA",
        "group": "00627267",
        "group_num": [
            "recI71TTCWHfPCy9r"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 638,
        "2023": 0,
        "2024": 0,
        "id": "recwcwiA8zSzmDYA3",
        "row": 8204,
        "state": "FLORIDA",
        "group": "00631393",
        "group_num": [
            "reck78Kfw5DKknQWI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3075,
        "2023": 0,
        "2024": 0,
        "id": "recAWn40jsihydGaD",
        "row": 8169,
        "state": "FLORIDA",
        "group": "3343963",
        "group_num": [
            "recSyyoEFI5vr5Be3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1200,
        "2022": 1200,
        "2023": 0,
        "2024": 0,
        "id": "recB7LzfyQOLEjMCE",
        "row": 8171,
        "state": "FLORIDA",
        "group": "00605103",
        "group_num": [
            "recE54nWk8OumqgIp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recCdAzRue7ZDlbsD",
        "row": 8210,
        "state": "FLORIDA",
        "group": "00634045",
        "group_num": [
            "recvYYzDYgj3iilJE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recCi2Hy5t8UnQJS3",
        "row": 8186,
        "state": "FLORIDA",
        "group": "00625890",
        "group_num": [
            "rec4GGpcSMz6BKVlV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recDzeJODCh84dyvO",
        "row": 8180,
        "state": "FLORIDA",
        "group": "00613603",
        "group_num": [
            "recYqRrn034Kz9gJE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 287,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDAFPi11soAkRBM",
        "row": 8196,
        "state": "FLORIDA",
        "group": "00627936",
        "group_num": [
            "recdBbddzljjUfV31"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1845,
        "2023": 0,
        "2024": 0,
        "id": "recG2P0RmAeUgczWd",
        "row": 8212,
        "state": "FLORIDA",
        "group": "00634822",
        "group_num": [
            "rec3iMjkH8QkASUyz"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 950,
        "2022": 950,
        "2023": 0,
        "2024": 0,
        "id": "recGbqp3OUTVFjhGn",
        "row": 8177,
        "state": "FLORIDA",
        "group": "00610901",
        "group_num": [
            "recTS6oxaT8KWZV0T"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "recHvB2U8xV3uf5eI",
        "row": 8175,
        "state": "FLORIDA",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1915,
        "2024": 0,
        "id": "recHCTHcX7qsdvkgZ",
        "row": 8190,
        "state": "FLORIDA",
        "group": "00627267",
        "group_num": [
            "recI71TTCWHfPCy9r"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recIPWR2vKPgeYUYB",
        "row": 8193,
        "state": "FLORIDA",
        "group": "00627645",
        "group_num": [
            "recHfkbesJ9jijFyE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 1811,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIkfTe773J8ol8x",
        "row": 8176,
        "state": "FLORIDA",
        "group": "00610901",
        "group_num": [
            "recTS6oxaT8KWZV0T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recJmkIxs43zKxDM1",
        "row": 8187,
        "state": "FLORIDA",
        "group": "00625890",
        "group_num": [
            "rec4GGpcSMz6BKVlV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 619,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMY55bUde2lqw0d",
        "row": 8182,
        "state": "FLORIDA",
        "group": "00624707",
        "group_num": [
            "recdtm7zIu4Ac9Ngv"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recMPs0GIkTYl640D",
        "row": 8209,
        "state": "FLORIDA",
        "group": "00634045",
        "group_num": [
            "recvYYzDYgj3iilJE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 653,
        "2023": 0,
        "2024": 0,
        "id": "recMoo42NmUvQQfwP",
        "row": 8174,
        "state": "FLORIDA",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recNHatqOP04TEn1H",
        "row": 8199,
        "state": "FLORIDA",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVSvuXzeKJevLuD",
        "row": 8216,
        "state": "FLORIDA",
        "group": "FPCI",
        "group_num": [
            "recYy9gVpj2N2hG5m"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recViiW19Gs4IJjTc",
        "row": 8214,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 260,
        "2024": 0,
        "id": "recXyZK5AQMI33dot",
        "row": 8219,
        "state": "FLORIDA",
        "group": "ML0000",
        "group_num": [
            "receOjAkVAMGurEjD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1739,
        "2024": 0,
        "id": "recxeIlnNzyb0fNAV",
        "row": 8218,
        "state": "FLORIDA",
        "group": "ML0000",
        "group_num": [
            "receOjAkVAMGurEjD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 4000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC9KXQFqVs9OvBw",
        "row": 8215,
        "state": "FLORIDA",
        "group": "FPCI",
        "group_num": [
            "recYy9gVpj2N2hG5m"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 2035,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMIkG0bQyZKGBgi",
        "row": 8217,
        "state": "FLORIDA",
        "group": "G12",
        "group_num": [
            "recciz8AePOIXfgtb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 460,
        "2024": 460,
        "id": "rec3HSZhJ9u7Nj5ys",
        "row": 11425,
        "state": "FLORIDA",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 211,
        "id": "recAjH2HaubvTD1ze",
        "row": 11422,
        "state": "FLORIDA",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recez9NBk0mLIVKLZ",
        "row": 11489,
        "state": "FLORIDA",
        "group": "2004014",
        "group_num": [
            "recL1642PIMKk5e6q"
        ],
        "procedure": "Detox",
        "provider": "ALLEGIANCE (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 3000,
        "2024": 0,
        "id": "rech1cNd4JIQ7VhjZ",
        "row": 11487,
        "state": "FLORIDA",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recmda4E9BQOZ2Yg9",
        "row": 11492,
        "state": "FLORIDA",
        "group": "333207",
        "group_num": [
            "recUg8lsC5ez3CjXQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recPtSLpE13mrRPkQ",
        "row": 11496,
        "state": "FLORIDA",
        "group": "3339028",
        "group_num": [
            "recXc59m5Jc9nA9Uy"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2800,
        "2024": 0,
        "id": "recST3ovuwcz7qUjA",
        "row": 11519,
        "state": "FLORIDA",
        "group": "00630994",
        "group_num": [
            "recSZ17kbg5cTOMJf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "rec9nN2jWMNhPCqwf",
        "row": 11495,
        "state": "FLORIDA",
        "group": "3337780",
        "group_num": [
            "rec6x8DOk1DjesuPb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2917,
        "2024": 0,
        "id": "recaF4R4iQPswkeYt",
        "row": 11514,
        "state": "FLORIDA",
        "group": "3343171",
        "group_num": [
            "recHK8NLfh56wF2kr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "reccRY2MjlRpGjozk",
        "row": 11488,
        "state": "FLORIDA",
        "group": "2001003",
        "group_num": [
            "recICYon4tkNRI5hx"
        ],
        "procedure": "Detox",
        "provider": "ALLEGIANCE (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recokyXVxeJC8KmP8",
        "row": 11494,
        "state": "FLORIDA",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2800,
        "2024": 0,
        "id": "recorVost8U0WCcI0",
        "row": 11518,
        "state": "FLORIDA",
        "group": "3338229",
        "group_num": [
            "recR4YhwXqVHbhHrG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recrHsC9Wy6t8YNmG",
        "row": 11493,
        "state": "FLORIDA",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2829,
        "2024": 0,
        "id": "recNYTaTl2AzvtAJL",
        "row": 11516,
        "state": "FLORIDA",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 2197,
        "2024": 0,
        "id": "receFKQGzZuwNOG5T",
        "row": 11570,
        "state": "FLORIDA",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "rechgwqUie3lQQfMU",
        "row": 11548,
        "state": "FLORIDA",
        "group": "3342007",
        "group_num": [
            "recOqxCQNBQPooKjC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recjJvvIlyEgXcybB",
        "row": 11540,
        "state": "FLORIDA",
        "group": "2001003",
        "group_num": [
            "recICYon4tkNRI5hx"
        ],
        "procedure": "RTC",
        "provider": "ALLEGIANCE (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2710,
        "2024": 0,
        "id": "recj8x2M0GsC7reuf",
        "row": 11524,
        "state": "FLORIDA",
        "group": "00616997",
        "group_num": [
            "recoaFyhteO7lbQbE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "reckmzjdxIhykqgNS",
        "row": 11544,
        "state": "FLORIDA",
        "group": "2004034",
        "group_num": [
            "recyOtw3zNrYJG11T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recmtVNMXgzJyQuGP",
        "row": 11546,
        "state": "FLORIDA",
        "group": "2499850",
        "group_num": [
            "recKev7W7SkHqXL2P"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recPnAmi1dgs6e4gy",
        "row": 11549,
        "state": "FLORIDA",
        "group": "3343171",
        "group_num": [
            "recHK8NLfh56wF2kr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2588,
        "2024": 0,
        "id": "recQPt2OfvoDmXxKC",
        "row": 11527,
        "state": "FLORIDA",
        "group": "2499850",
        "group_num": [
            "recKev7W7SkHqXL2P"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recUSkPU1Lql6ZTgp",
        "row": 11545,
        "state": "FLORIDA",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2585,
        "2024": 0,
        "id": "rec0Dy3FedjHSRTY1",
        "row": 11528,
        "state": "FLORIDA",
        "group": "00637981",
        "group_num": [
            "rec7x9qkmTMoIafRd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2221,
        "2024": 0,
        "id": "rec7tXRXkBgGQOZm7",
        "row": 11566,
        "state": "FLORIDA",
        "group": "3342331",
        "group_num": [
            "rec8oyZNH1nRvD6zN"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2678,
        "2024": 0,
        "id": "rec8fzcXOGYjkJfpM",
        "row": 11526,
        "state": "FLORIDA",
        "group": "3343713",
        "group_num": [
            "rec5JxTpyZtvbdXzx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2322,
        "2024": 0,
        "id": "recaJGvIt2IexIp10",
        "row": 11560,
        "state": "FLORIDA",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2400,
        "2024": 0,
        "id": "recdoS9ipLVNVnVJb",
        "row": 11537,
        "state": "FLORIDA",
        "group": "3339745",
        "group_num": [
            "recfqi6Jv4vWxBrw0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recqX1fyMMdPdRxck",
        "row": 11550,
        "state": "FLORIDA",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2195,
        "2024": 0,
        "id": "recqKN0bcVFRP1gwz",
        "row": 11571,
        "state": "FLORIDA",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recsrGoP5A42kjo1o",
        "row": 11541,
        "state": "FLORIDA",
        "group": "2004014",
        "group_num": [
            "recL1642PIMKk5e6q"
        ],
        "procedure": "RTC",
        "provider": "ALLEGIANCE (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2450,
        "2024": 0,
        "id": "recByws6Dg5HsoubN",
        "row": 11536,
        "state": "FLORIDA",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2732,
        "2024": 0,
        "id": "recFaewTRp9szCIGg",
        "row": 11523,
        "state": "FLORIDA",
        "group": "3337320",
        "group_num": [
            "rec2qf07iJTsNdPJn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 2558,
        "2024": 0,
        "id": "recGiPMJVch8yB3Kt",
        "row": 11530,
        "state": "FLORIDA",
        "group": "3337320",
        "group_num": [
            "rec2qf07iJTsNdPJn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2309,
        "2024": 0,
        "id": "recI6ZOMNfzA1MYP4",
        "row": 11562,
        "state": "FLORIDA",
        "group": "3339768",
        "group_num": [
            "reclAN69QrCh0rdPp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2217,
        "2024": 0,
        "id": "recKVdhIaNyqNoDyB",
        "row": 11567,
        "state": "FLORIDA",
        "group": "3343713",
        "group_num": [
            "rec5JxTpyZtvbdXzx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recKCjVsZrNeBP9Kt",
        "row": 11547,
        "state": "FLORIDA",
        "group": "3330666",
        "group_num": [
            "reca1lI3ViqOFHgCo"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2301,
        "2024": 0,
        "id": "recNPqJjo6hM7pxoz",
        "row": 11564,
        "state": "FLORIDA",
        "group": "3342007",
        "group_num": [
            "recOqxCQNBQPooKjC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1951,
        "2024": 0,
        "id": "receSXEndE5jYSq9E",
        "row": 11598,
        "state": "FLORIDA",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "rechCUTOG5YyoztBm",
        "row": 11589,
        "state": "FLORIDA",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2110,
        "2024": 0,
        "id": "rechkv7uM94szPGSA",
        "row": 11576,
        "state": "FLORIDA",
        "group": "3339745",
        "group_num": [
            "recfqi6Jv4vWxBrw0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1937,
        "2024": 0,
        "id": "recjDqe5G8K5VXHGD",
        "row": 11600,
        "state": "FLORIDA",
        "group": "3338229",
        "group_num": [
            "recR4YhwXqVHbhHrG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recmC9mYrzujBMkau",
        "row": 11591,
        "state": "FLORIDA",
        "group": "3343249",
        "group_num": [
            "recM7UpyiBOOTyMFJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2025,
        "2024": 0,
        "id": "recTwHKHFNUbHE8XG",
        "row": 11586,
        "state": "FLORIDA",
        "group": "00650817",
        "group_num": [
            "rec0JEhyLoSdGHuLU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2108,
        "2024": 0,
        "id": "recpKLc2xQo6OmOZn",
        "row": 11578,
        "state": "FLORIDA",
        "group": "XB0000",
        "group_num": [
            "recYseaiHxEF00F2i"
        ],
        "procedure": "Detox",
        "provider": "TRUSMARK (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2190,
        "2024": 0,
        "id": "recxLuQklxnJZ4ofj",
        "row": 11572,
        "state": "FLORIDA",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1855,
        "2024": 0,
        "id": "recyBiLGXuenAeOHM",
        "row": 11612,
        "state": "FLORIDA",
        "group": "3339028",
        "group_num": [
            "recXc59m5Jc9nA9Uy"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2040,
        "2024": 0,
        "id": "recz4pvbtFZ5l0EW2",
        "row": 11583,
        "state": "FLORIDA",
        "group": "3339510",
        "group_num": [
            "rec6hNIfI7vcxXvW1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2022,
        "2024": 0,
        "id": "recGGfw9IKBwcZBpx",
        "row": 11587,
        "state": "FLORIDA",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PARTNERS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recN1EX81MvUZiIoe",
        "row": 11590,
        "state": "FLORIDA",
        "group": "3341266",
        "group_num": [
            "recVUxZ0k4HVxJ7iI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1706,
        "2024": 0,
        "id": "recR8G3d0fYLc2AhE",
        "row": 11640,
        "state": "FLORIDA",
        "group": "00630994",
        "group_num": [
            "recSZ17kbg5cTOMJf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1733,
        "2024": 0,
        "id": "recWbABSLD22EwxQ8",
        "row": 11634,
        "state": "FLORIDA",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1680,
        "2024": 0,
        "id": "rec3iqJqG7K0Lvh1f",
        "row": 11648,
        "state": "FLORIDA",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1646,
        "2024": 0,
        "id": "rec7AVBhazi5ZOC29",
        "row": 11655,
        "state": "FLORIDA",
        "group": "3342331",
        "group_num": [
            "rec8oyZNH1nRvD6zN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1746,
        "2024": 0,
        "id": "rectCktsuJswwQmn2",
        "row": 11632,
        "state": "FLORIDA",
        "group": "333207",
        "group_num": [
            "recUg8lsC5ez3CjXQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1775,
        "2024": 0,
        "id": "recxKni0pqMNsGoMC",
        "row": 11626,
        "state": "FLORIDA",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1601,
        "2024": 0,
        "id": "recByGXXKj39ZmRCP",
        "row": 11657,
        "state": "FLORIDA",
        "group": "XB0000",
        "group_num": [
            "recYseaiHxEF00F2i"
        ],
        "procedure": "RTC",
        "provider": "TRUSMARK (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1741,
        "2024": 0,
        "id": "recBbcVKUNK7RvbN4",
        "row": 11633,
        "state": "FLORIDA",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1680,
        "2024": 0,
        "id": "recERGlSCUfpFpigr",
        "row": 11647,
        "state": "FLORIDA",
        "group": "3168712",
        "group_num": [
            "recHctkBgIU86vQcj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1707,
        "2024": 0,
        "id": "recGyJZgGJ7mA3ecQ",
        "row": 11638,
        "state": "FLORIDA",
        "group": "00617445",
        "group_num": [
            "recDRq3opr43ctVhq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1606,
        "2024": 0,
        "id": "recGhTbZzLKQFd4I9",
        "row": 11656,
        "state": "FLORIDA",
        "group": "3331096",
        "group_num": [
            "recbuTrpKpTfjDXaG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1162,
        "2024": 0,
        "id": "recjQLXMzuGBHnd6l",
        "row": 11709,
        "state": "FLORIDA",
        "group": "3345160",
        "group_num": [
            "recTrANPU1LANzIcY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1503,
        "2024": 0,
        "id": "reck5DjyzaUKjBjfj",
        "row": 11672,
        "state": "FLORIDA",
        "group": "00621840",
        "group_num": [
            "recYWuZkm2EIljYYF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1062,
        "2024": 0,
        "id": "recXYTy9gXhc8tzNo",
        "row": 11718,
        "state": "FLORIDA",
        "group": "3330666",
        "group_num": [
            "reca1lI3ViqOFHgCo"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1050,
        "2024": 0,
        "id": "rec1FCVltl7Z9lzZT",
        "row": 11720,
        "state": "FLORIDA",
        "group": "9623",
        "group_num": [
            "recLYXXdFajUANpmF"
        ],
        "procedure": "RTC",
        "provider": "KEY BENEFIT ADMINISTRATIONS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1480,
        "2024": 0,
        "id": "reccW7jHfSr5iWzbU",
        "row": 11678,
        "state": "FLORIDA",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1126,
        "2024": 0,
        "id": "rectRZ25sLP8uDnqY",
        "row": 11712,
        "state": "FLORIDA",
        "group": "3340311",
        "group_num": [
            "recffZRjMVfe69LwF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1050,
        "2024": 0,
        "id": "reczYo9HtuEtmvQ5V",
        "row": 11721,
        "state": "FLORIDA",
        "group": "9623",
        "group_num": [
            "recLYXXdFajUANpmF"
        ],
        "procedure": "PHP",
        "provider": "KEY BENEFIT ADMINISTRATIONS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1915,
        "2023": 1501,
        "2024": 0,
        "id": "recEZzXHLTo1jLQVx",
        "row": 11673,
        "state": "FLORIDA",
        "group": "00627267",
        "group_num": [
            "recI71TTCWHfPCy9r"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 947,
        "2024": 0,
        "id": "recf7b6Q2QPLXOhmW",
        "row": 11799,
        "state": "FLORIDA",
        "group": "9711C",
        "group_num": [
            "reciNcfnCRH8ABrrj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recOlGGlSzWPLoRbV",
        "row": 11820,
        "state": "FLORIDA",
        "group": "3330535",
        "group_num": [
            "recFWBJZUcD350fWh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1037,
        "2024": 0,
        "id": "recRjrE7j2Tq6XpJw",
        "row": 11722,
        "state": "FLORIDA",
        "group": "2500322",
        "group_num": [
            "recmYiCQL6q80U3Sw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 927,
        "2024": 0,
        "id": "recXxg6rDe6h7eSL4",
        "row": 11802,
        "state": "FLORIDA",
        "group": "2500322",
        "group_num": [
            "recmYiCQL6q80U3Sw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "2023": 900,
        "2024": 0,
        "id": "rec9cmY217Otb3Tsa",
        "row": 11818,
        "state": "FLORIDA",
        "group": "3208240",
        "group_num": [
            "rec4hAPa6QYq6cKbp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 920,
        "2024": 0,
        "id": "recoI4eQgohfLtrT0",
        "row": 11803,
        "state": "FLORIDA",
        "group": "3343914",
        "group_num": [
            "recP8bISxltK1dXkX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 908,
        "2024": 0,
        "id": "recoR1RllAFuXUnc8",
        "row": 11817,
        "state": "FLORIDA",
        "group": "00634545",
        "group_num": [
            "recACUuBm5G8OFqDc"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recsYqvARi6YA18gM",
        "row": 11821,
        "state": "FLORIDA",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 995,
        "2024": 0,
        "id": "recsO1JfJ1zx1YeY4",
        "row": 11724,
        "state": "FLORIDA",
        "group": "00881200",
        "group_num": [
            "recdj30cw3SSMQ1uS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 952,
        "2024": 0,
        "id": "recKl2LId6TE05KN4",
        "row": 11786,
        "state": "FLORIDA",
        "group": "00626924",
        "group_num": [
            "rec6dPnZf5wRbV21I"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 794,
        "2024": 0,
        "id": "reco9C6LPGFcvZGd5",
        "row": 11854,
        "state": "FLORIDA",
        "group": "00630994",
        "group_num": [
            "recSZ17kbg5cTOMJf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 684,
        "2024": 0,
        "id": "recrJaUA95a8zFTiW",
        "row": 11870,
        "state": "FLORIDA",
        "group": "00650817",
        "group_num": [
            "rec0JEhyLoSdGHuLU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recvjQ6MXq5VDWhX4",
        "row": 11824,
        "state": "FLORIDA",
        "group": "00630617",
        "group_num": [
            "receFbzRUBVJOtugu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recziCs9eJbVaShgl",
        "row": 11823,
        "state": "FLORIDA",
        "group": "3341944",
        "group_num": [
            "recaYnJDG9JXSrqS9"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recAxZpWjVVr29zab",
        "row": 11822,
        "state": "FLORIDA",
        "group": "3339907",
        "group_num": [
            "recUZCFXDESRJHBgV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 722,
        "2024": 0,
        "id": "recIRSuFaSMMWTC5e",
        "row": 11863,
        "state": "FLORIDA",
        "group": "00634545",
        "group_num": [
            "recACUuBm5G8OFqDc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 560,
        "2024": 0,
        "id": "recjIL6Q8fPL33hME",
        "row": 11906,
        "state": "FLORIDA",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 560,
        "2024": 0,
        "id": "reckVH1AxsnOc87H9",
        "row": 11908,
        "state": "FLORIDA",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 677,
        "2024": 0,
        "id": "recm7aqaTp8NpzEZA",
        "row": 11874,
        "state": "FLORIDA",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 610,
        "2024": 0,
        "id": "recQObZW4eIqG21SV",
        "row": 11880,
        "state": "FLORIDA",
        "group": "3339510",
        "group_num": [
            "rec6hNIfI7vcxXvW1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 589,
        "2024": 0,
        "id": "recSIvm7VlSwMUDfg",
        "row": 11889,
        "state": "FLORIDA",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 600,
        "2024": 0,
        "id": "rec5DX3PMpUwzRWrH",
        "row": 11882,
        "state": "FLORIDA",
        "group": "3208240",
        "group_num": [
            "rec4hAPa6QYq6cKbp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 560,
        "2024": 0,
        "id": "rec85p6lpxLRFybvV",
        "row": 11911,
        "state": "FLORIDA",
        "group": "3339768",
        "group_num": [
            "reclAN69QrCh0rdPp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 600,
        "2024": 0,
        "id": "recok6fG1L6ZpA0Iy",
        "row": 11886,
        "state": "FLORIDA",
        "group": "3341944",
        "group_num": [
            "recaYnJDG9JXSrqS9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 600,
        "2024": 0,
        "id": "recrcAlnlihBdNhG9",
        "row": 11884,
        "state": "FLORIDA",
        "group": "2500565",
        "group_num": [
            "recIt66bPqaprZlDU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 672,
        "2024": 0,
        "id": "recrlu27zAjCkGWEy",
        "row": 11875,
        "state": "FLORIDA",
        "group": "3333086",
        "group_num": [
            "recIiF9gJOdF1ZqD7"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 560,
        "2024": 0,
        "id": "recwixyRimuH4HgS2",
        "row": 11910,
        "state": "FLORIDA",
        "group": "333207",
        "group_num": [
            "recUg8lsC5ez3CjXQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 600,
        "2024": 0,
        "id": "recEoIiEf1rfTnBkZ",
        "row": 11887,
        "state": "FLORIDA",
        "group": "00639102",
        "group_num": [
            "reca3IAPzv2eebBOA"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 600,
        "2024": 0,
        "id": "recLZzitmuTot3L03",
        "row": 11885,
        "state": "FLORIDA",
        "group": "3337965",
        "group_num": [
            "recF0IhKs0kbb4sow"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 600,
        "2024": 0,
        "id": "recMb7JlVW3uE6Jd3",
        "row": 11883,
        "state": "FLORIDA",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 460,
        "2024": 0,
        "id": "recUT06r1FTO8a9WS",
        "row": 12006,
        "state": "FLORIDA",
        "group": "2499850",
        "group_num": [
            "recKev7W7SkHqXL2P"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 460,
        "2024": 0,
        "id": "rec4TYPvqLoohCdOf",
        "row": 12005,
        "state": "FLORIDA",
        "group": "3342810",
        "group_num": [
            "recwCZdeUNlICBQzs"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 460,
        "2024": 0,
        "id": "rec6ERwIMFxLcQV7E",
        "row": 12004,
        "state": "FLORIDA",
        "group": "3345548",
        "group_num": [
            "recHJ1z7z6pAPemuV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 491,
        "2024": 0,
        "id": "recoMD2zfBnTT5U5p",
        "row": 11972,
        "state": "FLORIDA",
        "group": "00616997",
        "group_num": [
            "recoaFyhteO7lbQbE"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 350,
        "2024": 0,
        "id": "recitV1IcvJU5DLdJ",
        "row": 12061,
        "state": "FLORIDA",
        "group": "3333849",
        "group_num": [
            "recuEuQmd0cuQAEfV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 425,
        "2024": 0,
        "id": "recmwuRirWDqNkL4I",
        "row": 12041,
        "state": "FLORIDA",
        "group": "3330535",
        "group_num": [
            "recFWBJZUcD350fWh"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 345,
        "2024": 0,
        "id": "recR5VGaauyDbpyLE",
        "row": 12064,
        "state": "FLORIDA",
        "group": "00639102",
        "group_num": [
            "reca3IAPzv2eebBOA"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 425,
        "2024": 0,
        "id": "recT2Qgf3QBEV77U2",
        "row": 12042,
        "state": "FLORIDA",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 328,
        "2024": 0,
        "id": "recUP2HuknfFTjBln",
        "row": 12068,
        "state": "FLORIDA",
        "group": "00633585",
        "group_num": [
            "rec7NZpgsEtGCKC64"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 334,
        "2024": 0,
        "id": "recVVhYei6mG0aydR",
        "row": 12067,
        "state": "FLORIDA",
        "group": "00633585",
        "group_num": [
            "rec7NZpgsEtGCKC64"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 350,
        "2024": 0,
        "id": "recVfbjByMrP0qee4",
        "row": 12062,
        "state": "FLORIDA",
        "group": "00606997",
        "group_num": [
            "rec3e6qfewc02Jian"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 350,
        "2024": 0,
        "id": "rec02xWvhZJwJEFuL",
        "row": 12060,
        "state": "FLORIDA",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 425,
        "2024": 0,
        "id": "rec1krBpYzq9BQVn3",
        "row": 12039,
        "state": "FLORIDA",
        "group": "3208240",
        "group_num": [
            "rec4hAPa6QYq6cKbp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 425,
        "2024": 0,
        "id": "reczE9QLgutRCWYQl",
        "row": 12045,
        "state": "FLORIDA",
        "group": "3341944",
        "group_num": [
            "recaYnJDG9JXSrqS9"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 428,
        "2024": 0,
        "id": "reczetA6eJyRqqYKp",
        "row": 12038,
        "state": "FLORIDA",
        "group": "00626924",
        "group_num": [
            "rec6dPnZf5wRbV21I"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 425,
        "2024": 0,
        "id": "recIJJeSSIj8sTQBW",
        "row": 12044,
        "state": "FLORIDA",
        "group": "3339907",
        "group_num": [
            "recUZCFXDESRJHBgV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 425,
        "2024": 0,
        "id": "recKbhtk2WJD93sKF",
        "row": 12043,
        "state": "FLORIDA",
        "group": "3337965",
        "group_num": [
            "recF0IhKs0kbb4sow"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 425,
        "2024": 0,
        "id": "recNB7YOcSqtZJh9z",
        "row": 12040,
        "state": "FLORIDA",
        "group": "3308496",
        "group_num": [
            "recOXJjAW6fPcy3Hv"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 295,
        "2024": 0,
        "id": "recg0SJGSR0lVhIo0",
        "row": 12117,
        "state": "FLORIDA",
        "group": "3343914",
        "group_num": [
            "recP8bISxltK1dXkX"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "recmXdjQbSdd8pdnF",
        "row": 12107,
        "state": "FLORIDA",
        "group": "3339907",
        "group_num": [
            "recUZCFXDESRJHBgV"
        ],
        "procedure": "OP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "recRv9XuncouKyq4R",
        "row": 12106,
        "state": "FLORIDA",
        "group": "3330535",
        "group_num": [
            "recFWBJZUcD350fWh"
        ],
        "procedure": "OP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "reczchEBIUsgWVkXY",
        "row": 12108,
        "state": "FLORIDA",
        "group": "00630617",
        "group_num": [
            "receFbzRUBVJOtugu"
        ],
        "procedure": "OP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 219,
        "2024": 0,
        "id": "recSJwd5hdtmcPqvC",
        "row": 12147,
        "state": "FLORIDA",
        "group": "3343249",
        "group_num": [
            "recM7UpyiBOOTyMFJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 211,
        "2024": 0,
        "id": "rec88U17Vup6VHhEy",
        "row": 12152,
        "state": "FLORIDA",
        "group": "3342810",
        "group_num": [
            "recwCZdeUNlICBQzs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 237,
        "2024": 0,
        "id": "recqHBBu8RqnEnSTh",
        "row": 12138,
        "state": "FLORIDA",
        "group": "3341266",
        "group_num": [
            "recVUxZ0k4HVxJ7iI"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 160,
        "2024": 0,
        "id": "recfITCr3EHi85Xbw",
        "row": 12206,
        "state": "FLORIDA",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recTopld9OWjIEv05",
        "row": 12188,
        "state": "FLORIDA",
        "group": "00606997",
        "group_num": [
            "rec3e6qfewc02Jian"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 158,
        "2024": 0,
        "id": "rec0LyVlc7MMk4qWi",
        "row": 12207,
        "state": "FLORIDA",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PARTNERS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 131,
        "2024": 0,
        "id": "rec7vULPKEzzjvaNC",
        "row": 12210,
        "state": "FLORIDA",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recFJvZxf9uQkeKrQ",
        "row": 12187,
        "state": "FLORIDA",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 164,
        "2023": 0,
        "2024": 0,
        "id": "recOYmAdgUuIR8oIA",
        "row": 12269,
        "state": "FLORIDA",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recOH6GXvdxgsu1AP",
        "row": 12280,
        "state": "FLORIDA",
        "group": "3344292",
        "group_num": [
            "recSMGXxihRmItinC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 0,
        "2024": 0,
        "id": "rec4LKcAo6vwOm70p",
        "row": 12318,
        "state": "FLORIDA",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recoiJc1wYe6do6aD",
        "row": 12321,
        "state": "FLORIDA",
        "group": "N53",
        "group_num": [
            "recnh4SDlwKQrpXJD"
        ],
        "procedure": "OP",
        "provider": "LUCENT HEALTH (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 232,
        "2023": 0,
        "2024": 0,
        "id": "recsDqfnv6qvw9cMs",
        "row": 12310,
        "state": "FLORIDA",
        "group": "00881200",
        "group_num": [
            "recdj30cw3SSMQ1uS"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recshuqUIEEk4HeTn",
        "row": 12278,
        "state": "FLORIDA",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 0,
        "2024": 0,
        "id": "recwJQFV20UtHJOZ5",
        "row": 12279,
        "state": "FLORIDA",
        "group": "3343912",
        "group_num": [
            "recgZrebk54j25hb7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 0,
        "2024": 0,
        "id": "reckHz4sg8qnw1Mrb",
        "row": 12340,
        "state": "FLORIDA",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "2023": 0,
        "2024": 0,
        "id": "recaYdSKx6m3Si9IL",
        "row": 12338,
        "state": "FLORIDA",
        "group": "3206324",
        "group_num": [
            "recAfgA9qHPTpUaio"
        ],
        "procedure": "OP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "2023": 0,
        "2024": 0,
        "id": "recwMpe7X7fjdwdN7",
        "row": 12326,
        "state": "FLORIDA",
        "group": "3337320",
        "group_num": [
            "rec2qf07iJTsNdPJn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "2023": 0,
        "2024": 0,
        "id": "recBVw4bH1KhKpYJU",
        "row": 12327,
        "state": "FLORIDA",
        "group": "ML0000",
        "group_num": [
            "receOjAkVAMGurEjD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rechTwsye6gAiaMy1",
        "row": 12396,
        "state": "FLORIDA",
        "group": "3343912",
        "group_num": [
            "recgZrebk54j25hb7"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recXnEh1vm1JmonQp",
        "row": 12397,
        "state": "FLORIDA",
        "group": "3344292",
        "group_num": [
            "recSMGXxihRmItinC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "rec5wq7DeEFNuxgDo",
        "row": 12395,
        "state": "FLORIDA",
        "group": "3341305",
        "group_num": [
            "recB6b2TphSGhEQSw"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recczF0JpJfZeYQke",
        "row": 12394,
        "state": "FLORIDA",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "recQxAlq8RsPdirxa",
        "row": 12434,
        "state": "FLORIDA",
        "group": "3343930",
        "group_num": [
            "rectuab38N0rh4nbS"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "recWU1EwXDVkwArz1",
        "row": 12433,
        "state": "FLORIDA",
        "group": "3340291",
        "group_num": [
            "recrpOjf7aCioVlIh"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 464,
        "2023": 0,
        "2024": 0,
        "id": "rec2GgSaYpFL8lEiy",
        "row": 12458,
        "state": "FLORIDA",
        "group": "0226017",
        "group_num": [
            "rec1vB8YjBEO99Fqa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "recavlf7wRPgTfYML",
        "row": 12436,
        "state": "FLORIDA",
        "group": "00881200",
        "group_num": [
            "recdj30cw3SSMQ1uS"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "reccOms32rK2wn7BK",
        "row": 12431,
        "state": "FLORIDA",
        "group": "3327595",
        "group_num": [
            "rec3FhvBPg4PuJVwF"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "recutLfLZ5NTHGg43",
        "row": 12432,
        "state": "FLORIDA",
        "group": "3337320",
        "group_num": [
            "rec2qf07iJTsNdPJn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "recznDjZ20SGtkJqV",
        "row": 12435,
        "state": "FLORIDA",
        "group": "3344443",
        "group_num": [
            "reckkBMebntcXW3wm"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recA5Pg4OcMNceBFR",
        "row": 12447,
        "state": "FLORIDA",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "OP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "2023": 0,
        "2024": 0,
        "id": "recDEhG64Xc69Dory",
        "row": 12430,
        "state": "FLORIDA",
        "group": "3316316",
        "group_num": [
            "recHPVVRhgUSuOdXm"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 0,
        "2024": 0,
        "id": "rechmDBqOwZNG1Ga4",
        "row": 12535,
        "state": "FLORIDA",
        "group": "3336220",
        "group_num": [
            "recii4tqRWjiF5nqG"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recilKBGHQLlZoQuF",
        "row": 12559,
        "state": "FLORIDA",
        "group": "3343930",
        "group_num": [
            "rectuab38N0rh4nbS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 638,
        "2023": 0,
        "2024": 0,
        "id": "recO3Sui0Dr6nmrPE",
        "row": 12569,
        "state": "FLORIDA",
        "group": "00631393",
        "group_num": [
            "reck78Kfw5DKknQWI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recQq2UEPkUCrjhye",
        "row": 12554,
        "state": "FLORIDA",
        "group": "2004001",
        "group_num": [
            "recDw1C221n78JZgj"
        ],
        "procedure": "RTC",
        "provider": "ALLEGIANCE (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recVdHP9U6RUISkom",
        "row": 12556,
        "state": "FLORIDA",
        "group": "3327595",
        "group_num": [
            "rec3FhvBPg4PuJVwF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recqofSp7mHmJupWv",
        "row": 12557,
        "state": "FLORIDA",
        "group": "3340291",
        "group_num": [
            "recrpOjf7aCioVlIh"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recrXbk8p56Azd2MC",
        "row": 12555,
        "state": "FLORIDA",
        "group": "3316316",
        "group_num": [
            "recHPVVRhgUSuOdXm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "2023": 0,
        "2024": 0,
        "id": "recsWo1PgkP9jzdPO",
        "row": 12536,
        "state": "FLORIDA",
        "group": "06880A003",
        "group_num": [
            "recmM3J2bSrlSbn8J"
        ],
        "procedure": "PHP",
        "provider": "CIGNA GLOBAL HEALTH BENEFITS (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recszHTvEYMPfMpon",
        "row": 12560,
        "state": "FLORIDA",
        "group": "3344443",
        "group_num": [
            "reckkBMebntcXW3wm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 563,
        "2023": 0,
        "2024": 0,
        "id": "recthO2X8HXVijiLu",
        "row": 12545,
        "state": "FLORIDA",
        "group": "3343912",
        "group_num": [
            "recgZrebk54j25hb7"
        ],
        "procedure": "OP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recyNubQKpRaXSfh3",
        "row": 12558,
        "state": "FLORIDA",
        "group": "3342025",
        "group_num": [
            "recsjiQqTthVsbtMQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 0,
        "2024": 0,
        "id": "recJy5W5p5ymNgEEg",
        "row": 12546,
        "state": "FLORIDA",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 745,
        "2023": 0,
        "2024": 0,
        "id": "recXVZEkvnXIlE7ng",
        "row": 12605,
        "state": "FLORIDA",
        "group": "3334628",
        "group_num": [
            "recTLXUcfMLBGWhX8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 653,
        "2023": 0,
        "2024": 0,
        "id": "rectYhQuy4f6ySvCE",
        "row": 12585,
        "state": "FLORIDA",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 732,
        "2023": 0,
        "2024": 0,
        "id": "recNO0MzL0IQcultN",
        "row": 12602,
        "state": "FLORIDA",
        "group": "3338618",
        "group_num": [
            "recCh0OKvM84MUJg4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "2023": 0,
        "2024": 0,
        "id": "recb7edYNcAFYahjT",
        "row": 12667,
        "state": "FLORIDA",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recmMsEUvZyrKumsw",
        "row": 12691,
        "state": "FLORIDA",
        "group": "3340291",
        "group_num": [
            "recrpOjf7aCioVlIh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recwNGxiIwU2corrC",
        "row": 12689,
        "state": "FLORIDA",
        "group": "3207160",
        "group_num": [
            "recKWrTMW3NZNU6lQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recKmT73WdPErmK34",
        "row": 12690,
        "state": "FLORIDA",
        "group": "3316316",
        "group_num": [
            "recHPVVRhgUSuOdXm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 0,
        "2024": 0,
        "id": "recMqbio2QcM3h1XI",
        "row": 12692,
        "state": "FLORIDA",
        "group": "3343930",
        "group_num": [
            "rectuab38N0rh4nbS"
        ],
        "procedure": "IOP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1024,
        "2023": 0,
        "2024": 0,
        "id": "recpVxmKvc1EX3kym",
        "row": 12747,
        "state": "FLORIDA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1079,
        "2023": 0,
        "2024": 0,
        "id": "recqXMzNSZvFutQEA",
        "row": 12751,
        "state": "FLORIDA",
        "group": "00631393",
        "group_num": [
            "reck78Kfw5DKknQWI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1375,
        "2023": 0,
        "2024": 0,
        "id": "recna3tgWDi7vUeBf",
        "row": 12789,
        "state": "FLORIDA",
        "group": "3334628",
        "group_num": [
            "recTLXUcfMLBGWhX8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1743,
        "2023": 0,
        "2024": 0,
        "id": "recR9sES4s6MpGpvj",
        "row": 12821,
        "state": "FLORIDA",
        "group": "ML0000",
        "group_num": [
            "receOjAkVAMGurEjD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1727,
        "2023": 0,
        "2024": 0,
        "id": "rec0dvvoCmpsNYzrS",
        "row": 12819,
        "state": "FLORIDA",
        "group": "3338618",
        "group_num": [
            "recCh0OKvM84MUJg4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1714,
        "2023": 0,
        "2024": 0,
        "id": "rec8uwKx2d1Nqlese",
        "row": 12817,
        "state": "FLORIDA",
        "group": "00627645",
        "group_num": [
            "recHfkbesJ9jijFyE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recOd5pGeIgvoLgu0",
        "row": 12834,
        "state": "FLORIDA",
        "group": "3341246",
        "group_num": [
            "recTQkIuxdEaUU5u0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recRRJM9xz6Px8fzI",
        "row": 12830,
        "state": "FLORIDA",
        "group": "3328490",
        "group_num": [
            "rec18yehSkze02KTC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1875,
        "2023": 0,
        "2024": 0,
        "id": "recUfzZD7f9PTd4hJ",
        "row": 12853,
        "state": "FLORIDA",
        "group": "3341302",
        "group_num": [
            "recWn9G0RbSHAYadq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1845,
        "2023": 0,
        "2024": 0,
        "id": "recXAyb8ARtEYu2JV",
        "row": 12846,
        "state": "FLORIDA",
        "group": "00634822",
        "group_num": [
            "rec3iMjkH8QkASUyz"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec2LkBvEtuVdfWZv",
        "row": 12832,
        "state": "FLORIDA",
        "group": "3339643",
        "group_num": [
            "recMaFfkUSgfVN4EF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1875,
        "2023": 0,
        "2024": 0,
        "id": "rec2jIdXXJzjVbIph",
        "row": 12852,
        "state": "FLORIDA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec4yy1dO4XfeuJfy",
        "row": 12842,
        "state": "FLORIDA",
        "group": "3210196",
        "group_num": [
            "recU5IBK7ugybj9cQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "rec8uwdxssWrQsgR3",
        "row": 12841,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "2023": 0,
        "2024": 0,
        "id": "reccwPhBATdhb0Aaa",
        "row": 12856,
        "state": "FLORIDA",
        "group": "3343119",
        "group_num": [
            "recvfbz61vSgmF6M8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recc40nlkXCbraRSu",
        "row": 12835,
        "state": "FLORIDA",
        "group": "3343794",
        "group_num": [
            "recMx0jD79cR8zJIa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recp7KQqe2hv2VXpp",
        "row": 12837,
        "state": "FLORIDA",
        "group": "00625890",
        "group_num": [
            "rec4GGpcSMz6BKVlV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1847,
        "2023": 0,
        "2024": 0,
        "id": "recu75bcqDdX9iKbP",
        "row": 12847,
        "state": "FLORIDA",
        "group": "3210196",
        "group_num": [
            "recU5IBK7ugybj9cQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recv06twUmELIPVnj",
        "row": 12831,
        "state": "FLORIDA",
        "group": "3332379",
        "group_num": [
            "rec3foQxFYJpdrJwb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recxt2Imp3r9mTok6",
        "row": 12828,
        "state": "FLORIDA",
        "group": "2498633",
        "group_num": [
            "rec74G7OJ2iBYw0hM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recEBoeBiMk5HKSu8",
        "row": 12840,
        "state": "FLORIDA",
        "group": "00634045",
        "group_num": [
            "recvYYzDYgj3iilJE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recEkgUl5nJ2MWeuN",
        "row": 12833,
        "state": "FLORIDA",
        "group": "3340334",
        "group_num": [
            "reccrVYOeWZHZFcWe"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1761,
        "2023": 0,
        "2024": 0,
        "id": "recH1ZK25IqxWWqyG",
        "row": 12825,
        "state": "FLORIDA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recHJ9MXVnTQN9qWk",
        "row": 12836,
        "state": "FLORIDA",
        "group": "00612690",
        "group_num": [
            "recRxYnk01cSGEPrf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recHk8KuQxapG6H72",
        "row": 12839,
        "state": "FLORIDA",
        "group": "00631484",
        "group_num": [
            "recKaJf5r46mrINaY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1869,
        "2023": 0,
        "2024": 0,
        "id": "recI36aNG0Ode3Vvi",
        "row": 12849,
        "state": "FLORIDA",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2103,
        "2023": 0,
        "2024": 0,
        "id": "recJWpLQ1FeNocCC5",
        "row": 12869,
        "state": "FLORIDA",
        "group": "3330972",
        "group_num": [
            "recW7cyDTaOwWqlgT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recJn9efl5p80pvq8",
        "row": 12829,
        "state": "FLORIDA",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1810,
        "2023": 0,
        "2024": 0,
        "id": "recKtLYqZEvLPGskP",
        "row": 12843,
        "state": "FLORIDA",
        "group": "3170520",
        "group_num": [
            "recQs9r2L2LOKnkqi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1830,
        "2023": 0,
        "2024": 0,
        "id": "recMUcVHTwT4Rnh4L",
        "row": 12845,
        "state": "FLORIDA",
        "group": "3336220",
        "group_num": [
            "recii4tqRWjiF5nqG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 0,
        "2024": 0,
        "id": "recMz4TBdzcVFXXTj",
        "row": 12838,
        "state": "FLORIDA",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2518,
        "2023": 1814,
        "2024": 0,
        "id": "recfbBkcH8eoHRVo8",
        "row": 12908,
        "state": "FLORIDA",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec6vCLUr6xZtTmSY",
        "row": 12916,
        "state": "FLORIDA",
        "group": "2498633",
        "group_num": [
            "rec74G7OJ2iBYw0hM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec7dviEKex1dAv2p",
        "row": 12917,
        "state": "FLORIDA",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recbZ08VVtZhN47xe",
        "row": 12921,
        "state": "FLORIDA",
        "group": "3337219",
        "group_num": [
            "recYBBBsXgPkmIi0Y"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rectnLdoGCoA4eKig",
        "row": 12919,
        "state": "FLORIDA",
        "group": "3332379",
        "group_num": [
            "rec3foQxFYJpdrJwb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recvBTzC8RVVvgkUT",
        "row": 12920,
        "state": "FLORIDA",
        "group": "3337219",
        "group_num": [
            "recYBBBsXgPkmIi0Y"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2142,
        "2023": 0,
        "2024": 0,
        "id": "recFK2I7cEHGNDsW6",
        "row": 12874,
        "state": "FLORIDA",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recKa6BJGnslmK8EC",
        "row": 12918,
        "state": "FLORIDA",
        "group": "3328490",
        "group_num": [
            "rec18yehSkze02KTC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recgekYmAQNVvc1qB",
        "row": 12929,
        "state": "FLORIDA",
        "group": "00627645",
        "group_num": [
            "recHfkbesJ9jijFyE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recn35TiGC7MorjP4",
        "row": 12924,
        "state": "FLORIDA",
        "group": "3341302",
        "group_num": [
            "recWn9G0RbSHAYadq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2800,
        "2024": 0,
        "id": "recQ43Q88IaYwsAWG",
        "row": 12923,
        "state": "FLORIDA",
        "group": "3341246",
        "group_num": [
            "recTQkIuxdEaUU5u0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2800,
        "2024": 0,
        "id": "recSCX5f8566wRlPY",
        "row": 12934,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3026,
        "2023": 0,
        "2024": 0,
        "id": "rec2C16oC0cP2HuJ1",
        "row": 12962,
        "state": "FLORIDA",
        "group": "0226017",
        "group_num": [
            "rec1vB8YjBEO99Fqa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec3X7pjc7zqnRUiT",
        "row": 12931,
        "state": "FLORIDA",
        "group": "00631484",
        "group_num": [
            "recKaJf5r46mrINaY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "rec6xauas7ioVlXVm",
        "row": 12922,
        "state": "FLORIDA",
        "group": "3339643",
        "group_num": [
            "recMaFfkUSgfVN4EF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recctbZSQxiRpRV2d",
        "row": 12927,
        "state": "FLORIDA",
        "group": "00625890",
        "group_num": [
            "rec4GGpcSMz6BKVlV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recdCKDmBdjVpBvBE",
        "row": 12932,
        "state": "FLORIDA",
        "group": "00634045",
        "group_num": [
            "recvYYzDYgj3iilJE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recwV566VsfHb7L2o",
        "row": 12926,
        "state": "FLORIDA",
        "group": "00613603",
        "group_num": [
            "recYqRrn034Kz9gJE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recATtHJBJqIC4FzB",
        "row": 12928,
        "state": "FLORIDA",
        "group": "00627267",
        "group_num": [
            "recI71TTCWHfPCy9r"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recGmwC0fSrotUxM6",
        "row": 12933,
        "state": "FLORIDA",
        "group": "00634822",
        "group_num": [
            "rec3iMjkH8QkASUyz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recLajYhOUM59DtD6",
        "row": 12930,
        "state": "FLORIDA",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3075,
        "2023": 0,
        "2024": 0,
        "id": "recMMmJm6fHlkm8mq",
        "row": 12964,
        "state": "FLORIDA",
        "group": "3343963",
        "group_num": [
            "recSyyoEFI5vr5Be3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recNaAir9fMyNlgfE",
        "row": 12925,
        "state": "FLORIDA",
        "group": "00612690",
        "group_num": [
            "recRxYnk01cSGEPrf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4140,
        "2023": 0,
        "2024": 0,
        "id": "rec3vEXMVBzaReHI8",
        "row": 12987,
        "state": "FLORIDA",
        "group": "3337592",
        "group_num": [
            "recD36wrPyid2qox5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1086,
        "2023": 1174,
        "2024": 0,
        "id": "recU4BlYnMpyJWCk4",
        "row": 8220,
        "state": "FLORIDA",
        "group": ":DAC424K",
        "group_num": [
            "recyr8jk91L8LUOkF"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1002,
        "2023": 948,
        "2024": 0,
        "id": "recFYfJ6qEInDaHpQ",
        "row": 8221,
        "state": "FLORIDA",
        "group": ":DAC424K",
        "group_num": [
            "recyr8jk91L8LUOkF"
        ],
        "procedure": "RTC",
        "provider": "DIVERSIFIED GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "2023": 0,
        "2024": 0,
        "id": "recU71RHU1zKEKWwS",
        "row": 12753,
        "state": "FLORIDA",
        "group": ":DAC424K",
        "group_num": [
            "recyr8jk91L8LUOkF"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED ADMINISTRATION CORP (DIVERSIFIED GROUP)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 986,
        "2023": 0,
        "2024": 0,
        "id": "reczatEU7IdP8hgAC",
        "row": 12734,
        "state": "FLORIDA",
        "group": ":DAC424K",
        "group_num": [
            "recyr8jk91L8LUOkF"
        ],
        "procedure": "RTC",
        "provider": "DIVERSIFIED ADMINISTRATION CORP (DIVERSIFIED GROUP)"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVEP78HdTulq8sJ",
        "row": 8222,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "EBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 2471,
        "2024": 0,
        "id": "recemwK7xzwLU8ZMD",
        "row": 8226,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 986,
        "2023": 0,
        "2024": 0,
        "id": "recW9ZLRvgL3OSetB",
        "row": 8224,
        "state": "FLORIDA",
        "group": "00480",
        "group_num": [
            "recDjEPtlVNaRJngP"
        ],
        "procedure": "RTC",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 974,
        "2023": 0,
        "2024": 0,
        "id": "rec3EHsEPLpPQEkx1",
        "row": 8223,
        "state": "FLORIDA",
        "group": "00480",
        "group_num": [
            "recDjEPtlVNaRJngP"
        ],
        "procedure": "Detox",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 974,
        "2023": 0,
        "2024": 0,
        "id": "recVDvHTQMTS4pnt3",
        "row": 12732,
        "state": "FLORIDA",
        "group": "00480",
        "group_num": [
            "recDjEPtlVNaRJngP"
        ],
        "procedure": "Detox",
        "provider": "EBMS (EMPLOYEE BENEFIT MANAGEMENT SERVICE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 986,
        "2023": 0,
        "2024": 0,
        "id": "recKxEyiU9XYA5WE7",
        "row": 12733,
        "state": "FLORIDA",
        "group": "00480",
        "group_num": [
            "recDjEPtlVNaRJngP"
        ],
        "procedure": "RTC",
        "provider": "EBMS (EMPLOYEE BENEFIT MANAGEMENT SERVICE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2139,
        "2023": 2139,
        "2024": 0,
        "id": "recVqe5vdvMzaceLW",
        "row": 12873,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "EBMS (EMPLOYEE BENEFIT MANAGEMENT SERVICE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2898,
        "2023": 0,
        "2024": 0,
        "id": "recOlGDCwGZxt23jT",
        "row": 12938,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "EBMS (EMPLOYEE BENEFIT MANAGEMENT SERVICE)"
    },
    {
        "2020": 186,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recloRbZzd1j1YwKl",
        "row": 8229,
        "state": "FLORIDA",
        "group": "00TS99",
        "group_num": [
            "recaWEIKEii5lzHxj"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 326,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPkXKMxlcHtLWz8",
        "row": 8227,
        "state": "FLORIDA",
        "group": "0V3822",
        "group_num": [
            "recrkWB3XR1kugrEh"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 163,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "rec0qtRtUP1B8fDfP",
        "row": 8228,
        "state": "FLORIDA",
        "group": "0V3822",
        "group_num": [
            "recrkWB3XR1kugrEh"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccZNMr6vhv64wbz",
        "row": 8230,
        "state": "FLORIDA",
        "group": "00TS99",
        "group_num": [
            "recaWEIKEii5lzHxj"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 492,
        "2024": 0,
        "id": "rechcqOUYLf2bmQgS",
        "row": 11971,
        "state": "FLORIDA",
        "group": "257105",
        "group_num": [
            "recYkhmr79n7PHMEJ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 522,
        "2024": 0,
        "id": "reckuHUIq7JtQU6yk",
        "row": 11958,
        "state": "FLORIDA",
        "group": "0P3667",
        "group_num": [
            "recodkQxhDyGt7E0o"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recmupDBUd5IjdPjw",
        "row": 11947,
        "state": "FLORIDA",
        "group": "209201",
        "group_num": [
            "rec1IoWSP5rcNSCdv"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recS6nt4uiDee0hwr",
        "row": 11950,
        "state": "FLORIDA",
        "group": "257104",
        "group_num": [
            "rec7AgqKBpkvJz9Jm"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recVggNovKbjPjBzh",
        "row": 11945,
        "state": "FLORIDA",
        "group": "000712",
        "group_num": [
            "recRj37Fxwxlm0XsZ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "rec2gQVCtwSZNU180",
        "row": 11953,
        "state": "FLORIDA",
        "group": "GYMACCESSSILVERHMOBC7741",
        "group_num": [
            "recAVur3heN4HuvlH"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "rec5DDNyeDzVgFnzw",
        "row": 11948,
        "state": "FLORIDA",
        "group": "226100",
        "group_num": [
            "recdQm007PgA1rHDz"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "rec6ZEan2MJyWPjbW",
        "row": 11944,
        "state": "FLORIDA",
        "group": "000622",
        "group_num": [
            "recAZ9osSvJ8w3XU8"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "rec9OnDTwgPqOrjwD",
        "row": 11946,
        "state": "FLORIDA",
        "group": "133106",
        "group_num": [
            "recKGFjlVatOVQOWi"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "2024": 0,
        "id": "recdKgK2yjx5GCjbT",
        "row": 11927,
        "state": "FLORIDA",
        "group": "253201",
        "group_num": [
            "recdqMJaAXtqlv2ys"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "2023": 516,
        "2024": 0,
        "id": "recqkuIwllmO65dVM",
        "row": 11960,
        "state": "FLORIDA",
        "group": "257106",
        "group_num": [
            "recIYJ4XyI7QeN7BC"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recrgtTNvqm9tAdQl",
        "row": 11943,
        "state": "FLORIDA",
        "group": "00H075",
        "group_num": [
            "recr74mLJcEmXBuZC"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 496,
        "2023": 525,
        "2024": 0,
        "id": "recuZcohpWl1ytpnw",
        "row": 11929,
        "state": "FLORIDA",
        "group": "293101",
        "group_num": [
            "recmzglpKuHXUqSvg"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recAvNG5rgeFkjNrj",
        "row": 11930,
        "state": "FLORIDA",
        "group": "304101",
        "group_num": [
            "recJhAKWuRbJXJKKI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recBzUqwcIbxI98hF",
        "row": 11928,
        "state": "FLORIDA",
        "group": "305106",
        "group_num": [
            "recHwUPiYh0ecHjED"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recCwV0hq6ZbrhxVO",
        "row": 11952,
        "state": "FLORIDA",
        "group": "273101",
        "group_num": [
            "recpNLeRluU50bFBU"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recFxeSyBXtGOzXtO",
        "row": 11951,
        "state": "FLORIDA",
        "group": "258205",
        "group_num": [
            "recKQz3in2zY5cGhY"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 525,
        "2024": 0,
        "id": "recLbr2F0SU3qCnks",
        "row": 11949,
        "state": "FLORIDA",
        "group": "255206",
        "group_num": [
            "recEHIoZBLEYF6kFI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "receE3HDip6I0WRY3",
        "row": 12016,
        "state": "FLORIDA",
        "group": "000622",
        "group_num": [
            "recAZ9osSvJ8w3XU8"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 450,
        "2024": 0,
        "id": "recfMNvuFxv2uZ01g",
        "row": 12011,
        "state": "FLORIDA",
        "group": "00H075",
        "group_num": [
            "recr74mLJcEmXBuZC"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "recfqXC3XJn9dZ3W9",
        "row": 12019,
        "state": "FLORIDA",
        "group": "255206",
        "group_num": [
            "recEHIoZBLEYF6kFI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "rec0rAPOJ4YUaF1jt",
        "row": 12020,
        "state": "FLORIDA",
        "group": "258205",
        "group_num": [
            "recKQz3in2zY5cGhY"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 451,
        "2024": 0,
        "id": "rec2Qt3WDaNx8yBcm",
        "row": 12009,
        "state": "FLORIDA",
        "group": "0P3667",
        "group_num": [
            "recodkQxhDyGt7E0o"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 467,
        "2024": 0,
        "id": "rec7ErUgKPThuLbOA",
        "row": 11999,
        "state": "FLORIDA",
        "group": "257106",
        "group_num": [
            "recIYJ4XyI7QeN7BC"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "recvZoixwoNRUztYs",
        "row": 12017,
        "state": "FLORIDA",
        "group": "133106",
        "group_num": [
            "recKGFjlVatOVQOWi"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "recyWPaZw9HZ2noSp",
        "row": 12021,
        "state": "FLORIDA",
        "group": "304101",
        "group_num": [
            "recJhAKWuRbJXJKKI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "reczPnBPm1Il74fvq",
        "row": 12018,
        "state": "FLORIDA",
        "group": "253201",
        "group_num": [
            "recdqMJaAXtqlv2ys"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 473,
        "2024": 0,
        "id": "recFRDAL8XmziYWQn",
        "row": 11997,
        "state": "FLORIDA",
        "group": "000797",
        "group_num": [
            "recJZczQrA2PC3uWG"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 450,
        "2024": 0,
        "id": "recIE9td6HoIDk30e",
        "row": 12010,
        "state": "FLORIDA",
        "group": "257105",
        "group_num": [
            "recYkhmr79n7PHMEJ"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 450,
        "2024": 0,
        "id": "recNZaMkkSm16Xqa0",
        "row": 12012,
        "state": "FLORIDA",
        "group": "000712",
        "group_num": [
            "recRj37Fxwxlm0XsZ"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "recgewa90rljOYsTh",
        "row": 12023,
        "state": "FLORIDA",
        "group": "MDRX01",
        "group_num": [
            "recptUgZ7FC69GQCj"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 414,
        "2024": 0,
        "id": "reciv2z0KNelZOsuJ",
        "row": 12051,
        "state": "FLORIDA",
        "group": "000797",
        "group_num": [
            "recJZczQrA2PC3uWG"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 450,
        "2024": 0,
        "id": "reciPAr7tvCGNvhec",
        "row": 12022,
        "state": "FLORIDA",
        "group": "GYMACCESSSILVERHMOBC7741",
        "group_num": [
            "recAVur3heN4HuvlH"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 436,
        "2023": 446,
        "2024": 0,
        "id": "rec2p4Rdu2dArGrBw",
        "row": 12028,
        "state": "FLORIDA",
        "group": "PADV01",
        "group_num": [
            "rec6XkgGnNmcNxIrj"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 338,
        "2024": 0,
        "id": "recCNrj0ldEWZBAcl",
        "row": 12066,
        "state": "FLORIDA",
        "group": "305106",
        "group_num": [
            "recHwUPiYh0ecHjED"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 180,
        "2024": 0,
        "id": "rechfBO1xeEWUXPau",
        "row": 12167,
        "state": "FLORIDA",
        "group": "293101",
        "group_num": [
            "recmzglpKuHXUqSvg"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 187,
        "2024": 0,
        "id": "reckGkqnc7U7qqhv0",
        "row": 12163,
        "state": "FLORIDA",
        "group": "PADV01",
        "group_num": [
            "rec6XkgGnNmcNxIrj"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "recmbmMA3x5Fq1z3U",
        "row": 12169,
        "state": "FLORIDA",
        "group": "000179",
        "group_num": [
            "rec9CBL9fnAiHHUAJ"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "recSY0D8mmvVnpG2k",
        "row": 12168,
        "state": "FLORIDA",
        "group": "000179",
        "group_num": [
            "rec9CBL9fnAiHHUAJ"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "rec7b2TCYxtXrvhEU",
        "row": 12170,
        "state": "FLORIDA",
        "group": "255206",
        "group_num": [
            "recEHIoZBLEYF6kFI"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 180,
        "2024": 0,
        "id": "recsTzs3Vkz1aABeL",
        "row": 12164,
        "state": "FLORIDA",
        "group": "00H075",
        "group_num": [
            "recr74mLJcEmXBuZC"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 180,
        "2024": 0,
        "id": "rectBZQ7icbhZRgMy",
        "row": 12165,
        "state": "FLORIDA",
        "group": "257105",
        "group_num": [
            "recYkhmr79n7PHMEJ"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 180,
        "2024": 0,
        "id": "rectk9L0psomlbwnB",
        "row": 12166,
        "state": "FLORIDA",
        "group": "257106",
        "group_num": [
            "recIYJ4XyI7QeN7BC"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "recCWlprzFIj7Rcyr",
        "row": 12171,
        "state": "FLORIDA",
        "group": "258205",
        "group_num": [
            "recKQz3in2zY5cGhY"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 230,
        "2024": 0,
        "id": "recKxi9NIGMlQMk5X",
        "row": 12140,
        "state": "FLORIDA",
        "group": "000712",
        "group_num": [
            "recRj37Fxwxlm0XsZ"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 37,
        "2024": 0,
        "id": "reclwA3PJ7rx60AST",
        "row": 12216,
        "state": "FLORIDA",
        "group": "257106",
        "group_num": [
            "recIYJ4XyI7QeN7BC"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "rec1xz0XkJ6mfA1su",
        "row": 12174,
        "state": "FLORIDA",
        "group": "MDRX01",
        "group_num": [
            "recptUgZ7FC69GQCj"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 25,
        "2024": 0,
        "id": "rec1amkxm5QfoZ6fr",
        "row": 12221,
        "state": "FLORIDA",
        "group": "293101",
        "group_num": [
            "recmzglpKuHXUqSvg"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "rec4hFnd3Lt5ogeK7",
        "row": 12173,
        "state": "FLORIDA",
        "group": "GYMACCESSSILVERHMOBC7741",
        "group_num": [
            "recAVur3heN4HuvlH"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 37,
        "2024": 0,
        "id": "recd4G0EloC4szta6",
        "row": 12217,
        "state": "FLORIDA",
        "group": "304101",
        "group_num": [
            "recJhAKWuRbJXJKKI"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 27,
        "2024": 0,
        "id": "recumnEfV3jWUi4Uw",
        "row": 12220,
        "state": "FLORIDA",
        "group": "PADV01",
        "group_num": [
            "rec6XkgGnNmcNxIrj"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 180,
        "2024": 0,
        "id": "recyq5CXGDsC9VzU5",
        "row": 12172,
        "state": "FLORIDA",
        "group": "304101",
        "group_num": [
            "recJhAKWuRbJXJKKI"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 37,
        "2024": 0,
        "id": "recHBxIjKKnSkK1fg",
        "row": 12215,
        "state": "FLORIDA",
        "group": "257101",
        "group_num": [
            "recUccOKXOejtecxq"
        ],
        "procedure": "OP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 0,
        "2024": 0,
        "id": "recWuP9cVYIyqdpeI",
        "row": 12294,
        "state": "FLORIDA",
        "group": "200101",
        "group_num": [
            "recE78GiHNUjDnmqP"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recZN2DHTCqn7m3QS",
        "row": 12449,
        "state": "FLORIDA",
        "group": "253200",
        "group_num": [
            "recYNQfzHKs223byQ"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "rec7XiY1aM8j5Fzia",
        "row": 12450,
        "state": "FLORIDA",
        "group": "293102",
        "group_num": [
            "recKxqg5RBDVzQRcL"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 445,
        "2023": 0,
        "2024": 0,
        "id": "recx0lMMIHE8deSKY",
        "row": 12438,
        "state": "FLORIDA",
        "group": "293101",
        "group_num": [
            "recmzglpKuHXUqSvg"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 0,
        "2024": 0,
        "id": "recIRCkMz3MGZrvDH",
        "row": 12448,
        "state": "FLORIDA",
        "group": "200101",
        "group_num": [
            "recE78GiHNUjDnmqP"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 501,
        "2023": 0,
        "2024": 0,
        "id": "recc9j0hnBGGABVEK",
        "row": 12509,
        "state": "FLORIDA",
        "group": "259201",
        "group_num": [
            "recnPvDjAilirE4GL"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 521,
        "2023": 0,
        "2024": 0,
        "id": "recrhRejGY5xKEBFz",
        "row": 12516,
        "state": "FLORIDA",
        "group": "293102",
        "group_num": [
            "recKxqg5RBDVzQRcL"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "reccghRDJz4YVFOOJ",
        "row": 12528,
        "state": "FLORIDA",
        "group": "200101",
        "group_num": [
            "recE78GiHNUjDnmqP"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recDBqKQWQ6PbKZOr",
        "row": 12529,
        "state": "FLORIDA",
        "group": "267101",
        "group_num": [
            "recUNxqIWbhLcPExI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 0,
        "2024": 0,
        "id": "recMzAmYjlNpR9RBc",
        "row": 12527,
        "state": "FLORIDA",
        "group": "00HS99",
        "group_num": [
            "recZ8WsOxd5mrqrlW"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA HEALTH CARE PLAN (FLORIDA HEALTH CARE)"
    },
    {
        "2020": 135,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjhsf892la7dWbS",
        "row": 8232,
        "state": "FLORIDA",
        "group": "Y00002636",
        "group_num": [
            "rec89uNHR728ncVUC"
        ],
        "procedure": "PHP",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 60,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVXle2Zpd9w3mxh",
        "row": 8233,
        "state": "FLORIDA",
        "group": "Y00002636",
        "group_num": [
            "rec89uNHR728ncVUC"
        ],
        "procedure": "OP",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 1225,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrOopLQWBVfpx0g",
        "row": 8231,
        "state": "FLORIDA",
        "group": "Y00002636",
        "group_num": [
            "rec89uNHR728ncVUC"
        ],
        "procedure": "RTC",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 1403,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZjtkQY8JXLKYKU",
        "row": 8235,
        "state": "FLORIDA",
        "group": "705214",
        "group_num": [
            "recS81ysw30m7zA6f"
        ],
        "procedure": "PHP",
        "provider": "GOLDEN RULE"
    },
    {
        "2020": 1785,
        "2021": 0,
        "2022": 1785,
        "2023": 0,
        "2024": 0,
        "id": "recN1FgDZnrByu0bc",
        "row": 8234,
        "state": "FLORIDA",
        "group": "705214",
        "group_num": [
            "recS81ysw30m7zA6f"
        ],
        "procedure": "RTC",
        "provider": "GOLDEN RULE"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnQCg8R8Yg2ME0c",
        "row": 8246,
        "state": "FLORIDA",
        "group": "ST7090",
        "group_num": [
            "recfBwbjoo50aFTuz"
        ],
        "procedure": "Detox",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 666,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPuStMUgO1gKKpt",
        "row": 8240,
        "state": "FLORIDA",
        "group": "FT7005",
        "group_num": [
            "recufj4hxwzhxg63o"
        ],
        "procedure": "RTC",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 205,
        "2021": 205,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXt5Owepq0SVfxz",
        "row": 8247,
        "state": "FLORIDA",
        "group": "ST7090",
        "group_num": [
            "recfBwbjoo50aFTuz"
        ],
        "procedure": "IOP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 436,
        "2021": 436,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXOg4MmYdycj1wF",
        "row": 8237,
        "state": "FLORIDA",
        "group": "FT7004",
        "group_num": [
            "reckiKOOHkc3QnlCH"
        ],
        "procedure": "PHP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 425,
        "2021": 425,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec28T6MKtctAFCVr",
        "row": 8243,
        "state": "FLORIDA",
        "group": "FT7090",
        "group_num": [
            "recNJEg4ytuKKqSoW"
        ],
        "procedure": "PHP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 205,
        "2021": 300,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recdeUZ5wzWZ1vXj2",
        "row": 8238,
        "state": "FLORIDA",
        "group": "FT7004",
        "group_num": [
            "reckiKOOHkc3QnlCH"
        ],
        "procedure": "IOP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqS3KyQRTiPXIHx",
        "row": 8242,
        "state": "FLORIDA",
        "group": "FT7090",
        "group_num": [
            "recNJEg4ytuKKqSoW"
        ],
        "procedure": "RTC",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 45,
        "2021": 45,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqnMa8gUudQ6keC",
        "row": 8239,
        "state": "FLORIDA",
        "group": "FT7004",
        "group_num": [
            "reckiKOOHkc3QnlCH"
        ],
        "procedure": "OP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqrU12RZLM1iS2h",
        "row": 8244,
        "state": "FLORIDA",
        "group": "FT10000",
        "group_num": [
            "recO6aPIhqpKpJTRM"
        ],
        "procedure": "RTC",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 459,
        "2021": 666,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrjmVATcabxRHuq",
        "row": 8236,
        "state": "FLORIDA",
        "group": "FT7004",
        "group_num": [
            "reckiKOOHkc3QnlCH"
        ],
        "procedure": "RTC",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 45,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recshidZ7HTA2pFzB",
        "row": 8248,
        "state": "FLORIDA",
        "group": "ST7090",
        "group_num": [
            "recfBwbjoo50aFTuz"
        ],
        "procedure": "OP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recICVIoUdY1sSCh6",
        "row": 8245,
        "state": "FLORIDA",
        "group": "FT70002",
        "group_num": [
            "reciClBLFz6ugPSZp"
        ],
        "procedure": "RTC",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 436,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNye3jmsuki0Z4O",
        "row": 8241,
        "state": "FLORIDA",
        "group": "FT7005",
        "group_num": [
            "recufj4hxwzhxg63o"
        ],
        "procedure": "PHP",
        "provider": "HEALTH FIRST HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 400,
        "2024": 0,
        "id": "rec2SXWD50a6Khvbu",
        "row": 12054,
        "state": "FLORIDA",
        "group": "3611",
        "group_num": [
            "recYaKub50G57U03L"
        ],
        "procedure": "RTC",
        "provider": "3611 (HEALTH PARTNERS)"
    },
    {
        "2020": 283,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRmMGVVeejDqH3L",
        "row": 8251,
        "state": "FLORIDA",
        "group": "W08",
        "group_num": [
            "recCDDHfElIGDVjB4"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 1100,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYTLIWbUirHx0fT",
        "row": 8249,
        "state": "FLORIDA",
        "group": "W08",
        "group_num": [
            "recCDDHfElIGDVjB4"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 2300,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec1hmSTkVDICp7i1",
        "row": 8250,
        "state": "FLORIDA",
        "group": "W08",
        "group_num": [
            "recCDDHfElIGDVjB4"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3282,
        "2024": 0,
        "id": "recynCTSwLTv57ylv",
        "row": 8253,
        "state": "FLORIDA",
        "group": "WD2",
        "group_num": [
            "recu1aw9eXgMBawlX"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 847,
        "2024": 0,
        "id": "recCpFBsCtGyVmkbx",
        "row": 8254,
        "state": "FLORIDA",
        "group": "WD2",
        "group_num": [
            "recu1aw9eXgMBawlX"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 86,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDp47vd2qZsCNpu",
        "row": 8252,
        "state": "FLORIDA",
        "group": "W08",
        "group_num": [
            "recCDDHfElIGDVjB4"
        ],
        "procedure": "OP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 847,
        "2023": 0,
        "2024": 0,
        "id": "recve4d65g8q7hror",
        "row": 12665,
        "state": "FLORIDA",
        "group": "WD2",
        "group_num": [
            "recu1aw9eXgMBawlX"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLAN INC (HEALTH PLANS INC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3282,
        "2023": 0,
        "2024": 0,
        "id": "recNJZl9uAws1srHy",
        "row": 12975,
        "state": "FLORIDA",
        "group": "WD2",
        "group_num": [
            "recu1aw9eXgMBawlX"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PLAN INC (HEALTH PLANS INC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "receabjfzv7VdEnzJ",
        "row": 11498,
        "state": "FLORIDA",
        "group": "OJJH834",
        "group_num": [
            "recMo0CGek3m94VA1"
        ],
        "procedure": "Detox",
        "provider": "HEALTH SMART (HEALTH SMART)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1129,
        "2024": 0,
        "id": "recR1tktncsr7KFIw",
        "row": 11711,
        "state": "FLORIDA",
        "group": "OJJH834",
        "group_num": [
            "recMo0CGek3m94VA1"
        ],
        "procedure": "RTC",
        "provider": "HEALTH SMART (HEALTH SMART)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 830,
        "2024": 0,
        "id": "recMpsHt1lsaqZv6W",
        "row": 8256,
        "state": "FLORIDA",
        "group": "119948",
        "group_num": [
            "recnowtFeCMS4iF13"
        ],
        "procedure": "RTC",
        "provider": "HEALTHCOMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 824,
        "2023": 884,
        "2024": 0,
        "id": "recf9EKs78MbczdIk",
        "row": 11833,
        "state": "FLORIDA",
        "group": "119948",
        "group_num": [
            "recnowtFeCMS4iF13"
        ],
        "procedure": "RTC",
        "provider": "HEALTHCOMP (HEALTHCOMP)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3138,
        "2023": 3138,
        "2024": 0,
        "id": "recBRKV5NAGGjxWbN",
        "row": 12971,
        "state": "FLORIDA",
        "group": "119948",
        "group_num": [
            "recnowtFeCMS4iF13"
        ],
        "procedure": "Detox",
        "provider": "HEALTHCOMP (HEALTHCOMP)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 54880,
        "2024": 0,
        "id": "recJIl4VRZ1vYyw50",
        "row": 8257,
        "state": "FLORIDA",
        "group": "0180964713",
        "group_num": [
            "recTOwLPiDfTzi2T3"
        ],
        "procedure": "RTC",
        "provider": "HEALTHLINK"
    },
    {
        "2020": 0,
        "2021": 750,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recoFL3w2DtB5O0b3",
        "row": 8258,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 663,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrNmXm5FEvAf5RW",
        "row": 8259,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 204,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjFbmtz5ZgTBHna",
        "row": 8260,
        "state": "FLORIDA",
        "group": "55437",
        "group_num": [
            "recLNITYjUtpHidux"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 356,
        "2023": 0,
        "2024": 0,
        "id": "recOMwzbTcyZy33aQ",
        "row": 8263,
        "state": "FLORIDA",
        "group": "743356",
        "group_num": [
            "rec9zkHiXrvmUUUaZ"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 77,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7Z4dAJ9F9ql88R",
        "row": 8261,
        "state": "FLORIDA",
        "group": "55437",
        "group_num": [
            "recLNITYjUtpHidux"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 518,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recP9LAbASKhfzrxc",
        "row": 8270,
        "state": "FLORIDA",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 660,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQxouuZ3Uo3zY5a",
        "row": 8267,
        "state": "FLORIDA",
        "group": "837799",
        "group_num": [
            "recCbtNvM6XC0xF3X"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 686,
        "2023": 0,
        "2024": 0,
        "id": "recToU4s44WQ9URjn",
        "row": 8264,
        "state": "FLORIDA",
        "group": "777843",
        "group_num": [
            "recoVHFCqSQ6MwP0w"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 243,
        "2023": 0,
        "2024": 0,
        "id": "recbmStRXfyeBfqZF",
        "row": 8266,
        "state": "FLORIDA",
        "group": "834695",
        "group_num": [
            "rechu30yYO2UWMXaZ"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 649,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyLRXVPsF2NWRDw",
        "row": 8265,
        "state": "FLORIDA",
        "group": "827952",
        "group_num": [
            "reckkKT51SafrU5XL"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 437,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recA5Kwg8sFgOZ3T7",
        "row": 8268,
        "state": "FLORIDA",
        "group": "837799",
        "group_num": [
            "recCbtNvM6XC0xF3X"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 748,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMrrLCa1Ad43Jv9",
        "row": 8269,
        "state": "FLORIDA",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 665,
        "2024": 0,
        "id": "recogRoydTit7JJY0",
        "row": 11876,
        "state": "FLORIDA",
        "group": "843093",
        "group_num": [
            "recyEo7q27eRUoF0R"
        ],
        "procedure": "RTC",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 350,
        "2024": 0,
        "id": "recRiwXzRMcoqmJ99",
        "row": 12063,
        "state": "FLORIDA",
        "group": "843093",
        "group_num": [
            "recyEo7q27eRUoF0R"
        ],
        "procedure": "PHP",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 200,
        "2024": 0,
        "id": "recqtOBeDEfJZZuAP",
        "row": 12155,
        "state": "FLORIDA",
        "group": "843093",
        "group_num": [
            "recyEo7q27eRUoF0R"
        ],
        "procedure": "IOP",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "2023": 0,
        "2024": 0,
        "id": "recZj8hl9yPFTCBEZ",
        "row": 12302,
        "state": "FLORIDA",
        "group": "830587",
        "group_num": [
            "recpQXkUuY3uPzROV"
        ],
        "procedure": "IOP",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 243,
        "2023": 0,
        "2024": 0,
        "id": "recGtkVi8mI0WCfMi",
        "row": 12316,
        "state": "FLORIDA",
        "group": "834695",
        "group_num": [
            "rechu30yYO2UWMXaZ"
        ],
        "procedure": "IOP",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 356,
        "2023": 0,
        "2024": 0,
        "id": "recYtQngh6qpo1z76",
        "row": 12408,
        "state": "FLORIDA",
        "group": "743356",
        "group_num": [
            "rec9zkHiXrvmUUUaZ"
        ],
        "procedure": "RTC",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 0,
        "2024": 0,
        "id": "recwYozfjyOtm2eTl",
        "row": 12407,
        "state": "FLORIDA",
        "group": "830587",
        "group_num": [
            "recpQXkUuY3uPzROV"
        ],
        "procedure": "PHP",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 805,
        "2023": 0,
        "2024": 0,
        "id": "rec5rOyNSeCobTqfr",
        "row": 12615,
        "state": "FLORIDA",
        "group": "743356",
        "group_num": [
            "rec9zkHiXrvmUUUaZ"
        ],
        "procedure": "Detox",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 903,
        "2023": 0,
        "2024": 0,
        "id": "recmmYC5liUJK7Ltv",
        "row": 12693,
        "state": "FLORIDA",
        "group": "Y0637001",
        "group_num": [
            "recpy7dKejCdVYNCV"
        ],
        "procedure": "RTC",
        "provider": "HUMANA (HUMANA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1050,
        "2024": 0,
        "id": "recoWhJiyMQqpNLfc",
        "row": 11719,
        "state": "FLORIDA",
        "group": "H880309",
        "group_num": [
            "recMooI0b1MsHTFJ6"
        ],
        "procedure": "RTC",
        "provider": "IMAGINE360 (IMAGINE360)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 297,
        "2024": 0,
        "id": "recvZ3HfGBfkqrzvH",
        "row": 12114,
        "state": "FLORIDA",
        "group": "H880309",
        "group_num": [
            "recMooI0b1MsHTFJ6"
        ],
        "procedure": "PHP",
        "provider": "IMAGINE360 (IMAGINE360)"
    },
    {
        "2020": 0,
        "2021": 4800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXKHBV35GKoQuL3",
        "row": 8271,
        "state": "FLORIDA",
        "group": "7775971",
        "group_num": [
            "recXYBnvfTmyILslC"
        ],
        "procedure": "Detox",
        "provider": "JP FARLEY CORP"
    },
    {
        "2020": 0,
        "2021": 1694,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczruniwERctqjpJ",
        "row": 8272,
        "state": "FLORIDA",
        "group": "7775971",
        "group_num": [
            "recXYBnvfTmyILslC"
        ],
        "procedure": "RTC",
        "provider": "JP FARLEY CORP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 775,
        "2023": 0,
        "2024": 0,
        "id": "recku77htogwr0vjh",
        "row": 8274,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "LINECO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "2023": 0,
        "2024": 0,
        "id": "recwz9WD6xjWSpPck",
        "row": 8273,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "LINECO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 775,
        "2023": 0,
        "2024": 0,
        "id": "recD6FVsZIixAEQri",
        "row": 12608,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "LINECO (LINECO)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "2023": 0,
        "2024": 0,
        "id": "recAfIzSNGq5ioxI1",
        "row": 12668,
        "state": "FLORIDA",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "LINECO (LINECO)"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckrSlhSMmSfVQju",
        "row": 8276,
        "state": "FLORIDA",
        "group": "000PTH83401BP00",
        "group_num": [
            "recZLdTx5CnZM4JSo"
        ],
        "procedure": "RTC",
        "provider": "LOWER HUDSON VALLEY EAP"
    },
    {
        "2020": 0,
        "2021": 825,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recombsbYbVt79xnN",
        "row": 8275,
        "state": "FLORIDA",
        "group": "000PTH83401BP00",
        "group_num": [
            "recZLdTx5CnZM4JSo"
        ],
        "procedure": "Detox",
        "provider": "LOWER HUDSON VALLEY EAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 334,
        "2023": 0,
        "2024": 0,
        "id": "recUBIBchobSzn4fG",
        "row": 8278,
        "state": "FLORIDA",
        "group": "B09",
        "group_num": [
            "recx25e46KUejesTN"
        ],
        "procedure": "PHP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 334,
        "2023": 0,
        "2024": 0,
        "id": "recYvUdoaG6kyvZbx",
        "row": 8279,
        "state": "FLORIDA",
        "group": "B09",
        "group_num": [
            "recx25e46KUejesTN"
        ],
        "procedure": "IOP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 807,
        "2022": 1172,
        "2023": 0,
        "2024": 0,
        "id": "rectsrIdPmX8tlrlB",
        "row": 8277,
        "state": "FLORIDA",
        "group": "B09",
        "group_num": [
            "recx25e46KUejesTN"
        ],
        "procedure": "RTC",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 0,
        "2024": 0,
        "id": "recOmR8pAolulvYcJ",
        "row": 8281,
        "state": "FLORIDA",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectEQCz98ukVPT4J",
        "row": 8282,
        "state": "FLORIDA",
        "group": "028565801000004",
        "group_num": [
            "reci7Bi9LsyyShHwq"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 1870,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect6Ckh7KgMPXqTH",
        "row": 8283,
        "state": "FLORIDA",
        "group": "028565801000004",
        "group_num": [
            "reci7Bi9LsyyShHwq"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 500,
        "2022": 250,
        "2023": 0,
        "2024": 0,
        "id": "recBUOvBguA79ERkA",
        "row": 8280,
        "state": "FLORIDA",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 580,
        "2024": 0,
        "id": "rec46rG77GpADix3l",
        "row": 11890,
        "state": "FLORIDA",
        "group": "153",
        "group_num": [
            "recONEROyLFXCQlU3"
        ],
        "procedure": "Detox",
        "provider": "MAGNACARE (MAGNACARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 966,
        "2023": 0,
        "2024": 0,
        "id": "recSsea1lRtb7mJKp",
        "row": 8284,
        "state": "FLORIDA",
        "group": "GR120",
        "group_num": [
            "recP2bG4YjkRygTrH"
        ],
        "procedure": "Detox",
        "provider": "MARPAI FKA CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1482,
        "2023": 0,
        "2024": 0,
        "id": "rec3nlq5CnF482pdG",
        "row": 8285,
        "state": "FLORIDA",
        "group": "GR120",
        "group_num": [
            "recP2bG4YjkRygTrH"
        ],
        "procedure": "RTC",
        "provider": "MARPAI FKA CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4146,
        "2023": 0,
        "2024": 0,
        "id": "recXFhDcHCb4zOZ9e",
        "row": 8289,
        "state": "FLORIDA",
        "group": "WAK",
        "group_num": [
            "rec1kwI3Wm8GprSBB"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec9sOwJu4xrbrP7s",
        "row": 8287,
        "state": "FLORIDA",
        "group": "7107",
        "group_num": [
            "recL6aIl5bXyY5ogy"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recf2XwadQO0ayIj6",
        "row": 11500,
        "state": "FLORIDA",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2976,
        "2024": 0,
        "id": "recz9iQfRsW11q35E",
        "row": 11512,
        "state": "FLORIDA",
        "group": "8180",
        "group_num": [
            "recDDjj9Jw8sYYlfR"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 954,
        "2024": 0,
        "id": "recOC492YKrGANmci",
        "row": 11746,
        "state": "FLORIDA",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST BENEFIT SERVICES (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 999,
        "2024": 0,
        "id": "recYpFmJnvT0nZX2W",
        "row": 11723,
        "state": "FLORIDA",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 929,
        "2024": 0,
        "id": "rec2ALAwJHSAswDOs",
        "row": 11801,
        "state": "FLORIDA",
        "group": "8180",
        "group_num": [
            "recDDjj9Jw8sYYlfR"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "rect9NCibIZYzmLNf",
        "row": 11825,
        "state": "FLORIDA",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "PHP",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 617,
        "2024": 0,
        "id": "recAoePURt37QlAZh",
        "row": 11879,
        "state": "FLORIDA",
        "group": "8180",
        "group_num": [
            "recDDjj9Jw8sYYlfR"
        ],
        "procedure": "PHP",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 288,
        "2024": 0,
        "id": "recGBPZgzPdD1w3Qw",
        "row": 12124,
        "state": "FLORIDA",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "IOP",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recsz0KX0yRPLFAoh",
        "row": 12896,
        "state": "FLORIDA",
        "group": "7107",
        "group_num": [
            "recL6aIl5bXyY5ogy"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST BENEFIT SERVICES (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recXQ7Nd4wTt8VE0v",
        "row": 12947,
        "state": "FLORIDA",
        "group": "7107",
        "group_num": [
            "recL6aIl5bXyY5ogy"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST BENEFIT SERVICES (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4146,
        "2023": 0,
        "2024": 0,
        "id": "recm4BfXAG5udQFEA",
        "row": 12989,
        "state": "FLORIDA",
        "group": "WAK",
        "group_num": [
            "rec1kwI3Wm8GprSBB"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 0,
        "2024": 0,
        "id": "recPq22VkiaKoUTYe",
        "row": 13016,
        "state": "FLORIDA",
        "group": "WAK",
        "group_num": [
            "rec1kwI3Wm8GprSBB"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST INC (MEDCOST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recVYGfq28qvdytcz",
        "row": 8291,
        "state": "FLORIDA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3088,
        "2023": 3088,
        "2024": 0,
        "id": "recbKQCCDRegS0uCS",
        "row": 8293,
        "state": "FLORIDA",
        "group": "A0022",
        "group_num": [
            "rec5Gx9cEIgdcinOc"
        ],
        "procedure": "RTC",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recMGmmgf0tW1mHvL",
        "row": 8292,
        "state": "FLORIDA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "RTC",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recYiwWwgpnudwke9",
        "row": 12885,
        "state": "FLORIDA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "RTC",
        "provider": "MAYO CLINIC HEALTH SOLUTIONS (MEDICA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec6AzqRBesn2YgpM",
        "row": 12888,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3088,
        "2023": 0,
        "2024": 0,
        "id": "recPGc7tGJm3G2hWp",
        "row": 12965,
        "state": "FLORIDA",
        "group": "A0022",
        "group_num": [
            "rec5Gx9cEIgdcinOc"
        ],
        "procedure": "RTC",
        "provider": "MEDICA (MEDICA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recrCM84NgeNvZSaC",
        "row": 12946,
        "state": "FLORIDA",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MAYO CLINIC HEALTH SOLUTIONS (MEDICA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "2023": 0,
        "2024": 0,
        "id": "recXW0ycvZ6Moi8pb",
        "row": 8297,
        "state": "FLORIDA",
        "group": "851718563",
        "group_num": [
            "rec64h4aZ1C9xRyo1"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 881,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDNL54sAktxU5P7",
        "row": 8294,
        "state": "FLORIDA",
        "group": "229030340",
        "group_num": [
            "rec25sqriwk1x86eh"
        ],
        "procedure": "Detox",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 251,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFot8d6aAvDf4Mx",
        "row": 8295,
        "state": "FLORIDA",
        "group": "229030340",
        "group_num": [
            "rec25sqriwk1x86eh"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "2023": 0,
        "2024": 0,
        "id": "recMHTx595oYfsr70",
        "row": 8296,
        "state": "FLORIDA",
        "group": "418470272",
        "group_num": [
            "recz5aHckRN1UAw60"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "2023": 0,
        "2024": 0,
        "id": "recwZE0MblsEFYtXM",
        "row": 12323,
        "state": "FLORIDA",
        "group": "851718563",
        "group_num": [
            "rec64h4aZ1C9xRyo1"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH (MEDICAL MUTUAL OF OH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "2023": 0,
        "2024": 0,
        "id": "recA9LVwtbK7NdW5S",
        "row": 12322,
        "state": "FLORIDA",
        "group": "418470272",
        "group_num": [
            "recz5aHckRN1UAw60"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH (MEDICAL MUTUAL OF OH)"
    },
    {
        "2020": 0,
        "2021": 880,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfg2eMF2To1YIei",
        "row": 8304,
        "state": "FLORIDA",
        "group": "15776",
        "group_num": [
            "recbdIqCn1YG6sBlD"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 883,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmD9lgsiz8d86Vd",
        "row": 8306,
        "state": "FLORIDA",
        "group": "16117",
        "group_num": [
            "recQ0ypHx47JoGg8N"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 436,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPBFGYBbRe7iOmc",
        "row": 8311,
        "state": "FLORIDA",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 897,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPlSguWuOjpf93C",
        "row": 8307,
        "state": "FLORIDA",
        "group": "16117",
        "group_num": [
            "recQ0ypHx47JoGg8N"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 205,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recVXQGjDW7WFV7IB",
        "row": 8301,
        "state": "FLORIDA",
        "group": "14351",
        "group_num": [
            "recLzIKF8HAtR3SMr"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 810,
        "2022": 1748,
        "2023": 0,
        "2024": 0,
        "id": "recYukhqftciXrPs6",
        "row": 8310,
        "state": "FLORIDA",
        "group": "16220",
        "group_num": [
            "recTNQTurntHQYIaw"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 210,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYip7JXtgVriGmi",
        "row": 8312,
        "state": "FLORIDA",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 666,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZnjPimwovoe8zN",
        "row": 8302,
        "state": "FLORIDA",
        "group": "15149",
        "group_num": [
            "rec6i1wnmqEDmR0zu"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 844,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2cWhgOShdHP4bT",
        "row": 8303,
        "state": "FLORIDA",
        "group": "15776",
        "group_num": [
            "recbdIqCn1YG6sBlD"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 1196,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2mq6RaejEkgIFp",
        "row": 8298,
        "state": "FLORIDA",
        "group": "14198",
        "group_num": [
            "rec5yx86FHBNivZHV"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 1018,
        "2021": 873,
        "2022": 2961,
        "2023": 0,
        "2024": 0,
        "id": "recbJb2AAwGPvN29L",
        "row": 8309,
        "state": "FLORIDA",
        "group": "16220",
        "group_num": [
            "recTNQTurntHQYIaw"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 466,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectWozMOjvnXYYrm",
        "row": 8308,
        "state": "FLORIDA",
        "group": "16117",
        "group_num": [
            "recQ0ypHx47JoGg8N"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 466,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvFFDjwcK7JWQ8y",
        "row": 8305,
        "state": "FLORIDA",
        "group": "15776",
        "group_num": [
            "recbdIqCn1YG6sBlD"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 653,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczLmvpeetFs233r",
        "row": 8299,
        "state": "FLORIDA",
        "group": "14198",
        "group_num": [
            "rec5yx86FHBNivZHV"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 455,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recB2yF2dMBOOoUQK",
        "row": 8300,
        "state": "FLORIDA",
        "group": "14198",
        "group_num": [
            "rec5yx86FHBNivZHV"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 440,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjHKeI9OhkBUAKa",
        "row": 8318,
        "state": "FLORIDA",
        "group": "A5011",
        "group_num": [
            "recyqUUSZ18DO55rw"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 411,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYUh54y7Y0l0Bme",
        "row": 8317,
        "state": "FLORIDA",
        "group": "A5011",
        "group_num": [
            "recyqUUSZ18DO55rw"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recYt0844ScMfZwrG",
        "row": 8314,
        "state": "FLORIDA",
        "group": "17711",
        "group_num": [
            "rec84UcRUtT2PsGYr"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recMMPGDTULZZT49j",
        "row": 8316,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1995,
        "2024": 0,
        "id": "recl3e4L7g0HWlRkL",
        "row": 11595,
        "state": "FLORIDA",
        "group": "14103",
        "group_num": [
            "recU9bHbFM80PecUj"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1510,
        "2024": 0,
        "id": "recU5hzckgtDSgmvy",
        "row": 11670,
        "state": "FLORIDA",
        "group": "14321",
        "group_num": [
            "recpgENzsWSWTtWko"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1652,
        "2024": 0,
        "id": "recv2cAhsaRmmctMk",
        "row": 11654,
        "state": "FLORIDA",
        "group": "14956",
        "group_num": [
            "recaV23UrqExF8c6e"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1308,
        "2024": 0,
        "id": "rec3DaO0UXBEFubgd",
        "row": 11695,
        "state": "FLORIDA",
        "group": "14956",
        "group_num": [
            "recaV23UrqExF8c6e"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1363,
        "2024": 0,
        "id": "recxpug8vUYjLPJoa",
        "row": 11689,
        "state": "FLORIDA",
        "group": "14321",
        "group_num": [
            "recpgENzsWSWTtWko"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recgN3O7YrsLIlBoY",
        "row": 12719,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 0,
        "2024": 0,
        "id": "recKCcNUOk4o2Dmau",
        "row": 12718,
        "state": "FLORIDA",
        "group": "17711",
        "group_num": [
            "rec84UcRUtT2PsGYr"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1201,
        "2023": 0,
        "2024": 0,
        "id": "recSKCxAVmh5rFUtP",
        "row": 12769,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1201,
        "2023": 0,
        "2024": 0,
        "id": "recLQSGf8ZTojJ97G",
        "row": 12768,
        "state": "FLORIDA",
        "group": "17711",
        "group_num": [
            "rec84UcRUtT2PsGYr"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2138,
        "2023": 0,
        "2024": 0,
        "id": "recjjEPgU2jOYN586",
        "row": 12872,
        "state": "FLORIDA",
        "group": "16220",
        "group_num": [
            "recTNQTurntHQYIaw"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2961,
        "2023": 0,
        "2024": 0,
        "id": "recWqKQeKn3J9CDRj",
        "row": 12940,
        "state": "FLORIDA",
        "group": "16220",
        "group_num": [
            "recTNQTurntHQYIaw"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 677,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recag771LcHaYOEk8",
        "row": 8319,
        "state": "FLORIDA",
        "group": "009632",
        "group_num": [
            "recNDbbYWdvvoNSeH"
        ],
        "procedure": "Detox",
        "provider": "MHN"
    },
    {
        "2020": 677,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczWPgrPGQB2A58x",
        "row": 8320,
        "state": "FLORIDA",
        "group": "009632",
        "group_num": [
            "recNDbbYWdvvoNSeH"
        ],
        "procedure": "RTC",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 500,
        "2024": 0,
        "id": "rec58ziSN8XtrDJpL",
        "row": 8322,
        "state": "FLORIDA",
        "group": "002320618",
        "group_num": [
            "recBtPb0B3Hd6ZYT5"
        ],
        "procedure": "RTC",
        "provider": "MODERN ASSISTANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 600,
        "2024": 0,
        "id": "reczDwreIvhypFi2w",
        "row": 8321,
        "state": "FLORIDA",
        "group": "002320618",
        "group_num": [
            "recBtPb0B3Hd6ZYT5"
        ],
        "procedure": "Detox",
        "provider": "MODERN ASSISTANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "rec6kHsiitDZTMfX9",
        "row": 12505,
        "state": "FLORIDA",
        "group": "002320618",
        "group_num": [
            "recBtPb0B3Hd6ZYT5"
        ],
        "procedure": "RTC",
        "provider": "MODERN ASSISTANCE (MODERN ASSISTANCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recgUJPWpHLuCqx6u",
        "row": 12561,
        "state": "FLORIDA",
        "group": "002320618",
        "group_num": [
            "recBtPb0B3Hd6ZYT5"
        ],
        "procedure": "Detox",
        "provider": "MODERN ASSISTANCE (MODERN ASSISTANCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2200,
        "2024": 0,
        "id": "recSQjcbtq0AF3eCb",
        "row": 11569,
        "state": "FLORIDA",
        "group": "413891",
        "group_num": [
            "recGLJmPSwoUHHrqi"
        ],
        "procedure": "PHP",
        "provider": "MVP HEALTHCARE (MVP HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2100,
        "2024": 0,
        "id": "reciIYiKQFwVTSa8c",
        "row": 11579,
        "state": "FLORIDA",
        "group": "490663",
        "group_num": [
            "rec7MHgRnZaGGtZfV"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTHCARE (MVP HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1800,
        "2024": 0,
        "id": "reclvZCdLfT93tX6C",
        "row": 11619,
        "state": "FLORIDA",
        "group": "413891",
        "group_num": [
            "recGLJmPSwoUHHrqi"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTHCARE (MVP HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 600,
        "2024": 0,
        "id": "recc9SLq4yzGQykE4",
        "row": 11888,
        "state": "FLORIDA",
        "group": "413891",
        "group_num": [
            "recGLJmPSwoUHHrqi"
        ],
        "procedure": "OP",
        "provider": "MVP HEALTHCARE (MVP HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recXHUArqVtSYLIrP",
        "row": 8324,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "NIPPON LIFE INSURANCE COMPANY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec05jnNqSviLVqBd",
        "row": 12886,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "NIPPON LIVE BENEFITS (NIPPON LIFE INSURANCE COMPANY)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recfLerLNl4nkX0fq",
        "row": 12948,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "NIPPON LIVE BENEFITS (NIPPON LIFE INSURANCE COMPANY)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 104,
        "2023": 0,
        "2024": 0,
        "id": "recezNwODBxpNLWPM",
        "row": 8325,
        "state": "FLORIDA",
        "group": "72732",
        "group_num": [
            "rec79sNCZyYgqRdQD"
        ],
        "procedure": "PHP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2321,
        "2024": 0,
        "id": "recX0mEAmRMyRX5Mb",
        "row": 11561,
        "state": "FLORIDA",
        "group": "72713",
        "group_num": [
            "recHQh5j25km5l0zz"
        ],
        "procedure": "RTC",
        "provider": "OPTIMA HEALTH (OPTIMA HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1500,
        "2024": 0,
        "id": "reccB4wEKIpTwdLz7",
        "row": 11675,
        "state": "FLORIDA",
        "group": "72713",
        "group_num": [
            "recHQh5j25km5l0zz"
        ],
        "procedure": "PHP",
        "provider": "OPTIMA HEALTH (OPTIMA HEALTH)"
    },
    {
        "2020": 235,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receCSXJvdzBEyG69",
        "row": 8329,
        "state": "FLORIDA",
        "group": "1275477",
        "group_num": [
            "recQnJoviv8IjsWGd"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 219,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOt56isGC3wy3zs",
        "row": 8326,
        "state": "FLORIDA",
        "group": "1267026",
        "group_num": [
            "recMypLG5Vq5lwBRR"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 297,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRIR6VrQIQjJFRu",
        "row": 8345,
        "state": "FLORIDA",
        "group": "OI3859",
        "group_num": [
            "reclNqnom5eyWCMWK"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 43,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5icRbXuX6tXwUT",
        "row": 8343,
        "state": "FLORIDA",
        "group": "AT2285",
        "group_num": [
            "recU1v7SdhKovg8ar"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 122,
        "2023": 0,
        "2024": 0,
        "id": "recbCnOFwL5OL36ED",
        "row": 8341,
        "state": "FLORIDA",
        "group": "1438082",
        "group_num": [
            "rec2aiCrOXNT0vC0n"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4915,
        "2023": 0,
        "2024": 0,
        "id": "recbKHR9QwqSgaan6",
        "row": 8327,
        "state": "FLORIDA",
        "group": "1270366",
        "group_num": [
            "recVmZcnClMa1xeEb"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "2023": 0,
        "2024": 0,
        "id": "reccbmziFroqbEWEB",
        "row": 8340,
        "state": "FLORIDA",
        "group": "1409905",
        "group_num": [
            "recTauXQmDPUFtYod"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "2023": 0,
        "2024": 0,
        "id": "recooLrqKHiUmdZPI",
        "row": 8335,
        "state": "FLORIDA",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 758,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqa4VGgdUXU2jw3",
        "row": 8344,
        "state": "FLORIDA",
        "group": "OI3859",
        "group_num": [
            "reclNqnom5eyWCMWK"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 649,
        "2023": 0,
        "2024": 0,
        "id": "recEtom7cJKachLvE",
        "row": 8334,
        "state": "FLORIDA",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 289,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJorFNrmUt5Q3Xy",
        "row": 8342,
        "state": "FLORIDA",
        "group": "AT2285",
        "group_num": [
            "recU1v7SdhKovg8ar"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3813,
        "2024": 0,
        "id": "recyy81kqME4j8lHB",
        "row": 11472,
        "state": "FLORIDA",
        "group": "1521602",
        "group_num": [
            "rec3tamFdGwrmJddF"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2700,
        "2024": 0,
        "id": "recCT4v1MEsjxXowO",
        "row": 11525,
        "state": "FLORIDA",
        "group": "1521602",
        "group_num": [
            "rec3tamFdGwrmJddF"
        ],
        "procedure": "PHP",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 644,
        "2024": 0,
        "id": "recXisNAM1OxIk3kh",
        "row": 11877,
        "state": "FLORIDA",
        "group": "1292712",
        "group_num": [
            "recEs3gEwC9hLvAFu"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "2023": 237,
        "2024": 0,
        "id": "recxIwO0XNuJGzxPD",
        "row": 12137,
        "state": "FLORIDA",
        "group": "1285892",
        "group_num": [
            "rec6tYJ0X2TTgMgVO"
        ],
        "procedure": "PHP",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 91,
        "2023": 0,
        "2024": 0,
        "id": "rec4b9R1epCkQCnqC",
        "row": 12261,
        "state": "FLORIDA",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "PHP",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 122,
        "2023": 0,
        "2024": 0,
        "id": "rec629fQXzGT6DPUC",
        "row": 12262,
        "state": "FLORIDA",
        "group": "1438082",
        "group_num": [
            "rec2aiCrOXNT0vC0n"
        ],
        "procedure": "IOP",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 0,
        "2024": 0,
        "id": "recK5MkJY69jJqyXb",
        "row": 12312,
        "state": "FLORIDA",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "2023": 0,
        "2024": 0,
        "id": "recxPOj9OYZTy3Dv8",
        "row": 12328,
        "state": "FLORIDA",
        "group": "1409905",
        "group_num": [
            "recTauXQmDPUFtYod"
        ],
        "procedure": "PHP",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 470,
        "2023": 471,
        "2024": 0,
        "id": "recTis6EVEJArFWwU",
        "row": 12465,
        "state": "FLORIDA",
        "group": "1285892",
        "group_num": [
            "rec6tYJ0X2TTgMgVO"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 479,
        "2023": 0,
        "2024": 0,
        "id": "recL7fMuNDLkFFFsk",
        "row": 12473,
        "state": "FLORIDA",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 517,
        "2023": 0,
        "2024": 0,
        "id": "recM85hRqKAc93Ysk",
        "row": 12514,
        "state": "FLORIDA",
        "group": "1409905",
        "group_num": [
            "recTauXQmDPUFtYod"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 638,
        "2023": 0,
        "2024": 0,
        "id": "rec3QXrtYNJzyDshK",
        "row": 12570,
        "state": "FLORIDA",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 669,
        "2023": 0,
        "2024": 0,
        "id": "recPMRJwXrXqP14XM",
        "row": 12591,
        "state": "FLORIDA",
        "group": "1375336",
        "group_num": [
            "rec4TOsSswskcNN18"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 658,
        "2023": 0,
        "2024": 0,
        "id": "recBRQHIuNn49iOMf",
        "row": 12586,
        "state": "FLORIDA",
        "group": "1293337",
        "group_num": [
            "recJ0BECYKD2Wi3dx"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1056,
        "2023": 0,
        "2024": 0,
        "id": "recUZNj8q7snZ4jiV",
        "row": 12750,
        "state": "FLORIDA",
        "group": "1293337",
        "group_num": [
            "recJ0BECYKD2Wi3dx"
        ],
        "procedure": "Detox",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "2023": 0,
        "2024": 0,
        "id": "recqQ08be06OkwZHo",
        "row": 12912,
        "state": "FLORIDA",
        "group": "1358470",
        "group_num": [
            "recjozazoPbW06MsW"
        ],
        "procedure": "PHP",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4328,
        "2023": 0,
        "2024": 0,
        "id": "rechRARSaZOBsmsUe",
        "row": 12994,
        "state": "FLORIDA",
        "group": "1270366",
        "group_num": [
            "recVmZcnClMa1xeEb"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "2023": 0,
        "2024": 0,
        "id": "recukRhsDA8fmeRwQ",
        "row": 12981,
        "state": "FLORIDA",
        "group": "1358470",
        "group_num": [
            "recjozazoPbW06MsW"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recjnDES3hWyJtmMs",
        "row": 8348,
        "state": "FLORIDA",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 777,
        "2023": 0,
        "2024": 0,
        "id": "rec9UHL4oGP6xdveO",
        "row": 8347,
        "state": "FLORIDA",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 0,
        "2024": 0,
        "id": "recCHaDNdAtK34vXO",
        "row": 8346,
        "state": "FLORIDA",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "Detox",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 700,
        "2024": 0,
        "id": "reckkQ4jElkM5kAJW",
        "row": 11867,
        "state": "FLORIDA",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 700,
        "2024": 0,
        "id": "recYWprCNofNRKWJg",
        "row": 11868,
        "state": "FLORIDA",
        "group": "G0040817",
        "group_num": [
            "recgdYzwtl1mjUjDn"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 500,
        "2024": 0,
        "id": "recfuLFlSrHxbZNgB",
        "row": 11967,
        "state": "FLORIDA",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 175,
        "2024": 0,
        "id": "recZmBYHoA8vaDCxb",
        "row": 12199,
        "state": "FLORIDA",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 0,
        "2024": 0,
        "id": "recv7LWRAnymnvaol",
        "row": 12506,
        "state": "FLORIDA",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 777,
        "2023": 0,
        "2024": 0,
        "id": "reczCRd8Gl8658aAP",
        "row": 12609,
        "state": "FLORIDA",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 0,
        "2024": 0,
        "id": "recDbDQJTHcMBs6qg",
        "row": 12798,
        "state": "FLORIDA",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "Detox",
        "provider": "PACIFIC SOURCE (PACIFIC SOURCE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1663,
        "2023": 0,
        "2024": 0,
        "id": "reckIC6JWQVE9Q4e9",
        "row": 8349,
        "state": "FLORIDA",
        "group": "HSC002",
        "group_num": [
            "recMQjhMFgvECRD61"
        ],
        "procedure": "RTC",
        "provider": "PARAMOUNT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "recXIeypK3C70P7GX",
        "row": 12110,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "PHILADELPHIA AMERICA LIFE INSURANCE (PHILADELPHIA AMERICAN LIFE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "rec75w6q6g38xwkL9",
        "row": 12109,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "PHILADELPHIA AMERICAN LIFE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2945,
        "2024": 0,
        "id": "recZVugJIczYit8e9",
        "row": 11513,
        "state": "FLORIDA",
        "group": "10302",
        "group_num": [
            "rec8Ge9YXmBritCBm"
        ],
        "procedure": "RTC",
        "provider": "PIEDMONT (PIEDMONT COMMUNITY HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3720,
        "2024": 0,
        "id": "recyA9f2mBYbG5GFC",
        "row": 11474,
        "state": "FLORIDA",
        "group": "10302",
        "group_num": [
            "rec8Ge9YXmBritCBm"
        ],
        "procedure": "Detox",
        "provider": "PIEDMONT (PIEDMONT COMMUNITY HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2032,
        "2023": 2032,
        "2024": 0,
        "id": "recWORKfexMjUiHIQ",
        "row": 11585,
        "state": "FLORIDA",
        "group": "0412160007",
        "group_num": [
            "recSsitKMcIYUmSPN"
        ],
        "procedure": "RTC",
        "provider": "SANFORD HEALTH PLAN (SANFORD HEALTH PLAN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3500,
        "2023": 0,
        "2024": 0,
        "id": "recFnppUqUqRnbLTy",
        "row": 12980,
        "state": "FLORIDA",
        "group": "0412160007",
        "group_num": [
            "recSsitKMcIYUmSPN"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN (SANFORD HEALTH PLAN)"
    },
    {
        "2020": 851,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfKXBjHAWTPxhtK",
        "row": 8351,
        "state": "FLORIDA",
        "group": "5300",
        "group_num": [
            "recGPjJ89tb7SrE8I"
        ],
        "procedure": "PHP",
        "provider": "SECURE HEALTH"
    },
    {
        "2020": 2054,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQI2qgbc4FEzVgz",
        "row": 8350,
        "state": "FLORIDA",
        "group": "5300",
        "group_num": [
            "recGPjJ89tb7SrE8I"
        ],
        "procedure": "RTC",
        "provider": "SECURE HEALTH"
    },
    {
        "2020": 131,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recu6Zib9vKlBI82K",
        "row": 8353,
        "state": "FLORIDA",
        "group": "5300",
        "group_num": [
            "recGPjJ89tb7SrE8I"
        ],
        "procedure": "OP",
        "provider": "SECURE HEALTH"
    },
    {
        "2020": 208,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recMifquZE2uPhMON",
        "row": 8352,
        "state": "FLORIDA",
        "group": "5300",
        "group_num": [
            "recGPjJ89tb7SrE8I"
        ],
        "procedure": "IOP",
        "provider": "SECURE HEALTH"
    },
    {
        "2020": 0,
        "2021": 650,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec80HFqC5awvKqOJ",
        "row": 8354,
        "state": "FLORIDA",
        "group": "001",
        "group_num": [
            "rec1wB5gOu2RdrkOF"
        ],
        "procedure": "RTC",
        "provider": "SEMINOLE TRIBE OF FLORIDA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 683,
        "2024": 0,
        "id": "rec1nJkPJG7iT56kf",
        "row": 11873,
        "state": "FLORIDA",
        "group": "001",
        "group_num": [
            "rec1wB5gOu2RdrkOF"
        ],
        "procedure": "RTC",
        "provider": "SEMINOLE TRIBE OF FL (SEMINOLE TRIBE OF FLORIDA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 133,
        "2023": 0,
        "2024": 0,
        "id": "recFlLjoTFFHAxuME",
        "row": 12264,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "SUNSHINE HEALTH (SUNSHINE STATE HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 0,
        "2024": 0,
        "id": "recfURSofpKJfQpnW",
        "row": 12356,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "SUNSHINE HEALTH (SUNSHINE STATE HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 632,
        "2023": 0,
        "2024": 0,
        "id": "rec7wl3e6PP4N9jhA",
        "row": 12567,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "SUNSHINE HEALTH (SUNSHINE STATE HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 717,
        "2023": 0,
        "2024": 0,
        "id": "recw1tEYG1oLdkOTr",
        "row": 12601,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "SUNSHINE HEALTH (SUNSHINE STATE HEALTH)"
    },
    {
        "2020": 0,
        "2021": 327,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXUUPh52eoVDL7i",
        "row": 8355,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "TRICARE EAST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 306,
        "2023": 0,
        "2024": 0,
        "id": "rec5y5u8UtiYI9c5B",
        "row": 8356,
        "state": "FLORIDA",
        "group": "GROUPA",
        "group_num": [
            "recGVgddCoQFlyqe6"
        ],
        "procedure": "PHP",
        "provider": "TRICARE EAST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 339,
        "2024": 0,
        "id": "recoDyb1HelJjLaXp",
        "row": 12065,
        "state": "FLORIDA",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "IOP",
        "provider": "HUMANA (TRICARE EAST)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 306,
        "2023": 0,
        "2024": 0,
        "id": "rec00YnqXfp9yMbtk",
        "row": 12359,
        "state": "FLORIDA",
        "group": "GROUPA",
        "group_num": [
            "recGVgddCoQFlyqe6"
        ],
        "procedure": "PHP",
        "provider": "TRICARE (TRICARE EAST)"
    },
    {
        "2020": 380,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec393WpmEuAj7pN9",
        "row": 8358,
        "state": "FLORIDA",
        "group": "SM90061C",
        "group_num": [
            "recAEBVH9FYbnljoQ"
        ],
        "procedure": "IOP",
        "provider": "TRUSTMARK HEALTH"
    },
    {
        "2020": 0,
        "2021": 210,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGRZS6JNnpR61Ei",
        "row": 8357,
        "state": "FLORIDA",
        "group": "6C0000",
        "group_num": [
            "recHKCDq9gB2cRcU4"
        ],
        "procedure": "IOP",
        "provider": "TRUSTMARK HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recmOGhHBd3qj2Quu",
        "row": 8360,
        "state": "FLORIDA",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 425,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec5lYkKjkyKvR929",
        "row": 8363,
        "state": "FLORIDA",
        "group": "210520000",
        "group_num": [
            "rec8aHOueaJBBN3jN"
        ],
        "procedure": "PHP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 600,
        "2022": 1517,
        "2023": 0,
        "2024": 0,
        "id": "recHYKjhxrURLX0IP",
        "row": 8362,
        "state": "FLORIDA",
        "group": "210520000",
        "group_num": [
            "rec8aHOueaJBBN3jN"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 566,
        "2023": 0,
        "2024": 0,
        "id": "recKVRqQBlxsURPt4",
        "row": 8361,
        "state": "FLORIDA",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "PHP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 566,
        "2023": 0,
        "2024": 0,
        "id": "recOF3SvXrMDCZvNG",
        "row": 12549,
        "state": "FLORIDA",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "PHP",
        "provider": "TUFTS HEALTH PLAN (TUFTS HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 0,
        "2024": 0,
        "id": "recYb0dLXVN8eQJ3L",
        "row": 12935,
        "state": "FLORIDA",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH PLAN (TUFTS HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "2023": 0,
        "2024": 0,
        "id": "reckkYCa1qM1WEHmq",
        "row": 12991,
        "state": "FLORIDA",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "Detox",
        "provider": "TUFTS HEALTH PLAN (TUFTS HEALTH)"
    },
    {
        "2020": 235,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recePiOTZoQms6vcm",
        "row": 8394,
        "state": "FLORIDA",
        "group": "76413208",
        "group_num": [
            "recYHM6vtGEgdVYeL"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2351,
        "2023": 0,
        "2024": 0,
        "id": "recect7WPuh00yVJR",
        "row": 8411,
        "state": "FLORIDA",
        "group": "76415194",
        "group_num": [
            "recbBDtAkl3Dm3Afy"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "reclArfVMT4e6QmDT",
        "row": 8407,
        "state": "FLORIDA",
        "group": "76414769",
        "group_num": [
            "recw54WliTeyp7ElK"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2151,
        "2023": 0,
        "2024": 0,
        "id": "recmdr5FDFDYRtRRU",
        "row": 8376,
        "state": "FLORIDA",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2666,
        "2023": 0,
        "2024": 0,
        "id": "recOVXZgMFK4vf2fS",
        "row": 8380,
        "state": "FLORIDA",
        "group": "76411925",
        "group_num": [
            "recDUtgIv1ejw4MwF"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recOKsnxUVwSeNvn9",
        "row": 8392,
        "state": "FLORIDA",
        "group": "76413037",
        "group_num": [
            "recONRIwwFQOgrAd9"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 375,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recR02rYEULyhhqWo",
        "row": 8364,
        "state": "FLORIDA",
        "group": "7641192",
        "group_num": [
            "recC5uxK2p8KNaseC"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 300,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSYxG1cQmJ3HZlG",
        "row": 8403,
        "state": "FLORIDA",
        "group": "76414226",
        "group_num": [
            "recPALkO29Wu2mbEc"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1746,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recS73kKSlv7YEESz",
        "row": 8386,
        "state": "FLORIDA",
        "group": "76412524",
        "group_num": [
            "recjNtVhXnCVfswYR"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTst28sYcrKkV0Y",
        "row": 8373,
        "state": "FLORIDA",
        "group": "76411014",
        "group_num": [
            "recNeaWAJAYA1fRbv"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2102,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recU6HHsN7aVgfT1U",
        "row": 8402,
        "state": "FLORIDA",
        "group": "76414165",
        "group_num": [
            "recPZiBoDyI4n3Yhe"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 173,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recV71t4NXCvtrvqq",
        "row": 8401,
        "state": "FLORIDA",
        "group": "76414118",
        "group_num": [
            "recjgrTLEhFdERQax"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recXITu3XRvXdurKv",
        "row": 8390,
        "state": "FLORIDA",
        "group": "76412885",
        "group_num": [
            "recdLc1XqzgHLWrq1"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2315,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZdx9tQp7hUuz1y",
        "row": 8396,
        "state": "FLORIDA",
        "group": "76413389",
        "group_num": [
            "recdeMvGC3AHcwEZR"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec1ESTLi9t3BXMjb",
        "row": 8408,
        "state": "FLORIDA",
        "group": "76414875",
        "group_num": [
            "rec0xVQBKYCbd8DD3"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "2023": 0,
        "2024": 0,
        "id": "rec2q2gG3MxZiAW2z",
        "row": 8384,
        "state": "FLORIDA",
        "group": "76412015",
        "group_num": [
            "recEYJdJnlfo4X5Nu"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 235,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4iSV1ZNcs2l1BH",
        "row": 8370,
        "state": "FLORIDA",
        "group": "76410897",
        "group_num": [
            "rec4jRHsttFes7LPh"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2200,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6uozyXpqWQ9xih",
        "row": 8378,
        "state": "FLORIDA",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1650,
        "2022": 1312,
        "2023": 0,
        "2024": 0,
        "id": "rec7BHoLNabWJqOPV",
        "row": 8379,
        "state": "FLORIDA",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 1803,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7KguUoLk6Weu1P",
        "row": 8395,
        "state": "FLORIDA",
        "group": "76413389",
        "group_num": [
            "recdeMvGC3AHcwEZR"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec7NsSHjQsSdrtGt",
        "row": 8413,
        "state": "FLORIDA",
        "group": "76580065",
        "group_num": [
            "recYYr8T9T54YtQru"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recdX9NWPrxp6DKpI",
        "row": 8372,
        "state": "FLORIDA",
        "group": "76410922",
        "group_num": [
            "recFon01a5UHSnzjm"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 234,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recpfRKQbNQhGyLiB",
        "row": 8381,
        "state": "FLORIDA",
        "group": "76411925",
        "group_num": [
            "recDUtgIv1ejw4MwF"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1650,
        "2023": 0,
        "2024": 0,
        "id": "recqgOpVw3a4W8p1U",
        "row": 8368,
        "state": "FLORIDA",
        "group": "76410382",
        "group_num": [
            "rec8OrREodvmP8g0C"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 28,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqo1iK96vpJXSFT",
        "row": 8382,
        "state": "FLORIDA",
        "group": "76411925",
        "group_num": [
            "recDUtgIv1ejw4MwF"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2833,
        "2023": 0,
        "2024": 0,
        "id": "recsYwSxr7KRRa8Wi",
        "row": 8399,
        "state": "FLORIDA",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 3363,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectafbJuWShmDHKT",
        "row": 8385,
        "state": "FLORIDA",
        "group": "76412524",
        "group_num": [
            "recjNtVhXnCVfswYR"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1536,
        "2023": 0,
        "2024": 0,
        "id": "recu0uEwpS9SXhnq9",
        "row": 8377,
        "state": "FLORIDA",
        "group": "76411627",
        "group_num": [
            "recstVQ5hKBkDvgnN"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1615,
        "2023": 0,
        "2024": 0,
        "id": "recwW21HJQ1Yt2tEx",
        "row": 8387,
        "state": "FLORIDA",
        "group": "76412652",
        "group_num": [
            "recqKSlWZsjVj73bG"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "2023": 0,
        "2024": 0,
        "id": "recwsFnmtJxlbw5D5",
        "row": 8400,
        "state": "FLORIDA",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1762,
        "2023": 0,
        "2024": 0,
        "id": "recxE4qkOYyXcdVsU",
        "row": 8367,
        "state": "FLORIDA",
        "group": "76410382",
        "group_num": [
            "rec8OrREodvmP8g0C"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 195,
        "2023": 195,
        "2024": 0,
        "id": "recDkJgMSWEbzAjHQ",
        "row": 8388,
        "state": "FLORIDA",
        "group": "76412652",
        "group_num": [
            "recqKSlWZsjVj73bG"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 2000,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFuVZJ7Pe2sZjwX",
        "row": 8369,
        "state": "FLORIDA",
        "group": "76410631",
        "group_num": [
            "recd2kK76brWxz7Jn"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 2095,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFek4x3bIENokTd",
        "row": 8374,
        "state": "FLORIDA",
        "group": "76411014",
        "group_num": [
            "recNeaWAJAYA1fRbv"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recGTMdZmZQKjRbPu",
        "row": 8383,
        "state": "FLORIDA",
        "group": "76412015",
        "group_num": [
            "recEYJdJnlfo4X5Nu"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 2300,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recIOwMfaUIMfZ8wM",
        "row": 8393,
        "state": "FLORIDA",
        "group": "76413037",
        "group_num": [
            "recONRIwwFQOgrAd9"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2100,
        "2024": 0,
        "id": "recIdqteU9yhkwfr7",
        "row": 8409,
        "state": "FLORIDA",
        "group": "76415179",
        "group_num": [
            "recfDYlHLHSHznBXX"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 1791,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJPtgUa6yXGU3hD",
        "row": 8391,
        "state": "FLORIDA",
        "group": "76412966",
        "group_num": [
            "recQw6gLVxIyndo4a"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "2023": 0,
        "2024": 0,
        "id": "recKuKHVl850WQZBc",
        "row": 8405,
        "state": "FLORIDA",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recKfdS7xMDYdOVk2",
        "row": 8366,
        "state": "FLORIDA",
        "group": "76410108",
        "group_num": [
            "recLShRoamTr76Ksg"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "2023": 0,
        "2024": 0,
        "id": "recNUHtDwIp2LRWj6",
        "row": 8404,
        "state": "FLORIDA",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 228,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnmPZIQTvmQTelp",
        "row": 8414,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 186,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recKLLYnSealUiCXR",
        "row": 8415,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recfD9iQ1YuAh90W7",
        "row": 11483,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recgthxLAJYBkUt6C",
        "row": 11501,
        "state": "FLORIDA",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recT05Xn5gpKFsnPm",
        "row": 11486,
        "state": "FLORIDA",
        "group": "76413120",
        "group_num": [
            "recXVOeaoAiJtK72n"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "rec18Cbkgc4ob5lsG",
        "row": 11503,
        "state": "FLORIDA",
        "group": "76413548",
        "group_num": [
            "recQky9k8y70oLyYA"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "rec2ymbKjZm1AeoWw",
        "row": 11504,
        "state": "FLORIDA",
        "group": "76413592",
        "group_num": [
            "recW2nDchefSHCWjb"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "rec4aPaVsDyjnVxGq",
        "row": 11481,
        "state": "FLORIDA",
        "group": "76414217",
        "group_num": [
            "recy5m2jAD7hb8qRo"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2996,
        "2024": 0,
        "id": "rec63mOqHlW5NWBq1",
        "row": 11511,
        "state": "FLORIDA",
        "group": "76411481",
        "group_num": [
            "recE6njBkJuqNOGhv"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recuOyXrp5HGEEaAr",
        "row": 11505,
        "state": "FLORIDA",
        "group": "76413838",
        "group_num": [
            "recVynnm5GqXEZ1Py"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recv9iQ4C9pocdqIx",
        "row": 11484,
        "state": "FLORIDA",
        "group": "76414047",
        "group_num": [
            "recrHe3okjpfaLYZc"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recAI6Q1KnDoyPvPW",
        "row": 11502,
        "state": "FLORIDA",
        "group": "76413249",
        "group_num": [
            "recLpJ4nSHhfwwYz8"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recCc5k7fYQ2l81ih",
        "row": 11506,
        "state": "FLORIDA",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recHmKXHdFOccNSrV",
        "row": 11485,
        "state": "FLORIDA",
        "group": "76416215",
        "group_num": [
            "reclT1hD74mQc3ESG"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recKo34moYkr3rZDL",
        "row": 11482,
        "state": "FLORIDA",
        "group": "76414536",
        "group_num": [
            "recqzmuWyDrfm3tWV"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2375,
        "2024": 0,
        "id": "reck7Y2hnHVMWsSlc",
        "row": 11538,
        "state": "FLORIDA",
        "group": "76410108",
        "group_num": [
            "recLShRoamTr76Ksg"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2373,
        "2024": 0,
        "id": "recPfYKlG12wqPH3q",
        "row": 11557,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "rec0TXsURgfecHAvS",
        "row": 11554,
        "state": "FLORIDA",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2503,
        "2024": 0,
        "id": "rec7wOiQBDl25VKVg",
        "row": 11533,
        "state": "FLORIDA",
        "group": "76414217",
        "group_num": [
            "recy5m2jAD7hb8qRo"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recbzvOj2o9jMdLd9",
        "row": 11553,
        "state": "FLORIDA",
        "group": "76414165",
        "group_num": [
            "recPZiBoDyI4n3Yhe"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2304,
        "2024": 0,
        "id": "recAgCT9W78fwj4lU",
        "row": 11563,
        "state": "FLORIDA",
        "group": "76413249",
        "group_num": [
            "recLpJ4nSHhfwwYz8"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recCAiXco2JFs1COM",
        "row": 11551,
        "state": "FLORIDA",
        "group": "76411481",
        "group_num": [
            "recE6njBkJuqNOGhv"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2364,
        "2024": 0,
        "id": "recC3i69rmzkjI2Pn",
        "row": 11558,
        "state": "FLORIDA",
        "group": "76413838",
        "group_num": [
            "recVynnm5GqXEZ1Py"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recKrfkvv3JP1yxpu",
        "row": 11552,
        "state": "FLORIDA",
        "group": "76414047",
        "group_num": [
            "recrHe3okjpfaLYZc"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2146,
        "2024": 0,
        "id": "recceTDcuITyoB03s",
        "row": 11575,
        "state": "FLORIDA",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2000,
        "2024": 0,
        "id": "recJmit4pZii8W9wC",
        "row": 11593,
        "state": "FLORIDA",
        "group": "76413592",
        "group_num": [
            "recW2nDchefSHCWjb"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2035,
        "2024": 0,
        "id": "recMf94T6Ewj0u5ei",
        "row": 11584,
        "state": "FLORIDA",
        "group": "76411289",
        "group_num": [
            "rec8wQTDSZezyyvXj"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1793,
        "2024": 0,
        "id": "rec4BE4imbisL2hHM",
        "row": 11623,
        "state": "FLORIDA",
        "group": "76414536",
        "group_num": [
            "recqzmuWyDrfm3tWV"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1543,
        "2024": 0,
        "id": "recdhiArd3hBLfkGY",
        "row": 11663,
        "state": "FLORIDA",
        "group": "76411289",
        "group_num": [
            "rec8wQTDSZezyyvXj"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1500,
        "2024": 0,
        "id": "receSEVpHItR4ENbS",
        "row": 11677,
        "state": "FLORIDA",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1500,
        "2024": 0,
        "id": "recYc1iszqcDLH0jC",
        "row": 11676,
        "state": "FLORIDA",
        "group": "76414165",
        "group_num": [
            "recPZiBoDyI4n3Yhe"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1251,
        "2024": 0,
        "id": "recZcfBG3GJERYVk9",
        "row": 11700,
        "state": "FLORIDA",
        "group": "76414536",
        "group_num": [
            "recqzmuWyDrfm3tWV"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1467,
        "2024": 0,
        "id": "rec6gHqkfPLBAc0Zi",
        "row": 11680,
        "state": "FLORIDA",
        "group": "76414217",
        "group_num": [
            "recy5m2jAD7hb8qRo"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1250,
        "2024": 0,
        "id": "recIuQZgG1AtmxVQO",
        "row": 11701,
        "state": "FLORIDA",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "IOP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 300,
        "2024": 0,
        "id": "reccbGQhY3bpLhqpb",
        "row": 12113,
        "state": "FLORIDA",
        "group": "76413588",
        "group_num": [
            "recYp0o4cX4g4a3hl"
        ],
        "procedure": "IOP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recT0hzZ1iwAlqsve",
        "row": 12234,
        "state": "FLORIDA",
        "group": "76410922",
        "group_num": [
            "recFon01a5UHSnzjm"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec0kowmsF8W3Uj4J",
        "row": 12235,
        "state": "FLORIDA",
        "group": "76410922",
        "group_num": [
            "recFon01a5UHSnzjm"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 195,
        "2023": 0,
        "2024": 0,
        "id": "reclKaSW5NluoMskd",
        "row": 12301,
        "state": "FLORIDA",
        "group": "76412652",
        "group_num": [
            "recqKSlWZsjVj73bG"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1615,
        "2023": 0,
        "2024": 0,
        "id": "recVs9Wy1MuyTDxO7",
        "row": 12807,
        "state": "FLORIDA",
        "group": "76412652",
        "group_num": [
            "recqKSlWZsjVj73bG"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "2023": 0,
        "2024": 0,
        "id": "recXyAei0PygnBZcw",
        "row": 12802,
        "state": "FLORIDA",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "IOP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1434,
        "2023": 0,
        "2024": 0,
        "id": "recdxc4TlxpLBmdRw",
        "row": 12793,
        "state": "FLORIDA",
        "group": "76411627",
        "group_num": [
            "recstVQ5hKBkDvgnN"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "2023": 0,
        "2024": 0,
        "id": "recqQq7mIjFPmYydz",
        "row": 12801,
        "state": "FLORIDA",
        "group": "76412015",
        "group_num": [
            "recEYJdJnlfo4X5Nu"
        ],
        "procedure": "IOP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "2023": 0,
        "2024": 0,
        "id": "recVn3izvEphXCg3b",
        "row": 12850,
        "state": "FLORIDA",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1884,
        "2023": 0,
        "2024": 0,
        "id": "rec65I4vx9m3aprT1",
        "row": 12854,
        "state": "FLORIDA",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "rec8pqvtRYOWFAsjS",
        "row": 12861,
        "state": "FLORIDA",
        "group": "76412015",
        "group_num": [
            "recEYJdJnlfo4X5Nu"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "recrbjdzI1ARQlArA",
        "row": 12867,
        "state": "FLORIDA",
        "group": "76415179",
        "group_num": [
            "recfDYlHLHSHznBXX"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2067,
        "2023": 0,
        "2024": 0,
        "id": "rectMo9vSqNgnJuPq",
        "row": 12866,
        "state": "FLORIDA",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "2023": 0,
        "2024": 0,
        "id": "recI6mORhNh4Kmlnf",
        "row": 12823,
        "state": "FLORIDA",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "PHP",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "reciw23YFAKMC7P9C",
        "row": 12895,
        "state": "FLORIDA",
        "group": "76414769",
        "group_num": [
            "recw54WliTeyp7ElK"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recOyZizuYc55Jbsk",
        "row": 12887,
        "state": "FLORIDA",
        "group": "76412885",
        "group_num": [
            "recdLc1XqzgHLWrq1"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2151,
        "2023": 0,
        "2024": 0,
        "id": "recRb5pszeBqN4w7b",
        "row": 12875,
        "state": "FLORIDA",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "rec8ZUvlxUA7LfvK5",
        "row": 12894,
        "state": "FLORIDA",
        "group": "76414875",
        "group_num": [
            "rec0xVQBKYCbd8DD3"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2666,
        "2023": 0,
        "2024": 0,
        "id": "recbc61C2WHjYxzLS",
        "row": 12911,
        "state": "FLORIDA",
        "group": "76411925",
        "group_num": [
            "recDUtgIv1ejw4MwF"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2351,
        "2023": 0,
        "2024": 0,
        "id": "recy1VxxjE1Zj1CbO",
        "row": 12882,
        "state": "FLORIDA",
        "group": "76415194",
        "group_num": [
            "recbBDtAkl3Dm3Afy"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recN38yvwJ53tu9wg",
        "row": 12883,
        "state": "FLORIDA",
        "group": "76580065",
        "group_num": [
            "recYYr8T9T54YtQru"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "reckICVZQeOb6BDHi",
        "row": 12950,
        "state": "FLORIDA",
        "group": "76412885",
        "group_num": [
            "recdLc1XqzgHLWrq1"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recmWQb9BKvm0fnVz",
        "row": 12951,
        "state": "FLORIDA",
        "group": "76413553",
        "group_num": [
            "recgPWdJ7EqhoT6vK"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "rec6CGrj6dNKYBtQd",
        "row": 12952,
        "state": "FLORIDA",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2833,
        "2023": 0,
        "2024": 0,
        "id": "rec83yRyZPBx2kHxb",
        "row": 12936,
        "state": "FLORIDA",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "2024": 0,
        "id": "recsSYjHETBY7dnnU",
        "row": 12949,
        "state": "FLORIDA",
        "group": "76410108",
        "group_num": [
            "recLShRoamTr76Ksg"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2929,
        "2023": 0,
        "2024": 0,
        "id": "recs2qycbwQe6UYoU",
        "row": 12939,
        "state": "FLORIDA",
        "group": "76415194",
        "group_num": [
            "recbBDtAkl3Dm3Afy"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recyhxC61Dz0s3lfH",
        "row": 12953,
        "state": "FLORIDA",
        "group": "76414769",
        "group_num": [
            "recw54WliTeyp7ElK"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recHc5uua7dzqT37U",
        "row": 12961,
        "state": "FLORIDA",
        "group": "76580065",
        "group_num": [
            "recYYr8T9T54YtQru"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 1035,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "receNnEX2FOJGfUA8",
        "row": 8452,
        "state": "FLORIDA",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 595,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rechYNhdOEZpfvSpX",
        "row": 8440,
        "state": "FLORIDA",
        "group": "04L8972",
        "group_num": [
            "recMm0KDKL2nRjrwU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 709,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciUBKsLZYCTfK2z",
        "row": 8433,
        "state": "FLORIDA",
        "group": "8U6351",
        "group_num": [
            "recNqJlqxklgjysJh"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckTfuT8OtVkhCSp",
        "row": 8428,
        "state": "FLORIDA",
        "group": "4X3365",
        "group_num": [
            "recxP4HAzo1hLcH3u"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 30,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmNnFNhNn0Qn0OH",
        "row": 8454,
        "state": "FLORIDA",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPqUpE7G6dXkJKb",
        "row": 8423,
        "state": "FLORIDA",
        "group": "3Y9553",
        "group_num": [
            "recqbapc28fRyXR5p"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 595,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQeDzN0TDienbvE",
        "row": 8427,
        "state": "FLORIDA",
        "group": "4X3365",
        "group_num": [
            "recxP4HAzo1hLcH3u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 575,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recRsAQSoyG3rOSSR",
        "row": 8431,
        "state": "FLORIDA",
        "group": "5X3432",
        "group_num": [
            "recFqF46pDC8Y0mn6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1062,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recTxqpQEaNVrNZno",
        "row": 8450,
        "state": "FLORIDA",
        "group": "15106",
        "group_num": [
            "recoLObWHW6MzMHE6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1650,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recUE53bqMQZdmt5O",
        "row": 8456,
        "state": "FLORIDA",
        "group": "81697",
        "group_num": [
            "recf5XOSQta9ClVaP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWrtya8VmHlDZaV",
        "row": 8442,
        "state": "FLORIDA",
        "group": "04L8972",
        "group_num": [
            "recMm0KDKL2nRjrwU"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYzm3xQZRgqYQQG",
        "row": 8459,
        "state": "FLORIDA",
        "group": "82025",
        "group_num": [
            "rec13Xsw9567XJIE1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1260,
        "2021": 1787,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYdcmRSdgjXJKVp",
        "row": 8451,
        "state": "FLORIDA",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 31,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZrJG8hz9w6xMbo",
        "row": 8443,
        "state": "FLORIDA",
        "group": "04L8972",
        "group_num": [
            "recMm0KDKL2nRjrwU"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 713,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2YUYnYvfrrmJS9",
        "row": 8426,
        "state": "FLORIDA",
        "group": "4X3365",
        "group_num": [
            "recxP4HAzo1hLcH3u"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2285,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec3d0sLpdvsXHHTX",
        "row": 8448,
        "state": "FLORIDA",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec54ngs2zrksSsKz",
        "row": 8441,
        "state": "FLORIDA",
        "group": "04L8972",
        "group_num": [
            "recMm0KDKL2nRjrwU"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1140,
        "2022": 1140,
        "2023": 0,
        "2024": 0,
        "id": "rec6Zj8fsr5qzRAHm",
        "row": 8447,
        "state": "FLORIDA",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 571,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6f7nQIMebWylna",
        "row": 8424,
        "state": "FLORIDA",
        "group": "4L3512",
        "group_num": [
            "rec5tC3pV9AP8NVMY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 572,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9sg9uRFGtON5ul",
        "row": 8434,
        "state": "FLORIDA",
        "group": "9L0193",
        "group_num": [
            "recRRmAoSX4iIqZe9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9Cmi4BG1Lj8d72",
        "row": 8458,
        "state": "FLORIDA",
        "group": "82025",
        "group_num": [
            "rec13Xsw9567XJIE1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 842,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recasmJ4pFwqqkZq0",
        "row": 8453,
        "state": "FLORIDA",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 595,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccCp2Vcm0yxQlUI",
        "row": 8437,
        "state": "FLORIDA",
        "group": "9W3346",
        "group_num": [
            "recmdNgbIwtrvhRcM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 847,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqeF6nGqwnyXe59",
        "row": 8418,
        "state": "FLORIDA",
        "group": "0X8160",
        "group_num": [
            "rec0rF2uEzI1IIORT"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1055,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recr5lNMuAv4oX0W5",
        "row": 8449,
        "state": "FLORIDA",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1287,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recsu9Zfw7sej3Rwi",
        "row": 8457,
        "state": "FLORIDA",
        "group": "81697",
        "group_num": [
            "recf5XOSQta9ClVaP"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3120,
        "2023": 0,
        "2024": 0,
        "id": "rect0fms7emoNiaPh",
        "row": 8444,
        "state": "FLORIDA",
        "group": "00832",
        "group_num": [
            "recYcPK7htW5EbvPl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 595,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectG63szFrKih9g6",
        "row": 8422,
        "state": "FLORIDA",
        "group": "3Y9553",
        "group_num": [
            "recqbapc28fRyXR5p"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 571,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recujz9k3IYDDpvea",
        "row": 8419,
        "state": "FLORIDA",
        "group": "0X8160",
        "group_num": [
            "rec0rF2uEzI1IIORT"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 571,
        "2022": 0,
        "2023": 553,
        "2024": 0,
        "id": "recvYjbtd3CKS1eZc",
        "row": 8462,
        "state": "FLORIDA",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 262,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwRbNa0BX2AmpKe",
        "row": 8463,
        "state": "FLORIDA",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 357,
        "2023": 0,
        "2024": 0,
        "id": "recAes3QRzg30K7Js",
        "row": 8420,
        "state": "FLORIDA",
        "group": "3Y2052",
        "group_num": [
            "recwMKcVyGlfC8nkd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 219,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recDlysZa1oo0iyfa",
        "row": 8439,
        "state": "FLORIDA",
        "group": "01U5417",
        "group_num": [
            "recFy9zrlyDqQVGxk"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 170,
        "2021": 233,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recGcuuqmH9o9dbBe",
        "row": 8438,
        "state": "FLORIDA",
        "group": "9W3346",
        "group_num": [
            "recmdNgbIwtrvhRcM"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 808,
        "2023": 0,
        "2024": 0,
        "id": "recIjyQjXzbOgPUZE",
        "row": 8416,
        "state": "FLORIDA",
        "group": "0F4615",
        "group_num": [
            "recYhBz0mT3YYsq7k"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1088,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recNbj5t0V54LYYfR",
        "row": 8460,
        "state": "FLORIDA",
        "group": "82025",
        "group_num": [
            "rec13Xsw9567XJIE1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 202,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recftMPkmQz7DCZvK",
        "row": 8479,
        "state": "FLORIDA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 31,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recfDtWGAF6MNYgSB",
        "row": 8501,
        "state": "FLORIDA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recf5OExpYL72apQx",
        "row": 8503,
        "state": "FLORIDA",
        "group": "702649",
        "group_num": [
            "recoQe2CWU4zCsctB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1445,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recgxFXc6dk6HRlYC",
        "row": 8504,
        "state": "FLORIDA",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 2000,
        "2023": 0,
        "2024": 0,
        "id": "recgdDqbeRMsBb85d",
        "row": 8509,
        "state": "FLORIDA",
        "group": "704203",
        "group_num": [
            "rec9NHfQbXuz2JrWy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 258,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recjhJmxkW4WFXDs0",
        "row": 8506,
        "state": "FLORIDA",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3120,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recllNSg27ySFMtyL",
        "row": 8481,
        "state": "FLORIDA",
        "group": "214279",
        "group_num": [
            "recOoKuixyvEOIso1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 571,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reclpG6N9nBgvU2wJ",
        "row": 8505,
        "state": "FLORIDA",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recmnjRYtgBBMFO5l",
        "row": 8561,
        "state": "FLORIDA",
        "group": "782065",
        "group_num": [
            "recLyZTcTKtrJZLR9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3652,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnsyeiG9nmSCmqB",
        "row": 8544,
        "state": "FLORIDA",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 263,
        "2022": 252,
        "2023": 0,
        "2024": 0,
        "id": "recnPULpC8MIWprnF",
        "row": 8478,
        "state": "FLORIDA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 60,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnePTcXYDlpDYES",
        "row": 8539,
        "state": "FLORIDA",
        "group": "729803",
        "group_num": [
            "recyLlfMRyZEkA6gt"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 873,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recPg1aUYbpevf9Pw",
        "row": 8510,
        "state": "FLORIDA",
        "group": "704203",
        "group_num": [
            "rec9NHfQbXuz2JrWy"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 249,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQV1IEuUS1WzGza",
        "row": 8512,
        "state": "FLORIDA",
        "group": "704285",
        "group_num": [
            "recjl7nm8xrbts3rz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2285,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQheyNhQGIoBGkm",
        "row": 8545,
        "state": "FLORIDA",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "2023": 0,
        "2024": 0,
        "id": "recRUmwjukLUG7rqj",
        "row": 8518,
        "state": "FLORIDA",
        "group": "708809",
        "group_num": [
            "rec0Kqg9EXryBMzyK"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 336,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recSpttv7WVnEdCrS",
        "row": 8537,
        "state": "FLORIDA",
        "group": "729784",
        "group_num": [
            "recsYJ3IqajgSsdY3"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 570,
        "2022": 570,
        "2023": 0,
        "2024": 0,
        "id": "recUn6mfQlLDqYkS6",
        "row": 8477,
        "state": "FLORIDA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 568,
        "2021": 2231,
        "2022": 553,
        "2023": 553,
        "2024": 0,
        "id": "recVIJ8ssdt5cWqJQ",
        "row": 8473,
        "state": "FLORIDA",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 516,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWtYRvCXspVzCMe",
        "row": 8529,
        "state": "FLORIDA",
        "group": "716838",
        "group_num": [
            "recyYVkNeqtIz6MO7"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 571,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recWvk2sUYhHvHXHG",
        "row": 8511,
        "state": "FLORIDA",
        "group": "704285",
        "group_num": [
            "recjl7nm8xrbts3rz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2665,
        "2023": 2266,
        "2024": 0,
        "id": "recXXVASymJXepucs",
        "row": 8483,
        "state": "FLORIDA",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 108,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXurJZwCQTaPHoW",
        "row": 8468,
        "state": "FLORIDA",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 563,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYxZwXwyFX3igrd",
        "row": 8515,
        "state": "FLORIDA",
        "group": "707205",
        "group_num": [
            "recDtSAfq8kuB7MFi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 134,
        "2023": 0,
        "2024": 0,
        "id": "rec0aBLzDv1aSESIB",
        "row": 8489,
        "state": "FLORIDA",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec14IME7D0AP3kOL",
        "row": 8499,
        "state": "FLORIDA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 276,
        "2023": 0,
        "2024": 0,
        "id": "rec144NiDfTsqD9sp",
        "row": 8519,
        "state": "FLORIDA",
        "group": "708809",
        "group_num": [
            "rec0Kqg9EXryBMzyK"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4291,
        "2022": 570,
        "2023": 0,
        "2024": 0,
        "id": "rec2sIg3eFAzFzUvR",
        "row": 8516,
        "state": "FLORIDA",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1631,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec2cKf3g7qNilQdF",
        "row": 8521,
        "state": "FLORIDA",
        "group": "710773",
        "group_num": [
            "recbwN2hqkr5p0iiQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec35M4Lu8UHMUoCR",
        "row": 8500,
        "state": "FLORIDA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 75,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4721ZYEEOlkvH7",
        "row": 8538,
        "state": "FLORIDA",
        "group": "729784",
        "group_num": [
            "recsYJ3IqajgSsdY3"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "2024": 0,
        "id": "rec4gNGsQ5VCzwheV",
        "row": 8470,
        "state": "FLORIDA",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1140,
        "2022": 1140,
        "2023": 0,
        "2024": 0,
        "id": "rec5K5XO69WrRtfjS",
        "row": 8482,
        "state": "FLORIDA",
        "group": "214279",
        "group_num": [
            "recOoKuixyvEOIso1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1698,
        "2021": 2000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec7MQ2buhLCWA5vS",
        "row": 8465,
        "state": "FLORIDA",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 649,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec9Vers99rzpPHXr",
        "row": 8485,
        "state": "FLORIDA",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 23,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reccrs4uNNaDUh9I1",
        "row": 8550,
        "state": "FLORIDA",
        "group": "742673",
        "group_num": [
            "rec3OdxtulDcwGDHf"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recd9wEdVlPyCk07R",
        "row": 8562,
        "state": "FLORIDA",
        "group": "782065",
        "group_num": [
            "recLyZTcTKtrJZLR9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4178,
        "2022": 4178,
        "2023": 0,
        "2024": 0,
        "id": "recoRlzGfFTdSbomD",
        "row": 8480,
        "state": "FLORIDA",
        "group": "196819",
        "group_num": [
            "recKJN0MJSKGJKYNW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 595,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrVeU77Bjz3ecV1",
        "row": 8520,
        "state": "FLORIDA",
        "group": "709872",
        "group_num": [
            "recebc0CaNaD5XqQq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 715,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recratjWLL36dmNlv",
        "row": 8467,
        "state": "FLORIDA",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 595,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recrn4myiB3n1trKZ",
        "row": 8560,
        "state": "FLORIDA",
        "group": "782065",
        "group_num": [
            "recLyZTcTKtrJZLR9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rect1gQdFNJfFROpT",
        "row": 8464,
        "state": "FLORIDA",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2328,
        "2022": 2288,
        "2023": 0,
        "2024": 0,
        "id": "recvUHoNTkMTMrhFN",
        "row": 8492,
        "state": "FLORIDA",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 763,
        "2021": 0,
        "2022": 134,
        "2023": 0,
        "2024": 0,
        "id": "recvs6ac5OxtJW8BQ",
        "row": 8559,
        "state": "FLORIDA",
        "group": "755829",
        "group_num": [
            "recEaYNO706Cf0uRW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 4342,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recv3F73lmPv2jJ51",
        "row": 8528,
        "state": "FLORIDA",
        "group": "716838",
        "group_num": [
            "recyYVkNeqtIz6MO7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 227,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recwdkdOcSRZ7oyuS",
        "row": 8475,
        "state": "FLORIDA",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 591,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reczDAevfm8nthC4M",
        "row": 8524,
        "state": "FLORIDA",
        "group": "715014",
        "group_num": [
            "recrzTZ2zeSHUlhYu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 3266,
        "2021": 359,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recAqegWrsSDBJNHB",
        "row": 8557,
        "state": "FLORIDA",
        "group": "755829",
        "group_num": [
            "recEaYNO706Cf0uRW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 50,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recB3Qg18yL5mOHzt",
        "row": 8507,
        "state": "FLORIDA",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 668,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEGlspKTyMkFStV",
        "row": 8496,
        "state": "FLORIDA",
        "group": "701444",
        "group_num": [
            "recvpOiH5igDgQfbp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 571,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recFL2NQCzZ41xVK0",
        "row": 8527,
        "state": "FLORIDA",
        "group": "716441",
        "group_num": [
            "recIemOESYYxnYA7u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 276,
        "2022": 276,
        "2023": 0,
        "2024": 0,
        "id": "recGQiRLbpk257Not",
        "row": 8517,
        "state": "FLORIDA",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 184,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recH0LSOTetTUpooS",
        "row": 8530,
        "state": "FLORIDA",
        "group": "716838",
        "group_num": [
            "recyYVkNeqtIz6MO7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recH6r9XSHPQxcALU",
        "row": 8502,
        "state": "FLORIDA",
        "group": "702649",
        "group_num": [
            "recoQe2CWU4zCsctB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 854,
        "2021": 191,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recICLVbFCTPJ5CCA",
        "row": 8558,
        "state": "FLORIDA",
        "group": "755829",
        "group_num": [
            "recEaYNO706Cf0uRW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 838,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recJpnvP2srUBy0Bc",
        "row": 8536,
        "state": "FLORIDA",
        "group": "729784",
        "group_num": [
            "recsYJ3IqajgSsdY3"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 271,
        "2022": 271,
        "2023": 0,
        "2024": 0,
        "id": "recLxp3bCgmcrEu11",
        "row": 8525,
        "state": "FLORIDA",
        "group": "715014",
        "group_num": [
            "recrzTZ2zeSHUlhYu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1058,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLORANDgqViB7rN",
        "row": 8495,
        "state": "FLORIDA",
        "group": "701444",
        "group_num": [
            "recvpOiH5igDgQfbp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2602,
        "2021": 2400,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLiOsxyS48HnWDz",
        "row": 8466,
        "state": "FLORIDA",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 544,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recguACmh3MN9VFyL",
        "row": 8585,
        "state": "FLORIDA",
        "group": "912774",
        "group_num": [
            "recdSprWhIrmiKn3v"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4088,
        "2023": 0,
        "2024": 0,
        "id": "recjQ7dQkz6KGM3Ey",
        "row": 8570,
        "state": "FLORIDA",
        "group": "902670",
        "group_num": [
            "rec9vi7MquqHiE7QH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1631,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reckZ9LbCQMUuatvB",
        "row": 8580,
        "state": "FLORIDA",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recnp6qAJbG0jHphr",
        "row": 8599,
        "state": "FLORIDA",
        "group": "919529",
        "group_num": [
            "recF5HxfO1LuUDLd8"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 521,
        "2023": 0,
        "2024": 0,
        "id": "recQjRQQul8J0JWxR",
        "row": 8589,
        "state": "FLORIDA",
        "group": "914816",
        "group_num": [
            "recDVQsqfLLOoVoMu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 252,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recQl4QqwbjIf5ArG",
        "row": 8602,
        "state": "FLORIDA",
        "group": "921107",
        "group_num": [
            "recz2dsYAPZP8sU4w"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 527,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recXqtONG4i6Wzthl",
        "row": 8592,
        "state": "FLORIDA",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1358,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recYMWadc91I55Ipe",
        "row": 8567,
        "state": "FLORIDA",
        "group": "901673",
        "group_num": [
            "recCiF2U1EMBwjCwO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 247,
        "2023": 0,
        "2024": 0,
        "id": "recZ3qOOKtSkoyHnT",
        "row": 8583,
        "state": "FLORIDA",
        "group": "906040",
        "group_num": [
            "recoRhup26tBBg0Xg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2382,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recZrqW5DGFJZt2zl",
        "row": 8595,
        "state": "FLORIDA",
        "group": "915688",
        "group_num": [
            "recY0joXuQ1ieiUX8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 38,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec4XPDGflFzQcLbQ",
        "row": 8581,
        "state": "FLORIDA",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 595,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6Dg1HYi6Z76BDj",
        "row": 8584,
        "state": "FLORIDA",
        "group": "906828",
        "group_num": [
            "recwpHpxhY3YUk6Du"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 2800,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rec6HOzd3h8DTAuL5",
        "row": 8594,
        "state": "FLORIDA",
        "group": "915688",
        "group_num": [
            "recY0joXuQ1ieiUX8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 517,
        "2023": 0,
        "2024": 0,
        "id": "reco2mLRF25etrWku",
        "row": 8576,
        "state": "FLORIDA",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 251,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recqRlfC9Ssl9t4SZ",
        "row": 8588,
        "state": "FLORIDA",
        "group": "914756",
        "group_num": [
            "rectbavPNjZQn0meZ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "2023": 0,
        "2024": 0,
        "id": "recrSIuU80VzlCdvs",
        "row": 8586,
        "state": "FLORIDA",
        "group": "914109",
        "group_num": [
            "recETXIXHvW3Q5Uix"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 233,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recubd4peBcN8AsyE",
        "row": 8598,
        "state": "FLORIDA",
        "group": "919529",
        "group_num": [
            "recF5HxfO1LuUDLd8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1689,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyW8rBMrlNlNhRp",
        "row": 8565,
        "state": "FLORIDA",
        "group": "901673",
        "group_num": [
            "recCiF2U1EMBwjCwO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 219,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recC8m3BaBPT8FEZT",
        "row": 8573,
        "state": "FLORIDA",
        "group": "903539",
        "group_num": [
            "rec4wwSYo7cFCFOdN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1621,
        "2022": 1656,
        "2023": 0,
        "2024": 0,
        "id": "recDX1vL0fOmbSU9a",
        "row": 8566,
        "state": "FLORIDA",
        "group": "901673",
        "group_num": [
            "recCiF2U1EMBwjCwO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 740,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recK58RsgfNCWvB6p",
        "row": 8610,
        "state": "FLORIDA",
        "group": "0744575",
        "group_num": [
            "recXGD65Mqr2UuUYx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 5240,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLgHF2hybfqnEBN",
        "row": 8582,
        "state": "FLORIDA",
        "group": "906040",
        "group_num": [
            "recoRhup26tBBg0Xg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1048,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recLpAav7r8C3jFBz",
        "row": 8568,
        "state": "FLORIDA",
        "group": "901673",
        "group_num": [
            "recCiF2U1EMBwjCwO"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 3367,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recN9h1yRLEfI3QI3",
        "row": 8587,
        "state": "FLORIDA",
        "group": "914240",
        "group_num": [
            "recJgGPcix0GCnqr5"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2800,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "reciojFBbbe3KaS2c",
        "row": 8616,
        "state": "FLORIDA",
        "group": "1760209",
        "group_num": [
            "recjYMtQrM9EVDY8B"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1360,
        "2023": 0,
        "2024": 0,
        "id": "recjoipM8UwpUKEr7",
        "row": 8618,
        "state": "FLORIDA",
        "group": "2358232",
        "group_num": [
            "recZbTneMgGpOoz8N"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 833,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recm6fv8TjnCRAlhE",
        "row": 8617,
        "state": "FLORIDA",
        "group": "1760209",
        "group_num": [
            "recjYMtQrM9EVDY8B"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 847,
        "2022": 1620,
        "2023": 0,
        "2024": 0,
        "id": "recOa81VzqSSW1JaV",
        "row": 8625,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2495,
        "2023": 0,
        "2024": 0,
        "id": "rec74s9aMqNRJ8GPB",
        "row": 8629,
        "state": "FLORIDA",
        "group": "VALMONT",
        "group_num": [
            "recP6oUTvZPbY59rF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5413,
        "2023": 0,
        "2024": 0,
        "id": "rec8vTyV9IBnlNHMt",
        "row": 8622,
        "state": "FLORIDA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1538,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "rectw6pockKUr9ciS",
        "row": 8627,
        "state": "FLORIDA",
        "group": "AIUC0UI",
        "group_num": [
            "recTkpp1hZfRaPhdP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 1980,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recvmvC4w0xp0ILZ1",
        "row": 8624,
        "state": "FLORIDA",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 638,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recxFHtRhLPntJyIR",
        "row": 8620,
        "state": "FLORIDA",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 396,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recyB3dl6BifvcJdN",
        "row": 8621,
        "state": "FLORIDA",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 2700,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recEoLcNKKe9ptxEs",
        "row": 8623,
        "state": "FLORIDA",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 1538,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "id": "recHkqg0wtleacCR3",
        "row": 8628,
        "state": "FLORIDA",
        "group": "AIUC0UI",
        "group_num": [
            "recTkpp1hZfRaPhdP"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3818,
        "2024": 0,
        "id": "recky7xS0zASJwCVg",
        "row": 11471,
        "state": "FLORIDA",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4023,
        "2024": 0,
        "id": "recRcgZAhzOL1WJdZ",
        "row": 11467,
        "state": "FLORIDA",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "2023": 4000,
        "2024": 0,
        "id": "recXmRhbSC5s1U0rW",
        "row": 11468,
        "state": "FLORIDA",
        "group": "915259",
        "group_num": [
            "recZWcY20QZ839que"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 919,
        "id": "reccokOFpZMS3KEt2",
        "row": 11432,
        "state": "FLORIDA",
        "group": "905610",
        "group_num": [
            "rec9iIgiZtZ5uPeAs"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1523,
        "2024": 1572,
        "id": "rectDx7oSpTrJZaWZ",
        "row": 11436,
        "state": "FLORIDA",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 839,
        "id": "recAzOcvtxN3oWJRf",
        "row": 11431,
        "state": "FLORIDA",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4445,
        "2024": 0,
        "id": "recB2Fpnf175Lnjba",
        "row": 11463,
        "state": "FLORIDA",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 1337,
        "id": "recDoKYFSZDVNjqhz",
        "row": 11434,
        "state": "FLORIDA",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4248,
        "2024": 0,
        "id": "recEv8IeYCyL4cWlZ",
        "row": 11464,
        "state": "FLORIDA",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 69825,
        "2024": 0,
        "id": "recMOwlIxBCtdBXfs",
        "row": 11438,
        "state": "FLORIDA",
        "group": "80440",
        "group_num": [
            "recfANQzqc9G9NyyF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 3000,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recn5ILp6CMBD820s",
        "row": 11510,
        "state": "FLORIDA",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3720,
        "2024": 0,
        "id": "recaDcTHusSvMErjx",
        "row": 11473,
        "state": "FLORIDA",
        "group": "72005953AM",
        "group_num": [
            "reccpfwHZkqku0YNy"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1757,
        "2023": 3161,
        "2024": 0,
        "id": "recdW4jBw7tjbdxgT",
        "row": 11478,
        "state": "FLORIDA",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recqZNNy0NGh2uymg",
        "row": 11509,
        "state": "FLORIDA",
        "group": "903509",
        "group_num": [
            "recFxuB25h9vXQNcc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recApxajM9bkv7x95",
        "row": 11507,
        "state": "FLORIDA",
        "group": "202970",
        "group_num": [
            "rechEfq7mBTffspFQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3000,
        "2024": 0,
        "id": "recKp9T7v3GNWMEOx",
        "row": 11508,
        "state": "FLORIDA",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2580,
        "2024": 0,
        "id": "rechQTUjeJeb2l3vQ",
        "row": 11529,
        "state": "FLORIDA",
        "group": "915259",
        "group_num": [
            "recZWcY20QZ839que"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "rec5aJPou2tvTBF02",
        "row": 11556,
        "state": "FLORIDA",
        "group": "903509",
        "group_num": [
            "recFxuB25h9vXQNcc"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2375,
        "2024": 0,
        "id": "recu0SaCpSXH5gSCo",
        "row": 11555,
        "state": "FLORIDA",
        "group": "202970",
        "group_num": [
            "rechEfq7mBTffspFQ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 2000,
        "2022": 3000,
        "2023": 2375,
        "2024": 0,
        "id": "recNJp3QGg4VHaLSu",
        "row": 11539,
        "state": "FLORIDA",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2335,
        "2023": 2108,
        "2024": 0,
        "id": "recVhRXaztesN1AA1",
        "row": 11577,
        "state": "FLORIDA",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2188,
        "2024": 0,
        "id": "rec2B6PEM6IpjR49y",
        "row": 11573,
        "state": "FLORIDA",
        "group": "80440",
        "group_num": [
            "recfANQzqc9G9NyyF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2060,
        "2024": 0,
        "id": "recbc8Oqh78OBc6A4",
        "row": 11582,
        "state": "FLORIDA",
        "group": "717823",
        "group_num": [
            "recHUq7xY2OGQqSMw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1853,
        "2024": 0,
        "id": "recDefqsxTok48XOG",
        "row": 11613,
        "state": "FLORIDA",
        "group": "72005953AM",
        "group_num": [
            "reccpfwHZkqku0YNy"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1714,
        "2024": 0,
        "id": "recXtIPu93E6cBL86",
        "row": 11637,
        "state": "FLORIDA",
        "group": "G1025077",
        "group_num": [
            "recCOOSHkCpJu8WEV"
        ],
        "procedure": "RTC",
        "provider": "SELECT HEALTH (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1793,
        "2024": 0,
        "id": "recYWvHqjoXP9n18D",
        "row": 11622,
        "state": "FLORIDA",
        "group": "919054",
        "group_num": [
            "recB83oKcdvwi96qj"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3184,
        "2023": 1760,
        "2024": 0,
        "id": "recu4UbVmK4DcYn69",
        "row": 11629,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1131,
        "2024": 0,
        "id": "recfSCMyvmnzLUr7o",
        "row": 11710,
        "state": "FLORIDA",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1170,
        "2024": 0,
        "id": "recSi0keTLhxVKysE",
        "row": 11705,
        "state": "FLORIDA",
        "group": "7X0265",
        "group_num": [
            "recU8SIm3UWcQHtq9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1170,
        "2024": 0,
        "id": "recTerrmrqu3pJUlt",
        "row": 11706,
        "state": "FLORIDA",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 640,
        "2021": 571,
        "2022": 1425,
        "2023": 1098,
        "2024": 0,
        "id": "recanzSqdqOkV91nI",
        "row": 11715,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1463,
        "2024": 0,
        "id": "recDJP9oeE9A5DpvM",
        "row": 11681,
        "state": "FLORIDA",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1423,
        "2024": 0,
        "id": "recLuldfptcDmHxj6",
        "row": 11682,
        "state": "FLORIDA",
        "group": "202200046401",
        "group_num": [
            "recKpZ3PWZskw18UK"
        ],
        "procedure": "RTC",
        "provider": "UHC STUDENT RESOURCES (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recj4XYjDMFoNCIX7",
        "row": 11811,
        "state": "FLORIDA",
        "group": "707278",
        "group_num": [
            "recvcGBB2UBc6ZQtY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "reclMlqutRz9ynFmm",
        "row": 11807,
        "state": "FLORIDA",
        "group": "80860",
        "group_num": [
            "rec8wKjxXIi9jYIki"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recYLU4g1z2xfYLY2",
        "row": 11806,
        "state": "FLORIDA",
        "group": "9M1047",
        "group_num": [
            "recK20FVSHfvHz3J1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "rec5ogChaFNn7ktef",
        "row": 11808,
        "state": "FLORIDA",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 919,
        "2024": 0,
        "id": "rec7ihpK5uCJSC1RF",
        "row": 11804,
        "state": "FLORIDA",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "rec7mIR9IDnCNmOSm",
        "row": 11813,
        "state": "FLORIDA",
        "group": "713531",
        "group_num": [
            "recRl9O8dnKIMvH2V"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "rec8j4x0Zl2Tj7By8",
        "row": 11812,
        "state": "FLORIDA",
        "group": "712525",
        "group_num": [
            "recAQuubnfqRgnTNb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "2023": 919,
        "2024": 0,
        "id": "rec9OrThYstjvYHFJ",
        "row": 11805,
        "state": "FLORIDA",
        "group": "742373",
        "group_num": [
            "recAcWITQb6bv5QYm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recda1XHrn1T01kvm",
        "row": 11810,
        "state": "FLORIDA",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recvPthjSmRwGbJNX",
        "row": 11809,
        "state": "FLORIDA",
        "group": "703981",
        "group_num": [
            "reclZgMNn165cBlxp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recvrsDvirm0r4vsJ",
        "row": 11815,
        "state": "FLORIDA",
        "group": "927712",
        "group_num": [
            "reck0BnbPw0bYgruv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recxMmXXgQdBfsNca",
        "row": 11816,
        "state": "FLORIDA",
        "group": "2501351",
        "group_num": [
            "recyASR4UQEf3E8MK"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 919,
        "2024": 0,
        "id": "recz2vCBZxIDjXXZQ",
        "row": 11814,
        "state": "FLORIDA",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 836,
        "2024": 0,
        "id": "recjCXOTR4WPzuGOB",
        "row": 11841,
        "state": "FLORIDA",
        "group": "7L3961",
        "group_num": [
            "recPxK3l7dpwNAZwY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 808,
        "2024": 0,
        "id": "recVwlHSSTOoCR6Kt",
        "row": 11851,
        "state": "FLORIDA",
        "group": "709335",
        "group_num": [
            "rec6koBcfPiJT6ZmM"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 889,
        "2024": 0,
        "id": "recV7bAxVIIT4rei2",
        "row": 11830,
        "state": "FLORIDA",
        "group": "701368",
        "group_num": [
            "rec8dQEDIEbOc4hi2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 820,
        "2024": 0,
        "id": "recWGHhGZ5a6y0KF2",
        "row": 11848,
        "state": "FLORIDA",
        "group": "1103835",
        "group_num": [
            "recoywgCZHLm0JA3s"
        ],
        "procedure": "RTC",
        "provider": "CONNECTICARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 715,
        "2024": 0,
        "id": "rec2R4cVquKY0Dugu",
        "row": 11864,
        "state": "FLORIDA",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 511,
        "2023": 703,
        "2024": 0,
        "id": "reccn6kYSsVWq9CH3",
        "row": 11866,
        "state": "FLORIDA",
        "group": "902849",
        "group_num": [
            "recZ8HeZXU1lJYkOB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 791,
        "2024": 0,
        "id": "recqCOScG6uf3R3tx",
        "row": 11855,
        "state": "FLORIDA",
        "group": "1431330",
        "group_num": [
            "recdSsNYRI8ewbT42"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 791,
        "2024": 0,
        "id": "recseAJqdih6Y1HxX",
        "row": 11856,
        "state": "FLORIDA",
        "group": "1418151",
        "group_num": [
            "recNJrWdufGUJ1Kno"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 836,
        "2024": 0,
        "id": "rectWOmTzWElgkTIZ",
        "row": 11840,
        "state": "FLORIDA",
        "group": "6F5527",
        "group_num": [
            "recIADS4BIDUCihEr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 734,
        "2024": 0,
        "id": "recxd1kzkmibyAX7Z",
        "row": 11860,
        "state": "FLORIDA",
        "group": "7X0265",
        "group_num": [
            "recU8SIm3UWcQHtq9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 836,
        "2024": 0,
        "id": "recxjA01MsTtEeT4I",
        "row": 11839,
        "state": "FLORIDA",
        "group": "5P1291",
        "group_num": [
            "recrCo4FidCg4JyEe"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 892,
        "2024": 0,
        "id": "recySlSyqXx0Vrccy",
        "row": 11828,
        "state": "FLORIDA",
        "group": "5P1291",
        "group_num": [
            "recrCo4FidCg4JyEe"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 889,
        "2024": 0,
        "id": "recAigq3sFxqDKSUk",
        "row": 11832,
        "state": "FLORIDA",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 867,
        "2024": 0,
        "id": "recDM4UIXVZCpK2XL",
        "row": 11835,
        "state": "FLORIDA",
        "group": "919054",
        "group_num": [
            "recB83oKcdvwi96qj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 4355,
        "2022": 889,
        "2023": 889,
        "2024": 0,
        "id": "recICmUkJlBA5EV2J",
        "row": 11829,
        "state": "FLORIDA",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 820,
        "2024": 0,
        "id": "recIlYeytlqXP3qJL",
        "row": 11849,
        "state": "FLORIDA",
        "group": "2437587",
        "group_num": [
            "recERzRiaabUoOxGD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 889,
        "2024": 0,
        "id": "recKl6BGLBklnHseT",
        "row": 11831,
        "state": "FLORIDA",
        "group": "729636",
        "group_num": [
            "recUY3h7NhQH4DJj7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 704,
        "2024": 0,
        "id": "recN8QoXXKIndnVFa",
        "row": 11865,
        "state": "FLORIDA",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 734,
        "2024": 0,
        "id": "recNilZSZC4CWhthV",
        "row": 11859,
        "state": "FLORIDA",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recjBkB9dadm3PgKu",
        "row": 11901,
        "state": "FLORIDA",
        "group": "927712",
        "group_num": [
            "reck0BnbPw0bYgruv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 577,
        "2024": 0,
        "id": "reckOCjrtvWtxlRLF",
        "row": 11893,
        "state": "FLORIDA",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 566,
        "2024": 0,
        "id": "recnK72RPowOGwfYb",
        "row": 11904,
        "state": "FLORIDA",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1227,
        "2023": 609,
        "2024": 0,
        "id": "recnnr6WD3XMCL63I",
        "row": 11881,
        "state": "FLORIDA",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recOinyHZG5Xz8wpJ",
        "row": 11897,
        "state": "FLORIDA",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recZT1oyzW95aiVXI",
        "row": 11894,
        "state": "FLORIDA",
        "group": "9M1047",
        "group_num": [
            "recK20FVSHfvHz3J1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "rec11R3N7YjWbSp00",
        "row": 11895,
        "state": "FLORIDA",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "rec4Ttp6DkTKcvhlF",
        "row": 11900,
        "state": "FLORIDA",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "rec7wYnJSSgrt89GN",
        "row": 11902,
        "state": "FLORIDA",
        "group": "2501351",
        "group_num": [
            "recyASR4UQEf3E8MK"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 637,
        "2023": 553,
        "2024": 0,
        "id": "recpxao1mtdh3lxZk",
        "row": 11914,
        "state": "FLORIDA",
        "group": "715414",
        "group_num": [
            "rec5z7Uw8Xk9dDj0l"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recrb3HHNq7jYbRXa",
        "row": 11898,
        "state": "FLORIDA",
        "group": "703981",
        "group_num": [
            "reclZgMNn165cBlxp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 553,
        "2024": 0,
        "id": "recwTTvLhn9KRjB45",
        "row": 11915,
        "state": "FLORIDA",
        "group": "729636",
        "group_num": [
            "recUY3h7NhQH4DJj7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 523,
        "2023": 553,
        "2024": 0,
        "id": "recAhWHXj3Qsh1AhI",
        "row": 11913,
        "state": "FLORIDA",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 558,
        "2024": 0,
        "id": "recBez8Rj7JFHcQH2",
        "row": 11912,
        "state": "FLORIDA",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 553,
        "2024": 0,
        "id": "recDUihB255ji766n",
        "row": 11916,
        "state": "FLORIDA",
        "group": "924851",
        "group_num": [
            "rec9QeG2dOm8DLKU7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 577,
        "2024": 0,
        "id": "recEOFgGHChaT6KAc",
        "row": 11892,
        "state": "FLORIDA",
        "group": "742373",
        "group_num": [
            "recAcWITQb6bv5QYm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recH2ykOItRpc9MPM",
        "row": 11896,
        "state": "FLORIDA",
        "group": "80860",
        "group_num": [
            "rec8wKjxXIi9jYIki"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 595,
        "2021": 558,
        "2022": 538,
        "2023": 564,
        "2024": 0,
        "id": "recKtgswgoZxRmt8A",
        "row": 11905,
        "state": "FLORIDA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recLXFw9Meb8U5rEH",
        "row": 11899,
        "state": "FLORIDA",
        "group": "707278",
        "group_num": [
            "recvcGBB2UBc6ZQtY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 577,
        "2024": 0,
        "id": "recM52fkYS0kMlCKP",
        "row": 11903,
        "state": "FLORIDA",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 501,
        "2024": 0,
        "id": "recnAKhj5qSBRFSLl",
        "row": 11965,
        "state": "FLORIDA",
        "group": "1431330",
        "group_num": [
            "recdSsNYRI8ewbT42"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 504,
        "2024": 0,
        "id": "rec602PzXwD5vCa9b",
        "row": 11964,
        "state": "FLORIDA",
        "group": "1418151",
        "group_num": [
            "recNJrWdufGUJ1Kno"
        ],
        "procedure": "RTC",
        "provider": "1418151 (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 1655,
        "2022": 1596,
        "2023": 510,
        "2024": 0,
        "id": "rec8JXjYrWza89KrP",
        "row": 11962,
        "state": "FLORIDA",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 524,
        "2024": 0,
        "id": "rectXlycT4w2N6Qsg",
        "row": 11957,
        "state": "FLORIDA",
        "group": "6F5527",
        "group_num": [
            "recIADS4BIDUCihEr"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 524,
        "2024": 0,
        "id": "recx191bJ8NywReoG",
        "row": 11956,
        "state": "FLORIDA",
        "group": "7L3961",
        "group_num": [
            "recPxK3l7dpwNAZwY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 512,
        "2024": 0,
        "id": "recAcZrOI9kOtr4T0",
        "row": 11961,
        "state": "FLORIDA",
        "group": "5L3966",
        "group_num": [
            "recZnLI3CM0uwmpSw"
        ],
        "procedure": "RTC",
        "provider": "OPTUM (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 574,
        "2023": 524,
        "2024": 0,
        "id": "recHy1Ig0WH3QYHjS",
        "row": 11954,
        "state": "FLORIDA",
        "group": "712525",
        "group_num": [
            "recAQuubnfqRgnTNb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 507,
        "2024": 0,
        "id": "recIYuLRjnvMelk2J",
        "row": 11963,
        "state": "FLORIDA",
        "group": "709335",
        "group_num": [
            "rec6koBcfPiJT6ZmM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 461,
        "2024": 0,
        "id": "recsDuCbXqooOtgQQ",
        "row": 12002,
        "state": "FLORIDA",
        "group": "1103835",
        "group_num": [
            "recoywgCZHLm0JA3s"
        ],
        "procedure": "PHP",
        "provider": "CONNECTICARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 488,
        "2023": 488,
        "2024": 0,
        "id": "recDcQa2Owt5ET1Co",
        "row": 11973,
        "state": "FLORIDA",
        "group": "9R2349",
        "group_num": [
            "recthqYU0dHiFaMGO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 461,
        "2024": 0,
        "id": "recNVuPZctiuI5vSz",
        "row": 12003,
        "state": "FLORIDA",
        "group": "2437587",
        "group_num": [
            "recERzRiaabUoOxGD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 423,
        "2024": 0,
        "id": "recUHdWbpq0k61jSC",
        "row": 12046,
        "state": "FLORIDA",
        "group": "701368",
        "group_num": [
            "rec8dQEDIEbOc4hi2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 365,
        "2024": 0,
        "id": "recMsbKeHKdHUVzaq",
        "row": 12058,
        "state": "FLORIDA",
        "group": "G1025077",
        "group_num": [
            "recCOOSHkCpJu8WEV"
        ],
        "procedure": "PHP",
        "provider": "SELECT HEALTH (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 320,
        "2024": 0,
        "id": "recfBiCiql52WAHxJ",
        "row": 12098,
        "state": "FLORIDA",
        "group": "FLDSNP",
        "group_num": [
            "recnzaT2pGwVsMgMI"
        ],
        "procedure": "PHP",
        "provider": "MEDICARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 276,
        "2024": 0,
        "id": "recZKHv5VlmaSn3OU",
        "row": 12127,
        "state": "FLORIDA",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 246,
        "2024": 0,
        "id": "rec2jUI2tt28lOfI3",
        "row": 12131,
        "state": "FLORIDA",
        "group": "9R2349",
        "group_num": [
            "recthqYU0dHiFaMGO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 276,
        "2024": 0,
        "id": "recptHC8lpjJEOagr",
        "row": 12128,
        "state": "FLORIDA",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 239,
        "2024": 0,
        "id": "recpMEVEsqcJge1f3",
        "row": 12135,
        "state": "FLORIDA",
        "group": "1W7077",
        "group_num": [
            "recomjZRQbJ8B0WVe"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 276,
        "2024": 0,
        "id": "recu27SmnhWUPecrX",
        "row": 12129,
        "state": "FLORIDA",
        "group": "742373",
        "group_num": [
            "recAcWITQb6bv5QYm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 222,
        "2024": 0,
        "id": "recv07yCpcXbtYnEH",
        "row": 12145,
        "state": "FLORIDA",
        "group": "2F9604",
        "group_num": [
            "recjXK0Ys05LXS9QL"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 276,
        "2024": 0,
        "id": "recxdlK2XxI4D10SL",
        "row": 12126,
        "state": "FLORIDA",
        "group": "9M1047",
        "group_num": [
            "recK20FVSHfvHz3J1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 174,
        "2024": 0,
        "id": "recisvkJUNpsisUHE",
        "row": 12201,
        "state": "FLORIDA",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "2023": 0,
        "2024": 0,
        "id": "recPTD80ZiRqFs6bn",
        "row": 12266,
        "state": "FLORIDA",
        "group": "712525",
        "group_num": [
            "recAQuubnfqRgnTNb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "2023": 0,
        "2024": 0,
        "id": "recYpSQzcfjFHUdzg",
        "row": 12265,
        "state": "FLORIDA",
        "group": "5Q5022",
        "group_num": [
            "recqMCCbx6ZczRKDU"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "2023": 0,
        "2024": 0,
        "id": "recJ6cPhbHBpfYLAS",
        "row": 12252,
        "state": "FLORIDA",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 3798,
        "2022": 3628,
        "2023": 0,
        "2024": 0,
        "id": "recN0EYO35L0deRlj",
        "row": 12236,
        "state": "FLORIDA",
        "group": "752490",
        "group_num": [
            "recyO9eVQngdrS7ix"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "2023": 0,
        "2024": 0,
        "id": "recWy7ibyJ2FfnWvF",
        "row": 12295,
        "state": "FLORIDA",
        "group": "914816",
        "group_num": [
            "recDVQsqfLLOoVoMu"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 235,
        "2023": 0,
        "2024": 0,
        "id": "recDVtWZ56qjA9RJp",
        "row": 12311,
        "state": "FLORIDA",
        "group": "2Y4403",
        "group_num": [
            "recXm7ikSESeMVZoj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 252,
        "2022": 276,
        "2023": 0,
        "2024": 0,
        "id": "recfYTxS38jmb0FPv",
        "row": 12336,
        "state": "FLORIDA",
        "group": "921107",
        "group_num": [
            "recz2dsYAPZP8sU4w"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 278,
        "2023": 0,
        "2024": 0,
        "id": "rechHD15MtnllgbRR",
        "row": 12337,
        "state": "FLORIDA",
        "group": "5Q5022",
        "group_num": [
            "recqMCCbx6ZczRKDU"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "2023": 0,
        "2024": 0,
        "id": "recjLOA9mUYyT5YMK",
        "row": 12329,
        "state": "FLORIDA",
        "group": "9L8730",
        "group_num": [
            "receDWgvn3q7EzXAn"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 247,
        "2022": 436,
        "2023": 0,
        "2024": 0,
        "id": "rectfQZ4YjwGnYc1u",
        "row": 12364,
        "state": "FLORIDA",
        "group": "752490",
        "group_num": [
            "recyO9eVQngdrS7ix"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 0,
        "2024": 0,
        "id": "recFkZGwxg4oU5ViK",
        "row": 12334,
        "state": "FLORIDA",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 867,
        "2021": 0,
        "2022": 275,
        "2023": 0,
        "2024": 0,
        "id": "recGQZDgBID1wJxGX",
        "row": 12335,
        "state": "FLORIDA",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 357,
        "2023": 0,
        "2024": 0,
        "id": "recij98xo6cCTPsMQ",
        "row": 12409,
        "state": "FLORIDA",
        "group": "3Y2052",
        "group_num": [
            "recwMKcVyGlfC8nkd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "2023": 0,
        "2024": 0,
        "id": "recP6sq3QnQ2ae667",
        "row": 12460,
        "state": "FLORIDA",
        "group": "7Q7140",
        "group_num": [
            "recuKSo0HrcOpygQP"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 524,
        "2023": 0,
        "2024": 0,
        "id": "recneV4AWnUOWuJPm",
        "row": 12517,
        "state": "FLORIDA",
        "group": "4W5784",
        "group_num": [
            "reckQUXk9kSjNptYm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "2023": 0,
        "2024": 0,
        "id": "recSSOlToqloBF9RR",
        "row": 12512,
        "state": "FLORIDA",
        "group": "3Y2052",
        "group_num": [
            "recwMKcVyGlfC8nkd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 501,
        "2023": 0,
        "2024": 0,
        "id": "recSlXBAB4ceu02Zp",
        "row": 12510,
        "state": "FLORIDA",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 513,
        "2023": 513,
        "2024": 0,
        "id": "recYb8H1DPxfx4RnC",
        "row": 12513,
        "state": "FLORIDA",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 518,
        "2023": 0,
        "2024": 0,
        "id": "rec1p17tlzAES8NBB",
        "row": 12515,
        "state": "FLORIDA",
        "group": "923344",
        "group_num": [
            "recKXoz70QExViTa3"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 480,
        "2023": 0,
        "2024": 0,
        "id": "rec2RJgXsePIMhqoB",
        "row": 12474,
        "state": "FLORIDA",
        "group": "2358232",
        "group_num": [
            "recZbTneMgGpOoz8N"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "2023": 0,
        "2024": 0,
        "id": "recbGAW5eIBNZMO4i",
        "row": 12511,
        "state": "FLORIDA",
        "group": "0F4615",
        "group_num": [
            "recYhBz0mT3YYsq7k"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 537,
        "2023": 0,
        "2024": 0,
        "id": "receVXEqtzo2P9iPz",
        "row": 12532,
        "state": "FLORIDA",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "2023": 0,
        "2024": 0,
        "id": "rec20RW8UAXDbZRtV",
        "row": 12550,
        "state": "FLORIDA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "2023": 0,
        "2024": 0,
        "id": "rec2l2fBosIrV4Q2j",
        "row": 12551,
        "state": "FLORIDA",
        "group": "921107",
        "group_num": [
            "recz2dsYAPZP8sU4w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 553,
        "2024": 0,
        "id": "rec93huQ509CxBb0C",
        "row": 12537,
        "state": "FLORIDA",
        "group": "717168",
        "group_num": [
            "recRg9TfgSUgMKk2n"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 553,
        "2024": 0,
        "id": "recdnbZ1QyQbX52o3",
        "row": 12539,
        "state": "FLORIDA",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 553,
        "2024": 0,
        "id": "recpEIIVDLZO9A6Jv",
        "row": 12538,
        "state": "FLORIDA",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 0,
        "2024": 0,
        "id": "recufRrQaNyiV5WJh",
        "row": 12540,
        "state": "FLORIDA",
        "group": "902633",
        "group_num": [
            "recjHTOBllTyzhnYG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 808,
        "2023": 0,
        "2024": 0,
        "id": "recSAu15INDslPUIk",
        "row": 12616,
        "state": "FLORIDA",
        "group": "0F4615",
        "group_num": [
            "recYhBz0mT3YYsq7k"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 697,
        "2023": 0,
        "2024": 0,
        "id": "recsDHIplQQ4MlQfV",
        "row": 12596,
        "state": "FLORIDA",
        "group": "923974",
        "group_num": [
            "recK25HQra2vcNbfx"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "recUVlNyptIzLTAux",
        "row": 12672,
        "state": "FLORIDA",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 893,
        "2022": 893,
        "2023": 0,
        "2024": 0,
        "id": "rec57PP5RWDwzeZVk",
        "row": 12686,
        "state": "FLORIDA",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 847,
        "2022": 889,
        "2023": 0,
        "2024": 0,
        "id": "recoUhoZItMWbOPbS",
        "row": 12682,
        "state": "FLORIDA",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "2023": 0,
        "2024": 0,
        "id": "recuG0o9UEPpRPRxd",
        "row": 12683,
        "state": "FLORIDA",
        "group": "717168",
        "group_num": [
            "recRg9TfgSUgMKk2n"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "2023": 0,
        "2024": 0,
        "id": "recwx8hAMKYyyF3lG",
        "row": 12674,
        "state": "FLORIDA",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1140,
        "2023": 0,
        "2024": 0,
        "id": "reckjgXwa17pPE3S4",
        "row": 12761,
        "state": "FLORIDA",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1131,
        "2023": 0,
        "2024": 0,
        "id": "rec4WvlK5BlV0lTF5",
        "row": 12758,
        "state": "FLORIDA",
        "group": "730992",
        "group_num": [
            "recSletlMIiNYBI0N"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1140,
        "2023": 0,
        "2024": 0,
        "id": "recpov90UQydUTtY6",
        "row": 12760,
        "state": "FLORIDA",
        "group": "00832",
        "group_num": [
            "recYcPK7htW5EbvPl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "2023": 1350,
        "2024": 0,
        "id": "recnEe90DkSzQffiZ",
        "row": 12787,
        "state": "FLORIDA",
        "group": "0908220",
        "group_num": [
            "recw69a5sFsAM7g8w"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 1500,
        "2024": 0,
        "id": "recYUFJiRB5UJ6cVO",
        "row": 12799,
        "state": "FLORIDA",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1358,
        "2023": 0,
        "2024": 0,
        "id": "recZmCiJVmhEA0wfa",
        "row": 12788,
        "state": "FLORIDA",
        "group": "0908220",
        "group_num": [
            "recw69a5sFsAM7g8w"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 3120,
        "2022": 1337,
        "2023": 0,
        "2024": 0,
        "id": "reccKn7nWB0z18fA5",
        "row": 12785,
        "state": "FLORIDA",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 5843,
        "2022": 1494,
        "2023": 1489,
        "2024": 0,
        "id": "recrfnfHJC7JUcKW0",
        "row": 12797,
        "state": "FLORIDA",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "2023": 0,
        "2024": 0,
        "id": "recBk6JmLcFZ1Aw0D",
        "row": 12775,
        "state": "FLORIDA",
        "group": "923974",
        "group_num": [
            "recK25HQra2vcNbfx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1508,
        "2023": 0,
        "2024": 0,
        "id": "recBrS7z2NTfOr6U7",
        "row": 12800,
        "state": "FLORIDA",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "RTC",
        "provider": "UHC (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "2023": 0,
        "2024": 0,
        "id": "recTzf2vZPEIt0ZQh",
        "row": 12857,
        "state": "FLORIDA",
        "group": "813234",
        "group_num": [
            "recK3uiAadt6InoTl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1978,
        "2023": 0,
        "2024": 0,
        "id": "recap6QUj2AanVLjW",
        "row": 12859,
        "state": "FLORIDA",
        "group": "15447",
        "group_num": [
            "recZXq55QAYDTfCQD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 2000,
        "2024": 0,
        "id": "recbhx5XbUpKJ94Lc",
        "row": 12862,
        "state": "FLORIDA",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 0,
        "2024": 0,
        "id": "reczCWQ3ESfwnlZC2",
        "row": 12868,
        "state": "FLORIDA",
        "group": "0908220",
        "group_num": [
            "recw69a5sFsAM7g8w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recgPkidnyumCIoKB",
        "row": 12892,
        "state": "FLORIDA",
        "group": "903918",
        "group_num": [
            "rec27WV3dOJVqylbi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2380,
        "2023": 0,
        "2024": 0,
        "id": "rechChS3IU27vzBiz",
        "row": 12897,
        "state": "FLORIDA",
        "group": "902849",
        "group_num": [
            "recZ8HeZXU1lJYkOB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2466,
        "2023": 0,
        "2024": 0,
        "id": "recZSjmuPPRmbS0eK",
        "row": 12904,
        "state": "FLORIDA",
        "group": "813234",
        "group_num": [
            "recK3uiAadt6InoTl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2396,
        "2023": 0,
        "2024": 0,
        "id": "recoooicfCD9lhl4q",
        "row": 12898,
        "state": "FLORIDA",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2495,
        "2023": 0,
        "2024": 0,
        "id": "recpmZVxvLA5BY4cO",
        "row": 12906,
        "state": "FLORIDA",
        "group": "VALMONT",
        "group_num": [
            "recP6oUTvZPbY59rF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2375,
        "2024": 0,
        "id": "recvCpaVzeJAGsbxb",
        "row": 12893,
        "state": "FLORIDA",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2375,
        "2024": 0,
        "id": "recxLH86CJPvBX2Jf",
        "row": 12890,
        "state": "FLORIDA",
        "group": "730583",
        "group_num": [
            "reccv2MtKbea2jwRg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recyThNWDuwxR2aTV",
        "row": 12889,
        "state": "FLORIDA",
        "group": "730109",
        "group_num": [
            "recaydJHy3MQdFuie"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 1500,
        "2022": 2250,
        "2023": 0,
        "2024": 0,
        "id": "recBbHCsQNocxOd4m",
        "row": 12877,
        "state": "FLORIDA",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 0,
        "2024": 0,
        "id": "recEJbME8wtZ12wa1",
        "row": 12891,
        "state": "FLORIDA",
        "group": "754471",
        "group_num": [
            "recPkH5yRLDfgNYTn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 4175,
        "2022": 2425,
        "2023": 0,
        "2024": 0,
        "id": "recKiIqNdTqtsvTjM",
        "row": 12901,
        "state": "FLORIDA",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "Detox",
        "provider": "UHC (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recjpVHAqduY5svDl",
        "row": 12954,
        "state": "FLORIDA",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "reckAfmYeWlLvNwVO",
        "row": 12958,
        "state": "FLORIDA",
        "group": "754471",
        "group_num": [
            "recPkH5yRLDfgNYTn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "2024": 0,
        "id": "recnERUtNPjDchOfO",
        "row": 12956,
        "state": "FLORIDA",
        "group": "730109",
        "group_num": [
            "recaydJHy3MQdFuie"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recWPTFdxXdRih39Y",
        "row": 12959,
        "state": "FLORIDA",
        "group": "903918",
        "group_num": [
            "rec27WV3dOJVqylbi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recqZL2sPWyDBuUru",
        "row": 12955,
        "state": "FLORIDA",
        "group": "706662",
        "group_num": [
            "rec0TUXNlOK9M4Rm0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recqttdNapLLkrmAk",
        "row": 12960,
        "state": "FLORIDA",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 0,
        "2024": 0,
        "id": "recCGL5A1uQH7WN7d",
        "row": 12957,
        "state": "FLORIDA",
        "group": "744407",
        "group_num": [
            "recCbOWpAmfh4fYPl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3120,
        "2023": 0,
        "2024": 0,
        "id": "recLoPy16XTmSdEcx",
        "row": 12969,
        "state": "FLORIDA",
        "group": "00832",
        "group_num": [
            "recYcPK7htW5EbvPl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "2023": 0,
        "2024": 0,
        "id": "reccl9m86y3OaInVF",
        "row": 12995,
        "state": "FLORIDA",
        "group": "922403",
        "group_num": [
            "rec5ETXW7LoJil2rz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4507,
        "2023": 0,
        "2024": 0,
        "id": "recoyAKRJ2RFVzwnZ",
        "row": 12997,
        "state": "FLORIDA",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5413,
        "2023": 0,
        "2024": 0,
        "id": "recsUCobSY91nODRI",
        "row": 13007,
        "state": "FLORIDA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3800,
        "2023": 0,
        "2024": 0,
        "id": "recuQzen8YvjyNH48",
        "row": 12982,
        "state": "FLORIDA",
        "group": "742856",
        "group_num": [
            "recb7RggkXuytATDU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "2023": 0,
        "2024": 0,
        "id": "recJwtgE7iSTVRUWc",
        "row": 12996,
        "state": "FLORIDA",
        "group": "922403",
        "group_num": [
            "rec5ETXW7LoJil2rz"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 3325,
        "2024": 0,
        "id": "recPfBe1DG65Ouvch",
        "row": 8632,
        "state": "FLORIDA",
        "group": "78800205",
        "group_num": [
            "recGoXFcn6qKSIf1U"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 0,
        "2024": 0,
        "id": "rectGs8CqkeTlWK8j",
        "row": 8630,
        "state": "FLORIDA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4038,
        "2024": 0,
        "id": "rec90tP2A1QlY9xJQ",
        "row": 11465,
        "state": "FLORIDA",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 4038,
        "2024": 0,
        "id": "recAxET6R2eqUj0LT",
        "row": 11466,
        "state": "FLORIDA",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "RTC",
        "provider": "GEHA (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 5099,
        "2024": 0,
        "id": "recMUHwcHPV3cJoWn",
        "row": 11456,
        "state": "FLORIDA",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 2550,
        "2024": 0,
        "id": "reck8ySqUOE8V2OSf",
        "row": 11531,
        "state": "FLORIDA",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 0,
        "2024": 0,
        "id": "recZYtmI16o3wcfzM",
        "row": 12986,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5100,
        "2023": 0,
        "2024": 0,
        "id": "rec8U4QDHcC4S4lbq",
        "row": 13003,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3325,
        "2023": 0,
        "2024": 0,
        "id": "recy31tHC7Skhn4Tt",
        "row": 12976,
        "state": "FLORIDA",
        "group": "78800205",
        "group_num": [
            "recGoXFcn6qKSIf1U"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "2023": 4200,
        "2024": 0,
        "id": "recBSfpHydtOC82Ji",
        "row": 12992,
        "state": "FLORIDA",
        "group": "78800205",
        "group_num": [
            "recGoXFcn6qKSIf1U"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 0,
        "2024": 0,
        "id": "recDe4M0rb1TUE1nq",
        "row": 12985,
        "state": "FLORIDA",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH SHARED SERVICES (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1100,
        "2024": 0,
        "id": "recPLuDpNB0cD8o89",
        "row": 11714,
        "state": "FLORIDA",
        "group": "00130566",
        "group_num": [
            "recTM0aL6yPHonxNH"
        ],
        "procedure": "RTC",
        "provider": "UNIVERA HEALTH CARE (UNIVERA  HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 850,
        "2024": 0,
        "id": "recfkok7YJow1kozX",
        "row": 11836,
        "state": "FLORIDA",
        "group": "00130566",
        "group_num": [
            "recTM0aL6yPHonxNH"
        ],
        "procedure": "PHP",
        "provider": "UNIVERA HEALTH CARE (UNIVERA  HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 405,
        "2023": 0,
        "2024": 0,
        "id": "recdYh3v0PX30IKJt",
        "row": 8634,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNIVERSITY OF UTAH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 405,
        "2023": 0,
        "2024": 0,
        "id": "recG6Ab9sKv6HDmYd",
        "row": 12427,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNIVERSITY OF UTAH (UNIVERSITY OF UTAH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1191,
        "2023": 0,
        "2024": 0,
        "id": "reccR6Ath0DsTvvnd",
        "row": 12766,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNIVERSITY OF UTAH (UNIVERSITY OF UTAH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 0,
        "2024": 0,
        "id": "recV4p0egjpZ9ofpC",
        "row": 8637,
        "state": "FLORIDA",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "PHP",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2810,
        "2023": 1025,
        "2024": 0,
        "id": "recyyn3CWIV56JevW",
        "row": 8636,
        "state": "FLORIDA",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "RTC",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1267,
        "2023": 0,
        "2024": 0,
        "id": "recITVTeRSO8RmZ8f",
        "row": 8635,
        "state": "FLORIDA",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "Detox",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1259,
        "2024": 0,
        "id": "recgr2EOeWWPMCyFM",
        "row": 11699,
        "state": "FLORIDA",
        "group": "022381300",
        "group_num": [
            "recGIkSdP9LH2NeiS"
        ],
        "procedure": "RTC",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 800,
        "2024": 0,
        "id": "rec8wgfbWQLhGS9l7",
        "row": 11852,
        "state": "FLORIDA",
        "group": "006938304",
        "group_num": [
            "recsNv77YssmvKR7m"
        ],
        "procedure": "RTC",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 686,
        "2024": 0,
        "id": "recuujvEcgNCXfLRt",
        "row": 11869,
        "state": "FLORIDA",
        "group": "006938304",
        "group_num": [
            "recsNv77YssmvKR7m"
        ],
        "procedure": "Detox",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 900,
        "2024": 0,
        "id": "recFi6Fk2yVJ0cnul",
        "row": 11826,
        "state": "FLORIDA",
        "group": "021884301",
        "group_num": [
            "recOAWrUYjFEAuj7m"
        ],
        "procedure": "RTC",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 0,
        "2024": 0,
        "id": "recPyAACN4HJ9ivit",
        "row": 12317,
        "state": "FLORIDA",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "PHP",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1267,
        "2023": 0,
        "2024": 0,
        "id": "reczgstvi4gbVoZYk",
        "row": 12776,
        "state": "FLORIDA",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "Detox",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2161,
        "2023": 0,
        "2024": 0,
        "id": "rec4KNhGR8e06yxo4",
        "row": 12876,
        "state": "FLORIDA",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "RTC",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "2023": 0,
        "2024": 0,
        "id": "recOcVz0rbOKLng6W",
        "row": 12716,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UPRISE HEALTH (UPRISE HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "2023": 0,
        "2024": 0,
        "id": "rec5R6Sgr6ad6SQtu",
        "row": 12755,
        "state": "FLORIDA",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UPRISE HEALTH (UPRISE HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 600,
        "2024": 0,
        "id": "recQ6jP37ya13UXJ0",
        "row": 8638,
        "state": "FLORIDA",
        "group": "2022PRU",
        "group_num": [
            "reciaIBeQ0VQn2R8l"
        ],
        "procedure": "RTC",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 0,
        "2024": 0,
        "id": "recpT9uS1tywbHIt5",
        "row": 12562,
        "state": "FLORIDA",
        "group": "2022PRU",
        "group_num": [
            "reciaIBeQ0VQn2R8l"
        ],
        "procedure": "RTC",
        "provider": "WEB TPA (WEB TPA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 4200,
        "id": "recOCZDoWZ3AG6Hbl",
        "row": 13058,
        "state": "MASSACHUSETTS",
        "group": "005363001000101",
        "group_num": [
            "rec2NQl5207cep8Ei"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 196,
        "id": "rec9wtixyJk5xSEYr",
        "row": 13018,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1502,
        "id": "recgjfP4S0zdslNQL",
        "row": 13092,
        "state": "MASSACHUSETTS",
        "group": "014419701000118",
        "group_num": [
            "recqSR79DRmH0sO4C"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recgmgH3AQsXpBBdd",
        "row": 13088,
        "state": "MASSACHUSETTS",
        "group": "014194301800400",
        "group_num": [
            "recfCKgZCaxi9TESX"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "rechRmyPrGRWURMnZ",
        "row": 13089,
        "state": "MASSACHUSETTS",
        "group": "014194301800900",
        "group_num": [
            "recGTDCXfE0OWSKD8"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4515,
        "id": "rech4nwcX33y3PLzz",
        "row": 13100,
        "state": "MASSACHUSETTS",
        "group": "017507005000001",
        "group_num": [
            "rec7Q6bp3dozuy2B8"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recjZ5HPTUjkLFf8F",
        "row": 13101,
        "state": "MASSACHUSETTS",
        "group": "017514201000001",
        "group_num": [
            "recZZAcliNWqmMJvf"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1054,
        "id": "reckCwlYsTtwR4SYO",
        "row": 13110,
        "state": "MASSACHUSETTS",
        "group": "019723901000701",
        "group_num": [
            "recrzbB37OzoSsEKq"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1467,
        "id": "recPR0LrIyXOFJii6",
        "row": 13115,
        "state": "MASSACHUSETTS",
        "group": "028559301500020",
        "group_num": [
            "recPiVwS5pLoNlNrw"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recTcC0cGSrKEUoah",
        "row": 13099,
        "state": "MASSACHUSETTS",
        "group": "017506701100001",
        "group_num": [
            "reco6adsX7RyQp84A"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recW5VaR2SG9Jacex",
        "row": 13114,
        "state": "MASSACHUSETTS",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1075,
        "id": "recXyJQ9PHWLri2J3",
        "row": 13102,
        "state": "MASSACHUSETTS",
        "group": "017591801100001",
        "group_num": [
            "rec3K2JXFzdkE1nJq"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1645,
        "id": "recZnd7bO0lmWgY81",
        "row": 13085,
        "state": "MASSACHUSETTS",
        "group": "010016601200022",
        "group_num": [
            "recX9FnUEjAKHxe4P"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1080,
        "id": "rec4MKiwDj5EBRrVD",
        "row": 13103,
        "state": "MASSACHUSETTS",
        "group": "017657802200005",
        "group_num": [
            "recrsNdkxlW77fRJ5"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1014,
        "id": "rec4PYI0hqzeelmDd",
        "row": 13086,
        "state": "MASSACHUSETTS",
        "group": "010016601700020",
        "group_num": [
            "recWgTLngDJCyYjkq"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 996,
        "id": "rec7WUSjUQ9JLPWLL",
        "row": 13108,
        "state": "MASSACHUSETTS",
        "group": "018623901200001",
        "group_num": [
            "recSU090WmlYdHV6G"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1173,
        "id": "rec7GF8kzzeHVMCoC",
        "row": 13090,
        "state": "MASSACHUSETTS",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "rec8HdNyVJPBN68Em",
        "row": 13106,
        "state": "MASSACHUSETTS",
        "group": "018125901100003",
        "group_num": [
            "rectRDnsQqcMc1DAK"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "rec8NelQXgs7kHSdU",
        "row": 13091,
        "state": "MASSACHUSETTS",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recbi9a3QLgiob1bZ",
        "row": 13107,
        "state": "MASSACHUSETTS",
        "group": "018623901100501",
        "group_num": [
            "recffcumCxVTNGpwE"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1241,
        "2023": 996,
        "id": "recbiudgJE2uJXI6p",
        "row": 13095,
        "state": "MASSACHUSETTS",
        "group": "016962201200004",
        "group_num": [
            "recKu0rTqQ93pVPGJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3143,
        "id": "recdX5dO30tAkCUpq",
        "row": 13087,
        "state": "MASSACHUSETTS",
        "group": "010919002500151",
        "group_num": [
            "rec0a5mYEwDPcYZOh"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3900,
        "id": "recrUXt4KfXys1kaV",
        "row": 13096,
        "state": "MASSACHUSETTS",
        "group": "016964501000001",
        "group_num": [
            "recWMxgffyObEk0O2"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "recsBtVvg2L3FYDGZ",
        "row": 13098,
        "state": "MASSACHUSETTS",
        "group": "017500102000001",
        "group_num": [
            "reccERpM7mz5h3kYY"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recuE9D7IXQkAmnVz",
        "row": 13109,
        "state": "MASSACHUSETTS",
        "group": "018623901500001",
        "group_num": [
            "reccZUqYDXOC8ixKS"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 953,
        "id": "recvdtVF68NlC9S8N",
        "row": 13093,
        "state": "MASSACHUSETTS",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recGsswNMnUDck9Hv",
        "row": 13104,
        "state": "MASSACHUSETTS",
        "group": "017704301000001",
        "group_num": [
            "recEAWJq4pZCDeJ9w"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "recIwqTjndNpLfONm",
        "row": 13097,
        "state": "MASSACHUSETTS",
        "group": "016964601200001",
        "group_num": [
            "recYZysBnT8zMyQ7b"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recfwk1PBsM89KTqI",
        "row": 13123,
        "state": "MASSACHUSETTS",
        "group": "047976601200010",
        "group_num": [
            "recQ3thXXZZpvu3qb"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recfIA0mfSGrTnLxz",
        "row": 13121,
        "state": "MASSACHUSETTS",
        "group": "047655201400001",
        "group_num": [
            "recOg3s4GNq51BNii"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4333,
        "id": "recgpDocVklt2lqAF",
        "row": 13154,
        "state": "MASSACHUSETTS",
        "group": "086882201400010",
        "group_num": [
            "recRtVtXa5cFN7r0J"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 497,
        "2024": 13342,
        "id": "recnhHG3H2yTtVZtC",
        "row": 13147,
        "state": "MASSACHUSETTS",
        "group": "086629801000006",
        "group_num": [
            "recRdR84MxpG2GhG4"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1634,
        "id": "recOJWzWMpONineGf",
        "row": 13144,
        "state": "MASSACHUSETTS",
        "group": "084723501200101",
        "group_num": [
            "recUT6ALIVFbomes6"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1140,
        "id": "recQFhNp5XzzhPkl4",
        "row": 13143,
        "state": "MASSACHUSETTS",
        "group": "083920201100002",
        "group_num": [
            "recUuGJquW2ynBPyO"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1079,
        "id": "recUQ1MKrEEBx6rrd",
        "row": 13149,
        "state": "MASSACHUSETTS",
        "group": "086826404000113",
        "group_num": [
            "recjT1gKFEBT7S9NY"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 3331,
        "id": "recUoPQABz5VmOa7g",
        "row": 13157,
        "state": "MASSACHUSETTS",
        "group": "087000001700001",
        "group_num": [
            "reccKVk2JL5mhGNiy"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1522,
        "id": "rec1JlQyj4AYyLLba",
        "row": 13156,
        "state": "MASSACHUSETTS",
        "group": "086989302200001",
        "group_num": [
            "recCaRAp97sZOt9MW"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "rec47rqynLKYJXUca",
        "row": 13153,
        "state": "MASSACHUSETTS",
        "group": "086881601000001",
        "group_num": [
            "recK8UKMoFHTDlCE4"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "rec4nfYtIfy493Ybe",
        "row": 13120,
        "state": "MASSACHUSETTS",
        "group": "046983001600001",
        "group_num": [
            "recXgCMFmQZwFMv05"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3416,
        "id": "rec5LS6Mysec5uQTR",
        "row": 13125,
        "state": "MASSACHUSETTS",
        "group": "070450203300101",
        "group_num": [
            "recXxjHFCar6N5vLa"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "rec7sXsX6kbIwl1PO",
        "row": 13122,
        "state": "MASSACHUSETTS",
        "group": "047659202000025",
        "group_num": [
            "recfJeKIMFYwtM9Iw"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "rec7FGf2TGu343YWv",
        "row": 13140,
        "state": "MASSACHUSETTS",
        "group": "081246303100001",
        "group_num": [
            "reczBNa9iMeRZjwFp"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "rec8ihI8Bg5Woi8Zx",
        "row": 13151,
        "state": "MASSACHUSETTS",
        "group": "086857701600001",
        "group_num": [
            "recUm2mvhGoKnadNj"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "rec8jrTlO77IM2TVO",
        "row": 13145,
        "state": "MASSACHUSETTS",
        "group": "086523701200112",
        "group_num": [
            "recEDGPf9UwD8BtD1"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1531,
        "id": "rec9ZziVCvFaxHm6d",
        "row": 13139,
        "state": "MASSACHUSETTS",
        "group": "081128103800001",
        "group_num": [
            "reczWut2n696s3Wuz"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recaiTnQbMfA9sOWe",
        "row": 13142,
        "state": "MASSACHUSETTS",
        "group": "083725201500101",
        "group_num": [
            "reciy9UO21xttFYjB"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "reccb4flCxq233g3b",
        "row": 13155,
        "state": "MASSACHUSETTS",
        "group": "086989001200001",
        "group_num": [
            "recR9VxzWWPqWv1Qm"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4100,
        "id": "recdklH112vA0VA7t",
        "row": 13146,
        "state": "MASSACHUSETTS",
        "group": "086540402000100",
        "group_num": [
            "reclWa2pEFtaYBHyp"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 844,
        "id": "recv70iJXuldpnFcz",
        "row": 13137,
        "state": "MASSACHUSETTS",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "recxWdFosvQ6l0io4",
        "row": 13136,
        "state": "MASSACHUSETTS",
        "group": "072488201200001",
        "group_num": [
            "recsu33HBRHhNiZyT"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "2023": 4969,
        "id": "recxqGjfREi30Ezro",
        "row": 13118,
        "state": "MASSACHUSETTS",
        "group": "039363002700002",
        "group_num": [
            "rec1SWhtaGeLxnznw"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "recEBDhgdgFEZVixi",
        "row": 13138,
        "state": "MASSACHUSETTS",
        "group": "073739201400464",
        "group_num": [
            "reciGQZLNHIQYl14r"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "2023": 3386,
        "id": "recGBlabfLN1r2dV3",
        "row": 13134,
        "state": "MASSACHUSETTS",
        "group": "072432802400001",
        "group_num": [
            "recduyo6t6HitAz9X"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "recHwOFMgbKe8Fh9C",
        "row": 13124,
        "state": "MASSACHUSETTS",
        "group": "066697301400001",
        "group_num": [
            "recFuIorDPQ5C0tOk"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 935,
        "id": "recI2iJosTgGZBUsm",
        "row": 13150,
        "state": "MASSACHUSETTS",
        "group": "086856003500011",
        "group_num": [
            "rec4tVIBqVMDbCfLo"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 946,
        "id": "recJi41fFVxmfbMLF",
        "row": 13135,
        "state": "MASSACHUSETTS",
        "group": "072442501300401",
        "group_num": [
            "rec2bGl71pSt6T7Kn"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2433,
        "id": "recKQVjnq6xmT3Hyk",
        "row": 13158,
        "state": "MASSACHUSETTS",
        "group": "087707601400007",
        "group_num": [
            "rec1pXAyBuVN1qTFO"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "recK3u4fKpSZqiWqP",
        "row": 13152,
        "state": "MASSACHUSETTS",
        "group": "086862002100109",
        "group_num": [
            "reccZOyVawj3efCCM"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 760,
        "id": "recNXFqbpUFtOgRHx",
        "row": 13148,
        "state": "MASSACHUSETTS",
        "group": "086810901700004",
        "group_num": [
            "recK6HGmygQIRmgEC"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1271,
        "id": "rec2ysahASfHQ1jJA",
        "row": 13199,
        "state": "MASSACHUSETTS",
        "group": "16962201300004",
        "group_num": [
            "rec7eLIsXf8r75rgE"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1196,
        "2023": 7948,
        "id": "recTni8P6RbdvPo2u",
        "row": 13305,
        "state": "MASSACHUSETTS",
        "group": "86873801900001",
        "group_num": [
            "recK5eAXsLWNaVPhN"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recstUwGDqywachJM",
        "row": 13306,
        "state": "MASSACHUSETTS",
        "group": "87009501100309",
        "group_num": [
            "reckoJA6k3qkrA9mJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4455,
        "id": "recxdx4PlDPVN6v5D",
        "row": 13293,
        "state": "MASSACHUSETTS",
        "group": "72445804100001",
        "group_num": [
            "recmR9RhcQQ9l8EML"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recHB0c7AQvGDxjPC",
        "row": 13277,
        "state": "MASSACHUSETTS",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "Detox",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 24309,
        "id": "recfLsQmhNNmscOA2",
        "row": 13399,
        "state": "MASSACHUSETTS",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "recff5t4HrsgR1Y02",
        "row": 13382,
        "state": "MASSACHUSETTS",
        "group": "017507005000001",
        "group_num": [
            "rec7Q6bp3dozuy2B8"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "rech1QSHpO4X62AlB",
        "row": 13378,
        "state": "MASSACHUSETTS",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1342,
        "id": "rechCFwmfTOzBsDwQ",
        "row": 13411,
        "state": "MASSACHUSETTS",
        "group": "087707601400007",
        "group_num": [
            "rec1pXAyBuVN1qTFO"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recjzjBCfWLK6ph0C",
        "row": 13386,
        "state": "MASSACHUSETTS",
        "group": "018125901100003",
        "group_num": [
            "rectRDnsQqcMc1DAK"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 39000,
        "id": "reckMZdVhB0yF0siL",
        "row": 13407,
        "state": "MASSACHUSETTS",
        "group": "086857701600001",
        "group_num": [
            "recUm2mvhGoKnadNj"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "reclSPkCrptzp9WL6",
        "row": 13393,
        "state": "MASSACHUSETTS",
        "group": "046983001600001",
        "group_num": [
            "recXgCMFmQZwFMv05"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1765,
        "id": "recO4KCB7qaCcFbhv",
        "row": 13383,
        "state": "MASSACHUSETTS",
        "group": "017657802200005",
        "group_num": [
            "recrsNdkxlW77fRJ5"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "2023": 3088,
        "id": "recSJ3zSPnNbQREdh",
        "row": 13392,
        "state": "MASSACHUSETTS",
        "group": "039363002700002",
        "group_num": [
            "rec1SWhtaGeLxnznw"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1563,
        "id": "recT8Qx6eM477sAYA",
        "row": 13403,
        "state": "MASSACHUSETTS",
        "group": "084723501200101",
        "group_num": [
            "recUT6ALIVFbomes6"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3400,
        "id": "recUePwX6K5gLz68r",
        "row": 13404,
        "state": "MASSACHUSETTS",
        "group": "086540402000100",
        "group_num": [
            "reclWa2pEFtaYBHyp"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "recVN3HcL3pNtYzd5",
        "row": 13398,
        "state": "MASSACHUSETTS",
        "group": "072488201200001",
        "group_num": [
            "recsu33HBRHhNiZyT"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recXO6PADH9llWDkJ",
        "row": 13406,
        "state": "MASSACHUSETTS",
        "group": "086826404000113",
        "group_num": [
            "recjT1gKFEBT7S9NY"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3667,
        "id": "recXgoPs5FunfMm30",
        "row": 13396,
        "state": "MASSACHUSETTS",
        "group": "047976601200010",
        "group_num": [
            "recQ3thXXZZpvu3qb"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recYUEfikKsQMVNCd",
        "row": 13402,
        "state": "MASSACHUSETTS",
        "group": "083725201500101",
        "group_num": [
            "reciy9UO21xttFYjB"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1310,
        "id": "recZVSpZp7Zgd4pxT",
        "row": 13385,
        "state": "MASSACHUSETTS",
        "group": "017704301000001",
        "group_num": [
            "recEAWJq4pZCDeJ9w"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3088,
        "id": "rec4pJocd4j0xTpTe",
        "row": 13400,
        "state": "MASSACHUSETTS",
        "group": "081128103800001",
        "group_num": [
            "reczWut2n696s3Wuz"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 865,
        "id": "rec5MRh2YhWMir2Sv",
        "row": 13380,
        "state": "MASSACHUSETTS",
        "group": "016962201200004",
        "group_num": [
            "recKu0rTqQ93pVPGJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2889,
        "id": "rec7KgOXUMDXQJbD5",
        "row": 13381,
        "state": "MASSACHUSETTS",
        "group": "016964501000001",
        "group_num": [
            "recWMxgffyObEk0O2"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 51870,
        "id": "rec7Mf3XVBC3xPMFE",
        "row": 13394,
        "state": "MASSACHUSETTS",
        "group": "047655201400001",
        "group_num": [
            "recOg3s4GNq51BNii"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recad6l17H95fQQjW",
        "row": 13379,
        "state": "MASSACHUSETTS",
        "group": "014419701000118",
        "group_num": [
            "recqSR79DRmH0sO4C"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "reccM8nWfJ70qtQTC",
        "row": 13374,
        "state": "MASSACHUSETTS",
        "group": "86873801900001",
        "group_num": [
            "recK5eAXsLWNaVPhN"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 35875,
        "id": "recdwkM4Fz57COU9r",
        "row": 13409,
        "state": "MASSACHUSETTS",
        "group": "086989302200001",
        "group_num": [
            "recCaRAp97sZOt9MW"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 74563,
        "id": "recoXzHYHkaVvOxIm",
        "row": 13384,
        "state": "MASSACHUSETTS",
        "group": "017704301000001",
        "group_num": [
            "recEAWJq4pZCDeJ9w"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3472,
        "id": "recoA4LJk7PlcNMrL",
        "row": 13408,
        "state": "MASSACHUSETTS",
        "group": "086862002100109",
        "group_num": [
            "reccZOyVawj3efCCM"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 17364,
        "id": "recpqf1XEhj5a0jIV",
        "row": 13405,
        "state": "MASSACHUSETTS",
        "group": "086810901700004",
        "group_num": [
            "recK6HGmygQIRmgEC"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 14756,
        "id": "recsnNjpBgiQKyBbC",
        "row": 13389,
        "state": "MASSACHUSETTS",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 42344,
        "id": "rectU68lm5bLcQTDm",
        "row": 13376,
        "state": "MASSACHUSETTS",
        "group": "010919002500151",
        "group_num": [
            "rec0a5mYEwDPcYZOh"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "id": "rectsZff1tHIytXbh",
        "row": 13401,
        "state": "MASSACHUSETTS",
        "group": "081246303100001",
        "group_num": [
            "reczBNa9iMeRZjwFp"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 26156,
        "id": "recvn2kvYBQOupQbL",
        "row": 13388,
        "state": "MASSACHUSETTS",
        "group": "019723901000701",
        "group_num": [
            "recrzbB37OzoSsEKq"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3325,
        "id": "recxIRugo81ImIwwc",
        "row": 13377,
        "state": "MASSACHUSETTS",
        "group": "014194301800900",
        "group_num": [
            "recGTDCXfE0OWSKD8"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3406,
        "id": "recBviOrUT3wZ82LK",
        "row": 13395,
        "state": "MASSACHUSETTS",
        "group": "047659202000025",
        "group_num": [
            "recfJeKIMFYwtM9Iw"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2637,
        "id": "recGTrIuaS6C8RBw1",
        "row": 13410,
        "state": "MASSACHUSETTS",
        "group": "087000001700001",
        "group_num": [
            "reccKVk2JL5mhGNiy"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "recGV8EuanrpfCcq9",
        "row": 13397,
        "state": "MASSACHUSETTS",
        "group": "066697301400001",
        "group_num": [
            "recFuIorDPQ5C0tOk"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 7400,
        "id": "recHIhZYvhGXGp8sw",
        "row": 13391,
        "state": "MASSACHUSETTS",
        "group": "028559301500020",
        "group_num": [
            "recPiVwS5pLoNlNrw"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 559,
        "id": "recJruQ1UpP8WUFy6",
        "row": 13390,
        "state": "MASSACHUSETTS",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "PHP",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "id": "recMsAqzjAfMS119e",
        "row": 13375,
        "state": "MASSACHUSETTS",
        "group": "010016601700020",
        "group_num": [
            "recWgTLngDJCyYjkq"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2476,
        "id": "recMlVWDnLHDldlgx",
        "row": 13387,
        "state": "MASSACHUSETTS",
        "group": "018623901200001",
        "group_num": [
            "recSU090WmlYdHV6G"
        ],
        "procedure": "RTC",
        "provider": "AETNA (AETNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2466,
        "id": "receQBGhkDwNt08kr",
        "row": 13049,
        "state": "MASSACHUSETTS",
        "group": "002374561",
        "group_num": [
            "rec3fx19sC19wQmVy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1229,
        "id": "recfUgMTq8vsh19nQ",
        "row": 13053,
        "state": "MASSACHUSETTS",
        "group": "004004826",
        "group_num": [
            "recZfQMhm2IM1Febr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1191,
        "id": "recgJgc6m3JvuMxsT",
        "row": 13039,
        "state": "MASSACHUSETTS",
        "group": "002318594",
        "group_num": [
            "recVjL8jdlOqi4YDf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "rech4ev6SH6WsxpL4",
        "row": 13029,
        "state": "MASSACHUSETTS",
        "group": "000EWQ83401AP002",
        "group_num": [
            "recRSYcYQQfw1v0o8"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2520,
        "id": "recixhZ1nHiC9FSTi",
        "row": 13044,
        "state": "MASSACHUSETTS",
        "group": "002364614",
        "group_num": [
            "recgIcZ4GdwQdahFB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recjt5wqGiNCC0HeQ",
        "row": 13046,
        "state": "MASSACHUSETTS",
        "group": "002364683",
        "group_num": [
            "recFU7vPSGOilWYdD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1207,
        "id": "recliCjAcHywbTZn5",
        "row": 13041,
        "state": "MASSACHUSETTS",
        "group": "002347959",
        "group_num": [
            "recXAqs2ROUU2v8zq"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recOz5NTVQhhdYX9Z",
        "row": 13035,
        "state": "MASSACHUSETTS",
        "group": "001097M392",
        "group_num": [
            "recv8Ycvkf3wvHDL5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "id": "recPBV7WS3NyGQlK2",
        "row": 13052,
        "state": "MASSACHUSETTS",
        "group": "00310884",
        "group_num": [
            "recD2U5dBdHENQFtP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1263,
        "2023": 1258,
        "id": "recQeIZMS49X0FPae",
        "row": 13038,
        "state": "MASSACHUSETTS",
        "group": "002291579",
        "group_num": [
            "recZDVZyy0oMsqfOB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recRAHv4gRC5TvjgF",
        "row": 13034,
        "state": "MASSACHUSETTS",
        "group": "001097M200",
        "group_num": [
            "rec4dPMaDn8GSR4O9"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1246,
        "id": "recRDo9046YBaqcVb",
        "row": 13048,
        "state": "MASSACHUSETTS",
        "group": "002370159",
        "group_num": [
            "recHIZhRkqJpHkgzs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "recSWfxkPtO9ZQa8u",
        "row": 13028,
        "state": "MASSACHUSETTS",
        "group": "000AHE834AAAP001",
        "group_num": [
            "recQkrWcAE1tRcSPi"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recWsMXMBXsGJVI1Q",
        "row": 13056,
        "state": "MASSACHUSETTS",
        "group": "00528985",
        "group_num": [
            "receCeE1KI9Gu0pwn"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1262,
        "id": "recWH83cK2tYBbjyu",
        "row": 13047,
        "state": "MASSACHUSETTS",
        "group": "002364970",
        "group_num": [
            "recz7rLgJYPDVGfDv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recXamorJxDK1t32Z",
        "row": 13030,
        "state": "MASSACHUSETTS",
        "group": "000P9D83401AP001",
        "group_num": [
            "recL0MZTfZTtpY8gx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec4GQXOgSZZZEUYo",
        "row": 13055,
        "state": "MASSACHUSETTS",
        "group": "00527289",
        "group_num": [
            "rect4IoYQQ7lEhZFV"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1256,
        "id": "rec5fFwfEv5oh5qwU",
        "row": 13043,
        "state": "MASSACHUSETTS",
        "group": "002364490",
        "group_num": [
            "rec0EAndkpryjh2hZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2337,
        "2023": 525,
        "id": "rec7kfCXLCRjZM2Iy",
        "row": 13020,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1235,
        "id": "rec8IWl8adXJUyouE",
        "row": 13040,
        "state": "MASSACHUSETTS",
        "group": "002334386",
        "group_num": [
            "recF2b145qJ5XAECv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec9VZ0BNf2iVOoSc",
        "row": 13054,
        "state": "MASSACHUSETTS",
        "group": "004099M128",
        "group_num": [
            "rec8OsXkAaikBDf1B"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "id": "recctiJ4LS7p2P8XF",
        "row": 13027,
        "state": "MASSACHUSETTS",
        "group": "00091256",
        "group_num": [
            "recNGbe8YyLtLKdiC"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1850,
        "id": "reccrZNpISBlMHZ8W",
        "row": 13036,
        "state": "MASSACHUSETTS",
        "group": "001162E482",
        "group_num": [
            "recAq10gJUed0cE3A"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2159,
        "id": "recoaG0smzfsFgjpQ",
        "row": 13032,
        "state": "MASSACHUSETTS",
        "group": "000TUR83419AO001",
        "group_num": [
            "recScDVMEW1YvHz5G"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recrLBZ231zc9LDWx",
        "row": 13060,
        "state": "MASSACHUSETTS",
        "group": "005965M018",
        "group_num": [
            "recVSDnDMzMV4gZvB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1266,
        "id": "reczOrAX3e0GXuuQG",
        "row": 13051,
        "state": "MASSACHUSETTS",
        "group": "002376761",
        "group_num": [
            "recmZJcq6C8NX08zb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "2024": 2400,
        "id": "recAxqgMO8fDbYrUE",
        "row": 13026,
        "state": "MASSACHUSETTS",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recDSGIv15fOE99gQ",
        "row": 13059,
        "state": "MASSACHUSETTS",
        "group": "005545M002",
        "group_num": [
            "recBNTljRIC1oEuKN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1332,
        "id": "recHTb4Ir07Q4tXDn",
        "row": 13050,
        "state": "MASSACHUSETTS",
        "group": "002375841",
        "group_num": [
            "recI61eNnfD98iyyi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recJAjR4YpKfVpAA9",
        "row": 13067,
        "state": "MASSACHUSETTS",
        "group": "00704240",
        "group_num": [
            "recYyKdleQ2VFEqES"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "id": "recJGCYvqd5EP3Lkn",
        "row": 13037,
        "state": "MASSACHUSETTS",
        "group": "00123268",
        "group_num": [
            "recfFuyIWCoGitNhD"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recJ2ez0Sjc4wUM1s",
        "row": 13019,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 736,
        "id": "recKEJTwpM0C1gDeX",
        "row": 13045,
        "state": "MASSACHUSETTS",
        "group": "002364671",
        "group_num": [
            "recIvf2RKB4w0CiRH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "recN6w242vHqOu9nL",
        "row": 13031,
        "state": "MASSACHUSETTS",
        "group": "000TSJ83403YP001",
        "group_num": [
            "recM0LqPlYHB0VVmT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1299,
        "id": "recfvPgHjltP1YXJR",
        "row": 13076,
        "state": "MASSACHUSETTS",
        "group": "008504F0000",
        "group_num": [
            "rec49oSUqX9TZqDkd"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2223,
        "id": "recjntfAVCTjhBaHs",
        "row": 13113,
        "state": "MASSACHUSETTS",
        "group": "02560020",
        "group_num": [
            "rec6r0GDOd5KdV9pS"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "reckA9EAitvSY8UeD",
        "row": 13084,
        "state": "MASSACHUSETTS",
        "group": "00D668M100",
        "group_num": [
            "recpwn4Cv0UvLGGN6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2155,
        "id": "recT57Mm1H0WSErX5",
        "row": 13074,
        "state": "MASSACHUSETTS",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recXbU1sdileNzqOs",
        "row": 13083,
        "state": "MASSACHUSETTS",
        "group": "00935000000",
        "group_num": [
            "recs2lJlQqikf2G7E"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recZyr22q5uEW9Luy",
        "row": 13078,
        "state": "MASSACHUSETTS",
        "group": "008932",
        "group_num": [
            "recgb2WWpsrFVGbot"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE ADMINISTRATORS (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2069,
        "id": "recZhRUCIHrmnDzkN",
        "row": 13072,
        "state": "MASSACHUSETTS",
        "group": "00760890000",
        "group_num": [
            "recUcZz6thRfympLJ"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2038,
        "id": "rec0bfHdmwrUYAijE",
        "row": 13070,
        "state": "MASSACHUSETTS",
        "group": "00758290015",
        "group_num": [
            "rec221qOurmAGq9tE"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1456,
        "id": "rec1QpOVKAEyiQRvg",
        "row": 13079,
        "state": "MASSACHUSETTS",
        "group": "00917000000",
        "group_num": [
            "recU3zhicEP5VevvB"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "rec8bBLEcWDSn35v4",
        "row": 13081,
        "state": "MASSACHUSETTS",
        "group": "00925000000",
        "group_num": [
            "recCZmq8VeyFp1P9q"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec9F6041UPF7g2AJ",
        "row": 13082,
        "state": "MASSACHUSETTS",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1643,
        "id": "recougZQ7H3MYlEUc",
        "row": 13073,
        "state": "MASSACHUSETTS",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 978,
        "id": "recpXhNcpi4ARCFLl",
        "row": 13075,
        "state": "MASSACHUSETTS",
        "group": "00762600000",
        "group_num": [
            "rec0vNRWfBQGqSjBX"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2089,
        "id": "recr9Iw2NKNwbMErf",
        "row": 13069,
        "state": "MASSACHUSETTS",
        "group": "00758290014",
        "group_num": [
            "recE7MLCrWvbYKHw1"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2122,
        "id": "recACpSILpByD1452",
        "row": 13077,
        "state": "MASSACHUSETTS",
        "group": "008513C",
        "group_num": [
            "rec3zhiEoTArEaV6C"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 892,
        "id": "recADakzpnBZGj6Db",
        "row": 13068,
        "state": "MASSACHUSETTS",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2992,
        "id": "recCPOaem9A9xL7rG",
        "row": 13071,
        "state": "MASSACHUSETTS",
        "group": "00758490038",
        "group_num": [
            "recVqK6SDOsn3Z955"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recFtarbOuzSemirk",
        "row": 13111,
        "state": "MASSACHUSETTS",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1555,
        "id": "recHL2TZ7viqnE1V0",
        "row": 13080,
        "state": "MASSACHUSETTS",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recKQLRkrC44wONJZ",
        "row": 13112,
        "state": "MASSACHUSETTS",
        "group": "022240",
        "group_num": [
            "recZnzuJyM9kWqxsd"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "recMj7aE2VjnCLobY",
        "row": 13094,
        "state": "MASSACHUSETTS",
        "group": "016937",
        "group_num": [
            "recQIMQ9fELHi903m"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 636,
        "id": "recfOQxTkwpIHqYOW",
        "row": 13164,
        "state": "MASSACHUSETTS",
        "group": "10045164",
        "group_num": [
            "recIxqJbVKCyqM9M8"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1756,
        "id": "rechTUuBtDerMbwak",
        "row": 13160,
        "state": "MASSACHUSETTS",
        "group": "089524",
        "group_num": [
            "recWMApAbTI2ovGm7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recjQgUADiHPFgU0e",
        "row": 13165,
        "state": "MASSACHUSETTS",
        "group": "10049870",
        "group_num": [
            "rec8GiUT3crTtwC7w"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "reclUCUnU2vlTrkYa",
        "row": 13127,
        "state": "MASSACHUSETTS",
        "group": "071821000H",
        "group_num": [
            "recrpNyINDyYJ3Dz1"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recn4lk7gG28FtIrP",
        "row": 13133,
        "state": "MASSACHUSETTS",
        "group": "072378",
        "group_num": [
            "rec7iSwIRd9xUogyc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "id": "recPFi6jdPng0Dq1B",
        "row": 13129,
        "state": "MASSACHUSETTS",
        "group": "071850001S",
        "group_num": [
            "rec2v9u4V4o9Iftw0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recPjoI9qvOMTYnDf",
        "row": 13159,
        "state": "MASSACHUSETTS",
        "group": "088451",
        "group_num": [
            "reci9awGKByPNEmJ2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recTRojqyavUuEzNH",
        "row": 13128,
        "state": "MASSACHUSETTS",
        "group": "071844003H",
        "group_num": [
            "recmKDXaDynmwUml8"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "rec2fvLPqtyVLuHiC",
        "row": 13132,
        "state": "MASSACHUSETTS",
        "group": "071889243H",
        "group_num": [
            "reculzcwQLYzmC8KX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec4YDHFvzygjzCw5",
        "row": 13131,
        "state": "MASSACHUSETTS",
        "group": "071877058A",
        "group_num": [
            "recSyqUx1yTYAWFml"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "rec4jVEpBOGzEIcpl",
        "row": 13126,
        "state": "MASSACHUSETTS",
        "group": "071820004H",
        "group_num": [
            "recdbvncKsT36WH1u"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 636,
        "id": "rec7GLcHoLygCdcIE",
        "row": 13166,
        "state": "MASSACHUSETTS",
        "group": "10053921",
        "group_num": [
            "recjWo90MCkqqKbUl"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "rec8GwIfXUK2a08oG",
        "row": 13163,
        "state": "MASSACHUSETTS",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "Detox",
        "provider": "BC OF ID (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1127,
        "id": "recr5oQ0StleAK0tR",
        "row": 13161,
        "state": "MASSACHUSETTS",
        "group": "0EJ681",
        "group_num": [
            "recwrfURA383HzXyi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rectkPhTvldT3i4M2",
        "row": 13130,
        "state": "MASSACHUSETTS",
        "group": "071851004H",
        "group_num": [
            "recb26YRnHcGRxtMm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3580,
        "id": "recyRdn0NqQoUrgzb",
        "row": 13162,
        "state": "MASSACHUSETTS",
        "group": "0G1111",
        "group_num": [
            "rectnhOQ6XPWYuXDH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "id": "recHCXQMN0sW6wXNb",
        "row": 13167,
        "state": "MASSACHUSETTS",
        "group": "10054256",
        "group_num": [
            "recmhmDjmxmar5Kmg"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recNRiwNXMn4ZPZAE",
        "row": 13141,
        "state": "MASSACHUSETTS",
        "group": "081846001H",
        "group_num": [
            "rectXCxwfSyyoZ4tL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "receZ63LkCJeWuyCw",
        "row": 13193,
        "state": "MASSACHUSETTS",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "rece9WvYUWSqCEOjQ",
        "row": 13176,
        "state": "MASSACHUSETTS",
        "group": "10704497",
        "group_num": [
            "recaSYrwsh47eJBtq"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2524,
        "id": "rechz8EUdTYO3kRsi",
        "row": 13201,
        "state": "MASSACHUSETTS",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1689,
        "2023": 2578,
        "id": "recktNgrlu4ev4hmA",
        "row": 13211,
        "state": "MASSACHUSETTS",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "reclbvfgjUyJZCI6r",
        "row": 13198,
        "state": "MASSACHUSETTS",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recm0SpWDpJkRnW7c",
        "row": 13192,
        "state": "MASSACHUSETTS",
        "group": "14167642",
        "group_num": [
            "recseiyiy76prHmzH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2714,
        "id": "recmkboPEAXQKEZvX",
        "row": 13200,
        "state": "MASSACHUSETTS",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 554,
        "id": "recOaMbF2AIxenXlM",
        "row": 13169,
        "state": "MASSACHUSETTS",
        "group": "10312121",
        "group_num": [
            "recUVeYj75WcOj8nf"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recRcK6QG3Tn8jTZB",
        "row": 13213,
        "state": "MASSACHUSETTS",
        "group": "213043M5B2",
        "group_num": [
            "recp0MsHRPCkozBKf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3460,
        "id": "recT2DgFLXHnNprDC",
        "row": 13185,
        "state": "MASSACHUSETTS",
        "group": "121212",
        "group_num": [
            "rec0X6xokCCwWSGhh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recUyk5dUhuswEIiD",
        "row": 13208,
        "state": "MASSACHUSETTS",
        "group": "197163G6AI",
        "group_num": [
            "reca3VjqTwBGHNf0O"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1212,
        "id": "recVPDKwb1anUfpak",
        "row": 13187,
        "state": "MASSACHUSETTS",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recXTCx49NYsY6Yc3",
        "row": 13178,
        "state": "MASSACHUSETTS",
        "group": "10783165",
        "group_num": [
            "recW7oMpkRUawBuby"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recYKiBqff41EVNGj",
        "row": 13175,
        "state": "MASSACHUSETTS",
        "group": "10663976",
        "group_num": [
            "recvNVQxjgVhzGLPQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 233,
        "id": "rec3BzhZOzdbG4Gw7",
        "row": 13209,
        "state": "MASSACHUSETTS",
        "group": "1W9D",
        "group_num": [
            "recyJYcs52qRUlLv9"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec6WWK8nUmC6QoEf",
        "row": 13186,
        "state": "MASSACHUSETTS",
        "group": "121625",
        "group_num": [
            "recBWN51XtY2gxOaP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recoijAHjoeuVDRX3",
        "row": 13177,
        "state": "MASSACHUSETTS",
        "group": "10773595",
        "group_num": [
            "recJK94m8KvneCmzz"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF DE (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recpEsfuoWa3jlyNo",
        "row": 13212,
        "state": "MASSACHUSETTS",
        "group": "212081M1EA",
        "group_num": [
            "rec8EhRfj7NqoMBdH"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recp4a2vSbTG4M6a9",
        "row": 13170,
        "state": "MASSACHUSETTS",
        "group": "10350478",
        "group_num": [
            "recFZ2Yd87yDQ4cND"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF ND (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 484,
        "id": "recwhDDsuWF5gtoPl",
        "row": 13171,
        "state": "MASSACHUSETTS",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5969,
        "id": "recy3U4pDOA5nSliT",
        "row": 13172,
        "state": "MASSACHUSETTS",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recCEuDF6mbAHW3TU",
        "row": 13174,
        "state": "MASSACHUSETTS",
        "group": "10568087",
        "group_num": [
            "recZwXyS4HUBUP3ai"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recDBcNsg22mreZai",
        "row": 13214,
        "state": "MASSACHUSETTS",
        "group": "213059M5HL",
        "group_num": [
            "recuJEA1XWMaZgk2h"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recErkuC3YC8mnPYR",
        "row": 13215,
        "state": "MASSACHUSETTS",
        "group": "213064M6NA",
        "group_num": [
            "recQCsTDAvryIxjBI"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recHEJrUFJqJkL3GZ",
        "row": 13191,
        "state": "MASSACHUSETTS",
        "group": "14160729",
        "group_num": [
            "recyHHjQOxaVj1N35"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2767,
        "id": "recJVZAxjpws0eZAU",
        "row": 13197,
        "state": "MASSACHUSETTS",
        "group": "168258",
        "group_num": [
            "rec5BybbUXATWiKo8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2160,
        "id": "recLP7Mro8Am46udY",
        "row": 13206,
        "state": "MASSACHUSETTS",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2833,
        "id": "recftkKqC5dKf1d22",
        "row": 13240,
        "state": "MASSACHUSETTS",
        "group": "300308BC1M",
        "group_num": [
            "recuCBfau1hnX2TwV"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recjxdFE8nk6NvCWN",
        "row": 13236,
        "state": "MASSACHUSETTS",
        "group": "289224",
        "group_num": [
            "rec3WStoe4xCiKlkY"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recOyIPhofwMOfeGy",
        "row": 13238,
        "state": "MASSACHUSETTS",
        "group": "300063001M",
        "group_num": [
            "recMxC5AwyVtb0lbs"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1959,
        "id": "recQRQZEHhIRVNdag",
        "row": 13226,
        "state": "MASSACHUSETTS",
        "group": "246237",
        "group_num": [
            "recQdOA3x4C2glaG7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recVxhIgBk4MSwK0w",
        "row": 13242,
        "state": "MASSACHUSETTS",
        "group": "300526001M",
        "group_num": [
            "rechSnSk6v1FyCOUO"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2229,
        "id": "recY8PYJZk2iyEUXz",
        "row": 13233,
        "state": "MASSACHUSETTS",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recZm87j4d85IQgzm",
        "row": 13239,
        "state": "MASSACHUSETTS",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec0lUvWMFR5d3Gza",
        "row": 13234,
        "state": "MASSACHUSETTS",
        "group": "270236M4A1",
        "group_num": [
            "recx1OMmFlzQIs09I"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "rec83sadPcIysUx3q",
        "row": 13222,
        "state": "MASSACHUSETTS",
        "group": "242557",
        "group_num": [
            "recYBUY1SsYXXaXPQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "rec8k3sdcmIxsPJaT",
        "row": 13241,
        "state": "MASSACHUSETTS",
        "group": "300480001M",
        "group_num": [
            "reckjOAjEKkuJfACF"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recoMdcUlOso8Comh",
        "row": 13219,
        "state": "MASSACHUSETTS",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1153,
        "id": "recrKpVLYM0wM4Fc0",
        "row": 13221,
        "state": "MASSACHUSETTS",
        "group": "239465",
        "group_num": [
            "recjKgSeOe2vla8zW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "rect2tUc8DUX0PUyn",
        "row": 13230,
        "state": "MASSACHUSETTS",
        "group": "270088MLMB",
        "group_num": [
            "rec0U6574kbxdZbbh"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 890,
        "id": "rectjHSyISghuASOU",
        "row": 13227,
        "state": "MASSACHUSETTS",
        "group": "247516",
        "group_num": [
            "reciq2T682oDuWMvN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "recB2HejjsW7Ig96w",
        "row": 13225,
        "state": "MASSACHUSETTS",
        "group": "245081",
        "group_num": [
            "rec2gtiSLGPO0ajEo"
        ],
        "procedure": "Detox",
        "provider": "HMSA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recHDgJCWGrSKpu6F",
        "row": 13232,
        "state": "MASSACHUSETTS",
        "group": "270154M6FA",
        "group_num": [
            "recND3MrxI2ZGcsrB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 515,
        "2023": 515,
        "id": "recI46puGqf3yEcjl",
        "row": 13235,
        "state": "MASSACHUSETTS",
        "group": "277242M001",
        "group_num": [
            "rec6TfDzOALe4Q06G"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "recMYCprufTBlpRi0",
        "row": 13231,
        "state": "MASSACHUSETTS",
        "group": "270092D1VA",
        "group_num": [
            "recdb4NyXFpEv7NHY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recfFtlWqeLQV0K4S",
        "row": 13291,
        "state": "MASSACHUSETTS",
        "group": "7213520MZS",
        "group_num": [
            "recvW4SXMnem5bJl8"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "reckw5OwSTs5MynSj",
        "row": 13285,
        "state": "MASSACHUSETTS",
        "group": "715976K00",
        "group_num": [
            "recpS5xRV5axgOn4Z"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2516,
        "id": "recOC77QU6rUo3c6a",
        "row": 13279,
        "state": "MASSACHUSETTS",
        "group": "6VMM00",
        "group_num": [
            "reccGWISOvYOLvpeX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recTDo0cE5YLKnjjm",
        "row": 13272,
        "state": "MASSACHUSETTS",
        "group": "3776503STM",
        "group_num": [
            "recnDglhKfwgP8Wnu"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1487,
        "id": "recZtxDofuHBEwK6X",
        "row": 13278,
        "state": "MASSACHUSETTS",
        "group": "6VMJ00",
        "group_num": [
            "recKnTmeNoqhwoZ82"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4153,
        "id": "recZaFPA4ZPg23Hxv",
        "row": 13304,
        "state": "MASSACHUSETTS",
        "group": "801242M003",
        "group_num": [
            "reckdiRR1pnNy8eaA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1926,
        "id": "rec2CywNgdpA2ioRu",
        "row": 13303,
        "state": "MASSACHUSETTS",
        "group": "800426M002",
        "group_num": [
            "recqd9Ojbf1DvAdef"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "rec3wXl9E6CYJl733",
        "row": 13288,
        "state": "MASSACHUSETTS",
        "group": "720902MM2A",
        "group_num": [
            "rec9woZxoyCPrysvx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recbxqL6jkYcFbFO7",
        "row": 13290,
        "state": "MASSACHUSETTS",
        "group": "7213520CNM",
        "group_num": [
            "recWF6oDTsZSakuCU"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recqSWRuHyUGboYWI",
        "row": 13276,
        "state": "MASSACHUSETTS",
        "group": "601720",
        "group_num": [
            "rec1c3xDPOWL8mwbg"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2431,
        "id": "recuZmMc3dGedMVup",
        "row": 13292,
        "state": "MASSACHUSETTS",
        "group": "7213520N1M",
        "group_num": [
            "recdYpeKOeIdHpAgi"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recyQ8Smr8HO5rRtB",
        "row": 13271,
        "state": "MASSACHUSETTS",
        "group": "375375000M",
        "group_num": [
            "reckhWYhLhdGwiBFQ"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recBZCDDKaX7s6xOK",
        "row": 13289,
        "state": "MASSACHUSETTS",
        "group": "720904TM3A",
        "group_num": [
            "recdcF498aJyHpMhy"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2746,
        "id": "recNKcsiKOPnMomn2",
        "row": 13287,
        "state": "MASSACHUSETTS",
        "group": "720097A00M",
        "group_num": [
            "recHtLmzyETFw98RV"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "2024": 829,
        "id": "reciVpz9zz67Ar4XR",
        "row": 13323,
        "state": "MASSACHUSETTS",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "reciWAHw52Cx9C9GQ",
        "row": 13353,
        "state": "MASSACHUSETTS",
        "group": "L00367000H",
        "group_num": [
            "recmCLDKjRY6GDCwZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 543,
        "id": "reck17dfcrVaIUAtZ",
        "row": 13354,
        "state": "MASSACHUSETTS",
        "group": "L01301M005",
        "group_num": [
            "reclHaCXHlyId2OcK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF NH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "reck5FnDfMVCdI5ti",
        "row": 13319,
        "state": "MASSACHUSETTS",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reckcavwMf0Wn5hyB",
        "row": 13344,
        "state": "MASSACHUSETTS",
        "group": "GELBERGROUP",
        "group_num": [
            "rec5cL5R3yZti0oTD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "recOhvmYguut4W9N8",
        "row": 13349,
        "state": "MASSACHUSETTS",
        "group": "JNY016M001",
        "group_num": [
            "recZhuJV6qce68TPx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1807,
        "2023": 1688,
        "id": "recPF5DcUO3kL0C3w",
        "row": 13365,
        "state": "MASSACHUSETTS",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "id": "recR1OXKZs31Fne3D",
        "row": 13326,
        "state": "MASSACHUSETTS",
        "group": "BHB361",
        "group_num": [
            "recm4zYUdiIPhOaIP"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "2023": 1290,
        "id": "recRgS22ZFwI6PeIv",
        "row": 13336,
        "state": "MASSACHUSETTS",
        "group": "CS0016001H",
        "group_num": [
            "recLwhAYFiq3BA79v"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recTxroEmBO84U853",
        "row": 13348,
        "state": "MASSACHUSETTS",
        "group": "JEY",
        "group_num": [
            "recH1UcY9eFzXBV9Q"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recUFjMOAv89Fd1ME",
        "row": 13332,
        "state": "MASSACHUSETTS",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3135,
        "id": "recVLoirWnPeVBuAE",
        "row": 13318,
        "state": "MASSACHUSETTS",
        "group": "997182022M",
        "group_num": [
            "rec1I7wwH3HiRN6uQ"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recXOEFP57ayrEA1d",
        "row": 13334,
        "state": "MASSACHUSETTS",
        "group": "CS0009009H",
        "group_num": [
            "recqO4n1NSbKsLNQb"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5443,
        "id": "recZgCHL2hVNIxr2t",
        "row": 13352,
        "state": "MASSACHUSETTS",
        "group": "L00144M005",
        "group_num": [
            "recaK55zL1l8xfJVv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recZosl69t8pAmDsG",
        "row": 13335,
        "state": "MASSACHUSETTS",
        "group": "CS0015000H",
        "group_num": [
            "recVOOOWR7FSFm3D3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 581,
        "2023": 674,
        "id": "rec45xbwRTaavTYnB",
        "row": 13343,
        "state": "MASSACHUSETTS",
        "group": "GB0192M002",
        "group_num": [
            "recIwlAhUPtIzxOTH"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "rec4dtaDZqaq2NQuV",
        "row": 13341,
        "state": "MASSACHUSETTS",
        "group": "DNT361",
        "group_num": [
            "recU3T8zJ7bFHBPqx"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recbTZPwUX37TLEg2",
        "row": 13363,
        "state": "MASSACHUSETTS",
        "group": "OH2197M001",
        "group_num": [
            "reclvvURIxYZPgubv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "id": "recc7wfxmVFMO5q0L",
        "row": 13364,
        "state": "MASSACHUSETTS",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1273,
        "id": "recpqe8uV1lUkKogK",
        "row": 13350,
        "state": "MASSACHUSETTS",
        "group": "JNY020M001",
        "group_num": [
            "recBeXMsnWs20qaa1"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recv9ToSduLbnns8K",
        "row": 13356,
        "state": "MASSACHUSETTS",
        "group": "L06213M001",
        "group_num": [
            "reczVfiA43du803oW"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3429,
        "id": "reczWnEEtU3y8EX9T",
        "row": 13345,
        "state": "MASSACHUSETTS",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2553,
        "id": "recz3QAcnzEkzJCRb",
        "row": 13324,
        "state": "MASSACHUSETTS",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3367,
        "id": "recAGDyl9qrj7BCRl",
        "row": 13321,
        "state": "MASSACHUSETTS",
        "group": "ADPGA1M298",
        "group_num": [
            "recAIHGvfo7XIyduS"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 525,
        "id": "recA3JUwiKdU2jvBG",
        "row": 13367,
        "state": "MASSACHUSETTS",
        "group": "P58478",
        "group_num": [
            "rec8DN1pooeYiOxfa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recDGDV693UKxU9Wo",
        "row": 13366,
        "state": "MASSACHUSETTS",
        "group": "P54320",
        "group_num": [
            "reczdhlxJ6ckTpENV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2746,
        "id": "recEeNtv0VD3X10HP",
        "row": 13355,
        "state": "MASSACHUSETTS",
        "group": "L05845M002",
        "group_num": [
            "rec456yMLVAtfaUwk"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "recMbTVwe1NUc7iUv",
        "row": 13337,
        "state": "MASSACHUSETTS",
        "group": "CS0130001H",
        "group_num": [
            "reccJeWFHf6Qapb52"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2154,
        "id": "recMg81QHPCc0wiLI",
        "row": 13333,
        "state": "MASSACHUSETTS",
        "group": "CS0009002S",
        "group_num": [
            "recpTr2YSXAnGAqh4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2571,
        "id": "recfwXho95dfppiQ1",
        "row": 13370,
        "state": "MASSACHUSETTS",
        "group": "W31060M001",
        "group_num": [
            "rececrd8x7IkT1XYv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1992,
        "id": "recjzeqmkcT7jTDMm",
        "row": 13414,
        "state": "MASSACHUSETTS",
        "group": "6VMJ00",
        "group_num": [
            "recKnTmeNoqhwoZ82"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1992,
        "id": "reckemNa60LekwkVm",
        "row": 13415,
        "state": "MASSACHUSETTS",
        "group": "6VMM00",
        "group_num": [
            "reccGWISOvYOLvpeX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "id": "recRGJbb8Snr67Kul",
        "row": 13412,
        "state": "MASSACHUSETTS",
        "group": "277242M001",
        "group_num": [
            "rec6TfDzOALe4Q06G"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 1138,
        "id": "recrS1rMoSUDvkSsa",
        "row": 13417,
        "state": "MASSACHUSETTS",
        "group": "000EWQ83401AP002",
        "group_num": [
            "recRSYcYQQfw1v0o8"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recAhqPjrrIsmUPOa",
        "row": 13368,
        "state": "MASSACHUSETTS",
        "group": "PI4634",
        "group_num": [
            "receBYCA15mepbhn8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "recF3M3te1U0KWKzi",
        "row": 13413,
        "state": "MASSACHUSETTS",
        "group": "197163G6AI",
        "group_num": [
            "reca3VjqTwBGHNf0O"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recICSQ9f0olCNT4i",
        "row": 13371,
        "state": "MASSACHUSETTS",
        "group": "WPB361",
        "group_num": [
            "recyj7rkkyKBWAghR"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "id": "recLp1HNnJsuMJbq7",
        "row": 13416,
        "state": "MASSACHUSETTS",
        "group": "6VMM00",
        "group_num": [
            "reccGWISOvYOLvpeX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "receLCyNev8JLGfUV",
        "row": 13454,
        "state": "MASSACHUSETTS",
        "group": "022240",
        "group_num": [
            "recZnzuJyM9kWqxsd"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2160,
        "id": "receRF46QU89q00Kk",
        "row": 13445,
        "state": "MASSACHUSETTS",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recfutBYv6ANN0Guu",
        "row": 13438,
        "state": "MASSACHUSETTS",
        "group": "ADPGA1M298",
        "group_num": [
            "recAIHGvfo7XIyduS"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recmGUgCID083fhHM",
        "row": 13446,
        "state": "MASSACHUSETTS",
        "group": "270236M4A1",
        "group_num": [
            "recx1OMmFlzQIs09I"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recOI7QMJMqCSt6JF",
        "row": 13427,
        "state": "MASSACHUSETTS",
        "group": "270154M6FA",
        "group_num": [
            "recND3MrxI2ZGcsrB"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4354,
        "id": "recPd55iZ9LsA6qi1",
        "row": 13451,
        "state": "MASSACHUSETTS",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recRLnaL6fV3YbyFD",
        "row": 13449,
        "state": "MASSACHUSETTS",
        "group": "213064M6NA",
        "group_num": [
            "recQCsTDAvryIxjBI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recUExqNtrQ8I5Jrr",
        "row": 13420,
        "state": "MASSACHUSETTS",
        "group": "001097M200",
        "group_num": [
            "rec4dPMaDn8GSR4O9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recULQHths7FG8CDe",
        "row": 13459,
        "state": "MASSACHUSETTS",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recUcfViElrHKPe8U",
        "row": 13437,
        "state": "MASSACHUSETTS",
        "group": "L00367000H",
        "group_num": [
            "recmCLDKjRY6GDCwZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recWakC5Z8IHW1UaI",
        "row": 13425,
        "state": "MASSACHUSETTS",
        "group": "005965M018",
        "group_num": [
            "recVSDnDMzMV4gZvB"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 723,
        "id": "recXamXwWtE8cDrth",
        "row": 13430,
        "state": "MASSACHUSETTS",
        "group": "801242M003",
        "group_num": [
            "reckdiRR1pnNy8eaA"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1373,
        "2024": 525,
        "id": "recY4uuZtey7J3Bay",
        "row": 13463,
        "state": "MASSACHUSETTS",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 449,
        "2023": 1712,
        "id": "recZGKoL1rFOQwHxy",
        "row": 13435,
        "state": "MASSACHUSETTS",
        "group": "CS0016001H",
        "group_num": [
            "recLwhAYFiq3BA79v"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1930,
        "id": "rec0rshcXSzM1AZ6e",
        "row": 13421,
        "state": "MASSACHUSETTS",
        "group": "001097M392",
        "group_num": [
            "recv8Ycvkf3wvHDL5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 2138,
        "id": "rec14Z7onyKlWlzvo",
        "row": 13426,
        "state": "MASSACHUSETTS",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec1g9ATT1AsRMsHC",
        "row": 13462,
        "state": "MASSACHUSETTS",
        "group": "GELBERGROUP",
        "group_num": [
            "rec5cL5R3yZti0oTD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1214,
        "id": "rec2d5QxNtOX5AbCP",
        "row": 13466,
        "state": "MASSACHUSETTS",
        "group": "002334386",
        "group_num": [
            "recF2b145qJ5XAECv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2100,
        "id": "rec3t8Cl8ulBbOPXe",
        "row": 13448,
        "state": "MASSACHUSETTS",
        "group": "212081M1EA",
        "group_num": [
            "rec8EhRfj7NqoMBdH"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 338,
        "id": "rec3oGgGnne3ljHJX",
        "row": 13440,
        "state": "MASSACHUSETTS",
        "group": "GB0192M002",
        "group_num": [
            "recIwlAhUPtIzxOTH"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rec4DrmC8Ay8npH5R",
        "row": 13418,
        "state": "MASSACHUSETTS",
        "group": "000TSJ83403YP001",
        "group_num": [
            "recM0LqPlYHB0VVmT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 925,
        "2023": 525,
        "id": "rec5C3WXxKjjh1dmf",
        "row": 13465,
        "state": "MASSACHUSETTS",
        "group": "PI4634",
        "group_num": [
            "receBYCA15mepbhn8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "rec6IDoodZGBPsHlO",
        "row": 13457,
        "state": "MASSACHUSETTS",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec8XuHrBWHsXHWx0",
        "row": 13453,
        "state": "MASSACHUSETTS",
        "group": "016937",
        "group_num": [
            "recQIMQ9fELHi903m"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1016,
        "id": "rec8QNLhMcS546qUN",
        "row": 13436,
        "state": "MASSACHUSETTS",
        "group": "CS0130001H",
        "group_num": [
            "reccJeWFHf6Qapb52"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2200,
        "id": "recc735NdrA62Y2qc",
        "row": 13441,
        "state": "MASSACHUSETTS",
        "group": "213043M5B2",
        "group_num": [
            "recp0MsHRPCkozBKf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 645,
        "id": "recox8yyyBhXCBdAi",
        "row": 13432,
        "state": "MASSACHUSETTS",
        "group": "071889243H",
        "group_num": [
            "reculzcwQLYzmC8KX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "recps02HHyY0eVTJT",
        "row": 13461,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recp7JBloAOiBLl7U",
        "row": 13447,
        "state": "MASSACHUSETTS",
        "group": "W31060M001",
        "group_num": [
            "rececrd8x7IkT1XYv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recplBA0ox2JiN8cO",
        "row": 13452,
        "state": "MASSACHUSETTS",
        "group": "0EJ681",
        "group_num": [
            "recwrfURA383HzXyi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 859,
        "id": "recsVJSVxNJBGts4S",
        "row": 13464,
        "state": "MASSACHUSETTS",
        "group": "P54320",
        "group_num": [
            "reczdhlxJ6ckTpENV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1992,
        "2023": 1404,
        "id": "recssAgy3a0mklNQ3",
        "row": 13428,
        "state": "MASSACHUSETTS",
        "group": "800426M002",
        "group_num": [
            "recqd9Ojbf1DvAdef"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 2200,
        "id": "recsfAPJS53xeHEti",
        "row": 13422,
        "state": "MASSACHUSETTS",
        "group": "004099M128",
        "group_num": [
            "rec8OsXkAaikBDf1B"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "id": "rectzne6uTBkOcBJh",
        "row": 13444,
        "state": "MASSACHUSETTS",
        "group": "270092D1VA",
        "group_num": [
            "recdb4NyXFpEv7NHY"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1761,
        "id": "recvtL4RFpnuZEm4p",
        "row": 13431,
        "state": "MASSACHUSETTS",
        "group": "071821000H",
        "group_num": [
            "recrpNyINDyYJ3Dz1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 265,
        "id": "recyUdBKr3GDhP6aV",
        "row": 13424,
        "state": "MASSACHUSETTS",
        "group": "005545M002",
        "group_num": [
            "recBNTljRIC1oEuKN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recBSMHPzPngb9Xtw",
        "row": 13423,
        "state": "MASSACHUSETTS",
        "group": "005545M002",
        "group_num": [
            "recBNTljRIC1oEuKN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1574,
        "id": "recBrci6x9nSdG9Y9",
        "row": 13429,
        "state": "MASSACHUSETTS",
        "group": "801242M003",
        "group_num": [
            "reckdiRR1pnNy8eaA"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recCKk3jpU4sM0bII",
        "row": 13455,
        "state": "MASSACHUSETTS",
        "group": "121625",
        "group_num": [
            "recBWN51XtY2gxOaP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 793,
        "id": "recCdj5jQWCWd987O",
        "row": 13434,
        "state": "MASSACHUSETTS",
        "group": "CS0009009H",
        "group_num": [
            "recqO4n1NSbKsLNQb"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1973,
        "2024": 1900,
        "id": "recDDZu9AFEqFJSw8",
        "row": 13419,
        "state": "MASSACHUSETTS",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "id": "recDdg0uF1S8yRgM3",
        "row": 13443,
        "state": "MASSACHUSETTS",
        "group": "270088MLMB",
        "group_num": [
            "rec0U6574kbxdZbbh"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 737,
        "id": "recDgn4Hr6bhgVdm2",
        "row": 13433,
        "state": "MASSACHUSETTS",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2139,
        "id": "recFNxASCoBUFKU0e",
        "row": 13456,
        "state": "MASSACHUSETTS",
        "group": "168258",
        "group_num": [
            "rec5BybbUXATWiKo8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recHvYGPUJMWyq8UC",
        "row": 13460,
        "state": "MASSACHUSETTS",
        "group": "601720",
        "group_num": [
            "rec1c3xDPOWL8mwbg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recIvvIH9UyfmktrV",
        "row": 13439,
        "state": "MASSACHUSETTS",
        "group": "ADPGA1M298",
        "group_num": [
            "recAIHGvfo7XIyduS"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1199,
        "id": "recLJpzpLdNkUtu6e",
        "row": 13467,
        "state": "MASSACHUSETTS",
        "group": "002347959",
        "group_num": [
            "recXAqs2ROUU2v8zq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2158,
        "id": "recLeuaQlFR9Pz8FL",
        "row": 13450,
        "state": "MASSACHUSETTS",
        "group": "OH2197M001",
        "group_num": [
            "reclvvURIxYZPgubv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recMhujmyBVEmNJ8m",
        "row": 13442,
        "state": "MASSACHUSETTS",
        "group": "213059M5HL",
        "group_num": [
            "recuJEA1XWMaZgk2h"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2399,
        "id": "recNnTIqwLe1qd3tz",
        "row": 13458,
        "state": "MASSACHUSETTS",
        "group": "247516",
        "group_num": [
            "reciq2T682oDuWMvN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1646,
        "2024": 1537,
        "id": "receU9kJ1xrPeykvT",
        "row": 13473,
        "state": "MASSACHUSETTS",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1243,
        "id": "recjkqnPhNn1Vk8Zw",
        "row": 13470,
        "state": "MASSACHUSETTS",
        "group": "002370159",
        "group_num": [
            "recHIZhRkqJpHkgzs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recSo3cOB2HhEfuUD",
        "row": 13474,
        "state": "MASSACHUSETTS",
        "group": "14167642",
        "group_num": [
            "recseiyiy76prHmzH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3912,
        "id": "rec0ptrnRKx82TwGa",
        "row": 13498,
        "state": "MASSACHUSETTS",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rec3OE1KXrLnrUA8A",
        "row": 13494,
        "state": "MASSACHUSETTS",
        "group": "00528985",
        "group_num": [
            "receCeE1KI9Gu0pwn"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "id": "rec9T9eVkanY6JpQU",
        "row": 13481,
        "state": "MASSACHUSETTS",
        "group": "715976K00",
        "group_num": [
            "recpS5xRV5axgOn4Z"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1304,
        "id": "recap4KcUCqXrT3nH",
        "row": 13472,
        "state": "MASSACHUSETTS",
        "group": "002375841",
        "group_num": [
            "recI61eNnfD98iyyi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recbBVZBocawfQZlf",
        "row": 13475,
        "state": "MASSACHUSETTS",
        "group": "14167642",
        "group_num": [
            "recseiyiy76prHmzH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3558,
        "id": "reccEpuIHnPdrnWHY",
        "row": 13496,
        "state": "MASSACHUSETTS",
        "group": "BHB361",
        "group_num": [
            "recm4zYUdiIPhOaIP"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1158,
        "id": "recpw4wGOFenS64Wm",
        "row": 13480,
        "state": "MASSACHUSETTS",
        "group": "10350478",
        "group_num": [
            "recFZ2Yd87yDQ4cND"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF ND (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recqdoqqPT7ceAZdh",
        "row": 13497,
        "state": "MASSACHUSETTS",
        "group": "DNT361",
        "group_num": [
            "recU3T8zJ7bFHBPqx"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recqeaDFhS1uurxoI",
        "row": 13500,
        "state": "MASSACHUSETTS",
        "group": "JEY",
        "group_num": [
            "recH1UcY9eFzXBV9Q"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recrS4YhrYcbuH0NU",
        "row": 13479,
        "state": "MASSACHUSETTS",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recu1rwOLdNW4M3L6",
        "row": 13493,
        "state": "MASSACHUSETTS",
        "group": "00527289",
        "group_num": [
            "rect4IoYQQ7lEhZFV"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3246,
        "id": "recuQb2xquJ1ZPItG",
        "row": 13482,
        "state": "MASSACHUSETTS",
        "group": "121212",
        "group_num": [
            "rec0X6xokCCwWSGhh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recwiI8oAtBthW1rd",
        "row": 13477,
        "state": "MASSACHUSETTS",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3054,
        "id": "recycrCa7VkLUXoRI",
        "row": 13468,
        "state": "MASSACHUSETTS",
        "group": "002364671",
        "group_num": [
            "recIvf2RKB4w0CiRH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1313,
        "id": "recAVjmQgZ7MnapRl",
        "row": 13469,
        "state": "MASSACHUSETTS",
        "group": "002364970",
        "group_num": [
            "recz7rLgJYPDVGfDv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recCH2iohyIRcX0W7",
        "row": 13476,
        "state": "MASSACHUSETTS",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "2024": 829,
        "id": "recDWLsdx52oH07GF",
        "row": 13478,
        "state": "MASSACHUSETTS",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recETNZhVfbUeizXI",
        "row": 13499,
        "state": "MASSACHUSETTS",
        "group": "WPB361",
        "group_num": [
            "recyj7rkkyKBWAghR"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3131,
        "id": "recJQImzekKz7bB28",
        "row": 13495,
        "state": "MASSACHUSETTS",
        "group": "00704240",
        "group_num": [
            "recYyKdleQ2VFEqES"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2092,
        "id": "recKZizpZWuGzOXRG",
        "row": 13471,
        "state": "MASSACHUSETTS",
        "group": "002374561",
        "group_num": [
            "rec3fx19sC19wQmVy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "recLZAqiEanNsXIGe",
        "row": 13483,
        "state": "MASSACHUSETTS",
        "group": "00310884",
        "group_num": [
            "recD2U5dBdHENQFtP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2033,
        "id": "recjAHJ8YUnNsMYyi",
        "row": 13559,
        "state": "MASSACHUSETTS",
        "group": "00760890000",
        "group_num": [
            "recUcZz6thRfympLJ"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "reckuPyc4Racpfj6x",
        "row": 13531,
        "state": "MASSACHUSETTS",
        "group": "000P9D83401AP001",
        "group_num": [
            "recL0MZTfZTtpY8gx"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4580,
        "id": "recmz0boROHdJqJtu",
        "row": 13566,
        "state": "MASSACHUSETTS",
        "group": "00935000000",
        "group_num": [
            "recs2lJlQqikf2G7E"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2127,
        "id": "recPtP0IVqkJBCXT8",
        "row": 13560,
        "state": "MASSACHUSETTS",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2275,
        "2023": 3377,
        "id": "recPI9e5sAABcpozL",
        "row": 13533,
        "state": "MASSACHUSETTS",
        "group": "300308BC1M",
        "group_num": [
            "recuCBfau1hnX2TwV"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2159,
        "id": "recSRr9WKpPfXb57j",
        "row": 13532,
        "state": "MASSACHUSETTS",
        "group": "000TUR83419AO001",
        "group_num": [
            "recScDVMEW1YvHz5G"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 906,
        "id": "recT9EFhtER5HmdvU",
        "row": 13562,
        "state": "MASSACHUSETTS",
        "group": "00762600000",
        "group_num": [
            "rec0vNRWfBQGqSjBX"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2296,
        "id": "recU12BtN6aHg3BKp",
        "row": 13537,
        "state": "MASSACHUSETTS",
        "group": "7213520CNM",
        "group_num": [
            "recWF6oDTsZSakuCU"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recVTOtxJiRigaH4j",
        "row": 13567,
        "state": "MASSACHUSETTS",
        "group": "008932",
        "group_num": [
            "recgb2WWpsrFVGbot"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 423,
        "id": "recXdRxz3fHMZmpEW",
        "row": 13547,
        "state": "MASSACHUSETTS",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2359,
        "id": "recZaxCCVDd3rK6ne",
        "row": 13565,
        "state": "MASSACHUSETTS",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3135,
        "id": "rec0D5JdbOJ02VD52",
        "row": 13534,
        "state": "MASSACHUSETTS",
        "group": "720097A00M",
        "group_num": [
            "recHtLmzyETFw98RV"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 887,
        "id": "rec0EM3A0GKtga4EQ",
        "row": 13554,
        "state": "MASSACHUSETTS",
        "group": "245081",
        "group_num": [
            "rec2gtiSLGPO0ajEo"
        ],
        "procedure": "RTC",
        "provider": "HMSA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1249,
        "2024": 2500,
        "id": "rec2XDMzK1uzaBSp0",
        "row": 13556,
        "state": "MASSACHUSETTS",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4316,
        "id": "rec3BwBDAykMW5psp",
        "row": 13530,
        "state": "MASSACHUSETTS",
        "group": "000AHE834AAAP001",
        "group_num": [
            "recQkrWcAE1tRcSPi"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3838,
        "id": "rec69RLfgyLEQ8CMi",
        "row": 13558,
        "state": "MASSACHUSETTS",
        "group": "00758490038",
        "group_num": [
            "recVqK6SDOsn3Z955"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1995,
        "id": "rec7WKZMcOsjxe2Hu",
        "row": 13561,
        "state": "MASSACHUSETTS",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recaWrfZKZKDB1ozO",
        "row": 13546,
        "state": "MASSACHUSETTS",
        "group": "00091256",
        "group_num": [
            "recNGbe8YyLtLKdiC"
        ],
        "procedure": "RTC",
        "provider": "EXCELLUS BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "2023": 2746,
        "id": "recodiHTgaSmR0g5m",
        "row": 13540,
        "state": "MASSACHUSETTS",
        "group": "300526001M",
        "group_num": [
            "rechSnSk6v1FyCOUO"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recq0Q8wZes2We1Pd",
        "row": 13539,
        "state": "MASSACHUSETTS",
        "group": "300480001M",
        "group_num": [
            "reckjOAjEKkuJfACF"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1817,
        "id": "recqxeVI6npUaPXzo",
        "row": 13555,
        "state": "MASSACHUSETTS",
        "group": "008513C",
        "group_num": [
            "rec3zhiEoTArEaV6C"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recr1HLXqFZNtJYMC",
        "row": 13541,
        "state": "MASSACHUSETTS",
        "group": "375375000M",
        "group_num": [
            "reckhWYhLhdGwiBFQ"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recur5feLEsNKNod8",
        "row": 13553,
        "state": "MASSACHUSETTS",
        "group": "10568087",
        "group_num": [
            "recZwXyS4HUBUP3ai"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recvz8KBh9xSeQpGs",
        "row": 13564,
        "state": "MASSACHUSETTS",
        "group": "00925000000",
        "group_num": [
            "recCZmq8VeyFp1P9q"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1160,
        "id": "recv7R6LJe9YLYZFh",
        "row": 13542,
        "state": "MASSACHUSETTS",
        "group": "JNY020M001",
        "group_num": [
            "recBeXMsnWs20qaa1"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4149,
        "id": "recvkOvoDxqMOkGXG",
        "row": 13536,
        "state": "MASSACHUSETTS",
        "group": "3776503STM",
        "group_num": [
            "recnDglhKfwgP8Wnu"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recz3nIzzhSQdAIB1",
        "row": 13538,
        "state": "MASSACHUSETTS",
        "group": "7213520MZS",
        "group_num": [
            "recvW4SXMnem5bJl8"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recBz7TbtzMow1uLS",
        "row": 13551,
        "state": "MASSACHUSETTS",
        "group": "10773595",
        "group_num": [
            "recJK94m8KvneCmzz"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recD5gG8ZYDY1tGQH",
        "row": 13548,
        "state": "MASSACHUSETTS",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1411,
        "id": "recFY7eMlFpv4VZp8",
        "row": 13563,
        "state": "MASSACHUSETTS",
        "group": "00917000000",
        "group_num": [
            "recU3zhicEP5VevvB"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2051,
        "id": "recGKGuWH2yfbDhv2",
        "row": 13557,
        "state": "MASSACHUSETTS",
        "group": "00758290014",
        "group_num": [
            "recE7MLCrWvbYKHw1"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2043,
        "id": "recL7T18QaNCernMw",
        "row": 13543,
        "state": "MASSACHUSETTS",
        "group": "L06213M001",
        "group_num": [
            "reczVfiA43du803oW"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "2023": 2746,
        "id": "recMQXhok2IZCvKGT",
        "row": 13535,
        "state": "MASSACHUSETTS",
        "group": "720902MM2A",
        "group_num": [
            "rec9woZxoyCPrysvx"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recNQSzyn8yi9YaXC",
        "row": 13552,
        "state": "MASSACHUSETTS",
        "group": "02560020",
        "group_num": [
            "rec6r0GDOd5KdV9pS"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "recgNRIJiVRaAnyTd",
        "row": 13572,
        "state": "MASSACHUSETTS",
        "group": "10312121",
        "group_num": [
            "recUVeYj75WcOj8nf"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "recQmavBqUgvIuYYR",
        "row": 13571,
        "state": "MASSACHUSETTS",
        "group": "10054256",
        "group_num": [
            "recmhmDjmxmar5Kmg"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "recbpRTAUHPB8yLZF",
        "row": 13570,
        "state": "MASSACHUSETTS",
        "group": "10053921",
        "group_num": [
            "recjWo90MCkqqKbUl"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 577,
        "id": "recAurusGZQmMU5Ga",
        "row": 13568,
        "state": "MASSACHUSETTS",
        "group": "10045164",
        "group_num": [
            "recIxqJbVKCyqM9M8"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 828,
        "id": "recLRpqGvLYoE43x6",
        "row": 13569,
        "state": "MASSACHUSETTS",
        "group": "10049870",
        "group_num": [
            "rec8GiUT3crTtwC7w"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA (BCBS OF MA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4266,
        "2023": 6000,
        "id": "recKaDgwpoAxIGsbf",
        "row": 13021,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5778,
        "2023": 6000,
        "id": "reckuiMNEJniLPD55",
        "row": 13116,
        "state": "MASSACHUSETTS",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recA2c5SvNt4nOf7J",
        "row": 13117,
        "state": "MASSACHUSETTS",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1852,
        "2023": 1852,
        "id": "recmRhX61bshBk5NT",
        "row": 13351,
        "state": "MASSACHUSETTS",
        "group": "JNY021M001",
        "group_num": [
            "rec6vHmmWGdbREJv0"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "id": "rec2ramWlYLHhAfdT",
        "row": 13358,
        "state": "MASSACHUSETTS",
        "group": "NYS",
        "group_num": [
            "recLzXTWVoGJVQcq9"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "reccKKDNGoXQ2orLR",
        "row": 13360,
        "state": "MASSACHUSETTS",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recdH8YHfGarQAE45",
        "row": 13361,
        "state": "MASSACHUSETTS",
        "group": "NYS015",
        "group_num": [
            "recr18guteE0NBZWM"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "recuNSCF6L7CiYz2k",
        "row": 13362,
        "state": "MASSACHUSETTS",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recFJ8j3shHmWh2JR",
        "row": 13359,
        "state": "MASSACHUSETTS",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "id": "recZkXm5hJtKZXrru",
        "row": 13372,
        "state": "MASSACHUSETTS",
        "group": "Y00001UU7A",
        "group_num": [
            "recVc9w4DJjeAH5PS"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recJ8TRqNdf4zvjY4",
        "row": 13373,
        "state": "MASSACHUSETTS",
        "group": "Y00370",
        "group_num": [
            "recYLvz4EAZ2SLZRW"
        ],
        "procedure": "Detox",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 4750,
        "id": "reci659lNjtYbtetM",
        "row": 13491,
        "state": "MASSACHUSETTS",
        "group": "Y00001UU7A",
        "group_num": [
            "recVc9w4DJjeAH5PS"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "recnlJZtzjabNYPfa",
        "row": 13488,
        "state": "MASSACHUSETTS",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "rec3jx354r8wLYBnN",
        "row": 13489,
        "state": "MASSACHUSETTS",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1504,
        "id": "rec7IzT5CfuoOn1PL",
        "row": 13487,
        "state": "MASSACHUSETTS",
        "group": "JNY021M001",
        "group_num": [
            "rec6vHmmWGdbREJv0"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recr1VZ6d1CdcV5En",
        "row": 13485,
        "state": "MASSACHUSETTS",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3268,
        "id": "recB7f5q3aNUFtYuR",
        "row": 13486,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 4708,
        "2024": 4750,
        "id": "recIeJJqnzEjxASQO",
        "row": 13484,
        "state": "MASSACHUSETTS",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recJdfuIyyNNBW7ch",
        "row": 13490,
        "state": "MASSACHUSETTS",
        "group": "NYS015",
        "group_num": [
            "recr18guteE0NBZWM"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recKoGvuwON3hZA3D",
        "row": 13492,
        "state": "MASSACHUSETTS",
        "group": "Y00370",
        "group_num": [
            "recYLvz4EAZ2SLZRW"
        ],
        "procedure": "RTC",
        "provider": "BEACON (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recZ9rqyfZXFfCtQ8",
        "row": 13545,
        "state": "MASSACHUSETTS",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recFThdrp0FlQ0aEV",
        "row": 13544,
        "state": "MASSACHUSETTS",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE PLAN (BEACON)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 865,
        "id": "recCoss0KrtjCxmi5",
        "row": 13042,
        "state": "MASSACHUSETTS",
        "group": "002357",
        "group_num": [
            "reckONKZVGvDf33Ge"
        ],
        "procedure": "Detox",
        "provider": "BOON CHAPMAN ADMINISTRATORS (BOON CHAPMAN ADMINISTRATORS)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1356,
        "id": "recNqWZyqD40ASAjD",
        "row": 13331,
        "state": "MASSACHUSETTS",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "Detox",
        "provider": "CENTIVO (CENTIVO)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1315,
        "id": "recFMNdVzoWKO9eX1",
        "row": 13501,
        "state": "MASSACHUSETTS",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "RTC",
        "provider": "CENTIVO (CENTIVO)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2636,
        "id": "rechRinHfujZluuLe",
        "row": 13066,
        "state": "MASSACHUSETTS",
        "group": "00637932",
        "group_num": [
            "rec1wSHvatg8KPPM5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2622,
        "id": "recnxF7wVZzMxlV1g",
        "row": 13064,
        "state": "MASSACHUSETTS",
        "group": "00629526",
        "group_num": [
            "recq80LYFtWODtOok"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1250,
        "id": "recOFIzAFMKCRPqj8",
        "row": 13025,
        "state": "MASSACHUSETTS",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2877,
        "2023": 2750,
        "id": "rec5ZfG83dbg24NXe",
        "row": 13062,
        "state": "MASSACHUSETTS",
        "group": "00616428",
        "group_num": [
            "recUgoGSqERH8SSbn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2750,
        "2023": 2721,
        "id": "rec7oLTpdyErv6Ubc",
        "row": 13061,
        "state": "MASSACHUSETTS",
        "group": "00611370",
        "group_num": [
            "recJNKD1cUuzEWxmn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2377,
        "id": "recoFQSEmrTJon5wT",
        "row": 13063,
        "state": "MASSACHUSETTS",
        "group": "00620959",
        "group_num": [
            "recpX0YTLDgJf48rZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2252,
        "id": "recBXcdgWWxwsILG4",
        "row": 13065,
        "state": "MASSACHUSETTS",
        "group": "00633807",
        "group_num": [
            "rec1KrHsse0NVy8ux"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2482,
        "2023": 1739,
        "id": "recChC6bqnddB20Fd",
        "row": 13022,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "recvKcV7OCpwmp7Bc",
        "row": 13119,
        "state": "MASSACHUSETTS",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 798,
        "id": "recclQZXWdPthL2BN",
        "row": 13210,
        "state": "MASSACHUSETTS",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2457,
        "id": "recftDGvgq97XOjNp",
        "row": 13250,
        "state": "MASSACHUSETTS",
        "group": "3213120",
        "group_num": [
            "rec2IHTUnV577EPim"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2275,
        "id": "recgSeXqHQr4HzQxV",
        "row": 13262,
        "state": "MASSACHUSETTS",
        "group": "333837",
        "group_num": [
            "recqTNFQRNxRmGANV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2218,
        "id": "recglFLpL0hBQH9w1",
        "row": 13265,
        "state": "MASSACHUSETTS",
        "group": "3341102",
        "group_num": [
            "reci3ZhSztnj4pPhY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2252,
        "id": "rechLeprmrPUeWIRP",
        "row": 13251,
        "state": "MASSACHUSETTS",
        "group": "3319768",
        "group_num": [
            "recF43o14kVABoJ7T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2351,
        "2024": 2231,
        "id": "reckWmd2IphYkuP2V",
        "row": 13257,
        "state": "MASSACHUSETTS",
        "group": "3331910",
        "group_num": [
            "rece21aw4R3lXguOZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1814,
        "id": "recPwxpOHtTrtJOBb",
        "row": 13248,
        "state": "MASSACHUSETTS",
        "group": "3210856",
        "group_num": [
            "recg762L532U1DJH9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1806,
        "id": "recTMJKa9TggQ3QXL",
        "row": 13264,
        "state": "MASSACHUSETTS",
        "group": "3339129",
        "group_num": [
            "recOTzQLd5VriwjeX"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2384,
        "id": "recWZmEFJQdGtRvUT",
        "row": 13267,
        "state": "MASSACHUSETTS",
        "group": "3343336",
        "group_num": [
            "rechg7XXqUzMJxcXz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2217,
        "id": "recWCzALSKenCgf9c",
        "row": 13244,
        "state": "MASSACHUSETTS",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2301,
        "id": "recWlBkJRuPwFrXHw",
        "row": 13255,
        "state": "MASSACHUSETTS",
        "group": "3330934",
        "group_num": [
            "recNmWEdvHx4v6WZN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2750,
        "id": "recY2mm2d0DMCqWjG",
        "row": 13266,
        "state": "MASSACHUSETTS",
        "group": "3342670",
        "group_num": [
            "recla1Z2mhBVURsMC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2324,
        "id": "rec2vJQivHUry4apJ",
        "row": 13253,
        "state": "MASSACHUSETTS",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2145,
        "id": "rec3GB9tu8QVTUTzw",
        "row": 13249,
        "state": "MASSACHUSETTS",
        "group": "3212812",
        "group_num": [
            "recr43HhKOoLUHbGM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2410,
        "id": "recdx0VXd8bQr4Mu8",
        "row": 13254,
        "state": "MASSACHUSETTS",
        "group": "3329926",
        "group_num": [
            "recCq7xmuUgRQAhlK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2587,
        "id": "recqwUJcdUaMatiwU",
        "row": 13252,
        "state": "MASSACHUSETTS",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1781,
        "id": "rectFPr9tvBVoDLKE",
        "row": 13247,
        "state": "MASSACHUSETTS",
        "group": "3210548",
        "group_num": [
            "rechu0wjl3Qo6ZQD8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2417,
        "id": "recA5KjYgPWpaMnfe",
        "row": 13261,
        "state": "MASSACHUSETTS",
        "group": "3336239",
        "group_num": [
            "rec5PA5Xztm7gWfe0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2239,
        "id": "recA7ShkEsPFEwAzi",
        "row": 13245,
        "state": "MASSACHUSETTS",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2373,
        "id": "recDFlexZr034Px4s",
        "row": 13229,
        "state": "MASSACHUSETTS",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2217,
        "id": "recFYL5s9ghHFZhcZ",
        "row": 13259,
        "state": "MASSACHUSETTS",
        "group": "3333421",
        "group_num": [
            "recy89hKLLuNykkMO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2239,
        "id": "recFQPI6yzpSksmUn",
        "row": 13256,
        "state": "MASSACHUSETTS",
        "group": "3331040",
        "group_num": [
            "recFFe9jPmAeq5lwG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1706,
        "id": "recHI9TwY61Oh0FQr",
        "row": 13228,
        "state": "MASSACHUSETTS",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2439,
        "id": "recIihni4pAxYKLsS",
        "row": 13263,
        "state": "MASSACHUSETTS",
        "group": "3338891",
        "group_num": [
            "recxvstyoGPTaOah1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2650,
        "id": "recJ8KmueUaK0kX7u",
        "row": 13246,
        "state": "MASSACHUSETTS",
        "group": "3210452",
        "group_num": [
            "recejQti8zWHY99Rd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2275,
        "id": "recNGE89F12upjjV0",
        "row": 13258,
        "state": "MASSACHUSETTS",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2366,
        "2023": 2366,
        "id": "reczTJknAvi4BUmVz",
        "row": 13270,
        "state": "MASSACHUSETTS",
        "group": "3344578",
        "group_num": [
            "recEaZgsHhuNPVd11"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2219,
        "id": "recEw69TMAYPhuNvM",
        "row": 13268,
        "state": "MASSACHUSETTS",
        "group": "3343353",
        "group_num": [
            "recEGMYWyHJstSaW5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2252,
        "id": "recJhxLC8TqpNOPnj",
        "row": 13269,
        "state": "MASSACHUSETTS",
        "group": "3343439",
        "group_num": [
            "recdY7bPDn3hylacF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 993,
        "id": "recgQ7YYPAn2Rl7ia",
        "row": 13338,
        "state": "MASSACHUSETTS",
        "group": "DAC198G1",
        "group_num": [
            "recLqYwY6M3ABJVKr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recvsnDjbIA5QJinW",
        "row": 13342,
        "state": "MASSACHUSETTS",
        "group": "EUSA0117",
        "group_num": [
            "recRXbyms1YaMhyev"
        ],
        "procedure": "Detox",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2196,
        "id": "recfdPU70inuKIke7",
        "row": 13514,
        "state": "MASSACHUSETTS",
        "group": "3331040",
        "group_num": [
            "recFFe9jPmAeq5lwG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recmTvXQTpqi8U5G7",
        "row": 13511,
        "state": "MASSACHUSETTS",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 811,
        "2023": 850,
        "id": "recmxw7uVPWfnAyhG",
        "row": 13502,
        "state": "MASSACHUSETTS",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 574,
        "id": "recQopQ5KmCCvYhMT",
        "row": 13516,
        "state": "MASSACHUSETTS",
        "group": "3331910",
        "group_num": [
            "rece21aw4R3lXguOZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2373,
        "id": "recVm5pVA7QXJazrh",
        "row": 13505,
        "state": "MASSACHUSETTS",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2208,
        "id": "recXxmDf9a19C6bhY",
        "row": 13510,
        "state": "MASSACHUSETTS",
        "group": "3319768",
        "group_num": [
            "recF43o14kVABoJ7T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2240,
        "id": "recXaDdWUXDfoxQBv",
        "row": 13509,
        "state": "MASSACHUSETTS",
        "group": "3213120",
        "group_num": [
            "rec2IHTUnV577EPim"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2267,
        "id": "recb0ymPk45zs5kFB",
        "row": 13515,
        "state": "MASSACHUSETTS",
        "group": "3331910",
        "group_num": [
            "rece21aw4R3lXguOZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2280,
        "id": "recdYmZlnWuyrQjRL",
        "row": 13503,
        "state": "MASSACHUSETTS",
        "group": "333837",
        "group_num": [
            "recqTNFQRNxRmGANV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1764,
        "id": "recdNwwMJgEvdXH8S",
        "row": 13508,
        "state": "MASSACHUSETTS",
        "group": "3210548",
        "group_num": [
            "rechu0wjl3Qo6ZQD8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2288,
        "id": "recwohC1e4RUhuhwy",
        "row": 13513,
        "state": "MASSACHUSETTS",
        "group": "3329926",
        "group_num": [
            "recCq7xmuUgRQAhlK"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2155,
        "id": "recCyzgxxcYmcC5L1",
        "row": 13504,
        "state": "MASSACHUSETTS",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2177,
        "id": "recD6lNoPaIbAM98y",
        "row": 13506,
        "state": "MASSACHUSETTS",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2298,
        "id": "recF0GHAlDastE0gg",
        "row": 13507,
        "state": "MASSACHUSETTS",
        "group": "3210452",
        "group_num": [
            "recejQti8zWHY99Rd"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2278,
        "id": "recGHGv5bytcYz5qc",
        "row": 13517,
        "state": "MASSACHUSETTS",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2168,
        "id": "recJfRmWpNuKhrd6R",
        "row": 13512,
        "state": "MASSACHUSETTS",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2310,
        "id": "recjzQwJWtLzdhT1j",
        "row": 13519,
        "state": "MASSACHUSETTS",
        "group": "3339129",
        "group_num": [
            "recOTzQLd5VriwjeX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2165,
        "id": "recnkxOQnntN1lITe",
        "row": 13518,
        "state": "MASSACHUSETTS",
        "group": "3333421",
        "group_num": [
            "recy89hKLLuNykkMO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2280,
        "id": "recTKdy1INqijxUxC",
        "row": 13522,
        "state": "MASSACHUSETTS",
        "group": "3343439",
        "group_num": [
            "recdY7bPDn3hylacF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2188,
        "id": "rec3k2xBgob0GCT2S",
        "row": 13521,
        "state": "MASSACHUSETTS",
        "group": "3343353",
        "group_num": [
            "recEGMYWyHJstSaW5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2252,
        "id": "rec4ltNbHqU9GkgsR",
        "row": 13525,
        "state": "MASSACHUSETTS",
        "group": "00616428",
        "group_num": [
            "recUgoGSqERH8SSbn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2183,
        "id": "rec7biopr7KxQqVRA",
        "row": 13526,
        "state": "MASSACHUSETTS",
        "group": "00637932",
        "group_num": [
            "rec1wSHvatg8KPPM5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2257,
        "2023": 2285,
        "id": "recaLkehIdQakTs10",
        "row": 13524,
        "state": "MASSACHUSETTS",
        "group": "00611370",
        "group_num": [
            "recJNKD1cUuzEWxmn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2351,
        "2023": 2028,
        "2024": 1760,
        "id": "recbPoEpuATaquUBp",
        "row": 13527,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2305,
        "2023": 2287,
        "id": "recqecdcbNDeEjyX6",
        "row": 13523,
        "state": "MASSACHUSETTS",
        "group": "3344578",
        "group_num": [
            "recEaZgsHhuNPVd11"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2218,
        "id": "recIe7YEE0ToqbCFO",
        "row": 13520,
        "state": "MASSACHUSETTS",
        "group": "3341102",
        "group_num": [
            "reci3ZhSztnj4pPhY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 993,
        "id": "recKUETu9qatuTRIY",
        "row": 13528,
        "state": "MASSACHUSETTS",
        "group": "DAC198G1",
        "group_num": [
            "recLqYwY6M3ABJVKr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA (CIGNA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1305,
        "id": "recfLDJ2KKj8oPUYg",
        "row": 13339,
        "state": "MASSACHUSETTS",
        "group": "DAC379K",
        "group_num": [
            "recUVnV0lzvhfrJ4T"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED ADMINISTRATION CORP (DIVERSIFIED ADMINISTRATION CORP)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1123,
        "id": "reczSjGFA5D73DxP0",
        "row": 13340,
        "state": "MASSACHUSETTS",
        "group": "DAC463G",
        "group_num": [
            "recPgwdRdkDoLCv6M"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED ADMINISTRATION CORP (DIVERSIFIED ADMINISTRATION CORP)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rect8Y1qTxZPysZCL",
        "row": 13168,
        "state": "MASSACHUSETTS",
        "group": "10118319",
        "group_num": [
            "recBaSYviwHBfR507"
        ],
        "procedure": "Detox",
        "provider": "GEISINGER (GEISINGER HEALTH PLAN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rechdbhBRiVEX6Nb5",
        "row": 13549,
        "state": "MASSACHUSETTS",
        "group": "10118319",
        "group_num": [
            "recBaSYviwHBfR507"
        ],
        "procedure": "RTC",
        "provider": "GEISINGER (GEISINGER HEALTH PLAN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "id": "recWIh2HnGbYhw6vg",
        "row": 13184,
        "state": "MASSACHUSETTS",
        "group": "1191140004",
        "group_num": [
            "recPHisHtnIt8qKUD"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND (HEALTH NEW ENGLAND)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "id": "reccfmxj170LEt5fv",
        "row": 13183,
        "state": "MASSACHUSETTS",
        "group": "1183610002",
        "group_num": [
            "recaZS7cntEUAfMJx"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND (HEALTH NEW ENGLAND)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 509,
        "id": "recpP3Z7vqtn22iu4",
        "row": 13369,
        "state": "MASSACHUSETTS",
        "group": "S030530009",
        "group_num": [
            "recBIFavKVqcqz85D"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND (HEALTH NEW ENGLAND)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1290,
        "id": "recoASjgyAJUafxU1",
        "row": 13325,
        "state": "MASSACHUSETTS",
        "group": "BA2",
        "group_num": [
            "recqYgVLzvqBAwMkW"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLAN INC (HEALTH PLANS INC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recrFyooevaD1pFdb",
        "row": 13320,
        "state": "MASSACHUSETTS",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MEDICA (MEDICA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recG9zJTG1nrdQzeb",
        "row": 13573,
        "state": "MASSACHUSETTS",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "RTC",
        "provider": "MEDICA (MEDICA)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "reckUghEfCaF0kLLP",
        "row": 13205,
        "state": "MASSACHUSETTS",
        "group": "18263",
        "group_num": [
            "recPoweq8hMcbkUNR"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3276,
        "id": "recnFYwTWIuqPBkXt",
        "row": 13204,
        "state": "MASSACHUSETTS",
        "group": "18219",
        "group_num": [
            "recQDLaWIrl8WADBp"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2808,
        "id": "recUnPALxHj4REOPC",
        "row": 13195,
        "state": "MASSACHUSETTS",
        "group": "14526",
        "group_num": [
            "recqdx8BAslpClkfU"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3330,
        "2023": 3885,
        "id": "rec0cd4iAjuGp3D4o",
        "row": 13202,
        "state": "MASSACHUSETTS",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recGmHHTjS6M8HNQi",
        "row": 13196,
        "state": "MASSACHUSETTS",
        "group": "16009",
        "group_num": [
            "recZBmDPWpNaHVFyZ"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "recNyygk8Gt90DZu4",
        "row": 13322,
        "state": "MASSACHUSETTS",
        "group": "AF016",
        "group_num": [
            "rec0J8CfGND3HVJg2"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "2023": 3176,
        "id": "recR5XdwnGNNHSUpF",
        "row": 13576,
        "state": "MASSACHUSETTS",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "id": "recoKxK7ocilcCHeb",
        "row": 13578,
        "state": "MASSACHUSETTS",
        "group": "AF016",
        "group_num": [
            "rec0J8CfGND3HVJg2"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2593,
        "2024": 2593,
        "id": "recz8PdOxINk80kZW",
        "row": 13574,
        "state": "MASSACHUSETTS",
        "group": "14526",
        "group_num": [
            "recqdx8BAslpClkfU"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2628,
        "id": "recCcFO9mV28Nv7wH",
        "row": 13577,
        "state": "MASSACHUSETTS",
        "group": "18219",
        "group_num": [
            "recQDLaWIrl8WADBp"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3242,
        "id": "recGWJAw52yfNsglX",
        "row": 13575,
        "state": "MASSACHUSETTS",
        "group": "16009",
        "group_num": [
            "recZBmDPWpNaHVFyZ"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN HEALTH (MERITAIN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "recaZfAn5jJAq0xdA",
        "row": 13357,
        "state": "MASSACHUSETTS",
        "group": "N68700",
        "group_num": [
            "rec1TUrbePJ4KgsJi"
        ],
        "procedure": "Detox",
        "provider": "NIPPON LIVE BENEFITS (NIPPON LIFE INSURANCE COMPANY)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 884,
        "id": "reclxwBd95zyk8meL",
        "row": 13194,
        "state": "MASSACHUSETTS",
        "group": "1419340",
        "group_num": [
            "recUcgkXFTqcwFyci"
        ],
        "procedure": "Detox",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 776,
        "id": "rec3u3lrUBVkoM3pi",
        "row": 13190,
        "state": "MASSACHUSETTS",
        "group": "1405029",
        "group_num": [
            "rec3FWozqgF75MmL9"
        ],
        "procedure": "Detox",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2123,
        "id": "recHq62tZ2m7vxXBi",
        "row": 13579,
        "state": "MASSACHUSETTS",
        "group": "1405029",
        "group_num": [
            "rec3FWozqgF75MmL9"
        ],
        "procedure": "RTC",
        "provider": "OXFORD (OXFORD)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 510,
        "id": "recWxhW5gaLCuPUvq",
        "row": 13057,
        "state": "MASSACHUSETTS",
        "group": "00531868",
        "group_num": [
            "reculCfJQUH6uVQso"
        ],
        "procedure": "Detox",
        "provider": "QUEST BEHAVIORAL HEALTH (QUEST  BEHAVIORAL HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 372,
        "id": "rec6Hr5GQND3EdkSg",
        "row": 13581,
        "state": "MASSACHUSETTS",
        "group": "00531868",
        "group_num": [
            "reculCfJQUH6uVQso"
        ],
        "procedure": "PHP",
        "provider": "QUEST BEHAVIORAL HEALTH (QUEST  BEHAVIORAL HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 578,
        "id": "recDt0ZnJpt09ZwQG",
        "row": 13580,
        "state": "MASSACHUSETTS",
        "group": "00531868",
        "group_num": [
            "reculCfJQUH6uVQso"
        ],
        "procedure": "RTC",
        "provider": "QUEST BEHAVIORAL HEALTH (QUEST  BEHAVIORAL HEALTH)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "recL12bd2X4pMcAyT",
        "row": 13033,
        "state": "MASSACHUSETTS",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN (SANFORD HEALTH PLAN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "recQaBLpgGIDUKY3p",
        "row": 13582,
        "state": "MASSACHUSETTS",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "RTC",
        "provider": "SANFORD HEALTH PLAN (SANFORD HEALTH PLAN)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recSb1KVLGLTnAfaf",
        "row": 13298,
        "state": "MASSACHUSETTS",
        "group": "76411550",
        "group_num": [
            "recAyiyyXinsGHbPL"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2657,
        "id": "recTOdxqdrlqcZv7l",
        "row": 13301,
        "state": "MASSACHUSETTS",
        "group": "76414583",
        "group_num": [
            "rec1A9UwuRzH1zXX6"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3008,
        "id": "rec40gRu9MGVV98ah",
        "row": 13300,
        "state": "MASSACHUSETTS",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "rec6pZJgsmua416kS",
        "row": 13299,
        "state": "MASSACHUSETTS",
        "group": "76413268",
        "group_num": [
            "recD3S0t8eeHz9U2y"
        ],
        "procedure": "Detox",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3325,
        "id": "recUsIbC0fe4wqSis",
        "row": 13584,
        "state": "MASSACHUSETTS",
        "group": "76414583",
        "group_num": [
            "rec1A9UwuRzH1zXX6"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "reczczljGgEqTBsjc",
        "row": 13583,
        "state": "MASSACHUSETTS",
        "group": "76413268",
        "group_num": [
            "recD3S0t8eeHz9U2y"
        ],
        "procedure": "RTC",
        "provider": "UMR (UMR)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recaqJoLcOwOxflS8",
        "row": 13189,
        "state": "MASSACHUSETTS",
        "group": "131192M038",
        "group_num": [
            "recIDDzb9GUjEsBWz"
        ],
        "procedure": "Detox",
        "provider": "UNICARE (UNICARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1067,
        "2023": 1067,
        "id": "recdGbMLI00uCSV57",
        "row": 13188,
        "state": "MASSACHUSETTS",
        "group": "131192M025",
        "group_num": [
            "recW8cDbkr0EjtwUh"
        ],
        "procedure": "Detox",
        "provider": "UNICARE (UNICARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1067,
        "id": "recZwbVq3Zo8L8Py5",
        "row": 13585,
        "state": "MASSACHUSETTS",
        "group": "131192M025",
        "group_num": [
            "recW8cDbkr0EjtwUh"
        ],
        "procedure": "RTC",
        "provider": "UNICARE (UNICARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2577,
        "2023": 2516,
        "id": "recvUf8YauHJhjLpc",
        "row": 13024,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1483,
        "id": "recgimbiyRoPsj87b",
        "row": 13181,
        "state": "MASSACHUSETTS",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "Detox",
        "provider": "CONNECTICARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "reclgBPnCLUxTCJS8",
        "row": 13179,
        "state": "MASSACHUSETTS",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "recUxoLAfwtWoeD57",
        "row": 13217,
        "state": "MASSACHUSETTS",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4020,
        "id": "recYULEiAQfJdvzTg",
        "row": 13173,
        "state": "MASSACHUSETTS",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec5mSdrGRyBgZDPB",
        "row": 13180,
        "state": "MASSACHUSETTS",
        "group": "1102837CONNECTICARE2440329OPTUM",
        "group_num": [
            "rec1yblQQfelQtYUr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recbPhpBIzBpjIpOF",
        "row": 13182,
        "state": "MASSACHUSETTS",
        "group": "1150079",
        "group_num": [
            "recRKffjH29Wni8DY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2217,
        "id": "recoeNHdCXDSIKNlL",
        "row": 13203,
        "state": "MASSACHUSETTS",
        "group": "182019",
        "group_num": [
            "recF9SecLWXiZkNbZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "recDGuQUNcnDMK86H",
        "row": 13216,
        "state": "MASSACHUSETTS",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1998,
        "id": "recD2Pa1fRkgvxv3D",
        "row": 13207,
        "state": "MASSACHUSETTS",
        "group": "192988",
        "group_num": [
            "recUKj31TUzBcXRWq"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recO7Z5WvdMU5QIU0",
        "row": 13224,
        "state": "MASSACHUSETTS",
        "group": "2437522",
        "group_num": [
            "rec9il0JYk2hEolPW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 927,
        "id": "rec00w0G5zdZwh69F",
        "row": 13237,
        "state": "MASSACHUSETTS",
        "group": "2K3784",
        "group_num": [
            "recrMqlpY1L4Lu9Ji"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "recrOcJznNn5RHcJv",
        "row": 13220,
        "state": "MASSACHUSETTS",
        "group": "2314817",
        "group_num": [
            "rec63f7FwR4vbFxNf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2260,
        "2024": 2228,
        "id": "recrbYN3jc2z03D23",
        "row": 13218,
        "state": "MASSACHUSETTS",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4430,
        "id": "recxfpWlqJ9Tt0Pep",
        "row": 13260,
        "state": "MASSACHUSETTS",
        "group": "3335320",
        "group_num": [
            "rec18VSD7okHRdJe3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recEBMEaBGDdudLt3",
        "row": 13223,
        "state": "MASSACHUSETTS",
        "group": "2437320",
        "group_num": [
            "rec3fGnCHfl2UaTVy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2820,
        "id": "recLzXeXC7Mt2aHrs",
        "row": 13243,
        "state": "MASSACHUSETTS",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 994,
        "id": "recfTnapeegvS5MxF",
        "row": 13280,
        "state": "MASSACHUSETTS",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "rechvOjuOJYwoG4Yv",
        "row": 13286,
        "state": "MASSACHUSETTS",
        "group": "717257",
        "group_num": [
            "recdNIuxVKDK6fnBL"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 927,
        "id": "reciLlg6EM7mF2ujb",
        "row": 13315,
        "state": "MASSACHUSETTS",
        "group": "921378",
        "group_num": [
            "recjClfU9ElbzD0bB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4551,
        "id": "recjcHQB021E5Yg6h",
        "row": 13297,
        "state": "MASSACHUSETTS",
        "group": "752302",
        "group_num": [
            "rec9QGgHKio6uyaqZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 365,
        "id": "recmc3G3sd1crh3f5",
        "row": 13296,
        "state": "MASSACHUSETTS",
        "group": "744274",
        "group_num": [
            "recVu937iapmeWGLB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 994,
        "id": "recnUwKYNGZmFJnro",
        "row": 13307,
        "state": "MASSACHUSETTS",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1020,
        "id": "recSQ3eJ2z29DFGdi",
        "row": 13317,
        "state": "MASSACHUSETTS",
        "group": "929225",
        "group_num": [
            "recNKOP83DVGKL4KV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 972,
        "id": "recVCUGvzGus6YbpO",
        "row": 13314,
        "state": "MASSACHUSETTS",
        "group": "915574",
        "group_num": [
            "recJTKNMbxLVNrFLk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2533,
        "2023": 904,
        "id": "recY7bzpsNNLkQkAq",
        "row": 13309,
        "state": "MASSACHUSETTS",
        "group": "909481",
        "group_num": [
            "recReX4cwwvYRgncf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3720,
        "id": "rec3oG6BY7OqeoJec",
        "row": 13302,
        "state": "MASSACHUSETTS",
        "group": "78800001",
        "group_num": [
            "recE52xTrhoPcdIuN"
        ],
        "procedure": "Detox",
        "provider": "HARVARD PILGRIM (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3745,
        "id": "rec7jnLOojMYIQLFU",
        "row": 13312,
        "state": "MASSACHUSETTS",
        "group": "914680",
        "group_num": [
            "recsXyoBjT9G19Ekv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2227,
        "id": "recbVsqe5fiDI8vSY",
        "row": 13295,
        "state": "MASSACHUSETTS",
        "group": "742026",
        "group_num": [
            "recY4idpL3VQxfoQg"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 639,
        "2023": 639,
        "id": "reccpQ4QM0uVfjqnw",
        "row": 13308,
        "state": "MASSACHUSETTS",
        "group": "909268",
        "group_num": [
            "rec27TYtQKQW15RmY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1096,
        "id": "recppPvDlJcnqngQD",
        "row": 13311,
        "state": "MASSACHUSETTS",
        "group": "911528",
        "group_num": [
            "reck7pmbAMZzGATwI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recse5s0kWQTrwrfh",
        "row": 13284,
        "state": "MASSACHUSETTS",
        "group": "715632",
        "group_num": [
            "recMV0eWNZ9SL9s4Z"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4629,
        "id": "recwWDu1EnkvMYZ6k",
        "row": 13281,
        "state": "MASSACHUSETTS",
        "group": "705362",
        "group_num": [
            "recxpjk3pM0tUpaak"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "recyoa0GTNVoOuGyb",
        "row": 13282,
        "state": "MASSACHUSETTS",
        "group": "706254",
        "group_num": [
            "recr7C8GZ3HbxaTF2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1807,
        "id": "reczkd3phWg4xaBTJ",
        "row": 13283,
        "state": "MASSACHUSETTS",
        "group": "709876",
        "group_num": [
            "recl41ddjMpWQJCUL"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2230,
        "id": "recA9kwtrQ1Q7E3MB",
        "row": 13316,
        "state": "MASSACHUSETTS",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "id": "recAnhXbvxzFlXyRn",
        "row": 13274,
        "state": "MASSACHUSETTS",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 972,
        "id": "recHcoaUKnguGPym4",
        "row": 13310,
        "state": "MASSACHUSETTS",
        "group": "911335",
        "group_num": [
            "rec2ho0CU0wXGgwNp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1654,
        "id": "recIy9tKT3jYNsGjb",
        "row": 13313,
        "state": "MASSACHUSETTS",
        "group": "914756",
        "group_num": [
            "rectbavPNjZQn0meZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 927,
        "id": "recIJZCb0QfpHPPBv",
        "row": 13273,
        "state": "MASSACHUSETTS",
        "group": "3Q3234",
        "group_num": [
            "recyly1fPk9kZlUmk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2781,
        "id": "recI614zQtW9U2muY",
        "row": 13294,
        "state": "MASSACHUSETTS",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rechV047U3l9wJlxV",
        "row": 13328,
        "state": "MASSACHUSETTS",
        "group": "CC000007",
        "group_num": [
            "recpqLgYHMcRfYBFE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recPxM3DUPK7wzLvb",
        "row": 13327,
        "state": "MASSACHUSETTS",
        "group": "CC000006",
        "group_num": [
            "rectnIl5hjy3q4juJ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1275,
        "id": "recV3ksbctSQxYddh",
        "row": 13330,
        "state": "MASSACHUSETTS",
        "group": "CC000220",
        "group_num": [
            "recswTwJD8LNqZzY9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1473,
        "id": "recdvnnw3d46tnj2h",
        "row": 13347,
        "state": "MASSACHUSETTS",
        "group": "HP105192",
        "group_num": [
            "recsw1JTptqOLWiOL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recISsIB4lCJPjIrI",
        "row": 13346,
        "state": "MASSACHUSETTS",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "Detox",
        "provider": "HARVARD PILGRIM (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 1287,
        "2024": 1483,
        "id": "recKuW8JcqPFGFpL6",
        "row": 13329,
        "state": "MASSACHUSETTS",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1710,
        "id": "recgohgx1tMBTHpRK",
        "row": 13550,
        "state": "MASSACHUSETTS",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "RTC",
        "provider": "HARVARD PILGRIM (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "2024": 960,
        "id": "recxjwxTvYXn51zj9",
        "row": 13529,
        "state": "MASSACHUSETTS",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "RTC",
        "provider": "CONNECTICARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recfxF9K3YWUIrEi4",
        "row": 13594,
        "state": "MASSACHUSETTS",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recflDbYhUEbs8sqd",
        "row": 13587,
        "state": "MASSACHUSETTS",
        "group": "3Q3234",
        "group_num": [
            "recyly1fPk9kZlUmk"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2138,
        "id": "recgsZteq9DJxwTig",
        "row": 13596,
        "state": "MASSACHUSETTS",
        "group": "709876",
        "group_num": [
            "recl41ddjMpWQJCUL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "reclkqr6xRgiIQupr",
        "row": 13586,
        "state": "MASSACHUSETTS",
        "group": "2K3784",
        "group_num": [
            "recrMqlpY1L4Lu9Ji"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 1104,
        "id": "recnwFo2rmbWCqcDB",
        "row": 13607,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1916,
        "id": "recn3QUxON7XH3hAK",
        "row": 13600,
        "state": "MASSACHUSETTS",
        "group": "752302",
        "group_num": [
            "rec9QGgHKio6uyaqZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 660,
        "id": "recndp9I4rIkUoFOv",
        "row": 13595,
        "state": "MASSACHUSETTS",
        "group": "706254",
        "group_num": [
            "recr7C8GZ3HbxaTF2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recQ7iKGnVCqrhNA7",
        "row": 13603,
        "state": "MASSACHUSETTS",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recScYBI1XhN0EOWQ",
        "row": 13606,
        "state": "MASSACHUSETTS",
        "group": "2437320",
        "group_num": [
            "rec3fGnCHfl2UaTVy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2202,
        "2024": 960,
        "id": "recUHhc8fZKJgJeMY",
        "row": 13609,
        "state": "MASSACHUSETTS",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2376,
        "id": "recWSWBlfglR0aPl1",
        "row": 13602,
        "state": "MASSACHUSETTS",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 660,
        "id": "rec28KPptxLborfl5",
        "row": 13591,
        "state": "MASSACHUSETTS",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "rec4ep2aqUfAtfOD9",
        "row": 13601,
        "state": "MASSACHUSETTS",
        "group": "915574",
        "group_num": [
            "recJTKNMbxLVNrFLk"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2013,
        "id": "recaYH2hYbMmSH2UJ",
        "row": 13590,
        "state": "MASSACHUSETTS",
        "group": "192988",
        "group_num": [
            "recUKj31TUzBcXRWq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recsUuGPYB7eZnud6",
        "row": 13608,
        "state": "MASSACHUSETTS",
        "group": "CC000006",
        "group_num": [
            "rectnIl5hjy3q4juJ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1710,
        "id": "rectp4q4QE9r2p2yb",
        "row": 13605,
        "state": "MASSACHUSETTS",
        "group": "2314817",
        "group_num": [
            "rec63f7FwR4vbFxNf"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 660,
        "id": "recvXe2zRSIgGXksw",
        "row": 13597,
        "state": "MASSACHUSETTS",
        "group": "717257",
        "group_num": [
            "recdNIuxVKDK6fnBL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2346,
        "2024": 2423,
        "id": "recAvTvhGFUZnmIQf",
        "row": 13592,
        "state": "MASSACHUSETTS",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recEmnj1fkkfoMPkb",
        "row": 13604,
        "state": "MASSACHUSETTS",
        "group": "1150079",
        "group_num": [
            "recRKffjH29Wni8DY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1520,
        "id": "recFUJFM67XIGjBUm",
        "row": 13589,
        "state": "MASSACHUSETTS",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2207,
        "id": "recKZRB7mjyNiIfrl",
        "row": 13599,
        "state": "MASSACHUSETTS",
        "group": "742026",
        "group_num": [
            "recY4idpL3VQxfoQg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "id": "recLW6Twi6iHtDjUZ",
        "row": 13588,
        "state": "MASSACHUSETTS",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1800,
        "id": "recMWBXQglWYqcdNS",
        "row": 13598,
        "state": "MASSACHUSETTS",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2233,
        "id": "recNaKZ29SjrktA2s",
        "row": 13593,
        "state": "MASSACHUSETTS",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE (UNITED HEALTH CARE)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5400,
        "id": "recVdREEZ1RCarc1U",
        "row": 13023,
        "state": "MASSACHUSETTS",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "GEHA (UNITED HEALTH CARE SHARED SERVICES)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2173,
        "id": "reciDahsjAg87Md8V",
        "row": 13105,
        "state": "MASSACHUSETTS",
        "group": "018084300",
        "group_num": [
            "reckt8RfrqFEt4Aqm"
        ],
        "procedure": "Detox",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2188,
        "id": "recvRSrf6ftqZbwzp",
        "row": 13610,
        "state": "MASSACHUSETTS",
        "group": "018084300",
        "group_num": [
            "reckt8RfrqFEt4Aqm"
        ],
        "procedure": "RTC",
        "provider": "UPMC (UPMC)"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2841,
        "id": "recLpE5jgLjxrIDO3",
        "row": 13275,
        "state": "MASSACHUSETTS",
        "group": "503157",
        "group_num": [
            "recWUcCSpBE962ilh"
        ],
        "procedure": "RTC",
        "provider": "WELLNET (WELLNET)"
    }
]