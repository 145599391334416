import { Typography } from "@mui/material";

function NotEnoughPermissoin() {
  return (
    <div className="test">
      <Typography variant="h4" color="#052442" mt={3} mb={3}>
        You have no enough permission to visit this page.
      </Typography>
    </div>
  );
}

export default NotEnoughPermissoin;
