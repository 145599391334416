// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updatePassword
} from "firebase/auth";
import { getFirestore, getDoc, doc, setDoc } from "firebase/firestore";
import { setAccessToken, setUserId } from "../utils/cookie";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB5T_Zzj3xOnXszAa8QYZCmfsiMOAtjsYM",
  authDomain: "nick-erp.firebaseapp.com",
  projectId: "nick-erp",
  storageBucket: "nick-erp.appspot.com",
  messagingSenderId: "185219891960",
  appId: "1:185219891960:web:29bb377ebc9ebaa907310d",
  measurementId: "G-845S8MJGVJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userInfo = await signInWithEmailAndPassword(auth, email, password);
      const token = await auth.currentUser.getIdToken();

      const docRef = doc(db, "users", userInfo.user.uid);
      const userSnapshot = await getDoc(docRef);
      const userData = userSnapshot.data();

      setAccessToken(token);
      setUserId(userInfo.user.uid);
      console.log("userinfo", userInfo)
      if (userData) {
        resolve({
          success: true,
          user: userData,
        });
      } else {

        resolve({
          success: false,
          err: { message: "User does not exist." },
        });
      }
    } catch (err) {
      console.log("error", err)
      resolve({
        success: false,
        code: err?.code,
        err,
      });
    }
  });
};

const registerWithEmailAndPassword = async (
  firstName,
  lastName,
  email,
  password,
  role,
  phone
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      const docRef = doc(db, "users", user.uid);
      await setDoc(docRef, {
        firstName,
        lastName,
        email,
        role,
        phone,
        id: user.uid,
        timezone: Intl.DateTimeFormat().resolvedOptions(),
      });
      const token = await auth.currentUser.getIdToken();

      const currentUserRef = doc(db, "users", user.uid);
      const currentUserSnapshot = await getDoc(currentUserRef);
      const userData = currentUserSnapshot.data();

      setAccessToken(token);
      setUserId(user.uid);

      resolve({
        success: true,
        user: userData,
      });
    } catch (err) {
      resolve({
        success: false,
        code: err?.code,
        err,
      });
    }
  });
};

const getCurrentUserInfo = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentUserRef = doc(db, "users", userId);
      const currentUserSnapshot = await getDoc(currentUserRef);
      const userData = currentUserSnapshot.data();

      resolve({
        success: true,
        user: userData,
      });
    } catch (err) {
      resolve({
        success: false,
        code: err?.code,
        err,
      });
    }
  });
};

const updateUserPassword = async (password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = auth.currentUser;

      await updatePassword(currentUser, password);

      resolve({
        success: true,
        user: currentUser,
      });
    } catch (err) {
      resolve({
        success: false,
        code: err?.code,
        err,
      });
    }
  });
}

const gerOrganizationByID = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentOrgRef = doc(db, "organizations", id);
      const currentOrgSnapshot = await getDoc(currentOrgRef);
      const orgData = currentOrgSnapshot.data();

      resolve({
        success: true,
        organization: orgData,
      });
    } catch (err) {
      resolve({
        success: false,
        code: err?.code,
        err,
      });
    }
  });
};

export {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  getCurrentUserInfo,
  updateUserPassword,
  gerOrganizationByID
};
