import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, selectIsAuthLoading, selectUser } from "../../store/auth";
import { Backdrop, CircularProgress } from "@mui/material";
import { getAccessToken } from "../../utils/cookie";

const RequireAuth = () => {
  const token = getAccessToken();

  const dispatch = useDispatch();
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const user = useSelector(selectUser);
  const location = useLocation();

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
    }
  }, []);

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  if (isAuthLoading || !user) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return <Outlet />;
};

export default RequireAuth;
