import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function Pending() {
  return (
    <Box sx={{ height: 900, width: "100%" }}>
      <Typography variant="h4">
        The admin will invite you to an organization and send an email. Please
        wait.
      </Typography>
    </Box>
  );
}
