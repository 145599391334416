import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { getOrganizations, removeOrganization } from "../../store/admin";
import AddOrgModal from "../../components/AddOrgModal";
import ConfirmModal from "../../components/ConfirmModal";

export default function AdminOrgs() {
  const dispatch = useDispatch();
  const adminStore = useSelector((store) => store.admin);
  const [addOrgOpen, setAddOrgOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [removingOrg, setRemovingOrg] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editOrgInfo, setEditOrgInfo] = useState({
    companyName: "",
    primaryUser: {
      firstName: "",
      lastName: "",
      email: "",
    },
    state: [],
    dataset: [],
    notes: "",
  });

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  const handleAddOrg = () => {
    setEditOrgInfo({
      companyName: "",
      primaryUser: {
        firstName: "",
        lastName: "",
        email: "",
      },
      state: [],
      dataset: [],
      notes: "",
    });
    setCreateModalOpen(true);
    setAddOrgOpen(true);
  };
  const handleAddOrgModalClose = () => {
    setAddOrgOpen(false);
  };

  const handleRemoveOrg = () => {
    dispatch(
      removeOrganization({
        id: removingOrg.id,
      })
    );
    handleConfirmModalClose();
  };

  const handleConfirmModal = (org) => {
    setRemovingOrg(org);
    setConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const handleEditOrg = (org) => {
    setEditOrgInfo(org);
    setCreateModalOpen(false);
    setAddOrgOpen(true);
  };

  const handleEditModalInputChange = (e, type) => {
    const {
      target: { value },
    } = e;

    const customOrg = {
      ...editOrgInfo,
    };

    if (type === "state") {
      customOrg.state = typeof value === "string" ? value.split(",") : value;
    } else if (type === "dataset") {
      customOrg.dataset = typeof value === "string" ? value.split(",") : value;
    } else if (type === "primary") {
      customOrg.primaryUser[e.target.name] = value;
    } else {
      customOrg[e.target.name] = value;
    }
    setEditOrgInfo(customOrg);
  };

  useEffect(() => {
    if (!adminStore.isLoading && addOrgOpen && !adminStore?.error) {
      setAddOrgOpen(false);
    }
  }, [adminStore]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        alignContent="center"
        justifyContent="space-between"
        sx={{ marginBottom: "20px" }}
      >
        <Typography variant="h4">
          {adminStore.organizations.length > 0
            ? "Organizations"
            : "No Organizations"}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleAddOrg()}
        >
          Add
        </Button>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={adminStore.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {adminStore.organizations.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Primary User</TableCell>
                <TableCell></TableCell>
                <TableCell>Available States</TableCell>
                <TableCell>Data Classes</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.organizations.map((org, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {org.companyName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${org.primaryUser.firstName} ${org.primaryUser.lastName}`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {org.primaryUser.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {org.state.map((state, index) => (
                      <Chip
                        label={state}
                        key={index}
                        sx={{ marginRight: "5px" }}
                      />
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {org.dataset?.map((d, index) => (
                      <Chip
                        label={d}
                        key={index}
                        sx={{ marginRight: "5px" }}
                      />
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {org.notes}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditOrg({ ...org, dataset: org.dataset || [] })}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleConfirmModal(org)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AddOrgModal
        isOpen={addOrgOpen}
        handleClose={handleAddOrgModalClose}
        data={editOrgInfo}
        handleDataChange={handleEditModalInputChange}
        createModal={createModalOpen}
      />
      <ConfirmModal
        open={confirmModalOpen}
        onModalClose={handleConfirmModalClose}
        onModalConfirm={handleRemoveOrg}
        askingContent={`Remove ${removingOrg?.companyName}?`}
        isLoading={adminStore.isLoading}
      />
    </Box>
  );
}
