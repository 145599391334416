export const extended = [
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 1178,
        "2024": 0,
        "id": "recV4IkbSaeTW5EV4",
        "row": 20257,
        "state": "",
        "group": "",
        "group_num": [
            "recdtzi7zv7tbRTxz"
        ],
        "procedure": "Detox",
        "provider": "6 DEGREES HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 295,
        "id": "recjdlaMh0EVknKzW",
        "row": 20259,
        "state": "",
        "group": "",
        "group_num": [
            "reckDf8R2uJomibZ7"
        ],
        "procedure": "PHP",
        "provider": "90 DEGREE BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 361,
        "id": "rec0MvhMtfpm6u8EI",
        "row": 20260,
        "state": "",
        "group": "",
        "group_num": [
            "reckDf8R2uJomibZ7"
        ],
        "procedure": "IOP",
        "provider": "90 DEGREE BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1080,
        "id": "rec98gUFADLyqcvTu",
        "row": 20258,
        "state": "",
        "group": "",
        "group_num": [
            "rec17FsGqWKawyZYV"
        ],
        "procedure": "RTC",
        "provider": "90 DEGREE BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recOf7b5Ury4UODhW",
        "row": 20261,
        "state": "",
        "group": "",
        "group_num": [
            "recFefxRr163bI0AH"
        ],
        "procedure": "RTC",
        "provider": "1199 NATIONAL BENEFIT FUND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 500,
        "id": "recdz2V8i19ZAeNQ5",
        "row": 20263,
        "state": "",
        "group": "G0185106",
        "group_num": [
            "recfKP8K82C1dhmgq"
        ],
        "procedure": "IOP",
        "provider": "ACTIVE CARE MANAGEMENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 57,
        "id": "rect2KIVUieCLH6v7",
        "row": 20264,
        "state": "",
        "group": "G0185106",
        "group_num": [
            "recfKP8K82C1dhmgq"
        ],
        "procedure": "Detox",
        "provider": "ACTIVE CARE MANAGEMENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recEq8kBpBxYokf64",
        "row": 20262,
        "state": "",
        "group": "G0185106",
        "group_num": [
            "recfKP8K82C1dhmgq"
        ],
        "procedure": "RTC",
        "provider": "ACTIVE CARE MANAGEMENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1380,
        "id": "recd1T4gjGnz2P8Ag",
        "row": 20265,
        "state": "",
        "group": "",
        "group_num": [
            "rec57UmTXPjBTFfQH"
        ],
        "procedure": "Detox",
        "provider": "ADVENTIST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 419,
        "id": "recewA8ETXXNTRKlO",
        "row": 20300,
        "state": "",
        "group": "",
        "group_num": [
            "recLYooDkkw5asMwI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "rece3fJZoU1AVdj7T",
        "row": 20301,
        "state": "",
        "group": "",
        "group_num": [
            "recFKeqBNnSpcewXH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rece4WF9YZ5a7HzWR",
        "row": 20271,
        "state": "",
        "group": "",
        "group_num": [
            "recUUqagCTR6cCUch"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 292,
        "id": "recfXxc9OQQvPesg8",
        "row": 20286,
        "state": "",
        "group": "",
        "group_num": [
            "recN77Kxe8wHAoTtV"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "id": "recg877lw1wXAGKjI",
        "row": 20281,
        "state": "",
        "group": "418470272",
        "group_num": [
            "recz5aHckRN1UAw60"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "id": "reciywAdxaAa82n6s",
        "row": 20291,
        "state": "",
        "group": "",
        "group_num": [
            "recQoXbEKCKg5zUUE"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "2023": 758,
        "id": "recihnlbFyrK6BEGf",
        "row": 20277,
        "state": "",
        "group": "",
        "group_num": [
            "recZeFGC0C5c6Dwbe"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1060,
        "2023": 655,
        "id": "reckjSOu3uF4jfiiL",
        "row": 20270,
        "state": "",
        "group": "0175436",
        "group_num": [
            "recJ20xtz3RUfxyFb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1610,
        "id": "reclRDe4eNNhZkjsh",
        "row": 20306,
        "state": "",
        "group": "",
        "group_num": [
            "recAHKbhnRQqYr8GY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recnOKv4x65LjOnBw",
        "row": 20284,
        "state": "",
        "group": "",
        "group_num": [
            "recp21FGXz2iYIUN0"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 164,
        "id": "recOUJYTvZY1sQqmr",
        "row": 20282,
        "state": "",
        "group": "418470272",
        "group_num": [
            "recz5aHckRN1UAw60"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1434,
        "id": "recOdoS40P2zGnXX2",
        "row": 20267,
        "state": "",
        "group": "",
        "group_num": [
            "recsRfhKUdllxuTG9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 489,
        "id": "recQZRI04N6bkwS99",
        "row": 20288,
        "state": "",
        "group": "",
        "group_num": [
            "recu3JuzOoG7KcdRY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recQQtCDyDENV6YoI",
        "row": 20294,
        "state": "",
        "group": "",
        "group_num": [
            "rec5qaSs8nfqJXIGo"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1515,
        "id": "recR4LAZOL1P4ws0z",
        "row": 20293,
        "state": "",
        "group": "",
        "group_num": [
            "reczZecw8fkzs4l0n"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1220,
        "id": "recTz4yp7oQFkkfkX",
        "row": 20274,
        "state": "",
        "group": "",
        "group_num": [
            "recvW7knddaU5gB4u"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recWtR2zxssbrqbKA",
        "row": 20298,
        "state": "",
        "group": "",
        "group_num": [
            "recLYooDkkw5asMwI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1119,
        "id": "recXS9bvXAfgcKVCa",
        "row": 20305,
        "state": "",
        "group": "",
        "group_num": [
            "recsMd23wt8UzBHCz"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 933,
        "id": "recXadeFhh8fqGgbI",
        "row": 20289,
        "state": "",
        "group": "",
        "group_num": [
            "recu3JuzOoG7KcdRY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 374,
        "2023": 454,
        "id": "recXjc0DRsdkYHLid",
        "row": 20269,
        "state": "",
        "group": "",
        "group_num": [
            "rec3E1m63Vh1oXfzl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 715,
        "id": "rec1TchKZ4I3sXh7e",
        "row": 20296,
        "state": "",
        "group": "",
        "group_num": [
            "rec5qaSs8nfqJXIGo"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "2023": 168,
        "id": "rec19k8YywIrrz7JE",
        "row": 20268,
        "state": "",
        "group": "",
        "group_num": [
            "rec3E1m63Vh1oXfzl"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1226,
        "id": "rec6WwoguFDFxzAFe",
        "row": 20283,
        "state": "",
        "group": "",
        "group_num": [
            "rec7hExTFUtjgpojj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1729,
        "2023": 1612,
        "id": "rec83avAEXr8fu5TB",
        "row": 20302,
        "state": "",
        "group": "",
        "group_num": [
            "recsMd23wt8UzBHCz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1305,
        "2023": 587,
        "id": "recamZubOq272Sfbg",
        "row": 20290,
        "state": "",
        "group": "",
        "group_num": [
            "recQoXbEKCKg5zUUE"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "id": "reccxWChLzZFkpA2C",
        "row": 20272,
        "state": "",
        "group": "",
        "group_num": [
            "recUUqagCTR6cCUch"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "2024": 925,
        "id": "recdLjXW1ZVWB5pEB",
        "row": 20280,
        "state": "",
        "group": "",
        "group_num": [
            "recc5SZCMtsLLPAaZ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1778,
        "id": "recdmogpqdNvUqYoq",
        "row": 20266,
        "state": "",
        "group": "",
        "group_num": [
            "recsRfhKUdllxuTG9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 966,
        "id": "recp1RsbEjvsdSBE9",
        "row": 20299,
        "state": "",
        "group": "",
        "group_num": [
            "recLYooDkkw5asMwI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1110,
        "id": "recqFuNauD8iEthPV",
        "row": 20297,
        "state": "",
        "group": "",
        "group_num": [
            "recW5oLV9pRsBjX6R"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "recrXwYdj4GU2EDG4",
        "row": 20304,
        "state": "",
        "group": "",
        "group_num": [
            "recsMd23wt8UzBHCz"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "2023": 985,
        "id": "recuO90VWyFtJHPDz",
        "row": 20292,
        "state": "",
        "group": "",
        "group_num": [
            "recQoXbEKCKg5zUUE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recw4lPj1WHuFmCEV",
        "row": 20287,
        "state": "",
        "group": "",
        "group_num": [
            "recuewHXbZ27eMpiv"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 872,
        "2023": 1200,
        "id": "recxuA2VutFXRuWBn",
        "row": 20276,
        "state": "",
        "group": "",
        "group_num": [
            "recZeFGC0C5c6Dwbe"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "recxkqfEPQ2rxTOm6",
        "row": 20275,
        "state": "",
        "group": "0767732",
        "group_num": [
            "rec257Rs2qWuE76MQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1295,
        "id": "reczTdDTiznEuLa7I",
        "row": 20303,
        "state": "",
        "group": "",
        "group_num": [
            "recsMd23wt8UzBHCz"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "reczigZMD8uz8YoR7",
        "row": 20279,
        "state": "",
        "group": "",
        "group_num": [
            "recc5SZCMtsLLPAaZ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 720,
        "id": "recEvOX2dDSsjo9D7",
        "row": 20273,
        "state": "",
        "group": "",
        "group_num": [
            "recvW7knddaU5gB4u"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "id": "recE37Qz3xSj2wK3H",
        "row": 20295,
        "state": "",
        "group": "",
        "group_num": [
            "rec5qaSs8nfqJXIGo"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1496,
        "id": "recIRw8pKwxxbycnd",
        "row": 20278,
        "state": "",
        "group": "",
        "group_num": [
            "recc5SZCMtsLLPAaZ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "recNQs2UnuseYDgzM",
        "row": 20285,
        "state": "",
        "group": "",
        "group_num": [
            "recN77Kxe8wHAoTtV"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 430,
        "2023": 461,
        "id": "receSVUZM9JiQ0DTs",
        "row": 20329,
        "state": "",
        "group": "17548001100046",
        "group_num": [
            "recyJojnVYEosX0ko"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "2023": 272,
        "id": "recfGpopz2dFvm2pk",
        "row": 20330,
        "state": "",
        "group": "",
        "group_num": [
            "reckGQKIwQBW6VIja"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1690,
        "id": "rechDvMN85ERDyYK5",
        "row": 20343,
        "state": "",
        "group": "",
        "group_num": [
            "recbeQ346MTt6hkDI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "reciqFUlgQilwvUfT",
        "row": 20314,
        "state": "",
        "group": "",
        "group_num": [
            "recwTWSR0qTWsSgX9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1271,
        "id": "recjwixB4eh6BwJcA",
        "row": 20321,
        "state": "",
        "group": "16962201300004",
        "group_num": [
            "rec7eLIsXf8r75rgE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recjpKwyb1LV4OfpU",
        "row": 20347,
        "state": "",
        "group": "",
        "group_num": [
            "recxB736V3V2pO3NO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1358,
        "id": "recky0UrjpS32uiSm",
        "row": 20353,
        "state": "",
        "group": "",
        "group_num": [
            "recc9pCAt0NApATkL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "reclSHto9Vv6bKbaD",
        "row": 20319,
        "state": "",
        "group": "16962201300004",
        "group_num": [
            "rec7eLIsXf8r75rgE"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1190,
        "id": "reclsiSwXkMkNiSMG",
        "row": 20318,
        "state": "",
        "group": "",
        "group_num": [
            "recz5zQJJSSAm5iyb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "id": "recloV81H7thkm2Kj",
        "row": 20323,
        "state": "",
        "group": "",
        "group_num": [
            "recXpxCjncnzpaAdV"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 430,
        "id": "recmRU1I2ZSWfdI7v",
        "row": 20342,
        "state": "",
        "group": "",
        "group_num": [
            "recCkRlAFBFqHf17d"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 330,
        "id": "recn8IQQ4xWlwFv2z",
        "row": 20315,
        "state": "",
        "group": "",
        "group_num": [
            "recwTWSR0qTWsSgX9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recOyzpcQYyyRJKoC",
        "row": 20309,
        "state": "",
        "group": "",
        "group_num": [
            "recddgodv588HxyCv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "2023": 1450,
        "id": "recOFUesV9tJp0eiG",
        "row": 20326,
        "state": "",
        "group": "",
        "group_num": [
            "recTiEZniIZzMHjlR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 943,
        "id": "recQLhoMRGZQxUgs9",
        "row": 20337,
        "state": "",
        "group": "",
        "group_num": [
            "recub3bnjh6poznhN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1194,
        "2023": 1068,
        "id": "recShjePk3EgcFG17",
        "row": 20311,
        "state": "",
        "group": "",
        "group_num": [
            "rec9IOOQCl7YE6GVT"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1623,
        "id": "recTuaQwSVu1hwTKr",
        "row": 20352,
        "state": "",
        "group": "",
        "group_num": [
            "recc9pCAt0NApATkL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1348,
        "id": "recT8757Pxc5W2NIL",
        "row": 20334,
        "state": "",
        "group": "",
        "group_num": [
            "rec6PJVAKjHjMoYnv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1095,
        "id": "recUc3Hvt9n4eqNqh",
        "row": 20317,
        "state": "",
        "group": "",
        "group_num": [
            "recHvyXLcepc6F5gh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "id": "recUlU3ZuGTjTY5Xy",
        "row": 20338,
        "state": "",
        "group": "",
        "group_num": [
            "recub3bnjh6poznhN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 769,
        "id": "recVacXKFrkSzVvCo",
        "row": 20331,
        "state": "",
        "group": "",
        "group_num": [
            "recyw7lN5QBsSaopb"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 978,
        "2023": 1063,
        "id": "recXM8jci5G6bTTmj",
        "row": 20344,
        "state": "",
        "group": "",
        "group_num": [
            "recbeQ346MTt6hkDI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 779,
        "id": "recYyXmykhGRQfHL4",
        "row": 20310,
        "state": "",
        "group": "",
        "group_num": [
            "recHJTDTkLHFRiblp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "rec0FPLtzYecRX84Q",
        "row": 20345,
        "state": "",
        "group": "",
        "group_num": [
            "recbeQ346MTt6hkDI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1076,
        "id": "rec06UxLCqNsRjTNt",
        "row": 20351,
        "state": "",
        "group": "",
        "group_num": [
            "recNa3DfrvQGZrVXr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1022,
        "2023": 849,
        "id": "rec202sF5HYrPR42q",
        "row": 20312,
        "state": "",
        "group": "",
        "group_num": [
            "rec9IOOQCl7YE6GVT"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2215,
        "2023": 476,
        "id": "rec4BcP6fODvDTNyS",
        "row": 20308,
        "state": "",
        "group": "14907701000001",
        "group_num": [
            "rec9WcHy3O4vMROAI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "id": "rec6BsA91GQTpZ5ZV",
        "row": 20349,
        "state": "",
        "group": "",
        "group_num": [
            "recNEyvvxNIbYVyHf"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1269,
        "id": "rec8Bt1xPIOrqc30f",
        "row": 20339,
        "state": "",
        "group": "",
        "group_num": [
            "recYbiz9CAXZEtS5f"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3299,
        "2024": 3361,
        "id": "rec8Cq2biBUrH2dbb",
        "row": 20336,
        "state": "",
        "group": "",
        "group_num": [
            "recQsMO6mQKRlk7U1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recbXeiKqCxoTziJo",
        "row": 20348,
        "state": "",
        "group": "",
        "group_num": [
            "recNEyvvxNIbYVyHf"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2703,
        "id": "recc7sOcK8DUtCRzZ",
        "row": 20340,
        "state": "",
        "group": "",
        "group_num": [
            "rec0o5jFa32lkXyZP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2880,
        "id": "recdPleAIkA7pDjQZ",
        "row": 20325,
        "state": "",
        "group": "",
        "group_num": [
            "recTiEZniIZzMHjlR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recozCirLF4zXpkc2",
        "row": 20327,
        "state": "",
        "group": "",
        "group_num": [
            "reclHDozEqDzzvtSN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1108,
        "id": "recocHkTYasJThdmk",
        "row": 20313,
        "state": "",
        "group": "",
        "group_num": [
            "rec9IOOQCl7YE6GVT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 887,
        "id": "recpImLxK7BEPdLrh",
        "row": 20333,
        "state": "",
        "group": "",
        "group_num": [
            "rec6PJVAKjHjMoYnv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1364,
        "id": "recqxJD0pIb0aF44x",
        "row": 20346,
        "state": "",
        "group": "",
        "group_num": [
            "recxB736V3V2pO3NO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2428,
        "id": "recr9yRmRLTdmrnIV",
        "row": 20335,
        "state": "",
        "group": "",
        "group_num": [
            "recQsMO6mQKRlk7U1"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "2023": 629,
        "id": "recrqxDWbHWoRhUQJ",
        "row": 20324,
        "state": "",
        "group": "",
        "group_num": [
            "recXpxCjncnzpaAdV"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1352,
        "id": "recsdeuit6X4yPsLJ",
        "row": 20341,
        "state": "",
        "group": "",
        "group_num": [
            "recDxLY2ET6uKFGQb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recuYxaeBtUMnW2ps",
        "row": 20320,
        "state": "",
        "group": "16962201300004",
        "group_num": [
            "rec7eLIsXf8r75rgE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 943,
        "id": "recE5w3LgypWOIesv",
        "row": 20322,
        "state": "",
        "group": "",
        "group_num": [
            "recXpxCjncnzpaAdV"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2662,
        "id": "recEnI6kKqimSP3Le",
        "row": 20307,
        "state": "",
        "group": "",
        "group_num": [
            "recAHKbhnRQqYr8GY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1041,
        "id": "recFvK0IKGcpKTTYY",
        "row": 20328,
        "state": "",
        "group": "17548001100046",
        "group_num": [
            "recyJojnVYEosX0ko"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 572,
        "id": "recFf7g5sVjxSxRfJ",
        "row": 20355,
        "state": "",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "id": "recHtKWEmx06n1W6J",
        "row": 20356,
        "state": "",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1469,
        "id": "recK1yD4laZ8E9YTS",
        "row": 20354,
        "state": "",
        "group": "66037701000007",
        "group_num": [
            "rec5dB7nd3WjcX2K9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 921,
        "id": "recLv52MBTKWRGkHb",
        "row": 20316,
        "state": "",
        "group": "",
        "group_num": [
            "recHvyXLcepc6F5gh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "2023": 758,
        "id": "recN2fmvy3ECriNIN",
        "row": 20332,
        "state": "",
        "group": "",
        "group_num": [
            "recyw7lN5QBsSaopb"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recNoo0zT7kajlknF",
        "row": 20350,
        "state": "",
        "group": "",
        "group_num": [
            "recNa3DfrvQGZrVXr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "id": "recfDEguDu52Y7HHP",
        "row": 20360,
        "state": "",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1306,
        "id": "rechI5SxOmnijoN9t",
        "row": 20402,
        "state": "",
        "group": "",
        "group_num": [
            "recc0fxte3TWAoQpO"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1901,
        "id": "reciTNBlRTCCvdT7z",
        "row": 20359,
        "state": "",
        "group": "",
        "group_num": [
            "recnaO3O2KgnLeFIX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1525,
        "id": "reci5HBCfVGmmuyqW",
        "row": 20401,
        "state": "",
        "group": "",
        "group_num": [
            "recc0fxte3TWAoQpO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1901,
        "id": "recjauZcz0zNCzOKi",
        "row": 20376,
        "state": "",
        "group": "",
        "group_num": [
            "recaRmULVyKuWhKS8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1117,
        "id": "recjnbIVjNtTALoCm",
        "row": 20368,
        "state": "",
        "group": "",
        "group_num": [
            "reczugd9jNaAVuKvy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "reckEL34um51UEslm",
        "row": 20399,
        "state": "",
        "group": "",
        "group_num": [
            "recu1TBkzCJb5Am4T"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "reckiBz9DYyvU8ega",
        "row": 20357,
        "state": "",
        "group": "",
        "group_num": [
            "recnaO3O2KgnLeFIX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4455,
        "id": "recmHztyuxrWt0TIt",
        "row": 20364,
        "state": "",
        "group": "72445804100001",
        "group_num": [
            "recmR9RhcQQ9l8EML"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recQ9o4r0roaDEtwz",
        "row": 20363,
        "state": "",
        "group": "72445804100001",
        "group_num": [
            "recmR9RhcQQ9l8EML"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "id": "recTEfxcC48pfuvo8",
        "row": 20369,
        "state": "",
        "group": "",
        "group_num": [
            "reczugd9jNaAVuKvy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "id": "recUXxIfS02j9Kao2",
        "row": 20374,
        "state": "",
        "group": "",
        "group_num": [
            "recaRmULVyKuWhKS8"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1870,
        "id": "recVLAEKxUaDnRym8",
        "row": 20404,
        "state": "",
        "group": "",
        "group_num": [
            "rec3OY7CtvjJ0Eyst"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1923,
        "id": "recWDBqFzxDoLSGhy",
        "row": 20386,
        "state": "",
        "group": "",
        "group_num": [
            "recdeqo06Ns56UJxj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recXJMTBrvS9UsKjL",
        "row": 20384,
        "state": "",
        "group": "",
        "group_num": [
            "recZMMMsCUV9DQWD7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 182,
        "2023": 362,
        "id": "recZUJmIgT7s2u2Pf",
        "row": 20382,
        "state": "",
        "group": "",
        "group_num": [
            "recHd2uPQI2t1UBew"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rec0e7hOAZnYEjiOj",
        "row": 20385,
        "state": "",
        "group": "",
        "group_num": [
            "recZMMMsCUV9DQWD7"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1704,
        "2023": 1352,
        "2024": 1853,
        "id": "rec50feEshO4miXTx",
        "row": 20406,
        "state": "",
        "group": "",
        "group_num": [
            "recgVbYuUEzI394Qp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "id": "rec51mws0E6Ed5zEe",
        "row": 20367,
        "state": "",
        "group": "",
        "group_num": [
            "recCu8cBEsSH4dlRl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1196,
        "2023": 994,
        "id": "rec6ECqF2hYxS11B7",
        "row": 20390,
        "state": "",
        "group": "86873801900001",
        "group_num": [
            "recK5eAXsLWNaVPhN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rec67K6yOzX6Jws3m",
        "row": 20398,
        "state": "",
        "group": "",
        "group_num": [
            "recu1TBkzCJb5Am4T"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 973,
        "id": "rec7wAYaMtUTa5zgH",
        "row": 20381,
        "state": "",
        "group": "",
        "group_num": [
            "recHd2uPQI2t1UBew"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "rec7hFAEF0DEfRauy",
        "row": 20377,
        "state": "",
        "group": "",
        "group_num": [
            "recvnwdyRHZfHIO7G"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "rec9LCtgrJOmrre2r",
        "row": 20388,
        "state": "",
        "group": "",
        "group_num": [
            "recMqFrIwP1wWWnw5"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 545,
        "id": "recbn6tDjhprSC0bo",
        "row": 20391,
        "state": "",
        "group": "",
        "group_num": [
            "recBZCliAhjtVoKKV"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recdECcRg1aJBcvah",
        "row": 20370,
        "state": "",
        "group": "",
        "group_num": [
            "recAPG5TMpmOSE697"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "2023": 1352,
        "id": "recoAzEWjoKeJuqob",
        "row": 20379,
        "state": "",
        "group": "",
        "group_num": [
            "rechBmuzEd1ZBTAwr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 4095,
        "id": "recqxQhrXnMDYO2ER",
        "row": 20361,
        "state": "",
        "group": "66697301400001",
        "group_num": [
            "rec6GWWWI24UvJvcg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "id": "recqFCVv8A6Fs36tV",
        "row": 20366,
        "state": "",
        "group": "",
        "group_num": [
            "recCu8cBEsSH4dlRl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2438,
        "id": "recqoTGtWiebV5psp",
        "row": 20378,
        "state": "",
        "group": "",
        "group_num": [
            "recvnwdyRHZfHIO7G"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "id": "rectT32ccO8wgQP68",
        "row": 20371,
        "state": "",
        "group": "75538501000725",
        "group_num": [
            "recP74meVjqTqAh2c"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2564,
        "id": "recunia4Xl1tCUk7E",
        "row": 20403,
        "state": "",
        "group": "",
        "group_num": [
            "rec3OY7CtvjJ0Eyst"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2925,
        "id": "recxX56dmcoGj8xKu",
        "row": 20387,
        "state": "",
        "group": "",
        "group_num": [
            "recdeqo06Ns56UJxj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "2023": 1022,
        "id": "recxplTnHbKt9wY7m",
        "row": 20373,
        "state": "",
        "group": "",
        "group_num": [
            "recaRmULVyKuWhKS8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 246,
        "id": "recy1NtL3wvNOsMc0",
        "row": 20372,
        "state": "",
        "group": "75538501000725",
        "group_num": [
            "recP74meVjqTqAh2c"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1357,
        "id": "recynEoYFs25niiY4",
        "row": 20395,
        "state": "",
        "group": "",
        "group_num": [
            "recvy2rRkjG6jQmcR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 231,
        "2023": 193,
        "id": "reczzPzCi22zu8igr",
        "row": 20392,
        "state": "",
        "group": "",
        "group_num": [
            "recBZCliAhjtVoKKV"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "id": "recA63vWK3P2PXmXP",
        "row": 20358,
        "state": "",
        "group": "",
        "group_num": [
            "recnaO3O2KgnLeFIX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1310,
        "id": "recBtLSWxVkXJE6wg",
        "row": 20362,
        "state": "",
        "group": "",
        "group_num": [
            "rec7aAXgElgiqo9df"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1008,
        "id": "recBaakukCTg1La6b",
        "row": 20396,
        "state": "",
        "group": "",
        "group_num": [
            "recvy2rRkjG6jQmcR"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recC6Q8l0Qx64TGuQ",
        "row": 20397,
        "state": "",
        "group": "87009501100309",
        "group_num": [
            "reckoJA6k3qkrA9mJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recDUJJWkHgkDb5J2",
        "row": 20365,
        "state": "",
        "group": "",
        "group_num": [
            "recCu8cBEsSH4dlRl"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recFwJmGlK7SaDuBS",
        "row": 20389,
        "state": "",
        "group": "86873801900001",
        "group_num": [
            "recK5eAXsLWNaVPhN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 431,
        "id": "recGHvtOwKvyM8WWJ",
        "row": 20393,
        "state": "",
        "group": "",
        "group_num": [
            "recBZCliAhjtVoKKV"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1074,
        "id": "recIKwO9g3VTI8UjL",
        "row": 20375,
        "state": "",
        "group": "",
        "group_num": [
            "recaRmULVyKuWhKS8"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 775,
        "id": "recI2J0B1DuqNToMh",
        "row": 20383,
        "state": "",
        "group": "",
        "group_num": [
            "recHd2uPQI2t1UBew"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recIig2wuCss7SxTp",
        "row": 20394,
        "state": "",
        "group": "",
        "group_num": [
            "recvy2rRkjG6jQmcR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2508,
        "id": "recJl4bYc8YY8Yzj2",
        "row": 20400,
        "state": "",
        "group": "",
        "group_num": [
            "recc0fxte3TWAoQpO"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 656,
        "id": "recLzqNIyO1jY9adp",
        "row": 20380,
        "state": "",
        "group": "",
        "group_num": [
            "rechBmuzEd1ZBTAwr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "recNaa8y8Sn37dsSd",
        "row": 20405,
        "state": "",
        "group": "",
        "group_num": [
            "rec4LhUvZguoL9lF8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "id": "recfWw3y71lK93zKi",
        "row": 20431,
        "state": "",
        "group": "010063505200006",
        "group_num": [
            "recVYhBVjhLSKKRHu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 594,
        "id": "recfKuUiZuFfxsgqO",
        "row": 20450,
        "state": "",
        "group": "010903201400012",
        "group_num": [
            "recKS7CW8zoi1LfZs"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 909,
        "id": "recgbIytKfXCxWx1y",
        "row": 20425,
        "state": "",
        "group": "",
        "group_num": [
            "rechWIqhwhFzFT05M"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "2023": 832,
        "id": "recglViAzxgKunyEe",
        "row": 20423,
        "state": "",
        "group": "",
        "group_num": [
            "recoRXLVz0AVAzIvY"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 51,
        "id": "recgrBNwk4XECjpzD",
        "row": 20454,
        "state": "",
        "group": "",
        "group_num": [
            "recPf1weInPB4FnZY"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1645,
        "id": "recj7RmYYlcVNuAVY",
        "row": 20416,
        "state": "",
        "group": "010016601200022",
        "group_num": [
            "recX9FnUEjAKHxe4P"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1014,
        "id": "reckcbxiRyh6IKUeJ",
        "row": 20419,
        "state": "",
        "group": "010016601700020",
        "group_num": [
            "recWgTLngDJCyYjkq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1618,
        "id": "reckpa0EZq36ZfFJc",
        "row": 20444,
        "state": "",
        "group": "",
        "group_num": [
            "recesmDFbq0DAdfWb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1057,
        "id": "reclM4UCIZGocBl8j",
        "row": 20447,
        "state": "",
        "group": "010844801100001",
        "group_num": [
            "recw0GurQNcKEHQtO"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2028,
        "id": "recmkLWkDFKPxbOwN",
        "row": 20453,
        "state": "",
        "group": "",
        "group_num": [
            "rechUOBcNcSg0F9F1"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "id": "recO8eSybApy7afAg",
        "row": 20430,
        "state": "",
        "group": "010063505200006",
        "group_num": [
            "recVYhBVjhLSKKRHu"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "2023": 554,
        "id": "recPqeG0KEt2Gw9Ab",
        "row": 20433,
        "state": "",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "id": "recQsMBobulZLTxy0",
        "row": 20417,
        "state": "",
        "group": "010016601700020",
        "group_num": [
            "recWgTLngDJCyYjkq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 718,
        "2023": 597,
        "id": "recQfoNFMG8IbkGv6",
        "row": 20434,
        "state": "",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 47,
        "id": "recRjhE0smI07VVlk",
        "row": 20440,
        "state": "",
        "group": "",
        "group_num": [
            "rec4FESWvN9qzr0fD"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "id": "recW91YOAVpRbTxzM",
        "row": 20426,
        "state": "",
        "group": "",
        "group_num": [
            "rechWIqhwhFzFT05M"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 722,
        "2024": 516,
        "id": "recWfj8NqjAQ031JZ",
        "row": 20456,
        "state": "",
        "group": "",
        "group_num": [
            "recEzclD0lrJw6V0K"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 751,
        "id": "recXC7bfqp8pCThwR",
        "row": 20439,
        "state": "",
        "group": "",
        "group_num": [
            "rec4FESWvN9qzr0fD"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1043,
        "id": "recYPJR4CddyZ9GfE",
        "row": 20418,
        "state": "",
        "group": "010016601700020",
        "group_num": [
            "recWgTLngDJCyYjkq"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1690,
        "id": "rec0Ykl19A36fx4O2",
        "row": 20420,
        "state": "",
        "group": "010016601800020",
        "group_num": [
            "recyEiFsRl55TE24Q"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1714,
        "2023": 1601,
        "id": "rec1tR8q19AuxS0eC",
        "row": 20421,
        "state": "",
        "group": "",
        "group_num": [
            "recoRXLVz0AVAzIvY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1663,
        "id": "rec3qlO59jwgJ7UWG",
        "row": 20415,
        "state": "",
        "group": "",
        "group_num": [
            "recwhOf861xJ5MKRl"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1805,
        "id": "rec5K8E5h1QXIC4nO",
        "row": 20411,
        "state": "",
        "group": "",
        "group_num": [
            "recE3jJ1lDPmYEVju"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 983,
        "id": "rec5aB4FDzSYRo2UD",
        "row": 20437,
        "state": "",
        "group": "",
        "group_num": [
            "recdGKpZJ6t6QJHZl"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1121,
        "id": "rec6YcW7Y7UPvEW8J",
        "row": 20449,
        "state": "",
        "group": "010903201400012",
        "group_num": [
            "recKS7CW8zoi1LfZs"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1436,
        "id": "rec7HRTqluJiNhbLO",
        "row": 20409,
        "state": "",
        "group": "87715701000001",
        "group_num": [
            "rechkCv13lQHAED6m"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1027,
        "2023": 4200,
        "2024": 1200,
        "id": "rec7ksutnh0G89uGd",
        "row": 20414,
        "state": "",
        "group": "005363001000101",
        "group_num": [
            "rec2NQl5207cep8Ei"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "rec9GHF2VK7Lb5D0f",
        "row": 20413,
        "state": "",
        "group": "005363001000101",
        "group_num": [
            "rec2NQl5207cep8Ei"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "rec9qgz2AgL9U29xF",
        "row": 20445,
        "state": "",
        "group": "",
        "group_num": [
            "recesmDFbq0DAdfWb"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1440,
        "id": "recaQIBP512BaQKFM",
        "row": 20442,
        "state": "",
        "group": "",
        "group_num": [
            "recSjU7p7CZSA96hx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "id": "reca6DREQmnW8LadQ",
        "row": 20429,
        "state": "",
        "group": "010029003300001",
        "group_num": [
            "recLg9li1OUKYUeWd"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1314,
        "id": "recboEh7IkgLrOvgH",
        "row": 20443,
        "state": "",
        "group": "",
        "group_num": [
            "rechnfIY71vv1AiF0"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "2023": 974,
        "id": "reccxBoV0azpvX4hf",
        "row": 20432,
        "state": "",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2380,
        "id": "recpGaGBpfeQSQKp6",
        "row": 20424,
        "state": "",
        "group": "",
        "group_num": [
            "recoRXLVz0AVAzIvY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2662,
        "2023": 3413,
        "id": "recp4sjCveueQmVV2",
        "row": 20408,
        "state": "",
        "group": "",
        "group_num": [
            "recgVbYuUEzI394Qp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "2023": 1448,
        "2024": 1533,
        "id": "recqiqFsjmbiJmVm6",
        "row": 20407,
        "state": "",
        "group": "",
        "group_num": [
            "recgVbYuUEzI394Qp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 637,
        "2023": 930,
        "id": "recs5ZoAjvoqbqFwn",
        "row": 20412,
        "state": "",
        "group": "005363001000101",
        "group_num": [
            "rec2NQl5207cep8Ei"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recxxmZeQTYkQyxHU",
        "row": 20446,
        "state": "",
        "group": "",
        "group_num": [
            "recesmDFbq0DAdfWb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 526,
        "2023": 494,
        "id": "recyzgWnuQNbJz9jQ",
        "row": 20441,
        "state": "",
        "group": "",
        "group_num": [
            "rec4FESWvN9qzr0fD"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1150,
        "id": "recB0xEfJpD4IQsfV",
        "row": 20455,
        "state": "",
        "group": "",
        "group_num": [
            "recPf1weInPB4FnZY"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 930,
        "id": "recDD4WWW8ytGfop2",
        "row": 20451,
        "state": "",
        "group": "010903401100003",
        "group_num": [
            "recGMRHxxl1WZ1fnj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1568,
        "id": "recD2lCfLk8JdJ7Ob",
        "row": 20438,
        "state": "",
        "group": "",
        "group_num": [
            "recdGKpZJ6t6QJHZl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 939,
        "id": "recGHXq9zIVWfHty6",
        "row": 20452,
        "state": "",
        "group": "",
        "group_num": [
            "rechUOBcNcSg0F9F1"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 896,
        "id": "recHnqlkphBfOEaWg",
        "row": 20410,
        "state": "",
        "group": "",
        "group_num": [
            "recEGEkf6gHChyciC"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1035,
        "id": "recJWkSta8CKSfY8x",
        "row": 20422,
        "state": "",
        "group": "",
        "group_num": [
            "recoRXLVz0AVAzIvY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1003,
        "2023": 1160,
        "id": "recJzewHzj0GGt254",
        "row": 20435,
        "state": "",
        "group": "010063505200202",
        "group_num": [
            "recNhdRf7pcqZYeiP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 498,
        "id": "recKJPeq9DRQoIdzW",
        "row": 20428,
        "state": "",
        "group": "010029002000001",
        "group_num": [
            "recLpax7Vu4FMY0rI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 219,
        "id": "recM2SOnsCfTQ5hgW",
        "row": 20427,
        "state": "",
        "group": "010029002000001",
        "group_num": [
            "recLpax7Vu4FMY0rI"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 543,
        "id": "recNOLggPbMgtjYq6",
        "row": 20448,
        "state": "",
        "group": "010844801100001",
        "group_num": [
            "recw0GurQNcKEHQtO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "id": "recN4uHUkwg0Zbp55",
        "row": 20436,
        "state": "",
        "group": "010198703000110",
        "group_num": [
            "recDGrbUtx0x9r6Sp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 332,
        "id": "receZstwjiDK8dKwP",
        "row": 20506,
        "state": "",
        "group": "",
        "group_num": [
            "recu7GSrsHX339BAj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 193,
        "id": "recfMYPLiM2uVGKo2",
        "row": 20498,
        "state": "",
        "group": "011544501100001",
        "group_num": [
            "rec8Y26tkB2S9HDEe"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recgxcXZ7u75WWaY8",
        "row": 20484,
        "state": "",
        "group": "",
        "group_num": [
            "recYmGA12sCQqX7Jp"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recjWeFvvCQTyNwjh",
        "row": 20493,
        "state": "",
        "group": "",
        "group_num": [
            "recbHaXi2SeJ6DRP8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1134,
        "2023": 897,
        "id": "reckNp7O9gsvOr223",
        "row": 20499,
        "state": "",
        "group": "011911902000002",
        "group_num": [
            "recLqWfXRU3GWs6mo"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 186,
        "id": "recmSBlBdrkQGlRTi",
        "row": 20457,
        "state": "",
        "group": "",
        "group_num": [
            "recEzclD0lrJw6V0K"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 871,
        "id": "recmrzamEPaRC71RM",
        "row": 20461,
        "state": "",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1901,
        "id": "recP09DRa8QgK1tNo",
        "row": 20459,
        "state": "",
        "group": "",
        "group_num": [
            "recCO6Qpwe98IdskZ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recPbLlSYoONA4Lal",
        "row": 20477,
        "state": "",
        "group": "",
        "group_num": [
            "recUujPJaOINsH6qt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3158,
        "2023": 1500,
        "id": "recPhjPbFVR5ddTtw",
        "row": 20485,
        "state": "",
        "group": "",
        "group_num": [
            "recAGXtVixI5vtbwt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3143,
        "id": "recSAP5qRFWHRjx3G",
        "row": 20473,
        "state": "",
        "group": "010919002500151",
        "group_num": [
            "rec0a5mYEwDPcYZOh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2195,
        "id": "recSdOLIkwkkojsOs",
        "row": 20479,
        "state": "",
        "group": "",
        "group_num": [
            "recNn5yu93IbSrmcQ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1197,
        "id": "recT6SQHvQUZUvr3L",
        "row": 20474,
        "state": "",
        "group": "",
        "group_num": [
            "recwFKjmgPw8YMZim"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recULnltSvaqB5PDC",
        "row": 20483,
        "state": "",
        "group": "",
        "group_num": [
            "recYmGA12sCQqX7Jp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recU6DyOwekh5ZKSV",
        "row": 20475,
        "state": "",
        "group": "",
        "group_num": [
            "recwFKjmgPw8YMZim"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recVQ16mKXXJ3ffT9",
        "row": 20503,
        "state": "",
        "group": "013837201600001",
        "group_num": [
            "recJBJeUazq5EZdL1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1429,
        "id": "recYMFmKtHxwProXn",
        "row": 20504,
        "state": "",
        "group": "",
        "group_num": [
            "recD6BqHdWr1aR8NH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recZbdQby2nl4LDuq",
        "row": 20471,
        "state": "",
        "group": "010919002500151",
        "group_num": [
            "rec0a5mYEwDPcYZOh"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "rec0Oa2b4uvvnTw28",
        "row": 20502,
        "state": "",
        "group": "013250701000709",
        "group_num": [
            "recHzOdxbarn4PolP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 862,
        "id": "rec2Md08eqSQJ0C9o",
        "row": 20494,
        "state": "",
        "group": "",
        "group_num": [
            "recpoXnGQmGMGdsnT"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1245,
        "2024": 715,
        "id": "rec26lRrfXMIZ2hyQ",
        "row": 20472,
        "state": "",
        "group": "010919002500151",
        "group_num": [
            "rec0a5mYEwDPcYZOh"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 656,
        "id": "rec3MNkEI2BjzZN0C",
        "row": 20481,
        "state": "",
        "group": "",
        "group_num": [
            "recYSSSdjRYvNZ0Dc"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "rec4OeQVKrThgHjCY",
        "row": 20482,
        "state": "",
        "group": "",
        "group_num": [
            "recYSSSdjRYvNZ0Dc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 481,
        "2024": 344,
        "id": "rec6AHvbJDhQy4jEl",
        "row": 20458,
        "state": "",
        "group": "",
        "group_num": [
            "recEzclD0lrJw6V0K"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 942,
        "id": "rec9YjfLmLQTGgire",
        "row": 20491,
        "state": "",
        "group": "",
        "group_num": [
            "recVsknZWanKf8dBn"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 966,
        "id": "recaCp8TuAqHJ6VYE",
        "row": 20500,
        "state": "",
        "group": "011916501100001",
        "group_num": [
            "recE76qxH1K7jCvrw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1957,
        "2023": 193,
        "id": "recbU8qD8ZYT3IKvl",
        "row": 20501,
        "state": "",
        "group": "011916501100001",
        "group_num": [
            "recE76qxH1K7jCvrw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 651,
        "id": "recb0XHUxvlr92J6N",
        "row": 20462,
        "state": "",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 895,
        "id": "recbiDdv6lzAKcCXA",
        "row": 20497,
        "state": "",
        "group": "011544501100001",
        "group_num": [
            "rec8Y26tkB2S9HDEe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 891,
        "id": "recc8WS40xndDP3is",
        "row": 20486,
        "state": "",
        "group": "",
        "group_num": [
            "recAGXtVixI5vtbwt"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recd8IxCxVR0k9lEn",
        "row": 20490,
        "state": "",
        "group": "",
        "group_num": [
            "recVeMaZ3mk3GBIuA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "id": "reco6WUgZDEZsofNp",
        "row": 20488,
        "state": "",
        "group": "",
        "group_num": [
            "recqmgPlR99wyWVNl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3413,
        "id": "recuScf4rKt0jr1YK",
        "row": 20476,
        "state": "",
        "group": "",
        "group_num": [
            "recD7ILjUu41CfSZU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 544,
        "2024": 448,
        "id": "recuPWk31yoE5zuVM",
        "row": 20464,
        "state": "",
        "group": "010914301100001",
        "group_num": [
            "recUC7senCQ48M895"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recvLCf3HiK6ot1Hy",
        "row": 20460,
        "state": "",
        "group": "",
        "group_num": [
            "recbQSSByC17iuFOw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3025,
        "id": "recx1rnIbZAMKthjK",
        "row": 20470,
        "state": "",
        "group": "010919002500151",
        "group_num": [
            "rec0a5mYEwDPcYZOh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1793,
        "id": "recyuyYZPvP7hqSZt",
        "row": 20478,
        "state": "",
        "group": "",
        "group_num": [
            "recNn5yu93IbSrmcQ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "id": "reczaySZxylHXXjAz",
        "row": 20487,
        "state": "",
        "group": "",
        "group_num": [
            "recqmgPlR99wyWVNl"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2608,
        "id": "recBel60gL1JUApVS",
        "row": 20480,
        "state": "",
        "group": "",
        "group_num": [
            "recNn5yu93IbSrmcQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recC9Ce67cHpWH6SA",
        "row": 20492,
        "state": "",
        "group": "",
        "group_num": [
            "recVsknZWanKf8dBn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 370,
        "id": "recDsltyvgSZSQ0x1",
        "row": 20468,
        "state": "",
        "group": "",
        "group_num": [
            "recvcDGYRZhvQGvWZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recDcP1uCNoRDMPED",
        "row": 20465,
        "state": "",
        "group": "010914301100001",
        "group_num": [
            "recUC7senCQ48M895"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 866,
        "id": "recEmhVKGdVhfBuPH",
        "row": 20496,
        "state": "",
        "group": "",
        "group_num": [
            "recpoXnGQmGMGdsnT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 415,
        "id": "recGqGu6lsdeb3Ty5",
        "row": 20495,
        "state": "",
        "group": "",
        "group_num": [
            "recpoXnGQmGMGdsnT"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recID6hUUamwRDTzc",
        "row": 20463,
        "state": "",
        "group": "010914301000001",
        "group_num": [
            "recCCyqN5hLsAUXxj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "recKZhziyuafZfD6P",
        "row": 20469,
        "state": "",
        "group": "",
        "group_num": [
            "recTPgaOz3Do6k7VY"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "2024": 489,
        "id": "recKCaZrlo1CdHORR",
        "row": 20467,
        "state": "",
        "group": "",
        "group_num": [
            "recvcDGYRZhvQGvWZ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 362,
        "id": "recL8p5dIIfe9xRER",
        "row": 20489,
        "state": "",
        "group": "",
        "group_num": [
            "recuS7d2ZBf4WuzEi"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 499,
        "id": "recLqcyXKIvpPi9dN",
        "row": 20505,
        "state": "",
        "group": "",
        "group_num": [
            "recKPrrDfF9SVr7wP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1539,
        "id": "recNhSC8AlyJTjl2B",
        "row": 20466,
        "state": "",
        "group": "",
        "group_num": [
            "recvcDGYRZhvQGvWZ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "2023": 1238,
        "id": "recehjQ9gbVweRPhW",
        "row": 20548,
        "state": "",
        "group": "014197401000005",
        "group_num": [
            "rec6Ek7afvM7iHE1H"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 653,
        "id": "recfILtd5Ry3dbVsd",
        "row": 20539,
        "state": "",
        "group": "",
        "group_num": [
            "rec8qWKxu9gAiHneK"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "rechDeMwA459izTNX",
        "row": 20556,
        "state": "",
        "group": "",
        "group_num": [
            "rec4foZnnA0LGfvSb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1779,
        "id": "rechLK1X1kOQwfWfM",
        "row": 20538,
        "state": "",
        "group": "",
        "group_num": [
            "recU9LSGqkhUwRT4o"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "recjVZjFSTv6XPzwK",
        "row": 20516,
        "state": "",
        "group": "",
        "group_num": [
            "rec2OCGxX3JazqlGQ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1640,
        "id": "recjjpEtqD2vbE4ee",
        "row": 20525,
        "state": "",
        "group": "",
        "group_num": [
            "recdclMXRssjRY5AO"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "2023": 1459,
        "id": "recl9RmlrNniGTM3N",
        "row": 20523,
        "state": "",
        "group": "",
        "group_num": [
            "rec3ELhhMvn4NWxTR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1535,
        "id": "recmTX3eDoKdi2ySa",
        "row": 20524,
        "state": "",
        "group": "",
        "group_num": [
            "rec3ELhhMvn4NWxTR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1586,
        "id": "recmHJdZ4UsbI0tAm",
        "row": 20536,
        "state": "",
        "group": "",
        "group_num": [
            "recO5HCoY0cKRwoIh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1270,
        "id": "recPCOhNvxnMtJnhz",
        "row": 20545,
        "state": "",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1189,
        "id": "recPE5RXWWaa0zIge",
        "row": 20509,
        "state": "",
        "group": "014135301200001",
        "group_num": [
            "recApSK3N38Jwmatr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1308,
        "id": "recQVUC5KJDm9D8xO",
        "row": 20533,
        "state": "",
        "group": "014194301800900",
        "group_num": [
            "recGTDCXfE0OWSKD8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1143,
        "2023": 613,
        "id": "recQvoVNNQgz1VlEW",
        "row": 20515,
        "state": "",
        "group": "",
        "group_num": [
            "recrILv5N2yb57Tq6"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1039,
        "id": "recS2SQMcKNG6HMXl",
        "row": 20511,
        "state": "",
        "group": "",
        "group_num": [
            "recUDHd8kKasrJ0jr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1683,
        "id": "recSohwywfjtUUBSf",
        "row": 20540,
        "state": "",
        "group": "",
        "group_num": [
            "rec8qWKxu9gAiHneK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 579,
        "id": "recTDR0gvqzGMt9QD",
        "row": 20532,
        "state": "",
        "group": "014194301800900",
        "group_num": [
            "recGTDCXfE0OWSKD8"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 992,
        "id": "recVJHnqHkPxnLAGB",
        "row": 20550,
        "state": "",
        "group": "014197401000005",
        "group_num": [
            "rec6Ek7afvM7iHE1H"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 444,
        "id": "recYHokax415HcXjj",
        "row": 20513,
        "state": "",
        "group": "014187203200000",
        "group_num": [
            "recyOkraDA8dC4Ii3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 49,
        "id": "recZ2PEiSyYHK5oiA",
        "row": 20507,
        "state": "",
        "group": "",
        "group_num": [
            "recu7GSrsHX339BAj"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "id": "rec0DFyf5e4kB5oH4",
        "row": 20521,
        "state": "",
        "group": "014194301700200",
        "group_num": [
            "recmnhfH59aqKRLW9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2090,
        "id": "rec3jG9W22juq0LT3",
        "row": 20530,
        "state": "",
        "group": "014194301800900",
        "group_num": [
            "recGTDCXfE0OWSKD8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 462,
        "id": "rec4PeIHoeCJoOBuK",
        "row": 20510,
        "state": "",
        "group": "014135301200001",
        "group_num": [
            "recApSK3N38Jwmatr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "rec6OqTyU2hREEYCI",
        "row": 20529,
        "state": "",
        "group": "014194301800400",
        "group_num": [
            "recfCKgZCaxi9TESX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "id": "rec65hHBkp85KaRfW",
        "row": 20544,
        "state": "",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "rec6nJuN65dKPZ0K0",
        "row": 20547,
        "state": "",
        "group": "014197401000004",
        "group_num": [
            "recahYjQfIaI8LRDu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1097,
        "id": "rec7CvT2qjERA9Sp6",
        "row": 20519,
        "state": "",
        "group": "",
        "group_num": [
            "rec2OCGxX3JazqlGQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "rec7pmzdaLnMCaCTT",
        "row": 20537,
        "state": "",
        "group": "",
        "group_num": [
            "recO5HCoY0cKRwoIh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "id": "rec9vzBzziseQ6Vfq",
        "row": 20518,
        "state": "",
        "group": "",
        "group_num": [
            "rec2OCGxX3JazqlGQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1443,
        "id": "recaVEDaHMcqmDSGr",
        "row": 20528,
        "state": "",
        "group": "014194301800400",
        "group_num": [
            "recfCKgZCaxi9TESX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1079,
        "id": "recceBT4zAj3sXuEy",
        "row": 20554,
        "state": "",
        "group": "",
        "group_num": [
            "recsri1rg6pVNzagM"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recdDhnWEvkkRIHZu",
        "row": 20555,
        "state": "",
        "group": "",
        "group_num": [
            "reco6rcuE5BXn5RmE"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1306,
        "id": "recpPQ6a2NRe3KDLx",
        "row": 20541,
        "state": "",
        "group": "014197401000002",
        "group_num": [
            "recKJBXjgkf6rWogW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1277,
        "id": "recu7Ae9cRWWbSxzH",
        "row": 20546,
        "state": "",
        "group": "014197401000004",
        "group_num": [
            "recahYjQfIaI8LRDu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1071,
        "2023": 1063,
        "id": "recvxz5HHh1fjFlAJ",
        "row": 20527,
        "state": "",
        "group": "",
        "group_num": [
            "recv8S2QKXPyvUR0h"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1568,
        "id": "recywRAIews5V9B85",
        "row": 20549,
        "state": "",
        "group": "014197401000005",
        "group_num": [
            "rec6Ek7afvM7iHE1H"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1180,
        "id": "recznIpywGmydb1gF",
        "row": 20517,
        "state": "",
        "group": "",
        "group_num": [
            "rec2OCGxX3JazqlGQ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "id": "reczpl27lByySZinm",
        "row": 20512,
        "state": "",
        "group": "014187203200000",
        "group_num": [
            "recyOkraDA8dC4Ii3"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1468,
        "id": "recBhIxKMknldo1Rx",
        "row": 20526,
        "state": "",
        "group": "",
        "group_num": [
            "recv8S2QKXPyvUR0h"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 985,
        "id": "recCQtpiB104umQq0",
        "row": 20514,
        "state": "",
        "group": "",
        "group_num": [
            "recrILv5N2yb57Tq6"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1433,
        "id": "recEYFZe7uMqipNPR",
        "row": 20553,
        "state": "",
        "group": "",
        "group_num": [
            "recsri1rg6pVNzagM"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 486,
        "id": "recF6DLrDCo6GF4w6",
        "row": 20522,
        "state": "",
        "group": "014194301700200",
        "group_num": [
            "recmnhfH59aqKRLW9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1965,
        "2023": 1965,
        "id": "recHFzI7ZB0VUrJYg",
        "row": 20552,
        "state": "",
        "group": "",
        "group_num": [
            "recsri1rg6pVNzagM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1273,
        "id": "recHn0n2GpCQCqCFy",
        "row": 20543,
        "state": "",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "recJKV3en4ogfOEdG",
        "row": 20508,
        "state": "",
        "group": "",
        "group_num": [
            "recu7GSrsHX339BAj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1527,
        "id": "recJ76DbmPiVMIqSm",
        "row": 20542,
        "state": "",
        "group": "014197401000003",
        "group_num": [
            "recF6Z8ASyyU6jto4"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1232,
        "id": "recKlQm2mb1ZlTau9",
        "row": 20551,
        "state": "",
        "group": "014197401000006",
        "group_num": [
            "recrYSMm0n6EOxhf5"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2090,
        "id": "recKmoxs1xueassq8",
        "row": 20534,
        "state": "",
        "group": "",
        "group_num": [
            "recQ3CKq2VsfvriOy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recLYWCc0ix5CH5vg",
        "row": 20531,
        "state": "",
        "group": "014194301800900",
        "group_num": [
            "recGTDCXfE0OWSKD8"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1390,
        "id": "recMwmKfGw4vMr2es",
        "row": 20535,
        "state": "",
        "group": "",
        "group_num": [
            "recQ3CKq2VsfvriOy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 772,
        "id": "recNsIBsVqyXTpcOu",
        "row": 20520,
        "state": "",
        "group": "",
        "group_num": [
            "recmrA8tm6effvKN5"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1034,
        "id": "receOO7D9ppwVLkuC",
        "row": 20573,
        "state": "",
        "group": "",
        "group_num": [
            "recg3nyYL81vxmbKG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recfAw8aruT7m6CzW",
        "row": 20592,
        "state": "",
        "group": "",
        "group_num": [
            "recLxuHnRRrDR9osF"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1187,
        "id": "rechaarEqHyr96SN2",
        "row": 20583,
        "state": "",
        "group": "014389901300001",
        "group_num": [
            "recKKyfm8xVl1nvhy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "recjJ8uChuXId46A3",
        "row": 20560,
        "state": "",
        "group": "",
        "group_num": [
            "rec8if7283wgp3mlR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 973,
        "id": "reckVQ6ynbGWREwXG",
        "row": 20578,
        "state": "",
        "group": "",
        "group_num": [
            "recwne7tZ4OkQppGy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1239,
        "id": "reclVfT9LR53Biv6t",
        "row": 20571,
        "state": "",
        "group": "",
        "group_num": [
            "recbmahS3COtU3NvQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2594,
        "id": "reclPpwQaBw0F160f",
        "row": 20596,
        "state": "",
        "group": "",
        "group_num": [
            "recAGLpowzPTvZURT"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 379,
        "id": "reclr9egwNPK4JiwW",
        "row": 20580,
        "state": "",
        "group": "",
        "group_num": [
            "recmGU21t6ffIyEZX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1124,
        "id": "recnPyiu6JAjwSw3V",
        "row": 20577,
        "state": "",
        "group": "",
        "group_num": [
            "recwne7tZ4OkQppGy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recOsyVttqEEufItJ",
        "row": 20593,
        "state": "",
        "group": "",
        "group_num": [
            "recLxuHnRRrDR9osF"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recPHFXnmNO0KUR8h",
        "row": 20600,
        "state": "",
        "group": "",
        "group_num": [
            "recCeyrjuW82y8lyX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 760,
        "id": "recPldMbvtbfClRA5",
        "row": 20595,
        "state": "",
        "group": "014421101000006",
        "group_num": [
            "recKLfWOd6Xo4Qthv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1225,
        "id": "recQZ5yqB2Pj7EHGP",
        "row": 20582,
        "state": "",
        "group": "",
        "group_num": [
            "recmGd4ycNbeVSIpC"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1539,
        "id": "recRlF1EEvIr1ca5C",
        "row": 20557,
        "state": "",
        "group": "",
        "group_num": [
            "recIjXvDJDuTf6cI7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1540,
        "id": "recSveT5kAwkk5wKY",
        "row": 20599,
        "state": "",
        "group": "",
        "group_num": [
            "recCeyrjuW82y8lyX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 516,
        "id": "recSEXUqJn62zlkUh",
        "row": 20574,
        "state": "",
        "group": "",
        "group_num": [
            "recGZl6wudHoIsR4q"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recUDLwftvGDjleJx",
        "row": 20597,
        "state": "",
        "group": "",
        "group_num": [
            "recAGLpowzPTvZURT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3078,
        "id": "recWU0icEvf7NY0IE",
        "row": 20605,
        "state": "",
        "group": "014683701000001",
        "group_num": [
            "reciFG2JgcidFVb3t"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 875,
        "id": "recX5KDWIg0C3SNff",
        "row": 20585,
        "state": "",
        "group": "",
        "group_num": [
            "recnHhzQc0f88y6N8"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recYJZAI6VVr66Ca6",
        "row": 20568,
        "state": "",
        "group": "",
        "group_num": [
            "recrV0i5QrDIHXnTX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recYbL4FpfqRmc5AR",
        "row": 20603,
        "state": "",
        "group": "",
        "group_num": [
            "recAEpEAOM6S52dmM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rec0VlSev2t89YMJc",
        "row": 20586,
        "state": "",
        "group": "014419701000118",
        "group_num": [
            "recqSR79DRmH0sO4C"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1796,
        "id": "rec1tHkNDuKr18EpR",
        "row": 20572,
        "state": "",
        "group": "",
        "group_num": [
            "recbmahS3COtU3NvQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 370,
        "id": "rec12iKnIXVOcC1zs",
        "row": 20559,
        "state": "",
        "group": "",
        "group_num": [
            "recIjXvDJDuTf6cI7"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2438,
        "id": "rec2K846Chkk0vkuv",
        "row": 20562,
        "state": "",
        "group": "",
        "group_num": [
            "rec8if7283wgp3mlR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1043,
        "2024": 500,
        "id": "rec2NFsRSk9IuepXD",
        "row": 20602,
        "state": "",
        "group": "",
        "group_num": [
            "rec1wHPIV8aWGJdKt"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1244,
        "id": "rec4HhPsl5gYn2kTG",
        "row": 20594,
        "state": "",
        "group": "014421101000006",
        "group_num": [
            "recKLfWOd6Xo4Qthv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1606,
        "id": "rec4etoyPuxpgdXzs",
        "row": 20570,
        "state": "",
        "group": "",
        "group_num": [
            "recbmahS3COtU3NvQ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rec6nISFNeAMC2Aw5",
        "row": 20565,
        "state": "",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "rec71pI4jia2uw5iA",
        "row": 20590,
        "state": "",
        "group": "",
        "group_num": [
            "recCpII0nA5MCVpUh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 344,
        "2024": 215,
        "id": "rec8w4Ii0xMbIqswf",
        "row": 20575,
        "state": "",
        "group": "",
        "group_num": [
            "recGZl6wudHoIsR4q"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "2023": 1417,
        "id": "recoENK0gdktcQBZr",
        "row": 20584,
        "state": "",
        "group": "",
        "group_num": [
            "recnHhzQc0f88y6N8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "recpqFFyT3PNG8UUb",
        "row": 20598,
        "state": "",
        "group": "",
        "group_num": [
            "recCeyrjuW82y8lyX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1268,
        "id": "recqECn8cetBMWPlO",
        "row": 20591,
        "state": "",
        "group": "",
        "group_num": [
            "recgxiWNNJrrI5eCl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1502,
        "id": "recq6f4RsNwDpJgWM",
        "row": 20589,
        "state": "",
        "group": "014419701000118",
        "group_num": [
            "recqSR79DRmH0sO4C"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2186,
        "id": "recqhXEhoCQa0gKzL",
        "row": 20604,
        "state": "",
        "group": "",
        "group_num": [
            "recAEpEAOM6S52dmM"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 909,
        "id": "recthdwuL7no96dU5",
        "row": 20587,
        "state": "",
        "group": "014419701000118",
        "group_num": [
            "recqSR79DRmH0sO4C"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1690,
        "id": "recuicFZvTjU2iQhZ",
        "row": 20576,
        "state": "",
        "group": "014370501000001",
        "group_num": [
            "recMCTvVm8VynvlJu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "id": "recv44g2XBuISsZtj",
        "row": 20581,
        "state": "",
        "group": "",
        "group_num": [
            "rec08DUpiocSa8Lep"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recv9FCLRNiNiLG8R",
        "row": 20567,
        "state": "",
        "group": "",
        "group_num": [
            "recOhdrAFxTly0D9X"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2011,
        "2023": 1526,
        "id": "recwyCOPkffGakESn",
        "row": 20563,
        "state": "",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "2023": 1928,
        "id": "reczNZDohgfKoqbrf",
        "row": 20566,
        "state": "",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "2023": 299,
        "2024": 310,
        "id": "reczQgzmVFDcVF9Zo",
        "row": 20579,
        "state": "",
        "group": "",
        "group_num": [
            "recmGU21t6ffIyEZX"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1047,
        "id": "reczj9nmeL3Cqpq12",
        "row": 20601,
        "state": "",
        "group": "",
        "group_num": [
            "rec1wHPIV8aWGJdKt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recAMkI9yWgeQrhGK",
        "row": 20564,
        "state": "",
        "group": "014198102300001",
        "group_num": [
            "recZap0SiHngrIvmb"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "2023": 758,
        "id": "recCUbz8XNnXSSl1Z",
        "row": 20588,
        "state": "",
        "group": "014419701000118",
        "group_num": [
            "recqSR79DRmH0sO4C"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "2024": 550,
        "id": "recCLiHtfwAFcwxUf",
        "row": 20558,
        "state": "",
        "group": "",
        "group_num": [
            "recIjXvDJDuTf6cI7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 556,
        "id": "recCiqRvHLnxT2oW6",
        "row": 20606,
        "state": "",
        "group": "",
        "group_num": [
            "recWjPSmvzCtQPGBR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "id": "recEcVCUIotBPpAEr",
        "row": 20569,
        "state": "",
        "group": "",
        "group_num": [
            "recrV0i5QrDIHXnTX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1794,
        "id": "recKR4DY1seh1h0f8",
        "row": 20561,
        "state": "",
        "group": "",
        "group_num": [
            "rec8if7283wgp3mlR"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "2023": 887,
        "id": "rech1fZmbCyBkutXU",
        "row": 20627,
        "state": "",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1272,
        "id": "rechh8KpbnDDdTcDp",
        "row": 20647,
        "state": "",
        "group": "",
        "group_num": [
            "recIISYpx3pZAscAX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1268,
        "id": "recjSGjAPUIGY4Jrk",
        "row": 20616,
        "state": "",
        "group": "",
        "group_num": [
            "recg63EyP65sKlykU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "2024": 89,
        "id": "recjRYvUaJX5a6PTw",
        "row": 20655,
        "state": "",
        "group": "",
        "group_num": [
            "recl7VQpvXlwe3iSz"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 838,
        "2023": 951,
        "id": "reckVgrdD4uPKXgXg",
        "row": 20614,
        "state": "",
        "group": "014995001200001",
        "group_num": [
            "recDsHX8Ur2OiA2xa"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 697,
        "id": "reckIdaDHyGDhnKO3",
        "row": 20634,
        "state": "",
        "group": "016108701100001",
        "group_num": [
            "recG4bg23QZ28rHKp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "id": "reclY3IVJGg1Nrpsv",
        "row": 20612,
        "state": "",
        "group": "014986601200001",
        "group_num": [
            "recD5jM5BNjQhKpSH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 904,
        "id": "reclJomsiEp4Vm5nU",
        "row": 20626,
        "state": "",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1656,
        "id": "reclK8I5JSLuJcXtm",
        "row": 20652,
        "state": "",
        "group": "",
        "group_num": [
            "reclwyLGM9h8QBdRA"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 404,
        "id": "recm2fdTW7nTk00Xs",
        "row": 20644,
        "state": "",
        "group": "",
        "group_num": [
            "recyRIgjfnwUampne"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 647,
        "id": "recn2DTpwDOXNVrbo",
        "row": 20640,
        "state": "",
        "group": "",
        "group_num": [
            "recgV4YUIlJ2XLhRN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2438,
        "id": "recQ29jppafHXWv0L",
        "row": 20632,
        "state": "",
        "group": "",
        "group_num": [
            "recCSQ79pMdlUXZ3I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "recRk0bKGOKwPJezt",
        "row": 20629,
        "state": "",
        "group": "",
        "group_num": [
            "recWcyob0RZ9Dz8Ss"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 556,
        "id": "recSdvZwBUdDBoDFr",
        "row": 20609,
        "state": "",
        "group": "014937203300021",
        "group_num": [
            "recXqCeek9WzsttwX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recTmFIUyPXZw78f9",
        "row": 20642,
        "state": "",
        "group": "",
        "group_num": [
            "recgV4YUIlJ2XLhRN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 215,
        "id": "recViabxzOI32Q8IK",
        "row": 20611,
        "state": "",
        "group": "",
        "group_num": [
            "recmNYlcmvZg9iHRY"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 962,
        "id": "recWYXBELiuUtEV4N",
        "row": 20643,
        "state": "",
        "group": "",
        "group_num": [
            "recsAPn9qzcBUV3ep"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 641,
        "id": "recWyAqcjUrzQUWQM",
        "row": 20620,
        "state": "",
        "group": "016014401600002",
        "group_num": [
            "reca8ebDuL7yXGLsV"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 986,
        "2023": 986,
        "id": "recWf8lG5MXKTJByr",
        "row": 20618,
        "state": "",
        "group": "",
        "group_num": [
            "recaH4qXLNL7hgT6T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "id": "recXY7luZoITn8x0i",
        "row": 20619,
        "state": "",
        "group": "016014401600001",
        "group_num": [
            "rec1uwnXa8EUrrJON"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recXMgHDCKLSK3okN",
        "row": 20608,
        "state": "",
        "group": "",
        "group_num": [
            "rec72dlsWbMZURsO5"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 333,
        "id": "recYxKjRQWzGOW55D",
        "row": 20617,
        "state": "",
        "group": "",
        "group_num": [
            "recFhwgIlsfItTT1q"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 847,
        "2024": 806,
        "id": "rec00I81xmlwqjWlB",
        "row": 20650,
        "state": "",
        "group": "",
        "group_num": [
            "recZp5z6C2bHmRFNh"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "id": "rec1FqBg8K0nK0H9B",
        "row": 20636,
        "state": "",
        "group": "016125001200001",
        "group_num": [
            "rec7RPcMvxSD4Qsyv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1117,
        "id": "rec1J23V63T3MDoWn",
        "row": 20628,
        "state": "",
        "group": "",
        "group_num": [
            "recWcyob0RZ9Dz8Ss"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 472,
        "id": "rec2T2JypRb72fYCB",
        "row": 20607,
        "state": "",
        "group": "",
        "group_num": [
            "recWjPSmvzCtQPGBR"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1801,
        "id": "rec5j8JN8J64KHP1h",
        "row": 20631,
        "state": "",
        "group": "",
        "group_num": [
            "recCSQ79pMdlUXZ3I"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 656,
        "id": "rec97XwmmjesexE88",
        "row": 20623,
        "state": "",
        "group": "",
        "group_num": [
            "recplwV8Jv0wCPs3b"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recaG1SCwPekCjnVf",
        "row": 20651,
        "state": "",
        "group": "",
        "group_num": [
            "recy2JyS4g4MZv5eh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1195,
        "id": "recbKpEku1s1jYx4y",
        "row": 20635,
        "state": "",
        "group": "",
        "group_num": [
            "recdyngWGUST6hMfa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "id": "recbbBttp01aXMT7j",
        "row": 20610,
        "state": "",
        "group": "014955401400001",
        "group_num": [
            "recM8OuwV8vOx2hqh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 531,
        "id": "recpGS5VvJ09c0cQz",
        "row": 20654,
        "state": "",
        "group": "",
        "group_num": [
            "recl7VQpvXlwe3iSz"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recpOTdyF2nfH7Qtm",
        "row": 20649,
        "state": "",
        "group": "",
        "group_num": [
            "recZp5z6C2bHmRFNh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "id": "recqNU9cU6OasCXbm",
        "row": 20625,
        "state": "",
        "group": "016097801100002",
        "group_num": [
            "recEvq4DNx1ZZlTE7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 981,
        "id": "rectO9sFgjMdrzgyp",
        "row": 20639,
        "state": "",
        "group": "",
        "group_num": [
            "rec94rV6ZjQm069cd"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1395,
        "id": "rectnl7DyFe5Isjpu",
        "row": 20653,
        "state": "",
        "group": "",
        "group_num": [
            "recUp6ADNP5XqpDWG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1027,
        "id": "recwTVC5M6nEMZ4RG",
        "row": 20630,
        "state": "",
        "group": "",
        "group_num": [
            "recWcyob0RZ9Dz8Ss"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "2023": 916,
        "id": "recwuR30kBUcw7Bf9",
        "row": 20613,
        "state": "",
        "group": "014986601200001",
        "group_num": [
            "recD5jM5BNjQhKpSH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recx2O9vGwTCOyNsQ",
        "row": 20633,
        "state": "",
        "group": "",
        "group_num": [
            "recjVF4pyS5iKELUE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 856,
        "id": "recynxvSaBnz9Z0i1",
        "row": 20637,
        "state": "",
        "group": "",
        "group_num": [
            "rec94rV6ZjQm069cd"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "id": "reczkljRlW1ZfW7AE",
        "row": 20645,
        "state": "",
        "group": "016947502400005",
        "group_num": [
            "rec4hDBeIn9A4fGKg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 348,
        "2024": 348,
        "id": "recBDzfOxmAF7ejOv",
        "row": 20656,
        "state": "",
        "group": "",
        "group_num": [
            "recl7VQpvXlwe3iSz"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 982,
        "id": "recCxWAAiNYHE8ff3",
        "row": 20648,
        "state": "",
        "group": "",
        "group_num": [
            "recIISYpx3pZAscAX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 934,
        "id": "recE7XCm5B1wiCBkZ",
        "row": 20624,
        "state": "",
        "group": "",
        "group_num": [
            "recplwV8Jv0wCPs3b"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 457,
        "id": "recEjRH3l9dElsTCo",
        "row": 20622,
        "state": "",
        "group": "016097801000001",
        "group_num": [
            "recD60jaf2ReFOTrf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 47,
        "id": "recFkNzb2EZZ67F26",
        "row": 20641,
        "state": "",
        "group": "",
        "group_num": [
            "recgV4YUIlJ2XLhRN"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "id": "recInRghM3RH5p8ti",
        "row": 20621,
        "state": "",
        "group": "016097801000001",
        "group_num": [
            "recD60jaf2ReFOTrf"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 642,
        "id": "recJdmXC38L1LZTpR",
        "row": 20638,
        "state": "",
        "group": "",
        "group_num": [
            "rec94rV6ZjQm069cd"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1089,
        "id": "recKZGbqXjNbK6aPL",
        "row": 20646,
        "state": "",
        "group": "016947502400005",
        "group_num": [
            "rec4hDBeIn9A4fGKg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 908,
        "id": "recLPdyh0aEZPgjXD",
        "row": 20615,
        "state": "",
        "group": "014995001200001",
        "group_num": [
            "recDsHX8Ur2OiA2xa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2046,
        "id": "receJFABlCfYhqmx4",
        "row": 20704,
        "state": "",
        "group": "",
        "group_num": [
            "recegl61egRMrKzSD"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 784,
        "id": "receej2aowVWKo3fP",
        "row": 20701,
        "state": "",
        "group": "",
        "group_num": [
            "recjluZ5wAdkBB1Tw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1112,
        "id": "recfj38bD3Dgp76LO",
        "row": 20674,
        "state": "",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 208,
        "id": "recgTTEd3mvdvL89e",
        "row": 20680,
        "state": "",
        "group": "",
        "group_num": [
            "recE7pdZExOXRYMGB"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1149,
        "id": "recigoUQuVURsaHem",
        "row": 20661,
        "state": "",
        "group": "016954101400038",
        "group_num": [
            "reci2bqhVsbggCxsQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "recjEOELmMsE2CI9r",
        "row": 20696,
        "state": "",
        "group": "016964601200001",
        "group_num": [
            "recYZysBnT8zMyQ7b"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 403,
        "id": "recmPIG1yLUg8GGTc",
        "row": 20664,
        "state": "",
        "group": "",
        "group_num": [
            "recvax0eUGPckb9XP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 312,
        "id": "recO0byzoQSkidQ5B",
        "row": 20666,
        "state": "",
        "group": "",
        "group_num": [
            "recnCpgJc5JfiTRRU"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "2024": 1190,
        "id": "recO192eMuISCyAsE",
        "row": 20677,
        "state": "",
        "group": "016957901500002",
        "group_num": [
            "recGhzBJGDvB1glDP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "recPdvzXGF9wmg1zB",
        "row": 20660,
        "state": "",
        "group": "016954101400038",
        "group_num": [
            "reci2bqhVsbggCxsQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recQuiEsY0MgqKnea",
        "row": 20682,
        "state": "",
        "group": "",
        "group_num": [
            "recivqagAzlG7xlHw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1784,
        "id": "recQBgt0qb0lqj9sL",
        "row": 20697,
        "state": "",
        "group": "",
        "group_num": [
            "recZm0x06rJIJkTbB"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1494,
        "id": "recRiVmasCoykkyNm",
        "row": 20678,
        "state": "",
        "group": "",
        "group_num": [
            "reciT8AiNYB71HzfW"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 845,
        "id": "recUWEGXOET1QZJxo",
        "row": 20673,
        "state": "",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "id": "recWU1m49K2KFWaAl",
        "row": 20672,
        "state": "",
        "group": "016956501100001",
        "group_num": [
            "recBh3pv8QUetwM8a"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1241,
        "2023": 996,
        "id": "recWtEkt0g6jE1jLI",
        "row": 20687,
        "state": "",
        "group": "016962201200004",
        "group_num": [
            "recKu0rTqQ93pVPGJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "2024": 26,
        "id": "rec0TPfRyZd4B5Xn2",
        "row": 20706,
        "state": "",
        "group": "",
        "group_num": [
            "recdhxbXNk8T7FjT8"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1014,
        "id": "rec05QtqhD9MFUFUy",
        "row": 20688,
        "state": "",
        "group": "",
        "group_num": [
            "rec8QeHi7gqETVUrK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 700,
        "id": "rec07xFWQXmQBrP0v",
        "row": 20675,
        "state": "",
        "group": "",
        "group_num": [
            "recnJlRDBXGwInKWD"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1300,
        "id": "rec1pZZDimCyPzJ8y",
        "row": 20689,
        "state": "",
        "group": "",
        "group_num": [
            "recIz8u33qYlLH6AM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1568,
        "id": "rec2Z3ZXAx78YJIW8",
        "row": 20705,
        "state": "",
        "group": "",
        "group_num": [
            "recegl61egRMrKzSD"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 966,
        "id": "rec2zCJgtIGUKV95H",
        "row": 20667,
        "state": "",
        "group": "",
        "group_num": [
            "rectqIZ57YrEzJ49n"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2889,
        "id": "rec4dl9LxjmNkejHe",
        "row": 20692,
        "state": "",
        "group": "016964501000001",
        "group_num": [
            "recWMxgffyObEk0O2"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1539,
        "id": "rec6yL4abzwyunEeE",
        "row": 20679,
        "state": "",
        "group": "",
        "group_num": [
            "recFaqW1xK8gqjwK7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 893,
        "id": "rec8kMYvqFBmf0JQj",
        "row": 20671,
        "state": "",
        "group": "016955101000003",
        "group_num": [
            "recoEFxAPYgKCDcBg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recaX3ZCdMqinvnZE",
        "row": 20702,
        "state": "",
        "group": "",
        "group_num": [
            "rec5kkwsxL52CFdCQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1043,
        "id": "reca2eZK7hhtmdcgU",
        "row": 20662,
        "state": "",
        "group": "",
        "group_num": [
            "recPlwTbQKjdH8K34"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 523,
        "id": "recaq8DKhvvupoGGF",
        "row": 20657,
        "state": "",
        "group": "",
        "group_num": [
            "reccNoxQugTnbx5GB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 721,
        "id": "reccOLAs9G5VOh3Dc",
        "row": 20665,
        "state": "",
        "group": "",
        "group_num": [
            "recvax0eUGPckb9XP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 940,
        "id": "recdDQZTUTMt427Dg",
        "row": 20663,
        "state": "",
        "group": "016954201000047",
        "group_num": [
            "recqR93rPYCluxjmA"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recdiGcDKaAw2OdAV",
        "row": 20700,
        "state": "",
        "group": "",
        "group_num": [
            "rec2dWgTID3DLoIiH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 443,
        "id": "recrnZ3aEk5SNLrbL",
        "row": 20681,
        "state": "",
        "group": "",
        "group_num": [
            "recE7pdZExOXRYMGB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 51,
        "id": "recsIThB9p8YLT3ly",
        "row": 20658,
        "state": "",
        "group": "",
        "group_num": [
            "recJkm8rmVQUR9KJj"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 865,
        "id": "recsr62WbvuZ3YC1o",
        "row": 20686,
        "state": "",
        "group": "016962201200004",
        "group_num": [
            "recKu0rTqQ93pVPGJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1142,
        "id": "recu8EUezaJYsQsNp",
        "row": 20690,
        "state": "",
        "group": "",
        "group_num": [
            "recP0V4EwrAQViCx9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 722,
        "id": "recvBv7EaGLJupXGu",
        "row": 20669,
        "state": "",
        "group": "",
        "group_num": [
            "recKRhhGchvtULLx8"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1539,
        "id": "recvHRLhcmKnjDzct",
        "row": 20684,
        "state": "",
        "group": "016961704300001",
        "group_num": [
            "recGLxZVbwj5TigJx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recwsxkUVO1OccmRP",
        "row": 20693,
        "state": "",
        "group": "016964501000001",
        "group_num": [
            "recWMxgffyObEk0O2"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1036,
        "id": "recyk6C1c5h18KNPS",
        "row": 20691,
        "state": "",
        "group": "",
        "group_num": [
            "recP0V4EwrAQViCx9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 481,
        "2024": 346,
        "id": "reczu1V1GNHGQIohh",
        "row": 20670,
        "state": "",
        "group": "",
        "group_num": [
            "recKRhhGchvtULLx8"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1772,
        "id": "reczInKHDZnx2o2zm",
        "row": 20695,
        "state": "",
        "group": "016964601200001",
        "group_num": [
            "recYZysBnT8zMyQ7b"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recALJ8CnP50mtWjy",
        "row": 20685,
        "state": "",
        "group": "",
        "group_num": [
            "recL2dZRZZJzBbvEw"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2438,
        "id": "recCbhsqjZ7MtObNK",
        "row": 20698,
        "state": "",
        "group": "",
        "group_num": [
            "recZm0x06rJIJkTbB"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2871,
        "2023": 3900,
        "id": "recDN2n6ZwBdfHWag",
        "row": 20694,
        "state": "",
        "group": "016964501000001",
        "group_num": [
            "recWMxgffyObEk0O2"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 524,
        "2024": 543,
        "id": "recH8vdNEJiNPRe9y",
        "row": 20659,
        "state": "",
        "group": "016953901300028",
        "group_num": [
            "reciefmIkGvt3Tfmw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1190,
        "id": "recICql3oiQYsLw3C",
        "row": 20676,
        "state": "",
        "group": "",
        "group_num": [
            "recnJlRDBXGwInKWD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1015,
        "id": "recIJyV5SjUkPU7bA",
        "row": 20699,
        "state": "",
        "group": "",
        "group_num": [
            "rec0nKy8Noj5w1EOA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 776,
        "id": "recJpbcFiDydtCzbt",
        "row": 20703,
        "state": "",
        "group": "",
        "group_num": [
            "recEVo8KQsqd3nMyy"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1824,
        "id": "recLNxPypgxvhDLai",
        "row": 20683,
        "state": "",
        "group": "",
        "group_num": [
            "rec13ESq0Fe4kPw1r"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "id": "recMFzKQNqBU59nCG",
        "row": 20668,
        "state": "",
        "group": "016954201300033",
        "group_num": [
            "reczll5xXeFkIAiRF"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "id": "recfEJ2l6G9HtpQwZ",
        "row": 20736,
        "state": "",
        "group": "",
        "group_num": [
            "recH4ppYcd9jDWxIN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 944,
        "id": "recfIgCHWVLn9Ys2q",
        "row": 20752,
        "state": "",
        "group": "",
        "group_num": [
            "rec7fNu8ytKR9Cjto"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 946,
        "id": "recm615kqOKLbwMSO",
        "row": 20712,
        "state": "",
        "group": "",
        "group_num": [
            "recxYZfDo1SrVkuMN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 504,
        "id": "recnoXo4EKtJtAf3C",
        "row": 20745,
        "state": "",
        "group": "",
        "group_num": [
            "reccETYoyg6jdUMrN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recOZiLMaP60ueciN",
        "row": 20728,
        "state": "",
        "group": "017507005000001",
        "group_num": [
            "rec7Q6bp3dozuy2B8"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 668,
        "id": "recPGGhm9NAAjR5w3",
        "row": 20722,
        "state": "",
        "group": "",
        "group_num": [
            "recopSGg1VPGubYkC"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "recRXbKcByhPezFHe",
        "row": 20721,
        "state": "",
        "group": "017500102000001",
        "group_num": [
            "reccERpM7mz5h3kYY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1444,
        "id": "recUaNejJ1tbUQzCr",
        "row": 20725,
        "state": "",
        "group": "017506701100001",
        "group_num": [
            "reco6adsX7RyQp84A"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2106,
        "id": "recVz2bJBEhzst7a9",
        "row": 20716,
        "state": "",
        "group": "",
        "group_num": [
            "recmrBnQIHdTNPRCI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 750,
        "2024": 750,
        "id": "recWHVRVqAxg3QN6I",
        "row": 20744,
        "state": "",
        "group": "",
        "group_num": [
            "reccETYoyg6jdUMrN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4515,
        "id": "recW8ux6vm8lxpOzJ",
        "row": 20729,
        "state": "",
        "group": "017507005000001",
        "group_num": [
            "rec7Q6bp3dozuy2B8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 2640,
        "id": "recXi0H5Knj0O5oxZ",
        "row": 20726,
        "state": "",
        "group": "017506701100001",
        "group_num": [
            "reco6adsX7RyQp84A"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 543,
        "id": "recZWQMCojr7ECdr3",
        "row": 20742,
        "state": "",
        "group": "017513603300104",
        "group_num": [
            "recHDugj5dIaq8Ebt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1791,
        "id": "rec00vwcQh4ggxVq5",
        "row": 20730,
        "state": "",
        "group": "",
        "group_num": [
            "recHOChleKQGs3FS4"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 636,
        "id": "rec33DSTnMVHbQcQS",
        "row": 20707,
        "state": "",
        "group": "",
        "group_num": [
            "recdhxbXNk8T7FjT8"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "rec4qWWjLv6FuXqcD",
        "row": 20723,
        "state": "",
        "group": "",
        "group_num": [
            "recopSGg1VPGubYkC"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 724,
        "id": "rec6S9uQbNMp0mk6l",
        "row": 20741,
        "state": "",
        "group": "017513603300104",
        "group_num": [
            "recHDugj5dIaq8Ebt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1011,
        "id": "rec7xoG3FXQPTtyJb",
        "row": 20724,
        "state": "",
        "group": "",
        "group_num": [
            "recTZ07AW3ZSWSuoO"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2250,
        "id": "rec9tkgH2NxUg55Qu",
        "row": 20732,
        "state": "",
        "group": "",
        "group_num": [
            "recJvxKuEbISCVihg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 951,
        "id": "rec9BUMV9LYtyd8Mx",
        "row": 20709,
        "state": "",
        "group": "017029301300009",
        "group_num": [
            "recCeU852OOG6HlAq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1032,
        "id": "rec9aH6BIDweQeKou",
        "row": 20747,
        "state": "",
        "group": "017543801500003",
        "group_num": [
            "recLYx6te8nLC2n5g"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1935,
        "id": "reccGEVxRWLefuFkB",
        "row": 20715,
        "state": "",
        "group": "",
        "group_num": [
            "recBEx1u6eNfNF4F0"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1277,
        "id": "reccLfqA4o5GclEok",
        "row": 20714,
        "state": "",
        "group": "017206301000700",
        "group_num": [
            "recp6ol7LLG4vfB8V"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1110,
        "id": "reccMiVJ0GnqU08Du",
        "row": 20739,
        "state": "",
        "group": "",
        "group_num": [
            "reciA3GROygEtKNg1"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 559,
        "2023": 828,
        "id": "recq1Chg7EdySC7Q1",
        "row": 20754,
        "state": "",
        "group": "017574801000100",
        "group_num": [
            "recJxQoDze5GqHPc9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2458,
        "id": "recrJxKGrjezJlPsH",
        "row": 20708,
        "state": "",
        "group": "",
        "group_num": [
            "rec5RV7XYjHgUzu9M"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "recrjnOkHkjLGIKIE",
        "row": 20727,
        "state": "",
        "group": "017507005000001",
        "group_num": [
            "rec7Q6bp3dozuy2B8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 664,
        "id": "recsY4W0zStda5PnZ",
        "row": 20738,
        "state": "",
        "group": "",
        "group_num": [
            "recNEVUySy75ilG0c"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 757,
        "id": "rectO1PG6tTAGXWxO",
        "row": 20750,
        "state": "",
        "group": "",
        "group_num": [
            "recsKGxrfE9EheGBd"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "2023": 2356,
        "id": "recuh6qmF4cpJ50iq",
        "row": 20734,
        "state": "",
        "group": "",
        "group_num": [
            "recs6xn1o1o0UxJlP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 664,
        "2024": 665,
        "id": "recvK2iGFgo9OjGO2",
        "row": 20753,
        "state": "",
        "group": "",
        "group_num": [
            "recsvBGJUiKWawLuv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 165,
        "id": "recxC5HZBt6skZHz9",
        "row": 20756,
        "state": "",
        "group": "017586001000001",
        "group_num": [
            "reckJBiUYb7sMeJYZ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recxeBQqcwCnKA1eA",
        "row": 20743,
        "state": "",
        "group": "017514201000001",
        "group_num": [
            "recZZAcliNWqmMJvf"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 856,
        "id": "recyGCbcz3ohPROKD",
        "row": 20733,
        "state": "",
        "group": "",
        "group_num": [
            "recs6xn1o1o0UxJlP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1075,
        "id": "recy7tTtz1LlA6Ya6",
        "row": 20710,
        "state": "",
        "group": "017029301300009",
        "group_num": [
            "recCeU852OOG6HlAq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1273,
        "2023": 1273,
        "id": "reczbknS89ZoH7Jcp",
        "row": 20720,
        "state": "",
        "group": "017500102000001",
        "group_num": [
            "reccERpM7mz5h3kYY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 111,
        "id": "reczchBD7Hqn0w7JE",
        "row": 20746,
        "state": "",
        "group": "",
        "group_num": [
            "reclOhT0ueMW5oQcE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1122,
        "id": "recAeIVmeY2qPLE5h",
        "row": 20740,
        "state": "",
        "group": "",
        "group_num": [
            "reciA3GROygEtKNg1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1194,
        "2023": 895,
        "id": "recByniVcrY3FanSG",
        "row": 20755,
        "state": "",
        "group": "017574801000100",
        "group_num": [
            "recJxQoDze5GqHPc9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1829,
        "id": "recClgbak2yPCWBiS",
        "row": 20731,
        "state": "",
        "group": "",
        "group_num": [
            "recHOChleKQGs3FS4"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2117,
        "id": "recE3NyK2uDlo2icV",
        "row": 20717,
        "state": "",
        "group": "",
        "group_num": [
            "recmrBnQIHdTNPRCI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 605,
        "id": "recEkMANdniTVFg6w",
        "row": 20751,
        "state": "",
        "group": "",
        "group_num": [
            "recsKGxrfE9EheGBd"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "2023": 2747,
        "id": "recHDrhmhi80f44EJ",
        "row": 20748,
        "state": "",
        "group": "",
        "group_num": [
            "recNSpvqk4G65tMkM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1020,
        "id": "recIv6haNsiJuoHEF",
        "row": 20737,
        "state": "",
        "group": "",
        "group_num": [
            "recH4ppYcd9jDWxIN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1172,
        "id": "recJiOn3XoCkfDBwY",
        "row": 20711,
        "state": "",
        "group": "",
        "group_num": [
            "recjhN124vUoabkI9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1193,
        "id": "recKWGfaxMsCCg0cM",
        "row": 20719,
        "state": "",
        "group": "",
        "group_num": [
            "recYKbVpeWg1A3NZV"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1152,
        "id": "recK4w9NxOLPcmBKH",
        "row": 20749,
        "state": "",
        "group": "",
        "group_num": [
            "recNSpvqk4G65tMkM"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1907,
        "2023": 1181,
        "id": "recL0FCRk2tkdFLM8",
        "row": 20735,
        "state": "",
        "group": "",
        "group_num": [
            "recH4ppYcd9jDWxIN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3754,
        "id": "recMECiZqmnroTHDh",
        "row": 20713,
        "state": "",
        "group": "",
        "group_num": [
            "reczNg9UOuIGzejxo"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1476,
        "id": "recMriz05CxQhWqpY",
        "row": 20718,
        "state": "",
        "group": "",
        "group_num": [
            "recmrBnQIHdTNPRCI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "id": "recgVcUX89NSeO2ik",
        "row": 20770,
        "state": "",
        "group": "017656301300044",
        "group_num": [
            "recvvFPq73Qy87sQe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recgVVu9jeo1IRKWm",
        "row": 20801,
        "state": "",
        "group": "018125901100003",
        "group_num": [
            "rectRDnsQqcMc1DAK"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1075,
        "id": "recgx9tA09eZl37yN",
        "row": 20760,
        "state": "",
        "group": "017591801100001",
        "group_num": [
            "rec3K2JXFzdkE1nJq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 770,
        "id": "recio9Kbb62f3YnUg",
        "row": 20777,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ptgmSeJKD4Ebj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 101,
        "id": "recjMsiSYzd8b0Kj9",
        "row": 20764,
        "state": "",
        "group": "",
        "group_num": [
            "rec9IAqrnwBOHIhhs"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 47,
        "id": "reckbffFpTBewILy7",
        "row": 20773,
        "state": "",
        "group": "",
        "group_num": [
            "recSErsnxSsuzDyDc"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 399,
        "id": "recll835S7m6aSsLQ",
        "row": 20757,
        "state": "",
        "group": "017586001000001",
        "group_num": [
            "reckJBiUYb7sMeJYZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 453,
        "2024": 453,
        "id": "recOhTh96AUAuyoOt",
        "row": 20784,
        "state": "",
        "group": "",
        "group_num": [
            "recop7eXvAIllfjHS"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 238,
        "id": "recPxkv12khqX8dov",
        "row": 20798,
        "state": "",
        "group": "",
        "group_num": [
            "recl5jq5l9k0Ku4sX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 254,
        "id": "recPEWXTSi4O8lFAQ",
        "row": 20768,
        "state": "",
        "group": "",
        "group_num": [
            "recTAjjl45NWZPZfI"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2157,
        "id": "recPIoQ3PGNrSNjSI",
        "row": 20788,
        "state": "",
        "group": "",
        "group_num": [
            "recirU7NOUIxWwYFk"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1075,
        "id": "recQ3skmzKpxzpJP9",
        "row": 20796,
        "state": "",
        "group": "",
        "group_num": [
            "recn7T60zKCN9lrNM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recRXTlfDfMKfTqDE",
        "row": 20806,
        "state": "",
        "group": "",
        "group_num": [
            "recrkuxVARD3J3slG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1765,
        "id": "recSzBEu6mHvtLJlP",
        "row": 20774,
        "state": "",
        "group": "017657802200005",
        "group_num": [
            "recrsNdkxlW77fRJ5"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 371,
        "id": "recT5KiGTCpLhigpy",
        "row": 20787,
        "state": "",
        "group": "",
        "group_num": [
            "recXv2IBONNIC7vBN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 376,
        "2023": 330,
        "id": "recUttyaFFbrZdSHk",
        "row": 20780,
        "state": "",
        "group": "",
        "group_num": [
            "recwUI93FxJxJLSil"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1551,
        "id": "recVwMeZmObt4ZpZ4",
        "row": 20804,
        "state": "",
        "group": "",
        "group_num": [
            "recrkuxVARD3J3slG"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1026,
        "id": "recVjjFwUUZZnnC0e",
        "row": 20797,
        "state": "",
        "group": "",
        "group_num": [
            "rec1eBEoGJBfh8qEY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recXIg0A1dNFQStaa",
        "row": 20803,
        "state": "",
        "group": "018125901100003",
        "group_num": [
            "rectRDnsQqcMc1DAK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1174,
        "2023": 908,
        "id": "rec0jk34mWjJXqZWe",
        "row": 20762,
        "state": "",
        "group": "",
        "group_num": [
            "recr0stUTTDA2zpmI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 51,
        "id": "rec2SVulrtD1DXJ3B",
        "row": 20793,
        "state": "",
        "group": "",
        "group_num": [
            "rec9atgaXVqvgRI8m"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "id": "rec4F8PC1fSvIvvYg",
        "row": 20794,
        "state": "",
        "group": "017830001000703",
        "group_num": [
            "recB2V9tE69UGEUOJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1123,
        "id": "rec4GmIE5rV31s4z7",
        "row": 20771,
        "state": "",
        "group": "017656301300044",
        "group_num": [
            "recvvFPq73Qy87sQe"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1166,
        "id": "rec4lzzFrfZDYOjpN",
        "row": 20783,
        "state": "",
        "group": "",
        "group_num": [
            "recQP5YlmvC3PkAWW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1043,
        "id": "rec6kpXpCOzw8DvSj",
        "row": 20802,
        "state": "",
        "group": "018125901100003",
        "group_num": [
            "rectRDnsQqcMc1DAK"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1071,
        "id": "rec913dTynLXjff7Z",
        "row": 20785,
        "state": "",
        "group": "",
        "group_num": [
            "recL0DA6nrdVIJ5mJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 642,
        "id": "rec9LiNGKwsljB7KC",
        "row": 20782,
        "state": "",
        "group": "",
        "group_num": [
            "recduXmnTRAOlxUp0"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "id": "recarKmfEN8Q9Lc30",
        "row": 20781,
        "state": "",
        "group": "",
        "group_num": [
            "recduXmnTRAOlxUp0"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recbanS8drHaExPfR",
        "row": 20792,
        "state": "",
        "group": "",
        "group_num": [
            "rec3t6f7GWYdPqIsV"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "reccI4HYBUMntpXF3",
        "row": 20769,
        "state": "",
        "group": "",
        "group_num": [
            "recsSSDUsCuv5DBXU"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1310,
        "id": "recoD63HtKbnIqVke",
        "row": 20790,
        "state": "",
        "group": "017704301000001",
        "group_num": [
            "recEAWJq4pZCDeJ9w"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 761,
        "id": "recpfpi1Q53il6MRY",
        "row": 20765,
        "state": "",
        "group": "",
        "group_num": [
            "reciCXcHyzTsMmU8G"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2180,
        "id": "recqebUpGwFPdGsnb",
        "row": 20776,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ptgmSeJKD4Ebj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 757,
        "id": "recrHPmAZlAqJjKPo",
        "row": 20786,
        "state": "",
        "group": "",
        "group_num": [
            "recL0DA6nrdVIJ5mJ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 221,
        "id": "rectf0f4aig8BsNwF",
        "row": 20758,
        "state": "",
        "group": "017591801100001",
        "group_num": [
            "rec3K2JXFzdkE1nJq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3107,
        "id": "recxYpECbWexoszSH",
        "row": 20789,
        "state": "",
        "group": "017704301000001",
        "group_num": [
            "recEAWJq4pZCDeJ9w"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "recyXCKkSkOO7vUKY",
        "row": 20805,
        "state": "",
        "group": "",
        "group_num": [
            "recrkuxVARD3J3slG"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 210,
        "id": "recy7neIYQHAQeIWq",
        "row": 20778,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ptgmSeJKD4Ebj"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 111,
        "id": "reczdBy8JW8HTC2EF",
        "row": 20763,
        "state": "",
        "group": "",
        "group_num": [
            "recfLS8zcr81ZzKtk"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 904,
        "id": "recAQPXBr0LHYgy0o",
        "row": 20761,
        "state": "",
        "group": "",
        "group_num": [
            "recr0stUTTDA2zpmI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 328,
        "id": "recA5NB1cqVgCLZjf",
        "row": 20779,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ptgmSeJKD4Ebj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1115,
        "id": "recFLyESm8MxmB3JO",
        "row": 20767,
        "state": "",
        "group": "",
        "group_num": [
            "recTAjjl45NWZPZfI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 831,
        "id": "recGKAXvnRt2UE8MZ",
        "row": 20800,
        "state": "",
        "group": "018118402400003",
        "group_num": [
            "recZ8bf0uW3cKGIhV"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recHlTFoqEQuaMR1J",
        "row": 20791,
        "state": "",
        "group": "017704301000001",
        "group_num": [
            "recEAWJq4pZCDeJ9w"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "recJ2Wsa4r5rnH28P",
        "row": 20759,
        "state": "",
        "group": "017591801100001",
        "group_num": [
            "rec3K2JXFzdkE1nJq"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1023,
        "id": "recJqLSA8BHbKESsj",
        "row": 20799,
        "state": "",
        "group": "",
        "group_num": [
            "recl5jq5l9k0Ku4sX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 330,
        "id": "recKyZihp30UD8voO",
        "row": 20766,
        "state": "",
        "group": "",
        "group_num": [
            "reciCXcHyzTsMmU8G"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1080,
        "id": "recK8617NROIZESvl",
        "row": 20775,
        "state": "",
        "group": "017657802200005",
        "group_num": [
            "recrsNdkxlW77fRJ5"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recLah8qOeYVhJoYE",
        "row": 20795,
        "state": "",
        "group": "",
        "group_num": [
            "recHfUddUqbPhuuQq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recNQyJuKX40UUimg",
        "row": 20772,
        "state": "",
        "group": "",
        "group_num": [
            "recGAEj77X9w0i01N"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2398,
        "id": "receNH1AAWto9NRut",
        "row": 20820,
        "state": "",
        "group": "018623901200001",
        "group_num": [
            "recSU090WmlYdHV6G"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 904,
        "id": "recfUjNJPMBr5J8lK",
        "row": 20818,
        "state": "",
        "group": "018623901100501",
        "group_num": [
            "recffcumCxVTNGpwE"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1726,
        "2023": 1280,
        "id": "recgVLIsgjGPQoJgA",
        "row": 20814,
        "state": "",
        "group": "",
        "group_num": [
            "rec8BnmZTaPpQPh0T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recgKhVajSJtOsvsG",
        "row": 20844,
        "state": "",
        "group": "",
        "group_num": [
            "rec9eTkXOXwxQYqo6"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2364,
        "id": "rechcpbrlLOeGijfR",
        "row": 20812,
        "state": "",
        "group": "",
        "group_num": [
            "reccPCg4sjcYcFsdj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "id": "recnm60rldJT3FBBf",
        "row": 20838,
        "state": "",
        "group": "",
        "group_num": [
            "rec3D4VoVuuuLadpY"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 453,
        "id": "recQiAJjzsyEX7mgQ",
        "row": 20836,
        "state": "",
        "group": "",
        "group_num": [
            "reclxlyYnLXbjWjHq"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 867,
        "id": "recRWfdvAPkMJDJ2j",
        "row": 20808,
        "state": "",
        "group": "",
        "group_num": [
            "recJuYftpXa0cYOrC"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 631,
        "id": "recSJLrEYRMT4BtFi",
        "row": 20855,
        "state": "",
        "group": "",
        "group_num": [
            "rec9JyHogIaJ34GrY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2612,
        "id": "recTTcGElemkO4KUi",
        "row": 20848,
        "state": "",
        "group": "",
        "group_num": [
            "rec9TgoUDVXxisjtf"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1568,
        "id": "recT0g2Kw5jOTSdUO",
        "row": 20824,
        "state": "",
        "group": "",
        "group_num": [
            "rec85Y4ZGWMtHdfsW"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1103,
        "id": "recUJMyNZkt7CfN1V",
        "row": 20821,
        "state": "",
        "group": "018623901200001",
        "group_num": [
            "recSU090WmlYdHV6G"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 653,
        "2024": 973,
        "id": "recVVmPPrhnS4ZDEO",
        "row": 20833,
        "state": "",
        "group": "",
        "group_num": [
            "recaUUXqzC8KmU2d1"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 869,
        "id": "recVzylmWsSNENAnP",
        "row": 20822,
        "state": "",
        "group": "018623901200001",
        "group_num": [
            "recSU090WmlYdHV6G"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recVJ9pNGTjM7jTlD",
        "row": 20809,
        "state": "",
        "group": "",
        "group_num": [
            "recHDHY460Avgil8s"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1054,
        "id": "recWHvnRAgcKOXLSi",
        "row": 20852,
        "state": "",
        "group": "019723901000701",
        "group_num": [
            "recrzbB37OzoSsEKq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recWIyMbglcyf5tIY",
        "row": 20827,
        "state": "",
        "group": "",
        "group_num": [
            "recgdIqp0R2VEQQZO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recXyMgeAxFpw2T2y",
        "row": 20843,
        "state": "",
        "group": "019303901100001",
        "group_num": [
            "recRCoS1SDath3wHE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 448,
        "id": "recXhLZKniOPYUFYx",
        "row": 20811,
        "state": "",
        "group": "",
        "group_num": [
            "reccPCg4sjcYcFsdj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1895,
        "id": "recXihE8A9HbNFKYh",
        "row": 20842,
        "state": "",
        "group": "",
        "group_num": [
            "recq1FB6RbYtOod3W"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1566,
        "id": "recYKqxTmbLpQ3LAS",
        "row": 20840,
        "state": "",
        "group": "",
        "group_num": [
            "recq1FB6RbYtOod3W"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 286,
        "id": "recYQ1BaK1Su3bCVB",
        "row": 20835,
        "state": "",
        "group": "",
        "group_num": [
            "recHUok7dQec1tZwx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1645,
        "id": "recY3ZVsownfCjKs2",
        "row": 20817,
        "state": "",
        "group": "",
        "group_num": [
            "rec99L0x1REVmcx17"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1628,
        "2023": 1323,
        "id": "recYbcAwernO0uNSn",
        "row": 20834,
        "state": "",
        "group": "018744301000001",
        "group_num": [
            "reccnXe0EDDQfXAEO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recZ4Ya0ujVoXcfU3",
        "row": 20826,
        "state": "",
        "group": "018623901500001",
        "group_num": [
            "reccZUqYDXOC8ixKS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 148,
        "id": "recZ9yW417iACOokg",
        "row": 20856,
        "state": "",
        "group": "",
        "group_num": [
            "recMk9CXXrD12luqU"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "rec08Pxh73OeOBwUB",
        "row": 20831,
        "state": "",
        "group": "",
        "group_num": [
            "recrijRwttwpTw5Hn"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec1WuZFoAcP92FZ1",
        "row": 20841,
        "state": "",
        "group": "",
        "group_num": [
            "recq1FB6RbYtOod3W"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 428,
        "id": "rec3NYBSwycQROjyk",
        "row": 20837,
        "state": "",
        "group": "",
        "group_num": [
            "rec3D4VoVuuuLadpY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1955,
        "id": "rec7IzrKpiJLzrZ7M",
        "row": 20847,
        "state": "",
        "group": "",
        "group_num": [
            "rec9TgoUDVXxisjtf"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1558,
        "id": "rec7QyB8auWkI8YaT",
        "row": 20815,
        "state": "",
        "group": "",
        "group_num": [
            "rec8BnmZTaPpQPh0T"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "reccH4XihHiZp3ju6",
        "row": 20825,
        "state": "",
        "group": "",
        "group_num": [
            "rec85Y4ZGWMtHdfsW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "2024": 1283,
        "id": "recpy2z8Fys9Inex1",
        "row": 20810,
        "state": "",
        "group": "",
        "group_num": [
            "recHDHY460Avgil8s"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "id": "recpIWZlErczmcusS",
        "row": 20832,
        "state": "",
        "group": "",
        "group_num": [
            "recrijRwttwpTw5Hn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recqPaAoLdArorWzu",
        "row": 20850,
        "state": "",
        "group": "",
        "group_num": [
            "recr4xsZdRU7A1T17"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 955,
        "id": "recr1nUHFAGRlf90g",
        "row": 20829,
        "state": "",
        "group": "",
        "group_num": [
            "recrijRwttwpTw5Hn"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "rect1JqVeMu4Yo9B5",
        "row": 20854,
        "state": "",
        "group": "",
        "group_num": [
            "recvetBYubf8evKIT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recw0lnWwus8PwKY9",
        "row": 20846,
        "state": "",
        "group": "",
        "group_num": [
            "rech5dqgYzvRSgvdS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1539,
        "id": "recB5hhtVFvUgynop",
        "row": 20849,
        "state": "",
        "group": "",
        "group_num": [
            "recZtWv921gT4XLcm"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3072,
        "id": "recDAmIFoPZd7CJIf",
        "row": 20845,
        "state": "",
        "group": "",
        "group_num": [
            "rech5dqgYzvRSgvdS"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1137,
        "id": "recDBnPrPzw2NjIku",
        "row": 20851,
        "state": "",
        "group": "019723901000701",
        "group_num": [
            "recrzbB37OzoSsEKq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 996,
        "id": "recFEWfKZdgHXrA2H",
        "row": 20823,
        "state": "",
        "group": "018623901200001",
        "group_num": [
            "recSU090WmlYdHV6G"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 559,
        "id": "recGT9APYt7RXhxxy",
        "row": 20813,
        "state": "",
        "group": "",
        "group_num": [
            "recBDVh3k7BXUjWFo"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 389,
        "id": "recGU3OcKoy7DpF1g",
        "row": 20839,
        "state": "",
        "group": "",
        "group_num": [
            "rec3D4VoVuuuLadpY"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2420,
        "2024": 664,
        "id": "recHbOhIXZogpxyek",
        "row": 20816,
        "state": "",
        "group": "",
        "group_num": [
            "rec99L0x1REVmcx17"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recITl5y7klTssjmA",
        "row": 20828,
        "state": "",
        "group": "",
        "group_num": [
            "recX3qfJn11bUJvoo"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 969,
        "id": "recJA3rUf15D2ekch",
        "row": 20830,
        "state": "",
        "group": "",
        "group_num": [
            "recrijRwttwpTw5Hn"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1056,
        "id": "recLLPN9YMygKGdFH",
        "row": 20853,
        "state": "",
        "group": "",
        "group_num": [
            "recxdY4JS9OXSILru"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recMUntPTZCfzVpCc",
        "row": 20819,
        "state": "",
        "group": "018623901100501",
        "group_num": [
            "recffcumCxVTNGpwE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "recMALSDX5eelRZU7",
        "row": 20807,
        "state": "",
        "group": "",
        "group_num": [
            "recJuYftpXa0cYOrC"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 102,
        "id": "recfBBo0oMOGRiJue",
        "row": 20894,
        "state": "",
        "group": "028562901100006",
        "group_num": [
            "recP55oXzyQ8g9Eg9"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 410,
        "id": "recgsK87hydcUVnJb",
        "row": 20890,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bgn2CgIr7zFza"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 469,
        "id": "recgOHD26VbIgOhHQ",
        "row": 20870,
        "state": "",
        "group": "028400102100001",
        "group_num": [
            "recmZea2Xb9lhNr1t"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recg9d01uyL5o7gQ2",
        "row": 20879,
        "state": "",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recgrgcZn2pP9WRXE",
        "row": 20864,
        "state": "",
        "group": "",
        "group_num": [
            "recRhQdqIyBvSzWPw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 674,
        "2024": 148,
        "id": "rechCefrAIihXZIgT",
        "row": 20892,
        "state": "",
        "group": "028562901000004",
        "group_num": [
            "recFNgy32AfpQAJPJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 242,
        "id": "rechlBreRXOb5ioiv",
        "row": 20887,
        "state": "",
        "group": "028559301500230",
        "group_num": [
            "recExs6EyXE2HtqbR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "reciysLEnSzPoJIFb",
        "row": 20862,
        "state": "",
        "group": "",
        "group_num": [
            "recwCgu4ZCZtYFkMz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 845,
        "2023": 985,
        "2024": 559,
        "id": "recjPdz9xUoAUrWdw",
        "row": 20903,
        "state": "",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "recj8kX7d7ahFjjFU",
        "row": 20858,
        "state": "",
        "group": "",
        "group_num": [
            "rectIHsjg8T7IWcUY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 457,
        "2024": 476,
        "id": "reck2pPAYBVGZdf8F",
        "row": 20881,
        "state": "",
        "group": "",
        "group_num": [
            "recZSfiprSvFDvxVc"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2916,
        "2024": 1062,
        "id": "recl1N0NTSFZZ3arI",
        "row": 20891,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bgn2CgIr7zFza"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 298,
        "id": "recmtoJB8IiTLlLEn",
        "row": 20895,
        "state": "",
        "group": "028562901100006",
        "group_num": [
            "recP55oXzyQ8g9Eg9"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2205,
        "id": "recmGbe5kPNZYlQBS",
        "row": 20893,
        "state": "",
        "group": "028562901100006",
        "group_num": [
            "recP55oXzyQ8g9Eg9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 448,
        "id": "recPeeUWtzkoksDvy",
        "row": 20872,
        "state": "",
        "group": "028400102400001",
        "group_num": [
            "recRC4WyxdK1Ozx8Z"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recRMHY1Z0HwM78VS",
        "row": 20901,
        "state": "",
        "group": "028565801000001",
        "group_num": [
            "recJG2m2oyzicsblI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "id": "recV0agSMW98umVPX",
        "row": 20871,
        "state": "",
        "group": "028400102400001",
        "group_num": [
            "recRC4WyxdK1Ozx8Z"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 196,
        "id": "recYE2nA6X4PhyiNX",
        "row": 20882,
        "state": "",
        "group": "",
        "group_num": [
            "recZSfiprSvFDvxVc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2372,
        "2023": 2314,
        "id": "recYbaLBoHHNx3wNd",
        "row": 20896,
        "state": "",
        "group": "028562901100006",
        "group_num": [
            "recP55oXzyQ8g9Eg9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recYjsJKgKm5SJgo9",
        "row": 20885,
        "state": "",
        "group": "",
        "group_num": [
            "rec3k483QAFYe5jpR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 1459,
        "id": "recZ5Znt47iyImi1c",
        "row": 20863,
        "state": "",
        "group": "023173101500021",
        "group_num": [
            "recz7gJxbuVW4B0SY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 901,
        "2023": 1084,
        "id": "rec2K4obLGnA8K57A",
        "row": 20859,
        "state": "",
        "group": "023173101400021",
        "group_num": [
            "recDOQ1rPJ3szPXFm"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1397,
        "id": "rec3lFZweiwRROFq1",
        "row": 20865,
        "state": "",
        "group": "",
        "group_num": [
            "rect0jo7lUccmi38M"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 559,
        "2024": 559,
        "id": "rec4JImUqXf1lobVd",
        "row": 20877,
        "state": "",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 308,
        "id": "rec60dX7HmtgE6b2n",
        "row": 20883,
        "state": "",
        "group": "028559301500020",
        "group_num": [
            "recPiVwS5pLoNlNrw"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1494,
        "id": "rec7TzwQq61tQzUCG",
        "row": 20857,
        "state": "",
        "group": "",
        "group_num": [
            "rectIHsjg8T7IWcUY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2534,
        "id": "rec7Kkawyt7BbNOrA",
        "row": 20888,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bgn2CgIr7zFza"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1467,
        "id": "rec79hcmeQ2K1HCnh",
        "row": 20884,
        "state": "",
        "group": "028559301500020",
        "group_num": [
            "recPiVwS5pLoNlNrw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "id": "rec8Jn4NBfEifTlGM",
        "row": 20869,
        "state": "",
        "group": "028390701600001",
        "group_num": [
            "recb1RGSM4w4kPnfG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4344,
        "id": "rec8rM5evW9crLYHE",
        "row": 20899,
        "state": "",
        "group": "",
        "group_num": [
            "recx8hfZBYvXUumJR"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 1084,
        "2024": 1190,
        "id": "rec9cZV1Qz64vtpzo",
        "row": 20905,
        "state": "",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 1530,
        "id": "recabVFfE55HSQTFX",
        "row": 20902,
        "state": "",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 838,
        "2024": 599,
        "id": "recbWRSZBKm3rkzve",
        "row": 20898,
        "state": "",
        "group": "",
        "group_num": [
            "recSXt7nyhKFW45X1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1289,
        "id": "recdtf9Zeo5y8Gz3i",
        "row": 20906,
        "state": "",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 476,
        "id": "recdFWE0wzcMwr5nW",
        "row": 20876,
        "state": "",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 193,
        "id": "recoWPeNWCRbW6CS1",
        "row": 20875,
        "state": "",
        "group": "028554803000001",
        "group_num": [
            "recRtJXn3ASmjkNZ2"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 709,
        "id": "recoxdu3LjLHE7DzR",
        "row": 20889,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bgn2CgIr7zFza"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 874,
        "2024": 925,
        "id": "recqdvGGLkxDg1d2u",
        "row": 20874,
        "state": "",
        "group": "",
        "group_num": [
            "rec2grEQyJhGWxMtA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "2024": 494,
        "id": "recr2m6wYOxJDurAd",
        "row": 20900,
        "state": "",
        "group": "",
        "group_num": [
            "recVgBTcn4rtk6TS2"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1254,
        "id": "recwDjj3qJUEbm8h7",
        "row": 20868,
        "state": "",
        "group": "",
        "group_num": [
            "recMb8upS3SGQr2t8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "id": "recwHU4w6FUOmE5xs",
        "row": 20860,
        "state": "",
        "group": "",
        "group_num": [
            "receRKOk8fkULKH6Z"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recxEh19XIMFbea7p",
        "row": 20880,
        "state": "",
        "group": "",
        "group_num": [
            "rec5DtsreZxHF6eIu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 559,
        "id": "recy5bZd6cSjWbFYb",
        "row": 20886,
        "state": "",
        "group": "028559301500230",
        "group_num": [
            "recExs6EyXE2HtqbR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 380,
        "id": "reczzgrILgC23rmwX",
        "row": 20866,
        "state": "",
        "group": "",
        "group_num": [
            "rect0jo7lUccmi38M"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 52,
        "id": "recCidwu9lbiyGpX6",
        "row": 20897,
        "state": "",
        "group": "",
        "group_num": [
            "recZzwtyzHwBmVWjo"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 684,
        "2023": 684,
        "id": "recElks0EE7UwS869",
        "row": 20867,
        "state": "",
        "group": "",
        "group_num": [
            "recO9rBDfZXwj64kK"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recFEfSZSqngz7ftl",
        "row": 20873,
        "state": "",
        "group": "",
        "group_num": [
            "recs7q2xksyJNsinx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 642,
        "id": "recGyjdJ7EpYvepWl",
        "row": 20861,
        "state": "",
        "group": "",
        "group_num": [
            "receRKOk8fkULKH6Z"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "2024": 642,
        "id": "recG6czOkJyYJlg9p",
        "row": 20904,
        "state": "",
        "group": "028573102000001",
        "group_num": [
            "recQtWhv9IbDuuQYh"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 131,
        "id": "recJrNIOUUCT0qskH",
        "row": 20878,
        "state": "",
        "group": "028559301000202",
        "group_num": [
            "recy0sktS1iD8CSMa"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 620,
        "id": "recg5NWYYcDoZRH1v",
        "row": 20921,
        "state": "",
        "group": "030349501000709",
        "group_num": [
            "recKU8CDWJjGEnJjl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "2023": 325,
        "id": "recgm5Q7qnHjrFd4q",
        "row": 20911,
        "state": "",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1434,
        "id": "recippCubXeV2czxg",
        "row": 20924,
        "state": "",
        "group": "",
        "group_num": [
            "reccvWRiCb19fYAHY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2104,
        "id": "recl3SA64GrtSf32I",
        "row": 20925,
        "state": "",
        "group": "",
        "group_num": [
            "reccvWRiCb19fYAHY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 49,
        "id": "recmVARNU1gchId4d",
        "row": 20932,
        "state": "",
        "group": "",
        "group_num": [
            "recjWQf2xOpNlcz6B"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "id": "recOdDda1qJh4RIXH",
        "row": 20936,
        "state": "",
        "group": "032640201000004",
        "group_num": [
            "rec95FtemycKPB9Bs"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 953,
        "2023": 1062,
        "2024": 660,
        "id": "recPN2u5M71zo6sJW",
        "row": 20910,
        "state": "",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 662,
        "id": "recQwNdBq8xJIHPui",
        "row": 20918,
        "state": "",
        "group": "",
        "group_num": [
            "reccmDNAK5w17489B"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2180,
        "id": "recRnWPdPY4NqYyU8",
        "row": 20930,
        "state": "",
        "group": "",
        "group_num": [
            "recjWQf2xOpNlcz6B"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3413,
        "id": "recSrVcIdcliL9YJ7",
        "row": 20950,
        "state": "",
        "group": "",
        "group_num": [
            "recpqWqxgGPHk62m8"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "2023": 1352,
        "id": "recVWe9pyfetiVcxn",
        "row": 20952,
        "state": "",
        "group": "",
        "group_num": [
            "recZZidSlHFaf5ifs"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1009,
        "id": "recVyPNKDWzQU3sxl",
        "row": 20909,
        "state": "",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recVDD9sRdmZRSrXX",
        "row": 20908,
        "state": "",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recVilannJ1aOJNIb",
        "row": 20928,
        "state": "",
        "group": "",
        "group_num": [
            "recg59QpnExgOA7Sv"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4394,
        "id": "recX5nra0aQnpLI5l",
        "row": 20945,
        "state": "",
        "group": "",
        "group_num": [
            "rechEXKhRHMP1Wydc"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 852,
        "id": "recYeruSUCG3jwhDe",
        "row": 20937,
        "state": "",
        "group": "",
        "group_num": [
            "rec5HRx5OuKhlBKFG"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2662,
        "id": "recZY8iLaDJD5unAx",
        "row": 20938,
        "state": "",
        "group": "",
        "group_num": [
            "rec5HRx5OuKhlBKFG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1901,
        "id": "recZ9RzZZlTFMEFuF",
        "row": 20955,
        "state": "",
        "group": "",
        "group_num": [
            "recZZidSlHFaf5ifs"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 401,
        "2024": 303,
        "id": "rec1uTaDCn60dPnzD",
        "row": 20940,
        "state": "",
        "group": "",
        "group_num": [
            "recC6KJbgpGmxzLG1"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "rec1ImxkAPzL86Wqj",
        "row": 20934,
        "state": "",
        "group": "031483203300101",
        "group_num": [
            "rec3vku7V7Tmq2vjB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "id": "rec2mphi1HqQ8Rt9a",
        "row": 20926,
        "state": "",
        "group": "030717701300201",
        "group_num": [
            "recmiPj5aJxkI7T4p"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 673,
        "id": "rec5qQZ03fgprCZ7M",
        "row": 20927,
        "state": "",
        "group": "",
        "group_num": [
            "recg59QpnExgOA7Sv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 427,
        "2023": 478,
        "id": "rec8p7Hj1KUwW0wlA",
        "row": 20913,
        "state": "",
        "group": "",
        "group_num": [
            "rechuy8ZgmZukE3we"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1767,
        "id": "rec95mDLZpKLPUQWc",
        "row": 20942,
        "state": "",
        "group": "",
        "group_num": [
            "recrFhJTk3IQ4ahto"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 481,
        "id": "recaP5XnZNxlk5Mg1",
        "row": 20956,
        "state": "",
        "group": "",
        "group_num": [
            "recPJPpP1NriaItDp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1320,
        "id": "recbMYxMlq5Ed7dzb",
        "row": 20954,
        "state": "",
        "group": "",
        "group_num": [
            "recZZidSlHFaf5ifs"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2755,
        "id": "recb9M83uC8QJrCUe",
        "row": 20944,
        "state": "",
        "group": "",
        "group_num": [
            "recK1nu2A7DAcb7KE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1805,
        "2023": 1888,
        "id": "reccWTlLqqdN9qjcB",
        "row": 20943,
        "state": "",
        "group": "",
        "group_num": [
            "recK1nu2A7DAcb7KE"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1932,
        "id": "recd2LJYCCPOPkmeU",
        "row": 20949,
        "state": "",
        "group": "",
        "group_num": [
            "recpqWqxgGPHk62m8"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "2023": 1190,
        "2024": 1190,
        "id": "recoSUk04SOVaCQ0f",
        "row": 20912,
        "state": "",
        "group": "028573105500001",
        "group_num": [
            "recNIPXHkg4hzyoI9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 982,
        "id": "recokAl5f5X41rDnN",
        "row": 20935,
        "state": "",
        "group": "",
        "group_num": [
            "receNvEHtkFgx1NTp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1035,
        "id": "recqHY7q4b9JYTYy5",
        "row": 20953,
        "state": "",
        "group": "",
        "group_num": [
            "recZZidSlHFaf5ifs"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 608,
        "id": "recr3ScLsbnCIUeej",
        "row": 20939,
        "state": "",
        "group": "",
        "group_num": [
            "recC6KJbgpGmxzLG1"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 355,
        "2023": 357,
        "id": "rectrnKku93JJkwco",
        "row": 20948,
        "state": "",
        "group": "",
        "group_num": [
            "recI6ZUIItCrWS9ZR"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 864,
        "id": "recvaWxJA9MYX5yHg",
        "row": 20933,
        "state": "",
        "group": "",
        "group_num": [
            "recjWQf2xOpNlcz6B"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 330,
        "id": "recxh1LDo70XKzLhK",
        "row": 20919,
        "state": "",
        "group": "",
        "group_num": [
            "reccmDNAK5w17489B"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1151,
        "id": "recyCt9ZiQ46YT5TD",
        "row": 20929,
        "state": "",
        "group": "",
        "group_num": [
            "recZJ3uyR5F8AJReI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "id": "recybANYeb9KtYKO6",
        "row": 20922,
        "state": "",
        "group": "030349501000709",
        "group_num": [
            "recKU8CDWJjGEnJjl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 374,
        "2023": 480,
        "id": "recydcmlloNKkbZF4",
        "row": 20947,
        "state": "",
        "group": "",
        "group_num": [
            "recI6ZUIItCrWS9ZR"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1336,
        "id": "reczSQVd7I8cKRvVI",
        "row": 20914,
        "state": "",
        "group": "",
        "group_num": [
            "reczxvwZNWAswFhSl"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 148,
        "id": "reczcR1EjpxfZzqt5",
        "row": 20920,
        "state": "",
        "group": "",
        "group_num": [
            "reco4VWhItICrPb2t"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 494,
        "id": "recCog0kSi52AomLX",
        "row": 20951,
        "state": "",
        "group": "",
        "group_num": [
            "recyOQ7AqzRvHkYRg"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1325,
        "2023": 1325,
        "id": "recDGYkYQjaLhI9ov",
        "row": 20931,
        "state": "",
        "group": "",
        "group_num": [
            "recjWQf2xOpNlcz6B"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 639,
        "id": "recIRH6DqDSz5nAVP",
        "row": 20923,
        "state": "",
        "group": "030349501000709",
        "group_num": [
            "recKU8CDWJjGEnJjl"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recI21Ye3bLCuoWJF",
        "row": 20915,
        "state": "",
        "group": "",
        "group_num": [
            "reczxvwZNWAswFhSl"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 497,
        "2023": 570,
        "id": "recJA2abEscfvkbKa",
        "row": 20916,
        "state": "",
        "group": "",
        "group_num": [
            "reczxvwZNWAswFhSl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recJ2jMM9lfLIzJXR",
        "row": 20907,
        "state": "",
        "group": "028573104000001",
        "group_num": [
            "rec27dko7AS55nQ4T"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 535,
        "2024": 451,
        "id": "recJ83p8U5Q5qsEgy",
        "row": 20941,
        "state": "",
        "group": "",
        "group_num": [
            "recC6KJbgpGmxzLG1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1035,
        "id": "recKeQn4LxzyyNFlg",
        "row": 20917,
        "state": "",
        "group": "",
        "group_num": [
            "recyEiRlEvKAPqK0v"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 437,
        "2023": 494,
        "id": "recLkVDJ3Ok4l8Dqm",
        "row": 20946,
        "state": "",
        "group": "",
        "group_num": [
            "recI6ZUIItCrWS9ZR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "receDUz3omOd33rc6",
        "row": 21005,
        "state": "",
        "group": "",
        "group_num": [
            "recAP7EaC01NRBExE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 865,
        "id": "recfDrRckWnMs8aAE",
        "row": 20968,
        "state": "",
        "group": "",
        "group_num": [
            "recJd0bBFT260i06G"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "2023": 4969,
        "id": "recgIT62eT40PKFda",
        "row": 20972,
        "state": "",
        "group": "039363002700002",
        "group_num": [
            "rec1SWhtaGeLxnznw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1749,
        "id": "recg8cy4vivCi3V1S",
        "row": 20965,
        "state": "",
        "group": "039363001900011",
        "group_num": [
            "rec0Chf1ZClUkoFMJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "id": "recjftN5fz8B18sFS",
        "row": 20963,
        "state": "",
        "group": "",
        "group_num": [
            "recqHHHeaop4MZbKe"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "2023": 1020,
        "id": "reclS0YrVOyxzex5Y",
        "row": 20993,
        "state": "",
        "group": "",
        "group_num": [
            "recyk0bWLZBY7IUtW"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "id": "recmWoXKPwfYxyONp",
        "row": 20957,
        "state": "",
        "group": "034280601800171",
        "group_num": [
            "rectLgYAqn5QrG7Xy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3145,
        "id": "recmMmQdixKUKOtah",
        "row": 20959,
        "state": "",
        "group": "034280602200001",
        "group_num": [
            "rec8IMSQ6FCEs1AqD"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1377,
        "id": "recm8bEEwpVf5yych",
        "row": 20960,
        "state": "",
        "group": "034280602200001",
        "group_num": [
            "rec8IMSQ6FCEs1AqD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 48,
        "id": "recRLTFsKSMKnZpRD",
        "row": 20977,
        "state": "",
        "group": "046605601800023",
        "group_num": [
            "rec8nI3qyoWLDrCqu"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recR4XY9SQez7qTcx",
        "row": 20996,
        "state": "",
        "group": "",
        "group_num": [
            "recyk0bWLZBY7IUtW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 522,
        "id": "recTs8LMtWf9MUnus",
        "row": 20967,
        "state": "",
        "group": "",
        "group_num": [
            "recaE7FKQL4qguump"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recTHSxIT9T96QgJa",
        "row": 21001,
        "state": "",
        "group": "046983001600001",
        "group_num": [
            "recXgCMFmQZwFMv05"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "id": "recTqeozxBmk3eWnR",
        "row": 20987,
        "state": "",
        "group": "",
        "group_num": [
            "reciGSjMPJ9K4UTFr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 189,
        "id": "recWvZdNUov0Ia3GA",
        "row": 20975,
        "state": "",
        "group": "046605601800015",
        "group_num": [
            "recVQCjTGkVrD3fRu"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 106,
        "id": "recYggodM5EpO5Unm",
        "row": 21006,
        "state": "",
        "group": "",
        "group_num": [
            "reclysLbVhvHCyiLR"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1004,
        "id": "recZmNO5YFNi8JuVY",
        "row": 20982,
        "state": "",
        "group": "",
        "group_num": [
            "recOZGk3bXK2J61KW"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rec048fl799oO5V1v",
        "row": 21000,
        "state": "",
        "group": "046983001600001",
        "group_num": [
            "recXgCMFmQZwFMv05"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 119,
        "2023": 158,
        "id": "rec1qWteU9waDDrxU",
        "row": 20989,
        "state": "",
        "group": "",
        "group_num": [
            "recPuSdV7BVBAukJj"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1526,
        "id": "rec2KtAaYQ73uWZ3b",
        "row": 20973,
        "state": "",
        "group": "039363002700008",
        "group_num": [
            "recAs5L4y6VDGTgOs"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "rec2Q4NmYbMxoG5dY",
        "row": 20980,
        "state": "",
        "group": "",
        "group_num": [
            "recw7QzLuDVXxCfBe"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 724,
        "id": "rec3iZKWgnizk3ojY",
        "row": 21002,
        "state": "",
        "group": "047489803400101",
        "group_num": [
            "rec19Ko25GkKNseyw"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1306,
        "id": "rec4LH3IKjxrxp3S3",
        "row": 20981,
        "state": "",
        "group": "",
        "group_num": [
            "recw7QzLuDVXxCfBe"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "2024": 22,
        "id": "rec4fsB2KkNBtaGTL",
        "row": 20983,
        "state": "",
        "group": "",
        "group_num": [
            "recOZGk3bXK2J61KW"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1928,
        "id": "rec5advUBJGM5SZkd",
        "row": 20974,
        "state": "",
        "group": "039363002700008",
        "group_num": [
            "recAs5L4y6VDGTgOs"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2443,
        "id": "rec5heqbwA7o85wVQ",
        "row": 20999,
        "state": "",
        "group": "",
        "group_num": [
            "reccIdUBFVhflkifn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2104,
        "id": "rec7QqxCWfBpKePqg",
        "row": 20985,
        "state": "",
        "group": "",
        "group_num": [
            "rec3HB51JMolynMAW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1333,
        "id": "rec8W0GPeySfBgqJu",
        "row": 20988,
        "state": "",
        "group": "",
        "group_num": [
            "reciGSjMPJ9K4UTFr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "2023": 240,
        "id": "rec8Oe7DvJhmf2z24",
        "row": 20991,
        "state": "",
        "group": "",
        "group_num": [
            "recnWmUqvbYQZINAE"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "2023": 838,
        "id": "reccCnDYTnCDTIMbR",
        "row": 20990,
        "state": "",
        "group": "",
        "group_num": [
            "recPuSdV7BVBAukJj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1901,
        "id": "recdxEBnwHht1UemD",
        "row": 20964,
        "state": "",
        "group": "",
        "group_num": [
            "recqHHHeaop4MZbKe"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "2024": 725,
        "id": "recoTxh2WbtJZxvWr",
        "row": 20984,
        "state": "",
        "group": "",
        "group_num": [
            "recOZGk3bXK2J61KW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1720,
        "id": "rectIWwHO3UU0dajS",
        "row": 20995,
        "state": "",
        "group": "",
        "group_num": [
            "recyk0bWLZBY7IUtW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "recuWd2LaoggH5523",
        "row": 20998,
        "state": "",
        "group": "",
        "group_num": [
            "reccIdUBFVhflkifn"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "id": "recvUXBEb6Jz0DkgQ",
        "row": 20976,
        "state": "",
        "group": "046605601800015",
        "group_num": [
            "recVQCjTGkVrD3fRu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 1000,
        "id": "recvPipg43d84dycR",
        "row": 20971,
        "state": "",
        "group": "039363002700002",
        "group_num": [
            "rec1SWhtaGeLxnznw"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 516,
        "id": "recw0FTVlqAb55MOu",
        "row": 20992,
        "state": "",
        "group": "",
        "group_num": [
            "recnWmUqvbYQZINAE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1338,
        "id": "recwqnMClXx3slFEH",
        "row": 20958,
        "state": "",
        "group": "034280601800171",
        "group_num": [
            "rectLgYAqn5QrG7Xy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 1057,
        "id": "recxRNw4gyNgCRZd7",
        "row": 20970,
        "state": "",
        "group": "039363002700002",
        "group_num": [
            "rec1SWhtaGeLxnznw"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "id": "recy6wXF34SoGYouk",
        "row": 21004,
        "state": "",
        "group": "",
        "group_num": [
            "recAP7EaC01NRBExE"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recBw5kd5nv8cBjSQ",
        "row": 20961,
        "state": "",
        "group": "",
        "group_num": [
            "recqHHHeaop4MZbKe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "id": "recCVophsnr2EZgh3",
        "row": 20962,
        "state": "",
        "group": "",
        "group_num": [
            "recqHHHeaop4MZbKe"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 329,
        "id": "recCzBSaxPhPmLOl8",
        "row": 21003,
        "state": "",
        "group": "",
        "group_num": [
            "recAP7EaC01NRBExE"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1789,
        "id": "recDMFsp8bjGEXhFS",
        "row": 20997,
        "state": "",
        "group": "",
        "group_num": [
            "reccIdUBFVhflkifn"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2385,
        "id": "recDavVngihz9yrJL",
        "row": 20978,
        "state": "",
        "group": "",
        "group_num": [
            "recw7QzLuDVXxCfBe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "id": "recEmK8D3xBmEaoiG",
        "row": 20979,
        "state": "",
        "group": "",
        "group_num": [
            "recw7QzLuDVXxCfBe"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 904,
        "id": "recFmkuwFWPgdvv3y",
        "row": 20986,
        "state": "",
        "group": "",
        "group_num": [
            "reciGSjMPJ9K4UTFr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2125,
        "id": "recJQz26H1tNFd1Fm",
        "row": 20994,
        "state": "",
        "group": "",
        "group_num": [
            "recyk0bWLZBY7IUtW"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 257,
        "id": "recKiYMg7jAU9uLq8",
        "row": 20966,
        "state": "",
        "group": "",
        "group_num": [
            "recaE7FKQL4qguump"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "2023": 3088,
        "id": "recLlu55p8d2JNY8q",
        "row": 20969,
        "state": "",
        "group": "039363002700002",
        "group_num": [
            "rec1SWhtaGeLxnznw"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1257,
        "id": "recednAyind0DVUEr",
        "row": 21052,
        "state": "",
        "group": "",
        "group_num": [
            "recOaghI1fUfEKE8Q"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recfNtXuXQlexHikq",
        "row": 21051,
        "state": "",
        "group": "048687501000101",
        "group_num": [
            "recAaVWr4tOr2vO7M"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2240,
        "id": "recff5Axkr7GEnphW",
        "row": 21043,
        "state": "",
        "group": "",
        "group_num": [
            "reczkAG6olh3v2xsx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3431,
        "id": "rechpFY8hkMgq6AVl",
        "row": 21049,
        "state": "",
        "group": "048686104800002",
        "group_num": [
            "reciG5ebUwZfdjH1g"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 977,
        "id": "reciehSAOGXijUXah",
        "row": 21009,
        "state": "",
        "group": "",
        "group_num": [
            "receNJAcvYVebpOvi"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recjb0R5ZyReXDpos",
        "row": 21010,
        "state": "",
        "group": "",
        "group_num": [
            "receNJAcvYVebpOvi"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1609,
        "2023": 1463,
        "id": "recjq1iR0R2Jwe09V",
        "row": 21013,
        "state": "",
        "group": "047500604100001",
        "group_num": [
            "recHLMcT9eHxic9MP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 934,
        "id": "reckSl7BCwhygBj1D",
        "row": 21017,
        "state": "",
        "group": "",
        "group_num": [
            "recPD6Fu9nBsz0qUR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3667,
        "id": "recksOpSxcVJRO2ro",
        "row": 21041,
        "state": "",
        "group": "047976601200010",
        "group_num": [
            "recQ3thXXZZpvu3qb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "reckNSUBSmSsmeUrS",
        "row": 21047,
        "state": "",
        "group": "048686104800002",
        "group_num": [
            "reciG5ebUwZfdjH1g"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "reclKBhcBvqrZAjEh",
        "row": 21016,
        "state": "",
        "group": "",
        "group_num": [
            "rectu3ZmZiOIxF9YJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 367,
        "id": "recm1o6oxYMwBDdoz",
        "row": 21019,
        "state": "",
        "group": "",
        "group_num": [
            "recPD6Fu9nBsz0qUR"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1539,
        "2024": 1539,
        "id": "recnCof2FU3Rzqw2S",
        "row": 21027,
        "state": "",
        "group": "",
        "group_num": [
            "recT5YjPl8IQ6zaBr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recnf5wdtW8ZQfuZ3",
        "row": 21026,
        "state": "",
        "group": "047659202000025",
        "group_num": [
            "recfJeKIMFYwtM9Iw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 371,
        "id": "recOrSxhXjqUF0jex",
        "row": 21011,
        "state": "",
        "group": "",
        "group_num": [
            "receNJAcvYVebpOvi"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recQ1qw3FRpxOmpSH",
        "row": 21054,
        "state": "",
        "group": "",
        "group_num": [
            "rec0Nl0yyECyeK2pc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 84,
        "id": "recRXuvH7ubFXCUqj",
        "row": 21036,
        "state": "",
        "group": "",
        "group_num": [
            "recy1knQVLhzL4nOn"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "recRpBgxFgWQ8fAGW",
        "row": 21008,
        "state": "",
        "group": "",
        "group_num": [
            "receNJAcvYVebpOvi"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1001,
        "id": "recSyc4OpsPQZjTti",
        "row": 21038,
        "state": "",
        "group": "",
        "group_num": [
            "recYc1erlIP459giW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 842,
        "id": "recSKxt0bRGaGGMjH",
        "row": 21018,
        "state": "",
        "group": "",
        "group_num": [
            "recPD6Fu9nBsz0qUR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 49,
        "id": "recSc9pPyCtOX0oj9",
        "row": 21033,
        "state": "",
        "group": "047676101100012",
        "group_num": [
            "recqQscaBkgZZvXch"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 846,
        "2024": 453,
        "id": "recSoT8egOV7ey38j",
        "row": 21048,
        "state": "",
        "group": "048686104800002",
        "group_num": [
            "reciG5ebUwZfdjH1g"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 710,
        "id": "recTj8TSzmiW5mMBl",
        "row": 21007,
        "state": "",
        "group": "",
        "group_num": [
            "reclysLbVhvHCyiLR"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2934,
        "id": "recU3nMXtLqPoeZ2D",
        "row": 21040,
        "state": "",
        "group": "",
        "group_num": [
            "recTPYl4UAmiqfFCo"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recU4yBX1Dmp5kipO",
        "row": 21042,
        "state": "",
        "group": "047976601200010",
        "group_num": [
            "recQ3thXXZZpvu3qb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1663,
        "2023": 439,
        "id": "recWTu65JK3R3zRMn",
        "row": 21012,
        "state": "",
        "group": "047500604100001",
        "group_num": [
            "recHLMcT9eHxic9MP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2241,
        "2023": 1950,
        "id": "recWEvTgqsXnEHw1s",
        "row": 21023,
        "state": "",
        "group": "",
        "group_num": [
            "rectkAj44jfGC9h1a"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "id": "recXDl31esfiuDlst",
        "row": 21055,
        "state": "",
        "group": "053155401000005",
        "group_num": [
            "rec2XPI7amIHfted7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "id": "recYyVdOSTeRF6cCK",
        "row": 21053,
        "state": "",
        "group": "",
        "group_num": [
            "recOaghI1fUfEKE8Q"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "id": "recYCjoESy7g54ZOb",
        "row": 21035,
        "state": "",
        "group": "047715801200006",
        "group_num": [
            "recLkwzrcRFnssVUU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 544,
        "id": "recZ78Os9MVUQv1uA",
        "row": 21020,
        "state": "",
        "group": "",
        "group_num": [
            "recPD6Fu9nBsz0qUR"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 550,
        "id": "rec6rIZoj3K7ApA6n",
        "row": 21028,
        "state": "",
        "group": "",
        "group_num": [
            "recT5YjPl8IQ6zaBr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 858,
        "id": "reccWvl3N5ykpPRzF",
        "row": 21024,
        "state": "",
        "group": "",
        "group_num": [
            "rectkAj44jfGC9h1a"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "2024": 700,
        "id": "recdf2q4VUvSIqRd6",
        "row": 21032,
        "state": "",
        "group": "047676101100012",
        "group_num": [
            "recqQscaBkgZZvXch"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3242,
        "id": "recoRgXnPXXTrU2KA",
        "row": 21021,
        "state": "",
        "group": "047655201400001",
        "group_num": [
            "recOg3s4GNq51BNii"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recpVFqDnTQNESP1f",
        "row": 21022,
        "state": "",
        "group": "047655201400001",
        "group_num": [
            "recOg3s4GNq51BNii"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1760,
        "id": "recqBpRmam8GlttxR",
        "row": 21045,
        "state": "",
        "group": "",
        "group_num": [
            "reczkAG6olh3v2xsx"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recrrdw5FZohvpQ9e",
        "row": 21056,
        "state": "",
        "group": "053155401000005",
        "group_num": [
            "rec2XPI7amIHfted7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 518,
        "id": "recu9CeYShfQ8yjXG",
        "row": 21050,
        "state": "",
        "group": "",
        "group_num": [
            "recjQl4ZJdKSbEL7L"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1596,
        "id": "recwg6KBemsNxEnr4",
        "row": 21015,
        "state": "",
        "group": "047500604100001",
        "group_num": [
            "recHLMcT9eHxic9MP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3406,
        "id": "reczmmVsZZNJkTqyF",
        "row": 21025,
        "state": "",
        "group": "047659202000025",
        "group_num": [
            "recfJeKIMFYwtM9Iw"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recFXwIe25DHpl1DV",
        "row": 21031,
        "state": "",
        "group": "",
        "group_num": [
            "recvPrLG4MagmNMUi"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 1042,
        "id": "recFFdr6gX0ppAjE9",
        "row": 21034,
        "state": "",
        "group": "047676101100012",
        "group_num": [
            "recqQscaBkgZZvXch"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1773,
        "id": "recFORa6FTy3LRcVe",
        "row": 21046,
        "state": "",
        "group": "",
        "group_num": [
            "reczkAG6olh3v2xsx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1080,
        "id": "recIqqYe9vXoVLGNF",
        "row": 21030,
        "state": "",
        "group": "",
        "group_num": [
            "reclvzSi727yhcHW9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 933,
        "id": "recJyffvLOyhkvYa0",
        "row": 21029,
        "state": "",
        "group": "",
        "group_num": [
            "reclvzSi727yhcHW9"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2136,
        "id": "recJnG60dgf42LSc4",
        "row": 21044,
        "state": "",
        "group": "",
        "group_num": [
            "reczkAG6olh3v2xsx"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1016,
        "id": "recLM0TXfMdYBaqhG",
        "row": 21014,
        "state": "",
        "group": "047500604100001",
        "group_num": [
            "recHLMcT9eHxic9MP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 963,
        "id": "recMezzYu6IbCFB62",
        "row": 21037,
        "state": "",
        "group": "",
        "group_num": [
            "recYc1erlIP459giW"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recNsfJ5w7lI3Czwa",
        "row": 21039,
        "state": "",
        "group": "047926001300001",
        "group_num": [
            "rec7tiyGOvU5u02Y0"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 871,
        "id": "receJ2kivQU7Yrl4z",
        "row": 21101,
        "state": "",
        "group": "068615603200101",
        "group_num": [
            "rec0vhiljtUE5Vclg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 469,
        "id": "recgYeE32xG0eU9oL",
        "row": 21077,
        "state": "",
        "group": "",
        "group_num": [
            "recmS67jIitTaoYDQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1901,
        "id": "reckEXJXcNO1V8VJY",
        "row": 21066,
        "state": "",
        "group": "",
        "group_num": [
            "recfhqYc9tSNSKrJ7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "reclUENS7SXsICYdt",
        "row": 21099,
        "state": "",
        "group": "066697301400001",
        "group_num": [
            "recFuIorDPQ5C0tOk"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 585,
        "2024": 344,
        "id": "reclXT0zGiYbvAcIy",
        "row": 21062,
        "state": "",
        "group": "",
        "group_num": [
            "recyZH3yEiwcDNPTj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "reclYp9aeXVtqK0li",
        "row": 21090,
        "state": "",
        "group": "",
        "group_num": [
            "rec2dCQQZVsH5asls"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recnTSoJuHzA5973H",
        "row": 21086,
        "state": "",
        "group": "065874203100005",
        "group_num": [
            "recgVbO1Viv7zfQQt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 494,
        "id": "recOfBUkjpBS0q9wG",
        "row": 21089,
        "state": "",
        "group": "",
        "group_num": [
            "rec4Cfb4s032Zjh5L"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 904,
        "id": "recP13Me4ebWtQc2i",
        "row": 21100,
        "state": "",
        "group": "",
        "group_num": [
            "recvrNwPVUVeZ4nv8"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4139,
        "id": "recP2Nmx6PrsUgHpP",
        "row": 21080,
        "state": "",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 861,
        "id": "recQJOUaPGG3OrOrC",
        "row": 21088,
        "state": "",
        "group": "",
        "group_num": [
            "rec9AVkz3r3GFQpNW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "2023": 759,
        "id": "recQ6clwkZRNaUE6S",
        "row": 21065,
        "state": "",
        "group": "",
        "group_num": [
            "recfhqYc9tSNSKrJ7"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 536,
        "2024": 816,
        "id": "recQmWD7ScrlCCUNl",
        "row": 21094,
        "state": "",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1406,
        "id": "recRhnhRtRHwhUjjv",
        "row": 21078,
        "state": "",
        "group": "",
        "group_num": [
            "recbfcnL4V9D3rnYc"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 393,
        "id": "recSL2Vvdsu6BxV9G",
        "row": 21095,
        "state": "",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1551,
        "id": "recTRXHdQqXMZZqLt",
        "row": 21091,
        "state": "",
        "group": "",
        "group_num": [
            "recxKchU0ooIVqVkh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1585,
        "id": "recVJls3Wsbn8QyhZ",
        "row": 21074,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Gb70NIIL3ELSg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 945,
        "id": "recVex5LS90vHJ7zi",
        "row": 21087,
        "state": "",
        "group": "",
        "group_num": [
            "rec9AVkz3r3GFQpNW"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1206,
        "id": "recWybgdqjvndoEfT",
        "row": 21058,
        "state": "",
        "group": "",
        "group_num": [
            "rec3EaBoMWxDfLvru"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 240,
        "id": "recWmB0IhJgI8O6yl",
        "row": 21070,
        "state": "",
        "group": "",
        "group_num": [
            "recsxA8AVMmYR4GLo"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recX9KE3h2vnv0BOS",
        "row": 21084,
        "state": "",
        "group": "",
        "group_num": [
            "recTthnWFkDthVCDh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 233,
        "2023": 225,
        "id": "recYgMlwFNefs8WRh",
        "row": 21067,
        "state": "",
        "group": "",
        "group_num": [
            "rec74MPZTyCaoxvjn"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1296,
        "id": "recZvP418R2l7fJPk",
        "row": 21083,
        "state": "",
        "group": "",
        "group_num": [
            "recyddUVaq8pYkDeA"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "rec0WdGaIRKQusQ59",
        "row": 21092,
        "state": "",
        "group": "065958501900101",
        "group_num": [
            "recYWqhM2CeRFTEDh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "id": "rec0geTpcERQFWraj",
        "row": 21063,
        "state": "",
        "group": "",
        "group_num": [
            "recwyFlIacIvOpuCu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 48,
        "id": "rec2VEPfjGDtZbAGW",
        "row": 21076,
        "state": "",
        "group": "",
        "group_num": [
            "recSNn0eYcjMqCyaM"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1190,
        "id": "rec333OEUrEH1hYNB",
        "row": 21096,
        "state": "",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2672,
        "2023": 2104,
        "id": "rec4A74SM4Df2HYZ2",
        "row": 21082,
        "state": "",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "rec7KfdtfmQQK0ny5",
        "row": 21075,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Gb70NIIL3ELSg"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 370,
        "id": "rec7lfo7ztrj4WhJv",
        "row": 21104,
        "state": "",
        "group": "",
        "group_num": [
            "recb50pKKFYV2Lz7t"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1057,
        "id": "rec7qEJNiFV5tOTRK",
        "row": 21059,
        "state": "",
        "group": "",
        "group_num": [
            "rec3EaBoMWxDfLvru"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "id": "rec8zGx2JmCXiVhXw",
        "row": 21068,
        "state": "",
        "group": "",
        "group_num": [
            "rec74MPZTyCaoxvjn"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recaYBgyeqs6QeQf7",
        "row": 21061,
        "state": "",
        "group": "",
        "group_num": [
            "recoEuCfnLDUWezac"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3003,
        "id": "recbQaxxLgbHTNui6",
        "row": 21081,
        "state": "",
        "group": "065422803100000",
        "group_num": [
            "recYwue4PPzy54wTY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 839,
        "id": "recd1qNJNUK40kg89",
        "row": 21105,
        "state": "",
        "group": "",
        "group_num": [
            "recIry34oFTv5IEU7"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 712,
        "2023": 591,
        "id": "recqpS2FAmFtKWKuR",
        "row": 21057,
        "state": "",
        "group": "053155401000005",
        "group_num": [
            "rec2XPI7amIHfted7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1041,
        "id": "recrH9pOpaXHeHOW3",
        "row": 21106,
        "state": "",
        "group": "069747801500001",
        "group_num": [
            "recEtXAesEs2pu5Ix"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recrOrLXdBKiZ2DIY",
        "row": 21102,
        "state": "",
        "group": "068617803300102",
        "group_num": [
            "recY2BJCcyyj95a5m"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "id": "recv6yFXgNfdb5Fn7",
        "row": 21073,
        "state": "",
        "group": "062026101800101",
        "group_num": [
            "recfh9HI6IP7sbPBZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 466,
        "id": "recAzy9eHwfUSt39D",
        "row": 21098,
        "state": "",
        "group": "066697301400001",
        "group_num": [
            "recFuIorDPQ5C0tOk"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "id": "recAcCdCiP47kkt96",
        "row": 21071,
        "state": "",
        "group": "",
        "group_num": [
            "recsxA8AVMmYR4GLo"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recAq2NmXhonwlxWn",
        "row": 21085,
        "state": "",
        "group": "",
        "group_num": [
            "rec5642rYPt9rBdsv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recDUAQfA14R6ahri",
        "row": 21060,
        "state": "",
        "group": "057922501700002",
        "group_num": [
            "recftolgD0cYlvZIq"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "recD3PpPz8tKXLAdT",
        "row": 21097,
        "state": "",
        "group": "066697301400001",
        "group_num": [
            "recFuIorDPQ5C0tOk"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recFqFDH69efUTsfi",
        "row": 21072,
        "state": "",
        "group": "062026101800101",
        "group_num": [
            "recfh9HI6IP7sbPBZ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1434,
        "id": "recHix5UgJLdGHIra",
        "row": 21079,
        "state": "",
        "group": "",
        "group_num": [
            "recccqsBZK8b3yWha"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1352,
        "id": "recKu2e23BZDe4twH",
        "row": 21064,
        "state": "",
        "group": "",
        "group_num": [
            "recfhqYc9tSNSKrJ7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1660,
        "id": "recLvSBK9En5HU4hG",
        "row": 21103,
        "state": "",
        "group": "068617803300102",
        "group_num": [
            "recY2BJCcyyj95a5m"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "id": "recLpfM4GmwTisypK",
        "row": 21069,
        "state": "",
        "group": "060751602700004",
        "group_num": [
            "rec5r6orohihAjKIn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 941,
        "id": "recMjRg6a0j0OjThi",
        "row": 21093,
        "state": "",
        "group": "066697301000003",
        "group_num": [
            "recVhQ41xUl1X8RrX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1614,
        "2024": 1307,
        "id": "recgcfSIyCOx5LXMx",
        "row": 21152,
        "state": "",
        "group": "",
        "group_num": [
            "recvXKwq07Lbnu4tv"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 275,
        "id": "recgdvTvFztRlRGzs",
        "row": 21120,
        "state": "",
        "group": "",
        "group_num": [
            "recGWsvqfDlqqN1m5"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 516,
        "id": "reciOnhFYjAiW92TK",
        "row": 21112,
        "state": "",
        "group": "",
        "group_num": [
            "recDosOkcalTMhrzA"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "2023": 1063,
        "id": "recjnKKPcZLHwR8Gk",
        "row": 21142,
        "state": "",
        "group": "",
        "group_num": [
            "recjh1x1Z1WCLDzHH"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2502,
        "id": "recllTet0yDne9yRK",
        "row": 21134,
        "state": "",
        "group": "070142601400001",
        "group_num": [
            "rec2xyKjIHLEuD4eb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 222,
        "id": "recO84JzBnRUOvRBj",
        "row": 21108,
        "state": "",
        "group": "",
        "group_num": [
            "reckWX0y19oE4oHUB"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1568,
        "id": "recPsQJHF2so6pXtA",
        "row": 21147,
        "state": "",
        "group": "",
        "group_num": [
            "recNo9PYLqUaLipnY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1120,
        "id": "recQsk1y64v57pJ8p",
        "row": 21124,
        "state": "",
        "group": "",
        "group_num": [
            "recMQexaTb4PBeoPk"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1574,
        "id": "recQERNoXoxqudy1p",
        "row": 21117,
        "state": "",
        "group": "069802102400001",
        "group_num": [
            "reciPb9QC4YHKHkT9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1015,
        "id": "recTZeqgCGUzQOpqM",
        "row": 21155,
        "state": "",
        "group": "",
        "group_num": [
            "recz7CK644Ce6XsPf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1150,
        "id": "recVbziqWVK83fXWY",
        "row": 21154,
        "state": "",
        "group": "",
        "group_num": [
            "recooDTadJOxSiW00"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 632,
        "2024": 664,
        "id": "recW4ucRcILgMo6EX",
        "row": 21136,
        "state": "",
        "group": "",
        "group_num": [
            "recGOAMhsjMajJw7B"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3416,
        "id": "recXT3ewqflpGTN7z",
        "row": 21149,
        "state": "",
        "group": "070450203300101",
        "group_num": [
            "recXxjHFCar6N5vLa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1434,
        "id": "recXQZGFUAskuG8yH",
        "row": 21116,
        "state": "",
        "group": "",
        "group_num": [
            "rec9pFpenkKqQUSjG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "id": "recYBIaFYms7tkQw0",
        "row": 21133,
        "state": "",
        "group": "070142601400001",
        "group_num": [
            "rec2xyKjIHLEuD4eb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2007,
        "id": "recZYFFQqaMk7ZIqr",
        "row": 21125,
        "state": "",
        "group": "",
        "group_num": [
            "recMQexaTb4PBeoPk"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 682,
        "id": "rec1lffF2n0LwGnS1",
        "row": 21139,
        "state": "",
        "group": "",
        "group_num": [
            "reckXPCi3YLVom0hU"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "rec23HXee60APwwSu",
        "row": 21141,
        "state": "",
        "group": "",
        "group_num": [
            "recjh1x1Z1WCLDzHH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 494,
        "id": "rec2efxcmJtm2jmSd",
        "row": 21145,
        "state": "",
        "group": "",
        "group_num": [
            "recdN2fsaV3oTBj3X"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 593,
        "2024": 344,
        "id": "rec3Xk3ksatTLFzyA",
        "row": 21111,
        "state": "",
        "group": "",
        "group_num": [
            "recLykIccqZHkC7vp"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1250,
        "id": "rec6unAzCQPKD78aG",
        "row": 21122,
        "state": "",
        "group": "",
        "group_num": [
            "recMQexaTb4PBeoPk"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1434,
        "id": "rec789Vlmszc8tNtO",
        "row": 21115,
        "state": "",
        "group": "",
        "group_num": [
            "rec9pFpenkKqQUSjG"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2662,
        "id": "rec8aHup2SGZJmJsJ",
        "row": 21143,
        "state": "",
        "group": "",
        "group_num": [
            "recjh1x1Z1WCLDzHH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1649,
        "id": "rec8fh3OkWt1IFpQB",
        "row": 21146,
        "state": "",
        "group": "",
        "group_num": [
            "recNo9PYLqUaLipnY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3662,
        "id": "rec9bvZIaFoE3sJEc",
        "row": 21132,
        "state": "",
        "group": "",
        "group_num": [
            "rec6E2OnKh8p8vBBN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 440,
        "id": "rec9mvU1eyJEiAVuZ",
        "row": 21123,
        "state": "",
        "group": "",
        "group_num": [
            "recMQexaTb4PBeoPk"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 155,
        "id": "recakUXxbx5paYfEl",
        "row": 21140,
        "state": "",
        "group": "",
        "group_num": [
            "reckXPCi3YLVom0hU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recbL2FTZsNOD0uST",
        "row": 21156,
        "state": "",
        "group": "",
        "group_num": [
            "recx6iQvIGWTvfbv7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2430,
        "id": "recdXTDEMiD5uPcOP",
        "row": 21153,
        "state": "",
        "group": "",
        "group_num": [
            "recvXKwq07Lbnu4tv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1423,
        "id": "recdf9jOcxc2sqJG6",
        "row": 21150,
        "state": "",
        "group": "",
        "group_num": [
            "recvXKwq07Lbnu4tv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 686,
        "id": "recrMdGvEooePvUiP",
        "row": 21113,
        "state": "",
        "group": "",
        "group_num": [
            "rec4p9xO2JssYsrlv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1434,
        "id": "recurV96chS2wFITO",
        "row": 21126,
        "state": "",
        "group": "070108002600001",
        "group_num": [
            "rec6JWvXdfTt3msDL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1394,
        "id": "recw50qXzuw0dJOuI",
        "row": 21138,
        "state": "",
        "group": "",
        "group_num": [
            "reckXPCi3YLVom0hU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "2024": 35,
        "id": "recxAb87ad7ScWQLP",
        "row": 21110,
        "state": "",
        "group": "",
        "group_num": [
            "recLykIccqZHkC7vp"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 430,
        "id": "recxoDj4PIomPpc5n",
        "row": 21109,
        "state": "",
        "group": "",
        "group_num": [
            "reckWX0y19oE4oHUB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1789,
        "id": "recyRuJ9VpIgOubFP",
        "row": 21119,
        "state": "",
        "group": "",
        "group_num": [
            "rec7UIg8cKfF3j1DT"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "id": "reczLj1I1G1nyR5Ej",
        "row": 21107,
        "state": "",
        "group": "069747803800001",
        "group_num": [
            "recxzh93EYtrtnPqT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1486,
        "id": "recB74qqsgQqUIP76",
        "row": 21131,
        "state": "",
        "group": "070109301100803",
        "group_num": [
            "recpve2RVJ4meOSRX"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1608,
        "id": "recCsl2ySBPx2zVAe",
        "row": 21148,
        "state": "",
        "group": "",
        "group_num": [
            "recNo9PYLqUaLipnY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2091,
        "id": "recCFhD9TcEVVjmcf",
        "row": 21151,
        "state": "",
        "group": "",
        "group_num": [
            "recvXKwq07Lbnu4tv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 451,
        "id": "recFs6cRW3o0xGNb8",
        "row": 21137,
        "state": "",
        "group": "",
        "group_num": [
            "recGOAMhsjMajJw7B"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 417,
        "id": "recFBorzPmfFNpD6e",
        "row": 21129,
        "state": "",
        "group": "070109301100803",
        "group_num": [
            "recpve2RVJ4meOSRX"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 974,
        "id": "recFQfZ64s6NYGiAx",
        "row": 21127,
        "state": "",
        "group": "070108002600001",
        "group_num": [
            "rec6JWvXdfTt3msDL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 964,
        "id": "recHbbtjUaMjTin2k",
        "row": 21128,
        "state": "",
        "group": "070109301100803",
        "group_num": [
            "recpve2RVJ4meOSRX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recL1hBuzipY69okN",
        "row": 21135,
        "state": "",
        "group": "",
        "group_num": [
            "recGOAMhsjMajJw7B"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "2023": 294,
        "id": "recLiw5pZWd83KpoC",
        "row": 21130,
        "state": "",
        "group": "070109301100803",
        "group_num": [
            "recpve2RVJ4meOSRX"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2007,
        "id": "recMsq0sMGMr4r9Sp",
        "row": 21121,
        "state": "",
        "group": "",
        "group_num": [
            "recMQexaTb4PBeoPk"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1938,
        "id": "recMls3J8If8PvLw7",
        "row": 21144,
        "state": "",
        "group": "070149604700014",
        "group_num": [
            "rec8uYBhgKiQyNRds"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 493,
        "2024": 344,
        "id": "recMnUslAe5DE79im",
        "row": 21114,
        "state": "",
        "group": "",
        "group_num": [
            "rec4p9xO2JssYsrlv"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 933,
        "id": "recNaBKAnkFIWi5HF",
        "row": 21118,
        "state": "",
        "group": "",
        "group_num": [
            "recGWDRpMljfsFhyj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 186,
        "id": "recgXj5hKldRfaVCO",
        "row": 21201,
        "state": "",
        "group": "",
        "group_num": [
            "reccNPftcQ3j0yRtL"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1226,
        "id": "recg9paahiTfYeyKP",
        "row": 21166,
        "state": "",
        "group": "071998204300020",
        "group_num": [
            "recBN7uHGUQGIzB6X"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1127,
        "id": "reciqhFkcXl4KRkIT",
        "row": 21173,
        "state": "",
        "group": "072442501300401",
        "group_num": [
            "rec2bGl71pSt6T7Kn"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2438,
        "id": "recjHKvZZwL277Vs2",
        "row": 21159,
        "state": "",
        "group": "",
        "group_num": [
            "recJZXgEcOofGTngi"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 925,
        "id": "recjRn5no9JaiEIHJ",
        "row": 21180,
        "state": "",
        "group": "",
        "group_num": [
            "recb89N5QW0AsOFkZ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 602,
        "id": "reck6WJD7ty45XMbu",
        "row": 21200,
        "state": "",
        "group": "",
        "group_num": [
            "reccNPftcQ3j0yRtL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recmhLUp32vPasle5",
        "row": 21170,
        "state": "",
        "group": "",
        "group_num": [
            "recz6gAyVuu5J5UOt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1787,
        "id": "recmn7I5aVz6UtMUf",
        "row": 21171,
        "state": "",
        "group": "072432802400001",
        "group_num": [
            "recduyo6t6HitAz9X"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2105,
        "id": "recOXkb9IAQgzYasd",
        "row": 21176,
        "state": "",
        "group": "",
        "group_num": [
            "recQfrsnsocRZXcCu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1307,
        "id": "recO4VgLSgKNdeRru",
        "row": 21196,
        "state": "",
        "group": "",
        "group_num": [
            "recSxmfed6ik4bAFZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1384,
        "id": "recPZ9itcbNpklQgm",
        "row": 21182,
        "state": "",
        "group": "072781301500001",
        "group_num": [
            "recvyvt7ZPJ7OE3pP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2721,
        "id": "recP6eK67LUS5atoV",
        "row": 21162,
        "state": "",
        "group": "071998201300020",
        "group_num": [
            "recKDuRDTNfA7pBSn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recR5JXcuX3YPXLbR",
        "row": 21157,
        "state": "",
        "group": "",
        "group_num": [
            "recx6iQvIGWTvfbv7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "recTTnQWsM6y9Toa6",
        "row": 21191,
        "state": "",
        "group": "073739201400464",
        "group_num": [
            "reciGQZLNHIQYl14r"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1147,
        "id": "recT9WxxUdnQACXkp",
        "row": 21197,
        "state": "",
        "group": "",
        "group_num": [
            "recSxmfed6ik4bAFZ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 723,
        "id": "recUDk05czl4XtnSa",
        "row": 21199,
        "state": "",
        "group": "",
        "group_num": [
            "reccNPftcQ3j0yRtL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 779,
        "id": "rec04mEuJWuki5Z5G",
        "row": 21194,
        "state": "",
        "group": "",
        "group_num": [
            "recZOcWNEN7duiegK"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "rec1Ue5Mc28ruIiXE",
        "row": 21160,
        "state": "",
        "group": "071871501700003",
        "group_num": [
            "recceKNPHcyg2e3Wa"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1259,
        "id": "rec2bUMcNZ4iKIQNi",
        "row": 21158,
        "state": "",
        "group": "",
        "group_num": [
            "recJZXgEcOofGTngi"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "id": "rec3K6p4EnCzOWRn5",
        "row": 21184,
        "state": "",
        "group": "072781301500001",
        "group_num": [
            "recvyvt7ZPJ7OE3pP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 430,
        "id": "rec53CS0oy6lfoVB4",
        "row": 21202,
        "state": "",
        "group": "",
        "group_num": [
            "reccNPftcQ3j0yRtL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "rec6ZidSIu2zlM8QG",
        "row": 21185,
        "state": "",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "id": "rec61cxir9vMQ0ZY8",
        "row": 21205,
        "state": "",
        "group": "",
        "group_num": [
            "recJzL1I9Eojkrxp3"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "rec6nIcdDr30PnPWA",
        "row": 21203,
        "state": "",
        "group": "",
        "group_num": [
            "rech9zRunNro7imZX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "2023": 3386,
        "id": "rec8kEjJfWuU9fth9",
        "row": 21172,
        "state": "",
        "group": "072432802400001",
        "group_num": [
            "recduyo6t6HitAz9X"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "2023": 44,
        "id": "recahTEqJ6JoZDHt3",
        "row": 21198,
        "state": "",
        "group": "",
        "group_num": [
            "rec98QAKrcR1pdzPa"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 903,
        "2023": 968,
        "id": "recbuZK6z9ttam1vE",
        "row": 21181,
        "state": "",
        "group": "072781301100001",
        "group_num": [
            "recPdNOsEmnWKFhgJ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 767,
        "id": "recbHsSdHwLiwaqQ6",
        "row": 21206,
        "state": "",
        "group": "",
        "group_num": [
            "recJzL1I9Eojkrxp3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 351,
        "id": "recc3vOrh6NAMxob5",
        "row": 21187,
        "state": "",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recdXD8mlAt7Mbf8r",
        "row": 21178,
        "state": "",
        "group": "072488201200001",
        "group_num": [
            "recsu33HBRHhNiZyT"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1159,
        "id": "recq4VwXJ72z1L2JK",
        "row": 21193,
        "state": "",
        "group": "",
        "group_num": [
            "recm8eLiqhNfkrEhj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1157,
        "id": "recrwG5iYvGgl4aHn",
        "row": 21195,
        "state": "",
        "group": "",
        "group_num": [
            "recSxmfed6ik4bAFZ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1168,
        "id": "recsdOkmoakcuZIJb",
        "row": 21204,
        "state": "",
        "group": "",
        "group_num": [
            "recJzL1I9Eojkrxp3"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "id": "rectOtEe6NH5Cs0sG",
        "row": 21167,
        "state": "",
        "group": "071998204300020",
        "group_num": [
            "recBN7uHGUQGIzB6X"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1381,
        "id": "recyub0G5pWEKmzbr",
        "row": 21161,
        "state": "",
        "group": "071871501700003",
        "group_num": [
            "recceKNPHcyg2e3Wa"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1037,
        "id": "recA3YBJorYs43gwe",
        "row": 21190,
        "state": "",
        "group": "073739201400464",
        "group_num": [
            "reciGQZLNHIQYl14r"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 946,
        "id": "recCCHN2lXATMQzqz",
        "row": 21175,
        "state": "",
        "group": "072442501300401",
        "group_num": [
            "rec2bGl71pSt6T7Kn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "recCeKnc3QBzCfRxd",
        "row": 21179,
        "state": "",
        "group": "072488201200001",
        "group_num": [
            "recsu33HBRHhNiZyT"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 559,
        "id": "recErfX0u11ve3yph",
        "row": 21183,
        "state": "",
        "group": "072781301500001",
        "group_num": [
            "recvyvt7ZPJ7OE3pP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 559,
        "2024": 730,
        "id": "recFVZYn0wVtzy7yJ",
        "row": 21186,
        "state": "",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 918,
        "2024": 642,
        "id": "recFvAXoUh65xpz6w",
        "row": 21188,
        "state": "",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1061,
        "2023": 1061,
        "id": "recGyfKvzqFjlU7ZO",
        "row": 21174,
        "state": "",
        "group": "072442501300401",
        "group_num": [
            "rec2bGl71pSt6T7Kn"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recHCIHh7zTFqdQnr",
        "row": 21163,
        "state": "",
        "group": "",
        "group_num": [
            "recvCTRKiQJBlBM1Y"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1256,
        "id": "recHPCzsPe4r4TsNU",
        "row": 21192,
        "state": "",
        "group": "",
        "group_num": [
            "recm8eLiqhNfkrEhj"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1067,
        "2023": 1559,
        "id": "recJXXxK4vDdfm7us",
        "row": 21165,
        "state": "",
        "group": "071998204300020",
        "group_num": [
            "recBN7uHGUQGIzB6X"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 980,
        "2023": 1017,
        "id": "recJxSrBq1O3JdjY5",
        "row": 21189,
        "state": "",
        "group": "072781301600001",
        "group_num": [
            "recLilLi5Yb0ERwDD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 728,
        "2024": 1150,
        "id": "recJnkz53SI6LlerY",
        "row": 21164,
        "state": "",
        "group": "",
        "group_num": [
            "rec2GhXQjb5GNXkqj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recK0RV3vBF3cxD1F",
        "row": 21169,
        "state": "",
        "group": "",
        "group_num": [
            "recz6gAyVuu5J5UOt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 910,
        "2023": 910,
        "id": "recNUtGqe9p3DM3OU",
        "row": 21168,
        "state": "",
        "group": "",
        "group_num": [
            "recKgsT0l7yYHgRHY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3691,
        "id": "recNBK1AVfFVZD6Ey",
        "row": 21177,
        "state": "",
        "group": "072488201200001",
        "group_num": [
            "recsu33HBRHhNiZyT"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1552,
        "id": "receEVS2g0vUS69BD",
        "row": 21235,
        "state": "",
        "group": "080010003000001",
        "group_num": [
            "recWkFowM0DXmUmgQ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "rechWbhVXkaOAckYE",
        "row": 21256,
        "state": "",
        "group": "",
        "group_num": [
            "recLVtLrWX30yzVNs"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 956,
        "id": "rech38Awm4gnkfe72",
        "row": 21240,
        "state": "",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1323,
        "id": "reci3ggCs1Yk22r3z",
        "row": 21244,
        "state": "",
        "group": "080010504200001",
        "group_num": [
            "recq5s7zXYiosB4MN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 93,
        "id": "reci9ItubnNsgR3G3",
        "row": 21227,
        "state": "",
        "group": "",
        "group_num": [
            "recVVjgZxfaKdmwQ3"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "id": "recijn9iKtp0PCoeV",
        "row": 21217,
        "state": "",
        "group": "077379701000700",
        "group_num": [
            "recC55gAM5fbflf4H"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2612,
        "id": "recOyVbsFERuDbKty",
        "row": 21209,
        "state": "",
        "group": "",
        "group_num": [
            "recrweNdQaKkN1Pbs"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1820,
        "id": "recQ6CCMN95Kmhx6x",
        "row": 21226,
        "state": "",
        "group": "",
        "group_num": [
            "recQjrBHUZXEfjf1V"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 479,
        "2024": 500,
        "id": "recR9I1G6Zvy8IdmF",
        "row": 21223,
        "state": "",
        "group": "077940904200601",
        "group_num": [
            "recL2O5h4AXwjdjRr"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1282,
        "id": "recSkKQcvM6AuaUtQ",
        "row": 21213,
        "state": "",
        "group": "",
        "group_num": [
            "recFQwD8B2Z6efI6T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 757,
        "id": "recTCvox2q10r6QV6",
        "row": 21234,
        "state": "",
        "group": "080010003000001",
        "group_num": [
            "recWkFowM0DXmUmgQ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1539,
        "id": "recT8iYv93FOa9VAs",
        "row": 21207,
        "state": "",
        "group": "",
        "group_num": [
            "rec1aVK2U71j4Fxsn"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1532,
        "id": "recUtogBGCHJ3QaTi",
        "row": 21248,
        "state": "",
        "group": "080020104200100",
        "group_num": [
            "recDJzIo2QxZMDGfA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 428,
        "id": "recUxGzY8kTNn08AK",
        "row": 21250,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VYL9nELmL7LN6"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 925,
        "id": "recVUwsbnvnyzVZXM",
        "row": 21243,
        "state": "",
        "group": "",
        "group_num": [
            "recaN3jkqtNZgbz5C"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "id": "recVYjryC1XyfIOCD",
        "row": 21214,
        "state": "",
        "group": "",
        "group_num": [
            "recFQwD8B2Z6efI6T"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3774,
        "2023": 4394,
        "id": "recWBNp4IQfG0tolz",
        "row": 21224,
        "state": "",
        "group": "077940904200601",
        "group_num": [
            "recL2O5h4AXwjdjRr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 680,
        "id": "recW6hC5kK3zbDMI4",
        "row": 21230,
        "state": "",
        "group": "",
        "group_num": [
            "recDeU3qOGtjRn75y"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 760,
        "id": "recXpPYcCkSReWPVc",
        "row": 21211,
        "state": "",
        "group": "",
        "group_num": [
            "recu7Y4xjcwCSHpmg"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1345,
        "id": "recXqAI7VNzBhVWQ7",
        "row": 21233,
        "state": "",
        "group": "080010001700001",
        "group_num": [
            "rech8qfFMDAr0bAqX"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 275,
        "id": "recY7A4h17lMiV3x2",
        "row": 21242,
        "state": "",
        "group": "",
        "group_num": [
            "recubjrVbqyn6nhVX"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "rec0jzB4qhGMdNaD5",
        "row": 21225,
        "state": "",
        "group": "077940904800111",
        "group_num": [
            "recLi2NJLhWC8tmeR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 977,
        "id": "rec3PdVPTZwReZP3O",
        "row": 21208,
        "state": "",
        "group": "",
        "group_num": [
            "rec1aVK2U71j4Fxsn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 935,
        "id": "rec4PAAJm2Sr5XYUk",
        "row": 21252,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VYL9nELmL7LN6"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 439,
        "id": "rec4oRhb3hhlxbuiu",
        "row": 21212,
        "state": "",
        "group": "",
        "group_num": [
            "recu7Y4xjcwCSHpmg"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "rec9ZapeuV0AzcwHx",
        "row": 21221,
        "state": "",
        "group": "077940904200501",
        "group_num": [
            "recH7diBrpdEIga2K"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1289,
        "id": "rec9upNpZThnDRU63",
        "row": 21231,
        "state": "",
        "group": "",
        "group_num": [
            "rec92B7D65xK7Tg56"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1968,
        "id": "recbvpKuShfO6S0oJ",
        "row": 21232,
        "state": "",
        "group": "",
        "group_num": [
            "rec92B7D65xK7Tg56"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1710,
        "id": "reccDVp8Lo6dp5Jxa",
        "row": 21255,
        "state": "",
        "group": "",
        "group_num": [
            "recKL76A4F8JI1KVC"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 925,
        "id": "reco8DOwU1224htXL",
        "row": 21253,
        "state": "",
        "group": "",
        "group_num": [
            "recY1yEAvjZaYmyGU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1206,
        "id": "recpV4cRMMlGUX3Je",
        "row": 21254,
        "state": "",
        "group": "",
        "group_num": [
            "recY1yEAvjZaYmyGU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1139,
        "2024": 1598,
        "id": "recptJn91jivt0mI9",
        "row": 21237,
        "state": "",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recpyEwBsGmhA0ckh",
        "row": 21210,
        "state": "",
        "group": "",
        "group_num": [
            "recu7Y4xjcwCSHpmg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1525,
        "id": "recrjbvcTersMAKUS",
        "row": 21249,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VYL9nELmL7LN6"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1291,
        "id": "recssF3YwdyhPIZQz",
        "row": 21251,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VYL9nELmL7LN6"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1671,
        "id": "recvAvIDcDPBTj8S3",
        "row": 21245,
        "state": "",
        "group": "080010504200001",
        "group_num": [
            "recq5s7zXYiosB4MN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recvem4VvydNozyhj",
        "row": 21220,
        "state": "",
        "group": "077631701000001",
        "group_num": [
            "rec7FAHk3D22RGkXv"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recxQc4xG0swUT4Y9",
        "row": 21246,
        "state": "",
        "group": "",
        "group_num": [
            "recUoybAFPEzWzLzE"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "reczvsHiczZvcMfY2",
        "row": 21247,
        "state": "",
        "group": "",
        "group_num": [
            "recXbOVJuYFHl64fQ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2567,
        "2023": 4394,
        "id": "recDgSJc8IwaD2pOP",
        "row": 21222,
        "state": "",
        "group": "077940904200601",
        "group_num": [
            "recL2O5h4AXwjdjRr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "id": "recFLugrGJXvVhGtq",
        "row": 21229,
        "state": "",
        "group": "",
        "group_num": [
            "recDeU3qOGtjRn75y"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 717,
        "id": "recFaWrJM9cCKDy0d",
        "row": 21215,
        "state": "",
        "group": "",
        "group_num": [
            "recFQwD8B2Z6efI6T"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recGamYp22l6fNzM4",
        "row": 21216,
        "state": "",
        "group": "077379701000700",
        "group_num": [
            "recC55gAM5fbflf4H"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 588,
        "id": "recHFnHYcrCdaFWpf",
        "row": 21238,
        "state": "",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recHaKNh6tvcZtIDB",
        "row": 21236,
        "state": "",
        "group": "080010003000001",
        "group_num": [
            "recWkFowM0DXmUmgQ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1465,
        "id": "recJdLpiHw62Ucb88",
        "row": 21228,
        "state": "",
        "group": "",
        "group_num": [
            "recDeU3qOGtjRn75y"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2535,
        "id": "recLFxrBNz5vh6kF5",
        "row": 21241,
        "state": "",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "2023": 128,
        "id": "recNwUDEetW5Fogv5",
        "row": 21239,
        "state": "",
        "group": "080010003100001",
        "group_num": [
            "reckZhuOkSiOOM8Id"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 655,
        "id": "recN6OzFxrOy1TExt",
        "row": 21219,
        "state": "",
        "group": "077631701000001",
        "group_num": [
            "rec7FAHk3D22RGkXv"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "id": "recN9r8u5SDnpcl3u",
        "row": 21218,
        "state": "",
        "group": "077379701000700",
        "group_num": [
            "recC55gAM5fbflf4H"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "id": "receUFhdFIXssGgAX",
        "row": 21275,
        "state": "",
        "group": "081246303100001",
        "group_num": [
            "reczBNa9iMeRZjwFp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "recfH3Hy43aGcYXnH",
        "row": 21281,
        "state": "",
        "group": "",
        "group_num": [
            "recdNGyhPtaWPburf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recmYUXmmjA4XFAMB",
        "row": 21277,
        "state": "",
        "group": "081246303100001",
        "group_num": [
            "reczBNa9iMeRZjwFp"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1377,
        "id": "recmxJ9nSL60dU3MH",
        "row": 21269,
        "state": "",
        "group": "",
        "group_num": [
            "recVMphNeHnaxwja0"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recnIaFnQ1ValKP8u",
        "row": 21305,
        "state": "",
        "group": "",
        "group_num": [
            "recJdEilXUTSwkfpd"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1753,
        "id": "recnqhDV7jSMFQCnJ",
        "row": 21280,
        "state": "",
        "group": "",
        "group_num": [
            "recXQIvexOq8Iryre"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "2024": 1800,
        "id": "recPI5gnXS3El1hK6",
        "row": 21283,
        "state": "",
        "group": "081898201000001",
        "group_num": [
            "recnrPoMHjwadxjxl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1521,
        "id": "recQ1yNATRgTMaFlT",
        "row": 21302,
        "state": "",
        "group": "",
        "group_num": [
            "recbFKHDGnakIekIz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recSlsGOtk6qws480",
        "row": 21267,
        "state": "",
        "group": "",
        "group_num": [
            "recMCCCRgL0QbcnSB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 85,
        "id": "recV51ZQk1p9Bem8u",
        "row": 21306,
        "state": "",
        "group": "",
        "group_num": [
            "recu4wZUGzsPJ6CvY"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 1200,
        "id": "recWwwkZ1Gue2VGHW",
        "row": 21276,
        "state": "",
        "group": "081246303100001",
        "group_num": [
            "reczBNa9iMeRZjwFp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2436,
        "id": "recXpTfjbZibz2LFs",
        "row": 21290,
        "state": "",
        "group": "",
        "group_num": [
            "recPLipdNzgB3ZhTR"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1379,
        "id": "rec0G7KLOp2u5GboF",
        "row": 21289,
        "state": "",
        "group": "",
        "group_num": [
            "recPLipdNzgB3ZhTR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "rec0OzjcF1Xf4zJX0",
        "row": 21301,
        "state": "",
        "group": "083725201500101",
        "group_num": [
            "reciy9UO21xttFYjB"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "id": "rec1ze40LhXhtnXkn",
        "row": 21259,
        "state": "",
        "group": "080952701800001",
        "group_num": [
            "recf7agb77btdksLD"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1746,
        "id": "rec1CgofGuUjeiXh7",
        "row": 21268,
        "state": "",
        "group": "",
        "group_num": [
            "recVMphNeHnaxwja0"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 757,
        "id": "rec2g69uciVV3KlvZ",
        "row": 21299,
        "state": "",
        "group": "",
        "group_num": [
            "recymtRJmI40gZFoB"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1531,
        "id": "rec3nEUNUv8fh4M6h",
        "row": 21272,
        "state": "",
        "group": "081128103800001",
        "group_num": [
            "reczWut2n696s3Wuz"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "rec4xYxjGCmXkAquD",
        "row": 21295,
        "state": "",
        "group": "",
        "group_num": [
            "rec2oHYizh5V7CQBs"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 697,
        "id": "rec4MlgOIn9pqG6FO",
        "row": 21262,
        "state": "",
        "group": "",
        "group_num": [
            "recguYpo6EWRKMhzp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1224,
        "id": "rec4519REvGRoyw3q",
        "row": 21282,
        "state": "",
        "group": "",
        "group_num": [
            "recKCIWiNiHUGFwey"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1825,
        "id": "rec8qq4YvY15TIk7n",
        "row": 21258,
        "state": "",
        "group": "",
        "group_num": [
            "rec629RYrEBMVHjhd"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 766,
        "id": "reccXbnr59bHKeDeU",
        "row": 21279,
        "state": "",
        "group": "",
        "group_num": [
            "reczoms28D9agDeGZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "reccBGJunlVVA5NKs",
        "row": 21266,
        "state": "",
        "group": "",
        "group_num": [
            "recMCCCRgL0QbcnSB"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 836,
        "2024": 1260,
        "id": "reccbSKdwrqmRrhXi",
        "row": 21284,
        "state": "",
        "group": "081898201000001",
        "group_num": [
            "recnrPoMHjwadxjxl"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 428,
        "id": "reccqU51VyKN76KRg",
        "row": 21287,
        "state": "",
        "group": "",
        "group_num": [
            "recObyBhrIduLRvhJ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1026,
        "id": "recrWqg9UUrmXACvg",
        "row": 21270,
        "state": "",
        "group": "",
        "group_num": [
            "recVMphNeHnaxwja0"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 57,
        "2023": 47,
        "id": "recrYasFWyRCiqzrQ",
        "row": 21296,
        "state": "",
        "group": "",
        "group_num": [
            "rec9wF6SR4v8o8y8w"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 966,
        "id": "recrAcjZjlo1jje0f",
        "row": 21303,
        "state": "",
        "group": "",
        "group_num": [
            "recbFKHDGnakIekIz"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recsMw6EE4pc6pcS7",
        "row": 21273,
        "state": "",
        "group": "",
        "group_num": [
            "recMAB4g1F1MKnfdy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1140,
        "id": "recsNaaOOVBHenoHU",
        "row": 21304,
        "state": "",
        "group": "083920201100002",
        "group_num": [
            "recUuGJquW2ynBPyO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2508,
        "id": "recsdUFMXLtyuu90g",
        "row": 21285,
        "state": "",
        "group": "",
        "group_num": [
            "recObyBhrIduLRvhJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1366,
        "id": "rectjzBnGveHekjp3",
        "row": 21291,
        "state": "",
        "group": "",
        "group_num": [
            "rec6V5UsNTV1aCDd6"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1201,
        "id": "recvrCpDGQRzZI441",
        "row": 21260,
        "state": "",
        "group": "080952701800001",
        "group_num": [
            "recf7agb77btdksLD"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1525,
        "id": "recwNNDSqgAgdaAvg",
        "row": 21286,
        "state": "",
        "group": "",
        "group_num": [
            "recObyBhrIduLRvhJ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1306,
        "id": "recyXj3g3n6xBW98g",
        "row": 21288,
        "state": "",
        "group": "",
        "group_num": [
            "recObyBhrIduLRvhJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recy1idx8udnRpdba",
        "row": 21265,
        "state": "",
        "group": "",
        "group_num": [
            "recguYpo6EWRKMhzp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1118,
        "id": "recy7AuXCO1rYL0B2",
        "row": 21257,
        "state": "",
        "group": "",
        "group_num": [
            "recLVtLrWX30yzVNs"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1463,
        "id": "recAB0bz5hEwBbpPP",
        "row": 21263,
        "state": "",
        "group": "",
        "group_num": [
            "recguYpo6EWRKMhzp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "id": "recA4M3qYzNhjNLVr",
        "row": 21293,
        "state": "",
        "group": "",
        "group_num": [
            "recXA9NwbRReeLAxr"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 417,
        "id": "recAapaNUqhNrbmNT",
        "row": 21292,
        "state": "",
        "group": "",
        "group_num": [
            "recXA9NwbRReeLAxr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "recAo20PhD2UCXvm0",
        "row": 21297,
        "state": "",
        "group": "",
        "group_num": [
            "recZDLMtKUMgLj4lE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recBpEtEjBsec1Hb0",
        "row": 21278,
        "state": "",
        "group": "081246303100001",
        "group_num": [
            "reczBNa9iMeRZjwFp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1697,
        "id": "recCkhGZvfRdnGdyn",
        "row": 21261,
        "state": "",
        "group": "",
        "group_num": [
            "rechhjKUQtvZBE4wC"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 323,
        "id": "recDY26eVTZWuwKRg",
        "row": 21294,
        "state": "",
        "group": "",
        "group_num": [
            "recXA9NwbRReeLAxr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recDJX6ya3iU3zbDN",
        "row": 21298,
        "state": "",
        "group": "",
        "group_num": [
            "recymtRJmI40gZFoB"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1073,
        "2023": 1183,
        "id": "recFue2hbFpWYjuFb",
        "row": 21264,
        "state": "",
        "group": "",
        "group_num": [
            "recguYpo6EWRKMhzp"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3088,
        "id": "recG10zmdNJXe2szp",
        "row": 21271,
        "state": "",
        "group": "081128103800001",
        "group_num": [
            "reczWut2n696s3Wuz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recHcnRbsyFXdkcxt",
        "row": 21300,
        "state": "",
        "group": "083725201500101",
        "group_num": [
            "reciy9UO21xttFYjB"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recIEYKKAOWTLDAOR",
        "row": 21274,
        "state": "",
        "group": "",
        "group_num": [
            "recMAB4g1F1MKnfdy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1898,
        "id": "recfncRtfOKeWn3HB",
        "row": 21329,
        "state": "",
        "group": "",
        "group_num": [
            "recrU7PyYnzKZaAXz"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "rechNpF04DyhlaSdA",
        "row": 21313,
        "state": "",
        "group": "084728902000001",
        "group_num": [
            "recCXYap80QwW7pFE"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3442,
        "id": "rechfDrEYIngOSqwT",
        "row": 21327,
        "state": "",
        "group": "",
        "group_num": [
            "recrU7PyYnzKZaAXz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "reciXj6irsGOafoGb",
        "row": 21346,
        "state": "",
        "group": "",
        "group_num": [
            "recLgjjXFAdQTn0al"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 453,
        "2024": 453,
        "id": "recijrkpQwCpg0b63",
        "row": 21308,
        "state": "",
        "group": "",
        "group_num": [
            "recVnxeC0ZBVqdKHu"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 392,
        "id": "reclJcOcqjNTZyBmy",
        "row": 21325,
        "state": "",
        "group": "086364601200044",
        "group_num": [
            "recAk5b2Ymc0Y9VCJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 258,
        "2023": 252,
        "id": "recnyt9bqDmhnJWPt",
        "row": 21324,
        "state": "",
        "group": "086364601200044",
        "group_num": [
            "recAk5b2Ymc0Y9VCJ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3400,
        "id": "recnmq4QJxe8zs8Hg",
        "row": 21331,
        "state": "",
        "group": "086382601800304",
        "group_num": [
            "reczOLBlmpGuvDFDx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 20,
        "id": "recOHSuDOQpkKtT1T",
        "row": 21340,
        "state": "",
        "group": "",
        "group_num": [
            "recuCvAoUnr2QtNcA"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 641,
        "2023": 497,
        "id": "recPMgkxRp4vxCAwI",
        "row": 21335,
        "state": "",
        "group": "086387501000001",
        "group_num": [
            "reckIR6JMWin7XYQ1"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 760,
        "id": "recRaloxN98VFJV60",
        "row": 21355,
        "state": "",
        "group": "086530705200001",
        "group_num": [
            "recjcij1LhUCLSnaW"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1525,
        "id": "recSANM7bpm0oX69v",
        "row": 21336,
        "state": "",
        "group": "086387501000001",
        "group_num": [
            "reckIR6JMWin7XYQ1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1444,
        "id": "recSqec6FPdfQA4Rs",
        "row": 21315,
        "state": "",
        "group": "084782101000001",
        "group_num": [
            "recyvRyTG5wuLUVNh"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "id": "recT9PeU0mjKG0ELm",
        "row": 21343,
        "state": "",
        "group": "086523701200105",
        "group_num": [
            "recVcwXZtS1yItNVp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recUM4C8LKVFJm9iR",
        "row": 21344,
        "state": "",
        "group": "086523701200105",
        "group_num": [
            "recVcwXZtS1yItNVp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1494,
        "id": "recXE1XxrUXbk4eeS",
        "row": 21317,
        "state": "",
        "group": "",
        "group_num": [
            "recByRhRg6iBzhpKm"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recXfikALRgabGGDw",
        "row": 21347,
        "state": "",
        "group": "086523701200112",
        "group_num": [
            "recEDGPf9UwD8BtD1"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3900,
        "id": "recXhLjvGrUBnOJg7",
        "row": 21337,
        "state": "",
        "group": "086387501000001",
        "group_num": [
            "reckIR6JMWin7XYQ1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1560,
        "id": "recY1I1i0XUfFVnSP",
        "row": 21332,
        "state": "",
        "group": "086384401000001",
        "group_num": [
            "recZXv12iNUxJIoFu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1295,
        "id": "rec1WTLK30cklSJOY",
        "row": 21352,
        "state": "",
        "group": "",
        "group_num": [
            "recIoFmtBI5bsd6xL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2393,
        "id": "rec4dfYwt1ofPv81P",
        "row": 21339,
        "state": "",
        "group": "086387701300001",
        "group_num": [
            "recjuV418qQqEUVaU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 935,
        "id": "rec5wTl53f3vJszIF",
        "row": 21320,
        "state": "",
        "group": "",
        "group_num": [
            "recsvykvglQhg8HRs"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1525,
        "id": "rec6L9U1WvTZdJm8k",
        "row": 21350,
        "state": "",
        "group": "",
        "group_num": [
            "recIoFmtBI5bsd6xL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3400,
        "2024": 3400,
        "id": "rec6PixNZLxItKXKw",
        "row": 21330,
        "state": "",
        "group": "086382601800304",
        "group_num": [
            "reczOLBlmpGuvDFDx"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 428,
        "id": "rec9DCj3F5rwkjP80",
        "row": 21351,
        "state": "",
        "group": "",
        "group_num": [
            "recIoFmtBI5bsd6xL"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1574,
        "id": "recoXvxmHnmc6Is3V",
        "row": 21353,
        "state": "",
        "group": "",
        "group_num": [
            "recFkF0ClxvnYSNNx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recotASH0O12buuKp",
        "row": 21345,
        "state": "",
        "group": "",
        "group_num": [
            "recLgjjXFAdQTn0al"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 430,
        "id": "recou0JgxkJXTtwMa",
        "row": 21322,
        "state": "",
        "group": "",
        "group_num": [
            "recsvykvglQhg8HRs"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "id": "recpYi8qkfc10eqGB",
        "row": 21356,
        "state": "",
        "group": "086530705200001",
        "group_num": [
            "recjcij1LhUCLSnaW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1970,
        "id": "rect8vtAHyVXCOtOH",
        "row": 21333,
        "state": "",
        "group": "086384401000001",
        "group_num": [
            "recZXv12iNUxJIoFu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 874,
        "id": "recuGIUSdrGckF6lt",
        "row": 21354,
        "state": "",
        "group": "086530705200001",
        "group_num": [
            "recjcij1LhUCLSnaW"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 938,
        "id": "recw0xfjTTmg1naMq",
        "row": 21318,
        "state": "",
        "group": "",
        "group_num": [
            "rec3ccUceyNfVRNUa"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1043,
        "id": "recwadIhEeFRzSedX",
        "row": 21310,
        "state": "",
        "group": "084723501200101",
        "group_num": [
            "recUT6ALIVFbomes6"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 617,
        "id": "recyaI4k0UCZioDDm",
        "row": 21321,
        "state": "",
        "group": "",
        "group_num": [
            "recsvykvglQhg8HRs"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "reczTOvPjWiLWlGaZ",
        "row": 21349,
        "state": "",
        "group": "086523701200112",
        "group_num": [
            "recEDGPf9UwD8BtD1"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1044,
        "id": "reczLEkl4FsisyXem",
        "row": 21348,
        "state": "",
        "group": "086523701200112",
        "group_num": [
            "recEDGPf9UwD8BtD1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 504,
        "id": "reczOXAYa4jYS7CeW",
        "row": 21342,
        "state": "",
        "group": "",
        "group_num": [
            "recHvAjR1sVuqJjTf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3003,
        "id": "reczcvw0nyPVJkawl",
        "row": 21328,
        "state": "",
        "group": "",
        "group_num": [
            "recrU7PyYnzKZaAXz"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1240,
        "2024": 1318,
        "id": "recALrCvFj8gVwc28",
        "row": 21314,
        "state": "",
        "group": "",
        "group_num": [
            "recGFGHMgqWqdJ8oP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2550,
        "id": "recCMoOIV3MIst1om",
        "row": 21334,
        "state": "",
        "group": "086387501000001",
        "group_num": [
            "reckIR6JMWin7XYQ1"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2010,
        "2024": 2393,
        "id": "recE1QKbIlOIH3CiO",
        "row": 21319,
        "state": "",
        "group": "",
        "group_num": [
            "recVz2NbqBJOD8nnk"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 162,
        "2024": 133,
        "id": "recEndAuO264gZxmG",
        "row": 21341,
        "state": "",
        "group": "",
        "group_num": [
            "recHvAjR1sVuqJjTf"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 522,
        "2024": 542,
        "id": "recFATf9A767A95gN",
        "row": 21307,
        "state": "",
        "group": "",
        "group_num": [
            "reclUMKfbVXzs30ds"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 502,
        "id": "recHpl9va79oP2FHv",
        "row": 21338,
        "state": "",
        "group": "086387701300001",
        "group_num": [
            "recjuV418qQqEUVaU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1336,
        "id": "recJk61lwUtZM8b2W",
        "row": 21312,
        "state": "",
        "group": "",
        "group_num": [
            "recJ8SVxfkkFWCBaO"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1634,
        "id": "recKgGy0dSeil87bX",
        "row": 21311,
        "state": "",
        "group": "084723501200101",
        "group_num": [
            "recUT6ALIVFbomes6"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recKqO9vZm1KJvjxy",
        "row": 21316,
        "state": "",
        "group": "084782101000001",
        "group_num": [
            "recyvRyTG5wuLUVNh"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1563,
        "id": "recLtZzQATAfqyFil",
        "row": 21309,
        "state": "",
        "group": "084723501200101",
        "group_num": [
            "recUT6ALIVFbomes6"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 726,
        "id": "recM0PfHh7Epai9vT",
        "row": 21323,
        "state": "",
        "group": "086364601200044",
        "group_num": [
            "recAk5b2Ymc0Y9VCJ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 51,
        "id": "recNxb3nK94ke7AqQ",
        "row": 21326,
        "state": "",
        "group": "",
        "group_num": [
            "rec9v8HWOoUhUbLpK"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1451,
        "id": "recfaky7ymSG8gvZ7",
        "row": 21368,
        "state": "",
        "group": "086545001000002",
        "group_num": [
            "reckRau9up2PM83ds"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "2023": 1181,
        "id": "rech16SPv7rVSRVdz",
        "row": 21367,
        "state": "",
        "group": "086545001000002",
        "group_num": [
            "reckRau9up2PM83ds"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 348,
        "id": "rechBDPXW86VqCGUv",
        "row": 21399,
        "state": "",
        "group": "086810901700004",
        "group_num": [
            "recK6HGmygQIRmgEC"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 87,
        "id": "rechb6BPj3xDbk0ha",
        "row": 21393,
        "state": "",
        "group": "",
        "group_num": [
            "recdqemcCpaqnZlbJ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 46,
        "id": "recj1FMqr4UXZpN9p",
        "row": 21370,
        "state": "",
        "group": "",
        "group_num": [
            "recqRz80ECS5g0OlT"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2714,
        "id": "recjimMinXaXZSK5d",
        "row": 21375,
        "state": "",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2687,
        "id": "reckdV3xUP8ASQKQB",
        "row": 21374,
        "state": "",
        "group": "086621102200102",
        "group_num": [
            "recJ5XJNTNo9LpxGW"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1916,
        "2023": 2178,
        "id": "reclX9Aj6rKkK9d8c",
        "row": 21376,
        "state": "",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1296,
        "id": "recnyvCmlXHY4pR9q",
        "row": 21392,
        "state": "",
        "group": "",
        "group_num": [
            "recdqemcCpaqnZlbJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 966,
        "id": "recOVTpuVRV08Ooe2",
        "row": 21404,
        "state": "",
        "group": "",
        "group_num": [
            "recWLWpe3A2QrAetr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1657,
        "2023": 1105,
        "2024": 1190,
        "id": "recO23ditf8oAKb2U",
        "row": 21384,
        "state": "",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "id": "recPo6Pc4nRSGCPEa",
        "row": 21381,
        "state": "",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recQRM1cj2kItd1tV",
        "row": 21389,
        "state": "",
        "group": "",
        "group_num": [
            "recBtpy9qpFuO9bqe"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1217,
        "id": "recSkJwOfirNwoHLj",
        "row": 21366,
        "state": "",
        "group": "",
        "group_num": [
            "recV3rAyM3s4UU8Ox"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1231,
        "id": "recUTCvWfyxrMo8W8",
        "row": 21371,
        "state": "",
        "group": "086587301300702",
        "group_num": [
            "recxN5ItJIPGNT74H"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1060,
        "id": "recUOl8aJLpaLDOuE",
        "row": 21372,
        "state": "",
        "group": "",
        "group_num": [
            "rec3dYpaopkTMgAOK"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 490,
        "id": "recUokw21tM0IdwJ4",
        "row": 21398,
        "state": "",
        "group": "086810901700004",
        "group_num": [
            "recK6HGmygQIRmgEC"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "recVuP4SS8hKwFFUx",
        "row": 21359,
        "state": "",
        "group": "",
        "group_num": [
            "recLH3XjCei1QGvUi"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recWHQaSiW4Yo3wkM",
        "row": 21391,
        "state": "",
        "group": "",
        "group_num": [
            "recBtpy9qpFuO9bqe"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 111,
        "id": "recXl3vdk9XQqwFJW",
        "row": 21396,
        "state": "",
        "group": "",
        "group_num": [
            "recP824fqqMYMjIej"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "2023": 48,
        "id": "recYS1oxqbSzKv4f9",
        "row": 21369,
        "state": "",
        "group": "",
        "group_num": [
            "recUUWkEI1ARR7myq"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 727,
        "id": "recZNz80MNa2n5K1J",
        "row": 21394,
        "state": "",
        "group": "",
        "group_num": [
            "recdqemcCpaqnZlbJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 909,
        "id": "rec3Dy4FMDCG1UGzh",
        "row": 21406,
        "state": "",
        "group": "086826404000113",
        "group_num": [
            "recjT1gKFEBT7S9NY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 755,
        "id": "rec52J6Fxt4h4WqBA",
        "row": 21397,
        "state": "",
        "group": "086810901700004",
        "group_num": [
            "recK6HGmygQIRmgEC"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1577,
        "id": "rec7md9KfoVgIiJlb",
        "row": 21377,
        "state": "",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "rec93bxTa8wQaOKqb",
        "row": 21361,
        "state": "",
        "group": "",
        "group_num": [
            "recLH3XjCei1QGvUi"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 497,
        "2024": 1906,
        "id": "rec98zPTXVde0Di18",
        "row": 21385,
        "state": "",
        "group": "086629801000006",
        "group_num": [
            "recRdR84MxpG2GhG4"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1682,
        "id": "recb0yVkxAAJgRFhU",
        "row": 21387,
        "state": "",
        "group": "086630201000001",
        "group_num": [
            "recSOHvxy0g4ckAU7"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recdLZs7KlTjkkOjq",
        "row": 21360,
        "state": "",
        "group": "",
        "group_num": [
            "recLH3XjCei1QGvUi"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1337,
        "id": "recozCsd0SkRXHy8I",
        "row": 21379,
        "state": "",
        "group": "086621903000001",
        "group_num": [
            "recpPnRtMngsn2Aef"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 111,
        "id": "recpUiGU5RdFZTipI",
        "row": 21395,
        "state": "",
        "group": "",
        "group_num": [
            "reclYU0DOHOhphuYJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 760,
        "id": "recsSNhxhhMsOiSkX",
        "row": 21400,
        "state": "",
        "group": "086810901700004",
        "group_num": [
            "recK6HGmygQIRmgEC"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 897,
        "id": "recsQNgh70f1p3AMx",
        "row": 21365,
        "state": "",
        "group": "",
        "group_num": [
            "recq1bWWJofpxYl1X"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 694,
        "id": "rectuOyTfSoIEqFGx",
        "row": 21382,
        "state": "",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 143,
        "id": "recvIUpnqpWRKrtsJ",
        "row": 21390,
        "state": "",
        "group": "",
        "group_num": [
            "recBtpy9qpFuO9bqe"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "id": "recxKgGAx4S9IZzpX",
        "row": 21403,
        "state": "",
        "group": "",
        "group_num": [
            "recWLWpe3A2QrAetr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 808,
        "id": "recxl2TL5OgxYlf4f",
        "row": 21402,
        "state": "",
        "group": "",
        "group_num": [
            "recgmDodm8ZAao22z"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 633,
        "id": "recyw2wIqq0phqBP7",
        "row": 21364,
        "state": "",
        "group": "",
        "group_num": [
            "recq1bWWJofpxYl1X"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 581,
        "2024": 674,
        "id": "recAfZpQtnlTDZYOs",
        "row": 21383,
        "state": "",
        "group": "086629801000001",
        "group_num": [
            "recJ0R9ijAJjECEmG"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4100,
        "id": "recEHjbkE5QYi01b8",
        "row": 21363,
        "state": "",
        "group": "086540402000100",
        "group_num": [
            "reclWa2pEFtaYBHyp"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1082,
        "2024": 642,
        "id": "recF6gmymwtXIBnUm",
        "row": 21380,
        "state": "",
        "group": "086621903000001",
        "group_num": [
            "recpPnRtMngsn2Aef"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "id": "recFe6fP8cb55BhGR",
        "row": 21386,
        "state": "",
        "group": "086630201000001",
        "group_num": [
            "recSOHvxy0g4ckAU7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3400,
        "id": "recGHydp9Iau0uVMv",
        "row": 21362,
        "state": "",
        "group": "086540402000100",
        "group_num": [
            "reclWa2pEFtaYBHyp"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1545,
        "id": "recHL8hKNzgsIuyBs",
        "row": 21358,
        "state": "",
        "group": "",
        "group_num": [
            "recmMh8NS2KeuzlS1"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recI9jnnaMukYtNwf",
        "row": 21405,
        "state": "",
        "group": "086826404000113",
        "group_num": [
            "recjT1gKFEBT7S9NY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recIlKFITfVgEHTdS",
        "row": 21378,
        "state": "",
        "group": "086621104200202",
        "group_num": [
            "recVOBcSf5jC3fw8x"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 714,
        "id": "recJaVs2bp51E8DUj",
        "row": 21373,
        "state": "",
        "group": "",
        "group_num": [
            "rec3dYpaopkTMgAOK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 909,
        "id": "recJjYx4guSPbjpBF",
        "row": 21401,
        "state": "",
        "group": "",
        "group_num": [
            "recgmDodm8ZAao22z"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1327,
        "id": "recLf2S0FB9ItQ7se",
        "row": 21357,
        "state": "",
        "group": "",
        "group_num": [
            "recmMh8NS2KeuzlS1"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "recLm9ITX1sbIciIx",
        "row": 21388,
        "state": "",
        "group": "086640405000010",
        "group_num": [
            "recbPKLUY7iHXDH00"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 134,
        "id": "recez9SQN0rD6iF8m",
        "row": 21449,
        "state": "",
        "group": "086856003500011",
        "group_num": [
            "rec4tVIBqVMDbCfLo"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1530,
        "id": "rece9dz216l1vYXJK",
        "row": 21436,
        "state": "",
        "group": "",
        "group_num": [
            "rec4bVamIF36Gn5FA"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3003,
        "id": "rechmce8WD3kSm1KW",
        "row": 21424,
        "state": "",
        "group": "086840301200001",
        "group_num": [
            "recC09LGcyQafWhUU"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "2023": 3986,
        "id": "recjHLSA22ywCxEeF",
        "row": 21423,
        "state": "",
        "group": "086840301200001",
        "group_num": [
            "recC09LGcyQafWhUU"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2023,
        "id": "reckgVhhmnxhSXcSq",
        "row": 21445,
        "state": "",
        "group": "086855901000001",
        "group_num": [
            "recYeL4v99MaiQwft"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1396,
        "id": "recl08TB9KYWyospB",
        "row": 21421,
        "state": "",
        "group": "",
        "group_num": [
            "recXEpEk0G6UCKAS3"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1736,
        "id": "recOJlI7MbGmgbLGG",
        "row": 21448,
        "state": "",
        "group": "",
        "group_num": [
            "recwg0x515BSCFRSz"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recOMuACV522kwHk4",
        "row": 21456,
        "state": "",
        "group": "086857701600001",
        "group_num": [
            "recUm2mvhGoKnadNj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1011,
        "2023": 510,
        "id": "recObBTxehxDjw3ou",
        "row": 21444,
        "state": "",
        "group": "",
        "group_num": [
            "recjIeVwisq7B5vNM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1079,
        "id": "recOeweok3P76wX8X",
        "row": 21408,
        "state": "",
        "group": "086826404000113",
        "group_num": [
            "recjT1gKFEBT7S9NY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "id": "recPK4ANb2Fj1Jc3a",
        "row": 21435,
        "state": "",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1111,
        "2023": 750,
        "id": "recRYDowrYsEknTTe",
        "row": 21411,
        "state": "",
        "group": "086828102000004",
        "group_num": [
            "recgtbLhaU6QrPicH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2787,
        "2023": 1434,
        "id": "recR1cbuxzfqUjBWL",
        "row": 21430,
        "state": "",
        "group": "086840401200001",
        "group_num": [
            "recBi2Z0G3Ni3pZmY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1829,
        "id": "recR4Le2sJcV4AgTY",
        "row": 21453,
        "state": "",
        "group": "",
        "group_num": [
            "recwOitI0UZppGnWP"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "id": "recSYDptrIpZRwwRM",
        "row": 21437,
        "state": "",
        "group": "",
        "group_num": [
            "recZ8knLXQNiIXlZ4"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1364,
        "2023": 3003,
        "id": "recVZSHXRETPtRGjc",
        "row": 21431,
        "state": "",
        "group": "086840401200001",
        "group_num": [
            "recBi2Z0G3Ni3pZmY"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recWyWcFOQe3Ul2x6",
        "row": 21426,
        "state": "",
        "group": "086840301200001",
        "group_num": [
            "recC09LGcyQafWhUU"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1579,
        "id": "recWIxllU1CnPMhL1",
        "row": 21454,
        "state": "",
        "group": "",
        "group_num": [
            "rec9n96Eh2pVw1BnH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 962,
        "id": "recXUfFjL5V664x8a",
        "row": 21455,
        "state": "",
        "group": "",
        "group_num": [
            "rec5Hx5Tj4VFn60aF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 605,
        "2023": 859,
        "id": "recYbaRa90euLsveU",
        "row": 21442,
        "state": "",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1035,
        "2023": 1568,
        "id": "rec039btSuVuSehOP",
        "row": 21451,
        "state": "",
        "group": "",
        "group_num": [
            "recwOitI0UZppGnWP"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 847,
        "id": "rec3EgVcF4R73KUZG",
        "row": 21419,
        "state": "",
        "group": "",
        "group_num": [
            "recErrA5hteKxkCM3"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 652,
        "id": "rec3KujcsqqJXR4CI",
        "row": 21434,
        "state": "",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2779,
        "2023": 1434,
        "id": "rec4H7kI5S3UB83Y7",
        "row": 21432,
        "state": "",
        "group": "086840401200001",
        "group_num": [
            "recBi2Z0G3Ni3pZmY"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "id": "rec4HWe06ZM0RFutC",
        "row": 21422,
        "state": "",
        "group": "",
        "group_num": [
            "recXEpEk0G6UCKAS3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 269,
        "id": "rec428W71cEWYXWRJ",
        "row": 21428,
        "state": "",
        "group": "086840301800001",
        "group_num": [
            "recvEMFAyEidsji5T"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1452,
        "id": "rec5C7ISIj2skXflj",
        "row": 21447,
        "state": "",
        "group": "086855901000001",
        "group_num": [
            "recYeL4v99MaiQwft"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 780,
        "id": "rec5QB202rVxKL0bh",
        "row": 21427,
        "state": "",
        "group": "086840301800001",
        "group_num": [
            "recvEMFAyEidsji5T"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1989,
        "id": "rec72XBXHHQduvmNg",
        "row": 21420,
        "state": "",
        "group": "",
        "group_num": [
            "recXEpEk0G6UCKAS3"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1699,
        "id": "rec9Bcn6OwpxysUWx",
        "row": 21412,
        "state": "",
        "group": "",
        "group_num": [
            "recsN0cQ0SRTXaO5s"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 890,
        "id": "reca5qfsr2xAPPTfv",
        "row": 21414,
        "state": "",
        "group": "",
        "group_num": [
            "recWd5fzOMCk4h7yg"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1806,
        "id": "recadB6BFIH3lgKNv",
        "row": 21418,
        "state": "",
        "group": "",
        "group_num": [
            "recErrA5hteKxkCM3"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1648,
        "2023": 1181,
        "id": "recbU9qI63vHZReMM",
        "row": 21440,
        "state": "",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recb1NWhBT2I2SoCA",
        "row": 21450,
        "state": "",
        "group": "",
        "group_num": [
            "recwOitI0UZppGnWP"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 862,
        "id": "reconrq6s5m57aF2T",
        "row": 21413,
        "state": "",
        "group": "",
        "group_num": [
            "recsN0cQ0SRTXaO5s"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 960,
        "id": "recr0raFPmnvNZgF2",
        "row": 21409,
        "state": "",
        "group": "",
        "group_num": [
            "recHHGSnm625008vt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1525,
        "id": "recrc5WoDEZ8HnnRD",
        "row": 21415,
        "state": "",
        "group": "",
        "group_num": [
            "recWd5fzOMCk4h7yg"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1156,
        "id": "recvJY7uowOQ6pMlP",
        "row": 21439,
        "state": "",
        "group": "",
        "group_num": [
            "recOH9RBIOMjfb4aY"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "id": "recvfH0HFPCwVLqYI",
        "row": 21407,
        "state": "",
        "group": "086826404000113",
        "group_num": [
            "recjT1gKFEBT7S9NY"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 411,
        "id": "recy7XiH1LewQGi3u",
        "row": 21429,
        "state": "",
        "group": "086840301800001",
        "group_num": [
            "recvEMFAyEidsji5T"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2553,
        "id": "recBTaEYcSorjQ5Qh",
        "row": 21416,
        "state": "",
        "group": "",
        "group_num": [
            "recWd5fzOMCk4h7yg"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 653,
        "id": "recB9YxXdAHrrTaPB",
        "row": 21417,
        "state": "",
        "group": "086831101200104",
        "group_num": [
            "recXxxFoEF163Wn5o"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1485,
        "id": "recCjd4QqLb6YkcxD",
        "row": 21433,
        "state": "",
        "group": "086840401800001",
        "group_num": [
            "recd9g1aO5tPz3R5I"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "2023": 2104,
        "id": "recD9DdHmuf46MWzz",
        "row": 21443,
        "state": "",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1102,
        "id": "recHGUa6P4LsDQD8X",
        "row": 21410,
        "state": "",
        "group": "",
        "group_num": [
            "recHHGSnm625008vt"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 727,
        "id": "recH8l0kSUSEDFOeS",
        "row": 21446,
        "state": "",
        "group": "086855901000001",
        "group_num": [
            "recYeL4v99MaiQwft"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 911,
        "2023": 525,
        "id": "recJjc0X8OyL5MTj5",
        "row": 21441,
        "state": "",
        "group": "086851401300207",
        "group_num": [
            "reczvRcXNdeP0coaI"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 884,
        "id": "recL7yVWajL0j7XpC",
        "row": 21438,
        "state": "",
        "group": "",
        "group_num": [
            "recZ8knLXQNiIXlZ4"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 371,
        "id": "recMGBvj2UBWLrsLe",
        "row": 21425,
        "state": "",
        "group": "086840301200001",
        "group_num": [
            "recC09LGcyQafWhUU"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "2023": 1297,
        "2024": 1289,
        "id": "recMaz48335hsIOay",
        "row": 21452,
        "state": "",
        "group": "",
        "group_num": [
            "recwOitI0UZppGnWP"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 732,
        "id": "recf1aWrrwVrT6TgM",
        "row": 21460,
        "state": "",
        "group": "086859801000002",
        "group_num": [
            "recqFryrpDESgUt6W"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "rechXM4iMLGQm9Jzn",
        "row": 21500,
        "state": "",
        "group": "086873802100001",
        "group_num": [
            "recc6kGz9qrYFqEAk"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 111,
        "id": "recjUR4Pbejib5mk2",
        "row": 21490,
        "state": "",
        "group": "",
        "group_num": [
            "recSLNcUaK2EP3SYN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1494,
        "id": "recl4UTWPouPbPhe6",
        "row": 21479,
        "state": "",
        "group": "",
        "group_num": [
            "recdfZEmGl3D5a43Z"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 944,
        "id": "recmtuOneieyEmqWB",
        "row": 21487,
        "state": "",
        "group": "",
        "group_num": [
            "rec2KxEnPqSZzksWZ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 930,
        "2023": 1041,
        "id": "recngjNFZqRMAj6zW",
        "row": 21499,
        "state": "",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 607,
        "2024": 377,
        "id": "recOpMQaXoQZ2ulKQ",
        "row": 21461,
        "state": "",
        "group": "086859801100001",
        "group_num": [
            "recE7zW4tDoW4PcWK"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 939,
        "id": "recRCix5NVAsYTFkS",
        "row": 21484,
        "state": "",
        "group": "086869601600003",
        "group_num": [
            "rec08WnLJVfBcJPS7"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 137,
        "id": "recRLctvMdbEeAku2",
        "row": 21462,
        "state": "",
        "group": "",
        "group_num": [
            "recdIxNBjSeyqTaGF"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 541,
        "id": "recUv5USnLVMpnbtq",
        "row": 21502,
        "state": "",
        "group": "",
        "group_num": [
            "recUaOClIRYDTFXSL"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "2023": 2199,
        "id": "recVy2oDvjNZzHxfn",
        "row": 21464,
        "state": "",
        "group": "",
        "group_num": [
            "recdIxNBjSeyqTaGF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "recVgCeHKDy38FjCH",
        "row": 21501,
        "state": "",
        "group": "",
        "group_num": [
            "recUaOClIRYDTFXSL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "recWKb4Z40NPhJWHq",
        "row": 21477,
        "state": "",
        "group": "",
        "group_num": [
            "recgSjNFAc9mmjI8s"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1724,
        "id": "recWfXrzTgLw21sgK",
        "row": 21474,
        "state": "",
        "group": "",
        "group_num": [
            "recHZYCBHMBFzsFl2"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 678,
        "id": "recXICyHBwNrISN9s",
        "row": 21465,
        "state": "",
        "group": "",
        "group_num": [
            "recrbJON9AGO23vZf"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 1150,
        "id": "recXbu90Ky1m6jR4j",
        "row": 21478,
        "state": "",
        "group": "086869601200020",
        "group_num": [
            "recAqD7u5Nzl9Ar0j"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 915,
        "id": "recY6KLjPgFqEi1mS",
        "row": 21486,
        "state": "",
        "group": "",
        "group_num": [
            "recmKmzGZKm0Ig4X0"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "rec1uCfm0wclsiPbR",
        "row": 21466,
        "state": "",
        "group": "",
        "group_num": [
            "rec16G7VpBca0tyqb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 767,
        "id": "rec7I9TIsvIXi0c9W",
        "row": 21458,
        "state": "",
        "group": "086859801000002",
        "group_num": [
            "recqFryrpDESgUt6W"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "id": "rec7mlVGzHzJH0mIC",
        "row": 21471,
        "state": "",
        "group": "",
        "group_num": [
            "recoN25mK7S7KSNte"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4663,
        "id": "recaZBdAWxdXnhTP2",
        "row": 21469,
        "state": "",
        "group": "086862002100109",
        "group_num": [
            "reccZOyVawj3efCCM"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 931,
        "id": "recaCLwa4n4ph0m58",
        "row": 21498,
        "state": "",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "recbUH54WiI0FrDYt",
        "row": 21480,
        "state": "",
        "group": "",
        "group_num": [
            "recdfZEmGl3D5a43Z"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 1202,
        "id": "recbX7fppkP92xG1b",
        "row": 21468,
        "state": "",
        "group": "086862002100109",
        "group_num": [
            "reccZOyVawj3efCCM"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1663,
        "id": "recc9AUVh3medsG1H",
        "row": 21493,
        "state": "",
        "group": "",
        "group_num": [
            "recbqZUPDLqa9GhQM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 311,
        "id": "recdfe7djafoUBFmd",
        "row": 21492,
        "state": "",
        "group": "",
        "group_num": [
            "recEDDb4kag7P3JXc"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "reco4ScG7Q5NKG7vK",
        "row": 21491,
        "state": "",
        "group": "",
        "group_num": [
            "recEDDb4kag7P3JXc"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "rectIICy06LyvUegU",
        "row": 21495,
        "state": "",
        "group": "",
        "group_num": [
            "recAQ1WuMn2ZMfGOz"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 626,
        "id": "recuKwdDRiT63OoOA",
        "row": 21470,
        "state": "",
        "group": "",
        "group_num": [
            "recoN25mK7S7KSNte"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 263,
        "id": "recuRrPRussQr5FSj",
        "row": 21463,
        "state": "",
        "group": "",
        "group_num": [
            "recdIxNBjSeyqTaGF"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "2024": 31,
        "id": "recuc3FKgCgW7LGMz",
        "row": 21503,
        "state": "",
        "group": "",
        "group_num": [
            "recUaOClIRYDTFXSL"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1079,
        "id": "recur7pmdfhNcvDNr",
        "row": 21481,
        "state": "",
        "group": "",
        "group_num": [
            "recWS89p9PEA90roJ"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 482,
        "id": "recvolIofwl25PgAA",
        "row": 21504,
        "state": "",
        "group": "",
        "group_num": [
            "recUaOClIRYDTFXSL"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 531,
        "id": "recwyrPlWexbhY0n5",
        "row": 21476,
        "state": "",
        "group": "",
        "group_num": [
            "recgSjNFAc9mmjI8s"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recA0nNRsyAsPz9tL",
        "row": 21473,
        "state": "",
        "group": "",
        "group_num": [
            "recKRzxsjcRZqjwr0"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 557,
        "id": "recBZw4zlWmEf23om",
        "row": 21506,
        "state": "",
        "group": "",
        "group_num": [
            "rec6BuRJHXSpyNtvb"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1006,
        "id": "recCPl7rlihJvKPPw",
        "row": 21489,
        "state": "",
        "group": "",
        "group_num": [
            "recSLNcUaK2EP3SYN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 609,
        "id": "recCP03hyaSDeFtKf",
        "row": 21488,
        "state": "",
        "group": "",
        "group_num": [
            "rec2KxEnPqSZzksWZ"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "recDw1DEymrN1iWYn",
        "row": 21483,
        "state": "",
        "group": "",
        "group_num": [
            "recWS89p9PEA90roJ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "2023": 1063,
        "id": "recD7sOahEIZhSF1Y",
        "row": 21475,
        "state": "",
        "group": "",
        "group_num": [
            "recHZYCBHMBFzsFl2"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "2023": 1629,
        "id": "recEsu3sLdkIVJhLI",
        "row": 21496,
        "state": "",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1010,
        "2023": 958,
        "id": "recFwkPqBaKUg2epr",
        "row": 21497,
        "state": "",
        "group": "086873801900001",
        "group_num": [
            "reco0eIaoeAz5Z4zr"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "id": "recHwTC2OmhlexQb4",
        "row": 21505,
        "state": "",
        "group": "086873802300001",
        "group_num": [
            "recTEBMVpZu3Yhuuq"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1494,
        "id": "recHp703mMssdeNcN",
        "row": 21472,
        "state": "",
        "group": "",
        "group_num": [
            "recGogzRPvyNPhgdL"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1127,
        "id": "recIWIdOlmTMqcJGo",
        "row": 21485,
        "state": "",
        "group": "086869601600006",
        "group_num": [
            "recBp2s5FIqJnXLAK"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 572,
        "id": "recIhnrgY3wDSyz5X",
        "row": 21459,
        "state": "",
        "group": "086859801000002",
        "group_num": [
            "recqFryrpDESgUt6W"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3472,
        "id": "recKx85f29fMPe6zv",
        "row": 21467,
        "state": "",
        "group": "086862002100109",
        "group_num": [
            "reccZOyVawj3efCCM"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recLFKDqGxH0uUVDL",
        "row": 21457,
        "state": "",
        "group": "086857701600001",
        "group_num": [
            "recUm2mvhGoKnadNj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recMfWSpOH1LIbKwq",
        "row": 21494,
        "state": "",
        "group": "",
        "group_num": [
            "recAQ1WuMn2ZMfGOz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recNJHzxd5HeDzHBl",
        "row": 21482,
        "state": "",
        "group": "",
        "group_num": [
            "recWS89p9PEA90roJ"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 47,
        "id": "rece2jUERSFHxfvhK",
        "row": 21518,
        "state": "",
        "group": "086884401100002",
        "group_num": [
            "recg1pYaXStKKeMja"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 363,
        "id": "recfy3Fb6H4TjKUcu",
        "row": 21531,
        "state": "",
        "group": "086970601000202",
        "group_num": [
            "recciimQG1uxLu0Si"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "rechcbLBJmwmRtlkw",
        "row": 21519,
        "state": "",
        "group": "086884401100002",
        "group_num": [
            "recg1pYaXStKKeMja"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recihGPO54JYgT52z",
        "row": 21546,
        "state": "",
        "group": "",
        "group_num": [
            "recH2BPduREHFQCl9"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2637,
        "id": "reckHIdep4aEXsnFE",
        "row": 21542,
        "state": "",
        "group": "087000001700001",
        "group_num": [
            "reccKVk2JL5mhGNiy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1017,
        "id": "reck4lt6WaETKHek2",
        "row": 21514,
        "state": "",
        "group": "086881601000001",
        "group_num": [
            "recK8UKMoFHTDlCE4"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 147,
        "id": "reckd0JbbIiBHv46X",
        "row": 21525,
        "state": "",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1522,
        "id": "reckhiVfPofozz3qR",
        "row": 21538,
        "state": "",
        "group": "086989302200001",
        "group_num": [
            "recCaRAp97sZOt9MW"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 447,
        "id": "reckpqrbgDSUBPa9s",
        "row": 21526,
        "state": "",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 428,
        "id": "reclW40y0YpscdVCv",
        "row": 21532,
        "state": "",
        "group": "",
        "group_num": [
            "recd8h84q457bFDF5"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1087,
        "id": "recmdNzomWF1NgSkO",
        "row": 21521,
        "state": "",
        "group": "",
        "group_num": [
            "recl7qTvvtHmA8fNC"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recOupBJMHEAddtN8",
        "row": 21535,
        "state": "",
        "group": "",
        "group_num": [
            "recY3rh4uDakAJCZ5"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recPZIqtn94gnlaYh",
        "row": 21543,
        "state": "",
        "group": "087000001700001",
        "group_num": [
            "reccKVk2JL5mhGNiy"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2382,
        "id": "recQWbTKgkwGgxtLF",
        "row": 21511,
        "state": "",
        "group": "",
        "group_num": [
            "recBp3P77dkyURn6g"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 76,
        "id": "recQ30VjljVhM9bIK",
        "row": 21540,
        "state": "",
        "group": "",
        "group_num": [
            "recXUlEZhtf1XueOp"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 683,
        "id": "recQgqFgqdVRBJk5v",
        "row": 21529,
        "state": "",
        "group": "",
        "group_num": [
            "recK9PaWRVwq6yuew"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1008,
        "id": "recRZnxLtRNaCkqMK",
        "row": 21547,
        "state": "",
        "group": "",
        "group_num": [
            "recmu8jJvwJYVuIGb"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recRgABi9KJs7DQqS",
        "row": 21522,
        "state": "",
        "group": "",
        "group_num": [
            "recJ3kS1mp2khVpol"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1183,
        "id": "recSYI4v1GFUrXaLZ",
        "row": 21555,
        "state": "",
        "group": "087707601400007",
        "group_num": [
            "rec1pXAyBuVN1qTFO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1560,
        "id": "recSePsKGzbxywsiM",
        "row": 21537,
        "state": "",
        "group": "086989302200001",
        "group_num": [
            "recCaRAp97sZOt9MW"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 488,
        "id": "recUjdQ6P0yCPsEh0",
        "row": 21510,
        "state": "",
        "group": "",
        "group_num": [
            "recdnjNc4uzJ1xNGN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 481,
        "id": "recW6BLTUEZkBMPuu",
        "row": 21507,
        "state": "",
        "group": "",
        "group_num": [
            "recl3hebbjmhFZi0o"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 421,
        "id": "recXhDV9NCT1aPpgj",
        "row": 21541,
        "state": "",
        "group": "",
        "group_num": [
            "recXUlEZhtf1XueOp"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "2024": 1442,
        "id": "recYTv01MCDGcTECl",
        "row": 21534,
        "state": "",
        "group": "",
        "group_num": [
            "recY3rh4uDakAJCZ5"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1532,
        "id": "recYrK4i6UfEVdiSi",
        "row": 21520,
        "state": "",
        "group": "",
        "group_num": [
            "recl7qTvvtHmA8fNC"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1313,
        "id": "rec1Ni9dZoBDz90rO",
        "row": 21527,
        "state": "",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "rec84ybZV6x0C9x6I",
        "row": 21551,
        "state": "",
        "group": "",
        "group_num": [
            "rechlf0vWoI2Z3kiS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rec86MXlYJOd7GC7Q",
        "row": 21516,
        "state": "",
        "group": "",
        "group_num": [
            "recBov9KgnYMF5Ul6"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 1200,
        "id": "recaFn9BueQpoQdaL",
        "row": 21545,
        "state": "",
        "group": "",
        "group_num": [
            "recWs1hvE9OZK0f6b"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1256,
        "id": "reccHI46xAkzG4Vrh",
        "row": 21528,
        "state": "",
        "group": "",
        "group_num": [
            "recZzpPv0O3vc7BAr"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recoSHorrC71F9hGI",
        "row": 21550,
        "state": "",
        "group": "",
        "group_num": [
            "recmu8jJvwJYVuIGb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1331,
        "id": "recoAv6LPMlqiqZpD",
        "row": 21524,
        "state": "",
        "group": "086887001500013",
        "group_num": [
            "recnLZupafYmx6wKm"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1342,
        "2023": 2424,
        "2024": 2898,
        "id": "recp9cJl5ihgA2vtd",
        "row": 21554,
        "state": "",
        "group": "087707601400007",
        "group_num": [
            "rec1pXAyBuVN1qTFO"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "2023": 3331,
        "id": "recqJJJkGqlDfskDo",
        "row": 21544,
        "state": "",
        "group": "087000001700001",
        "group_num": [
            "reccKVk2JL5mhGNiy"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recqnzzCok9hgWIpE",
        "row": 21536,
        "state": "",
        "group": "086989001200001",
        "group_num": [
            "recR9VxzWWPqWv1Qm"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1128,
        "id": "recsa9E7JbAsh7dcW",
        "row": 21552,
        "state": "",
        "group": "087600101700001",
        "group_num": [
            "rec5X9ZmVBLH0r2Lu"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 240,
        "2023": 245,
        "id": "recupMrzEKykPNQ23",
        "row": 21509,
        "state": "",
        "group": "",
        "group_num": [
            "recdnjNc4uzJ1xNGN"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 744,
        "id": "recxqqqujWKpGKkJA",
        "row": 21513,
        "state": "",
        "group": "086881601000001",
        "group_num": [
            "recK8UKMoFHTDlCE4"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 557,
        "id": "recyQ6Z5t11sKdrmA",
        "row": 21549,
        "state": "",
        "group": "",
        "group_num": [
            "recmu8jJvwJYVuIGb"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 924,
        "id": "recyaaPeodFfMwhnP",
        "row": 21533,
        "state": "",
        "group": "",
        "group_num": [
            "recd8h84q457bFDF5"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 467,
        "id": "recz0XPATS24PMhCf",
        "row": 21530,
        "state": "",
        "group": "",
        "group_num": [
            "rec3FY1I8VVOYSrg3"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 133,
        "id": "reczCZAEK6DUdZlLP",
        "row": 21548,
        "state": "",
        "group": "",
        "group_num": [
            "recmu8jJvwJYVuIGb"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1325,
        "id": "recEdjMPloInHct8r",
        "row": 21539,
        "state": "",
        "group": "",
        "group_num": [
            "recXUlEZhtf1XueOp"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 872,
        "id": "recFvduMGZ6kRj1fk",
        "row": 21508,
        "state": "",
        "group": "",
        "group_num": [
            "recCIvMhm7v78yWnO"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1034,
        "id": "recFPLK7DKLQMBsx8",
        "row": 21523,
        "state": "",
        "group": "",
        "group_num": [
            "recJ3kS1mp2khVpol"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4333,
        "id": "recFn9wph7eKFVsaa",
        "row": 21517,
        "state": "",
        "group": "086882201400010",
        "group_num": [
            "recRtVtXa5cFN7r0J"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recHP4cVJvrjMrKEZ",
        "row": 21553,
        "state": "",
        "group": "087600101700001",
        "group_num": [
            "rec5X9ZmVBLH0r2Lu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1342,
        "id": "recI9xe3MsV5dyqDA",
        "row": 21515,
        "state": "",
        "group": "",
        "group_num": [
            "recHONUp1kqIcG6jJ"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recLbzJSDlEyRLjYl",
        "row": 21556,
        "state": "",
        "group": "087707601400007",
        "group_num": [
            "rec1pXAyBuVN1qTFO"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2083,
        "id": "recMjfqSwdAHKtHob",
        "row": 21512,
        "state": "",
        "group": "086880802400011",
        "group_num": [
            "rectftZwL7DzQkWjF"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "receGNbXaXaptNacS",
        "row": 21582,
        "state": "",
        "group": "088079102600011",
        "group_num": [
            "recMsNCfDGuYiw07U"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 111,
        "id": "rech7s7WEXiHtCppc",
        "row": 21599,
        "state": "",
        "group": "",
        "group_num": [
            "recSD9Yyyr4ROwDa9"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3148,
        "id": "rechivbbzC37F2TSK",
        "row": 21560,
        "state": "",
        "group": "",
        "group_num": [
            "recmFAxL2JGoPUeMj"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 801,
        "id": "recizSFkCpJHocIxE",
        "row": 21604,
        "state": "",
        "group": "088552401000008",
        "group_num": [
            "recQv6U0bCZGqJbre"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recjS6IEDQAIqPXvn",
        "row": 21584,
        "state": "",
        "group": "088199903100106",
        "group_num": [
            "recuTmRwmuAMmqA00"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recjzFlJ1ojgC4bOE",
        "row": 21568,
        "state": "",
        "group": "",
        "group_num": [
            "recCQyHqMEJf65dBy"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2174,
        "id": "reckyLHSTFZT73ymy",
        "row": 21562,
        "state": "",
        "group": "",
        "group_num": [
            "recchghgWmYekkTsn"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "2023": 2257,
        "2024": 1853,
        "id": "recOtPWQMCTM6bj6o",
        "row": 21561,
        "state": "",
        "group": "",
        "group_num": [
            "recchghgWmYekkTsn"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "recOepjOyPwxJsIhH",
        "row": 21595,
        "state": "",
        "group": "",
        "group_num": [
            "rec82i8eoSs2BU4r2"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 660,
        "id": "recRiFAMfVxoJUlAt",
        "row": 21597,
        "state": "",
        "group": "",
        "group_num": [
            "recBJ9hWoGqi3whO0"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1693,
        "2023": 2468,
        "id": "recRoQCbMeALl7Jut",
        "row": 21576,
        "state": "",
        "group": "",
        "group_num": [
            "recHNVLuxOVJOGWEN"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "2023": 47,
        "id": "recSsjhgNfbxK6eHO",
        "row": 21593,
        "state": "",
        "group": "088352801900001",
        "group_num": [
            "recQuRY0Ihfc619mE"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3087,
        "id": "recUsB7ThEdo8QdA4",
        "row": 21570,
        "state": "",
        "group": "",
        "group_num": [
            "recknpa4BqEyDMecb"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recUrVjuxwTRCIyz2",
        "row": 21581,
        "state": "",
        "group": "088079102600011",
        "group_num": [
            "recMsNCfDGuYiw07U"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2087,
        "id": "recVFuWrTBXUyMD17",
        "row": 21564,
        "state": "",
        "group": "",
        "group_num": [
            "recP8ZZt4qKUaQhvB"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2003,
        "id": "recV5pGtvALp3R5hx",
        "row": 21580,
        "state": "",
        "group": "",
        "group_num": [
            "rec0Zkydbh1pCdLub"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 111,
        "2024": 111,
        "id": "recWtZb1pAP8QUuHD",
        "row": 21587,
        "state": "",
        "group": "",
        "group_num": [
            "recriETZ6GwrG66xZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 417,
        "id": "recXRM7alNcBCovJ7",
        "row": 21591,
        "state": "",
        "group": "",
        "group_num": [
            "rec8mr9V6XVejyeWt"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "recX5z7CDLNb7Iel4",
        "row": 21583,
        "state": "",
        "group": "",
        "group_num": [
            "recudU3fsQKpkQ5lj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recZL9WHbIaOjFmxY",
        "row": 21585,
        "state": "",
        "group": "088199903100106",
        "group_num": [
            "recuTmRwmuAMmqA00"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2512,
        "2023": 2319,
        "id": "rec2kqG8CgEqWiOmd",
        "row": 21579,
        "state": "",
        "group": "",
        "group_num": [
            "recHNVLuxOVJOGWEN"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "rec3Fj1o70SEaAuyu",
        "row": 21603,
        "state": "",
        "group": "",
        "group_num": [
            "recuuFRZareOR7Kwn"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 391,
        "id": "rec9O4VfCYyMXo327",
        "row": 21601,
        "state": "",
        "group": "",
        "group_num": [
            "recnPpaokr8ptzQtd"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "recaH8fQbUDCDm5oF",
        "row": 21558,
        "state": "",
        "group": "",
        "group_num": [
            "recmFAxL2JGoPUeMj"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recbBt24OCflFNRGk",
        "row": 21572,
        "state": "",
        "group": "087712102100003",
        "group_num": [
            "recGLxjORVh3F4dMz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2023": 239,
        "id": "recbltyohM99UQL8a",
        "row": 21594,
        "state": "",
        "group": "088352801900001",
        "group_num": [
            "recQuRY0Ihfc619mE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1899,
        "id": "recbqgUE9mlDk5udt",
        "row": 21592,
        "state": "",
        "group": "",
        "group_num": [
            "reclJVn8gHRphunEr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "id": "recd9q7leRZ7IFIRT",
        "row": 21602,
        "state": "",
        "group": "",
        "group_num": [
            "recnPpaokr8ptzQtd"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1069,
        "2024": 750,
        "id": "recr1c9jM1yFeMf5V",
        "row": 21565,
        "state": "",
        "group": "",
        "group_num": [
            "rec7FZZ8Q7BNXr0Yn"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "id": "recsauVNPj7hNjXAa",
        "row": 21605,
        "state": "",
        "group": "088552401000008",
        "group_num": [
            "recQv6U0bCZGqJbre"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3226,
        "id": "recuTMBOH0D4uaSA8",
        "row": 21563,
        "state": "",
        "group": "",
        "group_num": [
            "recchghgWmYekkTsn"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 435,
        "id": "recvFr8vrkAFgkQto",
        "row": 21598,
        "state": "",
        "group": "",
        "group_num": [
            "recSD9Yyyr4ROwDa9"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 630,
        "id": "recvloUhZ6TUKxjdJ",
        "row": 21566,
        "state": "",
        "group": "",
        "group_num": [
            "rec7FZZ8Q7BNXr0Yn"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "id": "recxXzgVRfepfEgA0",
        "row": 21586,
        "state": "",
        "group": "088275801000213",
        "group_num": [
            "recnG760DS9Vha6Iu"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 1000,
        "id": "recycGgfq7a7CaCUg",
        "row": 21575,
        "state": "",
        "group": "087715702000002",
        "group_num": [
            "rec7fFl1wVtKAFpiZ"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1539,
        "id": "recz2gYgVIOJN0IBE",
        "row": 21606,
        "state": "",
        "group": "088553601700005",
        "group_num": [
            "recNRTcwb3WfXItBw"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2433,
        "2023": 2653,
        "id": "recA0TDK0DWcNHAF4",
        "row": 21557,
        "state": "",
        "group": "087707601400007",
        "group_num": [
            "rec1pXAyBuVN1qTFO"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 623,
        "id": "recAvv9mXSZTSyxEC",
        "row": 21600,
        "state": "",
        "group": "",
        "group_num": [
            "recnPpaokr8ptzQtd"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "id": "recANbkpK29yxcIz4",
        "row": 21588,
        "state": "",
        "group": "",
        "group_num": [
            "rec8mr9V6XVejyeWt"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recDnPbDAh7HUjPi8",
        "row": 21569,
        "state": "",
        "group": "",
        "group_num": [
            "recknpa4BqEyDMecb"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1114,
        "2024": 1292,
        "id": "recEszAbCa7MFOhFs",
        "row": 21578,
        "state": "",
        "group": "",
        "group_num": [
            "recHNVLuxOVJOGWEN"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "id": "recEBzL81gya7pOw9",
        "row": 21589,
        "state": "",
        "group": "",
        "group_num": [
            "rec8mr9V6XVejyeWt"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1944,
        "id": "recF357uzp4GLjuaj",
        "row": 21574,
        "state": "",
        "group": "087715701600001",
        "group_num": [
            "recmRtGsy4gS209rS"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 962,
        "id": "recGbUbRccvHyRYcg",
        "row": 21559,
        "state": "",
        "group": "",
        "group_num": [
            "recmFAxL2JGoPUeMj"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1466,
        "id": "recGmwrVVFQhIWGBB",
        "row": 21573,
        "state": "",
        "group": "087715701600001",
        "group_num": [
            "recmRtGsy4gS209rS"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2466,
        "id": "recHX0lbEytIHGivo",
        "row": 21567,
        "state": "",
        "group": "087709304500210",
        "group_num": [
            "recp0ozj6djU99Oio"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 208,
        "id": "recIWggZ4mZTAPG8f",
        "row": 21590,
        "state": "",
        "group": "",
        "group_num": [
            "rec8mr9V6XVejyeWt"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recJw4a1Xkr7F1OMq",
        "row": 21571,
        "state": "",
        "group": "",
        "group_num": [
            "recknpa4BqEyDMecb"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "2023": 1472,
        "id": "recKV34rkyNTVBQ7h",
        "row": 21577,
        "state": "",
        "group": "",
        "group_num": [
            "recHNVLuxOVJOGWEN"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2490,
        "id": "recMlMyhDUDSeepOj",
        "row": 21596,
        "state": "",
        "group": "",
        "group_num": [
            "recBJ9hWoGqi3whO0"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2020,
        "id": "recfa7aaSo5FrcEZY",
        "row": 21626,
        "state": "",
        "group": "",
        "group_num": [
            "recZNrteQwFSFD6OH"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recjISULwYapmt1hU",
        "row": 21608,
        "state": "",
        "group": "",
        "group_num": [
            "rec7CHjyBEva8qc7o"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 410,
        "id": "reclIgFBpQXUUc04m",
        "row": 21611,
        "state": "",
        "group": "089553001000304",
        "group_num": [
            "recGVkkV2vY89Hyap"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recPMZ9thHlGJZPZv",
        "row": 21616,
        "state": "",
        "group": "",
        "group_num": [
            "rechuZ6mS6r6kZ7WI"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recQnOzxFMVeb8rSi",
        "row": 21613,
        "state": "",
        "group": "",
        "group_num": [
            "recy7nhfXiprSmAqa"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1063,
        "id": "recSOs1JAAI76EaYh",
        "row": 21614,
        "state": "",
        "group": "",
        "group_num": [
            "recy7nhfXiprSmAqa"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 316,
        "id": "recVqWwtZSZQUSo6W",
        "row": 21623,
        "state": "",
        "group": "",
        "group_num": [
            "recx0IepzadFr43ws"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 240,
        "2023": 240,
        "id": "recXvVMyr7agDBAcT",
        "row": 21610,
        "state": "",
        "group": "089553001000304",
        "group_num": [
            "recGVkkV2vY89Hyap"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 631,
        "2023": 105,
        "id": "rec3oQ3dwFp36wCtR",
        "row": 21622,
        "state": "",
        "group": "",
        "group_num": [
            "recx0IepzadFr43ws"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 912,
        "id": "rec8XZBEHaLy56uuF",
        "row": 21621,
        "state": "",
        "group": "THEVANGUARDGROUP",
        "group_num": [
            "recR9O1iKXopeUAb2",
            "recicyKlqVdBzfbbl"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 439,
        "2023": 129,
        "2024": 84,
        "id": "reca1L6kCdMDAc8cB",
        "row": 21624,
        "state": "",
        "group": "",
        "group_num": [
            "reclXYll8h4frYaFE"
        ],
        "procedure": "OP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1082,
        "id": "recd2XRrFM1T8iqx9",
        "row": 21619,
        "state": "",
        "group": "",
        "group_num": [
            "recQr0iCMiWzYcRzr"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2197,
        "id": "recsBSsGg8gM33N84",
        "row": 21618,
        "state": "",
        "group": "",
        "group_num": [
            "recN3UGDF3CAESxNx"
        ],
        "procedure": "PHP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1859,
        "id": "rect0K7D7RUzLUA9R",
        "row": 21620,
        "state": "",
        "group": "",
        "group_num": [
            "recQr0iCMiWzYcRzr"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2702,
        "id": "recvma5SUYuO1JxLZ",
        "row": 21627,
        "state": "",
        "group": "",
        "group_num": [
            "recZNrteQwFSFD6OH"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 193,
        "id": "recBuiktzvHzxnjMg",
        "row": 21609,
        "state": "",
        "group": "088759901800009",
        "group_num": [
            "reco0k630XQKqlQmv"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 667,
        "id": "recCWfL4N2bIhfKid",
        "row": 21615,
        "state": "",
        "group": "",
        "group_num": [
            "rechuZ6mS6r6kZ7WI"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 311,
        "id": "recGIhQzzTrGDSO2h",
        "row": 21625,
        "state": "",
        "group": "",
        "group_num": [
            "reclXYll8h4frYaFE"
        ],
        "procedure": "IOP",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1919,
        "id": "recKptdkhop5Muyq9",
        "row": 21612,
        "state": "",
        "group": "",
        "group_num": [
            "recedXY8jCdwKMadR"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recLOW0bsEo1TGswW",
        "row": 21617,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "RTC",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "id": "recMwqZyLaUDIQCc7",
        "row": 21607,
        "state": "",
        "group": "",
        "group_num": [
            "recn3VTVGDGWrkxjx"
        ],
        "procedure": "Detox",
        "provider": "AETNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "reciKWeJ7qpbracs1",
        "row": 21633,
        "state": "",
        "group": "",
        "group_num": [
            "recFDu1ehEM84LBwd"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "recmkrzVa52835jdu",
        "row": 21656,
        "state": "",
        "group": "",
        "group_num": [
            "recDViLXG6ZZhg2dl"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "recSZvV1M8XN4q4ic",
        "row": 21642,
        "state": "",
        "group": "",
        "group_num": [
            "reczZZqrBNJeiqjXz"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "id": "recSNVcPNoNMnDOId",
        "row": 21641,
        "state": "",
        "group": "",
        "group_num": [
            "reczZZqrBNJeiqjXz"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 537,
        "id": "recUVs7IFzeUWBTXE",
        "row": 21636,
        "state": "",
        "group": "5400002249",
        "group_num": [
            "recSxRTGjl8k6jFSD"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "id": "recVygiFaNsdu0Rbn",
        "row": 21637,
        "state": "",
        "group": "5400004955",
        "group_num": [
            "recNzVeVonpzMf8wC"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 316,
        "id": "recVBIZJVznYHDoLQ",
        "row": 21647,
        "state": "",
        "group": "",
        "group_num": [
            "recVNdXTttb31IasW"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "id": "recWtQQVidulE3c9c",
        "row": 21630,
        "state": "",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 283,
        "id": "recZlFreLBh8veYxw",
        "row": 21639,
        "state": "",
        "group": "",
        "group_num": [
            "recEAFTJH2R11AxLo"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 134,
        "id": "rec17Mlhdx0UcpWyV",
        "row": 21648,
        "state": "",
        "group": "",
        "group_num": [
            "recVNdXTttb31IasW"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 264,
        "id": "rec2ii1yCojntTtSQ",
        "row": 21652,
        "state": "",
        "group": "",
        "group_num": [
            "recntDgPoTafAi9im"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 108,
        "2024": 108,
        "id": "rec3Da0tHOpkPrMqa",
        "row": 21645,
        "state": "",
        "group": "",
        "group_num": [
            "recCJnklbb5Me5lRB"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 763,
        "id": "rec5iCQNYcNqMr4Ya",
        "row": 21653,
        "state": "",
        "group": "",
        "group_num": [
            "recXhzxv7VEwWXDYI"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 352,
        "id": "rec6F8GgqKtSDIfLT",
        "row": 21628,
        "state": "",
        "group": "",
        "group_num": [
            "reclEbZrdZDE5igx6"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "rec6qJkeOJC7eYXwy",
        "row": 21646,
        "state": "",
        "group": "",
        "group_num": [
            "recCJnklbb5Me5lRB"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "reccJamnOzEwQDZQg",
        "row": 21640,
        "state": "",
        "group": "",
        "group_num": [
            "reczZZqrBNJeiqjXz"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 143,
        "id": "recckpWainzSNTV92",
        "row": 21650,
        "state": "",
        "group": "5400010490",
        "group_num": [
            "recvlJkLQ0WpVXq6H"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 665,
        "id": "recdSOekuMYu88ucK",
        "row": 21632,
        "state": "",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "id": "recpwzRQzxpMzTf61",
        "row": 21631,
        "state": "",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recpiZ4c8NIhgydpZ",
        "row": 21649,
        "state": "",
        "group": "5400010490",
        "group_num": [
            "recvlJkLQ0WpVXq6H"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 115,
        "id": "recwG3RhieljAby3b",
        "row": 21655,
        "state": "",
        "group": "",
        "group_num": [
            "recNXsC8OhwHcAW03"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 488,
        "id": "reczQcXQzI3P9J0RY",
        "row": 21654,
        "state": "",
        "group": "5400013043",
        "group_num": [
            "recaVmtiHejdWz8Mu"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "2023": 610,
        "id": "recBKdgCD3gmXvR4T",
        "row": 21643,
        "state": "",
        "group": "",
        "group_num": [
            "recCJnklbb5Me5lRB"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 90,
        "id": "recBLhO4b0cfmY6tg",
        "row": 21634,
        "state": "",
        "group": "",
        "group_num": [
            "recFDu1ehEM84LBwd"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1206,
        "id": "recCaGRqlNtxTJnxv",
        "row": 21651,
        "state": "",
        "group": "",
        "group_num": [
            "recntDgPoTafAi9im"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 552,
        "id": "recDNYLp0TQCCubhW",
        "row": 21638,
        "state": "",
        "group": "",
        "group_num": [
            "recEAFTJH2R11AxLo"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 266,
        "id": "recHHHeYFIkJTON63",
        "row": 21644,
        "state": "",
        "group": "",
        "group_num": [
            "recCJnklbb5Me5lRB"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 158,
        "id": "recL4MvIAvXLVDShG",
        "row": 21635,
        "state": "",
        "group": "",
        "group_num": [
            "recFDu1ehEM84LBwd"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 562,
        "2023": 749,
        "id": "recMMBpij0c9mfDti",
        "row": 21629,
        "state": "",
        "group": "908868",
        "group_num": [
            "recI7eeEYhNhR5z9e"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 524,
        "id": "recgnEtlTrohdoNGi",
        "row": 21661,
        "state": "",
        "group": "5400017978",
        "group_num": [
            "rec6J8QQNvfAAXDac"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 134,
        "2023": 141,
        "id": "rechXREt31cbbbguC",
        "row": 21692,
        "state": "",
        "group": "",
        "group_num": [
            "recce6X5v7KRJ9NOM"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 569,
        "id": "reclVTcKQOeayjgKa",
        "row": 21689,
        "state": "",
        "group": "",
        "group_num": [
            "rec4cUq553gDrSVxV"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "reclMataEXzGsTUCA",
        "row": 21666,
        "state": "",
        "group": "",
        "group_num": [
            "recr0ghHsruc6ryta"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recOVMr9HoP6Tqom0",
        "row": 21678,
        "state": "",
        "group": "",
        "group_num": [
            "recUSv2I4yXbaROwH"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recPFzrv4cJRqH8Bo",
        "row": 21668,
        "state": "",
        "group": "",
        "group_num": [
            "recr0ghHsruc6ryta"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2695,
        "id": "recQaqDucYzGpGzUo",
        "row": 21683,
        "state": "",
        "group": "",
        "group_num": [
            "rec5XmumbYjy78YTt"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "recSBy7D3DqlM3csY",
        "row": 21687,
        "state": "",
        "group": "",
        "group_num": [
            "recJfEpTZihz3TAam"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 374,
        "id": "recScBcnxpLnzHdFQ",
        "row": 21670,
        "state": "",
        "group": "5400020287",
        "group_num": [
            "recuVR5Lw7A2Hnhlo"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "recSgqo2BT4nRrLfu",
        "row": 21684,
        "state": "",
        "group": "",
        "group_num": [
            "rec5XmumbYjy78YTt"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 266,
        "id": "recSkOYmGUhpuOa3w",
        "row": 21672,
        "state": "",
        "group": "",
        "group_num": [
            "recA4SR6udTSYw5ym"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "id": "recTq5dVMxBFHXxmF",
        "row": 21680,
        "state": "",
        "group": "5400030123",
        "group_num": [
            "recAwCz0T581AhZa7"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "recVJkl4XEADP13mY",
        "row": 21667,
        "state": "",
        "group": "",
        "group_num": [
            "recr0ghHsruc6ryta"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 959,
        "id": "recWwys54VGLHPTZL",
        "row": 21682,
        "state": "",
        "group": "5400030631",
        "group_num": [
            "recSOkFCTHWbaHqjY"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "id": "recWIUIb04nCayrah",
        "row": 21669,
        "state": "",
        "group": "5400019412",
        "group_num": [
            "recyjHpT2rHk5WYaJ"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "recZ8NgONYDfmMwkV",
        "row": 21675,
        "state": "",
        "group": "",
        "group_num": [
            "recIeqCXx1W8U0smQ"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recZlmgdS3V3ryxCN",
        "row": 21674,
        "state": "",
        "group": "",
        "group_num": [
            "rec9wyRn5HcrZXuzH"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "rec1c3xLzWugLmkfy",
        "row": 21657,
        "state": "",
        "group": "",
        "group_num": [
            "recDViLXG6ZZhg2dl"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 389,
        "id": "rec2VPAU4ZgWdmga7",
        "row": 21690,
        "state": "",
        "group": "",
        "group_num": [
            "recce6X5v7KRJ9NOM"
        ],
        "procedure": "PHP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "id": "rec3NXV5ZeF3DguhP",
        "row": 21677,
        "state": "",
        "group": "5400024585",
        "group_num": [
            "recDl1GIgWOn24n2f"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec8PslSa4ddGO15a",
        "row": 21660,
        "state": "",
        "group": "",
        "group_num": [
            "rec4hrXt0tzYhIXwe"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 604,
        "id": "recaP6CvwHDon5dI1",
        "row": 21673,
        "state": "",
        "group": "",
        "group_num": [
            "recA4SR6udTSYw5ym"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recddWYStYIyYPUDm",
        "row": 21658,
        "state": "",
        "group": "",
        "group_num": [
            "recxkcl7T5YN9ez4U"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 836,
        "id": "recqNhSksyiZM5KG8",
        "row": 21662,
        "state": "",
        "group": "5400017978",
        "group_num": [
            "rec6J8QQNvfAAXDac"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "id": "recvuQjllK5g44VTY",
        "row": 21659,
        "state": "",
        "group": "",
        "group_num": [
            "recLgXmOPPbeUZlb5"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 352,
        "2023": 215,
        "id": "recwMBt6l4F8UeIZf",
        "row": 21663,
        "state": "",
        "group": "5400018698",
        "group_num": [
            "recYqtcFRef5of0x7"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "2023": 475,
        "id": "recy1nQIDhSeCOhcY",
        "row": 21686,
        "state": "",
        "group": "",
        "group_num": [
            "recJfEpTZihz3TAam"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "reczvtENwv0RzUuzv",
        "row": 21665,
        "state": "",
        "group": "",
        "group_num": [
            "recr0ghHsruc6ryta"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "2023": 26,
        "id": "reczgr1kLl6KX4RPP",
        "row": 21691,
        "state": "",
        "group": "",
        "group_num": [
            "recce6X5v7KRJ9NOM"
        ],
        "procedure": "OP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 220,
        "id": "recAHOeyVa43N1nnM",
        "row": 21679,
        "state": "",
        "group": "",
        "group_num": [
            "recYWCjovVmLKl8DM"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recBFFi0UlCussM7f",
        "row": 21664,
        "state": "",
        "group": "5400018698",
        "group_num": [
            "recYqtcFRef5of0x7"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recBRHMLEpfaZo7AT",
        "row": 21693,
        "state": "",
        "group": "",
        "group_num": [
            "recO6mksSyABiPUFU"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 611,
        "id": "recCt4mnAKwMr6nL3",
        "row": 21681,
        "state": "",
        "group": "5400030631",
        "group_num": [
            "recSOkFCTHWbaHqjY"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "id": "recHKOZfzNBw9pJLr",
        "row": 21685,
        "state": "",
        "group": "",
        "group_num": [
            "recsHcPtzZonrwLrl"
        ],
        "procedure": "IOP",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 517,
        "id": "recIzZVgN2vtbyRd4",
        "row": 21676,
        "state": "",
        "group": "5400024549",
        "group_num": [
            "recvRsCSUmXhMP6kj"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 442,
        "id": "recJL2Ygdeckd5OOp",
        "row": 21671,
        "state": "",
        "group": "",
        "group_num": [
            "recA4SR6udTSYw5ym"
        ],
        "procedure": "RTC",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recMktoPosO6kmZUh",
        "row": 21688,
        "state": "",
        "group": "",
        "group_num": [
            "recJfEpTZihz3TAam"
        ],
        "procedure": "Detox",
        "provider": "ALL SAVERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 128,
        "id": "reckoW4rwhHaXf6rW",
        "row": 21697,
        "state": "",
        "group": "",
        "group_num": [
            "rechmAsb5oBB8Qx5d"
        ],
        "procedure": "OP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 244,
        "id": "recOMv7AdSwZwUaqp",
        "row": 21695,
        "state": "",
        "group": "",
        "group_num": [
            "recusaLUYQF3pDVES"
        ],
        "procedure": "PHP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2208,
        "id": "recPEKSZNdoNreOUx",
        "row": 21694,
        "state": "",
        "group": "",
        "group_num": [
            "rec8wzNVE36KundK9"
        ],
        "procedure": "PHP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "id": "rec1ycfp0geBErAFD",
        "row": 21698,
        "state": "",
        "group": "",
        "group_num": [
            "rechmAsb5oBB8Qx5d"
        ],
        "procedure": "IOP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "rec32fDtayoiv1Mtm",
        "row": 21696,
        "state": "",
        "group": "",
        "group_num": [
            "rechmAsb5oBB8Qx5d"
        ],
        "procedure": "PHP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 790,
        "id": "rec7ULmJDR19PuP9d",
        "row": 21701,
        "state": "",
        "group": "",
        "group_num": [
            "recWYthCblu6522tj"
        ],
        "procedure": "RTC",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "id": "recFGj1iKzU7X9MaL",
        "row": 21700,
        "state": "",
        "group": "",
        "group_num": [
            "recLp6iq68TqASXBi"
        ],
        "procedure": "IOP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "id": "recLMf5oP4fWClSwP",
        "row": 21699,
        "state": "",
        "group": "",
        "group_num": [
            "recLp6iq68TqASXBi"
        ],
        "procedure": "PHP",
        "provider": "ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 156,
        "id": "rechDRKoIJ05SNrtI",
        "row": 21703,
        "state": "",
        "group": "FFMEXCHANGE",
        "group_num": [
            "reczG75oBwPwEYMwa"
        ],
        "procedure": "IOP",
        "provider": "ALLIANT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 118,
        "id": "recr13SFDpbWqOIck",
        "row": 21702,
        "state": "",
        "group": "FFMEXCHANGE",
        "group_num": [
            "reczG75oBwPwEYMwa"
        ],
        "procedure": "OP",
        "provider": "ALLIANT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 112,
        "id": "recPHniswFMtWlo3Q",
        "row": 21706,
        "state": "",
        "group": "",
        "group_num": [
            "recCq4LYqVHFNvsmW"
        ],
        "procedure": "OP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recaVuURvjXe9cfMN",
        "row": 21704,
        "state": "",
        "group": "A07121",
        "group_num": [
            "recTqFTwW3jD5wvtz"
        ],
        "procedure": "Detox",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "id": "recz0YgNsMny3FU6n",
        "row": 21705,
        "state": "",
        "group": "",
        "group_num": [
            "recCq4LYqVHFNvsmW"
        ],
        "procedure": "PHP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 440,
        "id": "recnDcSfnII6QbixM",
        "row": 21714,
        "state": "",
        "group": "",
        "group_num": [
            "recjTYCqnaqIxxi2j"
        ],
        "procedure": "IOP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2866,
        "2024": 1344,
        "id": "recOrJipwdl6nN0aP",
        "row": 21719,
        "state": "",
        "group": "L231076",
        "group_num": [
            "recy7xpVBT1a2ltt2"
        ],
        "procedure": "RTC",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1214,
        "id": "recUAVLN8impXILEH",
        "row": 21715,
        "state": "",
        "group": "",
        "group_num": [
            "recxk24NK3r1CQBwj"
        ],
        "procedure": "RTC",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3527,
        "id": "recVCrgTnMA1qPeFH",
        "row": 21718,
        "state": "",
        "group": "",
        "group_num": [
            "recx5SnZYHZORlvZr"
        ],
        "procedure": "Detox",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1514,
        "id": "recXTfNO47XimXZBf",
        "row": 21708,
        "state": "",
        "group": "",
        "group_num": [
            "recu6OfTre0UxOcWz"
        ],
        "procedure": "RTC",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "2024": 240,
        "id": "rec3pafzLtC27S4A8",
        "row": 21710,
        "state": "",
        "group": "",
        "group_num": [
            "recZqV4vi6oGkoujf"
        ],
        "procedure": "IOP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 134,
        "id": "reccRt1S2rnFnyAi0",
        "row": 21712,
        "state": "",
        "group": "",
        "group_num": [
            "recdX3LqX9ASTKjoF"
        ],
        "procedure": "IOP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 174,
        "id": "recdBlVGb8fdpACEn",
        "row": 21716,
        "state": "",
        "group": "",
        "group_num": [
            "recCKSKBOVQD3wIYg"
        ],
        "procedure": "IOP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2523,
        "id": "recucjUrLqFoQYUys",
        "row": 21720,
        "state": "",
        "group": "L231076",
        "group_num": [
            "recy7xpVBT1a2ltt2"
        ],
        "procedure": "Detox",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1207,
        "id": "recz9Z7mdHVYYT9wu",
        "row": 21709,
        "state": "",
        "group": "",
        "group_num": [
            "recu6OfTre0UxOcWz"
        ],
        "procedure": "Detox",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recBfOU1BfnwGYF19",
        "row": 21707,
        "state": "",
        "group": "",
        "group_num": [
            "recCq4LYqVHFNvsmW"
        ],
        "procedure": "IOP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 556,
        "id": "recHY0GuIVPUlpIVx",
        "row": 21713,
        "state": "",
        "group": "",
        "group_num": [
            "recjTYCqnaqIxxi2j"
        ],
        "procedure": "PHP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4792,
        "2023": 5493,
        "id": "recHxEzbkx0uXsbYD",
        "row": 21717,
        "state": "",
        "group": "",
        "group_num": [
            "recx5SnZYHZORlvZr"
        ],
        "procedure": "RTC",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 452,
        "id": "recLUCYlTQV99OhwR",
        "row": 21711,
        "state": "",
        "group": "",
        "group_num": [
            "recdX3LqX9ASTKjoF"
        ],
        "procedure": "PHP",
        "provider": "ALLIED BENEFIT SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recHeHmhcytvi4Hk3",
        "row": 21721,
        "state": "",
        "group": "008150001",
        "group_num": [
            "recvTEu2rDjKm49l7"
        ],
        "procedure": "OP",
        "provider": "AMBETTER"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1329,
        "id": "recg0QfzYUFEsMILu",
        "row": 21723,
        "state": "",
        "group": "0712010",
        "group_num": [
            "recooTo1hPrMi9yXY"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1831,
        "id": "reclxBjRBoYbRJVBE",
        "row": 21728,
        "state": "",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "IOP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recRvCb6QK221Pfx0",
        "row": 21725,
        "state": "",
        "group": "",
        "group_num": [
            "recknAX2piBp75gR0"
        ],
        "procedure": "IOP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "recazmWkdrcmZSmBt",
        "row": 21727,
        "state": "",
        "group": "",
        "group_num": [
            "rec8aFv5w3nUDSufv"
        ],
        "procedure": "IOP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recBRThZgM6IX4jt0",
        "row": 21724,
        "state": "",
        "group": "",
        "group_num": [
            "recknAX2piBp75gR0"
        ],
        "procedure": "RTC",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1260,
        "id": "recEBTThlKXQ5ovZj",
        "row": 21726,
        "state": "",
        "group": "",
        "group_num": [
            "rec8aFv5w3nUDSufv"
        ],
        "procedure": "PHP",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3429,
        "id": "recJJBjLTgw7uQSFL",
        "row": 21722,
        "state": "",
        "group": "0113004",
        "group_num": [
            "rec1R3txB7v9JQtsb"
        ],
        "procedure": "Detox",
        "provider": "AMERIBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 441,
        "id": "reckvwjT2unOzWbTF",
        "row": 21731,
        "state": "",
        "group": "32201",
        "group_num": [
            "recvKwq9cTL2pJaV2"
        ],
        "procedure": "Detox",
        "provider": "AMERICAN PLAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "2024": 287,
        "id": "recndXm2AiR3UGOpV",
        "row": 21730,
        "state": "",
        "group": "32201",
        "group_num": [
            "recvKwq9cTL2pJaV2"
        ],
        "procedure": "PHP",
        "provider": "AMERICAN PLAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 199,
        "id": "recTANvmSCTWkltXZ",
        "row": 21729,
        "state": "",
        "group": "32201",
        "group_num": [
            "recvKwq9cTL2pJaV2"
        ],
        "procedure": "RTC",
        "provider": "AMERICAN PLAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1093,
        "id": "recch8Vay91anYECO",
        "row": 21733,
        "state": "",
        "group": "",
        "group_num": [
            "recUggi1TIYl51BwO"
        ],
        "procedure": "PHP",
        "provider": "AMERIHEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1794,
        "id": "recGULaVSjf2m8OER",
        "row": 21732,
        "state": "",
        "group": "",
        "group_num": [
            "recUggi1TIYl51BwO"
        ],
        "procedure": "RTC",
        "provider": "AMERIHEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1670,
        "id": "recfJlOkk2TWMcIF2",
        "row": 21736,
        "state": "",
        "group": "",
        "group_num": [
            "recNPc7bMKyALS4lq"
        ],
        "procedure": "IOP",
        "provider": "ANGLE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recz5dpzi6nWUMybP",
        "row": 21734,
        "state": "",
        "group": "",
        "group_num": [
            "recNPc7bMKyALS4lq"
        ],
        "procedure": "PHP",
        "provider": "ANGLE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 795,
        "id": "recHktmg1ShWKJgSY",
        "row": 21735,
        "state": "",
        "group": "",
        "group_num": [
            "recNPc7bMKyALS4lq"
        ],
        "procedure": "OP",
        "provider": "ANGLE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "2024": 254,
        "id": "recfFVx23g9mxFLDW",
        "row": 16948,
        "state": "",
        "group": "",
        "group_num": [
            "recRalvL5CyucKLAY"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 125,
        "id": "recg7PA5SEy5tGnGE",
        "row": 16981,
        "state": "",
        "group": "L01412M010",
        "group_num": [
            "recuKYM4mN0NdVkJv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "rech3XT2rAR4jcEeW",
        "row": 16936,
        "state": "",
        "group": "",
        "group_num": [
            "rece2WpYk9pN4Q4tK"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1550,
        "id": "recjMcKAC2NcqOkbQ",
        "row": 16960,
        "state": "",
        "group": "",
        "group_num": [
            "recxjBbtBOXq7jQCL"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3167,
        "2023": 2139,
        "id": "recjo7B9r5exfVFlv",
        "row": 16983,
        "state": "",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "reclJnKCPFrz7veTg",
        "row": 16978,
        "state": "",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 210,
        "id": "recQwTiT09xtwaR0b",
        "row": 16956,
        "state": "",
        "group": "",
        "group_num": [
            "recXBsW5Z7M9T25Mx"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recRh8b7vSGEt4XqP",
        "row": 16966,
        "state": "",
        "group": "276945M004",
        "group_num": [
            "rec4hkUQxE4q1Pby7"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recSeLqdbjGmqhXM4",
        "row": 16951,
        "state": "",
        "group": "",
        "group_num": [
            "recJ8RBn6LrbOFZSO"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 350,
        "id": "recTRFxu5D1lx01ud",
        "row": 16945,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 448,
        "2024": 745,
        "id": "recUTzhYoWzrmmq78",
        "row": 16971,
        "state": "",
        "group": "",
        "group_num": [
            "recGB3CrxjKj4RAPp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1195,
        "2023": 529,
        "id": "recVBII2RLzeiM3nn",
        "row": 16947,
        "state": "",
        "group": "",
        "group_num": [
            "recRalvL5CyucKLAY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recVjCi3xqbapxywQ",
        "row": 16957,
        "state": "",
        "group": "CB030X",
        "group_num": [
            "recBktXlHH1TG8jME"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 277,
        "id": "recW1HSwuCwGwCEQj",
        "row": 16970,
        "state": "",
        "group": "",
        "group_num": [
            "recGB3CrxjKj4RAPp"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "recWwsMEKcvTOjtJx",
        "row": 16980,
        "state": "",
        "group": "L01412M010",
        "group_num": [
            "recuKYM4mN0NdVkJv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1395,
        "2023": 1395,
        "id": "recWgLbPsFyqT2sjn",
        "row": 16946,
        "state": "",
        "group": "",
        "group_num": [
            "recRalvL5CyucKLAY"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recYsdPkbLUmuLMbw",
        "row": 16955,
        "state": "",
        "group": "",
        "group_num": [
            "recm6TZ5JUbm4mOCI"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recYFNzskEZ0tLUIo",
        "row": 16962,
        "state": "",
        "group": "",
        "group_num": [
            "rec0XLdfSxijVmIT3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recYeJ46kxqTWPbZo",
        "row": 16959,
        "state": "",
        "group": "",
        "group_num": [
            "recxjBbtBOXq7jQCL"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "rec1ltHIhfwaIzh2V",
        "row": 16965,
        "state": "",
        "group": "276945M002",
        "group_num": [
            "rec0102jUeiNjdHFr"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "id": "rec2SGKSYezmebxcQ",
        "row": 16942,
        "state": "",
        "group": "L01221M002",
        "group_num": [
            "rec6wWVAepIR9c1OM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 510,
        "id": "rec37wIdygFzx55Uh",
        "row": 16973,
        "state": "",
        "group": "174287M004",
        "group_num": [
            "rec8Sw5whI4nPhhou"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "id": "rec3gVltbOIDKauRo",
        "row": 16982,
        "state": "",
        "group": "L03882M002",
        "group_num": [
            "recvcT3WE9V5qJG2K"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "rec4n3XhAU7prfvQo",
        "row": 16964,
        "state": "",
        "group": "276945M002",
        "group_num": [
            "rec0102jUeiNjdHFr"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "rec7PP8fB2fvjKzr3",
        "row": 16941,
        "state": "",
        "group": "",
        "group_num": [
            "recwulw866VOqUdXI"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1398,
        "id": "rec7Q8PhPt8wMDEnC",
        "row": 16975,
        "state": "",
        "group": "174287M004",
        "group_num": [
            "rec8Sw5whI4nPhhou"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1740,
        "id": "rec794Xs8Ruc00Eu5",
        "row": 16968,
        "state": "",
        "group": "",
        "group_num": [
            "recuUpSL8uua3bTBK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2308,
        "2023": 2308,
        "id": "rec8pR3Y1mFQ1aPC3",
        "row": 16938,
        "state": "",
        "group": "174264M3A1",
        "group_num": [
            "recJqFnPgLeYcWN5K"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recaf9Jz9n6oa4H5z",
        "row": 16950,
        "state": "",
        "group": "",
        "group_num": [
            "recJ8RBn6LrbOFZSO"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 44,
        "2024": 44,
        "id": "recpPsYdlme71hcAI",
        "row": 16943,
        "state": "",
        "group": "",
        "group_num": [
            "recIWdUTyzhhShEIp"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2220,
        "id": "recqhBk41plRiaIRT",
        "row": 16969,
        "state": "",
        "group": "",
        "group_num": [
            "recuUpSL8uua3bTBK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "recsk99eCgkx8Yv46",
        "row": 16940,
        "state": "",
        "group": "",
        "group_num": [
            "recwulw866VOqUdXI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "recuz9rUScGfHQ28Q",
        "row": 16958,
        "state": "",
        "group": "CB030X",
        "group_num": [
            "recBktXlHH1TG8jME"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recwzLbVhQFOlOekx",
        "row": 16961,
        "state": "",
        "group": "",
        "group_num": [
            "recxjBbtBOXq7jQCL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 519,
        "id": "recxjRSwjRDolma1X",
        "row": 16935,
        "state": "",
        "group": "",
        "group_num": [
            "recq7ajJbksHMFStm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 375,
        "2024": 475,
        "id": "recy2luVpzTmHdZCM",
        "row": 16976,
        "state": "",
        "group": "",
        "group_num": [
            "recceb3FL1Muudc6m"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "reczYu1FklwSOtTRS",
        "row": 16963,
        "state": "",
        "group": "276945M002",
        "group_num": [
            "rec0102jUeiNjdHFr"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 350,
        "id": "reczwhiBfVbSBX9Nb",
        "row": 16944,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 508,
        "id": "recAHmeDrPymQJtuJ",
        "row": 16972,
        "state": "",
        "group": "174287M004",
        "group_num": [
            "rec8Sw5whI4nPhhou"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 102,
        "id": "recDixc5fcpL5EBfx",
        "row": 16952,
        "state": "",
        "group": "",
        "group_num": [
            "recJ8RBn6LrbOFZSO"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1295,
        "id": "recELrEJNyyvGvnsS",
        "row": 16979,
        "state": "",
        "group": "",
        "group_num": [
            "recdNV1Cj3AcpFNvx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "id": "recFJaDM0SbSI7k9k",
        "row": 16967,
        "state": "",
        "group": "276945M004",
        "group_num": [
            "rec4hkUQxE4q1Pby7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 162,
        "2024": 270,
        "id": "recFMn380FrfU02PD",
        "row": 16939,
        "state": "",
        "group": "",
        "group_num": [
            "rec9SVuPq9u2YqCgA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recFnlUrmOGqZvgvi",
        "row": 16949,
        "state": "",
        "group": "",
        "group_num": [
            "recFaK6yOWv8oSztA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recHtQ7OhG6esWkSt",
        "row": 16953,
        "state": "",
        "group": "",
        "group_num": [
            "recm6TZ5JUbm4mOCI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recI2Z3Yya4hPqsSD",
        "row": 16974,
        "state": "",
        "group": "174287M004",
        "group_num": [
            "rec8Sw5whI4nPhhou"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 845,
        "id": "recJmrKTLflTw2ZoU",
        "row": 16977,
        "state": "",
        "group": "170194M507",
        "group_num": [
            "recKmebfsV1Phd6eW"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recL01YzfDIvOqUIZ",
        "row": 16954,
        "state": "",
        "group": "",
        "group_num": [
            "recm6TZ5JUbm4mOCI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3277,
        "id": "recMH3u87EMTrWYbh",
        "row": 16937,
        "state": "",
        "group": "174264M3A1",
        "group_num": [
            "recJqFnPgLeYcWN5K"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "receEdE2nmfAu6zUG",
        "row": 16988,
        "state": "",
        "group": "",
        "group_num": [
            "reczCv1JMVC5XjWqv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 293,
        "id": "reciaH3bAkjyLYucu",
        "row": 17010,
        "state": "",
        "group": "",
        "group_num": [
            "recEAMS2J8AFY6K17"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recjuIOg3PT0XqCsJ",
        "row": 16989,
        "state": "",
        "group": "",
        "group_num": [
            "reczCv1JMVC5XjWqv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 498,
        "id": "reckF6oEre2xYjGhK",
        "row": 17002,
        "state": "",
        "group": "",
        "group_num": [
            "recxrS0Rv24zoimgz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 502,
        "id": "reckk1WyKM0fh2aLC",
        "row": 17019,
        "state": "",
        "group": "277727P591",
        "group_num": [
            "rec0C3kTKEF2IbXiw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recmzqvXJcjkNrL66",
        "row": 17027,
        "state": "",
        "group": "",
        "group_num": [
            "recjzzFJpXOm4vEKP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1135,
        "id": "recnE55Vgl8fkoy51",
        "row": 17033,
        "state": "",
        "group": "",
        "group_num": [
            "recXAr8DEltSBB6sA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1095,
        "id": "recO1ndinzIkmiDVe",
        "row": 17016,
        "state": "",
        "group": "C22681M2A2",
        "group_num": [
            "rec6qWbNU08QTNKc0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recOEwCXkwyXipaU9",
        "row": 17017,
        "state": "",
        "group": "",
        "group_num": [
            "recWz61Hko57CsGLb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 275,
        "id": "recO7tGkMu7BDStSv",
        "row": 17020,
        "state": "",
        "group": "",
        "group_num": [
            "recz0iKqr9PNothC3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 201,
        "id": "recOkvgA8FLg8pJ56",
        "row": 17001,
        "state": "",
        "group": "",
        "group_num": [
            "recxrS0Rv24zoimgz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "recP51XpUQa9N4ISS",
        "row": 16990,
        "state": "",
        "group": "",
        "group_num": [
            "reczCv1JMVC5XjWqv"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recTj9hAW8GWTqKex",
        "row": 17029,
        "state": "",
        "group": "",
        "group_num": [
            "rec6outt4Ksono85s"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 613,
        "id": "recUY4ATgmZEXV2O4",
        "row": 16998,
        "state": "",
        "group": "",
        "group_num": [
            "recpVM1i48EoVVcHD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recVbdsnAZrITz6fS",
        "row": 17030,
        "state": "",
        "group": "",
        "group_num": [
            "recXAr8DEltSBB6sA"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 590,
        "id": "recVf8XUv4pVJIehk",
        "row": 16996,
        "state": "",
        "group": "",
        "group_num": [
            "recMUN1MrxY3uzcsX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "recVj1LV62pMXLh9P",
        "row": 17032,
        "state": "",
        "group": "",
        "group_num": [
            "recXAr8DEltSBB6sA"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 315,
        "id": "recZD7L6k96EMHrAY",
        "row": 16999,
        "state": "",
        "group": "",
        "group_num": [
            "recpVM1i48EoVVcHD"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 17,
        "id": "rec0nWAwwRMsLID0F",
        "row": 17021,
        "state": "",
        "group": "",
        "group_num": [
            "recz0iKqr9PNothC3"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "rec1AuvN58nMcSwpd",
        "row": 17023,
        "state": "",
        "group": "",
        "group_num": [
            "recuAJ9uD0F3RckVj"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 633,
        "id": "rec3lWvm0UkJlsSAB",
        "row": 16991,
        "state": "",
        "group": "",
        "group_num": [
            "reczCv1JMVC5XjWqv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "rec483oiDrcNSCluf",
        "row": 17024,
        "state": "",
        "group": "",
        "group_num": [
            "recuAJ9uD0F3RckVj"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1533,
        "id": "rec5T8Nj3rI3ziWOl",
        "row": 17009,
        "state": "",
        "group": "",
        "group_num": [
            "recEAMS2J8AFY6K17"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "rec5z7wV8kPIHoKsK",
        "row": 17014,
        "state": "",
        "group": "C22681M2A2",
        "group_num": [
            "rec6qWbNU08QTNKc0"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "rec5CiOJXjCEA95Xa",
        "row": 17013,
        "state": "",
        "group": "C22681M1A2",
        "group_num": [
            "recgNs0XUw0WhAYVN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "id": "rec6dmCP9tqb2LUHF",
        "row": 16995,
        "state": "",
        "group": "",
        "group_num": [
            "rectdePgKCk3E3Ym7"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "rec6fXkEG0lLN4TTh",
        "row": 17022,
        "state": "",
        "group": "",
        "group_num": [
            "recuAJ9uD0F3RckVj"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rec6rbYFc38Y9BO1p",
        "row": 16993,
        "state": "",
        "group": "",
        "group_num": [
            "recqcDdPKfIEjH4a5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1320,
        "id": "rec8vV3CDsswrQ0s9",
        "row": 17000,
        "state": "",
        "group": "",
        "group_num": [
            "recpVM1i48EoVVcHD"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1449,
        "id": "rec8OjHynnrEYkpft",
        "row": 17015,
        "state": "",
        "group": "C22681M2A2",
        "group_num": [
            "rec6qWbNU08QTNKc0"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1633,
        "2023": 1925,
        "id": "rec9vMr1JNNwCAuSM",
        "row": 16984,
        "state": "",
        "group": "174134M1A1",
        "group_num": [
            "reciKRt5ErLoLYa4D"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1472,
        "id": "reccFaVNrFtLYtYTR",
        "row": 17025,
        "state": "",
        "group": "",
        "group_num": [
            "recF7MEy44eW7UvHD"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2659,
        "2024": 1254,
        "id": "recdwct2Q6prPpiYb",
        "row": 17011,
        "state": "",
        "group": "",
        "group_num": [
            "recEAMS2J8AFY6K17"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "id": "recdrkgWyTxMGaIpi",
        "row": 17005,
        "state": "",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 542,
        "id": "recpH0SC2iI0cjDLe",
        "row": 17003,
        "state": "",
        "group": "",
        "group_num": [
            "recunAd89dF5pjCuf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1700,
        "id": "recrVATitws2785m0",
        "row": 16992,
        "state": "",
        "group": "",
        "group_num": [
            "recqcDdPKfIEjH4a5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2188,
        "id": "rectTOck5XDeq6Bq5",
        "row": 16985,
        "state": "",
        "group": "",
        "group_num": [
            "receWCaLk5IOOMsT8"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "2023": 1200,
        "id": "recusZktnZCE4sIha",
        "row": 16986,
        "state": "",
        "group": "",
        "group_num": [
            "recVuczxtPX6gaZKZ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 44,
        "2024": 23,
        "id": "recyZCRGt1cL6JS4M",
        "row": 17018,
        "state": "",
        "group": "",
        "group_num": [
            "recWz61Hko57CsGLb"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "recyzmmzH3XmVnCDy",
        "row": 17026,
        "state": "",
        "group": "",
        "group_num": [
            "recjzzFJpXOm4vEKP"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 141,
        "id": "reczG1cPuyuBqeKai",
        "row": 17006,
        "state": "",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 86,
        "id": "recBa2M7kmJfB2gnJ",
        "row": 17004,
        "state": "",
        "group": "",
        "group_num": [
            "recunAd89dF5pjCuf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recBkH1P3BxWrpIYL",
        "row": 16987,
        "state": "",
        "group": "",
        "group_num": [
            "recVuczxtPX6gaZKZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 890,
        "id": "recCKpU8yUjFxrvZj",
        "row": 16997,
        "state": "",
        "group": "",
        "group_num": [
            "recpVM1i48EoVVcHD"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2076,
        "id": "recFQnUqgnlb2ogf6",
        "row": 17007,
        "state": "",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recGTFZpet9kFQdVI",
        "row": 16994,
        "state": "",
        "group": "282505M003",
        "group_num": [
            "reckSXTwSPysXmVK4"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2815,
        "2023": 1836,
        "id": "recG68bI8TNcNFgPT",
        "row": 17008,
        "state": "",
        "group": "174056MAA1",
        "group_num": [
            "recK0qMxPqIKFD8Yb"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 869,
        "id": "recLLNjUrFX1OxUhe",
        "row": 17028,
        "state": "",
        "group": "",
        "group_num": [
            "recDGlexrr9mzejEB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 701,
        "id": "recMNx02sHjuJmDGp",
        "row": 17031,
        "state": "",
        "group": "",
        "group_num": [
            "recXAr8DEltSBB6sA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2156,
        "id": "recNCMZTls9hwQaBN",
        "row": 17012,
        "state": "",
        "group": "",
        "group_num": [
            "recEAMS2J8AFY6K17"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "receSKSrdLHwPH3XL",
        "row": 17052,
        "state": "",
        "group": "",
        "group_num": [
            "recBSglRvqxkez4PP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "receb1IRExAPEobJr",
        "row": 17065,
        "state": "",
        "group": "1875HE",
        "group_num": [
            "rec8pqzcJu2siqsPu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 248,
        "id": "recekOftOjZksCpIq",
        "row": 17069,
        "state": "",
        "group": "",
        "group_num": [
            "recOvojeYWCxRb0o7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1364,
        "2023": 1500,
        "id": "recen3wkxkrWrBHya",
        "row": 17073,
        "state": "",
        "group": "L01072M026",
        "group_num": [
            "rec3hkf2mgtmyB7Ks"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recfFVKip13xZS1jG",
        "row": 17046,
        "state": "",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 396,
        "id": "reckaukNGKGJuOReC",
        "row": 17081,
        "state": "",
        "group": "",
        "group_num": [
            "recxEbSARUWJbNb4n"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3231,
        "id": "reclg54189Z7LEi0u",
        "row": 17071,
        "state": "",
        "group": "284932M007",
        "group_num": [
            "recEJbFvISlGKv69e"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "recOwmJnEFnPrxH38",
        "row": 17034,
        "state": "",
        "group": "",
        "group_num": [
            "reciGFdNyAk44Y8eX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 361,
        "id": "recOxMpm4lgj318RK",
        "row": 17040,
        "state": "",
        "group": "",
        "group_num": [
            "recFdQ9TgMsiAq6qj"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1294,
        "id": "recPATWSDniJFaSnj",
        "row": 17078,
        "state": "",
        "group": "",
        "group_num": [
            "rec8cNmO5ZA4seiX5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1598,
        "id": "recPP1oKAP81wpAgW",
        "row": 17066,
        "state": "",
        "group": "174084M1A1",
        "group_num": [
            "recBkt5xMrE8M6DGs"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recRLvcGuWQV42RGl",
        "row": 17060,
        "state": "",
        "group": "",
        "group_num": [
            "recUUBl182HJoelB7"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1002,
        "id": "recTvArm7CpAD8E38",
        "row": 17049,
        "state": "",
        "group": "",
        "group_num": [
            "recNeemdyPSRyFYT2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recTKrrKRMpaAA6ZA",
        "row": 17055,
        "state": "",
        "group": "",
        "group_num": [
            "reccd1sF7Piwqc3Rd"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 333,
        "id": "recXLmhmLTbXk7AQd",
        "row": 17036,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Vkv0fq2OkyyLE"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1258,
        "id": "recZiIo9cx4uAc61q",
        "row": 17083,
        "state": "",
        "group": "",
        "group_num": [
            "recxEbSARUWJbNb4n"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "rec1jNBt6tGb4pERP",
        "row": 17056,
        "state": "",
        "group": "",
        "group_num": [
            "reccd1sF7Piwqc3Rd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 379,
        "id": "rec4aRuiAJUDqnuRr",
        "row": 17041,
        "state": "",
        "group": "",
        "group_num": [
            "recoVdz5KHXNXrU65"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec4nt9O4A1P7sZSy",
        "row": 17075,
        "state": "",
        "group": "L01072M026",
        "group_num": [
            "rec3hkf2mgtmyB7Ks"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec57H1hlcZ4AEopS",
        "row": 17057,
        "state": "",
        "group": "",
        "group_num": [
            "reccd1sF7Piwqc3Rd"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rec63hll0QMUTZArk",
        "row": 17070,
        "state": "",
        "group": "284932M007",
        "group_num": [
            "recEJbFvISlGKv69e"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 670,
        "id": "rec8SkPM807gyjpuA",
        "row": 17043,
        "state": "",
        "group": "",
        "group_num": [
            "recUXOQrxf3gfKLRR"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "recb8I8di7jo3hQfp",
        "row": 17039,
        "state": "",
        "group": "307951",
        "group_num": [
            "recYzkSbx77Gf0MMM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "2023": 717,
        "id": "reccaq5O5GdRA4viX",
        "row": 17068,
        "state": "",
        "group": "",
        "group_num": [
            "recOvojeYWCxRb0o7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "recoEPrAv3McyMOnM",
        "row": 17047,
        "state": "",
        "group": "",
        "group_num": [
            "recZfwVUB0p5Wh0io"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 129,
        "id": "reco93OkHbHIwAXbI",
        "row": 17042,
        "state": "",
        "group": "",
        "group_num": [
            "recUXOQrxf3gfKLRR"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 499,
        "id": "recpQMBDX438AcTpl",
        "row": 17063,
        "state": "",
        "group": "",
        "group_num": [
            "rec2c2EYEIwiJJZ1O"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recqLL1zl3zdBTcpR",
        "row": 17064,
        "state": "",
        "group": "1875HE",
        "group_num": [
            "rec8pqzcJu2siqsPu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "id": "recqMXShbor7fKQiO",
        "row": 17044,
        "state": "",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recq286KMd62jxoxa",
        "row": 17076,
        "state": "",
        "group": "",
        "group_num": [
            "recPGDHnPmDSB75he"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "id": "recqhqDrwo6rdZgwG",
        "row": 17037,
        "state": "",
        "group": "",
        "group_num": [
            "recodgonO2I0q4ZtR"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "rect2aaVhJbmRFX81",
        "row": 17061,
        "state": "",
        "group": "",
        "group_num": [
            "rechAuVfo0PvFWQzc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 581,
        "id": "recuOX1r5mzOOxvcI",
        "row": 17077,
        "state": "",
        "group": "",
        "group_num": [
            "recPGDHnPmDSB75he"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "id": "recun7VAJ4lFm3pOk",
        "row": 17062,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Vkv0fq2OkyyLE"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recvQyJBWFDkNTmbm",
        "row": 17067,
        "state": "",
        "group": "",
        "group_num": [
            "rec9nuKzw20wlnT56"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recwFUwqHdOMDkHZp",
        "row": 17050,
        "state": "",
        "group": "",
        "group_num": [
            "recNeemdyPSRyFYT2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 91,
        "id": "recxCjTYVImFnaTs6",
        "row": 17053,
        "state": "",
        "group": "A53206",
        "group_num": [
            "recWcAOQfexnPRM5y"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recyVcf3a7mkQI77B",
        "row": 17054,
        "state": "",
        "group": "",
        "group_num": [
            "recejoWFSkURwAzEy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recycgkJ3euAMoJLk",
        "row": 17038,
        "state": "",
        "group": "",
        "group_num": [
            "recT0sEOU9rCm3XSX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1458,
        "id": "reczxoUMIOleqrEdO",
        "row": 17079,
        "state": "",
        "group": "",
        "group_num": [
            "rec8cNmO5ZA4seiX5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recz2kNSWPqLY9Z0h",
        "row": 17058,
        "state": "",
        "group": "J34360",
        "group_num": [
            "reciy4T3RTV29CjNR"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recC1DdzRX7EWZm5u",
        "row": 17059,
        "state": "",
        "group": "J34360",
        "group_num": [
            "reciy4T3RTV29CjNR"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 981,
        "id": "recDVC3IzLRREUaaL",
        "row": 17072,
        "state": "",
        "group": "284932M007",
        "group_num": [
            "recEJbFvISlGKv69e"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recDQT2FCOfT4tzZF",
        "row": 17074,
        "state": "",
        "group": "L01072M026",
        "group_num": [
            "rec3hkf2mgtmyB7Ks"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 41,
        "2024": 41,
        "id": "recGRPEBNRgBoCJz6",
        "row": 17082,
        "state": "",
        "group": "",
        "group_num": [
            "recxEbSARUWJbNb4n"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3500,
        "id": "recG2hyrG12Z4owSS",
        "row": 17051,
        "state": "",
        "group": "",
        "group_num": [
            "recNeemdyPSRyFYT2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 456,
        "id": "recI1e2uNPf3B8vXg",
        "row": 17048,
        "state": "",
        "group": "",
        "group_num": [
            "recZfwVUB0p5Wh0io"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 386,
        "id": "recJuQLXKDZpjkkQV",
        "row": 17035,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Vkv0fq2OkyyLE"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recL1PQ6KUrh68CIh",
        "row": 17045,
        "state": "",
        "group": "464987",
        "group_num": [
            "recRUpXXxKIVqPAga"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 562,
        "id": "recN6Lws03IrMZtjz",
        "row": 17080,
        "state": "",
        "group": "170100MD31",
        "group_num": [
            "reckAxXnheAb4OhQ1"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 293,
        "id": "recfR1J9Y588VBSqO",
        "row": 17093,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Bla3t6Fb5KMVB"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recjVL5HepufKQ5e2",
        "row": 17131,
        "state": "",
        "group": "",
        "group_num": [
            "recH7zq6cF1gSknLD"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 56,
        "id": "recjOtuiGryxFL8j8",
        "row": 17086,
        "state": "",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1516,
        "id": "recjOTDjpVXkpCGYs",
        "row": 17088,
        "state": "",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "reckBi8ZVgpeCdK2Y",
        "row": 17132,
        "state": "",
        "group": "",
        "group_num": [
            "recze5yQXQmWc9jzj"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "reclZMRzt8q3Uub5z",
        "row": 17101,
        "state": "",
        "group": "C22715A2A4",
        "group_num": [
            "recAf4VKeSFCxRDQf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 676,
        "id": "recQcbfeQ2Untuf77",
        "row": 17116,
        "state": "",
        "group": "",
        "group_num": [
            "recyQs84B4mkh7Zh3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 597,
        "id": "recRDXoXD7WButJt1",
        "row": 17085,
        "state": "",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "recSm1JXpHLGqi7NT",
        "row": 17127,
        "state": "",
        "group": "",
        "group_num": [
            "recE61JJUMQUIHSub"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 55,
        "2024": 1088,
        "id": "recUUFRgxIEqXUpK1",
        "row": 17117,
        "state": "",
        "group": "",
        "group_num": [
            "recyQs84B4mkh7Zh3"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1600,
        "id": "recU37Ed34ghqnYCo",
        "row": 17091,
        "state": "",
        "group": "",
        "group_num": [
            "recaoZTtN4ubeQ9f3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1019,
        "id": "recV3b9ZYPNJHeYSS",
        "row": 17122,
        "state": "",
        "group": "",
        "group_num": [
            "recaYyFHrnJjWkkvu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "recWNbqtDMG306DlD",
        "row": 17113,
        "state": "",
        "group": "",
        "group_num": [
            "reczUnplp6U3drKL7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2764,
        "2024": 2400,
        "id": "recX0br6DNiwO649U",
        "row": 17125,
        "state": "",
        "group": "",
        "group_num": [
            "reccUTCImTDRpatl6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1229,
        "id": "recXyCJo1dUH1QGam",
        "row": 17098,
        "state": "",
        "group": "",
        "group_num": [
            "recppjKnVJ7infakP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 270,
        "id": "recYIJ6avTDUDmL8e",
        "row": 17100,
        "state": "",
        "group": "",
        "group_num": [
            "recQoA4TQKhbRyXID"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1885,
        "id": "recZGYxkKSDyi1zaz",
        "row": 17106,
        "state": "",
        "group": "174205M5A2",
        "group_num": [
            "recLw1fwrwMcbqdp1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 660,
        "id": "recZRq98K2ow5Ohx0",
        "row": 17129,
        "state": "",
        "group": "",
        "group_num": [
            "recH7zq6cF1gSknLD"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 520,
        "id": "rec1uZLtHsrUx5LTU",
        "row": 17103,
        "state": "",
        "group": "",
        "group_num": [
            "rech1tTmiSdkZ6Pmg"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1851,
        "id": "rec4SIsYvZxU8EXAd",
        "row": 17107,
        "state": "",
        "group": "174205M5A2",
        "group_num": [
            "recLw1fwrwMcbqdp1"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1694,
        "id": "rec4Z0Oh3YrtfLkMC",
        "row": 17095,
        "state": "",
        "group": "174022MJAD",
        "group_num": [
            "reclhMuZz1OXc0vum"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 272,
        "id": "rec48KY66E5qGVByN",
        "row": 17128,
        "state": "",
        "group": "40828A",
        "group_num": [
            "recgFS92JDd0luxMM"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 894,
        "2024": 2350,
        "id": "rec4gikmqWNVrvq4a",
        "row": 17099,
        "state": "",
        "group": "",
        "group_num": [
            "rec1rQSEQ2Fs8jKaE"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 540,
        "id": "rec6XMo90dJYwlP24",
        "row": 17130,
        "state": "",
        "group": "",
        "group_num": [
            "recH7zq6cF1gSknLD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "rec6IQRlrmTHoHQ4s",
        "row": 17102,
        "state": "",
        "group": "C22715A2A4",
        "group_num": [
            "recAf4VKeSFCxRDQf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 597,
        "id": "rec6KbQZoNg2EcvJk",
        "row": 17084,
        "state": "",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 702,
        "id": "rec8NMgF48Ila07by",
        "row": 17112,
        "state": "",
        "group": "",
        "group_num": [
            "reczUnplp6U3drKL7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 236,
        "id": "rec9JjzWyy8mfLtMh",
        "row": 17096,
        "state": "",
        "group": "174022MJAF",
        "group_num": [
            "recrQX1yp8CqRbDTb"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recaXfLVb0Et42tlq",
        "row": 17105,
        "state": "",
        "group": "C22715A1A3",
        "group_num": [
            "recruehVned6iIPXi"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1865,
        "id": "recpTWtKoWzUsuIO6",
        "row": 17123,
        "state": "",
        "group": "",
        "group_num": [
            "recYewl4cI6tBKnzY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1375,
        "id": "recsI0TjayIjGmBMf",
        "row": 17087,
        "state": "",
        "group": "282474M001",
        "group_num": [
            "reckn5vWFLppsxL8h"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1850,
        "id": "rectX29davFatLpoY",
        "row": 17090,
        "state": "",
        "group": "",
        "group_num": [
            "recVVEUpG7oB6qwg4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 688,
        "2023": 447,
        "id": "rectaaJA4f1S2aMO9",
        "row": 17092,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Bla3t6Fb5KMVB"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rectmQZ6TrQfgDnzT",
        "row": 17108,
        "state": "",
        "group": "",
        "group_num": [
            "recGn6T0pLLU6Ya1m"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 552,
        "id": "recu02N5SEEHisi6h",
        "row": 17094,
        "state": "",
        "group": "174022MJAD",
        "group_num": [
            "reclhMuZz1OXc0vum"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recuQkzJkIt7dgRKU",
        "row": 17115,
        "state": "",
        "group": "",
        "group_num": [
            "rec2mcH7j7asUGYGm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recx1WDoKDF0lL77P",
        "row": 17089,
        "state": "",
        "group": "",
        "group_num": [
            "recVVEUpG7oB6qwg4"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 158,
        "id": "recxDrnDicMA5P419",
        "row": 17121,
        "state": "",
        "group": "",
        "group_num": [
            "recaYyFHrnJjWkkvu"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1908,
        "id": "recySFyKSehm1ruzT",
        "row": 17133,
        "state": "",
        "group": "",
        "group_num": [
            "rectCXtTLP8puQ0ji"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recz3jmFtE68hMKwD",
        "row": 17111,
        "state": "",
        "group": "",
        "group_num": [
            "recUDYWa8NDPy8rAe"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recBNF9lw0fJiEedi",
        "row": 17120,
        "state": "",
        "group": "",
        "group_num": [
            "recaYyFHrnJjWkkvu"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 714,
        "id": "recCAQD3B1RJq0ouI",
        "row": 17104,
        "state": "",
        "group": "",
        "group_num": [
            "rech1tTmiSdkZ6Pmg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2283,
        "2023": 1923,
        "id": "recDo2diquf0AFZLN",
        "row": 17118,
        "state": "",
        "group": "L03984M006",
        "group_num": [
            "recOYtZ8ON6K1LW1A"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recG5gEC2kcBbdC9Q",
        "row": 17126,
        "state": "",
        "group": "40521B",
        "group_num": [
            "recqryEawi3TY5TmV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2523,
        "id": "recGk1J5WjGyF0Zj0",
        "row": 17124,
        "state": "",
        "group": "",
        "group_num": [
            "reccUTCImTDRpatl6"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 581,
        "2023": 840,
        "id": "recH8ihLctquVIihJ",
        "row": 17114,
        "state": "",
        "group": "174226M5A2",
        "group_num": [
            "recipnfrtWD11oW7S"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recHbKbKPsRvhnFDB",
        "row": 17109,
        "state": "",
        "group": "174205M6A6",
        "group_num": [
            "recIOgKOoPj6TMdbx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "2023": 1904,
        "id": "recJlDhChIqVYrrTa",
        "row": 17097,
        "state": "",
        "group": "174022MJAF",
        "group_num": [
            "recrQX1yp8CqRbDTb"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recKbhhIdB2gvCRuo",
        "row": 17119,
        "state": "",
        "group": "",
        "group_num": [
            "recaYyFHrnJjWkkvu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recNvnYdP8SFvfqov",
        "row": 17110,
        "state": "",
        "group": "",
        "group_num": [
            "recUDYWa8NDPy8rAe"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 284,
        "id": "rece6jbQdaiQargJK",
        "row": 17166,
        "state": "",
        "group": "",
        "group_num": [
            "recqwgl3CjAHzixHy"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "rece6YvBlHLo159dU",
        "row": 17167,
        "state": "",
        "group": "",
        "group_num": [
            "recKslPlMbHa1qmkm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "recgIylmE3ecboAQ7",
        "row": 17136,
        "state": "",
        "group": "277727J659",
        "group_num": [
            "rec8FXjzBsliwH5nw"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 270,
        "id": "recgKPrs938ak6Zn3",
        "row": 17146,
        "state": "",
        "group": "",
        "group_num": [
            "recTnKy0pl0ovurmn"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3500,
        "id": "recgQjzrMiF9z90Uy",
        "row": 17148,
        "state": "",
        "group": "",
        "group_num": [
            "recmkeKyYr9lVwIj5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 50,
        "2024": 50,
        "id": "rechLIClNgHGMo3Yt",
        "row": 17158,
        "state": "",
        "group": "",
        "group_num": [
            "recyuo22VNyjVWFRm"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1938,
        "id": "rechM4W6fHKqtEYnn",
        "row": 17155,
        "state": "",
        "group": "C20879M6A1",
        "group_num": [
            "recJoqBZeLp1W9Ddm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "reciqNPiqpbcKDwOQ",
        "row": 17162,
        "state": "",
        "group": "",
        "group_num": [
            "recTTwyKt25AA6kfn"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 428,
        "2023": 428,
        "id": "reck5kcnyE9tb1cc3",
        "row": 17172,
        "state": "",
        "group": "",
        "group_num": [
            "recP3YHagEpTbjnPa"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "recnukclBTkX5SulK",
        "row": 17143,
        "state": "",
        "group": "",
        "group_num": [
            "recBL60m3KrLV95cv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 548,
        "id": "recOA273zxPBW7FKZ",
        "row": 17154,
        "state": "",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 666,
        "id": "recPWpQCBZ0kjQxRA",
        "row": 17156,
        "state": "",
        "group": "",
        "group_num": [
            "recyuo22VNyjVWFRm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 286,
        "2024": 256,
        "id": "recPffWig5Yq4TcqJ",
        "row": 17183,
        "state": "",
        "group": "",
        "group_num": [
            "recD4eViVaiIdbmkv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 270,
        "id": "recQgwzXmnaicyNsY",
        "row": 17164,
        "state": "",
        "group": "",
        "group_num": [
            "recTTwyKt25AA6kfn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "id": "recRIwmSf2DKc10ml",
        "row": 17160,
        "state": "",
        "group": "282543M002",
        "group_num": [
            "recpQ3FLbnCpOxiKZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "id": "recT7jrLk0OiholcP",
        "row": 17161,
        "state": "",
        "group": "282543M002",
        "group_num": [
            "recpQ3FLbnCpOxiKZ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 666,
        "id": "recUgxoCTICoxGbv9",
        "row": 17157,
        "state": "",
        "group": "",
        "group_num": [
            "recyuo22VNyjVWFRm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 437,
        "id": "recXSLmoDugE7TC1N",
        "row": 17168,
        "state": "",
        "group": "",
        "group_num": [
            "recKslPlMbHa1qmkm"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recXsTgpvr4uSUI3C",
        "row": 17144,
        "state": "",
        "group": "",
        "group_num": [
            "recEghMjuApSPHcdw"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "rec0LIx4cMM1rIdH1",
        "row": 17141,
        "state": "",
        "group": "277727M716",
        "group_num": [
            "recn4BqXa7Ql8jaGz"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rec2oFbOInLO0Rkxv",
        "row": 17145,
        "state": "",
        "group": "",
        "group_num": [
            "recTnKy0pl0ovurmn"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "rec4mMUg71EXFIhxE",
        "row": 17142,
        "state": "",
        "group": "277727M716",
        "group_num": [
            "recn4BqXa7Ql8jaGz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1597,
        "id": "rec56hPYesIoTUftZ",
        "row": 17180,
        "state": "",
        "group": "",
        "group_num": [
            "rec4D1Qv5VoAyuRHE"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1067,
        "id": "rec609oBssDtbTnFp",
        "row": 17165,
        "state": "",
        "group": "",
        "group_num": [
            "recB7BoxmFXVvzwHQ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1749,
        "id": "rec682p8DFp3OLego",
        "row": 17159,
        "state": "",
        "group": "",
        "group_num": [
            "recyuo22VNyjVWFRm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 710,
        "id": "rec7TXFijVvU3ssQm",
        "row": 17182,
        "state": "",
        "group": "",
        "group_num": [
            "recD4eViVaiIdbmkv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2456,
        "id": "rec88Cls23Yr3YTCF",
        "row": 17150,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 270,
        "id": "recahZPZvJk6GT7tZ",
        "row": 17173,
        "state": "",
        "group": "",
        "group_num": [
            "rec2ip66qUoQof5MV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 418,
        "id": "recamKRdOQBEJOrWP",
        "row": 17137,
        "state": "",
        "group": "277727M353",
        "group_num": [
            "recMtF8tF8elmHbGF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "recdlTv0RKcS32GB8",
        "row": 17181,
        "state": "",
        "group": "",
        "group_num": [
            "rec4D1Qv5VoAyuRHE"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recppSM4XRGv53Mhh",
        "row": 17169,
        "state": "",
        "group": "",
        "group_num": [
            "recKslPlMbHa1qmkm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 336,
        "id": "recrHWKcxi9dBHRW1",
        "row": 17178,
        "state": "",
        "group": "277242M002",
        "group_num": [
            "recBgoZiNUwJw2UFT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recrcc3ahTfxZ0P6T",
        "row": 17170,
        "state": "",
        "group": "280509S206",
        "group_num": [
            "recHB3EXjrPbTzptg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1996,
        "id": "recuEYUDKzKRZggYH",
        "row": 17149,
        "state": "",
        "group": "",
        "group_num": [
            "rec4z0LUjfumORdTC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recxrbxvSyJN5ykSG",
        "row": 17151,
        "state": "",
        "group": "",
        "group_num": [
            "rec432hr97r1tG4ld"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recy1ALCjvlhRd83i",
        "row": 17139,
        "state": "",
        "group": "",
        "group_num": [
            "recUAXXw14R7oX4DZ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recBFMP2ueyU2KUOi",
        "row": 17171,
        "state": "",
        "group": "280509S206",
        "group_num": [
            "recHB3EXjrPbTzptg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 640,
        "id": "recDILTFwHMjSTBd9",
        "row": 17135,
        "state": "",
        "group": "",
        "group_num": [
            "reciI1Tfz9MsYSe5N"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2757,
        "id": "recD8z22qy0xYxLLz",
        "row": 17138,
        "state": "",
        "group": "277727M357",
        "group_num": [
            "recqmhnHvrCoXwjSg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 515,
        "2023": 515,
        "id": "recEyKbKq3stJVEw1",
        "row": 17176,
        "state": "",
        "group": "277242M001",
        "group_num": [
            "rec6TfDzOALe4Q06G"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1689,
        "id": "recE8i1McC6lNwE2M",
        "row": 17153,
        "state": "",
        "group": "",
        "group_num": [
            "rec432hr97r1tG4ld"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recFN5ZmmujPpasYH",
        "row": 17174,
        "state": "",
        "group": "",
        "group_num": [
            "rec0I0J7zr5b7pGR3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 698,
        "id": "recFPVZLdEMygt5A1",
        "row": 17177,
        "state": "",
        "group": "277242M002",
        "group_num": [
            "recBgoZiNUwJw2UFT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recIymIZ7Pw9TpZZB",
        "row": 17134,
        "state": "",
        "group": "",
        "group_num": [
            "rectCXtTLP8puQ0ji"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "id": "recIfWdtLM6Be2Zo8",
        "row": 17175,
        "state": "",
        "group": "277242M001",
        "group_num": [
            "rec6TfDzOALe4Q06G"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recIkrLGDMd2MUaoU",
        "row": 17163,
        "state": "",
        "group": "",
        "group_num": [
            "recTTwyKt25AA6kfn"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 495,
        "id": "recL5hGHTqEbDWjYo",
        "row": 17147,
        "state": "",
        "group": "174277M3AT",
        "group_num": [
            "recU4uh49kq22UBHM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1578,
        "id": "recMp5d5ZiUpvTxz2",
        "row": 17152,
        "state": "",
        "group": "",
        "group_num": [
            "rec432hr97r1tG4ld"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 140,
        "id": "recNCGOOF1FRWugBq",
        "row": 17179,
        "state": "",
        "group": "276748M600",
        "group_num": [
            "recSSsft62YC3beF3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recNh2l353CpeLlw4",
        "row": 17140,
        "state": "",
        "group": "",
        "group_num": [
            "recUAXXw14R7oX4DZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recfDZjJlRo1pSB0p",
        "row": 17224,
        "state": "",
        "group": "L03775M011",
        "group_num": [
            "recbxk086jOv9rpH4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1395,
        "id": "reckZEXqc4fKNCxvL",
        "row": 17196,
        "state": "",
        "group": "",
        "group_num": [
            "recqZuItFwwZ3cRxX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1111,
        "2024": 1200,
        "id": "reckdYOtfnXAvFL4E",
        "row": 17199,
        "state": "",
        "group": "",
        "group_num": [
            "rec0SSsV4bxKY5XYs"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "reclZSk4jKbLw0n3r",
        "row": 17200,
        "state": "",
        "group": "",
        "group_num": [
            "rec0SSsV4bxKY5XYs"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recm8Bg0kYjqyU1i7",
        "row": 17209,
        "state": "",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recSXUYnzpJ1OQyIV",
        "row": 17204,
        "state": "",
        "group": "",
        "group_num": [
            "recpa3ViTXtHM4f7r"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 511,
        "id": "recSCUB0k9bUgFtSn",
        "row": 17215,
        "state": "",
        "group": "",
        "group_num": [
            "recOyzfNywmkqhMLx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "2023": 2042,
        "id": "recT7QCxJt5c7u4eE",
        "row": 17201,
        "state": "",
        "group": "",
        "group_num": [
            "rec0SSsV4bxKY5XYs"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recXkg37cb8zBbchp",
        "row": 17206,
        "state": "",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "id": "recYlBKu0dQPKuR3d",
        "row": 17214,
        "state": "",
        "group": "",
        "group_num": [
            "recqVa644xPfLFQnI"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 244,
        "id": "recZmrgjiUlIInJWW",
        "row": 17203,
        "state": "",
        "group": "",
        "group_num": [
            "recdJRQcvXOhvTZy9"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1666,
        "id": "rec1vilvmFEpwkQ1a",
        "row": 17227,
        "state": "",
        "group": "174258M1AF",
        "group_num": [
            "reckRTHGT36w2oAVW"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "rec2WAnsQjpOgNBGk",
        "row": 17210,
        "state": "",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 311,
        "id": "rec2PugAC5REG15SH",
        "row": 17211,
        "state": "",
        "group": "",
        "group_num": [
            "recwiuIEk17uLejeO"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1100,
        "id": "rec4XEmlapaiY1pZg",
        "row": 17228,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bEA51NvL46b1j"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 146,
        "id": "rec4Lti3xVKCIoogJ",
        "row": 17223,
        "state": "",
        "group": "L03775M011",
        "group_num": [
            "recbxk086jOv9rpH4"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1609,
        "id": "rec5A5DoENVNs3QzC",
        "row": 17193,
        "state": "",
        "group": "",
        "group_num": [
            "recE2Acd8oY0T5g3W"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 548,
        "id": "rec6pj37DsEdGarK3",
        "row": 17213,
        "state": "",
        "group": "",
        "group_num": [
            "recqVa644xPfLFQnI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "id": "rec7xh47QPXOglN9D",
        "row": 17190,
        "state": "",
        "group": "1855RD",
        "group_num": [
            "recz8oRWtBsrOOHm4"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 552,
        "id": "rec73YV5Lx6oQfIqE",
        "row": 17226,
        "state": "",
        "group": "174258M1AF",
        "group_num": [
            "reckRTHGT36w2oAVW"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 378,
        "id": "rec9Is0cviRf25fQs",
        "row": 17195,
        "state": "",
        "group": "",
        "group_num": [
            "recP3YHagEpTbjnPa"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 146,
        "id": "rec9htnonTZsK6dfX",
        "row": 17207,
        "state": "",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "id": "recaMFDZsVs4mCVgF",
        "row": 17218,
        "state": "",
        "group": "282229M001",
        "group_num": [
            "recYRNfRcMKKK9Zig"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recdOFsUtI6JKDvYP",
        "row": 17217,
        "state": "",
        "group": "282229M001",
        "group_num": [
            "recYRNfRcMKKK9Zig"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recdj1LYZc1PFEcAH",
        "row": 17202,
        "state": "",
        "group": "",
        "group_num": [
            "recdJRQcvXOhvTZy9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 540,
        "id": "recoSLFidgRO3wikg",
        "row": 17185,
        "state": "",
        "group": "",
        "group_num": [
            "recR4CcnxCL33YtGL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recovCbyIXRR5tyQF",
        "row": 17205,
        "state": "",
        "group": "",
        "group_num": [
            "recpa3ViTXtHM4f7r"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recqjgkF8f31DyF69",
        "row": 17221,
        "state": "",
        "group": "L03775M001",
        "group_num": [
            "recb8Jeqpp6DPnBis"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 36,
        "id": "recrl6FC6Jr2WuLHR",
        "row": 17184,
        "state": "",
        "group": "",
        "group_num": [
            "recFJ2WTXtk695Ueq"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "id": "recsUceJxoOW33H7Q",
        "row": 17187,
        "state": "",
        "group": "788800",
        "group_num": [
            "recuCT5ps3qQV3f4y"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recsKwKFiSpBUlZqI",
        "row": 17194,
        "state": "",
        "group": "",
        "group_num": [
            "recP3YHagEpTbjnPa"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2093,
        "id": "recweFQr3AYZB4pci",
        "row": 17229,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bEA51NvL46b1j"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recxT3xP9GVkTRWZg",
        "row": 17222,
        "state": "",
        "group": "L03775M011",
        "group_num": [
            "recbxk086jOv9rpH4"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1376,
        "id": "reczyZzjL96655a0U",
        "row": 17192,
        "state": "",
        "group": "",
        "group_num": [
            "recE2Acd8oY0T5g3W"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1000,
        "id": "recCXhzm9jKDHDo9h",
        "row": 17225,
        "state": "",
        "group": "",
        "group_num": [
            "rec2v7SgSHYSXJU91"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recCcOgwjLBfG032I",
        "row": 17198,
        "state": "",
        "group": "",
        "group_num": [
            "rec70jZshxeDnq33W"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1425,
        "id": "recESooQcVSSFapQ6",
        "row": 17197,
        "state": "",
        "group": "",
        "group_num": [
            "rec70jZshxeDnq33W"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "recEvEy5Z10Q4qzSU",
        "row": 17189,
        "state": "",
        "group": "",
        "group_num": [
            "recRPQ88Cbl5Nd2NQ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "recGNg0j57EQ0XDyN",
        "row": 17186,
        "state": "",
        "group": "788800",
        "group_num": [
            "recuCT5ps3qQV3f4y"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 530,
        "id": "recHpgKmkeuwMUKGA",
        "row": 17219,
        "state": "",
        "group": "282745M004",
        "group_num": [
            "rec6Q9niZ93jSWSEC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recHrth2fIsmmppI8",
        "row": 17191,
        "state": "",
        "group": "1855RD",
        "group_num": [
            "recz8oRWtBsrOOHm4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recJ7oZI1arWp1UQc",
        "row": 17208,
        "state": "",
        "group": "281594M013",
        "group_num": [
            "recAKH27PSuwL5tuC"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recLA1g4isgRMLY4G",
        "row": 17216,
        "state": "",
        "group": "",
        "group_num": [
            "recOyzfNywmkqhMLx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recL9FXRHD3ZGh9KR",
        "row": 17212,
        "state": "",
        "group": "",
        "group_num": [
            "recwiuIEk17uLejeO"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 588,
        "id": "recMLF5QXHs2wtYTx",
        "row": 17220,
        "state": "",
        "group": "",
        "group_num": [
            "rechUJS5piLW3f8Fz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1750,
        "id": "recNUp6sljgwhYmAg",
        "row": 17188,
        "state": "",
        "group": "",
        "group_num": [
            "recClp62giM5lSOyG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 558,
        "id": "rec0gKk9oB4lmgCJt",
        "row": 16934,
        "state": "recAgFw6riLDk8wCC",
        "group": "",
        "group_num": [
            "recq7ajJbksHMFStm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BC OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 329,
        "id": "recRIoy3FNhsw47zJ",
        "row": 17232,
        "state": "",
        "group": "197163G6AI",
        "group_num": [
            "reca3VjqTwBGHNf0O"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 959,
        "2024": 1200,
        "id": "rec27KsDG9BzMcErr",
        "row": 17231,
        "state": "",
        "group": "197163G6AI",
        "group_num": [
            "reca3VjqTwBGHNf0O"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "recFNqhvl53dvg5eT",
        "row": 17233,
        "state": "",
        "group": "197163G6AI",
        "group_num": [
            "reca3VjqTwBGHNf0O"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2024": 414,
        "id": "recM4uc6D8jAhJq7i",
        "row": 17230,
        "state": "",
        "group": "65JB00",
        "group_num": [
            "recM4HNUQdFvVUTIn"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recebV7H9dWsI76Pj",
        "row": 17265,
        "state": "",
        "group": "5L9W00",
        "group_num": [
            "rec3HH1D11qcQ505z"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recgnCLBjtTqGO3kw",
        "row": 17250,
        "state": "",
        "group": "",
        "group_num": [
            "recxt7Bx6odZkcW0S"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "rechMDaCq0mXl7nDe",
        "row": 17269,
        "state": "",
        "group": "",
        "group_num": [
            "recysFEOBSp45UUZ9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1022,
        "id": "rech4AnqRNZIeIl2F",
        "row": 17252,
        "state": "",
        "group": "",
        "group_num": [
            "recxt7Bx6odZkcW0S"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4260,
        "id": "recjmhHPl5CXa7ihf",
        "row": 17276,
        "state": "",
        "group": "174295M2A6",
        "group_num": [
            "recDbJ0hRw5WHmIBM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 44,
        "id": "reckcsmbN4zkjm4lb",
        "row": 17263,
        "state": "",
        "group": "",
        "group_num": [
            "reckFRMbH8jDH05bV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1812,
        "id": "reclYu30mAeo4FaHv",
        "row": 17275,
        "state": "",
        "group": "174295M2A6",
        "group_num": [
            "recDbJ0hRw5WHmIBM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 891,
        "id": "recOUiUONNLfmRnod",
        "row": 17248,
        "state": "",
        "group": "L04339M067",
        "group_num": [
            "recthAUrQlV8O99wc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "2024": 116,
        "id": "recQBesndiVhZ3t32",
        "row": 17244,
        "state": "",
        "group": "",
        "group_num": [
            "recAiHbS1lCvdtBEu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "id": "recR3hrHkf1LmpqO2",
        "row": 17255,
        "state": "",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "2024": 2743,
        "id": "recT7iepvG4ewljIN",
        "row": 17271,
        "state": "",
        "group": "",
        "group_num": [
            "recysFEOBSp45UUZ9"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 646,
        "id": "recTacbH2frOztOCK",
        "row": 17258,
        "state": "",
        "group": "",
        "group_num": [
            "recOXWNVyb2CDVSSM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 615,
        "2023": 688,
        "id": "recUS2nd1mrG11oPo",
        "row": 17247,
        "state": "",
        "group": "",
        "group_num": [
            "recwE30ATxiRLg1pk"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recW3eucKTXW109ks",
        "row": 17268,
        "state": "",
        "group": "",
        "group_num": [
            "recysFEOBSp45UUZ9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recX7JBZaB1qpMCr3",
        "row": 17266,
        "state": "",
        "group": "",
        "group_num": [
            "recXiQBLUTBnMPQTr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "2023": 773,
        "id": "recYtUXGVLv2pq9oG",
        "row": 17278,
        "state": "",
        "group": "195610D011",
        "group_num": [
            "recvkguZ1YBJCyldV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "id": "recZJbMsrlNCFlgCg",
        "row": 17249,
        "state": "",
        "group": "L04339M067",
        "group_num": [
            "recthAUrQlV8O99wc"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rec2AozIWBJUSm4Ze",
        "row": 17262,
        "state": "",
        "group": "",
        "group_num": [
            "reckFRMbH8jDH05bV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 870,
        "id": "rec3N1Jrsw3ZRiLs4",
        "row": 17259,
        "state": "",
        "group": "",
        "group_num": [
            "reci92BETWIMHre4s"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 723,
        "id": "rec3eQnTAywXpoClm",
        "row": 17251,
        "state": "",
        "group": "",
        "group_num": [
            "recxt7Bx6odZkcW0S"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "rec4rLttuK4vI4Ebn",
        "row": 17234,
        "state": "",
        "group": "197163G6AI",
        "group_num": [
            "reca3VjqTwBGHNf0O"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rec5aBn0RYAWM5vUN",
        "row": 17260,
        "state": "",
        "group": "",
        "group_num": [
            "recqKgD5SvfDlAq6P"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 257,
        "2023": 88,
        "id": "rec6B6Hjf630Xsx1B",
        "row": 17236,
        "state": "",
        "group": "L00003M101",
        "group_num": [
            "recMz42jkTYexuQnK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "rec7in2ya30vPv0IT",
        "row": 17277,
        "state": "",
        "group": "L00943M002",
        "group_num": [
            "recnyDBMIGsYfDDM7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1305,
        "id": "rec8CNXgeyFxmrXJC",
        "row": 17243,
        "state": "",
        "group": "197248X081",
        "group_num": [
            "recXx5C0AeMXjnotb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recb88n9uAfR430PA",
        "row": 17261,
        "state": "",
        "group": "E015580005",
        "group_num": [
            "recIxvGvIEq7ZRAVf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 769,
        "2023": 3459,
        "id": "recc3X9cJUQqVOidG",
        "row": 17245,
        "state": "",
        "group": "JNT015MB01",
        "group_num": [
            "reclqY3F8HLzwJHRF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 32,
        "id": "recppu1TTJWrN28Rm",
        "row": 17256,
        "state": "",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recrwn669npNMy8Uh",
        "row": 17272,
        "state": "",
        "group": "",
        "group_num": [
            "reczyLPFv6u6h0PlT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 847,
        "id": "recsxp9dOMB36pgtH",
        "row": 17235,
        "state": "",
        "group": "",
        "group_num": [
            "recBA4ntS5cPmUhqu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "rectq6Y7rWXE6uUQ1",
        "row": 17253,
        "state": "",
        "group": "",
        "group_num": [
            "recZkoimJy3AzSw5Y"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2939,
        "2023": 3000,
        "id": "recvx88RWeqPO9LRk",
        "row": 17242,
        "state": "",
        "group": "197248D084",
        "group_num": [
            "recyca8qu1fKBE0Jo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 205,
        "id": "recvRMdwzEgvOlmh7",
        "row": 17273,
        "state": "",
        "group": "",
        "group_num": [
            "reczyLPFv6u6h0PlT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 375,
        "id": "recwiRsbkTdt8c3rQ",
        "row": 17280,
        "state": "",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 147,
        "id": "recxuCMC0fH70i2R0",
        "row": 17283,
        "state": "",
        "group": "F28434",
        "group_num": [
            "recFilFakR9zAaglV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 209,
        "id": "recyVBhUPSciUFiJO",
        "row": 17264,
        "state": "",
        "group": "",
        "group_num": [
            "recaw3IY6Mhgvpsk6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "id": "recyBtnOpn52BFdCa",
        "row": 17237,
        "state": "",
        "group": "L00003M101",
        "group_num": [
            "recMz42jkTYexuQnK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recydmdf28yEfkOlt",
        "row": 17241,
        "state": "",
        "group": "197248D084",
        "group_num": [
            "recyca8qu1fKBE0Jo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 694,
        "2023": 702,
        "id": "reczAiKefIUvDitmx",
        "row": 17274,
        "state": "",
        "group": "174295M2L2",
        "group_num": [
            "recRfnm0WDi12fv29"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 414,
        "id": "recBGPD2EcbS3eDsC",
        "row": 17267,
        "state": "",
        "group": "",
        "group_num": [
            "rec0nevCqmQ6iPHZo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 407,
        "id": "recFEXfRASK8JSYFg",
        "row": 17240,
        "state": "",
        "group": "",
        "group_num": [
            "recCpErIZtnB2mQ7w"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2909,
        "2023": 1384,
        "id": "recFjPZ3lq2Smdqzt",
        "row": 17246,
        "state": "",
        "group": "JNT015MB01",
        "group_num": [
            "reclqY3F8HLzwJHRF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "id": "recGOb6MGW31fx7H3",
        "row": 17281,
        "state": "",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recG317Ie9aC75KGw",
        "row": 17254,
        "state": "",
        "group": "196586MAS3",
        "group_num": [
            "recoatir9FcEqcHo6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1633,
        "id": "recIXVXw9qkC3cOxu",
        "row": 17282,
        "state": "",
        "group": "174058MCAN",
        "group_num": [
            "recJ3iAVjo63v9ntl"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "2024": 1900,
        "id": "recI4kFZxswVxM6Hy",
        "row": 17270,
        "state": "",
        "group": "",
        "group_num": [
            "recysFEOBSp45UUZ9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 54,
        "id": "recJi0qCYFZ0JptHW",
        "row": 17279,
        "state": "",
        "group": "195610D011",
        "group_num": [
            "recvkguZ1YBJCyldV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 265,
        "id": "recKQGFalN5HSk358",
        "row": 17239,
        "state": "",
        "group": "L00003MP03",
        "group_num": [
            "recCPwjvNu1rjbQRr"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 997,
        "id": "recMGrfouLGf4ka8B",
        "row": 17238,
        "state": "",
        "group": "L00003MM01",
        "group_num": [
            "rec77YXtvk8zHEUHX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 701,
        "id": "recNKMmWyfaiQuOe5",
        "row": 17257,
        "state": "",
        "group": "",
        "group_num": [
            "recOXWNVyb2CDVSSM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3333,
        "2023": 6000,
        "id": "receQHUDnPkR6ZGji",
        "row": 17325,
        "state": "",
        "group": "281845M006",
        "group_num": [
            "recQiCXPdFDco7ADu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 390,
        "id": "rechaGPlndnofRCjR",
        "row": 17287,
        "state": "",
        "group": "5JQY00",
        "group_num": [
            "recjIUmhKIY1HmczO"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 277,
        "id": "rechaXRCCzmOIpQdv",
        "row": 17316,
        "state": "",
        "group": "",
        "group_num": [
            "rec4cCHiWsSXgltJU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1729,
        "id": "recioYzRLQUY7zRwO",
        "row": 17318,
        "state": "",
        "group": "",
        "group_num": [
            "recchMFNBE5J9Z21k"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 34,
        "id": "recmYzEvLWp4gtqPx",
        "row": 17314,
        "state": "",
        "group": "H36538",
        "group_num": [
            "recAymJ5cdfdTyRT7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 827,
        "id": "recmi9yC1cBBXdXRD",
        "row": 17307,
        "state": "",
        "group": "G62968",
        "group_num": [
            "recm8i2wt8QBTSd4n"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recnKTT7DxG5yKlrm",
        "row": 17308,
        "state": "",
        "group": "G62968",
        "group_num": [
            "recm8i2wt8QBTSd4n"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recOa2QddsS1qgC6a",
        "row": 17304,
        "state": "",
        "group": "",
        "group_num": [
            "recRCaOEB1ZyYUHz5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recQILxoF17TnD5Q3",
        "row": 17327,
        "state": "",
        "group": "",
        "group_num": [
            "recFACJ3sz7G4oUGb"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1650,
        "id": "recSinWwClbOpftwy",
        "row": 17323,
        "state": "",
        "group": "281845M006",
        "group_num": [
            "recQiCXPdFDco7ADu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recSlKGpOJyhPYvUr",
        "row": 17291,
        "state": "",
        "group": "65KP00",
        "group_num": [
            "rectDJ1WbDaREAK6V"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recTdV85uAtCIYY1j",
        "row": 17331,
        "state": "",
        "group": "L04077M001",
        "group_num": [
            "rec8eI49FltikV8aZ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recTluOtRgp3ZcBWe",
        "row": 17294,
        "state": "",
        "group": "",
        "group_num": [
            "rec6P24ARkpKtL9AF"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 1674,
        "id": "recVBGUoWQ8KvTGnn",
        "row": 17320,
        "state": "",
        "group": "4CQ500",
        "group_num": [
            "recaozkT88aJw1D2E"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recVkJyergbqtYCuv",
        "row": 17312,
        "state": "",
        "group": "",
        "group_num": [
            "recqsKgNdTQlYU1Fo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 548,
        "id": "recWpr5cygtbmyS5L",
        "row": 17289,
        "state": "",
        "group": "5JR500",
        "group_num": [
            "recdqYl8pmaH5TFae"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 296,
        "id": "recY2aEb9z26DVTWm",
        "row": 17285,
        "state": "",
        "group": "",
        "group_num": [
            "recPgYGr2pLX37OBg"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 755,
        "id": "recZNvyxLliQNLEhP",
        "row": 17317,
        "state": "",
        "group": "",
        "group_num": [
            "recg4X07MOl1LRqbz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 883,
        "id": "rec0sAMbe86t4UgEp",
        "row": 17299,
        "state": "",
        "group": "498433",
        "group_num": [
            "recdnsN6c3LiLzn2s"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 103,
        "id": "rec1zF7DjnqNyvaGY",
        "row": 17286,
        "state": "",
        "group": "V64578MC01",
        "group_num": [
            "recaD9O0JjtfpGmb9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rec2j2LACxq8wpC7V",
        "row": 17288,
        "state": "",
        "group": "5JQY00",
        "group_num": [
            "recjIUmhKIY1HmczO"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "rec5YQ2pmqjccYsnR",
        "row": 17303,
        "state": "",
        "group": "",
        "group_num": [
            "recRCaOEB1ZyYUHz5"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2209,
        "id": "rec5nGLgHiAiTrXga",
        "row": 17332,
        "state": "",
        "group": "L04077M001",
        "group_num": [
            "rec8eI49FltikV8aZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 970,
        "id": "rec6Ax3qAp46tJTMQ",
        "row": 17321,
        "state": "",
        "group": "4CQ500",
        "group_num": [
            "recaozkT88aJw1D2E"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 396,
        "id": "rec641yIVnQAfKmV0",
        "row": 17328,
        "state": "",
        "group": "",
        "group_num": [
            "recFACJ3sz7G4oUGb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "rec7mjFlL8A7tby5U",
        "row": 17297,
        "state": "",
        "group": "453865",
        "group_num": [
            "recPrdwd8MD0B0Zsj"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 686,
        "id": "rec94K1uSY91mIAdW",
        "row": 17298,
        "state": "",
        "group": "498433",
        "group_num": [
            "recdnsN6c3LiLzn2s"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "reca68UVI37qL45D3",
        "row": 17300,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "reconWEBU8rtC1iof",
        "row": 17296,
        "state": "",
        "group": "",
        "group_num": [
            "recvdPKyn8ZQiKeX9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 814,
        "id": "recp0E6K9fbWK4XFA",
        "row": 17292,
        "state": "",
        "group": "65KS00",
        "group_num": [
            "recDFnlcmd1gbGx3I"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recru0LxSrBWLwjfM",
        "row": 17302,
        "state": "",
        "group": "",
        "group_num": [
            "recRCaOEB1ZyYUHz5"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recrBCC02MLkWecxG",
        "row": 17315,
        "state": "",
        "group": "H50106",
        "group_num": [
            "recGIXJERC9qrNhJX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "2023": 835,
        "2024": 851,
        "id": "recu5FmNyeEGxOV52",
        "row": 17284,
        "state": "",
        "group": "V64578M001",
        "group_num": [
            "recWJ0yqF8SmVnl6E"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2613,
        "id": "recwYI0QsHBZp2tAA",
        "row": 17324,
        "state": "",
        "group": "281845M006",
        "group_num": [
            "recQiCXPdFDco7ADu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recxLnFdzbHCQHE5B",
        "row": 17290,
        "state": "",
        "group": "65KP00",
        "group_num": [
            "rectDJ1WbDaREAK6V"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 561,
        "2023": 244,
        "id": "recy5X4jzSMkyPL3V",
        "row": 17293,
        "state": "",
        "group": "65KS00",
        "group_num": [
            "recDFnlcmd1gbGx3I"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "id": "reczqGWvAkSx2Y97u",
        "row": 17313,
        "state": "",
        "group": "H36538",
        "group_num": [
            "recAymJ5cdfdTyRT7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recBZTM3ri87yBy3x",
        "row": 17330,
        "state": "",
        "group": "L04077M001",
        "group_num": [
            "rec8eI49FltikV8aZ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "recBsToWsDgKN0YdH",
        "row": 17309,
        "state": "",
        "group": "",
        "group_num": [
            "recqsKgNdTQlYU1Fo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 34,
        "id": "recB7uA6A4WCtGtCI",
        "row": 17301,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 941,
        "id": "recCL9FzDZNkOt8Lf",
        "row": 17333,
        "state": "",
        "group": "196102M019",
        "group_num": [
            "recm10T7UocqkZmPP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 827,
        "id": "recCQVLsk64o32nfn",
        "row": 17305,
        "state": "",
        "group": "A40973",
        "group_num": [
            "recIGGnGXMHlniseV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1421,
        "2024": 1452,
        "id": "recDUbhzdbqmALWON",
        "row": 17329,
        "state": "",
        "group": "",
        "group_num": [
            "recKJ9iRDJJudzoNH"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 955,
        "id": "recDp5ggGcbjBF7xN",
        "row": 17326,
        "state": "",
        "group": "",
        "group_num": [
            "receYe9XkmQI6W1BV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 829,
        "id": "recEhEoKRTb9Ka0Ji",
        "row": 17319,
        "state": "",
        "group": "",
        "group_num": [
            "recQMY73pXgmmPtpv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "2023": 34,
        "id": "recITpmoSZSDfnTRO",
        "row": 17306,
        "state": "",
        "group": "A40973",
        "group_num": [
            "recIGGnGXMHlniseV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1643,
        "id": "recICB0ULp9yGrnoV",
        "row": 17322,
        "state": "",
        "group": "6RVZ00",
        "group_num": [
            "recKjw772xPybIqLR"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 966,
        "id": "recKIa3bi3NqgWz6G",
        "row": 17311,
        "state": "",
        "group": "",
        "group_num": [
            "recqsKgNdTQlYU1Fo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recKgaYiNqGk38hqL",
        "row": 17310,
        "state": "",
        "group": "",
        "group_num": [
            "recqsKgNdTQlYU1Fo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recLK8cda6X0upYJX",
        "row": 17295,
        "state": "",
        "group": "",
        "group_num": [
            "recF69VKDosTk8re4"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recf6ghJluPigl2Ln",
        "row": 17346,
        "state": "",
        "group": "195575M130",
        "group_num": [
            "reco8LFeyAd2iQ69q"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1528,
        "id": "recfdmPptL73wNwp5",
        "row": 17339,
        "state": "",
        "group": "L00994H001",
        "group_num": [
            "recyjjwJ8JgDfSwKk"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 916,
        "id": "reci37GcBxyiYYtM3",
        "row": 17345,
        "state": "",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "reclxDZuHSuiOhuhO",
        "row": 17365,
        "state": "",
        "group": "",
        "group_num": [
            "recrn8VKoIUZIqR2A"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recn15CupcW3qYaA7",
        "row": 17336,
        "state": "",
        "group": "",
        "group_num": [
            "rec79MtYvU84xNDvB"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 414,
        "2024": 414,
        "id": "recPY4UmvlxkE0DX0",
        "row": 17370,
        "state": "",
        "group": "195610X011",
        "group_num": [
            "recqfIlC0F8vaNtGX"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 814,
        "id": "recPQzGVTIxUqGLjD",
        "row": 17335,
        "state": "",
        "group": "5L9K00",
        "group_num": [
            "rectgHp6ER7yA6Ual"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recQU5PdOLuhWDfpD",
        "row": 17357,
        "state": "",
        "group": "G232994002",
        "group_num": [
            "recdollVuOnDwmaav"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recQziiEBMIGFHrpF",
        "row": 17359,
        "state": "",
        "group": "L00296M002",
        "group_num": [
            "rec7tYKxFnL4Ajc8K"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 868,
        "id": "recQ9ozkQfnqTWdXi",
        "row": 17342,
        "state": "",
        "group": "",
        "group_num": [
            "rec5QPY83tSVWhfH2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "recRwDNkiUxugSiTi",
        "row": 17347,
        "state": "",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recR3Xtcs21L7QERo",
        "row": 17364,
        "state": "",
        "group": "",
        "group_num": [
            "recrn8VKoIUZIqR2A"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recT7cO9t9aoZ0inZ",
        "row": 17343,
        "state": "",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recUInVP4nbB8VMbA",
        "row": 17353,
        "state": "",
        "group": "C23218H046",
        "group_num": [
            "recxlgTpcToXsedYF"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recVMoPf3f9d3H4Mk",
        "row": 17363,
        "state": "",
        "group": "",
        "group_num": [
            "recPG4cShU6X0ngD6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1538,
        "2023": 265,
        "id": "recVjb15gPZo3PL55",
        "row": 17354,
        "state": "",
        "group": "C066360G10",
        "group_num": [
            "recmOjeIzXeGwHx2Y"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 735,
        "id": "recWRMdkiSdk8vhNb",
        "row": 17351,
        "state": "",
        "group": "",
        "group_num": [
            "rec769kYZkSLXRURV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "recZUNYYkcMA5Natp",
        "row": 17362,
        "state": "",
        "group": "L05594M001",
        "group_num": [
            "recYeOExFfqIqKBYz"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recZMYXufubEsNk6S",
        "row": 17371,
        "state": "",
        "group": "195610X011",
        "group_num": [
            "recqfIlC0F8vaNtGX"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "2023": 687,
        "id": "rec0RYC9sDQj5g1Ns",
        "row": 17349,
        "state": "",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "id": "rec0n6tdSwDbMsq4a",
        "row": 17338,
        "state": "",
        "group": "65HK00",
        "group_num": [
            "recjvkIaGZv5gJ9O5"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 528,
        "2023": 414,
        "id": "rec1klk9iy33z4hNt",
        "row": 17348,
        "state": "",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 374,
        "id": "rec57saiK1JLHVknw",
        "row": 17352,
        "state": "",
        "group": "",
        "group_num": [
            "rec769kYZkSLXRURV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 276,
        "id": "rec5dXq0yJJrhpJ2r",
        "row": 17361,
        "state": "",
        "group": "L05594M001",
        "group_num": [
            "recYeOExFfqIqKBYz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "rec6UwF9M8FBKRaXF",
        "row": 17341,
        "state": "",
        "group": "DY3700",
        "group_num": [
            "reczOZOA0xA9Jbgna"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recb0toAtHQ7Plx33",
        "row": 17360,
        "state": "",
        "group": "L03887M002",
        "group_num": [
            "recg2ZCXDyTasGHtY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 1022,
        "id": "recbbZumYCbnOJRxy",
        "row": 17350,
        "state": "",
        "group": "197250M002",
        "group_num": [
            "rec1f34oiQtbQYkj3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "2023": 38,
        "2024": 38,
        "id": "recpEZq14sV5I2bwr",
        "row": 17369,
        "state": "",
        "group": "C083710010",
        "group_num": [
            "recEblEsiqILFYZYJ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recp2d40xRgkoIVn9",
        "row": 17344,
        "state": "",
        "group": "195232M006",
        "group_num": [
            "recWDpSNkDTCp6LNn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 805,
        "2023": 875,
        "id": "recrrc6O2urTUFDLA",
        "row": 17356,
        "state": "",
        "group": "C232543001",
        "group_num": [
            "recVxyLf0O92oijU0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 38,
        "id": "rectXwLkb5dpxm7AN",
        "row": 17337,
        "state": "",
        "group": "",
        "group_num": [
            "rec79MtYvU84xNDvB"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 528,
        "id": "rect4urg6Iwv5d8Ih",
        "row": 17367,
        "state": "",
        "group": "C23218H010",
        "group_num": [
            "recUUW7oEwhh6qNQy"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recyMnGnaW7PVrJE7",
        "row": 17358,
        "state": "",
        "group": "L00296M002",
        "group_num": [
            "rec7tYKxFnL4Ajc8K"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recAdfmzRtfVxfKNQ",
        "row": 17340,
        "state": "",
        "group": "DY3700",
        "group_num": [
            "reczOZOA0xA9Jbgna"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1108,
        "2023": 814,
        "id": "recCA33StIWQCc6WM",
        "row": 17368,
        "state": "",
        "group": "C083710010",
        "group_num": [
            "recEblEsiqILFYZYJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 41,
        "id": "recMklI759sqpplfm",
        "row": 17355,
        "state": "",
        "group": "C066360G10",
        "group_num": [
            "recmOjeIzXeGwHx2Y"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2340,
        "id": "recNuJhw3w7SqtjXw",
        "row": 17334,
        "state": "",
        "group": "5L9K00",
        "group_num": [
            "rectgHp6ER7yA6Ual"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 868,
        "id": "recN6MUsShIsb2nAW",
        "row": 17366,
        "state": "",
        "group": "",
        "group_num": [
            "recbQLmqqPpbwdxiM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2476,
        "2024": 2400,
        "id": "reckfKroAjExFipRP",
        "row": 17377,
        "state": "",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1850,
        "id": "recSWYXekxyqDSDm4",
        "row": 17382,
        "state": "",
        "group": "001162E482",
        "group_num": [
            "recAq10gJUed0cE3A"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recSMcBIO2PqnnFmX",
        "row": 17378,
        "state": "",
        "group": "001097M200",
        "group_num": [
            "rec4dPMaDn8GSR4O9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1930,
        "id": "recVYgwbrIZnnzxN0",
        "row": 17380,
        "state": "",
        "group": "001097M392",
        "group_num": [
            "recv8Ycvkf3wvHDL5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1522,
        "id": "recV5gPVKXUk2LikP",
        "row": 17372,
        "state": "",
        "group": "270142M1AF",
        "group_num": [
            "recTYDhtui02DJpP6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 398,
        "id": "recWBmZJXmIEFgsOo",
        "row": 17374,
        "state": "",
        "group": "270142M1AF",
        "group_num": [
            "recTYDhtui02DJpP6"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 2200,
        "id": "rec0YxcIb6dcFiSNt",
        "row": 17383,
        "state": "",
        "group": "004099M128",
        "group_num": [
            "rec8OsXkAaikBDf1B"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec7vAMa9RLYELW13",
        "row": 17379,
        "state": "",
        "group": "001097M200",
        "group_num": [
            "rec4dPMaDn8GSR4O9"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1885,
        "2024": 1900,
        "id": "reccWezBFatuBgEDw",
        "row": 17376,
        "state": "",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "2024": 248,
        "id": "recxSgWv0O8ahHuY4",
        "row": 17375,
        "state": "",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "id": "recFkI6nq8ifHbxHE",
        "row": 17373,
        "state": "",
        "group": "270142M1AF",
        "group_num": [
            "recTYDhtui02DJpP6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recMQNRhfVBJdKsFc",
        "row": 17381,
        "state": "",
        "group": "001097M392",
        "group_num": [
            "recv8Ycvkf3wvHDL5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "receZyEmWMmkhHxyc",
        "row": 17421,
        "state": "",
        "group": "CS0015000H",
        "group_num": [
            "recVOOOWR7FSFm3D3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "receolBy06jPIlbXP",
        "row": 17399,
        "state": "",
        "group": "",
        "group_num": [
            "recZ91fpV2uq0k8x4"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recgO1moFVkcfUjef",
        "row": 17414,
        "state": "",
        "group": "270154M6FA",
        "group_num": [
            "recND3MrxI2ZGcsrB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1484,
        "2024": 1457,
        "id": "recgbVlxjAjkLc3qq",
        "row": 17432,
        "state": "",
        "group": "801266M118",
        "group_num": [
            "recsZgKGJJGxFxmMv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 449,
        "2023": 1712,
        "id": "reciSdJf54AWjKzWu",
        "row": 17422,
        "state": "",
        "group": "CS0016001H",
        "group_num": [
            "recLwhAYFiq3BA79v"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "id": "recjgHWsW9dhEkSpS",
        "row": 17415,
        "state": "",
        "group": "800312M004",
        "group_num": [
            "recRAvoKzjfBMCzdD"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 241,
        "id": "reckRang2dRd4vOSF",
        "row": 17390,
        "state": "",
        "group": "800426M002",
        "group_num": [
            "recqd9Ojbf1DvAdef"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "2023": 1290,
        "id": "reclYMHYK55bNnaml",
        "row": 17423,
        "state": "",
        "group": "CS0016001H",
        "group_num": [
            "recLwhAYFiq3BA79v"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recSWjGDxdpETpXg4",
        "row": 17424,
        "state": "",
        "group": "",
        "group_num": [
            "recnzbO50SwJbjZds"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1992,
        "2023": 1404,
        "id": "recSsRIHyuLDTtYHZ",
        "row": 17391,
        "state": "",
        "group": "800426M002",
        "group_num": [
            "recqd9Ojbf1DvAdef"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1983,
        "id": "recSuArnafT79AQch",
        "row": 17400,
        "state": "",
        "group": "",
        "group_num": [
            "rec9RigVH7AmESuGD"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1088,
        "2024": 1220,
        "id": "recUH1UkKv7OjqREz",
        "row": 17412,
        "state": "",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recVIRiHzNwTPl7UI",
        "row": 17413,
        "state": "",
        "group": "270154M6FA",
        "group_num": [
            "recND3MrxI2ZGcsrB"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recWOiLS08BtBwH0l",
        "row": 17388,
        "state": "",
        "group": "005965M018",
        "group_num": [
            "recVSDnDMzMV4gZvB"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "recWQ6XuHFjC3cuNz",
        "row": 17427,
        "state": "",
        "group": "CS0130001H",
        "group_num": [
            "reccJeWFHf6Qapb52"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "recXsycq7BNgHuopE",
        "row": 17410,
        "state": "",
        "group": "000EWQ83401AP002",
        "group_num": [
            "recRSYcYQQfw1v0o8"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recYAsym50LsbEQa6",
        "row": 17387,
        "state": "",
        "group": "005545M002",
        "group_num": [
            "recBNTljRIC1oEuKN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 332,
        "2023": 373,
        "id": "recZHBv8m95JE8Na9",
        "row": 17407,
        "state": "",
        "group": "",
        "group_num": [
            "recoikIls9TI3CfKw"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1574,
        "id": "rec0UAJh6WYGgvALW",
        "row": 17396,
        "state": "",
        "group": "801242M003",
        "group_num": [
            "reckdiRR1pnNy8eaA"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 490,
        "id": "rec2zKvGODvDBN9tz",
        "row": 17402,
        "state": "",
        "group": "CT2002M001",
        "group_num": [
            "recfPiL7R4mMPPPMm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 419,
        "id": "rec3uGFe5YWCzHxg4",
        "row": 17403,
        "state": "",
        "group": "",
        "group_num": [
            "recQdHQXZlc5WfPN8"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "rec4lLsxJAYdltilK",
        "row": 17420,
        "state": "",
        "group": "CS0009009H",
        "group_num": [
            "recqO4n1NSbKsLNQb"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2376,
        "id": "rec6z36VgPuNt1yOZ",
        "row": 17428,
        "state": "",
        "group": "L00367000H",
        "group_num": [
            "recmCLDKjRY6GDCwZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 598,
        "id": "rec6OtuOgeuzlYakP",
        "row": 17393,
        "state": "",
        "group": "800976M006",
        "group_num": [
            "rec2wvLnvIXr4kAoz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3785,
        "id": "rec89U166PDQajhup",
        "row": 17408,
        "state": "",
        "group": "",
        "group_num": [
            "recavIwE2HJmLRH0n"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "2024": 44,
        "id": "recbtz2iPMP4eLVH6",
        "row": 17431,
        "state": "",
        "group": "L03459M005",
        "group_num": [
            "recuApFYLlK6SJNwf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1016,
        "id": "recbveJPNabQXcOPl",
        "row": 17426,
        "state": "",
        "group": "CS0130001H",
        "group_num": [
            "reccJeWFHf6Qapb52"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recdU7XpHu3V27gfu",
        "row": 17429,
        "state": "",
        "group": "L00367000H",
        "group_num": [
            "recmCLDKjRY6GDCwZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1926,
        "id": "recooMi5emy3JuxkE",
        "row": 17392,
        "state": "",
        "group": "800426M002",
        "group_num": [
            "recqd9Ojbf1DvAdef"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 793,
        "id": "recsAY3B8LVLaUQQe",
        "row": 17419,
        "state": "",
        "group": "CS0009009H",
        "group_num": [
            "recqO4n1NSbKsLNQb"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1281,
        "2024": 1380,
        "id": "recs3tPum1CA2Mgt0",
        "row": 17411,
        "state": "",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4153,
        "id": "rectX2XKYESZLZLIl",
        "row": 17397,
        "state": "",
        "group": "801242M003",
        "group_num": [
            "reckdiRR1pnNy8eaA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1374,
        "id": "recuRQOYoQciPxlQg",
        "row": 17404,
        "state": "",
        "group": "270142M1AF",
        "group_num": [
            "recTYDhtui02DJpP6"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 265,
        "id": "recvCy8QtcsNuhTyD",
        "row": 17385,
        "state": "",
        "group": "005545M002",
        "group_num": [
            "recBNTljRIC1oEuKN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 474,
        "id": "recvIjLir2aLbw0ak",
        "row": 17406,
        "state": "",
        "group": "",
        "group_num": [
            "recoikIls9TI3CfKw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 723,
        "id": "recvJlzGihJ6zuvbD",
        "row": 17395,
        "state": "",
        "group": "801242M003",
        "group_num": [
            "reckdiRR1pnNy8eaA"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 1138,
        "id": "recvjBm84OcwMrZTB",
        "row": 17409,
        "state": "",
        "group": "000EWQ83401AP002",
        "group_num": [
            "recRSYcYQQfw1v0o8"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recwIpXdiY6ilsCG2",
        "row": 17386,
        "state": "",
        "group": "005545M002",
        "group_num": [
            "recBNTljRIC1oEuKN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recydJOaj1mqm76IW",
        "row": 17389,
        "state": "",
        "group": "005965M018",
        "group_num": [
            "recVSDnDMzMV4gZvB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recyewD80LkGGPDzA",
        "row": 17384,
        "state": "",
        "group": "004099M128",
        "group_num": [
            "rec8OsXkAaikBDf1B"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3800,
        "id": "reczihP65M4zMY4Zj",
        "row": 17425,
        "state": "",
        "group": "",
        "group_num": [
            "recjt6xv38Ktz0cpB"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recA10nYvcawwJrP6",
        "row": 17417,
        "state": "",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2154,
        "id": "recCQBkctD1MrKmsn",
        "row": 17418,
        "state": "",
        "group": "CS0009002S",
        "group_num": [
            "recpTr2YSXAnGAqh4"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recDCkdQ9Wex3XG46",
        "row": 17398,
        "state": "",
        "group": "",
        "group_num": [
            "recZ91fpV2uq0k8x4"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 458,
        "id": "recF8MM3hRi3J0tRe",
        "row": 17394,
        "state": "",
        "group": "800976M006",
        "group_num": [
            "rec2wvLnvIXr4kAoz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1562,
        "id": "recI1qvXjzHv3kGfG",
        "row": 17433,
        "state": "",
        "group": "801266M118",
        "group_num": [
            "recsZgKGJJGxFxmMv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2335,
        "id": "recLFH11q9qAMQ8Iv",
        "row": 17401,
        "state": "",
        "group": "",
        "group_num": [
            "rec9RigVH7AmESuGD"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 443,
        "id": "recNw9Eem6vj5JnMC",
        "row": 17430,
        "state": "",
        "group": "L03459M005",
        "group_num": [
            "recuApFYLlK6SJNwf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 737,
        "id": "recN2P3o1FZg9E6Sw",
        "row": 17416,
        "state": "",
        "group": "CS0009002H",
        "group_num": [
            "reccvDNmXdtqLqbpm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 695,
        "id": "recN7NcTjRfvxrr3k",
        "row": 17405,
        "state": "",
        "group": "",
        "group_num": [
            "recoikIls9TI3CfKw"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "receDYGr6HiCMQ1LU",
        "row": 17435,
        "state": "",
        "group": "000TSJ83403YP001",
        "group_num": [
            "recM0LqPlYHB0VVmT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "rececQIbx86ExyMzX",
        "row": 17455,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 529,
        "id": "reckz61e2VpNiwFRo",
        "row": 17444,
        "state": "",
        "group": "",
        "group_num": [
            "reczKvl5nN9EBjaBT"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "id": "reckIlzNfQNlS3mw1",
        "row": 17449,
        "state": "",
        "group": "6VMM00",
        "group_num": [
            "reccGWISOvYOLvpeX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recmwtEcekM7EnHzp",
        "row": 17478,
        "state": "",
        "group": "5L3A00",
        "group_num": [
            "recjOMYOjn5l1nE9k"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recnkxbeaBCaPtSd2",
        "row": 17446,
        "state": "",
        "group": "6VM500",
        "group_num": [
            "recxnSDRoqTuX8Qky"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2516,
        "id": "recnqEbMow4RkjoRr",
        "row": 17451,
        "state": "",
        "group": "6VMM00",
        "group_num": [
            "reccGWISOvYOLvpeX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recQnx0B4JKijl7j1",
        "row": 17473,
        "state": "",
        "group": "",
        "group_num": [
            "recRkUdm7H9QlU6ce"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recSwao7yUkTfVTxO",
        "row": 17462,
        "state": "",
        "group": "",
        "group_num": [
            "reckmvMc7lPZZKkHb"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recSFhrUAoUSsKsNS",
        "row": 17454,
        "state": "",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recUPVjorGSBK2tta",
        "row": 17453,
        "state": "",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recVANmPKVy0q3TRz",
        "row": 17436,
        "state": "",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 265,
        "id": "recWOAM3KwpWY13Ej",
        "row": 17472,
        "state": "",
        "group": "",
        "group_num": [
            "recqRGjurP11omEsC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recWOTjUcMklZdU5S",
        "row": 17468,
        "state": "",
        "group": "071889243H",
        "group_num": [
            "reculzcwQLYzmC8KX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "2023": 2138,
        "id": "recXsBG66ETLpxu1i",
        "row": 17441,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "id": "rec076RmMKuh4uRwx",
        "row": 17477,
        "state": "",
        "group": "66CV00",
        "group_num": [
            "recu7rOFwvWt8t17y"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec17Wbg69Sk53hpQ",
        "row": 17466,
        "state": "",
        "group": "071877058A",
        "group_num": [
            "recSyqUx1yTYAWFml"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1487,
        "id": "rec3HU3DFhZCWEWXX",
        "row": 17448,
        "state": "",
        "group": "6VMJ00",
        "group_num": [
            "recKnTmeNoqhwoZ82"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "id": "rec8VC9aihprUHnYl",
        "row": 17452,
        "state": "",
        "group": "66BN00",
        "group_num": [
            "recAJYJ9nTJEPYZYT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 375,
        "id": "rec9QS0Tlip4s7hI3",
        "row": 17437,
        "state": "",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1992,
        "id": "recblcDDkbvlzBwG0",
        "row": 17447,
        "state": "",
        "group": "6VMJ00",
        "group_num": [
            "recKnTmeNoqhwoZ82"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1740,
        "id": "reccSH2tJmpOTPCxO",
        "row": 17445,
        "state": "",
        "group": "",
        "group_num": [
            "recYwc7HoU8RxnsXF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 645,
        "id": "reccZotedEqCPOIJN",
        "row": 17467,
        "state": "",
        "group": "071889243H",
        "group_num": [
            "reculzcwQLYzmC8KX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recce93nnX9tMOxm2",
        "row": 17438,
        "state": "",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recocy5Jkx6XrHIRU",
        "row": 17442,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recpRyKRcYSBV6B7K",
        "row": 17461,
        "state": "",
        "group": "071851004H",
        "group_num": [
            "recb26YRnHcGRxtMm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recuSBXG6Ibk2s6ni",
        "row": 17471,
        "state": "",
        "group": "",
        "group_num": [
            "recdzmb9WtIvJ4Wmo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "id": "recv5MjpR2Eftco6v",
        "row": 17460,
        "state": "",
        "group": "071850001S",
        "group_num": [
            "rec2v9u4V4o9Iftw0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recw9glKEEVK2FpNl",
        "row": 17434,
        "state": "",
        "group": "000TSJ83403YP001",
        "group_num": [
            "recM0LqPlYHB0VVmT"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recyWQeqhGki8wcgV",
        "row": 17470,
        "state": "",
        "group": "",
        "group_num": [
            "recdzmb9WtIvJ4Wmo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recyY11RIFlNspRMN",
        "row": 17469,
        "state": "",
        "group": "A43968",
        "group_num": [
            "receVPJPFJq3QXT12"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 154,
        "id": "recBASctMNX3KHEf8",
        "row": 17474,
        "state": "",
        "group": "",
        "group_num": [
            "recRkUdm7H9QlU6ce"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recBPSUS7tMIFAaTq",
        "row": 17456,
        "state": "",
        "group": "071820004H",
        "group_num": [
            "recdbvncKsT36WH1u"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recEEFTRDslkvWM79",
        "row": 17439,
        "state": "",
        "group": "000TSJ83407YP001",
        "group_num": [
            "recL052ssHyyTxyZo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recE6nq1knLNYlM4O",
        "row": 17463,
        "state": "",
        "group": "071877058A",
        "group_num": [
            "recSyqUx1yTYAWFml"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 888,
        "id": "recE9fdsFXrPQDrP0",
        "row": 17443,
        "state": "",
        "group": "",
        "group_num": [
            "reczKvl5nN9EBjaBT"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2138,
        "id": "recEbS1PGZIiv7Flm",
        "row": 17476,
        "state": "",
        "group": "",
        "group_num": [
            "recRkUdm7H9QlU6ce"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recFPI9PJbs7NQqdi",
        "row": 17464,
        "state": "",
        "group": "071877058A",
        "group_num": [
            "recSyqUx1yTYAWFml"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recFdKKGkfBVwWXUE",
        "row": 17459,
        "state": "",
        "group": "071844003H",
        "group_num": [
            "recmKDXaDynmwUml8"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recGUP8800ik0VXhu",
        "row": 17440,
        "state": "",
        "group": "081846001H",
        "group_num": [
            "rectXCxwfSyyoZ4tL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1992,
        "id": "recGBJRmjhKgHoEq4",
        "row": 17450,
        "state": "",
        "group": "6VMM00",
        "group_num": [
            "reccGWISOvYOLvpeX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recJrf7cjbzN8z77X",
        "row": 17458,
        "state": "",
        "group": "071821000H",
        "group_num": [
            "recrpNyINDyYJ3Dz1"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recK2DB0dqWFosSTO",
        "row": 17465,
        "state": "",
        "group": "071877058A",
        "group_num": [
            "recSyqUx1yTYAWFml"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2147,
        "id": "recLIu0lDfKlL8R94",
        "row": 17475,
        "state": "",
        "group": "",
        "group_num": [
            "recRkUdm7H9QlU6ce"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1761,
        "id": "recM7IY9RWbZgIdqB",
        "row": 17457,
        "state": "",
        "group": "071821000H",
        "group_num": [
            "recrpNyINDyYJ3Dz1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF CT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rechHyms8TewwcD6s",
        "row": 17483,
        "state": "",
        "group": "",
        "group_num": [
            "recECq1mTVxMAwSlb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 956,
        "id": "recn9PWDwa5MhrKFw",
        "row": 17480,
        "state": "",
        "group": "",
        "group_num": [
            "recF2kLRc43qTNC7b"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2828,
        "id": "recY1dOl2VPOCdnpL",
        "row": 17482,
        "state": "",
        "group": "",
        "group_num": [
            "recF2kLRc43qTNC7b"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 253,
        "id": "recvKJi0hxMnT1htB",
        "row": 17479,
        "state": "",
        "group": "",
        "group_num": [
            "recUWxm6TKsLPZWSA"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2054,
        "id": "reczq3S3UoXdWGgzB",
        "row": 17481,
        "state": "",
        "group": "",
        "group_num": [
            "recF2kLRc43qTNC7b"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "rece1uot7JuXeMILp",
        "row": 17509,
        "state": "",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2495,
        "id": "recip4MDAqVSte53A",
        "row": 17499,
        "state": "",
        "group": "174549M211",
        "group_num": [
            "recIaU9MdfrATIP3T"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recl1oE8DxEi6rA0p",
        "row": 17504,
        "state": "",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 782,
        "id": "recleEhO5gkJbihvF",
        "row": 17488,
        "state": "",
        "group": "GB0192M003",
        "group_num": [
            "recJxsKQ9x9qIbHCn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 939,
        "2024": 1050,
        "id": "reclrLLE9Gp7CLGOC",
        "row": 17502,
        "state": "",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recPn9Jls2URIlLwC",
        "row": 17531,
        "state": "",
        "group": "ADPGA1M298",
        "group_num": [
            "recAIHGvfo7XIyduS"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 526,
        "id": "recQxZ12rSMbYOVmh",
        "row": 17526,
        "state": "",
        "group": "",
        "group_num": [
            "recAz3TFahnUXXz3C"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2366,
        "2023": 1254,
        "id": "recRI2vH7UPTssCdR",
        "row": 17512,
        "state": "",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recSP1QlLqESvgedm",
        "row": 17522,
        "state": "",
        "group": "",
        "group_num": [
            "recNpgLJBk3aVtoa2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recSfNj6F3hWH8Wm1",
        "row": 17484,
        "state": "",
        "group": "",
        "group_num": [
            "receuHkYwj8ITsMlc"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1661,
        "2024": 1500,
        "id": "recT1wABfipIPIyP0",
        "row": 17501,
        "state": "",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recUWqVECgTmX3mkX",
        "row": 17500,
        "state": "",
        "group": "174549M211",
        "group_num": [
            "recIaU9MdfrATIP3T"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1340,
        "id": "recVSGem3NdHqp2bd",
        "row": 17533,
        "state": "",
        "group": "GA7787M003",
        "group_num": [
            "recfGFFa6jleks3qA"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2828,
        "id": "recV6vBfnVX6wV9AV",
        "row": 17517,
        "state": "",
        "group": "",
        "group_num": [
            "rec6kuRYAfs2uW4GW"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recXVe9MHEeMAmXdr",
        "row": 17523,
        "state": "",
        "group": "",
        "group_num": [
            "recNpgLJBk3aVtoa2"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recY0sd1U6Azn68sH",
        "row": 17508,
        "state": "",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1112,
        "id": "recZTeLfvw5ENhQoU",
        "row": 17489,
        "state": "",
        "group": "GB0192M003",
        "group_num": [
            "recJxsKQ9x9qIbHCn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 251,
        "id": "recZVXOelIxd9obsx",
        "row": 17516,
        "state": "",
        "group": "",
        "group_num": [
            "recv78pDxUHQupe6z"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 949,
        "2023": 250,
        "id": "recZ34D8cBPmThtNu",
        "row": 17495,
        "state": "",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "id": "recZaWJplZL4NA3Gv",
        "row": 17518,
        "state": "",
        "group": "",
        "group_num": [
            "rec8K2RV1uGycCJMs"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2747,
        "id": "rec0dAkZhqqUCXuVW",
        "row": 17506,
        "state": "",
        "group": "",
        "group_num": [
            "reccatz6ajbeSe4P0"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 106,
        "2023": 82,
        "id": "rec3u7AqzHxYbeAI9",
        "row": 17503,
        "state": "",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "rec3Gjic6agQLb2mK",
        "row": 17496,
        "state": "",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 1669,
        "id": "rec3LIDvKQjo1o5xt",
        "row": 17497,
        "state": "",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 438,
        "id": "rec4lWfdFbSm4106L",
        "row": 17511,
        "state": "",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "rec60KWr3dkbsyiDb",
        "row": 17521,
        "state": "",
        "group": "",
        "group_num": [
            "recNpgLJBk3aVtoa2"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2532,
        "id": "rec7QFR6px0yGvTL0",
        "row": 17487,
        "state": "",
        "group": "GB0192M003",
        "group_num": [
            "recJxsKQ9x9qIbHCn"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2805,
        "id": "recaBshpRi8PDNOoy",
        "row": 17507,
        "state": "",
        "group": "",
        "group_num": [
            "reccatz6ajbeSe4P0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 265,
        "id": "recb5FkFETQp8Qlmd",
        "row": 17515,
        "state": "",
        "group": "",
        "group_num": [
            "recPnQxZTeiB1ZTHR"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3242,
        "2023": 3623,
        "2024": 2247,
        "id": "recog7P8S2nK6myCp",
        "row": 17498,
        "state": "",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 989,
        "2023": 328,
        "id": "recqcbSl4m21xdHPo",
        "row": 17494,
        "state": "",
        "group": "174549M111",
        "group_num": [
            "reck2QDssOOXEhYHP"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 66,
        "id": "recuIbVQlxoYTjcgL",
        "row": 17525,
        "state": "",
        "group": "02AP001",
        "group_num": [
            "recUKp3vxe1Vg4v4I"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recup94SDhzxY4lO7",
        "row": 17530,
        "state": "",
        "group": "ADPGA1M298",
        "group_num": [
            "recAIHGvfo7XIyduS"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 211,
        "id": "recyAHGo7DAm0EaT2",
        "row": 17514,
        "state": "",
        "group": "",
        "group_num": [
            "rec5wk8UdxE0qqMBx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 247,
        "2024": 263,
        "id": "recya4OcE5y1FZHNE",
        "row": 17520,
        "state": "",
        "group": "",
        "group_num": [
            "recTZUJCAThH3elsK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 620,
        "id": "recyrXbuXDRqkjZcP",
        "row": 17519,
        "state": "",
        "group": "",
        "group_num": [
            "rec5zfbapJeSVx8vQ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "2023": 800,
        "id": "recAmVbfvf3sH9Gcn",
        "row": 17492,
        "state": "",
        "group": "",
        "group_num": [
            "recYEGbZk2qv98elO"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 945,
        "id": "recBuyhdbn6IkZTAB",
        "row": 17490,
        "state": "",
        "group": "GB0192M005",
        "group_num": [
            "recdsiZKzqkIq5A8N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1970,
        "id": "recBvvngFyuEH63ml",
        "row": 17513,
        "state": "",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 467,
        "id": "recEf4LEjZwkOkal9",
        "row": 17491,
        "state": "",
        "group": "GB0192M005",
        "group_num": [
            "recdsiZKzqkIq5A8N"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 338,
        "id": "recF4Usc9fiJJZh0J",
        "row": 17485,
        "state": "",
        "group": "GB0192M002",
        "group_num": [
            "recIwlAhUPtIzxOTH"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 302,
        "id": "recGT7FeFjGuos2rx",
        "row": 17527,
        "state": "",
        "group": "",
        "group_num": [
            "recAz3TFahnUXXz3C"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2542,
        "id": "recJFBpQj4OsPOArw",
        "row": 17505,
        "state": "",
        "group": "174549M411",
        "group_num": [
            "recrcFt7EYwaRgeVM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 382,
        "id": "recKZGOQydsjdtDSa",
        "row": 17524,
        "state": "",
        "group": "",
        "group_num": [
            "rec03BvIrVLbXaca8"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 581,
        "2023": 674,
        "id": "recKMWudMZq1IZqec",
        "row": 17486,
        "state": "",
        "group": "GB0192M002",
        "group_num": [
            "recIwlAhUPtIzxOTH"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 457,
        "id": "recKRlFgRqu6hiEp2",
        "row": 17493,
        "state": "",
        "group": "",
        "group_num": [
            "recYEGbZk2qv98elO"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1241,
        "2023": 503,
        "id": "recKffVaOQzeskXPe",
        "row": 17510,
        "state": "",
        "group": "174614M1GN",
        "group_num": [
            "rec90GvBdQU8RaiEN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recMFDIXDVMYQyBmO",
        "row": 17528,
        "state": "",
        "group": "",
        "group_num": [
            "recHvt8xni02VN2tY"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "2024": 225,
        "id": "recMPNKessXKFPeV1",
        "row": 17529,
        "state": "",
        "group": "",
        "group_num": [
            "recHvt8xni02VN2tY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3367,
        "id": "recN9RatwxON0ffJE",
        "row": 17532,
        "state": "",
        "group": "ADPGA1M298",
        "group_num": [
            "recAIHGvfo7XIyduS"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 342,
        "id": "recfdWP9SAVh8QBCr",
        "row": 17540,
        "state": "",
        "group": "",
        "group_num": [
            "recfmWca8rRQwvrCk"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recfipdYv0SgIciXf",
        "row": 17569,
        "state": "",
        "group": "",
        "group_num": [
            "recUUFCbze17LVTkz"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "2024": 151,
        "id": "reciZnSxAPE1uSkhz",
        "row": 17571,
        "state": "",
        "group": "",
        "group_num": [
            "rec9QlO93JfPkSNVz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 205,
        "2024": 205,
        "id": "reciPMAu1bWNtURmI",
        "row": 17580,
        "state": "",
        "group": "",
        "group_num": [
            "recSk4hl7jjKZY4jP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 380,
        "id": "recjThCeSyhguhImW",
        "row": 17574,
        "state": "",
        "group": "",
        "group_num": [
            "rec5HT0H0KxTW5drR"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4149,
        "2023": 943,
        "id": "recjQop4LEYczLqZT",
        "row": 17557,
        "state": "",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 716,
        "id": "recj5cCw3RzniZaPu",
        "row": 17567,
        "state": "",
        "group": "",
        "group_num": [
            "recUUFCbze17LVTkz"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1800,
        "id": "reckNIEDvUjQYJhT7",
        "row": 17541,
        "state": "",
        "group": "",
        "group_num": [
            "rec83yfzgqrPzFWaU"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 960,
        "id": "reclbovraljJkWpK0",
        "row": 17559,
        "state": "",
        "group": "",
        "group_num": [
            "rectStPnkNH0WjshV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "reclqwVdNMkSSdvFJ",
        "row": 17561,
        "state": "",
        "group": "",
        "group_num": [
            "recfYCizqOktW38eS"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 419,
        "2023": 497,
        "id": "recmdOiJxXEx3enC2",
        "row": 17568,
        "state": "",
        "group": "",
        "group_num": [
            "recUUFCbze17LVTkz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 442,
        "id": "recnswkboP1VzwEwF",
        "row": 17581,
        "state": "",
        "group": "",
        "group_num": [
            "reca82zU0GT54VzYC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 442,
        "id": "recniLRn2GGemkcFY",
        "row": 17576,
        "state": "",
        "group": "",
        "group_num": [
            "recgENHAD44q2JCbb"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "id": "recV5NWvoztE4e9Lu",
        "row": 17537,
        "state": "",
        "group": "",
        "group_num": [
            "recaolPktCI1DlCgd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 39,
        "id": "recWYkjb5F1XHyxRB",
        "row": 17578,
        "state": "",
        "group": "",
        "group_num": [
            "recgENHAD44q2JCbb"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 349,
        "id": "recXUHdiHrOTGTsAW",
        "row": 17562,
        "state": "",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1556,
        "id": "recXVQiunf60XPsYt",
        "row": 17543,
        "state": "",
        "group": "174592D3NA",
        "group_num": [
            "recHGadvLA8iXMEpM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1795,
        "id": "recYkCFghkIWrjuzr",
        "row": 17544,
        "state": "",
        "group": "174539N2A1",
        "group_num": [
            "rec9jfHdhVJvBOD7D"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1066,
        "2024": 493,
        "id": "rec0tKXFl7lD8cFrW",
        "row": 17547,
        "state": "",
        "group": "",
        "group_num": [
            "reczkrddi9zuFpgYu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1697,
        "id": "rec0gINcr3x225nru",
        "row": 17539,
        "state": "",
        "group": "174566MFA1",
        "group_num": [
            "rec56EWQ77Y5PtrXU"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 615,
        "id": "rec2UJPozuEElkmef",
        "row": 17551,
        "state": "",
        "group": "",
        "group_num": [
            "recm1BJpKCevdjF8L"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "rec3Eu1CYt5OgDjxh",
        "row": 17552,
        "state": "",
        "group": "",
        "group_num": [
            "recm1BJpKCevdjF8L"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec50jxd5EWFDkIYd",
        "row": 17535,
        "state": "",
        "group": "",
        "group_num": [
            "rec8K2RV1uGycCJMs"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2471,
        "id": "rec54FG71AZ4FZ3kU",
        "row": 17565,
        "state": "",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1993,
        "2023": 2078,
        "2024": 1032,
        "id": "rec61Zy0cDt2tCfoN",
        "row": 17553,
        "state": "",
        "group": "174572BBA1",
        "group_num": [
            "rectZizwDUkziVWiL"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1505,
        "id": "recaWLtYgUE9N7Tng",
        "row": 17542,
        "state": "",
        "group": "174592D3NA",
        "group_num": [
            "recHGadvLA8iXMEpM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recbeNm92C92wJWwi",
        "row": 17570,
        "state": "",
        "group": "",
        "group_num": [
            "rec9QlO93JfPkSNVz"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 208,
        "id": "recdtrw0nLBIGeYU5",
        "row": 17577,
        "state": "",
        "group": "",
        "group_num": [
            "recgENHAD44q2JCbb"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recpKnMU0VrJy4mN3",
        "row": 17546,
        "state": "",
        "group": "",
        "group_num": [
            "recpKcrHzohxByo0P"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1869,
        "id": "recrEKNKy6mFwPdLz",
        "row": 17538,
        "state": "",
        "group": "174566MFA1",
        "group_num": [
            "rec56EWQ77Y5PtrXU"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1517,
        "id": "recstU8y0APDcBdtJ",
        "row": 17534,
        "state": "",
        "group": "GA7787M003",
        "group_num": [
            "recfGFFa6jleks3qA"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1097,
        "id": "recuOAibnTTX83tWX",
        "row": 17555,
        "state": "",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1907,
        "id": "recwv3oiEiQtLdZZU",
        "row": 17545,
        "state": "",
        "group": "174539N2A1",
        "group_num": [
            "rec9jfHdhVJvBOD7D"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1799,
        "id": "recwlRoQYz6xxllDA",
        "row": 17566,
        "state": "",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 188,
        "id": "recxjJ5LAlKSgFEb2",
        "row": 17575,
        "state": "",
        "group": "",
        "group_num": [
            "recc8p27oIlAT9shU"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 405,
        "id": "reczJkVmKkBLQ3Ymv",
        "row": 17560,
        "state": "",
        "group": "",
        "group_num": [
            "rectStPnkNH0WjshV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "recB5eZaMBxxZwik2",
        "row": 17550,
        "state": "",
        "group": "GA9274M016",
        "group_num": [
            "rec8YtNfcESSrbmwp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recCMClGUTKKuTtOa",
        "row": 17579,
        "state": "",
        "group": "",
        "group_num": [
            "recssRj8gcEv9nGXU"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 153,
        "id": "recCobxfWUP9ufh8Y",
        "row": 17563,
        "state": "",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 224,
        "id": "recE72cMILT0evhFP",
        "row": 17583,
        "state": "",
        "group": "",
        "group_num": [
            "recpr21rnQDwZxNsG"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 78,
        "id": "recEgodke4ZjSYkJe",
        "row": 17564,
        "state": "",
        "group": "174572BFC1",
        "group_num": [
            "recLWm1rjZap2k1AV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1069,
        "2024": 1437,
        "id": "recEkHWaKOoNr7gBg",
        "row": 17573,
        "state": "",
        "group": "",
        "group_num": [
            "rec9QlO93JfPkSNVz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 71,
        "id": "recFW3JKVl4nBnH3F",
        "row": 17536,
        "state": "",
        "group": "",
        "group_num": [
            "rec8K2RV1uGycCJMs"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recFv3lgHrRBXO79h",
        "row": 17582,
        "state": "",
        "group": "",
        "group_num": [
            "recpr21rnQDwZxNsG"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "2024": 384,
        "id": "recFg3paj0K5vnEqk",
        "row": 17548,
        "state": "",
        "group": "",
        "group_num": [
            "reczkrddi9zuFpgYu"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2045,
        "id": "recGdJpmVIdzkgMXu",
        "row": 17554,
        "state": "",
        "group": "174572BBA1",
        "group_num": [
            "rectZizwDUkziVWiL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2488,
        "2023": 1086,
        "id": "recHXAu5UVQajUaS9",
        "row": 17556,
        "state": "",
        "group": "174572BBB1",
        "group_num": [
            "recVNirGp2gvhUsDk"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 418,
        "id": "recKrChEjThEJN6gq",
        "row": 17558,
        "state": "",
        "group": "",
        "group_num": [
            "rectStPnkNH0WjshV"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 34,
        "id": "recLLHf13u521xf09",
        "row": 17572,
        "state": "",
        "group": "",
        "group_num": [
            "rec9QlO93JfPkSNVz"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recNPP5QO70U8Tqfq",
        "row": 17549,
        "state": "",
        "group": "",
        "group_num": [
            "recLunPGZvQJ7KKfA"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1449,
        "id": "recih83bj39BVY3QD",
        "row": 17588,
        "state": "",
        "group": "201045A810",
        "group_num": [
            "recum7CWwDJLBtdy0"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recjMM4UKJdJ2mRlr",
        "row": 17606,
        "state": "",
        "group": "",
        "group_num": [
            "recip4NxHHhfDX5as"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recjgvv8oaX41XtI7",
        "row": 17587,
        "state": "",
        "group": "",
        "group_num": [
            "receyFFg40AqviI2A"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1680,
        "id": "reclF1Ll2vZuU5236",
        "row": 17595,
        "state": "",
        "group": "GA7186M011",
        "group_num": [
            "recNY02sWLnlNQz5n"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "2024": 402,
        "id": "recmhwAKVYoj6mssP",
        "row": 17601,
        "state": "",
        "group": "",
        "group_num": [
            "rect5SCkpPVWUMonD"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 197,
        "id": "recOChaNRLxBYh9vW",
        "row": 17603,
        "state": "",
        "group": "",
        "group_num": [
            "recxLbsAiMXhjihsz"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recOcZcvYaIekPvWx",
        "row": 17598,
        "state": "",
        "group": "",
        "group_num": [
            "recIOWV6fdZkpYf79"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 371,
        "id": "recRgqZt7AEjegIRF",
        "row": 17591,
        "state": "",
        "group": "",
        "group_num": [
            "recM4MP029sAg2sJa"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 34,
        "id": "recTR29jjYDoyjuAP",
        "row": 17600,
        "state": "",
        "group": "",
        "group_num": [
            "recIOWV6fdZkpYf79"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 257,
        "id": "recWWoHh7FlcKcnM4",
        "row": 17612,
        "state": "",
        "group": "A05621",
        "group_num": [
            "reckWUQLtmk4affuf"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1315,
        "id": "recW0g6D9PQpaA6vw",
        "row": 17589,
        "state": "",
        "group": "201045A810",
        "group_num": [
            "recum7CWwDJLBtdy0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec1tIhGPNMWdc8YP",
        "row": 17592,
        "state": "",
        "group": "GA6085M001",
        "group_num": [
            "recZhx2kLdxmYMy8j"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 745,
        "id": "rec2RYGvCDl2LMqfw",
        "row": 17596,
        "state": "",
        "group": "",
        "group_num": [
            "reckzFXyTipPjIGMB"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 865,
        "id": "rec463kZIprRCqyre",
        "row": 17605,
        "state": "",
        "group": "",
        "group_num": [
            "recM2Hfk6jBzpPEZo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 34,
        "id": "rec5QJM4gM29BycHi",
        "row": 17584,
        "state": "",
        "group": "",
        "group_num": [
            "recpr21rnQDwZxNsG"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "rec5fkYJlVv5TTDfv",
        "row": 17610,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ALrB5eWaagyGs"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 226,
        "id": "rec9Co158kZq3WLp7",
        "row": 17602,
        "state": "",
        "group": "",
        "group_num": [
            "rect5SCkpPVWUMonD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recbiKpbW6Py3G83W",
        "row": 17607,
        "state": "",
        "group": "",
        "group_num": [
            "recip4NxHHhfDX5as"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1500,
        "id": "reccfoJzl99yCNimQ",
        "row": 17586,
        "state": "",
        "group": "",
        "group_num": [
            "recovsA7ZggbsTZGW"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1689,
        "id": "reco7BQ9G3AGmCVUf",
        "row": 17590,
        "state": "",
        "group": "1871VL",
        "group_num": [
            "recqNykDeJWy8f221"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 34,
        "id": "recobMPaxoEU5heQR",
        "row": 17608,
        "state": "",
        "group": "",
        "group_num": [
            "recip4NxHHhfDX5as"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 202,
        "id": "recpTS3ayIDQK2jKF",
        "row": 17611,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ALrB5eWaagyGs"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 160,
        "id": "recqO3XfTqaRv3aWu",
        "row": 17585,
        "state": "",
        "group": "",
        "group_num": [
            "rec2w0Nmei3yXrENo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 224,
        "id": "recqaYxmaqoaXLxYC",
        "row": 17597,
        "state": "",
        "group": "",
        "group_num": [
            "reckzFXyTipPjIGMB"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recuY7mw1apRCVilj",
        "row": 17614,
        "state": "",
        "group": "A05621",
        "group_num": [
            "reckWUQLtmk4affuf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "reczhz1f37lja3OC4",
        "row": 17593,
        "state": "",
        "group": "GA6085M001",
        "group_num": [
            "recZhx2kLdxmYMy8j"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recBjFicg1mYqjGd3",
        "row": 17609,
        "state": "",
        "group": "SP3225M001",
        "group_num": [
            "recA6XuusqcRBElmC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recG6J0QIReBDN9aV",
        "row": 17604,
        "state": "",
        "group": "A07848",
        "group_num": [
            "rectGSAMf1dabAuu7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1657,
        "id": "recHh5LRqfMG74kRB",
        "row": 17594,
        "state": "",
        "group": "GA7186M011",
        "group_num": [
            "recNY02sWLnlNQz5n"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "2023": 189,
        "id": "recKQ5qGtSIFlBWpM",
        "row": 17613,
        "state": "",
        "group": "A05621",
        "group_num": [
            "reckWUQLtmk4affuf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recN8KrjiDmCcLe4i",
        "row": 17599,
        "state": "",
        "group": "",
        "group_num": [
            "recIOWV6fdZkpYf79"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF GA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 283,
        "id": "recevSIX5ZgrBsk7l",
        "row": 17615,
        "state": "",
        "group": "270213OFM7",
        "group_num": [
            "recIJoisaNJEihlZN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1762,
        "id": "recebYFGbQaZl23AK",
        "row": 17632,
        "state": "",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1962,
        "id": "rechdkHZnx7mJXkeF",
        "row": 17631,
        "state": "",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 518,
        "id": "recjV0GN50rwx6AjM",
        "row": 17621,
        "state": "",
        "group": "270163P1CA",
        "group_num": [
            "recHSMdNzAocQ1fQS"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1902,
        "id": "recnlabeIIQsor7ay",
        "row": 17616,
        "state": "",
        "group": "270213OFM7",
        "group_num": [
            "recIJoisaNJEihlZN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 610,
        "id": "recRTMZOOosAmcmNL",
        "row": 17617,
        "state": "",
        "group": "",
        "group_num": [
            "rec5hVTAoMoN5sXLn"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 329,
        "id": "recZRjn72dni80KSM",
        "row": 17624,
        "state": "",
        "group": "270139M1BA",
        "group_num": [
            "recRUQ9itfNWtcb4N"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 534,
        "id": "rec3yQrlG9Cuw0PXr",
        "row": 17620,
        "state": "",
        "group": "",
        "group_num": [
            "recpKsmxPFiqdI9p2"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recdDJNxovEZ4m1a1",
        "row": 17627,
        "state": "",
        "group": "",
        "group_num": [
            "recIRRh6JLWJM2Q9b"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1940,
        "2024": 1943,
        "id": "recqkox6ipKwp8bnF",
        "row": 17625,
        "state": "",
        "group": "270139M1BA",
        "group_num": [
            "recRUQ9itfNWtcb4N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 229,
        "id": "recsElFzNeDHwr5sd",
        "row": 17619,
        "state": "",
        "group": "",
        "group_num": [
            "recpKsmxPFiqdI9p2"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recvcm1zIprO1jTPi",
        "row": 17633,
        "state": "",
        "group": "270236M4A1",
        "group_num": [
            "recx1OMmFlzQIs09I"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "recxEfjDSim9Qn3tS",
        "row": 17623,
        "state": "",
        "group": "270139M1BA",
        "group_num": [
            "recRUQ9itfNWtcb4N"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1218,
        "id": "reczZmcPKXs25QsH6",
        "row": 17618,
        "state": "",
        "group": "",
        "group_num": [
            "recpKsmxPFiqdI9p2"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recB59sOmwYMLGjGq",
        "row": 17629,
        "state": "",
        "group": "270088MLMB",
        "group_num": [
            "rec0U6574kbxdZbbh"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 789,
        "2023": 967,
        "id": "recJVaXjggCVIJLhc",
        "row": 17626,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 312,
        "id": "recNFCLGHewUuH275",
        "row": 17630,
        "state": "",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "id": "recNLFpemN1PvccJO",
        "row": 17628,
        "state": "",
        "group": "270088MLMB",
        "group_num": [
            "rec0U6574kbxdZbbh"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recNgenDiZhYXoUOa",
        "row": 17622,
        "state": "",
        "group": "",
        "group_num": [
            "recnuCpCj0Kne1evL"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recg2O0uFy9iQXmjd",
        "row": 17646,
        "state": "",
        "group": "",
        "group_num": [
            "recUSK3J0g4e7d7Yx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 378,
        "id": "recl6aetvBuWy6MBl",
        "row": 17667,
        "state": "",
        "group": "",
        "group_num": [
            "recwTeSUrpRF056In"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1996,
        "id": "reclbdu6fBbGhtRJ3",
        "row": 17668,
        "state": "",
        "group": "270099M2AA",
        "group_num": [
            "reczZPCiGmfnU1y8r"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recOzYBpl11IoA7YB",
        "row": 17673,
        "state": "",
        "group": "213043M5B2",
        "group_num": [
            "recp0MsHRPCkozBKf"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5000,
        "id": "recOPX71xYFQQCZ8R",
        "row": 17678,
        "state": "",
        "group": "",
        "group_num": [
            "recnB7bigaJSh31NK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2828,
        "id": "recOlAmdbXwsHDldm",
        "row": 17663,
        "state": "",
        "group": "",
        "group_num": [
            "recEzWAYU8d0rhDKY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 316,
        "id": "recOqJUEZnerOwtdT",
        "row": 17670,
        "state": "",
        "group": "",
        "group_num": [
            "recORYyfWcyDyocoo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2200,
        "id": "recQIWuiKcyXPBZwi",
        "row": 17674,
        "state": "",
        "group": "213043M5B2",
        "group_num": [
            "recp0MsHRPCkozBKf"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1724,
        "id": "recRaFCqDDZWFLPg8",
        "row": 17651,
        "state": "",
        "group": "270181G3A1",
        "group_num": [
            "recHNl4nDPROBSnnP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recSA4snz1E2YwlOA",
        "row": 17664,
        "state": "",
        "group": "",
        "group_num": [
            "recwC9oxogo0Dwg5z"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recTUVTsIFr5pCjop",
        "row": 17639,
        "state": "",
        "group": "270083PGIA",
        "group_num": [
            "recpLmZONDBNO4mxI"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2093,
        "id": "recUa2BboEYWtB4r7",
        "row": 17662,
        "state": "",
        "group": "",
        "group_num": [
            "recEzWAYU8d0rhDKY"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 581,
        "id": "recV6Q37XhWUwegIz",
        "row": 17656,
        "state": "",
        "group": "",
        "group_num": [
            "recVjf9frXRe1ZHNe"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 536,
        "id": "recWv9eE0Szqs8El7",
        "row": 17669,
        "state": "",
        "group": "270082N1ED",
        "group_num": [
            "recQGxWZHdKy6QxlB"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recYwhy5Wb93AWNEz",
        "row": 17644,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recYHBVdGKiXNUbk2",
        "row": 17665,
        "state": "",
        "group": "",
        "group_num": [
            "recwC9oxogo0Dwg5z"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1914,
        "id": "recY4VVXqjUUUimi3",
        "row": 17650,
        "state": "",
        "group": "213051M1GB",
        "group_num": [
            "rec5CeP0jZ5DnKG6j"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3099,
        "id": "rec1xdtJvES6QdCyU",
        "row": 17676,
        "state": "",
        "group": "270159F1JA",
        "group_num": [
            "rectH3OBEc8EGN8F5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1682,
        "id": "rec1mqttJ3bA71dJA",
        "row": 17652,
        "state": "",
        "group": "270181G3A1",
        "group_num": [
            "recHNl4nDPROBSnnP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1655,
        "id": "rec1rrlxJ0l0Kaj3S",
        "row": 17654,
        "state": "",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "id": "rec34yCMvODNXb35H",
        "row": 17683,
        "state": "",
        "group": "213010P13Y",
        "group_num": [
            "recXyQ9IcBGYpHkAQ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "rec3fMkFHvUghHbTW",
        "row": 17653,
        "state": "",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1556,
        "id": "rec6LUJNeDwN0u3JM",
        "row": 17647,
        "state": "",
        "group": "",
        "group_num": [
            "recUSK3J0g4e7d7Yx"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1533,
        "2023": 497,
        "id": "rec7dCGev57sBGDGg",
        "row": 17682,
        "state": "",
        "group": "213010P13Y",
        "group_num": [
            "recXyQ9IcBGYpHkAQ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec8YgwGQUmzz9QgY",
        "row": 17660,
        "state": "",
        "group": "213059M5HL",
        "group_num": [
            "recuJEA1XWMaZgk2h"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "2024": 4750,
        "id": "rec8m0RiynDb5waR4",
        "row": 17635,
        "state": "",
        "group": "2130661EIB",
        "group_num": [
            "recYWa1kWKw4siQi1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3156,
        "id": "rec9qPC65NdqMvP8I",
        "row": 17641,
        "state": "",
        "group": "213051A3FA",
        "group_num": [
            "rechYPCVyUNvxNgPp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recaTOk9PAcXFHcyF",
        "row": 17659,
        "state": "",
        "group": "213059M5HL",
        "group_num": [
            "recuJEA1XWMaZgk2h"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recbbfh23mZpHS57O",
        "row": 17634,
        "state": "",
        "group": "270236M4A1",
        "group_num": [
            "recx1OMmFlzQIs09I"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 552,
        "id": "recdFnj1V3qtWNaaA",
        "row": 17645,
        "state": "",
        "group": "",
        "group_num": [
            "recUSK3J0g4e7d7Yx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recd2b9khsNxdxU7S",
        "row": 17680,
        "state": "",
        "group": "W24174M109",
        "group_num": [
            "rech1TTdnEHbtNjnv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recpg1zJYsopk95Ni",
        "row": 17648,
        "state": "",
        "group": "213051M1FA",
        "group_num": [
            "recdOaGNm5EYluoMv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4000,
        "id": "recrYTVvosuu55dqH",
        "row": 17658,
        "state": "",
        "group": "270234M2FC",
        "group_num": [
            "recnWwfHorYsNzmLF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "rectqme5CqsRn8W9x",
        "row": 17643,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3134,
        "id": "recvL0wy4yZxvsupF",
        "row": 17636,
        "state": "",
        "group": "2130661EIB",
        "group_num": [
            "recYWa1kWKw4siQi1"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1596,
        "id": "recy1jJzH7VTO01B8",
        "row": 17640,
        "state": "",
        "group": "2700833GIA",
        "group_num": [
            "recYM9rT8kOas3x5J"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1124,
        "id": "recyQAG7B4HUyEQ0G",
        "row": 17672,
        "state": "",
        "group": "",
        "group_num": [
            "recORYyfWcyDyocoo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1305,
        "id": "recA8gYCX3XidQRx1",
        "row": 17661,
        "state": "",
        "group": "",
        "group_num": [
            "recEzWAYU8d0rhDKY"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recAotOhlJdjWY3ci",
        "row": 17642,
        "state": "",
        "group": "213051A3FA",
        "group_num": [
            "rechYPCVyUNvxNgPp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recEP5loULOLMPJMW",
        "row": 17666,
        "state": "",
        "group": "",
        "group_num": [
            "recwTeSUrpRF056In"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 78,
        "2023": 70,
        "id": "recFlf9Rm9SJSzgAA",
        "row": 17657,
        "state": "",
        "group": "",
        "group_num": [
            "recVjf9frXRe1ZHNe"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 398,
        "id": "recGyoToCPNYZKf49",
        "row": 17671,
        "state": "",
        "group": "",
        "group_num": [
            "recORYyfWcyDyocoo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recGeJUPMSDsnLG8Q",
        "row": 17677,
        "state": "",
        "group": "",
        "group_num": [
            "recnB7bigaJSh31NK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2611,
        "2024": 6000,
        "id": "recHg09htjYnq2RRj",
        "row": 17679,
        "state": "",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 87,
        "id": "recI2a5NXTaeOowSe",
        "row": 17681,
        "state": "",
        "group": "",
        "group_num": [
            "recBZ2jsROkIPTOOK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1700,
        "id": "recKU4xbflC3mbxn9",
        "row": 17638,
        "state": "",
        "group": "270083PGIA",
        "group_num": [
            "recpLmZONDBNO4mxI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2204,
        "id": "recKi6v3ruDa5rDnQ",
        "row": 17655,
        "state": "",
        "group": "270181M3A1",
        "group_num": [
            "recrR2pDJdlGlRloX"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2107,
        "id": "recKiq3r4MVPKBjt2",
        "row": 17649,
        "state": "",
        "group": "213051M1FB",
        "group_num": [
            "recM33VV1afrDr4JZ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recLWAdundGcZtWJv",
        "row": 17675,
        "state": "",
        "group": "213043M5B2",
        "group_num": [
            "recp0MsHRPCkozBKf"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 611,
        "id": "recMnNedmQJvGJ6vm",
        "row": 17637,
        "state": "",
        "group": "",
        "group_num": [
            "reckirfZr6IqUTQ1R"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "id": "recfZWKrI4fTJEOIh",
        "row": 17701,
        "state": "",
        "group": "270092D1VA",
        "group_num": [
            "recdb4NyXFpEv7NHY"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "id": "recgjh6gxDnpujf2f",
        "row": 17685,
        "state": "",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "rechyupT3alS0cWWb",
        "row": 17712,
        "state": "",
        "group": "174245MGVA",
        "group_num": [
            "rechrSZuXtJff7kxG"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1402,
        "2024": 1402,
        "id": "rechiumjw7uESLeAr",
        "row": 17703,
        "state": "",
        "group": "",
        "group_num": [
            "recmep4hEyZAdFItP"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 539,
        "id": "recjCV01iPty5DwyT",
        "row": 17695,
        "state": "",
        "group": "213065M3AA",
        "group_num": [
            "recA1vFmcbTZipOo1"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 176,
        "id": "reclZ3pML5mIeJqHg",
        "row": 17689,
        "state": "",
        "group": "",
        "group_num": [
            "recCswO1drgC8wh3T"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 414,
        "id": "recOPPhQ3aYWwjNRN",
        "row": 17700,
        "state": "",
        "group": "",
        "group_num": [
            "rec65rAosqRyEMeLv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recPNxbxQkusowS6m",
        "row": 17728,
        "state": "",
        "group": "W12600M002",
        "group_num": [
            "recOcLCA5VYTspOtK"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "recP48Xp8xIvoY15e",
        "row": 17707,
        "state": "",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 323,
        "id": "recPk0VJUVfbYoUlN",
        "row": 17684,
        "state": "",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 53,
        "id": "recUJeJ5N0nhyk2KQ",
        "row": 17709,
        "state": "",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1871,
        "id": "recVKJFT7SL7jtDdM",
        "row": 17710,
        "state": "",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1847,
        "id": "recWLWzL0L4ZICKR3",
        "row": 17698,
        "state": "",
        "group": "",
        "group_num": [
            "rec7dCTPrhbsRj4EO"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 536,
        "id": "recW2gx1jCwM0VxHV",
        "row": 17721,
        "state": "",
        "group": "",
        "group_num": [
            "rec6YWmaq071oeFuV"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 532,
        "id": "recYT65zK5slNeFkG",
        "row": 17727,
        "state": "",
        "group": "W12600M002",
        "group_num": [
            "recOcLCA5VYTspOtK"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 411,
        "2024": 338,
        "id": "recYzuxZN8HffyKwy",
        "row": 17713,
        "state": "",
        "group": "",
        "group_num": [
            "recjLUT2Ioh1dSUMP"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 770,
        "id": "recYPZvBU7r4ZzQYT",
        "row": 17705,
        "state": "",
        "group": "",
        "group_num": [
            "recThWvRSKs4CRQS8"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 37,
        "id": "recZI7Z6aPcnU2SIG",
        "row": 17722,
        "state": "",
        "group": "",
        "group_num": [
            "rec6YWmaq071oeFuV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "rec2QaOjDR5h6p3Vu",
        "row": 17730,
        "state": "",
        "group": "",
        "group_num": [
            "recB3nqW2pVyRjBxB"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1738,
        "2023": 1673,
        "id": "rec8m7UHo74kszgJr",
        "row": 17696,
        "state": "",
        "group": "213065M3AA",
        "group_num": [
            "recA1vFmcbTZipOo1"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "rec9MtDI9J2ErBpdP",
        "row": 17729,
        "state": "",
        "group": "",
        "group_num": [
            "recB3nqW2pVyRjBxB"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "recd3f90O5kyh8eVP",
        "row": 17688,
        "state": "",
        "group": "",
        "group_num": [
            "recCswO1drgC8wh3T"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1750,
        "id": "recoxmC5Z5fmRusbe",
        "row": 17691,
        "state": "",
        "group": "",
        "group_num": [
            "recasxKTvdCmqmx4T"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 632,
        "id": "recozWIhUe1spECKu",
        "row": 17720,
        "state": "",
        "group": "",
        "group_num": [
            "recxbfPf8KQoPUJoY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recobHbK1VbjQnpsW",
        "row": 17716,
        "state": "",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recqwCGDQgH2GAfoN",
        "row": 17706,
        "state": "",
        "group": "",
        "group_num": [
            "recThWvRSKs4CRQS8"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2828,
        "id": "recqB2oXEvsNj6M4O",
        "row": 17693,
        "state": "",
        "group": "",
        "group_num": [
            "recNTzo9kEGbhKQl7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2229,
        "id": "recqefxZ2ebDR0JOs",
        "row": 17687,
        "state": "",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2160,
        "id": "recrptpBiueloAjUG",
        "row": 17686,
        "state": "",
        "group": "270182M0A0",
        "group_num": [
            "rec1w9YuPf1nXijB6"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3096,
        "id": "rect5yJFnwYypPd1q",
        "row": 17725,
        "state": "",
        "group": "27008564KA",
        "group_num": [
            "recIpCUoyGz44QghV"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1941,
        "id": "recvOGHH3Xpiws6wa",
        "row": 17719,
        "state": "",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "2024": 553,
        "id": "recwyUxFSdAs2YnRm",
        "row": 17723,
        "state": "",
        "group": "",
        "group_num": [
            "recko0V5fSmhed98d"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 548,
        "id": "recxheDxa6ZpJLSsQ",
        "row": 17708,
        "state": "",
        "group": "174245MENC",
        "group_num": [
            "recg4rhOIRWYWlT3I"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recAiHU1bdHOAog1m",
        "row": 17717,
        "state": "",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recBgpNF2VHRuX1c9",
        "row": 17694,
        "state": "",
        "group": "",
        "group_num": [
            "recL2QyN8Q92toNbS"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1649,
        "2024": 1649,
        "id": "recC7IZWjsdrqH10d",
        "row": 17704,
        "state": "",
        "group": "",
        "group_num": [
            "recmep4hEyZAdFItP"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3036,
        "id": "recDtrSxf48DDA6y2",
        "row": 17711,
        "state": "",
        "group": "174245MGVA",
        "group_num": [
            "rechrSZuXtJff7kxG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4520,
        "id": "recFTDU5xkUGX4XUd",
        "row": 17726,
        "state": "",
        "group": "27008564KA",
        "group_num": [
            "recIpCUoyGz44QghV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2160,
        "id": "recFuZGyU95oSOdXh",
        "row": 17718,
        "state": "",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2093,
        "id": "recGSS8eUi3NMHwHb",
        "row": 17692,
        "state": "",
        "group": "",
        "group_num": [
            "recNTzo9kEGbhKQl7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recGKMI7yBFMFO1vI",
        "row": 17714,
        "state": "",
        "group": "",
        "group_num": [
            "recjLUT2Ioh1dSUMP"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2001,
        "id": "recHCqztNDdmrkdgE",
        "row": 17697,
        "state": "",
        "group": "",
        "group_num": [
            "rec7dCTPrhbsRj4EO"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "recHrnoZSq1dlAffj",
        "row": 17702,
        "state": "",
        "group": "270092D1VA",
        "group_num": [
            "recdb4NyXFpEv7NHY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 246,
        "id": "recKwLrSDxdbu9Jx9",
        "row": 17699,
        "state": "",
        "group": "270213OFM7",
        "group_num": [
            "recIJoisaNJEihlZN"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 894,
        "id": "recKj3ZfVPyZmNYPy",
        "row": 17724,
        "state": "",
        "group": "",
        "group_num": [
            "recko0V5fSmhed98d"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "id": "recMUBB3HmfMzjRlz",
        "row": 17690,
        "state": "",
        "group": "",
        "group_num": [
            "recCswO1drgC8wh3T"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 589,
        "id": "recNwLhbMTlvk3jYm",
        "row": 17715,
        "state": "",
        "group": "1877BL",
        "group_num": [
            "recgBFhPejS8zPlAg"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF IN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 265,
        "id": "recrF62QebImpNWDR",
        "row": 17731,
        "state": "",
        "group": "L03495M002",
        "group_num": [
            "recLOvYbcr0OsHhWy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1865,
        "id": "recAvYRUUeoom655C",
        "row": 17732,
        "state": "",
        "group": "214012M3A1",
        "group_num": [
            "reck8RQYmCA9LbBnm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2085,
        "id": "recAeR84y4aVh0DWd",
        "row": 17733,
        "state": "",
        "group": "",
        "group_num": [
            "rec7O9wFlqovpkZi0"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "rece9TkKKBEsixiZd",
        "row": 17746,
        "state": "",
        "group": "",
        "group_num": [
            "rechda4HzTgRq80U0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2571,
        "id": "recgzqpWEg4SC6ScQ",
        "row": 17743,
        "state": "",
        "group": "W31060M001",
        "group_num": [
            "rececrd8x7IkT1XYv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1177,
        "2023": 248,
        "id": "recgIV1cXczQxfjzD",
        "row": 17749,
        "state": "",
        "group": "214021M2NA",
        "group_num": [
            "recFAwT1UnHovBTzo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 826,
        "id": "recjJT3o6g4Hyx70u",
        "row": 17758,
        "state": "",
        "group": "",
        "group_num": [
            "recwQ1m1R8b6BA1jl"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 341,
        "id": "recREkMCQXIvmDVlG",
        "row": 17755,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recUXA5o0fovd43qU",
        "row": 17744,
        "state": "",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recVEBxiYOAZGoh6v",
        "row": 17737,
        "state": "",
        "group": "W32168M005",
        "group_num": [
            "recTao1MCJoMinas5"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 1538,
        "id": "recWHveMP5rvYJ9EH",
        "row": 17751,
        "state": "",
        "group": "214021M2NA",
        "group_num": [
            "recFAwT1UnHovBTzo"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2571,
        "id": "recXBqzSoUEMX0TLb",
        "row": 17760,
        "state": "",
        "group": "",
        "group_num": [
            "reclmpYDnlgNdWYCF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 885,
        "id": "recY0cMtKLYRvqtA0",
        "row": 17745,
        "state": "",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recYmnFtJ6TVBguGX",
        "row": 17747,
        "state": "",
        "group": "",
        "group_num": [
            "recsGrLkbnFnrr5I3"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "rec5Mn1M9neal9Akb",
        "row": 17752,
        "state": "",
        "group": "",
        "group_num": [
            "reccrAyQdjQWqc2ve"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1925,
        "id": "rec8WYkyavt5j8U6d",
        "row": 17759,
        "state": "",
        "group": "",
        "group_num": [
            "reclmpYDnlgNdWYCF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 518,
        "id": "rec8h9u2bVi34d3BG",
        "row": 17748,
        "state": "",
        "group": "",
        "group_num": [
            "recK0hecbhh9vz2HM"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3121,
        "id": "reccABRjC4zLh07j5",
        "row": 17740,
        "state": "",
        "group": "L03519M014",
        "group_num": [
            "rec9ig0At6JkM8Niv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1754,
        "id": "reccIJdK5WTWmMz1N",
        "row": 17741,
        "state": "",
        "group": "L03519M014",
        "group_num": [
            "rec9ig0At6JkM8Niv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recoadHHgdi2uJNLX",
        "row": 17754,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recpXfiFIWgn9t5lL",
        "row": 17736,
        "state": "",
        "group": "W32168M005",
        "group_num": [
            "recTao1MCJoMinas5"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recp1aTY2QsJnPvzI",
        "row": 17739,
        "state": "",
        "group": "W29649M001",
        "group_num": [
            "recijfBWKuNlVmGfq"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 500,
        "id": "recq77QTe2A3e7MsB",
        "row": 17757,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recqrnj8h2rnTzXvh",
        "row": 17750,
        "state": "",
        "group": "214021M2NA",
        "group_num": [
            "recFAwT1UnHovBTzo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "id": "reczz4E1PoFAZyfoq",
        "row": 17753,
        "state": "",
        "group": "",
        "group_num": [
            "reccrAyQdjQWqc2ve"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recCArNPUX5ylArqk",
        "row": 17742,
        "state": "",
        "group": "W31060M001",
        "group_num": [
            "rececrd8x7IkT1XYv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recDNw18cdJWmmxyG",
        "row": 17738,
        "state": "",
        "group": "W32168M005",
        "group_num": [
            "recTao1MCJoMinas5"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recEykeV6aQwHASsH",
        "row": 17734,
        "state": "",
        "group": "JKY038M013",
        "group_num": [
            "recwuV0EdCQmtAOtu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recF2RZK38FohBMPB",
        "row": 17735,
        "state": "",
        "group": "JKY038M013",
        "group_num": [
            "recwuV0EdCQmtAOtu"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recNxnQYsSUASimRF",
        "row": 17756,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF KY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2300,
        "id": "rec19pYyd45OIUQSB",
        "row": 17763,
        "state": "",
        "group": "",
        "group_num": [
            "recFBilEvKset3kNL"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF ME ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1097,
        "id": "rec4TohFJarVprErJ",
        "row": 17762,
        "state": "",
        "group": "",
        "group_num": [
            "recdPOTE8LcIEFunD"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF ME ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 828,
        "id": "recczGdbbOCjKVztH",
        "row": 17761,
        "state": "",
        "group": "00C020M001",
        "group_num": [
            "recygi4ZYhzf41GXS"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF ME ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recePK7T7XguU3sRs",
        "row": 17765,
        "state": "",
        "group": "",
        "group_num": [
            "recbgGOxfm5LWTcpx"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 672,
        "id": "recfD8Tvm95BsCg3h",
        "row": 17777,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rech34tiYO4gULWRu",
        "row": 17770,
        "state": "",
        "group": "",
        "group_num": [
            "recERPNAk7IFS682f"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 460,
        "id": "reciDEaXwVz6t42Sz",
        "row": 17773,
        "state": "",
        "group": "",
        "group_num": [
            "recHgFtZQcHMXmvJ9"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5443,
        "id": "recjM4fhlRVlZQXyF",
        "row": 17764,
        "state": "",
        "group": "L00144M005",
        "group_num": [
            "recaK55zL1l8xfJVv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 517,
        "id": "reckCSixyVXvgqhs3",
        "row": 17782,
        "state": "",
        "group": "",
        "group_num": [
            "recPfkPsatCpvhDxD"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recTFfhREcOCtT24m",
        "row": 17769,
        "state": "",
        "group": "00233307",
        "group_num": [
            "rechPxXocjKVGKRS2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1980,
        "2023": 2110,
        "id": "recUuuNyGcX044iqs",
        "row": 17779,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2151,
        "id": "recXHi7tTzN5UwafM",
        "row": 17767,
        "state": "",
        "group": "",
        "group_num": [
            "recB0B8Un6hHh4dwG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2420,
        "2023": 2909,
        "id": "recYAqQmHOYcWdHq4",
        "row": 17780,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 329,
        "id": "rec1UZdEkTLx7FGDM",
        "row": 17775,
        "state": "",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 701,
        "id": "rec5pfBPGBmefrC4Q",
        "row": 17776,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1325,
        "id": "rec7WOJRLNUddrwbD",
        "row": 17772,
        "state": "",
        "group": "",
        "group_num": [
            "recHgFtZQcHMXmvJ9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "rec8ezgOwYBnMsJQY",
        "row": 17768,
        "state": "",
        "group": "00233307",
        "group_num": [
            "rechPxXocjKVGKRS2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recbgEY3xRQ0877AV",
        "row": 17781,
        "state": "",
        "group": "",
        "group_num": [
            "recPfkPsatCpvhDxD"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1944,
        "id": "recd2hgVW4ZQCVgDH",
        "row": 17766,
        "state": "",
        "group": "L01359M002",
        "group_num": [
            "recFhGf0cLnyvnmeV"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 42,
        "id": "rectzpaBOQI9qnrtU",
        "row": 17778,
        "state": "",
        "group": "230018ACA2",
        "group_num": [
            "recLQH6J1shKreA4N"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1456,
        "id": "recAqWb7ZKwAObyyp",
        "row": 17771,
        "state": "",
        "group": "",
        "group_num": [
            "reckIHmQNoKTxwMpJ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "2024": 435,
        "id": "recKlU0pmPxhR2rQo",
        "row": 17774,
        "state": "",
        "group": "230018AAA2",
        "group_num": [
            "rec4swx80oAGdiMyf"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF MO ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 543,
        "id": "rectR6SXxEPXctyb9",
        "row": 17783,
        "state": "",
        "group": "L01301M005",
        "group_num": [
            "reclHaCXHlyId2OcK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF NH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "2024": 414,
        "id": "rec1YV3T43H86rlDa",
        "row": 17784,
        "state": "",
        "group": "L06419M002",
        "group_num": [
            "reckxZjjq6L8Vhy5o"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF NH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 320,
        "id": "recP433ZnntoRnMYm",
        "row": 17788,
        "state": "",
        "group": "",
        "group_num": [
            "recJGELIRqahBnhTp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF NV ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1697,
        "id": "recUb5j7T0V3bxNC9",
        "row": 17789,
        "state": "",
        "group": "",
        "group_num": [
            "recJGELIRqahBnhTp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF NV ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 111,
        "id": "rec7LwMmFF90U9XII",
        "row": 17786,
        "state": "",
        "group": "F27588",
        "group_num": [
            "recmIlWV8raEbhQZK"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF NV ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recveheMzTpt6Q5Td",
        "row": 17787,
        "state": "",
        "group": "F27588",
        "group_num": [
            "recmIlWV8raEbhQZK"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF NV ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "reczNTAkXc9AKljsZ",
        "row": 17785,
        "state": "",
        "group": "000UMQ834",
        "group_num": [
            "rechpdVZtSpQCn5ZJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF NV ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 874,
        "id": "recIzJhCVGYZpjgb4",
        "row": 17790,
        "state": "",
        "group": "",
        "group_num": [
            "recJGELIRqahBnhTp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF NV ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 935,
        "id": "recgyxse3S1lsXRXT",
        "row": 17807,
        "state": "",
        "group": "212083MCCA",
        "group_num": [
            "recfRdmk3rDjwgvzm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1840,
        "id": "recj4MvUTsX7pHzgS",
        "row": 17796,
        "state": "",
        "group": "",
        "group_num": [
            "recpFQpMHvp6bB8U3"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 495,
        "id": "recl3TY8TctSMyqRb",
        "row": 17819,
        "state": "",
        "group": "",
        "group_num": [
            "recJOs8lvo3KzIwNq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1858,
        "id": "recnH9TMuKELpRtd2",
        "row": 17827,
        "state": "",
        "group": "",
        "group_num": [
            "recIbx9X2TMgu4BVq"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1961,
        "id": "recOy3xSOOBciqFtH",
        "row": 17813,
        "state": "",
        "group": "212010M5A3",
        "group_num": [
            "recULCUHVMRUyBXB7"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 360,
        "id": "recQY8GeXebYp9S64",
        "row": 17816,
        "state": "",
        "group": "000EMB834AACP00",
        "group_num": [
            "recEqTienx96lZhEs"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recQFfryxVQZwufVh",
        "row": 17791,
        "state": "",
        "group": "270114M1AN",
        "group_num": [
            "recmY9oSD6gAVlVHw"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recQQ7gUvQdEWzB4K",
        "row": 17818,
        "state": "",
        "group": "",
        "group_num": [
            "recJOs8lvo3KzIwNq"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "id": "recQa6x81n7dvmYef",
        "row": 17810,
        "state": "",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 252,
        "2023": 241,
        "id": "recRwtNehIBw7F2bj",
        "row": 17829,
        "state": "",
        "group": "212063M1AA",
        "group_num": [
            "recLkuH3wZ3jrLXIy"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1862,
        "id": "recTDDn4HtMShKY1j",
        "row": 17801,
        "state": "",
        "group": "",
        "group_num": [
            "recWcz5PY6rNVMXE9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 785,
        "id": "recT33JqzwSHw7yQ1",
        "row": 17798,
        "state": "",
        "group": "",
        "group_num": [
            "recTEAy3kN2ep6Jwk"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2397,
        "id": "recXZW2yBv09IfgQd",
        "row": 17808,
        "state": "",
        "group": "212083MCCA",
        "group_num": [
            "recfRdmk3rDjwgvzm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 698,
        "2023": 698,
        "id": "rec1AgZMtBJizsfKa",
        "row": 17793,
        "state": "",
        "group": "",
        "group_num": [
            "rec8opATixuSi7bgd"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1854,
        "id": "rec2EM6MCVNytqOw1",
        "row": 17803,
        "state": "",
        "group": "",
        "group_num": [
            "recuQeF3pBbo3vuDv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2061,
        "id": "rec3suJmb92YgG9XT",
        "row": 17826,
        "state": "",
        "group": "",
        "group_num": [
            "recIbx9X2TMgu4BVq"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 59,
        "id": "rec33DyOrxuqO90JB",
        "row": 17831,
        "state": "",
        "group": "212063M1AA",
        "group_num": [
            "recLkuH3wZ3jrLXIy"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1183,
        "2023": 3000,
        "id": "rec6vugTfUMlmtN1u",
        "row": 17809,
        "state": "",
        "group": "212083MCCA",
        "group_num": [
            "recfRdmk3rDjwgvzm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 943,
        "id": "rec70lvbTCnlPyOJa",
        "row": 17806,
        "state": "",
        "group": "",
        "group_num": [
            "recW2kDI9DFb14LoB"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "rec7xGtKGVKb8zZgF",
        "row": 17817,
        "state": "",
        "group": "000EMB834AACP00",
        "group_num": [
            "recEqTienx96lZhEs"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1517,
        "id": "rec7Q5dJcb1FpggL1",
        "row": 17833,
        "state": "",
        "group": "W51070M001",
        "group_num": [
            "recG6PLHDVguAREQg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec8ApXnTWC6hvD33",
        "row": 17821,
        "state": "",
        "group": "",
        "group_num": [
            "recPdz43gaxK0Ud6b"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1226,
        "id": "rec9siCNv6prFrVYM",
        "row": 17804,
        "state": "",
        "group": "",
        "group_num": [
            "recW2kDI9DFb14LoB"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "rec9GIuDay9kGiZDG",
        "row": 17800,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3193,
        "2023": 1308,
        "id": "rec9nRPgCPJP2XQn3",
        "row": 17805,
        "state": "",
        "group": "",
        "group_num": [
            "recW2kDI9DFb14LoB"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "reccRj9A35N7bS4zf",
        "row": 17792,
        "state": "",
        "group": "270114M1AN",
        "group_num": [
            "recmY9oSD6gAVlVHw"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1346,
        "id": "recdGsNHLBT4JP1sP",
        "row": 17832,
        "state": "",
        "group": "W51070M001",
        "group_num": [
            "recG6PLHDVguAREQg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "id": "recdO6tuIixEo3myR",
        "row": 17812,
        "state": "",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 533,
        "id": "recoLZbJkWBXmjVPp",
        "row": 17822,
        "state": "",
        "group": "",
        "group_num": [
            "recPdz43gaxK0Ud6b"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 940,
        "id": "recpTRpHSNHXewof2",
        "row": 17815,
        "state": "",
        "group": "W51001M001",
        "group_num": [
            "recSZewMnYeKOLLqq"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 746,
        "id": "recpUgnmz2OFmOk69",
        "row": 17797,
        "state": "",
        "group": "",
        "group_num": [
            "recTEAy3kN2ep6Jwk"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 286,
        "id": "recpO4HUkalD2Niuh",
        "row": 17830,
        "state": "",
        "group": "212063M1AA",
        "group_num": [
            "recLkuH3wZ3jrLXIy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 79,
        "2023": 293,
        "id": "recsF7hmMKc1NFgFJ",
        "row": 17820,
        "state": "",
        "group": "",
        "group_num": [
            "recJOs8lvo3KzIwNq"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 711,
        "2023": 698,
        "id": "recsJ185fAnLmgxY1",
        "row": 17802,
        "state": "",
        "group": "",
        "group_num": [
            "recuQeF3pBbo3vuDv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recvwqllECUASPLjO",
        "row": 17824,
        "state": "",
        "group": "OH2860M001",
        "group_num": [
            "recHTM4w4rga0OLKK"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recvKti5i7a5m7NHO",
        "row": 17828,
        "state": "",
        "group": "",
        "group_num": [
            "recOAcEXsRcVg9HLu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "recw4K4NRuD2AQeAO",
        "row": 17794,
        "state": "",
        "group": "",
        "group_num": [
            "recAAAGkApOUuwltd"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "reczXg5t4lQ3CxtvI",
        "row": 17795,
        "state": "",
        "group": "",
        "group_num": [
            "recAAAGkApOUuwltd"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 312,
        "id": "recAd9VBjX1jhmz3m",
        "row": 17811,
        "state": "",
        "group": "003327000CCAG01",
        "group_num": [
            "recHrmkdVU0RktGqf"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recIGbOYKJSVcnGPu",
        "row": 17799,
        "state": "",
        "group": "",
        "group_num": [
            "recTEAy3kN2ep6Jwk"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recK1Hb8cv5iglMB4",
        "row": 17823,
        "state": "",
        "group": "",
        "group_num": [
            "recybYBWXNSJlWzN2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recKDddCGermQPnyL",
        "row": 17825,
        "state": "",
        "group": "OH2860M001",
        "group_num": [
            "recHTM4w4rga0OLKK"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1869,
        "id": "recNjQ7l91dzoeQVE",
        "row": 17814,
        "state": "",
        "group": "212010M5A3",
        "group_num": [
            "recULCUHVMRUyBXB7"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1869,
        "id": "receH0wuFovLpWFHp",
        "row": 17839,
        "state": "",
        "group": "OH2637M003",
        "group_num": [
            "rec7jVPHeyQ7Saftp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recfZAjx7W9uJGJZj",
        "row": 17852,
        "state": "",
        "group": "",
        "group_num": [
            "recrKPlYmHyCnYX4b"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "id": "recglnZhaGyHhSoCr",
        "row": 17857,
        "state": "",
        "group": "003320026FFAG00",
        "group_num": [
            "recROHrkMDq2hmm1K"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 144,
        "2024": 147,
        "id": "rechUMz5b6rWfKlIE",
        "row": 17834,
        "state": "",
        "group": "",
        "group_num": [
            "recn1ouc2FEA21xaJ"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recmTX91IYlguUBrX",
        "row": 17858,
        "state": "",
        "group": "212107M3A2",
        "group_num": [
            "recMoid7jkus4BtOX"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1305,
        "id": "recmueDMB2qS9KJtH",
        "row": 17845,
        "state": "",
        "group": "",
        "group_num": [
            "recTsDVgUSkcqeRGo"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recmd1GFqXbW81VJx",
        "row": 17882,
        "state": "",
        "group": "",
        "group_num": [
            "reccmxMTOOXPKJUH9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recnD2wyX5mnmT5nV",
        "row": 17846,
        "state": "",
        "group": "",
        "group_num": [
            "recrKPlYmHyCnYX4b"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "id": "recnl5hPCYKEiglg9",
        "row": 17842,
        "state": "",
        "group": "W41524M003",
        "group_num": [
            "recytMKFAaPA3ET4W"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 360,
        "id": "recO0bUww71wuN2NX",
        "row": 17881,
        "state": "",
        "group": "",
        "group_num": [
            "reccmxMTOOXPKJUH9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 686,
        "id": "recOihyDMvkOsvpZg",
        "row": 17871,
        "state": "",
        "group": "",
        "group_num": [
            "recnGEXx8Ijxq4PRM"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recPOXDxmivu6JWzw",
        "row": 17851,
        "state": "",
        "group": "",
        "group_num": [
            "recrKPlYmHyCnYX4b"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1706,
        "id": "recQpDCQSE1VmM6XP",
        "row": 17878,
        "state": "",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recS1oWNcCLkvvO6a",
        "row": 17847,
        "state": "",
        "group": "",
        "group_num": [
            "recrKPlYmHyCnYX4b"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 367,
        "id": "recS6mMbHA1nj4M1D",
        "row": 17880,
        "state": "",
        "group": "",
        "group_num": [
            "reck7JT5Y6HbSZDSt"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 958,
        "id": "recSqjxjGVwvW22ey",
        "row": 17867,
        "state": "",
        "group": "213064M6NA",
        "group_num": [
            "recQCsTDAvryIxjBI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2517,
        "id": "recTWk9cx2dj350kN",
        "row": 17859,
        "state": "",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2158,
        "id": "recTXDP1pkUOuLl2o",
        "row": 17865,
        "state": "",
        "group": "OH2197M001",
        "group_num": [
            "reclvvURIxYZPgubv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 296,
        "id": "recUqohLIElEi6t10",
        "row": 17837,
        "state": "",
        "group": "",
        "group_num": [
            "recZpKsPh9SA5wG27"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "id": "recW6alcsrmZsHm1x",
        "row": 17877,
        "state": "",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 288,
        "id": "recWch59ImVhw30U0",
        "row": 17876,
        "state": "",
        "group": "",
        "group_num": [
            "reczmuidKcQGLBPtz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recXnmZVYSSXLFBVL",
        "row": 17841,
        "state": "",
        "group": "W41524M003",
        "group_num": [
            "recytMKFAaPA3ET4W"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 640,
        "id": "recZtwREgAEeUAH8F",
        "row": 17875,
        "state": "",
        "group": "",
        "group_num": [
            "reczmuidKcQGLBPtz"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2332,
        "id": "recZyKD0t2W0MMAxU",
        "row": 17860,
        "state": "",
        "group": "W31060MR09",
        "group_num": [
            "recmcjsqd0fx9LSRc"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "rec0DXP1VMez5tysf",
        "row": 17862,
        "state": "",
        "group": "212081M1EA",
        "group_num": [
            "rec8EhRfj7NqoMBdH"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 435,
        "id": "rec2emNMJEQLJFyZV",
        "row": 17883,
        "state": "",
        "group": "212085P1F2",
        "group_num": [
            "recWiMQR2f7LeFSqC"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "id": "rec54pvhGweIz4UsF",
        "row": 17870,
        "state": "",
        "group": "",
        "group_num": [
            "recHUtgOZN7iji1Zv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 998,
        "id": "rec6kheDqZJz31LA8",
        "row": 17863,
        "state": "",
        "group": "",
        "group_num": [
            "rect92KawO2R0iJ0y"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "rec6lgokIjIJNZX7n",
        "row": 17844,
        "state": "",
        "group": "",
        "group_num": [
            "recTsDVgUSkcqeRGo"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 21,
        "id": "rec8MlBkKs5F9spk3",
        "row": 17835,
        "state": "",
        "group": "",
        "group_num": [
            "recn1ouc2FEA21xaJ"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recbIVAkA4y4NNdIz",
        "row": 17840,
        "state": "",
        "group": "W41499M001",
        "group_num": [
            "recnE4s1K0zkPaTmQ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 248,
        "id": "recd1iXVJDwml54gW",
        "row": 17864,
        "state": "",
        "group": "",
        "group_num": [
            "recpWuIy4CF4xunpz"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "id": "recp1B9sqcNWHvOO2",
        "row": 17873,
        "state": "",
        "group": "",
        "group_num": [
            "recV4LzuSxuNYjS83"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recp3LVlgMsKmt0FA",
        "row": 17866,
        "state": "",
        "group": "OH2197M001",
        "group_num": [
            "reclvvURIxYZPgubv"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recrLaSi0eg7y5yv1",
        "row": 17855,
        "state": "",
        "group": "213051M1FA",
        "group_num": [
            "recdOaGNm5EYluoMv"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2100,
        "id": "recsTDpVHGBgEVGO7",
        "row": 17861,
        "state": "",
        "group": "212081M1EA",
        "group_num": [
            "rec8EhRfj7NqoMBdH"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3906,
        "2023": 952,
        "id": "rectQ36njMCaHCrXK",
        "row": 17848,
        "state": "",
        "group": "W42781M001",
        "group_num": [
            "reckehoDBHehBMaat"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1791,
        "id": "recuWGCkMYGctqOjb",
        "row": 17856,
        "state": "",
        "group": "213051M1FA",
        "group_num": [
            "recdOaGNm5EYluoMv"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recuFHP7Dstsy8bhu",
        "row": 17853,
        "state": "",
        "group": "213051M1FA",
        "group_num": [
            "recdOaGNm5EYluoMv"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 556,
        "id": "recxvFE2ACaw1uabL",
        "row": 17843,
        "state": "",
        "group": "",
        "group_num": [
            "recTsDVgUSkcqeRGo"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "reczVrXbxBmlkgMd8",
        "row": 17850,
        "state": "",
        "group": "",
        "group_num": [
            "recrKPlYmHyCnYX4b"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1694,
        "id": "reczMTbeG50gUArX5",
        "row": 17838,
        "state": "",
        "group": "OH2637M003",
        "group_num": [
            "rec7jVPHeyQ7Saftp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 63,
        "id": "reczkpQI2EqY6MFPR",
        "row": 17874,
        "state": "",
        "group": "",
        "group_num": [
            "recV4LzuSxuNYjS83"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recDAItHzNHGgeYor",
        "row": 17872,
        "state": "",
        "group": "212012P3AP",
        "group_num": [
            "rec0uU78I8fHW4ZiC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recEuDTFXFdpRHVkd",
        "row": 17869,
        "state": "",
        "group": "213064M6NA",
        "group_num": [
            "recQCsTDAvryIxjBI"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recGxg0EYnkI0z4sG",
        "row": 17868,
        "state": "",
        "group": "213064M6NA",
        "group_num": [
            "recQCsTDAvryIxjBI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "id": "recIM3iL78cpGqbqh",
        "row": 17849,
        "state": "",
        "group": "W42781M001",
        "group_num": [
            "reckehoDBHehBMaat"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recIiHf2SHhnnZMfd",
        "row": 17854,
        "state": "",
        "group": "213051M1FA",
        "group_num": [
            "recdOaGNm5EYluoMv"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5151,
        "2023": 1869,
        "id": "recM2Ac7udpbxBRcM",
        "row": 17879,
        "state": "",
        "group": "212069M1AF",
        "group_num": [
            "recrISaiLA1Og4mLN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "2024": 18,
        "id": "recMlKtua4X9vId99",
        "row": 17836,
        "state": "",
        "group": "",
        "group_num": [
            "rec33YBCE791Vpcz4",
            "rechi77yejkJcs0na"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2167,
        "id": "reclq55AIJW4BWJPD",
        "row": 17890,
        "state": "",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recnJdVyRMxloowKo",
        "row": 17887,
        "state": "",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1888,
        "id": "recnhiS6MKa9OPnMY",
        "row": 17885,
        "state": "",
        "group": "212085P1F2",
        "group_num": [
            "recWiMQR2f7LeFSqC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "id": "recQYB6ALgYNKtXGG",
        "row": 17897,
        "state": "",
        "group": "W51014M002",
        "group_num": [
            "recNtnX6TQg1AcUzc"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recU47a31lcwMlZyY",
        "row": 17892,
        "state": "",
        "group": "",
        "group_num": [
            "rec7s5K8A8XJysSBZ"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 243,
        "id": "recU7cHLP0x0OKECp",
        "row": 17888,
        "state": "",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2111,
        "id": "recVxOmcKn0kK67ln",
        "row": 17893,
        "state": "",
        "group": "",
        "group_num": [
            "rec7s5K8A8XJysSBZ"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1790,
        "id": "rec5JTR0PXHWnlR0m",
        "row": 17884,
        "state": "",
        "group": "212085P1F2",
        "group_num": [
            "recWiMQR2f7LeFSqC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 116,
        "2023": 297,
        "id": "recolgRKkXDoD9t11",
        "row": 17895,
        "state": "",
        "group": "",
        "group_num": [
            "rechhJ1ObparAl93U"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recwuCuF9hpykvpCY",
        "row": 17898,
        "state": "",
        "group": "212044M1DA",
        "group_num": [
            "rec4kUD4xl9FYHeQ0"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1750,
        "id": "recx9NlnsIWi8J6ud",
        "row": 17889,
        "state": "",
        "group": "270089M1WA",
        "group_num": [
            "recnhKvK8ovpkchUN"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 360,
        "id": "recyBXy8UV4cBqw1E",
        "row": 17886,
        "state": "",
        "group": "",
        "group_num": [
            "recImNeArks41dOzn"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recAYGRKtAX3SaYBW",
        "row": 17899,
        "state": "",
        "group": "212044M1DA",
        "group_num": [
            "rec4kUD4xl9FYHeQ0"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recCe7SoxvzfO81Wn",
        "row": 17894,
        "state": "",
        "group": "004009959EEAG00",
        "group_num": [
            "recVyY1lx6udMsnxj"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 980,
        "id": "recEKXC7Hwueqwvim",
        "row": 17891,
        "state": "",
        "group": "",
        "group_num": [
            "rec8UZw96v13ts1u5"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 39,
        "2023": 52,
        "id": "recIyB29ja6AKA72r",
        "row": 17896,
        "state": "",
        "group": "",
        "group_num": [
            "rechhJ1ObparAl93U"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF OH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 388,
        "id": "recf0ct5Li8Cmkbsf",
        "row": 17924,
        "state": "",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1700,
        "id": "rechnheWRjLaWBHc4",
        "row": 17919,
        "state": "",
        "group": "",
        "group_num": [
            "recm5wAqTHNp9F3MW"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "reci17gC8A3fIaYUf",
        "row": 17927,
        "state": "",
        "group": "VA3051M001",
        "group_num": [
            "recTI3khmT5KXcIAz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1865,
        "id": "reclH6ImhCGH0Zc4d",
        "row": 17925,
        "state": "",
        "group": "174587C1GA",
        "group_num": [
            "recUjXLWspw5mXj4p"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recOUG1Mu8UJ3MMTM",
        "row": 17902,
        "state": "",
        "group": "T7367AMA03",
        "group_num": [
            "recNk0P61LsaE2bTI"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 565,
        "id": "recO3xqxL5UHT9hdU",
        "row": 17900,
        "state": "",
        "group": "201075MGDG",
        "group_num": [
            "recR9u7WDJGIaJY5c"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1805,
        "id": "recOprc9bRvuu3YiQ",
        "row": 17922,
        "state": "",
        "group": "174587A1AA",
        "group_num": [
            "recPhX7YNj0Wp33ps"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1792,
        "id": "recPTH5wDRodnCPlz",
        "row": 17912,
        "state": "",
        "group": "201064HAFA",
        "group_num": [
            "recgZSxwypZCfNOoF"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recSLwdLbDH7WxgIa",
        "row": 17923,
        "state": "",
        "group": "174587A1AA",
        "group_num": [
            "recPhX7YNj0Wp33ps"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1372,
        "id": "recTH3XvZhebi8d3f",
        "row": 17907,
        "state": "",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recW673F95fQzZPpX",
        "row": 17930,
        "state": "",
        "group": "0B5KC3M039",
        "group_num": [
            "reckeRR2TqJxHOUUe"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2289,
        "id": "recX77OBFAOAWgmlp",
        "row": 17915,
        "state": "",
        "group": "",
        "group_num": [
            "rec4Uhf3qthjXp0Ph"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3219,
        "id": "recYJLysMm9Qr0qvR",
        "row": 17911,
        "state": "",
        "group": "201064HAFA",
        "group_num": [
            "recgZSxwypZCfNOoF"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recY2UA7lUK7CRhao",
        "row": 17932,
        "state": "",
        "group": "0B5KC3M039",
        "group_num": [
            "reckeRR2TqJxHOUUe"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recYjiFSETKRGhJ5S",
        "row": 17910,
        "state": "",
        "group": "201064CP4L",
        "group_num": [
            "recig7Oym8VcJyxlC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "id": "recZTaSjkTgnAG8Mk",
        "row": 17906,
        "state": "",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1773,
        "id": "rec0SzzVjWS4q8PKc",
        "row": 17921,
        "state": "",
        "group": "0A319BM001",
        "group_num": [
            "rec5qWRNpw0ftD2bu"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec8lFudbR8sSU8ju",
        "row": 17931,
        "state": "",
        "group": "0B5KC3M039",
        "group_num": [
            "reckeRR2TqJxHOUUe"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec9UxUI4wgTPXn3E",
        "row": 17903,
        "state": "",
        "group": "T7367AMA03",
        "group_num": [
            "recNk0P61LsaE2bTI"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 323,
        "id": "recan6CpPTetRm5Bn",
        "row": 17933,
        "state": "",
        "group": "00D668M100",
        "group_num": [
            "recpwn4Cv0UvLGGN6"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recqAf9DZd6A8pKx9",
        "row": 17916,
        "state": "",
        "group": "",
        "group_num": [
            "recEfukkMw6PYQPyp"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recrkj0il7Ya6ry5T",
        "row": 17928,
        "state": "",
        "group": "VA3051M001",
        "group_num": [
            "recTI3khmT5KXcIAz"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3107,
        "id": "recwAqiqzkCpoIeIY",
        "row": 17904,
        "state": "",
        "group": "201028M1PA",
        "group_num": [
            "rec5quPkXyZuPMSOC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1932,
        "id": "recxy44cyTODYZdT2",
        "row": 17908,
        "state": "",
        "group": "201028M1PH",
        "group_num": [
            "recOotkjhiE0IQnHw"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2309,
        "2023": 3353,
        "id": "recxzbO0InKajxWm2",
        "row": 17901,
        "state": "",
        "group": "201075MGDG",
        "group_num": [
            "recR9u7WDJGIaJY5c"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "recxihTKjGeDoQWBJ",
        "row": 17920,
        "state": "",
        "group": "0A319BM001",
        "group_num": [
            "rec5qWRNpw0ftD2bu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2552,
        "id": "recF78D54LZk6Oq4h",
        "row": 17926,
        "state": "",
        "group": "VA2094M011",
        "group_num": [
            "recolXCz1Li1ju21S"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3279,
        "id": "recF7qxs0GTYuir0v",
        "row": 17909,
        "state": "",
        "group": "201064CP4L",
        "group_num": [
            "recig7Oym8VcJyxlC"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1659,
        "id": "recGQ5RaHctARm0iD",
        "row": 17918,
        "state": "",
        "group": "JVA002M042",
        "group_num": [
            "recoZDszkShrcJv4M"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 575,
        "id": "recIzqa4U8ocRkOBN",
        "row": 17913,
        "state": "",
        "group": "201044M1A1",
        "group_num": [
            "recH1I5Yr0aCE46FX"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4144,
        "id": "recJvBWmqnock8j0r",
        "row": 17905,
        "state": "",
        "group": "201028M1PA",
        "group_num": [
            "rec5quPkXyZuPMSOC"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1404,
        "2024": 656,
        "id": "recKs0KkaEGmmUMBw",
        "row": 17929,
        "state": "",
        "group": "",
        "group_num": [
            "recf1h5Tdr63vAmKG"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 192,
        "id": "recLc6IpZKsOKwysl",
        "row": 17917,
        "state": "",
        "group": "",
        "group_num": [
            "recEfukkMw6PYQPyp"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1292,
        "id": "recMx0vPIG4NeqDwd",
        "row": 17914,
        "state": "",
        "group": "",
        "group_num": [
            "rec4Uhf3qthjXp0Ph"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1133,
        "id": "recgzBRvh9pA5uH7o",
        "row": 17938,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 297,
        "id": "recgEcIwU1SFS9eNH",
        "row": 17945,
        "state": "",
        "group": "",
        "group_num": [
            "recxorwwqNqJHdSRy"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recgOIB1bzTs3KvJK",
        "row": 17941,
        "state": "",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 39,
        "id": "recioUJQQeqWZPcwO",
        "row": 17935,
        "state": "",
        "group": "",
        "group_num": [
            "recDSdSX9PveRlMGf"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recjgrws8HPBg0nh8",
        "row": 17934,
        "state": "",
        "group": "00D668M100",
        "group_num": [
            "recpwn4Cv0UvLGGN6"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "reckxda1nADZwxXw1",
        "row": 17942,
        "state": "",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5157,
        "id": "recOFB0dg8AMcG7rR",
        "row": 17956,
        "state": "",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2401,
        "id": "recRfjpX1KSdVXIrk",
        "row": 17955,
        "state": "",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recZMlu99NDEfd8pO",
        "row": 17953,
        "state": "",
        "group": "",
        "group_num": [
            "recpDmXietngjJYWz"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "rec1e7CM46aGY8oQq",
        "row": 17943,
        "state": "",
        "group": "M11092",
        "group_num": [
            "recxBIhZ6njKemXgg"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "2023": 565,
        "id": "rec3J7rAeDQ4MIa9H",
        "row": 17954,
        "state": "",
        "group": "201062M5A2",
        "group_num": [
            "recbKGQ2mqsarXlOp"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rec8HtBOijE1SbqvV",
        "row": 17946,
        "state": "",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec8nwXUWwR9v95gY",
        "row": 17948,
        "state": "",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 435,
        "2024": 435,
        "id": "rec9cIUjiL1m5b7Uo",
        "row": 17951,
        "state": "",
        "group": "000010M018",
        "group_num": [
            "rec1NHRcBOkZsjbZ9"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recb1aoSVfGnKVBl6",
        "row": 17944,
        "state": "",
        "group": "0A319BM001",
        "group_num": [
            "rec5qWRNpw0ftD2bu"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "recxAm4pHsvoePqM9",
        "row": 17940,
        "state": "",
        "group": "201052A2A1",
        "group_num": [
            "reciVUuuMe9NC9JKJ"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1974,
        "2023": 414,
        "id": "recxGt0Cwq8tPbAOH",
        "row": 17937,
        "state": "",
        "group": "201060M1A1",
        "group_num": [
            "recGmiWryl7lCTSEI"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 303,
        "id": "recyC7KfxKnOKkbdE",
        "row": 17947,
        "state": "",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1374,
        "id": "recAduriVpaj5NJWt",
        "row": 17952,
        "state": "",
        "group": "000010M018",
        "group_num": [
            "rec1NHRcBOkZsjbZ9"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 87,
        "id": "recHpIuKfElr7g4Xd",
        "row": 17936,
        "state": "",
        "group": "",
        "group_num": [
            "reco1bfuMBWTMhakY"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recJ126xoYOY8c0UE",
        "row": 17939,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recKkfIWIBTMxEnng",
        "row": 17949,
        "state": "",
        "group": "M16094",
        "group_num": [
            "recSR3zvFQQSKKG0t"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recMGifQMIDxQiXh0",
        "row": 17950,
        "state": "",
        "group": "",
        "group_num": [
            "rec15ECUibj2R4fXh"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 84,
        "2024": 84,
        "id": "receSK2uV3qMgbRzz",
        "row": 17966,
        "state": "",
        "group": "",
        "group_num": [
            "reckFHpR6wAXYo10C"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1804,
        "id": "recgfLvu5eJ7ikrNu",
        "row": 17971,
        "state": "",
        "group": "",
        "group_num": [
            "recoLY2f8aRCnTzNY"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 76,
        "id": "rechjqJZDo7KZ8UIL",
        "row": 17972,
        "state": "",
        "group": "",
        "group_num": [
            "recctGmVKK68HGz4T"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 930,
        "id": "recjYWVFA5LbxJ2Zv",
        "row": 17967,
        "state": "",
        "group": "",
        "group_num": [
            "recIJuLUiGRKo2a3W"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recjR2S720oD3ISWK",
        "row": 17977,
        "state": "",
        "group": "",
        "group_num": [
            "recSUo8yJtqgO2sqM"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "reckgsvvIbpt9cTI0",
        "row": 17978,
        "state": "",
        "group": "",
        "group_num": [
            "recht0vAhwZmY0yR8"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recSTRHg9wbEjWHGu",
        "row": 17964,
        "state": "",
        "group": "",
        "group_num": [
            "recveeZI7gVnuyDOO"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "2023": 170,
        "id": "recTPlgv45ar2znS6",
        "row": 17962,
        "state": "",
        "group": "",
        "group_num": [
            "recTpv38NC3IiER7D"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recUkK4usohLLrzRo",
        "row": 17958,
        "state": "",
        "group": "",
        "group_num": [
            "rec3rUuHqtkM9p99C"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 59,
        "id": "rec0I6KXHwrWl46Ep",
        "row": 17981,
        "state": "",
        "group": "",
        "group_num": [
            "recKOhnVfeuzpu5tL"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "id": "rec4De4V3MJDHURvg",
        "row": 17979,
        "state": "",
        "group": "",
        "group_num": [
            "rec0oKBSMa6i1gBZr"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "rec5zbV8nyfGqAGe1",
        "row": 17980,
        "state": "",
        "group": "",
        "group_num": [
            "recKOhnVfeuzpu5tL"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "id": "rec6NdM2BXQjZn9yU",
        "row": 17982,
        "state": "",
        "group": "",
        "group_num": [
            "rece1eUZphfYHb4GV"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "id": "rec9joYO0QUSBc1YN",
        "row": 17961,
        "state": "",
        "group": "",
        "group_num": [
            "recTpv38NC3IiER7D"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1962,
        "id": "recddB54TKORS4l2R",
        "row": 17970,
        "state": "",
        "group": "210043M1A4",
        "group_num": [
            "rechd8hu5BDHSjsfM"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "id": "recucmYcHttk0Dhza",
        "row": 17975,
        "state": "",
        "group": "",
        "group_num": [
            "recQww4vTeqOA9JpN"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 276,
        "id": "recvSHAZMT583cn5J",
        "row": 17976,
        "state": "",
        "group": "",
        "group_num": [
            "recv216xDCSoEDAas"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 70,
        "id": "recwhvxX7JG0TQv7W",
        "row": 17963,
        "state": "",
        "group": "",
        "group_num": [
            "recTpv38NC3IiER7D"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1577,
        "id": "recAwB5eLu71HfYfq",
        "row": 17957,
        "state": "",
        "group": "",
        "group_num": [
            "recnvmfS15ESb1Wi2"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recCzpvWCWMkNguAX",
        "row": 17960,
        "state": "",
        "group": "",
        "group_num": [
            "recEBHnPl05PQPDL4"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 494,
        "id": "recCCYugp02TIBQqe",
        "row": 17974,
        "state": "",
        "group": "",
        "group_num": [
            "recQww4vTeqOA9JpN"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 772,
        "id": "recFBL09W5AcPm4lP",
        "row": 17969,
        "state": "",
        "group": "",
        "group_num": [
            "rec6874V9kE0kFrJ2"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 480,
        "id": "recGZZEwkZjsQdV5e",
        "row": 17965,
        "state": "",
        "group": "",
        "group_num": [
            "reckFHpR6wAXYo10C"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 585,
        "id": "recJKU2SjgVNpae4d",
        "row": 17983,
        "state": "",
        "group": "",
        "group_num": [
            "recHdYKqzUHCJSTla"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "id": "recKtuDAPq7Qu9ZZX",
        "row": 17959,
        "state": "",
        "group": "",
        "group_num": [
            "rec3rUuHqtkM9p99C"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recKh4nTIAjPJqkk2",
        "row": 17973,
        "state": "",
        "group": "",
        "group_num": [
            "recQww4vTeqOA9JpN"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 452,
        "id": "recM3sx8q2EICv0pR",
        "row": 17968,
        "state": "",
        "group": "",
        "group_num": [
            "recIJuLUiGRKo2a3W"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 517,
        "id": "recfpTgsek5RXBmNY",
        "row": 17984,
        "state": "",
        "group": "",
        "group_num": [
            "recHdYKqzUHCJSTla"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "reckAvQ8WM3AX0JRc",
        "row": 18001,
        "state": "",
        "group": "",
        "group_num": [
            "receW7oyBTORJqDBh"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recnPJRvXbq1NMdZF",
        "row": 17999,
        "state": "",
        "group": "",
        "group_num": [
            "receX6chikC8200es"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 59,
        "id": "recRyOZ5JolrZptf0",
        "row": 17988,
        "state": "",
        "group": "",
        "group_num": [
            "recx1bj88v8EZ7ble"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 998,
        "id": "recYYmqBRgIsjxp9T",
        "row": 17989,
        "state": "",
        "group": "",
        "group_num": [
            "reck7JT5Y6HbSZDSt"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2290,
        "id": "recY11ceLfJErKBpT",
        "row": 17997,
        "state": "",
        "group": "210029M2AA",
        "group_num": [
            "rec0yKlxkxY3Yf8Qn"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1100,
        "id": "rec3ekJOZr739FTH6",
        "row": 17994,
        "state": "",
        "group": "",
        "group_num": [
            "recz90JDEy78bw6mm"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 438,
        "id": "rec5EPm0biO7TJxaM",
        "row": 17993,
        "state": "",
        "group": "",
        "group_num": [
            "recsFo0geRcbqQBlY"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recb0CIZVSMdip0P9",
        "row": 17996,
        "state": "",
        "group": "",
        "group_num": [
            "recz90JDEy78bw6mm"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recbMb97h28T1S4XT",
        "row": 18000,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 938,
        "id": "reccj4yfr1RGGNOhm",
        "row": 17992,
        "state": "",
        "group": "",
        "group_num": [
            "recsFo0geRcbqQBlY"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "id": "recq71M2xxzVOuEGH",
        "row": 17987,
        "state": "",
        "group": "",
        "group_num": [
            "recx1bj88v8EZ7ble"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1700,
        "2024": 1700,
        "id": "rectgNxSOFr56GGhC",
        "row": 17995,
        "state": "",
        "group": "",
        "group_num": [
            "recz90JDEy78bw6mm"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1655,
        "2023": 1670,
        "id": "recuW1rjSkl4O4kJt",
        "row": 18004,
        "state": "",
        "group": "",
        "group_num": [
            "recG4cE1zo9D7Hx63"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "2023": 454,
        "id": "reczAHNlIfpRVllTZ",
        "row": 17991,
        "state": "",
        "group": "",
        "group_num": [
            "reck7JT5Y6HbSZDSt"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recz5EB0WtH90OMmL",
        "row": 18002,
        "state": "",
        "group": "",
        "group_num": [
            "receW7oyBTORJqDBh"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "id": "recEs3JRe3GKM2twg",
        "row": 18005,
        "state": "",
        "group": "",
        "group_num": [
            "recG4cE1zo9D7Hx63"
        ],
        "procedure": "OP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4958,
        "id": "recFT4ZZJStdmohEe",
        "row": 17986,
        "state": "",
        "group": "W80533M011",
        "group_num": [
            "recki0fzlgsm2kpJs"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2308,
        "id": "recFeTOzPanVk1utJ",
        "row": 17998,
        "state": "",
        "group": "210029M2AA",
        "group_num": [
            "rec0yKlxkxY3Yf8Qn"
        ],
        "procedure": "Detox",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3353,
        "id": "recGj2omJM8u2gefP",
        "row": 17985,
        "state": "",
        "group": "W80533M011",
        "group_num": [
            "recki0fzlgsm2kpJs"
        ],
        "procedure": "RTC",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2434,
        "id": "recH0eAwMLnaNGWtQ",
        "row": 18003,
        "state": "",
        "group": "",
        "group_num": [
            "recG4cE1zo9D7Hx63"
        ],
        "procedure": "PHP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 533,
        "id": "recI17bSeaMxt1QgR",
        "row": 17990,
        "state": "",
        "group": "",
        "group_num": [
            "reck7JT5Y6HbSZDSt"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 113,
        "id": "recKoCBUa3263dPKn",
        "row": 18006,
        "state": "",
        "group": "",
        "group_num": [
            "recrzAKfEvb1cxQ7N"
        ],
        "procedure": "IOP",
        "provider": "ANTHEM BCBS OF WI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3600,
        "id": "recxfSlaZHw0ZxMSh",
        "row": 21737,
        "state": "",
        "group": "",
        "group_num": [
            "recYxvaRh7nfQ5lhi"
        ],
        "procedure": "Detox",
        "provider": "APEX HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1350,
        "id": "recye40cZb9L7H069",
        "row": 21738,
        "state": "",
        "group": "",
        "group_num": [
            "recUbR2MRu9eQoAup"
        ],
        "procedure": "RTC",
        "provider": "ARISE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2421,
        "id": "recKMCZoMVetMdruW",
        "row": 21739,
        "state": "",
        "group": "ACNCC016",
        "group_num": [
            "recrrNiHq1Ouej9GM"
        ],
        "procedure": "RTC",
        "provider": "ARIZONA CARE NETWORK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 250,
        "id": "recVZeAsukomdNf3i",
        "row": 21740,
        "state": "",
        "group": "",
        "group_num": [
            "recigtj9u5lSCX9G9"
        ],
        "procedure": "RTC",
        "provider": "ARP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "id": "reco4Kr3t27TIOEzK",
        "row": 21741,
        "state": "",
        "group": "",
        "group_num": [
            "recigtj9u5lSCX9G9"
        ],
        "procedure": "PHP",
        "provider": "ARP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recyFJF5id8n3QfFU",
        "row": 21742,
        "state": "",
        "group": "1060",
        "group_num": [
            "recAbjuBizJCKIbbJ"
        ],
        "procedure": "Detox",
        "provider": "ASR HEALTH BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "recInFpGjDaqeRWmC",
        "row": 21743,
        "state": "",
        "group": "",
        "group_num": [
            "recigtj9u5lSCX9G9"
        ],
        "procedure": "IOP",
        "provider": "ASSISTANCE RECOVERY PROGRAM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 364,
        "id": "recT5I20LojfxayxL",
        "row": 21744,
        "state": "",
        "group": "I002730",
        "group_num": [
            "recx2zAtQiikp9Wq8"
        ],
        "procedure": "RTC",
        "provider": "AULTCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 625,
        "id": "recM8qox8O1QLhhCv",
        "row": 21745,
        "state": "",
        "group": "I002730",
        "group_num": [
            "recx2zAtQiikp9Wq8"
        ],
        "procedure": "Detox",
        "provider": "AULTCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5003,
        "id": "rec1UoBOimcvt2K7t",
        "row": 21746,
        "state": "",
        "group": "SDMP22",
        "group_num": [
            "reciEVvs9MrRpSKHx"
        ],
        "procedure": "RTC",
        "provider": "AVERA HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "reclz79NlMiqva7Nx",
        "row": 18017,
        "state": "",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "Detox",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recnXgkN45ZxqYLll",
        "row": 18011,
        "state": "",
        "group": "10020590",
        "group_num": [
            "recoLfG97EOikfB7A"
        ],
        "procedure": "Detox",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recOBooQl9mUMKJby",
        "row": 18015,
        "state": "",
        "group": "10036302",
        "group_num": [
            "recLvfoqM0jUQa7bE"
        ],
        "procedure": "OP",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recTP1MKzCIIDhqa4",
        "row": 18010,
        "state": "",
        "group": "10020590",
        "group_num": [
            "recoLfG97EOikfB7A"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "rec1dP0Gy9HriIKJk",
        "row": 18009,
        "state": "",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "Detox",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 21,
        "id": "rec1j1jU0vECnNEbV",
        "row": 18012,
        "state": "",
        "group": "10020590",
        "group_num": [
            "recoLfG97EOikfB7A"
        ],
        "procedure": "OP",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4354,
        "id": "rec7D5R6ouHuF3rpS",
        "row": 18016,
        "state": "",
        "group": "10036166",
        "group_num": [
            "recyxFJB5m8mMbHwk"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 888,
        "id": "rec8Y3Bdu6draRl6t",
        "row": 18008,
        "state": "",
        "group": "10021744",
        "group_num": [
            "rec9JDUS72khKWtKc"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "reccPEoriuHo2VUTi",
        "row": 18007,
        "state": "",
        "group": "10040000",
        "group_num": [
            "recXQ4zraykbMB70Q"
        ],
        "procedure": "RTC",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 258,
        "id": "recrCmkLMumywjqYq",
        "row": 18014,
        "state": "",
        "group": "10036302",
        "group_num": [
            "recLvfoqM0jUQa7bE"
        ],
        "procedure": "IOP",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recEP7zkuPsVSyrds",
        "row": 18013,
        "state": "",
        "group": "",
        "group_num": [
            "recjOYeEFJrqlB4B9"
        ],
        "procedure": "IOP",
        "provider": "BC OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 292,
        "id": "recilJabf8EU8yV4I",
        "row": 18032,
        "state": "",
        "group": "",
        "group_num": [
            "recuft0mhLi7QakGJ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 378,
        "id": "recOc7lndwrYCN53A",
        "row": 18031,
        "state": "",
        "group": "",
        "group_num": [
            "recuft0mhLi7QakGJ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 351,
        "id": "rec0QnN6u5YxPT3id",
        "row": 18024,
        "state": "",
        "group": "",
        "group_num": [
            "rechi5DowDbrpSBGO"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 463,
        "id": "rec3m04Udb9jmq7WS",
        "row": 18023,
        "state": "",
        "group": "",
        "group_num": [
            "rec5g5Fljvx5PZirb"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 659,
        "id": "rec7GL3QHgF1FijFS",
        "row": 18026,
        "state": "",
        "group": "0009148A01",
        "group_num": [
            "recDvdLJaoredHSuM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 378,
        "id": "recavVJcrUrTpGhhL",
        "row": 18020,
        "state": "",
        "group": "",
        "group_num": [
            "recnaQo3LfP8LmsFj"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 331,
        "2024": 323,
        "id": "recbykECSga6GX6FL",
        "row": 18029,
        "state": "",
        "group": "",
        "group_num": [
            "recomkjwcFWZhCbs5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 333,
        "2024": 248,
        "id": "recqmtMU8g5naRE4T",
        "row": 18019,
        "state": "",
        "group": "",
        "group_num": [
            "recnaQo3LfP8LmsFj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 77,
        "id": "recr4arzIPJRCX1s5",
        "row": 18027,
        "state": "",
        "group": "",
        "group_num": [
            "recQAhCFsHOlDnfCP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 530,
        "id": "recs5ho6iL95ZCQEE",
        "row": 18022,
        "state": "",
        "group": "0009196400",
        "group_num": [
            "receg0w7J0v8qxvTX"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recxPkeo3dleLBJWn",
        "row": 18033,
        "state": "",
        "group": "",
        "group_num": [
            "recYWwnhyBJfOil4e"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 343,
        "id": "recxh8DiwzRlDeJjb",
        "row": 18028,
        "state": "",
        "group": "",
        "group_num": [
            "recomkjwcFWZhCbs5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 430,
        "2024": 464,
        "id": "recEPmyEZyagfGInn",
        "row": 18018,
        "state": "",
        "group": "",
        "group_num": [
            "recDOyZbfgM6yo2n2"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 388,
        "id": "recG62G5t0TCry1wG",
        "row": 18021,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recMpWyelERogfTRW",
        "row": 18025,
        "state": "",
        "group": "",
        "group_num": [
            "rechi5DowDbrpSBGO"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 23,
        "id": "recNQ1PCHfcKJcTRr",
        "row": 18030,
        "state": "",
        "group": "",
        "group_num": [
            "recomkjwcFWZhCbs5"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 389,
        "2024": 464,
        "id": "recjXbVYBrv8oJgNn",
        "row": 18039,
        "state": "",
        "group": "",
        "group_num": [
            "recEP7XWmZAlTOASr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "id": "recmVkyc9QnLruUTW",
        "row": 18037,
        "state": "",
        "group": "0092851000",
        "group_num": [
            "recIFbWgBARAPMXlD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 285,
        "id": "recPCT0LHxUzafGfv",
        "row": 18035,
        "state": "",
        "group": "",
        "group_num": [
            "reca7JvUl1tnd0wXX"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 710,
        "id": "rec3lD16aqmXRc3bz",
        "row": 18038,
        "state": "",
        "group": "",
        "group_num": [
            "recoYcyovi035OLWE"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 146,
        "2024": 232,
        "id": "recrDVgzam7rRe9J8",
        "row": 18040,
        "state": "",
        "group": "",
        "group_num": [
            "recEP7XWmZAlTOASr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 24,
        "id": "rectWlbiScVW0zhGy",
        "row": 18034,
        "state": "",
        "group": "",
        "group_num": [
            "recYWwnhyBJfOil4e"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "id": "recyc5gXr8tJk9940",
        "row": 18036,
        "state": "",
        "group": "",
        "group_num": [
            "reca7JvUl1tnd0wXX"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1218,
        "id": "recGZmIKjp60HW0gK",
        "row": 18041,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recmZNIS6o4etu38C",
        "row": 18042,
        "state": "",
        "group": "",
        "group_num": [
            "recPU2gZ2r3e5cuQG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 726,
        "id": "recRNhnxoxMgRSiG6",
        "row": 18047,
        "state": "",
        "group": "HS00100009",
        "group_num": [
            "recyk8AqSDENCeNVq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "id": "recVKpmpgOgTSGesj",
        "row": 18046,
        "state": "",
        "group": "HS00100009",
        "group_num": [
            "recyk8AqSDENCeNVq"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recYcRnyxSehpGu1m",
        "row": 18048,
        "state": "",
        "group": "EG01100001",
        "group_num": [
            "recga4ga0Vp6PfYGQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "rec44RNYnhABTxUU6",
        "row": 18045,
        "state": "",
        "group": "",
        "group_num": [
            "recF06Uvv0QQcQfVO"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 935,
        "id": "recbl8jtolkH6Ejlx",
        "row": 18043,
        "state": "",
        "group": "",
        "group_num": [
            "recPU2gZ2r3e5cuQG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "reccWZhrggqNPE3h7",
        "row": 18044,
        "state": "",
        "group": "",
        "group_num": [
            "recF06Uvv0QQcQfVO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 297,
        "id": "rectzY2fuoXRIeKcV",
        "row": 18049,
        "state": "",
        "group": "EG01100001",
        "group_num": [
            "recga4ga0Vp6PfYGQ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "id": "recyF20lbyY0tQQWi",
        "row": 18051,
        "state": "",
        "group": "ES00100023",
        "group_num": [
            "recBf9VzKXOUPooBA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 100,
        "id": "recKdPoZkaA58oq85",
        "row": 18050,
        "state": "",
        "group": "EG01100001",
        "group_num": [
            "recga4ga0Vp6PfYGQ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 38,
        "id": "recgB1aUSb751krHL",
        "row": 18063,
        "state": "",
        "group": "",
        "group_num": [
            "rec9SVuPq9u2YqCgA"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "id": "recicY8qp8eWapxhR",
        "row": 18075,
        "state": "",
        "group": "",
        "group_num": [
            "recbW94sYXR6jTDS2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recnVX6E0yK2YNWX7",
        "row": 18078,
        "state": "",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 317,
        "id": "recnOHnMHb5DYGCJu",
        "row": 18072,
        "state": "",
        "group": "",
        "group_num": [
            "recokHySEHuaqo0jU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "2024": 248,
        "id": "recPjA6EVbvaqaQ0L",
        "row": 18061,
        "state": "",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recRF0qpItjszOZTR",
        "row": 18076,
        "state": "",
        "group": "",
        "group_num": [
            "recbW94sYXR6jTDS2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "id": "recR9kWR7Nj2d950d",
        "row": 18081,
        "state": "",
        "group": "036436",
        "group_num": [
            "rec00VQjXqaaosjBD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3037,
        "2023": 502,
        "id": "recUqTi4xoKQCZ7g4",
        "row": 18055,
        "state": "",
        "group": "EMH222",
        "group_num": [
            "recRZ4rx0GeH1I6ZX"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2750,
        "id": "recVC1DuAMFkAmE2l",
        "row": 18053,
        "state": "",
        "group": "",
        "group_num": [
            "rechyTurCuv2QbSO5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1020,
        "id": "recXehx9wYijzRqLX",
        "row": 18079,
        "state": "",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 766,
        "2023": 800,
        "id": "rec0OLOZ9yzobfTa0",
        "row": 18083,
        "state": "",
        "group": "030906",
        "group_num": [
            "recqqxBJR8GKVLaCJ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "2024": 248,
        "id": "rec2xtmlet4fJi3Ar",
        "row": 18064,
        "state": "",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "rec2JmTn6OzH2wSAH",
        "row": 18080,
        "state": "",
        "group": "036436",
        "group_num": [
            "rec00VQjXqaaosjBD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1292,
        "2024": 4750,
        "id": "rec4mxA1uOyvo6nQ0",
        "row": 18056,
        "state": "",
        "group": "FMC001",
        "group_num": [
            "recDqK3CyZEre6Tkr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1208,
        "2023": 5125,
        "id": "recaoH1PCufHghxoZ",
        "row": 18057,
        "state": "",
        "group": "FMC001",
        "group_num": [
            "recDqK3CyZEre6Tkr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "id": "recdvQmEojuOtGLQP",
        "row": 18066,
        "state": "",
        "group": "022729",
        "group_num": [
            "recjgprP7dJuFUVmj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recqAKtiZcsw8ml0K",
        "row": 18070,
        "state": "",
        "group": "0BPM03",
        "group_num": [
            "reci0IUUK6S0qdJxD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recqNIXsnCegYAav4",
        "row": 18062,
        "state": "",
        "group": "000676M100",
        "group_num": [
            "rece6Zq9fCLKB5OHG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 575,
        "id": "rectnbkrqmrAf5T20",
        "row": 18071,
        "state": "",
        "group": "",
        "group_num": [
            "recxt7Bx6odZkcW0S"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recupISkHOHGL3Ae1",
        "row": 18077,
        "state": "",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recCY0UEodvu02Ppa",
        "row": 18059,
        "state": "",
        "group": "GIC001",
        "group_num": [
            "recsbNGYllUw5NNkX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "recC42zbxNoIwqtzz",
        "row": 18074,
        "state": "",
        "group": "",
        "group_num": [
            "recsmU3JPJ6McLiJo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recCmp3J5erLXNLJn",
        "row": 18073,
        "state": "",
        "group": "",
        "group_num": [
            "recokHySEHuaqo0jU"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "id": "recDfmWOQD2jTyJsn",
        "row": 18082,
        "state": "",
        "group": "038941",
        "group_num": [
            "recfH7Gmh9sgKUt96"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "id": "recE6ChC7NjnAatYt",
        "row": 18068,
        "state": "",
        "group": "0BPM03",
        "group_num": [
            "reci0IUUK6S0qdJxD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 760,
        "id": "recFS2zwHj9hS2dnJ",
        "row": 18058,
        "state": "",
        "group": "",
        "group_num": [
            "reclqlC9TbZbZEKrk"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 510,
        "id": "recJesksJ9gCVeXwG",
        "row": 18054,
        "state": "",
        "group": "EMH222",
        "group_num": [
            "recRZ4rx0GeH1I6ZX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 151,
        "id": "recKjekatm8AazjV8",
        "row": 18065,
        "state": "",
        "group": "270094M1HA",
        "group_num": [
            "rec3z58NTfNDwB0Ls"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recLwGJuWdk9LdMSz",
        "row": 18060,
        "state": "",
        "group": "FMC001",
        "group_num": [
            "recDqK3CyZEre6Tkr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "id": "recMRlFgJcf5YIWFu",
        "row": 18052,
        "state": "",
        "group": "TCR001",
        "group_num": [
            "recv693IezCcROygW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recM5vtgbMPItN4ym",
        "row": 18067,
        "state": "",
        "group": "022729",
        "group_num": [
            "recjgprP7dJuFUVmj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recMaTIo9lpVtMWfM",
        "row": 18069,
        "state": "",
        "group": "0BPM03",
        "group_num": [
            "reci0IUUK6S0qdJxD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recejxKV1oJOVezNH",
        "row": 18086,
        "state": "",
        "group": "",
        "group_num": [
            "recSHnm66ILyXTDXi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recfwUni38A2VKx2f",
        "row": 18102,
        "state": "",
        "group": "",
        "group_num": [
            "recZrTn81R5tE6W6S"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1854,
        "id": "recfDMytAFPrqConT",
        "row": 18104,
        "state": "",
        "group": "",
        "group_num": [
            "recYCjQzOnhxdZV6D"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recO0svYLfc8RRhcd",
        "row": 18091,
        "state": "",
        "group": "",
        "group_num": [
            "rectQ0MJLpn5SgD5P"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "2023": 414,
        "id": "recOsS3rrTBiN4lhA",
        "row": 18097,
        "state": "",
        "group": "038865",
        "group_num": [
            "recD4aH8f2hXrkvyq"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "id": "recSy1uFJA035Yozo",
        "row": 18088,
        "state": "",
        "group": "",
        "group_num": [
            "recP4T4KNFP5q6him"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 478,
        "id": "recSGPEbZZQqVuURy",
        "row": 18101,
        "state": "",
        "group": "231189",
        "group_num": [
            "rec4m6NNfqbrSc54D"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "2024": 43,
        "id": "recW8RXZHmie1OCiO",
        "row": 18089,
        "state": "",
        "group": "",
        "group_num": [
            "recP4T4KNFP5q6him"
        ],
        "procedure": "OP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 570,
        "id": "rec34CxPwfFHHWZjk",
        "row": 18098,
        "state": "",
        "group": "",
        "group_num": [
            "rec3YSDlyE2pOaDYT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "id": "rec3jLfkK91DlNJt4",
        "row": 18090,
        "state": "",
        "group": "",
        "group_num": [
            "rectQ0MJLpn5SgD5P"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "reccsr4nKIXMVslCN",
        "row": 18096,
        "state": "",
        "group": "038023",
        "group_num": [
            "recbcovalY01Mt1kD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "reccrm1VYgsPb0Ryq",
        "row": 18084,
        "state": "",
        "group": "030906",
        "group_num": [
            "recqqxBJR8GKVLaCJ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "reco7vN0xAmtCFGB1",
        "row": 18087,
        "state": "",
        "group": "",
        "group_num": [
            "recP4T4KNFP5q6him"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "rectYHhmu493SJZAe",
        "row": 18100,
        "state": "",
        "group": "",
        "group_num": [
            "rec3YSDlyE2pOaDYT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recv3yRshZ36YS6PU",
        "row": 18095,
        "state": "",
        "group": "",
        "group_num": [
            "recTTPT2BNaqw27tz"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "recy0iKUlTsobCaBr",
        "row": 18099,
        "state": "",
        "group": "",
        "group_num": [
            "rec3YSDlyE2pOaDYT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recHIfGHnhD0ZCKsR",
        "row": 18093,
        "state": "",
        "group": "034873",
        "group_num": [
            "recpJy9He11B00Afx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 570,
        "id": "recIZOikP2dnj6kZU",
        "row": 18085,
        "state": "",
        "group": "",
        "group_num": [
            "reczBoh5owQ4LbLYe"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "id": "recKVIrNmtSfGv0Eq",
        "row": 18094,
        "state": "",
        "group": "",
        "group_num": [
            "recTTPT2BNaqw27tz"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recKyyWlPLf5BMHjV",
        "row": 18103,
        "state": "",
        "group": "039645",
        "group_num": [
            "recy4GUqPxvM4UdOX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 755,
        "id": "recLvWAQkxbjnhq1H",
        "row": 18092,
        "state": "",
        "group": "",
        "group_num": [
            "recPq4vAE6rxda2Ba"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF AZ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recmIvvXoSVntWQHi",
        "row": 18108,
        "state": "",
        "group": "828691",
        "group_num": [
            "reclQWHNdvZOFBJcu"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF HI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "id": "recTF9aDO0zq1FpoO",
        "row": 18107,
        "state": "",
        "group": "828691",
        "group_num": [
            "reclQWHNdvZOFBJcu"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF HI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "id": "recbebxJvA857crAA",
        "row": 18105,
        "state": "",
        "group": "9976721",
        "group_num": [
            "recCa0sKC4JgQOeJp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF HI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 302,
        "id": "recs9BUdGd0Bq6F7I",
        "row": 18106,
        "state": "",
        "group": "9976721",
        "group_num": [
            "recCa0sKC4JgQOeJp"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF HI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 44,
        "2024": 44,
        "id": "receIfBPksNcAgC3B",
        "row": 18120,
        "state": "",
        "group": "",
        "group_num": [
            "recuGTwM5cn5n36xN"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 960,
        "id": "rechnU2gnEVv8QaQb",
        "row": 18133,
        "state": "",
        "group": "7NUW60",
        "group_num": [
            "recdUUd307ivPu3SM"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reciI31c3zaKxZbt9",
        "row": 18123,
        "state": "",
        "group": "",
        "group_num": [
            "recaMWt2WDfpyJJNG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "recjg7yucd8xQfSll",
        "row": 18128,
        "state": "",
        "group": "7NUS00",
        "group_num": [
            "recw79VUR257lLbuw"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1710,
        "id": "recOLnfbDiBHBOH6f",
        "row": 18131,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3750,
        "id": "recPiHRFvelN0Akn4",
        "row": 18121,
        "state": "",
        "group": "",
        "group_num": [
            "recH3PPPX6HeVx7Wr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recYYvU4NqydJe0GC",
        "row": 18116,
        "state": "",
        "group": "",
        "group_num": [
            "rec8ULcoSBILDKnml"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "id": "recZsvElc0O107dqL",
        "row": 18130,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recZfoq5RPUmR60xJ",
        "row": 18117,
        "state": "",
        "group": "015331",
        "group_num": [
            "recXeM24ZGZjKAeNi"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec1mrl3ZAbh4ayLC",
        "row": 18118,
        "state": "",
        "group": "",
        "group_num": [
            "recdE2l4wRNnjlyOF"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "2023": 826,
        "id": "rec2lbVCtXNV6BuZ5",
        "row": 18125,
        "state": "",
        "group": "",
        "group_num": [
            "recZpRgD1ZR7vp8Zd"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1546,
        "id": "rec7K14hpdT7WXcee",
        "row": 18112,
        "state": "",
        "group": "287824",
        "group_num": [
            "reck4A4P1xMl4RTK4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "rec8w4Nmtt2wbMtkl",
        "row": 18127,
        "state": "",
        "group": "7NUS00",
        "group_num": [
            "recw79VUR257lLbuw"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "reca6T8D1r79XG6j8",
        "row": 18122,
        "state": "",
        "group": "",
        "group_num": [
            "recaMWt2WDfpyJJNG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "2023": 47,
        "2024": 47,
        "id": "recdyl5Fnkkxa7mol",
        "row": 18126,
        "state": "",
        "group": "",
        "group_num": [
            "recZpRgD1ZR7vp8Zd"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recofzDCd5js5SocV",
        "row": 18119,
        "state": "",
        "group": "",
        "group_num": [
            "recuGTwM5cn5n36xN"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2347,
        "2023": 3188,
        "id": "recutJ416lM2VPpVp",
        "row": 18132,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recwO5nWnXfqBBcoI",
        "row": 18109,
        "state": "",
        "group": "",
        "group_num": [
            "recpUZ2qIPfSObZmE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 162,
        "id": "recxmc8xnIl3n7Rg1",
        "row": 18114,
        "state": "",
        "group": "287824",
        "group_num": [
            "reck4A4P1xMl4RTK4"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reczoym2I8HkK90Lz",
        "row": 18124,
        "state": "",
        "group": "",
        "group_num": [
            "recaMWt2WDfpyJJNG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recCDYj0aWwYAwd3A",
        "row": 18113,
        "state": "",
        "group": "287824",
        "group_num": [
            "reck4A4P1xMl4RTK4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1114,
        "id": "recFb7hGJtfBUDgvy",
        "row": 18115,
        "state": "",
        "group": "",
        "group_num": [
            "rec8ULcoSBILDKnml"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "id": "recHXQPYogZwujpyX",
        "row": 18129,
        "state": "",
        "group": "",
        "group_num": [
            "recRTJhXv7bSqqUpE"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1884,
        "id": "recIwD6uij9DzTh8U",
        "row": 18110,
        "state": "",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recKKFg3DpCz6vE1w",
        "row": 18111,
        "state": "",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recgTgh73f6bPFwoZ",
        "row": 18169,
        "state": "",
        "group": "169146",
        "group_num": [
            "recfrJRY72kdjV9Zy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recggacJzN6PYY93k",
        "row": 18135,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1074,
        "2023": 4395,
        "id": "rechUDRU6qKYU2JOU",
        "row": 18161,
        "state": "",
        "group": "050689",
        "group_num": [
            "recfZqwP1r2ZuZxdT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 512,
        "id": "reciI6ltQh08nqifV",
        "row": 18147,
        "state": "",
        "group": "P12709",
        "group_num": [
            "rec7CKMH8ORwFJkUW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 873,
        "2024": 900,
        "id": "reci2PIHE2zpv3yF3",
        "row": 18144,
        "state": "",
        "group": "",
        "group_num": [
            "recyvrdOhWMd0TTx9"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1315,
        "id": "recjbaE6BvWafnMxj",
        "row": 18156,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reckuaKdB34y8sApZ",
        "row": 18146,
        "state": "",
        "group": "282919",
        "group_num": [
            "recc7Pn3xbzUOu5IH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "reckE66TVj9bwxnbZ",
        "row": 18174,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "reckN7IRY41tftlry",
        "row": 18136,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 502,
        "id": "reckd9OTsXIJyCjha",
        "row": 18153,
        "state": "",
        "group": "053222",
        "group_num": [
            "rectW7FKo8EUPV3Pu"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "reclNM1LpztfO46e8",
        "row": 18142,
        "state": "",
        "group": "959647",
        "group_num": [
            "recgKzBbzGXkvZ543"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "2024": 525,
        "id": "recnRPkMntXhLDD39",
        "row": 18145,
        "state": "",
        "group": "282919",
        "group_num": [
            "recc7Pn3xbzUOu5IH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 675,
        "id": "recPPtO52YT62dG0X",
        "row": 18134,
        "state": "",
        "group": "7NUW60",
        "group_num": [
            "recdUUd307ivPu3SM"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1395,
        "id": "recQdW2GQv7pYxal8",
        "row": 18168,
        "state": "",
        "group": "",
        "group_num": [
            "reczFGPQOeM9Kl7p4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 927,
        "id": "recR9w3L34tH6rXuF",
        "row": 18170,
        "state": "",
        "group": "P41122",
        "group_num": [
            "recFF06q6UFVFQxut"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recT1bYi2bMmtf1UX",
        "row": 18176,
        "state": "",
        "group": "601720",
        "group_num": [
            "rec1c3xDPOWL8mwbg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recTOOh2cUttsgxjg",
        "row": 18159,
        "state": "",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recXIH1yDkLrawCBF",
        "row": 18178,
        "state": "",
        "group": "022240",
        "group_num": [
            "recZnzuJyM9kWqxsd"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1464,
        "id": "recZzVw9ONlN9ASdG",
        "row": 18140,
        "state": "",
        "group": "959607",
        "group_num": [
            "recpWA2EyVt3dfsuw"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 2795,
        "id": "rec12laFn8WeE0AM8",
        "row": 18149,
        "state": "",
        "group": "014407",
        "group_num": [
            "recJPvxWEbNjQDTJv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4146,
        "id": "rec4jH0Vh5FhUKgrx",
        "row": 18139,
        "state": "",
        "group": "",
        "group_num": [
            "rec4I0Q6ACkGzgetV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 774,
        "id": "rec6WsHx0WpOZwCMX",
        "row": 18155,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recaz7sfl0Cir3T88",
        "row": 18177,
        "state": "",
        "group": "601720",
        "group_num": [
            "rec1c3xDPOWL8mwbg"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 126,
        "id": "recbZYiAtqtFvfngt",
        "row": 18171,
        "state": "",
        "group": "P41122",
        "group_num": [
            "recFF06q6UFVFQxut"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1658,
        "id": "recbDja4NqrxvG5UA",
        "row": 18162,
        "state": "",
        "group": "191276",
        "group_num": [
            "rec0nF8AIk6VJDrIx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 493,
        "id": "recbNiEBAFmnsy1j6",
        "row": 18181,
        "state": "",
        "group": "PC3955",
        "group_num": [
            "recdO3tndnMB60EAy"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recbiqirMCTUODx1A",
        "row": 18179,
        "state": "",
        "group": "022240",
        "group_num": [
            "recZnzuJyM9kWqxsd"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 709,
        "id": "recdjENcoTG4rxFZt",
        "row": 18163,
        "state": "",
        "group": "088451",
        "group_num": [
            "reci9awGKByPNEmJ2"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "id": "recob0KACYlY5eQV2",
        "row": 18167,
        "state": "",
        "group": "376014",
        "group_num": [
            "reczgcjsnz7bDlBNC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1250,
        "id": "recrD3LTWqGQtMPIr",
        "row": 18173,
        "state": "",
        "group": "",
        "group_num": [
            "recoSKP42WcvvBvbv"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "2023": 218,
        "id": "recr6ygmiR4XMtBOT",
        "row": 18172,
        "state": "",
        "group": "",
        "group_num": [
            "recoSKP42WcvvBvbv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2409,
        "id": "recs9acaEtF7L5ZzN",
        "row": 18157,
        "state": "",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1410,
        "id": "recurkPG732dnRk4M",
        "row": 18148,
        "state": "",
        "group": "195500",
        "group_num": [
            "rec2LfvgFprxJtFbG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2950,
        "id": "recv408g18bJ7QkRv",
        "row": 18150,
        "state": "",
        "group": "014407",
        "group_num": [
            "recJPvxWEbNjQDTJv"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recweRW93lOUyMJwQ",
        "row": 18137,
        "state": "",
        "group": "",
        "group_num": [
            "rec4I0Q6ACkGzgetV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recBy7nTgfdyfVpKv",
        "row": 18154,
        "state": "",
        "group": "053222",
        "group_num": [
            "rectW7FKo8EUPV3Pu"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2950,
        "id": "recDU8lmzaP722IFV",
        "row": 18138,
        "state": "",
        "group": "",
        "group_num": [
            "rec4I0Q6ACkGzgetV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2337,
        "2023": 525,
        "id": "recD2KHo2FVu5tcb2",
        "row": 18175,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recETBt8s7XxVYUnp",
        "row": 18160,
        "state": "",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "2024": 1250,
        "id": "recFt5yCuURso2jTm",
        "row": 18164,
        "state": "",
        "group": "088451",
        "group_num": [
            "reci9awGKByPNEmJ2"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 869,
        "id": "recFzZJiQ7hFyxhsD",
        "row": 18143,
        "state": "",
        "group": "022258",
        "group_num": [
            "recP9k8SUgjdF8jrD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recFDNkSC6NIrFp32",
        "row": 18183,
        "state": "",
        "group": "",
        "group_num": [
            "rec87ac7UZJsjJZFG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 868,
        "2023": 387,
        "id": "recFNaH6Iq7CUalqx",
        "row": 18151,
        "state": "",
        "group": "014407",
        "group_num": [
            "recJPvxWEbNjQDTJv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recFbasnxKOSTJeDj",
        "row": 18158,
        "state": "",
        "group": "555681",
        "group_num": [
            "recv0n6IKASwaFHHL"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "id": "recJDRkqcoRJMOf0D",
        "row": 18180,
        "state": "",
        "group": "",
        "group_num": [
            "recdkG6CXqrZR1TVM"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "2024": 985,
        "id": "recKZNewLfiTsECFy",
        "row": 18182,
        "state": "",
        "group": "PC3955",
        "group_num": [
            "recdO3tndnMB60EAy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 558,
        "id": "recKBup1PoHoipdXa",
        "row": 18166,
        "state": "",
        "group": "376011",
        "group_num": [
            "recLqMgSgSZqCFt4b"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 266,
        "id": "recLQJgHiHR43YLWX",
        "row": 18152,
        "state": "",
        "group": "",
        "group_num": [
            "recMcqiz66xC5Q2Du"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recM1zMjeHuFjXeZu",
        "row": 18165,
        "state": "",
        "group": "088451",
        "group_num": [
            "reci9awGKByPNEmJ2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recMphSe6eQUqOMW6",
        "row": 18141,
        "state": "",
        "group": "959647",
        "group_num": [
            "recgKzBbzGXkvZ543"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 535,
        "id": "recePMBj3Jgclx0Zp",
        "row": 18208,
        "state": "",
        "group": "053256",
        "group_num": [
            "rec7cZaQ0goVSIob2"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 525,
        "id": "receQOaR9Gl9AK00S",
        "row": 18187,
        "state": "",
        "group": "",
        "group_num": [
            "recep9aEPpl8BY9OL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1274,
        "id": "recgJ7u22cUbn2HPy",
        "row": 18222,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1508,
        "2023": 862,
        "id": "rechdopmMAGOHjwxo",
        "row": 18223,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 814,
        "2023": 1043,
        "id": "reciGqmejsiUEqhTm",
        "row": 18232,
        "state": "",
        "group": "",
        "group_num": [
            "recc39lC6oD2UMKws"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "reciGI7Aj5yHsx9Dd",
        "row": 18206,
        "state": "",
        "group": "002318594",
        "group_num": [
            "recVjL8jdlOqi4YDf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recj3jOJsnKS21EK8",
        "row": 18227,
        "state": "",
        "group": "380001",
        "group_num": [
            "recb280zpDaN7LbBs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "id": "reckYn5DWrYC6bDI6",
        "row": 18233,
        "state": "",
        "group": "",
        "group_num": [
            "recc39lC6oD2UMKws"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2950,
        "id": "recPtGvKPJtr63jnY",
        "row": 18204,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recPvt11kA8tg0OQ1",
        "row": 18188,
        "state": "",
        "group": "016937",
        "group_num": [
            "recQIMQ9fELHi903m"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 525,
        "id": "recPMmZ30AgtiK66Q",
        "row": 18212,
        "state": "",
        "group": "P58478",
        "group_num": [
            "rec8DN1pooeYiOxfa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 528,
        "id": "recP3o4QqwcnaAgMM",
        "row": 18220,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 76,
        "id": "recPfNIKXtXaJbKgz",
        "row": 18202,
        "state": "",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "2024": 218,
        "id": "recSH3KKnNDB5VtGE",
        "row": 18211,
        "state": "",
        "group": "P58478",
        "group_num": [
            "rec8DN1pooeYiOxfa"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 229,
        "2023": 242,
        "id": "recSbeIj9EV5WEYAg",
        "row": 18186,
        "state": "",
        "group": "",
        "group_num": [
            "recep9aEPpl8BY9OL"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 236,
        "id": "recUv0SQ9niUpx5nd",
        "row": 18217,
        "state": "",
        "group": "",
        "group_num": [
            "recSTuUZVBekSU6zr"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recWIs1cdocZGiloB",
        "row": 18189,
        "state": "",
        "group": "016937",
        "group_num": [
            "recQIMQ9fELHi903m"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1188,
        "id": "rec0EyeEyjym7AYtI",
        "row": 18230,
        "state": "",
        "group": "41672613590",
        "group_num": [
            "rechot92CPlMOYZD2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 409,
        "id": "rec0im8CkG7AZoxXG",
        "row": 18221,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec13jKHPflHOfrto",
        "row": 18225,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 315,
        "2024": 2080,
        "id": "rec2x0TqWGxft3NKd",
        "row": 18198,
        "state": "",
        "group": "256916",
        "group_num": [
            "recGsOIB6KinEYszZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "rec2fKC9wPLV66s2e",
        "row": 18190,
        "state": "",
        "group": "016937",
        "group_num": [
            "recQIMQ9fELHi903m"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 873,
        "id": "rec4WjeZBweFr7rx9",
        "row": 18197,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 525,
        "id": "rec4tz3nCGhcZBGtG",
        "row": 18210,
        "state": "",
        "group": "",
        "group_num": [
            "rec7IlJZqwKFurZyz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 238,
        "id": "rec5UMW8GB5bBpzYD",
        "row": 18219,
        "state": "",
        "group": "",
        "group_num": [
            "recY7sA8OCefaX6FT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 948,
        "id": "rec8tlM4MITnzBEGU",
        "row": 18228,
        "state": "",
        "group": "380011",
        "group_num": [
            "recNEimVvZe3Th9Dz"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "rec9hAHfw8mG0Rcg7",
        "row": 18207,
        "state": "",
        "group": "053256",
        "group_num": [
            "rec7cZaQ0goVSIob2"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 493,
        "id": "rec9pt8akLjo20dED",
        "row": 18194,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 194,
        "id": "recbOEVs9Pf7seZZx",
        "row": 18205,
        "state": "",
        "group": "737248",
        "group_num": [
            "recHGo6qFOXgZXzSg"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "id": "recoNCIV9MLiVgS2X",
        "row": 18199,
        "state": "",
        "group": "256916",
        "group_num": [
            "recGsOIB6KinEYszZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 800,
        "id": "recscHakjnaLehMLK",
        "row": 18192,
        "state": "",
        "group": "",
        "group_num": [
            "recpEixmeaxhQeZom"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 323,
        "id": "recttK0XlJN0L82jB",
        "row": 18193,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 740,
        "2023": 843,
        "id": "recubVu324P9zm8Ie",
        "row": 18196,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 770,
        "id": "recvp169eV0Q3TMqF",
        "row": 18216,
        "state": "",
        "group": "",
        "group_num": [
            "recSTuUZVBekSU6zr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1043,
        "id": "recxcx0y1Yj7RYuiP",
        "row": 18201,
        "state": "",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2024": 313,
        "id": "recxp5Xa6W46eWPgi",
        "row": 18195,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 173,
        "id": "recyEqmKjPqDtm5VR",
        "row": 18231,
        "state": "",
        "group": "",
        "group_num": [
            "recc39lC6oD2UMKws"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1645,
        "id": "recyjqtf3f0A2zjAv",
        "row": 18229,
        "state": "",
        "group": "380011",
        "group_num": [
            "recNEimVvZe3Th9Dz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2329,
        "id": "recAw5D8CohAh0fOf",
        "row": 18213,
        "state": "",
        "group": "P38825",
        "group_num": [
            "recvNEWQPx3inbWeV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 580,
        "id": "recBjoMSnAepsTj0Q",
        "row": 18224,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1105,
        "id": "recFW83h8sbLzd3K2",
        "row": 18184,
        "state": "",
        "group": "",
        "group_num": [
            "recep9aEPpl8BY9OL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recFOJMA8MoGYi4tb",
        "row": 18214,
        "state": "",
        "group": "P38825",
        "group_num": [
            "recvNEWQPx3inbWeV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 76,
        "id": "recHHIeeaNnWStBsJ",
        "row": 18200,
        "state": "",
        "group": "256917",
        "group_num": [
            "recqWtQIYCw4DqD4s"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "id": "recHM8oYvhmsH9vZw",
        "row": 18203,
        "state": "",
        "group": "256922",
        "group_num": [
            "recZ520y8UEKxncG3"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "id": "recH8oaqhiuawvfY2",
        "row": 18185,
        "state": "",
        "group": "",
        "group_num": [
            "recep9aEPpl8BY9OL"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recJEWoP4nB2G8b3i",
        "row": 18226,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 204,
        "id": "recKBHkUe4ApEEpip",
        "row": 18215,
        "state": "",
        "group": "",
        "group_num": [
            "recSTuUZVBekSU6zr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recLEToJLqWBgolNN",
        "row": 18191,
        "state": "",
        "group": "",
        "group_num": [
            "recKbpJDmk6UDI1Po"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2862,
        "id": "recNvun6rb6AXwtCn",
        "row": 18209,
        "state": "",
        "group": "0G1111",
        "group_num": [
            "rectnhOQ6XPWYuXDH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 950,
        "id": "recNG2HX1kLfJgu3e",
        "row": 18218,
        "state": "",
        "group": "",
        "group_num": [
            "recY7sA8OCefaX6FT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 158,
        "id": "recf7DoE4OxaJjbYt",
        "row": 18265,
        "state": "",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 222,
        "id": "recfowfJwno1VBaJw",
        "row": 18238,
        "state": "",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1008,
        "id": "recmwAwhGfVUCD280",
        "row": 18250,
        "state": "",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1049,
        "id": "recn3VrdrhP2InzZu",
        "row": 18275,
        "state": "",
        "group": "0EV767",
        "group_num": [
            "rec1vwNiyhQglMYsH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recPGZUfBvkjhxI9X",
        "row": 18248,
        "state": "",
        "group": "",
        "group_num": [
            "recxODvJqY5VUbktS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recRkwOeCqtabeNkm",
        "row": 18240,
        "state": "",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 975,
        "id": "recSUphgUEZKNXZLd",
        "row": 18279,
        "state": "",
        "group": "072378",
        "group_num": [
            "rec7iSwIRd9xUogyc"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "recT34o976SnmcSgt",
        "row": 18263,
        "state": "",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recUcfH3YpmiLA0RK",
        "row": 18268,
        "state": "",
        "group": "0EE555",
        "group_num": [
            "receTsENi7BxqaE3u"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recWWiG6jdkXvq3VV",
        "row": 18283,
        "state": "",
        "group": "PH3533",
        "group_num": [
            "rec0WfJq6KTD74mE4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 733,
        "id": "recW2gw0roFtYNdfe",
        "row": 18257,
        "state": "",
        "group": "",
        "group_num": [
            "recwUuDJZqLo7ez05"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recXTmSPa81aKCpig",
        "row": 18251,
        "state": "",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recXWcxNXZwHcktWV",
        "row": 18239,
        "state": "",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1217,
        "id": "recYWgvfF1qL2CjOv",
        "row": 18280,
        "state": "",
        "group": "072378",
        "group_num": [
            "rec7iSwIRd9xUogyc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "recYlwFd7Yfa6zW4N",
        "row": 18261,
        "state": "",
        "group": "",
        "group_num": [
            "recZR5lfTULrCwKOo"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recYqR01AHaAyVoI8",
        "row": 18276,
        "state": "",
        "group": "",
        "group_num": [
            "recAeVwz42HSwgITi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recZRlRFqa9JWa9Mo",
        "row": 18243,
        "state": "",
        "group": "",
        "group_num": [
            "reclwAmxAHBDKPHL5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "rec7TtBcdpyiOrmds",
        "row": 18281,
        "state": "",
        "group": "072378",
        "group_num": [
            "rec7iSwIRd9xUogyc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "id": "rec98tWPtUlL9RzXl",
        "row": 18236,
        "state": "",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1127,
        "id": "recaF4hS8LZRCBZw1",
        "row": 18272,
        "state": "",
        "group": "0EJ681",
        "group_num": [
            "recwrfURA383HzXyi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recdvqksbRoTp18BT",
        "row": 18237,
        "state": "",
        "group": "300139",
        "group_num": [
            "recumCwc9EIBpm2yS"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 315,
        "id": "recdiVAeaDbHf7Xrk",
        "row": 18253,
        "state": "",
        "group": "888587",
        "group_num": [
            "recShTk4p95VDIx8O"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 738,
        "id": "recr7m5HWSp72Wo0T",
        "row": 18266,
        "state": "",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 267,
        "id": "rect0yUvkZKuyl82u",
        "row": 18282,
        "state": "",
        "group": "",
        "group_num": [
            "rec6mAXtxU0OGaD1f"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recuuU4SJ0za3qduP",
        "row": 18244,
        "state": "",
        "group": "",
        "group_num": [
            "recZjj1wi8VfWC1x9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recvwYKLQzPEpEIE0",
        "row": 18246,
        "state": "",
        "group": "",
        "group_num": [
            "recxODvJqY5VUbktS"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 835,
        "id": "recvN0BHZHgLWxJzf",
        "row": 18255,
        "state": "",
        "group": "",
        "group_num": [
            "rec2C2GrDvT5rHGcb"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 641,
        "id": "recw9mb1yewy98gkf",
        "row": 18259,
        "state": "",
        "group": "",
        "group_num": [
            "recZR5lfTULrCwKOo"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 840,
        "id": "recyyLsXlPmvnS8Pg",
        "row": 18264,
        "state": "",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recyaYmoC6t2v7IKM",
        "row": 18249,
        "state": "",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recyeL8KYqaA0yI2h",
        "row": 18262,
        "state": "",
        "group": "",
        "group_num": [
            "recZR5lfTULrCwKOo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 37,
        "2024": 37,
        "id": "recyq5S4nLzrh5ayq",
        "row": 18247,
        "state": "",
        "group": "",
        "group_num": [
            "recxODvJqY5VUbktS"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "id": "reczuUjvZcgG9oDql",
        "row": 18260,
        "state": "",
        "group": "",
        "group_num": [
            "recZR5lfTULrCwKOo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 80,
        "2023": 78,
        "id": "recAXowdRWPjHkzdz",
        "row": 18256,
        "state": "",
        "group": "",
        "group_num": [
            "rec2C2GrDvT5rHGcb"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 227,
        "id": "recAdrGcfuOFfaIZ2",
        "row": 18269,
        "state": "",
        "group": "0EJ681",
        "group_num": [
            "recwrfURA383HzXyi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 702,
        "id": "recCZPV3zB7KN7IZy",
        "row": 18273,
        "state": "",
        "group": "0EU004",
        "group_num": [
            "recP0WBN0K5RxJDLM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recCwERpqdznBYTE1",
        "row": 18274,
        "state": "",
        "group": "0EU004",
        "group_num": [
            "recP0WBN0K5RxJDLM"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recDVsjy1oQsOyjLm",
        "row": 18267,
        "state": "",
        "group": "IS1802",
        "group_num": [
            "reccyCg7BQ4PRFxOh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recFPCh8w8ENo6DVR",
        "row": 18252,
        "state": "",
        "group": "015695",
        "group_num": [
            "recyXr5fQgcqIj7Pf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recGReUUHaTTbnsI6",
        "row": 18245,
        "state": "",
        "group": "",
        "group_num": [
            "recxODvJqY5VUbktS"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recHLhdK0Rv8KntXm",
        "row": 18270,
        "state": "",
        "group": "0EJ681",
        "group_num": [
            "recwrfURA383HzXyi"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "recI0yD2cFTjrbEfg",
        "row": 18241,
        "state": "",
        "group": "300249",
        "group_num": [
            "recMJJPayBX0sE2Qn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 604,
        "id": "recIIiTfOqjHZTNEz",
        "row": 18234,
        "state": "",
        "group": "",
        "group_num": [
            "recsLot07ut5LhMk7"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "id": "recIjUFertJSwVi0C",
        "row": 18235,
        "state": "",
        "group": "",
        "group_num": [
            "recsLot07ut5LhMk7"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 451,
        "id": "recKIvysvZ65D1qdc",
        "row": 18278,
        "state": "",
        "group": "",
        "group_num": [
            "recxi0ppczvr0ZE3F"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recKgjGnl7GaSgHw1",
        "row": 18258,
        "state": "",
        "group": "",
        "group_num": [
            "recwUuDJZqLo7ez05"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 109,
        "2023": 218,
        "id": "recKndtyyVl4mamTK",
        "row": 18254,
        "state": "",
        "group": "IB2804",
        "group_num": [
            "recWZK3TxZ0875FNx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recLaMneTxwHgoCxB",
        "row": 18277,
        "state": "",
        "group": "231398",
        "group_num": [
            "recTHgt9RypgM4UvR"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recMnE3RDZID4vORu",
        "row": 18271,
        "state": "",
        "group": "0EJ681",
        "group_num": [
            "recwrfURA383HzXyi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3255,
        "id": "recNzwi6WmAgFHqZp",
        "row": 18242,
        "state": "",
        "group": "",
        "group_num": [
            "reclwAmxAHBDKPHL5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1024,
        "2024": 525,
        "id": "recfHcZzP277MrJyp",
        "row": 18287,
        "state": "",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1100,
        "id": "recgvmi75BiwtiuEN",
        "row": 18316,
        "state": "",
        "group": "121625",
        "group_num": [
            "recBWN51XtY2gxOaP"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 105,
        "2023": 123,
        "id": "recgko10vfisd5bcI",
        "row": 18308,
        "state": "",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1018,
        "id": "reciXRpLxQWruHceo",
        "row": 18331,
        "state": "",
        "group": "696978",
        "group_num": [
            "recUB12GkAVTQSU5w"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1176,
        "id": "recjWwdi0kIbCYL1G",
        "row": 18285,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "recknUuhpEx7wkBHb",
        "row": 18313,
        "state": "",
        "group": "",
        "group_num": [
            "recDorVTwMptCxqym"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 540,
        "id": "reclRtrVk5VyZfkBN",
        "row": 18291,
        "state": "",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recTpBZhGFSZYsLYN",
        "row": 18333,
        "state": "",
        "group": "",
        "group_num": [
            "reciw1T9GZlulwIMs"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 867,
        "2023": 587,
        "2024": 414,
        "id": "recUaNeW51UOMHCc9",
        "row": 18307,
        "state": "",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recUixjciLnDKv5P4",
        "row": 18326,
        "state": "",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2480,
        "2023": 218,
        "id": "recUiR9bGpce0M5aw",
        "row": 18289,
        "state": "",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 378,
        "2023": 525,
        "id": "recXdjj4106CDVtlY",
        "row": 18301,
        "state": "",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 651,
        "2023": 218,
        "id": "recXok71lp5UkoGOe",
        "row": 18298,
        "state": "",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2331,
        "2023": 1986,
        "id": "rec0CORBBmoE54MOp",
        "row": 18293,
        "state": "",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec1kyrZWRmFZPSlu",
        "row": 18318,
        "state": "",
        "group": "121625",
        "group_num": [
            "recBWN51XtY2gxOaP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1084,
        "id": "rec307GoHdAZhV8Qd",
        "row": 18295,
        "state": "",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "rec682WTTx49X4NG9",
        "row": 18327,
        "state": "",
        "group": "",
        "group_num": [
            "reck90wrbGu0j6pj6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 432,
        "2023": 151,
        "2024": 151,
        "id": "rec8EoZpYVt9pjfAw",
        "row": 18286,
        "state": "",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1706,
        "2023": 539,
        "id": "rec8MsC2EZ1DV1I32",
        "row": 18306,
        "state": "",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2139,
        "id": "recaYrFipS1CaEAm8",
        "row": 18322,
        "state": "",
        "group": "168258",
        "group_num": [
            "rec5BybbUXATWiKo8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 314,
        "id": "reca4YHTUHOwsA12g",
        "row": 18332,
        "state": "",
        "group": "",
        "group_num": [
            "recWFp4pT4UyfRqQb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2479,
        "id": "recbbZJNGYIFavBrl",
        "row": 18314,
        "state": "",
        "group": "0095867S02",
        "group_num": [
            "recQ2SJDjzqqalTlu"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1019,
        "2023": 717,
        "id": "reccveKoO35rj9XfL",
        "row": 18309,
        "state": "",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 1629,
        "id": "recdODwOHMQr73Ins",
        "row": 18292,
        "state": "",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recdRsqTztGchZyRY",
        "row": 18329,
        "state": "",
        "group": "696962",
        "group_num": [
            "recJfmUTFnwA6tjMs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4350,
        "id": "recoKDEkWmg9SEZbd",
        "row": 18303,
        "state": "",
        "group": "014228",
        "group_num": [
            "recEh0j45yIUFXwOc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1082,
        "2023": 317,
        "2024": 414,
        "id": "recogo463AycXfpMc",
        "row": 18290,
        "state": "",
        "group": "021177",
        "group_num": [
            "recQqJxeLXZ21eolp"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 595,
        "2023": 900,
        "id": "recprMyLMQ0P8Y7tO",
        "row": 18299,
        "state": "",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rectOixGKaXUbL1ZM",
        "row": 18317,
        "state": "",
        "group": "121625",
        "group_num": [
            "recBWN51XtY2gxOaP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "rect2aevXEMW8nrbr",
        "row": 18319,
        "state": "",
        "group": "854328",
        "group_num": [
            "recrPpUAzYKKqQaPA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2375,
        "id": "recwSyGgcB4G1s328",
        "row": 18296,
        "state": "",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2767,
        "id": "recw2DevttEpxlKvB",
        "row": 18323,
        "state": "",
        "group": "168258",
        "group_num": [
            "rec5BybbUXATWiKo8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1838,
        "id": "recxVknDCR8EBUxlL",
        "row": 18284,
        "state": "",
        "group": "PH3533",
        "group_num": [
            "rec0WfJq6KTD74mE4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 817,
        "2023": 420,
        "id": "recyrgG7B2RoDQhvX",
        "row": 18330,
        "state": "",
        "group": "696962",
        "group_num": [
            "recJfmUTFnwA6tjMs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 439,
        "2023": 486,
        "id": "reczxcoD8sophgPo6",
        "row": 18304,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1204,
        "id": "recATrK38jG0OVkOl",
        "row": 18312,
        "state": "",
        "group": "178860",
        "group_num": [
            "reciLST5MJfJXgr9Z"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1807,
        "2023": 1606,
        "id": "recA0U14OcQirH5kc",
        "row": 18288,
        "state": "",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recCS983Jj7Xb3SCc",
        "row": 18321,
        "state": "",
        "group": "",
        "group_num": [
            "recD1zz1y9abcCs9q"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 289,
        "id": "recCUU6ZJT8OIOhnu",
        "row": 18325,
        "state": "",
        "group": "",
        "group_num": [
            "recSXRNVNwM3cLEX5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 304,
        "id": "recCFgrFszzVyWe7u",
        "row": 18300,
        "state": "",
        "group": "206133",
        "group_num": [
            "recKs5MQQmDes6sce"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recCQinOTQ8lU0d9U",
        "row": 18302,
        "state": "",
        "group": "014228",
        "group_num": [
            "recEh0j45yIUFXwOc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 228,
        "id": "recDLc5XRCpPdGEgE",
        "row": 18324,
        "state": "",
        "group": "",
        "group_num": [
            "recSXRNVNwM3cLEX5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 644,
        "2023": 623,
        "id": "recEsyqyPv1RvZNDB",
        "row": 18310,
        "state": "",
        "group": "P13168",
        "group_num": [
            "recltc61wu7ofDj4W"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 807,
        "id": "recEm7qSB95asBssm",
        "row": 18315,
        "state": "",
        "group": "121625",
        "group_num": [
            "recBWN51XtY2gxOaP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1400,
        "id": "recHIO41VrRY4XpJE",
        "row": 18294,
        "state": "",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recHbKw1PO6HfRr9W",
        "row": 18320,
        "state": "",
        "group": "854328",
        "group_num": [
            "recrPpUAzYKKqQaPA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 646,
        "id": "recIiJgaqS84BLhdZ",
        "row": 18311,
        "state": "",
        "group": "178860",
        "group_num": [
            "reciLST5MJfJXgr9Z"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "id": "recJm5KjUbCaOcenb",
        "row": 18305,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2889,
        "2023": 2999,
        "id": "recKIseHn5W4ZJP7Y",
        "row": 18297,
        "state": "",
        "group": "031179",
        "group_num": [
            "recoJOrlDpPysimE8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recMlzf0Gq78b3T5d",
        "row": 18328,
        "state": "",
        "group": "696962",
        "group_num": [
            "recJfmUTFnwA6tjMs"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "id": "receSy6u7IXuSguiF",
        "row": 18363,
        "state": "",
        "group": "231189",
        "group_num": [
            "rec4m6NNfqbrSc54D"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recezlyzQqbVzYA1h",
        "row": 18339,
        "state": "",
        "group": "",
        "group_num": [
            "rec8gU2eJ6xxfwWfP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1026,
        "id": "recfDDD9QRwjHYtg2",
        "row": 18382,
        "state": "",
        "group": "",
        "group_num": [
            "rec13olwoDzsqDAvI"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 61,
        "id": "recgYmS4RB6OljDF5",
        "row": 18353,
        "state": "",
        "group": "072378",
        "group_num": [
            "rec7iSwIRd9xUogyc"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recgBvOVBNK82u1tk",
        "row": 18377,
        "state": "",
        "group": "GELBERGROUP",
        "group_num": [
            "rec5cL5R3yZti0oTD"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 212,
        "id": "recgCFFaFHjiaqmkh",
        "row": 18345,
        "state": "",
        "group": "",
        "group_num": [
            "recFQQJiJ4rMT7pEW"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 741,
        "id": "recgQmp09w1bF76hc",
        "row": 18373,
        "state": "",
        "group": "",
        "group_num": [
            "recractVb8dPV3CAa"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 595,
        "2023": 218,
        "id": "rechS6pl8VBiCSSht",
        "row": 18334,
        "state": "",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recjbu0jY9O8WLxnE",
        "row": 18362,
        "state": "",
        "group": "221068",
        "group_num": [
            "reczroQzyv16m4eTG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 584,
        "id": "reckRKTgUZ36DzdJC",
        "row": 18369,
        "state": "",
        "group": "289804",
        "group_num": [
            "recGu8PME55zU1V1I"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "id": "recmYkCC1S9N8Nk25",
        "row": 18375,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recOAXqjUGgsTZGW2",
        "row": 18378,
        "state": "",
        "group": "GELBERGROUP",
        "group_num": [
            "rec5cL5R3yZti0oTD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1689,
        "2023": 2578,
        "id": "recPY3lUwn9QwWg1K",
        "row": 18337,
        "state": "",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recQC8QAjSZ6FfOni",
        "row": 18380,
        "state": "",
        "group": "P06574",
        "group_num": [
            "reczLzYnPIOesW7Cs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1586,
        "id": "recSGZhmpvLLCDxAF",
        "row": 18342,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "id": "recTC1Piy0bVXdA00",
        "row": 18364,
        "state": "",
        "group": "231189",
        "group_num": [
            "rec4m6NNfqbrSc54D"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1231,
        "id": "recUNF6xRr2FMiVGv",
        "row": 18343,
        "state": "",
        "group": "",
        "group_num": [
            "recFQQJiJ4rMT7pEW"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 639,
        "id": "recZfK3gC5zXYMhYZ",
        "row": 18367,
        "state": "",
        "group": "",
        "group_num": [
            "recpliM6Rcx8k1rZc"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rec0GTKlHUR8aLuMB",
        "row": 18383,
        "state": "",
        "group": "P13666",
        "group_num": [
            "rec3jfkL8qWHnz9W9"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 671,
        "id": "rec1aqfmhXv3pvkoD",
        "row": 18370,
        "state": "",
        "group": "",
        "group_num": [
            "recractVb8dPV3CAa"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 205,
        "id": "rec1lG1MnZoREfAUr",
        "row": 18365,
        "state": "",
        "group": "",
        "group_num": [
            "rechj8t6jGLogoY5a"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1364,
        "id": "rec2BDLg45WXQKjwv",
        "row": 18350,
        "state": "",
        "group": "",
        "group_num": [
            "recYuL5hkvhk1iZhC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2138,
        "2023": 1499,
        "id": "rec4Jeyhq3oRPamKj",
        "row": 18358,
        "state": "",
        "group": "759645",
        "group_num": [
            "rec2Zft8phm738MOQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 807,
        "2024": 814,
        "id": "rec4eh8AeTBmrtUFr",
        "row": 18340,
        "state": "",
        "group": "",
        "group_num": [
            "rec8gU2eJ6xxfwWfP"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1003,
        "id": "rec5QUYMJj9F2ChMO",
        "row": 18368,
        "state": "",
        "group": "",
        "group_num": [
            "recpliM6Rcx8k1rZc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "rec54Jw17dawO29vH",
        "row": 18379,
        "state": "",
        "group": "P06574",
        "group_num": [
            "reczLzYnPIOesW7Cs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "rec6u0dNQcl1KeZRd",
        "row": 18360,
        "state": "",
        "group": "117623",
        "group_num": [
            "rec8cGULTIafwQAFM"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1081,
        "id": "rec8NI6uv7Rjk688B",
        "row": 18361,
        "state": "",
        "group": "117630",
        "group_num": [
            "rec6YTatyW1leg8RS"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 137,
        "id": "rec9PHQwEAYUababD",
        "row": 18352,
        "state": "",
        "group": "072378",
        "group_num": [
            "rec7iSwIRd9xUogyc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "rec95VmfkaLe9Uu16",
        "row": 18341,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 414,
        "id": "recafBWVduU7fCHLa",
        "row": 18357,
        "state": "",
        "group": "",
        "group_num": [
            "recxgg5rDmtpmak97"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1250,
        "id": "recpnoorKdJ25yW0z",
        "row": 18366,
        "state": "",
        "group": "",
        "group_num": [
            "rechj8t6jGLogoY5a"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 545,
        "id": "recqgMr1y9ZvgIzUk",
        "row": 18336,
        "state": "",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 603,
        "id": "recqnAdLcaLW5ZScI",
        "row": 18354,
        "state": "",
        "group": "099967",
        "group_num": [
            "recuXM1Jl0VwVL0Nw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1755,
        "id": "recrXEsdCYtj2n07J",
        "row": 18348,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 217,
        "2023": 222,
        "id": "recsI0KjcWPlZtbr7",
        "row": 18347,
        "state": "",
        "group": "",
        "group_num": [
            "rec4LVZ3CmfPvSjh2"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recxIA7KyeaKJ3vjC",
        "row": 18344,
        "state": "",
        "group": "",
        "group_num": [
            "recFQQJiJ4rMT7pEW"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1372,
        "id": "recx2VNRgTRcYidwi",
        "row": 18355,
        "state": "",
        "group": "099967",
        "group_num": [
            "recuXM1Jl0VwVL0Nw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 927,
        "id": "recxay7ebCDo0bCFq",
        "row": 18381,
        "state": "",
        "group": "P09407",
        "group_num": [
            "recjwqbnKaMFnfmbz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 710,
        "id": "recyLBDGJH4Wcoh3K",
        "row": 18372,
        "state": "",
        "group": "",
        "group_num": [
            "recractVb8dPV3CAa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "reczzBgKmiYjFVMQX",
        "row": 18374,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 271,
        "id": "reczjkPTgu0J3EkNz",
        "row": 18351,
        "state": "",
        "group": "",
        "group_num": [
            "recYuL5hkvhk1iZhC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1179,
        "id": "recAGmsbXWkbBrNBl",
        "row": 18335,
        "state": "",
        "group": "211252",
        "group_num": [
            "rec78St97xhIBT3x3"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1778,
        "2023": 857,
        "id": "recBvDpvgbiWJP9oQ",
        "row": 18359,
        "state": "",
        "group": "759645",
        "group_num": [
            "rec2Zft8phm738MOQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "2024": 686,
        "id": "recEaPDOotOiMXLq6",
        "row": 18356,
        "state": "",
        "group": "",
        "group_num": [
            "recEhcwlVnRiqZqs4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recEmLSxme2CL5iD5",
        "row": 18338,
        "state": "",
        "group": "021166",
        "group_num": [
            "recNWgJcgBSvC2zmN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 241,
        "id": "recGIAv028SSQaagN",
        "row": 18346,
        "state": "",
        "group": "",
        "group_num": [
            "recks4CO65CICsqxq"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 33,
        "2024": 33,
        "id": "recK3fYSq9kM4WFO9",
        "row": 18376,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 664,
        "2024": 999,
        "id": "recLxdvUQHeEHo5BI",
        "row": 18371,
        "state": "",
        "group": "",
        "group_num": [
            "recractVb8dPV3CAa"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recMkjVc15lKwdSWz",
        "row": 18349,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "id": "recgytg1L7dq7hiXX",
        "row": 18406,
        "state": "",
        "group": "P58472",
        "group_num": [
            "recMyAo6mVcJU63tt"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 431,
        "id": "recgNEAnCl3cfB2xp",
        "row": 18416,
        "state": "",
        "group": "",
        "group_num": [
            "recvoJjFS4GaP47Q4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "reci10h8bdFxRlof5",
        "row": 18403,
        "state": "",
        "group": "P54320",
        "group_num": [
            "reczdhlxJ6ckTpENV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "reciw44fNUyAbWbn5",
        "row": 18419,
        "state": "",
        "group": "",
        "group_num": [
            "recUwBcfc1EKshL0S"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 74,
        "2023": 64,
        "id": "recicYQEzo1ohoyn5",
        "row": 18427,
        "state": "",
        "group": "",
        "group_num": [
            "rechdSiDCau9mCXQg"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 859,
        "id": "recj5HwBWwEu9DTIF",
        "row": 18402,
        "state": "",
        "group": "P54320",
        "group_num": [
            "reczdhlxJ6ckTpENV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "reclVH7TRnRwVFsoR",
        "row": 18392,
        "state": "",
        "group": "P35581",
        "group_num": [
            "rec5aZMXrHQ0o1PZq"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recmvSw1YIHUYUBSs",
        "row": 18423,
        "state": "",
        "group": "PC1533",
        "group_num": [
            "recUBVL4UN8amp6Jv"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1004,
        "id": "recnuaU5HeG76L4bj",
        "row": 18424,
        "state": "",
        "group": "",
        "group_num": [
            "reclAgdfrYiCRAAUy"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "2023": 882,
        "id": "recPkM6tZtCuPO5FN",
        "row": 18385,
        "state": "",
        "group": "P16325",
        "group_num": [
            "rec2N4jsvggHhLjDc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "id": "recUScWN0l9UCkvcB",
        "row": 18428,
        "state": "",
        "group": "PF2071",
        "group_num": [
            "recJNZZFjPO8k7gMT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 490,
        "id": "recUbGMPiixm9MuQO",
        "row": 18390,
        "state": "",
        "group": "P31479",
        "group_num": [
            "recXohoAmVbzl3A5o"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 835,
        "id": "recWD5SiLjq5tXZ4g",
        "row": 18426,
        "state": "",
        "group": "",
        "group_num": [
            "rechdSiDCau9mCXQg"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recWkP2zzDmhQDaOd",
        "row": 18409,
        "state": "",
        "group": "",
        "group_num": [
            "reccenHeBy1lFUbXu"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 76,
        "2023": 76,
        "id": "recXNArG41Fy7HV4f",
        "row": 18414,
        "state": "",
        "group": "",
        "group_num": [
            "recoBMCNscDJgn3HJ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recXb3wLS6fb9OQ7R",
        "row": 18394,
        "state": "",
        "group": "P44052",
        "group_num": [
            "reclCnvfERXvFESxE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "2023": 833,
        "id": "recZJzd2EDnElaM0D",
        "row": 18400,
        "state": "",
        "group": "P54320",
        "group_num": [
            "reczdhlxJ6ckTpENV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "2023": 27,
        "id": "recZlGy5Wp4WwwmgL",
        "row": 18386,
        "state": "",
        "group": "P16325",
        "group_num": [
            "rec2N4jsvggHhLjDc"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "rec17iW35PKirB9nj",
        "row": 18384,
        "state": "",
        "group": "P13666",
        "group_num": [
            "rec3jfkL8qWHnz9W9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 160,
        "2024": 160,
        "id": "rec2UPdEKyMZAE2OH",
        "row": 18399,
        "state": "",
        "group": "",
        "group_num": [
            "recE0J2Muw2Hbs2bN"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "rec3SUtchHeLbZeFB",
        "row": 18408,
        "state": "",
        "group": "P75819",
        "group_num": [
            "rec3LVmxZLNxmWM7H"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 598,
        "2023": 598,
        "id": "rec4TOpFXtgSnbSYa",
        "row": 18397,
        "state": "",
        "group": "",
        "group_num": [
            "recyuDHQnFKh5k4zL"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1085,
        "2023": 1088,
        "id": "rec4XAYjNy9pwCdeS",
        "row": 18410,
        "state": "",
        "group": "P91150",
        "group_num": [
            "recWg1ee6TclZvVE8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1114,
        "id": "rec5LIPHFSrFwbCYP",
        "row": 18433,
        "state": "",
        "group": "",
        "group_num": [
            "recGXCLNMsCSzTokm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1509,
        "id": "rec5oXZBEiIyqOJhY",
        "row": 18404,
        "state": "",
        "group": "P56630",
        "group_num": [
            "recEoAsKzsXPgTuGr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "id": "rec7C8yz09oLwUbCB",
        "row": 18405,
        "state": "",
        "group": "P58472",
        "group_num": [
            "recMyAo6mVcJU63tt"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "rec7PjNENkpvGGAbs",
        "row": 18393,
        "state": "",
        "group": "P40970",
        "group_num": [
            "recEY4JhGc6LJuwwl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1536,
        "id": "rec9FRJ3pkEvJqiTB",
        "row": 18418,
        "state": "",
        "group": "",
        "group_num": [
            "recUwBcfc1EKshL0S"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 63,
        "id": "reca811xUsvKX7m94",
        "row": 18411,
        "state": "",
        "group": "P91150",
        "group_num": [
            "recWg1ee6TclZvVE8"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recbnmCh8FN9rcD11",
        "row": 18401,
        "state": "",
        "group": "P54320",
        "group_num": [
            "reczdhlxJ6ckTpENV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 863,
        "2023": 525,
        "id": "recckR6IjqfFGwJnm",
        "row": 18415,
        "state": "",
        "group": "",
        "group_num": [
            "recoBMCNscDJgn3HJ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 996,
        "id": "recrmIvHvtVATAAyH",
        "row": 18396,
        "state": "",
        "group": "",
        "group_num": [
            "recyuDHQnFKh5k4zL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "id": "recsYLMv4toDmSvgk",
        "row": 18389,
        "state": "",
        "group": "P31479",
        "group_num": [
            "recXohoAmVbzl3A5o"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 608,
        "id": "recvRL5sUkaN51Mdk",
        "row": 18387,
        "state": "",
        "group": "",
        "group_num": [
            "recMvt6sIH1hvZAVz"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1007,
        "id": "recwdtTxkqLGIm0NH",
        "row": 18391,
        "state": "",
        "group": "P35581",
        "group_num": [
            "rec5aZMXrHQ0o1PZq"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recxxu0ZKtpwmNC5I",
        "row": 18395,
        "state": "",
        "group": "P44052",
        "group_num": [
            "reclCnvfERXvFESxE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2353,
        "id": "recxbCGmST6uTbFCl",
        "row": 18430,
        "state": "",
        "group": "PG0846",
        "group_num": [
            "rec4G7UooYQdd3Iu4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1600,
        "id": "recyY1XFuG833JNlc",
        "row": 18431,
        "state": "",
        "group": "PG0846",
        "group_num": [
            "rec4G7UooYQdd3Iu4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1194,
        "id": "recy2oWCr1EL0F6IX",
        "row": 18421,
        "state": "",
        "group": "",
        "group_num": [
            "recROe6owm48X54MQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "2023": 271,
        "id": "recy3KqS73LVxiQ3g",
        "row": 18413,
        "state": "",
        "group": "",
        "group_num": [
            "recoBMCNscDJgn3HJ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reczsazCsyaO575be",
        "row": 18388,
        "state": "",
        "group": "",
        "group_num": [
            "recMvt6sIH1hvZAVz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 76,
        "id": "recBZV6B2QT9Ipe6c",
        "row": 18398,
        "state": "",
        "group": "",
        "group_num": [
            "recyuDHQnFKh5k4zL"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "2024": 525,
        "id": "recB5dln9jT6w1ATm",
        "row": 18417,
        "state": "",
        "group": "P99280",
        "group_num": [
            "recbZUgledVP1JvSy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 404,
        "id": "recC1grdqu0cjOqhj",
        "row": 18407,
        "state": "",
        "group": "P58472",
        "group_num": [
            "recMyAo6mVcJU63tt"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recCuTmNqjwwkCpTG",
        "row": 18422,
        "state": "",
        "group": "",
        "group_num": [
            "recOBxYReO4TFPXtI"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 665,
        "id": "recDKbgZvsmZOb1SR",
        "row": 18429,
        "state": "",
        "group": "PF2071",
        "group_num": [
            "recJNZZFjPO8k7gMT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 90,
        "id": "recD8vDOxX0VrMPax",
        "row": 18425,
        "state": "",
        "group": "",
        "group_num": [
            "reclAgdfrYiCRAAUy"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recEQtC5H2GKqH1Xv",
        "row": 18420,
        "state": "",
        "group": "",
        "group_num": [
            "recUwBcfc1EKshL0S"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 567,
        "id": "recGClBuV97dqkaFC",
        "row": 18412,
        "state": "",
        "group": "",
        "group_num": [
            "recoBMCNscDJgn3HJ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 496,
        "id": "recG3ymzpn3JEkfiA",
        "row": 18432,
        "state": "",
        "group": "",
        "group_num": [
            "recGXCLNMsCSzTokm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rechR30Ouf1PWuVbx",
        "row": 18435,
        "state": "",
        "group": "PI4634",
        "group_num": [
            "receBYCA15mepbhn8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 54,
        "id": "reciv8gRYCGzfg7rj",
        "row": 18469,
        "state": "",
        "group": "",
        "group_num": [
            "recZXjDDRDOF3XEWk"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "id": "reckr2aVQefjuYWYi",
        "row": 18471,
        "state": "",
        "group": "",
        "group_num": [
            "recG2vm2edVMUCcXh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "recmFAW3L9tBmHjfK",
        "row": 18441,
        "state": "",
        "group": "",
        "group_num": [
            "recjuhpEqUEwn5yYP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "2024": 26,
        "id": "recmoGndZl2sKFrq2",
        "row": 18446,
        "state": "",
        "group": "",
        "group_num": [
            "recTY6KYrJrHr5HVZ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 580,
        "2023": 760,
        "id": "recn1XQFxmcDWfYjQ",
        "row": 18448,
        "state": "",
        "group": "",
        "group_num": [
            "recxUkrGWbp8eYHks"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recPXsBr5hkfRr66V",
        "row": 18455,
        "state": "",
        "group": "226613",
        "group_num": [
            "recWeX31CaqVD8gGl"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 76,
        "id": "recQTWnZpQr1s2Nhn",
        "row": 18440,
        "state": "",
        "group": "",
        "group_num": [
            "recjuhpEqUEwn5yYP"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recQInvuEnN8hRVrL",
        "row": 18449,
        "state": "",
        "group": "PL1119",
        "group_num": [
            "recroAQpO3WVRRT9c"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "recVQHT341atZ6Q1w",
        "row": 18438,
        "state": "",
        "group": "",
        "group_num": [
            "recjuhpEqUEwn5yYP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "recVojlQhuNKWaUZo",
        "row": 18452,
        "state": "",
        "group": "208194",
        "group_num": [
            "rec8RCzDEweLQ5Ydu"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recWwTMZhDoyGqRzV",
        "row": 18451,
        "state": "",
        "group": "195569",
        "group_num": [
            "recYP0vLpXQHphZax"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recXBztaWfUFa1flc",
        "row": 18437,
        "state": "",
        "group": "PI4634",
        "group_num": [
            "receBYCA15mepbhn8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 27,
        "id": "recZSoEZBR4P6EbSZ",
        "row": 18457,
        "state": "",
        "group": "",
        "group_num": [
            "recapk55sfuh4rp21"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "rec0HmCDOofxo7F6M",
        "row": 18450,
        "state": "",
        "group": "195569",
        "group_num": [
            "recYP0vLpXQHphZax"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 525,
        "id": "rec1bzE5oKQAfmubN",
        "row": 18470,
        "state": "",
        "group": "208921",
        "group_num": [
            "recB5P54eZvuxutWa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 700,
        "id": "rec1cP4iu7jU2J5b3",
        "row": 18453,
        "state": "",
        "group": "214248",
        "group_num": [
            "recniLF61uRz9UPeE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 667,
        "id": "rec3StLlh6nYhuBN3",
        "row": 18454,
        "state": "",
        "group": "226613",
        "group_num": [
            "recWeX31CaqVD8gGl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1049,
        "id": "rec7JhMKUOKS72w0S",
        "row": 18445,
        "state": "",
        "group": "",
        "group_num": [
            "recTY6KYrJrHr5HVZ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2399,
        "id": "rec7Ol0qIzKlo593O",
        "row": 18458,
        "state": "",
        "group": "247516",
        "group_num": [
            "reciq2T682oDuWMvN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "id": "rec8ADtCN7QOxJwNG",
        "row": 18442,
        "state": "",
        "group": "PJ2620",
        "group_num": [
            "recXu7rdSxpOaluBZ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 159,
        "id": "rec8DaodVVbARPKZQ",
        "row": 18434,
        "state": "",
        "group": "PI4634",
        "group_num": [
            "receBYCA15mepbhn8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 67,
        "id": "rec8N6WasGItNbjfy",
        "row": 18468,
        "state": "",
        "group": "",
        "group_num": [
            "reczokjWYooACM6dN"
        ],
        "procedure": "OP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reca9I8AxjdpslVOo",
        "row": 18467,
        "state": "",
        "group": "299102",
        "group_num": [
            "recWiKR1dSemsz9jS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1575,
        "id": "recpiuaYwAiiFFqkP",
        "row": 18443,
        "state": "",
        "group": "",
        "group_num": [
            "reczTRZIL6W2waajT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recti4wgNVCewCXsg",
        "row": 18466,
        "state": "",
        "group": "299102",
        "group_num": [
            "recWiKR1dSemsz9jS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 579,
        "id": "recu4rL604nxb2ILj",
        "row": 18464,
        "state": "",
        "group": "",
        "group_num": [
            "recKbrWIC5569CRf7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recvNohtHoZnvHEPp",
        "row": 18456,
        "state": "",
        "group": "",
        "group_num": [
            "recapk55sfuh4rp21"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 252,
        "id": "recwCbD49jJiWImS2",
        "row": 18439,
        "state": "",
        "group": "",
        "group_num": [
            "recjuhpEqUEwn5yYP"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recznwwZyt7mLbJZy",
        "row": 18444,
        "state": "",
        "group": "",
        "group_num": [
            "recTY6KYrJrHr5HVZ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 890,
        "id": "recBcvb3LdCVOB04n",
        "row": 18459,
        "state": "",
        "group": "247516",
        "group_num": [
            "reciq2T682oDuWMvN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1085,
        "2023": 1088,
        "id": "recEHA3FVHKOEATVq",
        "row": 18460,
        "state": "",
        "group": "255259",
        "group_num": [
            "rec5mJERib5zWmd7n"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1875,
        "id": "recEfiQYEGSJtyxw8",
        "row": 18461,
        "state": "",
        "group": "265402",
        "group_num": [
            "recdK83rw2owlCFWo"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "id": "recFhdNiTMNfGAT2g",
        "row": 18465,
        "state": "",
        "group": "288892",
        "group_num": [
            "recXezeh7dOCs69L4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1106,
        "id": "recH5kiwK0poiDdk4",
        "row": 18447,
        "state": "",
        "group": "PK4720",
        "group_num": [
            "reca90Njtp1cSaOph"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 925,
        "2023": 525,
        "id": "recHnySb2RdnnA5Yu",
        "row": 18436,
        "state": "",
        "group": "PI4634",
        "group_num": [
            "receBYCA15mepbhn8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 412,
        "id": "recJnF2A7cheub64X",
        "row": 18463,
        "state": "",
        "group": "",
        "group_num": [
            "recKbrWIC5569CRf7"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 585,
        "id": "recMrR7hZ2YWHFM0Q",
        "row": 18462,
        "state": "",
        "group": "",
        "group_num": [
            "recKbrWIC5569CRf7"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF IL ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "id": "recf46zWAhTi9tJ1d",
        "row": 18475,
        "state": "",
        "group": "",
        "group_num": [
            "recbrIzPLwgC2ccXC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF KANSAS CITY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recnXn7WTCMg6O8hw",
        "row": 18477,
        "state": "",
        "group": "",
        "group_num": [
            "recLMphAKP0oB8QSu"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF KANSAS CITY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recRfwCbqLkyWHSLS",
        "row": 18472,
        "state": "",
        "group": "",
        "group_num": [
            "recC2Z88oCTL73doH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF KANSAS CITY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 414,
        "id": "recV4RqY1JUnPCylr",
        "row": 18474,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF KANSAS CITY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "id": "recaENaiMGPTsifi6",
        "row": 18473,
        "state": "",
        "group": "",
        "group_num": [
            "recC2Z88oCTL73doH"
        ],
        "procedure": "OP",
        "provider": "BCBS OF KANSAS CITY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "id": "recNQx6nw92kGuJM0",
        "row": 18476,
        "state": "",
        "group": "12057000",
        "group_num": [
            "rechFLKtDunitXaLU"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF KANSAS CITY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 374,
        "id": "rec0BJ4MvgWhONz4f",
        "row": 18478,
        "state": "",
        "group": "",
        "group_num": [
            "rec0peMCYtw2pYbQo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF KS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 374,
        "id": "rec11h1EPXnAQqBqc",
        "row": 18479,
        "state": "",
        "group": "",
        "group_num": [
            "reclhPJgp2YD2SZh9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF KS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recoz4sjiq6ZzYcxJ",
        "row": 18481,
        "state": "",
        "group": "9627055",
        "group_num": [
            "recOIRHpxpuZvKbbW"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF KS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 374,
        "id": "recGUBbjUZIUbizob",
        "row": 18480,
        "state": "",
        "group": "",
        "group_num": [
            "reclhPJgp2YD2SZh9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF KS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "id": "recPDY66laOecySFL",
        "row": 18483,
        "state": "",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 370,
        "id": "recaPKvHUZJ5jqfmT",
        "row": 18482,
        "state": "",
        "group": "ST222ERC",
        "group_num": [
            "recQHIAJzfOwK0OYy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "2023": 185,
        "id": "reckQHTXxs1TzSUQy",
        "row": 18497,
        "state": "",
        "group": "37678FF2",
        "group_num": [
            "reckCiuzdegcSLA03"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 269,
        "id": "recmKnBEyHtkNdu2h",
        "row": 18486,
        "state": "",
        "group": "29D68ERC",
        "group_num": [
            "rec5r4vDb5e5qPy9I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 466,
        "id": "recn07DwDqZ6xWxN8",
        "row": 18492,
        "state": "",
        "group": "",
        "group_num": [
            "recLO7qORYFl9HYHj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 224,
        "id": "recnuPRwGIVrSdk7v",
        "row": 18494,
        "state": "",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 419,
        "id": "recVd21HOYSyPgSni",
        "row": 18500,
        "state": "",
        "group": "81150FF2",
        "group_num": [
            "recALkvSO3u8PRYKv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 197,
        "id": "recWTlgR7Rml07Uvi",
        "row": 18485,
        "state": "",
        "group": "76871FF1",
        "group_num": [
            "recamFQ3pl3BAOFa0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 285,
        "id": "rec7Bny929077grOl",
        "row": 18491,
        "state": "",
        "group": "35X02ERC",
        "group_num": [
            "rec4bElVqSsGFlePC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 246,
        "id": "rec87EbLqlDbHn2kw",
        "row": 18496,
        "state": "",
        "group": "",
        "group_num": [
            "recogPEPV8yyyw8Sv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 167,
        "id": "rec8iZW8wtCzb9eD6",
        "row": 18493,
        "state": "",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 185,
        "id": "recdS939wH82CLauC",
        "row": 18489,
        "state": "",
        "group": "35F68ERC",
        "group_num": [
            "recXHwP2gvQ3YmohC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 489,
        "id": "recsiBWhMTJYVLnuN",
        "row": 18498,
        "state": "",
        "group": "37678FF2",
        "group_num": [
            "reckCiuzdegcSLA03"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 370,
        "id": "rectTpnLgLobZl5vO",
        "row": 18488,
        "state": "",
        "group": "",
        "group_num": [
            "rec2jedOL97MyAZWZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "id": "recxXgLSyWBopwcBa",
        "row": 18487,
        "state": "",
        "group": "29D68ERC",
        "group_num": [
            "rec5r4vDb5e5qPy9I"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 241,
        "id": "recD2zx7s341E977h",
        "row": 18499,
        "state": "",
        "group": "81150FF2",
        "group_num": [
            "recALkvSO3u8PRYKv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recDoFj74OSc6wPey",
        "row": 18484,
        "state": "",
        "group": "78K40ERC",
        "group_num": [
            "recxOwQVc6l3tRkyw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 231,
        "id": "recG2AvYyhnWQHeM6",
        "row": 18490,
        "state": "",
        "group": "35X02ERC",
        "group_num": [
            "rec4bElVqSsGFlePC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 466,
        "id": "recIcTVm3lxNM09Qq",
        "row": 18495,
        "state": "",
        "group": "78P62ERC",
        "group_num": [
            "recpS8cE4RMhaoDhQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 173,
        "id": "recLOJUruE0TXdoOA",
        "row": 18502,
        "state": "",
        "group": "85311FF2",
        "group_num": [
            "rec7uaD8yZUuIewbL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 373,
        "id": "recNsfKkEt18KWcjv",
        "row": 18503,
        "state": "",
        "group": "85311FF2",
        "group_num": [
            "rec7uaD8yZUuIewbL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 130,
        "id": "recN56lkXIBLlFXbY",
        "row": 18501,
        "state": "",
        "group": "85311FF2",
        "group_num": [
            "rec7uaD8yZUuIewbL"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF LA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1262,
        "id": "receWRjKg3tRE93pW",
        "row": 18525,
        "state": "",
        "group": "002364970",
        "group_num": [
            "recz7rLgJYPDVGfDv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1295,
        "2023": 414,
        "id": "recjgtGquQdV621FL",
        "row": 18506,
        "state": "",
        "group": "002349525",
        "group_num": [
            "recxIoAarRtvGmtBU"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 316,
        "id": "recji0xB1y6nWOvhC",
        "row": 18530,
        "state": "",
        "group": "002374257",
        "group_num": [
            "recpqxJmBTpF9xr1O"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "reckmfGwneBcxzgUZ",
        "row": 18504,
        "state": "",
        "group": "002321613",
        "group_num": [
            "recWLa8lrFMh2Y5VQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 517,
        "id": "reclTN8Qcibln7Leu",
        "row": 18521,
        "state": "",
        "group": "",
        "group_num": [
            "recEabV3Wh47joYaB"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "id": "recmG6cOcTk44lF2y",
        "row": 18511,
        "state": "",
        "group": "80963",
        "group_num": [
            "reczIOON3EZKygglC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2520,
        "id": "recPx6BkQUZL8SoGZ",
        "row": 18513,
        "state": "",
        "group": "002364614",
        "group_num": [
            "recgIcZ4GdwQdahFB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3054,
        "id": "recVN87TjwK70HUom",
        "row": 18516,
        "state": "",
        "group": "002364671",
        "group_num": [
            "recIvf2RKB4w0CiRH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 407,
        "2023": 407,
        "id": "recX2TRZFYqfBou8z",
        "row": 18522,
        "state": "",
        "group": "",
        "group_num": [
            "recjHGWzFdymc43eG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2974,
        "id": "recYKEK8A7bSlv0oa",
        "row": 18523,
        "state": "",
        "group": "",
        "group_num": [
            "recjHGWzFdymc43eG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "rec2KBVdRIuKqqTk3",
        "row": 18509,
        "state": "",
        "group": "",
        "group_num": [
            "recod7rxo4TUyazAG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1188,
        "id": "rec4rhIobbT6BBsUN",
        "row": 18507,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "rec56j5r4Bh28NqKC",
        "row": 18515,
        "state": "",
        "group": "002364671",
        "group_num": [
            "recIvf2RKB4w0CiRH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "2023": 49,
        "id": "rec8IiaPdNa8HQ6eL",
        "row": 18533,
        "state": "",
        "group": "",
        "group_num": [
            "recymyvIoc2uoZ2dr"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "id": "recoeGYy3jhkgJV58",
        "row": 18510,
        "state": "",
        "group": "",
        "group_num": [
            "recod7rxo4TUyazAG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 585,
        "id": "recomMw2An2VM6bsS",
        "row": 18529,
        "state": "",
        "group": "002374257",
        "group_num": [
            "recpqxJmBTpF9xr1O"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2435,
        "id": "recpbgB0GP9ysWKEs",
        "row": 18512,
        "state": "",
        "group": "002345986",
        "group_num": [
            "recD8C8ZZa0IHx0e5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "id": "recrsncKw7GeTDtMW",
        "row": 18531,
        "state": "",
        "group": "",
        "group_num": [
            "recymyvIoc2uoZ2dr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 385,
        "2023": 221,
        "id": "recud4aVfEVHy0fu1",
        "row": 18532,
        "state": "",
        "group": "",
        "group_num": [
            "recymyvIoc2uoZ2dr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1313,
        "id": "recvnLJlrL4pdHY05",
        "row": 18524,
        "state": "",
        "group": "002364970",
        "group_num": [
            "recz7rLgJYPDVGfDv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1243,
        "id": "recySoAelFig1Rw9a",
        "row": 18527,
        "state": "",
        "group": "002370159",
        "group_num": [
            "recHIZhRkqJpHkgzs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 736,
        "id": "reczw7YeSlbKje7RI",
        "row": 18517,
        "state": "",
        "group": "002364671",
        "group_num": [
            "recIvf2RKB4w0CiRH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1246,
        "id": "recARtp7yMRf8v9oP",
        "row": 18528,
        "state": "",
        "group": "002370159",
        "group_num": [
            "recHIZhRkqJpHkgzs"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "recA59ju6aJ4OqngN",
        "row": 18526,
        "state": "",
        "group": "002370159",
        "group_num": [
            "recHIZhRkqJpHkgzs"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1223,
        "id": "recBZFhwuTGnJHFDC",
        "row": 18518,
        "state": "",
        "group": "002364683",
        "group_num": [
            "recFU7vPSGOilWYdD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2873,
        "id": "recDVfaRRVZ50Y4Qo",
        "row": 18520,
        "state": "",
        "group": "",
        "group_num": [
            "rec5TdJCZIbkVuR1G"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1197,
        "id": "recDDCMYg9AGXfbed",
        "row": 18514,
        "state": "",
        "group": "002364671",
        "group_num": [
            "recIvf2RKB4w0CiRH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "id": "recFJwAEkdAyN5yby",
        "row": 18505,
        "state": "",
        "group": "002321613",
        "group_num": [
            "recWLa8lrFMh2Y5VQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1210,
        "id": "recFbkhTRwi2rGucr",
        "row": 18508,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recH5uiJghqXbA4Xd",
        "row": 18519,
        "state": "",
        "group": "002364683",
        "group_num": [
            "recFU7vPSGOilWYdD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recfiBA7yMF2vDlSp",
        "row": 18583,
        "state": "",
        "group": "",
        "group_num": [
            "recCBsXGegNrorecC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recgtEs12CqG00IBN",
        "row": 18543,
        "state": "",
        "group": "",
        "group_num": [
            "recXWrWcMrASQhj5d"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1389,
        "id": "recislB5H0SkhCLtF",
        "row": 18557,
        "state": "",
        "group": "287399041",
        "group_num": [
            "recXkNb6R7DULNtjl"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1248,
        "id": "recjXJFgduJUQfFon",
        "row": 18552,
        "state": "",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2130,
        "id": "reckxA2p1tbLG7oNQ",
        "row": 18561,
        "state": "",
        "group": "004004826",
        "group_num": [
            "recZfQMhm2IM1Febr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4619,
        "id": "reckgPYa8D83Dof4a",
        "row": 18582,
        "state": "",
        "group": "",
        "group_num": [
            "recWQ7pX2JlbMH0DR"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1256,
        "id": "recOj7AUvHVFjJ1dC",
        "row": 18544,
        "state": "",
        "group": "002364490",
        "group_num": [
            "rec0EAndkpryjh2hZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1208,
        "id": "recQ4MGapWCkwx2BZ",
        "row": 18546,
        "state": "",
        "group": "002355673",
        "group_num": [
            "recNYeqsonKBBcPLC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2269,
        "id": "recTdYUeftp8V2SQj",
        "row": 18538,
        "state": "",
        "group": "002349912",
        "group_num": [
            "recNVqbS75uRcZvWP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1235,
        "id": "recVfQI21byFg5TIU",
        "row": 18564,
        "state": "",
        "group": "002334386",
        "group_num": [
            "recF2b145qJ5XAECv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1332,
        "id": "recWfkObH16gnMRSn",
        "row": 18577,
        "state": "",
        "group": "002375841",
        "group_num": [
            "recI61eNnfD98iyyi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1782,
        "id": "recXzIvJBOItQfPel",
        "row": 18537,
        "state": "",
        "group": "002349912",
        "group_num": [
            "recNVqbS75uRcZvWP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1229,
        "id": "recXIPzsFZNn3s2Wz",
        "row": 18562,
        "state": "",
        "group": "004004826",
        "group_num": [
            "recZfQMhm2IM1Febr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "id": "recYKFJ0csTkn7vTb",
        "row": 18548,
        "state": "",
        "group": "002359187",
        "group_num": [
            "recNgUOmnyCWBHdQo"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3255,
        "id": "recZYh7XVfQatl8xD",
        "row": 18556,
        "state": "",
        "group": "287399041",
        "group_num": [
            "recXkNb6R7DULNtjl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1385,
        "id": "rec0yWyi2tnxx7t7Y",
        "row": 18560,
        "state": "",
        "group": "002365119",
        "group_num": [
            "recaJaSCpDNdtSvZL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1246,
        "id": "rec2KmXyfNcmh4xPm",
        "row": 18553,
        "state": "",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2466,
        "id": "rec2K1tGlWEVGVLrH",
        "row": 18573,
        "state": "",
        "group": "002374561",
        "group_num": [
            "rec3fx19sC19wQmVy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 298,
        "id": "rec3csxqDiqJigrHA",
        "row": 18580,
        "state": "",
        "group": "",
        "group_num": [
            "recikSEREgyizhNcn"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 187,
        "id": "rec51BZfi80rCNsLM",
        "row": 18542,
        "state": "",
        "group": "",
        "group_num": [
            "recXWrWcMrASQhj5d"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1263,
        "2023": 1258,
        "id": "rec5FCdfRTDKYHkiH",
        "row": 18534,
        "state": "",
        "group": "002291579",
        "group_num": [
            "recZDVZyy0oMsqfOB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1837,
        "id": "rec6OnJRjZQb3avVa",
        "row": 18575,
        "state": "",
        "group": "",
        "group_num": [
            "rec4rUTP2Wr1q3vlC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec6fN3m1rO3BC5dN",
        "row": 18539,
        "state": "",
        "group": "002349912",
        "group_num": [
            "recNVqbS75uRcZvWP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "id": "rec7VxEMLK1ZrwO0m",
        "row": 18569,
        "state": "",
        "group": "002368389",
        "group_num": [
            "reccu2ZgD2ydMEM8b"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1225,
        "id": "rec7iIEgmIl8ry81V",
        "row": 18571,
        "state": "",
        "group": "002368604",
        "group_num": [
            "recAHJVIIHZEyLwni"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1208,
        "id": "rec88HedMKL2m2zVf",
        "row": 18535,
        "state": "",
        "group": "002343173",
        "group_num": [
            "reclN4nQKx3x0WTML"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1133,
        "id": "reca9JVjthO4BqLrG",
        "row": 18558,
        "state": "",
        "group": "002367544",
        "group_num": [
            "receCXgvFeMbcFeq5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2777,
        "id": "recbz4MWTpuSfPn9X",
        "row": 18545,
        "state": "",
        "group": "002352773",
        "group_num": [
            "rec2r92MDHVQqR2bI"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1191,
        "id": "recbHsbK1jyw2TybC",
        "row": 18536,
        "state": "",
        "group": "002318594",
        "group_num": [
            "recVjL8jdlOqi4YDf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recbLvnzq2RqVivQK",
        "row": 18554,
        "state": "",
        "group": "",
        "group_num": [
            "rec1wh6d2iTjOLT1s"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1487,
        "id": "reccG1zwsFlDHyfVG",
        "row": 18547,
        "state": "",
        "group": "002359187",
        "group_num": [
            "recNgUOmnyCWBHdQo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1040,
        "id": "reccPcpr8spSHddn4",
        "row": 18579,
        "state": "",
        "group": "",
        "group_num": [
            "recikSEREgyizhNcn"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1214,
        "id": "recc5FzdxmPfKaMPr",
        "row": 18563,
        "state": "",
        "group": "002334386",
        "group_num": [
            "recF2b145qJ5XAECv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1266,
        "id": "reccjzx9pd5DDTv0p",
        "row": 18578,
        "state": "",
        "group": "002376761",
        "group_num": [
            "recmZJcq6C8NX08zb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2092,
        "id": "recpwn0XeiTV1nkfB",
        "row": 18572,
        "state": "",
        "group": "002374561",
        "group_num": [
            "rec3fx19sC19wQmVy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1258,
        "id": "recpHEscadYV2dOK2",
        "row": 18574,
        "state": "",
        "group": "",
        "group_num": [
            "recpfD1mTTTLZ643F"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1142,
        "id": "recpf6IEdote2FO3r",
        "row": 18559,
        "state": "",
        "group": "002367544",
        "group_num": [
            "receCXgvFeMbcFeq5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1555,
        "id": "recqTP8GZgSbpEX3h",
        "row": 18541,
        "state": "",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1414,
        "id": "recwfK6IvfILxGZvF",
        "row": 18555,
        "state": "",
        "group": "287399041",
        "group_num": [
            "recXkNb6R7DULNtjl"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1199,
        "id": "recyT74yuNiIbs64B",
        "row": 18567,
        "state": "",
        "group": "002347959",
        "group_num": [
            "recXAqs2ROUU2v8zq"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1207,
        "id": "recyFOiTMZEcph0iY",
        "row": 18568,
        "state": "",
        "group": "002347959",
        "group_num": [
            "recXAqs2ROUU2v8zq"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 63,
        "id": "recAR6tMjAf4nzTzI",
        "row": 18566,
        "state": "",
        "group": "",
        "group_num": [
            "recHL9uTMuIOiV4Ol"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "recCcC8vBybln7LrS",
        "row": 18549,
        "state": "",
        "group": "002375556",
        "group_num": [
            "recKhKaPy5Js97D6h"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1186,
        "id": "recEywkXnAQzpoaBx",
        "row": 18550,
        "state": "",
        "group": "107319042",
        "group_num": [
            "recMsa4bcZ65HoYaS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 602,
        "id": "recE2Vl7Dg4z3isI0",
        "row": 18565,
        "state": "",
        "group": "",
        "group_num": [
            "recHL9uTMuIOiV4Ol"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1176,
        "id": "recFNuX2VZH3NmXlJ",
        "row": 18570,
        "state": "",
        "group": "002368467",
        "group_num": [
            "rec679O9c5jvftvjY"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "recKQww84GeiDnkfF",
        "row": 18551,
        "state": "",
        "group": "107399042",
        "group_num": [
            "recfgvqMW6LNs9R8q"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1646,
        "2024": 1537,
        "id": "recKfb2Je9IE3oYWH",
        "row": 18540,
        "state": "",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1304,
        "id": "recLHOUbUvj6z40aG",
        "row": 18576,
        "state": "",
        "group": "002375841",
        "group_num": [
            "recI61eNnfD98iyyi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 166,
        "id": "recMpB1gwj6oxmY9S",
        "row": 18581,
        "state": "",
        "group": "",
        "group_num": [
            "recikSEREgyizhNcn"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 113,
        "id": "reclfYAkKvVcuRtaJ",
        "row": 18588,
        "state": "",
        "group": "",
        "group_num": [
            "recuruV3c2i33gRCF"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recbBElYYVBZlFHzL",
        "row": 18590,
        "state": "",
        "group": "002364773",
        "group_num": [
            "recK1fdXNufpxYX1e"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "2024": 151,
        "id": "recoOOMrQYZBYSlzq",
        "row": 18584,
        "state": "",
        "group": "",
        "group_num": [
            "recCBsXGegNrorecC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recwDrC3HDvOeivb5",
        "row": 18589,
        "state": "",
        "group": "002364773",
        "group_num": [
            "recK1fdXNufpxYX1e"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recEYxoLTjEdC4j3z",
        "row": 18587,
        "state": "",
        "group": "989242870",
        "group_num": [
            "recVJggBNZCMCRjvU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1164,
        "id": "recECWGoDzo3qQmuP",
        "row": 18586,
        "state": "",
        "group": "006955578",
        "group_num": [
            "recM3ps5JoefEF4Dv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 447,
        "id": "recENwdzVQwoLnJ3G",
        "row": 18585,
        "state": "",
        "group": "006955578",
        "group_num": [
            "recM3ps5JoefEF4Dv"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 800,
        "id": "recewtDUPQOzpRZdE",
        "row": 18601,
        "state": "",
        "group": "",
        "group_num": [
            "rec7UO6doaSlXpeIM"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "rechPGFPN9caTp8Or",
        "row": 18631,
        "state": "",
        "group": "000071569200010",
        "group_num": [
            "recIOGsFL2MQW0OqA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recjwyZ2UtBPdsYlX",
        "row": 18592,
        "state": "",
        "group": "",
        "group_num": [
            "recefajZsdrqvDwB6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recjjhmTqEXuWDhqq",
        "row": 18604,
        "state": "",
        "group": "",
        "group_num": [
            "recc9olG2VkWeEasc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "reclFeN24U5ui4tcN",
        "row": 18606,
        "state": "",
        "group": "",
        "group_num": [
            "rec4jrzHm6pbvtXug"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 772,
        "id": "recnNsyzGeFBxyp7P",
        "row": 18595,
        "state": "",
        "group": "",
        "group_num": [
            "recIiFWhBXZeDa4Tj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 162,
        "id": "recPMztMHyvcTZO7d",
        "row": 18598,
        "state": "",
        "group": "",
        "group_num": [
            "recZcnsCpSgkEGyQf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 769,
        "id": "recP26DSdzbOK6JAg",
        "row": 18632,
        "state": "",
        "group": "",
        "group_num": [
            "recSxX5GilECAVy6M"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "2023": 1758,
        "id": "recQhS91n3o1nbXh6",
        "row": 18630,
        "state": "",
        "group": "",
        "group_num": [
            "recIKYBgZRp5EV1qZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 118,
        "id": "recSHWRhsT1CWGNl6",
        "row": 18603,
        "state": "",
        "group": "",
        "group_num": [
            "recOjv5kH7xkBDiKk"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recTZzEzrGniSwrEP",
        "row": 18629,
        "state": "",
        "group": "",
        "group_num": [
            "rec8lTxXVzzRPWbU9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recU3hbGnY4ayFLwd",
        "row": 18602,
        "state": "",
        "group": "",
        "group_num": [
            "recPD5al0QPrBDaXc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recU9PeH8IXJWPUhz",
        "row": 18614,
        "state": "",
        "group": "",
        "group_num": [
            "recDQtyLkjPWDXzs4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recVp332X6ZfAzE6A",
        "row": 18615,
        "state": "",
        "group": "",
        "group_num": [
            "recDQtyLkjPWDXzs4"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recX05MYo9ua9Bulb",
        "row": 18609,
        "state": "",
        "group": "71544",
        "group_num": [
            "recSfJ7dEpd6PURu7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 767,
        "id": "rec0mNJ7Lcj3pDpaG",
        "row": 18612,
        "state": "",
        "group": "",
        "group_num": [
            "recIGewKcB5mcBtHH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec2OFa9rhH0pTPSR",
        "row": 18613,
        "state": "",
        "group": "",
        "group_num": [
            "recIGewKcB5mcBtHH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 45,
        "id": "rec35d3dqDjROBsxY",
        "row": 18608,
        "state": "",
        "group": "71544",
        "group_num": [
            "recSfJ7dEpd6PURu7"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 45,
        "id": "rec4JGAMofJ3BHnrJ",
        "row": 18591,
        "state": "",
        "group": "",
        "group_num": [
            "recefajZsdrqvDwB6"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "rec9bbGkc6l8PVe6x",
        "row": 18622,
        "state": "",
        "group": "",
        "group_num": [
            "recmBJp3bbG4Lc2Id"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "reccT40MtdOnts7v8",
        "row": 18611,
        "state": "",
        "group": "",
        "group_num": [
            "recKHtgbyFH6g7SCp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "recdeLYbXz4YtRXFb",
        "row": 18618,
        "state": "",
        "group": "",
        "group_num": [
            "rechIYTkAeIGbupN3"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 800,
        "id": "recp8k1OFh1Iyz3T5",
        "row": 18624,
        "state": "",
        "group": "",
        "group_num": [
            "recESWZVAcURu1o7e"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recqQkvBuUCvRWhU3",
        "row": 18593,
        "state": "",
        "group": "",
        "group_num": [
            "rec05L3F24aEsKP0E"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recqpzGrhRwA1ERrj",
        "row": 18621,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 800,
        "id": "recrH0jbHjd2zZx8D",
        "row": 18599,
        "state": "",
        "group": "",
        "group_num": [
            "recBhuM1uJfBkmf5u"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 45,
        "id": "recuD2Fd2Bdf66AA3",
        "row": 18627,
        "state": "",
        "group": "",
        "group_num": [
            "rec8lTxXVzzRPWbU9"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recu8yk6vpBqQ6b5D",
        "row": 18594,
        "state": "",
        "group": "",
        "group_num": [
            "rec05L3F24aEsKP0E"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recvxCB9Ne3LjJdYW",
        "row": 18610,
        "state": "",
        "group": "71544",
        "group_num": [
            "recSfJ7dEpd6PURu7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "id": "recxG3siHitjR4YdS",
        "row": 18605,
        "state": "",
        "group": "",
        "group_num": [
            "recc9olG2VkWeEasc"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recyI8emh3hup3gBm",
        "row": 18597,
        "state": "",
        "group": "",
        "group_num": [
            "recZcnsCpSgkEGyQf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recyNkNkm8oHkYrTy",
        "row": 18617,
        "state": "",
        "group": "",
        "group_num": [
            "rechIYTkAeIGbupN3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recynmrEhd7UlXqjk",
        "row": 18607,
        "state": "",
        "group": "",
        "group_num": [
            "rec6FMAw73mJ3blY0"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 45,
        "id": "recA1ch4X9CxNZIRF",
        "row": 18616,
        "state": "",
        "group": "",
        "group_num": [
            "rechIYTkAeIGbupN3"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "id": "recBGhkAojWUSpgM6",
        "row": 18623,
        "state": "",
        "group": "",
        "group_num": [
            "recmBJp3bbG4Lc2Id"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1196,
        "id": "recBofQKLEGVxfHlt",
        "row": 18619,
        "state": "",
        "group": "0000715491310",
        "group_num": [
            "recAYb32ogZHu8wf8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recCOXaJa29sGvgkr",
        "row": 18625,
        "state": "",
        "group": "",
        "group_num": [
            "recESWZVAcURu1o7e"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 192,
        "id": "recEdqJrrY2v5dnKN",
        "row": 18628,
        "state": "",
        "group": "",
        "group_num": [
            "rec8lTxXVzzRPWbU9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2024": 800,
        "id": "recEpc9f5amVGCOjL",
        "row": 18600,
        "state": "",
        "group": "",
        "group_num": [
            "rec7UO6doaSlXpeIM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recJsN2Aar6VjJtQF",
        "row": 18620,
        "state": "",
        "group": "000071549130011",
        "group_num": [
            "rec7wg9rczT73cmsa"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recMTmcl6f7855RGq",
        "row": 18596,
        "state": "",
        "group": "",
        "group_num": [
            "recG7RzkizfZWh6XT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 762,
        "id": "recM1IK4T2lHg8oUT",
        "row": 18633,
        "state": "",
        "group": "",
        "group_num": [
            "recSxX5GilECAVy6M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 771,
        "id": "recNWEm6aIZGXGVXS",
        "row": 18626,
        "state": "",
        "group": "",
        "group_num": [
            "recFwL7sQnN2yFhjV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 533,
        "id": "rece79MtV0jYTjcJZ",
        "row": 18656,
        "state": "",
        "group": "",
        "group_num": [
            "rec8Yrs3QcDQ0IijZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recgZH3MJtkbJxiPp",
        "row": 18645,
        "state": "",
        "group": "",
        "group_num": [
            "rec7bo3oG9ApA3THJ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "reciZDuhQHI2qDLDQ",
        "row": 18655,
        "state": "",
        "group": "",
        "group_num": [
            "rec8Yrs3QcDQ0IijZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 769,
        "id": "recmi2H2UCBXyWLit",
        "row": 18637,
        "state": "",
        "group": "",
        "group_num": [
            "recX05oyDAqZrjmbl"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 7,
        "id": "recPFSHmfO00tDEER",
        "row": 18660,
        "state": "",
        "group": "",
        "group_num": [
            "reckj5n41n6NiBbyN"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 773,
        "id": "recRWGuiyTKkNyUtF",
        "row": 18659,
        "state": "",
        "group": "",
        "group_num": [
            "rec8z6wjx2sDx2rBB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recTj1LsamDbWPIow",
        "row": 18657,
        "state": "",
        "group": "",
        "group_num": [
            "recUwlypre7uwzJh2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 302,
        "id": "recVdzzvO0knOBY4O",
        "row": 18648,
        "state": "",
        "group": "",
        "group_num": [
            "rec5AfWe1ef2YY6AY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "recVgD23b8DgswxVE",
        "row": 18642,
        "state": "",
        "group": "",
        "group_num": [
            "recIf74VcaEk2Ohoo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 783,
        "id": "recXmpCt9doNJKqML",
        "row": 18644,
        "state": "",
        "group": "",
        "group_num": [
            "rec7bo3oG9ApA3THJ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "rec3Hi4Ed6xsQ2fas",
        "row": 18643,
        "state": "",
        "group": "",
        "group_num": [
            "recBNZeaIliSS2mMI"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec6gLFQOGdeJrWpC",
        "row": 18651,
        "state": "",
        "group": "",
        "group_num": [
            "recJSLfwnE2wlvEd2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 212,
        "id": "rec7xdZHYxS6nT8ZU",
        "row": 18636,
        "state": "",
        "group": "",
        "group_num": [
            "recorzqDzCkn8F4wz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "rec9J0axsrqa8SNt0",
        "row": 18635,
        "state": "",
        "group": "",
        "group_num": [
            "rec40TOmMfSUWXB1L"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 212,
        "id": "reccT1TNen4diaSsj",
        "row": 18653,
        "state": "",
        "group": "",
        "group_num": [
            "recLdmZ5rC2UOE0iS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "reccpCwfNiJLtKPxr",
        "row": 18641,
        "state": "",
        "group": "",
        "group_num": [
            "recIf74VcaEk2Ohoo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 756,
        "id": "recdzM5eTdmUldebf",
        "row": 18639,
        "state": "",
        "group": "",
        "group_num": [
            "recKp46moKifLoTpE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recpuORo0tfBSJemu",
        "row": 18647,
        "state": "",
        "group": "00281551",
        "group_num": [
            "rec9k4XBMkWrLtOKq"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recwGotchCHUfeHJw",
        "row": 18634,
        "state": "",
        "group": "",
        "group_num": [
            "rec40TOmMfSUWXB1L"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 747,
        "id": "recx0GIedqX48E1VY",
        "row": 18654,
        "state": "",
        "group": "",
        "group_num": [
            "reck5ToM14AWGegFX"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "id": "recy3trJ1YLQuh6eK",
        "row": 18646,
        "state": "",
        "group": "00281551",
        "group_num": [
            "rec9k4XBMkWrLtOKq"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recFG6sAMmnDdB3eP",
        "row": 18661,
        "state": "",
        "group": "",
        "group_num": [
            "reckj5n41n6NiBbyN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 203,
        "id": "recIjAAAy9il9ysk0",
        "row": 18658,
        "state": "",
        "group": "",
        "group_num": [
            "recWPq4zA1VsSrn0G"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recJWeCHqjma7dFeH",
        "row": 18640,
        "state": "",
        "group": "",
        "group_num": [
            "recTYsA2Pa0IB1BWa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 800,
        "id": "recJOaCWXe182oKZE",
        "row": 18650,
        "state": "",
        "group": "",
        "group_num": [
            "recJSLfwnE2wlvEd2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recKuTioARkjLLl7Z",
        "row": 18662,
        "state": "",
        "group": "",
        "group_num": [
            "reckj5n41n6NiBbyN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recLNGc80ptI5H14U",
        "row": 18638,
        "state": "",
        "group": "",
        "group_num": [
            "recX05oyDAqZrjmbl"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 204,
        "id": "recLltsLB7E94tHjN",
        "row": 18652,
        "state": "",
        "group": "",
        "group_num": [
            "recLdmZ5rC2UOE0iS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "recMPCEVzbg3xAluU",
        "row": 18649,
        "state": "",
        "group": "",
        "group_num": [
            "recR7127ECQnOY7vH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 643,
        "id": "receJa52xPwQ3q7Pd",
        "row": 18668,
        "state": "",
        "group": "",
        "group_num": [
            "reclZbn4WfC55fltE"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recgrVaXc5j5b2ZYZ",
        "row": 18666,
        "state": "",
        "group": "",
        "group_num": [
            "reczKvDr2tJNlqB1G"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 716,
        "id": "rech58iLHugKfjPHT",
        "row": 18667,
        "state": "",
        "group": "10195191",
        "group_num": [
            "recUYKLibvLjOJE2S"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2198,
        "id": "rech9iASZQmivzKLO",
        "row": 18675,
        "state": "",
        "group": "",
        "group_num": [
            "rec9jWLWEIcDcDKMb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "recjydcgBUZ1FnHar",
        "row": 18669,
        "state": "",
        "group": "",
        "group_num": [
            "recbXGTbxKNrSl4aV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "2023": 1758,
        "id": "recT6UGIBuIGyVZqJ",
        "row": 18680,
        "state": "",
        "group": "",
        "group_num": [
            "recgREY99RorCHUuC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2050,
        "2023": 2110,
        "id": "recUwsA1ID1J3Voia",
        "row": 18678,
        "state": "",
        "group": "",
        "group_num": [
            "recu2yWba9GPtVfOZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2930,
        "id": "rec0nISKYmTbWmsVt",
        "row": 18681,
        "state": "",
        "group": "",
        "group_num": [
            "recSJsKD0E17dF3FV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 616,
        "id": "rec2TECsXxjvw2vMR",
        "row": 18672,
        "state": "",
        "group": "",
        "group_num": [
            "recYMx2OX80QUIdF1"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "rec722HAgs94xyDXS",
        "row": 18671,
        "state": "",
        "group": "",
        "group_num": [
            "recbaK15SsCWICY7a"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2637,
        "2024": 1758,
        "id": "rec8mmnIyIWzkGpPx",
        "row": 18679,
        "state": "",
        "group": "",
        "group_num": [
            "recBdl508uXg3P1zX"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recb4qp6eEMg3ap56",
        "row": 18683,
        "state": "",
        "group": "10261236",
        "group_num": [
            "recKbI2O9e1ZvwFmy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recuYf6py8VDLEFMI",
        "row": 18682,
        "state": "",
        "group": "",
        "group_num": [
            "recU8QvncVHZMP7Al"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recDS738XrYpV8uDO",
        "row": 18673,
        "state": "",
        "group": "",
        "group_num": [
            "recYMx2OX80QUIdF1"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recGtWGWYubWogPKU",
        "row": 18665,
        "state": "",
        "group": "10484071",
        "group_num": [
            "recfUymmNTKWitXBv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recGyzJnPwD575K2s",
        "row": 18663,
        "state": "",
        "group": "",
        "group_num": [
            "recVwPxlnVw7O7QmZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 18,
        "id": "recGzrWqKHtq1SWbh",
        "row": 18670,
        "state": "",
        "group": "",
        "group_num": [
            "recunLfCF2qdSnaz6"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "id": "recJOJmhiGyI9q8IJ",
        "row": 18664,
        "state": "",
        "group": "10484071",
        "group_num": [
            "recfUymmNTKWitXBv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 684,
        "2024": 600,
        "id": "recKL3C3Uwl7wFe3B",
        "row": 18674,
        "state": "",
        "group": "",
        "group_num": [
            "recqS9Zz5C3Ql5YWm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recLl3LXioqV5sPI9",
        "row": 18676,
        "state": "",
        "group": "10282137",
        "group_num": [
            "rec5EV1I6plhPgWc6"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2038,
        "id": "recNfnzuDBzhEsY0n",
        "row": 18677,
        "state": "",
        "group": "",
        "group_num": [
            "rec4qOY0NlNToNGTi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "receZ5oFlVSSTtAvD",
        "row": 18693,
        "state": "",
        "group": "",
        "group_num": [
            "rec30VguRplMlmBa7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recfYVF2rXZzE3wQZ",
        "row": 18695,
        "state": "",
        "group": "",
        "group_num": [
            "recEojP9BbtAIrPJ7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2687,
        "id": "rechlk3RkTil4k1Ex",
        "row": 18716,
        "state": "",
        "group": "",
        "group_num": [
            "recUL9XPFDzllPYVR"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1758,
        "id": "reci0vQo8TgCMkph4",
        "row": 18718,
        "state": "",
        "group": "",
        "group_num": [
            "recFIC1csyeQYMv2M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "reckUZayepW3ai28B",
        "row": 18689,
        "state": "",
        "group": "",
        "group_num": [
            "recKvyZQFp2dwoGrh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 44,
        "id": "reclCCk8RQ5Z8t5KN",
        "row": 18708,
        "state": "",
        "group": "",
        "group_num": [
            "recBqJXkctmhoNlTC"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1468,
        "id": "recnsrrh3plpuSfyX",
        "row": 18690,
        "state": "",
        "group": "",
        "group_num": [
            "recSm344RFdlUkjmD"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recOj0b5BlZu7TazI",
        "row": 18719,
        "state": "",
        "group": "050630",
        "group_num": [
            "rectzjy86COXoKJaY"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 184,
        "id": "recOpMUlHKrDobuap",
        "row": 18704,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recSwQANpsLaA1jrq",
        "row": 18723,
        "state": "",
        "group": "",
        "group_num": [
            "rec2F7I8jH3OhUDpC"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 349,
        "2023": 175,
        "id": "recSKHwzdgjLdHlPc",
        "row": 18697,
        "state": "",
        "group": "",
        "group_num": [
            "recLD42iUjpXZ7HtO"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recSrJuBfGVl3T0Te",
        "row": 18714,
        "state": "",
        "group": "",
        "group_num": [
            "recG4gtddkOE5DiqR"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1465,
        "id": "recUtsDKqhRFUFfOq",
        "row": 18696,
        "state": "",
        "group": "",
        "group_num": [
            "recNIpBjQs0LElGG0"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recVgdDLfGWNphCd6",
        "row": 18720,
        "state": "",
        "group": "10261545",
        "group_num": [
            "recJIOAspCPpIGpdL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recWdpTvovIIRV2Yy",
        "row": 18731,
        "state": "",
        "group": "",
        "group_num": [
            "recREv8MT9zmEB9YE"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recXb7iytfd76aEzA",
        "row": 18727,
        "state": "",
        "group": "",
        "group_num": [
            "recYRtzWQbSqxT78n"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recXgZBc5iqVp1TjO",
        "row": 18686,
        "state": "",
        "group": "",
        "group_num": [
            "rec4OxrqDfsXx5tjO"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recY4lsZP7xo92xeE",
        "row": 18721,
        "state": "",
        "group": "10261545",
        "group_num": [
            "recJIOAspCPpIGpdL"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recYjTprRGlZFoT5o",
        "row": 18726,
        "state": "",
        "group": "10421505",
        "group_num": [
            "recgI7uaU06Gwcveq"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recYnSrlPTQ1ONhNe",
        "row": 18712,
        "state": "",
        "group": "10289561",
        "group_num": [
            "recX9hzj67ZnOnCE7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1465,
        "id": "rec28dywaR9ZXBMzY",
        "row": 18691,
        "state": "",
        "group": "",
        "group_num": [
            "recEjNT6HcqF3aWHT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec2ekVBO8oo2iBIN",
        "row": 18710,
        "state": "",
        "group": "",
        "group_num": [
            "recBqJXkctmhoNlTC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 86,
        "2023": 52,
        "id": "rec2eV6pB00gcMdgP",
        "row": 18725,
        "state": "",
        "group": "",
        "group_num": [
            "rec2F7I8jH3OhUDpC"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "2023": 1125,
        "id": "rec36K0M41j4Hjjne",
        "row": 18713,
        "state": "",
        "group": "10289562",
        "group_num": [
            "recUvev7xOT3sfsug"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rec4QXhGatox4TpT4",
        "row": 18709,
        "state": "",
        "group": "",
        "group_num": [
            "recBqJXkctmhoNlTC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "rec80SV3RhreZz9pW",
        "row": 18724,
        "state": "",
        "group": "",
        "group_num": [
            "rec2F7I8jH3OhUDpC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 991,
        "2024": 1420,
        "id": "rec8pAe4yVEnOzKDP",
        "row": 18703,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1758,
        "id": "rec9gV7Ol9Cg7bE3s",
        "row": 18684,
        "state": "",
        "group": "",
        "group_num": [
            "recf1eBkuvqDtd6dF"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 33,
        "id": "recbK64iFvpZOlSfF",
        "row": 18730,
        "state": "",
        "group": "",
        "group_num": [
            "recNhhCsJvp9XhKti"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recchjrSviZDnPnds",
        "row": 18732,
        "state": "",
        "group": "",
        "group_num": [
            "rec6GDLhSiATs5pbg"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1762,
        "id": "recocPxvdr6RboAnF",
        "row": 18711,
        "state": "",
        "group": "10289561",
        "group_num": [
            "recX9hzj67ZnOnCE7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recokeu0azjLYyejk",
        "row": 18692,
        "state": "",
        "group": "",
        "group_num": [
            "reciWs9RAfL0EMFx1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recpgd9B8r9rRf5MY",
        "row": 18699,
        "state": "",
        "group": "",
        "group_num": [
            "recuzbUcDmtHY7zlS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "2023": 1967,
        "id": "recqVkK4qltOpCNEE",
        "row": 18705,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1325,
        "2023": 1525,
        "id": "recq0TUPz9u7m3JrU",
        "row": 18702,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1758,
        "id": "recqG5zmgomvqKTdL",
        "row": 18701,
        "state": "",
        "group": "",
        "group_num": [
            "rec8wG2gv4ShAtZh1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3391,
        "id": "recqRa2vsf374RScA",
        "row": 18717,
        "state": "",
        "group": "",
        "group_num": [
            "recE2I8sXN5ghRrzx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1668,
        "id": "recs7TYfaP7wZvoVb",
        "row": 18685,
        "state": "",
        "group": "",
        "group_num": [
            "recDA509FFyGTn1af"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "rectXT1wiBVP7EOE8",
        "row": 18722,
        "state": "",
        "group": "10261545",
        "group_num": [
            "recJIOAspCPpIGpdL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recyv0tILaFRhY74h",
        "row": 18698,
        "state": "",
        "group": "",
        "group_num": [
            "recuzbUcDmtHY7zlS"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "id": "recAZ6grHKN6fGRie",
        "row": 18733,
        "state": "",
        "group": "10295703",
        "group_num": [
            "recVSSVzmRU5T9cK2"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recBoCFr4IMzPscz3",
        "row": 18707,
        "state": "",
        "group": "",
        "group_num": [
            "recBqJXkctmhoNlTC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "2023": 1758,
        "id": "recCOdujwfIbwRHSQ",
        "row": 18688,
        "state": "",
        "group": "",
        "group_num": [
            "recDqqrzXVdthATdu"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recCRpEUACC9hlmPl",
        "row": 18694,
        "state": "",
        "group": "",
        "group_num": [
            "recQVQDL3JlbvTsgz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 264,
        "2024": 297,
        "id": "recDSFlWc4llgCRqm",
        "row": 18729,
        "state": "",
        "group": "",
        "group_num": [
            "recNhhCsJvp9XhKti"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recElbwY1u3bBffB0",
        "row": 18687,
        "state": "",
        "group": "",
        "group_num": [
            "recjLuVRvgolafp4c"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "id": "recFgH8t0xTO0S4mC",
        "row": 18715,
        "state": "",
        "group": "",
        "group_num": [
            "rec4qbMqwOenvnqmH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 958,
        "2023": 967,
        "id": "recI7iAn0GjiyakA5",
        "row": 18706,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1750,
        "id": "recLdNk71BpvxQiuU",
        "row": 18700,
        "state": "",
        "group": "",
        "group_num": [
            "recuzbUcDmtHY7zlS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "2024": 295,
        "id": "recN1N6XyAAwg8gId",
        "row": 18728,
        "state": "",
        "group": "",
        "group_num": [
            "recNhhCsJvp9XhKti"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "recTCtuUHPg6GCuKO",
        "row": 18735,
        "state": "",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recUWayXHZ5BCoRz5",
        "row": 18736,
        "state": "",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recUwqZkO7qD9XXId",
        "row": 18737,
        "state": "",
        "group": "018430",
        "group_num": [
            "recxAHkFRSiODwvu6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "rec4vMfrxRxep1PdZ",
        "row": 18743,
        "state": "",
        "group": "041665",
        "group_num": [
            "recWlJXGqEyDRcLlx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recoTFRdLaZdCo7ra",
        "row": 18739,
        "state": "",
        "group": "031936",
        "group_num": [
            "rec4IW3CfL9eInXO4"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recox45fTvD7XrxrE",
        "row": 18742,
        "state": "",
        "group": "035106",
        "group_num": [
            "rec7VP4n5zPOCUXoH"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recphQCZ6cjUAijM9",
        "row": 18740,
        "state": "",
        "group": "035106",
        "group_num": [
            "rec7VP4n5zPOCUXoH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recxHj92aGx6fMuS3",
        "row": 18741,
        "state": "",
        "group": "035106",
        "group_num": [
            "rec7VP4n5zPOCUXoH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 569,
        "id": "recyQuTw8UvjLgyQG",
        "row": 18734,
        "state": "",
        "group": "001520",
        "group_num": [
            "recLJkzkVOhSRIQFx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 32,
        "id": "recA8FxaRrjdVoIsK",
        "row": 18744,
        "state": "",
        "group": "",
        "group_num": [
            "recQEnjJ83iowjOO4"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "id": "recHYnFxGxG0gZhhB",
        "row": 18738,
        "state": "",
        "group": "031936",
        "group_num": [
            "rec4IW3CfL9eInXO4"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "recgSh0zIHtqwtlBn",
        "row": 18747,
        "state": "",
        "group": "",
        "group_num": [
            "recApAlG9vINLgzPc"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recgs8dXSQaPwmIfC",
        "row": 18746,
        "state": "",
        "group": "",
        "group_num": [
            "recApAlG9vINLgzPc"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF MT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1016,
        "id": "rec2C7BV04vc26A3T",
        "row": 18745,
        "state": "",
        "group": "",
        "group_num": [
            "recApAlG9vINLgzPc"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "rec8ZcUXg9YwpmKDl",
        "row": 18750,
        "state": "",
        "group": "138690",
        "group_num": [
            "recHmTFyFb2z4buVT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF MT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 50,
        "id": "recBY2JbajqeFFzPS",
        "row": 18748,
        "state": "",
        "group": "",
        "group_num": [
            "recVFUTMQEZTduwpn"
        ],
        "procedure": "OP",
        "provider": "BCBS OF MT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recKXvbSzciC8xKFi",
        "row": 18749,
        "state": "",
        "group": "138690",
        "group_num": [
            "recHmTFyFb2z4buVT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF MT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recekHqogcVlrV3s0",
        "row": 18777,
        "state": "",
        "group": "14172597",
        "group_num": [
            "rectS52nfA9upPXoE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recjhOvxN7n05RtpI",
        "row": 18773,
        "state": "",
        "group": "14162996",
        "group_num": [
            "recsxq2chDbTHu1bh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "reckUVeyl0Y1CSoD4",
        "row": 18760,
        "state": "",
        "group": "14163502",
        "group_num": [
            "rec8OLcSy8YE4o8rz"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "reckA7WScAijSO2BO",
        "row": 18782,
        "state": "",
        "group": "",
        "group_num": [
            "recDqTcaDfy5wCIKV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "reckHYiwuHn9OeMXw",
        "row": 18776,
        "state": "",
        "group": "14172597",
        "group_num": [
            "rectS52nfA9upPXoE"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recR1ZOi1gOuBesNe",
        "row": 18780,
        "state": "",
        "group": "",
        "group_num": [
            "recTtN97FQ48Y3CUC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recRRreMpR9ShaCmZ",
        "row": 18768,
        "state": "",
        "group": "B0000003",
        "group_num": [
            "receTlBNQch9hE8gN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recRmRCniVV14ZSw7",
        "row": 18759,
        "state": "",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 674,
        "id": "recS5bGjq2Z4tXXsl",
        "row": 18754,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "id": "recT9yEsfdJlznv4w",
        "row": 18753,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recUkH6QJRLj6HmKz",
        "row": 18775,
        "state": "",
        "group": "",
        "group_num": [
            "recRFlgg6KA2pkpXD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recWKFrQeQ8sDLmFm",
        "row": 18778,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recXUB69gb3EhnxUp",
        "row": 18756,
        "state": "",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 921,
        "id": "recZsygVLwCKYOwAY",
        "row": 18771,
        "state": "",
        "group": "",
        "group_num": [
            "recjm6r01v5iDUGM1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recZLDgQOQwtkuTzL",
        "row": 18766,
        "state": "",
        "group": "",
        "group_num": [
            "rec9byRT4ps8BMTSj"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "rec1UfJ8qqCCOVCbP",
        "row": 18770,
        "state": "",
        "group": "",
        "group_num": [
            "recjm6r01v5iDUGM1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 271,
        "id": "rec2dtCitUJM7W1Xn",
        "row": 18774,
        "state": "",
        "group": "",
        "group_num": [
            "recRFlgg6KA2pkpXD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2136,
        "id": "rec30MqLRXxrNdk0I",
        "row": 18761,
        "state": "",
        "group": "14163529",
        "group_num": [
            "recMX5fy0pcUFAiVo"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 414,
        "id": "rec42Yk0ixqH6Cl78",
        "row": 18757,
        "state": "",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 795,
        "id": "rec60h7vrzzg6TbMD",
        "row": 18772,
        "state": "",
        "group": "14162996",
        "group_num": [
            "recsxq2chDbTHu1bh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3415,
        "id": "recbt4XVUT4Qka4Yn",
        "row": 18762,
        "state": "",
        "group": "14163529",
        "group_num": [
            "recMX5fy0pcUFAiVo"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "2024": 297,
        "id": "reccZYXTVveShxxay",
        "row": 18767,
        "state": "",
        "group": "",
        "group_num": [
            "recTz8amCPw8nvsLC"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4500,
        "id": "recow7pWCuQH3KfQ4",
        "row": 18769,
        "state": "",
        "group": "",
        "group_num": [
            "recqRWo7aSiAMpn9l"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 369,
        "id": "recpKzV9PesF88Jz2",
        "row": 18781,
        "state": "",
        "group": "",
        "group_num": [
            "recDqTcaDfy5wCIKV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "id": "recpP9NlP7itwY7ev",
        "row": 18779,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recpnT7Ia0RBAGzDV",
        "row": 18758,
        "state": "",
        "group": "14164190",
        "group_num": [
            "reccDukjIvDLiQIcm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "2024": 525,
        "id": "recqT84a1az3gtCPf",
        "row": 18755,
        "state": "",
        "group": "",
        "group_num": [
            "recPnOFsVoihx5GO5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 830,
        "id": "recuFQ7dE61hctGj3",
        "row": 18752,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 608,
        "id": "recB8CYpeAJOdEsnf",
        "row": 18764,
        "state": "",
        "group": "",
        "group_num": [
            "rec9byRT4ps8BMTSj"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 303,
        "id": "recEidRpbrqc6K2PZ",
        "row": 18765,
        "state": "",
        "group": "",
        "group_num": [
            "rec9byRT4ps8BMTSj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 151,
        "id": "recFtE4lM0fcaQse3",
        "row": 18783,
        "state": "",
        "group": "",
        "group_num": [
            "recDqTcaDfy5wCIKV"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recJKMADLsouOR7Jz",
        "row": 18751,
        "state": "",
        "group": "",
        "group_num": [
            "recgxYCejOoKLM5n0"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2096,
        "id": "recK7Kc0IwNOZye0X",
        "row": 18763,
        "state": "",
        "group": "",
        "group_num": [
            "rec9byRT4ps8BMTSj"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "receSAtXth8Y5ULUL",
        "row": 18802,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "id": "rechXgst1l9Uqe8Mu",
        "row": 18798,
        "state": "",
        "group": "",
        "group_num": [
            "recm4KTz3r4P9HOUt"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "reciUa0peRQlAG3ps",
        "row": 18805,
        "state": "",
        "group": "",
        "group_num": [
            "recBCiXMWRQOvENyZ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recj0dSGfA9MgG3MS",
        "row": 18831,
        "state": "",
        "group": "14166371",
        "group_num": [
            "recRKpBpuqYww4vro"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recjHLKVApwTYOiMD",
        "row": 18829,
        "state": "",
        "group": "14162738",
        "group_num": [
            "recCT3lVsti6qhoBp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 278,
        "2023": 297,
        "2024": 332,
        "id": "recjILRcrch5OArmh",
        "row": 18806,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1258,
        "2023": 1221,
        "2024": 829,
        "id": "recjIRrgmkICNcARq",
        "row": 18809,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 897,
        "id": "reckFkfSYa5cOFM0U",
        "row": 18832,
        "state": "",
        "group": "14166371",
        "group_num": [
            "recRKpBpuqYww4vro"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "reck9e3QtTtLDoS0O",
        "row": 18822,
        "state": "",
        "group": "",
        "group_num": [
            "reccspLnMZUvMt6ks"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recOxN6ZGhASrnKBA",
        "row": 18824,
        "state": "",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recQE7LP27b4JrCNw",
        "row": 18826,
        "state": "",
        "group": "",
        "group_num": [
            "recyvFRUOo09kQm64"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recRYUNSScAzEFjav",
        "row": 18789,
        "state": "",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recRCLRofK73M1jEC",
        "row": 18784,
        "state": "",
        "group": "14162444",
        "group_num": [
            "recYq9cIK1qxeLu02"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recRFBUPOTMUmhQRb",
        "row": 18801,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2011,
        "2023": 4409,
        "id": "recSFjexrjrMMufVt",
        "row": 18813,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recSPMx0zHN6l2a5R",
        "row": 18795,
        "state": "",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recSq3wINGhCVsXQU",
        "row": 18814,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recWTDHADNUAFlMq5",
        "row": 18788,
        "state": "",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recWTfDC8xvQoEiM2",
        "row": 18833,
        "state": "",
        "group": "",
        "group_num": [
            "rec11HWY2GbuhHiDP"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 129,
        "2023": 217,
        "id": "recWUPh9EzhC95KSe",
        "row": 18808,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "2024": 829,
        "id": "recXOxk9vgS14vPMd",
        "row": 18818,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 260,
        "id": "recYF9OSbxKA89Fdk",
        "row": 18792,
        "state": "",
        "group": "",
        "group_num": [
            "recBf7SPRWJZJV87y"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5233,
        "2023": 829,
        "2024": 829,
        "id": "recYJqSztgngTjJLV",
        "row": 18819,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 297,
        "id": "recZqg4XDQe1dy2oa",
        "row": 18815,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 223,
        "id": "rec0axsYfW54UTGSl",
        "row": 18830,
        "state": "",
        "group": "",
        "group_num": [
            "recWjfvxYbr6LiLBx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rec50qW6DYgzuTuo8",
        "row": 18794,
        "state": "",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 607,
        "id": "rec6VXmAJbYU2Me41",
        "row": 18799,
        "state": "",
        "group": "",
        "group_num": [
            "recm4KTz3r4P9HOUt"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "rec8PLXjzbbH5T1xa",
        "row": 18828,
        "state": "",
        "group": "14160729",
        "group_num": [
            "recyHHjQOxaVj1N35"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 242,
        "2023": 220,
        "2024": 277,
        "id": "recaUdYXhVYkirKcq",
        "row": 18807,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recbUXlcDLTUKVI9q",
        "row": 18791,
        "state": "",
        "group": "14168500",
        "group_num": [
            "recpgdaOQt2dT5TxD"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "reccwRbdoXcUbtCzd",
        "row": 18823,
        "state": "",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 279,
        "id": "recoyNBynFtkmB3ac",
        "row": 18804,
        "state": "",
        "group": "",
        "group_num": [
            "recBCiXMWRQOvENyZ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 281,
        "id": "recoj5Q9gqhxIAZDx",
        "row": 18821,
        "state": "",
        "group": "",
        "group_num": [
            "reccspLnMZUvMt6ks"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4755,
        "2023": 2208,
        "2024": 829,
        "id": "recpmFeDVtrh8i4rC",
        "row": 18810,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 248,
        "id": "recqdNJw6NLwh4Ihy",
        "row": 18797,
        "state": "",
        "group": "",
        "group_num": [
            "recm4KTz3r4P9HOUt"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recsM2LJZVzEnNxWR",
        "row": 18787,
        "state": "",
        "group": "14164473",
        "group_num": [
            "recbzSq7khRhhRvU1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recsi0XBJ6yma1qw0",
        "row": 18790,
        "state": "",
        "group": "14168500",
        "group_num": [
            "recpgdaOQt2dT5TxD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recv86sWnrSFVAVVZ",
        "row": 18811,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1165,
        "2023": 829,
        "id": "recy2MbvyioFJKniT",
        "row": 18812,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recB1cxasnw0dxgNR",
        "row": 18796,
        "state": "",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 189,
        "id": "recCSd6HVWcHZlex9",
        "row": 18803,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 290,
        "2024": 339,
        "id": "recC0jom3cTOTC1SY",
        "row": 18816,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 36,
        "id": "recC5AfFmpUZ8J5QY",
        "row": 18827,
        "state": "",
        "group": "",
        "group_num": [
            "recyvFRUOo09kQm64"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "2024": 297,
        "id": "recC7KeNlJBImfJAt",
        "row": 18786,
        "state": "",
        "group": "",
        "group_num": [
            "recT51SPawPVneftt"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recJFYKAH8aTKmjIw",
        "row": 18793,
        "state": "",
        "group": "14164165",
        "group_num": [
            "rech1d9HrL5gGu2yO"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "2023": 297,
        "id": "recJH33yNUWPlhyvH",
        "row": 18800,
        "state": "",
        "group": "",
        "group_num": [
            "recpfdHvZo8jeEmOd"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 134,
        "2023": 127,
        "id": "recLC1oljTUo5sPMN",
        "row": 18817,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recLQzjGbKGASsmO1",
        "row": 18820,
        "state": "",
        "group": "B0000002",
        "group_num": [
            "recsadtX6iWCnXFeA"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5354,
        "id": "recMH9CbCzUho92nC",
        "row": 18825,
        "state": "",
        "group": "14157048",
        "group_num": [
            "recx3raikebycJ0L0"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recNi2e0QkBYIXbtk",
        "row": 18785,
        "state": "",
        "group": "14162444",
        "group_num": [
            "recYq9cIK1qxeLu02"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "receSSEAeyhk03R6z",
        "row": 18844,
        "state": "",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recfewbu0jlb2pH6Q",
        "row": 18854,
        "state": "",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recjFdw4lEkuC8M2G",
        "row": 18842,
        "state": "",
        "group": "",
        "group_num": [
            "recoF3owr8MZeQuBo"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recm48yWNZh8697c5",
        "row": 18843,
        "state": "",
        "group": "",
        "group_num": [
            "recoF3owr8MZeQuBo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recn8Y2uakRspxip3",
        "row": 18849,
        "state": "",
        "group": "14174677",
        "group_num": [
            "recDRJjvF6a8AaKak"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recPd1GkJuZaozHjs",
        "row": 18836,
        "state": "",
        "group": "14167642",
        "group_num": [
            "recseiyiy76prHmzH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recR05bbnkpbzmlsR",
        "row": 18859,
        "state": "",
        "group": "",
        "group_num": [
            "recjCmW9Zu8PtOxH0"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recRNNmAxTP4DzlHQ",
        "row": 18850,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recZi9iCGdV0HVFu9",
        "row": 18840,
        "state": "",
        "group": "14171437",
        "group_num": [
            "recUi5bTq6apcVbbH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "id": "rec1aq7UccJgtjcUP",
        "row": 18858,
        "state": "",
        "group": "",
        "group_num": [
            "recjCmW9Zu8PtOxH0"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 24,
        "id": "rec29cUvPtXg1BG7C",
        "row": 18861,
        "state": "",
        "group": "",
        "group_num": [
            "recCSOF5zPYdTRmzI"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "rec4HlxuoVP0C3jix",
        "row": 18846,
        "state": "",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 303,
        "id": "rec4LKv3vyuMe9WVS",
        "row": 18834,
        "state": "",
        "group": "",
        "group_num": [
            "recDp0eXB2jkyP4eS"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1265,
        "id": "rec4q2TbLzGn5TnZW",
        "row": 18848,
        "state": "",
        "group": "14174677",
        "group_num": [
            "recDRJjvF6a8AaKak"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1640,
        "id": "rec5tKc4REH5vjFqU",
        "row": 18860,
        "state": "",
        "group": "",
        "group_num": [
            "recCSOF5zPYdTRmzI"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "id": "rec5ItmdMu0IYcAHz",
        "row": 18835,
        "state": "",
        "group": "",
        "group_num": [
            "recDp0eXB2jkyP4eS"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recaVL8SBQuJUH51s",
        "row": 18851,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 257,
        "id": "recpSTYtNM8op0JcW",
        "row": 18839,
        "state": "",
        "group": "",
        "group_num": [
            "receDDHqAMWm15XXS"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4500,
        "id": "recp98W3LV1yRgCKS",
        "row": 18864,
        "state": "",
        "group": "",
        "group_num": [
            "recxVVQntBF7BbdE1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recqqmTjnwKwgyKPC",
        "row": 18847,
        "state": "",
        "group": "14174677",
        "group_num": [
            "recDRJjvF6a8AaKak"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "2024": 297,
        "id": "recrbAb4omtgNqrr7",
        "row": 18853,
        "state": "",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 829,
        "id": "recuXKgdMNon8JqTS",
        "row": 18845,
        "state": "",
        "group": "14174523",
        "group_num": [
            "recxgbeRnRsfdkGLh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recwj8irRHQrTaiJ5",
        "row": 18852,
        "state": "",
        "group": "B0000001",
        "group_num": [
            "rec86W0nruE9ODs1M"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "2024": 1800,
        "id": "recyZEPnu5lKq9ROJ",
        "row": 18863,
        "state": "",
        "group": "W12600M001",
        "group_num": [
            "recRxGiPHwFE07WFr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recBXaQeKDFdNrsym",
        "row": 18841,
        "state": "",
        "group": "14172079",
        "group_num": [
            "recBuVZJcODJkCKWY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 830,
        "2023": 1303,
        "id": "recER7l6uPoBeFidP",
        "row": 18856,
        "state": "",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recFwBT2bayOiWgPk",
        "row": 18862,
        "state": "",
        "group": "",
        "group_num": [
            "reciuuc6dFeWyBYF5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "recFmdWT8N8R5d930",
        "row": 18855,
        "state": "",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "OP",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "2023": 858,
        "2024": 829,
        "id": "recHJVbRYDt9eISov",
        "row": 18857,
        "state": "",
        "group": "14170742",
        "group_num": [
            "recZQREs0snAmAfsj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recKpQXXOSWtS5Yd4",
        "row": 18837,
        "state": "",
        "group": "14167642",
        "group_num": [
            "recseiyiy76prHmzH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recNYuY4wqzTMEyYg",
        "row": 18838,
        "state": "",
        "group": "14167642",
        "group_num": [
            "recseiyiy76prHmzH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "2023": 4395,
        "id": "recf46S8YprsBTizw",
        "row": 18870,
        "state": "",
        "group": "",
        "group_num": [
            "recZkqUu5jp9SjBVP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1158,
        "id": "recglfDuoQsmH3X1D",
        "row": 18866,
        "state": "",
        "group": "10350478",
        "group_num": [
            "recFZ2Yd87yDQ4cND"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 973,
        "id": "recjFJTFkbc47mHwQ",
        "row": 18869,
        "state": "",
        "group": "10353824",
        "group_num": [
            "recFGR0wt8U1GZjlp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "2023": 4395,
        "id": "rec5s12K7WWFnVt2f",
        "row": 18871,
        "state": "",
        "group": "",
        "group_num": [
            "recigRYuGmeXTNazx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 447,
        "2024": 447,
        "id": "reco8JX6XadCxUGcb",
        "row": 18868,
        "state": "",
        "group": "10353824",
        "group_num": [
            "recFGR0wt8U1GZjlp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 4800,
        "id": "recqgBtQatrlCdvvP",
        "row": 18867,
        "state": "",
        "group": "10350478",
        "group_num": [
            "recFZ2Yd87yDQ4cND"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 315,
        "id": "rect2EasoAm5LUYgi",
        "row": 18865,
        "state": "",
        "group": "",
        "group_num": [
            "recvuyxzk5Jz1EhiG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF ND ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "reclC3jjKFU0CItbT",
        "row": 18877,
        "state": "",
        "group": "",
        "group_num": [
            "recU8h0HxmYOxpF7L"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recUCnlKhCppQNR4x",
        "row": 18876,
        "state": "",
        "group": "103556002",
        "group_num": [
            "recZbzewteJvLuxb9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "rec0gt6kQqi6rsn0p",
        "row": 18878,
        "state": "",
        "group": "",
        "group_num": [
            "recMMKkuNimpJ8u0W"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recahswDsmH5lGqvw",
        "row": 18873,
        "state": "",
        "group": "100724002",
        "group_num": [
            "recAti7LSYdo8tSBB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recwmBz6jia9tZe2x",
        "row": 18875,
        "state": "",
        "group": "103556002",
        "group_num": [
            "recZbzewteJvLuxb9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 210,
        "id": "recLw2BukRTn4cmiu",
        "row": 18872,
        "state": "",
        "group": "478965",
        "group_num": [
            "recn1vaPeV113cvJz"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recM8cBCkWPu2Wk7m",
        "row": 18874,
        "state": "",
        "group": "101935002",
        "group_num": [
            "recyXAqc7N91z8L6F"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2007,
        "id": "recQAbfdEM5cwxWz3",
        "row": 18881,
        "state": "",
        "group": "N13794",
        "group_num": [
            "rec0Cfm3buVdFQ19Q"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 642,
        "id": "rec0uzGkEwQI4WZEu",
        "row": 18882,
        "state": "",
        "group": "",
        "group_num": [
            "recP01qkvsIosIl1v"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 758,
        "id": "rec03HQarSpTvuGUQ",
        "row": 18883,
        "state": "",
        "group": "",
        "group_num": [
            "recP01qkvsIosIl1v"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 829,
        "id": "recbXifmLQ5XHlJaD",
        "row": 18879,
        "state": "",
        "group": "",
        "group_num": [
            "recHDtxgvw97v9crL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 742,
        "id": "recL6AjxEMlHbHQum",
        "row": 18880,
        "state": "",
        "group": "N13794",
        "group_num": [
            "rec0Cfm3buVdFQ19Q"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2900,
        "id": "recguhVqP7Oci1olo",
        "row": 18890,
        "state": "",
        "group": "",
        "group_num": [
            "recg5lNUhWAtb4XZW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "2024": 553,
        "id": "reclKmGF7MSTmkJat",
        "row": 18884,
        "state": "",
        "group": "",
        "group_num": [
            "rec5QBG4a3CjeDjnY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 772,
        "id": "rec333GHF7dyHEIsS",
        "row": 18891,
        "state": "",
        "group": "",
        "group_num": [
            "recauLZJxUzlqMpWv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "id": "recbAcCgfbWM66E0e",
        "row": 18889,
        "state": "",
        "group": "",
        "group_num": [
            "recg5lNUhWAtb4XZW"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 749,
        "id": "recF9bx0FCLfFp4tX",
        "row": 18887,
        "state": "",
        "group": "",
        "group_num": [
            "recxJsiyZtPWOEgYQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recHJqaDvxqPtQ8ei",
        "row": 18888,
        "state": "",
        "group": "",
        "group_num": [
            "recEZtYd4lpRESp3r"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 755,
        "id": "recJ5vGzmlI50FdK4",
        "row": 18886,
        "state": "",
        "group": "",
        "group_num": [
            "recxJsiyZtPWOEgYQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 767,
        "id": "recKswl9KHg4JPiPW",
        "row": 18892,
        "state": "",
        "group": "",
        "group_num": [
            "recauLZJxUzlqMpWv"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 271,
        "id": "recMtBs1dKab5FSKx",
        "row": 18885,
        "state": "",
        "group": "",
        "group_num": [
            "rec5QBG4a3CjeDjnY"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF NM ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "2024": 300,
        "id": "recgLszKdyqX2x10L",
        "row": 18911,
        "state": "",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "rechIxMRPizwdupHI",
        "row": 18897,
        "state": "",
        "group": "YNS017",
        "group_num": [
            "recSI60q2K4jQ6ecU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1235,
        "id": "recl6buWtPhlJ2I26",
        "row": 18904,
        "state": "",
        "group": "",
        "group_num": [
            "recr7A9IYVDOu8UjM"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 894,
        "id": "recl9CX4ppdnBsMhI",
        "row": 18919,
        "state": "",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 531,
        "id": "recmIcrHH20rM1c7f",
        "row": 18921,
        "state": "",
        "group": "",
        "group_num": [
            "reclpsUW7qE7Ic9LH"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recOthL1oPfGUtnxD",
        "row": 18893,
        "state": "",
        "group": "",
        "group_num": [
            "recWYL91e4E2agmwC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recWSyXPTcPY0OHtE",
        "row": 18922,
        "state": "",
        "group": "",
        "group_num": [
            "reclpsUW7qE7Ic9LH"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recXhIIKPwK2yHPeo",
        "row": 18902,
        "state": "",
        "group": "",
        "group_num": [
            "recjLUT2Ioh1dSUMP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "2023": 2086,
        "id": "rec1dxjQUscapCkbx",
        "row": 18915,
        "state": "",
        "group": "OG1800",
        "group_num": [
            "recBky7nTdVR38skH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 894,
        "id": "rec2DirESTqpGRhua",
        "row": 18907,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "rec3yUuyM1ZBEZv8x",
        "row": 18908,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "rec5ajNZIVEqggsN5",
        "row": 18896,
        "state": "",
        "group": "YN9744",
        "group_num": [
            "recnN4Ga1uktFIZdT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "rec6uHboTUzlo9p1e",
        "row": 18900,
        "state": "",
        "group": "235718",
        "group_num": [
            "reckDEWhUsi53z0VB"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1040,
        "id": "rec6zRub09lxjNcI7",
        "row": 18929,
        "state": "",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "2023": 1047,
        "id": "rec7RSRYt3GhmjSgO",
        "row": 18913,
        "state": "",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec78QdXCX097VB5c",
        "row": 18906,
        "state": "",
        "group": "Y06940",
        "group_num": [
            "recqCHK79t8u2gmqU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 357,
        "2024": 357,
        "id": "rec8iXriBKx0WngWv",
        "row": 18899,
        "state": "",
        "group": "",
        "group_num": [
            "recMswxG1NUH4IDhS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 544,
        "id": "rec8ke4YpFJKt9IIb",
        "row": 18910,
        "state": "",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "reccRCel2VcNUgrqF",
        "row": 18894,
        "state": "",
        "group": "YN9744",
        "group_num": [
            "recnN4Ga1uktFIZdT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 716,
        "id": "reccd9Ryq2vzbL2py",
        "row": 18898,
        "state": "",
        "group": "YNS017",
        "group_num": [
            "recSI60q2K4jQ6ecU"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 238,
        "id": "recrEa7JTExKHDgXc",
        "row": 18905,
        "state": "",
        "group": "Y06940",
        "group_num": [
            "recqCHK79t8u2gmqU"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 236,
        "id": "recstuyU1D6b4Vd1t",
        "row": 18918,
        "state": "",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "OP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "recsvbGmv410hTwwF",
        "row": 18920,
        "state": "",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 211,
        "id": "recu36BTkwMxkjEBC",
        "row": 18927,
        "state": "",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "OP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 894,
        "id": "recu9veVG9tVvmEwm",
        "row": 18923,
        "state": "",
        "group": "",
        "group_num": [
            "reclpsUW7qE7Ic9LH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 761,
        "id": "recxWdXdXbIc9CuHZ",
        "row": 18924,
        "state": "",
        "group": "",
        "group_num": [
            "reclpsUW7qE7Ic9LH"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "id": "recxX1XsD9VrwoYA2",
        "row": 18914,
        "state": "",
        "group": "OG1800",
        "group_num": [
            "recBky7nTdVR38skH"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 767,
        "2023": 866,
        "id": "recA2FUAthvapHAqE",
        "row": 18928,
        "state": "",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "2023": 553,
        "id": "recC7LJRQOUbpvT7f",
        "row": 18916,
        "state": "",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 371,
        "2023": 300,
        "id": "recEUTNvwenMa89vV",
        "row": 18926,
        "state": "",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 629,
        "2023": 542,
        "id": "recFhyOT9FMEOobC5",
        "row": 18925,
        "state": "",
        "group": "OS1802",
        "group_num": [
            "recDOBKY79hlZFRJ9"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recFkTud8bg36IV7E",
        "row": 18895,
        "state": "",
        "group": "YN9744",
        "group_num": [
            "recnN4Ga1uktFIZdT"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 370,
        "2024": 400,
        "id": "recFmKCrjhdRGwrZ0",
        "row": 18903,
        "state": "",
        "group": "",
        "group_num": [
            "recr7A9IYVDOu8UjM"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 312,
        "2023": 291,
        "id": "recI21P9kY5ccOqHt",
        "row": 18917,
        "state": "",
        "group": "OS1121",
        "group_num": [
            "recQNgxjUsPFtQ9A1"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 871,
        "id": "recLcq2BVq2T9I3TN",
        "row": 18912,
        "state": "",
        "group": "OB1803",
        "group_num": [
            "recH06Xy7U3Jj0UDk"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recMSXsWiKwvCMXxC",
        "row": 18909,
        "state": "",
        "group": "",
        "group_num": [
            "rec6kriDFLJIw9Qc7"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 833,
        "id": "recNLpVBjJHWADmxQ",
        "row": 18901,
        "state": "",
        "group": "235718",
        "group_num": [
            "reckDEWhUsi53z0VB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF OK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "id": "recesMjZnYqzkIWTG",
        "row": 18931,
        "state": "",
        "group": "01002693",
        "group_num": [
            "rec4anJsSCXuzTa9P"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF RI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "id": "receQpKdygufjZV7Y",
        "row": 18933,
        "state": "",
        "group": "",
        "group_num": [
            "recJlET2bFfD6YCZG"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF RI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 676,
        "id": "rec2YppidDpTg09zX",
        "row": 18932,
        "state": "",
        "group": "01002693",
        "group_num": [
            "rec4anJsSCXuzTa9P"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF RI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "reczTD0YAafg7jmqx",
        "row": 18930,
        "state": "",
        "group": "0000CITY",
        "group_num": [
            "rec02RMUO3vArTWPj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF RI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 445,
        "id": "rec1c5rLTW08b3pwa",
        "row": 18934,
        "state": "",
        "group": "",
        "group_num": [
            "recJlET2bFfD6YCZG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF RI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 676,
        "id": "recukJ5yQTT5QNcF2",
        "row": 18935,
        "state": "",
        "group": "",
        "group_num": [
            "recJlET2bFfD6YCZG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF RI ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "receqXDs9RKw46B4C",
        "row": 18940,
        "state": "",
        "group": "715976K00",
        "group_num": [
            "recpS5xRV5axgOn4Z"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 888,
        "id": "recf2YOHymx87oAjC",
        "row": 18937,
        "state": "",
        "group": "",
        "group_num": [
            "recSBusZkTFNJnu7G"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "reckli7McnRfBSSmB",
        "row": 18961,
        "state": "",
        "group": "",
        "group_num": [
            "reclYeStFoUJdD6lR"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "id": "reckp8qNdCmXUEXIy",
        "row": 18939,
        "state": "",
        "group": "715976K00",
        "group_num": [
            "recpS5xRV5axgOn4Z"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "reclGzNafONviBRxV",
        "row": 18960,
        "state": "",
        "group": "",
        "group_num": [
            "reclYeStFoUJdD6lR"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3523,
        "id": "recmWyArLUPsBAeMZ",
        "row": 18945,
        "state": "",
        "group": "",
        "group_num": [
            "recqvJxxrEBRWREMG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "2024": 135,
        "id": "recnjqRBhdnahvB9I",
        "row": 18972,
        "state": "",
        "group": "",
        "group_num": [
            "recyw18YkuQRxuTBR"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recnkoL4bNsHCfZfc",
        "row": 18964,
        "state": "",
        "group": "708540402",
        "group_num": [
            "recU4PR6KC6QRVVxG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1568,
        "id": "recQhejcsOn6ntxMt",
        "row": 18953,
        "state": "",
        "group": "",
        "group_num": [
            "recAPHM8Wo03OlAAc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 951,
        "id": "recRyCC0Ktm1PnXcN",
        "row": 18973,
        "state": "",
        "group": "93BA11310",
        "group_num": [
            "rec9Lm0zLX3OIP9vc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2175,
        "id": "recRdgT9RrgXDjoHx",
        "row": 18941,
        "state": "",
        "group": "716071908",
        "group_num": [
            "recptBOYAPnSQfWSd"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1260,
        "id": "recSEHGbj6hQ4wcww",
        "row": 18936,
        "state": "",
        "group": "",
        "group_num": [
            "recSBusZkTFNJnu7G"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "id": "recSjQTzhguPe55LC",
        "row": 18942,
        "state": "",
        "group": "716071917",
        "group_num": [
            "recH1UrPzJbfsyD2i"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recXxyJIvSMMzoPy3",
        "row": 18948,
        "state": "",
        "group": "716070718",
        "group_num": [
            "recwQJXjYUUXWZFd8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2266,
        "id": "rec0W7jcggZeVsZPa",
        "row": 18969,
        "state": "",
        "group": "002132100",
        "group_num": [
            "recfWWW9WrjntIw9O"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 405,
        "id": "rec0Lq4hKaM59rNqB",
        "row": 18968,
        "state": "",
        "group": "",
        "group_num": [
            "recY4f9myVZoH2jXr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rec1I9ttiGaiGxFLU",
        "row": 18950,
        "state": "",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "rec4yr1C12NltwnCg",
        "row": 18965,
        "state": "",
        "group": "708540402",
        "group_num": [
            "recU4PR6KC6QRVVxG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2951,
        "2023": 3000,
        "id": "rec6cJrUCgNAU567p",
        "row": 18954,
        "state": "",
        "group": "",
        "group_num": [
            "recdBQEkXcGIks5tB"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "2023": 230,
        "id": "rec7RFdDwFRTdzJlK",
        "row": 18958,
        "state": "",
        "group": "716030204",
        "group_num": [
            "recBj2gQdpJ8pkCNz"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 294,
        "id": "reccEUczwQxMZLVYN",
        "row": 18962,
        "state": "",
        "group": "715940401",
        "group_num": [
            "recg3wpTup61zHAhG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 799,
        "id": "recdyzHBIVgtMR3wI",
        "row": 18971,
        "state": "",
        "group": "002135200",
        "group_num": [
            "recNXI9Wfb4ZrBfKw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 1427,
        "id": "recdnOsSBYt7yCFQF",
        "row": 18947,
        "state": "",
        "group": "",
        "group_num": [
            "recQUvlpv3u3DPNdV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recpPEwHycS9MBsRa",
        "row": 18938,
        "state": "",
        "group": "716001829",
        "group_num": [
            "recb3Io2KejzW4kuG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1892,
        "2023": 1800,
        "id": "recscpTu4yocm3jwq",
        "row": 18955,
        "state": "",
        "group": "",
        "group_num": [
            "recdBQEkXcGIks5tB"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recv6OM1wPUzGnI3B",
        "row": 18967,
        "state": "",
        "group": "002030610",
        "group_num": [
            "rec5smU1jHB6b77uE"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1708,
        "id": "recwCaRFhj7AQDt63",
        "row": 18957,
        "state": "",
        "group": "",
        "group_num": [
            "rectMxneMWqTx8CPo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "id": "recxsFtsVqdgVotbZ",
        "row": 18959,
        "state": "",
        "group": "716030204",
        "group_num": [
            "recBj2gQdpJ8pkCNz"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 914,
        "id": "recx9cRk5u41BF4uB",
        "row": 18944,
        "state": "",
        "group": "",
        "group_num": [
            "recrVFXrDOqalkzHn"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "reczWkPbEMIlkzoHN",
        "row": 18974,
        "state": "",
        "group": "93BA11310",
        "group_num": [
            "rec9Lm0zLX3OIP9vc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3000,
        "id": "recAHJk29rEZC64KQ",
        "row": 18949,
        "state": "",
        "group": "",
        "group_num": [
            "recqRWo7aSiAMpn9l"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 393,
        "id": "recBMEw41hi0QaFul",
        "row": 18963,
        "state": "",
        "group": "",
        "group_num": [
            "recYgeWJNdmKOjkTT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 888,
        "id": "recCXDiG6UDbcun6f",
        "row": 18952,
        "state": "",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1738,
        "id": "recEkashLMbDRFckO",
        "row": 18966,
        "state": "",
        "group": "716029942",
        "group_num": [
            "recxKC5wOeROZbxNe"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1317,
        "id": "recErDHuFkVvsMsyw",
        "row": 18943,
        "state": "",
        "group": "",
        "group_num": [
            "recrVFXrDOqalkzHn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 519,
        "id": "recHjQsCmY5dpfiZY",
        "row": 18946,
        "state": "",
        "group": "",
        "group_num": [
            "recW29M3cZ4q85XuG"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 396,
        "id": "recJhKqwSijnGSk7I",
        "row": 18970,
        "state": "",
        "group": "002135200",
        "group_num": [
            "recNXI9Wfb4ZrBfKw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 952,
        "id": "recLYrlH5pwhx8YP6",
        "row": 18951,
        "state": "",
        "group": "718468213",
        "group_num": [
            "recl4te9XChe9mpEi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2296,
        "id": "recM29dFZ0X8hDeJk",
        "row": 18956,
        "state": "",
        "group": "",
        "group_num": [
            "rectMxneMWqTx8CPo"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF SC ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1284,
        "2023": 952,
        "id": "recgqxUHJP3BhDNeQ",
        "row": 18980,
        "state": "",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recZFY1XjRHUj9Y8j",
        "row": 18981,
        "state": "",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1175,
        "id": "rec7sYWLHmwDNig9U",
        "row": 18976,
        "state": "",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 480,
        "id": "rec9A8QZLraJj8hL4",
        "row": 18978,
        "state": "",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recczN75DMQaKbnW4",
        "row": 18983,
        "state": "",
        "group": "130499",
        "group_num": [
            "recBI5M4GaAn73z9X"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recdwG4TsDCPVHMiO",
        "row": 18975,
        "state": "",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recpAJ4ffo6o6dv3s",
        "row": 18982,
        "state": "",
        "group": "130499",
        "group_num": [
            "recBI5M4GaAn73z9X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 313,
        "id": "reczYL9KCASnEBg5b",
        "row": 18979,
        "state": "",
        "group": "82020",
        "group_num": [
            "recinJuXdulCKwfeV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recDjZIIkxPh6JXvQ",
        "row": 18977,
        "state": "",
        "group": "125211",
        "group_num": [
            "recUzZVrPqKZrCzXn"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "receL4RqhpfWweCuK",
        "row": 18996,
        "state": "",
        "group": "139138",
        "group_num": [
            "recvG7kmT36zZ5CC6"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recfw08OsJNOOeWGF",
        "row": 18994,
        "state": "",
        "group": "131937",
        "group_num": [
            "rec94tr6uA0WSEhmK"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 127,
        "2023": 127,
        "id": "recg28QeCjLhiOKVE",
        "row": 18998,
        "state": "",
        "group": "",
        "group_num": [
            "receiOo7Op4ETaiot"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "reciWJgIf8oFCewzl",
        "row": 18992,
        "state": "",
        "group": "100740",
        "group_num": [
            "recCWROII3kJIr0Qi"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recinecZ2r0qtRnBZ",
        "row": 18984,
        "state": "",
        "group": "82333",
        "group_num": [
            "recayaMyEfJpiAiZx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 22,
        "id": "recjKcYjqBFvff8Qt",
        "row": 18999,
        "state": "",
        "group": "",
        "group_num": [
            "receiOo7Op4ETaiot"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recTytLRbE3l3o8BG",
        "row": 18997,
        "state": "",
        "group": "139138",
        "group_num": [
            "recvG7kmT36zZ5CC6"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "recUCfha5P0gM6VUN",
        "row": 18991,
        "state": "",
        "group": "100740",
        "group_num": [
            "recCWROII3kJIr0Qi"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec2YqFBFBmEw6xSb",
        "row": 18985,
        "state": "",
        "group": "82333",
        "group_num": [
            "recayaMyEfJpiAiZx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec3KjzCQeDKt9L6X",
        "row": 18990,
        "state": "",
        "group": "130461",
        "group_num": [
            "reciKkxGm23NSsYIA"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1803,
        "id": "rec9w4Mahh0JIkowj",
        "row": 18988,
        "state": "",
        "group": "270213OFM7",
        "group_num": [
            "recIJoisaNJEihlZN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2184,
        "id": "rec9GA2SvKVLILj3A",
        "row": 18989,
        "state": "",
        "group": "130461",
        "group_num": [
            "reciKkxGm23NSsYIA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recq84PSnb5C1N4lj",
        "row": 18993,
        "state": "",
        "group": "106149",
        "group_num": [
            "recSJzIRke938LdFa"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recEpi4rXx822R5aD",
        "row": 18986,
        "state": "",
        "group": "",
        "group_num": [
            "rech5G7qWSNQd9lRF"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "recIZknBfrbTIvbSF",
        "row": 18987,
        "state": "",
        "group": "",
        "group_num": [
            "rech5G7qWSNQd9lRF"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recMFHHDJ914GGZ4l",
        "row": 18995,
        "state": "",
        "group": "139138",
        "group_num": [
            "recvG7kmT36zZ5CC6"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TN ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 980,
        "id": "recitCDt2BNGXcKAk",
        "row": 19020,
        "state": "",
        "group": "239465",
        "group_num": [
            "recjKgSeOe2vla8zW"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "id": "recigdbZk2sQCCaWm",
        "row": 19017,
        "state": "",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1756,
        "id": "recjA2CeaxrcmoTvn",
        "row": 19007,
        "state": "",
        "group": "089524",
        "group_num": [
            "recWMApAbTI2ovGm7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recjFiss1Fp6IJV9V",
        "row": 19000,
        "state": "",
        "group": "300368",
        "group_num": [
            "recV5AzLM1jNxXEV5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 116,
        "2023": 767,
        "id": "reckFAM4ks36MNveJ",
        "row": 19014,
        "state": "",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 357,
        "2024": 357,
        "id": "reckMKVHxwcVzZ0hA",
        "row": 19009,
        "state": "",
        "group": "",
        "group_num": [
            "recifiLbHbR5ZYuyA"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2154,
        "2024": 2033,
        "id": "reck7JLNJw5tSGcxr",
        "row": 19033,
        "state": "",
        "group": "",
        "group_num": [
            "rec8QVPzBhXwwWKwj"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 426,
        "id": "reclTvyViTMTCCck8",
        "row": 19023,
        "state": "",
        "group": "",
        "group_num": [
            "rec3FhEKe3cpNMZ1Y"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1580,
        "2023": 320,
        "id": "recmsMpPEQPzuvmzu",
        "row": 19002,
        "state": "",
        "group": "271103",
        "group_num": [
            "recEAY3lAModCzPjf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 927,
        "id": "recmdzgFXu2zkCUE3",
        "row": 19019,
        "state": "",
        "group": "239465",
        "group_num": [
            "recjKgSeOe2vla8zW"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 470,
        "2023": 500,
        "id": "recPpUjHSEYQTvLqC",
        "row": 19031,
        "state": "",
        "group": "",
        "group_num": [
            "recAiHbS1lCvdtBEu"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "id": "recQg0MZxOYsIAbTM",
        "row": 19016,
        "state": "",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "recRtRAoGdyt5fBan",
        "row": 19003,
        "state": "",
        "group": "",
        "group_num": [
            "reckz4QJgbUBH9hXR"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1550,
        "2024": 1550,
        "id": "recRupRDELe1YVWpN",
        "row": 19026,
        "state": "",
        "group": "",
        "group_num": [
            "rec8wZgmURrIwDsjC"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 129,
        "id": "recZxUqpIcpDdd3zG",
        "row": 19008,
        "state": "",
        "group": "",
        "group_num": [
            "recifiLbHbR5ZYuyA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1435,
        "id": "recZCfdXLGCPmTYbL",
        "row": 19013,
        "state": "",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2523,
        "2023": 195,
        "id": "recZ3DkTQAin2pmEx",
        "row": 19011,
        "state": "",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "rec3GDCjwRYoaRdHj",
        "row": 19005,
        "state": "",
        "group": "",
        "group_num": [
            "reckz4QJgbUBH9hXR"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rec46Zmj5ti48dFSG",
        "row": 19025,
        "state": "",
        "group": "",
        "group_num": [
            "recUyNbQcXLsX0UFj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recoT7ZYudCOh3nKU",
        "row": 19018,
        "state": "",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1031,
        "2024": 1608,
        "id": "recsIDxyITtJP24zR",
        "row": 19027,
        "state": "",
        "group": "",
        "group_num": [
            "rec8wZgmURrIwDsjC"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recwu6gO5OTYoqdFa",
        "row": 19021,
        "state": "",
        "group": "",
        "group_num": [
            "recLN7aLMrtSbmyBb"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recxORjM4ZKXHjx5s",
        "row": 19001,
        "state": "",
        "group": "300368",
        "group_num": [
            "recV5AzLM1jNxXEV5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "id": "recyQRD2iJYXCvyz5",
        "row": 19022,
        "state": "",
        "group": "",
        "group_num": [
            "rec3FhEKe3cpNMZ1Y"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 159,
        "id": "recBTZsNxSLSOBsfh",
        "row": 19004,
        "state": "",
        "group": "",
        "group_num": [
            "reckz4QJgbUBH9hXR"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recBVifNmgx97jgXR",
        "row": 19015,
        "state": "",
        "group": "169027",
        "group_num": [
            "reco4RqQBw3K8hvzP"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1854,
        "id": "recBXpRpH1UScfG3i",
        "row": 19006,
        "state": "",
        "group": "275620",
        "group_num": [
            "reclml0al9jrmPbFN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recCIsqzroktPBOoM",
        "row": 19032,
        "state": "",
        "group": "203456",
        "group_num": [
            "recPFYV40kNVmChD3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1420,
        "id": "recEcdz7GTjMhNBIT",
        "row": 19030,
        "state": "",
        "group": "",
        "group_num": [
            "recAiHbS1lCvdtBEu"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1706,
        "2023": 383,
        "id": "recJ78Y52YTjaDcIa",
        "row": 19028,
        "state": "",
        "group": "271454",
        "group_num": [
            "recBAxrzjJ9w9C5TJ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1487,
        "id": "recKxOsAGs9ODBWHl",
        "row": 19010,
        "state": "",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 216,
        "id": "recKzD5BFNGRpGQN9",
        "row": 19024,
        "state": "",
        "group": "",
        "group_num": [
            "recUyNbQcXLsX0UFj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recKequbI6GAJhDNg",
        "row": 19029,
        "state": "",
        "group": "",
        "group_num": [
            "rec6YI6kEuokqak0n"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 355,
        "id": "recL2W05kantyMwNU",
        "row": 19012,
        "state": "",
        "group": "169026",
        "group_num": [
            "recKAwxoUZpsmaPHZ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "rece2gTDu3uDq8Vsi",
        "row": 19051,
        "state": "",
        "group": "",
        "group_num": [
            "rec8gmE1je1k4gkpe"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3600,
        "id": "recf2hevaRAMEmM07",
        "row": 19072,
        "state": "",
        "group": "190422",
        "group_num": [
            "reckfdvCCj1YB55wK"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 319,
        "2023": 291,
        "id": "recg3MTKoqiWdbLyO",
        "row": 19047,
        "state": "",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 3750,
        "id": "recg7A48qwfasAKyP",
        "row": 19076,
        "state": "",
        "group": "324237",
        "group_num": [
            "recLGsOWaoxOXbIoa"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "2023": 288,
        "id": "rech0BxLZctAIwcfi",
        "row": 19069,
        "state": "",
        "group": "",
        "group_num": [
            "recPumGLKfge6E4vm"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rechvkjDqCltHXQcM",
        "row": 19037,
        "state": "",
        "group": "266499",
        "group_num": [
            "recuPuSBUwXkqCNrf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "id": "reciPgdpkC9C0PG3e",
        "row": 19058,
        "state": "",
        "group": "",
        "group_num": [
            "recKkBTaPBVOzuIOD"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1431,
        "id": "recjVlJxZECIBQqr7",
        "row": 19056,
        "state": "",
        "group": "",
        "group_num": [
            "recs5fcAukiKjvLe3"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "id": "reclokWjPMJUHR603",
        "row": 19075,
        "state": "",
        "group": "190427",
        "group_num": [
            "rec0WSAB624mv7ym0"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "id": "recm9mi579mvx3eNw",
        "row": 19043,
        "state": "",
        "group": "121982",
        "group_num": [
            "recwd1nRTKgPaDWNr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "recOrZ7pgLEc3VsQb",
        "row": 19073,
        "state": "",
        "group": "190422",
        "group_num": [
            "reckfdvCCj1YB55wK"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recPGPfySRAPoy3S2",
        "row": 19034,
        "state": "",
        "group": "269589",
        "group_num": [
            "recZQ632gxnKdDotP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recQpb9tsGiOMDb2H",
        "row": 19066,
        "state": "",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1325,
        "id": "recRNqgGNKfiMBk83",
        "row": 19078,
        "state": "",
        "group": "324237",
        "group_num": [
            "recLGsOWaoxOXbIoa"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 459,
        "2023": 811,
        "id": "recRnu5xzArUtb4jZ",
        "row": 19062,
        "state": "",
        "group": "",
        "group_num": [
            "rec2xOglRs9dTnEnV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 496,
        "id": "recSsCbZS0edDTDZZ",
        "row": 19074,
        "state": "",
        "group": "190422",
        "group_num": [
            "reckfdvCCj1YB55wK"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3246,
        "id": "recSuid7nGxanjc4B",
        "row": 19041,
        "state": "",
        "group": "121212",
        "group_num": [
            "rec0X6xokCCwWSGhh"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 258,
        "id": "recSzIjfQUleu2BJE",
        "row": 19083,
        "state": "",
        "group": "",
        "group_num": [
            "recuu9YIaHiehbmrL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recUFD9rgTV2cSV79",
        "row": 19067,
        "state": "",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2981,
        "2023": 1850,
        "2024": 3453,
        "id": "recUJXbr4moAqtulJ",
        "row": 19045,
        "state": "",
        "group": "099198",
        "group_num": [
            "recOlhVE8bI3rZzgR"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recWc51Mpkn9G2Wac",
        "row": 19050,
        "state": "",
        "group": "",
        "group_num": [
            "rec8gmE1je1k4gkpe"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 130,
        "id": "recYNlI7UKkFsY9u5",
        "row": 19046,
        "state": "",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recZRbWFJcnceZyxC",
        "row": 19059,
        "state": "",
        "group": "",
        "group_num": [
            "recKkBTaPBVOzuIOD"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2340,
        "id": "rec0MNax5I3FjgEQp",
        "row": 19081,
        "state": "",
        "group": "116444",
        "group_num": [
            "recd5pvvGJApxBNSc"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 147,
        "id": "rec3f8IpiGfGO9JyY",
        "row": 19065,
        "state": "",
        "group": "",
        "group_num": [
            "recxZeSXqgVFUk7lh"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rec3rOv7kQdgb8Cgq",
        "row": 19060,
        "state": "",
        "group": "",
        "group_num": [
            "recbsGDbFT92pBtjM"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3460,
        "id": "rec4GsSPgfJt6JLzU",
        "row": 19042,
        "state": "",
        "group": "121212",
        "group_num": [
            "rec0X6xokCCwWSGhh"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "rec49mDS39Cd6mvtk",
        "row": 19070,
        "state": "",
        "group": "",
        "group_num": [
            "recEakLFviYLgo5tS"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1543,
        "id": "rec5HoSIvhUUS8A3Y",
        "row": 19063,
        "state": "",
        "group": "",
        "group_num": [
            "rec2xOglRs9dTnEnV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 412,
        "id": "rec6Z4aRRwHMcDe25",
        "row": 19054,
        "state": "",
        "group": "",
        "group_num": [
            "rec3KO0VXEHLh72TU"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 375,
        "id": "rec6gveVkeekbyOq0",
        "row": 19082,
        "state": "",
        "group": "",
        "group_num": [
            "recFmkgu69RKNr2Wg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "id": "rec6mLjukRLvJPkey",
        "row": 19077,
        "state": "",
        "group": "324237",
        "group_num": [
            "recLGsOWaoxOXbIoa"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4097,
        "id": "rec9F76Aio64Pb0A1",
        "row": 19057,
        "state": "",
        "group": "",
        "group_num": [
            "recIt0rARkT76Efps"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2204,
        "id": "rec9k0J0hqKiicuVR",
        "row": 19061,
        "state": "",
        "group": "",
        "group_num": [
            "recbsGDbFT92pBtjM"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 1317,
        "id": "reca4fs54Lv6EvoaZ",
        "row": 19068,
        "state": "",
        "group": "090271",
        "group_num": [
            "recrnnDy95OyjbKL5"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3616,
        "id": "recbpa40io3teCfkF",
        "row": 19080,
        "state": "",
        "group": "116444",
        "group_num": [
            "recd5pvvGJApxBNSc"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "reccW3QOxPBE6yTsa",
        "row": 19040,
        "state": "",
        "group": "",
        "group_num": [
            "rectvBHabTrjkvHzn"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recc0vJ8MfMon6z8i",
        "row": 19035,
        "state": "",
        "group": "269589",
        "group_num": [
            "recZQ632gxnKdDotP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 473,
        "id": "reccEs5R37HShX6Nh",
        "row": 19039,
        "state": "",
        "group": "266499",
        "group_num": [
            "recuPuSBUwXkqCNrf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 673,
        "id": "recqUOGtcHDQ5tVwW",
        "row": 19052,
        "state": "",
        "group": "",
        "group_num": [
            "recl7csRgVvIFNWuy"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1199,
        "id": "recsI5z0vSJwKbsoU",
        "row": 19049,
        "state": "",
        "group": "",
        "group_num": [
            "recTTQKoILIPbahXT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 369,
        "id": "recvfySoJcxiJnhqd",
        "row": 19064,
        "state": "",
        "group": "",
        "group_num": [
            "recxZeSXqgVFUk7lh"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3062,
        "2023": 480,
        "id": "recz0jOd16wDzZbq3",
        "row": 19044,
        "state": "",
        "group": "099198",
        "group_num": [
            "recOlhVE8bI3rZzgR"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 989,
        "id": "recAO6SOEk7aUiMs8",
        "row": 19071,
        "state": "",
        "group": "",
        "group_num": [
            "recEakLFviYLgo5tS"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "2023": 473,
        "id": "recELJk4be3WdC6nK",
        "row": 19048,
        "state": "",
        "group": "238000",
        "group_num": [
            "recayXYtLFe2jZrUL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 834,
        "id": "recHPFaMRygDQg4Wp",
        "row": 19053,
        "state": "",
        "group": "",
        "group_num": [
            "recl7csRgVvIFNWuy"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recIT6hq3ydaB4ZUB",
        "row": 19036,
        "state": "",
        "group": "",
        "group_num": [
            "recjfkweud6gPoXz4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3385,
        "id": "recKCnU4wMlix4ZQR",
        "row": 19055,
        "state": "",
        "group": "",
        "group_num": [
            "recs5fcAukiKjvLe3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 288,
        "id": "recKrOmNOgH3hmnwG",
        "row": 19038,
        "state": "",
        "group": "266499",
        "group_num": [
            "recuPuSBUwXkqCNrf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "id": "recLoWDb1Qz1tBGKx",
        "row": 19079,
        "state": "",
        "group": "",
        "group_num": [
            "recoBbT5VdNWHD2c2"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 63,
        "id": "recezkY977fdCYxYT",
        "row": 19099,
        "state": "",
        "group": "107008",
        "group_num": [
            "rec0xw3ReK93i1YCU"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 176,
        "id": "receIUXhOQEvFwmX6",
        "row": 19096,
        "state": "",
        "group": "",
        "group_num": [
            "recOiBHSOjtjvkW5F"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 832,
        "id": "rece5g6rCTBWOCaFf",
        "row": 19129,
        "state": "",
        "group": "",
        "group_num": [
            "recxkrQWMkzf6A4Pr"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "reci4LMZt6qolGeFB",
        "row": 19117,
        "state": "",
        "group": "",
        "group_num": [
            "recdBlBqWGT6MxSHz"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recnxhY0P2MJkB7dE",
        "row": 19109,
        "state": "",
        "group": "000641",
        "group_num": [
            "rec5fJlh6RvetBSBp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 398,
        "id": "recnHlECzc5DiTIzB",
        "row": 19101,
        "state": "",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 159,
        "id": "recPSg4kvvozOorO1",
        "row": 19115,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recS3w83D1duYyElz",
        "row": 19093,
        "state": "",
        "group": "485000",
        "group_num": [
            "recbMV3onuXQg6lXV"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 337,
        "id": "recTsi9c9St5GG16U",
        "row": 19110,
        "state": "",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recTdqIiodDjzyndb",
        "row": 19095,
        "state": "",
        "group": "",
        "group_num": [
            "recnpLqdJvQIl6afx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recUON9hH7xF4GfYH",
        "row": 19114,
        "state": "",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recVQ4nYouaCDM4jN",
        "row": 19105,
        "state": "",
        "group": "",
        "group_num": [
            "recSeKixM1fWBIVtG"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recVgRUff92c6agiq",
        "row": 19092,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 216,
        "id": "recXeoA1DtoNfwHpj",
        "row": 19107,
        "state": "",
        "group": "000641",
        "group_num": [
            "rec5fJlh6RvetBSBp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 559,
        "id": "recYs81cU49Xii5b5",
        "row": 19118,
        "state": "",
        "group": "",
        "group_num": [
            "recdBlBqWGT6MxSHz"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "2023": 688,
        "id": "recY3jJ544q6dtbRV",
        "row": 19111,
        "state": "",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 314,
        "id": "recZkBUsi9aDUeRv0",
        "row": 19087,
        "state": "",
        "group": "",
        "group_num": [
            "recoNSrfXIrdybwDt"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1668,
        "id": "rec4VhWj8QRrsCYt8",
        "row": 19128,
        "state": "",
        "group": "",
        "group_num": [
            "recxkrQWMkzf6A4Pr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1959,
        "id": "rec4WKQb2nPEeO3Uh",
        "row": 19086,
        "state": "",
        "group": "246237",
        "group_num": [
            "recQdOA3x4C2glaG7"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "rec61OdduDOpxRM98",
        "row": 19120,
        "state": "",
        "group": "",
        "group_num": [
            "rec26Cx9BCOVIWRZL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rec69kIsosIizkmig",
        "row": 19085,
        "state": "",
        "group": "246237",
        "group_num": [
            "recQdOA3x4C2glaG7"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "id": "rec7Uv4dU2InOfkzF",
        "row": 19131,
        "state": "",
        "group": "104892",
        "group_num": [
            "rece4tjxOCaOYKm5t"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 736,
        "id": "rec8bS3IYB5DccmR6",
        "row": 19094,
        "state": "",
        "group": "",
        "group_num": [
            "recnpLqdJvQIl6afx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 843,
        "id": "rec9RSJSSTkGnjVXh",
        "row": 19123,
        "state": "",
        "group": "",
        "group_num": [
            "rec8uoC6RFpMJ4XpT"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 991,
        "id": "recbuBF1o9rQV6vIq",
        "row": 19133,
        "state": "",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 338,
        "id": "recdVEljgUBvejTK2",
        "row": 19132,
        "state": "",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recofjLuqm53oISOx",
        "row": 19104,
        "state": "",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "recokuFiUsEG0Pdq0",
        "row": 19102,
        "state": "",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recpEKjda20cvqsoV",
        "row": 19121,
        "state": "",
        "group": "",
        "group_num": [
            "reclVa4bCzOFF0ECn"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "recq4NcsaRcQwg6ht",
        "row": 19126,
        "state": "",
        "group": "",
        "group_num": [
            "recw0r4XsKniyd8ud"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 302,
        "id": "recsJDQn3iDxkQZG7",
        "row": 19119,
        "state": "",
        "group": "",
        "group_num": [
            "rec26Cx9BCOVIWRZL"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 912,
        "id": "recwkGisGZnPRTpEC",
        "row": 19088,
        "state": "",
        "group": "",
        "group_num": [
            "recoNSrfXIrdybwDt"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 288,
        "id": "recyYSfhzZobZmw9A",
        "row": 19130,
        "state": "",
        "group": "",
        "group_num": [
            "recngJdz9upMMLQdd"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "2024": 23,
        "id": "recy1zWhUIJHd8you",
        "row": 19090,
        "state": "",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 263,
        "id": "reczHGp1yMGRqkdul",
        "row": 19127,
        "state": "",
        "group": "",
        "group_num": [
            "recw0r4XsKniyd8ud"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 670,
        "id": "reczL1jQw8UCgQNcf",
        "row": 19100,
        "state": "",
        "group": "012801",
        "group_num": [
            "recciYyRd3SLqJG5h"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 707,
        "id": "recz3TCUQglBB5jcO",
        "row": 19122,
        "state": "",
        "group": "",
        "group_num": [
            "rec8uoC6RFpMJ4XpT"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recAYpR3MLKqA0gbu",
        "row": 19084,
        "state": "",
        "group": "246237",
        "group_num": [
            "recQdOA3x4C2glaG7"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 253,
        "2023": 288,
        "id": "recBTJ4GjjM8OBfbB",
        "row": 19103,
        "state": "",
        "group": "071778",
        "group_num": [
            "rec2BNogwOWDOh03I"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "recCShAXTbmcKnEIk",
        "row": 19116,
        "state": "",
        "group": "",
        "group_num": [
            "recdBlBqWGT6MxSHz"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 390,
        "id": "recDg70pywqr3lKjK",
        "row": 19097,
        "state": "",
        "group": "",
        "group_num": [
            "recmr9VdLACOsETDI"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "recERNaj9Av9emQ1V",
        "row": 19124,
        "state": "",
        "group": "",
        "group_num": [
            "recw0r4XsKniyd8ud"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recF238rDXupugimu",
        "row": 19108,
        "state": "",
        "group": "000641",
        "group_num": [
            "rec5fJlh6RvetBSBp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 159,
        "id": "recHWckzvCz8tp4oj",
        "row": 19125,
        "state": "",
        "group": "",
        "group_num": [
            "recw0r4XsKniyd8ud"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 726,
        "id": "recHw8ra0fLu2DufH",
        "row": 19098,
        "state": "",
        "group": "",
        "group_num": [
            "recmr9VdLACOsETDI"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 49,
        "id": "recHcJ5kvBswXVfGO",
        "row": 19089,
        "state": "",
        "group": "",
        "group_num": [
            "recoNSrfXIrdybwDt"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recJFhRBOp4Uq9hjH",
        "row": 19091,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 27,
        "id": "recKZwiaCdgAQ0s5J",
        "row": 19112,
        "state": "",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 562,
        "id": "recNDzmMiJLoXvXsw",
        "row": 19106,
        "state": "",
        "group": "",
        "group_num": [
            "recSeKixM1fWBIVtG"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 253,
        "2023": 614,
        "2024": 4750,
        "id": "recNEUnWR0odNEG1E",
        "row": 19113,
        "state": "",
        "group": "272553",
        "group_num": [
            "rec8KIUl3NU0O9tPN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 176,
        "id": "recfqFFmYEGSqh090",
        "row": 19170,
        "state": "",
        "group": "",
        "group_num": [
            "recb90yORrME591F4"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 33,
        "id": "recgNuuIgdLysGmqq",
        "row": 19139,
        "state": "",
        "group": "",
        "group_num": [
            "rec00WAmABIBvqMSb"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 143,
        "id": "reciB6Di5bVKq0Szj",
        "row": 19149,
        "state": "",
        "group": "",
        "group_num": [
            "recCuFWFCD34wWX6W"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 294,
        "2023": 127,
        "id": "reck2gEiRnWKzxw5d",
        "row": 19174,
        "state": "",
        "group": "",
        "group_num": [
            "recmk6eADHUwchSAf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "id": "reclN1o5dR9qPwdrF",
        "row": 19145,
        "state": "",
        "group": "",
        "group_num": [
            "recqi561IjINT79Qo"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "reclja2LJU9iY51c3",
        "row": 19168,
        "state": "",
        "group": "249572",
        "group_num": [
            "recOeZFSggL7pNbod"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recm3KBZYQXkVTZPI",
        "row": 19135,
        "state": "",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 116,
        "id": "recmd2X8Xvn8S68IZ",
        "row": 19178,
        "state": "",
        "group": "",
        "group_num": [
            "recPEbmDWwwAR4nvb"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "recmowhIZ5mkdXV6D",
        "row": 19181,
        "state": "",
        "group": "283019",
        "group_num": [
            "recE36zfdf6Uu9pLF"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 216,
        "id": "recOLaUaEDu79oRHm",
        "row": 19173,
        "state": "",
        "group": "",
        "group_num": [
            "recmk6eADHUwchSAf"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 213,
        "2023": 198,
        "id": "recO4pt0qX7hVUhjH",
        "row": 19183,
        "state": "",
        "group": "289319",
        "group_num": [
            "recsjqmdAVJVE58vA"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recQEiiowegV5BoLR",
        "row": 19153,
        "state": "",
        "group": "",
        "group_num": [
            "recGNBHbiU7Vk4RiN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 288,
        "id": "recRXLKeKilr2HgOH",
        "row": 19179,
        "state": "",
        "group": "",
        "group_num": [
            "recPEbmDWwwAR4nvb"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 732,
        "2023": 828,
        "id": "recRQ7da2Sy0VF6jE",
        "row": 19169,
        "state": "",
        "group": "249572",
        "group_num": [
            "recOeZFSggL7pNbod"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 198,
        "id": "recSTttDDv6mZjAYk",
        "row": 19156,
        "state": "",
        "group": "202153",
        "group_num": [
            "recw1eWkgz8H9hvbj"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 397,
        "id": "recT8IjbfWXsVAIez",
        "row": 19162,
        "state": "",
        "group": "",
        "group_num": [
            "recoRPzL83cLBLftX"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 216,
        "id": "recTgsit7nBhJvTw3",
        "row": 19138,
        "state": "",
        "group": "",
        "group_num": [
            "recPZrQ9fiynh7K02"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recWV0GezJ2FIy7hq",
        "row": 19155,
        "state": "",
        "group": "",
        "group_num": [
            "recUumkPF0eVPFFIs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recX3Rvw8SVkd83rq",
        "row": 19160,
        "state": "",
        "group": "215150",
        "group_num": [
            "recAaxmkZYR43eZdy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 321,
        "id": "recY8EVaz4LYQTmkD",
        "row": 19136,
        "state": "",
        "group": "118563",
        "group_num": [
            "recpc7QnRvCHR8wqp"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "recZRXZMuxoVMUyvn",
        "row": 19150,
        "state": "",
        "group": "",
        "group_num": [
            "recCuFWFCD34wWX6W"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 159,
        "id": "recZaDZ0DDfb67yWA",
        "row": 19152,
        "state": "",
        "group": "",
        "group_num": [
            "recGNBHbiU7Vk4RiN"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2204,
        "id": "rec1heNqGnwfflNv7",
        "row": 19161,
        "state": "",
        "group": "",
        "group_num": [
            "rech3xiMCj13m2TwB"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1295,
        "2024": 159,
        "id": "rec1ngVvYOitasUgp",
        "row": 19158,
        "state": "",
        "group": "",
        "group_num": [
            "recq23fTLmPxnt2kw"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 664,
        "id": "rec3TtxbP8Y8nUr1l",
        "row": 19157,
        "state": "",
        "group": "202153",
        "group_num": [
            "recw1eWkgz8H9hvbj"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 27,
        "id": "rec3sItaj7xDRQATl",
        "row": 19164,
        "state": "",
        "group": "",
        "group_num": [
            "reclI5Uf5ix7rHARh"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "id": "rec4un9BmZzO3WQpR",
        "row": 19180,
        "state": "",
        "group": "283019",
        "group_num": [
            "recE36zfdf6Uu9pLF"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "2024": 116,
        "id": "rec63fm5nCavQRqij",
        "row": 19151,
        "state": "",
        "group": "",
        "group_num": [
            "recGNBHbiU7Vk4RiN"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1723,
        "2024": 288,
        "id": "rec7WsmsCrWkpCUkF",
        "row": 19146,
        "state": "",
        "group": "",
        "group_num": [
            "recgTY8zfFjQxvebk"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "id": "rec8KjrmNXuCsqP3f",
        "row": 19167,
        "state": "",
        "group": "249572",
        "group_num": [
            "recOeZFSggL7pNbod"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "rec8ksfE2fkahYjaQ",
        "row": 19134,
        "state": "",
        "group": "118222",
        "group_num": [
            "recYPFlNhKTFJVIDp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rec9Usu7fo5HkyI9F",
        "row": 19177,
        "state": "",
        "group": "",
        "group_num": [
            "recfO2pRAUz2Ep8ka"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 784,
        "id": "recbv84RiTcyQHRhE",
        "row": 19172,
        "state": "",
        "group": "256462",
        "group_num": [
            "rec8JjkUCfxvv22AP"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 154,
        "id": "reccnF875uoOSAsSi",
        "row": 19154,
        "state": "",
        "group": "",
        "group_num": [
            "recChnu73WsQI81ik"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "id": "reco5LoGrEpkZ7CF0",
        "row": 19144,
        "state": "",
        "group": "",
        "group_num": [
            "recqi561IjINT79Qo"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1354,
        "id": "recpqJPaPnTXr6GRs",
        "row": 19165,
        "state": "",
        "group": "242557",
        "group_num": [
            "recYBUY1SsYXXaXPQ"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recq4nFeoTMFUltzT",
        "row": 19147,
        "state": "",
        "group": "",
        "group_num": [
            "recR7MQ8kMFykHLya"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 264,
        "id": "recr6j8QN45YxzUgW",
        "row": 19140,
        "state": "",
        "group": "",
        "group_num": [
            "rec00WAmABIBvqMSb"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 104,
        "id": "recxJbMJny9S8aOPs",
        "row": 19143,
        "state": "",
        "group": "",
        "group_num": [
            "recqi561IjINT79Qo"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "reczKZYF6hYuKPonY",
        "row": 19175,
        "state": "",
        "group": "266520",
        "group_num": [
            "recnDu2oW6oSXrY4X"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recASy1x9A4FY6ork",
        "row": 19159,
        "state": "",
        "group": "215150",
        "group_num": [
            "recAaxmkZYR43eZdy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1579,
        "id": "recAVpiuv1S389GoW",
        "row": 19142,
        "state": "",
        "group": "",
        "group_num": [
            "recNfVvyQs8MOOl9T"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recBETiadi8Tywl7I",
        "row": 19171,
        "state": "",
        "group": "",
        "group_num": [
            "rec9EZ2qJqoGWdg4u"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 623,
        "id": "recB2QcZK3Am5XSIp",
        "row": 19137,
        "state": "",
        "group": "118563",
        "group_num": [
            "recpc7QnRvCHR8wqp"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 422,
        "id": "recCvvNwKQLGJiVsV",
        "row": 19176,
        "state": "",
        "group": "276165",
        "group_num": [
            "recufSbSmfORXQcWv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recFOM8nLsnlSnXsH",
        "row": 19166,
        "state": "",
        "group": "242557",
        "group_num": [
            "recYBUY1SsYXXaXPQ"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recJDS2COgS5ICfiV",
        "row": 19141,
        "state": "",
        "group": "",
        "group_num": [
            "rec00WAmABIBvqMSb"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 159,
        "id": "recLTeSLkhTX4ETeE",
        "row": 19163,
        "state": "",
        "group": "",
        "group_num": [
            "recoRPzL83cLBLftX"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recMXcBWnW9fS8KLB",
        "row": 19148,
        "state": "",
        "group": "",
        "group_num": [
            "recR7MQ8kMFykHLya"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recM49emwoPLug0Wi",
        "row": 19182,
        "state": "",
        "group": "289224",
        "group_num": [
            "rec3WStoe4xCiKlkY"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "id": "receQHeJtCX565U1X",
        "row": 19192,
        "state": "",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 27,
        "id": "recfUnn6kNbCwaiqQ",
        "row": 19203,
        "state": "",
        "group": "",
        "group_num": [
            "recyHXmrN70ULH88j"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 113,
        "id": "recfIhfLV3BIOm8G3",
        "row": 19217,
        "state": "",
        "group": "",
        "group_num": [
            "recreI47MDCbE0p48"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 473,
        "id": "recflPr7FqNMf7AbR",
        "row": 19197,
        "state": "",
        "group": "",
        "group_num": [
            "recjDACalDjSz9Vyw"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rechZfhyeFVOYHhkO",
        "row": 19200,
        "state": "",
        "group": "",
        "group_num": [
            "recZA2EZW7GHy1B0h"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2090,
        "id": "rechluQKH0Q5LjvNP",
        "row": 19186,
        "state": "",
        "group": "320088",
        "group_num": [
            "recLALLj59uvsbM92"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recQHidvhN8h0sjB5",
        "row": 19208,
        "state": "",
        "group": "",
        "group_num": [
            "recfErvfuS6Pm4wI8"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "id": "recSulkyZPw2dZPbn",
        "row": 19196,
        "state": "",
        "group": "",
        "group_num": [
            "recjDACalDjSz9Vyw"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 44,
        "2024": 44,
        "id": "recTIldYarFtn47ej",
        "row": 19213,
        "state": "",
        "group": "",
        "group_num": [
            "recSobRLZukMd0cAi"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 434,
        "id": "recVUezbSmr663onp",
        "row": 19189,
        "state": "",
        "group": "",
        "group_num": [
            "rec2tz2iwUzaKDCcw"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 422,
        "2024": 2213,
        "id": "recZJ6hLxhhjQNDNF",
        "row": 19199,
        "state": "",
        "group": "336072",
        "group_num": [
            "recXGXu64vRUCzsgE"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "recZ9enR8hlPTQ036",
        "row": 19219,
        "state": "",
        "group": "",
        "group_num": [
            "recsNqcxPmNu8MaxN"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "rec0jejfBFlcd2FPq",
        "row": 19220,
        "state": "",
        "group": "",
        "group_num": [
            "recsNqcxPmNu8MaxN"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 884,
        "2024": 248,
        "id": "rec3rndlfx8wbwycP",
        "row": 19214,
        "state": "",
        "group": "",
        "group_num": [
            "recHXeqTtbOFWhzTY"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 495,
        "id": "rec4Zg3BY37I4fowZ",
        "row": 19198,
        "state": "",
        "group": "328754",
        "group_num": [
            "recxkyA1RYrmct5QT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rec5SZyU7U1o6G6Ui",
        "row": 19206,
        "state": "",
        "group": "",
        "group_num": [
            "recVgCq8513Pmjama"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2821,
        "id": "rec6iFeaWBhVxBUVi",
        "row": 19184,
        "state": "",
        "group": "",
        "group_num": [
            "recotXLuuMnkpsPuT"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1009,
        "id": "rec7zjMPIjojhrm6t",
        "row": 19222,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1072,
        "id": "rec73dL6wl52uHHek",
        "row": 19211,
        "state": "",
        "group": "",
        "group_num": [
            "recuZc1jqP1UpgM7H"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 125,
        "id": "rec8Gx9b7q9R01ENe",
        "row": 19201,
        "state": "",
        "group": "",
        "group_num": [
            "recyHXmrN70ULH88j"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "rec8k7X16FB5kZk3q",
        "row": 19204,
        "state": "",
        "group": "",
        "group_num": [
            "recyHXmrN70ULH88j"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 153,
        "2023": 116,
        "id": "recaQnqU4s8uJ5Ou7",
        "row": 19205,
        "state": "",
        "group": "",
        "group_num": [
            "recVgCq8513Pmjama"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "id": "recajqhIoGkis2I34",
        "row": 19193,
        "state": "",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 414,
        "id": "recanID29fjYevi1X",
        "row": 19210,
        "state": "",
        "group": "",
        "group_num": [
            "recAkYldRs9MG7lJ9"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 422,
        "id": "reccWrmTVPxEVKSWq",
        "row": 19216,
        "state": "",
        "group": "355421",
        "group_num": [
            "recw1RZjtHy7airM0"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 152,
        "2024": 159,
        "id": "recckAQWlI6e9Pwy0",
        "row": 19202,
        "state": "",
        "group": "",
        "group_num": [
            "recyHXmrN70ULH88j"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recdogg6sX0xWTdpF",
        "row": 19209,
        "state": "",
        "group": "",
        "group_num": [
            "recfErvfuS6Pm4wI8"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 670,
        "id": "recoPOIcjp8Dqh2pI",
        "row": 19223,
        "state": "",
        "group": "",
        "group_num": [
            "recymEP4CQwkbYA41"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2067,
        "id": "recq3mGUOqN8N4afA",
        "row": 19190,
        "state": "",
        "group": "326456",
        "group_num": [
            "recOund0AhzKgZFWL"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 414,
        "id": "recuEOfujNVrTJcwX",
        "row": 19212,
        "state": "",
        "group": "",
        "group_num": [
            "recSobRLZukMd0cAi"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1930,
        "id": "recvDv3zx5wGjiwNp",
        "row": 19221,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1861,
        "id": "recvJpYYA96Y1p9D0",
        "row": 19195,
        "state": "",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 1883,
        "id": "recw3UOFrBm0OYUFs",
        "row": 19187,
        "state": "",
        "group": "320728",
        "group_num": [
            "reckbRcrG97NJEbxf"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1325,
        "id": "recA0r44v7yN5ydXD",
        "row": 19194,
        "state": "",
        "group": "327028",
        "group_num": [
            "recRCVxYTkKeQ7vly"
        ],
        "procedure": "OP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1782,
        "id": "recBvEwCtcz28j1X4",
        "row": 19185,
        "state": "",
        "group": "305381",
        "group_num": [
            "rec0zb2JvR0JgTG16"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recC4M1etAxRw8rX5",
        "row": 19188,
        "state": "",
        "group": "320728",
        "group_num": [
            "reckbRcrG97NJEbxf"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "recEKYGaKiog0prpz",
        "row": 19207,
        "state": "",
        "group": "",
        "group_num": [
            "recfErvfuS6Pm4wI8"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 422,
        "id": "recEmEd4WKIhygC2J",
        "row": 19191,
        "state": "",
        "group": "327006",
        "group_num": [
            "recChp7oiBqnd32G3"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1625,
        "id": "recGhbsJcjnC4TkIN",
        "row": 19215,
        "state": "",
        "group": "",
        "group_num": [
            "recq5KSsGTPIX3BE9"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 159,
        "id": "recNf5X3dpoRK5jcD",
        "row": 19218,
        "state": "",
        "group": "",
        "group_num": [
            "recreI47MDCbE0p48"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF TX ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 264,
        "id": "recdiIjeyONXg3EZK",
        "row": 19224,
        "state": "",
        "group": "50625",
        "group_num": [
            "rec0rbKIVwPhxX3UV"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF VT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recL9uZZVRbmOOCaS",
        "row": 19225,
        "state": "",
        "group": "50625",
        "group_num": [
            "rec0rbKIVwPhxX3UV"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF VT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 833,
        "id": "rechdLol5UeEgpmV9",
        "row": 19231,
        "state": "",
        "group": "10652409",
        "group_num": [
            "rec9Qbe7qcA2lwg7z"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recPwl5jERu6hP4Ou",
        "row": 19229,
        "state": "",
        "group": "10647445",
        "group_num": [
            "reciTnkUeX4cuW7Cy"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 833,
        "id": "recZdJHQBJ3wzb1vr",
        "row": 19233,
        "state": "",
        "group": "11445077",
        "group_num": [
            "recUxAG2mIRGicLIg"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1753,
        "id": "recac1LWhQsnYe6xn",
        "row": 19227,
        "state": "",
        "group": "10743700",
        "group_num": [
            "recMzxJoEuLY21HGc"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recprY8tP42PznQXf",
        "row": 19226,
        "state": "",
        "group": "00418019",
        "group_num": [
            "recMsXdc89OQiweGs"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2850,
        "id": "recrRa8YsbQTihZQr",
        "row": 19228,
        "state": "",
        "group": "715984202",
        "group_num": [
            "recoTgu9bzEzPbRW7"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recs5qrhprUroukcK",
        "row": 19230,
        "state": "",
        "group": "10647445",
        "group_num": [
            "reciTnkUeX4cuW7Cy"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 972,
        "id": "recJxTjhL2AaIgEBv",
        "row": 19232,
        "state": "",
        "group": "10652409",
        "group_num": [
            "rec9Qbe7qcA2lwg7z"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recgbWAeIMqsHxw1S",
        "row": 19239,
        "state": "",
        "group": "11448312",
        "group_num": [
            "recInv6crQNfQRCl2"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "id": "recRGISNabQAj6i0m",
        "row": 19238,
        "state": "",
        "group": "00310884",
        "group_num": [
            "recD2U5dBdHENQFtP"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "rec0gaXfVS8C5S8Jr",
        "row": 19237,
        "state": "",
        "group": "00310884",
        "group_num": [
            "recD2U5dBdHENQFtP"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "rect2moAiNeQlB7bs",
        "row": 19236,
        "state": "",
        "group": "10663976",
        "group_num": [
            "recvNVQxjgVhzGLPQ"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "id": "recH3meMRzB9mr1cC",
        "row": 19234,
        "state": "",
        "group": "10663976",
        "group_num": [
            "recvNVQxjgVhzGLPQ"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 217,
        "id": "recJMDQlI3C1lkxan",
        "row": 19235,
        "state": "",
        "group": "10663976",
        "group_num": [
            "recvNVQxjgVhzGLPQ"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF WESTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recgT1Zg7ebDEyXRa",
        "row": 19242,
        "state": "",
        "group": "10358356",
        "group_num": [
            "recg3Xg5e6F68ZpEV"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 961,
        "2023": 1029,
        "id": "rech7hqTphfehtVbm",
        "row": 19248,
        "state": "",
        "group": "",
        "group_num": [
            "recXEfD3L6v5V0Cve"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "recnTATGdw0Y78kEc",
        "row": 19246,
        "state": "",
        "group": "",
        "group_num": [
            "recXEfD3L6v5V0Cve"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "recnPQayhEzxhPAoM",
        "row": 19253,
        "state": "",
        "group": "",
        "group_num": [
            "recdpOHzo4kQlH1Vr"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1235,
        "id": "recSEHbEZvsyDouAa",
        "row": 19249,
        "state": "",
        "group": "",
        "group_num": [
            "recCo3eAGXq8F2dPv"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recXSy7YgZ56afIEx",
        "row": 19252,
        "state": "",
        "group": "",
        "group_num": [
            "recdpOHzo4kQlH1Vr"
        ],
        "procedure": "OP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 568,
        "id": "recXohPELMxolCwVY",
        "row": 19245,
        "state": "",
        "group": "",
        "group_num": [
            "recXEfD3L6v5V0Cve"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 563,
        "id": "rec15A9PUu0YIwhGq",
        "row": 19250,
        "state": "",
        "group": "",
        "group_num": [
            "recdpOHzo4kQlH1Vr"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "rec2uWwH7lCCQkVlG",
        "row": 19241,
        "state": "",
        "group": "",
        "group_num": [
            "recACrva6pN0KpbDd"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "rec7F5llP8ArrUZc9",
        "row": 19243,
        "state": "",
        "group": "",
        "group_num": [
            "recnYxzjjleJRunZM"
        ],
        "procedure": "PHP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "rec7PbXgKKVlLGF0C",
        "row": 19251,
        "state": "",
        "group": "",
        "group_num": [
            "recdpOHzo4kQlH1Vr"
        ],
        "procedure": "IOP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recsLIbq9Kw74ZzSD",
        "row": 19244,
        "state": "",
        "group": "",
        "group_num": [
            "recdjKlw3lJOHXyCR"
        ],
        "procedure": "Detox",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 158,
        "id": "reczchHaJwKORSGJj",
        "row": 19247,
        "state": "",
        "group": "",
        "group_num": [
            "recXEfD3L6v5V0Cve"
        ],
        "procedure": "OP",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recESTtlO7mfAZDgb",
        "row": 19240,
        "state": "",
        "group": "10711462",
        "group_num": [
            "rec2pkor5yDgxzTu5"
        ],
        "procedure": "RTC",
        "provider": "BCBS OF WY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1165,
        "2023": 490,
        "2024": 490,
        "id": "recizLP0MYHc2AzXi",
        "row": 21756,
        "state": "",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recirwEyqBYkbrzW3",
        "row": 21755,
        "state": "",
        "group": "054632",
        "group_num": [
            "recLCOQ9hxhhsW1kf"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1878,
        "2023": 2397,
        "2024": 2770,
        "id": "reclMifpjUygB6YTm",
        "row": 21752,
        "state": "",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3699,
        "2023": 4186,
        "2024": 4108,
        "id": "recRSu10SLeAHy4KM",
        "row": 21749,
        "state": "",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1340,
        "2023": 1340,
        "id": "recRk0mt7TXvpaond",
        "row": 21748,
        "state": "",
        "group": "",
        "group_num": [
            "rec4NGt94SrASD9TM"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 150,
        "id": "recTkJ4SDASjs068S",
        "row": 21747,
        "state": "",
        "group": "",
        "group_num": [
            "recgJHaELGQEuswCo"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 403,
        "2023": 537,
        "id": "recUhJd5GsNi3Vx1D",
        "row": 21751,
        "state": "",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5778,
        "2023": 5945,
        "id": "reca6x71y9faeKttw",
        "row": 21753,
        "state": "",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1237,
        "id": "reccYv2npChzccPRa",
        "row": 21754,
        "state": "",
        "group": "054632",
        "group_num": [
            "recLCOQ9hxhhsW1kf"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2812,
        "2023": 2938,
        "2024": 3750,
        "id": "recGSvTHp19REn4vg",
        "row": 21750,
        "state": "",
        "group": "030500",
        "group_num": [
            "recB1qRB72CNgzq2O"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1260,
        "2023": 1244,
        "id": "recf1pzdlXSAfxVRC",
        "row": 21761,
        "state": "",
        "group": "106528",
        "group_num": [
            "recke3ANugOvCT1FY"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3800,
        "id": "reciqlnWxDXCvc97E",
        "row": 21804,
        "state": "",
        "group": "",
        "group_num": [
            "rec2hTKApKneGnive"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recjWIPYXAQfJVIzB",
        "row": 21777,
        "state": "",
        "group": "",
        "group_num": [
            "recZinSSMox9YBnBB"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "reckCizhR2yKF33tM",
        "row": 21793,
        "state": "",
        "group": "KA1535",
        "group_num": [
            "rechu6N1LuUCSCjKi"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 205,
        "2024": 205,
        "id": "reck7tYSvcC9iSint",
        "row": 21766,
        "state": "",
        "group": "117630",
        "group_num": [
            "rec6YTatyW1leg8RS"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 74,
        "2024": 52,
        "id": "reckr3Fg1E0ZTJAsk",
        "row": 21771,
        "state": "",
        "group": "",
        "group_num": [
            "recvR0IDBiuZoygsU"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1377,
        "2024": 1138,
        "id": "reclyX1gHAyAGuo8Q",
        "row": 21763,
        "state": "",
        "group": "",
        "group_num": [
            "rec8rXJ9g3ouRz7sw"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 57,
        "id": "reclpWnx6XGv5Naiz",
        "row": 21784,
        "state": "",
        "group": "",
        "group_num": [
            "rectm55ifaXDJRYF7"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 53,
        "id": "recmtxjDIiKnmQ7Ua",
        "row": 21788,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "recmLDd4j55KXIMUj",
        "row": 21796,
        "state": "",
        "group": "KN0009",
        "group_num": [
            "recfJhEQGwsp1yEKz"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "recnZ8oCdM599LCwW",
        "row": 21799,
        "state": "",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 539,
        "id": "recQ0hd3qeRT335Su",
        "row": 21779,
        "state": "",
        "group": "0000832000043",
        "group_num": [
            "recy8WrvVMAqRBDqH"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recTtJEcI8lkrXBKC",
        "row": 21775,
        "state": "",
        "group": "",
        "group_num": [
            "recVmgMXs9kaClwxu"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "id": "recVdwepncvSoSl4y",
        "row": 21803,
        "state": "",
        "group": "NYS",
        "group_num": [
            "recLzXTWVoGJVQcq9"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recVr5aH0JkH3MI01",
        "row": 21800,
        "state": "",
        "group": "NEI001",
        "group_num": [
            "recbMCBLpawLF25p9"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recWVHJoX99qmcejl",
        "row": 21785,
        "state": "",
        "group": "",
        "group_num": [
            "rectm55ifaXDJRYF7"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 905,
        "id": "recXp2lAwA5ztMaRr",
        "row": 21781,
        "state": "",
        "group": "",
        "group_num": [
            "reczlovqX3XIALejM"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 105,
        "id": "recZCDeoNkq0uY3Q1",
        "row": 21776,
        "state": "",
        "group": "",
        "group_num": [
            "recVmgMXs9kaClwxu"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 663,
        "id": "recZIfGKFgiov124g",
        "row": 21783,
        "state": "",
        "group": "",
        "group_num": [
            "rectm55ifaXDJRYF7"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "rec1mAJKT5JgkqBLC",
        "row": 21790,
        "state": "",
        "group": "",
        "group_num": [
            "reca9c91f4aPvJ26i"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 797,
        "id": "rec2F3rpadbbQSIRT",
        "row": 21780,
        "state": "",
        "group": "0000832000043",
        "group_num": [
            "recy8WrvVMAqRBDqH"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 510,
        "id": "rec2d7Ancpsb59RSC",
        "row": 21797,
        "state": "",
        "group": "",
        "group_num": [
            "recAmnfRf8GngkOGD"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "rec5bDts8TeYA978t",
        "row": 21760,
        "state": "",
        "group": "",
        "group_num": [
            "rec8Hkn62JY55nk5A"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1390,
        "id": "rec9BN12raRjox1Hb",
        "row": 21765,
        "state": "",
        "group": "113110",
        "group_num": [
            "reccJzFOqCIFYLCrM"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recbx99jFFlBNZbB7",
        "row": 21774,
        "state": "",
        "group": "",
        "group_num": [
            "recVmgMXs9kaClwxu"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1660,
        "id": "reccq3ysg0syAZHCv",
        "row": 21767,
        "state": "",
        "group": "",
        "group_num": [
            "recu9sDHNOQI0SA13"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recpfKgzyZXSRx0fE",
        "row": 21773,
        "state": "",
        "group": "1102367",
        "group_num": [
            "rec5six7ns8594Atj"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4377,
        "2023": 3200,
        "id": "recqB138FNr4EJfka",
        "row": 21805,
        "state": "",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "id": "recrzHWzXrUdesRUm",
        "row": 21802,
        "state": "",
        "group": "NYS",
        "group_num": [
            "recLzXTWVoGJVQcq9"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1195,
        "2023": 1299,
        "id": "recsawH30N6rNXtcV",
        "row": 21762,
        "state": "",
        "group": "106528",
        "group_num": [
            "recke3ANugOvCT1FY"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recsnJy18dRBXMjXn",
        "row": 21801,
        "state": "",
        "group": "NYS",
        "group_num": [
            "recLzXTWVoGJVQcq9"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1800,
        "id": "recvHRBZSKoBX42uH",
        "row": 21772,
        "state": "",
        "group": "",
        "group_num": [
            "recvR0IDBiuZoygsU"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1794,
        "2023": 2750,
        "id": "recwaXa7xjLnZFycH",
        "row": 21782,
        "state": "",
        "group": "",
        "group_num": [
            "rectm55ifaXDJRYF7"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recyCF6fwG6cQAHqO",
        "row": 21768,
        "state": "",
        "group": "170161",
        "group_num": [
            "recGXjqeeAtHkKg9g"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recyNSsY3wCcDvJ32",
        "row": 21787,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "reczMo3I61FIdy0wQ",
        "row": 21789,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 353,
        "id": "reczeKu70vjEtwhp9",
        "row": 21757,
        "state": "",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1852,
        "2023": 1852,
        "id": "recBLjoVVKalfqSbx",
        "row": 21792,
        "state": "",
        "group": "JNY021M001",
        "group_num": [
            "rec6vHmmWGdbREJv0"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 268,
        "id": "recEk4k7lZHMYTRne",
        "row": 21764,
        "state": "",
        "group": "",
        "group_num": [
            "recVwrONvtu8wt0EF"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1504,
        "id": "recFHR1vn3oRNOiaC",
        "row": 21791,
        "state": "",
        "group": "JNY021M001",
        "group_num": [
            "rec6vHmmWGdbREJv0"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "recF6bBj671NYP1Fg",
        "row": 21806,
        "state": "",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recGTGaGp7qFxrchC",
        "row": 21795,
        "state": "",
        "group": "KN0009",
        "group_num": [
            "recfJhEQGwsp1yEKz"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "2024": 305,
        "id": "recGJR4fPbSTd95Io",
        "row": 21786,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 796,
        "id": "recHULpDAiFPclzQS",
        "row": 21798,
        "state": "",
        "group": "",
        "group_num": [
            "recAmnfRf8GngkOGD"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "2023": 1015,
        "id": "recHVWpBlSm7VFHMW",
        "row": 21758,
        "state": "",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 437,
        "2023": 450,
        "id": "recHQQ7BhnMfJ3TZX",
        "row": 21769,
        "state": "",
        "group": "",
        "group_num": [
            "recotW1AcawXAsqPl"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1369,
        "2023": 1512,
        "id": "recJImENdUc83yuVn",
        "row": 21759,
        "state": "",
        "group": "100346",
        "group_num": [
            "recN0g31CgoKRgFd6"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "recJdN5miYWVRgSOw",
        "row": 21778,
        "state": "",
        "group": "",
        "group_num": [
            "reciXj7CNBUeWd9C3"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 148,
        "id": "recKMmIln8meCkD9j",
        "row": 21770,
        "state": "",
        "group": "",
        "group_num": [
            "recpVLQELQ7UI8mL6"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "2024": 20,
        "id": "recLZop0mAp18zrnT",
        "row": 21794,
        "state": "",
        "group": "",
        "group_num": [
            "recwH4fRRFSGgsLuK"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "id": "recgtKV5z1BjUnuAM",
        "row": 21819,
        "state": "",
        "group": "",
        "group_num": [
            "recr3YSPUwA9YSf5S"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "id": "recgvUigmQ4KB2iMf",
        "row": 21818,
        "state": "",
        "group": "",
        "group_num": [
            "recr3YSPUwA9YSf5S"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 4750,
        "id": "recg5wx7YXU4IHHQF",
        "row": 21826,
        "state": "",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 86,
        "2024": 55,
        "id": "reclaQfG5dL7WeOFf",
        "row": 21834,
        "state": "",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "reclh2zPPa7OVSpsw",
        "row": 21847,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2024": 58,
        "id": "recnwfgAB2bwYmL9X",
        "row": 21824,
        "state": "",
        "group": "NYS018",
        "group_num": [
            "recYA90msKsTqpsZc"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "id": "recP1pwhl7GpVIHii",
        "row": 21807,
        "state": "",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3193,
        "id": "recRFJyZVkm5Vb4Jt",
        "row": 21829,
        "state": "",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recRnnHhWRTztYyZ1",
        "row": 21830,
        "state": "",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 90,
        "id": "recTFcoFdjRd7KOtn",
        "row": 21848,
        "state": "",
        "group": "",
        "group_num": [
            "recW21XrDkYDwy0t8"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 120,
        "id": "recUC7bRAENe27dRe",
        "row": 21845,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1423,
        "id": "recVTlhYuc2Q9pAIb",
        "row": 21850,
        "state": "",
        "group": "",
        "group_num": [
            "recBMooc6Io88omHQ"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2451,
        "2023": 2250,
        "id": "recWhV3WGfFrs4TQ8",
        "row": 21808,
        "state": "",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "2023": 3800,
        "id": "recYOUiBTGxcMM9mx",
        "row": 21822,
        "state": "",
        "group": "NYS018",
        "group_num": [
            "recYA90msKsTqpsZc"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "rec0nLt3fatRMSc04",
        "row": 21823,
        "state": "",
        "group": "NYS018",
        "group_num": [
            "recYA90msKsTqpsZc"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "2023": 2950,
        "id": "rec219MjktPgNcORV",
        "row": 21835,
        "state": "",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "rec21pKQXswumWBG5",
        "row": 21832,
        "state": "",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3743,
        "2023": 4562,
        "id": "rec2CDQgwBJt1iCgP",
        "row": 21812,
        "state": "",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3750,
        "2023": 3750,
        "id": "rec2ebWGTypnWPZD3",
        "row": 21833,
        "state": "",
        "group": "NYS1102",
        "group_num": [
            "recH0zy3t2oJ8RbvB"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "rec5TBgKvuUI00cT8",
        "row": 21854,
        "state": "",
        "group": "Y00001UU7A",
        "group_num": [
            "recVc9w4DJjeAH5PS"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 51,
        "id": "rec6iLLJAqLQiywGJ",
        "row": 21849,
        "state": "",
        "group": "SH11",
        "group_num": [
            "reckcXcBtYjEpLVW0"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec7xddgvZWdD00YQ",
        "row": 21831,
        "state": "",
        "group": "",
        "group_num": [
            "rec5J7WfM21sQt1Id"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 139,
        "2023": 139,
        "id": "rec8fWMSsk3ak3Sci",
        "row": 21828,
        "state": "",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "reccXXlOono5LwkNB",
        "row": 21811,
        "state": "",
        "group": "NYS010",
        "group_num": [
            "rec4tZqWTgezsRyyB"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "reccyit4EIQNCBauo",
        "row": 21856,
        "state": "",
        "group": "Y00146",
        "group_num": [
            "recUreBA7ookG2NP7"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "2023": 3200,
        "id": "recdWwf0A9PZPvaa5",
        "row": 21839,
        "state": "",
        "group": "",
        "group_num": [
            "recIn5ijLg0kF5YHB"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5000,
        "id": "recoyvkUhBaFqflUd",
        "row": 21842,
        "state": "",
        "group": "",
        "group_num": [
            "recIn5ijLg0kF5YHB"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "recp9uF6juioj83C7",
        "row": 21836,
        "state": "",
        "group": "NYSHIP",
        "group_num": [
            "recc2sfOZHzIb0rR4"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "2024": 6000,
        "id": "recuw6FoKR3swCxDs",
        "row": 21821,
        "state": "",
        "group": "NYS015",
        "group_num": [
            "recr18guteE0NBZWM"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3679,
        "2023": 3750,
        "id": "recuF7SnUfvUFjoxB",
        "row": 21827,
        "state": "",
        "group": "NYS020",
        "group_num": [
            "reccgKJ1v6LItpNDW"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recvFZTNAU0rWQki1",
        "row": 21841,
        "state": "",
        "group": "",
        "group_num": [
            "recIn5ijLg0kF5YHB"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recxx0hHZ3P2C4sBu",
        "row": 21853,
        "state": "",
        "group": "Y00001UU7A",
        "group_num": [
            "recVc9w4DJjeAH5PS"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "2023": 4750,
        "id": "recxeGZaSDinItbEU",
        "row": 21852,
        "state": "",
        "group": "Y00001UU7A",
        "group_num": [
            "recVc9w4DJjeAH5PS"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "2024": 4750,
        "id": "recyXUgXUS9F71zzO",
        "row": 21820,
        "state": "",
        "group": "NYS015",
        "group_num": [
            "recr18guteE0NBZWM"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1906,
        "id": "recz8VXHbSG8UEcGy",
        "row": 21851,
        "state": "",
        "group": "",
        "group_num": [
            "recBMooc6Io88omHQ"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1699,
        "id": "reczndWjWCMmfN6lH",
        "row": 21838,
        "state": "",
        "group": "",
        "group_num": [
            "recjdHY6NW7PconO6"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 135,
        "id": "recBYfArfHvUg3cWp",
        "row": 21814,
        "state": "",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "OP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recBRGqCViVSH35Bd",
        "row": 21810,
        "state": "",
        "group": "NYS010",
        "group_num": [
            "rec4tZqWTgezsRyyB"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recCuuzyU6g43nDtE",
        "row": 21817,
        "state": "",
        "group": "",
        "group_num": [
            "recr3YSPUwA9YSf5S"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2893,
        "id": "recCHw9D8PWevlgfd",
        "row": 21840,
        "state": "",
        "group": "",
        "group_num": [
            "recIn5ijLg0kF5YHB"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "recCI4KPNNeh2m2Ct",
        "row": 21843,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3152,
        "id": "recDCvwQKfNYVzEQc",
        "row": 21844,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "id": "recGP1b30uC9oCVQt",
        "row": 21855,
        "state": "",
        "group": "Y00001UU7A",
        "group_num": [
            "recVc9w4DJjeAH5PS"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "id": "recG32L8ti6xpeudC",
        "row": 21816,
        "state": "",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2075,
        "2023": 2500,
        "2024": 2500,
        "id": "recGioLi6hi9unz8Y",
        "row": 21825,
        "state": "",
        "group": "NYS018",
        "group_num": [
            "recYA90msKsTqpsZc"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1926,
        "id": "recHz4cfmsHxmLBev",
        "row": 21846,
        "state": "",
        "group": "P13346",
        "group_num": [
            "reck7Jq6F6kEQYiHy"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1974,
        "2023": 2231,
        "2024": 2500,
        "id": "recIvP9ht6o1uMzFk",
        "row": 21815,
        "state": "",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2683,
        "2023": 3000,
        "2024": 3000,
        "id": "recKqRuCqe6r4ibEb",
        "row": 21813,
        "state": "",
        "group": "NYS012",
        "group_num": [
            "recdai3S827JXtFfZ"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5747,
        "id": "recLCpMSkfztQkVSQ",
        "row": 21809,
        "state": "",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recNZEA9xRfqKYs5Y",
        "row": 21837,
        "state": "",
        "group": "NYSHIP",
        "group_num": [
            "recc2sfOZHzIb0rR4"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recW48FxdWJvfuGea",
        "row": 21865,
        "state": "",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1650,
        "id": "recWm45YUMgxiZpJz",
        "row": 21867,
        "state": "",
        "group": "",
        "group_num": [
            "recysEKdEeLA360e5"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recXoNa7EuGbqiLY2",
        "row": 21868,
        "state": "",
        "group": "YO0731",
        "group_num": [
            "rec4SuBFHdneHaFk9"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recYjqotvwziR47tb",
        "row": 21861,
        "state": "",
        "group": "",
        "group_num": [
            "rec6u09dIfcmJdtSh"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recpFk4LocCDCxepZ",
        "row": 21863,
        "state": "",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "2023": 305,
        "id": "recrA156TtIrLqYO3",
        "row": 21857,
        "state": "",
        "group": "",
        "group_num": [
            "reclU8L01c1dchd3z"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 113,
        "2023": 105,
        "id": "recrrlumMJI8NsqpY",
        "row": 21862,
        "state": "",
        "group": "",
        "group_num": [
            "rec6u09dIfcmJdtSh"
        ],
        "procedure": "IOP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recsSInmvSlgJkSCJ",
        "row": 21860,
        "state": "",
        "group": "",
        "group_num": [
            "reczidgUGdOw2clC9"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 558,
        "id": "recv7rSk1RWX3qKcG",
        "row": 21866,
        "state": "",
        "group": "",
        "group_num": [
            "rec2WuVWJo5xcLEB7"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recGWQzwMtEJ2IY82",
        "row": 21859,
        "state": "",
        "group": "Y00370",
        "group_num": [
            "recYLvz4EAZ2SLZRW"
        ],
        "procedure": "Detox",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "2023": 250,
        "id": "recKCoHXOrqQzviWb",
        "row": 21864,
        "state": "",
        "group": "Y00966",
        "group_num": [
            "recc1CaJelN8wjNsa"
        ],
        "procedure": "PHP",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recKPwn2Xd1F2PXO6",
        "row": 21858,
        "state": "",
        "group": "Y00370",
        "group_num": [
            "recYLvz4EAZ2SLZRW"
        ],
        "procedure": "RTC",
        "provider": "BEACON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2060,
        "id": "recawfbRoLAhuWVLB",
        "row": 19255,
        "state": "",
        "group": "282505M003",
        "group_num": [
            "reckSXTwSPysXmVK4"
        ],
        "procedure": "Detox",
        "provider": "BEACON ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1563,
        "id": "recrGo3IGE688lzzo",
        "row": 19254,
        "state": "",
        "group": "282505M003",
        "group_num": [
            "reckSXTwSPysXmVK4"
        ],
        "procedure": "RTC",
        "provider": "BEACON ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recZitKLIe15p4V3Q",
        "row": 21869,
        "state": "",
        "group": "",
        "group_num": [
            "rec8gmWCFnevPajIm"
        ],
        "procedure": "Detox",
        "provider": "BEAT IT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 350,
        "id": "rechUGHFjhBS2v1Hg",
        "row": 21873,
        "state": "",
        "group": "0074694002",
        "group_num": [
            "reci53fRsKWgYexy1"
        ],
        "procedure": "IOP",
        "provider": "BEHAVIORAL HEALTH SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 128,
        "id": "recSDRAbhxRElVTFV",
        "row": 21872,
        "state": "",
        "group": "0074694002",
        "group_num": [
            "reci53fRsKWgYexy1"
        ],
        "procedure": "OP",
        "provider": "BEHAVIORAL HEALTH SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "rec7WkjYLeGgO2Fc3",
        "row": 21871,
        "state": "",
        "group": "",
        "group_num": [
            "rec4GlmFjin8zeqRK"
        ],
        "procedure": "IOP",
        "provider": "BEHAVIORAL HEALTH SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 50,
        "id": "rectU9sKJYVpNCblf",
        "row": 21870,
        "state": "",
        "group": "",
        "group_num": [
            "rec4GlmFjin8zeqRK"
        ],
        "procedure": "OP",
        "provider": "BEHAVIORAL HEALTH SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2350,
        "id": "recW0dNIh0kuQ2JWd",
        "row": 21877,
        "state": "",
        "group": "",
        "group_num": [
            "recVyjqaWGxHTZxj6"
        ],
        "procedure": "RTC",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 407,
        "2023": 407,
        "id": "rec3QL9njmDZIWcUC",
        "row": 21875,
        "state": "",
        "group": "",
        "group_num": [
            "rec6oiG2b5BhmRLHe"
        ],
        "procedure": "IOP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 127,
        "id": "recpm8BraV06s5tAD",
        "row": 21874,
        "state": "",
        "group": "",
        "group_num": [
            "rec6oiG2b5BhmRLHe"
        ],
        "procedure": "OP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1875,
        "id": "recv8U4CMzWJHWdKr",
        "row": 21876,
        "state": "",
        "group": "ACNCC016",
        "group_num": [
            "recrrNiHq1Ouej9GM"
        ],
        "procedure": "PHP",
        "provider": "BENEFIT ADMINISTRATIVE SYSTEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3296,
        "id": "recVAMdk0JZOJAcCD",
        "row": 21878,
        "state": "",
        "group": "",
        "group_num": [
            "recnbcy7TecnOg3A9"
        ],
        "procedure": "Detox",
        "provider": "BIND BENEFITS, INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "rec3f4zxxUSINGTFk",
        "row": 21879,
        "state": "",
        "group": "",
        "group_num": [
            "recbUgfZmBIgVTF6K"
        ],
        "procedure": "RTC",
        "provider": "BIND BENEFITS, INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "reczsW2IgQoy6xDsb",
        "row": 21883,
        "state": "",
        "group": "",
        "group_num": [
            "recVrVDgrM6wHPjT7"
        ],
        "procedure": "Detox",
        "provider": "BIND HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "id": "recDQN84wyrzi0N9Y",
        "row": 21882,
        "state": "",
        "group": "",
        "group_num": [
            "recxwdvMqeHYvK1jp"
        ],
        "procedure": "PHP",
        "provider": "BIND HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recJIWs51Iyc9mL1P",
        "row": 21881,
        "state": "",
        "group": "",
        "group_num": [
            "reckeQcrnh2w5labO"
        ],
        "procedure": "Detox",
        "provider": "BIND HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2620,
        "id": "recJjQpEDP9oZxvyL",
        "row": 21880,
        "state": "",
        "group": "",
        "group_num": [
            "reckeQcrnh2w5labO"
        ],
        "procedure": "RTC",
        "provider": "BIND HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recfv2D46Q7UhPKZg",
        "row": 19258,
        "state": "",
        "group": "",
        "group_num": [
            "recCuRMAKLoBDZuRV"
        ],
        "procedure": "IOP",
        "provider": "BLUE ADVANTAGE ADMINISTRATORS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 357,
        "id": "recRyISNPEcHdmWeo",
        "row": 19256,
        "state": "",
        "group": "",
        "group_num": [
            "recRlGu1dIOt29xot"
        ],
        "procedure": "RTC",
        "provider": "BLUE ADVANTAGE ADMINISTRATORS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recRDk1s2BbR4HCwE",
        "row": 19257,
        "state": "",
        "group": "",
        "group_num": [
            "recCuRMAKLoBDZuRV"
        ],
        "procedure": "PHP",
        "provider": "BLUE ADVANTAGE ADMINISTRATORS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recwLQG7nHxVwgZS7",
        "row": 19259,
        "state": "",
        "group": "EG01100001",
        "group_num": [
            "recga4ga0Vp6PfYGQ"
        ],
        "procedure": "RTC",
        "provider": "BLUE ADVANTAGE ADMINISTRATORS OF AR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recgR15F2r1Ltw0Dn",
        "row": 19274,
        "state": "",
        "group": "",
        "group_num": [
            "recDRLAKVeCXwAE9q"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 81,
        "id": "recmZEqRuar6rel8Y",
        "row": 19264,
        "state": "",
        "group": "",
        "group_num": [
            "recKCWm73aD00RxO1"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recRWkllDMKu7i17J",
        "row": 19275,
        "state": "",
        "group": "W8000019",
        "group_num": [
            "recDuL5OzBzSlzeJ1"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recRz6Sn6tSudzbhk",
        "row": 19268,
        "state": "",
        "group": "",
        "group_num": [
            "rec51B9WfddjTVvVs"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 340,
        "id": "recW3dr4J04mO8L7s",
        "row": 19282,
        "state": "",
        "group": "W0051486",
        "group_num": [
            "reclqbmSM4sA7lhJm"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "id": "rec0NIicZcyIiOBEt",
        "row": 19276,
        "state": "",
        "group": "W8000019",
        "group_num": [
            "recDuL5OzBzSlzeJ1"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 674,
        "id": "rec04Gtm9g5zoJPlW",
        "row": 19267,
        "state": "",
        "group": "",
        "group_num": [
            "recb92bbqQBLvzdVk"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 497,
        "id": "rec34K1D7s0yL5rit",
        "row": 19273,
        "state": "",
        "group": "",
        "group_num": [
            "recDRLAKVeCXwAE9q"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 463,
        "id": "rec4uYBMpcWVKbIg7",
        "row": 19277,
        "state": "",
        "group": "",
        "group_num": [
            "rec6isTQqsS7ATk9A"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2119,
        "id": "rec4OdLJqMSAko5RV",
        "row": 19265,
        "state": "",
        "group": "",
        "group_num": [
            "recKCWm73aD00RxO1"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1440,
        "id": "recbKAoDGd7fgdVY2",
        "row": 19262,
        "state": "",
        "group": "",
        "group_num": [
            "reco8klpW6vLhsvdL"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recpfhbLkkePaLW7B",
        "row": 19270,
        "state": "",
        "group": "",
        "group_num": [
            "recXcRJZffKUWCQTv"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "rect2XO1MhuHd1CW4",
        "row": 19280,
        "state": "",
        "group": "",
        "group_num": [
            "recVay4r5YbfTUwAW"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "2023": 40,
        "id": "reczTpSLgsevKg8vw",
        "row": 19271,
        "state": "",
        "group": "",
        "group_num": [
            "recXcRJZffKUWCQTv"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1174,
        "id": "recAMVI97fcAsV017",
        "row": 19283,
        "state": "",
        "group": "W0051486",
        "group_num": [
            "reclqbmSM4sA7lhJm"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 978,
        "id": "recANIlYR7BU8CzLC",
        "row": 19269,
        "state": "",
        "group": "",
        "group_num": [
            "recXcRJZffKUWCQTv"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recAcQrPhDhsbjWbd",
        "row": 19263,
        "state": "",
        "group": "",
        "group_num": [
            "recKCWm73aD00RxO1"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 350,
        "id": "recELeF6wVjyJO6C6",
        "row": 19260,
        "state": "",
        "group": "",
        "group_num": [
            "recfW1hmEgjpdYs5c"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "recFCeWs81Tu37wZn",
        "row": 19261,
        "state": "",
        "group": "",
        "group_num": [
            "recfW1hmEgjpdYs5c"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 217,
        "id": "recIRFtczhWlBfcuZ",
        "row": 19266,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1006,
        "id": "recJH278wpcwLTqjK",
        "row": 19278,
        "state": "",
        "group": "",
        "group_num": [
            "rec6isTQqsS7ATk9A"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 366,
        "id": "recJ9xtbZ2oSgBeTk",
        "row": 19281,
        "state": "",
        "group": "",
        "group_num": [
            "rec0ftSha4ZW0Y7pc"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 651,
        "id": "recJora7REr5DvV3s",
        "row": 19272,
        "state": "",
        "group": "",
        "group_num": [
            "recqCJn7yr0oe4cGh"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 538,
        "id": "recLWdOIqjbL2CVQ5",
        "row": 19279,
        "state": "",
        "group": "",
        "group_num": [
            "recVay4r5YbfTUwAW"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recfdnfa0JCsx7bon",
        "row": 19311,
        "state": "",
        "group": "",
        "group_num": [
            "rec2hPLqLSSAwf71E"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "2024": 40,
        "id": "recgoBQ7AV9Ex4Wdi",
        "row": 19320,
        "state": "",
        "group": "",
        "group_num": [
            "recPfp8UqNFsTKAwZ"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 687,
        "2024": 687,
        "id": "rechE8m4idcQ4ZOj8",
        "row": 19310,
        "state": "",
        "group": "",
        "group_num": [
            "reclKcRAUhSF4hkJI"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 220,
        "id": "reci70doDzUFHMOE7",
        "row": 19284,
        "state": "",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recksEG5tbaL8b1ph",
        "row": 19317,
        "state": "",
        "group": "W0071937",
        "group_num": [
            "recYklTQYoc1XwTas"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 362,
        "id": "reck24VptdmWipWwj",
        "row": 19285,
        "state": "",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 263,
        "id": "recOJEotH06K4l6LA",
        "row": 19332,
        "state": "",
        "group": "",
        "group_num": [
            "recpypaW0HF57kjCH"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 954,
        "2024": 546,
        "id": "recQZBS9kmaCK9j8o",
        "row": 19308,
        "state": "",
        "group": "",
        "group_num": [
            "reclKcRAUhSF4hkJI"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recQlRZyBgVZpwRst",
        "row": 19312,
        "state": "",
        "group": "",
        "group_num": [
            "rec2hPLqLSSAwf71E"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recRr37f6lD8Me5SL",
        "row": 19315,
        "state": "",
        "group": "W0070120",
        "group_num": [
            "recjhE70wbCReFM2j"
        ],
        "procedure": "Detox",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "2023": 1440,
        "id": "recSQ3zSCavKNtK5a",
        "row": 19290,
        "state": "",
        "group": "",
        "group_num": [
            "recavj4r85FfwCVO2"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recSRtufHIxoD7okw",
        "row": 19299,
        "state": "",
        "group": "",
        "group_num": [
            "reckADExfKz8uIJiJ"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 258,
        "id": "recUi4bJ07PxnesNg",
        "row": 19319,
        "state": "",
        "group": "",
        "group_num": [
            "recPfp8UqNFsTKAwZ"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 148,
        "2024": 40,
        "id": "recWzLVRq5Fab5I70",
        "row": 19295,
        "state": "",
        "group": "",
        "group_num": [
            "recbpHdrcLUhilRfA"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 141,
        "id": "recWOlJ1E07FYI9uh",
        "row": 19288,
        "state": "",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 232,
        "id": "recWoo1QUb00T6gre",
        "row": 19302,
        "state": "",
        "group": "",
        "group_num": [
            "recRQvtDArfdfBVRh"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "recXvO6piRi4Sh8XC",
        "row": 19313,
        "state": "",
        "group": "",
        "group_num": [
            "rec2hPLqLSSAwf71E"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 283,
        "id": "recYZwAb2lHiF4eE6",
        "row": 19326,
        "state": "",
        "group": "",
        "group_num": [
            "recVuBEKkNWeBMT7s"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recZQZQcqXeVxakJq",
        "row": 19318,
        "state": "",
        "group": "",
        "group_num": [
            "recPfp8UqNFsTKAwZ"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "rec0xRisft2M5Unxp",
        "row": 19328,
        "state": "",
        "group": "",
        "group_num": [
            "recVuBEKkNWeBMT7s"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 546,
        "id": "rec0RLOGu5oHwRBmB",
        "row": 19333,
        "state": "",
        "group": "",
        "group_num": [
            "reckxfT36KXpZJISw"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 274,
        "id": "rec1G4Kl1gcvoNYvO",
        "row": 19309,
        "state": "",
        "group": "",
        "group_num": [
            "reclKcRAUhSF4hkJI"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "rec1ojx6Jte7rgYGb",
        "row": 19305,
        "state": "",
        "group": "",
        "group_num": [
            "recg1pD70G4IPJJph"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 273,
        "2024": 297,
        "id": "rec3lTNcEQmtiObNJ",
        "row": 19291,
        "state": "",
        "group": "",
        "group_num": [
            "recA4XV8iVUF3kGEn"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "id": "rec3olcnqr1uEQDYq",
        "row": 19300,
        "state": "",
        "group": "",
        "group_num": [
            "recONab0d85y2iTin"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 345,
        "id": "rec7LWd12IDg65ase",
        "row": 19324,
        "state": "",
        "group": "",
        "group_num": [
            "recZrtMB1DLTNSFtV"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 877,
        "id": "rec7gMMEeeFinMECm",
        "row": 19322,
        "state": "",
        "group": "W0079105",
        "group_num": [
            "recHrEK7hujw8RYlK"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 38,
        "id": "rec7nrtlG32yMtoDF",
        "row": 19303,
        "state": "",
        "group": "",
        "group_num": [
            "recRQvtDArfdfBVRh"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 207,
        "id": "rec9hPvgsay3eKP9C",
        "row": 19306,
        "state": "",
        "group": "",
        "group_num": [
            "recg1pD70G4IPJJph"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 345,
        "id": "rec9oXA5gBt0q21Ru",
        "row": 19327,
        "state": "",
        "group": "",
        "group_num": [
            "recVuBEKkNWeBMT7s"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 245,
        "id": "recbb1hExqnP57UdX",
        "row": 19330,
        "state": "",
        "group": "",
        "group_num": [
            "rec8Rh8tksIhVMFhP"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recbrba9fMhlbEpb5",
        "row": 19296,
        "state": "",
        "group": "",
        "group_num": [
            "recKrAqSJJYrmyzii"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recoatu7q7JnbxJyr",
        "row": 19316,
        "state": "",
        "group": "W0071937",
        "group_num": [
            "recYklTQYoc1XwTas"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 243,
        "id": "recqk3zm4rlE15ZCF",
        "row": 19331,
        "state": "",
        "group": "",
        "group_num": [
            "rec5cHf9FNgbHXySx"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "2024": 350,
        "id": "recqlOgBaOHiYcpuO",
        "row": 19323,
        "state": "",
        "group": "",
        "group_num": [
            "recZrtMB1DLTNSFtV"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "rectdlBOLrmHZThGs",
        "row": 19298,
        "state": "",
        "group": "",
        "group_num": [
            "recsyYnvOs5RElxMY"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 281,
        "id": "recvn0Tkq7OnI1TPY",
        "row": 19307,
        "state": "",
        "group": "",
        "group_num": [
            "recKZKsqiuMtZ8CeK"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 648,
        "id": "recwAWhRmrk9JVcXI",
        "row": 19321,
        "state": "",
        "group": "",
        "group_num": [
            "recPfp8UqNFsTKAwZ"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 742,
        "2023": 500,
        "id": "recyVErpzEg9qFLTX",
        "row": 19289,
        "state": "",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recyrMyRBzr8HmQt7",
        "row": 19293,
        "state": "",
        "group": "",
        "group_num": [
            "recbpHdrcLUhilRfA"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 345,
        "id": "recA4OIE7nBNicbMy",
        "row": 19294,
        "state": "",
        "group": "",
        "group_num": [
            "recbpHdrcLUhilRfA"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 40,
        "id": "recA9hNC0qY03urtX",
        "row": 19301,
        "state": "",
        "group": "",
        "group_num": [
            "recF5DKsxJ3cWnChl"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 360,
        "id": "recCrReVWtk6s1ybN",
        "row": 19287,
        "state": "",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 563,
        "id": "recD1asB2KYG21gyM",
        "row": 19304,
        "state": "",
        "group": "",
        "group_num": [
            "recRQvtDArfdfBVRh"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1006,
        "id": "recGqWyDdFRAK7IEq",
        "row": 19325,
        "state": "",
        "group": "",
        "group_num": [
            "recZrtMB1DLTNSFtV"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 679,
        "id": "recHCS6UzbuLod7H0",
        "row": 19286,
        "state": "",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "2023": 268,
        "id": "recHfYnKVPWqETGfr",
        "row": 19297,
        "state": "",
        "group": "",
        "group_num": [
            "recsyYnvOs5RElxMY"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1054,
        "id": "recIGA2qERWq1eyap",
        "row": 19329,
        "state": "",
        "group": "",
        "group_num": [
            "recVuBEKkNWeBMT7s"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recJVOy6PwG4ZDXK9",
        "row": 19314,
        "state": "",
        "group": "W0070120",
        "group_num": [
            "recjhE70wbCReFM2j"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 236,
        "2024": 297,
        "id": "recK0tXk8yc5jTIGI",
        "row": 19292,
        "state": "",
        "group": "",
        "group_num": [
            "recA4XV8iVUF3kGEn"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 16,
        "2023": 23,
        "2024": 26,
        "id": "receOGWiD6Se7FqcR",
        "row": 19336,
        "state": "",
        "group": "",
        "group_num": [
            "recbAPhti4Yi9V4TW"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 245,
        "id": "recjU3xWn28LAclVv",
        "row": 19360,
        "state": "",
        "group": "",
        "group_num": [
            "recmwbXMUXXPNzfxT"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "id": "recjXWc2Cur2tJOlQ",
        "row": 19339,
        "state": "",
        "group": "",
        "group_num": [
            "recBntWkjdiop8gLg"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "2023": 308,
        "2024": 299,
        "id": "recjNtSGR2H7CDTn0",
        "row": 19354,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 284,
        "2023": 350,
        "id": "recmcDCfn2W8MFThb",
        "row": 19344,
        "state": "",
        "group": "",
        "group_num": [
            "recqmp1c2KGczBGtW"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 380,
        "2024": 357,
        "id": "recRsGYTLUtZe8XYr",
        "row": 19362,
        "state": "",
        "group": "",
        "group_num": [
            "recASg0FO3ZnINIwA"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 183,
        "id": "recTp7S0CdS8wrANT",
        "row": 19358,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "2023": 319,
        "id": "recXJtz4YxnNKlvv2",
        "row": 19350,
        "state": "",
        "group": "",
        "group_num": [
            "recLSf7KVuVIc6wTg"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 245,
        "id": "recYDrzEWlujNKPxA",
        "row": 19345,
        "state": "",
        "group": "",
        "group_num": [
            "recqmp1c2KGczBGtW"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 414,
        "id": "recZcFHevd8qDWiIL",
        "row": 19346,
        "state": "",
        "group": "",
        "group_num": [
            "recpAWK59g9zlvisj"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 39,
        "id": "rec2IBOAyhP2TfQes",
        "row": 19340,
        "state": "",
        "group": "",
        "group_num": [
            "recBntWkjdiop8gLg"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 699,
        "id": "rec4WIAC39EqAgHva",
        "row": 19343,
        "state": "",
        "group": "",
        "group_num": [
            "recGQo7vpSWm1zW8M"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 236,
        "id": "rec4wPUXuvEVpn6yA",
        "row": 19337,
        "state": "",
        "group": "",
        "group_num": [
            "recDrd7PnprbA0haL"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recbjWOxOkk1XhU09",
        "row": 19334,
        "state": "",
        "group": "",
        "group_num": [
            "recbAPhti4Yi9V4TW"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 459,
        "2023": 478,
        "2024": 500,
        "id": "reccpgYhNEd1G8sKw",
        "row": 19356,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "recplhN2e1XAqxu7p",
        "row": 19361,
        "state": "",
        "group": "",
        "group_num": [
            "recmwbXMUXXPNzfxT"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recsmuM1JlPERWyrk",
        "row": 19348,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 224,
        "id": "rect1ISXp4dnGfcWS",
        "row": 19359,
        "state": "",
        "group": "",
        "group_num": [
            "recbmbuHMlvee7tQN"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 241,
        "2023": 271,
        "id": "rectwlaZKIqLFl3PQ",
        "row": 19335,
        "state": "",
        "group": "",
        "group_num": [
            "recbAPhti4Yi9V4TW"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "2023": 40,
        "2024": 40,
        "id": "rectRldnajib7qfti",
        "row": 19351,
        "state": "",
        "group": "",
        "group_num": [
            "recLSf7KVuVIc6wTg"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recvP6P9kle66P1iT",
        "row": 19341,
        "state": "",
        "group": "",
        "group_num": [
            "recZGXqLdPYFTt5Jb"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 441,
        "2023": 478,
        "2024": 500,
        "id": "recwrI6t8wvuPvH8X",
        "row": 19353,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "reczQhx56f1I4wZSg",
        "row": 19342,
        "state": "",
        "group": "",
        "group_num": [
            "recGQo7vpSWm1zW8M"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recBGzmBPlxTN0eTP",
        "row": 19347,
        "state": "",
        "group": "W8002426",
        "group_num": [
            "recAAX7CCBHOq5PSc"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 396,
        "id": "recC20BYu4R9Ii006",
        "row": 19363,
        "state": "",
        "group": "",
        "group_num": [
            "recE7LtWiA2EdLtWN"
        ],
        "procedure": "IOP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recCmSzQMonVeooRw",
        "row": 19357,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recDT3nwP6OK17knl",
        "row": 19338,
        "state": "",
        "group": "",
        "group_num": [
            "recDrd7PnprbA0haL"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 488,
        "id": "recHbO2lSjLNxi1sd",
        "row": 19352,
        "state": "",
        "group": "",
        "group_num": [
            "recLSf7KVuVIc6wTg"
        ],
        "procedure": "RTC",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 382,
        "2023": 454,
        "id": "recIypfnqmFUT0g2d",
        "row": 19349,
        "state": "",
        "group": "",
        "group_num": [
            "recLSf7KVuVIc6wTg"
        ],
        "procedure": "PHP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 41,
        "2023": 37,
        "id": "recIEyZgKZDlTmGjK",
        "row": 19355,
        "state": "",
        "group": "",
        "group_num": [
            "recaRxTHZayqj9My7"
        ],
        "procedure": "OP",
        "provider": "BLUE SHIELD OF CA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 684,
        "id": "recj3rQ1ZyZCXLmpi",
        "row": 21886,
        "state": "",
        "group": "",
        "group_num": [
            "recQl5k41JGtY26Yx"
        ],
        "procedure": "RTC",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 447,
        "id": "recWaqair8i4t7vzZ",
        "row": 21888,
        "state": "",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "PHP",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "id": "rec1admyM1lJ9EolZ",
        "row": 21887,
        "state": "",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "RTC",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 419,
        "id": "reczydOv6UiEznkuL",
        "row": 21884,
        "state": "",
        "group": "002357",
        "group_num": [
            "reckONKZVGvDf33Ge"
        ],
        "procedure": "PHP",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 865,
        "id": "recD6o2m8j7qPeRe8",
        "row": 21885,
        "state": "",
        "group": "002357",
        "group_num": [
            "reckONKZVGvDf33Ge"
        ],
        "procedure": "Detox",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "recJM6B9Oi27VODnt",
        "row": 21889,
        "state": "",
        "group": "650128",
        "group_num": [
            "recB5mUE4X7o1ug7j"
        ],
        "procedure": "IOP",
        "provider": "BOON CHAPMAN ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 890,
        "id": "recAJQkjB6lwVfvKN",
        "row": 21890,
        "state": "",
        "group": "650104",
        "group_num": [
            "recTsHa84WgkeIIyV"
        ],
        "procedure": "RTC",
        "provider": "BOON GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recjzVfA1KbhvcVoD",
        "row": 21892,
        "state": "",
        "group": "",
        "group_num": [
            "recpBaVIayjRULlbM"
        ],
        "procedure": "PHP",
        "provider": "BOULDER ADMINISTRATION SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 756,
        "id": "recp0OUyYlGUKd132",
        "row": 21891,
        "state": "",
        "group": "",
        "group_num": [
            "recpBaVIayjRULlbM"
        ],
        "procedure": "RTC",
        "provider": "BOULDER ADMINISTRATION SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 38,
        "2024": 38,
        "id": "recoUlpUJrhHE8CZK",
        "row": 19365,
        "state": "",
        "group": "",
        "group_num": [
            "reccXtXLX9FmYz9kd"
        ],
        "procedure": "OP",
        "provider": "BRIGHT HEALTH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1238,
        "id": "recN0QdID14y3iMHC",
        "row": 19364,
        "state": "",
        "group": "76180",
        "group_num": [
            "recfjjkWNT8p5kfiK"
        ],
        "procedure": "IOP",
        "provider": "BRIGHT HEALTH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1787,
        "2024": 1889,
        "id": "recn62DsnnwSxXoP3",
        "row": 19367,
        "state": "",
        "group": "10594915",
        "group_num": [
            "recLTVO0eDeztzHA1"
        ],
        "procedure": "RTC",
        "provider": "BS OF NORTHEASTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1970,
        "id": "rec2rqAWMVtleD9vf",
        "row": 19368,
        "state": "",
        "group": "10594915",
        "group_num": [
            "recLTVO0eDeztzHA1"
        ],
        "procedure": "Detox",
        "provider": "BS OF NORTHEASTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 460,
        "id": "recCJmGMBB7ZcY7x0",
        "row": 19366,
        "state": "",
        "group": "10594915",
        "group_num": [
            "recLTVO0eDeztzHA1"
        ],
        "procedure": "PHP",
        "provider": "BS OF NORTHEASTERN NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 414,
        "id": "rech3X3NZJJ8rWCmd",
        "row": 19369,
        "state": "",
        "group": "",
        "group_num": [
            "recf8oCwCFTpyQqox"
        ],
        "procedure": "IOP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "id": "reckoF8DHhY7rh3Bq",
        "row": 19371,
        "state": "",
        "group": "BHB361",
        "group_num": [
            "recm4zYUdiIPhOaIP"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 568,
        "2023": 3429,
        "id": "reclFGz7YgwqRmh4F",
        "row": 19376,
        "state": "",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recmZbBw05jP2kBm0",
        "row": 19374,
        "state": "",
        "group": "DNT361",
        "group_num": [
            "recU3T8zJ7bFHBPqx"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 3912,
        "id": "recm3GjCkiPeD20tc",
        "row": 19375,
        "state": "",
        "group": "GFD361",
        "group_num": [
            "rectSm4cEZgW6GRwW"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 150,
        "id": "recS6totgU14kRh2J",
        "row": 19380,
        "state": "",
        "group": "",
        "group_num": [
            "recbaUBMD5a4hvbym"
        ],
        "procedure": "IOP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recUNPSrMwfwo54oH",
        "row": 19377,
        "state": "",
        "group": "LBC361",
        "group_num": [
            "reco2vOUaWBSPuAqb"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 408,
        "id": "recowyVqTNiACTGWx",
        "row": 19372,
        "state": "",
        "group": "CIK361",
        "group_num": [
            "rec75Uhl6z8I0OuQu"
        ],
        "procedure": "PHP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 277,
        "id": "recr0B5CoDi7HXJoZ",
        "row": 19379,
        "state": "",
        "group": "",
        "group_num": [
            "recbaUBMD5a4hvbym"
        ],
        "procedure": "PHP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 599,
        "id": "recr1qa0a9U1ezWWy",
        "row": 19381,
        "state": "",
        "group": "",
        "group_num": [
            "recbaUBMD5a4hvbym"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recs9QV3UN7PrIdL4",
        "row": 19382,
        "state": "",
        "group": "WPB361",
        "group_num": [
            "recyj7rkkyKBWAghR"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recC3vZyLtTkLPYWb",
        "row": 19383,
        "state": "",
        "group": "WPB361",
        "group_num": [
            "recyj7rkkyKBWAghR"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3558,
        "id": "recKS8tGTxpYbC9LF",
        "row": 19370,
        "state": "",
        "group": "BHB361",
        "group_num": [
            "recm4zYUdiIPhOaIP"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recKrRGztOJkdkrJn",
        "row": 19373,
        "state": "",
        "group": "DNT361",
        "group_num": [
            "recU3T8zJ7bFHBPqx"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "2023": 500,
        "id": "recLmQR3FW2UFBMGb",
        "row": 19378,
        "state": "",
        "group": "LBC361",
        "group_num": [
            "reco2vOUaWBSPuAqb"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recfYiP7Et2Mlirvz",
        "row": 19388,
        "state": "",
        "group": "00527289",
        "group_num": [
            "rect4IoYQQ7lEhZFV"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recgG3WJX7aXJyPvV",
        "row": 19395,
        "state": "",
        "group": "00701175",
        "group_num": [
            "rec8OXumoAQlPFDRC"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recg4oMSVGjfRT1Qb",
        "row": 19390,
        "state": "",
        "group": "00528985",
        "group_num": [
            "receCeE1KI9Gu0pwn"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "reclLsQO67V90hYjH",
        "row": 19391,
        "state": "",
        "group": "00528985",
        "group_num": [
            "receCeE1KI9Gu0pwn"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recTWZ7VT76cXKsDM",
        "row": 19387,
        "state": "",
        "group": "00514618",
        "group_num": [
            "recUj39esS4RbKe11"
        ],
        "procedure": "IOP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 1900,
        "id": "rec3QMbGrBuKb0tin",
        "row": 19385,
        "state": "",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "id": "rec4orcVe5a4phPm7",
        "row": 19396,
        "state": "",
        "group": "00702644",
        "group_num": [
            "recSzhWQ5rTkp9riz"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "2023": 2500,
        "id": "recaBakUebSOMM5BO",
        "row": 19386,
        "state": "",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "reccjDdF75fhUfddW",
        "row": 19394,
        "state": "",
        "group": "00701175",
        "group_num": [
            "rec8OXumoAQlPFDRC"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recrPjMHeb0vX9nBx",
        "row": 19399,
        "state": "",
        "group": "00704240",
        "group_num": [
            "recYyKdleQ2VFEqES"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "2023": 555,
        "id": "recwXrqi1CNB8KBcM",
        "row": 19397,
        "state": "",
        "group": "00702644",
        "group_num": [
            "recSzhWQ5rTkp9riz"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 447,
        "id": "recw92Njb5wRBYGkQ",
        "row": 19393,
        "state": "",
        "group": "",
        "group_num": [
            "recaZYBHDSWyqaNek"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recFc4t6vqxc1L5ii",
        "row": 19389,
        "state": "",
        "group": "00527289",
        "group_num": [
            "rect4IoYQQ7lEhZFV"
        ],
        "procedure": "Detox",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recKObFWVEnvPnw7s",
        "row": 19384,
        "state": "",
        "group": "00900221",
        "group_num": [
            "recm2kDDH79OxYV2D"
        ],
        "procedure": "OP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "recLoxUVQRsVl1Myk",
        "row": 19392,
        "state": "",
        "group": "",
        "group_num": [
            "recaZYBHDSWyqaNek"
        ],
        "procedure": "IOP",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3131,
        "id": "recNv2a8OK8TDZmhj",
        "row": 19398,
        "state": "",
        "group": "00704240",
        "group_num": [
            "recYyKdleQ2VFEqES"
        ],
        "procedure": "RTC",
        "provider": "CAPITAL BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1095,
        "id": "recmIQYploSIzdzRe",
        "row": 21894,
        "state": "",
        "group": "",
        "group_num": [
            "recYNpxwIcCyqUGy4"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "recqdGHSgsmzWDYvi",
        "row": 21893,
        "state": "",
        "group": "",
        "group_num": [
            "recYNpxwIcCyqUGy4"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1484,
        "id": "recTQwj5GbDGP6tAC",
        "row": 19400,
        "state": "",
        "group": "",
        "group_num": [
            "recD0K2lRH1RN8iO5"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recaRFV56PC2Qia2h",
        "row": 19401,
        "state": "",
        "group": "",
        "group_num": [
            "rec5maIsfYuL7o1tN"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 322,
        "id": "recB5ksh6EdZv5W2L",
        "row": 19402,
        "state": "",
        "group": "JEY",
        "group_num": [
            "recH1UcY9eFzXBV9Q"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "receLnZKln41xzqmY",
        "row": 19421,
        "state": "",
        "group": "JEY",
        "group_num": [
            "recH1UcY9eFzXBV9Q"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 904,
        "id": "reciaDb96sqw2Orxn",
        "row": 19405,
        "state": "",
        "group": "005802609FL10006",
        "group_num": [
            "recIMy3eyRbYO7CmN"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 755,
        "id": "reckv4fHmBiOwVC4w",
        "row": 19423,
        "state": "",
        "group": "",
        "group_num": [
            "recjZRXe3pDTeGWrK"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recmRolvSONJ7zjxt",
        "row": 19426,
        "state": "",
        "group": "99K1",
        "group_num": [
            "rec7cekqMFmH11Xgr"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recnPzDHVknrzFwx4",
        "row": 19407,
        "state": "",
        "group": "",
        "group_num": [
            "recjTS0p5uEgmcyuS"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 794,
        "id": "recPcSM5gKcAt9avH",
        "row": 19431,
        "state": "",
        "group": "",
        "group_num": [
            "recMgslEcpcPhu0vB"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 936,
        "2023": 938,
        "id": "recQvG7KuQnnx3hhY",
        "row": 19411,
        "state": "",
        "group": "001903623MD10006",
        "group_num": [
            "recYoAfJgDHuE9JHz"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recQAxnBg0NE3G5GE",
        "row": 19420,
        "state": "",
        "group": "",
        "group_num": [
            "recl33Bop1hTeVSDC"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 902,
        "2023": 1104,
        "id": "recVUrIzbmwaFo7ty",
        "row": 19433,
        "state": "",
        "group": "005801171DC10006",
        "group_num": [
            "rec7MMSeHWCoORIi9"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 23,
        "id": "recXDdGMJg5hpxWbY",
        "row": 19413,
        "state": "",
        "group": "",
        "group_num": [
            "reccttiHTxHj9iL4j"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recXDiYbtuFwgUPtS",
        "row": 19414,
        "state": "",
        "group": "",
        "group_num": [
            "recgf3OUuYzuu7io3"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 196,
        "id": "rec1A0fi6bZiWxjtZ",
        "row": 19428,
        "state": "",
        "group": "99N1",
        "group_num": [
            "rechRK5CNHSxIfy1K"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 267,
        "id": "rec2meitpvOqPSyBz",
        "row": 19429,
        "state": "",
        "group": "99EF",
        "group_num": [
            "recqIx3Yq6s8JtA90"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 301,
        "id": "rec5t4nb4VZjIxcsY",
        "row": 19404,
        "state": "",
        "group": "",
        "group_num": [
            "recvVJsUUFy7JSoSj"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 23,
        "id": "rec92pnvCzTZ2AIvi",
        "row": 19410,
        "state": "",
        "group": "",
        "group_num": [
            "rech5xS93Y3D6lKSa"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "rec9kg6DsxSKACU5e",
        "row": 19403,
        "state": "",
        "group": "",
        "group_num": [
            "recpyEOQolJSwbS7w"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 610,
        "2024": 142,
        "id": "recau373BRmxUHYz0",
        "row": 19424,
        "state": "",
        "group": "",
        "group_num": [
            "recDCqu6eBlWlBwGn"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recdWFhSfkvUHGLUW",
        "row": 19430,
        "state": "",
        "group": "005801937FL10016",
        "group_num": [
            "recrgbXfFRnGVJCHq"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 970,
        "id": "recdPfI1TbJHQ6ROb",
        "row": 19406,
        "state": "",
        "group": "005802609FL10006",
        "group_num": [
            "recIMy3eyRbYO7CmN"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 134,
        "2024": 132,
        "id": "recsojZaIo0ETjLv4",
        "row": 19409,
        "state": "",
        "group": "",
        "group_num": [
            "rech5xS93Y3D6lKSa"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 32,
        "id": "rectIlbw7Zmt7YIkJ",
        "row": 19425,
        "state": "",
        "group": "",
        "group_num": [
            "recDCqu6eBlWlBwGn"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "2024": 142,
        "id": "rectM5zKJHik37evY",
        "row": 19415,
        "state": "",
        "group": "",
        "group_num": [
            "recgf3OUuYzuu7io3"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recvvbH95rQ47Pfvm",
        "row": 19412,
        "state": "",
        "group": "",
        "group_num": [
            "reccttiHTxHj9iL4j"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recxMiT2fEPFeRY07",
        "row": 19422,
        "state": "",
        "group": "JEY",
        "group_num": [
            "recH1UcY9eFzXBV9Q"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recx78Rma3z8ebW9b",
        "row": 19408,
        "state": "",
        "group": "",
        "group_num": [
            "recTM4WTSVZ9BIRf7"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "reczpUAf5axp3T69Z",
        "row": 19419,
        "state": "",
        "group": "",
        "group_num": [
            "recl33Bop1hTeVSDC"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recBZhgrR696Nm57E",
        "row": 19432,
        "state": "",
        "group": "",
        "group_num": [
            "recKM1xIHphJEF91I"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1666,
        "id": "recCbTwxG3XHpWVIA",
        "row": 19418,
        "state": "",
        "group": "005801706DC10240",
        "group_num": [
            "rec8HtXDWIYEqjIIu"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 1190,
        "id": "recDLq2f8TLOwofA9",
        "row": 19416,
        "state": "",
        "group": "F6D",
        "group_num": [
            "reciqV0knLTr3XjJo"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recF9A81lUll6fxUR",
        "row": 19427,
        "state": "",
        "group": "99N1",
        "group_num": [
            "rechRK5CNHSxIfy1K"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 952,
        "id": "recNq7Pit4pryOSBt",
        "row": 19417,
        "state": "",
        "group": "F6D",
        "group_num": [
            "reciqV0knLTr3XjJo"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recf096RP29yxxIXf",
        "row": 19448,
        "state": "",
        "group": "99EE",
        "group_num": [
            "recwmLTQBcnuuOQOU"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 131,
        "id": "rechXsjzb8twifXLe",
        "row": 19441,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 338,
        "id": "rechNQgprmjNF0J2L",
        "row": 19461,
        "state": "",
        "group": "",
        "group_num": [
            "recum45BpDd87XQ5a"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 495,
        "id": "rechjwkNybcwXAME4",
        "row": 19460,
        "state": "",
        "group": "",
        "group_num": [
            "recum45BpDd87XQ5a"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 710,
        "id": "reciAuUeKFtCt6S96",
        "row": 19478,
        "state": "",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2614,
        "id": "reclU2qFIwigkuBkg",
        "row": 19480,
        "state": "",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 199,
        "id": "reclIOW2GqNwPV7Fj",
        "row": 19442,
        "state": "",
        "group": "",
        "group_num": [
            "recgwOUF51ILOma04"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 44,
        "id": "reclf2MjkSmfAGhZY",
        "row": 19464,
        "state": "",
        "group": "26VZ",
        "group_num": [
            "rec0kNCH61oNvwVl0"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recmhXQVdum9fMueS",
        "row": 19457,
        "state": "",
        "group": "",
        "group_num": [
            "recjnKSIAtlurB0Vq"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recP4j1BKmRn9hEOk",
        "row": 19476,
        "state": "",
        "group": "",
        "group_num": [
            "recYpxQZhteJxm5M3"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "recQxbLDht2MPp9vQ",
        "row": 19445,
        "state": "",
        "group": "",
        "group_num": [
            "reckUbgjtp1VD7CRP"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 233,
        "id": "recSv3IN9Sz3iUl8P",
        "row": 19459,
        "state": "",
        "group": "1W9D",
        "group_num": [
            "recyJYcs52qRUlLv9"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 210,
        "id": "recUDieoJei2cgYm1",
        "row": 19458,
        "state": "",
        "group": "",
        "group_num": [
            "recjnKSIAtlurB0Vq"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "recUKtWp6zbpL7Lyf",
        "row": 19443,
        "state": "",
        "group": "",
        "group_num": [
            "recgwOUF51ILOma04"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "id": "recVFFIeIpnTmWVci",
        "row": 19454,
        "state": "",
        "group": "",
        "group_num": [
            "recpx65I3Ohgrnht4"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 152,
        "id": "recVqimflFJDvT3ZV",
        "row": 19470,
        "state": "",
        "group": "",
        "group_num": [
            "rec8DxwpKJyZoYWlH"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "id": "rec0x309aExljOBdX",
        "row": 19453,
        "state": "",
        "group": "",
        "group_num": [
            "recpx65I3Ohgrnht4"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "rec11qQJyTcXRraic",
        "row": 19456,
        "state": "",
        "group": "",
        "group_num": [
            "recxxtOpU9hICTRF1"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2282,
        "id": "rec2uiTuFLyqtKAPz",
        "row": 19436,
        "state": "",
        "group": "001902740PF10",
        "group_num": [
            "recrqqiupvdm0DHQE"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "rec2GnRfJ6xk29qQF",
        "row": 19435,
        "state": "",
        "group": "001901078MD10247",
        "group_num": [
            "recxwJ7plmKuD4gYr"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec2afktAXUc8WGqX",
        "row": 19469,
        "state": "",
        "group": "99K1",
        "group_num": [
            "rec7cekqMFmH11Xgr"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 37,
        "id": "rec5xrXdCPf56ADDJ",
        "row": 19438,
        "state": "",
        "group": "",
        "group_num": [
            "recdEkXz9derxTIp6"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "rec6WnImMU9wWWYs3",
        "row": 19473,
        "state": "",
        "group": "",
        "group_num": [
            "recKA5p4IZIfQVhWe"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "id": "rec7nRtHT1P059LCl",
        "row": 19468,
        "state": "",
        "group": "",
        "group_num": [
            "recBenka62LqbpwEA"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 581,
        "id": "rec8eQqxrmmhaU39r",
        "row": 19467,
        "state": "",
        "group": "",
        "group_num": [
            "recBenka62LqbpwEA"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 164,
        "id": "rec9BW4VUUVFrPXS7",
        "row": 19462,
        "state": "",
        "group": "",
        "group_num": [
            "recum45BpDd87XQ5a"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 506,
        "id": "recbIAGcNaUwyavMt",
        "row": 19475,
        "state": "",
        "group": "",
        "group_num": [
            "recYpxQZhteJxm5M3"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recbd2qTN7Fu7LuAu",
        "row": 19447,
        "state": "",
        "group": "",
        "group_num": [
            "recgQBs1XzwL4G4vd"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 208,
        "id": "recou4mRYKMkF5952",
        "row": 19474,
        "state": "",
        "group": "",
        "group_num": [
            "recKA5p4IZIfQVhWe"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recoF8XF6gBtyDLz1",
        "row": 19452,
        "state": "",
        "group": "",
        "group_num": [
            "recP339A47cVmbaO4"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 166,
        "id": "recoophoVS49ErcwO",
        "row": 19451,
        "state": "",
        "group": "99WE",
        "group_num": [
            "recdUwz7U6OFwaM8y"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 52,
        "id": "recrq7HdizJSgdwmp",
        "row": 19479,
        "state": "",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recswyPN4DuElyBP6",
        "row": 19477,
        "state": "",
        "group": "",
        "group_num": [
            "reckjT9zXNoIgxe2Y"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recsNkOapIpTZ0xVL",
        "row": 19466,
        "state": "",
        "group": "",
        "group_num": [
            "recBenka62LqbpwEA"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recsbOOvfMa1dUcpe",
        "row": 19434,
        "state": "",
        "group": "005801171DC10006",
        "group_num": [
            "rec7MMSeHWCoORIi9"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3589,
        "id": "recuhpJZAWqdg7eDV",
        "row": 19481,
        "state": "",
        "group": "99M1",
        "group_num": [
            "recZJPu7u1prqTYhA"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recwgtYnByQRFqgML",
        "row": 19437,
        "state": "",
        "group": "001902740PF10",
        "group_num": [
            "recrqqiupvdm0DHQE"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recy1hqAJtqzpJWeu",
        "row": 19465,
        "state": "",
        "group": "",
        "group_num": [
            "rec0DGxhjFcW8ETNB"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1260,
        "id": "reczI6CGURqd902EV",
        "row": 19455,
        "state": "",
        "group": "",
        "group_num": [
            "recxxtOpU9hICTRF1"
        ],
        "procedure": "PHP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 633,
        "id": "recESBBg4dqCIh7oC",
        "row": 19450,
        "state": "",
        "group": "99WE",
        "group_num": [
            "recdUwz7U6OFwaM8y"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recFi9nnK7I3Ym6t2",
        "row": 19444,
        "state": "",
        "group": "",
        "group_num": [
            "recgwOUF51ILOma04"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1561,
        "id": "recIWCqz9ZQc2MaHB",
        "row": 19439,
        "state": "",
        "group": "",
        "group_num": [
            "recKOddJyeFFlZfMk"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1046,
        "2023": 2175,
        "id": "recIIWp0Unj4iEsZz",
        "row": 19463,
        "state": "",
        "group": "26VZ",
        "group_num": [
            "rec0kNCH61oNvwVl0"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 54,
        "id": "recIlPNF0GDLgFpl3",
        "row": 19449,
        "state": "",
        "group": "99EE",
        "group_num": [
            "recwmLTQBcnuuOQOU"
        ],
        "procedure": "OP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recLHSj6tAArAOKj9",
        "row": 19440,
        "state": "",
        "group": "",
        "group_num": [
            "reckoHqwf3J4hcBot"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recLg17VgqoSS3UHd",
        "row": 19472,
        "state": "",
        "group": "",
        "group_num": [
            "recMAfdOzZjswXmxC"
        ],
        "procedure": "RTC",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 507,
        "id": "recMFmnM2RXlDt4cC",
        "row": 19446,
        "state": "",
        "group": "",
        "group_num": [
            "reckUbgjtp1VD7CRP"
        ],
        "procedure": "Detox",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 840,
        "id": "recNtQmnwkEAh017O",
        "row": 19471,
        "state": "",
        "group": "",
        "group_num": [
            "recgemI7YszCHP1yp"
        ],
        "procedure": "IOP",
        "provider": "CAREFIRST BCBS OF MD/DC/VA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recll01sqkfTlVES7",
        "row": 21897,
        "state": "",
        "group": "NYS009",
        "group_num": [
            "recYN6aUCoISEcYSF"
        ],
        "procedure": "RTC",
        "provider": "CARELON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "id": "rec0UYLqKDhsrBwKO",
        "row": 21896,
        "state": "",
        "group": "",
        "group_num": [
            "recHDGoS5IYFzK3KT"
        ],
        "procedure": "PHP",
        "provider": "CARELON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 305,
        "id": "recEhwLZNh4VtGDJ7",
        "row": 21895,
        "state": "",
        "group": "1102367",
        "group_num": [
            "rec5six7ns8594Atj"
        ],
        "procedure": "RTC",
        "provider": "CARELON"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1508,
        "2024": 1562,
        "id": "recVL4KX5BczaeLuZ",
        "row": 21898,
        "state": "",
        "group": "300",
        "group_num": [
            "recc9Kqe1XaS4CPdM"
        ],
        "procedure": "RTC",
        "provider": "CBHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1837,
        "id": "recGhKbLdacj5kCKa",
        "row": 21899,
        "state": "",
        "group": "300",
        "group_num": [
            "recc9Kqe1XaS4CPdM"
        ],
        "procedure": "Detox",
        "provider": "CBHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "id": "rechaMmifcna9E54a",
        "row": 21904,
        "state": "",
        "group": "",
        "group_num": [
            "recmYlI83B7wJRm59"
        ],
        "procedure": "IOP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1135,
        "id": "reciy05xUlx86J5Gc",
        "row": 21901,
        "state": "",
        "group": "",
        "group_num": [
            "recSFBbFyJMuxDeeG"
        ],
        "procedure": "IOP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "2024": 1918,
        "id": "recRSCklzGrHbYOc1",
        "row": 21900,
        "state": "",
        "group": "",
        "group_num": [
            "recSFBbFyJMuxDeeG"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recSRd9QmDTzy7ema",
        "row": 21905,
        "state": "",
        "group": "",
        "group_num": [
            "rec31Rldx5CjqN8y4"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 514,
        "id": "recJ2HntMc5CWYMWd",
        "row": 21906,
        "state": "",
        "group": "",
        "group_num": [
            "rec31Rldx5CjqN8y4"
        ],
        "procedure": "Detox",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recM1dzlkGZ1ZJUhQ",
        "row": 21902,
        "state": "",
        "group": "",
        "group_num": [
            "recmYlI83B7wJRm59"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1575,
        "id": "recNmIfrrbJ3nBaE6",
        "row": 21903,
        "state": "",
        "group": "",
        "group_num": [
            "recmYlI83B7wJRm59"
        ],
        "procedure": "PHP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1575,
        "id": "recfBSTDb6llwgAnW",
        "row": 21908,
        "state": "",
        "group": "",
        "group_num": [
            "rect0IjoPQwA1X0hV"
        ],
        "procedure": "PHP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "2024": 2500,
        "id": "recnObQYvcE02FONp",
        "row": 21916,
        "state": "",
        "group": "",
        "group_num": [
            "recDAQ84bv1ZC9YK1"
        ],
        "procedure": "Detox",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recRNbCZeFeArGlDf",
        "row": 21910,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Zph0bCbGRp2ZB"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 1135,
        "id": "rec4pib8EdIhiOBAP",
        "row": 21914,
        "state": "",
        "group": "",
        "group_num": [
            "recBdYN6oRDc90SJc"
        ],
        "procedure": "IOP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1575,
        "id": "recpT1ptch26yShBX",
        "row": 21911,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Zph0bCbGRp2ZB"
        ],
        "procedure": "PHP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recpWqqAiR2gRNW2R",
        "row": 21907,
        "state": "",
        "group": "",
        "group_num": [
            "rect0IjoPQwA1X0hV"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "id": "recukN7SKR1QbPQRR",
        "row": 21909,
        "state": "",
        "group": "",
        "group_num": [
            "rect0IjoPQwA1X0hV"
        ],
        "procedure": "IOP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 1135,
        "id": "recDBqDG26JJbSw8e",
        "row": 21912,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Zph0bCbGRp2ZB"
        ],
        "procedure": "IOP",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recJ7TrnjysRD69yU",
        "row": 21913,
        "state": "",
        "group": "",
        "group_num": [
            "recBdYN6oRDc90SJc"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "2024": 1474,
        "id": "recJfFQF3JcANiUfy",
        "row": 21915,
        "state": "",
        "group": "",
        "group_num": [
            "recDAQ84bv1ZC9YK1"
        ],
        "procedure": "RTC",
        "provider": "CDPHP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 460,
        "id": "rec1sWpeSXlidyHxI",
        "row": 21920,
        "state": "",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "IOP",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "rec9X82efi8oOUf9E",
        "row": 21924,
        "state": "",
        "group": "",
        "group_num": [
            "recOuM1hPlzVXpV51"
        ],
        "procedure": "OP",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1356,
        "id": "recczGBxzQ33IZN7q",
        "row": 21921,
        "state": "",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "Detox",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 404,
        "2023": 327,
        "id": "recwZ0nz2f3XOBgrF",
        "row": 21923,
        "state": "",
        "group": "",
        "group_num": [
            "recOuM1hPlzVXpV51"
        ],
        "procedure": "PHP",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 367,
        "id": "reczUzi7pyfUuawi1",
        "row": 21925,
        "state": "",
        "group": "",
        "group_num": [
            "recOuM1hPlzVXpV51"
        ],
        "procedure": "IOP",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 460,
        "id": "recCCpgE59gEtx1AR",
        "row": 21918,
        "state": "",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "PHP",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1315,
        "id": "recDt3XrpZ6ZZcce0",
        "row": 21917,
        "state": "",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "RTC",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 38,
        "id": "recIbuYsZ5ks2lb7p",
        "row": 21919,
        "state": "",
        "group": "CNCRT",
        "group_num": [
            "rec9H9EQzpIWqZdux"
        ],
        "procedure": "OP",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recIm644vNL9dqsdO",
        "row": 21922,
        "state": "",
        "group": "NFMEX",
        "group_num": [
            "recqWIIuA8LmSVdMi"
        ],
        "procedure": "Detox",
        "provider": "CENTIVO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 71,
        "id": "recUHckroNLx1ks00",
        "row": 21928,
        "state": "",
        "group": "",
        "group_num": [
            "recqxDQl19QcdMTy6"
        ],
        "procedure": "OP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 28,
        "id": "recUbLjy4JJUeVDQk",
        "row": 21932,
        "state": "",
        "group": "",
        "group_num": [
            "recpwmkP9LlBg7Q18"
        ],
        "procedure": "OP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recVvHNRjaye3kDZa",
        "row": 21930,
        "state": "",
        "group": "",
        "group_num": [
            "recjnR5cdj6Y8BbXL"
        ],
        "procedure": "IOP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec5TNEfYZLGEt3Oi",
        "row": 21927,
        "state": "",
        "group": "",
        "group_num": [
            "recqxDQl19QcdMTy6"
        ],
        "procedure": "PHP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "2024": 700,
        "id": "rec5MqUUHemkRn7zh",
        "row": 21933,
        "state": "",
        "group": "",
        "group_num": [
            "recpwmkP9LlBg7Q18"
        ],
        "procedure": "IOP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "rec5rAm2I44TR8fGO",
        "row": 21929,
        "state": "",
        "group": "",
        "group_num": [
            "recqxDQl19QcdMTy6"
        ],
        "procedure": "IOP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recbZQJyFryEPMPzt",
        "row": 21931,
        "state": "",
        "group": "",
        "group_num": [
            "recpwmkP9LlBg7Q18"
        ],
        "procedure": "PHP",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 880,
        "2024": 880,
        "id": "recrnPuzZO2VSuPka",
        "row": 21926,
        "state": "",
        "group": "",
        "group_num": [
            "recqxDQl19QcdMTy6"
        ],
        "procedure": "RTC",
        "provider": "CHORUS COMMUNTIY HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1409,
        "id": "recXIOtarVqYtTzUn",
        "row": 21934,
        "state": "",
        "group": "",
        "group_num": [
            "recDq2mMV7uuea7HS"
        ],
        "procedure": "Detox",
        "provider": "CHRISTIAN BROTHERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1981,
        "id": "rec3DkZfKEgE4xhUx",
        "row": 21935,
        "state": "",
        "group": "",
        "group_num": [
            "recypoeISikKk8un4"
        ],
        "procedure": "RTC",
        "provider": "CHRISTIAN BROTHERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "2024": 500,
        "id": "recmZNkxzy8RX0Jz1",
        "row": 21936,
        "state": "",
        "group": "",
        "group_num": [
            "rec9gWbqU38JHAImB"
        ],
        "procedure": "RTC",
        "provider": "CHRISTUS HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 425,
        "2024": 425,
        "id": "rec64JT6hVkjGm5Bi",
        "row": 21937,
        "state": "",
        "group": "",
        "group_num": [
            "rec9gWbqU38JHAImB"
        ],
        "procedure": "PHP",
        "provider": "CHRISTUS HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "2024": 265,
        "id": "recBVGMHKFZT8A9Ks",
        "row": 21938,
        "state": "",
        "group": "",
        "group_num": [
            "rec9gWbqU38JHAImB"
        ],
        "procedure": "IOP",
        "provider": "CHRISTUS HEALTH PLANS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 811,
        "2023": 850,
        "2024": 901,
        "id": "reciSV8dgerWJogQj",
        "row": 21940,
        "state": "",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 315,
        "id": "recThWjfu3B1mTu57",
        "row": 21944,
        "state": "",
        "group": "",
        "group_num": [
            "recPGZh2QAWYZ0nRb"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recY65KunKTEM4r8g",
        "row": 21939,
        "state": "",
        "group": "",
        "group_num": [
            "recQY58L95TJb9u8S"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1147,
        "id": "recZfhfUy8RaTr9PD",
        "row": 21952,
        "state": "",
        "group": "",
        "group_num": [
            "recJPDhpHvjIHGRuf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1885,
        "id": "rec2gL9AqjznUGexy",
        "row": 21953,
        "state": "",
        "group": "",
        "group_num": [
            "recJPDhpHvjIHGRuf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "2024": 729,
        "id": "rec2hz1zxw1e6wfiJ",
        "row": 21942,
        "state": "",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2700,
        "id": "recoJpPx1gdJ1RJjN",
        "row": 21946,
        "state": "",
        "group": "",
        "group_num": [
            "rec01WvZMcbvzE8Vk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 791,
        "id": "recp8sZPT6YJwqdwm",
        "row": 21956,
        "state": "",
        "group": "",
        "group_num": [
            "recwsx3MuTNBQHRhY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "2023": 310,
        "id": "recrUsMfiyoHtT0uA",
        "row": 21951,
        "state": "",
        "group": "",
        "group_num": [
            "recorpJ92gPwAzwfS"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "2024": 400,
        "id": "recrwSiR8krpf2UWm",
        "row": 21947,
        "state": "",
        "group": "",
        "group_num": [
            "recoSW1JVsKqXW7hT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "recxeySojOx05DILb",
        "row": 21949,
        "state": "",
        "group": "",
        "group_num": [
            "recKJqmTOjlDxayqo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "id": "recysuUy8cJykCJUb",
        "row": 21954,
        "state": "",
        "group": "",
        "group_num": [
            "recsj0MuQs9Rx2cKw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 714,
        "id": "recymgEXHD1wWIsBM",
        "row": 21948,
        "state": "",
        "group": "",
        "group_num": [
            "rectDXvcHGFpMzBv9"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 511,
        "id": "recz5CCBdMpeu0w5r",
        "row": 21943,
        "state": "",
        "group": "",
        "group_num": [
            "recPGZh2QAWYZ0nRb"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1920,
        "id": "recDJ06WiwbmBbeVs",
        "row": 21945,
        "state": "",
        "group": "",
        "group_num": [
            "rec01WvZMcbvzE8Vk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 738,
        "id": "recIcnbukwmGqvn6G",
        "row": 21955,
        "state": "",
        "group": "",
        "group_num": [
            "recsj0MuQs9Rx2cKw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 978,
        "id": "recJmrWaTkpUZ6PEh",
        "row": 21950,
        "state": "",
        "group": "",
        "group_num": [
            "recorpJ92gPwAzwfS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "recLvf5NpPyvgS1KW",
        "row": 21941,
        "state": "",
        "group": "042",
        "group_num": [
            "recNZslQdv8lxC5AE"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recfwM4jnfdihCF0U",
        "row": 21959,
        "state": "",
        "group": "9623",
        "group_num": [
            "recLYXXdFajUANpmF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1163,
        "id": "recfqWxAisYTfh2To",
        "row": 21974,
        "state": "",
        "group": "100431",
        "group_num": [
            "recnx7txA5bSjyPet"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 387,
        "id": "recjLAmIbFfMoBUIf",
        "row": 21999,
        "state": "",
        "group": "0226126",
        "group_num": [
            "recBQzfLeQSjEOof1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "reclGQHIPmBh5ZbE0",
        "row": 21969,
        "state": "",
        "group": "",
        "group_num": [
            "recEP5ZRWbIjydMvu"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 37,
        "id": "recO91zBvYRrPCt1g",
        "row": 22006,
        "state": "",
        "group": "",
        "group_num": [
            "recPffrULtJ89kFIr"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 947,
        "id": "recOnm6VAxTZQ3XNH",
        "row": 21961,
        "state": "",
        "group": "9711C",
        "group_num": [
            "reciNcfnCRH8ABrrj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1214,
        "id": "recP8R1q8hbS6LkjO",
        "row": 22002,
        "state": "",
        "group": "",
        "group_num": [
            "rec4CiNIsvzvBZSnL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1746,
        "id": "recQMBIypLyyXTAJT",
        "row": 21977,
        "state": "",
        "group": "333207",
        "group_num": [
            "recUg8lsC5ez3CjXQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "2023": 310,
        "id": "recRLgC3xxo2yF9T7",
        "row": 22001,
        "state": "",
        "group": "",
        "group_num": [
            "recBQPROpaJI23j3s"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "id": "recTxTLVpCf28HlrH",
        "row": 21996,
        "state": "",
        "group": "",
        "group_num": [
            "rec3rP1gupSOnnTdr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1040,
        "id": "recUxVxFtJfR2dG41",
        "row": 21981,
        "state": "",
        "group": "",
        "group_num": [
            "recumuuD1fdkhH34W"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1750,
        "id": "recVSSzqonJ9oKNCG",
        "row": 21990,
        "state": "",
        "group": "",
        "group_num": [
            "recLgEkvewndr4IMl"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2102,
        "id": "recVbWsA92seo0cLk",
        "row": 21968,
        "state": "",
        "group": "",
        "group_num": [
            "rec90o9YYXq9tjjRj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 537,
        "id": "recWT4wc0IgXyjM9t",
        "row": 21989,
        "state": "",
        "group": "",
        "group_num": [
            "rec74AsRgSg0BwFuP"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "recWKxehNC4VbJTis",
        "row": 21957,
        "state": "",
        "group": "",
        "group_num": [
            "recwsx3MuTNBQHRhY"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 319,
        "id": "recYFKSGRycq7oHAJ",
        "row": 21992,
        "state": "",
        "group": "0196736",
        "group_num": [
            "reczrEEqV7iNOKZJT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1936,
        "id": "recZuzCtN8oD6RLVr",
        "row": 21964,
        "state": "",
        "group": "",
        "group_num": [
            "recGIq9bcMFuJWuJ8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recZhE0hEQd7hHIcz",
        "row": 21960,
        "state": "",
        "group": "9623",
        "group_num": [
            "recLYXXdFajUANpmF"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 391,
        "id": "recZmTD5PqAyL5AM8",
        "row": 21970,
        "state": "",
        "group": "",
        "group_num": [
            "recEP5ZRWbIjydMvu"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 927,
        "2023": 1579,
        "id": "rec194PbV4WY9GRIz",
        "row": 22005,
        "state": "",
        "group": "",
        "group_num": [
            "recqq8XKsf6KHZglb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec3pnRjLqS64d5Lq",
        "row": 21979,
        "state": "",
        "group": "333207",
        "group_num": [
            "recUg8lsC5ez3CjXQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "id": "rec4Dn3cOumJ33viR",
        "row": 21965,
        "state": "",
        "group": "06880A003",
        "group_num": [
            "recmM3J2bSrlSbn8J"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1077,
        "id": "rec4JpF2KebOgd2NF",
        "row": 21971,
        "state": "",
        "group": "",
        "group_num": [
            "recFIpq95okgevHq6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "rec580z4etTGKskkd",
        "row": 21978,
        "state": "",
        "group": "333207",
        "group_num": [
            "recUg8lsC5ez3CjXQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 520,
        "2023": 520,
        "id": "rec7tC4K9BV8oQ6FW",
        "row": 22000,
        "state": "",
        "group": "",
        "group_num": [
            "recBQPROpaJI23j3s"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 438,
        "id": "rec7uY2hocveLpaOy",
        "row": 21988,
        "state": "",
        "group": "",
        "group_num": [
            "rec74AsRgSg0BwFuP"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "id": "rec7NolctzZnAJEQb",
        "row": 21966,
        "state": "",
        "group": "06880A003",
        "group_num": [
            "recmM3J2bSrlSbn8J"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 935,
        "2023": 992,
        "id": "rec8NGb3TvDeDSRCJ",
        "row": 22003,
        "state": "",
        "group": "",
        "group_num": [
            "rec4CiNIsvzvBZSnL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1176,
        "id": "reca3dhEx8PEcaPUj",
        "row": 21958,
        "state": "",
        "group": "",
        "group_num": [
            "recwsx3MuTNBQHRhY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5493,
        "id": "reccPeCU4wWonkDu6",
        "row": 21972,
        "state": "",
        "group": "",
        "group_num": [
            "rec5BYwRD25uZllIM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1075,
        "id": "recosrkT6GVqq4nUp",
        "row": 21975,
        "state": "",
        "group": "118605",
        "group_num": [
            "recnqEc0nxbLsBn8V"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 573,
        "id": "recoFRIni0J0twPE5",
        "row": 21994,
        "state": "",
        "group": "",
        "group_num": [
            "recLQomy6jqBKLtaF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recpzwWcF3080Gs8K",
        "row": 21983,
        "state": "",
        "group": "",
        "group_num": [
            "recumuuD1fdkhH34W"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 204,
        "id": "recrwqbPxfC4JFYOo",
        "row": 21986,
        "state": "",
        "group": "333837",
        "group_num": [
            "recqTNFQRNxRmGANV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 153,
        "2024": 154,
        "id": "recsjX4KmlMYSA3QQ",
        "row": 21963,
        "state": "",
        "group": "",
        "group_num": [
            "recWVcUUNC7vIJwRq"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2059,
        "id": "rectTQA2VFQ3f1mM7",
        "row": 21980,
        "state": "",
        "group": "",
        "group_num": [
            "recumuuD1fdkhH34W"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 464,
        "id": "recvZpwmPG7pcQ3xW",
        "row": 21997,
        "state": "",
        "group": "0226017",
        "group_num": [
            "rec1vB8YjBEO99Fqa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "recwGwsFBEuvLRqwh",
        "row": 21993,
        "state": "",
        "group": "",
        "group_num": [
            "rec6NLQdhohMvyUU9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4000,
        "id": "recyX9Zdb0NKYhaU2",
        "row": 22004,
        "state": "",
        "group": "0233935",
        "group_num": [
            "recrGMvFyPRWAaCFM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "id": "recy2vUfvxJZWuSaZ",
        "row": 21995,
        "state": "",
        "group": "",
        "group_num": [
            "recLQomy6jqBKLtaF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3026,
        "id": "recyiQP2xkNdgJI6S",
        "row": 21998,
        "state": "",
        "group": "0226017",
        "group_num": [
            "rec1vB8YjBEO99Fqa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1147,
        "id": "recAlmweXOoAVnZWj",
        "row": 21976,
        "state": "",
        "group": "118605",
        "group_num": [
            "recnqEc0nxbLsBn8V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2280,
        "id": "recBkh6IMPPJJcSQA",
        "row": 21984,
        "state": "",
        "group": "333837",
        "group_num": [
            "recqTNFQRNxRmGANV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 559,
        "2024": 586,
        "id": "recDkVVPrgpvhISgR",
        "row": 21962,
        "state": "",
        "group": "",
        "group_num": [
            "recWVcUUNC7vIJwRq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 708,
        "id": "recEuEbpfjqNEIKKA",
        "row": 21985,
        "state": "",
        "group": "333837",
        "group_num": [
            "recqTNFQRNxRmGANV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1842,
        "id": "recGJadLpSDO7Gwjq",
        "row": 21991,
        "state": "",
        "group": "",
        "group_num": [
            "recGoKlna9hOheL5D"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2041,
        "2023": 2033,
        "id": "recKHF0omVxgLPMrp",
        "row": 21967,
        "state": "",
        "group": "",
        "group_num": [
            "rec90o9YYXq9tjjRj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2275,
        "id": "recK39Rrm2x4kj7S6",
        "row": 21987,
        "state": "",
        "group": "333837",
        "group_num": [
            "recqTNFQRNxRmGANV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 86,
        "2023": 156,
        "id": "recLp5c92rkpVF2JC",
        "row": 21982,
        "state": "",
        "group": "",
        "group_num": [
            "recumuuD1fdkhH34W"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1163,
        "id": "recMgAdwcvciQOrgY",
        "row": 21973,
        "state": "",
        "group": "100431",
        "group_num": [
            "recnx7txA5bSjyPet"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2244,
        "id": "recg6ny2dksCjfOPd",
        "row": 22037,
        "state": "",
        "group": "2443694",
        "group_num": [
            "recpXY77EuZYleaGD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recjLliYH0EOcycyd",
        "row": 22032,
        "state": "",
        "group": "2004014",
        "group_num": [
            "recL1642PIMKk5e6q"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recjm1Esq5qInqWFi",
        "row": 22056,
        "state": "",
        "group": "",
        "group_num": [
            "recRcJMyefHi9BHZ0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2775,
        "id": "reclBpnTpUyZ0n9n2",
        "row": 22047,
        "state": "",
        "group": "",
        "group_num": [
            "rec6kTgsHWGgQi6cj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recnSe5wGUhBdAJC3",
        "row": 22052,
        "state": "",
        "group": "",
        "group_num": [
            "rec2IZbEHvL3JgIwm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 121,
        "id": "recnBRjcHlR3lEJ7U",
        "row": 22010,
        "state": "",
        "group": "",
        "group_num": [
            "rec5H7rT2ssIYn8Rg"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 181,
        "2024": 249,
        "id": "recnDeJ92Iy8XjFdn",
        "row": 22042,
        "state": "",
        "group": "2462110",
        "group_num": [
            "recBySQuHBR6SAkOa"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 798,
        "id": "recn4CQG5gWmWuPin",
        "row": 22018,
        "state": "",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recnjjKFPvkO7Kelz",
        "row": 22033,
        "state": "",
        "group": "2004034",
        "group_num": [
            "recyOtw3zNrYJG11T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 179,
        "id": "recOLY8XR0iAdL0Zy",
        "row": 22048,
        "state": "",
        "group": "",
        "group_num": [
            "rec6kTgsHWGgQi6cj"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 747,
        "id": "recOp8onyEgkdMSTz",
        "row": 22028,
        "state": "",
        "group": "",
        "group_num": [
            "recM6Dabn6QD2U0EY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "recQyAMR48jZVSkFW",
        "row": 22038,
        "state": "",
        "group": "",
        "group_num": [
            "recDJ8UM7Yc7n7AQh"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "id": "recStboIq4OTGlwDp",
        "row": 22009,
        "state": "",
        "group": "",
        "group_num": [
            "rec5H7rT2ssIYn8Rg"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recU8SycsnMqmfvG4",
        "row": 22036,
        "state": "",
        "group": "2443694",
        "group_num": [
            "recpXY77EuZYleaGD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "id": "recUcnQDTw0SuInVe",
        "row": 22008,
        "state": "",
        "group": "",
        "group_num": [
            "rec5H7rT2ssIYn8Rg"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3873,
        "id": "recVvmCn2qGGWHsl9",
        "row": 22030,
        "state": "",
        "group": "2001095",
        "group_num": [
            "rec2HHW6mjoWngiGO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2645,
        "id": "recXhvgxcMV9552Ac",
        "row": 22039,
        "state": "",
        "group": "2458462",
        "group_num": [
            "recyvifOa4CE7Cogi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recZQGywfHS6et1Kj",
        "row": 22031,
        "state": "",
        "group": "2004014",
        "group_num": [
            "recL1642PIMKk5e6q"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "id": "rec0ZlBAcUcyFm26a",
        "row": 22024,
        "state": "",
        "group": "2001062",
        "group_num": [
            "recC9kVAN9k39mtf3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "rec0J2q5rKcPir0n2",
        "row": 22007,
        "state": "",
        "group": "",
        "group_num": [
            "recPffrULtJ89kFIr"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 401,
        "id": "rec1IBCC1UkLGwTOV",
        "row": 22046,
        "state": "",
        "group": "",
        "group_num": [
            "rec1uTwABg5GwU124"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rec2fI3S3WgAGt5u0",
        "row": 22055,
        "state": "",
        "group": "",
        "group_num": [
            "recRcJMyefHi9BHZ0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "rec59OfxYIcUJO2PE",
        "row": 22045,
        "state": "",
        "group": "",
        "group_num": [
            "rec1uTwABg5GwU124"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 631,
        "id": "rec6jS8OUWf5twCks",
        "row": 22022,
        "state": "",
        "group": "",
        "group_num": [
            "recsZb0qV2mWsLH6U"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 945,
        "id": "rec6kPLHhxjEzpIdj",
        "row": 22020,
        "state": "",
        "group": "",
        "group_num": [
            "recnI3TkyP26HJxlC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 290,
        "id": "rec9glbRratmPMhE7",
        "row": 22050,
        "state": "",
        "group": "2465054",
        "group_num": [
            "recAUHkD8tSohECVC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "2024": 2400,
        "id": "recbQYazxZSu0KZzz",
        "row": 22043,
        "state": "",
        "group": "2462110",
        "group_num": [
            "recBySQuHBR6SAkOa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 798,
        "id": "recb86zaXImKY2UcV",
        "row": 22013,
        "state": "",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recoRBxJdlmkn1DTW",
        "row": 22016,
        "state": "",
        "group": "2001003",
        "group_num": [
            "recICYon4tkNRI5hx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 6000,
        "id": "recojb0dq1F4kaNy0",
        "row": 22015,
        "state": "",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1947,
        "2023": 1920,
        "id": "recs1WHN87OKSu1fv",
        "row": 22035,
        "state": "",
        "group": "2443694",
        "group_num": [
            "recpXY77EuZYleaGD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recss9D1F71pA9ELO",
        "row": 22019,
        "state": "",
        "group": "2001005",
        "group_num": [
            "recEU2dIkJV30rNKQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2775,
        "id": "recuADcTO0mZupcKq",
        "row": 22049,
        "state": "",
        "group": "",
        "group_num": [
            "rec6kTgsHWGgQi6cj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3713,
        "id": "recuIEQLsfR3lNIsC",
        "row": 22029,
        "state": "",
        "group": "2001095",
        "group_num": [
            "rec2HHW6mjoWngiGO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 798,
        "id": "recvG1jU0W2H9FOzi",
        "row": 22014,
        "state": "",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recwsKIAEgSNiBCtP",
        "row": 22017,
        "state": "",
        "group": "2001003",
        "group_num": [
            "recICYon4tkNRI5hx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 539,
        "id": "recwkrnOysQl4i81s",
        "row": 22011,
        "state": "",
        "group": "",
        "group_num": [
            "recAJPjYGIADTqELb"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1455,
        "2023": 1455,
        "id": "reczDW8jT2eng4NGn",
        "row": 22023,
        "state": "",
        "group": "",
        "group_num": [
            "recsZb0qV2mWsLH6U"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "reczpTvI0MGFflTEA",
        "row": 22051,
        "state": "",
        "group": "",
        "group_num": [
            "rec2IZbEHvL3JgIwm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1930,
        "2023": 1887,
        "2024": 1920,
        "id": "recByuGzzao42U38F",
        "row": 22040,
        "state": "",
        "group": "2462110",
        "group_num": [
            "recBySQuHBR6SAkOa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recC4jQ8cZTUp9cLm",
        "row": 22034,
        "state": "",
        "group": "",
        "group_num": [
            "recz6TABu3xAZgjB5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "recC6Zz2xCsRppIVX",
        "row": 22053,
        "state": "",
        "group": "",
        "group_num": [
            "recd1BNlBSoXbhQVA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5486,
        "2023": 4800,
        "id": "recCn0eDDc8sBalEX",
        "row": 22027,
        "state": "",
        "group": "2001063",
        "group_num": [
            "recTQnuNNP2thRLit"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "id": "recGskobAIpQa70Ik",
        "row": 22025,
        "state": "",
        "group": "2001063",
        "group_num": [
            "recTQnuNNP2thRLit"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "id": "recJfevf3JyuWNSKY",
        "row": 22054,
        "state": "",
        "group": "",
        "group_num": [
            "recd1BNlBSoXbhQVA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "id": "recK1cAFIKabFf7Ji",
        "row": 22026,
        "state": "",
        "group": "2001063",
        "group_num": [
            "recTQnuNNP2thRLit"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recK3R0tC2mEmJNK5",
        "row": 22012,
        "state": "",
        "group": "2001002",
        "group_num": [
            "recEP5CKDXDzfeK3M"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 438,
        "2023": 593,
        "2024": 571,
        "id": "recLNrtFgGusQbpPQ",
        "row": 22041,
        "state": "",
        "group": "2462110",
        "group_num": [
            "recBySQuHBR6SAkOa"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1442,
        "2024": 1185,
        "id": "recLkbWmkX1fgCzQE",
        "row": 22044,
        "state": "",
        "group": "",
        "group_num": [
            "rec1uTwABg5GwU124"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recNaOxO4xKJhSUuN",
        "row": 22021,
        "state": "",
        "group": "2001051",
        "group_num": [
            "reclEDLo52y3N1iTk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recexAGVyjXn9wRR5",
        "row": 22105,
        "state": "",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "id": "recf90LDNK8VmQUd5",
        "row": 22073,
        "state": "",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "id": "recgdK6WScaxbfqvm",
        "row": 22100,
        "state": "",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "recgpd8UlMMUSUi1X",
        "row": 22081,
        "state": "",
        "group": "",
        "group_num": [
            "recirgCMZlsPbi6s3"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "rechKuqqSGmKQyZTN",
        "row": 22069,
        "state": "",
        "group": "2498728",
        "group_num": [
            "recCae4MVTmBkfBWN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1920,
        "id": "recjUKc399oJ5VFRj",
        "row": 22063,
        "state": "",
        "group": "2498633",
        "group_num": [
            "rec74G7OJ2iBYw0hM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1917,
        "id": "recmU8zCJUaP1dEfZ",
        "row": 22078,
        "state": "",
        "group": "",
        "group_num": [
            "recYOipksd5QrZU4V"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3716,
        "2023": 2121,
        "id": "recnahF1iJo3ZUKE7",
        "row": 22070,
        "state": "",
        "group": "2498956",
        "group_num": [
            "recP5n0y7Wi5SjQiO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1046,
        "2024": 2375,
        "id": "recQvmFkFSmLXxpaE",
        "row": 22065,
        "state": "",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "2023": 172,
        "id": "recTDciB9bizvhY9E",
        "row": 22061,
        "state": "",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 2195,
        "id": "recUTibc8qk6dWroP",
        "row": 22058,
        "state": "",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recU9fu8ttcbSVZ1c",
        "row": 22072,
        "state": "",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recVmiwHwHF4AhNIK",
        "row": 22074,
        "state": "",
        "group": "2499053",
        "group_num": [
            "recPAWXMkFljd017X"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1750,
        "id": "recWxMRrs5DLh6MhJ",
        "row": 22082,
        "state": "",
        "group": "",
        "group_num": [
            "recirgCMZlsPbi6s3"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 413,
        "id": "recYP1iuZlPwzV71y",
        "row": 22059,
        "state": "",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2050,
        "id": "recZ29PK8jNGMB4FC",
        "row": 22092,
        "state": "",
        "group": "2500142",
        "group_num": [
            "recavefFc88NO2q7H"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2373,
        "id": "rec2wT7aBCdkKi4lG",
        "row": 22102,
        "state": "",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1952,
        "id": "rec2gESl6Lg38UQvE",
        "row": 22083,
        "state": "",
        "group": "",
        "group_num": [
            "recyCI7JPCdVpah5s"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "2023": 165,
        "2024": 230,
        "id": "rec4YfP0UJ0mD4XU4",
        "row": 22067,
        "state": "",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2121,
        "id": "rec4ZUmvPMub47Xyd",
        "row": 22079,
        "state": "",
        "group": "",
        "group_num": [
            "recYOipksd5QrZU4V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 586,
        "id": "rec46RSRVE5gQBxyf",
        "row": 22093,
        "state": "",
        "group": "2500142",
        "group_num": [
            "recavefFc88NO2q7H"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 176,
        "id": "rec4c7liNwzKCArAq",
        "row": 22060,
        "state": "",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1942,
        "2023": 1801,
        "id": "rec5aRQPPrmboWAjz",
        "row": 22090,
        "state": "",
        "group": "2499851",
        "group_num": [
            "recOynnGPWAXuowMa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "rec6AShNKzYSF0Gn2",
        "row": 22076,
        "state": "",
        "group": "2499331",
        "group_num": [
            "recCzJjOWDbN41CZf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "id": "rec84TBXo5siSxbFs",
        "row": 22104,
        "state": "",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "id": "recdTopQXU8zXx2lo",
        "row": 22098,
        "state": "",
        "group": "2500547",
        "group_num": [
            "recX1o2dB6HUe9G4w"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 460,
        "id": "recdfS4DoSpig6a0L",
        "row": 22087,
        "state": "",
        "group": "2499850",
        "group_num": [
            "recKev7W7SkHqXL2P"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1005,
        "2023": 2008,
        "id": "recqWSxpymAv0JzMW",
        "row": 22103,
        "state": "",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2625,
        "id": "recqXFt0K2g3Wdv1q",
        "row": 22097,
        "state": "",
        "group": "2500547",
        "group_num": [
            "recX1o2dB6HUe9G4w"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2500,
        "id": "recqHXpg7D9Jov5ok",
        "row": 22057,
        "state": "",
        "group": "",
        "group_num": [
            "receHyh0q9e3hkxnH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1975,
        "2024": 3800,
        "id": "recr5TqUo6buoA3Rb",
        "row": 22096,
        "state": "",
        "group": "2500547",
        "group_num": [
            "recX1o2dB6HUe9G4w"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 923,
        "id": "recsvDokYD99k7MJK",
        "row": 22084,
        "state": "",
        "group": "",
        "group_num": [
            "recyCI7JPCdVpah5s"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1005,
        "2023": 3000,
        "id": "recuSPri5fyHLGBEv",
        "row": 22106,
        "state": "",
        "group": "2500693",
        "group_num": [
            "recR2iuEPnRl9BUa3"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2373,
        "id": "recvT10t97CZLx8Pv",
        "row": 22099,
        "state": "",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "2023": 596,
        "id": "recvYUHG3QbhvuOMk",
        "row": 22066,
        "state": "",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 395,
        "id": "recvN4NDkgzWLSQMf",
        "row": 22077,
        "state": "",
        "group": "2499331",
        "group_num": [
            "recCzJjOWDbN41CZf"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "id": "recwdqmHQBcgMQv2W",
        "row": 22101,
        "state": "",
        "group": "2500691",
        "group_num": [
            "recEkhsqsVnJ10k2Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2001,
        "id": "recweKEMWd3si9RuR",
        "row": 22089,
        "state": "",
        "group": "2499851",
        "group_num": [
            "recOynnGPWAXuowMa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recxHoPybYdFMNESj",
        "row": 22075,
        "state": "",
        "group": "2499331",
        "group_num": [
            "recCzJjOWDbN41CZf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 2850,
        "2024": 2850,
        "id": "recz9VMr0XHPq1kzB",
        "row": 22085,
        "state": "",
        "group": "",
        "group_num": [
            "rec4dboZNdevx89md"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 764,
        "2023": 2464,
        "id": "recAWUQhBdR7I2x8J",
        "row": 22071,
        "state": "",
        "group": "2498956",
        "group_num": [
            "recP5n0y7Wi5SjQiO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recB7g47n2qaQdt9v",
        "row": 22064,
        "state": "",
        "group": "2498633",
        "group_num": [
            "rec74G7OJ2iBYw0hM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 2133,
        "2024": 3000,
        "id": "recD83GSa3XWcDZ7p",
        "row": 22068,
        "state": "",
        "group": "2498637",
        "group_num": [
            "rec00H9FVVXfGwz78"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2450,
        "id": "recEKvXVPeqNVINqa",
        "row": 22062,
        "state": "",
        "group": "2490718",
        "group_num": [
            "recexOdr731p1XsX5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 927,
        "id": "recI4xYaj6bJmdcXb",
        "row": 22094,
        "state": "",
        "group": "2500322",
        "group_num": [
            "recmYiCQL6q80U3Sw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1726,
        "2023": 1037,
        "id": "recKtbPXDuz6faUpQ",
        "row": 22095,
        "state": "",
        "group": "2500322",
        "group_num": [
            "recmYiCQL6q80U3Sw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "2024": 1556,
        "id": "recLbHCjxaZWWX4ru",
        "row": 22088,
        "state": "",
        "group": "2499850",
        "group_num": [
            "recKev7W7SkHqXL2P"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recM7N6kv09rK8TMa",
        "row": 22091,
        "state": "",
        "group": "",
        "group_num": [
            "recmPvtmjryRZNrWi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recMez0OpLEmQXx7s",
        "row": 22080,
        "state": "",
        "group": "",
        "group_num": [
            "recirgCMZlsPbi6s3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2375,
        "id": "recMpJ9gNyudoVI2y",
        "row": 22086,
        "state": "",
        "group": "2499850",
        "group_num": [
            "recKev7W7SkHqXL2P"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "2023": 196,
        "id": "receZguFLu9FlgAIH",
        "row": 22142,
        "state": "",
        "group": "3168712",
        "group_num": [
            "recHctkBgIU86vQcj"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2613,
        "2023": 3000,
        "id": "receHHfzWYuRWJgxF",
        "row": 22131,
        "state": "",
        "group": "3141424",
        "group_num": [
            "recSjtLHOkSUUFxjJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1892,
        "id": "recg6IwAmrZtdUnv9",
        "row": 22112,
        "state": "",
        "group": "",
        "group_num": [
            "recuTKnBdfm5iE7e1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1882,
        "2024": 1787,
        "id": "reci7Ge0VpImFZ4x7",
        "row": 22124,
        "state": "",
        "group": "",
        "group_num": [
            "recHxDqq24nPewjHq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "reclbtGoIPhsCwF1Q",
        "row": 22113,
        "state": "",
        "group": "2501059",
        "group_num": [
            "recxcfrTI1LsB1jRk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1531,
        "id": "recn3wAegi93sm1Mp",
        "row": 22110,
        "state": "",
        "group": "",
        "group_num": [
            "rec2kU0WaOPJF3rtT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1826,
        "id": "recOtXszqFczdtMMG",
        "row": 22108,
        "state": "",
        "group": "",
        "group_num": [
            "recoP4l8uhH3Mpo5r"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "id": "recQlDHt9VHm91Sk1",
        "row": 22140,
        "state": "",
        "group": "3157440",
        "group_num": [
            "recjPNr4uDEwI6Mux"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "recSH0uQ15rf7F8uC",
        "row": 22133,
        "state": "",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2208,
        "2023": 3000,
        "id": "recUskIaBdt9Tx5QW",
        "row": 22128,
        "state": "",
        "group": "",
        "group_num": [
            "reckCRHNNNHFtv6HE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recUb9muSKP4WpBGW",
        "row": 22138,
        "state": "",
        "group": "3157440",
        "group_num": [
            "recjPNr4uDEwI6Mux"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 358,
        "2024": 290,
        "id": "recUcr2KbCi1RPyEu",
        "row": 22151,
        "state": "",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "2023": 282,
        "2024": 280,
        "id": "recUmJ2pjIuHY5j1H",
        "row": 22136,
        "state": "",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1977,
        "2023": 2023,
        "id": "recWflK9GomombANo",
        "row": 22137,
        "state": "",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recXlr7sBCnvs1s8V",
        "row": 22111,
        "state": "",
        "group": "",
        "group_num": [
            "recuTKnBdfm5iE7e1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1680,
        "id": "recY1QBEqYKtACLTk",
        "row": 22141,
        "state": "",
        "group": "3168712",
        "group_num": [
            "recHctkBgIU86vQcj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3218,
        "2023": 3000,
        "id": "recYlKD80IUK8oaPJ",
        "row": 22154,
        "state": "",
        "group": "3173152",
        "group_num": [
            "recbLn9gejzu4Q294"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4500,
        "id": "recYo0FlfqWxqVQtK",
        "row": 22121,
        "state": "",
        "group": "",
        "group_num": [
            "recHmz4EiGZgQCZLg"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1026,
        "id": "rec0Uv8dXDSvRGxA2",
        "row": 22120,
        "state": "",
        "group": "",
        "group_num": [
            "recHmz4EiGZgQCZLg"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "rec0Ol4zZHH2vyROa",
        "row": 22116,
        "state": "",
        "group": "",
        "group_num": [
            "rec21Mtj7QKI7Bomi"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "id": "rec2S05khy5AY8qYd",
        "row": 22146,
        "state": "",
        "group": "3170520",
        "group_num": [
            "recQs9r2L2LOKnkqi"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "rec2RKzj0uCL3K1nI",
        "row": 22115,
        "state": "",
        "group": "2501097",
        "group_num": [
            "recZUdZoUuGZtJSOg"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1032,
        "2023": 1033,
        "id": "rec4Ovv4CHfa4USwt",
        "row": 22156,
        "state": "",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1966,
        "id": "rec548RsqC1xXMPIr",
        "row": 22144,
        "state": "",
        "group": "3168712",
        "group_num": [
            "recHctkBgIU86vQcj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 675,
        "id": "rec6r0d50vgYB1xF8",
        "row": 22149,
        "state": "",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "rec8N9unLyqNMEU4c",
        "row": 22119,
        "state": "",
        "group": "",
        "group_num": [
            "recHmz4EiGZgQCZLg"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 2284,
        "id": "recacJiyXRavSRn0K",
        "row": 22153,
        "state": "",
        "group": "3173152",
        "group_num": [
            "recbLn9gejzu4Q294"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 577,
        "id": "recbYyG3mSyN2vZsX",
        "row": 22127,
        "state": "",
        "group": "",
        "group_num": [
            "reckCRHNNNHFtv6HE"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 194,
        "id": "recb3vzlNFuF6TvyD",
        "row": 22123,
        "state": "",
        "group": "",
        "group_num": [
            "recdodIZDbeHnOThl"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2217,
        "2023": 2400,
        "id": "reccOPS4DP9urCypP",
        "row": 22134,
        "state": "",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 623,
        "id": "recvmcvzoKllcb0kS",
        "row": 22118,
        "state": "",
        "group": "",
        "group_num": [
            "recWqeOm7o5B6vg2Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 190,
        "2023": 190,
        "id": "recxAVkjeIallE2vV",
        "row": 22143,
        "state": "",
        "group": "3168712",
        "group_num": [
            "recHctkBgIU86vQcj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1906,
        "2023": 2191,
        "id": "recxKTSIL5os7h6FF",
        "row": 22126,
        "state": "",
        "group": "",
        "group_num": [
            "reckCRHNNNHFtv6HE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recxNYrMBrzwrb7Tv",
        "row": 22145,
        "state": "",
        "group": "3170360",
        "group_num": [
            "recwJvX6uNUm69SDk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1974,
        "2023": 2632,
        "2024": 2632,
        "id": "recxRmmqXt0BZHQ3v",
        "row": 22129,
        "state": "",
        "group": "3141424",
        "group_num": [
            "recSjtLHOkSUUFxjJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1996,
        "2023": 2003,
        "id": "recxbUNEx3iXOxSK2",
        "row": 22135,
        "state": "",
        "group": "3155584",
        "group_num": [
            "recCO9IW1QeVtVGkU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2614,
        "id": "recxc7oEvGX79C1z8",
        "row": 22125,
        "state": "",
        "group": "",
        "group_num": [
            "recHxDqq24nPewjHq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "id": "recySm8Q8hoY5KzFH",
        "row": 22109,
        "state": "",
        "group": "2500791",
        "group_num": [
            "recO2jcds1LAAmneO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "2023": 1051,
        "id": "recCUURQOJNsvp5ng",
        "row": 22152,
        "state": "",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1735,
        "id": "recDlEQMjxY0k4ARZ",
        "row": 22122,
        "state": "",
        "group": "",
        "group_num": [
            "recdodIZDbeHnOThl"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "id": "recGYoQStTga1ZlyO",
        "row": 22117,
        "state": "",
        "group": "",
        "group_num": [
            "recWqeOm7o5B6vg2Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1960,
        "2023": 1827,
        "id": "recGzRtsm32RMjU8a",
        "row": 22155,
        "state": "",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recHwaYooHUTu26b6",
        "row": 22107,
        "state": "",
        "group": "2500706",
        "group_num": [
            "reczz4j75RxqcTdeG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 157,
        "id": "recIqdw8sRI0qNFN9",
        "row": 22139,
        "state": "",
        "group": "3157440",
        "group_num": [
            "recjPNr4uDEwI6Mux"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1810,
        "id": "recJav9kxb3eJ0cNW",
        "row": 22148,
        "state": "",
        "group": "3170520",
        "group_num": [
            "recQs9r2L2LOKnkqi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2177,
        "2023": 1920,
        "id": "recK6goyHvb9xF618",
        "row": 22132,
        "state": "",
        "group": "3153616",
        "group_num": [
            "recwq35papranY8ju"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1088,
        "id": "recKiFk9DoL5fGETr",
        "row": 22114,
        "state": "",
        "group": "2501097",
        "group_num": [
            "recZUdZoUuGZtJSOg"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 396,
        "2024": 415,
        "id": "recMDZkzHRXO6bP9I",
        "row": 22150,
        "state": "",
        "group": "3170928",
        "group_num": [
            "recayCYnQkIZ5bPU1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 596,
        "id": "recMp1iwMoEZb0wC9",
        "row": 22130,
        "state": "",
        "group": "3141424",
        "group_num": [
            "recSjtLHOkSUUFxjJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 224,
        "id": "recNGic3VVfFUGT5s",
        "row": 22147,
        "state": "",
        "group": "3170520",
        "group_num": [
            "recQs9r2L2LOKnkqi"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3600,
        "id": "recgDiUI3ouUxQBSm",
        "row": 22190,
        "state": "",
        "group": "3207160",
        "group_num": [
            "recKWrTMW3NZNU6lQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "rechIp2FlksRtL71S",
        "row": 22183,
        "state": "",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 340,
        "id": "reclQGWSbJu3l5i4D",
        "row": 22182,
        "state": "",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recm05qleW3TcgbgT",
        "row": 22194,
        "state": "",
        "group": "",
        "group_num": [
            "rec8B65QSklv2goTa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recmFdZnSMpx2Bz6M",
        "row": 22195,
        "state": "",
        "group": "",
        "group_num": [
            "recfQCG4kFQcHIU4X"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "2024": 847,
        "id": "recntgCwd5U7WXILx",
        "row": 22204,
        "state": "",
        "group": "3209240",
        "group_num": [
            "recFvBfmipH5XKt1g"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2518,
        "id": "recnduWE6MFTTKjfX",
        "row": 22178,
        "state": "",
        "group": "",
        "group_num": [
            "recnLru4030Vosl1v"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2538,
        "id": "recPI80TUDaQ3CGpT",
        "row": 22191,
        "state": "",
        "group": "",
        "group_num": [
            "recJ5VOhVy0bTWfaX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1816,
        "id": "recPoxX2KfdSU2nJx",
        "row": 22184,
        "state": "",
        "group": "",
        "group_num": [
            "recFRdMgGMFrqUZjS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 410,
        "2023": 359,
        "id": "recTqmXkl0nD4zhL7",
        "row": 22172,
        "state": "",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1546,
        "id": "recURWq92VQR8lnNw",
        "row": 22164,
        "state": "",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 345,
        "id": "recYVrRu8ndTiAJ00",
        "row": 22166,
        "state": "",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 222,
        "2023": 990,
        "id": "recYzGaQaUgm1D9RY",
        "row": 22189,
        "state": "",
        "group": "3207160",
        "group_num": [
            "recKWrTMW3NZNU6lQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1814,
        "2023": 1920,
        "id": "recYoQytM5BLHovdC",
        "row": 22187,
        "state": "",
        "group": "3207160",
        "group_num": [
            "recKWrTMW3NZNU6lQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 977,
        "2023": 832,
        "2024": 664,
        "id": "recZX6YJavX7FUQiE",
        "row": 22160,
        "state": "",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1775,
        "id": "recZjT2W9XEGJ6KVc",
        "row": 22158,
        "state": "",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 393,
        "id": "rec0RAEbi1bupFG0B",
        "row": 22169,
        "state": "",
        "group": "",
        "group_num": [
            "reciCqw7wcrvMWBnK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 252,
        "2023": 157,
        "id": "rec03rnuppcarRO0T",
        "row": 22165,
        "state": "",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "rec0bzQ1dUOdXEfFb",
        "row": 22179,
        "state": "",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec1zvNW0LVbdG1VG",
        "row": 22201,
        "state": "",
        "group": "",
        "group_num": [
            "rec5W6zrMzZvp13W1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "2023": 292,
        "id": "rec4IBsC65jnkeCEq",
        "row": 22186,
        "state": "",
        "group": "3207016",
        "group_num": [
            "recIEv52upcncQGzE"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 139,
        "id": "rec4MAGgotRlsQBg9",
        "row": 22197,
        "state": "",
        "group": "3208540",
        "group_num": [
            "recZ4X2hgr4GjaYbW"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 302,
        "2023": 507,
        "2024": 285,
        "id": "rec497se5HNBb8DDZ",
        "row": 22162,
        "state": "",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "rec5l95ijKIsFzUh8",
        "row": 22206,
        "state": "",
        "group": "",
        "group_num": [
            "recbpBf9m0xcnjGCC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2473,
        "2023": 2332,
        "id": "rec6HGW3cn7TEku5e",
        "row": 22173,
        "state": "",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2348,
        "2023": 2477,
        "id": "rec77zT5V9x8sghb9",
        "row": 22159,
        "state": "",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "rec860OiWrglys7as",
        "row": 22196,
        "state": "",
        "group": "",
        "group_num": [
            "recfQCG4kFQcHIU4X"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 1414,
        "id": "recaVHtuRY5uI4ovQ",
        "row": 22203,
        "state": "",
        "group": "",
        "group_num": [
            "recj4wfwy5Xow8NKs"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2648,
        "2023": 2645,
        "2024": 1640,
        "id": "reccK0q35stlmomZV",
        "row": 22163,
        "state": "",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recovJMA8IauDAOaZ",
        "row": 22199,
        "state": "",
        "group": "3208640",
        "group_num": [
            "recA3UKNKLN2p7bPI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recoKUSlcNfdPXTk1",
        "row": 22174,
        "state": "",
        "group": "",
        "group_num": [
            "rec18OWcWzTRDlzzU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 834,
        "id": "rectktHLsFK0FUHh4",
        "row": 22180,
        "state": "",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 508,
        "id": "recuMDPhqRidGAqUn",
        "row": 22157,
        "state": "",
        "group": "3174104",
        "group_num": [
            "recT6iHmhXTeC07aH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recvbfmzoEULJa4rI",
        "row": 22200,
        "state": "",
        "group": "",
        "group_num": [
            "rec5W6zrMzZvp13W1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 219,
        "2023": 203,
        "id": "recx2WSFnSfafUPzu",
        "row": 22176,
        "state": "",
        "group": "",
        "group_num": [
            "recnLru4030Vosl1v"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3186,
        "2023": 2648,
        "id": "reczPiVTD8qg1jmPX",
        "row": 22202,
        "state": "",
        "group": "",
        "group_num": [
            "recj4wfwy5Xow8NKs"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "id": "recznwyWwKBvAhOjH",
        "row": 22188,
        "state": "",
        "group": "3207160",
        "group_num": [
            "recKWrTMW3NZNU6lQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 950,
        "id": "recAUmuFcRFCyJOMZ",
        "row": 22193,
        "state": "",
        "group": "3207608",
        "group_num": [
            "recJAZ8eYmKOLQEwk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 210,
        "id": "recABHALbzKApVqDn",
        "row": 22181,
        "state": "",
        "group": "3206224",
        "group_num": [
            "rec5eHWNBZSF9WrvY"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 208,
        "id": "recALVnfGVKQlPBpK",
        "row": 22161,
        "state": "",
        "group": "3174696",
        "group_num": [
            "rece2a8AxOjMZk40Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "id": "recC0KU8WIUAK5zgc",
        "row": 22192,
        "state": "",
        "group": "",
        "group_num": [
            "recJ5VOhVy0bTWfaX"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 642,
        "2023": 667,
        "id": "recCDq8nMpGRrzhnL",
        "row": 22171,
        "state": "",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recDFfjiHme8uDVWo",
        "row": 22168,
        "state": "",
        "group": "",
        "group_num": [
            "reciCqw7wcrvMWBnK"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1901,
        "2023": 2031,
        "id": "recEyaRZHROOPLlmQ",
        "row": 22170,
        "state": "",
        "group": "3186772",
        "group_num": [
            "rec8IMLer4XMMU9WC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 712,
        "id": "recFO7sbIxTxg7sA7",
        "row": 22175,
        "state": "",
        "group": "",
        "group_num": [
            "recnLru4030Vosl1v"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 641,
        "id": "recGQntL28ICMON00",
        "row": 22205,
        "state": "",
        "group": "",
        "group_num": [
            "recbpBf9m0xcnjGCC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "2023": 174,
        "id": "recKDGXbqGy0spHT9",
        "row": 22185,
        "state": "",
        "group": "3207016",
        "group_num": [
            "recIEv52upcncQGzE"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "2024": 1900,
        "id": "recKKSIfnbVW5MZZ5",
        "row": 22198,
        "state": "",
        "group": "3208640",
        "group_num": [
            "recA3UKNKLN2p7bPI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3810,
        "2023": 1954,
        "id": "recKqmOHpRXVkRfk8",
        "row": 22167,
        "state": "",
        "group": "3174704",
        "group_num": [
            "rec9RPIA2mYnCKMCH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 351,
        "id": "recLJquL266LM7l81",
        "row": 22177,
        "state": "",
        "group": "",
        "group_num": [
            "recnLru4030Vosl1v"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 496,
        "id": "recekvcJDEKQt0KaN",
        "row": 22212,
        "state": "",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "recfIVe7YLdc02Ww6",
        "row": 22244,
        "state": "",
        "group": "",
        "group_num": [
            "rec8BEjTV4ErEOPFq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 778,
        "id": "recgZwF1faVU0MDn2",
        "row": 22216,
        "state": "",
        "group": "",
        "group_num": [
            "recpfmhrIhuX2By3q"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 766,
        "2023": 596,
        "id": "reclUfnqxfh17qYQg",
        "row": 22211,
        "state": "",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2650,
        "id": "reclo2w2MI033Xtof",
        "row": 22229,
        "state": "",
        "group": "3210452",
        "group_num": [
            "recejQti8zWHY99Rd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "id": "recQAL9oIDhOBRhhU",
        "row": 22209,
        "state": "",
        "group": "3209708",
        "group_num": [
            "recW4kdtLUjvkdEC6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1662,
        "id": "recSwsxTmfsWLTNwp",
        "row": 22253,
        "state": "",
        "group": "",
        "group_num": [
            "recUb4dnVE4N6i0q1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 571,
        "id": "recSlv4x6CP3WhzPl",
        "row": 22240,
        "state": "",
        "group": "",
        "group_num": [
            "recm4hHpt153zGrnp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "recUgNN03R46lgPrR",
        "row": 22254,
        "state": "",
        "group": "3215304",
        "group_num": [
            "recJpAnNopIdv4Z8T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1856,
        "id": "recW7IwKMxaEfbiBp",
        "row": 22230,
        "state": "",
        "group": "3210548",
        "group_num": [
            "rechu0wjl3Qo6ZQD8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recXHa8Tf2qsX1lBn",
        "row": 22238,
        "state": "",
        "group": "3212812",
        "group_num": [
            "recr43HhKOoLUHbGM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1777,
        "id": "recXHnEBOdoqIbtlF",
        "row": 22214,
        "state": "",
        "group": "",
        "group_num": [
            "recXi8fhbd0M32YWj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 811,
        "id": "recYScNfuQaLSllnF",
        "row": 22220,
        "state": "",
        "group": "",
        "group_num": [
            "rec5reVC7fPUxNy1S"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1043,
        "id": "recYFyrc95XowQehA",
        "row": 22222,
        "state": "",
        "group": "",
        "group_num": [
            "recRFsCP3oXwlQbuQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 164,
        "id": "recY93Wll7rVLwPVF",
        "row": 22218,
        "state": "",
        "group": "",
        "group_num": [
            "recpfmhrIhuX2By3q"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1814,
        "id": "recZVoI4QdmMSfDKA",
        "row": 22232,
        "state": "",
        "group": "3210856",
        "group_num": [
            "recg762L532U1DJH9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2011,
        "id": "rec3wUlCbxXUcxOuI",
        "row": 22242,
        "state": "",
        "group": "",
        "group_num": [
            "rec8BEjTV4ErEOPFq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 168,
        "id": "rec3iChK6ZNZcCkPF",
        "row": 22249,
        "state": "",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "rec50Vjo5QLXVrn4V",
        "row": 22256,
        "state": "",
        "group": "3215304",
        "group_num": [
            "recJpAnNopIdv4Z8T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "rec5dYVJeBb5UsG4c",
        "row": 22248,
        "state": "",
        "group": "",
        "group_num": [
            "recYi5A96vRPkSFGH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2145,
        "id": "rec6BfF4duEHIi5Um",
        "row": 22237,
        "state": "",
        "group": "3212812",
        "group_num": [
            "recr43HhKOoLUHbGM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "rec7xudAnRL89JioW",
        "row": 22243,
        "state": "",
        "group": "",
        "group_num": [
            "rec8BEjTV4ErEOPFq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "rec8ncdtJgqrrkiiY",
        "row": 22250,
        "state": "",
        "group": "3213484",
        "group_num": [
            "rechJLRFggXLnMssB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1781,
        "id": "rec9G3tBhr3hHmc6p",
        "row": 22231,
        "state": "",
        "group": "3210548",
        "group_num": [
            "rechu0wjl3Qo6ZQD8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2340,
        "id": "recbvo9cVtd9uCIRc",
        "row": 22235,
        "state": "",
        "group": "",
        "group_num": [
            "recwwykvaJPFmgPiB"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "reccCshiWaqbmbYm5",
        "row": 22234,
        "state": "",
        "group": "",
        "group_num": [
            "recwwykvaJPFmgPiB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1847,
        "id": "recoRlwRkslBkplwx",
        "row": 22224,
        "state": "",
        "group": "3210196",
        "group_num": [
            "recU5IBK7ugybj9cQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "reco4puoRLFKGCiQe",
        "row": 22210,
        "state": "",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 187,
        "id": "recofiIEs94o8rwC4",
        "row": 22236,
        "state": "",
        "group": "3212272",
        "group_num": [
            "rec0jkIRIFFxvAsLT"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recpmqZJAR6i3nb7m",
        "row": 22233,
        "state": "",
        "group": "",
        "group_num": [
            "recwwykvaJPFmgPiB"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2298,
        "id": "recqSen7F4LFT1JKI",
        "row": 22228,
        "state": "",
        "group": "3210452",
        "group_num": [
            "recejQti8zWHY99Rd"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1105,
        "2023": 290,
        "id": "recru9zYXic6F8sCe",
        "row": 22225,
        "state": "",
        "group": "3210392",
        "group_num": [
            "reclBRS5SteeexMYT"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recstkGO4dqQOIRnc",
        "row": 22219,
        "state": "",
        "group": "",
        "group_num": [
            "rec5reVC7fPUxNy1S"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2457,
        "id": "recu3xMvg8omlHuT7",
        "row": 22247,
        "state": "",
        "group": "3213120",
        "group_num": [
            "rec2IHTUnV577EPim"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1607,
        "id": "recvLURQbtQ6e1KJt",
        "row": 22207,
        "state": "",
        "group": "",
        "group_num": [
            "receNzD2NC7Il4jIO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3345,
        "2023": 3000,
        "id": "recz9XeeZvnAGORBw",
        "row": 22213,
        "state": "",
        "group": "3209804",
        "group_num": [
            "rec6C10F12Aoxkq1s"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recADsdYdtLQAsb4f",
        "row": 22251,
        "state": "",
        "group": "",
        "group_num": [
            "rec2yqBTWEPr80GCa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recAim9Wtq8UM9MVR",
        "row": 22252,
        "state": "",
        "group": "",
        "group_num": [
            "rec2yqBTWEPr80GCa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1993,
        "id": "recBuChIATnXk9iIG",
        "row": 22215,
        "state": "",
        "group": "",
        "group_num": [
            "recXi8fhbd0M32YWj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "id": "recCPR20cnec0QNaJ",
        "row": 22255,
        "state": "",
        "group": "3215304",
        "group_num": [
            "recJpAnNopIdv4Z8T"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "2024": 158,
        "id": "recDUH7XdZC8GwlHF",
        "row": 22217,
        "state": "",
        "group": "",
        "group_num": [
            "recpfmhrIhuX2By3q"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2240,
        "id": "recD5yDwEkhrX5NbF",
        "row": 22246,
        "state": "",
        "group": "3213120",
        "group_num": [
            "rec2IHTUnV577EPim"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recE1c60ycgMI7hJj",
        "row": 22227,
        "state": "",
        "group": "",
        "group_num": [
            "recNOfGKuPZzp9xE8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recG49ybkv0fTFdNh",
        "row": 22221,
        "state": "",
        "group": "",
        "group_num": [
            "recRFsCP3oXwlQbuQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 693,
        "id": "recGnnmJ21v9s3avN",
        "row": 22208,
        "state": "",
        "group": "",
        "group_num": [
            "receNzD2NC7Il4jIO"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recJ9290kZdGHsGfk",
        "row": 22241,
        "state": "",
        "group": "",
        "group_num": [
            "recm4hHpt153zGrnp"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recJhNiFYlvRaxza3",
        "row": 22226,
        "state": "",
        "group": "",
        "group_num": [
            "recNOfGKuPZzp9xE8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recKu2Fkdsy8A69pD",
        "row": 22245,
        "state": "",
        "group": "",
        "group_num": [
            "recCyUe4NoPogIzaD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recM8BGOItAQ665ok",
        "row": 22223,
        "state": "",
        "group": "3210196",
        "group_num": [
            "recU5IBK7ugybj9cQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1520,
        "id": "recNVajtwv5vjz4fq",
        "row": 22239,
        "state": "",
        "group": "",
        "group_num": [
            "recm4hHpt153zGrnp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 183,
        "id": "recfu38CEwEe1BZCA",
        "row": 22292,
        "state": "",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "id": "rechaItGqbTqM61Ms",
        "row": 22294,
        "state": "",
        "group": "",
        "group_num": [
            "recgKY0PpT372NH9c"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1607,
        "id": "recmTbwNc7lCH2Rgx",
        "row": 22289,
        "state": "",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recmze6Q03q14XKui",
        "row": 22258,
        "state": "",
        "group": "3216276",
        "group_num": [
            "rec3CLWEumKWCK1r4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2846,
        "id": "recmbnujjKL3aSUQP",
        "row": 22265,
        "state": "",
        "group": "",
        "group_num": [
            "recZI02VY6HGm3oaN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "recnfR95MMYLYMBtG",
        "row": 22284,
        "state": "",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 191,
        "2024": 230,
        "id": "recPPkUnNy5IDqBr5",
        "row": 22264,
        "state": "",
        "group": "",
        "group_num": [
            "recZI02VY6HGm3oaN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recQdEWSJKSdmJ95i",
        "row": 22299,
        "state": "",
        "group": "3327596",
        "group_num": [
            "rec13pT2eiBX759WR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "2023": 693,
        "id": "recRYvqk5R0aIvu8k",
        "row": 22290,
        "state": "",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2454,
        "id": "recSAV8ZgBtWBNPma",
        "row": 22297,
        "state": "",
        "group": "",
        "group_num": [
            "recUGViTXbwSYZwuh"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recTYUpef0VcwevUt",
        "row": 22288,
        "state": "",
        "group": "3320608",
        "group_num": [
            "recRSB5fyyI8fUVHb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recZvWvmZGaspfd6I",
        "row": 22300,
        "state": "",
        "group": "",
        "group_num": [
            "recgDtkZk00oOLk3e"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 111,
        "id": "recZaAQ0hdkvUHv4I",
        "row": 22274,
        "state": "",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 442,
        "id": "rec3CbfA1pRMazVkO",
        "row": 22280,
        "state": "",
        "group": "",
        "group_num": [
            "rec0gOR3IWyctw18o"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 205,
        "id": "rec3JYGpkmKokAGSg",
        "row": 22270,
        "state": "",
        "group": "3316316",
        "group_num": [
            "recHPVVRhgUSuOdXm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 569,
        "id": "rec3RS1yr5VMnNm3h",
        "row": 22296,
        "state": "",
        "group": "",
        "group_num": [
            "recvXqoijIAonOrcl"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 59,
        "2023": 75,
        "id": "rec4ZPDwtpYra2ej8",
        "row": 22259,
        "state": "",
        "group": "3216828",
        "group_num": [
            "recdVOu4XrB1npz9Z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 178,
        "2024": 178,
        "id": "rec45yK8U7kof53Vt",
        "row": 22291,
        "state": "",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 418,
        "id": "rec5JImai1JeO8Rxu",
        "row": 22303,
        "state": "",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "rec6VudhP2BAonpDe",
        "row": 22287,
        "state": "",
        "group": "3320608",
        "group_num": [
            "recRSB5fyyI8fUVHb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "2024": 280,
        "id": "rec7LA8rs1dathnVw",
        "row": 22272,
        "state": "",
        "group": "",
        "group_num": [
            "recmq37lh6jTBvom5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 251,
        "id": "rec8IZElmQ9fO8etY",
        "row": 22267,
        "state": "",
        "group": "3311716",
        "group_num": [
            "rec5HbJsshtKsc3fn"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 60,
        "id": "rec8lzr4ma9U9MAwN",
        "row": 22271,
        "state": "",
        "group": "",
        "group_num": [
            "recmq37lh6jTBvom5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2024": 2467,
        "id": "rec9iA2QaTNNhtyOn",
        "row": 22293,
        "state": "",
        "group": "3324036",
        "group_num": [
            "recdHZsXRQYstQWTL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 3000,
        "id": "recaSkmPx5lxffDG0",
        "row": 22276,
        "state": "",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recar5Xz8r6BHEBY1",
        "row": 22266,
        "state": "",
        "group": "",
        "group_num": [
            "recpJ6cPUP5FN7nbV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2190,
        "id": "recqV6lqAcufE4Gh1",
        "row": 22283,
        "state": "",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 283,
        "2024": 290,
        "id": "recrToFJUsLSgZdeg",
        "row": 22275,
        "state": "",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2252,
        "id": "recrTYkP9WuNtx1Fk",
        "row": 22279,
        "state": "",
        "group": "3319768",
        "group_num": [
            "recF43o14kVABoJ7T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 340,
        "id": "recrV1CG8s6NaLMsp",
        "row": 22268,
        "state": "",
        "group": "3311716",
        "group_num": [
            "rec5HbJsshtKsc3fn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2208,
        "id": "recsenlEBqf1FdiSb",
        "row": 22277,
        "state": "",
        "group": "3319768",
        "group_num": [
            "recF43o14kVABoJ7T"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 160,
        "id": "recuWghxxQ7IJXue7",
        "row": 22285,
        "state": "",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "id": "recuzenLoEIwHAt3p",
        "row": 22295,
        "state": "",
        "group": "",
        "group_num": [
            "recgKY0PpT372NH9c"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1025,
        "2023": 1026,
        "id": "recvB5otaR106dQId",
        "row": 22302,
        "state": "",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recvIMgpYUtDUBIk5",
        "row": 22261,
        "state": "",
        "group": "",
        "group_num": [
            "recB473586yfEvfJ4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 1975,
        "id": "recvcb4ZRSnJQn6rR",
        "row": 22273,
        "state": "",
        "group": "3318184",
        "group_num": [
            "recYvAhlXq76chgZQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 677,
        "id": "recwUmHhgsibAbgxw",
        "row": 22286,
        "state": "",
        "group": "3320416",
        "group_num": [
            "recPL5VD31Mhywa0z"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "2023": 180,
        "id": "recwaAZZKNKwGgiH3",
        "row": 22281,
        "state": "",
        "group": "",
        "group_num": [
            "rec0gOR3IWyctw18o"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 390,
        "2023": 172,
        "2024": 228,
        "id": "recwm8sZ6NP1Yd3ba",
        "row": 22282,
        "state": "",
        "group": "",
        "group_num": [
            "rec0gOR3IWyctw18o"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 132,
        "id": "recyu9bSdTNXvZWKO",
        "row": 22269,
        "state": "",
        "group": "3316316",
        "group_num": [
            "recHPVVRhgUSuOdXm"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 173,
        "id": "recAHYutCxkzg4kct",
        "row": 22278,
        "state": "",
        "group": "3319768",
        "group_num": [
            "recF43o14kVABoJ7T"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "recC1xyKaIIgUNkxq",
        "row": 22306,
        "state": "",
        "group": "",
        "group_num": [
            "rec0bkylwuqaeuhh4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recC2B5EU8dPKSzcL",
        "row": 22305,
        "state": "",
        "group": "",
        "group_num": [
            "rec0bkylwuqaeuhh4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2476,
        "2024": 2500,
        "id": "recCdZMXGfLF46bjK",
        "row": 22304,
        "state": "",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1840,
        "2024": 1920,
        "id": "recDXh3W3iySGDlFW",
        "row": 22301,
        "state": "",
        "group": "3327984",
        "group_num": [
            "recMjascMy8r4nGm8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 277,
        "id": "recGY9ftEyPlQIZsV",
        "row": 22260,
        "state": "",
        "group": "3216828",
        "group_num": [
            "recdVOu4XrB1npz9Z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 950,
        "id": "recHXng3RPyAXwFYM",
        "row": 22263,
        "state": "",
        "group": "3309644",
        "group_num": [
            "rec5nIxvdWj2swuXF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recJC5T4lT5g53utT",
        "row": 22298,
        "state": "",
        "group": "3327595",
        "group_num": [
            "rec3FhvBPg4PuJVwF"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 548,
        "id": "recLdWY4Zr91iEqeI",
        "row": 22257,
        "state": "",
        "group": "",
        "group_num": [
            "recSZeIyE27esADMx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 533,
        "id": "recMIo5oOU2TLWeOJ",
        "row": 22262,
        "state": "",
        "group": "3309644",
        "group_num": [
            "rec5nIxvdWj2swuXF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3841,
        "2023": 3382,
        "id": "receMrd2B1cJmu8Qq",
        "row": 22336,
        "state": "",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rece4wwBIpI5ojtbN",
        "row": 22353,
        "state": "",
        "group": "",
        "group_num": [
            "recBfCqU0ELKZ7I7P"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1480,
        "id": "recgQ2QaiUuoaIEkx",
        "row": 22321,
        "state": "",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recgmVNqMk2QIRSsK",
        "row": 22329,
        "state": "",
        "group": "",
        "group_num": [
            "recP7x04ar2tCzlG3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 198,
        "id": "recitgxVen8xzx1M1",
        "row": 22327,
        "state": "",
        "group": "",
        "group_num": [
            "recNJ411cszgUTQcN"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1957,
        "2023": 3800,
        "id": "recifgIQgPoUYutHs",
        "row": 22334,
        "state": "",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2410,
        "id": "recm9SEJO8lEiAZpC",
        "row": 22351,
        "state": "",
        "group": "3329926",
        "group_num": [
            "recCq7xmuUgRQAhlK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "2023": 249,
        "id": "recnUMSONnix71RuV",
        "row": 22308,
        "state": "",
        "group": "",
        "group_num": [
            "recoWzj8WgvbCMMpw"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2340,
        "id": "recOFdXBH7FjNkn03",
        "row": 22330,
        "state": "",
        "group": "",
        "group_num": [
            "recP7x04ar2tCzlG3"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 413,
        "id": "recPfQTYqALtoifXi",
        "row": 22315,
        "state": "",
        "group": "3328317",
        "group_num": [
            "recg31PzLjp9XlEwh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recPkbGls65SC34M5",
        "row": 22328,
        "state": "",
        "group": "",
        "group_num": [
            "recP7x04ar2tCzlG3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recQ3s60ZnM41COlG",
        "row": 22356,
        "state": "",
        "group": "",
        "group_num": [
            "reciBYsY5ym20ixD2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 471,
        "id": "recQ70IgIRbHTt5IV",
        "row": 22346,
        "state": "",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 181,
        "2023": 177,
        "id": "recSejGEDNxr1jege",
        "row": 22339,
        "state": "",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2119,
        "id": "recUGxTLOkG4wNAwq",
        "row": 22337,
        "state": "",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1985,
        "id": "recW2DTEqJEnSuTTp",
        "row": 22355,
        "state": "",
        "group": "",
        "group_num": [
            "reciBYsY5ym20ixD2"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "2023": 589,
        "id": "recWo8TtpK9x6pYpq",
        "row": 22335,
        "state": "",
        "group": "3329543",
        "group_num": [
            "rectawmdmYuIt6ZFJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "2023": 202,
        "id": "recXEseNDWB1QkW7d",
        "row": 22350,
        "state": "",
        "group": "3329926",
        "group_num": [
            "recCq7xmuUgRQAhlK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 179,
        "id": "rec0gc7fsyIF0fMvh",
        "row": 22309,
        "state": "",
        "group": "",
        "group_num": [
            "recoWzj8WgvbCMMpw"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2400,
        "id": "rec1HXW4sJtohVb8j",
        "row": 22314,
        "state": "",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "rec2Ij9TQlcBSqEbs",
        "row": 22326,
        "state": "",
        "group": "3328645",
        "group_num": [
            "reckd8F1VpT7dN8Hx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "2024": 1705,
        "id": "rec3Cby9aFwO9gOGZ",
        "row": 22333,
        "state": "",
        "group": "",
        "group_num": [
            "recL6hGnJmzCBYDUU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rec4WTjpKXrpEJQLy",
        "row": 22352,
        "state": "",
        "group": "",
        "group_num": [
            "recTJW09ub1wPCfyC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 166,
        "id": "rec4zjKry5MIGMfTD",
        "row": 22354,
        "state": "",
        "group": "3330535",
        "group_num": [
            "recFWBJZUcD350fWh"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2119,
        "2023": 1920,
        "2024": 1732,
        "id": "rec6KUzfo7Dhp8Zqv",
        "row": 22331,
        "state": "",
        "group": "",
        "group_num": [
            "recL6hGnJmzCBYDUU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "rec68uTH4y5MGOy3j",
        "row": 22319,
        "state": "",
        "group": "3328490",
        "group_num": [
            "rec18yehSkze02KTC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2724,
        "2024": 2250,
        "id": "rec9CZ3ZQRtqTJshV",
        "row": 22324,
        "state": "",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recbLzTY6WVNSCpc2",
        "row": 22323,
        "state": "",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 224,
        "2024": 230,
        "id": "recb5dmLmykYX7Lcx",
        "row": 22340,
        "state": "",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "reccEdnzfnlzInJFk",
        "row": 22320,
        "state": "",
        "group": "3328490",
        "group_num": [
            "rec18yehSkze02KTC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 2288,
        "id": "recoG8TojAk75JVa7",
        "row": 22348,
        "state": "",
        "group": "3329926",
        "group_num": [
            "recCq7xmuUgRQAhlK"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 631,
        "id": "recqsWTzDJSAVsL5N",
        "row": 22343,
        "state": "",
        "group": "",
        "group_num": [
            "recNCPmMuO7QFLgBa"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2324,
        "2023": 1595,
        "id": "recqPVqA8yGQlYX8m",
        "row": 22341,
        "state": "",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1565,
        "id": "rectZ0qJMQywV9gFL",
        "row": 22345,
        "state": "",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3783,
        "id": "rectFIk2IO9neEO9F",
        "row": 22347,
        "state": "",
        "group": "3329799",
        "group_num": [
            "recoiTFelFped2nbO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 524,
        "2023": 409,
        "id": "recvvsVSxUzDbRQWD",
        "row": 22316,
        "state": "",
        "group": "3328406",
        "group_num": [
            "recRKA6FGm1MuVwcN"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recvOO7sHDS0RANSs",
        "row": 22312,
        "state": "",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1583,
        "2024": 1920,
        "id": "recv5NLsHopXRdYZ8",
        "row": 22310,
        "state": "",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 571,
        "id": "recxTVclBBFVNiOxC",
        "row": 22332,
        "state": "",
        "group": "",
        "group_num": [
            "recL6hGnJmzCBYDUU"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2540,
        "id": "recxgF0rYkRKivXM2",
        "row": 22344,
        "state": "",
        "group": "3329774",
        "group_num": [
            "recA3g8KYh7kQrlOt"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 112,
        "id": "reczA8eqCGN1a7hba",
        "row": 22317,
        "state": "",
        "group": "3328406",
        "group_num": [
            "recRKA6FGm1MuVwcN"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "reczEtXpzOiO8Cpgx",
        "row": 22342,
        "state": "",
        "group": "",
        "group_num": [
            "recNCPmMuO7QFLgBa"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recAUpZkQ4bpJetnx",
        "row": 22307,
        "state": "",
        "group": "",
        "group_num": [
            "recoWzj8WgvbCMMpw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "recF3TMbPMrbOhTL8",
        "row": 22311,
        "state": "",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 499,
        "id": "recF5qvEKmxHMvV4O",
        "row": 22338,
        "state": "",
        "group": "3329609",
        "group_num": [
            "recUINikDU5Ftozld"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 708,
        "id": "recF8AuMdvbeB74yK",
        "row": 22349,
        "state": "",
        "group": "3329926",
        "group_num": [
            "recCq7xmuUgRQAhlK"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "2023": 176,
        "id": "recHkOzw1XSQ6u7wi",
        "row": 22318,
        "state": "",
        "group": "3328406",
        "group_num": [
            "recRKA6FGm1MuVwcN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 762,
        "2023": 290,
        "2024": 290,
        "id": "recI0ZFqevYUh8r08",
        "row": 22313,
        "state": "",
        "group": "3328213",
        "group_num": [
            "recddET0JNthuHtHo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "id": "recLUFmKEoUemjgMA",
        "row": 22325,
        "state": "",
        "group": "3328645",
        "group_num": [
            "reckd8F1VpT7dN8Hx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 552,
        "id": "recLjXyS6A30k4pU1",
        "row": 22322,
        "state": "",
        "group": "3328516",
        "group_num": [
            "recbjXZyZtlbwswan"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "receooeBqmCgtYEDc",
        "row": 22370,
        "state": "",
        "group": "",
        "group_num": [
            "recXSIao8VMs0coAJ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recg8ZaDJsTWeHIWz",
        "row": 22392,
        "state": "",
        "group": "3332133",
        "group_num": [
            "recwYiFjJ5cYEqudr"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3740,
        "id": "recil2yQBE33OMNyc",
        "row": 22382,
        "state": "",
        "group": "3331930",
        "group_num": [
            "recqIUXgse1LxlN6V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1301,
        "id": "reckJ2LSCqHezYIWe",
        "row": 22362,
        "state": "",
        "group": "",
        "group_num": [
            "recYB7jMWWNX3eotQ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "reclZzWfGDorkFhp1",
        "row": 22397,
        "state": "",
        "group": "3332379",
        "group_num": [
            "rec3foQxFYJpdrJwb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recmVW6LhvbrOVlrI",
        "row": 22363,
        "state": "",
        "group": "3330934",
        "group_num": [
            "recNmWEdvHx4v6WZN"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 510,
        "id": "recnNUuGfWQyKxM1H",
        "row": 22404,
        "state": "",
        "group": "",
        "group_num": [
            "recBXAcbRw8oO9nlV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2660,
        "id": "recODrpLLFZZzXjmM",
        "row": 22374,
        "state": "",
        "group": "",
        "group_num": [
            "recKmnGx1GO6OntnU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recPpqj6VDSB6E9ds",
        "row": 22400,
        "state": "",
        "group": "",
        "group_num": [
            "recN1oAElwWqiTmcr"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 151,
        "id": "recRxyNmEMBiheHEG",
        "row": 22387,
        "state": "",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 681,
        "id": "recRPjq68T5IsaITX",
        "row": 22372,
        "state": "",
        "group": "3331096",
        "group_num": [
            "recbuTrpKpTfjDXaG"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 63,
        "2024": 60,
        "id": "recSRFYYjUKhlBAKr",
        "row": 22381,
        "state": "",
        "group": "3331930",
        "group_num": [
            "recqIUXgse1LxlN6V"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 361,
        "id": "recSa2IF3kGjvwvzM",
        "row": 22402,
        "state": "",
        "group": "3332740",
        "group_num": [
            "recQXxGR8cRushzhk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recUot7tKOrHQyAwj",
        "row": 22396,
        "state": "",
        "group": "3332379",
        "group_num": [
            "rec3foQxFYJpdrJwb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "2024": 290,
        "id": "recVEaqOgDSVokOAL",
        "row": 22376,
        "state": "",
        "group": "3331790",
        "group_num": [
            "recjKXOBADHMSsO6Y"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recVeE40kLqB0M8mm",
        "row": 22389,
        "state": "",
        "group": "3332105",
        "group_num": [
            "recK7j1Nuf3EyTIWs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1886,
        "2023": 1983,
        "2024": 1705,
        "id": "recWPAhgTz1ibfJtV",
        "row": 22378,
        "state": "",
        "group": "3331910",
        "group_num": [
            "rece21aw4R3lXguOZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 548,
        "id": "recW8mVvVZNXE2NoQ",
        "row": 22379,
        "state": "",
        "group": "3331910",
        "group_num": [
            "rece21aw4R3lXguOZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1795,
        "id": "recWiyBKShf7PrHdk",
        "row": 22360,
        "state": "",
        "group": "",
        "group_num": [
            "recYB7jMWWNX3eotQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recYIWSVeZkf2cPcW",
        "row": 22394,
        "state": "",
        "group": "",
        "group_num": [
            "recd1DKPR5Zw0bO9v"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recY93ZyqiuuXrm4U",
        "row": 22377,
        "state": "",
        "group": "3331790",
        "group_num": [
            "recjKXOBADHMSsO6Y"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2146,
        "2023": 2000,
        "id": "recZSE7tjrSG8UkO5",
        "row": 22367,
        "state": "",
        "group": "3330972",
        "group_num": [
            "recW7cyDTaOwWqlgT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rec1Z79Bsi7eHV78T",
        "row": 22383,
        "state": "",
        "group": "",
        "group_num": [
            "recjTf6aLwaOLmssj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 2400,
        "id": "rec2ng5sDspytcOSk",
        "row": 22393,
        "state": "",
        "group": "3332133",
        "group_num": [
            "recwYiFjJ5cYEqudr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1606,
        "id": "rec3uTzLvaYKoCuCG",
        "row": 22373,
        "state": "",
        "group": "3331096",
        "group_num": [
            "recbuTrpKpTfjDXaG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "rec32nXDbwCV2yg7Y",
        "row": 22395,
        "state": "",
        "group": "3332343",
        "group_num": [
            "recAdy3JThvYonBSj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec4aNXHl1hplNvUm",
        "row": 22385,
        "state": "",
        "group": "",
        "group_num": [
            "recjTf6aLwaOLmssj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2897,
        "id": "rec5RLGCrfcseEAG4",
        "row": 22386,
        "state": "",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 318,
        "id": "rec60YhQp4ecIzhGM",
        "row": 22403,
        "state": "",
        "group": "3332740",
        "group_num": [
            "recQXxGR8cRushzhk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1920,
        "id": "recaPYGbwGBqL8PTj",
        "row": 22405,
        "state": "",
        "group": "",
        "group_num": [
            "recTyzGLqf4iFbWkg"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 681,
        "id": "recaQOXhXF4SFyv0x",
        "row": 22391,
        "state": "",
        "group": "3332133",
        "group_num": [
            "recwYiFjJ5cYEqudr"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2239,
        "id": "reca7Hijtje3IeDcy",
        "row": 22369,
        "state": "",
        "group": "3331040",
        "group_num": [
            "recFFe9jPmAeq5lwG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recbbDl26fvX8DoOk",
        "row": 22357,
        "state": "",
        "group": "3330666",
        "group_num": [
            "reca1lI3ViqOFHgCo"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "id": "recpaj4imLbjwVlEB",
        "row": 22361,
        "state": "",
        "group": "",
        "group_num": [
            "recYB7jMWWNX3eotQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recpf8q468HdlXXaT",
        "row": 22406,
        "state": "",
        "group": "3333086",
        "group_num": [
            "recIiF9gJOdF1ZqD7"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recqqKeSBeotgt1Ll",
        "row": 22401,
        "state": "",
        "group": "3332693",
        "group_num": [
            "recfGyvReFZAnxnt6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "recszzJCTYH4H6Y7d",
        "row": 22364,
        "state": "",
        "group": "3330934",
        "group_num": [
            "recNmWEdvHx4v6WZN"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1062,
        "id": "rectUYpoFQj38TdPN",
        "row": 22358,
        "state": "",
        "group": "3330666",
        "group_num": [
            "reca1lI3ViqOFHgCo"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2558,
        "id": "rectYLxlPdfCugUHL",
        "row": 22366,
        "state": "",
        "group": "",
        "group_num": [
            "rec08wosvDL89rBIb"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 290,
        "2024": 290,
        "id": "recwUaDRI25VDychr",
        "row": 22399,
        "state": "",
        "group": "3332473",
        "group_num": [
            "recge03GAa6Lw29Cm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2301,
        "id": "recylWRMUcIQ8pAnB",
        "row": 22365,
        "state": "",
        "group": "3330934",
        "group_num": [
            "recNmWEdvHx4v6WZN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 60,
        "id": "reczbnDWK0kE1SEVi",
        "row": 22398,
        "state": "",
        "group": "3332473",
        "group_num": [
            "recge03GAa6Lw29Cm"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3500,
        "id": "recBRMGoAcqI0R2Jf",
        "row": 22375,
        "state": "",
        "group": "",
        "group_num": [
            "recKmnGx1GO6OntnU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recEHPX2J36NEKIT2",
        "row": 22371,
        "state": "",
        "group": "3331096",
        "group_num": [
            "recbuTrpKpTfjDXaG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2016,
        "id": "recFgxiWpC4N26hFR",
        "row": 22390,
        "state": "",
        "group": "3332133",
        "group_num": [
            "recwYiFjJ5cYEqudr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "recGDzSQZIG5DxGFG",
        "row": 22384,
        "state": "",
        "group": "",
        "group_num": [
            "recjTf6aLwaOLmssj"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2035,
        "id": "recGooAxRml1XY6Ux",
        "row": 22368,
        "state": "",
        "group": "3331040",
        "group_num": [
            "recFFe9jPmAeq5lwG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2275,
        "id": "recGqd9Wa37swMDGb",
        "row": 22388,
        "state": "",
        "group": "3332071",
        "group_num": [
            "recsQg4gOboQGaIQl"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2394,
        "2024": 2252,
        "id": "recIb1Ibol3y6FcNt",
        "row": 22380,
        "state": "",
        "group": "3331910",
        "group_num": [
            "rece21aw4R3lXguOZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1308,
        "id": "recMfcKiMRVbURPq2",
        "row": 22359,
        "state": "",
        "group": "",
        "group_num": [
            "recYB7jMWWNX3eotQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 722,
        "id": "recemCHh2wX3R9EJE",
        "row": 22427,
        "state": "",
        "group": "3333770",
        "group_num": [
            "reclom1FbBi6CFtre"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 162,
        "2024": 210,
        "id": "recf2h7P1rf5dUmK4",
        "row": 22410,
        "state": "",
        "group": "",
        "group_num": [
            "recsIpGRmJyXICUOO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2527,
        "id": "recfkIDPJcaAhlnhT",
        "row": 22435,
        "state": "",
        "group": "",
        "group_num": [
            "rec2BRCu82iry6LBG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 162,
        "2023": 180,
        "2024": 172,
        "id": "recgGR0bGcFNv5W0K",
        "row": 22446,
        "state": "",
        "group": "",
        "group_num": [
            "reck7pZnrfKDeD1Hu"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1764,
        "id": "recgaFR0lE9fmnvMi",
        "row": 22407,
        "state": "",
        "group": "3333086",
        "group_num": [
            "recIiF9gJOdF1ZqD7"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 922,
        "id": "rechJwEX6DIwoMTEJ",
        "row": 22448,
        "state": "",
        "group": "",
        "group_num": [
            "recw38crO0ANzN3Pq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1034,
        "id": "rechL6uJpU7wxnyYA",
        "row": 22417,
        "state": "",
        "group": "",
        "group_num": [
            "recwk4fxzA7y9a8I5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "2023": 2651,
        "id": "reci0kDHm9KekmD5v",
        "row": 22453,
        "state": "",
        "group": "3335355",
        "group_num": [
            "recpEFNL98rjgrYAZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4199,
        "2023": 4467,
        "id": "reckUCbj7IsXnmVaB",
        "row": 22431,
        "state": "",
        "group": "3333857",
        "group_num": [
            "recQxzIflr8Z9HxrT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "reckhJRkZo6nUG4Vm",
        "row": 22429,
        "state": "",
        "group": "3333787",
        "group_num": [
            "rec7PiTXbHHW4dRcz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2186,
        "id": "recma9EqRBci72Pla",
        "row": 22437,
        "state": "",
        "group": "",
        "group_num": [
            "rec2BRCu82iry6LBG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 607,
        "2023": 990,
        "id": "recnhQn0fqheOFYiX",
        "row": 22419,
        "state": "",
        "group": "3333413",
        "group_num": [
            "recNWA3zpJqKviG05"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1229,
        "id": "recPwCZPf8MQIo8g0",
        "row": 22442,
        "state": "",
        "group": "",
        "group_num": [
            "rec32CDgZxlISnKt6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1897,
        "id": "recP3PNj1nKmlYj4T",
        "row": 22418,
        "state": "",
        "group": "3333413",
        "group_num": [
            "recNWA3zpJqKviG05"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 186,
        "2024": 230,
        "id": "recQuWRs3U6g6BLoH",
        "row": 22455,
        "state": "",
        "group": "3335355",
        "group_num": [
            "recpEFNL98rjgrYAZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2711,
        "id": "recRKR3RnrHcb3vFi",
        "row": 22420,
        "state": "",
        "group": "3333413",
        "group_num": [
            "recNWA3zpJqKviG05"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recROiFSS55mV7Nje",
        "row": 22411,
        "state": "",
        "group": "3333252",
        "group_num": [
            "rec91lmn8XQHmndsT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recSBExIEKjDF4vA6",
        "row": 22414,
        "state": "",
        "group": "3333252",
        "group_num": [
            "rec91lmn8XQHmndsT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 536,
        "id": "recUIYVZS5ROEIEoO",
        "row": 22439,
        "state": "",
        "group": "",
        "group_num": [
            "reci9cwV7czuHbs4r"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 490,
        "id": "recWxMc04iuclh2p6",
        "row": 22454,
        "state": "",
        "group": "3335355",
        "group_num": [
            "recpEFNL98rjgrYAZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recWrlWQUuPWQHYnJ",
        "row": 22432,
        "state": "",
        "group": "3333868",
        "group_num": [
            "recDjHsggxYvT2Uqs"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1375,
        "id": "recXEwKeTRecJvwBG",
        "row": 22444,
        "state": "",
        "group": "3334628",
        "group_num": [
            "recTLXUcfMLBGWhX8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "2024": 4800,
        "id": "rec0vMPcqXVnHqAKF",
        "row": 22434,
        "state": "",
        "group": "3333868",
        "group_num": [
            "recDjHsggxYvT2Uqs"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 357,
        "id": "rec1B6OVEdDi1e91r",
        "row": 22428,
        "state": "",
        "group": "3333770",
        "group_num": [
            "reclom1FbBi6CFtre"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 356,
        "id": "rec3OF29PQrTwoqkC",
        "row": 22423,
        "state": "",
        "group": "3333421",
        "group_num": [
            "recy89hKLLuNykkMO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 536,
        "2024": 536,
        "id": "rec3lf4UChSevzifN",
        "row": 22438,
        "state": "",
        "group": "",
        "group_num": [
            "reci9cwV7czuHbs4r"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1556,
        "id": "rec3p4IBEG7NJKUe8",
        "row": 22445,
        "state": "",
        "group": "",
        "group_num": [
            "rec1eEtt7og1yO4rz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 693,
        "id": "rec4hEbcmjXKUHpMx",
        "row": 22451,
        "state": "",
        "group": "",
        "group_num": [
            "recdYQDwSi7EKj55y"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1364,
        "id": "rec7zsuiBaQWvlfj0",
        "row": 22440,
        "state": "",
        "group": "",
        "group_num": [
            "reci9cwV7czuHbs4r"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 2787,
        "2024": 2820,
        "id": "rec8Cy9bzUbSVyYpU",
        "row": 22456,
        "state": "",
        "group": "3335355",
        "group_num": [
            "recpEFNL98rjgrYAZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2165,
        "id": "rec8ov4GPfkENJF81",
        "row": 22421,
        "state": "",
        "group": "3333421",
        "group_num": [
            "recy89hKLLuNykkMO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "2024": 156,
        "id": "rec9frS2waQhldOQb",
        "row": 22426,
        "state": "",
        "group": "",
        "group_num": [
            "recLcmfIKgiYFnXHw"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "2023": 210,
        "id": "recaO7XfVfQU39DSw",
        "row": 22408,
        "state": "",
        "group": "",
        "group_num": [
            "reciYBNErLoKGPfA1"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 438,
        "2023": 680,
        "id": "recbVyKXGWesRlDho",
        "row": 22412,
        "state": "",
        "group": "3333252",
        "group_num": [
            "rec91lmn8XQHmndsT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 879,
        "id": "recblOATKT3tcdBMN",
        "row": 22436,
        "state": "",
        "group": "",
        "group_num": [
            "rec2BRCu82iry6LBG"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "2023": 990,
        "2024": 330,
        "id": "recdd1gxp0k8K2Oyl",
        "row": 22413,
        "state": "",
        "group": "3333252",
        "group_num": [
            "rec91lmn8XQHmndsT"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recdjhEqzAJVpseeI",
        "row": 22416,
        "state": "",
        "group": "",
        "group_num": [
            "recwk4fxzA7y9a8I5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2217,
        "id": "reco6Gp4KTbMFlS48",
        "row": 22424,
        "state": "",
        "group": "3333421",
        "group_num": [
            "recy89hKLLuNykkMO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 750,
        "id": "recpQpdnHjJVLRLS5",
        "row": 22449,
        "state": "",
        "group": "",
        "group_num": [
            "recw38crO0ANzN3Pq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recrTPOn49t8YNoJa",
        "row": 22452,
        "state": "",
        "group": "",
        "group_num": [
            "recdYQDwSi7EKj55y"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 755,
        "id": "recrZqSXS6nuoN6gY",
        "row": 22422,
        "state": "",
        "group": "3333421",
        "group_num": [
            "recy89hKLLuNykkMO"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 304,
        "2023": 171,
        "id": "rectvFSvF2NOOHwwC",
        "row": 22430,
        "state": "",
        "group": "",
        "group_num": [
            "rec6BFK35KhJjaklN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 346,
        "id": "rectc1zMMG45HYYxV",
        "row": 22409,
        "state": "",
        "group": "",
        "group_num": [
            "reciYBNErLoKGPfA1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2316,
        "id": "recvMznn9XiSK5AuU",
        "row": 22415,
        "state": "",
        "group": "",
        "group_num": [
            "recwk4fxzA7y9a8I5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 745,
        "id": "recz8mxgGvPQBfjnn",
        "row": 22443,
        "state": "",
        "group": "3334628",
        "group_num": [
            "recTLXUcfMLBGWhX8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1450,
        "2024": 2320,
        "id": "recBulyJsVLmu5EQt",
        "row": 22425,
        "state": "",
        "group": "3333634",
        "group_num": [
            "recOe2Wf7kyYREqDC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1848,
        "id": "recCNx4gsoDsYQaxz",
        "row": 22450,
        "state": "",
        "group": "",
        "group_num": [
            "recdYQDwSi7EKj55y"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recDsJJLnDp8dXvJV",
        "row": 22441,
        "state": "",
        "group": "",
        "group_num": [
            "rec4EksgJ5AIADwgP"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 332,
        "2023": 192,
        "2024": 180,
        "id": "recDDydxiYFPibuhM",
        "row": 22447,
        "state": "",
        "group": "",
        "group_num": [
            "reck7pZnrfKDeD1Hu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recDjs8TmGjl1aeh0",
        "row": 22433,
        "state": "",
        "group": "3333868",
        "group_num": [
            "recDjHsggxYvT2Uqs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1921,
        "2023": 1920,
        "id": "receOgakH50PSPECn",
        "row": 22474,
        "state": "",
        "group": "",
        "group_num": [
            "recWktNloiFSp41MN"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "2023": 990,
        "id": "recfB6FPjWMEJBJls",
        "row": 22483,
        "state": "",
        "group": "",
        "group_num": [
            "recsvV0O7LptFwv1M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1709,
        "2023": 1576,
        "2024": 2626,
        "id": "recfOSXcKI1Laaqig",
        "row": 22490,
        "state": "",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3891,
        "id": "recf34rw2kWeFXYsL",
        "row": 22497,
        "state": "",
        "group": "3337288",
        "group_num": [
            "rec8CJ1v3D1yU78vG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "id": "recgsrapl8wEYAnyF",
        "row": 22480,
        "state": "",
        "group": "",
        "group_num": [
            "recucplTeQdUHjkrk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 202,
        "id": "recjSo3sM0exj0iFi",
        "row": 22504,
        "state": "",
        "group": "",
        "group_num": [
            "receDYTkJJXzcjPo9"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2558,
        "id": "recj9yxdrfhe32OM5",
        "row": 22499,
        "state": "",
        "group": "3337320",
        "group_num": [
            "rec2qf07iJTsNdPJn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2732,
        "id": "recm2EFUsCPHR7W9N",
        "row": 22500,
        "state": "",
        "group": "3337320",
        "group_num": [
            "rec2qf07iJTsNdPJn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recmqS9wjcNV3iY3M",
        "row": 22493,
        "state": "",
        "group": "3337219",
        "group_num": [
            "recYBBBsXgPkmIi0Y"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1917,
        "2023": 2281,
        "2024": 2364,
        "id": "recnqHxpVo1lTBQHQ",
        "row": 22487,
        "state": "",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 3000,
        "id": "recOQWM4YZLFKpX57",
        "row": 22494,
        "state": "",
        "group": "3337219",
        "group_num": [
            "recYBBBsXgPkmIi0Y"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recP3Y4AYmlf2jaaw",
        "row": 22485,
        "state": "",
        "group": "",
        "group_num": [
            "recFXwLUwEYse28xw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2131,
        "id": "recPr3MywiSloAWdh",
        "row": 22458,
        "state": "",
        "group": "",
        "group_num": [
            "recTeRTyQwbkzZQ1e"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recQQ4GzXZv51bsLE",
        "row": 22484,
        "state": "",
        "group": "",
        "group_num": [
            "recsvV0O7LptFwv1M"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 217,
        "id": "recSmxBfNU4pQRCUR",
        "row": 22506,
        "state": "",
        "group": "",
        "group_num": [
            "recu6cQt0qDE7Px3M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recTKgVtbyfcCfmWv",
        "row": 22479,
        "state": "",
        "group": "",
        "group_num": [
            "recAY5eWeFpgcoYfg"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recU1s5q2EVqz9wYP",
        "row": 22481,
        "state": "",
        "group": "",
        "group_num": [
            "recsvV0O7LptFwv1M"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1830,
        "id": "recU7BqTTMeFgkJdK",
        "row": 22464,
        "state": "",
        "group": "3336220",
        "group_num": [
            "recii4tqRWjiF5nqG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "2024": 1920,
        "id": "recXEa87PpX6h5LLB",
        "row": 22460,
        "state": "",
        "group": "",
        "group_num": [
            "reco66AGIxF3q9hQi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recZAsoHayoJdoOip",
        "row": 22495,
        "state": "",
        "group": "3337241",
        "group_num": [
            "rechtoX1WUYBRBWEm"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 211,
        "id": "recZoNWKMQBtTuaoZ",
        "row": 22472,
        "state": "",
        "group": "3336422",
        "group_num": [
            "rec72a8eYUuiJJduA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 183,
        "id": "rec3pG5uvdjq0uG9n",
        "row": 22492,
        "state": "",
        "group": "",
        "group_num": [
            "recZ4Av5DTx6aWPya"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 681,
        "id": "rec6hXmDsUKKT7U3T",
        "row": 22468,
        "state": "",
        "group": "3336239",
        "group_num": [
            "rec5PA5Xztm7gWfe0"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 493,
        "id": "rec772IWBiFhjZERp",
        "row": 22471,
        "state": "",
        "group": "3336422",
        "group_num": [
            "rec72a8eYUuiJJduA"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 131,
        "id": "recaLtKIot2a5T5Jc",
        "row": 22489,
        "state": "",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recb6TYUIqO5nRQPe",
        "row": 22459,
        "state": "",
        "group": "3335838",
        "group_num": [
            "recheufQo0c2bF00o"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 852,
        "id": "recoYuOlwW6ZwX1Fb",
        "row": 22486,
        "state": "",
        "group": "",
        "group_num": [
            "recFXwLUwEYse28xw"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 670,
        "id": "recpeJj0XkCdNfDDO",
        "row": 22491,
        "state": "",
        "group": "",
        "group_num": [
            "recZ4Av5DTx6aWPya"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 479,
        "id": "recqfFJkQXQlS2onS",
        "row": 22473,
        "state": "",
        "group": "3336422",
        "group_num": [
            "rec72a8eYUuiJJduA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recrXXefFMspQuhGc",
        "row": 22476,
        "state": "",
        "group": "",
        "group_num": [
            "recWktNloiFSp41MN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 233,
        "id": "recrtheJV89TPAVwz",
        "row": 22498,
        "state": "",
        "group": "3337298",
        "group_num": [
            "recO3A9kPTchVmjeK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rectaZT8k6iqiKnST",
        "row": 22466,
        "state": "",
        "group": "",
        "group_num": [
            "recpSFFu1e31NAecw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recwDpggkA38hwZzt",
        "row": 22457,
        "state": "",
        "group": "",
        "group_num": [
            "recV1Ybf7IyAH43Sf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 543,
        "id": "recwhz2aPLY6yWfzh",
        "row": 22465,
        "state": "",
        "group": "3336220",
        "group_num": [
            "recii4tqRWjiF5nqG"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recx1W6kSZ3gdWkuR",
        "row": 22501,
        "state": "",
        "group": "",
        "group_num": [
            "recWu8prxwJw4hWJ0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 368,
        "id": "recAGDX1Sp2VzDO7n",
        "row": 22505,
        "state": "",
        "group": "",
        "group_num": [
            "recK6giDjsLZ7IVAu"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 462,
        "2023": 589,
        "id": "recBKsV9nbdosl9bp",
        "row": 22488,
        "state": "",
        "group": "3337001",
        "group_num": [
            "rec3nN0N93yNtIWCa"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 571,
        "id": "recCXsTgLCrJnPOLZ",
        "row": 22461,
        "state": "",
        "group": "",
        "group_num": [
            "reco66AGIxF3q9hQi"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recDhpqUGF6FQnbIG",
        "row": 22478,
        "state": "",
        "group": "",
        "group_num": [
            "recAY5eWeFpgcoYfg"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 438,
        "id": "recFPTEekhKwRZfdS",
        "row": 22482,
        "state": "",
        "group": "",
        "group_num": [
            "recsvV0O7LptFwv1M"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2036,
        "id": "recFhW9nTyCoiN2HD",
        "row": 22470,
        "state": "",
        "group": "3336422",
        "group_num": [
            "rec72a8eYUuiJJduA"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 197,
        "id": "recGdSG5nH8aWtiSy",
        "row": 22502,
        "state": "",
        "group": "",
        "group_num": [
            "recWu8prxwJw4hWJ0"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2417,
        "2023": 2347,
        "2024": 1781,
        "id": "recHgswWCbczCBwDr",
        "row": 22469,
        "state": "",
        "group": "3336239",
        "group_num": [
            "rec5PA5Xztm7gWfe0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 2280,
        "id": "recJaaJUq0hLSRfKF",
        "row": 22463,
        "state": "",
        "group": "",
        "group_num": [
            "rec1PaKxopH7s9QwE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recKSKiyEMEy3DFi2",
        "row": 22467,
        "state": "",
        "group": "3336239",
        "group_num": [
            "rec5PA5Xztm7gWfe0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 518,
        "id": "recKgwB8cZ0g2dwWJ",
        "row": 22477,
        "state": "",
        "group": "",
        "group_num": [
            "recuuJGur0LwXT44Q"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recLXZYvrw8H8EVWO",
        "row": 22496,
        "state": "",
        "group": "3337241",
        "group_num": [
            "rechtoX1WUYBRBWEm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recLkDFZcckOAEAjs",
        "row": 22462,
        "state": "",
        "group": "",
        "group_num": [
            "reco66AGIxF3q9hQi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "recMwB1NcHDnBnLRp",
        "row": 22475,
        "state": "",
        "group": "",
        "group_num": [
            "recWktNloiFSp41MN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 764,
        "2023": 1603,
        "id": "recNIcUYDARkahyZ8",
        "row": 22503,
        "state": "",
        "group": "",
        "group_num": [
            "recWu8prxwJw4hWJ0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1878,
        "id": "recfufRmTrF9sdumq",
        "row": 22553,
        "state": "",
        "group": "",
        "group_num": [
            "recSBzgTm9iuqdIHF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2439,
        "id": "recfPJ34ymGdx8NxQ",
        "row": 22539,
        "state": "",
        "group": "3338891",
        "group_num": [
            "recxvstyoGPTaOah1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recflibqmzfVsziWp",
        "row": 22524,
        "state": "",
        "group": "",
        "group_num": [
            "recjVyvWdvmpizJtD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2293,
        "id": "reckwA0pM0jqZg9ng",
        "row": 22507,
        "state": "",
        "group": "",
        "group_num": [
            "recpHJazTcggC9vIX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "reclzQzba1Pg8wqQ8",
        "row": 22511,
        "state": "",
        "group": "3337752",
        "group_num": [
            "recT7z6uPj4iTTMfh"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 732,
        "id": "reclBakCW7rRTqM02",
        "row": 22528,
        "state": "",
        "group": "3338618",
        "group_num": [
            "recCh0OKvM84MUJg4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "reclfVd44UwMvNy8z",
        "row": 22522,
        "state": "",
        "group": "3338372",
        "group_num": [
            "recBDbwV2MCmWaKFp"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recnmPmcA040xHzZ3",
        "row": 22549,
        "state": "",
        "group": "",
        "group_num": [
            "recwpqgEna5SHx8vi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "recOtxtWe55fhOqJH",
        "row": 22532,
        "state": "",
        "group": "",
        "group_num": [
            "recMq5dD2UZ4OgBz7"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1673,
        "id": "recO39uANC2JV4guo",
        "row": 22546,
        "state": "",
        "group": "3339028",
        "group_num": [
            "recXc59m5Jc9nA9Uy"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 476,
        "id": "recPt8TXHcNmbcIuc",
        "row": 22533,
        "state": "",
        "group": "",
        "group_num": [
            "recMq5dD2UZ4OgBz7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 95,
        "id": "recPD6b85orBlAdGv",
        "row": 22542,
        "state": "",
        "group": "",
        "group_num": [
            "recILNHJdsdT49edA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recPRMsjewNlOKsnm",
        "row": 22514,
        "state": "",
        "group": "",
        "group_num": [
            "recdIDbCek1YUj0cR"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1969,
        "id": "recQX7YH0599zFkst",
        "row": 22554,
        "state": "",
        "group": "3339129",
        "group_num": [
            "recOTzQLd5VriwjeX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recSfTh5j3cAmPnCS",
        "row": 22520,
        "state": "",
        "group": "3338371",
        "group_num": [
            "rec8WLjFARVDr1oVq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recTT56VJqRUUaqQw",
        "row": 22523,
        "state": "",
        "group": "3338477",
        "group_num": [
            "recZtLMjeQxzrcXNb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 929,
        "id": "recTvdTZ6cKVPvydG",
        "row": 22555,
        "state": "",
        "group": "3339129",
        "group_num": [
            "recOTzQLd5VriwjeX"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "id": "recVPdNqAhMKi7TXW",
        "row": 22551,
        "state": "",
        "group": "",
        "group_num": [
            "recwpqgEna5SHx8vi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 202,
        "id": "recWjSI1QO5IvYYzh",
        "row": 22556,
        "state": "",
        "group": "3339129",
        "group_num": [
            "recOTzQLd5VriwjeX"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recXZ02FS99qNmNZ9",
        "row": 22529,
        "state": "",
        "group": "3338626",
        "group_num": [
            "recVC7Aj57lm64QkX"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1727,
        "id": "recXzxRvtPN0swFyD",
        "row": 22527,
        "state": "",
        "group": "3338618",
        "group_num": [
            "recCh0OKvM84MUJg4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4140,
        "id": "recXH7lkyuiMxQJD4",
        "row": 22510,
        "state": "",
        "group": "3337592",
        "group_num": [
            "recD36wrPyid2qox5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 541,
        "id": "rec1ERpUKEOVAST3n",
        "row": 22508,
        "state": "",
        "group": "",
        "group_num": [
            "recpHJazTcggC9vIX"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1607,
        "id": "rec2iK6PbAahiooqi",
        "row": 22543,
        "state": "",
        "group": "",
        "group_num": [
            "reciAg5UhrmxiHquR"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "rec4syNN3BHgXOgXV",
        "row": 22540,
        "state": "",
        "group": "3338899",
        "group_num": [
            "rec00IxbdIaLGc8o1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "rec5egV4fAUwoyEPd",
        "row": 22516,
        "state": "",
        "group": "3338229",
        "group_num": [
            "recR4YhwXqVHbhHrG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "id": "rec5oWE8LRnbFRrq4",
        "row": 22547,
        "state": "",
        "group": "3339028",
        "group_num": [
            "recXc59m5Jc9nA9Uy"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "rec8VPpKOpy2cVwmZ",
        "row": 22534,
        "state": "",
        "group": "",
        "group_num": [
            "recMq5dD2UZ4OgBz7"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1911,
        "id": "recaDj5o8YzeZo0VS",
        "row": 22518,
        "state": "",
        "group": "",
        "group_num": [
            "rec1vEWDPQwkRxRgj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "recbOlKNBCEQ7mTgQ",
        "row": 22530,
        "state": "",
        "group": "",
        "group_num": [
            "recd4GoLuLfoZejbj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2519,
        "id": "reccvXPjm0RbaqxsA",
        "row": 22548,
        "state": "",
        "group": "3339028",
        "group_num": [
            "recXc59m5Jc9nA9Uy"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "id": "reccF5wJqfz0j8326",
        "row": 22525,
        "state": "",
        "group": "",
        "group_num": [
            "recjVyvWdvmpizJtD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1937,
        "id": "recdOXvVGiah6C4qP",
        "row": 22515,
        "state": "",
        "group": "3338229",
        "group_num": [
            "recR4YhwXqVHbhHrG"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recdn2uk98gxn03Og",
        "row": 22517,
        "state": "",
        "group": "3338293",
        "group_num": [
            "recE6BiXkUfZWOo0D"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3021,
        "id": "recpVs7aU6MHlt4E7",
        "row": 22509,
        "state": "",
        "group": "",
        "group_num": [
            "recpHJazTcggC9vIX"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 380,
        "id": "recprcB4UhxLiTZlP",
        "row": 22531,
        "state": "",
        "group": "",
        "group_num": [
            "recd4GoLuLfoZejbj"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recraT7JMAvi6pAfv",
        "row": 22550,
        "state": "",
        "group": "",
        "group_num": [
            "recwpqgEna5SHx8vi"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recshWIjUi2ZBurmb",
        "row": 22526,
        "state": "",
        "group": "",
        "group_num": [
            "recjVyvWdvmpizJtD"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1400,
        "id": "rectUE6HrFGSv5Y2Y",
        "row": 22535,
        "state": "",
        "group": "",
        "group_num": [
            "recUEmx51qhGpASnx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1868,
        "id": "recte0XnivMRq3CIf",
        "row": 22552,
        "state": "",
        "group": "",
        "group_num": [
            "recSBzgTm9iuqdIHF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 860,
        "id": "recvmryYwMHjBEaP1",
        "row": 22536,
        "state": "",
        "group": "",
        "group_num": [
            "recUEmx51qhGpASnx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recytGcc8HMW0hmQT",
        "row": 22541,
        "state": "",
        "group": "3338899",
        "group_num": [
            "rec00IxbdIaLGc8o1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 702,
        "id": "recykO8J06stOoftE",
        "row": 22537,
        "state": "",
        "group": "",
        "group_num": [
            "recUEmx51qhGpASnx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 198,
        "id": "reczE9ngDdOlBTm0J",
        "row": 22545,
        "state": "",
        "group": "",
        "group_num": [
            "reciAg5UhrmxiHquR"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 2101,
        "id": "recBSvo6cWNcOgeJ7",
        "row": 22519,
        "state": "",
        "group": "3338371",
        "group_num": [
            "rec8WLjFARVDr1oVq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 3370,
        "id": "recCAHz9XohbdkV5f",
        "row": 22521,
        "state": "",
        "group": "3338371",
        "group_num": [
            "rec8WLjFARVDr1oVq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recEfmyfl51LSqYjz",
        "row": 22512,
        "state": "",
        "group": "3337780",
        "group_num": [
            "rec6x8DOk1DjesuPb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 693,
        "id": "recJPiVV0G2tpvPdp",
        "row": 22544,
        "state": "",
        "group": "",
        "group_num": [
            "reciAg5UhrmxiHquR"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 762,
        "id": "recKZJQxN1ycLgjzz",
        "row": 22513,
        "state": "",
        "group": "",
        "group_num": [
            "recicPmnu8Dj6PLfC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recKuuATDot0rNXHG",
        "row": 22538,
        "state": "",
        "group": "3338891",
        "group_num": [
            "recxvstyoGPTaOah1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "receCD1mCn5F6s5of",
        "row": 22599,
        "state": "",
        "group": "",
        "group_num": [
            "recryqWhIto1ASFwS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 114,
        "2024": 143,
        "id": "receNc0T2C2BnQ1uP",
        "row": 22561,
        "state": "",
        "group": "",
        "group_num": [
            "rec4I2zfeH8eLWIw9"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 195,
        "id": "recfObG8ffyab5O9a",
        "row": 22573,
        "state": "",
        "group": "",
        "group_num": [
            "reclWy6gGevTUcTB7"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "reckfEMl9PQkTOmgh",
        "row": 22579,
        "state": "",
        "group": "3339745",
        "group_num": [
            "recfqi6Jv4vWxBrw0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 460,
        "id": "recknGTMesijnIVN8",
        "row": 22592,
        "state": "",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recly53LCjxUnjKvz",
        "row": 22577,
        "state": "",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1122,
        "id": "recnA7Gq02ocetUgO",
        "row": 22588,
        "state": "",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3000,
        "id": "recnID7rAz9WAVpaq",
        "row": 22598,
        "state": "",
        "group": "3340056",
        "group_num": [
            "recBy4kBKj1tTd2rD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recnk3qK2hREVbWsn",
        "row": 22568,
        "state": "",
        "group": "3339486",
        "group_num": [
            "recKiGvjUJ8dtl774"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "2023": 328,
        "id": "recPzuS6HXfg8qW9p",
        "row": 22574,
        "state": "",
        "group": "",
        "group_num": [
            "reclWy6gGevTUcTB7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 834,
        "id": "recPnzE9rRsDWT7kB",
        "row": 22584,
        "state": "",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 211,
        "id": "recQfUYYS70Ga601n",
        "row": 22593,
        "state": "",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 233,
        "id": "recSeegaTzF68pOz4",
        "row": 22565,
        "state": "",
        "group": "",
        "group_num": [
            "recQHxY9nIFbzBYyp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2110,
        "id": "recSjs8juxJtxEsya",
        "row": 22578,
        "state": "",
        "group": "3339745",
        "group_num": [
            "recfqi6Jv4vWxBrw0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2123,
        "id": "recWyzUZdyucyC2eo",
        "row": 22557,
        "state": "",
        "group": "3339129",
        "group_num": [
            "recOTzQLd5VriwjeX"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "recW7n9YbgqnSPkze",
        "row": 22581,
        "state": "",
        "group": "3339768",
        "group_num": [
            "reclAN69QrCh0rdPp"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recWkdICVaOs0eNMF",
        "row": 22606,
        "state": "",
        "group": "",
        "group_num": [
            "recgVWhs7CUscvJRi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recXF79HaufX94ZFA",
        "row": 22571,
        "state": "",
        "group": "3339643",
        "group_num": [
            "recMaFfkUSgfVN4EF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 353,
        "id": "recXqr4ULaC1CEs91",
        "row": 22575,
        "state": "",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1457,
        "2023": 1372,
        "id": "recXrG6SswY4ztSQE",
        "row": 22590,
        "state": "",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 192,
        "2024": 403,
        "id": "recYi8cieV0Z4ECfJ",
        "row": 22560,
        "state": "",
        "group": "",
        "group_num": [
            "rec4I2zfeH8eLWIw9"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1660,
        "id": "rec00BIkpTrG1yQWH",
        "row": 22602,
        "state": "",
        "group": "3340178",
        "group_num": [
            "recAiHuSqkCjXO5dL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec0n3p3ihT0l74pz",
        "row": 22601,
        "state": "",
        "group": "",
        "group_num": [
            "recryqWhIto1ASFwS"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 264,
        "id": "rec2QSpPfH6GmM4b5",
        "row": 22566,
        "state": "",
        "group": "",
        "group_num": [
            "recQHxY9nIFbzBYyp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 610,
        "id": "rec43U1LxVJt8opVN",
        "row": 22570,
        "state": "",
        "group": "3339510",
        "group_num": [
            "rec6hNIfI7vcxXvW1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "rec667DUPIdYQiRZB",
        "row": 22583,
        "state": "",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1741,
        "id": "recajhU6YjsvEz8Z1",
        "row": 22582,
        "state": "",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "2024": 217,
        "id": "recbN9761acsamDLf",
        "row": 22562,
        "state": "",
        "group": "",
        "group_num": [
            "rec4I2zfeH8eLWIw9"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 645,
        "id": "recqykNeaNBo48SPs",
        "row": 22603,
        "state": "",
        "group": "3340178",
        "group_num": [
            "recAiHuSqkCjXO5dL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 681,
        "id": "recsHN2fNg93a9Fk7",
        "row": 22600,
        "state": "",
        "group": "",
        "group_num": [
            "recryqWhIto1ASFwS"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recsctrFbv4GBML3E",
        "row": 22587,
        "state": "",
        "group": "3339838",
        "group_num": [
            "recbnAW98eCKwZfwI"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recuWANsW7S0Lznk7",
        "row": 22572,
        "state": "",
        "group": "3339643",
        "group_num": [
            "recMaFfkUSgfVN4EF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 2375,
        "2024": 2101,
        "id": "recurBNFSTTr0XKHy",
        "row": 22591,
        "state": "",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 423,
        "2023": 402,
        "id": "recvEnuBfX3MQhF7v",
        "row": 22589,
        "state": "",
        "group": "3339909",
        "group_num": [
            "recF14Ds3wvyqrHFR"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recxhfcoyhxigN5hd",
        "row": 22559,
        "state": "",
        "group": "",
        "group_num": [
            "reci3GkXHG0Ow9TTZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1920,
        "id": "recyBlL6ArunlX5aS",
        "row": 22604,
        "state": "",
        "group": "",
        "group_num": [
            "reclSTbnYAGe62AtK"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recz1KTkPBxMk7ZJy",
        "row": 22567,
        "state": "",
        "group": "3339400",
        "group_num": [
            "reca586JSJ8hV3VgP"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "2023": 207,
        "id": "reczdLQxDqgqkQTxm",
        "row": 22597,
        "state": "",
        "group": "",
        "group_num": [
            "recsYgmqW5KdGfEyo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 597,
        "id": "recA92UaPwxhxtiOJ",
        "row": 22558,
        "state": "",
        "group": "",
        "group_num": [
            "reci3GkXHG0Ow9TTZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 60,
        "id": "recAaLF27BckBwnPw",
        "row": 22586,
        "state": "",
        "group": "3339838",
        "group_num": [
            "recbnAW98eCKwZfwI"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2040,
        "id": "recBJNwTvVVQZ7bRG",
        "row": 22569,
        "state": "",
        "group": "3339510",
        "group_num": [
            "rec6hNIfI7vcxXvW1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 555,
        "id": "recDYrO8axwZbUDHH",
        "row": 22596,
        "state": "",
        "group": "",
        "group_num": [
            "recsYgmqW5KdGfEyo"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 3000,
        "id": "recDKyvcaU9NGShkn",
        "row": 22594,
        "state": "",
        "group": "3339988",
        "group_num": [
            "recuNc4kPgdBuoUnp"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "id": "recDmfo5HVFBpspnz",
        "row": 22576,
        "state": "",
        "group": "3339741",
        "group_num": [
            "recHA6DRBA8PfbtRC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recFCCAXwbYFZqyim",
        "row": 22564,
        "state": "",
        "group": "3339317",
        "group_num": [
            "recwRY83X3diR3Nw6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2829,
        "id": "recH8QcdWiE4kzUss",
        "row": 22585,
        "state": "",
        "group": "3339801",
        "group_num": [
            "recz8G0j2W7qXbN9W"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "id": "recIF51Rq6S22kjmT",
        "row": 22563,
        "state": "",
        "group": "3339317",
        "group_num": [
            "recwRY83X3diR3Nw6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2419,
        "id": "recIc0G5cjiM8p8fu",
        "row": 22580,
        "state": "",
        "group": "3339768",
        "group_num": [
            "reclAN69QrCh0rdPp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1974,
        "id": "recKeIdl5c7Oql5Hc",
        "row": 22595,
        "state": "",
        "group": "",
        "group_num": [
            "rec4K67FfjmUlEbuY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2300,
        "id": "recNXPvKU0NoTgNJW",
        "row": 22605,
        "state": "",
        "group": "",
        "group_num": [
            "reclSTbnYAGe62AtK"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 721,
        "id": "recf8L2Nx0QA2Nn2D",
        "row": 22633,
        "state": "",
        "group": "3341102",
        "group_num": [
            "reci3ZhSztnj4pPhY"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 438,
        "id": "recgdudyuw6pmp95P",
        "row": 22624,
        "state": "",
        "group": "",
        "group_num": [
            "recWEsKpgJoJTQioC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recicUCd3nVCTtfsy",
        "row": 22623,
        "state": "",
        "group": "",
        "group_num": [
            "recWEsKpgJoJTQioC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1126,
        "id": "recjADFbWhpCfxtMY",
        "row": 22608,
        "state": "",
        "group": "3340311",
        "group_num": [
            "recffZRjMVfe69LwF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 231,
        "id": "recjplOsAm4cfrzUv",
        "row": 22607,
        "state": "",
        "group": "3340282",
        "group_num": [
            "recLwizlAdfNibOfs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 207,
        "id": "reckUaLJtcRKfK0hV",
        "row": 22642,
        "state": "",
        "group": "",
        "group_num": [
            "rec9sCnPBwMb2rcIj"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 694,
        "id": "reckspYToHAO9fxNM",
        "row": 22656,
        "state": "",
        "group": "3341726",
        "group_num": [
            "rec3k87FRdukFSWor"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2538,
        "id": "reckcWP3daTErfwfY",
        "row": 22625,
        "state": "",
        "group": "3341050",
        "group_num": [
            "recMd5mvZaaqcwksL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 596,
        "id": "recmehzJ1y3sPT5ZO",
        "row": 22638,
        "state": "",
        "group": "",
        "group_num": [
            "recADtR0QidK7IBpk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 596,
        "id": "recOuZrxp1cWRiGqQ",
        "row": 22612,
        "state": "",
        "group": "",
        "group_num": [
            "recDEVRITXy8MyjI5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recO2jlUGWtxtd4M7",
        "row": 22627,
        "state": "",
        "group": "",
        "group_num": [
            "rec9fk7NuAaVjk3pI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recP0grt9CVey3Y7C",
        "row": 22640,
        "state": "",
        "group": "",
        "group_num": [
            "recADtR0QidK7IBpk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 225,
        "2024": 217,
        "id": "recP6LqMCGv07gKJG",
        "row": 22611,
        "state": "",
        "group": "",
        "group_num": [
            "recX6dfkWQC1KWS5r"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 170,
        "id": "recP7oRguAhDL6bJU",
        "row": 22613,
        "state": "",
        "group": "",
        "group_num": [
            "recDEVRITXy8MyjI5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 834,
        "id": "recP7o9rztPszMeFi",
        "row": 22641,
        "state": "",
        "group": "",
        "group_num": [
            "recjjQ6iI6Wvw7YuB"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2191,
        "id": "recQOAu2nK3Bto1xd",
        "row": 22635,
        "state": "",
        "group": "3341106",
        "group_num": [
            "recbMisNEiPlKqDVf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 631,
        "id": "recSbsHx060VG4mUj",
        "row": 22616,
        "state": "",
        "group": "",
        "group_num": [
            "rec6fvl3wcGYqnSdn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 593,
        "2023": 490,
        "id": "recTrALIy1N45uv0W",
        "row": 22629,
        "state": "",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 233,
        "id": "recUJRDsX8EZx3XJm",
        "row": 22654,
        "state": "",
        "group": "",
        "group_num": [
            "recnaZBLDlTFCsgMT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2123,
        "id": "recYrAfPipdYB38x1",
        "row": 22628,
        "state": "",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "recZwyd1mFj3XzuAx",
        "row": 22649,
        "state": "",
        "group": "3341266",
        "group_num": [
            "recVUxZ0k4HVxJ7iI"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 81,
        "id": "rec0iAOpqQAjIZFtp",
        "row": 22622,
        "state": "",
        "group": "3340951",
        "group_num": [
            "recAEcHk4pcIfO6wv"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "rec3VZf5REONlYfPN",
        "row": 22644,
        "state": "",
        "group": "3341208",
        "group_num": [
            "recIfkJI1yeZHYNGx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2248,
        "id": "rec3WFkykEQkVoVNK",
        "row": 22647,
        "state": "",
        "group": "3341246",
        "group_num": [
            "recTQkIuxdEaUU5u0"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 263,
        "2024": 263,
        "id": "rec3IJQPERd9pTaQa",
        "row": 22615,
        "state": "",
        "group": "",
        "group_num": [
            "recobXpTYqRkigVEB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 2755,
        "2024": 3000,
        "id": "rec4yiajxWfCh3fTf",
        "row": 22626,
        "state": "",
        "group": "3341050",
        "group_num": [
            "recMd5mvZaaqcwksL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2218,
        "id": "rec6slkAWwtDTiCLN",
        "row": 22632,
        "state": "",
        "group": "3341102",
        "group_num": [
            "reci3ZhSztnj4pPhY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recoxIYY33SsJZWYv",
        "row": 22652,
        "state": "",
        "group": "",
        "group_num": [
            "recKiqwVDBmPrbCbR"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2038,
        "id": "recokqxASwo2t1i17",
        "row": 22653,
        "state": "",
        "group": "",
        "group_num": [
            "recKiqwVDBmPrbCbR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "recp56b55ln9pLXkX",
        "row": 22639,
        "state": "",
        "group": "",
        "group_num": [
            "recADtR0QidK7IBpk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recqPbkOEf1LK3aZt",
        "row": 22648,
        "state": "",
        "group": "3341266",
        "group_num": [
            "recVUxZ0k4HVxJ7iI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 3088,
        "id": "recrcc63FcpVP3hjq",
        "row": 22631,
        "state": "",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recshLLQ1CCxX4wG3",
        "row": 22610,
        "state": "",
        "group": "3340334",
        "group_num": [
            "reccrVYOeWZHZFcWe"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 262,
        "id": "rectGVj8skf7dxbgD",
        "row": 22614,
        "state": "",
        "group": "",
        "group_num": [
            "recobXpTYqRkigVEB"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1969,
        "id": "rectLHcDQbYU9Hnm2",
        "row": 22637,
        "state": "",
        "group": "",
        "group_num": [
            "recADtR0QidK7IBpk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 194,
        "id": "recti7kqhmo3ui9IJ",
        "row": 22620,
        "state": "",
        "group": "",
        "group_num": [
            "recIP4ix89HK1E0Vd"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1520,
        "id": "recxSlNFvRXOcKRcq",
        "row": 22619,
        "state": "",
        "group": "",
        "group_num": [
            "recIP4ix89HK1E0Vd"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2575,
        "id": "recxYns2sSMCOfrOj",
        "row": 22655,
        "state": "",
        "group": "3341726",
        "group_num": [
            "rec3k87FRdukFSWor"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1864,
        "2023": 1882,
        "id": "reczWQfKfSLxWGWiH",
        "row": 22650,
        "state": "",
        "group": "3341302",
        "group_num": [
            "recWn9G0RbSHAYadq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 318,
        "2023": 244,
        "id": "recAVpnEXRUJIq6lN",
        "row": 22630,
        "state": "",
        "group": "3341058",
        "group_num": [
            "rec3y4D2ebcURP2Yn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 401,
        "2023": 438,
        "id": "recC5iWPkygzLHAja",
        "row": 22643,
        "state": "",
        "group": "",
        "group_num": [
            "rec9sCnPBwMb2rcIj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 160,
        "2023": 219,
        "2024": 261,
        "id": "recCncWR0dgkeSMDw",
        "row": 22618,
        "state": "",
        "group": "",
        "group_num": [
            "rec6fvl3wcGYqnSdn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2218,
        "id": "recDxJ6PQjum5iQAI",
        "row": 22634,
        "state": "",
        "group": "3341102",
        "group_num": [
            "reci3ZhSztnj4pPhY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recDmFRJopZNH8GgQ",
        "row": 22609,
        "state": "",
        "group": "3340334",
        "group_num": [
            "reccrVYOeWZHZFcWe"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3891,
        "id": "recEwBrA2A3WWiYBh",
        "row": 22636,
        "state": "",
        "group": "3341106",
        "group_num": [
            "recbMisNEiPlKqDVf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 182,
        "2024": 178,
        "id": "recHhyRzFfLcJu5kJ",
        "row": 22617,
        "state": "",
        "group": "",
        "group_num": [
            "rec6fvl3wcGYqnSdn"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recIsx8rd8jjtYIQT",
        "row": 22646,
        "state": "",
        "group": "3341246",
        "group_num": [
            "recTQkIuxdEaUU5u0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 565,
        "id": "recJT0U6k4nUdVCaX",
        "row": 22645,
        "state": "",
        "group": "3341208",
        "group_num": [
            "recIfkJI1yeZHYNGx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recJjsIVArAJVIVE0",
        "row": 22621,
        "state": "",
        "group": "",
        "group_num": [
            "reckHEZh0hEqSaOOc"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recNG6qUgpUkV3Fai",
        "row": 22651,
        "state": "",
        "group": "3341302",
        "group_num": [
            "recWn9G0RbSHAYadq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1933,
        "2023": 1902,
        "2024": 2360,
        "id": "receROckbPvAt1zwt",
        "row": 22659,
        "state": "",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2047,
        "id": "rechccKwDRuiZ5ZpY",
        "row": 22658,
        "state": "",
        "group": "3341726",
        "group_num": [
            "rec3k87FRdukFSWor"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "reciJaoercSn6wd0H",
        "row": 22695,
        "state": "",
        "group": "",
        "group_num": [
            "recvz0kDGEv4RHiDj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 184,
        "id": "reckvBhXj0tRTOal5",
        "row": 22671,
        "state": "",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "reclzPP4L4iCTnYJy",
        "row": 22674,
        "state": "",
        "group": "3342042",
        "group_num": [
            "recjSPzog5ckfo7gM"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "reclghEAhPjOcgATK",
        "row": 22697,
        "state": "",
        "group": "3342810",
        "group_num": [
            "recwCZdeUNlICBQzs"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "reclr94hyWYUQa4Wk",
        "row": 22689,
        "state": "",
        "group": "",
        "group_num": [
            "reciTOKFsScqaMeBZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recmJCXVRdPkfaMtw",
        "row": 22678,
        "state": "",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2221,
        "id": "recmlvIbAxx02katC",
        "row": 22691,
        "state": "",
        "group": "3342331",
        "group_num": [
            "rec8oyZNH1nRvD6zN"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 216,
        "id": "recPUubJ4rQGe2IyS",
        "row": 22662,
        "state": "",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 341,
        "2023": 199,
        "id": "recPVKG2lvmp3CdZ8",
        "row": 22702,
        "state": "",
        "group": "",
        "group_num": [
            "recchfBkqLdtNwcc5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 24,
        "2023": 91,
        "2024": 60,
        "id": "recPZJLnnhkHBl8Cn",
        "row": 22661,
        "state": "",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 257,
        "2024": 500,
        "id": "recQPIVOl3kFfXcqc",
        "row": 22660,
        "state": "",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "recRD6fVAR7TUGFU1",
        "row": 22677,
        "state": "",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2301,
        "id": "recSdsUAjLZb6im3g",
        "row": 22669,
        "state": "",
        "group": "3342007",
        "group_num": [
            "recOqxCQNBQPooKjC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 369,
        "id": "recShaHfSh3p7CFTJ",
        "row": 22672,
        "state": "",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2750,
        "id": "rec0CEYLneBchxBCY",
        "row": 22694,
        "state": "",
        "group": "3342670",
        "group_num": [
            "recla1Z2mhBVURsMC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "rec1LGESnGd1uyFQp",
        "row": 22700,
        "state": "",
        "group": "",
        "group_num": [
            "recchfBkqLdtNwcc5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 645,
        "id": "rec2NUr3ltVmU5aeH",
        "row": 22705,
        "state": "",
        "group": "",
        "group_num": [
            "recvdfKDvcDyMwJLM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "2024": 1668,
        "id": "rec3jYhjek4pKSP49",
        "row": 22706,
        "state": "",
        "group": "3342950",
        "group_num": [
            "recKGWogCu9L1GpZr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "2023": 211,
        "2024": 211,
        "id": "rec48vozXy7QWsbGb",
        "row": 22699,
        "state": "",
        "group": "3342810",
        "group_num": [
            "recwCZdeUNlICBQzs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 207,
        "id": "rec50UPOAdA2fEIXD",
        "row": 22701,
        "state": "",
        "group": "",
        "group_num": [
            "recchfBkqLdtNwcc5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1015,
        "id": "rec56D6qDNaFvGS2C",
        "row": 22667,
        "state": "",
        "group": "3341974",
        "group_num": [
            "rec6XUPhRDOPDVfA1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rec5jrKn4cpEQ5nmQ",
        "row": 22676,
        "state": "",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1942,
        "id": "rec6Pus4IIm4cDNy2",
        "row": 22693,
        "state": "",
        "group": "3342391",
        "group_num": [
            "recFVeVnLOpbnBdvc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1869,
        "2023": 1775,
        "id": "rec7hlp7D3DkMo9Nc",
        "row": 22673,
        "state": "",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 460,
        "2024": 460,
        "id": "rec7iBUhsNMwPGFUk",
        "row": 22698,
        "state": "",
        "group": "3342810",
        "group_num": [
            "recwCZdeUNlICBQzs"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 493,
        "id": "rec8AIXvcE4sHwQji",
        "row": 22681,
        "state": "",
        "group": "",
        "group_num": [
            "recCmEyirWzULIZNM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 290,
        "id": "reca4gVS20zQtMCwZ",
        "row": 22675,
        "state": "",
        "group": "3342042",
        "group_num": [
            "recjSPzog5ckfo7gM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recamRe5oIM4dCy38",
        "row": 22668,
        "state": "",
        "group": "3342007",
        "group_num": [
            "recOqxCQNBQPooKjC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 535,
        "id": "recbxgjPHTXRidDqn",
        "row": 22665,
        "state": "",
        "group": "3341944",
        "group_num": [
            "recaYnJDG9JXSrqS9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 772,
        "id": "reccGGfNHU4nDZJCK",
        "row": 22682,
        "state": "",
        "group": "",
        "group_num": [
            "recCmEyirWzULIZNM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 366,
        "id": "recoqiYX3Gn4JIQqT",
        "row": 22657,
        "state": "",
        "group": "3341726",
        "group_num": [
            "rec3k87FRdukFSWor"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "2023": 326,
        "id": "recp9kyIRsXxYgo9d",
        "row": 22664,
        "state": "",
        "group": "3341833",
        "group_num": [
            "recwDH9O0Ctcxx7YV"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1646,
        "id": "recvKpUHsc719SbQl",
        "row": 22692,
        "state": "",
        "group": "3342331",
        "group_num": [
            "rec8oyZNH1nRvD6zN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recwO50pF7vWaops8",
        "row": 22703,
        "state": "",
        "group": "3342837",
        "group_num": [
            "recUlqitKiQAuxe16"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 749,
        "id": "recxM4wMSuGjACaad",
        "row": 22688,
        "state": "",
        "group": "",
        "group_num": [
            "reclCzZVY74mBI2Gc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2198,
        "id": "recyYwtwHgzKJ1OXt",
        "row": 22686,
        "state": "",
        "group": "",
        "group_num": [
            "reclCzZVY74mBI2Gc"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recyqfkDZQtib8AZb",
        "row": 22666,
        "state": "",
        "group": "3341974",
        "group_num": [
            "rec6XUPhRDOPDVfA1"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1148,
        "id": "recAuWBfCHMV0fY91",
        "row": 22696,
        "state": "",
        "group": "3342799",
        "group_num": [
            "recnk49OMOqii0u9T"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "2024": 473,
        "id": "recAwZfrOArVnrTFx",
        "row": 22687,
        "state": "",
        "group": "",
        "group_num": [
            "reclCzZVY74mBI2Gc"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "id": "recEBOrZwHBK2k0pk",
        "row": 22690,
        "state": "",
        "group": "",
        "group_num": [
            "reciTOKFsScqaMeBZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recFp418fGgR6RQDG",
        "row": 22683,
        "state": "",
        "group": "",
        "group_num": [
            "reclw9ubAYQ1MJc0Q"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recGp09uEJlcTGNyJ",
        "row": 22684,
        "state": "",
        "group": "",
        "group_num": [
            "reclw9ubAYQ1MJc0Q"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1989,
        "2023": 2526,
        "id": "recHWzko4teaxcrIi",
        "row": 22663,
        "state": "",
        "group": "3341728",
        "group_num": [
            "recGXhjjhgix0Xy1t"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1371,
        "id": "recH8ihCgAhmk8hdn",
        "row": 22680,
        "state": "",
        "group": "",
        "group_num": [
            "recCmEyirWzULIZNM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "recI5qrVpO5HvpgxI",
        "row": 22704,
        "state": "",
        "group": "3342837",
        "group_num": [
            "recUlqitKiQAuxe16"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2489,
        "2023": 1723,
        "id": "recK4mYT5vndzzWxL",
        "row": 22670,
        "state": "",
        "group": "3342010",
        "group_num": [
            "recWsesrVeTVRpHc5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2022,
        "id": "recLpRkN6IprUsIfU",
        "row": 22679,
        "state": "",
        "group": "3342099",
        "group_num": [
            "recHZdW7tVKFpAu5H"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recNzwLRVhqtCw6bZ",
        "row": 22685,
        "state": "",
        "group": "3342187",
        "group_num": [
            "recteE5Mo7SMpTGRC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recee2eOINTIFU3BI",
        "row": 22748,
        "state": "",
        "group": "",
        "group_num": [
            "recdKWBEgJesLxWZe"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1158,
        "id": "recft3SIohjqrDuGz",
        "row": 22714,
        "state": "",
        "group": "3343064",
        "group_num": [
            "rect67Sy26eo7bKiI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1602,
        "id": "recmzA5v0GcuChuSX",
        "row": 22746,
        "state": "",
        "group": "",
        "group_num": [
            "recDLaEueq7MSJV2U"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2219,
        "id": "recOi89ZJPcx4v2pt",
        "row": 22737,
        "state": "",
        "group": "3343353",
        "group_num": [
            "recEGMYWyHJstSaW5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recQWEB0aLq42rwpo",
        "row": 22753,
        "state": "",
        "group": "",
        "group_num": [
            "recznusRlDz6RYSzf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "2024": 22,
        "id": "recRYpFy3pKD3EYEj",
        "row": 22709,
        "state": "",
        "group": "",
        "group_num": [
            "recUY5cBzB14wQ9A5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "2023": 812,
        "id": "recTYrLtx7T1eHMPq",
        "row": 22749,
        "state": "",
        "group": "",
        "group_num": [
            "recdKWBEgJesLxWZe"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 208,
        "id": "recTkYDMSBUgLtSpk",
        "row": 22719,
        "state": "",
        "group": "",
        "group_num": [
            "rec0laGegKA2xfbmu"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 302,
        "id": "recUdQ4flmhSDVIoW",
        "row": 22713,
        "state": "",
        "group": "3343052",
        "group_num": [
            "recSD4G0E4TBy9X0z"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2036,
        "id": "recVOyRb0WTnAFzF5",
        "row": 22751,
        "state": "",
        "group": "",
        "group_num": [
            "recDRka1zNkjcEIee"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recWIgXvKzq3usPIP",
        "row": 22707,
        "state": "",
        "group": "3342952",
        "group_num": [
            "rec4pXG0ZyWE3u4oE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 356,
        "id": "recXdJHxxK15wKAGK",
        "row": 22720,
        "state": "",
        "group": "",
        "group_num": [
            "rec0laGegKA2xfbmu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2678,
        "id": "recZWBahwk6x8UuY9",
        "row": 22755,
        "state": "",
        "group": "3343713",
        "group_num": [
            "rec5JxTpyZtvbdXzx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2277,
        "id": "recZa2UpFse7c4TKQ",
        "row": 22712,
        "state": "",
        "group": "",
        "group_num": [
            "recxCgWGhPEhuKdYk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "id": "recZhrRb5SWB78fWz",
        "row": 22722,
        "state": "",
        "group": "3343119",
        "group_num": [
            "recvfbz61vSgmF6M8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "rec18PNFkTt7RU9sX",
        "row": 22732,
        "state": "",
        "group": "3343336",
        "group_num": [
            "rechg7XXqUzMJxcXz"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 2991,
        "id": "rec2LMGTvGB8JsBFB",
        "row": 22708,
        "state": "",
        "group": "3342952",
        "group_num": [
            "rec4pXG0ZyWE3u4oE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "rec5LpBmQ6fxP1ohd",
        "row": 22745,
        "state": "",
        "group": "3343498",
        "group_num": [
            "rec7RkcFexmJWLmwT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "id": "rec5Q3wAv4E1rHNwY",
        "row": 22716,
        "state": "",
        "group": "",
        "group_num": [
            "recPnvVCSngY4K3q0"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2252,
        "id": "rec8GNbQwzsU5Wzmq",
        "row": 22739,
        "state": "",
        "group": "3343439",
        "group_num": [
            "recdY7bPDn3hylacF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 281,
        "2023": 289,
        "2024": 290,
        "id": "recbOEcWeEDJYrsRQ",
        "row": 22741,
        "state": "",
        "group": "3343452",
        "group_num": [
            "rec7dwYfvNYyUajH5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "reccn8VO6YQA4NVWx",
        "row": 22728,
        "state": "",
        "group": "3343249",
        "group_num": [
            "recM7UpyiBOOTyMFJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recdd33pxk0QxJhpF",
        "row": 22710,
        "state": "",
        "group": "",
        "group_num": [
            "recUY5cBzB14wQ9A5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2188,
        "id": "recpQfMTKufIEB3U3",
        "row": 22736,
        "state": "",
        "group": "3343353",
        "group_num": [
            "recEGMYWyHJstSaW5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recpfnE2pNsJgZMEc",
        "row": 22750,
        "state": "",
        "group": "",
        "group_num": [
            "recdKWBEgJesLxWZe"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1687,
        "id": "recqfQf8gGcOm7OAt",
        "row": 22747,
        "state": "",
        "group": "",
        "group_num": [
            "recDLaEueq7MSJV2U"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1938,
        "id": "recrYK2PNWJdeeoTK",
        "row": 22726,
        "state": "",
        "group": "",
        "group_num": [
            "rec8oTz8K1MB0NX5m"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 143,
        "id": "recs97XyVP8WR7BDf",
        "row": 22723,
        "state": "",
        "group": "3343119",
        "group_num": [
            "recvfbz61vSgmF6M8"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "rect34rwwrpz1ZYnx",
        "row": 22752,
        "state": "",
        "group": "",
        "group_num": [
            "recznusRlDz6RYSzf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2133,
        "id": "recuH7zTzVYTWLYuy",
        "row": 22756,
        "state": "",
        "group": "",
        "group_num": [
            "recnFgOZqrQoaJZ8J"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2772,
        "id": "recvHHFGy1bze8SU7",
        "row": 22731,
        "state": "",
        "group": "",
        "group_num": [
            "recR0lq1YinWAr5Vw"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 418,
        "id": "recwFmveoTDPGPTat",
        "row": 22718,
        "state": "",
        "group": "",
        "group_num": [
            "rec0laGegKA2xfbmu"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 990,
        "id": "recxWVfXZQqR0sJev",
        "row": 22733,
        "state": "",
        "group": "3343336",
        "group_num": [
            "rechg7XXqUzMJxcXz"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "2023": 2642,
        "id": "reczr0cXUr136c9IC",
        "row": 22730,
        "state": "",
        "group": "3343277",
        "group_num": [
            "recjEwY7ECARe5Dl6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "id": "recAM49srjbggdNGY",
        "row": 22721,
        "state": "",
        "group": "3343119",
        "group_num": [
            "recvfbz61vSgmF6M8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 3000,
        "id": "recA8EJtANoMAleLK",
        "row": 22742,
        "state": "",
        "group": "3343452",
        "group_num": [
            "rec7dwYfvNYyUajH5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2384,
        "id": "recBKEMf3FBdUHsBG",
        "row": 22734,
        "state": "",
        "group": "3343336",
        "group_num": [
            "rechg7XXqUzMJxcXz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2217,
        "id": "recBbAzOtPmqTSla0",
        "row": 22754,
        "state": "",
        "group": "3343713",
        "group_num": [
            "rec5JxTpyZtvbdXzx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2327,
        "id": "recCZDovM0aZ9hql3",
        "row": 22735,
        "state": "",
        "group": "3343339",
        "group_num": [
            "recjWkeKJAVE5uWZR"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recCn8beNuXoIXoRp",
        "row": 22727,
        "state": "",
        "group": "3343249",
        "group_num": [
            "recM7UpyiBOOTyMFJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1836,
        "id": "recDcHxvGFrvjRm3U",
        "row": 22717,
        "state": "",
        "group": "",
        "group_num": [
            "rec0laGegKA2xfbmu"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recEibafjuQivSkwV",
        "row": 22724,
        "state": "",
        "group": "3343171",
        "group_num": [
            "recHK8NLfh56wF2kr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "2023": 201,
        "2024": 172,
        "id": "recFy8G2rv9rdglUV",
        "row": 22743,
        "state": "",
        "group": "",
        "group_num": [
            "recnmW1DyQD5h09p1"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3734,
        "2023": 2538,
        "id": "recFQxa1flyoj9dLY",
        "row": 22711,
        "state": "",
        "group": "",
        "group_num": [
            "recxCgWGhPEhuKdYk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2280,
        "id": "recHp2bKtoL4HBGxn",
        "row": 22738,
        "state": "",
        "group": "3343439",
        "group_num": [
            "recdY7bPDn3hylacF"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 322,
        "2023": 192,
        "2024": 157,
        "id": "recISQYeCYSnJYPE5",
        "row": 22744,
        "state": "",
        "group": "",
        "group_num": [
            "recnmW1DyQD5h09p1"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recISW19uLNYLcUqT",
        "row": 22729,
        "state": "",
        "group": "3343277",
        "group_num": [
            "recjEwY7ECARe5Dl6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "2023": 2917,
        "id": "recKZiFuhVz6cFp2Z",
        "row": 22725,
        "state": "",
        "group": "3343171",
        "group_num": [
            "recHK8NLfh56wF2kr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1990,
        "id": "recLLTv6ppNU6xqd8",
        "row": 22715,
        "state": "",
        "group": "3343064",
        "group_num": [
            "rect67Sy26eo7bKiI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 77,
        "id": "recMgVePYQLy6uii2",
        "row": 22740,
        "state": "",
        "group": "3343452",
        "group_num": [
            "rec7dwYfvNYyUajH5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "receV2RrELDJCZSn4",
        "row": 22801,
        "state": "",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recfKv3P8dfajxpKo",
        "row": 22789,
        "state": "",
        "group": "",
        "group_num": [
            "recLSP3bti7LZo6QN"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recif4nncNKDNXpOM",
        "row": 22795,
        "state": "",
        "group": "",
        "group_num": [
            "recejxDc98gxQSmdc"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "reclCDIPwPoDcoXNk",
        "row": 22783,
        "state": "",
        "group": "",
        "group_num": [
            "recfT7t4oxemIPaf7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recms4HhFbHOtiD2A",
        "row": 22758,
        "state": "",
        "group": "3343794",
        "group_num": [
            "recMx0jD79cR8zJIa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recnt6kcKrSAyGAWE",
        "row": 22792,
        "state": "",
        "group": "3344413",
        "group_num": [
            "recDsTStVM1cIoN7C"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2818,
        "id": "recnmDciVzaQ2Tqj3",
        "row": 22767,
        "state": "",
        "group": "3343917",
        "group_num": [
            "recBQPMsdp3eF8uoB"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 119,
        "id": "recnmkrWFMna8qj33",
        "row": 22794,
        "state": "",
        "group": "",
        "group_num": [
            "recejxDc98gxQSmdc"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recRFFYHbOM6qc2do",
        "row": 22778,
        "state": "",
        "group": "",
        "group_num": [
            "recVOA5qtRUf4iYVN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 575,
        "id": "recRLwFtpkGZWjO5S",
        "row": 22780,
        "state": "",
        "group": "3344292",
        "group_num": [
            "recSMGXxihRmItinC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2033,
        "id": "recSiPkkRnDCMGxUb",
        "row": 22770,
        "state": "",
        "group": "",
        "group_num": [
            "recg09wc6FCUFmPfa"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1649,
        "id": "recTNpbJn8fZHbXd3",
        "row": 22787,
        "state": "",
        "group": "3344371",
        "group_num": [
            "rec7YvyKMiZAkyRB4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 341,
        "id": "recTggW3rD71uy8PU",
        "row": 22806,
        "state": "",
        "group": "",
        "group_num": [
            "recMzsjfoqEV6j7fc"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "id": "recUcdlqG1X4jirDK",
        "row": 22760,
        "state": "",
        "group": "3343801",
        "group_num": [
            "reczm9G3PCtwgEMe8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 208,
        "id": "recVUaExePaYQYox3",
        "row": 22805,
        "state": "",
        "group": "",
        "group_num": [
            "recMzsjfoqEV6j7fc"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2672,
        "2024": 1734,
        "id": "recXVbV3dYJ96MhCf",
        "row": 22779,
        "state": "",
        "group": "3344292",
        "group_num": [
            "recSMGXxihRmItinC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 575,
        "id": "recXol68tQgFeokgh",
        "row": 22788,
        "state": "",
        "group": "3344371",
        "group_num": [
            "rec7YvyKMiZAkyRB4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 159,
        "id": "rec1T9WD1knYPC6Cu",
        "row": 22773,
        "state": "",
        "group": "",
        "group_num": [
            "recTm2bGPwEtSvPA3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "id": "rec2hOdVZYgcyOeid",
        "row": 22791,
        "state": "",
        "group": "",
        "group_num": [
            "recLSP3bti7LZo6QN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3019,
        "2023": 1841,
        "2024": 762,
        "id": "rec3vgTYYl5iv6oiW",
        "row": 22781,
        "state": "",
        "group": "3344292",
        "group_num": [
            "recSMGXxihRmItinC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 700,
        "id": "rec3HxOoj6AIcYTLN",
        "row": 22761,
        "state": "",
        "group": "3343801",
        "group_num": [
            "reczm9G3PCtwgEMe8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "rec4QHaj4hCMGN55w",
        "row": 22804,
        "state": "",
        "group": "",
        "group_num": [
            "recMzsjfoqEV6j7fc"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "rec8AkAGyfWjSfF2y",
        "row": 22759,
        "state": "",
        "group": "3343794",
        "group_num": [
            "recMx0jD79cR8zJIa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1721,
        "id": "rec8PNBbexoCKJ6uy",
        "row": 22803,
        "state": "",
        "group": "",
        "group_num": [
            "recMzsjfoqEV6j7fc"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 763,
        "id": "rec9Yo1zD1EeHvKuB",
        "row": 22764,
        "state": "",
        "group": "",
        "group_num": [
            "recVmShEzXwaDe7XW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 2315,
        "id": "reca0dP6RNDFxv5bN",
        "row": 22802,
        "state": "",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1417,
        "id": "recbZtr0iG1SNQac2",
        "row": 22775,
        "state": "",
        "group": "",
        "group_num": [
            "rec4id24atoy4QBFQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recbtUmVCzOwQLe1L",
        "row": 22793,
        "state": "",
        "group": "3344413",
        "group_num": [
            "recDsTStVM1cIoN7C"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3075,
        "id": "recdWI5FmcAnqQ92D",
        "row": 22774,
        "state": "",
        "group": "3343963",
        "group_num": [
            "recSyyoEFI5vr5Be3"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1636,
        "id": "recpkGaJe9OL7zZ1I",
        "row": 22757,
        "state": "",
        "group": "",
        "group_num": [
            "recnlWP675XfStjLr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recq30BPakd2kf9mC",
        "row": 22769,
        "state": "",
        "group": "3343925",
        "group_num": [
            "recwTnKGvs1s125BL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 974,
        "id": "recrH4xBaIwzFyFME",
        "row": 22786,
        "state": "",
        "group": "",
        "group_num": [
            "recXkkn97vyFiubhb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 2919,
        "2024": 2643,
        "id": "recsWRWjoZfdG5O2Z",
        "row": 22798,
        "state": "",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1054,
        "id": "recsKN1RIViJsdOh7",
        "row": 22784,
        "state": "",
        "group": "",
        "group_num": [
            "recXkkn97vyFiubhb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "id": "recucuwyrUBRZP8wx",
        "row": 22772,
        "state": "",
        "group": "",
        "group_num": [
            "recTm2bGPwEtSvPA3"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2098,
        "id": "recuezI49arZVG7hc",
        "row": 22777,
        "state": "",
        "group": "",
        "group_num": [
            "recwQwIoU3QkGRTK2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 577,
        "id": "recwJrPfE8aTNrEV6",
        "row": 22762,
        "state": "",
        "group": "",
        "group_num": [
            "recGWP5thOPhZSp1K"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 295,
        "id": "recwrmJKP14WYez6h",
        "row": 22766,
        "state": "",
        "group": "3343914",
        "group_num": [
            "recP8bISxltK1dXkX"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "recBzLqr6PscF80tj",
        "row": 22768,
        "state": "",
        "group": "3343925",
        "group_num": [
            "recwTnKGvs1s125BL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 236,
        "id": "recB2orKviSbT0lTv",
        "row": 22771,
        "state": "",
        "group": "",
        "group_num": [
            "recg09wc6FCUFmPfa"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 299,
        "id": "recCvn5bnrk6vninW",
        "row": 22763,
        "state": "",
        "group": "",
        "group_num": [
            "recGWP5thOPhZSp1K"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1021,
        "2024": 472,
        "id": "recHXQIzaQPBdSTBR",
        "row": 22799,
        "state": "",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 64,
        "2023": 62,
        "id": "recHIe2SuHYDNTXWX",
        "row": 22800,
        "state": "",
        "group": "3344445",
        "group_num": [
            "recck0UCGZ7iLuHXx"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2267,
        "id": "recIC9ldsfPvDKpiJ",
        "row": 22782,
        "state": "",
        "group": "",
        "group_num": [
            "rec2pkX0HqmvMHcEa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1017,
        "id": "recI6kkf6j099QchQ",
        "row": 22790,
        "state": "",
        "group": "",
        "group_num": [
            "recLSP3bti7LZo6QN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recJbRVR3NTepVCf3",
        "row": 22776,
        "state": "",
        "group": "",
        "group_num": [
            "recwQwIoU3QkGRTK2"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 554,
        "id": "recLHNWLrEtuAibqh",
        "row": 22796,
        "state": "",
        "group": "3344443",
        "group_num": [
            "reckkBMebntcXW3wm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 575,
        "id": "recLi3NEJSm5dq3n3",
        "row": 22785,
        "state": "",
        "group": "",
        "group_num": [
            "recXkkn97vyFiubhb"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 920,
        "id": "recMyoPn8fTkizmHx",
        "row": 22765,
        "state": "",
        "group": "3343914",
        "group_num": [
            "recP8bISxltK1dXkX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1792,
        "id": "recNZI5nECjmCQOfZ",
        "row": 22797,
        "state": "",
        "group": "3344443",
        "group_num": [
            "reckkBMebntcXW3wm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3243,
        "id": "recejKXnsPh2nnSYC",
        "row": 22844,
        "state": "",
        "group": "",
        "group_num": [
            "rec6PAJiN3OhLNIl4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3740,
        "id": "recfAwhXAJfqvUAit",
        "row": 22831,
        "state": "",
        "group": "3344673",
        "group_num": [
            "recsZ8Yw5iRafqJ9h"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1162,
        "id": "recggiE7o5gPCrUVG",
        "row": 22854,
        "state": "",
        "group": "3345160",
        "group_num": [
            "recTrANPU1LANzIcY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2509,
        "id": "rechdUsP0JG8KpQY0",
        "row": 22849,
        "state": "",
        "group": "",
        "group_num": [
            "recvzc1vUxewDf0bn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1796,
        "id": "rechgoQehxjfiRsuk",
        "row": 22816,
        "state": "",
        "group": "",
        "group_num": [
            "recywb2A2lr4jgJNR"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 192,
        "id": "reciv1Ibmdy5kxZyM",
        "row": 22852,
        "state": "",
        "group": "",
        "group_num": [
            "recQ264vIrOEUdDXX"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "2023": 1125,
        "id": "recl2DHwn24jL0qh5",
        "row": 22822,
        "state": "",
        "group": "3344535",
        "group_num": [
            "recCzcBhi5mHHL7ay"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 276,
        "id": "recnS9cMMrT8Sc2db",
        "row": 22811,
        "state": "",
        "group": "3344505",
        "group_num": [
            "recNWKdXMVsNG8kA5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "2024": 280,
        "id": "recOUnEI0qjsOPptT",
        "row": 22851,
        "state": "",
        "group": "3345143",
        "group_num": [
            "recxDFkmAgbKOc88k"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2013,
        "id": "recO1pypkYLMzaX1o",
        "row": 22828,
        "state": "",
        "group": "",
        "group_num": [
            "recNZaGAJkS6SNKSc"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2493,
        "id": "recOJSlp4C9MjmaeL",
        "row": 22846,
        "state": "",
        "group": "",
        "group_num": [
            "recvzc1vUxewDf0bn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 708,
        "id": "recQJDXMg8srOjKdF",
        "row": 22825,
        "state": "",
        "group": "3344578",
        "group_num": [
            "recEaZgsHhuNPVd11"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recSVPVWR2j5XJjyk",
        "row": 22837,
        "state": "",
        "group": "",
        "group_num": [
            "recjPVerfrsvDsidu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 295,
        "id": "recUFXVDHVpoGNgeA",
        "row": 22821,
        "state": "",
        "group": "3344535",
        "group_num": [
            "recCzcBhi5mHHL7ay"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recVdtSFTKQ6D4c8H",
        "row": 22808,
        "state": "",
        "group": "",
        "group_num": [
            "rec5M6uvWDPuyCrdI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2864,
        "id": "recWjtBM9M0iujB8a",
        "row": 22845,
        "state": "",
        "group": "",
        "group_num": [
            "rec6PAJiN3OhLNIl4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 143,
        "2024": 60,
        "id": "recXzpRIt5dlISpsn",
        "row": 22850,
        "state": "",
        "group": "3345143",
        "group_num": [
            "recxDFkmAgbKOc88k"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1754,
        "id": "recXfqjd5bpkk6wgF",
        "row": 22835,
        "state": "",
        "group": "",
        "group_num": [
            "recjPVerfrsvDsidu"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "recYj8ifr8MpImKOr",
        "row": 22818,
        "state": "",
        "group": "",
        "group_num": [
            "recywb2A2lr4jgJNR"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "rec0UxhbbYJqIRLPq",
        "row": 22841,
        "state": "",
        "group": "3344824",
        "group_num": [
            "recciAYH8gufII9nq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "id": "rec0zXG2qag6yucEU",
        "row": 22815,
        "state": "",
        "group": "3344521",
        "group_num": [
            "recBx1KLfdFsO7Jpo"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rec0gzBKOF9qqdBvb",
        "row": 22842,
        "state": "",
        "group": "",
        "group_num": [
            "recg2llLPmJcSDzGL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec27lIT8CrnVq252",
        "row": 22838,
        "state": "",
        "group": "",
        "group_num": [
            "recjPVerfrsvDsidu"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 597,
        "id": "rec3DbrFEIY4cCmRM",
        "row": 22855,
        "state": "",
        "group": "3345160",
        "group_num": [
            "recTrANPU1LANzIcY"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1917,
        "id": "rec4e5sEC3BrZn1vE",
        "row": 22813,
        "state": "",
        "group": "",
        "group_num": [
            "rec7Hw1GbL9ThNnlt"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "id": "rec7H6JaVZQBez9cx",
        "row": 22840,
        "state": "",
        "group": "3344824",
        "group_num": [
            "recciAYH8gufII9nq"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 290,
        "id": "rec7HqeXQxXJ1oCz7",
        "row": 22823,
        "state": "",
        "group": "",
        "group_num": [
            "rec9OW6KTcbhJhcil"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2305,
        "2023": 2287,
        "id": "rec72dJUv5U2c73gG",
        "row": 22824,
        "state": "",
        "group": "3344578",
        "group_num": [
            "recEaZgsHhuNPVd11"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 187,
        "id": "rec8u5U9Z0z9SD3LH",
        "row": 22820,
        "state": "",
        "group": "",
        "group_num": [
            "recodew7fg76RJomd"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 949,
        "id": "reca1BpQBmG5bntA9",
        "row": 22836,
        "state": "",
        "group": "",
        "group_num": [
            "recjPVerfrsvDsidu"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "reco2m44d7JR66uGS",
        "row": 22807,
        "state": "",
        "group": "",
        "group_num": [
            "rec5M6uvWDPuyCrdI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "reco74gn7amO0mJRl",
        "row": 22812,
        "state": "",
        "group": "3344505",
        "group_num": [
            "recNWKdXMVsNG8kA5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "id": "recrrOgYY3XSYA7lq",
        "row": 22839,
        "state": "",
        "group": "3344824",
        "group_num": [
            "recciAYH8gufII9nq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 674,
        "id": "recuBXk8HdOfmkrww",
        "row": 22848,
        "state": "",
        "group": "",
        "group_num": [
            "recvzc1vUxewDf0bn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 597,
        "id": "recvV9R6RgR7LGEY1",
        "row": 22856,
        "state": "",
        "group": "",
        "group_num": [
            "recUEKm4ErBwbaYAC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2366,
        "2023": 2366,
        "id": "recvOAfWwpfQot0W5",
        "row": 22827,
        "state": "",
        "group": "3344578",
        "group_num": [
            "recEaZgsHhuNPVd11"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 244,
        "id": "recw1a3JZJe3g6rJE",
        "row": 22810,
        "state": "",
        "group": "3344505",
        "group_num": [
            "recNWKdXMVsNG8kA5"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 805,
        "id": "recw95LxxGhu6AAI2",
        "row": 22847,
        "state": "",
        "group": "",
        "group_num": [
            "recvzc1vUxewDf0bn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 554,
        "id": "recyhtoOULnsiAwu4",
        "row": 22834,
        "state": "",
        "group": "3344739",
        "group_num": [
            "recGRps4B57CSwSEG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "id": "recypFtG2tCsXJsGS",
        "row": 22832,
        "state": "",
        "group": "3344685",
        "group_num": [
            "recqrWAbHhTksuMf3"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "id": "recBalpu10lsmTrrK",
        "row": 22833,
        "state": "",
        "group": "3344685",
        "group_num": [
            "recqrWAbHhTksuMf3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "2024": 280,
        "id": "recCKyMjdJtT0s13u",
        "row": 22830,
        "state": "",
        "group": "",
        "group_num": [
            "recp978wUusKnV413"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 45,
        "id": "recC7U0sbWc8dxsgd",
        "row": 22819,
        "state": "",
        "group": "",
        "group_num": [
            "recodew7fg76RJomd"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2538,
        "id": "recF2F9IMJCHeU2ii",
        "row": 22809,
        "state": "",
        "group": "3344505",
        "group_num": [
            "recNWKdXMVsNG8kA5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recIqGx2xxSe4ehwA",
        "row": 22814,
        "state": "",
        "group": "3344521",
        "group_num": [
            "recBx1KLfdFsO7Jpo"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 204,
        "id": "recJTf9t4uyMBaLCp",
        "row": 22826,
        "state": "",
        "group": "3344578",
        "group_num": [
            "recEaZgsHhuNPVd11"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 217,
        "2024": 217,
        "id": "recJxwEVmzrrJ8VtU",
        "row": 22853,
        "state": "",
        "group": "",
        "group_num": [
            "recQ264vIrOEUdDXX"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2125,
        "id": "recJ8vjcuHIOlGOMm",
        "row": 22829,
        "state": "",
        "group": "",
        "group_num": [
            "recNZaGAJkS6SNKSc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 586,
        "id": "recNR0jodhRTfZQPw",
        "row": 22843,
        "state": "",
        "group": "",
        "group_num": [
            "recLpYEOcmAkSY91Z"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "2023": 692,
        "id": "recNoD1wFgNWX1D4u",
        "row": 22817,
        "state": "",
        "group": "",
        "group_num": [
            "recywb2A2lr4jgJNR"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 39,
        "id": "recesZ4y9oWuBgrd7",
        "row": 22883,
        "state": "",
        "group": "",
        "group_num": [
            "rec1zojl4X5ag7H0I"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 188,
        "id": "recf5sMkOFqYvaZgG",
        "row": 22878,
        "state": "",
        "group": "4657901",
        "group_num": [
            "recTbeRcj8bJQeyzT"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recgmCHv4X6JaiBrt",
        "row": 22857,
        "state": "",
        "group": "",
        "group_num": [
            "recUEKm4ErBwbaYAC"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 349,
        "id": "recn0HZOPTN2FZvGM",
        "row": 22879,
        "state": "",
        "group": "4657901",
        "group_num": [
            "recTbeRcj8bJQeyzT"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "id": "recnrDBsfuN9omJpU",
        "row": 22902,
        "state": "",
        "group": "00358728",
        "group_num": [
            "recN6vkvn3DmbLWvi"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recPVUGXklQZSXI1u",
        "row": 22862,
        "state": "",
        "group": "",
        "group_num": [
            "recDrzh2EvUSSK9CL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4418,
        "id": "recROAfMSWqVIX11e",
        "row": 22899,
        "state": "",
        "group": "00183586",
        "group_num": [
            "recP4IEuWUwVTOPEL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "recT6MNXCpu2SfpY0",
        "row": 22886,
        "state": "",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recVE3YAfUDgLygOs",
        "row": 22894,
        "state": "",
        "group": "",
        "group_num": [
            "recBppsIj8w5hly5U"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1250,
        "id": "recV5n8M2Q5O1jOph",
        "row": 22887,
        "state": "",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 523,
        "id": "recZAMRboYXRy8JNi",
        "row": 22877,
        "state": "",
        "group": "4657901",
        "group_num": [
            "recTbeRcj8bJQeyzT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 272,
        "2024": 280,
        "id": "recZ2cG6dPeJsesL7",
        "row": 22897,
        "state": "",
        "group": "",
        "group_num": [
            "recP47PcvzMKmBxxs"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "rec0ZlCx0pq3AjH6I",
        "row": 22869,
        "state": "",
        "group": "3345508",
        "group_num": [
            "reczxpNp0QMtKf80G"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 198,
        "id": "rec003y3EdBKFxGl5",
        "row": 22867,
        "state": "",
        "group": "",
        "group_num": [
            "recscBUXMCDPG5ure"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 230,
        "id": "rec0fLS0vkSQK6kQo",
        "row": 22873,
        "state": "",
        "group": "",
        "group_num": [
            "recqc8ycb4OW81Snr"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "rec1LSeC6C7FzQSvi",
        "row": 22880,
        "state": "",
        "group": "4657901",
        "group_num": [
            "recTbeRcj8bJQeyzT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "id": "rec1g2JXyRs4CHIDW",
        "row": 22900,
        "state": "",
        "group": "00358543",
        "group_num": [
            "recX1tHdnEFM8HVRA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1035,
        "id": "rec1nqDd85ug0u8le",
        "row": 22893,
        "state": "",
        "group": "",
        "group_num": [
            "recBppsIj8w5hly5U"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rec2vruGSZgV5B75M",
        "row": 22874,
        "state": "",
        "group": "",
        "group_num": [
            "recK1HmPL6LElKHX9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1334,
        "id": "rec2cAQAGIFAbSN4v",
        "row": 22891,
        "state": "",
        "group": "",
        "group_num": [
            "recizbP3RYpfIMoFZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "rec3GSPKC9SyL3YNj",
        "row": 22858,
        "state": "",
        "group": "",
        "group_num": [
            "recUEKm4ErBwbaYAC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 231,
        "id": "rec3mMdc10LqoEn70",
        "row": 22876,
        "state": "",
        "group": "",
        "group_num": [
            "reck4QirnPX863St3"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1892,
        "id": "rec4y0zMJJbhx49yH",
        "row": 22884,
        "state": "",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "rec4CLIs7SJ3VjJ9J",
        "row": 22905,
        "state": "",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "rec45azmRydreCM0v",
        "row": 22866,
        "state": "",
        "group": "3345322",
        "group_num": [
            "rec4JsfDQrUAIwmAJ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 293,
        "id": "rec6xTfjEOwQGsI6M",
        "row": 22898,
        "state": "",
        "group": "",
        "group_num": [
            "rec2RJdUQw8UaCg1V"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 890,
        "id": "rec6b4acGqRMGEWxC",
        "row": 22904,
        "state": "",
        "group": "00358992",
        "group_num": [
            "receEbNEC64D9DPnx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2589,
        "id": "rec6lp99qgYv34ZJG",
        "row": 22861,
        "state": "",
        "group": "",
        "group_num": [
            "recDrzh2EvUSSK9CL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 194,
        "id": "rec701WvkWdt1MfNx",
        "row": 22871,
        "state": "",
        "group": "",
        "group_num": [
            "recIUa6laGoMZooUQ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rec7aoCLlxDpTpstZ",
        "row": 22863,
        "state": "",
        "group": "",
        "group_num": [
            "reco0ge9NuxtyYhrI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 387,
        "2023": 290,
        "id": "rec8NMbs5jFOCX3JY",
        "row": 22889,
        "state": "",
        "group": "00052520",
        "group_num": [
            "recZYYgHhNw7NofoA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1283,
        "id": "rec9ZD8hxdRYMZ9nw",
        "row": 22885,
        "state": "",
        "group": "00000348",
        "group_num": [
            "recZbvKxisYq4AbA6"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3740,
        "id": "recb0KpBxGH28nELv",
        "row": 22890,
        "state": "",
        "group": "00052665",
        "group_num": [
            "recy4e9N2ZypEecyL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1460,
        "id": "recdgAEodPZbFaQja",
        "row": 22892,
        "state": "",
        "group": "",
        "group_num": [
            "recizbP3RYpfIMoFZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 798,
        "id": "recdn2T3guWxvwXpX",
        "row": 22881,
        "state": "",
        "group": "",
        "group_num": [
            "rec1YIePBFuQwTTxW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 460,
        "id": "recqaSsPUtUFKYB5J",
        "row": 22872,
        "state": "",
        "group": "3345548",
        "group_num": [
            "recHJ1z7z6pAPemuV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recrFni5j87XhYinq",
        "row": 22895,
        "state": "",
        "group": "",
        "group_num": [
            "recezigLCpnjslU5D"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 798,
        "2024": 798,
        "id": "recrF0Zq1cCru6Wjg",
        "row": 22882,
        "state": "",
        "group": "",
        "group_num": [
            "rec1YIePBFuQwTTxW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recrOupty5Ik9rtNw",
        "row": 22896,
        "state": "",
        "group": "",
        "group_num": [
            "recezigLCpnjslU5D"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "rectIn8g95g00ZqFO",
        "row": 22888,
        "state": "",
        "group": "00052520",
        "group_num": [
            "recZYYgHhNw7NofoA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1687,
        "id": "recvnL8YI3NJKKYcF",
        "row": 22901,
        "state": "",
        "group": "00358543",
        "group_num": [
            "recX1tHdnEFM8HVRA"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recyvNH2WobRVL7lj",
        "row": 22906,
        "state": "",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2400,
        "id": "reczD1Wr7jBZd70Dw",
        "row": 22875,
        "state": "",
        "group": "",
        "group_num": [
            "recK1HmPL6LElKHX9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 277,
        "id": "recHIHLMyyfKh2RbX",
        "row": 22868,
        "state": "",
        "group": "",
        "group_num": [
            "recscBUXMCDPG5ure"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recHa5geGi37rYpho",
        "row": 22903,
        "state": "",
        "group": "00358844",
        "group_num": [
            "recCJOqB1ecSOpdxk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recIZKAxkxtUxnQQr",
        "row": 22860,
        "state": "",
        "group": "",
        "group_num": [
            "recP3y0HsexPpPtjD"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 596,
        "id": "recIJxWTykZhl9nq6",
        "row": 22865,
        "state": "",
        "group": "3345322",
        "group_num": [
            "rec4JsfDQrUAIwmAJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 805,
        "id": "recJfxOErgfTgeeb6",
        "row": 22859,
        "state": "",
        "group": "",
        "group_num": [
            "recXcascE1L5NHelo"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 681,
        "id": "recL5kqzwhahzZ37e",
        "row": 22864,
        "state": "",
        "group": "",
        "group_num": [
            "reco0ge9NuxtyYhrI"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recLjyXvKthWZ6Hpq",
        "row": 22870,
        "state": "",
        "group": "3345508",
        "group_num": [
            "reczxpNp0QMtKf80G"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "id": "receXnF8OBMwVZTLC",
        "row": 22932,
        "state": "",
        "group": "00608699",
        "group_num": [
            "recANzolTFXsxf2kz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "2023": 1019,
        "id": "rececr09JSP5GbfNe",
        "row": 22948,
        "state": "",
        "group": "00611370",
        "group_num": [
            "recJNKD1cUuzEWxmn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 435,
        "id": "recfO0cGiFcjB5YSR",
        "row": 22938,
        "state": "",
        "group": "",
        "group_num": [
            "rec57qQNo4aQyPp1P"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "rechnngrRS9KPtjK0",
        "row": 22914,
        "state": "",
        "group": "00606338",
        "group_num": [
            "rect0wjK6fDc81W6q"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2750,
        "2023": 2721,
        "id": "reci0YvLMeEkaIdCl",
        "row": 22950,
        "state": "",
        "group": "00611370",
        "group_num": [
            "recJNKD1cUuzEWxmn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "id": "recjvL31MTMnRduZG",
        "row": 22940,
        "state": "",
        "group": "00609947",
        "group_num": [
            "rec47TLf65bheuYLZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recjG8si1hfPzGle9",
        "row": 22912,
        "state": "",
        "group": "00605103",
        "group_num": [
            "recE54nWk8OumqgIp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 291,
        "id": "recjafXCgTepIHtYY",
        "row": 22943,
        "state": "",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "recPEu5IZZd4Csy7Z",
        "row": 22919,
        "state": "",
        "group": "",
        "group_num": [
            "recRKwVlg1obr88E4"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1148,
        "id": "recPbh1BvGoXneQ1h",
        "row": 22929,
        "state": "",
        "group": "00608587",
        "group_num": [
            "rec2Xm9IUfs3Wh5zY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2450,
        "id": "recQIQaAbPGKmPtu0",
        "row": 22928,
        "state": "",
        "group": "",
        "group_num": [
            "recNE84q6YU3ylcvb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 747,
        "id": "recRZMgg1OjcdTeWq",
        "row": 22915,
        "state": "",
        "group": "00606338",
        "group_num": [
            "rect0wjK6fDc81W6q"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 60,
        "2024": 60,
        "id": "recRo5dfPkqOmKrAa",
        "row": 22917,
        "state": "",
        "group": "00606939",
        "group_num": [
            "reciADbUeAJflQ1Eb"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2958,
        "id": "recSdayJVukbdpPUH",
        "row": 22911,
        "state": "",
        "group": "",
        "group_num": [
            "recbkrarckzJIfWGC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "2023": 968,
        "id": "recTHlZsKUmzxc5Us",
        "row": 22949,
        "state": "",
        "group": "00611370",
        "group_num": [
            "recJNKD1cUuzEWxmn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "id": "recTHmflptmvZrh9O",
        "row": 22931,
        "state": "",
        "group": "00608699",
        "group_num": [
            "recANzolTFXsxf2kz"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 772,
        "id": "recWZrozCTizW4U0Z",
        "row": 22913,
        "state": "",
        "group": "",
        "group_num": [
            "recjPNl0n0PuehJNx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2257,
        "2023": 2285,
        "id": "recWRsnJ9Yz6EKdEI",
        "row": 22947,
        "state": "",
        "group": "00611370",
        "group_num": [
            "recJNKD1cUuzEWxmn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1565,
        "id": "recYTNdwzA81Itr7Z",
        "row": 22933,
        "state": "",
        "group": "",
        "group_num": [
            "recMb7T0MggDZAVTH"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 999,
        "id": "recYGultqkmetVaiQ",
        "row": 22909,
        "state": "",
        "group": "",
        "group_num": [
            "recbkrarckzJIfWGC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 649,
        "id": "recZZYRCCqRspqIIl",
        "row": 22953,
        "state": "",
        "group": "00611913",
        "group_num": [
            "recAAM9JSk2lyUpuA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 226,
        "id": "rec1fDuO2QNoNqcF1",
        "row": 22921,
        "state": "",
        "group": "",
        "group_num": [
            "recRKwVlg1obr88E4"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 153,
        "2024": 156,
        "id": "rec2I6lg0Jg2VvZWg",
        "row": 22937,
        "state": "",
        "group": "",
        "group_num": [
            "rec57qQNo4aQyPp1P"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 180,
        "id": "rec31NlkkbpGcaX0w",
        "row": 22951,
        "state": "",
        "group": "",
        "group_num": [
            "recqndvkFqQxFF5JT"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "rec8xgYNgQnKnyH0H",
        "row": 22926,
        "state": "",
        "group": "00608338",
        "group_num": [
            "reczdcH8QPeB4swfd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 977,
        "id": "rec8BcozGAd6mi7Gl",
        "row": 22946,
        "state": "",
        "group": "00610901",
        "group_num": [
            "recTS6oxaT8KWZV0T"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "id": "rec88M5BOWUTL25k6",
        "row": 22916,
        "state": "",
        "group": "00606450",
        "group_num": [
            "recXYDk0qoN3xypMy"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "rec8fVCblGebMicRb",
        "row": 22955,
        "state": "",
        "group": "",
        "group_num": [
            "recMpQl7A1VxFn5H8"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 208,
        "id": "rec9EvDBx7nmhNu8I",
        "row": 22924,
        "state": "",
        "group": "",
        "group_num": [
            "receDjVlBbQsTWv1f"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 526,
        "id": "rec9GPCjPPkL7cSPX",
        "row": 22922,
        "state": "",
        "group": "",
        "group_num": [
            "recRKwVlg1obr88E4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "id": "rec94cQbXCsf37qns",
        "row": 22930,
        "state": "",
        "group": "00608699",
        "group_num": [
            "recANzolTFXsxf2kz"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "reco6bJTnjRYfGEuf",
        "row": 22923,
        "state": "",
        "group": "",
        "group_num": [
            "receDjVlBbQsTWv1f"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 569,
        "id": "recoqdIF5L3GX2Np1",
        "row": 22934,
        "state": "",
        "group": "",
        "group_num": [
            "recMb7T0MggDZAVTH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 22,
        "id": "recqk3HTvHtIDRDLj",
        "row": 22939,
        "state": "",
        "group": "00609947",
        "group_num": [
            "rec47TLf65bheuYLZ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "2023": 600,
        "id": "recrbxqLCc4r2Ey46",
        "row": 22925,
        "state": "",
        "group": "",
        "group_num": [
            "receDjVlBbQsTWv1f"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 20,
        "2023": 22,
        "id": "recsPguoK4laYMw3X",
        "row": 22952,
        "state": "",
        "group": "00611913",
        "group_num": [
            "recAAM9JSk2lyUpuA"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 653,
        "id": "recvMELgGRDZKTDsL",
        "row": 22942,
        "state": "",
        "group": "00610894",
        "group_num": [
            "recE1zW3e0lZWHsju"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1006,
        "id": "recxxYeexzQRbuTG8",
        "row": 22945,
        "state": "",
        "group": "00610901",
        "group_num": [
            "recTS6oxaT8KWZV0T"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "id": "recx6Uf7c74ERP1Ip",
        "row": 22941,
        "state": "",
        "group": "",
        "group_num": [
            "recvY0IlZejqI9hBy"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2568,
        "id": "recAD0HmhkGlJNu7n",
        "row": 22908,
        "state": "",
        "group": "",
        "group_num": [
            "recbkrarckzJIfWGC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 562,
        "id": "recCvtTuh5vuoGYPq",
        "row": 22920,
        "state": "",
        "group": "",
        "group_num": [
            "recRKwVlg1obr88E4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "id": "recC95CinaIH4xZ14",
        "row": 22936,
        "state": "",
        "group": "",
        "group_num": [
            "rec0DzTKk60WULJtN"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1446,
        "id": "recGJPPRJgx3PBcJq",
        "row": 22935,
        "state": "",
        "group": "",
        "group_num": [
            "recfU9fgI3nAzcrRH"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recGPdLjr0M4zywwi",
        "row": 22944,
        "state": "",
        "group": "00610901",
        "group_num": [
            "recTS6oxaT8KWZV0T"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "id": "recGQV1ac3eKVBW8e",
        "row": 22910,
        "state": "",
        "group": "",
        "group_num": [
            "recbkrarckzJIfWGC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 658,
        "id": "recHHDTSyH0M72Mdy",
        "row": 22918,
        "state": "",
        "group": "00607117",
        "group_num": [
            "recZXK4W4dKBZYp4c"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1710,
        "id": "recHrtlrKgQHo5rHN",
        "row": 22927,
        "state": "",
        "group": "",
        "group_num": [
            "recNE84q6YU3ylcvb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recI9ehs213G0SVbT",
        "row": 22907,
        "state": "",
        "group": "00359144",
        "group_num": [
            "recnDck46mxHauXF4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 495,
        "2024": 1200,
        "id": "recNX0pRZS9sHRf2y",
        "row": 22954,
        "state": "",
        "group": "00612052",
        "group_num": [
            "rec59NKfLZqlrSVsS"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 60,
        "id": "recNq7Ug6UqTO6lpl",
        "row": 22956,
        "state": "",
        "group": "00612341",
        "group_num": [
            "recFrbLG8G4Q5KLOO"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "receLfB9HRDefSzbN",
        "row": 23003,
        "state": "",
        "group": "00620532",
        "group_num": [
            "rec0hP6pycKKOvWCx"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recf4CzABkpwwkhTP",
        "row": 22963,
        "state": "",
        "group": "00613215",
        "group_num": [
            "recKi1Jaj8PXsqMxm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 438,
        "2024": 282,
        "id": "rechrxdrVBzroufDF",
        "row": 22987,
        "state": "",
        "group": "00616997",
        "group_num": [
            "recoaFyhteO7lbQbE"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "reckWjSr7Cy69cwMe",
        "row": 22969,
        "state": "",
        "group": "00614180",
        "group_num": [
            "recSPY7kmGgenEtXb"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "id": "reckxeukZ6lUCEYot",
        "row": 22998,
        "state": "",
        "group": "",
        "group_num": [
            "recSsCyBOLiJxLhJJ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 571,
        "id": "reclYLWUBm1J79GkT",
        "row": 22962,
        "state": "",
        "group": "00613215",
        "group_num": [
            "recKi1Jaj8PXsqMxm"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2434,
        "id": "recm9szVCUgQt0rPM",
        "row": 22993,
        "state": "",
        "group": "",
        "group_num": [
            "recz1U61Yb2x09AW0"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2133,
        "id": "recn7LG0dEiFTYAud",
        "row": 23001,
        "state": "",
        "group": "",
        "group_num": [
            "recFfBsXM8rJI1LJv"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2252,
        "id": "recOCCCy0Ervpc3lB",
        "row": 22979,
        "state": "",
        "group": "00616428",
        "group_num": [
            "recUgoGSqERH8SSbn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3251,
        "2024": 576,
        "id": "recPtndkHpQ60I6C2",
        "row": 22974,
        "state": "",
        "group": "",
        "group_num": [
            "recUa3pNvpM4tW7d6"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 875,
        "id": "recPmaRSwU16MThqO",
        "row": 22981,
        "state": "",
        "group": "00616428",
        "group_num": [
            "recUgoGSqERH8SSbn"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recQQIfePLDYmFGAP",
        "row": 22965,
        "state": "",
        "group": "",
        "group_num": [
            "recH4OkrdisLBEs1j"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recRwIXakTzhX6GHb",
        "row": 23000,
        "state": "",
        "group": "",
        "group_num": [
            "recFfBsXM8rJI1LJv"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recR9wwXzvZRO9pRe",
        "row": 22959,
        "state": "",
        "group": "00612690",
        "group_num": [
            "recRxYnk01cSGEPrf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2250,
        "id": "recRidj0vXsBNMdTi",
        "row": 23005,
        "state": "",
        "group": "",
        "group_num": [
            "recvk6PbLw6N9O8Sm"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recSuuftqR9N4iIy9",
        "row": 22999,
        "state": "",
        "group": "",
        "group_num": [
            "recSsCyBOLiJxLhJJ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recWHbVHhI5N2cxMq",
        "row": 22957,
        "state": "",
        "group": "00612341",
        "group_num": [
            "recFrbLG8G4Q5KLOO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "id": "recYW8v7y1YsCLeTd",
        "row": 23004,
        "state": "",
        "group": "00620879",
        "group_num": [
            "recylSdKsYHA9GmrH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2048,
        "id": "rec0UOCSKO9diOB5K",
        "row": 22996,
        "state": "",
        "group": "",
        "group_num": [
            "recWmxszr0im78yIy"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "id": "rec2mDmfUbxzabE0h",
        "row": 22994,
        "state": "",
        "group": "",
        "group_num": [
            "recz1U61Yb2x09AW0"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "rec3wLaULUUYZF5d8",
        "row": 22978,
        "state": "",
        "group": "",
        "group_num": [
            "recdUNqlE0gB00KDU"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1626,
        "id": "rec3gRyxA90eriIBT",
        "row": 22967,
        "state": "",
        "group": "",
        "group_num": [
            "rec7YYljXfHrmeESe"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2131,
        "id": "rec4ZuYoM48kRliQt",
        "row": 22972,
        "state": "",
        "group": "",
        "group_num": [
            "recv8r13XARO3uCLx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2710,
        "id": "rec5kaqngAyC6K83j",
        "row": 22986,
        "state": "",
        "group": "00616997",
        "group_num": [
            "recoaFyhteO7lbQbE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 940,
        "id": "rec65X6VvoiBPKziv",
        "row": 22989,
        "state": "",
        "group": "00617445",
        "group_num": [
            "recDRq3opr43ctVhq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 268,
        "id": "rec8cC1nuOvPQ4kUQ",
        "row": 22964,
        "state": "",
        "group": "00613576",
        "group_num": [
            "recY0hrLVhDh50LIW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "id": "recaDw2fhjQFmIede",
        "row": 22976,
        "state": "",
        "group": "",
        "group_num": [
            "recd2bc9rN9ZAAbtn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 217,
        "id": "recabaSpTVJn5voYO",
        "row": 22991,
        "state": "",
        "group": "",
        "group_num": [
            "recmzjLM1wO8bmVzm"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recbVCxgAwNakiwcg",
        "row": 22971,
        "state": "",
        "group": "00614770",
        "group_num": [
            "recq3Rz3YIE000504"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2877,
        "2023": 2750,
        "id": "recbJ5Aseb9r1y3TJ",
        "row": 22982,
        "state": "",
        "group": "00616428",
        "group_num": [
            "recUgoGSqERH8SSbn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recpaa5lNdKhej9WG",
        "row": 22960,
        "state": "",
        "group": "00612890",
        "group_num": [
            "recY6tkZ2rupU8qkY"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recrQjlBvjdGIcg0v",
        "row": 22966,
        "state": "",
        "group": "00613603",
        "group_num": [
            "recYqRrn034Kz9gJE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2125,
        "id": "rect3ZeP4tA1VDj5W",
        "row": 22977,
        "state": "",
        "group": "",
        "group_num": [
            "recd2bc9rN9ZAAbtn"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1843,
        "id": "recuxrGG6fP15mNh3",
        "row": 22997,
        "state": "",
        "group": "00618737",
        "group_num": [
            "recrNVywqP4saSYcZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recwr2t1q5OCRyEwV",
        "row": 22973,
        "state": "",
        "group": "",
        "group_num": [
            "recUa3pNvpM4tW7d6"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2284,
        "id": "recxFRODGS8tqAmA4",
        "row": 22975,
        "state": "",
        "group": "",
        "group_num": [
            "recd2bc9rN9ZAAbtn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 60,
        "id": "recyKhWz2UFBZJon7",
        "row": 23006,
        "state": "",
        "group": "00620921",
        "group_num": [
            "recf3JSUtjOasKlUO"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recymUTRAT7z5zMqq",
        "row": 22961,
        "state": "",
        "group": "00613147",
        "group_num": [
            "recuVfEgtjUOKcF83"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "2023": 909,
        "id": "reczTvXrR0k4Cjuc4",
        "row": 22980,
        "state": "",
        "group": "00616428",
        "group_num": [
            "recUgoGSqERH8SSbn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 252,
        "id": "reczZZcpORI8QyzWP",
        "row": 22992,
        "state": "",
        "group": "",
        "group_num": [
            "recmzjLM1wO8bmVzm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "id": "recB9M37V5o364Zej",
        "row": 22984,
        "state": "",
        "group": "",
        "group_num": [
            "reczmcHjW7yeywIxE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1018,
        "id": "recEwQbI28cdSlW0z",
        "row": 22968,
        "state": "",
        "group": "",
        "group_num": [
            "rec7YYljXfHrmeESe"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1265,
        "id": "recFGPYj8eHKosEla",
        "row": 22983,
        "state": "",
        "group": "00616734",
        "group_num": [
            "recNNwqUgEwu5VASf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2648,
        "2023": 1707,
        "id": "recFpGN3btC221jwJ",
        "row": 22988,
        "state": "",
        "group": "00617445",
        "group_num": [
            "recDRq3opr43ctVhq"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recGcU5k29qrBUOh8",
        "row": 23002,
        "state": "",
        "group": "",
        "group_num": [
            "recnnQtgY1HgOYEeD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 275,
        "id": "recISIHosQG4wDB3N",
        "row": 22990,
        "state": "",
        "group": "00617593",
        "group_num": [
            "recgDrNJqUMPV7wC8"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 273,
        "2023": 280,
        "id": "recL13OZi3NkG1UQ9",
        "row": 22970,
        "state": "",
        "group": "00614770",
        "group_num": [
            "recq3Rz3YIE000504"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recLfDFZjqPFUjsty",
        "row": 22958,
        "state": "",
        "group": "00612690",
        "group_num": [
            "recRxYnk01cSGEPrf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 904,
        "id": "recMHtvHaBy1fn5lK",
        "row": 22985,
        "state": "",
        "group": "",
        "group_num": [
            "recxl4JcTbbauvS6G"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "id": "recNwSupdSGJOrv32",
        "row": 22995,
        "state": "",
        "group": "",
        "group_num": [
            "recz1U61Yb2x09AW0"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rece73JkXGFtHonJN",
        "row": 23047,
        "state": "",
        "group": "",
        "group_num": [
            "recGZohXwuAikhIzJ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1956,
        "id": "receialIiLfuezNzI",
        "row": 23010,
        "state": "",
        "group": "",
        "group_num": [
            "receWOCiSm33DUSZD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recercSqTrRB7dCwp",
        "row": 23052,
        "state": "",
        "group": "",
        "group_num": [
            "recZ0jTJJbvQhTyQH"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1650,
        "id": "recgTiIfXZuJpQwap",
        "row": 23053,
        "state": "",
        "group": "",
        "group_num": [
            "recno30CmwoIRDPTZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1021,
        "id": "recl5bnvfgz10WLuB",
        "row": 23012,
        "state": "",
        "group": "",
        "group_num": [
            "rec3kyW3XCg2xplvB"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 823,
        "id": "recOeU7KuBVu4Lnaq",
        "row": 23037,
        "state": "",
        "group": "",
        "group_num": [
            "recghJEg64TS9o8uC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recQMJhyqRmwwh53X",
        "row": 23038,
        "state": "",
        "group": "",
        "group_num": [
            "recuwe3fLeaMWDZ7s"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recRuwh9NeQpRuFqc",
        "row": 23042,
        "state": "",
        "group": "",
        "group_num": [
            "recpiPLGnArU78a5o"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "id": "recRObjLB6RwUTuVS",
        "row": 23054,
        "state": "",
        "group": "",
        "group_num": [
            "recgGFtRwH06FDXGD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "id": "recSR4hb1SC1ZClCm",
        "row": 23015,
        "state": "",
        "group": "00621840",
        "group_num": [
            "recYWuZkm2EIljYYF"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 480,
        "id": "recSnOL5e8byKectc",
        "row": 23035,
        "state": "",
        "group": "",
        "group_num": [
            "recyg1kLOhmUYuzRc"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1520,
        "id": "recTExrVLDJ47VYsh",
        "row": 23023,
        "state": "",
        "group": "",
        "group_num": [
            "recR7LnZDKdaemZIk"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recUJr6600Q7b2Y0j",
        "row": 23030,
        "state": "",
        "group": "",
        "group_num": [
            "rec19JehiTUHKUQLi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2424,
        "id": "recU4ZYsFKl6Ywgxl",
        "row": 23036,
        "state": "",
        "group": "00623894",
        "group_num": [
            "rec0e9GlB57YeGstX"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recUgZ5PnWe9IXXuR",
        "row": 23019,
        "state": "",
        "group": "",
        "group_num": [
            "recHRh8BMmsM9ivYV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 380,
        "id": "recZtnm9QmqQhyrIO",
        "row": 23044,
        "state": "",
        "group": "",
        "group_num": [
            "recfLbeSm23qDaDsy"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1999,
        "id": "rec0TDjLQoDxmVjE3",
        "row": 23025,
        "state": "",
        "group": "",
        "group_num": [
            "recV54Di19G3JqDQp"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "rec4YSEvMaLGAI4vS",
        "row": 23045,
        "state": "",
        "group": "",
        "group_num": [
            "recovPkanadvBJ9Jt"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "rec4ypIti6oxRzQyi",
        "row": 23039,
        "state": "",
        "group": "",
        "group_num": [
            "recuwe3fLeaMWDZ7s"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1004,
        "id": "rec6zXUJ3QKOarHEu",
        "row": 23009,
        "state": "",
        "group": "",
        "group_num": [
            "receWOCiSm33DUSZD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1090,
        "id": "reca4z62O4kaSwBoc",
        "row": 23032,
        "state": "",
        "group": "00623570",
        "group_num": [
            "reczSsaXWPdzYlMJf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "id": "recbgih9i81ktZOUy",
        "row": 23007,
        "state": "",
        "group": "00620921",
        "group_num": [
            "recf3JSUtjOasKlUO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2377,
        "id": "reccoeJon787ZFEij",
        "row": 23011,
        "state": "",
        "group": "00620959",
        "group_num": [
            "recpX0YTLDgJf48rZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1216,
        "id": "recdTs7oa17SdutPm",
        "row": 23024,
        "state": "",
        "group": "00623276",
        "group_num": [
            "receARClfsMCHiQ9M"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2412,
        "id": "recddDPhpfSxmN6zw",
        "row": 23026,
        "state": "",
        "group": "",
        "group_num": [
            "recV54Di19G3JqDQp"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 125,
        "id": "recov8X7dH76Hh2CG",
        "row": 23051,
        "state": "",
        "group": "",
        "group_num": [
            "recZ0jTJJbvQhTyQH"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 626,
        "id": "recqRD1l2co7vyO0w",
        "row": 23029,
        "state": "",
        "group": "",
        "group_num": [
            "rec9nhu2OGLfKwcdx"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1650,
        "id": "recrDSd47JuNtkbGV",
        "row": 23055,
        "state": "",
        "group": "",
        "group_num": [
            "recgGFtRwH06FDXGD"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 102,
        "id": "recrD0D6ZbqZUPDD4",
        "row": 23022,
        "state": "",
        "group": "",
        "group_num": [
            "recR7LnZDKdaemZIk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "2024": 290,
        "id": "recrknh7vjt6zOL66",
        "row": 23043,
        "state": "",
        "group": "",
        "group_num": [
            "recpiPLGnArU78a5o"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 217,
        "2023": 179,
        "2024": 174,
        "id": "recrpuAn38s5IOLLl",
        "row": 23049,
        "state": "",
        "group": "",
        "group_num": [
            "recDuFXVX5c4XUt4M"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "id": "recsocSBM1phMjunb",
        "row": 23020,
        "state": "",
        "group": "00622445",
        "group_num": [
            "recRsuol5KIkAg99Q"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "rectG2OaFv4iM5rfb",
        "row": 23031,
        "state": "",
        "group": "",
        "group_num": [
            "rec19JehiTUHKUQLi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rectabOu8z5Ge4tIr",
        "row": 23041,
        "state": "",
        "group": "",
        "group_num": [
            "recuwe3fLeaMWDZ7s"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recv42JpeDXUkA8uN",
        "row": 23017,
        "state": "",
        "group": "",
        "group_num": [
            "recF4MhPDQBwP5Dkb"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 585,
        "2023": 188,
        "id": "recvbIjF9ICfj7Vtg",
        "row": 23050,
        "state": "",
        "group": "",
        "group_num": [
            "recDuFXVX5c4XUt4M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2710,
        "id": "recwGABh5or1SmUOq",
        "row": 23014,
        "state": "",
        "group": "",
        "group_num": [
            "rec3kyW3XCg2xplvB"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recysR69PAiK09LAT",
        "row": 23046,
        "state": "",
        "group": "",
        "group_num": [
            "recOAJT2JwgzGkLkP"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1029,
        "id": "recyCZwA2Taw44Nkh",
        "row": 23034,
        "state": "",
        "group": "",
        "group_num": [
            "recyg1kLOhmUYuzRc"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recz1gE0jDLvknrQ8",
        "row": 23018,
        "state": "",
        "group": "",
        "group_num": [
            "recF4MhPDQBwP5Dkb"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 869,
        "id": "reczlDUQpuWA5LWvg",
        "row": 23027,
        "state": "",
        "group": "",
        "group_num": [
            "rec9nhu2OGLfKwcdx"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2430,
        "id": "recBPN7bcgAz9WFIt",
        "row": 23056,
        "state": "",
        "group": "",
        "group_num": [
            "recPGQTraN8qTCbCk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recEmdF7q9CTKpB2x",
        "row": 23033,
        "state": "",
        "group": "00623571",
        "group_num": [
            "recpP7HsdGQAornZw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 531,
        "id": "recFme1gj2IQRmxh4",
        "row": 23021,
        "state": "",
        "group": "00622445",
        "group_num": [
            "recRsuol5KIkAg99Q"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 280,
        "id": "recHtvsHFBtCo6IuJ",
        "row": 23016,
        "state": "",
        "group": "",
        "group_num": [
            "recNjnwdLfdPc22Zc"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1021,
        "id": "recIarh4bXMiIvmEr",
        "row": 23013,
        "state": "",
        "group": "",
        "group_num": [
            "rec3kyW3XCg2xplvB"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recJl6IcOVqOiZKZR",
        "row": 23048,
        "state": "",
        "group": "00624449",
        "group_num": [
            "recJNQgKJbj99mAYO"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 223,
        "id": "recKbV6U0Qen2WtOR",
        "row": 23028,
        "state": "",
        "group": "",
        "group_num": [
            "rec9nhu2OGLfKwcdx"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recLy5I6SlSJyJcnO",
        "row": 23008,
        "state": "",
        "group": "",
        "group_num": [
            "receWOCiSm33DUSZD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recN53eqybcryqwS2",
        "row": 23040,
        "state": "",
        "group": "",
        "group_num": [
            "recuwe3fLeaMWDZ7s"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recfe5J0CwDQZ9bdI",
        "row": 23105,
        "state": "",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recgjl31kmuf1lz9M",
        "row": 23075,
        "state": "",
        "group": "",
        "group_num": [
            "recZ5knTMQ0sZdfXG"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 883,
        "id": "rechpUITSDleizfvS",
        "row": 23059,
        "state": "",
        "group": "",
        "group_num": [
            "rechGLUX1eICa1M9r"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 682,
        "id": "reciQ80UhugQzGmtx",
        "row": 23060,
        "state": "",
        "group": "",
        "group_num": [
            "rechGLUX1eICa1M9r"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3740,
        "id": "reclT2HHsKwsfcdEy",
        "row": 23062,
        "state": "",
        "group": "",
        "group_num": [
            "rec4zM71vzDkK9e6w"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "reclk1wPmLHediPgx",
        "row": 23072,
        "state": "",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recmfbD03ZM5dwBfK",
        "row": 23073,
        "state": "",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "2023": 554,
        "id": "recnKpcDqpguKOZeh",
        "row": 23070,
        "state": "",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "recQo8CYUVRXkX5jM",
        "row": 23081,
        "state": "",
        "group": "00627448",
        "group_num": [
            "reckpIJgWqCkpaZoL"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recRYtsE0LolgxHuN",
        "row": 23064,
        "state": "",
        "group": "00625890",
        "group_num": [
            "rec4GGpcSMz6BKVlV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recRzL4bJY7ovC0jN",
        "row": 23082,
        "state": "",
        "group": "00627448",
        "group_num": [
            "reckpIJgWqCkpaZoL"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recRbrG72pENddW3b",
        "row": 23063,
        "state": "",
        "group": "00625890",
        "group_num": [
            "rec4GGpcSMz6BKVlV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recRdjrQ4zW32pdZw",
        "row": 23066,
        "state": "",
        "group": "00626028",
        "group_num": [
            "recx58GUrtyewKWeI"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2883,
        "id": "recRkjLuJOXUgLS2Y",
        "row": 23083,
        "state": "",
        "group": "",
        "group_num": [
            "recaaxMvIB7ZCO6No"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 258,
        "id": "recSBzfQSRPITvzSC",
        "row": 23098,
        "state": "",
        "group": "",
        "group_num": [
            "recvbhkMiAFOX5m5z"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "id": "recXU451D42uRuX8I",
        "row": 23067,
        "state": "",
        "group": "00626307",
        "group_num": [
            "rec5sG3rIdl9I5qPW"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recXsSJ6ygN7eTDEC",
        "row": 23080,
        "state": "",
        "group": "00627384",
        "group_num": [
            "recqbV9HHcwbQrJYA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "id": "recZXiKGul4u5duRG",
        "row": 23094,
        "state": "",
        "group": "00628342",
        "group_num": [
            "rec7Sc0mucGWXxpjj"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 261,
        "2024": 261,
        "id": "rec1HULODum0ngVR3",
        "row": 23102,
        "state": "",
        "group": "",
        "group_num": [
            "recALKPOd7miXD7v7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2855,
        "id": "rec1olWCmgfNjN6z6",
        "row": 23084,
        "state": "",
        "group": "",
        "group_num": [
            "recB08FVKXHncgKRZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "rec352N1JxIZYxH8F",
        "row": 23079,
        "state": "",
        "group": "00627267",
        "group_num": [
            "recI71TTCWHfPCy9r"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 786,
        "id": "rec3eLeuJc0DjriZy",
        "row": 23104,
        "state": "",
        "group": "00628878",
        "group_num": [
            "recJieGAp6wY1ij2J"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1395,
        "id": "rec5LF2FBh9gBElQy",
        "row": 23096,
        "state": "",
        "group": "",
        "group_num": [
            "recTIVqo4qtn5Poqt"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "rec7FPOj09172qVLF",
        "row": 23058,
        "state": "",
        "group": "",
        "group_num": [
            "recPGQTraN8qTCbCk"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1714,
        "id": "rec7RPsCDhfwdgdcK",
        "row": 23085,
        "state": "",
        "group": "00627645",
        "group_num": [
            "recHfkbesJ9jijFyE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1131,
        "id": "rec8PiG01sVB8pFPH",
        "row": 23099,
        "state": "",
        "group": "00628511",
        "group_num": [
            "recUAfZQTHVVr3tBc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "id": "rec9vi7FVId4JYtMX",
        "row": 23106,
        "state": "",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "id": "rec9Q4T9nMLEggGiR",
        "row": 23088,
        "state": "",
        "group": "00627856",
        "group_num": [
            "recWBQCgFRoE9jii6"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "id": "reccqpepEPcwwGIRZ",
        "row": 23071,
        "state": "",
        "group": "00626617",
        "group_num": [
            "reckjBb5GdM8JRCkM"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 428,
        "id": "recdu4n977x8PTQHy",
        "row": 23076,
        "state": "",
        "group": "00626924",
        "group_num": [
            "rec6dPnZf5wRbV21I"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recdbu4ajJNmOB7Vn",
        "row": 23086,
        "state": "",
        "group": "00627645",
        "group_num": [
            "recHfkbesJ9jijFyE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recqEjSs4VLeittxL",
        "row": 23092,
        "state": "",
        "group": "",
        "group_num": [
            "recsdbYe8KdU6EQvn"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "id": "recrOJdE4eHIAcVhC",
        "row": 23065,
        "state": "",
        "group": "00625977",
        "group_num": [
            "rec9oRdV0owIOCxEg"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 112,
        "2023": 195,
        "id": "recv9FXeleVUC1ZiC",
        "row": 23100,
        "state": "",
        "group": "",
        "group_num": [
            "recANyJKsITxncApO"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1591,
        "id": "recwqrJprU1CF2D8R",
        "row": 23078,
        "state": "",
        "group": "00627267",
        "group_num": [
            "recI71TTCWHfPCy9r"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recxsrrdJiWjRUB8i",
        "row": 23095,
        "state": "",
        "group": "00628342",
        "group_num": [
            "rec7Sc0mucGWXxpjj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recxAoTdJxcfMwdYk",
        "row": 23077,
        "state": "",
        "group": "00626924",
        "group_num": [
            "rec6dPnZf5wRbV21I"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "id": "recxAr93CbWLzbdqS",
        "row": 23089,
        "state": "",
        "group": "00627856",
        "group_num": [
            "recWBQCgFRoE9jii6"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "2023": 293,
        "id": "recxPon6qjUsMtsQy",
        "row": 23068,
        "state": "",
        "group": "00626307",
        "group_num": [
            "rec5sG3rIdl9I5qPW"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2668,
        "id": "recAZeauo4kq3KLuC",
        "row": 23074,
        "state": "",
        "group": "",
        "group_num": [
            "rec4p1demCSzJjRPB"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 82,
        "id": "recAPoROYuIXA35fD",
        "row": 23097,
        "state": "",
        "group": "",
        "group_num": [
            "recIOdA4Vwj0IV577"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "2023": 295,
        "id": "recDs0WTlDjDgrMrY",
        "row": 23103,
        "state": "",
        "group": "00628878",
        "group_num": [
            "recJieGAp6wY1ij2J"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 363,
        "id": "recDoXvtjhRvnYTLS",
        "row": 23101,
        "state": "",
        "group": "",
        "group_num": [
            "recANyJKsITxncApO"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 878,
        "id": "recGqrIEmdSZy5lVy",
        "row": 23069,
        "state": "",
        "group": "",
        "group_num": [
            "recGyI6IFFqHaOrEy"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recHXtqW2Tp2deute",
        "row": 23090,
        "state": "",
        "group": "00627936",
        "group_num": [
            "recdBbddzljjUfV31"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2128,
        "id": "recIIwx7KkpNEz5Jd",
        "row": 23061,
        "state": "",
        "group": "",
        "group_num": [
            "rec4zM71vzDkK9e6w"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "2023": 190,
        "id": "recIKvn2kJcNWgJd9",
        "row": 23087,
        "state": "",
        "group": "",
        "group_num": [
            "rec43pod3TgpMM1OV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recK299ZKyYypA4oy",
        "row": 23093,
        "state": "",
        "group": "",
        "group_num": [
            "recknfjjza8XXV2KF"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1425,
        "id": "recMxa3ywOoDuyP0H",
        "row": 23091,
        "state": "",
        "group": "",
        "group_num": [
            "recsdbYe8KdU6EQvn"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recMzSRsZ0PR058Od",
        "row": 23057,
        "state": "",
        "group": "",
        "group_num": [
            "recPGQTraN8qTCbCk"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 562,
        "id": "recf8o4Ot4DXIdN42",
        "row": 23141,
        "state": "",
        "group": "",
        "group_num": [
            "recJnjK2tjY3NELGI"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "rechs0h5grMxiGC6R",
        "row": 23150,
        "state": "",
        "group": "00632673",
        "group_num": [
            "recLSGchoEWS8djuK"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 638,
        "id": "reciZ8O8y2Dvhfj95",
        "row": 23134,
        "state": "",
        "group": "00631393",
        "group_num": [
            "reck78Kfw5DKknQWI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 2100,
        "id": "reciJqWXtXK6Q42i4",
        "row": 23133,
        "state": "",
        "group": "",
        "group_num": [
            "recKYFfbbaC4RehME"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "reci67KdyLU5cGGrF",
        "row": 23127,
        "state": "",
        "group": "00630994",
        "group_num": [
            "recSZ17kbg5cTOMJf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recksgs1AvMMxxFdO",
        "row": 23128,
        "state": "",
        "group": "00631032",
        "group_num": [
            "recHJ0M0XLPnfE0XV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "2024": 290,
        "id": "reclvEo4CW6LcqhUQ",
        "row": 23114,
        "state": "",
        "group": "00629966",
        "group_num": [
            "recq71O7sWeuZTd1M"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 519,
        "id": "reclzLFf9yDimxH7E",
        "row": 23112,
        "state": "",
        "group": "",
        "group_num": [
            "recdhMWYbB26SyR8E"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 803,
        "id": "recm7oByGhbsicSMJ",
        "row": 23147,
        "state": "",
        "group": "",
        "group_num": [
            "recZawbpzND55x488"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recSPnWkUMfbwpMqF",
        "row": 23137,
        "state": "",
        "group": "00631484",
        "group_num": [
            "recKaJf5r46mrINaY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "recScB1xePylSD57m",
        "row": 23139,
        "state": "",
        "group": "00631504",
        "group_num": [
            "rec2JYnDBtxx9lzdK"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "recTzCNXEw11QLuFb",
        "row": 23119,
        "state": "",
        "group": "",
        "group_num": [
            "recH1OsU2hhgmvAdi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 913,
        "id": "recWVVJiBYAFOre5b",
        "row": 23130,
        "state": "",
        "group": "",
        "group_num": [
            "recCG62ZcfbbjNWux"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1781,
        "id": "recXz83aJGCcP42G3",
        "row": 23140,
        "state": "",
        "group": "",
        "group_num": [
            "recJnjK2tjY3NELGI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1104,
        "id": "recYJ7uvyoLmdunIF",
        "row": 23146,
        "state": "",
        "group": "00632582",
        "group_num": [
            "rec73JmE6TAABhX5V"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "2023": 3740,
        "id": "recZSSXL63aEuufCc",
        "row": 23142,
        "state": "",
        "group": "00631683",
        "group_num": [
            "recKsNuLI24qki8qG"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 171,
        "id": "recZ2jo538WUuy1lp",
        "row": 23116,
        "state": "",
        "group": "",
        "group_num": [
            "recMcC4jAgYVHRTlT"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 101,
        "id": "rec07EWOJzwzzlf50",
        "row": 23153,
        "state": "",
        "group": "",
        "group_num": [
            "recVYTrhzLDuc53K5"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1079,
        "id": "rec1vXNzLPGTIVXue",
        "row": 23135,
        "state": "",
        "group": "00631393",
        "group_num": [
            "reck78Kfw5DKknQWI"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "rec2X3udEnKDYzaHJ",
        "row": 23118,
        "state": "",
        "group": "00630674",
        "group_num": [
            "reci7EzvBojy7s7rr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1975,
        "id": "rec2MpkLzMDzdqRsV",
        "row": 23117,
        "state": "",
        "group": "00630674",
        "group_num": [
            "reci7EzvBojy7s7rr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 473,
        "id": "rec3yg7zl1LQEHDCe",
        "row": 23156,
        "state": "",
        "group": "",
        "group_num": [
            "recgh9tT5kGjJOXrc"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 229,
        "id": "rec4aNKDF4MFmDEcj",
        "row": 23145,
        "state": "",
        "group": "",
        "group_num": [
            "recuJsjm7ynfeWh3e"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "rec7WyKDjYulxUevR",
        "row": 23138,
        "state": "",
        "group": "00631484",
        "group_num": [
            "recKaJf5r46mrINaY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "rec79lW3TXczzInND",
        "row": 23115,
        "state": "",
        "group": "",
        "group_num": [
            "rec4cw2mCkcw6OY18"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "rec96trEhJYC5Lu4H",
        "row": 23143,
        "state": "",
        "group": "00631831",
        "group_num": [
            "recS54MIlPgdV5VNV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "rec98vuYqeQ1UrHpy",
        "row": 23107,
        "state": "",
        "group": "00629079",
        "group_num": [
            "recdPMLe7VzFEdAoT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2937,
        "id": "recbHylbwHlZSgb8A",
        "row": 23111,
        "state": "",
        "group": "",
        "group_num": [
            "recesFcMQLcoXYiP4"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1021,
        "id": "recpqb3HZT33SrobU",
        "row": 23109,
        "state": "",
        "group": "",
        "group_num": [
            "recesFcMQLcoXYiP4"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recqF7MPS1c3vNiGE",
        "row": 23124,
        "state": "",
        "group": "",
        "group_num": [
            "recceBCj1qNm8ha8v"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "id": "rectRz0lWYJNh8Ynu",
        "row": 23122,
        "state": "",
        "group": "00630845",
        "group_num": [
            "recIkBudHCPKzsGxM"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 250,
        "id": "recvfvnxvbgWTXbzU",
        "row": 23131,
        "state": "",
        "group": "",
        "group_num": [
            "recmdP4NVjd0kgHSL"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 794,
        "id": "recwJYjoPBSxHw5Le",
        "row": 23126,
        "state": "",
        "group": "00630994",
        "group_num": [
            "recSZ17kbg5cTOMJf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2622,
        "id": "recxMX08gNpjJG2qm",
        "row": 23108,
        "state": "",
        "group": "00629526",
        "group_num": [
            "recq80LYFtWODtOok"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 621,
        "id": "recyayiVNE3cPUTjO",
        "row": 23151,
        "state": "",
        "group": "00632673",
        "group_num": [
            "recLSGchoEWS8djuK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 60,
        "2023": 60,
        "id": "reczHtzqUvMTBtZbr",
        "row": 23121,
        "state": "",
        "group": "00630845",
        "group_num": [
            "recIkBudHCPKzsGxM"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "id": "recz3rvTqrKRz8t8U",
        "row": 23129,
        "state": "",
        "group": "",
        "group_num": [
            "recFDiGHlWcKkkNA8"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 22,
        "id": "reczqlt2qLl0FwiY3",
        "row": 23113,
        "state": "",
        "group": "00629966",
        "group_num": [
            "recq71O7sWeuZTd1M"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recCrCGUgim8XJFry",
        "row": 23144,
        "state": "",
        "group": "00631831",
        "group_num": [
            "recS54MIlPgdV5VNV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1177,
        "id": "recDSzLnPEtw2HyKp",
        "row": 23149,
        "state": "",
        "group": "",
        "group_num": [
            "recjOCsJEKJyv4yxo"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1021,
        "id": "recDLE6UK35Qlal4t",
        "row": 23110,
        "state": "",
        "group": "",
        "group_num": [
            "recesFcMQLcoXYiP4"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 848,
        "id": "recEgZkjWB0OjuVM5",
        "row": 23148,
        "state": "",
        "group": "",
        "group_num": [
            "recZawbpzND55x488"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2384,
        "id": "recFGoQnCoxI6Nuhj",
        "row": 23120,
        "state": "",
        "group": "",
        "group_num": [
            "recH1OsU2hhgmvAdi"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2640,
        "id": "recGuiKtIUw2SBteP",
        "row": 23123,
        "state": "",
        "group": "",
        "group_num": [
            "recAz6Ti8McO52Y8d"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1706,
        "id": "recGIUhmOpUXEhD50",
        "row": 23125,
        "state": "",
        "group": "00630994",
        "group_num": [
            "recSZ17kbg5cTOMJf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 248,
        "id": "recIXoDAcbLK5blPA",
        "row": 23155,
        "state": "",
        "group": "",
        "group_num": [
            "recgh9tT5kGjJOXrc"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recJFHTd9e1JRn5zt",
        "row": 23132,
        "state": "",
        "group": "",
        "group_num": [
            "recKYFfbbaC4RehME"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 779,
        "id": "recJ25ZiZHd52c4RV",
        "row": 23152,
        "state": "",
        "group": "",
        "group_num": [
            "recyxM3DpWW9Jeqdi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recMp2iGmpvNXQVkt",
        "row": 23154,
        "state": "",
        "group": "",
        "group_num": [
            "recVYTrhzLDuc53K5"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recN3k6ZESvptryzo",
        "row": 23136,
        "state": "",
        "group": "00631477",
        "group_num": [
            "reco8zAuCuPDMf1m0"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recgtp7rlVmiQsA9c",
        "row": 23201,
        "state": "",
        "group": "00635449",
        "group_num": [
            "recsAuJsTw2A3zI0K"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2112,
        "id": "rechlqxhdJhQPXomp",
        "row": 23158,
        "state": "",
        "group": "",
        "group_num": [
            "recVbzKFd9sr8r6cx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "reci5FEuq38OQo74D",
        "row": 23160,
        "state": "",
        "group": "",
        "group_num": [
            "recDytE86Y8vtANbe"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "2024": 3800,
        "id": "reckQ99CjA6NcSObD",
        "row": 23169,
        "state": "",
        "group": "",
        "group_num": [
            "recvZ2d0THQChAVY1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 858,
        "id": "reclWsb9RrYdnhtGg",
        "row": 23186,
        "state": "",
        "group": "",
        "group_num": [
            "recnREfp6Ch0kNwHV"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 233,
        "id": "recnY2Yodw5MQ7hJe",
        "row": 23165,
        "state": "",
        "group": "",
        "group_num": [
            "recjlHvhNBKpGEdrv"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 456,
        "id": "recO2cdh2z5Y5orMf",
        "row": 23205,
        "state": "",
        "group": "",
        "group_num": [
            "recvQPeEoKod65tkN"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recQVaBOmwLzFsNKQ",
        "row": 23171,
        "state": "",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 990,
        "id": "recQ2Ac8zuXfTRJAf",
        "row": 23187,
        "state": "",
        "group": "",
        "group_num": [
            "recnREfp6Ch0kNwHV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recRNYNBSEPqFoEWH",
        "row": 23189,
        "state": "",
        "group": "00634446",
        "group_num": [
            "recy1eXq2LKZhqBn2"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recR6Uiunt93xqaET",
        "row": 23175,
        "state": "",
        "group": "00633915",
        "group_num": [
            "recDSs2D9klVUr4nz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recSIy8HCpD0NTVQj",
        "row": 23178,
        "state": "",
        "group": "",
        "group_num": [
            "recEF6q6PVg2fM00d"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "id": "recSPHnqqCBZZd0DO",
        "row": 23202,
        "state": "",
        "group": "",
        "group_num": [
            "rec1j4gqnXswUKrFq"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recScygkB7bhjy8eR",
        "row": 23188,
        "state": "",
        "group": "",
        "group_num": [
            "recnREfp6Ch0kNwHV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4155,
        "id": "recYpR1QeSYyq6dsO",
        "row": 23159,
        "state": "",
        "group": "00633364",
        "group_num": [
            "rechaFWDfdSPA8IH8"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 722,
        "id": "rec0GV1TQ5Ls2uQ9O",
        "row": 23193,
        "state": "",
        "group": "00634545",
        "group_num": [
            "recACUuBm5G8OFqDc"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2080,
        "id": "rec12YVfIHkOZrBfM",
        "row": 23176,
        "state": "",
        "group": "",
        "group_num": [
            "recEF6q6PVg2fM00d"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 220,
        "id": "rec3i4WCPpQUPnZAV",
        "row": 23198,
        "state": "",
        "group": "",
        "group_num": [
            "recrSIWA4GU752FxF"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 860,
        "id": "rec4XaUkqenrkBIX8",
        "row": 23177,
        "state": "",
        "group": "",
        "group_num": [
            "recEF6q6PVg2fM00d"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 764,
        "id": "rec4YlK3ItJy9ua6Q",
        "row": 23206,
        "state": "",
        "group": "",
        "group_num": [
            "recUyxBoagawcMVsk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3891,
        "id": "rec5Y1evcrVQWlCFJ",
        "row": 23174,
        "state": "",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2700,
        "id": "rec7Uv06HYrfeJm3y",
        "row": 23182,
        "state": "",
        "group": "",
        "group_num": [
            "recpn8S5xRr6omWX1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "rec88PUnSkwWn9Yak",
        "row": 23179,
        "state": "",
        "group": "00634045",
        "group_num": [
            "recvYYzDYgj3iilJE"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1008,
        "id": "rec9wIE4DjCgOCuI5",
        "row": 23173,
        "state": "",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recbZ4E4isHpFw6XF",
        "row": 23184,
        "state": "",
        "group": "",
        "group_num": [
            "recWTcLOClXgwXY6p"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 455,
        "id": "recdekHCigIDqClpA",
        "row": 23161,
        "state": "",
        "group": "00633585",
        "group_num": [
            "rec7NZpgsEtGCKC64"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 861,
        "id": "recr1t1ULDtPBvwnw",
        "row": 23190,
        "state": "",
        "group": "00634446",
        "group_num": [
            "recy1eXq2LKZhqBn2"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recs8QxDcp3Vje1sS",
        "row": 23191,
        "state": "",
        "group": "",
        "group_num": [
            "reczS1St9LeYrW4Fe"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "reculKsCD2tau8XOr",
        "row": 23195,
        "state": "",
        "group": "",
        "group_num": [
            "rec9VMzWP1E3MhW5H"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 481,
        "id": "recymLPmoUjCs6ggs",
        "row": 23163,
        "state": "",
        "group": "",
        "group_num": [
            "recjlHvhNBKpGEdrv"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 908,
        "id": "reczzqV2tEvxv8iRc",
        "row": 23192,
        "state": "",
        "group": "00634545",
        "group_num": [
            "recACUuBm5G8OFqDc"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "recA8fbZIg5uowPbN",
        "row": 23164,
        "state": "",
        "group": "",
        "group_num": [
            "recjlHvhNBKpGEdrv"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 780,
        "id": "recE1YMsZGfZvTH7j",
        "row": 23166,
        "state": "",
        "group": "",
        "group_num": [
            "rech7SRM4jOxJcWFd"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recENUwVqxl5IB1Gz",
        "row": 23204,
        "state": "",
        "group": "",
        "group_num": [
            "recP6tQOSsMRca0NY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1845,
        "id": "recEhkrsGOhzdlx2l",
        "row": 23196,
        "state": "",
        "group": "00634822",
        "group_num": [
            "rec3iMjkH8QkASUyz"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2213,
        "id": "recF1SwZn97uZwcYs",
        "row": 23181,
        "state": "",
        "group": "",
        "group_num": [
            "rec14vJHGUfTO10lQ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recFFiM8zVnHyDE10",
        "row": 23197,
        "state": "",
        "group": "00634822",
        "group_num": [
            "rec3iMjkH8QkASUyz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recG1ssDZ9ARXWitE",
        "row": 23162,
        "state": "",
        "group": "00633585",
        "group_num": [
            "rec7NZpgsEtGCKC64"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recGi8v2EDtDVov46",
        "row": 23157,
        "state": "",
        "group": "",
        "group_num": [
            "recP4rMmBF8D8hMTt"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recHUuewM2vpWQXPe",
        "row": 23203,
        "state": "",
        "group": "",
        "group_num": [
            "recP6tQOSsMRca0NY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2252,
        "id": "recHzb9kVaokshIZW",
        "row": 23168,
        "state": "",
        "group": "00633807",
        "group_num": [
            "rec1KrHsse0NVy8ux"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "recHbwKt2ZVpRUrfd",
        "row": 23180,
        "state": "",
        "group": "00634045",
        "group_num": [
            "recvYYzDYgj3iilJE"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "2023": 280,
        "id": "recIs9GcBQQ9y9nER",
        "row": 23183,
        "state": "",
        "group": "00634311",
        "group_num": [
            "recnEYG2iUATH5u8X"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 793,
        "id": "recIEVy84KiU3wudd",
        "row": 23167,
        "state": "",
        "group": "",
        "group_num": [
            "rech7SRM4jOxJcWFd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "2023": 839,
        "id": "recKZtxs8WKeymQAd",
        "row": 23172,
        "state": "",
        "group": "00633898",
        "group_num": [
            "recRVNosLI3rF8Axf"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 258,
        "id": "recKGzrWEFnMlyDqZ",
        "row": 23200,
        "state": "",
        "group": "",
        "group_num": [
            "recoedVoC8VPpEL9p"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recK7JGCr0FLpkHBo",
        "row": 23170,
        "state": "",
        "group": "",
        "group_num": [
            "recvZ2d0THQChAVY1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1108,
        "id": "recKl5dT8ExrhomfL",
        "row": 23194,
        "state": "",
        "group": "",
        "group_num": [
            "recMTjMGJTafVAse7"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recMIgYlJBgGeOHM8",
        "row": 23185,
        "state": "",
        "group": "",
        "group_num": [
            "recnREfp6Ch0kNwHV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2039,
        "id": "recMKCuxNPd7vaKyp",
        "row": 23199,
        "state": "",
        "group": "",
        "group_num": [
            "recoedVoC8VPpEL9p"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "receIJyD6p6GzFCDp",
        "row": 23224,
        "state": "",
        "group": "",
        "group_num": [
            "rec590ZNQSFg8d8Q9"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 166,
        "id": "recenFSPBq2VlJk3y",
        "row": 23218,
        "state": "",
        "group": "",
        "group_num": [
            "receBHaDOAwyDSGKt"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 60,
        "2024": 60,
        "id": "recfRsclRpKzYQvSF",
        "row": 23229,
        "state": "",
        "group": "",
        "group_num": [
            "recAsYCeoBQFdVEA7"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2025,
        "id": "rechW71uq4xEt2nML",
        "row": 23249,
        "state": "",
        "group": "00650817",
        "group_num": [
            "rec0JEhyLoSdGHuLU"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1031,
        "id": "rechenjKmmy2M38ee",
        "row": 23210,
        "state": "",
        "group": "",
        "group_num": [
            "rec4lKTDoViLcBAI2"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 831,
        "2024": 836,
        "id": "recizaBQwhNC0ziv2",
        "row": 23240,
        "state": "",
        "group": "",
        "group_num": [
            "recxhd5LBnrOFgVYO"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 22,
        "id": "reciDbO7jFULLrwRd",
        "row": 23208,
        "state": "",
        "group": "00636468",
        "group_num": [
            "rec69rRuQfK77kwPP"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 710,
        "id": "reckHZNNrkkHXjzAt",
        "row": 23211,
        "state": "",
        "group": "",
        "group_num": [
            "rec4lKTDoViLcBAI2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recm1mpx1sNlAK5lz",
        "row": 23241,
        "state": "",
        "group": "",
        "group_num": [
            "rec3YSvVRKovfiL18"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "id": "recnaFxMabWwu9aii",
        "row": 23253,
        "state": "",
        "group": "",
        "group_num": [
            "reczPyxNLFDfvcSQW"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 255,
        "id": "recnfKr7t7tVkeX01",
        "row": 23228,
        "state": "",
        "group": "",
        "group_num": [
            "recdedgjmDdTSHGeV"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3740,
        "id": "recOPziNxvcCHS6qx",
        "row": 23238,
        "state": "",
        "group": "",
        "group_num": [
            "recMS7qbgAdwduP25"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 60,
        "2024": 60,
        "id": "recQOEsbH9ondiE5p",
        "row": 23247,
        "state": "",
        "group": "",
        "group_num": [
            "recBBnoCtaL08Q8cm"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2157,
        "id": "recRH1fNm6EB5xmCb",
        "row": 23237,
        "state": "",
        "group": "",
        "group_num": [
            "recVScOKvRhjPsjEA"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "recUU8hwbyVEQp4IG",
        "row": 23239,
        "state": "",
        "group": "",
        "group_num": [
            "recMyeLtYp8tHraOZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 255,
        "id": "recUDGN5maugvBCF7",
        "row": 23215,
        "state": "",
        "group": "",
        "group_num": [
            "recrfk4aXhtgbEBGj"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 168,
        "2024": 166,
        "id": "recUquwxfjBSKGCmh",
        "row": 23231,
        "state": "",
        "group": "",
        "group_num": [
            "recwtR1JuQvsprgcp"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 448,
        "id": "recVoyAfGX9IAwHsY",
        "row": 23244,
        "state": "",
        "group": "",
        "group_num": [
            "recSrAXFfTejPNvqD"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 794,
        "id": "recW49L3DdoRLHJYk",
        "row": 23220,
        "state": "",
        "group": "",
        "group_num": [
            "recbYkRfT1UljFsh2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rec0o4PxZ1R6DQkSv",
        "row": 23217,
        "state": "",
        "group": "",
        "group_num": [
            "recfIrKJy7zLL2dmj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 366,
        "id": "rec2o4u2mR0L55U1g",
        "row": 23214,
        "state": "",
        "group": "",
        "group_num": [
            "recO0JmdHI76PbBMK"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2183,
        "id": "rec3JB5wirUxjZ84M",
        "row": 23222,
        "state": "",
        "group": "00637932",
        "group_num": [
            "rec1wSHvatg8KPPM5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 243,
        "2024": 243,
        "id": "rec4MsEXpvHVSzo5T",
        "row": 23219,
        "state": "",
        "group": "",
        "group_num": [
            "receBHaDOAwyDSGKt"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "rec6sa5diiqSPEL4j",
        "row": 23230,
        "state": "",
        "group": "",
        "group_num": [
            "recAsYCeoBQFdVEA7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "2023": 682,
        "id": "rec78abXPga6efKHa",
        "row": 23212,
        "state": "",
        "group": "",
        "group_num": [
            "recO0JmdHI76PbBMK"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 295,
        "id": "rec8rT6koNeKvGnCm",
        "row": 23254,
        "state": "",
        "group": "",
        "group_num": [
            "rec9aUv9lQTxN4Wxh"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2282,
        "id": "rec9SJDf2FOC2SFZ0",
        "row": 23233,
        "state": "",
        "group": "",
        "group_num": [
            "recKpGJUX1jDM1bNk"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec9P3jpZ8j9KAjk2",
        "row": 23243,
        "state": "",
        "group": "",
        "group_num": [
            "rec3YSvVRKovfiL18"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "rec9RNBK2HmHtZkFe",
        "row": 23248,
        "state": "",
        "group": "",
        "group_num": [
            "recBBnoCtaL08Q8cm"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 828,
        "id": "recbVZJKfO1IbEKDe",
        "row": 23236,
        "state": "",
        "group": "",
        "group_num": [
            "recVScOKvRhjPsjEA"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recbYjAEac2ezdN2I",
        "row": 23216,
        "state": "",
        "group": "",
        "group_num": [
            "rechvIUr1ZelfKME1"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 555,
        "id": "recckdGBEy11MEUPz",
        "row": 23209,
        "state": "",
        "group": "00636468",
        "group_num": [
            "rec69rRuQfK77kwPP"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recqFqZbPgSpn9upL",
        "row": 23246,
        "state": "",
        "group": "",
        "group_num": [
            "recvlMzoiHX16IYSN"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 934,
        "id": "recs0h4OEDKcqhEXk",
        "row": 23251,
        "state": "",
        "group": "",
        "group_num": [
            "reckRMC65Y2LfD493"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "id": "recsE2Qfn6iY7K3kP",
        "row": 23252,
        "state": "",
        "group": "",
        "group_num": [
            "reczPyxNLFDfvcSQW"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "rectWepUOHQOkGVng",
        "row": 23207,
        "state": "",
        "group": "",
        "group_num": [
            "reclKJ49eDvqwPL6j"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2001,
        "id": "recu4kcYa86z4nwmh",
        "row": 23235,
        "state": "",
        "group": "",
        "group_num": [
            "recVScOKvRhjPsjEA"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 824,
        "id": "recxVhTnIHE7xX37M",
        "row": 23225,
        "state": "",
        "group": "",
        "group_num": [
            "rec590ZNQSFg8d8Q9"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 906,
        "id": "recxLg72Q39ZwhxDN",
        "row": 23242,
        "state": "",
        "group": "",
        "group_num": [
            "rec3YSvVRKovfiL18"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2585,
        "id": "recxhTUjjRq5chlg7",
        "row": 23226,
        "state": "",
        "group": "00637981",
        "group_num": [
            "rec7x9qkmTMoIafRd"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2636,
        "id": "recySIRJYxsP6nBfT",
        "row": 23223,
        "state": "",
        "group": "00637932",
        "group_num": [
            "rec1wSHvatg8KPPM5"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2870,
        "id": "recyoXLgvYsRUvBrc",
        "row": 23221,
        "state": "",
        "group": "",
        "group_num": [
            "recEQ9v52QXuR74Wq"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 724,
        "id": "reczW4CLFr7IZUWCB",
        "row": 23245,
        "state": "",
        "group": "",
        "group_num": [
            "recag7OrOgvoGoMjP"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 59,
        "2023": 29,
        "2024": 45,
        "id": "recBnLjDKgPCxJhOX",
        "row": 23256,
        "state": "",
        "group": "00880800",
        "group_num": [
            "recHOrSdwtSyOLPCu"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 106,
        "id": "recChMbPmThxNQpLN",
        "row": 23213,
        "state": "",
        "group": "",
        "group_num": [
            "recO0JmdHI76PbBMK"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1075,
        "id": "recGf7P5aqwRPs5ug",
        "row": 23255,
        "state": "",
        "group": "00880800",
        "group_num": [
            "recHOrSdwtSyOLPCu"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 684,
        "id": "recJ6Zwmuix7XLIpP",
        "row": 23250,
        "state": "",
        "group": "00650817",
        "group_num": [
            "rec0JEhyLoSdGHuLU"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 182,
        "id": "recJaNqzXoxZYsBi8",
        "row": 23232,
        "state": "",
        "group": "",
        "group_num": [
            "recwtR1JuQvsprgcp"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 177,
        "2024": 174,
        "id": "recKbWY8lt1RpNwgv",
        "row": 23227,
        "state": "",
        "group": "",
        "group_num": [
            "recdedgjmDdTSHGeV"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2297,
        "id": "recN59kqgfHEVR6mE",
        "row": 23234,
        "state": "",
        "group": "",
        "group_num": [
            "recKpGJUX1jDM1bNk"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 668,
        "id": "recfD9VXxCpnCinOO",
        "row": 23306,
        "state": "",
        "group": "",
        "group_num": [
            "rec9SdjMjCSNR8NRU"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5930,
        "id": "rech94hK8ZUlHOkbZ",
        "row": 23294,
        "state": "",
        "group": "",
        "group_num": [
            "recCiHzoQB9SuSGKw"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "rechg0gaS2WMHaWym",
        "row": 23264,
        "state": "",
        "group": "21587245",
        "group_num": [
            "recdSayejbMBmSCQT"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 815,
        "id": "reciPwDu0o79nF8rQ",
        "row": 23273,
        "state": "",
        "group": "",
        "group_num": [
            "recrsTTJN2LKYKTJw"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 756,
        "id": "recibktAx2w5r8TVF",
        "row": 23276,
        "state": "",
        "group": "",
        "group_num": [
            "recByvRkgzgsu5lvb"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1113,
        "id": "reck6Xn4OOt5CQEFg",
        "row": 23288,
        "state": "",
        "group": "",
        "group_num": [
            "recBcbEhLOsBpgpQx"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 419,
        "2023": 290,
        "id": "reckkftF2nL2xQxg9",
        "row": 23297,
        "state": "",
        "group": "CRPTEXC",
        "group_num": [
            "recunocTr5sPz9Yf9"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 76,
        "id": "reclwqVqcoZUXo0P6",
        "row": 23285,
        "state": "",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 164,
        "id": "recl8ik7bpOqCV9rO",
        "row": 23270,
        "state": "",
        "group": "",
        "group_num": [
            "recudryv07LuNoRSZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recmwchIbo1txMQ2b",
        "row": 23287,
        "state": "",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recPgAlIVXsjfT7A9",
        "row": 23290,
        "state": "",
        "group": "",
        "group_num": [
            "recVfH1OmOr2hHyat"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1677,
        "id": "recPqjhKHt2s7y201",
        "row": 23277,
        "state": "",
        "group": "",
        "group_num": [
            "recdVYGwKN5sLnX8k"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2086,
        "id": "recQgOqOc5DAO0g9F",
        "row": 23271,
        "state": "",
        "group": "",
        "group_num": [
            "recudryv07LuNoRSZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recRgBhLcZSr03hjH",
        "row": 23268,
        "state": "",
        "group": "",
        "group_num": [
            "recudryv07LuNoRSZ"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "recSUyy0aR6zDzWvi",
        "row": 23280,
        "state": "",
        "group": "",
        "group_num": [
            "reci2rc3HEZNncfF7"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 394,
        "id": "recTsuIqosPoXwlj6",
        "row": 23282,
        "state": "",
        "group": "APWU00",
        "group_num": [
            "rec8BBDrRb038Hloa"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1639,
        "id": "recTKuC8yoF6HYhU4",
        "row": 23272,
        "state": "",
        "group": "",
        "group_num": [
            "rec8D30rlnitH4wje"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "2024": 2000,
        "id": "recTbt5iduy6f5DGa",
        "row": 23266,
        "state": "",
        "group": "",
        "group_num": [
            "recHYmz1bjuHAOCl1"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3740,
        "id": "recWv2CLzs7WT2rJr",
        "row": 23265,
        "state": "",
        "group": "006311365",
        "group_num": [
            "recyuXUU9gbgO0ki2"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2538,
        "id": "recWCVtiTqRqgrfIb",
        "row": 23293,
        "state": "",
        "group": "",
        "group_num": [
            "rec2QOuY5ebrjTYTN"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 205,
        "id": "recWd2Le2dtJo0qnB",
        "row": 23284,
        "state": "",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 531,
        "id": "recX7VyHyjzLwiw9z",
        "row": 23303,
        "state": "",
        "group": "",
        "group_num": [
            "recM7kZdkGbg0MdhL"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3200,
        "id": "recYNTlQ17FJVlJbg",
        "row": 23289,
        "state": "",
        "group": "",
        "group_num": [
            "recMmoveJUSi0289g"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 251,
        "id": "recZtPa32JHs4mOqU",
        "row": 23269,
        "state": "",
        "group": "",
        "group_num": [
            "recudryv07LuNoRSZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 306,
        "id": "rec0b8Ty6ssl3Zu6e",
        "row": 23275,
        "state": "",
        "group": "",
        "group_num": [
            "recbQ5VJT1YO9nVhZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 993,
        "id": "rec1lkHcWbL9gaJjn",
        "row": 23300,
        "state": "",
        "group": "DAC198G1",
        "group_num": [
            "recLqYwY6M3ABJVKr"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1226,
        "2024": 1100,
        "id": "rec1qFy8dQP6qaesb",
        "row": 23261,
        "state": "",
        "group": "",
        "group_num": [
            "recFcKtBZbavxsfoj"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 237,
        "id": "rec3X2lJhO58iZ4sC",
        "row": 23304,
        "state": "",
        "group": "",
        "group_num": [
            "recM7kZdkGbg0MdhL"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "rec3Iy7WeS66pbgYQ",
        "row": 23305,
        "state": "",
        "group": "",
        "group_num": [
            "rec9SdjMjCSNR8NRU"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "rec6idA5iaAUDMucW",
        "row": 23286,
        "state": "",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 415,
        "id": "rec8NFwg78z5Ny3n3",
        "row": 23267,
        "state": "",
        "group": "",
        "group_num": [
            "recoWZaMtwEKH0y5J"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2453,
        "id": "rec970RgUosrPOWY8",
        "row": 23301,
        "state": "",
        "group": "EUSA0117",
        "group_num": [
            "recRXbyms1YaMhyev"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 402,
        "id": "recabpEn45hww0tUM",
        "row": 23262,
        "state": "",
        "group": "",
        "group_num": [
            "recFcKtBZbavxsfoj"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recbkzrfOvHyXIii1",
        "row": 23302,
        "state": "",
        "group": "EUSA0117",
        "group_num": [
            "recRXbyms1YaMhyev"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 306,
        "2023": 286,
        "2024": 289,
        "id": "recpMrMrg3JLJreSE",
        "row": 23257,
        "state": "",
        "group": "00880800",
        "group_num": [
            "recHOrSdwtSyOLPCu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "recrGIfDD1tKW7Z8l",
        "row": 23260,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Iyw5Af69uEUbu"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1382,
        "2024": 1500,
        "id": "recsKGizIEDFSU8zk",
        "row": 23299,
        "state": "",
        "group": "DAC198G1",
        "group_num": [
            "recLqYwY6M3ABJVKr"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3418,
        "id": "recu4n57LiJ77XEpK",
        "row": 23291,
        "state": "",
        "group": "",
        "group_num": [
            "reciRvFT3YWKMUQVZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 993,
        "id": "recuefW1HYzL4kUnJ",
        "row": 23298,
        "state": "",
        "group": "DAC198G1",
        "group_num": [
            "recLqYwY6M3ABJVKr"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recyR88l6ZyKZBqfa",
        "row": 23258,
        "state": "",
        "group": "00881200",
        "group_num": [
            "recdj30cw3SSMQ1uS"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 357,
        "id": "recyfmqnUvty9T36S",
        "row": 23283,
        "state": "",
        "group": "APWU000",
        "group_num": [
            "rec4h3qZx5wSrz2HC"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 180,
        "id": "reczI6E2zglIQ0468",
        "row": 23263,
        "state": "",
        "group": "",
        "group_num": [
            "recxe70CL49Py2tRQ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "id": "recA8bUkEoz7A20um",
        "row": 23292,
        "state": "",
        "group": "",
        "group_num": [
            "recZUxoiCZuyhcFuI"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recCwQuW5UgQr4gw5",
        "row": 23281,
        "state": "",
        "group": "",
        "group_num": [
            "reci2rc3HEZNncfF7"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 304,
        "id": "recDsSYscnB0Zg0gn",
        "row": 23278,
        "state": "",
        "group": "",
        "group_num": [
            "recdVYGwKN5sLnX8k"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1657,
        "id": "recFPKdmDVncrfddB",
        "row": 23279,
        "state": "",
        "group": "",
        "group_num": [
            "recdVYGwKN5sLnX8k"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2336,
        "id": "recFhHOqZIyQSx1BA",
        "row": 23259,
        "state": "",
        "group": "",
        "group_num": [
            "recUK53fvrYwbMCCj"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2803,
        "id": "recFqdfvDdBvuIaPq",
        "row": 23295,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 416,
        "id": "recHC2EK9cHj2ek6g",
        "row": 23296,
        "state": "",
        "group": "CRPTEXC",
        "group_num": [
            "recunocTr5sPz9Yf9"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 791,
        "id": "recIryEgWuFRi8DfV",
        "row": 23274,
        "state": "",
        "group": "",
        "group_num": [
            "recrsTTJN2LKYKTJw"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 817,
        "id": "recgL6WN2z2DigLJ9",
        "row": 23318,
        "state": "",
        "group": "",
        "group_num": [
            "recZ7A43zqtaPwqQU"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recjSQ5LyxGyooyuW",
        "row": 23326,
        "state": "",
        "group": "",
        "group_num": [
            "recF7HxyofIxKZRFA"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 468,
        "id": "recjzaYYsvJy3EOGj",
        "row": 23328,
        "state": "",
        "group": "",
        "group_num": [
            "recyUtoxGsBcQb5Br"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "recllht7bClPvvNWI",
        "row": 23324,
        "state": "",
        "group": "",
        "group_num": [
            "rec7h0iGeDiZkQpRe"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2108,
        "id": "recntQgDdoY3lr4RT",
        "row": 23330,
        "state": "",
        "group": "XB0000",
        "group_num": [
            "recYseaiHxEF00F2i"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1313,
        "id": "recRNsQaZmO0LrG4u",
        "row": 23314,
        "state": "",
        "group": "",
        "group_num": [
            "recGFVVdBAu5OJwzi"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 829,
        "id": "recSVVjSobTMTRjxL",
        "row": 23323,
        "state": "",
        "group": "",
        "group_num": [
            "recUoAM9V04obu0VZ"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recURYqUa3xr2BuIE",
        "row": 23307,
        "state": "",
        "group": "",
        "group_num": [
            "recKArFubYcgW4YKV"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1743,
        "id": "recWaIU4GJpN3HsTu",
        "row": 23316,
        "state": "",
        "group": "ML0000",
        "group_num": [
            "receOjAkVAMGurEjD"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 726,
        "id": "recWr5NDUmAn8e9dp",
        "row": 23327,
        "state": "",
        "group": "",
        "group_num": [
            "recF7HxyofIxKZRFA"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3200,
        "id": "rec2j23bwZxVuPh3F",
        "row": 23319,
        "state": "",
        "group": "",
        "group_num": [
            "rec3t2DkoxOLiAYDf"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1036,
        "id": "rec410aEwe5CnUuuH",
        "row": 23313,
        "state": "",
        "group": "",
        "group_num": [
            "recR0TwKibzKrY7GY"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 510,
        "2024": 415,
        "id": "rec483l6jTdx9BeMw",
        "row": 23315,
        "state": "",
        "group": "",
        "group_num": [
            "recQhJfFy8vmnNhZq"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "rec8HSL1MUpM6T7Zi",
        "row": 23325,
        "state": "",
        "group": "",
        "group_num": [
            "rec7h0iGeDiZkQpRe"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1060,
        "id": "recc58Te4vJVdWZF2",
        "row": 23321,
        "state": "",
        "group": "",
        "group_num": [
            "recUoAM9V04obu0VZ"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1375,
        "id": "reco03PfiHghgm8CR",
        "row": 23309,
        "state": "",
        "group": "",
        "group_num": [
            "recwFFd1AXrYptto5"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1601,
        "id": "reco6a5w45yzLD6wV",
        "row": 23329,
        "state": "",
        "group": "XB0000",
        "group_num": [
            "recYseaiHxEF00F2i"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recsJ5RrzXlVpkByn",
        "row": 23310,
        "state": "",
        "group": "",
        "group_num": [
            "recTle3Y3JL7EPogZ"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "id": "recx7Zehlj3r4r5qC",
        "row": 23322,
        "state": "",
        "group": "",
        "group_num": [
            "recUoAM9V04obu0VZ"
        ],
        "procedure": "OP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5000,
        "id": "recAOU2PESWmXwqVh",
        "row": 23308,
        "state": "",
        "group": "",
        "group_num": [
            "recKArFubYcgW4YKV"
        ],
        "procedure": "Detox",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recFI0h1ntIzy1ajz",
        "row": 23312,
        "state": "",
        "group": "",
        "group_num": [
            "recR0TwKibzKrY7GY"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1271,
        "2024": 1244,
        "id": "recFJja9p7nmkeHhp",
        "row": 23331,
        "state": "",
        "group": "",
        "group_num": [
            "recPwqIznmAeQAZHs"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 260,
        "id": "recHXPA69raiLGVLQ",
        "row": 23317,
        "state": "",
        "group": "ML0000",
        "group_num": [
            "receOjAkVAMGurEjD"
        ],
        "procedure": "PHP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1305,
        "id": "recM09s1N9mgVml9L",
        "row": 23320,
        "state": "",
        "group": "S2860",
        "group_num": [
            "recQMl5Q9iQbEGwJz"
        ],
        "procedure": "IOP",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1124,
        "id": "recMe0SOvgBoEqu4r",
        "row": 23311,
        "state": "",
        "group": "",
        "group_num": [
            "recR0TwKibzKrY7GY"
        ],
        "procedure": "RTC",
        "provider": "CIGNA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 491,
        "id": "recfyO1xnjqIe2sx8",
        "row": 23332,
        "state": "",
        "group": "",
        "group_num": [
            "recMZwUy33GPtTNEP"
        ],
        "procedure": "RTC",
        "provider": "CIGNA GLOBAL"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1910,
        "id": "recE0N60G5XMxuD6V",
        "row": 23333,
        "state": "",
        "group": "",
        "group_num": [
            "recGIq9bcMFuJWuJ8"
        ],
        "procedure": "RTC",
        "provider": "CIGNA MEDICARE ACCESS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 754,
        "id": "recPmjTh7KLjstatX",
        "row": 23337,
        "state": "",
        "group": "10107218",
        "group_num": [
            "recjGRWf2O0wsuIoy"
        ],
        "procedure": "PHP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 620,
        "id": "recTIwOHUg9qxBlQY",
        "row": 23346,
        "state": "",
        "group": "",
        "group_num": [
            "recaZCrpnhVMIUv5E"
        ],
        "procedure": "IOP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 620,
        "2023": 1099,
        "id": "rec0LHf1DddLk4rTk",
        "row": 23339,
        "state": "",
        "group": "10107218",
        "group_num": [
            "recjGRWf2O0wsuIoy"
        ],
        "procedure": "IOP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 620,
        "2023": 616,
        "2024": 748,
        "id": "rec2lkO1RvQeAGSsZ",
        "row": 23343,
        "state": "",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "IOP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 677,
        "2023": 698,
        "2024": 1100,
        "id": "rec4rUv9uYQoF38jO",
        "row": 23341,
        "state": "",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "PHP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1449,
        "2024": 1362,
        "id": "rec5xp60ILaeOlQqS",
        "row": 23335,
        "state": "",
        "group": "10107212",
        "group_num": [
            "recOMck6JtJEJGjuL"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 796,
        "id": "rec9LKBgXW74DGoVo",
        "row": 23348,
        "state": "",
        "group": "S2612",
        "group_num": [
            "rect04aF8JvxOqmnX"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1060,
        "2023": 1296,
        "id": "reca4KXIrW2PHNAGS",
        "row": 23340,
        "state": "",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "RTC",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1138,
        "id": "reccuyuzPDO6Ek04n",
        "row": 23336,
        "state": "",
        "group": "10107218",
        "group_num": [
            "recjGRWf2O0wsuIoy"
        ],
        "procedure": "RTC",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1851,
        "2023": 2050,
        "id": "recuPysDCr1Ca8oHb",
        "row": 23344,
        "state": "",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "Detox",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1088,
        "id": "recBSw0I3w79rTzlw",
        "row": 23347,
        "state": "",
        "group": "",
        "group_num": [
            "recJE1ZS11GlAMcD6"
        ],
        "procedure": "IOP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 920,
        "id": "recCWSHcxTbcoGe15",
        "row": 23345,
        "state": "",
        "group": "",
        "group_num": [
            "recaZCrpnhVMIUv5E"
        ],
        "procedure": "PHP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1496,
        "id": "recDmjjaoyqSqo6cx",
        "row": 23334,
        "state": "",
        "group": "10107212",
        "group_num": [
            "recOMck6JtJEJGjuL"
        ],
        "procedure": "RTC",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "2024": 40,
        "id": "recF2aKDbs4lgfhnr",
        "row": 23342,
        "state": "",
        "group": "10107319",
        "group_num": [
            "recEnGNY7uhmaWKb7"
        ],
        "procedure": "OP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "2023": 40,
        "2024": 33,
        "id": "recGgQ41OXOQtXGin",
        "row": 23338,
        "state": "",
        "group": "10107218",
        "group_num": [
            "recjGRWf2O0wsuIoy"
        ],
        "procedure": "OP",
        "provider": "COMPSYCH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recZTuCSbIgL8qCCL",
        "row": 23349,
        "state": "",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "IOP",
        "provider": "CONNECTICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1637,
        "id": "rec3RGSFYAg8Qh70Q",
        "row": 23350,
        "state": "",
        "group": "",
        "group_num": [
            "recQu8zLMSoTf7PaA"
        ],
        "procedure": "RTC",
        "provider": "CONSOCIATE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "id": "rec4R9FueU3E0D2I2",
        "row": 23352,
        "state": "",
        "group": "GR120",
        "group_num": [
            "recP2bG4YjkRygTrH"
        ],
        "procedure": "IOP",
        "provider": "CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 199,
        "id": "recGyT7NkeLWNHEnl",
        "row": 23351,
        "state": "",
        "group": "GR120",
        "group_num": [
            "recP2bG4YjkRygTrH"
        ],
        "procedure": "PHP",
        "provider": "CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 720,
        "id": "recPbIfolp8xxxzSl",
        "row": 23353,
        "state": "",
        "group": "",
        "group_num": [
            "recyCmzmH0Ia471E3"
        ],
        "procedure": "PHP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 630,
        "id": "rec6Fy1nCf6rG0UZn",
        "row": 23355,
        "state": "",
        "group": "",
        "group_num": [
            "rechIbowS3ESjkCRz"
        ],
        "procedure": "IOP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1600,
        "id": "rectzW4MG3ZCaV1ef",
        "row": 23356,
        "state": "",
        "group": "",
        "group_num": [
            "recUoJSliMCZfUrF3"
        ],
        "procedure": "PHP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 720,
        "id": "recK8n91rrwUM44Dy",
        "row": 23354,
        "state": "",
        "group": "",
        "group_num": [
            "rechIbowS3ESjkCRz"
        ],
        "procedure": "PHP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 971,
        "id": "recladYvyMcVHoqNj",
        "row": 23358,
        "state": "",
        "group": "",
        "group_num": [
            "recHpcgMufZ5NDGtL"
        ],
        "procedure": "PHP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1400,
        "id": "recd3ruGrKeBV0cXr",
        "row": 23357,
        "state": "",
        "group": "",
        "group_num": [
            "recUoJSliMCZfUrF3"
        ],
        "procedure": "IOP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 630,
        "id": "recq7KcPRLDY4qEDK",
        "row": 23359,
        "state": "",
        "group": "",
        "group_num": [
            "recHpcgMufZ5NDGtL"
        ],
        "procedure": "IOP",
        "provider": "DEAN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1786,
        "id": "recReccxvgGz7aK0m",
        "row": 23361,
        "state": "",
        "group": "",
        "group_num": [
            "rec5dB21ruVePaZ8c"
        ],
        "procedure": "IOP",
        "provider": "DENVER HEALTH MEDICAL PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 24,
        "id": "recwD1hx0yjYbp2k2",
        "row": 23360,
        "state": "",
        "group": "",
        "group_num": [
            "rec5dB21ruVePaZ8c"
        ],
        "procedure": "OP",
        "provider": "DENVER HEALTH MEDICAL PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 425,
        "id": "recVBnZ9jovJsaowT",
        "row": 23362,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "OP",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 150,
        "id": "recZOYHNq9WLZ0TSY",
        "row": 23366,
        "state": "",
        "group": "",
        "group_num": [
            "recUH89q0WsudrIjc"
        ],
        "procedure": "PHP",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "id": "reccsxjlAyUvlDiuX",
        "row": 23367,
        "state": "",
        "group": "",
        "group_num": [
            "recUH89q0WsudrIjc"
        ],
        "procedure": "OP",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "id": "recwwmt4nNsEqAPxf",
        "row": 23364,
        "state": "",
        "group": "",
        "group_num": [
            "recJRYXwnOdtWpUEm"
        ],
        "procedure": "IOP",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "2023": 325,
        "id": "recBxOKLgZw3v3XQd",
        "row": 23365,
        "state": "",
        "group": "",
        "group_num": [
            "recUH89q0WsudrIjc"
        ],
        "procedure": "RTC",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "recHn1HoAXGMN61yV",
        "row": 23368,
        "state": "",
        "group": "",
        "group_num": [
            "recUH89q0WsudrIjc"
        ],
        "procedure": "IOP",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 98,
        "id": "recJiakhC8Lx9SVpC",
        "row": 23363,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "IOP",
        "provider": "DESERET MUTUAL BENEFIT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1305,
        "id": "rechovi4p5wglJO5G",
        "row": 23369,
        "state": "",
        "group": "DAC379K",
        "group_num": [
            "recUVnV0lzvhfrJ4T"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED ADMINISTRATION CORP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1123,
        "id": "recxXFsSrlEYQiAt9",
        "row": 23370,
        "state": "",
        "group": "DAC463G",
        "group_num": [
            "recPgwdRdkDoLCv6M"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED ADMINISTRATION CORP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1086,
        "2023": 1174,
        "id": "recmHjd5LCP3L5Fmc",
        "row": 23372,
        "state": "",
        "group": ":DAC424K",
        "group_num": [
            "recyr8jk91L8LUOkF"
        ],
        "procedure": "Detox",
        "provider": "DIVERSIFIED GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1002,
        "2023": 977,
        "id": "recdJwQjh1ud2eRFC",
        "row": 23371,
        "state": "",
        "group": ":DAC424K",
        "group_num": [
            "recyr8jk91L8LUOkF"
        ],
        "procedure": "RTC",
        "provider": "DIVERSIFIED GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recjOAp9qGpyJhlNd",
        "row": 23375,
        "state": "",
        "group": "EMH222",
        "group_num": [
            "recRZ4rx0GeH1I6ZX"
        ],
        "procedure": "Detox",
        "provider": "EMI HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 535,
        "id": "recZbSox97ArWZJlF",
        "row": 23373,
        "state": "",
        "group": "",
        "group_num": [
            "recUM3mzlAm59I8Yz"
        ],
        "procedure": "RTC",
        "provider": "EMI HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2167,
        "id": "recIcUweoES7RVlHY",
        "row": 23374,
        "state": "",
        "group": "EMH222",
        "group_num": [
            "recRZ4rx0GeH1I6ZX"
        ],
        "procedure": "RTC",
        "provider": "EMI HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4316,
        "id": "recfC17IYWJLCDRpE",
        "row": 19482,
        "state": "",
        "group": "000AHE834AAAP001",
        "group_num": [
            "recQkrWcAE1tRcSPi"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "rec8ZOHG0flgYmXbX",
        "row": 19483,
        "state": "",
        "group": "000AHE834AAAP001",
        "group_num": [
            "recQkrWcAE1tRcSPi"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recer4jhDfYaQJSgI",
        "row": 19511,
        "state": "",
        "group": "300063001M",
        "group_num": [
            "recMxC5AwyVtb0lbs"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recfxqDvEtCOBDtBH",
        "row": 19488,
        "state": "",
        "group": "300480001M",
        "group_num": [
            "reckjOAjEKkuJfACF"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1160,
        "id": "recfpxPNs3IP3I3wR",
        "row": 19499,
        "state": "",
        "group": "JNY020M001",
        "group_num": [
            "recBeXMsnWs20qaa1"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 170,
        "id": "recgK9lvPlbghcMma",
        "row": 19501,
        "state": "",
        "group": "",
        "group_num": [
            "recHDGoS5IYFzK3KT"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "2023": 2746,
        "id": "rechq7VxBLcY7NO03",
        "row": 19512,
        "state": "",
        "group": "300526001M",
        "group_num": [
            "rechSnSk6v1FyCOUO"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2931,
        "id": "reclpf7csQVKMvoWl",
        "row": 19532,
        "state": "",
        "group": "000USX834AAAO10",
        "group_num": [
            "recV7MmTYTv6EszWL"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2746,
        "id": "recnoP2E84uviJZo0",
        "row": 19497,
        "state": "",
        "group": "720097A00M",
        "group_num": [
            "recHtLmzyETFw98RV"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2806,
        "id": "recOtUJTx6pLOUGJ5",
        "row": 19491,
        "state": "",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recOAfe6rnmcF6ol1",
        "row": 19521,
        "state": "",
        "group": "7213520CNM",
        "group_num": [
            "recWF6oDTsZSakuCU"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2833,
        "id": "recOFwTaPYKMGenzw",
        "row": 19504,
        "state": "",
        "group": "300308BC1M",
        "group_num": [
            "recuCBfau1hnX2TwV"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "2024": 985,
        "id": "recO7nhpixreB0Mxn",
        "row": 19508,
        "state": "",
        "group": "300601B096",
        "group_num": [
            "receheJzSmHJcyvHG"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2159,
        "id": "recObR51GiM9Fi5qG",
        "row": 19529,
        "state": "",
        "group": "000TUR83419AO001",
        "group_num": [
            "recScDVMEW1YvHz5G"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2508,
        "id": "recOc8FtoJDAZJTQ2",
        "row": 19514,
        "state": "",
        "group": "",
        "group_num": [
            "recZ66MwiYKw41f6O"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3547,
        "id": "recPE1s0fZLPREsr1",
        "row": 19531,
        "state": "",
        "group": "000USX834AAAO10",
        "group_num": [
            "recV7MmTYTv6EszWL"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "2023": 170,
        "2024": 170,
        "id": "recQy9IardbR5fYPZ",
        "row": 19485,
        "state": "",
        "group": "",
        "group_num": [
            "recbumpQ6s75yiGPB"
        ],
        "procedure": "IOP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2275,
        "2023": 3377,
        "id": "recQleHMVfMgAKGsy",
        "row": 19503,
        "state": "",
        "group": "300308BC1M",
        "group_num": [
            "recuCBfau1hnX2TwV"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2043,
        "id": "recU6jnepKi5ytn9k",
        "row": 19527,
        "state": "",
        "group": "L06213M001",
        "group_num": [
            "reczVfiA43du803oW"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "2024": 326,
        "id": "recVHd8sjK1dnTzui",
        "row": 19502,
        "state": "",
        "group": "",
        "group_num": [
            "recHDGoS5IYFzK3KT"
        ],
        "procedure": "IOP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "id": "recVM8r3WzEHNa8IY",
        "row": 19509,
        "state": "",
        "group": "300601B096",
        "group_num": [
            "receheJzSmHJcyvHG"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 420,
        "id": "recVdY74DRvM3fBrT",
        "row": 19484,
        "state": "",
        "group": "",
        "group_num": [
            "recbumpQ6s75yiGPB"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recY9e9A3Hrip3dK8",
        "row": 19506,
        "state": "",
        "group": "375375000M",
        "group_num": [
            "reckhWYhLhdGwiBFQ"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recYlUKHlUEPMBO5x",
        "row": 19522,
        "state": "",
        "group": "7213520MZS",
        "group_num": [
            "recvW4SXMnem5bJl8"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 642,
        "id": "rec2yaxxNpp6AByXA",
        "row": 19526,
        "state": "",
        "group": "",
        "group_num": [
            "recfONGHUqMuPuCZG"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 76,
        "id": "rec3SsCMfVNdnZ4TW",
        "row": 19490,
        "state": "",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "rec4XoFp3BmKSIiWH",
        "row": 19525,
        "state": "",
        "group": "721273015M",
        "group_num": [
            "recCbGTbvp3tX51E5"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "rec5apgUNuV24ipv9",
        "row": 19518,
        "state": "",
        "group": "000P9D83401AP001",
        "group_num": [
            "recL0MZTfZTtpY8gx"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3135,
        "id": "rec8fibr21JnmCVB0",
        "row": 19496,
        "state": "",
        "group": "720097A00M",
        "group_num": [
            "recHtLmzyETFw98RV"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "rec9fy4591N6Amr37",
        "row": 19495,
        "state": "",
        "group": "720904TM3A",
        "group_num": [
            "recdcF498aJyHpMhy"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1273,
        "id": "reca6GVG82JB61Apt",
        "row": 19500,
        "state": "",
        "group": "JNY020M001",
        "group_num": [
            "recBeXMsnWs20qaa1"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2746,
        "id": "reccCBAAcYvaeniZ4",
        "row": 19492,
        "state": "",
        "group": "720902MM1A",
        "group_num": [
            "recUG1qQgY3TwfmZX"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3135,
        "id": "recdYvtcSlJQEaFvu",
        "row": 19487,
        "state": "",
        "group": "997182022M",
        "group_num": [
            "rec1I7wwH3HiRN6uQ"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2746,
        "id": "recqwemH1f2KvHRDo",
        "row": 19517,
        "state": "",
        "group": "L05845M002",
        "group_num": [
            "rec456yMLVAtfaUwk"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recranbobxdvJp02t",
        "row": 19489,
        "state": "",
        "group": "300480001M",
        "group_num": [
            "reckjOAjEKkuJfACF"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recrhWt2dMV0fX30f",
        "row": 19528,
        "state": "",
        "group": "L06213M001",
        "group_num": [
            "reczVfiA43du803oW"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2431,
        "id": "recsZ3Wwwt4rIDPug",
        "row": 19524,
        "state": "",
        "group": "7213520N1M",
        "group_num": [
            "recdYpeKOeIdHpAgi"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "rectXxuBgVHd4Xyc1",
        "row": 19494,
        "state": "",
        "group": "720902MM2A",
        "group_num": [
            "rec9woZxoyCPrysvx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2159,
        "id": "rectHW1kjkDqOPjhg",
        "row": 19530,
        "state": "",
        "group": "000TUR83419AO001",
        "group_num": [
            "recScDVMEW1YvHz5G"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 773,
        "id": "recu0rX9oQpz6sqqc",
        "row": 19505,
        "state": "",
        "group": "300385002M",
        "group_num": [
            "recWOYMidXHMOJmyx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recvnZ57e3KdbEFuE",
        "row": 19513,
        "state": "",
        "group": "300526001M",
        "group_num": [
            "rechSnSk6v1FyCOUO"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recw6BiXPkSan2olL",
        "row": 19515,
        "state": "",
        "group": "",
        "group_num": [
            "recZ66MwiYKw41f6O"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 667,
        "id": "recAujOGr2SYAR94O",
        "row": 19510,
        "state": "",
        "group": "300601026A",
        "group_num": [
            "recDRudbAAsTr43tm"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recAMJdSTgCBRSQ3k",
        "row": 19498,
        "state": "",
        "group": "",
        "group_num": [
            "recuUsuozGdGPXpP6"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recKXRXXFFknvPnKv",
        "row": 19507,
        "state": "",
        "group": "375375000M",
        "group_num": [
            "reckhWYhLhdGwiBFQ"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 29,
        "id": "recKdIuPGoZXBPBeb",
        "row": 19486,
        "state": "",
        "group": "",
        "group_num": [
            "recbumpQ6s75yiGPB"
        ],
        "procedure": "OP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "2023": 2746,
        "id": "recLor3R3LXqmxm3N",
        "row": 19493,
        "state": "",
        "group": "720902MM2A",
        "group_num": [
            "rec9woZxoyCPrysvx"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2296,
        "id": "recMHxVNf1lpOgQ6L",
        "row": 19520,
        "state": "",
        "group": "7213520CNM",
        "group_num": [
            "recWF6oDTsZSakuCU"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "recN0jeesFFaO0r10",
        "row": 19533,
        "state": "",
        "group": "000USX834AAAO101",
        "group_num": [
            "recpBV3m4V8bEgaaH"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recNwfYwsouQpy8vC",
        "row": 19523,
        "state": "",
        "group": "7213520MZS",
        "group_num": [
            "recvW4SXMnem5bJl8"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3529,
        "id": "recNnh6kbIjmm23Md",
        "row": 19516,
        "state": "",
        "group": "",
        "group_num": [
            "rectXYlEz4ZwWqvLX"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recNnrzlI7tyg43sq",
        "row": 19519,
        "state": "",
        "group": "000P9D83401AP001",
        "group_num": [
            "recL0MZTfZTtpY8gx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4149,
        "id": "recOq45ncRnGudMkh",
        "row": 19535,
        "state": "",
        "group": "3776503STM",
        "group_num": [
            "recnDglhKfwgP8Wnu"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "recPDQ63YF2GrZrtO",
        "row": 19539,
        "state": "",
        "group": "72076200US",
        "group_num": [
            "recyaEy8TNXG3TOTr"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3499,
        "id": "recWw7PClbfCWc60f",
        "row": 19538,
        "state": "",
        "group": "",
        "group_num": [
            "recAJQh4hsSsTAuwJ"
        ],
        "procedure": "RTC",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "2024": 3000,
        "id": "rec1ZxGH5etslUmnp",
        "row": 19540,
        "state": "",
        "group": "",
        "group_num": [
            "recrNbyYVVRaJwuLZ"
        ],
        "procedure": "PHP",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "rec2pm2bCrkYfXFkp",
        "row": 19537,
        "state": "",
        "group": "JNY016M001",
        "group_num": [
            "recZhuJV6qce68TPx"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2746,
        "id": "rec4U000pohsC3KJ5",
        "row": 19536,
        "state": "",
        "group": "3776503STM",
        "group_num": [
            "recnDglhKfwgP8Wnu"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recJ9f9yEsdoI8Pmu",
        "row": 19534,
        "state": "",
        "group": "000USX834AAAO101",
        "group_num": [
            "recpBV3m4V8bEgaaH"
        ],
        "procedure": "Detox",
        "provider": "EMPIRE BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 974,
        "id": "rece5Wl3LzPYiKk69",
        "row": 23380,
        "state": "",
        "group": "00480",
        "group_num": [
            "recDjEPtlVNaRJngP"
        ],
        "procedure": "Detox",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 294,
        "id": "recjirNlgVuvLlMCv",
        "row": 23377,
        "state": "",
        "group": "",
        "group_num": [
            "recLetYEQVIJ1RM68"
        ],
        "procedure": "PHP",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recTOCuQ95Peyp5WV",
        "row": 23376,
        "state": "",
        "group": "00276",
        "group_num": [
            "recwkMWfKop55cy3T"
        ],
        "procedure": "Detox",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 195,
        "id": "recsG7BKcQcyn2nZV",
        "row": 23378,
        "state": "",
        "group": "",
        "group_num": [
            "recLetYEQVIJ1RM68"
        ],
        "procedure": "IOP",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 986,
        "id": "recLWzMxkCmY1Jczq",
        "row": 23379,
        "state": "",
        "group": "00480",
        "group_num": [
            "recDjEPtlVNaRJngP"
        ],
        "procedure": "RTC",
        "provider": "EMPLOYEE BENEFIT MANAGEMENT SERVICE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1432,
        "id": "rec9BZ380TTOsRjOY",
        "row": 23381,
        "state": "",
        "group": "",
        "group_num": [
            "recs0WbxlB44LCktO"
        ],
        "procedure": "RTC",
        "provider": "ENTRUST CLAIMS TEAM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 1670,
        "id": "recO9od4QTWlrl4sa",
        "row": 23384,
        "state": "",
        "group": "",
        "group_num": [
            "recHr7sosnc6JLggG"
        ],
        "procedure": "IOP",
        "provider": "EXCEEDENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2443,
        "2023": 2779,
        "id": "recoQ8Qd9rt1c7MiF",
        "row": 23382,
        "state": "",
        "group": "",
        "group_num": [
            "recHr7sosnc6JLggG"
        ],
        "procedure": "PHP",
        "provider": "EXCEEDENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 795,
        "id": "recsFFJfObdPKPKv0",
        "row": 23383,
        "state": "",
        "group": "",
        "group_num": [
            "recHr7sosnc6JLggG"
        ],
        "procedure": "OP",
        "provider": "EXCEEDENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recCmK20K2mIS6ksY",
        "row": 23385,
        "state": "",
        "group": "",
        "group_num": [
            "recHr7sosnc6JLggG"
        ],
        "procedure": "Detox",
        "provider": "EXCEEDENT"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "rechVgoH2k6YGHHVg",
        "row": 19553,
        "state": "",
        "group": "",
        "group_num": [
            "recuIX5frpVLMPJSD"
        ],
        "procedure": "RTC",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 349,
        "2023": 289,
        "id": "rec1bZ4XTmlSzqpbK",
        "row": 19552,
        "state": "",
        "group": "",
        "group_num": [
            "recuIX5frpVLMPJSD"
        ],
        "procedure": "OP",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 522,
        "id": "rec1ixkoUFv1SUacD",
        "row": 19542,
        "state": "",
        "group": "00062720",
        "group_num": [
            "rec5psCuaAcgwNKAR"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "id": "rec2ey3En2PW7ezIo",
        "row": 19543,
        "state": "",
        "group": "00123268",
        "group_num": [
            "recfFuyIWCoGitNhD"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 336,
        "2023": 269,
        "id": "reccnRa8pj1STlPR4",
        "row": 19551,
        "state": "",
        "group": "",
        "group_num": [
            "recuIX5frpVLMPJSD"
        ],
        "procedure": "IOP",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 319,
        "id": "recqL5tpKzUoSmHRc",
        "row": 19550,
        "state": "",
        "group": "",
        "group_num": [
            "recuIX5frpVLMPJSD"
        ],
        "procedure": "PHP",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recxYzi28MXb11qlj",
        "row": 19548,
        "state": "",
        "group": "00091256",
        "group_num": [
            "recNGbe8YyLtLKdiC"
        ],
        "procedure": "RTC",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recx1Rc3zeyKpf5W7",
        "row": 19546,
        "state": "",
        "group": "",
        "group_num": [
            "recnN6EUoPDbi2dDY"
        ],
        "procedure": "RTC",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recAjIRoUHJSFE5bk",
        "row": 19544,
        "state": "",
        "group": "00006021",
        "group_num": [
            "recjKXEX6C7t6m2sB"
        ],
        "procedure": "RTC",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recFYrcc2c6mAqe9C",
        "row": 19541,
        "state": "",
        "group": "00010040",
        "group_num": [
            "recHnM5iBsskgdv1i"
        ],
        "procedure": "IOP",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "id": "recH6h7Hw5jjC9PMs",
        "row": 19549,
        "state": "",
        "group": "00091256",
        "group_num": [
            "recNGbe8YyLtLKdiC"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 468,
        "id": "recHoD8ckk9EAibkj",
        "row": 19547,
        "state": "",
        "group": "",
        "group_num": [
            "recnN6EUoPDbi2dDY"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "id": "recIOXoJC3126i7VC",
        "row": 19545,
        "state": "",
        "group": "00006021",
        "group_num": [
            "recjKXEX6C7t6m2sB"
        ],
        "procedure": "Detox",
        "provider": "EXCELLUS BCBS OF NY ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "id": "recjUKtIJITA1JeFZ",
        "row": 19578,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2024": 667,
        "id": "recj2vMFTCIpa322d",
        "row": 19563,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "reckKUutluFnNqTFc",
        "row": 19555,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 211,
        "2023": 360,
        "id": "recl5udozZYEREPZf",
        "row": 19582,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recl84RWm3MNPX6Qo",
        "row": 19581,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "2023": 172,
        "id": "recnRJFWrbQmpNKEI",
        "row": 19580,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 484,
        "id": "recPdRuFxyQO1kcyA",
        "row": 19558,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "2023": 200,
        "id": "recTt0c67HzVg9wTW",
        "row": 19577,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 79,
        "2024": 29,
        "id": "recWiLMLtCkP3AaO7",
        "row": 19569,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1758,
        "2023": 5465,
        "id": "recY1cMiJBcNU4vko",
        "row": 19571,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 456,
        "id": "recYfaXpO3zDMTbsn",
        "row": 19562,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "rec0Cm6mMAIDqRCKf",
        "row": 19554,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "rec2izeCdajtfX3if",
        "row": 19559,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1807,
        "id": "rec3bA9yui28VkoCR",
        "row": 19566,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 720,
        "id": "rec3eTNhj03A7EMSH",
        "row": 19567,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recbLXVPtnqReNsDr",
        "row": 19570,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "reccepktWEtTZAr8a",
        "row": 19556,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "2023": 360,
        "2024": 202,
        "id": "recqu8HbCCUmtvVZw",
        "row": 19573,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 172,
        "2023": 369,
        "id": "recr0Au8Jy2XwfaRC",
        "row": 19583,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 465,
        "2023": 650,
        "id": "recsGpEGPY9YMFOxV",
        "row": 19565,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 752,
        "2023": 1500,
        "id": "recsqocZGKIAnJH5u",
        "row": 19564,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "2023": 927,
        "id": "recvCASeDmv2jH4nd",
        "row": 19575,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 638,
        "id": "recv5ZKV2FFkVXdO6",
        "row": 19561,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "2024": 31,
        "id": "recBSUrd47Z90Zq52",
        "row": 19579,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 641,
        "id": "recCpfD5aoGGpUP7s",
        "row": 19572,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 885,
        "id": "recFmhXi9F3g0qwzA",
        "row": 19568,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 289,
        "id": "recIaMQDYjFcBFnhR",
        "row": 19560,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 423,
        "id": "recKWjxmmXiGdjSbP",
        "row": 19557,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "2023": 61,
        "id": "recKtsby4MysTkYqX",
        "row": 19574,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 5969,
        "id": "recKfkgarwjhgllQm",
        "row": 19576,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "id": "recexfyQ9OScPC3Be",
        "row": 19598,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "2023": 29,
        "id": "reclFzsKb0eCUNjtQ",
        "row": 19616,
        "state": "",
        "group": "",
        "group_num": [
            "recHLK3lrhwKux5oW"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 689,
        "id": "recmouGyeK4d7ZumV",
        "row": 19618,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "2024": 135,
        "id": "recnBiKTYpr9SjlC8",
        "row": 19600,
        "state": "",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 29,
        "id": "recQEOXNCuYt2BfXG",
        "row": 19617,
        "state": "",
        "group": "",
        "group_num": [
            "recGrq0pC7FpOMj15"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recStl0PShR9SGH4E",
        "row": 19613,
        "state": "",
        "group": "111",
        "group_num": [
            "rec4gOdaRwlYmJwFk"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "id": "recXQmkNRQwtjH42k",
        "row": 19606,
        "state": "",
        "group": "113",
        "group_num": [
            "recVeuA5JyTCBwjDZ"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "rec1bK1qn9L3QFGyU",
        "row": 19607,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 530,
        "id": "rec4cPc9rhsU1D5qq",
        "row": 19588,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 199,
        "id": "rec6fbBmzvTi9hBX1",
        "row": 19589,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "id": "rec7ct0thDNs05Rju",
        "row": 19596,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 202,
        "id": "rec8ObAGnyH0YzIkJ",
        "row": 19612,
        "state": "",
        "group": "111",
        "group_num": [
            "rec4gOdaRwlYmJwFk"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "2024": 29,
        "id": "rec9i9nb1Sa8neJS7",
        "row": 19608,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 638,
        "id": "recaGyPLx7141VGXr",
        "row": 19591,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "id": "recaLatj2ImfQ5Cnl",
        "row": 19604,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "recbtgxJpGZpOh3GJ",
        "row": 19610,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recbwVliuypYqPm8B",
        "row": 19587,
        "state": "",
        "group": "106",
        "group_num": [
            "recsEzJ4lZ97GgJSp"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "2024": 29,
        "id": "recbpur81Q4I03Xdk",
        "row": 19614,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "2023": 29,
        "2024": 29,
        "id": "recoii64JuXg7Q0qU",
        "row": 19605,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1175,
        "2023": 1072,
        "id": "recquJ1EE03pgaSwC",
        "row": 19585,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "id": "recrevWSfiWhuwvO5",
        "row": 19584,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recsTR1ko6ZyOsSdE",
        "row": 19603,
        "state": "",
        "group": "111",
        "group_num": [
            "rec4gOdaRwlYmJwFk"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "2024": 175,
        "id": "recsz3Z7eDvuSNBIa",
        "row": 19592,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 399,
        "id": "rectBTOoHqOhvOeW3",
        "row": 19615,
        "state": "",
        "group": "",
        "group_num": [
            "recHLK3lrhwKux5oW"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "id": "recvMkRG0sFUHqULR",
        "row": 19601,
        "state": "",
        "group": "111",
        "group_num": [
            "rec4gOdaRwlYmJwFk"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 2026,
        "id": "recwy2nxLxUHNsmKe",
        "row": 19611,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 29,
        "2024": 29,
        "id": "recwJilNRv3yatd1a",
        "row": 19590,
        "state": "",
        "group": "112",
        "group_num": [
            "reccW45JrvP4ogE3u"
        ],
        "procedure": "OP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "reczWIPFEjDjhLYjT",
        "row": 19599,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recCWGltnZzaLLZCy",
        "row": 19602,
        "state": "",
        "group": "111",
        "group_num": [
            "rec4gOdaRwlYmJwFk"
        ],
        "procedure": "RTC",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 227,
        "id": "recFi3ETInlI1ijbr",
        "row": 19594,
        "state": "",
        "group": "",
        "group_num": [
            "recfepdaOsFRtvRLS"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "recH5CcOsIUQ8Qf0M",
        "row": 19609,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recJtUCGRnGbDYgc5",
        "row": 19593,
        "state": "",
        "group": "",
        "group_num": [
            "recfepdaOsFRtvRLS"
        ],
        "procedure": "PHP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 2620,
        "id": "recJJ3w9lb7QGUVs7",
        "row": 19595,
        "state": "",
        "group": "104",
        "group_num": [
            "recUnAo8hrXGQWRFt"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 782,
        "id": "recJhOCTzl09AHnB9",
        "row": 19597,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "IOP",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1248,
        "2023": 670,
        "id": "recKUIU18AMX1zYax",
        "row": 19586,
        "state": "",
        "group": "105",
        "group_num": [
            "recwmPvvnBLMhPTyr"
        ],
        "procedure": "Detox",
        "provider": "FEDERAL BCBS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recM057nn9XZUEyg8",
        "row": 23386,
        "state": "",
        "group": "",
        "group_num": [
            "rec8PqzZjAqbhVmFK"
        ],
        "procedure": "PHP",
        "provider": "FIDELIS CARE OF NEW YORK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2702,
        "id": "rec9Rz0FPgaaWAKhJ",
        "row": 23387,
        "state": "",
        "group": "",
        "group_num": [
            "rec6iKGo7MfEbD6Ty"
        ],
        "procedure": "Detox",
        "provider": "FIRST CAROLINA CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recOOsr6b6lPzep9r",
        "row": 23396,
        "state": "",
        "group": "",
        "group_num": [
            "recFDFQiUXtKS0fCX"
        ],
        "procedure": "PHP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 520,
        "id": "recO49UmtqCnWSesO",
        "row": 23388,
        "state": "",
        "group": "",
        "group_num": [
            "rec5O50SFtd0gGfh4"
        ],
        "procedure": "PHP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 735,
        "id": "recX3Rq4xRnKAVCo6",
        "row": 23393,
        "state": "",
        "group": "",
        "group_num": [
            "recOPjW8JknD1gzrL"
        ],
        "procedure": "PHP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 122,
        "2024": 123,
        "id": "rec5nLes5pnLI5owN",
        "row": 23392,
        "state": "",
        "group": "",
        "group_num": [
            "recDHkY72JtuVYwfI"
        ],
        "procedure": "OP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 67,
        "id": "recq44rDJxsHgSv92",
        "row": 23389,
        "state": "",
        "group": "",
        "group_num": [
            "rec5O50SFtd0gGfh4"
        ],
        "procedure": "OP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 320,
        "id": "recwga1KQ33mEzEyq",
        "row": 23394,
        "state": "",
        "group": "",
        "group_num": [
            "recOPjW8JknD1gzrL"
        ],
        "procedure": "OP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 474,
        "id": "recyfkQGGIw0xHKAV",
        "row": 23390,
        "state": "",
        "group": "",
        "group_num": [
            "rec5O50SFtd0gGfh4"
        ],
        "procedure": "IOP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 589,
        "id": "recCLjeskZb5Q6E18",
        "row": 23395,
        "state": "",
        "group": "",
        "group_num": [
            "recOPjW8JknD1gzrL"
        ],
        "procedure": "IOP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "id": "recGJYZYkb28KZMyA",
        "row": 23391,
        "state": "",
        "group": "6475",
        "group_num": [
            "rec1aK7lLhNxxFHfm"
        ],
        "procedure": "IOP",
        "provider": "FIRST CHOICE HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "id": "recgFiJ6jfzw22FoE",
        "row": 19625,
        "state": "",
        "group": "",
        "group_num": [
            "recKY7GU4b6BWEY5H"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 330,
        "id": "recjk5x4ETm1f2Okr",
        "row": 19631,
        "state": "",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "reckm5UqaE3l7A3u3",
        "row": 19633,
        "state": "",
        "group": "99999U5K",
        "group_num": [
            "recIWAyIF2FErYCPP"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 245,
        "2024": 493,
        "id": "recSB6NFQW5ms2yOZ",
        "row": 19628,
        "state": "",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "2024": 985,
        "id": "recSgZrkVxBVuRZE1",
        "row": 19620,
        "state": "",
        "group": "85000000",
        "group_num": [
            "recgzMoaz0YGSBSwM"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 964,
        "id": "rec7YM0J5rZIC8nP0",
        "row": 19622,
        "state": "",
        "group": "D0975002",
        "group_num": [
            "recjIhplwdRYevZvr"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recazvrcANWEi7IN4",
        "row": 19630,
        "state": "",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 167,
        "id": "recdh7QfMWH8FbwTA",
        "row": 19619,
        "state": "",
        "group": "16153001",
        "group_num": [
            "rec1FFlOyYJOWmL2Z"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recoWiUL0F72LIYzr",
        "row": 19623,
        "state": "",
        "group": "",
        "group_num": [
            "recKY7GU4b6BWEY5H"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recsyTGn0EoswzrzX",
        "row": 19632,
        "state": "",
        "group": "99999U5F",
        "group_num": [
            "reczIWqiizjBrTczh"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recxZfXJXd8WyU12o",
        "row": 19624,
        "state": "",
        "group": "",
        "group_num": [
            "recKY7GU4b6BWEY5H"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "2024": 985,
        "id": "recxvF6o6QcMZiH41",
        "row": 19629,
        "state": "",
        "group": "99999U5A",
        "group_num": [
            "recJigKNOLWDKyO57"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 819,
        "id": "recCxdR6MFP27oJ8M",
        "row": 19621,
        "state": "",
        "group": "85000000",
        "group_num": [
            "recgzMoaz0YGSBSwM"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recHSD4LGCmxGErMC",
        "row": 19627,
        "state": "",
        "group": "B4829002",
        "group_num": [
            "recPhoPb3xGuzr6rC"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 435,
        "id": "recHIQrGXl28wqSDM",
        "row": 19626,
        "state": "",
        "group": "37461000DM",
        "group_num": [
            "recbSO190dp2R70C1"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 885,
        "id": "rece2lbXkodXEe2qT",
        "row": 19666,
        "state": "",
        "group": "99999U6H",
        "group_num": [
            "recGzyD7zsfeBZWX8"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 313,
        "id": "rece7S4tCcZSNDRMl",
        "row": 19636,
        "state": "",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 958,
        "id": "recinqiFqdbpb3o4w",
        "row": 19653,
        "state": "",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "reckY4jTsKoMK88bA",
        "row": 19682,
        "state": "",
        "group": "",
        "group_num": [
            "recCKG4Ps7WuM4Pv0"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 962,
        "id": "reck5i8fYDtiR5pCx",
        "row": 19675,
        "state": "",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 481,
        "id": "reckrdvt9gQgxMN5n",
        "row": 19646,
        "state": "",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "2024": 553,
        "id": "reclWWMbT2RiEL0fR",
        "row": 19659,
        "state": "",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recOFUZhiSeECcpyY",
        "row": 19649,
        "state": "",
        "group": "99999UU1",
        "group_num": [
            "recra9SioGbReFbfe"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 875,
        "2023": 985,
        "2024": 985,
        "id": "recPuGv9UY3f41Wiu",
        "row": 19637,
        "state": "",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 864,
        "id": "recSXXXVSVG3Pe9TW",
        "row": 19662,
        "state": "",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "recStDipWG8i1wBwq",
        "row": 19661,
        "state": "",
        "group": "99999U6F",
        "group_num": [
            "recug9l8MTDsFu0CM"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 390,
        "2023": 550,
        "id": "recTIvYqJrfFfGh8C",
        "row": 19673,
        "state": "",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 557,
        "id": "recV3G5cvSNW3s8K7",
        "row": 19643,
        "state": "",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 158,
        "2023": 158,
        "id": "recVibO5l2kCHXfdh",
        "row": 19641,
        "state": "",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 34,
        "id": "recWhqfbwdvTaLWRB",
        "row": 19664,
        "state": "",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recWi7ckj0kX00BM9",
        "row": 19669,
        "state": "",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recXYyuxFERoChHjA",
        "row": 19663,
        "state": "",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "id": "recXKq4DjM6M7pSAZ",
        "row": 19650,
        "state": "",
        "group": "99999UU3",
        "group_num": [
            "recdhsaDQH4NNda4F"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "recZxn7HTDi1lweNn",
        "row": 19676,
        "state": "",
        "group": "99999Z5F",
        "group_num": [
            "recYXWpZ7swwNhiDl"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "rec1ZTJACGFgIGEvi",
        "row": 19654,
        "state": "",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec1eIe34rxRYMEGN",
        "row": 19672,
        "state": "",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 882,
        "2023": 952,
        "id": "rec1iP74Q7keGK3yD",
        "row": 19680,
        "state": "",
        "group": "99999Z6J",
        "group_num": [
            "rec1bu6tlEbnPPKsQ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "id": "rec1kRnlalPFFZ6HK",
        "row": 19681,
        "state": "",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rec45Ql53E1tje8i6",
        "row": 19644,
        "state": "",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "id": "rec4mBwuljEaTxVs2",
        "row": 19640,
        "state": "",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "id": "rec6z2kE8TLrzCAKZ",
        "row": 19657,
        "state": "",
        "group": "99999ZF2",
        "group_num": [
            "recdL22CNhBOTlwFY"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "2024": 493,
        "id": "rec6l7FnLvRmyAlFB",
        "row": 19635,
        "state": "",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "id": "rec7uKjFybYGOKech",
        "row": 19645,
        "state": "",
        "group": "99999UF2",
        "group_num": [
            "recV03uO03Yet9MW6"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "2024": 400,
        "id": "rec7DDl6xGPOFBEAp",
        "row": 19660,
        "state": "",
        "group": "99999ZU7",
        "group_num": [
            "recP8TCMUcBuhZ7tj"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 493,
        "id": "reccWNBl9HkPdD4At",
        "row": 19651,
        "state": "",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recchPqa3Q4lun4m5",
        "row": 19648,
        "state": "",
        "group": "99999UFS",
        "group_num": [
            "recOT3skBN2yKcGmF"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 463,
        "id": "reco2Ujyn7YoC74Hk",
        "row": 19668,
        "state": "",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "id": "recpbnORHFPep0GB3",
        "row": 19665,
        "state": "",
        "group": "99999U6G",
        "group_num": [
            "rectajlYCTU9DfxgV"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recrqSB1p5nyQcAkx",
        "row": 19642,
        "state": "",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recsVd0dq6KfCX6zl",
        "row": 19658,
        "state": "",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "2024": 985,
        "id": "rectSvNYggsOUBY40",
        "row": 19656,
        "state": "",
        "group": "99999Z7F",
        "group_num": [
            "recNrzKrP44KavcLc"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recv6sasTrR6imqaQ",
        "row": 19639,
        "state": "",
        "group": "99999UCA",
        "group_num": [
            "recpWgCkKx5vJBXRA"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "recvi92ddl81tz4WT",
        "row": 19683,
        "state": "",
        "group": "",
        "group_num": [
            "recCKG4Ps7WuM4Pv0"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recwDfZlgyO5IVFlk",
        "row": 19677,
        "state": "",
        "group": "99999Z5F",
        "group_num": [
            "recYXWpZ7swwNhiDl"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 887,
        "2023": 952,
        "id": "recwFd6bVFvCu7mUK",
        "row": 19647,
        "state": "",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recw3R4L9Sdmf5knk",
        "row": 19638,
        "state": "",
        "group": "99999U6K",
        "group_num": [
            "recI3ZyrHjm6PnE0V"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 867,
        "2023": 952,
        "id": "reczMp7wfVIfJ9Qe8",
        "row": 19670,
        "state": "",
        "group": "99999UBU",
        "group_num": [
            "recmN33L2fBTU1OBT"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 313,
        "id": "recE7uaIRzxY1YAwW",
        "row": 19652,
        "state": "",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "recF57oUfW57APIV5",
        "row": 19674,
        "state": "",
        "group": "99999Z5A",
        "group_num": [
            "recpQnnGbFLcHSvPK"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recGJFZRTP1oDhzXr",
        "row": 19679,
        "state": "",
        "group": "",
        "group_num": [
            "rec0xVLsleN1z8yCl"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recHNO7ttCYJ5UkA5",
        "row": 19655,
        "state": "",
        "group": "99999Z6M",
        "group_num": [
            "reczlVc80yCGE5dPI"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recIt5tXVdztufVfs",
        "row": 19671,
        "state": "",
        "group": "99999UF4",
        "group_num": [
            "recmC1EMOqsPYUXf6"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "id": "recJrGtNeSIgMAeTp",
        "row": 19634,
        "state": "",
        "group": "99999U5W",
        "group_num": [
            "recb3jY1f0ck15XAZ"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "2023": 546,
        "id": "recL5w5WOW4GhM1YR",
        "row": 19667,
        "state": "",
        "group": "99999UBL",
        "group_num": [
            "recNiAa3Ma5eWC4V4"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recMVWlUv19x1qF89",
        "row": 19678,
        "state": "",
        "group": "",
        "group_num": [
            "rec0xVLsleN1z8yCl"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 303,
        "id": "receoTCMBhkqQvmd7",
        "row": 19694,
        "state": "",
        "group": "15827005",
        "group_num": [
            "rec7CojY6hzThIc1H"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "2024": 34,
        "id": "recfYwBo0pqCQ8jM4",
        "row": 19714,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2393,
        "id": "rechUvaWqwIV1bljj",
        "row": 19719,
        "state": "",
        "group": "",
        "group_num": [
            "recoHMpX35JD7UYUd"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recjFwxMLLU5Jb5go",
        "row": 19715,
        "state": "",
        "group": "B0619004",
        "group_num": [
            "recYfX6bB19jbmSPi"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 402,
        "id": "reclBq3N7QZK4hcbM",
        "row": 19689,
        "state": "",
        "group": "",
        "group_num": [
            "reckNvf4ifOXfUOcH"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 536,
        "id": "recmnXJOVjZtdP3Ol",
        "row": 19710,
        "state": "",
        "group": "",
        "group_num": [
            "recYciFWeftNPc0tT"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 78,
        "id": "recnsdi1cNMgD47UE",
        "row": 19706,
        "state": "",
        "group": "",
        "group_num": [
            "recicYALflUJld5gk"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "id": "recP6LYtGLOEsffcd",
        "row": 19685,
        "state": "",
        "group": "99999ZC1",
        "group_num": [
            "recBXyYyPv9dER1Ke"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "id": "recPdBssrZa7Wyn4N",
        "row": 19703,
        "state": "",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 878,
        "2023": 949,
        "2024": 985,
        "id": "recPpiBYJRgu8ExLy",
        "row": 19732,
        "state": "",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "recRmq9zjP4QoK6ki",
        "row": 19717,
        "state": "",
        "group": "B4247002",
        "group_num": [
            "recIGXlbRaJgckr6G"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 83,
        "id": "recRqKUl5IRbPaSBC",
        "row": 19712,
        "state": "",
        "group": "98094005",
        "group_num": [
            "recwEhiUnTLgiNMen"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 24,
        "id": "recRq3QQKzTw7uV1g",
        "row": 19695,
        "state": "",
        "group": "15827005",
        "group_num": [
            "rec7CojY6hzThIc1H"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recSwAWYyhx4g8D86",
        "row": 19699,
        "state": "",
        "group": "24326001",
        "group_num": [
            "recXGdeHtetDtflIH"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recWKKULhwWsrlvJj",
        "row": 19698,
        "state": "",
        "group": "24326001",
        "group_num": [
            "recXGdeHtetDtflIH"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "rec071uxMPvtyJeH4",
        "row": 19709,
        "state": "",
        "group": "",
        "group_num": [
            "rec3oxePM4BR4Uy9u"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "rec2InlQeb0ZPe0zB",
        "row": 19700,
        "state": "",
        "group": "32047003",
        "group_num": [
            "recQxc9fXsHrx58PY"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "rec3W44gRfXjpIPte",
        "row": 19726,
        "state": "",
        "group": "J6460001",
        "group_num": [
            "reccVlxK7JR3a3IAD"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "rec3zKNB17QhTzKam",
        "row": 19708,
        "state": "",
        "group": "60856003",
        "group_num": [
            "recJZFQXHxN09jmkk"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "rec3hmvG1cj8IBkne",
        "row": 19721,
        "state": "",
        "group": "D0459006",
        "group_num": [
            "recnCZpiCxUapizLS"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 320,
        "id": "rec4ato1eaGIYeF9j",
        "row": 19729,
        "state": "",
        "group": "",
        "group_num": [
            "recc0AXirQADPxmhN"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "rec4g4jSi5i8teK7p",
        "row": 19705,
        "state": "",
        "group": "",
        "group_num": [
            "recicYALflUJld5gk"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "rec5TzNvs82L54lMR",
        "row": 19728,
        "state": "",
        "group": "",
        "group_num": [
            "recc0AXirQADPxmhN"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 181,
        "id": "rec5f1hWf5hGk1qUC",
        "row": 19711,
        "state": "",
        "group": "",
        "group_num": [
            "recYciFWeftNPc0tT"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 912,
        "id": "rec81eKbtRflFY8ob",
        "row": 19684,
        "state": "",
        "group": "",
        "group_num": [
            "recCKG4Ps7WuM4Pv0"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 431,
        "2023": 476,
        "id": "rec8ji7yLHFSs073i",
        "row": 19696,
        "state": "",
        "group": "16425002",
        "group_num": [
            "recGlREWjuMsoBH78"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "2024": 222,
        "id": "rec9dwrIksOVdTRAZ",
        "row": 19690,
        "state": "",
        "group": "",
        "group_num": [
            "reckNvf4ifOXfUOcH"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "id": "recayjjAHPGdk2eg7",
        "row": 19731,
        "state": "",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "id": "recaiIMCC5AKf1fJm",
        "row": 19693,
        "state": "",
        "group": "16510R04",
        "group_num": [
            "reclBYSsDtthnV3mm"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recbYR35yoNfzFF6s",
        "row": 19686,
        "state": "",
        "group": "99999ZC1",
        "group_num": [
            "recBXyYyPv9dER1Ke"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 326,
        "id": "recbLhm2R05u7pbGe",
        "row": 19687,
        "state": "",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 295,
        "id": "recccF2XSMKECr0uA",
        "row": 19697,
        "state": "",
        "group": "16425002",
        "group_num": [
            "recGlREWjuMsoBH78"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "2023": 150,
        "id": "recd8PuN6KYZH2nID",
        "row": 19707,
        "state": "",
        "group": "",
        "group_num": [
            "recicYALflUJld5gk"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "recoITGFRxjbqLtfg",
        "row": 19702,
        "state": "",
        "group": "45121002",
        "group_num": [
            "recdavpgf7Q7EIAxO"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "id": "reco3nLunl3N0z0ZX",
        "row": 19725,
        "state": "",
        "group": "J6460001",
        "group_num": [
            "reccVlxK7JR3a3IAD"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 916,
        "id": "recpaK7D9t14hewy9",
        "row": 19724,
        "state": "",
        "group": "J0878001",
        "group_num": [
            "rec1D9MF0qRWe6F1f"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recqKIxRDBKTflrpu",
        "row": 19722,
        "state": "",
        "group": "D0459006",
        "group_num": [
            "recnCZpiCxUapizLS"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 467,
        "id": "recrDVqsTbytwgBDE",
        "row": 19723,
        "state": "",
        "group": "J0878001",
        "group_num": [
            "rec1D9MF0qRWe6F1f"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "id": "recsPcMwyvBTOvbjB",
        "row": 19733,
        "state": "",
        "group": "76402101",
        "group_num": [
            "recNHSDAk4mgXDMt6"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 458,
        "id": "recvIAXQbr12xIKHb",
        "row": 19713,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 43,
        "id": "recv4pq8sbg5k2dvE",
        "row": 19727,
        "state": "",
        "group": "J8758003",
        "group_num": [
            "rec66WcIojVgMtLqK"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 167,
        "id": "recwwbndrylwf9VfH",
        "row": 19691,
        "state": "",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "id": "recxJuqFuIR8k24NF",
        "row": 19701,
        "state": "",
        "group": "41954029",
        "group_num": [
            "recnbbmaof5cdfZ4d"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "id": "recyZWWXDC2GEkkuz",
        "row": 19688,
        "state": "",
        "group": "99999ZF4",
        "group_num": [
            "recuBuDGkFiKsXxWD"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "reczD4591tMRKohS0",
        "row": 19730,
        "state": "",
        "group": "",
        "group_num": [
            "recc0AXirQADPxmhN"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "id": "recAsRh9sbgUkgPOt",
        "row": 19720,
        "state": "",
        "group": "",
        "group_num": [
            "recoHMpX35JD7UYUd"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recBu9QiZuDMHPw8g",
        "row": 19718,
        "state": "",
        "group": "B4247002",
        "group_num": [
            "recIGXlbRaJgckr6G"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recBvDtapK8eBmsSL",
        "row": 19692,
        "state": "",
        "group": "99999UU5",
        "group_num": [
            "recsE31lb1XJlKNiI"
        ],
        "procedure": "OP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recCAEcMgSpGS67hQ",
        "row": 19716,
        "state": "",
        "group": "B0619004",
        "group_num": [
            "recYfX6bB19jbmSPi"
        ],
        "procedure": "Detox",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "2023": 550,
        "id": "recMRHERvEOqzQ9Dk",
        "row": 19704,
        "state": "",
        "group": "",
        "group_num": [
            "recicYALflUJld5gk"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "id": "recgZ75246RMA7tFa",
        "row": 19735,
        "state": "",
        "group": "99999ZAM",
        "group_num": [
            "recDaPV41K1PwVn2I"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 952,
        "2023": 952,
        "id": "recn0YhrTqNmavtsH",
        "row": 19734,
        "state": "",
        "group": "J7829002",
        "group_num": [
            "rec0E9xQcfwhCqmCn"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "id": "recsw2hAPS5kT20Qb",
        "row": 19736,
        "state": "",
        "group": "99999ZKH",
        "group_num": [
            "recV5Axor0h1yfdZf"
        ],
        "procedure": "PHP",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 985,
        "id": "recseL251i7Aax8nS",
        "row": 19738,
        "state": "",
        "group": "99999ZSB",
        "group_num": [
            "recid127QSeBvRceC"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 853,
        "id": "recz7Ir2nHxKeS33O",
        "row": 19737,
        "state": "",
        "group": "99999ZKH",
        "group_num": [
            "recV5Axor0h1yfdZf"
        ],
        "procedure": "RTC",
        "provider": "FLORIDA BLUE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "recW74YmaAt1ZpQ9b",
        "row": 23397,
        "state": "",
        "group": "0V3822",
        "group_num": [
            "recrkWB3XR1kugrEh"
        ],
        "procedure": "IOP",
        "provider": "FLORIDA HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 337,
        "id": "rechp6zATFIQmpO73",
        "row": 23405,
        "state": "",
        "group": "",
        "group_num": [
            "recUmwmESiatlbXMM"
        ],
        "procedure": "IOP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 107,
        "2023": 52,
        "id": "reckzixD8VndbNPWC",
        "row": 23402,
        "state": "",
        "group": "",
        "group_num": [
            "rech8NyTTuUb8805k"
        ],
        "procedure": "OP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 380,
        "2023": 492,
        "id": "recS9IrPxgnaWaacZ",
        "row": 23399,
        "state": "",
        "group": "",
        "group_num": [
            "recZzwtyzHwBmVWjo"
        ],
        "procedure": "IOP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 445,
        "id": "recTq1gnVPJN12Nlw",
        "row": 23401,
        "state": "",
        "group": "",
        "group_num": [
            "recs8DbGU36Z05kw0"
        ],
        "procedure": "IOP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 117,
        "2024": 110,
        "id": "recdJB6L3dC9DA9l8",
        "row": 23398,
        "state": "",
        "group": "",
        "group_num": [
            "recZzwtyzHwBmVWjo"
        ],
        "procedure": "OP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 148,
        "2023": 117,
        "id": "recDtskyhQlOxIJpN",
        "row": 23404,
        "state": "",
        "group": "",
        "group_num": [
            "recUmwmESiatlbXMM"
        ],
        "procedure": "OP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1468,
        "id": "recDu77y1VSmpI78T",
        "row": 23403,
        "state": "",
        "group": "",
        "group_num": [
            "rec1DANeQpvmea8OV"
        ],
        "procedure": "RTC",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recLOu6meYqFQN7kY",
        "row": 23400,
        "state": "",
        "group": "",
        "group_num": [
            "recs8DbGU36Z05kw0"
        ],
        "procedure": "OP",
        "provider": "FOREIGN SERVICE BENEFIT PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "rec2EpJHxAOfj0AEc",
        "row": 23406,
        "state": "",
        "group": "",
        "group_num": [
            "recthjN12RVQCK0w6"
        ],
        "procedure": "PHP",
        "provider": "FOX VALLEY LABORERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2899,
        "id": "recY56pVWW7xyDCin",
        "row": 23408,
        "state": "",
        "group": "",
        "group_num": [
            "rece29yCrMncEEB4P"
        ],
        "procedure": "Detox",
        "provider": "FRONTPATH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2813,
        "id": "reczIh4VhSe4An3MH",
        "row": 23407,
        "state": "",
        "group": "",
        "group_num": [
            "rece29yCrMncEEB4P"
        ],
        "procedure": "RTC",
        "provider": "FRONTPATH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 378,
        "2024": 378,
        "id": "recQGuMzoanekfDWm",
        "row": 23409,
        "state": "",
        "group": "",
        "group_num": [
            "recF7ItwfZNxfIMAV"
        ],
        "procedure": "PHP",
        "provider": "GBS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5250,
        "id": "recloNb7l6NoZvmK3",
        "row": 23411,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "Detox",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recn9Ag2FRazz8kuW",
        "row": 23414,
        "state": "",
        "group": "",
        "group_num": [
            "recoOWfxNMrYVzbBL"
        ],
        "procedure": "PHP",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "id": "reccOa1TzQrl4P3cw",
        "row": 23413,
        "state": "",
        "group": "",
        "group_num": [
            "reciMkZDXg4pr6Vax"
        ],
        "procedure": "OP",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5100,
        "id": "recpV8wFmVb8QVIHl",
        "row": 23410,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "Detox",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1575,
        "id": "recvmExnTS2Kw5JuL",
        "row": 23416,
        "state": "",
        "group": "",
        "group_num": [
            "recoOWfxNMrYVzbBL"
        ],
        "procedure": "IOP",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 602,
        "id": "recETjBU75FMwjKqA",
        "row": 23415,
        "state": "",
        "group": "",
        "group_num": [
            "recoOWfxNMrYVzbBL"
        ],
        "procedure": "OP",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4038,
        "id": "recJIGuvgBkKsXSia",
        "row": 23412,
        "state": "",
        "group": "",
        "group_num": [
            "recYpHQ7MxN54XLU3"
        ],
        "procedure": "RTC",
        "provider": "GEHA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "id": "recayxPNkOj9ptIXO",
        "row": 19739,
        "state": "",
        "group": "10711510",
        "group_num": [
            "rec0UbzIRe1V54T43"
        ],
        "procedure": "RTC",
        "provider": "GEHA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rec2X5PgllaZTT3ga",
        "row": 23417,
        "state": "",
        "group": "10118319",
        "group_num": [
            "recBaSYviwHBfR507"
        ],
        "procedure": "RTC",
        "provider": "GEISINGER HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recd05pGdEylBV5FC",
        "row": 23418,
        "state": "",
        "group": "10118319",
        "group_num": [
            "recBaSYviwHBfR507"
        ],
        "procedure": "Detox",
        "provider": "GEISINGER HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 640,
        "id": "recUdlmG5T89inF4F",
        "row": 23419,
        "state": "",
        "group": "",
        "group_num": [
            "recvPyvSSlKtnrZaq"
        ],
        "procedure": "RTC",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recVKLJDXnGnoxhSU",
        "row": 23421,
        "state": "",
        "group": "",
        "group_num": [
            "rec6LpT8yd5GQfZMD"
        ],
        "procedure": "Detox",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 305,
        "id": "recZPW9NWzHPmcWhr",
        "row": 23420,
        "state": "",
        "group": "",
        "group_num": [
            "rec6LpT8yd5GQfZMD"
        ],
        "procedure": "RTC",
        "provider": "GHI / EMBLEM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2088,
        "id": "reclmMliiafHSY7yR",
        "row": 23422,
        "state": "",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "RTC",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2200,
        "id": "recpTUC68MoYctdXf",
        "row": 23424,
        "state": "",
        "group": "S5015",
        "group_num": [
            "reclT8WYWM3NbfnMm"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2171,
        "id": "recpPdVzXOq2DpcWm",
        "row": 23423,
        "state": "",
        "group": "S2473",
        "group_num": [
            "rec2JiPEf6dnyVulL"
        ],
        "procedure": "Detox",
        "provider": "GILSBAR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recyQsD7fbh9QHDl7",
        "row": 23426,
        "state": "",
        "group": "705214",
        "group_num": [
            "recS81ysw30m7zA6f"
        ],
        "procedure": "Detox",
        "provider": "GOLDEN RULE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1785,
        "id": "recDq8NhbRytcuCZ6",
        "row": 23425,
        "state": "",
        "group": "705214",
        "group_num": [
            "recS81ysw30m7zA6f"
        ],
        "procedure": "RTC",
        "provider": "GOLDEN RULE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1296,
        "id": "recn7JyyLtveboJ96",
        "row": 23432,
        "state": "",
        "group": "",
        "group_num": [
            "recGFKKBvcGLakvcF"
        ],
        "procedure": "Detox",
        "provider": "GRAVIE ADMINISTRATIVE SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec17bGVH55RcUhzT",
        "row": 23430,
        "state": "",
        "group": "",
        "group_num": [
            "recY2iFI2974H4VJd"
        ],
        "procedure": "Detox",
        "provider": "GRAVIE ADMINISTRATIVE SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recbSE3y8rd7qLeKR",
        "row": 23428,
        "state": "",
        "group": "",
        "group_num": [
            "recHyA4ZeaNDKkYwy"
        ],
        "procedure": "Detox",
        "provider": "GRAVIE ADMINISTRATIVE SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 841,
        "id": "reccz6wgrBxxZWVas",
        "row": 23431,
        "state": "",
        "group": "",
        "group_num": [
            "recGFKKBvcGLakvcF"
        ],
        "procedure": "RTC",
        "provider": "GRAVIE ADMINISTRATIVE SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2141,
        "id": "recr3MdZDH6GuRPzF",
        "row": 23429,
        "state": "",
        "group": "",
        "group_num": [
            "recY2iFI2974H4VJd"
        ],
        "procedure": "RTC",
        "provider": "GRAVIE ADMINISTRATIVE SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recALzwjY1NniC4e2",
        "row": 23427,
        "state": "",
        "group": "",
        "group_num": [
            "recHyA4ZeaNDKkYwy"
        ],
        "procedure": "RTC",
        "provider": "GRAVIE ADMINISTRATIVE SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 987,
        "id": "recIYYzoHgQsZ4mgc",
        "row": 23433,
        "state": "",
        "group": "",
        "group_num": [
            "recGqiYgZWSy8RtKT"
        ],
        "procedure": "Detox",
        "provider": "GROUP ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 902,
        "id": "recqxcU0tE9wLOWbA",
        "row": 23434,
        "state": "",
        "group": "",
        "group_num": [
            "rec6MNUuZnHelKHiB"
        ],
        "procedure": "Detox",
        "provider": "GROUP AND PENSION ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 526,
        "id": "recfBUBuVJT7jVXTm",
        "row": 23436,
        "state": "",
        "group": "",
        "group_num": [
            "recHCFXIi5MdfqDPd"
        ],
        "procedure": "IOP",
        "provider": "GROUP BENEFIT SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 207,
        "id": "recV3sPcoohtDTZzQ",
        "row": 23435,
        "state": "",
        "group": "",
        "group_num": [
            "recHCFXIi5MdfqDPd"
        ],
        "procedure": "PHP",
        "provider": "GROUP BENEFIT SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recl4BPN6vluUoM6u",
        "row": 23438,
        "state": "",
        "group": "",
        "group_num": [
            "recvQJEf5RNWm95wB"
        ],
        "procedure": "RTC",
        "provider": "HALCYON BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "recqxbJ5AQBE6yUqE",
        "row": 23437,
        "state": "",
        "group": "0002",
        "group_num": [
            "recuXJnKfbB2DyCrJ"
        ],
        "procedure": "RTC",
        "provider": "HALCYON BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "recfOIowzaPMou9T7",
        "row": 23448,
        "state": "",
        "group": "",
        "group_num": [
            "recoxZy6k1DLhgWIz"
        ],
        "procedure": "Detox",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recnDUu4b2o2g3LRK",
        "row": 23450,
        "state": "",
        "group": "",
        "group_num": [
            "recNhgdGKYVeyVf0L"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "rec0G3SU5PLAFgDMi",
        "row": 23449,
        "state": "",
        "group": "",
        "group_num": [
            "recdTE90uQK8Wrzjp"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "rec08Tp0d4o0e5HZX",
        "row": 23443,
        "state": "",
        "group": "",
        "group_num": [
            "recfgOKxEdBJNW2qy"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 913,
        "id": "rec3VYy7qsj8Dr9Ka",
        "row": 23439,
        "state": "",
        "group": "",
        "group_num": [
            "recskZmW7Qm3IPAkf"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "rec7dI0npLS130PZC",
        "row": 23445,
        "state": "",
        "group": "",
        "group_num": [
            "rec9AIWYWdWACBJWE"
        ],
        "procedure": "Detox",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "recb8yJc17E2V3IVU",
        "row": 23444,
        "state": "",
        "group": "",
        "group_num": [
            "recfgOKxEdBJNW2qy"
        ],
        "procedure": "Detox",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recpE6fkPvs5pvU6a",
        "row": 23446,
        "state": "",
        "group": "",
        "group_num": [
            "rec8KsmZJGV4AsOv2"
        ],
        "procedure": "IOP",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "2024": 3409,
        "id": "reczXnvEISnrCHd3O",
        "row": 23442,
        "state": "",
        "group": "",
        "group_num": [
            "recDRH1mvO7y0rRb7"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "recBtbmUENx5Y5Vmf",
        "row": 23451,
        "state": "",
        "group": "",
        "group_num": [
            "recNhgdGKYVeyVf0L"
        ],
        "procedure": "Detox",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "recBdJ3U8qJjxBTBm",
        "row": 23441,
        "state": "",
        "group": "",
        "group_num": [
            "recLRVdRrZ2xjEXgr"
        ],
        "procedure": "Detox",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3214,
        "id": "recDLLUTDghRTD1cd",
        "row": 23447,
        "state": "",
        "group": "",
        "group_num": [
            "recoxZy6k1DLhgWIz"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3617,
        "id": "recJYC1OJoQKzgMlk",
        "row": 23440,
        "state": "",
        "group": "",
        "group_num": [
            "recLRVdRrZ2xjEXgr"
        ],
        "procedure": "RTC",
        "provider": "HAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 552,
        "id": "reckrXUH6rCpe9byo",
        "row": 23454,
        "state": "",
        "group": "",
        "group_num": [
            "recPXnDIKzmh6lOSn"
        ],
        "procedure": "RTC",
        "provider": "HARMONY HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "recXSI7dN00ThPpIA",
        "row": 23453,
        "state": "",
        "group": "",
        "group_num": [
            "recO2cIROhwIOYQcM"
        ],
        "procedure": "PHP",
        "provider": "HARMONY HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 772,
        "2023": 750,
        "id": "recvG2awinBnQRUun",
        "row": 23452,
        "state": "",
        "group": "",
        "group_num": [
            "recO2cIROhwIOYQcM"
        ],
        "procedure": "RTC",
        "provider": "HARMONY HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3150,
        "id": "recureTClvWtyKni9",
        "row": 23455,
        "state": "",
        "group": "",
        "group_num": [
            "recpmcKtB2bMZFSx1"
        ],
        "procedure": "Detox",
        "provider": "HARVARD PILGRIM"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 234,
        "id": "recZErfAIXN6s8O0I",
        "row": 23456,
        "state": "",
        "group": "1400",
        "group_num": [
            "recu06l9T9VDdKtpN"
        ],
        "procedure": "Detox",
        "provider": "HEALTH ALLIANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 944,
        "id": "recmBt0LSsZYFBR7O",
        "row": 23457,
        "state": "",
        "group": "HCOK",
        "group_num": [
            "recBelDlWIqgVTSwm"
        ],
        "procedure": "PHP",
        "provider": "HEALTH CHOICE OF OK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 94,
        "id": "rec6szXDtVi6mVQH1",
        "row": 23458,
        "state": "",
        "group": "HCOK",
        "group_num": [
            "recBelDlWIqgVTSwm"
        ],
        "procedure": "OP",
        "provider": "HEALTH CHOICE OF OK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 472,
        "id": "recwiSuCDbw18uk0W",
        "row": 23459,
        "state": "",
        "group": "HCOK",
        "group_num": [
            "recBelDlWIqgVTSwm"
        ],
        "procedure": "IOP",
        "provider": "HEALTH CHOICE OF OK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recTT0UTmBkiVCZW0",
        "row": 23462,
        "state": "",
        "group": "",
        "group_num": [
            "recoUrQcjMBmd7ZUn"
        ],
        "procedure": "IOP",
        "provider": "HEALTH EOS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 398,
        "id": "recdFQWNKo1NZtNsa",
        "row": 23461,
        "state": "",
        "group": "",
        "group_num": [
            "recoUrQcjMBmd7ZUn"
        ],
        "procedure": "OP",
        "provider": "HEALTH EOS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recsnk1k9nuD3RCqo",
        "row": 23460,
        "state": "",
        "group": "",
        "group_num": [
            "recoUrQcjMBmd7ZUn"
        ],
        "procedure": "PHP",
        "provider": "HEALTH EOS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recOBXFIfzJglu77b",
        "row": 23464,
        "state": "",
        "group": "",
        "group_num": [
            "reciYJyRx5Wlajr7Y"
        ],
        "procedure": "Detox",
        "provider": "HEALTH EZ"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 776,
        "id": "rectdKiCi9ujikTPI",
        "row": 23463,
        "state": "",
        "group": "",
        "group_num": [
            "reciYJyRx5Wlajr7Y"
        ],
        "procedure": "RTC",
        "provider": "HEALTH EZ"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recR4C5DXXtfeggqT",
        "row": 23466,
        "state": "",
        "group": "",
        "group_num": [
            "rec8AAaJtc0y1GAqw"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "id": "rec7yzGUaMyoGnVgs",
        "row": 23469,
        "state": "",
        "group": "1183610002",
        "group_num": [
            "recaZS7cntEUAfMJx"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 357,
        "id": "rec9pinRasaQs4yRn",
        "row": 23474,
        "state": "",
        "group": "",
        "group_num": [
            "recsCHKjeiaIy5nTX"
        ],
        "procedure": "IOP",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "id": "recot19Yg2IHozatf",
        "row": 23471,
        "state": "",
        "group": "1191140004",
        "group_num": [
            "recPHisHtnIt8qKUD"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "recpQJVRjOLWB2hYt",
        "row": 23468,
        "state": "",
        "group": "1183610002",
        "group_num": [
            "recaZS7cntEUAfMJx"
        ],
        "procedure": "IOP",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 775,
        "id": "recAj4SaMAPx3Sfzw",
        "row": 23467,
        "state": "",
        "group": "1183610002",
        "group_num": [
            "recaZS7cntEUAfMJx"
        ],
        "procedure": "RTC",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recCkAkGiWaw2lMRi",
        "row": 23472,
        "state": "",
        "group": "",
        "group_num": [
            "recsCHKjeiaIy5nTX"
        ],
        "procedure": "RTC",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "recF5U1aumNjoFmpI",
        "row": 23473,
        "state": "",
        "group": "",
        "group_num": [
            "recsCHKjeiaIy5nTX"
        ],
        "procedure": "PHP",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1116,
        "2023": 1920,
        "id": "recFgMrCXDnnSmimf",
        "row": 23470,
        "state": "",
        "group": "1191140004",
        "group_num": [
            "recPHisHtnIt8qKUD"
        ],
        "procedure": "RTC",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 509,
        "id": "recIULjGX4hRH8Da3",
        "row": 23475,
        "state": "",
        "group": "S030530009",
        "group_num": [
            "recBIFavKVqcqz85D"
        ],
        "procedure": "Detox",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recLT16qMN9GUggXP",
        "row": 23465,
        "state": "",
        "group": "",
        "group_num": [
            "rec8AAaJtc0y1GAqw"
        ],
        "procedure": "RTC",
        "provider": "HEALTH NEW ENGLAND"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 369,
        "2024": 425,
        "id": "recf1nctKEdDb8nH2",
        "row": 23486,
        "state": "",
        "group": "3611",
        "group_num": [
            "recYaKub50G57U03L"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 193,
        "id": "recn5ymStRjRHxVxT",
        "row": 23478,
        "state": "",
        "group": "",
        "group_num": [
            "recZrnaS4QT0VKAYW"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 309,
        "id": "recPoMSAcflpxoliK",
        "row": 23489,
        "state": "",
        "group": "",
        "group_num": [
            "rec2N1RmNnCtRzcub"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 41,
        "id": "recRkBHoxCCnithpU",
        "row": 23495,
        "state": "",
        "group": "",
        "group_num": [
            "rec2aA4am4RE2xoCI"
        ],
        "procedure": "OP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 267,
        "id": "recU6Sgn5R10TleY4",
        "row": 23480,
        "state": "",
        "group": "",
        "group_num": [
            "recep6AoeA6e2tqMd"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 503,
        "id": "recWVfrReQkjEhx2Q",
        "row": 23487,
        "state": "",
        "group": "",
        "group_num": [
            "rec2N1RmNnCtRzcub"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 453,
        "id": "recW4SvMAkyfwXmqM",
        "row": 23482,
        "state": "",
        "group": "",
        "group_num": [
            "recu7XjV78n2iwfxq"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "id": "recXjrYj7nryJ0a2H",
        "row": 23477,
        "state": "",
        "group": "0046",
        "group_num": [
            "recjY65dZ2yk408me"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 389,
        "id": "recZ4h3B1FeRJEX6x",
        "row": 23496,
        "state": "",
        "group": "",
        "group_num": [
            "rec2aA4am4RE2xoCI"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 310,
        "2023": 314,
        "id": "rec02Rhs9jBDFcPJS",
        "row": 23493,
        "state": "",
        "group": "",
        "group_num": [
            "recxjMsfAhSDyXtLg"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 180,
        "id": "rec86lFvFc59QoCMZ",
        "row": 23484,
        "state": "",
        "group": "",
        "group_num": [
            "recu7XjV78n2iwfxq"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 282,
        "id": "recaRFxH7ggN924N3",
        "row": 23483,
        "state": "",
        "group": "",
        "group_num": [
            "recu7XjV78n2iwfxq"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 27,
        "id": "recalbhZ7LNcLaSXM",
        "row": 23488,
        "state": "",
        "group": "",
        "group_num": [
            "rec2N1RmNnCtRzcub"
        ],
        "procedure": "OP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 213,
        "id": "recogP1AWoHzmfPEC",
        "row": 23490,
        "state": "",
        "group": "",
        "group_num": [
            "recsgE2ISGgypRGbt"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 468,
        "id": "recprPmFwyWBnuoMF",
        "row": 23481,
        "state": "",
        "group": "",
        "group_num": [
            "recu7XjV78n2iwfxq"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 213,
        "id": "recyd3GjRh4nAWi1R",
        "row": 23491,
        "state": "",
        "group": "",
        "group_num": [
            "recsgE2ISGgypRGbt"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 510,
        "id": "recAvhxmingizSMCq",
        "row": 23485,
        "state": "",
        "group": "",
        "group_num": [
            "rec2Fh7zeNVq080Rj"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "id": "recGSoIClUIZ0Qifp",
        "row": 23476,
        "state": "",
        "group": "0046",
        "group_num": [
            "recjY65dZ2yk408me"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 686,
        "id": "recHD9QnrVWjNrWMw",
        "row": 23497,
        "state": "",
        "group": "",
        "group_num": [
            "recDBYR0CVaXyZJGb"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 511,
        "id": "recHr03RhxlfCWHMc",
        "row": 23494,
        "state": "",
        "group": "",
        "group_num": [
            "rec2aA4am4RE2xoCI"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 341,
        "2024": 341,
        "id": "recLfzIernm3u58zV",
        "row": 23498,
        "state": "",
        "group": "",
        "group_num": [
            "recDBYR0CVaXyZJGb"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 517,
        "id": "recLgoHMuyDT4KlLq",
        "row": 23479,
        "state": "",
        "group": "",
        "group_num": [
            "recZrnaS4QT0VKAYW"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 510,
        "2023": 510,
        "id": "recMqRVcj4Rkbyt9W",
        "row": 23492,
        "state": "",
        "group": "",
        "group_num": [
            "recxjMsfAhSDyXtLg"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PARTNERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2125,
        "id": "recOTNK4BrkxlHfBO",
        "row": 23499,
        "state": "",
        "group": "",
        "group_num": [
            "rec7eWg8jVtqAGWmi"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PAYMENT SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1359,
        "id": "rec6vWljMoFre2TR1",
        "row": 23501,
        "state": "",
        "group": "",
        "group_num": [
            "rec7eWg8jVtqAGWmi"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PAYMENT SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 676,
        "id": "recMA6I6oCzm2btNl",
        "row": 23500,
        "state": "",
        "group": "",
        "group_num": [
            "rec7eWg8jVtqAGWmi"
        ],
        "procedure": "OP",
        "provider": "HEALTH PAYMENT SYSTEMS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 581,
        "id": "reckCLdJ8zoYBjaC4",
        "row": 23503,
        "state": "",
        "group": "",
        "group_num": [
            "recTqoiCkatN5I0mL"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLAN OF NEVADA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 207,
        "id": "recV1o1ZjWZH7ihpP",
        "row": 23502,
        "state": "",
        "group": "",
        "group_num": [
            "recCOVRt6HO8UtjXj"
        ],
        "procedure": "IOP",
        "provider": "HEALTH PLAN OF NEVADA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recG7qu4IDAZG1Sat",
        "row": 23504,
        "state": "",
        "group": "",
        "group_num": [
            "recw36QWGwXrZOGYa"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLAN OF NEVADA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1043,
        "id": "recjpkU5wn4GNjVLB",
        "row": 23506,
        "state": "",
        "group": "",
        "group_num": [
            "recbnrPmajW2xcHM9"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1450,
        "id": "recxFjtOJPEcbxhJd",
        "row": 23505,
        "state": "",
        "group": "",
        "group_num": [
            "recT8WSwdb5Tf1ZWO"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "receib8a96BfJx3CD",
        "row": 23514,
        "state": "",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1462,
        "id": "recmC0ioHI21RFoP7",
        "row": 23508,
        "state": "",
        "group": "",
        "group_num": [
            "recwERuKmGhWegYGR"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 903,
        "id": "recRdXxzBqbmmrOov",
        "row": 23510,
        "state": "",
        "group": "",
        "group_num": [
            "recoJOArU1E6N8mV1"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recXRW5Vi0ytWR2L0",
        "row": 23511,
        "state": "",
        "group": "",
        "group_num": [
            "recoJOArU1E6N8mV1"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 66,
        "id": "rec28GtNTWU13Py0R",
        "row": 23509,
        "state": "",
        "group": "WB9",
        "group_num": [
            "recoxik0stBGWylR6"
        ],
        "procedure": "OP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1290,
        "id": "rec4M23Be92HwFcTb",
        "row": 23507,
        "state": "",
        "group": "BA2",
        "group_num": [
            "recqYgVLzvqBAwMkW"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 847,
        "id": "rec7nLckHc8tJQtr9",
        "row": 23512,
        "state": "",
        "group": "WD2",
        "group_num": [
            "recu1aw9eXgMBawlX"
        ],
        "procedure": "RTC",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4176,
        "id": "recaVhu7lEGCtKMRp",
        "row": 23516,
        "state": "",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 392,
        "id": "recuA3sZ2T0s588kH",
        "row": 23515,
        "state": "",
        "group": "YG1",
        "group_num": [
            "recz1E1XSs7wXfSEO"
        ],
        "procedure": "PHP",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3282,
        "id": "recHCrQT5NKnaz22E",
        "row": 23513,
        "state": "",
        "group": "WD2",
        "group_num": [
            "recu1aw9eXgMBawlX"
        ],
        "procedure": "Detox",
        "provider": "HEALTH PLANS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 362,
        "id": "reck2nOA547SLbn9a",
        "row": 23518,
        "state": "",
        "group": "",
        "group_num": [
            "recaqguCqxYDoiWUk"
        ],
        "procedure": "PHP",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1316,
        "id": "recno8WaDozl9o0bF",
        "row": 23525,
        "state": "",
        "group": "",
        "group_num": [
            "recPlI2FbTa6kImwq"
        ],
        "procedure": "RTC",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec17smMtdiiufnvL",
        "row": 23524,
        "state": "",
        "group": "OJJH834",
        "group_num": [
            "recMo0CGek3m94VA1"
        ],
        "procedure": "Detox",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2501,
        "id": "recp5s2wId1dpKYug",
        "row": 23521,
        "state": "",
        "group": "",
        "group_num": [
            "recFgAtbzUSIfH4Xs"
        ],
        "procedure": "RTC",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3512,
        "id": "rectBC3auOjNHWhrL",
        "row": 23522,
        "state": "",
        "group": "",
        "group_num": [
            "recFgAtbzUSIfH4Xs"
        ],
        "procedure": "Detox",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 932,
        "id": "recv88YRXL61UwYDH",
        "row": 23517,
        "state": "",
        "group": "",
        "group_num": [
            "recaqguCqxYDoiWUk"
        ],
        "procedure": "RTC",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 949,
        "id": "recDAii1UAxp8efQy",
        "row": 23520,
        "state": "",
        "group": "",
        "group_num": [
            "recaqguCqxYDoiWUk"
        ],
        "procedure": "Detox",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1129,
        "id": "recJmh3od1QWE70n5",
        "row": 23523,
        "state": "",
        "group": "OJJH834",
        "group_num": [
            "recMo0CGek3m94VA1"
        ],
        "procedure": "RTC",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 362,
        "id": "recNBfqkH5z6t77sP",
        "row": 23519,
        "state": "",
        "group": "",
        "group_num": [
            "recaqguCqxYDoiWUk"
        ],
        "procedure": "IOP",
        "provider": "HEALTH SMART"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 71,
        "id": "recR4yYlFJiCzjiwj",
        "row": 23526,
        "state": "",
        "group": "",
        "group_num": [
            "recJlXvI5GYSwlGEv"
        ],
        "procedure": "OP",
        "provider": "HEALTHCARE STRATEGIES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "reckPqxKpkgUTTjYB",
        "row": 23529,
        "state": "",
        "group": "",
        "group_num": [
            "recYth7OYpss9kaQO"
        ],
        "procedure": "RTC",
        "provider": "HEALTHCOMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 830,
        "id": "recUJfMSrXdaCrRpe",
        "row": 23527,
        "state": "",
        "group": "119948",
        "group_num": [
            "recnowtFeCMS4iF13"
        ],
        "procedure": "RTC",
        "provider": "HEALTHCOMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3138,
        "id": "rec8X9qxjQ6moMzU2",
        "row": 23528,
        "state": "",
        "group": "119948",
        "group_num": [
            "recnowtFeCMS4iF13"
        ],
        "procedure": "Detox",
        "provider": "HEALTHCOMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recbGZtBirEUrKzMT",
        "row": 23531,
        "state": "",
        "group": "",
        "group_num": [
            "recYth7OYpss9kaQO"
        ],
        "procedure": "Detox",
        "provider": "HEALTHCOMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 750,
        "id": "recHrblPuxZr7VAf7",
        "row": 23530,
        "state": "",
        "group": "",
        "group_num": [
            "recYth7OYpss9kaQO"
        ],
        "procedure": "IOP",
        "provider": "HEALTHCOMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2117,
        "id": "recyf0ebHTxAbNcoj",
        "row": 19740,
        "state": "",
        "group": "",
        "group_num": [
            "reclqlC9TbZbZEKrk"
        ],
        "procedure": "Detox",
        "provider": "HEALTHCOMP ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1892,
        "id": "rec1zO7X9ysoyGxJF",
        "row": 23532,
        "state": "",
        "group": "0180964713",
        "group_num": [
            "recTOwLPiDfTzi2T3"
        ],
        "procedure": "RTC",
        "provider": "HEALTHLINK"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "rec3EmqWIEIMHg9HA",
        "row": 23536,
        "state": "",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "Detox",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 708,
        "2024": 708,
        "id": "reccn1Ud4Z9GVyCyl",
        "row": 23533,
        "state": "",
        "group": "",
        "group_num": [
            "rec5ATp35veLyJgSd"
        ],
        "procedure": "RTC",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4143,
        "id": "recrYcH4d8ogZ27zp",
        "row": 23535,
        "state": "",
        "group": "WHIRL",
        "group_num": [
            "rec6lT8JDzgSqg6ND"
        ],
        "procedure": "RTC",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1144,
        "id": "recvHuKdKORBiPWf8",
        "row": 23534,
        "state": "",
        "group": "",
        "group_num": [
            "rec7YqE51rTMRdZ95"
        ],
        "procedure": "RTC",
        "provider": "HEALTHSCOPE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1804,
        "id": "recgG6y9zNtDID5Hp",
        "row": 19750,
        "state": "",
        "group": "10006146",
        "group_num": [
            "recc7BZEWY6ok4hhF"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 158,
        "2023": 158,
        "id": "reci04GfJ2GHEz5Ye",
        "row": 19753,
        "state": "",
        "group": "",
        "group_num": [
            "recZ2haAH3drshMuH"
        ],
        "procedure": "OP",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "reclcRbm2QZJS0lBe",
        "row": 19744,
        "state": "",
        "group": "10773595",
        "group_num": [
            "recJK94m8KvneCmzz"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recSruBz7uADrMl56",
        "row": 19746,
        "state": "",
        "group": "10006612",
        "group_num": [
            "rec741Por2kjey7qD"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1746,
        "id": "recUdSEhNVQ1ODsiY",
        "row": 19745,
        "state": "",
        "group": "10006612",
        "group_num": [
            "rec741Por2kjey7qD"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3467,
        "id": "recoCmTY5ZtXYappv",
        "row": 19741,
        "state": "",
        "group": "",
        "group_num": [
            "recRfhNClh6yXFBmz"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recoHjBDWTBzXGbZu",
        "row": 19749,
        "state": "",
        "group": "10006146",
        "group_num": [
            "recc7BZEWY6ok4hhF"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rectR94qti7Kyh9Mc",
        "row": 19743,
        "state": "",
        "group": "10773595",
        "group_num": [
            "recJK94m8KvneCmzz"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recucN0xMa53YfXGs",
        "row": 19748,
        "state": "",
        "group": "01742600",
        "group_num": [
            "rec89COjBzo3y95Oo"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recyJJZgNs6DKGOxt",
        "row": 19754,
        "state": "",
        "group": "",
        "group_num": [
            "recZ2haAH3drshMuH"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recCJ33zJWX55cuoI",
        "row": 19742,
        "state": "",
        "group": "10773595",
        "group_num": [
            "recJK94m8KvneCmzz"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "id": "recDjpYWU6hY0R4sC",
        "row": 19747,
        "state": "",
        "group": "01742600",
        "group_num": [
            "rec89COjBzo3y95Oo"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recGkZrZTVoZ5bBOS",
        "row": 19751,
        "state": "",
        "group": "",
        "group_num": [
            "recZ2haAH3drshMuH"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "id": "recLDDuCDZXztvrED",
        "row": 19752,
        "state": "",
        "group": "",
        "group_num": [
            "recZ2haAH3drshMuH"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF DE ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "receuMHGHLSyQBRgf",
        "row": 19775,
        "state": "",
        "group": "10568087",
        "group_num": [
            "recZwXyS4HUBUP3ai"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 275,
        "id": "recf4ZV45R2qUtPf3",
        "row": 19767,
        "state": "",
        "group": "",
        "group_num": [
            "rectSc38JLCBwKuVy"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 590,
        "id": "recgExi8VPeCJgdHm",
        "row": 19758,
        "state": "",
        "group": "",
        "group_num": [
            "recv14DuaylHtriqQ"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1022,
        "2024": 1022,
        "id": "rechu5zC3ouiKXTU3",
        "row": 19763,
        "state": "",
        "group": "",
        "group_num": [
            "recDVzpcwqaT2ZWTM"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1818,
        "id": "rechRenS8pqKOfYM9",
        "row": 19762,
        "state": "",
        "group": "10662838",
        "group_num": [
            "recKUTDU92zyEPrKf"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 274,
        "2023": 143,
        "2024": 147,
        "id": "rechiKMq5zqLIKmoz",
        "row": 19759,
        "state": "",
        "group": "",
        "group_num": [
            "recv14DuaylHtriqQ"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "2024": 43,
        "id": "reciicM5kXSafEAWE",
        "row": 19780,
        "state": "",
        "group": "10704497",
        "group_num": [
            "recaSYrwsh47eJBtq"
        ],
        "procedure": "OP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 258,
        "id": "recj8yQRrRaw48Cs4",
        "row": 19778,
        "state": "",
        "group": "10704497",
        "group_num": [
            "recaSYrwsh47eJBtq"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1803,
        "id": "reckY5pUPKu08YZ8s",
        "row": 19771,
        "state": "",
        "group": "10484606",
        "group_num": [
            "recGOQLKpvF65Xqx0"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 474,
        "id": "recmIxWDrw1hznvvt",
        "row": 19755,
        "state": "",
        "group": "01745502",
        "group_num": [
            "recVqfCaO5TalyX2A"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1556,
        "id": "recPuE9s79TdH1ocr",
        "row": 19756,
        "state": "",
        "group": "01745502",
        "group_num": [
            "recVqfCaO5TalyX2A"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 27,
        "id": "recQyVWz2w6kQrqrM",
        "row": 19764,
        "state": "",
        "group": "",
        "group_num": [
            "recDVzpcwqaT2ZWTM"
        ],
        "procedure": "OP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1601,
        "id": "recSrpunMCQexOrjo",
        "row": 19757,
        "state": "",
        "group": "01745502",
        "group_num": [
            "recVqfCaO5TalyX2A"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5778,
        "id": "recXXhvKazlxJRTGk",
        "row": 19782,
        "state": "",
        "group": "10704497",
        "group_num": [
            "recaSYrwsh47eJBtq"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1014,
        "id": "rec4UE0Rc0L62x9pv",
        "row": 19766,
        "state": "",
        "group": "",
        "group_num": [
            "rectSc38JLCBwKuVy"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 791,
        "id": "recbxw2a2TSU8Fk4M",
        "row": 19773,
        "state": "",
        "group": "10567859",
        "group_num": [
            "recTzMgKXH3zlcP2q"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1763,
        "2024": 985,
        "id": "reccubq3CfsSci93I",
        "row": 19770,
        "state": "",
        "group": "10484606",
        "group_num": [
            "recGOQLKpvF65Xqx0"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 524,
        "id": "recdOyAUbpUpOgAdD",
        "row": 19765,
        "state": "",
        "group": "",
        "group_num": [
            "recMkBSgQs3S3LYCO"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recdm7atIzj5Hk1ac",
        "row": 19761,
        "state": "",
        "group": "10662838",
        "group_num": [
            "recKUTDU92zyEPrKf"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 375,
        "id": "recpIQnmydbxpJIyF",
        "row": 19779,
        "state": "",
        "group": "10704497",
        "group_num": [
            "recaSYrwsh47eJBtq"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recscdv3u5kQzvOhn",
        "row": 19776,
        "state": "",
        "group": "10568087",
        "group_num": [
            "recZwXyS4HUBUP3ai"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recEL7sDoECJJO3Bv",
        "row": 19772,
        "state": "",
        "group": "10567859",
        "group_num": [
            "recTzMgKXH3zlcP2q"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recFZoz4SZFlikGe5",
        "row": 19768,
        "state": "",
        "group": "",
        "group_num": [
            "recMD8yRO4DrVSvBD"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 528,
        "id": "recGzYo3xdXL5nzRT",
        "row": 19760,
        "state": "",
        "group": "10713600",
        "group_num": [
            "recuW6ygLW4vYoTLe"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recH6lbhemXBrM486",
        "row": 19781,
        "state": "",
        "group": "10704497",
        "group_num": [
            "recaSYrwsh47eJBtq"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 323,
        "id": "recMFkWXzeHwnl7VB",
        "row": 19774,
        "state": "",
        "group": "10568087",
        "group_num": [
            "recZwXyS4HUBUP3ai"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recN0FomRecfxFHCh",
        "row": 19777,
        "state": "",
        "group": "",
        "group_num": [
            "recFk0BMEPW3pn2Aj"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 323,
        "id": "recNgsCoeanWjt8v4",
        "row": 19783,
        "state": "",
        "group": "",
        "group_num": [
            "recoDcbAEO4ip5rCA"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recNg1QWmmUPq8L02",
        "row": 19769,
        "state": "",
        "group": "",
        "group_num": [
            "recMD8yRO4DrVSvBD"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 815,
        "id": "rech1YDyJZiCQyNpc",
        "row": 19822,
        "state": "",
        "group": "10442964",
        "group_num": [
            "recECn1VR3CFtTURa"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 339,
        "id": "reci9RH9Mu6StQiyX",
        "row": 19826,
        "state": "",
        "group": "",
        "group_num": [
            "recCRSEWYRpjvyFS6"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1697,
        "id": "reclprX6HtvimJ1LN",
        "row": 19816,
        "state": "",
        "group": "10486230",
        "group_num": [
            "recveaN1NihnZlTGD"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 236,
        "2023": 310,
        "id": "recmNHsqE7VLhDHR0",
        "row": 19799,
        "state": "",
        "group": "10584000",
        "group_num": [
            "reca1EZScsUm3xfkM"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 285,
        "2023": 189,
        "id": "recOzToRCFBnMbbgq",
        "row": 19800,
        "state": "",
        "group": "10584000",
        "group_num": [
            "reca1EZScsUm3xfkM"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 262,
        "2023": 414,
        "id": "recSyhCKucsG7DYqC",
        "row": 19792,
        "state": "",
        "group": "01484200",
        "group_num": [
            "recYQ4zfcEDv9hlWL"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1727,
        "id": "recUV2DBJsNe08fXY",
        "row": 19819,
        "state": "",
        "group": "05034600",
        "group_num": [
            "recIcVGgJK4S1fYCL"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1876,
        "id": "recUuBYh33J9GlXNV",
        "row": 19791,
        "state": "",
        "group": "",
        "group_num": [
            "recugIF5lzCr2HiWa"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recU66zg49LpHZzvj",
        "row": 19788,
        "state": "",
        "group": "10783165",
        "group_num": [
            "recW7oMpkRUawBuby"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "recUfFTCaVulKNs7v",
        "row": 19796,
        "state": "",
        "group": "",
        "group_num": [
            "rec3wEnw1SKtuX7TS"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recViIH2trbRBJwd7",
        "row": 19809,
        "state": "",
        "group": "",
        "group_num": [
            "rec7Osg7livXdEFy3"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "id": "recVi15acobTHfbZV",
        "row": 19811,
        "state": "",
        "group": "",
        "group_num": [
            "recKF8uFEnhav42sT"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recVlKSlGiDl0mkUR",
        "row": 19801,
        "state": "",
        "group": "10584000",
        "group_num": [
            "reca1EZScsUm3xfkM"
        ],
        "procedure": "OP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 43,
        "id": "recXSyk76VDJK6NjF",
        "row": 19812,
        "state": "",
        "group": "",
        "group_num": [
            "recKF8uFEnhav42sT"
        ],
        "procedure": "OP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 714,
        "id": "recYSTBuyXtFZn0fa",
        "row": 19808,
        "state": "",
        "group": "10621286",
        "group_num": [
            "rec6OoA0p3PFeXdO2"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 962,
        "id": "recZ1V6d6QVNGzSwQ",
        "row": 19789,
        "state": "",
        "group": "",
        "group_num": [
            "recBxWD4sIUPtshXV"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1362,
        "id": "recZu5wFpn0f4yaFK",
        "row": 19785,
        "state": "",
        "group": "01067400",
        "group_num": [
            "recysPjB0SjPsUYYg"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "rec0UWl89jnvj1m2t",
        "row": 19794,
        "state": "",
        "group": "10567864",
        "group_num": [
            "rec3cIvXnvY0BICfk"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "rec1sCNrl60lnysMW",
        "row": 19807,
        "state": "",
        "group": "10621286",
        "group_num": [
            "rec6OoA0p3PFeXdO2"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "2023": 835,
        "id": "rec1fapee6X4z64Xx",
        "row": 19821,
        "state": "",
        "group": "01741300",
        "group_num": [
            "recibuGLDr2nxNV2n"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 323,
        "id": "rec4KF8HWwCSxse2H",
        "row": 19818,
        "state": "",
        "group": "10568087",
        "group_num": [
            "recZwXyS4HUBUP3ai"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1869,
        "id": "rec461q04gzYPmlaw",
        "row": 19820,
        "state": "",
        "group": "05034600",
        "group_num": [
            "recIcVGgJK4S1fYCL"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 809,
        "id": "rec5eTEjBLxQa8XYl",
        "row": 19827,
        "state": "",
        "group": "",
        "group_num": [
            "recUwq57p3JZc68BZ"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2098,
        "id": "rec6hvDs623mGAAIp",
        "row": 19815,
        "state": "",
        "group": "01742600",
        "group_num": [
            "rec89COjBzo3y95Oo"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1749,
        "id": "rec8C21BMcxsUrMVz",
        "row": 19784,
        "state": "",
        "group": "08407000",
        "group_num": [
            "recpIaasX1LV36yYr"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "rec83DFeU8kz5HZM5",
        "row": 19806,
        "state": "",
        "group": "",
        "group_num": [
            "rec5J7ivhq6icdRmZ"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2223,
        "id": "rec8lZa0YMoRGhNbm",
        "row": 19831,
        "state": "",
        "group": "02560020",
        "group_num": [
            "rec6r0GDOd5KdV9pS"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 895,
        "id": "rec9JcMDZnQpsF37D",
        "row": 19803,
        "state": "",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recarXxQgM9s4wFDo",
        "row": 19804,
        "state": "",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 189,
        "id": "reccsLoLFN1GLa5Nv",
        "row": 19797,
        "state": "",
        "group": "",
        "group_num": [
            "rec3wEnw1SKtuX7TS"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1824,
        "id": "reccxo3O55q3uXCC1",
        "row": 19805,
        "state": "",
        "group": "",
        "group_num": [
            "recvKGv1uE8e5BWCR"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1942,
        "id": "recrHMRrbSHgf9vDY",
        "row": 19790,
        "state": "",
        "group": "",
        "group_num": [
            "recugIF5lzCr2HiWa"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1869,
        "id": "recvXR1FCjlAptBML",
        "row": 19786,
        "state": "",
        "group": "01067400",
        "group_num": [
            "recysPjB0SjPsUYYg"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 458,
        "id": "recvz4Fl3k3uDLPRD",
        "row": 19802,
        "state": "",
        "group": "10575989",
        "group_num": [
            "recQdd8rePFsbjRyj"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recvH89JjNl8PbBh5",
        "row": 19793,
        "state": "",
        "group": "01484200",
        "group_num": [
            "recYQ4zfcEDv9hlWL"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recxl4qbHTNefJ9Zr",
        "row": 19830,
        "state": "",
        "group": "02560020",
        "group_num": [
            "rec6r0GDOd5KdV9pS"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recyvNorCUzsuLtK9",
        "row": 19795,
        "state": "",
        "group": "",
        "group_num": [
            "recJz2Fuv1kOLJ72T"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 304,
        "id": "reczOAOVFMWCYolOa",
        "row": 19810,
        "state": "",
        "group": "",
        "group_num": [
            "recKF8uFEnhav42sT"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 138,
        "id": "recz29jdB53EjRMkH",
        "row": 19813,
        "state": "",
        "group": "",
        "group_num": [
            "recenOLtwnGjcsvl7"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 546,
        "id": "recACiCOcg0lFd455",
        "row": 19823,
        "state": "",
        "group": "",
        "group_num": [
            "rec58DqKD3xItcVG8"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 239,
        "id": "recCpTZ0a4jTYtcHS",
        "row": 19824,
        "state": "",
        "group": "10416806",
        "group_num": [
            "recIzUX4gNnv2386R"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recE2eMmLJcwBH6fd",
        "row": 19829,
        "state": "",
        "group": "02560020",
        "group_num": [
            "rec6r0GDOd5KdV9pS"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recG0uzs7cnhEelwz",
        "row": 19828,
        "state": "",
        "group": "",
        "group_num": [
            "recUwq57p3JZc68BZ"
        ],
        "procedure": "IOP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "id": "recGQIeZKO1hY2ILS",
        "row": 19787,
        "state": "",
        "group": "10783165",
        "group_num": [
            "recW7oMpkRUawBuby"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1985,
        "id": "recGdSoJP6LGqcRJZ",
        "row": 19814,
        "state": "",
        "group": "01742600",
        "group_num": [
            "rec89COjBzo3y95Oo"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1772,
        "id": "recImYAboe4DrpbDL",
        "row": 19798,
        "state": "",
        "group": "01620502",
        "group_num": [
            "recSaZwng7YRNihZZ"
        ],
        "procedure": "RTC",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1869,
        "id": "recJB2KOyANy3YIeK",
        "row": 19817,
        "state": "",
        "group": "10486230",
        "group_num": [
            "recveaN1NihnZlTGD"
        ],
        "procedure": "Detox",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "2024": 265,
        "id": "recKPjMRTbi4bDUqz",
        "row": 19825,
        "state": "",
        "group": "",
        "group_num": [
            "recNnFoSsQ5Ad2p2d"
        ],
        "procedure": "PHP",
        "provider": "HIGHMARK BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 442,
        "id": "recmZhmFBqOzsIbdu",
        "row": 23537,
        "state": "",
        "group": "",
        "group_num": [
            "rec6WxJokDGsQC1ix"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 36,
        "id": "recU8WAgPPqhFTPc5",
        "row": 23544,
        "state": "",
        "group": "",
        "group_num": [
            "recnVk5cbUdTOMS4r"
        ],
        "procedure": "OP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 426,
        "id": "recWgXaFBmtVAVHAY",
        "row": 23545,
        "state": "",
        "group": "",
        "group_num": [
            "recnVk5cbUdTOMS4r"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 187,
        "id": "recayJtieWW570sU2",
        "row": 23546,
        "state": "",
        "group": "",
        "group_num": [
            "recvoW2Z78dwnGvls"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recpQa8XbaEiRFecF",
        "row": 23543,
        "state": "",
        "group": "",
        "group_num": [
            "reclcQBBIcNOrZ3fC"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 341,
        "id": "recr8eDlG0LURYwWn",
        "row": 23540,
        "state": "",
        "group": "",
        "group_num": [
            "recmFx2g5RVKYWGwu"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "recv7qJLOfqwkFvFP",
        "row": 23547,
        "state": "",
        "group": "",
        "group_num": [
            "recrju1FHMuTSfbBR"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "id": "recw9oEcDKDCBFjCo",
        "row": 23541,
        "state": "",
        "group": "",
        "group_num": [
            "recVRjrS9Src7zUV9"
        ],
        "procedure": "OP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5,
        "2023": 66,
        "id": "recxFNUOPIFJ7fI5W",
        "row": 23538,
        "state": "",
        "group": "",
        "group_num": [
            "reca6wVQoNXCF2tky"
        ],
        "procedure": "OP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recERk3rKUXBztAkv",
        "row": 23542,
        "state": "",
        "group": "",
        "group_num": [
            "reclcQBBIcNOrZ3fC"
        ],
        "procedure": "OP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 254,
        "id": "recNpxn4n9G1SmKBr",
        "row": 23539,
        "state": "",
        "group": "",
        "group_num": [
            "reca6wVQoNXCF2tky"
        ],
        "procedure": "IOP",
        "provider": "HMA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 725,
        "2023": 725,
        "id": "receutQxAvLi3ju7Y",
        "row": 23550,
        "state": "",
        "group": "",
        "group_num": [
            "recvITKCf7khqTqpA"
        ],
        "procedure": "IOP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 925,
        "id": "recRW9w6zz2MG2jqH",
        "row": 23549,
        "state": "",
        "group": "",
        "group_num": [
            "recvITKCf7khqTqpA"
        ],
        "procedure": "PHP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1227,
        "id": "recTGgcR7ZGboGoMM",
        "row": 23554,
        "state": "",
        "group": "UFC001",
        "group_num": [
            "recJYYg5P2tLl8unS"
        ],
        "procedure": "Detox",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1262,
        "id": "recUlRFKMBah6gsOs",
        "row": 23551,
        "state": "",
        "group": "",
        "group_num": [
            "recBiTStUA224vLoR"
        ],
        "procedure": "RTC",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 925,
        "id": "recXX0JmTx5LbSwAg",
        "row": 23553,
        "state": "",
        "group": "UFC001",
        "group_num": [
            "recJYYg5P2tLl8unS"
        ],
        "procedure": "PHP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 725,
        "2023": 649,
        "id": "rec4AER0k3ACJcImI",
        "row": 23555,
        "state": "",
        "group": "",
        "group_num": [
            "rec8YimRkSAkCZXdX"
        ],
        "procedure": "IOP",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1071,
        "id": "recpoRqx8oHmNOjn0",
        "row": 23556,
        "state": "",
        "group": "",
        "group_num": [
            "rec8YimRkSAkCZXdX"
        ],
        "procedure": "Detox",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1420,
        "id": "rectAm4xgidqDxB7Y",
        "row": 23548,
        "state": "",
        "group": "",
        "group_num": [
            "recvITKCf7khqTqpA"
        ],
        "procedure": "RTC",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recIC4x4jCYzcDjyO",
        "row": 23552,
        "state": "",
        "group": "",
        "group_num": [
            "recBiTStUA224vLoR"
        ],
        "procedure": "Detox",
        "provider": "HMC HEALTHWORKS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 376,
        "id": "recl3RbnbQdzOv4Q0",
        "row": 23557,
        "state": "",
        "group": "",
        "group_num": [
            "recLrAo2JkeB70NYB"
        ],
        "procedure": "PHP",
        "provider": "HOLMAN GROUP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 675,
        "2023": 675,
        "id": "recTL4raNzwz9anHk",
        "row": 23559,
        "state": "",
        "group": "400143",
        "group_num": [
            "recX4jdA5XnkxL2PM"
        ],
        "procedure": "PHP",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 855,
        "2023": 855,
        "id": "rec0a8NcQzWpqRvOr",
        "row": 23558,
        "state": "",
        "group": "400143",
        "group_num": [
            "recX4jdA5XnkxL2PM"
        ],
        "procedure": "RTC",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "id": "rec2nwFgnPfcDXabD",
        "row": 23561,
        "state": "",
        "group": "",
        "group_num": [
            "rechx3yIHr7wid3Ll"
        ],
        "procedure": "OP",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 251,
        "id": "rec4grEfjkOF2Sy6W",
        "row": 23562,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "PHP",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 215,
        "id": "rec8d3fhYABXlkhW5",
        "row": 23563,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "IOP",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 396,
        "id": "recF5ObaYNn85j47D",
        "row": 23560,
        "state": "",
        "group": "",
        "group_num": [
            "rechx3yIHr7wid3Ll"
        ],
        "procedure": "PHP",
        "provider": "HOMETOWN HEALTHPLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 674,
        "id": "recPPg8xcIgUJ4Jh7",
        "row": 19833,
        "state": "",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1053,
        "id": "rec8nL9WCRklw3QuD",
        "row": 19832,
        "state": "",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "rechY3vMwBfUKaw0l",
        "row": 19875,
        "state": "",
        "group": "00925000000",
        "group_num": [
            "recCZmq8VeyFp1P9q"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1443,
        "2024": 1554,
        "id": "rechvxsMjdOQlSWBv",
        "row": 19836,
        "state": "",
        "group": "",
        "group_num": [
            "rec2lxTGcDGBKvvPT"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rechIQ5PAgg2Hsw19",
        "row": 19860,
        "state": "",
        "group": "00761081141",
        "group_num": [
            "recuX7GZItSMLggNp"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "reciYW9nxWaR7igxj",
        "row": 19842,
        "state": "",
        "group": "00759060000",
        "group_num": [
            "recs43q0lrBDCIuw5"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1999,
        "id": "recnLVY5HSF3B5kqr",
        "row": 19857,
        "state": "",
        "group": "00760530100",
        "group_num": [
            "recofsMDBNZNiQICi"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recOt9zAbGFd0jpGq",
        "row": 19878,
        "state": "",
        "group": "00925000000",
        "group_num": [
            "recCZmq8VeyFp1P9q"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recP0oXH2lGoqk0Jh",
        "row": 19866,
        "state": "",
        "group": "",
        "group_num": [
            "recV3VoBvRgH6mTzu"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1670,
        "id": "recPKbAS5YBIlI0Im",
        "row": 19840,
        "state": "",
        "group": "00758490038",
        "group_num": [
            "recVqK6SDOsn3Z955"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 956,
        "id": "recRFx2owWoc4A7a0",
        "row": 19839,
        "state": "",
        "group": "",
        "group_num": [
            "recWklkEX3V2zHsm5"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 140,
        "2023": 4500,
        "id": "recUST2m2M72mXDZK",
        "row": 19868,
        "state": "",
        "group": "935000000",
        "group_num": [
            "rec7A3vvnWnZsnSWv"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 461,
        "2024": 461,
        "id": "recVob2CYPaDsZYsa",
        "row": 19881,
        "state": "",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2089,
        "id": "recWT1Rz4f6xGb2FP",
        "row": 19850,
        "state": "",
        "group": "00758290014",
        "group_num": [
            "recE7MLCrWvbYKHw1"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1420,
        "id": "recWoT6ahQSnu1WJt",
        "row": 19873,
        "state": "",
        "group": "00917000000",
        "group_num": [
            "recU3zhicEP5VevvB"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2500,
        "id": "rec0CGAMW5HtsdxYd",
        "row": 19865,
        "state": "",
        "group": "",
        "group_num": [
            "rec14lSGd0AiFJ01c"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rec057JfW0BAtRYCN",
        "row": 19861,
        "state": "",
        "group": "00761081141",
        "group_num": [
            "recuX7GZItSMLggNp"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "rec1n08Wrm1jwbWfq",
        "row": 19877,
        "state": "",
        "group": "00925000000",
        "group_num": [
            "recCZmq8VeyFp1P9q"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1131,
        "2024": 1900,
        "id": "rec3Zh3C2ZlPwFcRe",
        "row": 19872,
        "state": "",
        "group": "00917000000",
        "group_num": [
            "recU3zhicEP5VevvB"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 435,
        "id": "rec3JKYUsNHLFGE60",
        "row": 19853,
        "state": "",
        "group": "",
        "group_num": [
            "recTkP2lTLKvu50zL"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1041,
        "2023": 976,
        "2024": 1000,
        "id": "rec6ySBG7Wigr92eg",
        "row": 19880,
        "state": "",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1006,
        "id": "rec7WQwR7nb7HQI5y",
        "row": 19863,
        "state": "",
        "group": "",
        "group_num": [
            "recNpNez811AsxJEK"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1249,
        "2023": 578,
        "2024": 1649,
        "id": "rec7FnPTHoctshUug",
        "row": 19847,
        "state": "",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1036,
        "id": "rec8hHAr109KOUiRC",
        "row": 19867,
        "state": "",
        "group": "",
        "group_num": [
            "recV3VoBvRgH6mTzu"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recaxa0NNqpiWiFln",
        "row": 19858,
        "state": "",
        "group": "",
        "group_num": [
            "recLxpZkwWniNlt26"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2013,
        "2023": 2109,
        "id": "recaMB55V5pCPrKX6",
        "row": 19834,
        "state": "",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recbiukZ3F6vUjCUH",
        "row": 19837,
        "state": "",
        "group": "00762010010",
        "group_num": [
            "recQ8rch5pmjJfeS7"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2992,
        "id": "reccI9MPByovESzV6",
        "row": 19841,
        "state": "",
        "group": "00758490038",
        "group_num": [
            "recVqK6SDOsn3Z955"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5218,
        "id": "recqQT13KN20McPF6",
        "row": 19856,
        "state": "",
        "group": "",
        "group_num": [
            "recOjsZJOpktqqRdw"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 892,
        "2023": 1087,
        "2024": 1029,
        "id": "recsw1RRenq0r3wD7",
        "row": 19848,
        "state": "",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2051,
        "id": "recslQL5OLZ8b3fuu",
        "row": 19849,
        "state": "",
        "group": "00758290014",
        "group_num": [
            "recE7MLCrWvbYKHw1"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recuYsJ6XUJMtwyuZ",
        "row": 19871,
        "state": "",
        "group": "00917000000",
        "group_num": [
            "recU3zhicEP5VevvB"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2038,
        "id": "recwYLQycImLpJYQB",
        "row": 19851,
        "state": "",
        "group": "00758290015",
        "group_num": [
            "rec221qOurmAGq9tE"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 66,
        "id": "recwLaAAzE8HoYM2g",
        "row": 19854,
        "state": "",
        "group": "",
        "group_num": [
            "recTkP2lTLKvu50zL"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "recwPEYPAzEqHy7mM",
        "row": 19846,
        "state": "",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1233,
        "id": "recxXw9XtxNf0MKC4",
        "row": 19874,
        "state": "",
        "group": "00917000002",
        "group_num": [
            "recpurEUP79fAB18B"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 398,
        "id": "recx0JgDca8mulsC7",
        "row": 19845,
        "state": "",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 712,
        "id": "recxCjiuA8kusNU3E",
        "row": 19844,
        "state": "",
        "group": "00757640008",
        "group_num": [
            "reciyMgwab1K8PeMG"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 699,
        "id": "reczBx5qI6Smia1dF",
        "row": 19852,
        "state": "",
        "group": "",
        "group_num": [
            "recTkP2lTLKvu50zL"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "reczlMzPgR6xLMFih",
        "row": 19869,
        "state": "",
        "group": "935000000",
        "group_num": [
            "rec7A3vvnWnZsnSWv"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1643,
        "id": "recAA4t7pIFUs51WC",
        "row": 19835,
        "state": "",
        "group": "00761150200",
        "group_num": [
            "recAJnbytFUYScw8J"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2359,
        "2023": 1747,
        "id": "recDTLs8d7GqxA5rZ",
        "row": 19882,
        "state": "",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1206,
        "2023": 1200,
        "2024": 1200,
        "id": "recDMYizafCND5FAu",
        "row": 19879,
        "state": "",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recD5xhGY5HPQytT4",
        "row": 19883,
        "state": "",
        "group": "00925000002",
        "group_num": [
            "reccmm7A2HASMIlAU"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recF1KSPZUm7vSmq9",
        "row": 19876,
        "state": "",
        "group": "00925000000",
        "group_num": [
            "recCZmq8VeyFp1P9q"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "id": "recGdAUwO3Ss0uVOq",
        "row": 19838,
        "state": "",
        "group": "00762010010",
        "group_num": [
            "recQ8rch5pmjJfeS7"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recI85MGWtn9VISMO",
        "row": 19864,
        "state": "",
        "group": "",
        "group_num": [
            "rec14lSGd0AiFJ01c"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3480,
        "id": "recJ2GW68nO8i4rcV",
        "row": 19855,
        "state": "",
        "group": "",
        "group_num": [
            "recrMaCHSejmXBtM2"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 4500,
        "id": "recJ9X0JUCEFFiT0b",
        "row": 19859,
        "state": "",
        "group": "",
        "group_num": [
            "recmyDDdzA5mAtnrL"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2578,
        "id": "recKTssELJP1xDfCd",
        "row": 19843,
        "state": "",
        "group": "00759060000",
        "group_num": [
            "recs43q0lrBDCIuw5"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 141,
        "id": "recLCnFbUIy1x325U",
        "row": 19862,
        "state": "",
        "group": "00761081141",
        "group_num": [
            "recuX7GZItSMLggNp"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1435,
        "id": "recNItHNDwCS5imRA",
        "row": 19870,
        "state": "",
        "group": "00917000000",
        "group_num": [
            "recU3zhicEP5VevvB"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "receMTKpdV4rPN5J0",
        "row": 19907,
        "state": "",
        "group": "00760393731",
        "group_num": [
            "recd7VTqut291xcG7"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1299,
        "id": "receOIO1nS8NMQRs9",
        "row": 19917,
        "state": "",
        "group": "008504F0000",
        "group_num": [
            "rec49oSUqX9TZqDkd"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1334,
        "id": "recekE5m4qB6ngHYn",
        "row": 19928,
        "state": "",
        "group": "",
        "group_num": [
            "rec4fyrSo4ms4ucqV"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1817,
        "id": "recgg2DhvAmNkQ2Gy",
        "row": 19923,
        "state": "",
        "group": "008513C",
        "group_num": [
            "rec3zhiEoTArEaV6C"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 348,
        "id": "recjR261ji3mJgKEd",
        "row": 19897,
        "state": "",
        "group": "",
        "group_num": [
            "recnZ5Nf3l8NBYGkp"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recj3xfJHlrMk3NtI",
        "row": 19926,
        "state": "",
        "group": "",
        "group_num": [
            "recVxzzYC450oh8f2"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 865,
        "id": "recjbbrbq1XxBDueb",
        "row": 19929,
        "state": "",
        "group": "",
        "group_num": [
            "recFBiWW3yyhhS4Lv"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recks7d2kMyJ6f69m",
        "row": 19911,
        "state": "",
        "group": "00935000002",
        "group_num": [
            "reciHjlxH7oKI1YMh"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 960,
        "id": "recnDOfeKRQsXwDNh",
        "row": 19895,
        "state": "",
        "group": "00761180001",
        "group_num": [
            "rec8Jn6AySCUaqrTf"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2155,
        "id": "recO4YtehdrlF72MC",
        "row": 19906,
        "state": "",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recRoBkElpXrlWip5",
        "row": 19927,
        "state": "",
        "group": "",
        "group_num": [
            "recfpGZBJJnbEWXSw"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "2024": 1000,
        "id": "recS7C2gwXO02kP9P",
        "row": 19893,
        "state": "",
        "group": "",
        "group_num": [
            "recTHo55zSmwv6vOg"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1211,
        "id": "recWTRzLVUCNfheDI",
        "row": 19913,
        "state": "",
        "group": "",
        "group_num": [
            "recinYouFpehsVQTw"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2069,
        "id": "recZstIF42Gl7ILZt",
        "row": 19910,
        "state": "",
        "group": "00760890000",
        "group_num": [
            "recUcZz6thRfympLJ"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 190,
        "2024": 190,
        "id": "rec0lDw73nE2WbT5F",
        "row": 19904,
        "state": "",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 739,
        "id": "rec2T7UwT1QyzMqAU",
        "row": 19894,
        "state": "",
        "group": "00761180001",
        "group_num": [
            "rec8Jn6AySCUaqrTf"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "rec3u1kttfzNwyTie",
        "row": 19908,
        "state": "",
        "group": "",
        "group_num": [
            "recsNK6KtpKuOXb9W"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 867,
        "id": "rec4miG0LJR3EbRJB",
        "row": 19892,
        "state": "",
        "group": "",
        "group_num": [
            "recTHo55zSmwv6vOg"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 360,
        "id": "rec5SkQDer8tmtE2B",
        "row": 19932,
        "state": "",
        "group": "",
        "group_num": [
            "recBJK2V8p2zwWRXR"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1600,
        "id": "rec8CzzJBICGn3QdE",
        "row": 19900,
        "state": "",
        "group": "",
        "group_num": [
            "recmB5s0AImKTg08r"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1111,
        "id": "recaRkuYdvdD9cCQh",
        "row": 19899,
        "state": "",
        "group": "00760890000",
        "group_num": [
            "recUcZz6thRfympLJ"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5000,
        "id": "recbGxSLL9Ppgf6pg",
        "row": 19912,
        "state": "",
        "group": "",
        "group_num": [
            "recFeNApK9XKp0XRk"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "2024": 1000,
        "id": "recb3nAAYemcu1LSc",
        "row": 19903,
        "state": "",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "reccGKHHtwTmeH1qV",
        "row": 19933,
        "state": "",
        "group": "",
        "group_num": [
            "recBJK2V8p2zwWRXR"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4290,
        "id": "recdFoyTzjpZUJNIU",
        "row": 19885,
        "state": "",
        "group": "00925000012",
        "group_num": [
            "recC6APjmEgvBx1po"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "recpu5YjGBi1RcKSu",
        "row": 19889,
        "state": "",
        "group": "00935000002",
        "group_num": [
            "reciHjlxH7oKI1YMh"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recqZWdyQNqMuils3",
        "row": 19922,
        "state": "",
        "group": "",
        "group_num": [
            "rech7A0MnKPyydTwe"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2122,
        "id": "recq5yA3HjYJUDj8g",
        "row": 19924,
        "state": "",
        "group": "008513C",
        "group_num": [
            "rec3zhiEoTArEaV6C"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1526,
        "id": "recrtCf8Ba18BgJzs",
        "row": 19886,
        "state": "",
        "group": "00935000000",
        "group_num": [
            "recs2lJlQqikf2G7E"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1173,
        "id": "recr81G4SNurc7gZI",
        "row": 19920,
        "state": "",
        "group": "",
        "group_num": [
            "recbtXiQEvrWaQhBU"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recrptXVufPZgqvon",
        "row": 19925,
        "state": "",
        "group": "",
        "group_num": [
            "recVxzzYC450oh8f2"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1097,
        "id": "recviupPak8Gx74ta",
        "row": 19914,
        "state": "",
        "group": "",
        "group_num": [
            "recinYouFpehsVQTw"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5000,
        "id": "recwx9AuNhDNFwZFg",
        "row": 19921,
        "state": "",
        "group": "",
        "group_num": [
            "recbtXiQEvrWaQhBU"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recw5rLmbXlHlzb6g",
        "row": 19884,
        "state": "",
        "group": "",
        "group_num": [
            "recfZ5553tYQZGzwx"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recwrgAgTIBtQJ24r",
        "row": 19916,
        "state": "",
        "group": "008504F0000",
        "group_num": [
            "rec49oSUqX9TZqDkd"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recx8xnHZcCVF3PTK",
        "row": 19915,
        "state": "",
        "group": "008504F0000",
        "group_num": [
            "rec49oSUqX9TZqDkd"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recBzhlIQBfAfsr9s",
        "row": 19930,
        "state": "",
        "group": "",
        "group_num": [
            "recFBiWW3yyhhS4Lv"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1085,
        "id": "recBcdnpDpRFbMRpt",
        "row": 19919,
        "state": "",
        "group": "",
        "group_num": [
            "recp52FV6LGQ47pLe"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1688,
        "id": "recCagSoPsiqlXzGY",
        "row": 19890,
        "state": "",
        "group": "",
        "group_num": [
            "recsNK6KtpKuOXb9W"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1989,
        "id": "recEuvpMSIL6o1oCS",
        "row": 19888,
        "state": "",
        "group": "00935000002",
        "group_num": [
            "reciHjlxH7oKI1YMh"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 686,
        "id": "recEvYIXr8ti8hYcg",
        "row": 19896,
        "state": "",
        "group": "",
        "group_num": [
            "recnZ5Nf3l8NBYGkp"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recE7RwDZK2Y0ga29",
        "row": 19902,
        "state": "",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3356,
        "2023": 3075,
        "id": "recEbWGJOIrrkqSjL",
        "row": 19901,
        "state": "",
        "group": "",
        "group_num": [
            "recmB5s0AImKTg08r"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1995,
        "id": "recG4iPTqxDqCxfXB",
        "row": 19905,
        "state": "",
        "group": "00762120000",
        "group_num": [
            "recOr5PHW1zjMPqFX"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2033,
        "id": "recGb6NbIuZS8Yw1J",
        "row": 19909,
        "state": "",
        "group": "00760890000",
        "group_num": [
            "recUcZz6thRfympLJ"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "2023": 2938,
        "id": "recI8aEsWuBqOEOCq",
        "row": 19887,
        "state": "",
        "group": "00935000000",
        "group_num": [
            "recs2lJlQqikf2G7E"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 367,
        "id": "recJzVQ0ySSOxIfv5",
        "row": 19918,
        "state": "",
        "group": "",
        "group_num": [
            "recp52FV6LGQ47pLe"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1268,
        "id": "recLH99nR3BXoHVfN",
        "row": 19891,
        "state": "",
        "group": "",
        "group_num": [
            "recsNK6KtpKuOXb9W"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recMVevjvuy5tq1LY",
        "row": 19898,
        "state": "",
        "group": "00760890000",
        "group_num": [
            "recUcZz6thRfympLJ"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 58,
        "id": "recNv8fti677ySnIL",
        "row": 19931,
        "state": "",
        "group": "",
        "group_num": [
            "recFBiWW3yyhhS4Lv"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1306,
        "id": "rechyEJxoZlbJcCui",
        "row": 19946,
        "state": "",
        "group": "00373D50078",
        "group_num": [
            "recFMp0M8r0u5tHh8"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 668,
        "2024": 740,
        "id": "recjl6qhuf5dQmOdK",
        "row": 19940,
        "state": "",
        "group": "",
        "group_num": [
            "rec2K9VlaSZx1QgBC"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1014,
        "id": "recYYy8eOPQwRXruA",
        "row": 19944,
        "state": "",
        "group": "",
        "group_num": [
            "recC4qoy5HiEl6h6P"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "rec28SAz0EJSvOnXe",
        "row": 19935,
        "state": "",
        "group": "",
        "group_num": [
            "recsXLMm5G2QbayD2"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 66,
        "id": "rec5v1fOPEH6vhSUv",
        "row": 19941,
        "state": "",
        "group": "",
        "group_num": [
            "rec2K9VlaSZx1QgBC"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1336,
        "id": "recdl0IAPp88fDkiw",
        "row": 19939,
        "state": "",
        "group": "",
        "group_num": [
            "recn9VTgOTTqX76Ap"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 978,
        "id": "recqyEiKWBl4fbtXm",
        "row": 19949,
        "state": "",
        "group": "00762600000",
        "group_num": [
            "rec0vNRWfBQGqSjBX"
        ],
        "procedure": "Detox",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 539,
        "id": "recqo2t9Sp5bGd8C5",
        "row": 19936,
        "state": "",
        "group": "",
        "group_num": [
            "recsXLMm5G2QbayD2"
        ],
        "procedure": "OP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1222,
        "id": "recvAGTRKqV0ubavG",
        "row": 19942,
        "state": "",
        "group": "",
        "group_num": [
            "recv6sO3yLr9H2KVy"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 598,
        "id": "recxATxv9zzDGddC3",
        "row": 19943,
        "state": "",
        "group": "",
        "group_num": [
            "recYKxg6eKNnkdYBi"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 906,
        "id": "recAb4xLpqxQ5AX64",
        "row": 19948,
        "state": "",
        "group": "00762600000",
        "group_num": [
            "rec0vNRWfBQGqSjBX"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 1000,
        "id": "recCfrbozCRsNv8AQ",
        "row": 19938,
        "state": "",
        "group": "",
        "group_num": [
            "recuCCQnJE0FPbHs8"
        ],
        "procedure": "IOP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 1200,
        "id": "recEZzpr5LvuwZrPj",
        "row": 19937,
        "state": "",
        "group": "",
        "group_num": [
            "recuCCQnJE0FPbHs8"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 916,
        "id": "recIq8FowUHcnB47Z",
        "row": 19945,
        "state": "",
        "group": "00373D50078",
        "group_num": [
            "recFMp0M8r0u5tHh8"
        ],
        "procedure": "RTC",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recJ1p8TA6PzG7iNY",
        "row": 19934,
        "state": "",
        "group": "",
        "group_num": [
            "recsXLMm5G2QbayD2"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recJKikEPOGpNbeDZ",
        "row": 19947,
        "state": "",
        "group": "00762600000",
        "group_num": [
            "rec0vNRWfBQGqSjBX"
        ],
        "procedure": "PHP",
        "provider": "HORIZON BCBS OF NJ ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1440,
        "2023": 742,
        "id": "recfPdyoBwqa8vpSN",
        "row": 23569,
        "state": "",
        "group": "",
        "group_num": [
            "recBvrnANElYyGPvE"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "id": "recg5adjCl7u2Fv74",
        "row": 23575,
        "state": "",
        "group": "685622",
        "group_num": [
            "recENmPNCL4WwaNqt"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recgqcdUIN2pdTV4S",
        "row": 23600,
        "state": "",
        "group": "840881",
        "group_num": [
            "recY48WqWZIWpXhYt"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1160,
        "id": "recj1N9HSGfSaooBi",
        "row": 23605,
        "state": "",
        "group": "",
        "group_num": [
            "recvre4vW8s9qTLN8"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 263,
        "2023": 134,
        "2024": 165,
        "id": "recjRZhsSmViP8M3d",
        "row": 23582,
        "state": "",
        "group": "",
        "group_num": [
            "recVnSAAh3BvKjC5C"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recOwNUpE7KWt1Ccq",
        "row": 23566,
        "state": "",
        "group": "619668",
        "group_num": [
            "recltlmqKCxBTxvVc"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "recOj4vamTFB5aCf9",
        "row": 23574,
        "state": "",
        "group": "",
        "group_num": [
            "recIox8gNKUdZsWUp"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 312,
        "id": "recQLEzwZnIGhQGIi",
        "row": 23594,
        "state": "",
        "group": "",
        "group_num": [
            "recLvzKAKhs2eIBCd"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 393,
        "id": "recR6xHfxzfLsSPeN",
        "row": 23578,
        "state": "",
        "group": "",
        "group_num": [
            "recOkEzZmuF1cZPYq"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1393,
        "2024": 2000,
        "id": "recVsCoGkPc6Q6msA",
        "row": 23576,
        "state": "",
        "group": "",
        "group_num": [
            "recTOPNmT1ddL59I9"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "id": "recWKed7EIbcbNxYl",
        "row": 23588,
        "state": "",
        "group": "",
        "group_num": [
            "recITVFEsV6CnltwF"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 715,
        "2023": 715,
        "2024": 715,
        "id": "recWL1ruHFKEaKcWx",
        "row": 23595,
        "state": "",
        "group": "827328",
        "group_num": [
            "recjUsjv6nSuIYmUJ"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 45,
        "2023": 126,
        "id": "recXZoNVEHU8DQ3y4",
        "row": 23590,
        "state": "",
        "group": "808007",
        "group_num": [
            "recdKx1kjUy52UFjw"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1997,
        "id": "recXugaLV0VRGOhJq",
        "row": 23570,
        "state": "",
        "group": "",
        "group_num": [
            "rec4XTKvaWC8zFEVU"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 686,
        "id": "recYGsFBmaIqm87AA",
        "row": 23585,
        "state": "",
        "group": "777843",
        "group_num": [
            "recoVHFCqSQ6MwP0w"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 263,
        "2023": 254,
        "id": "recZ0T2wRuywk34fy",
        "row": 23583,
        "state": "",
        "group": "",
        "group_num": [
            "recVnSAAh3BvKjC5C"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "id": "rec0HU0pkcFWkOrAX",
        "row": 23584,
        "state": "",
        "group": "766721",
        "group_num": [
            "recrsyBbftjm8jsyI"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 480,
        "id": "rec3g9qcM5WzWoi6D",
        "row": 23564,
        "state": "",
        "group": "",
        "group_num": [
            "recw22lJsJfKuGi16"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 750,
        "id": "rec4gzUrjeJjdRa3l",
        "row": 23573,
        "state": "",
        "group": "",
        "group_num": [
            "recIox8gNKUdZsWUp"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "rec53bl5AmkJFUjGH",
        "row": 23601,
        "state": "",
        "group": "840881",
        "group_num": [
            "recY48WqWZIWpXhYt"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 254,
        "id": "rec63CIPB5lvE1CZn",
        "row": 23604,
        "state": "",
        "group": "",
        "group_num": [
            "reczrqvjjO7lETk24"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "id": "rec8afSJmDPQQotBU",
        "row": 23572,
        "state": "",
        "group": "",
        "group_num": [
            "rec4XTKvaWC8zFEVU"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 249,
        "id": "rec9yCCHt4elnLRtA",
        "row": 23606,
        "state": "",
        "group": "",
        "group_num": [
            "recc31TpcIlOgzdjx"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 702,
        "id": "reccvYQSjTW2lcNbY",
        "row": 23591,
        "state": "",
        "group": "808007",
        "group_num": [
            "recdKx1kjUy52UFjw"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 27,
        "id": "reccOcZ6m9SKmdXMH",
        "row": 23568,
        "state": "",
        "group": "",
        "group_num": [
            "recBvrnANElYyGPvE"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 243,
        "id": "recotCyN4avF2EvrJ",
        "row": 23599,
        "state": "",
        "group": "834695",
        "group_num": [
            "rechu30yYO2UWMXaZ"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 715,
        "id": "recqaYhY4X7Tjk6so",
        "row": 23602,
        "state": "",
        "group": "",
        "group_num": [
            "recmavDqSLCrjMOTj"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 260,
        "id": "recuHLhdkDhoQILGS",
        "row": 23603,
        "state": "",
        "group": "",
        "group_num": [
            "recukfLlWehVFPoPR"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1160,
        "2023": 1160,
        "2024": 1160,
        "id": "recuoOqgnNL6T1aDG",
        "row": 23596,
        "state": "",
        "group": "827328",
        "group_num": [
            "recjUsjv6nSuIYmUJ"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 249,
        "id": "recvHGezuPp0WDQMh",
        "row": 23565,
        "state": "",
        "group": "",
        "group_num": [
            "recw22lJsJfKuGi16"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 249,
        "id": "recvRDBbborPaCJwH",
        "row": 23598,
        "state": "",
        "group": "",
        "group_num": [
            "recAP183kIBBmznFP"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 375,
        "id": "recyZ0pYgJ7mJEJ1c",
        "row": 23592,
        "state": "",
        "group": "",
        "group_num": [
            "recLvzKAKhs2eIBCd"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 260,
        "id": "recygJKvuVsoLWMgN",
        "row": 23587,
        "state": "",
        "group": "",
        "group_num": [
            "recdi6GbNY0RSRC4A"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 499,
        "2023": 511,
        "id": "reczHWTURgI2WNQr0",
        "row": 23581,
        "state": "",
        "group": "",
        "group_num": [
            "recVnSAAh3BvKjC5C"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 19,
        "id": "recBcYL8EXnw1WRq0",
        "row": 23586,
        "state": "",
        "group": "",
        "group_num": [
            "recdi6GbNY0RSRC4A"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "2023": 1663,
        "id": "recCUvIzSMRKDDj0D",
        "row": 23567,
        "state": "",
        "group": "",
        "group_num": [
            "recBvrnANElYyGPvE"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 160,
        "id": "recCxW0vwjKagnncJ",
        "row": 23593,
        "state": "",
        "group": "",
        "group_num": [
            "recLvzKAKhs2eIBCd"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 356,
        "id": "recEAtFX3UyLnnBIS",
        "row": 23579,
        "state": "",
        "group": "743356",
        "group_num": [
            "rec9zkHiXrvmUUUaZ"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 148,
        "2024": 716,
        "id": "recGXhQtjwwY0HbXs",
        "row": 23571,
        "state": "",
        "group": "",
        "group_num": [
            "rec4XTKvaWC8zFEVU"
        ],
        "procedure": "OP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 805,
        "id": "recG0336rpHjNeRai",
        "row": 23580,
        "state": "",
        "group": "743356",
        "group_num": [
            "rec9zkHiXrvmUUUaZ"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 499,
        "id": "recIzoC7olMYaCloY",
        "row": 23577,
        "state": "",
        "group": "",
        "group_num": [
            "rec6UezF2WS8mZTdY"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 494,
        "id": "recIk6Fw3f6e9oVyK",
        "row": 23597,
        "state": "",
        "group": "",
        "group_num": [
            "recAP183kIBBmznFP"
        ],
        "procedure": "PHP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 249,
        "id": "recMjpaISqzibIWJe",
        "row": 23589,
        "state": "",
        "group": "",
        "group_num": [
            "rec5LpXoCqxE6sLol"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 260,
        "id": "rec6ryMq915IzVdDl",
        "row": 23608,
        "state": "",
        "group": "",
        "group_num": [
            "receN3dCW1q811BSP"
        ],
        "procedure": "IOP",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "reccWUHdvMnEeGBRD",
        "row": 23609,
        "state": "",
        "group": "923382",
        "group_num": [
            "recCHKrsX1ednXVrX"
        ],
        "procedure": "Detox",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 715,
        "id": "recc6QhsUTEGwR8sX",
        "row": 23607,
        "state": "",
        "group": "",
        "group_num": [
            "recJvAHVtSmPoGxK0"
        ],
        "procedure": "RTC",
        "provider": "HUMANA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recQ1eAqeaI9HrhxW",
        "row": 23610,
        "state": "",
        "group": "P38825",
        "group_num": [
            "recvNEWQPx3inbWeV"
        ],
        "procedure": "RTC",
        "provider": "IBEW NECA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3448,
        "id": "rec9KACIldi71LdTU",
        "row": 23611,
        "state": "",
        "group": "P38825",
        "group_num": [
            "recvNEWQPx3inbWeV"
        ],
        "procedure": "Detox",
        "provider": "IBEW NECA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 741,
        "id": "recfZQYuklcDYlfTj",
        "row": 23615,
        "state": "",
        "group": "",
        "group_num": [
            "recl8p4v5dAAFwGkd"
        ],
        "procedure": "Detox",
        "provider": "IMAGINE360"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1057,
        "id": "recYf2X5BJicAO27Q",
        "row": 23612,
        "state": "",
        "group": "H880309",
        "group_num": [
            "recMooI0b1MsHTFJ6"
        ],
        "procedure": "RTC",
        "provider": "IMAGINE360"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 308,
        "id": "rec5QJnnzqbTnt3i6",
        "row": 23614,
        "state": "",
        "group": "H880309",
        "group_num": [
            "recMooI0b1MsHTFJ6"
        ],
        "procedure": "IOP",
        "provider": "IMAGINE360"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "id": "recs0t3nMRpvrYWEx",
        "row": 23613,
        "state": "",
        "group": "H880309",
        "group_num": [
            "recMooI0b1MsHTFJ6"
        ],
        "procedure": "PHP",
        "provider": "IMAGINE360"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1206,
        "id": "reckPVgrAJZbeWoCP",
        "row": 23616,
        "state": "",
        "group": "",
        "group_num": [
            "recABv1iEGrzMWBVp"
        ],
        "procedure": "Detox",
        "provider": "INDECS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "receL2519wDDoUNSt",
        "row": 19957,
        "state": "",
        "group": "",
        "group_num": [
            "recV1AwhRoNWHses7"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recl6SCim2ntd0qEg",
        "row": 19953,
        "state": "",
        "group": "008932",
        "group_num": [
            "recgb2WWpsrFVGbot"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recneAKyfBAWLnxnt",
        "row": 19962,
        "state": "",
        "group": "",
        "group_num": [
            "recBYGNrosqpiKVae"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4000,
        "id": "recQv1r53al2v0QAs",
        "row": 19960,
        "state": "",
        "group": "050630",
        "group_num": [
            "rectzjy86COXoKJaY"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1979,
        "id": "recRnwrGB4jh668j1",
        "row": 19955,
        "state": "",
        "group": "",
        "group_num": [
            "recFosuXYVLPkF8pD"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recSYuvxeDhao9Ect",
        "row": 19954,
        "state": "",
        "group": "008932",
        "group_num": [
            "recgb2WWpsrFVGbot"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recUArJcvUXyaMdxf",
        "row": 19956,
        "state": "",
        "group": "",
        "group_num": [
            "recFosuXYVLPkF8pD"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "reccTNQMfMywIztMx",
        "row": 19958,
        "state": "",
        "group": "005831",
        "group_num": [
            "rec2LDV8m3AE8c3Ui"
        ],
        "procedure": "PHP",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "rectUmBGVVCEOF1fk",
        "row": 19950,
        "state": "",
        "group": "",
        "group_num": [
            "recCTk86euQoFhJFy"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "2024": 2250,
        "id": "recvIAbhWotGUgkpy",
        "row": 19963,
        "state": "",
        "group": "",
        "group_num": [
            "recxLXOEicvjU18ij"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recwkFcJ3ZYr4fHwe",
        "row": 19959,
        "state": "",
        "group": "005831",
        "group_num": [
            "rec2LDV8m3AE8c3Ui"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4395,
        "id": "recAW0wikNK45QiM2",
        "row": 19961,
        "state": "",
        "group": "050630",
        "group_num": [
            "rectzjy86COXoKJaY"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1900,
        "id": "recJ9lBrT2pY6II8J",
        "row": 19952,
        "state": "",
        "group": "",
        "group_num": [
            "recF4yE3epsRHUwsZ"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 774,
        "id": "recMAX31NgZBUoMLG",
        "row": 19951,
        "state": "",
        "group": "",
        "group_num": [
            "recYzSD7TSWpfC8gF"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "id": "receBL7DIhGGFd61V",
        "row": 19975,
        "state": "",
        "group": "10054256",
        "group_num": [
            "recmhmDjmxmar5Kmg"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 689,
        "id": "reciItqOqW1W9MIzn",
        "row": 19974,
        "state": "",
        "group": "10054256",
        "group_num": [
            "recmhmDjmxmar5Kmg"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "reci6PZ10A0zKFxQ2",
        "row": 19979,
        "state": "",
        "group": "",
        "group_num": [
            "recBRqPwC3ZGH6nvR"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 828,
        "id": "reckW5OJoWaZXYskQ",
        "row": 19977,
        "state": "",
        "group": "10049870",
        "group_num": [
            "rec8GiUT3crTtwC7w"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 585,
        "id": "recR4rwivaAyJbBd2",
        "row": 19969,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "recReSbnBOIb5sLqd",
        "row": 19964,
        "state": "",
        "group": "10312121",
        "group_num": [
            "recUVeYj75WcOj8nf"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recVvxaK04pRiBud7",
        "row": 19972,
        "state": "",
        "group": "011061",
        "group_num": [
            "recVyR3sLysOG5I5c"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 467,
        "id": "recVksKbWO5CIf8Yw",
        "row": 19981,
        "state": "",
        "group": "10045164",
        "group_num": [
            "recIxqJbVKCyqM9M8"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 380,
        "id": "recXZEEkAh8iLFMTh",
        "row": 19966,
        "state": "",
        "group": "10425741",
        "group_num": [
            "rec2U5Z6BuABKCQgY"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 806,
        "id": "rec1p855gx2DCHBRD",
        "row": 19967,
        "state": "",
        "group": "",
        "group_num": [
            "rechhvTpqgMMddLYa"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "id": "rec2PKu6aYohQVbNy",
        "row": 19971,
        "state": "",
        "group": "10109083",
        "group_num": [
            "recrDEWO258h67sKL"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 612,
        "id": "rec5BVeub9BlnnUAP",
        "row": 19968,
        "state": "",
        "group": "10106282",
        "group_num": [
            "rec2iUXFrcK9ggRV2"
        ],
        "procedure": "IOP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "rec8Bi0IkBXT4fujQ",
        "row": 19970,
        "state": "",
        "group": "",
        "group_num": [
            "recBOf46M9aPNULQa"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "reccAOfeC9cbVC3SG",
        "row": 19978,
        "state": "",
        "group": "10049870",
        "group_num": [
            "rec8GiUT3crTtwC7w"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1736,
        "id": "reco24U8mGt01ZZho",
        "row": 19976,
        "state": "",
        "group": "",
        "group_num": [
            "recop1p0kDTKVtc6K"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 801,
        "id": "rectCPSxjC2dfXcVI",
        "row": 19983,
        "state": "",
        "group": "",
        "group_num": [
            "recM9pTHLZrScVp7i"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "recvYsgbKkJ5cAv5b",
        "row": 19980,
        "state": "",
        "group": "",
        "group_num": [
            "recMhOrzV8NrsDGb7"
        ],
        "procedure": "PHP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 554,
        "id": "recBCQQPgvEyi4VxF",
        "row": 19965,
        "state": "",
        "group": "10312121",
        "group_num": [
            "recUVeYj75WcOj8nf"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recBHY1daChD8aI5S",
        "row": 19973,
        "state": "",
        "group": "011061",
        "group_num": [
            "recVyR3sLysOG5I5c"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 636,
        "id": "recB2Hk1Q6MEtwPlK",
        "row": 19982,
        "state": "",
        "group": "10045164",
        "group_num": [
            "recIxqJbVKCyqM9M8"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 876,
        "id": "recgRDOSwXfvp847J",
        "row": 19988,
        "state": "",
        "group": "10053820",
        "group_num": [
            "rec0i4l63BNSbFHY1"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 296,
        "id": "recgdJCioTZHyyysp",
        "row": 19995,
        "state": "",
        "group": "",
        "group_num": [
            "reckMBYlp7pMFHIpo"
        ],
        "procedure": "IOP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 636,
        "id": "recgm2B7tvNCaoFhV",
        "row": 19987,
        "state": "",
        "group": "10053921",
        "group_num": [
            "recjWo90MCkqqKbUl"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "reciuVGQUZrFfwShq",
        "row": 19996,
        "state": "",
        "group": "",
        "group_num": [
            "recEbYqHpHLh5zmya"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "id": "reckbvdDr293SIz3w",
        "row": 19993,
        "state": "",
        "group": "",
        "group_num": [
            "recB6khxg24RAwb3i"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "id": "recmslYWjKiVywdRu",
        "row": 19984,
        "state": "",
        "group": "",
        "group_num": [
            "recZioI98EnmOt2bX"
        ],
        "procedure": "IOP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 335,
        "id": "recPYjZpS9pHb7GaW",
        "row": 19991,
        "state": "",
        "group": "",
        "group_num": [
            "recOTjpRcgkRKieyc"
        ],
        "procedure": "PHP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 156,
        "id": "recxlAHWh8vO8vRyc",
        "row": 19992,
        "state": "",
        "group": "",
        "group_num": [
            "recOTjpRcgkRKieyc"
        ],
        "procedure": "IOP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "2024": 577,
        "id": "recygY4UFIapAcH2j",
        "row": 19997,
        "state": "",
        "group": "10706853",
        "group_num": [
            "recNchzbUw7t0hfHS"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "recCb9lWpQIZMyGea",
        "row": 19986,
        "state": "",
        "group": "10053921",
        "group_num": [
            "recjWo90MCkqqKbUl"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 636,
        "id": "recFJciSzoDsJ6q09",
        "row": 19989,
        "state": "",
        "group": "10053820",
        "group_num": [
            "rec0i4l63BNSbFHY1"
        ],
        "procedure": "Detox",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recHrPV3HviEkmP4B",
        "row": 19985,
        "state": "",
        "group": "",
        "group_num": [
            "recsqRWrfe2IQBJj9"
        ],
        "procedure": "IOP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 335,
        "id": "recIK1LpqgoOl4h0m",
        "row": 19994,
        "state": "",
        "group": "",
        "group_num": [
            "reckMBYlp7pMFHIpo"
        ],
        "procedure": "PHP",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 585,
        "id": "recIOrph1XB9kCpzO",
        "row": 19990,
        "state": "",
        "group": "",
        "group_num": [
            "recDFaVkTwyK6sJdN"
        ],
        "procedure": "RTC",
        "provider": "INDEPENDENCE BCBS OF PA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3600,
        "id": "recIa9B9rrBxch7oH",
        "row": 23617,
        "state": "",
        "group": "",
        "group_num": [
            "rec9Vt3ULjL98k3b1"
        ],
        "procedure": "Detox",
        "provider": "INDIANA UNIVERSITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 52,
        "id": "recj59jUcZvStRoVx",
        "row": 23618,
        "state": "",
        "group": "",
        "group_num": [
            "recepbBzwEmzn5Jno"
        ],
        "procedure": "OP",
        "provider": "INNOVATION HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 408,
        "id": "recQRu2EqmYZ7ktLF",
        "row": 23619,
        "state": "",
        "group": "",
        "group_num": [
            "recLykIccqZHkC7vp"
        ],
        "procedure": "IOP",
        "provider": "INNOVATION HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 52,
        "id": "recRQl87ukuVleZDK",
        "row": 23620,
        "state": "",
        "group": "",
        "group_num": [
            "recIlFMuXkH70XjP1"
        ],
        "procedure": "OP",
        "provider": "INNOVATION HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 668,
        "2024": 786,
        "id": "recP9TM7PqEr5dKLB",
        "row": 23621,
        "state": "",
        "group": "",
        "group_num": [
            "rec9KKy0aDeGByQTb"
        ],
        "procedure": "RTC",
        "provider": "INTERVENTION STRATEGIES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "rec9xxb4gRx9ohPM6",
        "row": 23622,
        "state": "",
        "group": "",
        "group_num": [
            "rec9KKy0aDeGByQTb"
        ],
        "procedure": "Detox",
        "provider": "INTERVENTION STRATEGIES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recjVQ4ooO2qspCvD",
        "row": 23629,
        "state": "",
        "group": "",
        "group_num": [
            "recGF6ERE0hcDfZ9g"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1158,
        "id": "recnXebfpxjDoKqb9",
        "row": 23627,
        "state": "",
        "group": "",
        "group_num": [
            "rec9HpwhIMFy7A25K"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 260,
        "id": "recPjZP9SqguYGiwo",
        "row": 23624,
        "state": "",
        "group": "",
        "group_num": [
            "recjMVccjcPmRDCnj"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 388,
        "id": "recVsW3uuSmxcn0uI",
        "row": 23623,
        "state": "",
        "group": "",
        "group_num": [
            "recIGLFKTuND5f0Wn"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 622,
        "2023": 1020,
        "id": "rec3rwKuBqNKSLH9q",
        "row": 23636,
        "state": "",
        "group": "",
        "group_num": [
            "recb8TndTpu746Bci"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 494,
        "2024": 520,
        "id": "rec8ZZ3sE41ZWrhmo",
        "row": 23626,
        "state": "",
        "group": "",
        "group_num": [
            "rec9HpwhIMFy7A25K"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 479,
        "id": "recakWELZeWw50Wjv",
        "row": 23631,
        "state": "",
        "group": "",
        "group_num": [
            "recvox8v6J9Hg3obR"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 488,
        "id": "recb2DFWlMd7nYD5q",
        "row": 23630,
        "state": "",
        "group": "",
        "group_num": [
            "recYlcbv15MlH32MH"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 232,
        "2023": 463,
        "id": "recbh32P5M8uqDqt6",
        "row": 23633,
        "state": "",
        "group": "",
        "group_num": [
            "recQPkhjq1Ji51fMN"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1080,
        "id": "reczIDImT7jFJJTpE",
        "row": 23625,
        "state": "",
        "group": "",
        "group_num": [
            "recjMVccjcPmRDCnj"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1038,
        "id": "recAzDInZ0NcZXoh5",
        "row": 23634,
        "state": "",
        "group": "",
        "group_num": [
            "recQPkhjq1Ji51fMN"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 913,
        "id": "recElX5lwjCbHQLRU",
        "row": 23632,
        "state": "",
        "group": "",
        "group_num": [
            "recvox8v6J9Hg3obR"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 883,
        "id": "recFAzO17O1XcdL1t",
        "row": 23628,
        "state": "",
        "group": "",
        "group_num": [
            "recGF6ERE0hcDfZ9g"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 504,
        "id": "recInWbNPVHjkK1jw",
        "row": 23635,
        "state": "",
        "group": "",
        "group_num": [
            "recb8TndTpu746Bci"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION HLTH PLAN OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "recQjZxX0D9r8Dntn",
        "row": 23637,
        "state": "",
        "group": "",
        "group_num": [
            "recEx89lKp5SOEW4p"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "id": "recXl7a4FuxFYdB2F",
        "row": 23642,
        "state": "",
        "group": "",
        "group_num": [
            "recwp6zEMaVpvHD8x"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "rec0lTKGksjbsGiaq",
        "row": 23638,
        "state": "",
        "group": "",
        "group_num": [
            "rech8f8Q8xiWRQm12"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1700,
        "id": "recyiaAUZd86rep09",
        "row": 23639,
        "state": "",
        "group": "",
        "group_num": [
            "rec3gmw0pi4VYaLFc"
        ],
        "procedure": "IOP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recAZ58PcJxKnUhKP",
        "row": 23641,
        "state": "",
        "group": "",
        "group_num": [
            "recwp6zEMaVpvHD8x"
        ],
        "procedure": "OP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1785,
        "id": "recFBUvGFYLgwg2bn",
        "row": 23640,
        "state": "",
        "group": "",
        "group_num": [
            "recwp6zEMaVpvHD8x"
        ],
        "procedure": "PHP",
        "provider": "KAISER FOUNDATION OF CO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3700,
        "id": "recPz8JHCNKYNxUWs",
        "row": 23643,
        "state": "",
        "group": "",
        "group_num": [
            "recbDIcXk43h1J2Vc"
        ],
        "procedure": "RTC",
        "provider": "KAISER FOUNDATION SOUTHERN CA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recZ8xTnRqQJWjmcC",
        "row": 23644,
        "state": "",
        "group": "",
        "group_num": [
            "rec2dxAHkfuLliFJt"
        ],
        "procedure": "PHP",
        "provider": "KAISER OF NORTHERN CA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "recjYltk2otP5LIOR",
        "row": 23645,
        "state": "",
        "group": "",
        "group_num": [
            "recjCc90leFxCqhai"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 223,
        "id": "recVv8ZFYAKPlrjbb",
        "row": 23652,
        "state": "",
        "group": "",
        "group_num": [
            "rec6y7ShFJ5ADzpxc"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "rec3rureGIkeQo7xe",
        "row": 23646,
        "state": "",
        "group": "",
        "group_num": [
            "recgeeDIXgWgcpuBX"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recqFD8l8fBZkYsOQ",
        "row": 23648,
        "state": "",
        "group": "",
        "group_num": [
            "recD4kJMYnsfEtITE"
        ],
        "procedure": "RTC",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 860,
        "id": "recqpkTKCMc9f79lc",
        "row": 23653,
        "state": "",
        "group": "",
        "group_num": [
            "recgCyz6sw7VmxnAj"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 779,
        "2023": 779,
        "id": "recr4U15NWmqtw9a5",
        "row": 23656,
        "state": "",
        "group": "80001",
        "group_num": [
            "recSJI39sKkMWJ36r"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "recw6VaSdnVJDg6bb",
        "row": 23649,
        "state": "",
        "group": "",
        "group_num": [
            "recTiFT1AUlvTSBcd"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "recB1OKNjaKNX6bhT",
        "row": 23647,
        "state": "",
        "group": "",
        "group_num": [
            "recr6IjftQ96WVKsp"
        ],
        "procedure": "OP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recB86qdxoyRhy0E7",
        "row": 23650,
        "state": "",
        "group": "",
        "group_num": [
            "recvdmgz0immDxUhN"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recE9mIOQiTxsbUez",
        "row": 23655,
        "state": "",
        "group": "",
        "group_num": [
            "recXObHQ4hKrqmBTg"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "recKTl6ig9eSVBjKh",
        "row": 23654,
        "state": "",
        "group": "",
        "group_num": [
            "rectGz1zNb5ctQlE8"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 191,
        "2023": 219,
        "id": "recMbeUkC1f9H4KK1",
        "row": 23651,
        "state": "",
        "group": "",
        "group_num": [
            "rec6y7ShFJ5ADzpxc"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "reclo4v9m1n7eSvIL",
        "row": 23661,
        "state": "",
        "group": "",
        "group_num": [
            "recUBiWJ4XrJlGQWA"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 488,
        "id": "recmAVCtPjCuRfHuz",
        "row": 23667,
        "state": "",
        "group": "",
        "group_num": [
            "recb8TndTpu746Bci"
        ],
        "procedure": "OP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 962,
        "id": "recWTKPDcpDqTJ0kK",
        "row": 23657,
        "state": "",
        "group": "",
        "group_num": [
            "recrjTmL7FcQw5ZzE"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 137,
        "id": "recYD64KC9W63tzoz",
        "row": 23670,
        "state": "",
        "group": "",
        "group_num": [
            "recND9JVUSTvEYPiL"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 173,
        "id": "recZIPAzl4cvu9HV8",
        "row": 23665,
        "state": "",
        "group": "",
        "group_num": [
            "recjKnFZAcniqcSkd"
        ],
        "procedure": "OP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "recZacbO7Bsvn6I4L",
        "row": 23658,
        "state": "",
        "group": "",
        "group_num": [
            "rec9G118Lp5WYBq82"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "rec2bzYDKu0pECJqU",
        "row": 23662,
        "state": "",
        "group": "",
        "group_num": [
            "rec2yxMLjqtc4UGNJ"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "rec3yxtqTwybKfPnz",
        "row": 23664,
        "state": "",
        "group": "",
        "group_num": [
            "rec0jxtMbYJWhbhfz"
        ],
        "procedure": "OP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "rec5y3GT9JGzb9H8D",
        "row": 23659,
        "state": "",
        "group": "",
        "group_num": [
            "reczOHBSN7yv7353r"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 306,
        "id": "rec6bUlRBYT0P7Sud",
        "row": 23666,
        "state": "",
        "group": "",
        "group_num": [
            "recpGrVTOIBRcAMak"
        ],
        "procedure": "OP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2126,
        "2023": 725,
        "id": "recApkxwcsbck1R6J",
        "row": 23663,
        "state": "",
        "group": "",
        "group_num": [
            "recFefvS35gLP2iR1"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1372,
        "id": "recDgFx4n2bQQaSdm",
        "row": 23669,
        "state": "",
        "group": "",
        "group_num": [
            "recrRFGNXuKivZVWO"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 57,
        "id": "recKh3ePFDy53LGZG",
        "row": 23668,
        "state": "",
        "group": "",
        "group_num": [
            "recmamZz8KdoMnEoX"
        ],
        "procedure": "OP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "recMLy7yVQpC2HXgA",
        "row": 23660,
        "state": "",
        "group": "",
        "group_num": [
            "recVBoNtDbhpqbbM2"
        ],
        "procedure": "PHP",
        "provider": "KAISER PERMANENTE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1200,
        "id": "recVbxtua2VFwNrud",
        "row": 23671,
        "state": "",
        "group": "",
        "group_num": [
            "recgCyz6sw7VmxnAj"
        ],
        "procedure": "IOP",
        "provider": "KAISER PERMANENTE OF MD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 674,
        "id": "recnquU8NAJIBWMzM",
        "row": 23672,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "RTC",
        "provider": "LINECO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "recQrkeah3Nxoc7dk",
        "row": 23673,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "Detox",
        "provider": "LINECO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1069,
        "id": "rec4Ff0Zg0r9k3TiD",
        "row": 23675,
        "state": "",
        "group": "",
        "group_num": [
            "recTQRGrCXyyWhVyS"
        ],
        "procedure": "Detox",
        "provider": "LOOMIS COMPANY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1069,
        "id": "recto6B51XBOQQKZ2",
        "row": 23674,
        "state": "",
        "group": "",
        "group_num": [
            "recTQRGrCXyyWhVyS"
        ],
        "procedure": "RTC",
        "provider": "LOOMIS COMPANY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "receLbzj3Kd5rlDCK",
        "row": 23676,
        "state": "",
        "group": "005363001000101",
        "group_num": [
            "rec2NQl5207cep8Ei"
        ],
        "procedure": "RTC",
        "provider": "LOWER HUDSON VALLEY EAP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1172,
        "id": "recO0FkLk9o0gHDYQ",
        "row": 23677,
        "state": "",
        "group": "B09",
        "group_num": [
            "recx25e46KUejesTN"
        ],
        "procedure": "RTC",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "2024": 700,
        "id": "rec4rLHo8JWONtNSL",
        "row": 23683,
        "state": "",
        "group": "",
        "group_num": [
            "recFDFQiUXtKS0fCX"
        ],
        "procedure": "RTC",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "rec6HljVrbMLVfKCf",
        "row": 23684,
        "state": "",
        "group": "",
        "group_num": [
            "recFDFQiUXtKS0fCX"
        ],
        "procedure": "PHP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "2024": 700,
        "id": "rec8zAk59kXy2uyLL",
        "row": 23682,
        "state": "",
        "group": "",
        "group_num": [
            "rec52kHJ7x5soGwsU"
        ],
        "procedure": "Detox",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "2024": 700,
        "id": "recaPQsNSkl8qhyHo",
        "row": 23681,
        "state": "",
        "group": "",
        "group_num": [
            "rec52kHJ7x5soGwsU"
        ],
        "procedure": "RTC",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "2024": 700,
        "id": "recqjEqJO7UwfUZXI",
        "row": 23685,
        "state": "",
        "group": "",
        "group_num": [
            "recFDFQiUXtKS0fCX"
        ],
        "procedure": "Detox",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 334,
        "id": "recDbRTmdnUir9xZ4",
        "row": 23678,
        "state": "",
        "group": "B09",
        "group_num": [
            "recx25e46KUejesTN"
        ],
        "procedure": "PHP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 334,
        "id": "recENmtGMnd9LGtfS",
        "row": 23679,
        "state": "",
        "group": "B09",
        "group_num": [
            "recx25e46KUejesTN"
        ],
        "procedure": "IOP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "recFebx04TUszE14T",
        "row": 23680,
        "state": "",
        "group": "N55",
        "group_num": [
            "recjny2lQvGLzRrnW"
        ],
        "procedure": "IOP",
        "provider": "LUCENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 66,
        "id": "recefU5spwR6H847J",
        "row": 23692,
        "state": "",
        "group": "",
        "group_num": [
            "recrCGuOl8CRchd9V"
        ],
        "procedure": "OP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4750,
        "id": "reci9wfxMdYV2fx6K",
        "row": 23687,
        "state": "",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 50,
        "id": "recjhf7Vdt0r0qh1L",
        "row": 23695,
        "state": "",
        "group": "",
        "group_num": [
            "recelWSX70X2zMQ6W"
        ],
        "procedure": "OP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "reclq6DdBx8yf8s1H",
        "row": 23702,
        "state": "",
        "group": "W0069783",
        "group_num": [
            "rec7zY940J36cI3ry"
        ],
        "procedure": "OP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recm6ShhPvTa3QveL",
        "row": 23688,
        "state": "",
        "group": "901632",
        "group_num": [
            "recmVaLslADCrOoh7"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recPDOZmOMpDP46lt",
        "row": 23694,
        "state": "",
        "group": "0007408074",
        "group_num": [
            "recH45zdyJ5m85L8q"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "id": "recPF9LsDA6OVz2nD",
        "row": 23689,
        "state": "",
        "group": "10106282",
        "group_num": [
            "rec2iUXFrcK9ggRV2"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 483,
        "id": "recQZFFFrtfkBDVBO",
        "row": 23686,
        "state": "",
        "group": "",
        "group_num": [
            "recsfcPrxlJdSbOKj"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recY2TYFfqlvenN2m",
        "row": 23698,
        "state": "",
        "group": "A0000031",
        "group_num": [
            "recdJSKKs0F1nKXjV"
        ],
        "procedure": "PHP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "id": "recZKOIpeurUl49dx",
        "row": 23697,
        "state": "",
        "group": "A0000031",
        "group_num": [
            "recdJSKKs0F1nKXjV"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1754,
        "id": "rec1C0rUOnC1eezKg",
        "row": 23699,
        "state": "",
        "group": "",
        "group_num": [
            "recxrtu2ZZA8Z6rbP"
        ],
        "procedure": "RTC",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 837,
        "2023": 792,
        "id": "rec6Ga6jLUl78AZsU",
        "row": 23700,
        "state": "",
        "group": "",
        "group_num": [
            "reclzl9ORYkw4rJOG"
        ],
        "procedure": "PHP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 62,
        "id": "rec9o9o7RpdKFUcuM",
        "row": 23691,
        "state": "",
        "group": "",
        "group_num": [
            "recrCGuOl8CRchd9V"
        ],
        "procedure": "PHP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "rectndMC28y1NVMqh",
        "row": 23693,
        "state": "",
        "group": "",
        "group_num": [
            "recrCGuOl8CRchd9V"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 486,
        "id": "recDTr8IqGIdDYgc9",
        "row": 23701,
        "state": "",
        "group": "",
        "group_num": [
            "reclzl9ORYkw4rJOG"
        ],
        "procedure": "IOP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 52,
        "2023": 87,
        "id": "recF1jDBcDl361hQB",
        "row": 23696,
        "state": "",
        "group": "",
        "group_num": [
            "recOabhQ1pdrI6Rsd"
        ],
        "procedure": "OP",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recHdLFtY6WBT8lD4",
        "row": 23690,
        "state": "",
        "group": "000740803A",
        "group_num": [
            "recnPIZo8YcVyx0f3"
        ],
        "procedure": "Detox",
        "provider": "MAGELLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 580,
        "id": "recmHpWhj4ZoA7FOz",
        "row": 23703,
        "state": "",
        "group": "153",
        "group_num": [
            "recONEROyLFXCQlU3"
        ],
        "procedure": "Detox",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recQ70df61iIq6hoF",
        "row": 23706,
        "state": "",
        "group": "",
        "group_num": [
            "recHKQObwdF4qXaRR"
        ],
        "procedure": "IOP",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3200,
        "2023": 1023,
        "id": "recsGlLnfxEZU2LgG",
        "row": 23705,
        "state": "",
        "group": "",
        "group_num": [
            "recHKQObwdF4qXaRR"
        ],
        "procedure": "RTC",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recHYZPEiGEkK0bwl",
        "row": 23704,
        "state": "",
        "group": "",
        "group_num": [
            "recBx3LReFUaXOMN7"
        ],
        "procedure": "PHP",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1062,
        "2024": 2866,
        "id": "recoqVNudJsdw5Htr",
        "row": 23708,
        "state": "",
        "group": "",
        "group_num": [
            "recJpmGY5qkVfjoB7"
        ],
        "procedure": "RTC",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "recER5KqEC8PuipDO",
        "row": 23707,
        "state": "",
        "group": "",
        "group_num": [
            "recHKQObwdF4qXaRR"
        ],
        "procedure": "Detox",
        "provider": "MAGNACARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1482,
        "id": "recb0nXezfOaNnGu8",
        "row": 23709,
        "state": "",
        "group": "GR120",
        "group_num": [
            "recP2bG4YjkRygTrH"
        ],
        "procedure": "RTC",
        "provider": "MARPAI FKA CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 966,
        "id": "recctazLtJSpgdB6i",
        "row": 23710,
        "state": "",
        "group": "GR120",
        "group_num": [
            "recP2bG4YjkRygTrH"
        ],
        "procedure": "Detox",
        "provider": "MARPAI FKA CONTINENTAL BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 216,
        "id": "recNJAxuwlWiXyArk",
        "row": 23711,
        "state": "",
        "group": "",
        "group_num": [
            "recLcXgMmRpdEUDlR"
        ],
        "procedure": "IOP",
        "provider": "MARPAI HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "id": "recLmCRt9u54oTbCO",
        "row": 23712,
        "state": "",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MAYO MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2550,
        "id": "recmNA5HrfOGAb3UQ",
        "row": 23714,
        "state": "",
        "group": "",
        "group_num": [
            "recmav1T58oRkEbm3"
        ],
        "procedure": "Detox",
        "provider": "MCLAREN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2148,
        "id": "recb0OBxAWloT8FeP",
        "row": 23713,
        "state": "",
        "group": "",
        "group_num": [
            "recmav1T58oRkEbm3"
        ],
        "procedure": "RTC",
        "provider": "MCLAREN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "rectg1Cy3JSpRRSer",
        "row": 23716,
        "state": "",
        "group": "",
        "group_num": [
            "recaVIZN1XETAPJGG"
        ],
        "procedure": "RTC",
        "provider": "MCLAREN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 2250,
        "id": "recBihVf2kW6LCXbZ",
        "row": 23715,
        "state": "",
        "group": "",
        "group_num": [
            "recmmA3bkK9RaCRfD"
        ],
        "procedure": "RTC",
        "provider": "MCLAREN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 2145,
        "id": "recH9V6txFR4N4GFh",
        "row": 23717,
        "state": "",
        "group": "",
        "group_num": [
            "recPDjrVFyXrBO0R1"
        ],
        "procedure": "RTC",
        "provider": "MCLAREN HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 857,
        "id": "reca8JVu9PC8KRXEG",
        "row": 23719,
        "state": "",
        "group": "",
        "group_num": [
            "recifTBImvsGNqTRJ"
        ],
        "procedure": "IOP",
        "provider": "MEDBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 763,
        "id": "recAHTdU372ta91e4",
        "row": 23718,
        "state": "",
        "group": "",
        "group_num": [
            "recifTBImvsGNqTRJ"
        ],
        "procedure": "PHP",
        "provider": "MEDBEN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "recPKS9h4HMRE3yYe",
        "row": 23720,
        "state": "",
        "group": "P14479",
        "group_num": [
            "recLdQkdbuKla26BF"
        ],
        "procedure": "PHP",
        "provider": "MEDCARE TEAMESTERS LOCAL 731"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recTxBDoZ6ZQneczl",
        "row": 23721,
        "state": "",
        "group": "P14479",
        "group_num": [
            "recLdQkdbuKla26BF"
        ],
        "procedure": "OP",
        "provider": "MEDCARE TEAMESTERS LOCAL 731"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "id": "recyAZjFsRPWRl9lr",
        "row": 23722,
        "state": "",
        "group": "P14479",
        "group_num": [
            "recLdQkdbuKla26BF"
        ],
        "procedure": "IOP",
        "provider": "MEDCARE TEAMESTERS LOCAL 731"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rece1CJ3mGHEvjuVA",
        "row": 23733,
        "state": "",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "reclWiZ5lU60CwB7w",
        "row": 23723,
        "state": "",
        "group": "7107",
        "group_num": [
            "recL6aIl5bXyY5ogy"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 982,
        "id": "recOyy3HkQURdbeRC",
        "row": 23730,
        "state": "",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 953,
        "2024": 858,
        "id": "recU2gDqtaGDbbYnD",
        "row": 23727,
        "state": "",
        "group": "8180",
        "group_num": [
            "recDDjj9Jw8sYYlfR"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recXdHVBMlE5SFeQL",
        "row": 23732,
        "state": "",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "IOP",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4146,
        "id": "rec1XACBh5H1bc8iP",
        "row": 23734,
        "state": "",
        "group": "WAK",
        "group_num": [
            "rec1kwI3Wm8GprSBB"
        ],
        "procedure": "RTC",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "rec6pSOnVWF1bayax",
        "row": 23731,
        "state": "",
        "group": "9972",
        "group_num": [
            "recEuaaMEEuTJqt48"
        ],
        "procedure": "PHP",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "rec9CQlSnZn6e4H03",
        "row": 23735,
        "state": "",
        "group": "WAK",
        "group_num": [
            "rec1kwI3Wm8GprSBB"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 194,
        "id": "recbDZ6R7jRuEc9Tf",
        "row": 23725,
        "state": "",
        "group": "",
        "group_num": [
            "rec61G5iPg2PjvMMm"
        ],
        "procedure": "IOP",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 980,
        "id": "recdrP1bNj2Y70htl",
        "row": 23726,
        "state": "",
        "group": "",
        "group_num": [
            "rec4tmvF6XfnYaR7r"
        ],
        "procedure": "PHP",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recwlcTL9LKAYOtCS",
        "row": 23724,
        "state": "",
        "group": "7107",
        "group_num": [
            "recL6aIl5bXyY5ogy"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2976,
        "id": "recycdbycgFmjP1t9",
        "row": 23729,
        "state": "",
        "group": "8180",
        "group_num": [
            "recDDjj9Jw8sYYlfR"
        ],
        "procedure": "Detox",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 617,
        "id": "recCx31w9C5iDSqiT",
        "row": 23728,
        "state": "",
        "group": "8180",
        "group_num": [
            "recDDjj9Jw8sYYlfR"
        ],
        "procedure": "PHP",
        "provider": "MEDCOST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1552,
        "id": "recev4EJMmMSrvmFU",
        "row": 23741,
        "state": "",
        "group": "",
        "group_num": [
            "rec9yVrK7SdOIj5X7"
        ],
        "procedure": "IOP",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2049,
        "2023": 1900,
        "id": "recew7bQPiJ6Kd7we",
        "row": 23752,
        "state": "",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "RTC",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2618,
        "2023": 2375,
        "id": "recf0XbgT4zYLqPSq",
        "row": 23753,
        "state": "",
        "group": "A0021",
        "group_num": [
            "recv96wMY3yYHiHgX"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 520,
        "id": "recjMKcK6D4k3MiuR",
        "row": 23737,
        "state": "",
        "group": "",
        "group_num": [
            "recA41dGvYTmk2nht"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recklLBbmShKnTfro",
        "row": 23746,
        "state": "",
        "group": "",
        "group_num": [
            "recN3mKH2R2Mbfo4Y"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recl4LGNcVu4ve8Jh",
        "row": 23751,
        "state": "",
        "group": "",
        "group_num": [
            "recCbjDefKRGZQzEz"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2037,
        "id": "recPZJFhgNQunSMAD",
        "row": 23740,
        "state": "",
        "group": "",
        "group_num": [
            "rec9yVrK7SdOIj5X7"
        ],
        "procedure": "PHP",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recSAPESpf0MbiGiD",
        "row": 23750,
        "state": "",
        "group": "",
        "group_num": [
            "rec1X9r4IYa5i7Rlv"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recVQgXbTgAEzX107",
        "row": 23749,
        "state": "",
        "group": "",
        "group_num": [
            "rec6UVGeSw8aPhOCd"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3150,
        "id": "recXhuwwMKoVIDc99",
        "row": 23743,
        "state": "",
        "group": "",
        "group_num": [
            "reck8LY32CUf1Xq95"
        ],
        "procedure": "PHP",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 520,
        "id": "rec7853ecUFzVWE2z",
        "row": 23747,
        "state": "",
        "group": "",
        "group_num": [
            "recO12zb8z8RSJIlf"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec8FGvCr1VhcHYvt",
        "row": 23742,
        "state": "",
        "group": "",
        "group_num": [
            "receoLVuqClkl8e8b"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recbXNvyt7vnxxEQq",
        "row": 23748,
        "state": "",
        "group": "",
        "group_num": [
            "recVXdfbCe6QdTI9u"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "2024": 650,
        "id": "recceaIT2Z62qtAK8",
        "row": 23736,
        "state": "",
        "group": "",
        "group_num": [
            "rechQ6ZgSCgDA3U8K"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2198,
        "id": "recdAZ8mkWH0kWBMB",
        "row": 23756,
        "state": "",
        "group": "",
        "group_num": [
            "recqbJ5KXTB94WNvL"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3088,
        "2023": 3088,
        "id": "recpWbSrJ3P8xDqzu",
        "row": 23754,
        "state": "",
        "group": "A0022",
        "group_num": [
            "rec5Gx9cEIgdcinOc"
        ],
        "procedure": "RTC",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2655,
        "id": "recp6zgdP7aXvvNaq",
        "row": 23744,
        "state": "",
        "group": "",
        "group_num": [
            "reck8LY32CUf1Xq95"
        ],
        "procedure": "IOP",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1141,
        "id": "recqhinM1k8qluPPH",
        "row": 23738,
        "state": "",
        "group": "",
        "group_num": [
            "recuejyWht4FrR7uo"
        ],
        "procedure": "IOP",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recxf7Dez8u2BhERo",
        "row": 23745,
        "state": "",
        "group": "",
        "group_num": [
            "recwlR9tB5EDBvgCe"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2198,
        "id": "recDSCiJSASWero7R",
        "row": 23755,
        "state": "",
        "group": "A0022",
        "group_num": [
            "rec5Gx9cEIgdcinOc"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 488,
        "2024": 650,
        "id": "recEWpPyeoJ4CEprb",
        "row": 23739,
        "state": "",
        "group": "",
        "group_num": [
            "recCdZ0EEoeZFzCKF"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recdAydSvJrCCOYmd",
        "row": 23757,
        "state": "",
        "group": "",
        "group_num": [
            "recbuds6b3e7GoTx5"
        ],
        "procedure": "Detox",
        "provider": "MEDICA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1000,
        "id": "rece2AnTKevjIy9wK",
        "row": 23763,
        "state": "",
        "group": "",
        "group_num": [
            "rec4y8rM2jS8yhDg3"
        ],
        "procedure": "Detox",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "id": "recmD9cmshewxeGaf",
        "row": 23764,
        "state": "",
        "group": "851718563",
        "group_num": [
            "rec64h4aZ1C9xRyo1"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "2024": 164,
        "id": "recOYPdgzfi3eSmhP",
        "row": 23759,
        "state": "",
        "group": "139467003",
        "group_num": [
            "rec1moJeG2Mxu8OXT"
        ],
        "procedure": "IOP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "id": "recRGDGytvbbdN7I3",
        "row": 23761,
        "state": "",
        "group": "418470272",
        "group_num": [
            "recz5aHckRN1UAw60"
        ],
        "procedure": "PHP",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 950,
        "id": "recVwZHhp7lA0zl1U",
        "row": 23762,
        "state": "",
        "group": "",
        "group_num": [
            "rec4y8rM2jS8yhDg3"
        ],
        "procedure": "RTC",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "2024": 450,
        "id": "recqwXTC66blcnZzu",
        "row": 23758,
        "state": "",
        "group": "139467003",
        "group_num": [
            "rec1moJeG2Mxu8OXT"
        ],
        "procedure": "RTC",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1976,
        "id": "recxOnmW1JkbY1Roj",
        "row": 23760,
        "state": "",
        "group": "139467003",
        "group_num": [
            "rec1moJeG2Mxu8OXT"
        ],
        "procedure": "Detox",
        "provider": "MEDICAL MUTUAL OF OH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "id": "recfueT2u3LO09PpJ",
        "row": 23781,
        "state": "",
        "group": "",
        "group_num": [
            "recyQEcCFcymQuoTI"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3242,
        "id": "reci7BPg9FsxoYGXB",
        "row": 23791,
        "state": "",
        "group": "16009",
        "group_num": [
            "recZBmDPWpNaHVFyZ"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 527,
        "id": "recjZjLpVYQ7XkhU0",
        "row": 23769,
        "state": "",
        "group": "",
        "group_num": [
            "recRHJRDTRkWEuVJg"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1653,
        "2024": 1044,
        "id": "recmRE5egDY2GElHL",
        "row": 23801,
        "state": "",
        "group": "",
        "group_num": [
            "rechXffrYBDgo5XWG"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1135,
        "id": "recnWqfuY013BdAUA",
        "row": 23788,
        "state": "",
        "group": "",
        "group_num": [
            "recQQa59UteavfN2G"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 360,
        "id": "recRSNmJ0140KywRx",
        "row": 23784,
        "state": "",
        "group": "",
        "group_num": [
            "rec6jeMDh2aXVpW7v"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1250,
        "id": "recTRcXwn6xyHUBab",
        "row": 23785,
        "state": "",
        "group": "",
        "group_num": [
            "rec2weoNbSjL7Irbx"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2593,
        "2024": 2593,
        "id": "recTgSgEgegnQuoMF",
        "row": 23776,
        "state": "",
        "group": "14526",
        "group_num": [
            "recqdx8BAslpClkfU"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1215,
        "id": "recUhE3DRr6IxTaDv",
        "row": 23800,
        "state": "",
        "group": "",
        "group_num": [
            "recF7k2477f8WgPB4"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 669,
        "id": "recVLThHLYaGS6odc",
        "row": 23806,
        "state": "",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2808,
        "2024": 1190,
        "id": "recXz3aOOq2FnwzPG",
        "row": 23777,
        "state": "",
        "group": "14526",
        "group_num": [
            "recqdx8BAslpClkfU"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 383,
        "id": "recXQLbExnAWWzpWz",
        "row": 23783,
        "state": "",
        "group": "",
        "group_num": [
            "recpmNI25ooSB2xMz"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1582,
        "id": "recZOAbi9vqZMwjpE",
        "row": 23770,
        "state": "",
        "group": "",
        "group_num": [
            "rec2OEPhIA0HHlV12"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1177,
        "id": "recZ8R1lXgvZsJxhL",
        "row": 23793,
        "state": "",
        "group": "16115",
        "group_num": [
            "rec8IXCfxFkM8sJzH"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1032,
        "id": "rec17gkXou57stXQQ",
        "row": 23795,
        "state": "",
        "group": "16115",
        "group_num": [
            "rec8IXCfxFkM8sJzH"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1352,
        "id": "rec5eitXEK7FUXgZR",
        "row": 23765,
        "state": "",
        "group": "",
        "group_num": [
            "recdMr4uB4TlXSVW9"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1308,
        "2024": 1308,
        "id": "rec6tRzs2YpORaxTR",
        "row": 23778,
        "state": "",
        "group": "14956",
        "group_num": [
            "recaV23UrqExF8c6e"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "rec65PVZEpS9Kumre",
        "row": 23782,
        "state": "",
        "group": "",
        "group_num": [
            "recpmNI25ooSB2xMz"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 554,
        "id": "rec6a0tDqvfqelozi",
        "row": 23802,
        "state": "",
        "group": "",
        "group_num": [
            "rechXffrYBDgo5XWG"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1881,
        "id": "recay4838L4nI2cbh",
        "row": 23787,
        "state": "",
        "group": "",
        "group_num": [
            "recQQa59UteavfN2G"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 694,
        "id": "recaCQKgyu8ReFNMM",
        "row": 23773,
        "state": "",
        "group": "",
        "group_num": [
            "recv7ushHx8pTZVYn"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1652,
        "id": "recbW10eelqFpcz9e",
        "row": 23779,
        "state": "",
        "group": "14956",
        "group_num": [
            "recaV23UrqExF8c6e"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recbzOm0Ki1dT3jsK",
        "row": 23789,
        "state": "",
        "group": "",
        "group_num": [
            "recQQa59UteavfN2G"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "id": "recdKhxOuIflpqb3m",
        "row": 23805,
        "state": "",
        "group": "",
        "group_num": [
            "rec442LY9mT9FEWxp"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 816,
        "id": "recoyYPdSDb6YfbJO",
        "row": 23766,
        "state": "",
        "group": "00490",
        "group_num": [
            "recINgeFcGz72iFia"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1190,
        "id": "recomzimrdQhYFdED",
        "row": 23796,
        "state": "",
        "group": "",
        "group_num": [
            "recwO6vQ4vliRCmCG"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 350,
        "id": "rectMfxxyxxSKBTqF",
        "row": 23804,
        "state": "",
        "group": "",
        "group_num": [
            "rechXffrYBDgo5XWG"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 256,
        "id": "recz0tuZ0CkRqAPgL",
        "row": 23768,
        "state": "",
        "group": "",
        "group_num": [
            "recRHJRDTRkWEuVJg"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1995,
        "id": "recA4wb05SFB9u5rv",
        "row": 23772,
        "state": "",
        "group": "14103",
        "group_num": [
            "recU9bHbFM80PecUj"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1363,
        "id": "recArtryrwipY30i9",
        "row": 23774,
        "state": "",
        "group": "14321",
        "group_num": [
            "recpgENzsWSWTtWko"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1381,
        "id": "recBFotFz7cZUbDht",
        "row": 23771,
        "state": "",
        "group": "13737",
        "group_num": [
            "recSdK2wQha1Y2fgW"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "2024": 695,
        "id": "recBeSTuhJ7DNIV6w",
        "row": 23794,
        "state": "",
        "group": "16115",
        "group_num": [
            "rec8IXCfxFkM8sJzH"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recBoKSRnOoBncMkX",
        "row": 23780,
        "state": "",
        "group": "",
        "group_num": [
            "recyQEcCFcymQuoTI"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2221,
        "id": "recGtx7nKZn6j2Gug",
        "row": 23790,
        "state": "",
        "group": "",
        "group_num": [
            "recTqgTckSuELbjNw"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 888,
        "id": "recGDo0As7isDIz1h",
        "row": 23767,
        "state": "",
        "group": "02406",
        "group_num": [
            "recgBKwWwcGld3NJn"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1927,
        "id": "recGH2yPzpx9tBR25",
        "row": 23786,
        "state": "",
        "group": "",
        "group_num": [
            "recQQa59UteavfN2G"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 705,
        "2024": 85,
        "id": "recHkQbZGyoYk6vMy",
        "row": 23799,
        "state": "",
        "group": "",
        "group_num": [
            "recd1v9kXkUrxHEvW"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2961,
        "id": "recHrXkPNG6V6THFL",
        "row": 23797,
        "state": "",
        "group": "16220",
        "group_num": [
            "recTNQTurntHQYIaw"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1748,
        "id": "recKsOUc33yYF6hKt",
        "row": 23798,
        "state": "",
        "group": "16220",
        "group_num": [
            "recTNQTurntHQYIaw"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4095,
        "id": "recKpGc3B0XXj6DLq",
        "row": 23792,
        "state": "",
        "group": "16009",
        "group_num": [
            "recZBmDPWpNaHVFyZ"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1510,
        "id": "recLmalKUWMwOcIzd",
        "row": 23775,
        "state": "",
        "group": "14321",
        "group_num": [
            "recpgENzsWSWTtWko"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "2024": 150,
        "id": "recNIQjtwapy8TopQ",
        "row": 23803,
        "state": "",
        "group": "",
        "group_num": [
            "rechXffrYBDgo5XWG"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 880,
        "id": "recfYa0gQd9EMiqoG",
        "row": 23822,
        "state": "",
        "group": "",
        "group_num": [
            "recNXzKWETjd4288U"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2020,
        "id": "recfvryCPHQ03D7Ks",
        "row": 23846,
        "state": "",
        "group": "",
        "group_num": [
            "recMNd1B1iLfbtUsG"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recfAGoKNbysqs8t8",
        "row": 23809,
        "state": "",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 983,
        "id": "recj230YcAWZ8b0lj",
        "row": 23847,
        "state": "",
        "group": "",
        "group_num": [
            "recIh94ECubfvvqcF"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1846,
        "2023": 2476,
        "2024": 1190,
        "id": "reclk2o7NJukJrx6N",
        "row": 23830,
        "state": "",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3800,
        "id": "recmqRbTfb1cjliV7",
        "row": 23823,
        "state": "",
        "group": "",
        "group_num": [
            "rec7Q6Sj7qZJ8zNYL"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 646,
        "id": "recnE2imChRWMAqEr",
        "row": 23826,
        "state": "",
        "group": "",
        "group_num": [
            "rec7ByZ2DaHBiVXP3"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 928,
        "2023": 909,
        "id": "recnQ6PACZzkvXBcF",
        "row": 23815,
        "state": "",
        "group": "",
        "group_num": [
            "receMPsqVnGDrjZxf"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 646,
        "id": "recOslUjXKMM27gtG",
        "row": 23835,
        "state": "",
        "group": "",
        "group_num": [
            "rec5D12WyDs2Fllqt"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 713,
        "id": "recPEa23TPmIy2Ei1",
        "row": 23813,
        "state": "",
        "group": "",
        "group_num": [
            "rec1frZE1UcPNhHWv"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1520,
        "id": "recQmElM4QKYjibH2",
        "row": 23845,
        "state": "",
        "group": "",
        "group_num": [
            "recMNd1B1iLfbtUsG"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 880,
        "id": "recSh60k6soW3POA3",
        "row": 23812,
        "state": "",
        "group": "",
        "group_num": [
            "rec3svPomGl7Rzr6f"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 909,
        "id": "recUQwiy9a2yCoxIB",
        "row": 23855,
        "state": "",
        "group": "AF016",
        "group_num": [
            "rec0J8CfGND3HVJg2"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 438,
        "id": "recV1bWp4wcmGN0Wt",
        "row": 23844,
        "state": "",
        "group": "",
        "group_num": [
            "recO0PADtrsj3vIFf"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1927,
        "id": "recWcGtM6iKkDBqCA",
        "row": 23849,
        "state": "",
        "group": "",
        "group_num": [
            "recnRwyW1BqtVRsFX"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3276,
        "id": "recWmibVoJnO92p00",
        "row": 23840,
        "state": "",
        "group": "18219",
        "group_num": [
            "recQDLaWIrl8WADBp"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 330,
        "2023": 330,
        "id": "recZ2IDKt0ngA5Xme",
        "row": 23807,
        "state": "",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recZeyLE4FyxTT5ma",
        "row": 23825,
        "state": "",
        "group": "",
        "group_num": [
            "recoM5dCcGhqEvUDu"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "rec0FJL3ZjvvqUwb3",
        "row": 23818,
        "state": "",
        "group": "",
        "group_num": [
            "recy7rxtXycYDoVqS"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "rec1u7QXtyzifh1dV",
        "row": 23853,
        "state": "",
        "group": "",
        "group_num": [
            "recyZtsi1sWOJnlec"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 391,
        "id": "rec1vgpTgHbRTksNQ",
        "row": 23836,
        "state": "",
        "group": "",
        "group_num": [
            "rec5D12WyDs2Fllqt"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1190,
        "id": "rec1yllcZtL9zPdrw",
        "row": 23810,
        "state": "",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 800,
        "id": "rec1JbIvsKKSu2AVF",
        "row": 23817,
        "state": "",
        "group": "",
        "group_num": [
            "recy7rxtXycYDoVqS"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1887,
        "id": "rec3CmpUBMHLTw0IP",
        "row": 23833,
        "state": "",
        "group": "",
        "group_num": [
            "recUTK284ULelyhRY"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "rec38NJ8Oklbjb3LC",
        "row": 23841,
        "state": "",
        "group": "18263",
        "group_num": [
            "recPoweq8hMcbkUNR"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 795,
        "2024": 816,
        "id": "rec4Y04l1yK8bIZpz",
        "row": 23828,
        "state": "",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1674,
        "2023": 2357,
        "id": "rec4spThXNWaiCv9U",
        "row": 23827,
        "state": "",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1927,
        "id": "rec55GGCf861KfsGJ",
        "row": 23848,
        "state": "",
        "group": "",
        "group_num": [
            "recnRwyW1BqtVRsFX"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1352,
        "id": "rec9NvRgvGltIqxM7",
        "row": 23820,
        "state": "",
        "group": "",
        "group_num": [
            "rec8n9AvNepMwnQyN"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 194,
        "id": "reccRD6WyHkFyLWuF",
        "row": 23837,
        "state": "",
        "group": "",
        "group_num": [
            "rec5D12WyDs2Fllqt"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1039,
        "id": "reccR3M6qoMiUwsrY",
        "row": 23842,
        "state": "",
        "group": "",
        "group_num": [
            "recO0PADtrsj3vIFf"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 419,
        "id": "recc9suAtQFeXpoTE",
        "row": 23808,
        "state": "",
        "group": "16863",
        "group_num": [
            "recNhmwG7uwVDyBV6"
        ],
        "procedure": "OP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 816,
        "2024": 816,
        "id": "recdeJfaQMQjRFksN",
        "row": 23839,
        "state": "",
        "group": "18219",
        "group_num": [
            "recQDLaWIrl8WADBp"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1044,
        "2024": 1044,
        "id": "recqQU4Axok92RN5l",
        "row": 23814,
        "state": "",
        "group": "",
        "group_num": [
            "rec0a0KxXe4Q7DPIw"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 482,
        "id": "recsWiCK2Pyh1cyhA",
        "row": 23843,
        "state": "",
        "group": "",
        "group_num": [
            "recO0PADtrsj3vIFf"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1201,
        "id": "recuttbnMYLb5pr9e",
        "row": 23832,
        "state": "",
        "group": "17711",
        "group_num": [
            "rec84UcRUtT2PsGYr"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "2023": 642,
        "id": "recx3ejamrLMF45HT",
        "row": 23829,
        "state": "",
        "group": "17515",
        "group_num": [
            "rec6vdVwrujZp5f9N"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2637,
        "id": "recASFkizPDt8qHC5",
        "row": 23854,
        "state": "",
        "group": "AF016",
        "group_num": [
            "rec0J8CfGND3HVJg2"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 570,
        "id": "recAjdO3chVYqoMui",
        "row": 23852,
        "state": "",
        "group": "",
        "group_num": [
            "reczXf6JU7faYbNoF"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 347,
        "2023": 311,
        "id": "recBTxlaJhQVJLvkv",
        "row": 23819,
        "state": "",
        "group": "",
        "group_num": [
            "recy7rxtXycYDoVqS"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 758,
        "id": "recBMnxlk3Ya4FK1Q",
        "row": 23816,
        "state": "",
        "group": "",
        "group_num": [
            "receMPsqVnGDrjZxf"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1136,
        "2024": 990,
        "id": "recCwjZrnxQQV61iM",
        "row": 23834,
        "state": "",
        "group": "",
        "group_num": [
            "recUTK284ULelyhRY"
        ],
        "procedure": "IOP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 391,
        "id": "recCBOoKuqljI9rM4",
        "row": 23821,
        "state": "",
        "group": "",
        "group_num": [
            "recNXzKWETjd4288U"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 681,
        "id": "recCMEXm8v3EHhLvz",
        "row": 23851,
        "state": "",
        "group": "",
        "group_num": [
            "reczXf6JU7faYbNoF"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1375,
        "id": "recDpPKCL31mNP4hi",
        "row": 23850,
        "state": "",
        "group": "",
        "group_num": [
            "rece4ppCVZ31F2rIE"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2628,
        "id": "recHBIGPP53AA46c3",
        "row": 23838,
        "state": "",
        "group": "18219",
        "group_num": [
            "recQDLaWIrl8WADBp"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3331,
        "id": "recIGL1Bno5DY6bwV",
        "row": 23856,
        "state": "",
        "group": "AF016",
        "group_num": [
            "rec0J8CfGND3HVJg2"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1169,
        "id": "recJr8bJ80GLKd745",
        "row": 23824,
        "state": "",
        "group": "",
        "group_num": [
            "rec5MhWn6vE2BxyHy"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 951,
        "id": "recK8jmmoLHN6d7xl",
        "row": 23831,
        "state": "",
        "group": "17711",
        "group_num": [
            "rec84UcRUtT2PsGYr"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 646,
        "id": "recMoCZVx1YalooS3",
        "row": 23811,
        "state": "",
        "group": "",
        "group_num": [
            "rec3svPomGl7Rzr6f"
        ],
        "procedure": "RTC",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1381,
        "id": "recPWwtxWMPQb2urk",
        "row": 23858,
        "state": "",
        "group": "KC143",
        "group_num": [
            "rec8Un7dNo9Sydt5v"
        ],
        "procedure": "Detox",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1060,
        "id": "recuBN0tJpwpWrGba",
        "row": 23857,
        "state": "",
        "group": "",
        "group_num": [
            "recAwWPcCjNrq7erU"
        ],
        "procedure": "PHP",
        "provider": "MERITAIN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 866,
        "id": "recfYqhrgpCzVRh9O",
        "row": 23865,
        "state": "",
        "group": "",
        "group_num": [
            "recbQFfB1zbeQxMbH"
        ],
        "procedure": "RTC",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 866,
        "id": "recfCRO2GE47hzFko",
        "row": 23862,
        "state": "",
        "group": "",
        "group_num": [
            "recnzp4i9yi1GovKg"
        ],
        "procedure": "Detox",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 465,
        "id": "recm9XYsTsFVxc2xt",
        "row": 23868,
        "state": "",
        "group": "",
        "group_num": [
            "recHR546eQYc00YwR"
        ],
        "procedure": "PHP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 88,
        "id": "recQyG98OYR24dUGE",
        "row": 23864,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VMTB9OMLYOPCY"
        ],
        "procedure": "OP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 421,
        "id": "recQcu7FvqRhs0sV5",
        "row": 23870,
        "state": "",
        "group": "",
        "group_num": [
            "recGCjmHWspBNpS8v"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 762,
        "id": "recSafmJoZiTtPNl5",
        "row": 23871,
        "state": "",
        "group": "",
        "group_num": [
            "recg13pQUTlIXFGOT"
        ],
        "procedure": "Detox",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 461,
        "id": "recVhkqNiJ4meSuxU",
        "row": 23875,
        "state": "",
        "group": "",
        "group_num": [
            "recm9vKl6ui4HQEsi"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 627,
        "2023": 465,
        "id": "recYRyNaSD1eaIO0f",
        "row": 23873,
        "state": "",
        "group": "",
        "group_num": [
            "recIlNExeWkDWU1Qr"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 882,
        "id": "recY6FU8HcBykRSI3",
        "row": 23860,
        "state": "",
        "group": "",
        "group_num": [
            "recnzp4i9yi1GovKg"
        ],
        "procedure": "PHP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 647,
        "2024": 476,
        "id": "recbFAYk4usX2yFtX",
        "row": 23867,
        "state": "",
        "group": "",
        "group_num": [
            "recbQFfB1zbeQxMbH"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "2023": 225,
        "2024": 78,
        "id": "rectNHozy9saK3XiC",
        "row": 23872,
        "state": "",
        "group": "008833002",
        "group_num": [
            "recpCwKHIzavCLyrC"
        ],
        "procedure": "OP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 465,
        "2023": 17,
        "id": "recAPLlVZTnLFQ8HL",
        "row": 23874,
        "state": "",
        "group": "",
        "group_num": [
            "recm9vKl6ui4HQEsi"
        ],
        "procedure": "OP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1433,
        "id": "recAlwrH19HUMpTI7",
        "row": 23863,
        "state": "",
        "group": "",
        "group_num": [
            "recjRE4gFe6qiPTOM"
        ],
        "procedure": "Detox",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "id": "recBaZQz2E57b6lI7",
        "row": 23866,
        "state": "",
        "group": "",
        "group_num": [
            "recbQFfB1zbeQxMbH"
        ],
        "procedure": "OP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 465,
        "id": "recGxAOYFsqCjVoEf",
        "row": 23869,
        "state": "",
        "group": "",
        "group_num": [
            "recHR546eQYc00YwR"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 482,
        "id": "recKLZ6bbBhjjlE8z",
        "row": 23861,
        "state": "",
        "group": "",
        "group_num": [
            "recnzp4i9yi1GovKg"
        ],
        "procedure": "IOP",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1238,
        "id": "recND6ZeVuHBJO3Pp",
        "row": 23859,
        "state": "",
        "group": "",
        "group_num": [
            "recnzp4i9yi1GovKg"
        ],
        "procedure": "RTC",
        "provider": "MHN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 565,
        "id": "reclqWs0TclbKrYwG",
        "row": 23877,
        "state": "",
        "group": "",
        "group_num": [
            "recbURnEMucOZCy4E"
        ],
        "procedure": "PHP",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recTUcKvC9shTBDhA",
        "row": 23878,
        "state": "",
        "group": "",
        "group_num": [
            "reccOdxsRclfOUQ7z"
        ],
        "procedure": "RTC",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1925,
        "id": "recoeC2BzkViWITe6",
        "row": 23879,
        "state": "",
        "group": "",
        "group_num": [
            "recSvpG8VUIl5XXSk"
        ],
        "procedure": "RTC",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recrWPqaK05UkNUKD",
        "row": 23880,
        "state": "",
        "group": "",
        "group_num": [
            "recSvpG8VUIl5XXSk"
        ],
        "procedure": "OP",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 399,
        "id": "reczaIjLzjNuT1XHO",
        "row": 23876,
        "state": "",
        "group": "",
        "group_num": [
            "recY4kHNYh5mCOMhL"
        ],
        "procedure": "IOP",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1638,
        "id": "reczfKbD1HuKD5SJa",
        "row": 23882,
        "state": "",
        "group": "",
        "group_num": [
            "recHmSLg1ooW22H25"
        ],
        "procedure": "Detox",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "recMwoWjNksY3n1Mh",
        "row": 23881,
        "state": "",
        "group": "",
        "group_num": [
            "recSvpG8VUIl5XXSk"
        ],
        "procedure": "IOP",
        "provider": "MODA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recfqT0VlZ2yGSkJv",
        "row": 23883,
        "state": "",
        "group": "002320618",
        "group_num": [
            "recBtPb0B3Hd6ZYT5"
        ],
        "procedure": "RTC",
        "provider": "MODERN ASSISTANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recUQtwVZTk8wAfVQ",
        "row": 23884,
        "state": "",
        "group": "002320618",
        "group_num": [
            "recBtPb0B3Hd6ZYT5"
        ],
        "procedure": "Detox",
        "provider": "MODERN ASSISTANCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 120,
        "id": "recPwqG5CWAToLX7L",
        "row": 23885,
        "state": "",
        "group": "",
        "group_num": [
            "rec6LpT8yd5GQfZMD"
        ],
        "procedure": "IOP",
        "provider": "MOLINA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 81,
        "2023": 87,
        "id": "reco1IQqU5vsmElyH",
        "row": 23886,
        "state": "",
        "group": "",
        "group_num": [
            "recyvpVVi29tAcqgn"
        ],
        "procedure": "OP",
        "provider": "MOLINA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "rec1uQcpCuk3LGOrj",
        "row": 23888,
        "state": "",
        "group": "",
        "group_num": [
            "rec56IEu06aK5dBMO"
        ],
        "procedure": "PHP",
        "provider": "MOLINA MEDICAID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 379,
        "id": "recbsjYrC8E2RI3O5",
        "row": 23887,
        "state": "",
        "group": "",
        "group_num": [
            "rec56IEu06aK5dBMO"
        ],
        "procedure": "RTC",
        "provider": "MOLINA MEDICAID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recf1FEA6Gx7kB7MW",
        "row": 23890,
        "state": "",
        "group": "",
        "group_num": [
            "recqk4YOMGtlhBsri"
        ],
        "procedure": "PHP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recV76kbGcySPXNfR",
        "row": 23900,
        "state": "",
        "group": "",
        "group_num": [
            "recG3nx4quaNVJ81a"
        ],
        "procedure": "OP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recWn90V3R2tR4T5P",
        "row": 23902,
        "state": "",
        "group": "",
        "group_num": [
            "recWAsiF8nkSALbjO"
        ],
        "procedure": "RTC",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 278,
        "id": "recX7Bpl6Myur6Q8Z",
        "row": 23897,
        "state": "",
        "group": "",
        "group_num": [
            "recIkPyICMeRFmS9g"
        ],
        "procedure": "IOP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 311,
        "id": "rec0vmIw7IF7W6htr",
        "row": 23895,
        "state": "",
        "group": "",
        "group_num": [
            "recIkPyICMeRFmS9g"
        ],
        "procedure": "PHP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 328,
        "id": "rec5Meu9WjcSc4Zhj",
        "row": 23898,
        "state": "",
        "group": "",
        "group_num": [
            "recg8YwZ8KXChc4oC"
        ],
        "procedure": "PHP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 120,
        "id": "rec7LzWUaChiswixb",
        "row": 23901,
        "state": "",
        "group": "",
        "group_num": [
            "recG3nx4quaNVJ81a"
        ],
        "procedure": "IOP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recd4cOQJtJ5eNlBm",
        "row": 23899,
        "state": "",
        "group": "",
        "group_num": [
            "recAAZmrsbdmZwOrw"
        ],
        "procedure": "RTC",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recoPl0m5rsDGg3me",
        "row": 23892,
        "state": "",
        "group": "",
        "group_num": [
            "recJR1tcNF5LKfAw3"
        ],
        "procedure": "Detox",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 418,
        "id": "recr9E7RrAhHTl2Pg",
        "row": 23896,
        "state": "",
        "group": "",
        "group_num": [
            "recIkPyICMeRFmS9g"
        ],
        "procedure": "OP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "rectzSG4YwcfjrJ54",
        "row": 23894,
        "state": "",
        "group": "",
        "group_num": [
            "recbOMsbA0BCjhQYx"
        ],
        "procedure": "RTC",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2357,
        "id": "recBT0fWnFCa3CYsH",
        "row": 23891,
        "state": "",
        "group": "",
        "group_num": [
            "recJR1tcNF5LKfAw3"
        ],
        "procedure": "RTC",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recCz8kAHvhFqwbbC",
        "row": 23893,
        "state": "",
        "group": "",
        "group_num": [
            "recIsHdVUpFWZfUxn"
        ],
        "procedure": "RTC",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 138,
        "id": "recE2wCItBB9ICLl3",
        "row": 23904,
        "state": "",
        "group": "",
        "group_num": [
            "recLh29VkimBJ3aD5"
        ],
        "procedure": "IOP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recEp8gbu9VhXb8YP",
        "row": 23889,
        "state": "",
        "group": "",
        "group_num": [
            "recqk4YOMGtlhBsri"
        ],
        "procedure": "RTC",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recMxNW26pKMd03Zj",
        "row": 23903,
        "state": "",
        "group": "",
        "group_num": [
            "recWAsiF8nkSALbjO"
        ],
        "procedure": "PHP",
        "provider": "MOTIV HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recatGg4eBsoi8AjX",
        "row": 23906,
        "state": "",
        "group": "",
        "group_num": [
            "recGXhuSYsvUdUgsk"
        ],
        "procedure": "Detox",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "2024": 1950,
        "id": "recojpfuGbud1tAbp",
        "row": 23905,
        "state": "",
        "group": "",
        "group_num": [
            "recGXhuSYsvUdUgsk"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "receBoPk0j0pcAHOn",
        "row": 23925,
        "state": "",
        "group": "",
        "group_num": [
            "recoBtcNDOPY673z7"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recgqnwWjAfYa3Plj",
        "row": 23927,
        "state": "",
        "group": "",
        "group_num": [
            "recwHCy1ICiw7n70E"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1135,
        "id": "recmnQitGvZwJF3t3",
        "row": 23912,
        "state": "",
        "group": "",
        "group_num": [
            "recicKGVByBUeRYDk"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "2023": 1950,
        "id": "recnXhXoFUlZx1PKI",
        "row": 23907,
        "state": "",
        "group": "",
        "group_num": [
            "rec6Y9gz5ycSs6dcx"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recOFx4Uf8UgDlEBc",
        "row": 23916,
        "state": "",
        "group": "413891",
        "group_num": [
            "recGLJmPSwoUHHrqi"
        ],
        "procedure": "OP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1808,
        "2023": 1800,
        "id": "recPbSemxYQ2FHGtz",
        "row": 23921,
        "state": "",
        "group": "",
        "group_num": [
            "recw1YjUJvz5JPFh2"
        ],
        "procedure": "Detox",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recRIB1mXQal6oIYN",
        "row": 23911,
        "state": "",
        "group": "",
        "group_num": [
            "recicKGVByBUeRYDk"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1575,
        "2023": 1575,
        "id": "recXYDwLeVNu6DUVY",
        "row": 23928,
        "state": "",
        "group": "",
        "group_num": [
            "recwHCy1ICiw7n70E"
        ],
        "procedure": "PHP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "rec10FSBDMNUp4fee",
        "row": 23922,
        "state": "",
        "group": "",
        "group_num": [
            "rec6VElZyHroXfzVo"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2100,
        "id": "rec1d44FrzfcDsUIe",
        "row": 23930,
        "state": "",
        "group": "490663",
        "group_num": [
            "rec7MHgRnZaGGtZfV"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "rec2IzTeorcaFLt86",
        "row": 23926,
        "state": "",
        "group": "",
        "group_num": [
            "recj6g4VgIVbUMgl4"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "2023": 1950,
        "id": "reca3gKmiKZRfdbSR",
        "row": 23908,
        "state": "",
        "group": "",
        "group_num": [
            "rec39wlOOpzOHbbKS"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 993,
        "id": "recaeekTifqsmGqIr",
        "row": 23920,
        "state": "",
        "group": "",
        "group_num": [
            "recw1YjUJvz5JPFh2"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1800,
        "id": "recpfwji2iHKVL0gP",
        "row": 23917,
        "state": "",
        "group": "413891",
        "group_num": [
            "recGLJmPSwoUHHrqi"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1135,
        "id": "recuNvptSiQPn0Mfn",
        "row": 23923,
        "state": "",
        "group": "",
        "group_num": [
            "rec6VElZyHroXfzVo"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recvoinhGpm4WJuXL",
        "row": 23910,
        "state": "",
        "group": "",
        "group_num": [
            "rec39wlOOpzOHbbKS"
        ],
        "procedure": "Detox",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2200,
        "id": "recygoa2YCjJIFCD2",
        "row": 23915,
        "state": "",
        "group": "413891",
        "group_num": [
            "recGLJmPSwoUHHrqi"
        ],
        "procedure": "PHP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recAXKhab5p79eyoJ",
        "row": 23913,
        "state": "",
        "group": "",
        "group_num": [
            "recFuZXUU2pc1PuVm"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1135,
        "id": "recC46ufMOCcvTiE4",
        "row": 23909,
        "state": "",
        "group": "",
        "group_num": [
            "rec39wlOOpzOHbbKS"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 1135,
        "id": "recCeuWPRbi9dhytA",
        "row": 23929,
        "state": "",
        "group": "",
        "group_num": [
            "recwHCy1ICiw7n70E"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1575,
        "id": "recDqTbuKzjabkNL4",
        "row": 23919,
        "state": "",
        "group": "",
        "group_num": [
            "recw1YjUJvz5JPFh2"
        ],
        "procedure": "PHP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recGgUn2ameoWJZpB",
        "row": 23931,
        "state": "",
        "group": "",
        "group_num": [
            "recoMjmgJi0TCPZJ8"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1135,
        "id": "recIHoI83OTnTOmO3",
        "row": 23914,
        "state": "",
        "group": "",
        "group_num": [
            "recFuZXUU2pc1PuVm"
        ],
        "procedure": "IOP",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recLQesCNdzAcBYo2",
        "row": 23924,
        "state": "",
        "group": "",
        "group_num": [
            "recwHDboFV1G9kLnP"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "2023": 1950,
        "id": "recMCUxM4H6D2j3f2",
        "row": 23918,
        "state": "",
        "group": "",
        "group_num": [
            "recw1YjUJvz5JPFh2"
        ],
        "procedure": "RTC",
        "provider": "MVP HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "reckKgERBmMUsTLi0",
        "row": 23932,
        "state": "",
        "group": "14321",
        "group_num": [
            "recpgENzsWSWTtWko"
        ],
        "procedure": "Detox",
        "provider": "NEW YORK INDEPENDENT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 495,
        "id": "reclhxnmHsdAuuD4N",
        "row": 23933,
        "state": "",
        "group": "",
        "group_num": [
            "rectzXJJtvSipRAkX"
        ],
        "procedure": "PHP",
        "provider": "NIPPON LIFE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 350,
        "id": "recZPegQsbBZMxear",
        "row": 23934,
        "state": "",
        "group": "N68700",
        "group_num": [
            "rec1TUrbePJ4KgsJi"
        ],
        "procedure": "IOP",
        "provider": "NIPPON LIFE BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "recU3iYVHBj8SbIC8",
        "row": 23936,
        "state": "",
        "group": "N68700",
        "group_num": [
            "rec1TUrbePJ4KgsJi"
        ],
        "procedure": "Detox",
        "provider": "NIPPON LIFE INSURANCE COMPANY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1926,
        "id": "recss9RnfJswctlLP",
        "row": 23935,
        "state": "",
        "group": "N68700",
        "group_num": [
            "rec1TUrbePJ4KgsJi"
        ],
        "procedure": "PHP",
        "provider": "NIPPON LIFE INSURANCE COMPANY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2850,
        "id": "recyURh8pZDHvwMB3",
        "row": 23937,
        "state": "",
        "group": "N70700",
        "group_num": [
            "recN5BAWGHWkm5EFf"
        ],
        "procedure": "RTC",
        "provider": "NIPPON LIFE INSURANCE COMPANY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recZagUDQzzVCMuzv",
        "row": 19998,
        "state": "",
        "group": "",
        "group_num": [
            "rec5RvO8geVpNveUi"
        ],
        "procedure": "PHP",
        "provider": "OPERATING ENGINEERS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recr1HYzyM5GkmkTO",
        "row": 20000,
        "state": "",
        "group": "",
        "group_num": [
            "rec5RvO8geVpNveUi"
        ],
        "procedure": "OP",
        "provider": "OPERATING ENGINEERS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "reczm9cCacqmlG8vZ",
        "row": 19999,
        "state": "",
        "group": "",
        "group_num": [
            "rec5RvO8geVpNveUi"
        ],
        "procedure": "IOP",
        "provider": "OPERATING ENGINEERS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2321,
        "id": "reciuHrJR6AKi8ydf",
        "row": 23938,
        "state": "",
        "group": "72713",
        "group_num": [
            "recHQh5j25km5l0zz"
        ],
        "procedure": "RTC",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 104,
        "id": "reclQgFkKox1QkdiL",
        "row": 23940,
        "state": "",
        "group": "72732",
        "group_num": [
            "rec79sNCZyYgqRdQD"
        ],
        "procedure": "PHP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recnCTlPHdQfXWsin",
        "row": 23939,
        "state": "",
        "group": "72713",
        "group_num": [
            "recHQh5j25km5l0zz"
        ],
        "procedure": "PHP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1365,
        "id": "recQRCXfBlkVLsg3M",
        "row": 23942,
        "state": "",
        "group": "",
        "group_num": [
            "recgrpooXjDoKCyho"
        ],
        "procedure": "IOP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 464,
        "2023": 439,
        "id": "rec815tYD00qWgzNV",
        "row": 23941,
        "state": "",
        "group": "",
        "group_num": [
            "recgrpooXjDoKCyho"
        ],
        "procedure": "OP",
        "provider": "OPTIMA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 41,
        "id": "recQTGVqdlZLkUcrV",
        "row": 23945,
        "state": "",
        "group": "",
        "group_num": [
            "recDZUcpiS7qmhc1Z"
        ],
        "procedure": "OP",
        "provider": "OPTUM HEALTH BEHAVIORAL SOLUTIONS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 450,
        "id": "recQ696yRe8rgtk8i",
        "row": 23943,
        "state": "",
        "group": "",
        "group_num": [
            "rec3mtXYPfsPq4ruv"
        ],
        "procedure": "OP",
        "provider": "OPTUM HEALTH BEHAVIORAL SOLUTIONS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 360,
        "id": "rec3Tlnmk3fcu9CWu",
        "row": 23944,
        "state": "",
        "group": "",
        "group_num": [
            "recHNnhnM6ZqZCEJg"
        ],
        "procedure": "OP",
        "provider": "OPTUM HEALTH BEHAVIORAL SOLUTIONS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 128,
        "2024": 128,
        "id": "recsl2c7QCDwrbftZ",
        "row": 23946,
        "state": "",
        "group": "744644",
        "group_num": [
            "rec9YUVxWvxVZm3hN"
        ],
        "procedure": "OP",
        "provider": "OPTUM HEALTH BEHAVIORAL SOLUTIONS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 218,
        "id": "recvcupdse0D0HQhw",
        "row": 23947,
        "state": "",
        "group": "",
        "group_num": [
            "recZrrfoLh0ElmEsq"
        ],
        "procedure": "OP",
        "provider": "OPTUM HEALTH BEHAVIORAL SOLUTIONS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 997,
        "id": "recgzvCObnzaVLsgU",
        "row": 23956,
        "state": "",
        "group": "",
        "group_num": [
            "recJsCMPz7m8QsF8M"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 283,
        "id": "recQJcQZIFTB0UDLo",
        "row": 23952,
        "state": "",
        "group": "",
        "group_num": [
            "recKWzGNJWZdl6TMS"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 614,
        "id": "recQPwSpxZ6RpUdF4",
        "row": 23950,
        "state": "",
        "group": "",
        "group_num": [
            "recVwZo6sQXHm8SSU"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 599,
        "id": "recZYZJABY8nPnOpN",
        "row": 23951,
        "state": "",
        "group": "",
        "group_num": [
            "recKWzGNJWZdl6TMS"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "rec2WIor31rfDhncZ",
        "row": 23955,
        "state": "",
        "group": "",
        "group_num": [
            "recJsCMPz7m8QsF8M"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "rec5lq8qjavIrQrvY",
        "row": 23948,
        "state": "",
        "group": "",
        "group_num": [
            "recpGLK0zn74B9g2l"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 143,
        "id": "recvIg0yg4u4N2YNJ",
        "row": 23953,
        "state": "",
        "group": "",
        "group_num": [
            "recKWzGNJWZdl6TMS"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "recJbDyvqDejPxK03",
        "row": 23949,
        "state": "",
        "group": "",
        "group_num": [
            "recl2ELzZVR5jQLfj"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 997,
        "id": "recNGwvdHRwH6LROl",
        "row": 23954,
        "state": "",
        "group": "",
        "group_num": [
            "recKWzGNJWZdl6TMS"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 650,
        "id": "recf9bkDUTNt6LFvi",
        "row": 23974,
        "state": "",
        "group": "",
        "group_num": [
            "recjeXZ6qerZJ2MBT"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 470,
        "2023": 470,
        "id": "recfc87nidNY5Uou5",
        "row": 23981,
        "state": "",
        "group": "1285892",
        "group_num": [
            "rec6tYJ0X2TTgMgVO"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "recfhmONauAnHBxFy",
        "row": 23978,
        "state": "",
        "group": "",
        "group_num": [
            "reczijrimGJakbCYY"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 914,
        "id": "rechXidxgmGy5BJbt",
        "row": 23964,
        "state": "",
        "group": "",
        "group_num": [
            "rec7e7YtmIj2PnJdZ"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2374,
        "id": "recigWXCVh0rbIAMy",
        "row": 23968,
        "state": "",
        "group": "",
        "group_num": [
            "recccVTbhEahXORzv"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 774,
        "id": "recjBkMMgJhH8vxbs",
        "row": 23995,
        "state": "",
        "group": "1292230",
        "group_num": [
            "recJUN4SUV3LmiT6t"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 228,
        "id": "recjhqwtXRssp14WS",
        "row": 23982,
        "state": "",
        "group": "1285892",
        "group_num": [
            "rec6tYJ0X2TTgMgVO"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "id": "reclVpofYcKwJYuxQ",
        "row": 24003,
        "state": "",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recmrZxQZA29lAJUr",
        "row": 23957,
        "state": "",
        "group": "",
        "group_num": [
            "recYrKHu3IttE0Duj"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "id": "recn5EwW9lFIQipob",
        "row": 23959,
        "state": "",
        "group": "1270239",
        "group_num": [
            "recE1HYSF0Wtcxuba"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 279,
        "id": "recOTXlaUo8ASfGKe",
        "row": 23991,
        "state": "",
        "group": "",
        "group_num": [
            "recUvvfcEFzoUCZhC"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4314,
        "id": "recPrwh69wgOoOsS1",
        "row": 23960,
        "state": "",
        "group": "1270366",
        "group_num": [
            "recVmZcnClMa1xeEb"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 819,
        "id": "recRbT4Y7FLS0Ihgv",
        "row": 23967,
        "state": "",
        "group": "",
        "group_num": [
            "recglFw9Zv7vICzeH"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 987,
        "id": "recTYoX16IJiR4wAd",
        "row": 23996,
        "state": "",
        "group": "1292230",
        "group_num": [
            "recJUN4SUV3LmiT6t"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recUZPYvnnNdb02iX",
        "row": 23958,
        "state": "",
        "group": "",
        "group_num": [
            "reco3FVN3fjB5EuIQ"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1614,
        "id": "recWUjnGfeDSVA0bV",
        "row": 23965,
        "state": "",
        "group": "",
        "group_num": [
            "rec7e7YtmIj2PnJdZ"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2090,
        "2024": 3420,
        "id": "recZTxbIctuULN6Sh",
        "row": 23983,
        "state": "",
        "group": "",
        "group_num": [
            "recJUaRADvnuEvtLm"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "recZvKGs5nwhSiqc1",
        "row": 23993,
        "state": "",
        "group": "",
        "group_num": [
            "rec4AtxnHRwcPJBxJ"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 819,
        "id": "rec0AvhhKPQboTngo",
        "row": 23963,
        "state": "",
        "group": "",
        "group_num": [
            "recb8jYHQ7MWRkQpf"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 279,
        "id": "rec2PgBMXjt6qeZtV",
        "row": 23994,
        "state": "",
        "group": "",
        "group_num": [
            "rec4AtxnHRwcPJBxJ"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "id": "rec3Ar2etWw3HZnhb",
        "row": 23986,
        "state": "",
        "group": "",
        "group_num": [
            "recEPpho3z4u1EyZQ"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1056,
        "2024": 1056,
        "id": "rec3IxKRnrCnVWCbt",
        "row": 24004,
        "state": "",
        "group": "",
        "group_num": [
            "recbjOducBjkJxpH5"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 997,
        "id": "rec3OuumVIqS0umTQ",
        "row": 23979,
        "state": "",
        "group": "",
        "group_num": [
            "recjAZ5Kce7VMp0L5"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec4UaouGQYXZgjfb",
        "row": 23988,
        "state": "",
        "group": "",
        "group_num": [
            "recD0zJUNFseFROgm"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rec54y7TTsSXuk4OR",
        "row": 23976,
        "state": "",
        "group": "",
        "group_num": [
            "recAmsLekVFF3aBOX"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 439,
        "id": "rec6defGqp0NX1IGq",
        "row": 24005,
        "state": "",
        "group": "",
        "group_num": [
            "recbjOducBjkJxpH5"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 391,
        "id": "rec7FdIYdWDpXL46B",
        "row": 23985,
        "state": "",
        "group": "",
        "group_num": [
            "recEPpho3z4u1EyZQ"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 644,
        "id": "rec7HSYXHrgAxwtAC",
        "row": 23997,
        "state": "",
        "group": "1292712",
        "group_num": [
            "recEs3gEwC9hLvAFu"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1354,
        "id": "rec8Bxw26b0gsrSD8",
        "row": 23990,
        "state": "",
        "group": "",
        "group_num": [
            "recUvvfcEFzoUCZhC"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1396,
        "id": "rec8pUx8L4tWAgQja",
        "row": 23987,
        "state": "",
        "group": "",
        "group_num": [
            "recEPpho3z4u1EyZQ"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 103,
        "id": "recbHZe0EaF2QkbpF",
        "row": 23972,
        "state": "",
        "group": "",
        "group_num": [
            "recoa23Jv7QibyCTA"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 524,
        "id": "reccKnhiAr7dXAYy2",
        "row": 23966,
        "state": "",
        "group": "",
        "group_num": [
            "recglFw9Zv7vICzeH"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1056,
        "id": "recdwVzEkSrFveX3E",
        "row": 23999,
        "state": "",
        "group": "1293337",
        "group_num": [
            "recJ0BECYKD2Wi3dx"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 137,
        "id": "recugmS0S6paDFtfa",
        "row": 23992,
        "state": "",
        "group": "",
        "group_num": [
            "recUvvfcEFzoUCZhC"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recvep8VT7cfocLqB",
        "row": 23970,
        "state": "",
        "group": "",
        "group_num": [
            "rec5oXQHxetR45o6A"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 807,
        "id": "reczvuQUViZSUkIP7",
        "row": 23984,
        "state": "",
        "group": "",
        "group_num": [
            "recEPpho3z4u1EyZQ"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recAVwXrcLjUXJa3E",
        "row": 23969,
        "state": "",
        "group": "",
        "group_num": [
            "recccVTbhEahXORzv"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 279,
        "id": "recBDGB6N5NKU9rlU",
        "row": 23971,
        "state": "",
        "group": "",
        "group_num": [
            "rec5oXQHxetR45o6A"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "recC8PgAP6Opf32Lq",
        "row": 23975,
        "state": "",
        "group": "",
        "group_num": [
            "recsWek409iZ69taC"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 541,
        "id": "recDXsPozMbBmQhUe",
        "row": 23973,
        "state": "",
        "group": "",
        "group_num": [
            "recoa23Jv7QibyCTA"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 557,
        "id": "recDF4sa1SGZfRh5V",
        "row": 23962,
        "state": "",
        "group": "",
        "group_num": [
            "recb8jYHQ7MWRkQpf"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recF02OiaxAOBeJkI",
        "row": 23977,
        "state": "",
        "group": "",
        "group_num": [
            "recAmsLekVFF3aBOX"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 997,
        "2023": 997,
        "id": "recG5Ljz0q8kiq3Zx",
        "row": 23980,
        "state": "",
        "group": "",
        "group_num": [
            "reckzHyymdTOk9bjz"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4915,
        "id": "recHeZY2DsTNg848b",
        "row": 23961,
        "state": "",
        "group": "1270366",
        "group_num": [
            "recVmZcnClMa1xeEb"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 573,
        "id": "recIxqN4E8Bt514UJ",
        "row": 24002,
        "state": "",
        "group": "1315746",
        "group_num": [
            "recBM7xBGVdn5edbD"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 807,
        "id": "recJ7zxqfKjK5bMEi",
        "row": 24000,
        "state": "",
        "group": "",
        "group_num": [
            "rec8S1FopCFxtquzN"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1025,
        "id": "recLVqxeD3GJqYc8z",
        "row": 23989,
        "state": "",
        "group": "",
        "group_num": [
            "recD0zJUNFseFROgm"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 658,
        "id": "recL8DUDGlGrsmbU4",
        "row": 23998,
        "state": "",
        "group": "1293337",
        "group_num": [
            "recJ0BECYKD2Wi3dx"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 807,
        "id": "recMADECFi0qqiXqm",
        "row": 24001,
        "state": "",
        "group": "",
        "group_num": [
            "rechL5fa8LcugrW4g"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1942,
        "id": "recMm2go9DdR5lIvL",
        "row": 24006,
        "state": "",
        "group": "",
        "group_num": [
            "rectR0Aiu43ITPNGM"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "id": "rece2hK4vku9k6k18",
        "row": 24037,
        "state": "",
        "group": "",
        "group_num": [
            "recdYWw46CPZljo7g"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 302,
        "id": "reci0pYvXAIYajXn3",
        "row": 24017,
        "state": "",
        "group": "",
        "group_num": [
            "recnYSJaSVyWrhuSa"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 393,
        "id": "recjIRMpzzHiy72OI",
        "row": 24014,
        "state": "",
        "group": "",
        "group_num": [
            "reclqXgfavGHWiGsQ"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "id": "reckWP4dbDaVeJP5r",
        "row": 24032,
        "state": "",
        "group": "1330921",
        "group_num": [
            "recZlZqLMk5hGPEIC"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 40,
        "id": "reck5pcVslaFAOGNx",
        "row": 24022,
        "state": "",
        "group": "",
        "group_num": [
            "rec4YaRyABEq9ADZ7"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 669,
        "id": "reck8EF2XviKnunrv",
        "row": 24049,
        "state": "",
        "group": "1375336",
        "group_num": [
            "rec4TOsSswskcNN18"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 650,
        "id": "reclK7hAnqfyL9g0d",
        "row": 24012,
        "state": "",
        "group": "",
        "group_num": [
            "rec84ywpjGpXJeX4n"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1451,
        "id": "recmAPT1NaD8B06p6",
        "row": 24011,
        "state": "",
        "group": "",
        "group_num": [
            "recQAX2apVztK35R1"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recnwFWcc6R4h1gXv",
        "row": 24023,
        "state": "",
        "group": "",
        "group_num": [
            "rec4YaRyABEq9ADZ7"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 405,
        "id": "recO3ANODfAJ0OMI0",
        "row": 24056,
        "state": "",
        "group": "",
        "group_num": [
            "recXia5kYkvoX9lib"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "id": "recPDVMhSCmJYYbya",
        "row": 24031,
        "state": "",
        "group": "1330921",
        "group_num": [
            "recZlZqLMk5hGPEIC"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 509,
        "id": "recPjn6adkLw0lr48",
        "row": 24015,
        "state": "",
        "group": "",
        "group_num": [
            "recnYSJaSVyWrhuSa"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 464,
        "id": "recPksjReZeMsqgnC",
        "row": 24009,
        "state": "",
        "group": "",
        "group_num": [
            "rectR0Aiu43ITPNGM"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 827,
        "id": "recQv6RGrZENYiTV9",
        "row": 24013,
        "state": "",
        "group": "",
        "group_num": [
            "reclqXgfavGHWiGsQ"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 997,
        "id": "recRAxS1mahUhYcPL",
        "row": 24029,
        "state": "",
        "group": "",
        "group_num": [
            "recerdsWyfsXqWUpW"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1589,
        "id": "recRikmg6BGSxMbq8",
        "row": 24028,
        "state": "",
        "group": "",
        "group_num": [
            "recerdsWyfsXqWUpW"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 214,
        "2024": 214,
        "id": "recVA5duynHJdO24x",
        "row": 24047,
        "state": "",
        "group": "",
        "group_num": [
            "recfIaSECd2E9AXH7"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 88,
        "id": "recW2fecyGR9mCbM0",
        "row": 24008,
        "state": "",
        "group": "",
        "group_num": [
            "rectR0Aiu43ITPNGM"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 120,
        "id": "recXhMsNPzAeIfP3g",
        "row": 24016,
        "state": "",
        "group": "",
        "group_num": [
            "recnYSJaSVyWrhuSa"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 191,
        "id": "recXke86DjvTRYE6G",
        "row": 24048,
        "state": "",
        "group": "",
        "group_num": [
            "recux8aFbiKJESJJp"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recYfrBXOzdBiKqbW",
        "row": 24035,
        "state": "",
        "group": "",
        "group_num": [
            "rec8VSKNuACn9RdV0"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 798,
        "id": "rec4MejcBG9JxRCiL",
        "row": 24021,
        "state": "",
        "group": "",
        "group_num": [
            "recWBEANF7W8oI0Zc"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 346,
        "id": "rec6a00AZfjyEl5Ja",
        "row": 24018,
        "state": "",
        "group": "",
        "group_num": [
            "recbRNEEant9amHHr"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 405,
        "2023": 405,
        "id": "rec76SwwZCzWekYl9",
        "row": 24020,
        "state": "",
        "group": "",
        "group_num": [
            "recWBEANF7W8oI0Zc"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 200,
        "id": "rec8x2D26r9QUnSRs",
        "row": 24044,
        "state": "",
        "group": "",
        "group_num": [
            "recbiGYjugMAirkw7"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 804,
        "id": "rec9KfC7gSLw4ofP2",
        "row": 24038,
        "state": "",
        "group": "",
        "group_num": [
            "recIfNmIABEpknR2O"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 41,
        "id": "rec9aDVduwkTfvpVJ",
        "row": 24025,
        "state": "",
        "group": "",
        "group_num": [
            "recfVaO25dPcwtK6w"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 393,
        "id": "recaI2f4YMgSnRzok",
        "row": 24046,
        "state": "",
        "group": "",
        "group_num": [
            "recfIaSECd2E9AXH7"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 807,
        "id": "reccMTm7wwmyY7Ame",
        "row": 24034,
        "state": "",
        "group": "",
        "group_num": [
            "recKs0Rl9VxKXauxn"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3600,
        "id": "recdPgL9VMtuHQ7qJ",
        "row": 24041,
        "state": "",
        "group": "1358470",
        "group_num": [
            "recjozazoPbW06MsW"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "recoYYsxrdOrHf9oi",
        "row": 24040,
        "state": "",
        "group": "",
        "group_num": [
            "recfyAQnNazwhO69Y"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recpLwRcnIHCwo3wA",
        "row": 24033,
        "state": "",
        "group": "",
        "group_num": [
            "reclSS1mSZEphYH1B"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 340,
        "2023": 340,
        "id": "recsOZETGuIwWpscs",
        "row": 24026,
        "state": "",
        "group": "",
        "group_num": [
            "recwB7mMGFPJag0l4"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 838,
        "id": "recvgWVZHPx1Cvgfa",
        "row": 24007,
        "state": "",
        "group": "",
        "group_num": [
            "rectR0Aiu43ITPNGM"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2123,
        "id": "recw2jDdqmvzcSvw2",
        "row": 24051,
        "state": "",
        "group": "1405029",
        "group_num": [
            "rec3FWozqgF75MmL9"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 410,
        "id": "recw3Uax9CSJDdNj4",
        "row": 24052,
        "state": "",
        "group": "1405029",
        "group_num": [
            "rec3FWozqgF75MmL9"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 807,
        "id": "recxmGQow8ddWK8IQ",
        "row": 24024,
        "state": "",
        "group": "",
        "group_num": [
            "recjv75Sdpbvk3sgE"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2304,
        "id": "recyfViVwavXWyQww",
        "row": 24054,
        "state": "",
        "group": "",
        "group_num": [
            "recXia5kYkvoX9lib"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1194,
        "id": "recAJExN5wXCfDJLS",
        "row": 24045,
        "state": "",
        "group": "",
        "group_num": [
            "recfIaSECd2E9AXH7"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1168,
        "id": "recBpJmS8vqt4gpYh",
        "row": 24036,
        "state": "",
        "group": "1340984",
        "group_num": [
            "recdmRWcDIBweB0KF"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "id": "recDOiDg18wu7oWEc",
        "row": 24027,
        "state": "",
        "group": "",
        "group_num": [
            "recwB7mMGFPJag0l4"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 776,
        "id": "recD3zSN6K2gceerq",
        "row": 24053,
        "state": "",
        "group": "1405029",
        "group_num": [
            "rec3FWozqgF75MmL9"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 252,
        "id": "recDrDjwHHCA4jzh2",
        "row": 24050,
        "state": "",
        "group": "",
        "group_num": [
            "recUmKbHPTEbiuMD8"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 391,
        "id": "recF5FfbiQhg8DvvY",
        "row": 24039,
        "state": "",
        "group": "",
        "group_num": [
            "recIfNmIABEpknR2O"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 734,
        "id": "recGKHC0B4Lixa67J",
        "row": 24043,
        "state": "",
        "group": "",
        "group_num": [
            "recGlQLNXjMmOM8dx"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3596,
        "id": "recG9PccsL7XBlBcC",
        "row": 24030,
        "state": "",
        "group": "1330921",
        "group_num": [
            "recZlZqLMk5hGPEIC"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1332,
        "id": "recKclc5rsioKQGQb",
        "row": 24019,
        "state": "",
        "group": "",
        "group_num": [
            "recWBEANF7W8oI0Zc"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recLtV9JL5RbbK75N",
        "row": 24042,
        "state": "",
        "group": "1358470",
        "group_num": [
            "recjozazoPbW06MsW"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 827,
        "id": "recLmJRYPeehW9gII",
        "row": 24010,
        "state": "",
        "group": "",
        "group_num": [
            "recQAX2apVztK35R1"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 513,
        "id": "recMSKWRZJNEVCDj1",
        "row": 24055,
        "state": "",
        "group": "",
        "group_num": [
            "recXia5kYkvoX9lib"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 734,
        "id": "recertEIEUREeLPQU",
        "row": 24103,
        "state": "",
        "group": "",
        "group_num": [
            "recq5iyR5xCO0g3BT"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 143,
        "2023": 394,
        "id": "rechXRak6dojfzo1H",
        "row": 24067,
        "state": "",
        "group": "",
        "group_num": [
            "recN7gN7Eg1ZWjXCs"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "reciSQ3gIBfr1rpbC",
        "row": 24093,
        "state": "",
        "group": "",
        "group_num": [
            "recDPTh9R6t1Vf1xZ"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 223,
        "id": "recjUBVlAkMP8WHUC",
        "row": 24074,
        "state": "",
        "group": "1418050",
        "group_num": [
            "recLinUfbNZaA1Xei"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "id": "reckbNVXodBxYzq8B",
        "row": 24105,
        "state": "",
        "group": "",
        "group_num": [
            "recFgTOSKslDC44fz"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 650,
        "id": "reclH65Ftv69xKBFq",
        "row": 24083,
        "state": "",
        "group": "",
        "group_num": [
            "rec3okHAC0LR6K0Bm"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "reclnOUqevVHlID2l",
        "row": 24065,
        "state": "",
        "group": "",
        "group_num": [
            "recN7gN7Eg1ZWjXCs"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 122,
        "id": "recmIlh99NOSw0vWJ",
        "row": 24082,
        "state": "",
        "group": "1438082",
        "group_num": [
            "rec2aiCrOXNT0vC0n"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1343,
        "2024": 1562,
        "id": "recmk9jl2xDHYnStU",
        "row": 24099,
        "state": "",
        "group": "",
        "group_num": [
            "reciakwq4PA5AyDCk"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "2023": 634,
        "id": "recP6OPSOFbgMEQHh",
        "row": 24069,
        "state": "",
        "group": "",
        "group_num": [
            "recMnrupLXTImWee9"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 524,
        "id": "recQX8aCcP6uJLw9e",
        "row": 24071,
        "state": "",
        "group": "",
        "group_num": [
            "recbm3H5IUbiVL10w"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3813,
        "id": "recR3Rx3lPDgBQg46",
        "row": 24100,
        "state": "",
        "group": "1521602",
        "group_num": [
            "rec3tamFdGwrmJddF"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5000,
        "id": "recVsaTvxNV5pA1Mc",
        "row": 24081,
        "state": "",
        "group": "",
        "group_num": [
            "rec3X4d2xrla8BlQa"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 214,
        "id": "recXvGAXfiY1VcC6L",
        "row": 24095,
        "state": "",
        "group": "",
        "group_num": [
            "reclPjvrN0OHl4ozi"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 42,
        "id": "recZzlHUKnMimxOnT",
        "row": 24063,
        "state": "",
        "group": "",
        "group_num": [
            "rectkLhK6dDfdMHw6"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1532,
        "id": "recZAixlZUAMOGFd5",
        "row": 24102,
        "state": "",
        "group": "",
        "group_num": [
            "recR5bmF7lXFuwN4n"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "id": "recZ70qdH10wXJpXd",
        "row": 24059,
        "state": "",
        "group": "1409905",
        "group_num": [
            "recTauXQmDPUFtYod"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 525,
        "2023": 289,
        "id": "rec0rlCZ0zYhLdwoS",
        "row": 24057,
        "state": "",
        "group": "",
        "group_num": [
            "recXia5kYkvoX9lib"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 29,
        "id": "rec2BQ1l9I7cpQvIj",
        "row": 24061,
        "state": "",
        "group": "",
        "group_num": [
            "recgsjMeBNcjHons5"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "rec3KtIP8XZ06E8B1",
        "row": 24064,
        "state": "",
        "group": "",
        "group_num": [
            "rectkLhK6dDfdMHw6"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1318,
        "id": "rec3jenapdV8lcM3a",
        "row": 24097,
        "state": "",
        "group": "",
        "group_num": [
            "reciakwq4PA5AyDCk"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 650,
        "id": "rec5WzVkat7KLmkTk",
        "row": 24080,
        "state": "",
        "group": "",
        "group_num": [
            "rec3X4d2xrla8BlQa"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 331,
        "id": "rec55xl89dYDukfXD",
        "row": 24087,
        "state": "",
        "group": "",
        "group_num": [
            "recffjLAdZ0OOrrwS"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 819,
        "id": "rec62oNj8iRyQiIuC",
        "row": 24072,
        "state": "",
        "group": "",
        "group_num": [
            "recbm3H5IUbiVL10w"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1147,
        "id": "rec638OuGuILjmtFM",
        "row": 24096,
        "state": "",
        "group": "",
        "group_num": [
            "reclPjvrN0OHl4ozi"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 562,
        "2024": 575,
        "id": "rec8RPRJEyYe6V1b8",
        "row": 24098,
        "state": "",
        "group": "",
        "group_num": [
            "reciakwq4PA5AyDCk"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 813,
        "id": "rec9kbogf3AgEqeg6",
        "row": 24085,
        "state": "",
        "group": "",
        "group_num": [
            "recGTGLGfopWQIJC1"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 997,
        "id": "rec9mIJIbSFLQoDpl",
        "row": 24090,
        "state": "",
        "group": "",
        "group_num": [
            "recLudAGsUa73RmHe"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 279,
        "id": "recaH1r66zWMfa7fw",
        "row": 24066,
        "state": "",
        "group": "",
        "group_num": [
            "recN7gN7Eg1ZWjXCs"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 807,
        "id": "reca4lzwjjuhym9Z1",
        "row": 24077,
        "state": "",
        "group": "",
        "group_num": [
            "recxc1e5j8iw7OWhr"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "reca5Wr8CvuqLmKnq",
        "row": 24070,
        "state": "",
        "group": "",
        "group_num": [
            "receIe3pvF0wXGyra"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "2023": 91,
        "id": "recbz3RLJ9gIwPBs9",
        "row": 24073,
        "state": "",
        "group": "1418050",
        "group_num": [
            "recLinUfbNZaA1Xei"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "reccY1IM3mLV6fBrK",
        "row": 24079,
        "state": "",
        "group": "",
        "group_num": [
            "recmdVIzgJyhTfNu1"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2700,
        "id": "recqt5hdj1UElIWqt",
        "row": 24101,
        "state": "",
        "group": "1521602",
        "group_num": [
            "rec3tamFdGwrmJddF"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "recqafo8Qh2w1WCEe",
        "row": 24075,
        "state": "",
        "group": "1419340",
        "group_num": [
            "recUcgkXFTqcwFyci"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 21,
        "id": "recqgy8SHapDnCfot",
        "row": 24086,
        "state": "",
        "group": "",
        "group_num": [
            "recffjLAdZ0OOrrwS"
        ],
        "procedure": "OP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 734,
        "id": "recrPwuhJlyy9XLqT",
        "row": 24094,
        "state": "",
        "group": "",
        "group_num": [
            "reclPjvrN0OHl4ozi"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 997,
        "id": "recrhxlgR24qmf2zY",
        "row": 24068,
        "state": "",
        "group": "",
        "group_num": [
            "rec9xcpW5h6fAx6JA"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "rect6lxnGu3jmUNKX",
        "row": 24084,
        "state": "",
        "group": "",
        "group_num": [
            "recHQwMJ2WhfdJY70"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 884,
        "id": "recuTbkCvNayjyKv3",
        "row": 24076,
        "state": "",
        "group": "1419340",
        "group_num": [
            "recUcgkXFTqcwFyci"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 237,
        "id": "reczaGSFkv1HDVA4P",
        "row": 24062,
        "state": "",
        "group": "",
        "group_num": [
            "recgsjMeBNcjHons5"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 391,
        "id": "reczkSCwDvwMKcP8O",
        "row": 24078,
        "state": "",
        "group": "",
        "group_num": [
            "recxc1e5j8iw7OWhr"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 517,
        "id": "recBzz9CtCZb98kM7",
        "row": 24058,
        "state": "",
        "group": "1409905",
        "group_num": [
            "recTauXQmDPUFtYod"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "recBmX5Y5ki6cNE3q",
        "row": 24092,
        "state": "",
        "group": "",
        "group_num": [
            "recXoGaaT3gSM3rE3"
        ],
        "procedure": "Detox",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 279,
        "id": "recCmQHbfaNusmZx8",
        "row": 24089,
        "state": "",
        "group": "",
        "group_num": [
            "recLudAGsUa73RmHe"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "id": "recHVeKFvZlBPiELT",
        "row": 24060,
        "state": "",
        "group": "",
        "group_num": [
            "recgsjMeBNcjHons5"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recJpzK6yqayqA27M",
        "row": 24088,
        "state": "",
        "group": "",
        "group_num": [
            "recLudAGsUa73RmHe"
        ],
        "procedure": "RTC",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 256,
        "id": "recMRrlkI2UM3v6gn",
        "row": 24091,
        "state": "",
        "group": "",
        "group_num": [
            "recXoGaaT3gSM3rE3"
        ],
        "procedure": "PHP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 160,
        "id": "recNS5yQaS1a4l8cZ",
        "row": 24104,
        "state": "",
        "group": "",
        "group_num": [
            "recafg5DWWmPd9wjK"
        ],
        "procedure": "IOP",
        "provider": "OXFORD"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 357,
        "id": "recr2UVB2DZfunFzr",
        "row": 20001,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "RTC",
        "provider": "OXFORD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recXr2YryEc4tTsIS",
        "row": 24106,
        "state": "",
        "group": "",
        "group_num": [
            "recmv70DlNtJZQinE"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "id": "recgLRgG0IbSTfJXk",
        "row": 24111,
        "state": "",
        "group": "",
        "group_num": [
            "recZ6swVpnbRXuTHx"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "rechfyWeDGYCZCPks",
        "row": 24122,
        "state": "",
        "group": "",
        "group_num": [
            "recSQV1NKGKR9Nsqz"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "reciFWqB3bXWJKxng",
        "row": 24118,
        "state": "",
        "group": "",
        "group_num": [
            "rec6Q3eJIsqemlRl6"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 52,
        "id": "recmoMvzSKaYMwVoA",
        "row": 24123,
        "state": "",
        "group": "",
        "group_num": [
            "recSQV1NKGKR9Nsqz"
        ],
        "procedure": "OP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recRJEJvyZmAbpWAI",
        "row": 24121,
        "state": "",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "Detox",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 114,
        "id": "recReISJP2RkZ9fDN",
        "row": 24108,
        "state": "",
        "group": "",
        "group_num": [
            "rec8C4Tump0j3EOy2"
        ],
        "procedure": "OP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recXPSvUcaxw9xeDj",
        "row": 24120,
        "state": "",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "rec0NLntGVtqvlNfQ",
        "row": 24107,
        "state": "",
        "group": "",
        "group_num": [
            "recmv70DlNtJZQinE"
        ],
        "procedure": "Detox",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "id": "rec3E3Nvs9tm0fRWo",
        "row": 24124,
        "state": "",
        "group": "",
        "group_num": [
            "recSQV1NKGKR9Nsqz"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "rec6etvJxzHOqii1k",
        "row": 24117,
        "state": "",
        "group": "",
        "group_num": [
            "rec5A1fEjFUy6MaSo"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 75,
        "id": "rec9VeKZrIRS4hk0o",
        "row": 24109,
        "state": "",
        "group": "",
        "group_num": [
            "recDLumDZg7QysA0D"
        ],
        "procedure": "OP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 172,
        "id": "recb4bEuE4SLTuBqp",
        "row": 24110,
        "state": "",
        "group": "",
        "group_num": [
            "recDLumDZg7QysA0D"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recrte11hvdEf9bOm",
        "row": 24112,
        "state": "",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 183,
        "id": "rectACeOVPHNgsOjL",
        "row": 24116,
        "state": "",
        "group": "",
        "group_num": [
            "recrhUr0j1hZ2aMIa"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recy7L9EXiMI4VUwK",
        "row": 24115,
        "state": "",
        "group": "G0040817",
        "group_num": [
            "recgdYzwtl1mjUjDn"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "id": "recGbFI2HmeRIdRTR",
        "row": 24114,
        "state": "",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "IOP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 777,
        "id": "recJLnbTQ4Q412m83",
        "row": 24119,
        "state": "",
        "group": "N0010005",
        "group_num": [
            "rec1pruxYnl9zvhKa"
        ],
        "procedure": "RTC",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recNlHdI0sxUFc4gu",
        "row": 24113,
        "state": "",
        "group": "G0037418",
        "group_num": [
            "rec3C9C8dlo0tvZol"
        ],
        "procedure": "PHP",
        "provider": "PACIFIC SOURCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 850,
        "id": "recb8lJkDwzBd0a3C",
        "row": 24125,
        "state": "",
        "group": "",
        "group_num": [
            "recfbGig5BX7fTKsB"
        ],
        "procedure": "RTC",
        "provider": "PARAMOUNT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1663,
        "id": "reccnWSxekER6W6Rg",
        "row": 24127,
        "state": "",
        "group": "HSC002",
        "group_num": [
            "recMQjhMFgvECRD61"
        ],
        "procedure": "RTC",
        "provider": "PARAMOUNT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2635,
        "id": "recusted9ru88ZyvJ",
        "row": 24126,
        "state": "",
        "group": "",
        "group_num": [
            "recfbGig5BX7fTKsB"
        ],
        "procedure": "Detox",
        "provider": "PARAMOUNT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 526,
        "id": "recZMMphZyPXDdXCF",
        "row": 24128,
        "state": "",
        "group": "",
        "group_num": [
            "recZFnw41ThWpYY7x"
        ],
        "procedure": "IOP",
        "provider": "PHCS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 75,
        "id": "recUq52TUXNYsuTgO",
        "row": 24129,
        "state": "",
        "group": "",
        "group_num": [
            "recE6u1VmwA3cwx20"
        ],
        "procedure": "PHP",
        "provider": "PHILADELPHIA AMERICAN LIFE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1098,
        "id": "rec1hgaFek9yFpvtp",
        "row": 24130,
        "state": "",
        "group": "",
        "group_num": [
            "recUYCKLRYcOk6qVG"
        ],
        "procedure": "RTC",
        "provider": "PHYSICIAN'S HEALTH PLAN OF MI"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1154,
        "id": "reciyRcVC9ncU595p",
        "row": 24131,
        "state": "",
        "group": "",
        "group_num": [
            "rec94rC088tKtz1FV"
        ],
        "procedure": "RTC",
        "provider": "PHYSICIANS HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3953,
        "id": "recJtYSgCE2BdBJEI",
        "row": 24132,
        "state": "",
        "group": "",
        "group_num": [
            "rec94rC088tKtz1FV"
        ],
        "procedure": "Detox",
        "provider": "PHYSICIANS HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2945,
        "id": "reclf5M1o6tlXBHk0",
        "row": 24133,
        "state": "",
        "group": "10302",
        "group_num": [
            "rec8Ge9YXmBritCBm"
        ],
        "procedure": "RTC",
        "provider": "PIEDMONT COMMUNITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3720,
        "id": "recMLI1kVRmdZ24zb",
        "row": 24134,
        "state": "",
        "group": "10302",
        "group_num": [
            "rec8Ge9YXmBritCBm"
        ],
        "procedure": "Detox",
        "provider": "PIEDMONT COMMUNITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 914,
        "id": "reccBoOfxZcEsy3aQ",
        "row": 24135,
        "state": "",
        "group": "",
        "group_num": [
            "recPWKwn4DrRViUyP"
        ],
        "procedure": "PHP",
        "provider": "PRAIRIE STATES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 404,
        "2024": 213,
        "id": "recKYZhUtazy5qlcZ",
        "row": 24136,
        "state": "",
        "group": "",
        "group_num": [
            "recPWKwn4DrRViUyP"
        ],
        "procedure": "IOP",
        "provider": "PRAIRIE STATES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 793,
        "id": "rece6uhZA218IFBnR",
        "row": 24137,
        "state": "",
        "group": "",
        "group_num": [
            "recjVoaJ0L3Cta5Vm"
        ],
        "procedure": "RTC",
        "provider": "PREFERRED ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 37,
        "id": "recP9W1awiTkFO24O",
        "row": 24139,
        "state": "",
        "group": "",
        "group_num": [
            "recjVoaJ0L3Cta5Vm"
        ],
        "procedure": "OP",
        "provider": "PREFERRED ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1046,
        "id": "rec9mWuerd2JrMekr",
        "row": 24138,
        "state": "",
        "group": "",
        "group_num": [
            "recjVoaJ0L3Cta5Vm"
        ],
        "procedure": "PHP",
        "provider": "PREFERRED ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 174,
        "id": "recsfZLpmGARXykvJ",
        "row": 24140,
        "state": "",
        "group": "",
        "group_num": [
            "recjVoaJ0L3Cta5Vm"
        ],
        "procedure": "IOP",
        "provider": "PREFERRED ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 850,
        "id": "recgpAaxhXp6hplTx",
        "row": 24145,
        "state": "",
        "group": "PCH30364",
        "group_num": [
            "rectyjxmqrABCgMj2"
        ],
        "procedure": "RTC",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "2023": 1050,
        "id": "rechvaD3hF3gmFQyY",
        "row": 24144,
        "state": "",
        "group": "",
        "group_num": [
            "recnNftsJYBFHywvE"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "reclPbpS8DsoelKdj",
        "row": 24151,
        "state": "",
        "group": "PKA20006",
        "group_num": [
            "recW117txP98sMHLD"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 59,
        "id": "recZt2T12bImsIh1B",
        "row": 24149,
        "state": "",
        "group": "PKA20006",
        "group_num": [
            "recW117txP98sMHLD"
        ],
        "procedure": "OP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "rec1JxEyEAqltYoO3",
        "row": 24152,
        "state": "",
        "group": "",
        "group_num": [
            "recKWLUAc4QwnXq96"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recr1tmCk5UQXTkHU",
        "row": 24146,
        "state": "",
        "group": "PCH30364",
        "group_num": [
            "rectyjxmqrABCgMj2"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "reczrkkiKpSXJGMav",
        "row": 24141,
        "state": "",
        "group": "",
        "group_num": [
            "recnPwgx022vAXW2n"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "id": "recBHZ8RiaZD8Fr4M",
        "row": 24143,
        "state": "",
        "group": "GRV10758",
        "group_num": [
            "recp13Yic2otTVpZJ"
        ],
        "procedure": "IOP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "recCaAtYjFNsYgre3",
        "row": 24154,
        "state": "",
        "group": "",
        "group_num": [
            "recIQehzRezcfIGrt"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "recH2j19PfUnasFV7",
        "row": 24150,
        "state": "",
        "group": "PKA20006",
        "group_num": [
            "recW117txP98sMHLD"
        ],
        "procedure": "IOP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "recI5agyFd2dHmABc",
        "row": 24153,
        "state": "",
        "group": "",
        "group_num": [
            "recgb1W44DQ8pcuBB"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "recKiIXvFiD7jVuLd",
        "row": 24147,
        "state": "",
        "group": "",
        "group_num": [
            "recCapVSOjn8XJBar"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "id": "recKkUhO7gHwdl1IE",
        "row": 24142,
        "state": "",
        "group": "GRV10758",
        "group_num": [
            "recp13Yic2otTVpZJ"
        ],
        "procedure": "PHP",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1050,
        "id": "recLXwlxMbJpzfE42",
        "row": 24148,
        "state": "",
        "group": "",
        "group_num": [
            "recHnD0bxZiPFTQGS"
        ],
        "procedure": "Detox",
        "provider": "PREFERRED ONE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5618,
        "2023": 6000,
        "id": "recg9iUyvkkiCdFlZ",
        "row": 20011,
        "state": "",
        "group": "1100019",
        "group_num": [
            "recuWgwQQoszOdUrq"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "id": "recjaFANPvBTGpA7C",
        "row": 20006,
        "state": "",
        "group": "4022154",
        "group_num": [
            "recAfJRy5kcwi87iv"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "reckVLdFnAF6BrJAW",
        "row": 20003,
        "state": "",
        "group": "",
        "group_num": [
            "recoDgIXh0IEvRe9s"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recmYXu9DneGErQaC",
        "row": 20010,
        "state": "",
        "group": "1100019",
        "group_num": [
            "recuWgwQQoszOdUrq"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 88,
        "id": "recZUk2w4jjYHABsg",
        "row": 20004,
        "state": "",
        "group": "",
        "group_num": [
            "recoDgIXh0IEvRe9s"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "rec2ArBX2zaa8Wnau",
        "row": 20002,
        "state": "",
        "group": "",
        "group_num": [
            "recT0PNvsW4sVWLLO"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 286,
        "id": "reccnfS9HhOFpDraD",
        "row": 20008,
        "state": "",
        "group": "",
        "group_num": [
            "recm75P8ocRdT9gDI"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "id": "recpcXGOIpUlL27Wm",
        "row": 20005,
        "state": "",
        "group": "4006792",
        "group_num": [
            "reclX0qptmD1ny7xV"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "id": "recF5aOMakIE53Mma",
        "row": 20009,
        "state": "",
        "group": "",
        "group_num": [
            "recyfHpwIgijjIq6t"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "id": "recNyw1dtKmetcrQW",
        "row": 20007,
        "state": "",
        "group": "4022154",
        "group_num": [
            "recAfJRy5kcwi87iv"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF AK ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 125,
        "id": "receVczITllMnW9TH",
        "row": 20016,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "recf3qtwXtIY1xYeC",
        "row": 20031,
        "state": "",
        "group": "",
        "group_num": [
            "recV3U4UBWYpzcEZW"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 80,
        "id": "recjUdBerYShLMa7A",
        "row": 20012,
        "state": "",
        "group": "",
        "group_num": [
            "rec7PAQ4f0Zumln6J"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 63,
        "id": "recm5QpBQYS5jmMxe",
        "row": 20023,
        "state": "",
        "group": "",
        "group_num": [
            "reco8Z5XXr6dj9AhM"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "recnumCwcXC990ewA",
        "row": 20015,
        "state": "",
        "group": "7370000",
        "group_num": [
            "recxauBy5Yp18VdsY"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recnJmCacMIC49k0l",
        "row": 20013,
        "state": "",
        "group": "",
        "group_num": [
            "recuc2jh0DgENL2vf"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 88,
        "id": "recRnhmFZ5DOqG5Ar",
        "row": 20033,
        "state": "",
        "group": "4006792",
        "group_num": [
            "reclX0qptmD1ny7xV"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "2024": 67,
        "id": "recS3OoG8qmXAQMXS",
        "row": 20027,
        "state": "",
        "group": "",
        "group_num": [
            "recWikYxjE9wXB7DW"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 121,
        "2023": 76,
        "id": "recVWVnPgq5cFVenk",
        "row": 20020,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "id": "recV4bzUswBI0lHl0",
        "row": 20026,
        "state": "",
        "group": "",
        "group_num": [
            "recWikYxjE9wXB7DW"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recXYnCbthmVkDezO",
        "row": 20030,
        "state": "",
        "group": "40799634209",
        "group_num": [
            "recqirTIFLRTviFIu"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 102,
        "id": "recZs4ei3rLGhKPIp",
        "row": 20017,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1067,
        "2023": 1473,
        "id": "recZJUAz5HHCUFevJ",
        "row": 20018,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 76,
        "2024": 80,
        "id": "recZhomVBumsM1HfU",
        "row": 20029,
        "state": "",
        "group": "",
        "group_num": [
            "recET5rHPhRqhyZCt"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1247,
        "2023": 357,
        "id": "rec5dMBUSeveFmMwi",
        "row": 20028,
        "state": "",
        "group": "",
        "group_num": [
            "recET5rHPhRqhyZCt"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 195,
        "2023": 188,
        "id": "recoiUiJdirOht6gv",
        "row": 20019,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "reczcgXJusr06ANWC",
        "row": 20024,
        "state": "",
        "group": "",
        "group_num": [
            "receAXVfUUTcdUoik"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 80,
        "2023": 78,
        "id": "recBufs1zfIBD8uLq",
        "row": 20032,
        "state": "",
        "group": "",
        "group_num": [
            "recV3U4UBWYpzcEZW"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "id": "recHM4jdMG7Klt6RU",
        "row": 20014,
        "state": "",
        "group": "7370000",
        "group_num": [
            "recxauBy5Yp18VdsY"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 237,
        "id": "recLvGcvOAzmKb4GI",
        "row": 20022,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 223,
        "id": "recMLQvhLoFPXWTTE",
        "row": 20021,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 73,
        "id": "recMrP1xbGB3SbWm9",
        "row": 20025,
        "state": "",
        "group": "",
        "group_num": [
            "receAXVfUUTcdUoik"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 137,
        "id": "receyD0ZhEqX5MysM",
        "row": 20053,
        "state": "",
        "group": "",
        "group_num": [
            "recpjPkSr5YryEuwS"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 528,
        "id": "rece8i1FKfRrljlYn",
        "row": 20074,
        "state": "",
        "group": "",
        "group_num": [
            "rectBJ01DnAU4l2CU"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 100,
        "id": "rechze8jmNREncxNX",
        "row": 20071,
        "state": "",
        "group": "",
        "group_num": [
            "recFCHUIjDurXjG6b"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 73,
        "id": "reciGZLcOT2YZfaHL",
        "row": 20056,
        "state": "",
        "group": "",
        "group_num": [
            "recx65GPGyN2G7kqi"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "2024": 600,
        "id": "recicnRtpjtRYWf9i",
        "row": 20038,
        "state": "",
        "group": "",
        "group_num": [
            "recSJCTXiJjbZ4RyA"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 97,
        "id": "reclE51NUwGwaX65g",
        "row": 20050,
        "state": "",
        "group": "",
        "group_num": [
            "recpjPkSr5YryEuwS"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 610,
        "id": "reclRCTtNerSF6t75",
        "row": 20062,
        "state": "",
        "group": "",
        "group_num": [
            "recDwdFu9WHMi1qTX"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 78,
        "2024": 80,
        "id": "recm9z6ongS9ZpDhn",
        "row": 20059,
        "state": "",
        "group": "",
        "group_num": [
            "rectByBemu2S1PZ1Y"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1452,
        "2023": 1090,
        "id": "recnPUsrpENfrrZmP",
        "row": 20076,
        "state": "",
        "group": "",
        "group_num": [
            "recm75P8ocRdT9gDI"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 209,
        "id": "recPq8SB7KzXFXcsO",
        "row": 20063,
        "state": "",
        "group": "",
        "group_num": [
            "recSBLtx2w4WiOmpM"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recR9KD6DPt0eUV2b",
        "row": 20072,
        "state": "",
        "group": "",
        "group_num": [
            "rectBJ01DnAU4l2CU"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "recSRRZYv1vf57uS2",
        "row": 20069,
        "state": "",
        "group": "",
        "group_num": [
            "rec5MAlPd1VXyKVGs"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 261,
        "id": "recTWZLbZw0lH8WKe",
        "row": 20042,
        "state": "",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 276,
        "id": "recUA32c34UNjCK41",
        "row": 20064,
        "state": "",
        "group": "",
        "group_num": [
            "recSBLtx2w4WiOmpM"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "2024": 80,
        "id": "recUPusxmaj61w2Rl",
        "row": 20051,
        "state": "",
        "group": "",
        "group_num": [
            "recpjPkSr5YryEuwS"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 612,
        "id": "recXVHoVindPeri9K",
        "row": 20065,
        "state": "",
        "group": "",
        "group_num": [
            "recSzG4INPj63mG5a"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 161,
        "id": "recZoqg3rH52hqIxS",
        "row": 20081,
        "state": "",
        "group": "4024378",
        "group_num": [
            "recmTK6FsmoeeHhvs"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "id": "rec1VJLWds2irUfTM",
        "row": 20035,
        "state": "",
        "group": "1038793",
        "group_num": [
            "recbBnBM2kglNdjWc"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 230,
        "id": "rec2xiQDq7i3CqCdJ",
        "row": 20080,
        "state": "",
        "group": "4024378",
        "group_num": [
            "recmTK6FsmoeeHhvs"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 110,
        "id": "rec3qmc2J8s4qs5IN",
        "row": 20040,
        "state": "",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 617,
        "id": "rec5iIlXuanYBT1y1",
        "row": 20077,
        "state": "",
        "group": "",
        "group_num": [
            "recm75P8ocRdT9gDI"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "id": "rec5kbhtAv6WRYcqA",
        "row": 20058,
        "state": "",
        "group": "",
        "group_num": [
            "rectByBemu2S1PZ1Y"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec63FNdxUsxDkAex",
        "row": 20044,
        "state": "",
        "group": "",
        "group_num": [
            "recTasothIMNN7gDe"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "2024": 300,
        "id": "rec6gyUyI1RfKb1Fi",
        "row": 20039,
        "state": "",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4735,
        "id": "rec6qLLNWxA3MFMjT",
        "row": 20054,
        "state": "",
        "group": "",
        "group_num": [
            "recCxlvuT1dzxOnxN"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 72,
        "id": "rec8SvG3FZwrwThRL",
        "row": 20078,
        "state": "",
        "group": "",
        "group_num": [
            "recFILcHPJyXQp3UW"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "2024": 650,
        "id": "rec8A1jlQf1CJgwo9",
        "row": 20067,
        "state": "",
        "group": "",
        "group_num": [
            "recuVNhCGVm7bhkOQ"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec8B2PHfxqG0CeAI",
        "row": 20068,
        "state": "",
        "group": "",
        "group_num": [
            "recg3QBU52lvXOR2z"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 644,
        "id": "rec9qY33nUnzrJgAN",
        "row": 20083,
        "state": "",
        "group": "9524533",
        "group_num": [
            "recL8fJY0kplPoSnC"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 693,
        "id": "recaBrxnSLWkdylvP",
        "row": 20034,
        "state": "",
        "group": "4006792",
        "group_num": [
            "reclX0qptmD1ny7xV"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 136,
        "id": "reca30lAuebmy4EQK",
        "row": 20066,
        "state": "",
        "group": "",
        "group_num": [
            "recuVNhCGVm7bhkOQ"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 74,
        "id": "reccE0Wx7b5Y5TyVt",
        "row": 20037,
        "state": "",
        "group": "",
        "group_num": [
            "recfydaJGu358nT3b"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 743,
        "id": "recdVBbaiRKf5LdIF",
        "row": 20049,
        "state": "",
        "group": "",
        "group_num": [
            "recpjPkSr5YryEuwS"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 242,
        "id": "recqBtVVBGT4Y03MK",
        "row": 20052,
        "state": "",
        "group": "",
        "group_num": [
            "recpjPkSr5YryEuwS"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 329,
        "id": "rectwebSTD8dfuzhE",
        "row": 20075,
        "state": "",
        "group": "",
        "group_num": [
            "rectBJ01DnAU4l2CU"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 51,
        "id": "recuav6Vtk6fKiX1z",
        "row": 20057,
        "state": "",
        "group": "1000073",
        "group_num": [
            "rec6k1quFcGTPyinC"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 126,
        "2023": 1500,
        "id": "recvUZPQMRWU7LgjS",
        "row": 20048,
        "state": "",
        "group": "",
        "group_num": [
            "rec5PnGIoxzRksN3U"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 120,
        "id": "recvoiuPNSE0vVy07",
        "row": 20061,
        "state": "",
        "group": "",
        "group_num": [
            "recDwdFu9WHMi1qTX"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 332,
        "id": "recxxPfjyeKDcn8CY",
        "row": 20047,
        "state": "",
        "group": "",
        "group_num": [
            "rec5PnGIoxzRksN3U"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 34,
        "id": "reczE7UidLh80g0z7",
        "row": 20082,
        "state": "",
        "group": "4024378",
        "group_num": [
            "recmTK6FsmoeeHhvs"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 97,
        "id": "recBzRGKWIyHQQWoX",
        "row": 20036,
        "state": "",
        "group": "",
        "group_num": [
            "recfydaJGu358nT3b"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recCg8CyuiEJ5bzyG",
        "row": 20055,
        "state": "",
        "group": "",
        "group_num": [
            "recCxlvuT1dzxOnxN"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 37,
        "id": "recDNlpBD7swcrmjZ",
        "row": 20041,
        "state": "",
        "group": "1000010",
        "group_num": [
            "recOTpWXWyDnj7647"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 98,
        "id": "recGjqxFtGmLmzEpD",
        "row": 20045,
        "state": "",
        "group": "",
        "group_num": [
            "recS7OwbH4lr2gN6N"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 55,
        "id": "recIva4Rzs381sVWw",
        "row": 20079,
        "state": "",
        "group": "",
        "group_num": [
            "recFILcHPJyXQp3UW"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1417,
        "id": "recIaKqg9FNSH29Cf",
        "row": 20043,
        "state": "",
        "group": "",
        "group_num": [
            "recTasothIMNN7gDe"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 62,
        "id": "recIlVDUM4VVGLT3Z",
        "row": 20046,
        "state": "",
        "group": "",
        "group_num": [
            "recS7OwbH4lr2gN6N"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 650,
        "2023": 631,
        "id": "recLSz0rR8RiroJLp",
        "row": 20070,
        "state": "",
        "group": "",
        "group_num": [
            "rec5MAlPd1VXyKVGs"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recL0BxsDeyoazCoF",
        "row": 20060,
        "state": "",
        "group": "",
        "group_num": [
            "recJ0Akf1EUsi3C7N"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 94,
        "id": "recMQqGg2PZRZbGpW",
        "row": 20073,
        "state": "",
        "group": "",
        "group_num": [
            "rectBJ01DnAU4l2CU"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 87,
        "id": "recfx4rUYz17LUMkg",
        "row": 20095,
        "state": "",
        "group": "",
        "group_num": [
            "rectruMb82tuISg1G"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "reciclmLjfDNYJGvp",
        "row": 20098,
        "state": "",
        "group": "",
        "group_num": [
            "recnogX2ia6pnT80X"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "2024": 80,
        "id": "recRMoFVJseui0TCN",
        "row": 20087,
        "state": "",
        "group": "",
        "group_num": [
            "rec8kl94y5XBPCTLB"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "id": "recRg2wUbcSbP9FaQ",
        "row": 20100,
        "state": "",
        "group": "",
        "group_num": [
            "rec9P6dspjuGP6HMw"
        ],
        "procedure": "RTC",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "id": "recTnQJrLejMr6Ow7",
        "row": 20101,
        "state": "",
        "group": "",
        "group_num": [
            "rec9P6dspjuGP6HMw"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "id": "recWCOHct84D7NZmM",
        "row": 20086,
        "state": "",
        "group": "",
        "group_num": [
            "rec8kl94y5XBPCTLB"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 300,
        "id": "rec0uodJ98gTw0PBc",
        "row": 20088,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "PHP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 97,
        "id": "rec348sOeftjGjZtP",
        "row": 20089,
        "state": "",
        "group": "4000083",
        "group_num": [
            "recCYi1tlWec0v3y2"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "rec5EPZDOMjrm5xyM",
        "row": 20102,
        "state": "",
        "group": "",
        "group_num": [
            "recIz2YCKA4QX4rLH"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 80,
        "2023": 76,
        "id": "rec5FEOCTELEvWEvG",
        "row": 20096,
        "state": "",
        "group": "",
        "group_num": [
            "rectruMb82tuISg1G"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 97,
        "id": "rec7wJEOgvDQUr0AZ",
        "row": 20097,
        "state": "",
        "group": "",
        "group_num": [
            "recnogX2ia6pnT80X"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "recckgsPkNjCAelQB",
        "row": 20099,
        "state": "",
        "group": "",
        "group_num": [
            "recw8e0Ig39AXIaWv"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "id": "recdCtxQcyIjuQ14l",
        "row": 20084,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1046,
        "id": "recswTDCofDQb9kYo",
        "row": 20093,
        "state": "",
        "group": "",
        "group_num": [
            "recNkw1GTN1uW382M"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 59,
        "id": "rectuuVN2J3h8tuI9",
        "row": 20092,
        "state": "",
        "group": "",
        "group_num": [
            "recZOzuMEbEptuEQs"
        ],
        "procedure": "OP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 73,
        "id": "recwmHhEyRqIOcpG2",
        "row": 20094,
        "state": "",
        "group": "4015918",
        "group_num": [
            "recCeXShswOlnAzzt"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "recCkrV5PS1hUft0p",
        "row": 20085,
        "state": "",
        "group": "",
        "group_num": [
            "recsNMc78BchTOvqm"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 91,
        "id": "recDJsj78lnhAile7",
        "row": 20091,
        "state": "",
        "group": "",
        "group_num": [
            "recZOzuMEbEptuEQs"
        ],
        "procedure": "IOP",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "id": "recFs6niV0DxVPob7",
        "row": 20090,
        "state": "",
        "group": "",
        "group_num": [
            "rec2UxBBmx5R2TEyK"
        ],
        "procedure": "Detox",
        "provider": "PREMERA BCBS OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2237,
        "2023": 2282,
        "id": "recoxYrhsKI5dmdHT",
        "row": 24156,
        "state": "",
        "group": "",
        "group_num": [
            "recoTnFh6nvbOloaD"
        ],
        "procedure": "RTC",
        "provider": "PRESBYTERIAN HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "recIqYLOqUKkWnXUJ",
        "row": 24155,
        "state": "",
        "group": "",
        "group_num": [
            "recRQIW0L7MW2Of7T"
        ],
        "procedure": "PHP",
        "provider": "PRESBYTERIAN HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1936,
        "id": "receFCJmYKDpzHcMG",
        "row": 24163,
        "state": "",
        "group": "",
        "group_num": [
            "reckInqkx4xWusUSG"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1872,
        "id": "rechvRn6jPEZWw4K0",
        "row": 24170,
        "state": "",
        "group": "",
        "group_num": [
            "rec1QcYKIwyHv48c5"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "recjwMgHc27F5TGZ1",
        "row": 24164,
        "state": "",
        "group": "",
        "group_num": [
            "recmHlZRZz8JgfUxr"
        ],
        "procedure": "RTC",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "id": "recnHMT7TD6IJ1w0q",
        "row": 24162,
        "state": "",
        "group": "",
        "group_num": [
            "reckInqkx4xWusUSG"
        ],
        "procedure": "RTC",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "recODzuDM2yQC9ZQy",
        "row": 24166,
        "state": "",
        "group": "",
        "group_num": [
            "recO56jEBEiZe7w2X"
        ],
        "procedure": "RTC",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recRzsZnpFy2U2hgX",
        "row": 24168,
        "state": "",
        "group": "",
        "group_num": [
            "rec4nyk9uYNOoUKVD"
        ],
        "procedure": "RTC",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2550,
        "id": "recTnYv01K7Kzahum",
        "row": 24165,
        "state": "",
        "group": "",
        "group_num": [
            "recmHlZRZz8JgfUxr"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1876,
        "id": "recYgcSHKiiYY1VEB",
        "row": 24167,
        "state": "",
        "group": "",
        "group_num": [
            "recO56jEBEiZe7w2X"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2471,
        "id": "rec69PyT2Hxa2QuN0",
        "row": 24157,
        "state": "",
        "group": "",
        "group_num": [
            "recbF5Um5xAtrKJnt"
        ],
        "procedure": "RTC",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 213,
        "2023": 185,
        "id": "rec9LamlibHI5xn2s",
        "row": 24159,
        "state": "",
        "group": "",
        "group_num": [
            "recbF5Um5xAtrKJnt"
        ],
        "procedure": "OP",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recaEHgdikOgQHWKD",
        "row": 24169,
        "state": "",
        "group": "",
        "group_num": [
            "rec4nyk9uYNOoUKVD"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2069,
        "id": "recb81Ocv55PqEiOh",
        "row": 24161,
        "state": "",
        "group": "",
        "group_num": [
            "recfajU57486BvHyq"
        ],
        "procedure": "Detox",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 756,
        "id": "recxR98JXefIe1EGN",
        "row": 24160,
        "state": "",
        "group": "",
        "group_num": [
            "recbF5Um5xAtrKJnt"
        ],
        "procedure": "IOP",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 879,
        "id": "recEVEjqSTulnBaoN",
        "row": 24158,
        "state": "",
        "group": "",
        "group_num": [
            "recbF5Um5xAtrKJnt"
        ],
        "procedure": "PHP",
        "provider": "PRIORITY HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1375,
        "id": "recdSzjPhNh9tHsiW",
        "row": 24171,
        "state": "",
        "group": "",
        "group_num": [
            "recwFFd1AXrYptto5"
        ],
        "procedure": "RTC",
        "provider": "PROMINENCE HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "2023": 288,
        "id": "recekBvQSBBsSbLJM",
        "row": 24177,
        "state": "",
        "group": "",
        "group_num": [
            "rec8rXJ9g3ouRz7sw"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recjWsbJxUrFRt5Bp",
        "row": 24178,
        "state": "",
        "group": "",
        "group_num": [
            "rec8rXJ9g3ouRz7sw"
        ],
        "procedure": "IOP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1560,
        "id": "recUSOYvJreIZECQN",
        "row": 24181,
        "state": "",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1152,
        "id": "recUA82uYdqBHBWwe",
        "row": 24182,
        "state": "",
        "group": "",
        "group_num": [
            "recXTDlzrSxg6Sqte"
        ],
        "procedure": "RTC",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3420,
        "id": "recYNBVPEcO1fE8iA",
        "row": 24175,
        "state": "",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "Detox",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "2023": 288,
        "id": "rec3XmACupuiRKGP6",
        "row": 24174,
        "state": "",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "IOP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 370,
        "id": "rec3jEDNTX0QDPNOI",
        "row": 24179,
        "state": "",
        "group": "",
        "group_num": [
            "recVETwIsHEH2WIml"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 288,
        "id": "rec8ez4iELv4H7ao9",
        "row": 24173,
        "state": "",
        "group": "100315",
        "group_num": [
            "recHzlaXUMb0oiSFl"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 273,
        "id": "reczfWWdGy5FdkcZ1",
        "row": 24180,
        "state": "",
        "group": "",
        "group_num": [
            "recVETwIsHEH2WIml"
        ],
        "procedure": "IOP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 353,
        "id": "recB2Ui4ttoGzgMlp",
        "row": 24176,
        "state": "",
        "group": "106528",
        "group_num": [
            "recke3ANugOvCT1FY"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1333,
        "id": "recIN30K6wZWUBd4a",
        "row": 24172,
        "state": "",
        "group": "",
        "group_num": [
            "recSKnPQBje0r5OcQ"
        ],
        "procedure": "PHP",
        "provider": "PROVIDENCE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1446,
        "id": "recT4S6Lk55gBvTkk",
        "row": 20103,
        "state": "",
        "group": "",
        "group_num": [
            "rec5a1A6ZRGJb7dKA"
        ],
        "procedure": "Detox",
        "provider": "QUANTUM HEALTH ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 372,
        "id": "recOGcCJ7msWtjvKO",
        "row": 24184,
        "state": "",
        "group": "00531868",
        "group_num": [
            "reculCfJQUH6uVQso"
        ],
        "procedure": "PHP",
        "provider": "QUEST  BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 510,
        "id": "recWR4PJGyiBEbEvE",
        "row": 24185,
        "state": "",
        "group": "00531868",
        "group_num": [
            "reculCfJQUH6uVQso"
        ],
        "procedure": "Detox",
        "provider": "QUEST  BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 562,
        "id": "recomvwR6AxDDHXg0",
        "row": 24187,
        "state": "",
        "group": "",
        "group_num": [
            "recz6M1Wlh3qoUvMQ"
        ],
        "procedure": "Detox",
        "provider": "QUEST  BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 464,
        "id": "recwlSDZhjLOFAyHq",
        "row": 24186,
        "state": "",
        "group": "",
        "group_num": [
            "recz6M1Wlh3qoUvMQ"
        ],
        "procedure": "RTC",
        "provider": "QUEST  BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 578,
        "id": "recIFciDwWxcnYpU3",
        "row": 24183,
        "state": "",
        "group": "00531868",
        "group_num": [
            "reculCfJQUH6uVQso"
        ],
        "procedure": "RTC",
        "provider": "QUEST  BEHAVIORAL HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1030,
        "id": "reckYhJV7ySEvtQeP",
        "row": 20129,
        "state": "",
        "group": "",
        "group_num": [
            "recSVSqjYIMY8tP1Q"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 343,
        "id": "recnlAlKQFX79N9n5",
        "row": 20122,
        "state": "",
        "group": "",
        "group_num": [
            "recjh7eCOT37YEHJO"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 117,
        "id": "recO3Q5Q0O9Tv2kKz",
        "row": 20130,
        "state": "",
        "group": "",
        "group_num": [
            "recFnYOpRn7lUNakh"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recOrb4FFIG5wdrds",
        "row": 20127,
        "state": "",
        "group": "",
        "group_num": [
            "recSVSqjYIMY8tP1Q"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recRR3v7HgJPKJgAN",
        "row": 20117,
        "state": "",
        "group": "",
        "group_num": [
            "recFam8FFGClldhkJ"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recUBJNubNp1TSLhD",
        "row": 20124,
        "state": "",
        "group": "",
        "group_num": [
            "recxaDVtisOzq3Def"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "2023": 135,
        "id": "recURNfhOxrpkbLYL",
        "row": 20112,
        "state": "",
        "group": "",
        "group_num": [
            "rec9GJXLmp8t4ICkY"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recWOyDRidVFxLDIT",
        "row": 20126,
        "state": "",
        "group": "",
        "group_num": [
            "recSVSqjYIMY8tP1Q"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 7,
        "id": "recWbwAnqCcuaHVzO",
        "row": 20110,
        "state": "",
        "group": "",
        "group_num": [
            "recm1sdurrjL6jTEy"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recXAPTrhbvWQ6sTN",
        "row": 20118,
        "state": "",
        "group": "10012785",
        "group_num": [
            "rec9UWGceM5ehBEDR"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recXh4lcdREeXzqnl",
        "row": 20132,
        "state": "",
        "group": "",
        "group_num": [
            "recnYinbQTqS9xhoy"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recZyzKoZusxfapiN",
        "row": 20106,
        "state": "",
        "group": "",
        "group_num": [
            "recsL559dBWRbGRhc"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "id": "rec0Y8MUwGJBi5kxh",
        "row": 20121,
        "state": "",
        "group": "10014978",
        "group_num": [
            "recOxBaIL5GJF633S"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "rec798FzsYBFujYk2",
        "row": 20133,
        "state": "",
        "group": "",
        "group_num": [
            "recnYinbQTqS9xhoy"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "rec8svbOHkzGb7Q9y",
        "row": 20107,
        "state": "",
        "group": "",
        "group_num": [
            "recsL559dBWRbGRhc"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "recp8cEayB25t25II",
        "row": 20125,
        "state": "",
        "group": "",
        "group_num": [
            "rec7lQzeQPuIM91JM"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 11,
        "id": "recqmXCz5FGZRjWzn",
        "row": 20113,
        "state": "",
        "group": "",
        "group_num": [
            "rec9GJXLmp8t4ICkY"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "2023": 48,
        "id": "recrLeWBdYtAOq4oF",
        "row": 20128,
        "state": "",
        "group": "",
        "group_num": [
            "recSVSqjYIMY8tP1Q"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 140,
        "id": "recriFxquQekMTVzT",
        "row": 20123,
        "state": "",
        "group": "",
        "group_num": [
            "recjh7eCOT37YEHJO"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 110,
        "id": "recuz94lY0Td5mD8F",
        "row": 20109,
        "state": "",
        "group": "",
        "group_num": [
            "recm1sdurrjL6jTEy"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recvZxFbHdnSAwVBN",
        "row": 20104,
        "state": "",
        "group": "",
        "group_num": [
            "reck8yNUyyRiPtxk5"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recvmevTGH4y3ihxO",
        "row": 20116,
        "state": "",
        "group": "",
        "group_num": [
            "recFam8FFGClldhkJ"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 157,
        "id": "recyEO2XSJqySEPgc",
        "row": 20119,
        "state": "",
        "group": "10012785",
        "group_num": [
            "rec9UWGceM5ehBEDR"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 108,
        "id": "recynBhIduzgeESuG",
        "row": 20114,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "reczio8KMOBk2MQhw",
        "row": 20108,
        "state": "",
        "group": "",
        "group_num": [
            "recA2qbMg2oEP0Kt6"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "2023": 135,
        "id": "recAfQhg420sMSLWw",
        "row": 20120,
        "state": "",
        "group": "10014978",
        "group_num": [
            "recOxBaIL5GJF633S"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recBwB02GE74KLBXN",
        "row": 20111,
        "state": "",
        "group": "",
        "group_num": [
            "rec4CAZdzp73os78B"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recFI4RfXQQle7QkJ",
        "row": 20131,
        "state": "",
        "group": "",
        "group_num": [
            "recnYinbQTqS9xhoy"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 7,
        "2024": 32,
        "id": "recMmvxfTBpSrLzqS",
        "row": 20115,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recMmLdgV7Vox6BXi",
        "row": 20105,
        "state": "",
        "group": "",
        "group_num": [
            "recsL559dBWRbGRhc"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "2023": 14,
        "id": "recexdla30REsn9mU",
        "row": 20136,
        "state": "",
        "group": "",
        "group_num": [
            "recRPu0bShktWtlry"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recfkjMUSmpe3iQyT",
        "row": 20156,
        "state": "",
        "group": "",
        "group_num": [
            "rec4pl711uMfEAtrg"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "reckWv6JuqwCnMq8F",
        "row": 20151,
        "state": "",
        "group": "",
        "group_num": [
            "recg8AsZwrBMAmqqs"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "reclwZcwUQydKAj6Z",
        "row": 20147,
        "state": "",
        "group": "",
        "group_num": [
            "recZcJFM1JRZDxu1V"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recnVTSqlYb2BPkgR",
        "row": 20162,
        "state": "",
        "group": "",
        "group_num": [
            "recuZqrNOLPqw7SDH"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1913,
        "id": "recndBU0QzCece0aw",
        "row": 20134,
        "state": "",
        "group": "",
        "group_num": [
            "recRPu0bShktWtlry"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recOvXsQGWIdIMi6F",
        "row": 20155,
        "state": "",
        "group": "",
        "group_num": [
            "rec4pl711uMfEAtrg"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 378,
        "2023": 331,
        "id": "recP0MmBWgaSSb5jE",
        "row": 20145,
        "state": "",
        "group": "",
        "group_num": [
            "recVCLYQapFwO5eys"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 27,
        "id": "recQKFEyjNTjvImip",
        "row": 20148,
        "state": "",
        "group": "",
        "group_num": [
            "recZcJFM1JRZDxu1V"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recQ7LqdbDzrshm1k",
        "row": 20140,
        "state": "",
        "group": "",
        "group_num": [
            "recWht8DBOKQnj743"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 853,
        "2023": 135,
        "id": "recSyWoV6r6AGRwQq",
        "row": 20135,
        "state": "",
        "group": "",
        "group_num": [
            "recRPu0bShktWtlry"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recSPjzmWDlXOh1wL",
        "row": 20137,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recS4ZqKVTmFmHkvx",
        "row": 20157,
        "state": "",
        "group": "",
        "group_num": [
            "reckMgppjeevveQLH"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 162,
        "id": "recViSicQhVOKRUgT",
        "row": 20142,
        "state": "",
        "group": "",
        "group_num": [
            "recWht8DBOKQnj743"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "2023": 128,
        "id": "recXgbiYFV7UJo3jQ",
        "row": 20138,
        "state": "",
        "group": "",
        "group_num": [
            "reccLEYvJ6Oa5f5zR"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 320,
        "id": "rec0glZUuunsY3ZBp",
        "row": 20150,
        "state": "",
        "group": "",
        "group_num": [
            "recOnEfHj5zsOakZk"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "rec3I6EhLfHDQeQAO",
        "row": 20160,
        "state": "",
        "group": "",
        "group_num": [
            "recIMcCVJEKVSA5mh"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "rec3KOBki0ryePpe5",
        "row": 20153,
        "state": "",
        "group": "",
        "group_num": [
            "rec7lQzeQPuIM91JM"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rec66mB86UTbzN60P",
        "row": 20159,
        "state": "",
        "group": "",
        "group_num": [
            "recq5ENTpb33EZ4yx"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 270,
        "id": "recoDD3Evh59txfqp",
        "row": 20146,
        "state": "",
        "group": "",
        "group_num": [
            "recVCLYQapFwO5eys"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recoHY1SzXpBnbLZg",
        "row": 20163,
        "state": "",
        "group": "",
        "group_num": [
            "recdklQt0yKIGK8CS"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recpfB9xtSmTayu0G",
        "row": 20161,
        "state": "",
        "group": "",
        "group_num": [
            "recyrSmwAaXLbiy33"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recsR3CkSwKCDaSMv",
        "row": 20152,
        "state": "",
        "group": "",
        "group_num": [
            "recg8AsZwrBMAmqqs"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recARKa5MHpkkGAE7",
        "row": 20154,
        "state": "",
        "group": "",
        "group_num": [
            "rec7lQzeQPuIM91JM"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 886,
        "id": "recBIThZVy0NmYxUp",
        "row": 20143,
        "state": "",
        "group": "",
        "group_num": [
            "recWht8DBOKQnj743"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 340,
        "id": "recB6rAwFfls1raIV",
        "row": 20149,
        "state": "",
        "group": "",
        "group_num": [
            "recOnEfHj5zsOakZk"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 94,
        "id": "recJn2h7Mkkl4F2vb",
        "row": 20158,
        "state": "",
        "group": "",
        "group_num": [
            "recq5ENTpb33EZ4yx"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 133,
        "2023": 135,
        "id": "recK3EYPw52SVRcWU",
        "row": 20144,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 279,
        "2023": 1017,
        "id": "recLWD2l5mVgPMip0",
        "row": 20141,
        "state": "",
        "group": "",
        "group_num": [
            "recWht8DBOKQnj743"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 15,
        "2023": 13,
        "2024": 32,
        "id": "recNEnjjsl32NFAhC",
        "row": 20139,
        "state": "",
        "group": "",
        "group_num": [
            "reccLEYvJ6Oa5f5zR"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF OR ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 894,
        "id": "receaES0CWP094BDJ",
        "row": 20177,
        "state": "",
        "group": "",
        "group_num": [
            "recQGpSdhdo5QU2UQ"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 846,
        "id": "recfddfLXOuS6ICzD",
        "row": 20174,
        "state": "",
        "group": "",
        "group_num": [
            "recqxcVjc8El8PRZj"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "rec3sqAzuYI3sp3ou",
        "row": 20172,
        "state": "",
        "group": "60021265",
        "group_num": [
            "recmdB5Yjx6GsNHeL"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 127,
        "id": "rec8eUjoJWqDKaK9o",
        "row": 20179,
        "state": "",
        "group": "10045164",
        "group_num": [
            "recIxqJbVKCyqM9M8"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 23,
        "2024": 23,
        "id": "rec9IRhdEfqFaZ6uj",
        "row": 20173,
        "state": "",
        "group": "60021265",
        "group_num": [
            "recmdB5Yjx6GsNHeL"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "recaqGfkEtZEKD8Kd",
        "row": 20164,
        "state": "",
        "group": "",
        "group_num": [
            "rec4RQvBLe3VUl4JE"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recd5Yzbt3aGvhYF6",
        "row": 20166,
        "state": "",
        "group": "10007186",
        "group_num": [
            "rec8q2fNL1uDEClcG"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recpHlV3vND4BdPE6",
        "row": 20176,
        "state": "",
        "group": "",
        "group_num": [
            "recQGpSdhdo5QU2UQ"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 142,
        "2024": 147,
        "id": "recqiMb7WT0NY6xc3",
        "row": 20170,
        "state": "",
        "group": "",
        "group_num": [
            "recMMbAY7VXxINrKl"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 492,
        "id": "recwCYV9AzHHeatzG",
        "row": 20168,
        "state": "",
        "group": "",
        "group_num": [
            "recKy9wRF092AMHhH"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recwj3kvJG7weWS90",
        "row": 20167,
        "state": "",
        "group": "10007186",
        "group_num": [
            "rec8q2fNL1uDEClcG"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 770,
        "2023": 770,
        "id": "recAv4DulM3GXk7WM",
        "row": 20175,
        "state": "",
        "group": "",
        "group_num": [
            "recqxcVjc8El8PRZj"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recEe2ezyPBFwyGyC",
        "row": 20180,
        "state": "",
        "group": "10044688",
        "group_num": [
            "recJesfYoUy3yfwJK"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 374,
        "2024": 374,
        "id": "recFOpNQaE35UtPqR",
        "row": 20169,
        "state": "",
        "group": "",
        "group_num": [
            "recMMbAY7VXxINrKl"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 23,
        "2024": 23,
        "id": "recJkDagsBSoA879G",
        "row": 20171,
        "state": "",
        "group": "",
        "group_num": [
            "recMMbAY7VXxINrKl"
        ],
        "procedure": "OP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1053,
        "id": "recLqKp2cGGeaEsPT",
        "row": 20178,
        "state": "",
        "group": "",
        "group_num": [
            "recQGpSdhdo5QU2UQ"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "recNRUC9reXR8NA39",
        "row": 20165,
        "state": "",
        "group": "",
        "group_num": [
            "recKsXmGMIiXUc4ml"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BCBS OF UT ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recXGAFaIMsMVwI4g",
        "row": 20182,
        "state": "",
        "group": "",
        "group_num": [
            "recjdNUJAmyXCYEuP"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recbWRvPEXqeZi8hi",
        "row": 20183,
        "state": "",
        "group": "",
        "group_num": [
            "rec6ohxn1bQMG3XCH"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recJZKC8bms0HZ7KI",
        "row": 20181,
        "state": "",
        "group": "",
        "group_num": [
            "recjdNUJAmyXCYEuP"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BLUE SHIELD OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "2023": 47,
        "id": "recERa6ZVtrHP8jus",
        "row": 20186,
        "state": "",
        "group": "",
        "group_num": [
            "recj5tFJLvrZTZBRv"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 244,
        "id": "recJhRbPfrwVXm0t0",
        "row": 20185,
        "state": "",
        "group": "",
        "group_num": [
            "recj5tFJLvrZTZBRv"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 21,
        "id": "recNGUON9K8RZQvt1",
        "row": 20184,
        "state": "",
        "group": "",
        "group_num": [
            "rec6ohxn1bQMG3XCH"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF ID ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "reckJiZ2tn77hSVnF",
        "row": 20218,
        "state": "",
        "group": "",
        "group_num": [
            "rec5uDKlovwIHk3OA"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recmLvk9VZlppq8Hk",
        "row": 20191,
        "state": "",
        "group": "",
        "group_num": [
            "recCHMnKtAlBSkOtl"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 308,
        "id": "recOTQ32RcZCsaA4M",
        "row": 20204,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 192,
        "id": "recQZRc94vptBwOBG",
        "row": 20192,
        "state": "",
        "group": "",
        "group_num": [
            "reczGD1l9U6RI4Lb4"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 78,
        "id": "recSQ3GqvZAAgrxV6",
        "row": 20193,
        "state": "",
        "group": "",
        "group_num": [
            "reccFYB9g8J3TRySh"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 397,
        "id": "recTiuJyTvKt9kc8v",
        "row": 20219,
        "state": "",
        "group": "",
        "group_num": [
            "recWXDSLBt5nbnbIb"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 77,
        "id": "recUSJNme2zFgvjI2",
        "row": 20213,
        "state": "",
        "group": "",
        "group_num": [
            "recoqTEnrLx9vKYCh"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "id": "recV3ZHbdIFeU9pq6",
        "row": 20206,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 117,
        "2023": 99,
        "id": "recWVZN0nQ0dnwDPX",
        "row": 20205,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 19,
        "2024": 22,
        "id": "rec1gomOJaSJ15bRW",
        "row": 20214,
        "state": "",
        "group": "",
        "group_num": [
            "recoqTEnrLx9vKYCh"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "rec3evGG3whNbkgug",
        "row": 20215,
        "state": "",
        "group": "",
        "group_num": [
            "recOmwo864kxR9uRp"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 896,
        "id": "rec4w27206nYybSZL",
        "row": 20203,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 704,
        "id": "rec7UrU5ClEJNvBvI",
        "row": 20190,
        "state": "",
        "group": "",
        "group_num": [
            "recCHMnKtAlBSkOtl"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1605,
        "id": "rec9HIczvn6qVmIbn",
        "row": 20209,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "reccN0JnV5b4Hzm9k",
        "row": 20188,
        "state": "",
        "group": "",
        "group_num": [
            "recW6c7TxYW0dcgkv"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 332,
        "id": "recoYoYnCsCyQe5at",
        "row": 20220,
        "state": "",
        "group": "",
        "group_num": [
            "recWXDSLBt5nbnbIb"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2244,
        "id": "recoiBaQwSrmpbsk2",
        "row": 20212,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recq7GWBlWub7mhHu",
        "row": 20211,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recraGJP1cWDJzoVQ",
        "row": 20207,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "id": "recrebuplUZiD5tUl",
        "row": 20208,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "id": "recvs23TEEGRBkthg",
        "row": 20189,
        "state": "",
        "group": "",
        "group_num": [
            "recW6c7TxYW0dcgkv"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recv5HRTqrZNO3rmt",
        "row": 20187,
        "state": "",
        "group": "",
        "group_num": [
            "recF0Lob6UKx5kgpb"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recwuI11fjraW1WTD",
        "row": 20198,
        "state": "",
        "group": "",
        "group_num": [
            "recOEKDBCOQD8Op16"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 39,
        "id": "recygJVVIGmcolaMM",
        "row": 20201,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "OP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recz31riKFETTlbVE",
        "row": 20202,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 339,
        "id": "reczecdIi5exOPjaB",
        "row": 20199,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 119,
        "2023": 400,
        "2024": 400,
        "id": "recAXd1CSyWKFP2tI",
        "row": 20200,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 320,
        "2023": 320,
        "id": "recAczEwLLb0ZQSmQ",
        "row": 20194,
        "state": "",
        "group": "",
        "group_num": [
            "recyYkxzRIbQZfBbb"
        ],
        "procedure": "RTC",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1048,
        "id": "recAcSbJNQIX1jqSk",
        "row": 20210,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1395,
        "id": "recAgqd91EIAnMOOr",
        "row": 20217,
        "state": "",
        "group": "",
        "group_num": [
            "rec5uDKlovwIHk3OA"
        ],
        "procedure": "PHP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 374,
        "id": "recBYJNyGdGq06XZ5",
        "row": 20216,
        "state": "",
        "group": "",
        "group_num": [
            "recOmwo864kxR9uRp"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 99,
        "2024": 99,
        "id": "recHXOy58Zk2ToqOI",
        "row": 20196,
        "state": "",
        "group": "",
        "group_num": [
            "recH4LmCQzbxQoJ9T"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 383,
        "id": "recIeODog46rabd6h",
        "row": 20195,
        "state": "",
        "group": "",
        "group_num": [
            "recyYkxzRIbQZfBbb"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recKwRMW5rcJhnOSd",
        "row": 20197,
        "state": "",
        "group": "",
        "group_num": [
            "recOEKDBCOQD8Op16"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1030,
        "id": "recNuf5eaf49aJezk",
        "row": 20221,
        "state": "",
        "group": "10047886",
        "group_num": [
            "rec9TwlUPsFNmavAf"
        ],
        "procedure": "Detox",
        "provider": "REGENCE BLUE SHIELD OF WA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recQbU3JJXXNKoWz8",
        "row": 24191,
        "state": "",
        "group": "",
        "group_num": [
            "reccLEYvJ6Oa5f5zR"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recS4Y2CjHhSElTRe",
        "row": 24194,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Gcpns7RaTNcgv"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1046,
        "id": "rec3LeplN13eqXgE6",
        "row": 24188,
        "state": "",
        "group": "",
        "group_num": [
            "recNkw1GTN1uW382M"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "id": "rec46kLNu2mx45oQ8",
        "row": 24192,
        "state": "",
        "group": "",
        "group_num": [
            "reck8yNUyyRiPtxk5"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recyrLol9yamcTZNg",
        "row": 24190,
        "state": "",
        "group": "",
        "group_num": [
            "recnYinbQTqS9xhoy"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 28,
        "id": "recFbjHarJoEjc0vq",
        "row": 24189,
        "state": "",
        "group": "",
        "group_num": [
            "recnYinbQTqS9xhoy"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 135,
        "id": "recHZa7dObvyArBU5",
        "row": 24193,
        "state": "",
        "group": "",
        "group_num": [
            "reck8yNUyyRiPtxk5"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF ID"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "rech4HMVzzt4QQhsE",
        "row": 24201,
        "state": "",
        "group": "7NUS60",
        "group_num": [
            "recGpYjFS14m6cNKT"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 24,
        "id": "reciW2mI8DMqGrw4P",
        "row": 24200,
        "state": "",
        "group": "",
        "group_num": [
            "recRTJhXv7bSqqUpE"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "reciHdHMIxa8f1UtU",
        "row": 24204,
        "state": "",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 45,
        "2024": 47,
        "id": "recZcy3VmS9Fr7Za1",
        "row": 24202,
        "state": "",
        "group": "7NUW60",
        "group_num": [
            "recdUUd307ivPu3SM"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 900,
        "id": "rec2phznFrKfXCMPX",
        "row": 24198,
        "state": "",
        "group": "",
        "group_num": [
            "recyvrdOhWMd0TTx9"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 726,
        "id": "rec3w2cL9n6hclT7P",
        "row": 24206,
        "state": "",
        "group": "",
        "group_num": [
            "recXhVzJO8WxgdNhJ"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "id": "recap527FSjhh9JPI",
        "row": 24205,
        "state": "",
        "group": "",
        "group_num": [
            "recXhVzJO8WxgdNhJ"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "reczKiJGxW1GPx4T5",
        "row": 24203,
        "state": "",
        "group": "031843",
        "group_num": [
            "recdLWXicSOXlrXlP"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 825,
        "id": "recAhYxuncbYcQGrZ",
        "row": 24199,
        "state": "",
        "group": "7NUS00",
        "group_num": [
            "recw79VUR257lLbuw"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "2023": 48,
        "id": "recCdwDtGRtNRtQY1",
        "row": 24195,
        "state": "",
        "group": "",
        "group_num": [
            "recngCRwZWUqPDReq"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recGVaFvfU4sxVaL1",
        "row": 24196,
        "state": "",
        "group": "",
        "group_num": [
            "recngCRwZWUqPDReq"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 47,
        "2024": 47,
        "id": "recKVERoDQFqcz8wk",
        "row": 24197,
        "state": "",
        "group": "",
        "group_num": [
            "recZpRgD1ZR7vp8Zd"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 96,
        "2024": 99,
        "id": "recfdhdxhwEkSu0Am",
        "row": 24218,
        "state": "",
        "group": "",
        "group_num": [
            "recM5whSWOhXX7iLZ"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 22,
        "id": "recfiND4W6NrzQcFn",
        "row": 24233,
        "state": "",
        "group": "",
        "group_num": [
            "rec0e6nX7CKtpJtWy"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 19,
        "id": "rechYdh9Mo7391twP",
        "row": 24227,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "2024": 22,
        "id": "reciuxHCISJ92Dboo",
        "row": 24239,
        "state": "",
        "group": "",
        "group_num": [
            "rec8pAuRSUYQWzf3v"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 17,
        "id": "reciNlgzlsx5pjAwz",
        "row": 24212,
        "state": "",
        "group": "",
        "group_num": [
            "reckTcYQBle7qVkXK"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 94,
        "id": "recjC4TfM9z3w4lS5",
        "row": 24237,
        "state": "",
        "group": "",
        "group_num": [
            "recAz3TFahnUXXz3C"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recjNWCFC0VjdJs0U",
        "row": 24228,
        "state": "",
        "group": "",
        "group_num": [
            "recwbjizHnMwBBRFM"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "id": "recjdHH3c76w6jZjC",
        "row": 24246,
        "state": "",
        "group": "",
        "group_num": [
            "recvieTd4aAMt9iD8"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 72,
        "id": "reckotxRMCzwZiQpH",
        "row": 24219,
        "state": "",
        "group": "",
        "group_num": [
            "rec9guOXBZUSXeE9h"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recOIlHo8kE2rW996",
        "row": 24208,
        "state": "",
        "group": "",
        "group_num": [
            "rec5wq7mMpChw00Np"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 232,
        "2024": 290,
        "id": "recOf4VMsG7r3n2sH",
        "row": 24207,
        "state": "",
        "group": "",
        "group_num": [
            "reckksXmPUJ9SsUQQ"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 23,
        "id": "recRpZMUPC3XIQfY1",
        "row": 24215,
        "state": "",
        "group": "",
        "group_num": [
            "recoqTEnrLx9vKYCh"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 23,
        "id": "recSV6MtksiyqGvQP",
        "row": 24210,
        "state": "",
        "group": "",
        "group_num": [
            "recgxIUc1G8VdMAOX"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 22,
        "id": "recYIreqiIxeFuILl",
        "row": 24223,
        "state": "",
        "group": "",
        "group_num": [
            "rec96B1W0q6O60SkG"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "recY7BqlOPiX4x6x4",
        "row": 24217,
        "state": "",
        "group": "",
        "group_num": [
            "recM5whSWOhXX7iLZ"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 55,
        "2024": 91,
        "id": "recZ4TXVxnS2BcObM",
        "row": 24226,
        "state": "",
        "group": "",
        "group_num": [
            "recVYZDtccPt6AMSC"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "2023": 99,
        "id": "recZ8HsGBWoXjsP1R",
        "row": 24220,
        "state": "",
        "group": "",
        "group_num": [
            "rec9guOXBZUSXeE9h"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 41,
        "2023": 37,
        "id": "rec0YFFvw4DlgO8xL",
        "row": 24241,
        "state": "",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "2023": 99,
        "id": "rec0JSa0pHGKbcMnv",
        "row": 24244,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "rec097BOkf9BsBFRD",
        "row": 24238,
        "state": "",
        "group": "",
        "group_num": [
            "rec41HIjyLtFujA3O"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 77,
        "id": "rec2SxFkg6B9wnpl0",
        "row": 24211,
        "state": "",
        "group": "",
        "group_num": [
            "recqxsAuodM9717pP"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "id": "rec8PIiV7JivHVAjg",
        "row": 24236,
        "state": "",
        "group": "C20879M6A1",
        "group_num": [
            "recJoqBZeLp1W9Ddm"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 19,
        "id": "rec9oNEgTtUzYGtZc",
        "row": 24230,
        "state": "",
        "group": "",
        "group_num": [
            "recHRELqKb5ngQyWm"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 88,
        "2024": 99,
        "id": "recaYgX37ol5XUdq5",
        "row": 24234,
        "state": "",
        "group": "",
        "group_num": [
            "rec0e6nX7CKtpJtWy"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 19,
        "id": "recdlYH0NQxfNpdKL",
        "row": 24213,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 14,
        "id": "recq8FAQ0spM96Oh2",
        "row": 24231,
        "state": "",
        "group": "",
        "group_num": [
            "recHRELqKb5ngQyWm"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "id": "rectM0xZqR1JmoSQX",
        "row": 24232,
        "state": "",
        "group": "",
        "group_num": [
            "recyrCGP5UxA9Ijit"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 93,
        "2023": 99,
        "id": "rectfFCgoUVdonE1i",
        "row": 24216,
        "state": "",
        "group": "",
        "group_num": [
            "recoqTEnrLx9vKYCh"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recuVZc9YVdjlD9zG",
        "row": 24247,
        "state": "",
        "group": "",
        "group_num": [
            "recvieTd4aAMt9iD8"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 216,
        "2024": 290,
        "id": "recuJmoNfayAt6wFF",
        "row": 24240,
        "state": "",
        "group": "",
        "group_num": [
            "rec8pAuRSUYQWzf3v"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 750,
        "id": "recxRxgAiZRgKEbD4",
        "row": 24245,
        "state": "",
        "group": "",
        "group_num": [
            "rec58IDdIIb0z8FkO"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "2023": 99,
        "id": "recxciNMIVNnw8ZkV",
        "row": 24214,
        "state": "",
        "group": "10003948",
        "group_num": [
            "rectoDDwwPONf4LKK"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 82,
        "id": "recyxmU2EDe1yrG4b",
        "row": 24235,
        "state": "",
        "group": "",
        "group_num": [
            "recnKAnmpxniVzNHN"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 37,
        "id": "recykyWk0BoeQS9fH",
        "row": 24248,
        "state": "",
        "group": "",
        "group_num": [
            "rec2opB8O7FLV4Mo9"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 352,
        "id": "reczMPpsP33f0MGa2",
        "row": 24224,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 18,
        "2024": 22,
        "id": "recB8j9FzRlaoxts9",
        "row": 24222,
        "state": "",
        "group": "",
        "group_num": [
            "recY4jp4ldv8iqBk3"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recEFTfNvuEUVoGu0",
        "row": 24243,
        "state": "",
        "group": "P14602",
        "group_num": [
            "recuDS8ArLQZUGMwm"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 19,
        "2024": 22,
        "id": "recEmgfsSJ58dUful",
        "row": 24221,
        "state": "",
        "group": "",
        "group_num": [
            "recx3Ht0JuKWbU7xo"
        ],
        "procedure": "OP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 671,
        "id": "recHv1Z62gENcOSmP",
        "row": 24242,
        "state": "",
        "group": "P14558",
        "group_num": [
            "rec0eUC9k8dzx5YeT"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recID2BHiLBRTIHJp",
        "row": 24229,
        "state": "",
        "group": "",
        "group_num": [
            "recdxUmQUjULjBajE"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 917,
        "id": "recM38pG2MYauZ1pR",
        "row": 24209,
        "state": "",
        "group": "",
        "group_num": [
            "rec5wq7mMpChw00Np"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1455,
        "id": "recNCIEXIxcmk7EDg",
        "row": 24225,
        "state": "",
        "group": "10195482",
        "group_num": [
            "recIRQHmqgPgiTXP8"
        ],
        "procedure": "IOP",
        "provider": "REGENCE BS OF WA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 201,
        "id": "recSFWFezoJ812BHZ",
        "row": 20225,
        "state": "",
        "group": "020224",
        "group_num": [
            "rechBUvLQw19SXoc6"
        ],
        "procedure": "Detox",
        "provider": "REGENCE GROUP ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 34,
        "id": "recWo9GOoGG5CSfkk",
        "row": 20223,
        "state": "",
        "group": "020431",
        "group_num": [
            "recgzXuxwSnOXsfHs"
        ],
        "procedure": "OP",
        "provider": "REGENCE GROUP ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "rec6eJC7W46wak6DI",
        "row": 20224,
        "state": "",
        "group": "",
        "group_num": [
            "recCHMnKtAlBSkOtl"
        ],
        "procedure": "PHP",
        "provider": "REGENCE GROUP ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 762,
        "id": "recoFIV0APtMJU6pB",
        "row": 20227,
        "state": "",
        "group": "",
        "group_num": [
            "recWXLLwedCI9e1Uq"
        ],
        "procedure": "Detox",
        "provider": "REGENCE GROUP ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 767,
        "id": "recG54HPLIoQq7YKk",
        "row": 20226,
        "state": "",
        "group": "",
        "group_num": [
            "recWXLLwedCI9e1Uq"
        ],
        "procedure": "RTC",
        "provider": "REGENCE GROUP ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recMRv3UfEhhUzT83",
        "row": 20222,
        "state": "",
        "group": "020431",
        "group_num": [
            "recgzXuxwSnOXsfHs"
        ],
        "procedure": "IOP",
        "provider": "REGENCE GROUP ADMINISTRATORS ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2519,
        "id": "recVFBPkIQjPxA50S",
        "row": 24252,
        "state": "",
        "group": "",
        "group_num": [
            "recBKpzDLwrvqMwF7"
        ],
        "procedure": "Detox",
        "provider": "S&S HEALTHCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 657,
        "id": "recVRfY1DUXMmBrFx",
        "row": 24251,
        "state": "",
        "group": "",
        "group_num": [
            "recBKpzDLwrvqMwF7"
        ],
        "procedure": "IOP",
        "provider": "S&S HEALTHCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 762,
        "2023": 1908,
        "2024": 1772,
        "id": "rec0USc1o5JY8THMC",
        "row": 24249,
        "state": "",
        "group": "",
        "group_num": [
            "recBKpzDLwrvqMwF7"
        ],
        "procedure": "RTC",
        "provider": "S&S HEALTHCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 896,
        "id": "rec2TjT3OJqMbDoDB",
        "row": 24250,
        "state": "",
        "group": "",
        "group_num": [
            "recBKpzDLwrvqMwF7"
        ],
        "procedure": "PHP",
        "provider": "S&S HEALTHCARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1391,
        "id": "rec1cZYj0rJKcwT6B",
        "row": 24253,
        "state": "",
        "group": "",
        "group_num": [
            "recy0tPF7ollJ3NVb"
        ],
        "procedure": "RTC",
        "provider": "SAMERA HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1151,
        "id": "recuMCu2kbpTEZGh6",
        "row": 24254,
        "state": "",
        "group": "",
        "group_num": [
            "recTOHszYinU6tkYq"
        ],
        "procedure": "Detox",
        "provider": "SANA BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3987,
        "id": "recu2FTuU8IQefbwc",
        "row": 24255,
        "state": "",
        "group": "",
        "group_num": [
            "recluD3AG7GpC0Oz6"
        ],
        "procedure": "RTC",
        "provider": "SANA BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "2024": 376,
        "id": "reczl5FlwQjcJOZEd",
        "row": 24256,
        "state": "",
        "group": "",
        "group_num": [
            "recluD3AG7GpC0Oz6"
        ],
        "procedure": "PHP",
        "provider": "SANA BENEFITS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2880,
        "2023": 2023,
        "id": "recliFQGr1T9zdl4z",
        "row": 24259,
        "state": "",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "2023": 1585,
        "id": "recnTaZZ1rDBZjBLk",
        "row": 24258,
        "state": "",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "PHP",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1794,
        "id": "recTcBrudtPFNLzW9",
        "row": 24267,
        "state": "",
        "group": "",
        "group_num": [
            "rec1JVXbRiExV4GjP"
        ],
        "procedure": "RTC",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3814,
        "2023": 2100,
        "id": "rec3tRD7leb8VpXmb",
        "row": 24257,
        "state": "",
        "group": "0010240001",
        "group_num": [
            "reccYx5O416HJehHF"
        ],
        "procedure": "RTC",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3500,
        "id": "rec7zbb0X5eiPQOd4",
        "row": 24261,
        "state": "",
        "group": "0412160007",
        "group_num": [
            "recSsitKMcIYUmSPN"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recdGNLySS7Y5m4BO",
        "row": 24268,
        "state": "",
        "group": "",
        "group_num": [
            "rec8cX74I9axMpRLq"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1452,
        "2023": 522,
        "2024": 1086,
        "id": "rectC4Kqx5SVKSa4A",
        "row": 24266,
        "state": "",
        "group": "",
        "group_num": [
            "recBRONPXmE8hoVWG"
        ],
        "procedure": "IOP",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "2023": 1197,
        "2024": 1625,
        "id": "recvb17HvXqqyeDdq",
        "row": 24264,
        "state": "",
        "group": "",
        "group_num": [
            "recBRONPXmE8hoVWG"
        ],
        "procedure": "PHP",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recyKpqFMVXDVYZxq",
        "row": 24265,
        "state": "",
        "group": "",
        "group_num": [
            "recBRONPXmE8hoVWG"
        ],
        "procedure": "OP",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2032,
        "id": "recGnmyCjjecV0QKM",
        "row": 24260,
        "state": "",
        "group": "0412160007",
        "group_num": [
            "recSsitKMcIYUmSPN"
        ],
        "procedure": "RTC",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3077,
        "id": "recL3miBL5txkAmPq",
        "row": 24262,
        "state": "",
        "group": "",
        "group_num": [
            "recAslieBgUAHid2X"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2100,
        "id": "recNGXJPuFw4Hv74a",
        "row": 24263,
        "state": "",
        "group": "",
        "group_num": [
            "recZCY3nyXzqOaXGc"
        ],
        "procedure": "Detox",
        "provider": "SANFORD HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "rec2QlfL5Ph0PmzMq",
        "row": 24270,
        "state": "",
        "group": "",
        "group_num": [
            "rec5bY52kdP0pAiEe"
        ],
        "procedure": "IOP",
        "provider": "SCRIPTS CARMEL VALLEY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 900,
        "id": "recBf9fu35sidZaVH",
        "row": 24269,
        "state": "",
        "group": "",
        "group_num": [
            "rec5bY52kdP0pAiEe"
        ],
        "procedure": "RTC",
        "provider": "SCRIPTS CARMEL VALLEY"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 420,
        "id": "reciuCRkoIzrQdjH1",
        "row": 24276,
        "state": "",
        "group": "",
        "group_num": [
            "recopFi3kIyrdc7hZ"
        ],
        "procedure": "RTC",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 365,
        "id": "rec5v7MSXzyRZH8vu",
        "row": 24273,
        "state": "",
        "group": "",
        "group_num": [
            "rec4tJANelo99Idk7"
        ],
        "procedure": "PHP",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 437,
        "id": "recc7FOte7IRq30Ic",
        "row": 24272,
        "state": "",
        "group": "",
        "group_num": [
            "rec4tJANelo99Idk7"
        ],
        "procedure": "RTC",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 233,
        "id": "recp6Ny5byB2Lsh2y",
        "row": 24271,
        "state": "",
        "group": "",
        "group_num": [
            "rechUO9uWOF7oohio"
        ],
        "procedure": "IOP",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 200,
        "id": "recC0wp5v6qUWh70R",
        "row": 24275,
        "state": "",
        "group": "",
        "group_num": [
            "recktthj2Iw3FUqFO"
        ],
        "procedure": "IOP",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 225,
        "id": "recDdm4O975akJk2D",
        "row": 24274,
        "state": "",
        "group": "",
        "group_num": [
            "rec4tJANelo99Idk7"
        ],
        "procedure": "IOP",
        "provider": "SELECT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 683,
        "id": "rec3uiKw0MztBupnD",
        "row": 24277,
        "state": "",
        "group": "001",
        "group_num": [
            "rec1wB5gOu2RdrkOF"
        ],
        "procedure": "RTC",
        "provider": "SEMINOLE TRIBE OF FLORIDA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 75,
        "id": "receHVqWAY0fvaOE3",
        "row": 24281,
        "state": "",
        "group": "",
        "group_num": [
            "recqWqlOdPIOrxe4y"
        ],
        "procedure": "OP",
        "provider": "SHARP HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 613,
        "id": "recn22ZiG0QriZcKj",
        "row": 24280,
        "state": "",
        "group": "",
        "group_num": [
            "recpgPoNtZUPT3nQq"
        ],
        "procedure": "IOP",
        "provider": "SHARP HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recac1cMc1fkr55Tu",
        "row": 24279,
        "state": "",
        "group": "",
        "group_num": [
            "recpgPoNtZUPT3nQq"
        ],
        "procedure": "OP",
        "provider": "SHARP HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recrMomU5YmBDsVJi",
        "row": 24278,
        "state": "",
        "group": "",
        "group_num": [
            "recpgPoNtZUPT3nQq"
        ],
        "procedure": "RTC",
        "provider": "SHARP HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 672,
        "id": "reckbG2hJEM6RMmJf",
        "row": 24282,
        "state": "",
        "group": "",
        "group_num": [
            "recl7XA2zJmuevBZn"
        ],
        "procedure": "IOP",
        "provider": "SHASTA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 850,
        "id": "recZ7txmkGfROfSOG",
        "row": 24284,
        "state": "",
        "group": "",
        "group_num": [
            "recSCXxRJl8PSIUIQ"
        ],
        "procedure": "PHP",
        "provider": "SHASTA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recwW8s37zoSRSruB",
        "row": 24285,
        "state": "",
        "group": "",
        "group_num": [
            "recSCXxRJl8PSIUIQ"
        ],
        "procedure": "IOP",
        "provider": "SHASTA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "id": "recBcgRdxKfgvb030",
        "row": 24283,
        "state": "",
        "group": "",
        "group_num": [
            "recSCXxRJl8PSIUIQ"
        ],
        "procedure": "RTC",
        "provider": "SHASTA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 700,
        "id": "recg1aK2xvThOtLG6",
        "row": 24289,
        "state": "",
        "group": "",
        "group_num": [
            "rec9oHXJFAFtzoCv6"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recP5UfoKgTflmCiU",
        "row": 24290,
        "state": "",
        "group": "",
        "group_num": [
            "reckakhzS6DEQCo7J"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 194,
        "id": "recYB2Ljfpu1TpaBC",
        "row": 24287,
        "state": "",
        "group": "",
        "group_num": [
            "recbnGh1QMwJlFkrI"
        ],
        "procedure": "IOP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recZcLORORcbtsioN",
        "row": 24286,
        "state": "",
        "group": "",
        "group_num": [
            "rec3bBZirnSWUbGGw"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "rec0QLERvPOI9wWJg",
        "row": 24296,
        "state": "",
        "group": "",
        "group_num": [
            "recZSwxkRZWQsBZrl"
        ],
        "procedure": "IOP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1159,
        "id": "rec6Z4WONdkzQYeFd",
        "row": 24295,
        "state": "",
        "group": "",
        "group_num": [
            "recXXWVxUwRKvp5yK"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "rec9KX0WXfKmnuUA4",
        "row": 24293,
        "state": "",
        "group": "",
        "group_num": [
            "recxUiXCIdX9FCyFs"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 480,
        "id": "recdiZn0qsiVbi9sF",
        "row": 24291,
        "state": "",
        "group": "",
        "group_num": [
            "rec3MmuHJi8iUzJSw"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recoJbNDfVBqW9utA",
        "row": 24297,
        "state": "",
        "group": "",
        "group_num": [
            "recig69fr05kzMIru"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recpOXx55F7UnnKHf",
        "row": 24300,
        "state": "",
        "group": "",
        "group_num": [
            "recdALEXSryBh65P5"
        ],
        "procedure": "IOP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 325,
        "id": "recrV33wYXZZg2blG",
        "row": 24294,
        "state": "",
        "group": "",
        "group_num": [
            "recOJCNqpsNjo7gQx"
        ],
        "procedure": "IOP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "recxWo1Jt8RfNSOLJ",
        "row": 24292,
        "state": "",
        "group": "",
        "group_num": [
            "rec3MmuHJi8iUzJSw"
        ],
        "procedure": "PHP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "reczWy97w6AHSUF61",
        "row": 24298,
        "state": "",
        "group": "",
        "group_num": [
            "recTqoiCkatN5I0mL"
        ],
        "procedure": "RTC",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 735,
        "id": "recDWn9JTs4f7uV9m",
        "row": 24299,
        "state": "",
        "group": "",
        "group_num": [
            "recTqoiCkatN5I0mL"
        ],
        "procedure": "PHP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 725,
        "id": "recNIktwXIFCXxSy4",
        "row": 24288,
        "state": "",
        "group": "",
        "group_num": [
            "recdiRtMsT3Md0fOd"
        ],
        "procedure": "PHP",
        "provider": "SIERRA HEALTH SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1212,
        "id": "recmBuXZ5KFmDsbKZ",
        "row": 24301,
        "state": "",
        "group": "",
        "group_num": [
            "recrJFB2SvmCHDMTm"
        ],
        "procedure": "Detox",
        "provider": "SISCO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "id": "recfWFIaEaCMAbQCC",
        "row": 24305,
        "state": "",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1715,
        "2023": 1715,
        "id": "recj9TpGY4wPrD0Sr",
        "row": 24304,
        "state": "",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "RTC",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2167,
        "2023": 2167,
        "id": "reclndLU4yXYkWXAm",
        "row": 24306,
        "state": "",
        "group": "200340",
        "group_num": [
            "recvF56X9Fqbmorfn"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1463,
        "2023": 159,
        "id": "rec7IqVyb9vylDeiy",
        "row": 24302,
        "state": "",
        "group": "340",
        "group_num": [
            "recWlG46O5l5gsGQD"
        ],
        "procedure": "PHP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "2023": 500,
        "id": "reccwYA5v69HTIuWT",
        "row": 24303,
        "state": "",
        "group": "340",
        "group_num": [
            "recWlG46O5l5gsGQD"
        ],
        "procedure": "IOP",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1356,
        "id": "recgnuxQrZZbXuAEy",
        "row": 24310,
        "state": "",
        "group": "YCT001",
        "group_num": [
            "recFlhFmS10xalCZ0"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recb76zoG6P6vVixG",
        "row": 24307,
        "state": "",
        "group": "2003410",
        "group_num": [
            "recd1OvxHPJsyPwZB"
        ],
        "procedure": "RTC",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "reczLdEX3anfZnCn3",
        "row": 24308,
        "state": "",
        "group": "2003410",
        "group_num": [
            "recd1OvxHPJsyPwZB"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 502,
        "id": "reczNn1uKfnBiecNR",
        "row": 24309,
        "state": "",
        "group": "GHC001",
        "group_num": [
            "recxWvGKeUc4qiyhg"
        ],
        "procedure": "Detox",
        "provider": "SUMMIT HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 718,
        "id": "recgTVwy18qmWfFvp",
        "row": 24311,
        "state": "",
        "group": "",
        "group_num": [
            "reckua1eptxPH8wcu"
        ],
        "procedure": "PHP",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 683,
        "id": "recPdB7jKStCGaGQK",
        "row": 24312,
        "state": "",
        "group": "",
        "group_num": [
            "reckua1eptxPH8wcu"
        ],
        "procedure": "IOP",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2065,
        "id": "recSJx07bocmTRMvv",
        "row": 24320,
        "state": "",
        "group": "",
        "group_num": [
            "reciktrXNakYAXtUE"
        ],
        "procedure": "IOP",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recWTDu1CFZFuM0Ml",
        "row": 24317,
        "state": "",
        "group": "",
        "group_num": [
            "recnTkKTChXCcndUM"
        ],
        "procedure": "Detox",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3800,
        "id": "recWpSE2zYhpCaIN1",
        "row": 24316,
        "state": "",
        "group": "",
        "group_num": [
            "recnTkKTChXCcndUM"
        ],
        "procedure": "RTC",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5800,
        "2024": 4800,
        "id": "rec3sOh2BYLFiXm0v",
        "row": 24315,
        "state": "",
        "group": "",
        "group_num": [
            "rec2i70U6LVhbnaT5"
        ],
        "procedure": "Detox",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4950,
        "id": "recqKixxc10IyDkCW",
        "row": 24319,
        "state": "",
        "group": "",
        "group_num": [
            "recoU6xRQcIptjWot"
        ],
        "procedure": "Detox",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4724,
        "id": "rectq0jfrJwPoGRGA",
        "row": 24313,
        "state": "",
        "group": "",
        "group_num": [
            "recUbR2MRu9eQoAup"
        ],
        "procedure": "Detox",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recuNhUpnXWUzrm51",
        "row": 24318,
        "state": "",
        "group": "",
        "group_num": [
            "rec9zEwVmFxX4v3eW"
        ],
        "procedure": "Detox",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recvDJXInaSxlpzTm",
        "row": 24321,
        "state": "",
        "group": "",
        "group_num": [
            "reciktrXNakYAXtUE"
        ],
        "procedure": "Detox",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3919,
        "id": "recD9vtGvEOFu3cun",
        "row": 24314,
        "state": "",
        "group": "",
        "group_num": [
            "rec2i70U6LVhbnaT5"
        ],
        "procedure": "RTC",
        "provider": "SUREST HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "id": "rec5L5RLU56UGTlPx",
        "row": 24324,
        "state": "",
        "group": "",
        "group_num": [
            "recWagVtIm5O6ULeK"
        ],
        "procedure": "PHP",
        "provider": "TALL TREE ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 285,
        "id": "recB9WppkKeGl5gBE",
        "row": 24323,
        "state": "",
        "group": "",
        "group_num": [
            "rec6jNtxKoYJTtGzG"
        ],
        "procedure": "PHP",
        "provider": "TALL TREE ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recHkNtTzRDjCHerW",
        "row": 24322,
        "state": "",
        "group": "SFITS",
        "group_num": [
            "recspfg9JoW1dsQ8a"
        ],
        "procedure": "Detox",
        "provider": "TALL TREE ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 138,
        "id": "recNBJxReLYEN1p52",
        "row": 24325,
        "state": "",
        "group": "",
        "group_num": [
            "recWagVtIm5O6ULeK"
        ],
        "procedure": "IOP",
        "provider": "TALL TREE ADMINISTRATORS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "id": "recQsIAdPDYMz8Fog",
        "row": 24326,
        "state": "",
        "group": "",
        "group_num": [
            "recPXnDIKzmh6lOSn"
        ],
        "procedure": "RTC",
        "provider": "TEAMSTERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 914,
        "id": "recyanGo6d5M1bkwV",
        "row": 24327,
        "state": "",
        "group": "",
        "group_num": [
            "rechOS5RdTgGqo9G2"
        ],
        "procedure": "Detox",
        "provider": "TEAMSTERS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 689,
        "id": "recWK0PnHTLv0XdYl",
        "row": 24328,
        "state": "",
        "group": "",
        "group_num": [
            "recgwJglupKw6M7LK"
        ],
        "procedure": "RTC",
        "provider": "THE HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "rec6M84MQwf8KhW0p",
        "row": 24329,
        "state": "",
        "group": "",
        "group_num": [
            "recgwJglupKw6M7LK"
        ],
        "procedure": "PHP",
        "provider": "THE HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 425,
        "id": "recsdzXSDuFhIcQWV",
        "row": 24330,
        "state": "",
        "group": "",
        "group_num": [
            "recgwJglupKw6M7LK"
        ],
        "procedure": "IOP",
        "provider": "THE HEALTH PLAN"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 791,
        "id": "recybxYxc1OI0kwv3",
        "row": 24331,
        "state": "",
        "group": "",
        "group_num": [
            "rec9Rw3D7lxfNQxOT"
        ],
        "procedure": "RTC",
        "provider": "TPSC INST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1150,
        "id": "reclD64jw50vc0OMe",
        "row": 24332,
        "state": "",
        "group": "",
        "group_num": [
            "recEatgC6conZqDgQ"
        ],
        "procedure": "Detox",
        "provider": "TRANSWESTERN/HEALTH COMP"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3145,
        "id": "recjhjSVO2YaHvHGe",
        "row": 24333,
        "state": "",
        "group": "NAVJO",
        "group_num": [
            "rec71NcuHlCz2fI4j"
        ],
        "procedure": "RTC",
        "provider": "TRIBADO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1960,
        "id": "rec6voXiUrO40XA05",
        "row": 24334,
        "state": "",
        "group": "NAVJO",
        "group_num": [
            "rec71NcuHlCz2fI4j"
        ],
        "procedure": "Detox",
        "provider": "TRIBADO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 339,
        "2024": 339,
        "id": "recQJmBFmRWcUk9b4",
        "row": 24335,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "IOP",
        "provider": "TRICARE EAST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 459,
        "id": "reckBduz0DGoLPtqo",
        "row": 24336,
        "state": "",
        "group": "A",
        "group_num": [
            "recJ7iEUPailWvkSs"
        ],
        "procedure": "PHP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 348,
        "2023": 360,
        "id": "recPo19GxZ91hPtT6",
        "row": 24337,
        "state": "",
        "group": "A",
        "group_num": [
            "recJ7iEUPailWvkSs"
        ],
        "procedure": "IOP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 461,
        "id": "recQadOGECaFSflQB",
        "row": 24339,
        "state": "",
        "group": "",
        "group_num": [
            "recFWY9NL0gSLeMfH"
        ],
        "procedure": "PHP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 346,
        "2024": 372,
        "id": "recTKnfazJmrbvjEr",
        "row": 24338,
        "state": "",
        "group": "GROUPA",
        "group_num": [
            "recGVgddCoQFlyqe6"
        ],
        "procedure": "IOP",
        "provider": "TRICARE WEST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1856,
        "id": "recRm4cmbUik3jXpl",
        "row": 24340,
        "state": "",
        "group": "",
        "group_num": [
            "reclpVg1TlSevXzan"
        ],
        "procedure": "RTC",
        "provider": "TRUSTMARK HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 305,
        "id": "recbEcSuMwI7cAZd1",
        "row": 24341,
        "state": "",
        "group": "",
        "group_num": [
            "rec8OhS1ZPatWPX4u"
        ],
        "procedure": "PHP",
        "provider": "TRUSTMARK HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "id": "recJXb0zTLlSg8vpY",
        "row": 24342,
        "state": "",
        "group": "",
        "group_num": [
            "recEAuuoX7eWrDlg3"
        ],
        "procedure": "IOP",
        "provider": "TRUSTMARK HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 717,
        "id": "recnTXcBAho9pzgb8",
        "row": 24349,
        "state": "",
        "group": "",
        "group_num": [
            "recCGCHovX17aq29w"
        ],
        "procedure": "PHP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "2023": 2718,
        "id": "recQqtlqFEGs0xy9N",
        "row": 24346,
        "state": "",
        "group": "",
        "group_num": [
            "recf8HubMi0WKsf5H"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "rec1uVVheFrRKHop8",
        "row": 24355,
        "state": "",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "Detox",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "id": "rec1FTvy3Uksfudbm",
        "row": 24350,
        "state": "",
        "group": "",
        "group_num": [
            "recCGCHovX17aq29w"
        ],
        "procedure": "IOP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 818,
        "id": "rec3GNuO6C7mN8mnd",
        "row": 24347,
        "state": "",
        "group": "",
        "group_num": [
            "recf8HubMi0WKsf5H"
        ],
        "procedure": "PHP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2800,
        "id": "rec5Y8OIQGHFkdfeC",
        "row": 24353,
        "state": "",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 518,
        "id": "reca89WWaPjTmfHRs",
        "row": 24343,
        "state": "",
        "group": "48587",
        "group_num": [
            "recsAWYb9qF8CVU0n"
        ],
        "procedure": "OP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recowSzPBtq9lZRvV",
        "row": 24344,
        "state": "",
        "group": "48587",
        "group_num": [
            "recsAWYb9qF8CVU0n"
        ],
        "procedure": "IOP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2136,
        "id": "rectCjQQnXHf6qwYH",
        "row": 24356,
        "state": "",
        "group": "",
        "group_num": [
            "recrhzrWdnqPNoLmp"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recubcJZJGwMXyld8",
        "row": 24345,
        "state": "",
        "group": "",
        "group_num": [
            "rec8Lq4BFEvspfNtn"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2240,
        "2023": 2443,
        "id": "recv1Jusymgy9aPVm",
        "row": 24351,
        "state": "",
        "group": "45700000",
        "group_num": [
            "recuKhbFlUZwDqOlO"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 566,
        "id": "recF7k5xNw8iqmx1C",
        "row": 24354,
        "state": "",
        "group": "45750000",
        "group_num": [
            "reczggSHcPkLe7Y6M"
        ],
        "procedure": "PHP",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3217,
        "id": "recHmV6XB2YY3jiRK",
        "row": 24352,
        "state": "",
        "group": "45700000",
        "group_num": [
            "recuKhbFlUZwDqOlO"
        ],
        "procedure": "Detox",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3375,
        "id": "recN0cZrv5W93Y4US",
        "row": 24348,
        "state": "",
        "group": "",
        "group_num": [
            "recf8HubMi0WKsf5H"
        ],
        "procedure": "Detox",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "id": "recmKwsuGtYupxPrX",
        "row": 24358,
        "state": "",
        "group": "210520000",
        "group_num": [
            "rec8aHOueaJBBN3jN"
        ],
        "procedure": "RTC",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3125,
        "id": "recaFbakYNULQujvC",
        "row": 24357,
        "state": "",
        "group": "",
        "group_num": [
            "recrhzrWdnqPNoLmp"
        ],
        "procedure": "Detox",
        "provider": "TUFTS HEALTH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recd2gKS3F4nrsus1",
        "row": 24360,
        "state": "",
        "group": "",
        "group_num": [
            "recjYE4TV8F8LtcC3"
        ],
        "procedure": "Detox",
        "provider": "UHC - STUDENT RESOURCES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recxe29yCGvXAnpaQ",
        "row": 24359,
        "state": "",
        "group": "",
        "group_num": [
            "recjYE4TV8F8LtcC3"
        ],
        "procedure": "RTC",
        "provider": "UHC - STUDENT RESOURCES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1561,
        "id": "recVdmwdCkQLiF87u",
        "row": 24362,
        "state": "",
        "group": "",
        "group_num": [
            "recDKFr5RoWzQbu6I"
        ],
        "procedure": "Detox",
        "provider": "UHSS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2872,
        "id": "reccxVHdSfK7Z7IFX",
        "row": 24361,
        "state": "",
        "group": "",
        "group_num": [
            "recDKFr5RoWzQbu6I"
        ],
        "procedure": "RTC",
        "provider": "UHSS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recxGS5GGBacma2XF",
        "row": 24365,
        "state": "",
        "group": "",
        "group_num": [
            "rectdvwazSbsjgvby"
        ],
        "procedure": "Detox",
        "provider": "ULTRA BENEFITS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1150,
        "2024": 1077,
        "id": "recBX0a8LAWR6gJ7t",
        "row": 24363,
        "state": "",
        "group": "",
        "group_num": [
            "rectdvwazSbsjgvby"
        ],
        "procedure": "RTC",
        "provider": "ULTRA BENEFITS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 448,
        "2024": 418,
        "id": "recCcAkBqjVjvXZAW",
        "row": 24364,
        "state": "",
        "group": "",
        "group_num": [
            "rectdvwazSbsjgvby"
        ],
        "procedure": "PHP",
        "provider": "ULTRA BENEFITS INC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 209,
        "id": "recg3ZXyS8QYZIKIl",
        "row": 24386,
        "state": "",
        "group": "76070060",
        "group_num": [
            "recQwPzKsNn1RLMYR"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "id": "reciScSK0cvwVzq9I",
        "row": 24369,
        "state": "",
        "group": "7412547",
        "group_num": [
            "recZuVRabY0UB4KzD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "2023": 266,
        "id": "recim75aZ8yWdzjLY",
        "row": 24395,
        "state": "",
        "group": "",
        "group_num": [
            "receTohVMy06F9ky7"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "reckrhAtSuOJkoxX7",
        "row": 24393,
        "state": "",
        "group": "",
        "group_num": [
            "rec8QCwEpxXz0W8j5"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1650,
        "id": "reclGs5LELafOtNvZ",
        "row": 24400,
        "state": "",
        "group": "76410382",
        "group_num": [
            "rec8OrREodvmP8g0C"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 212,
        "id": "recnbWf25xiODkAKl",
        "row": 24379,
        "state": "",
        "group": "",
        "group_num": [
            "rec6hkEXpuO3GGdFC"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 526,
        "id": "recOkIr1qEXz2BSry",
        "row": 24398,
        "state": "",
        "group": "",
        "group_num": [
            "recJ9xR4RJYfNDDHA"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 220,
        "id": "recPtTtwCPQMS6YNY",
        "row": 24376,
        "state": "",
        "group": "",
        "group_num": [
            "recsOnXpTLbRMjyIQ"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1325,
        "2023": 1153,
        "id": "recRhBmxctjZpmbaR",
        "row": 24382,
        "state": "",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2880,
        "id": "recS9zvUTuXunOkzs",
        "row": 24391,
        "state": "",
        "group": "76070085",
        "group_num": [
            "rec1bH3K7BedYbSK9"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 250,
        "id": "recT3W6XnfTX9oyGe",
        "row": 24368,
        "state": "",
        "group": "",
        "group_num": [
            "recBkXUWixlMHINVU"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recVQ79W1kpsiLXYE",
        "row": 24394,
        "state": "",
        "group": "",
        "group_num": [
            "receTohVMy06F9ky7"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "id": "recYfKZwKGhyE0pQr",
        "row": 24377,
        "state": "",
        "group": "",
        "group_num": [
            "recsOnXpTLbRMjyIQ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 797,
        "id": "recZBFLP1GUVX1jfO",
        "row": 24366,
        "state": "",
        "group": "",
        "group_num": [
            "recBkXUWixlMHINVU"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recZLt78wmtf8pCQw",
        "row": 24396,
        "state": "",
        "group": "76410108",
        "group_num": [
            "recLShRoamTr76Ksg"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1440,
        "id": "rec1KE89BAsYrsMvX",
        "row": 24375,
        "state": "",
        "group": "",
        "group_num": [
            "recueS6L4JyXbHRz0"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2966,
        "2023": 3628,
        "id": "rec31jbRMmvV7elz7",
        "row": 24385,
        "state": "",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec5u9VnWwD4ZclRk",
        "row": 24406,
        "state": "",
        "group": "76410671",
        "group_num": [
            "recBV9jpFqIB5dzcv"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 322,
        "2024": 329,
        "id": "rec8M5y5K4Gn1g4XQ",
        "row": 24383,
        "state": "",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "rec922BXsLy1xcwnZ",
        "row": 24367,
        "state": "",
        "group": "",
        "group_num": [
            "recBkXUWixlMHINVU"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 209,
        "2023": 211,
        "id": "recpIHiXnoyXWHpgt",
        "row": 24403,
        "state": "",
        "group": "",
        "group_num": [
            "rec3WLs8S2JwsiyIP"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "id": "recsovPSILD9w2Mtq",
        "row": 24401,
        "state": "",
        "group": "76410531",
        "group_num": [
            "recC7kF3YtxMkjFMT"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 503,
        "id": "recumVTRjmh2ngUdQ",
        "row": 24370,
        "state": "",
        "group": "",
        "group_num": [
            "rec3F6q1aDejl2WuV"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2085,
        "id": "recySyqNmHjzyO8KV",
        "row": 24390,
        "state": "",
        "group": "76070061",
        "group_num": [
            "recPoBtfcxxgn3ecm"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 974,
        "id": "recyrF7Okv2Ixgc3M",
        "row": 24380,
        "state": "",
        "group": "",
        "group_num": [
            "recu93zK4TzinPDVu"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "2023": 401,
        "id": "recAt36GWcnayUFiK",
        "row": 24378,
        "state": "",
        "group": "",
        "group_num": [
            "rec6hkEXpuO3GGdFC"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "recCPJR2ZRXjsfW0t",
        "row": 24404,
        "state": "",
        "group": "",
        "group_num": [
            "rec3WLs8S2JwsiyIP"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recCRBWCYp3ARa5ZC",
        "row": 24402,
        "state": "",
        "group": "76410569",
        "group_num": [
            "recblkypnhewxPfw0"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 575,
        "id": "recCcCspTDh9cJS0L",
        "row": 24389,
        "state": "",
        "group": "76070061",
        "group_num": [
            "recPoBtfcxxgn3ecm"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recCoU83NfFS2ef4P",
        "row": 24387,
        "state": "",
        "group": "76070061",
        "group_num": [
            "recPoBtfcxxgn3ecm"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recFemQTHqnmxBMLw",
        "row": 24374,
        "state": "",
        "group": "",
        "group_num": [
            "recdTmcXU4vRPkYge"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1957,
        "id": "recGDvej3RQ6XV5yE",
        "row": 24372,
        "state": "",
        "group": "",
        "group_num": [
            "recdTmcXU4vRPkYge"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1767,
        "2023": 1440,
        "id": "recGMb8xX4KocpnzY",
        "row": 24381,
        "state": "",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 1349,
        "2024": 226,
        "id": "recKbNgrkodo7TRGI",
        "row": 24384,
        "state": "",
        "group": "76070058",
        "group_num": [
            "recai5vU3YgBGO0SJ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1762,
        "id": "recKqUBR6nios8grn",
        "row": 24399,
        "state": "",
        "group": "76410382",
        "group_num": [
            "rec8OrREodvmP8g0C"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recLYkwqzjo5ANntk",
        "row": 24397,
        "state": "",
        "group": "76410108",
        "group_num": [
            "recLShRoamTr76Ksg"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "id": "recL9i3XRfEMdbZu1",
        "row": 24371,
        "state": "",
        "group": "76030038",
        "group_num": [
            "recMNdzBMyP2POHEB"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 52,
        "id": "recMeBGtq7SuOoUks",
        "row": 24388,
        "state": "",
        "group": "76070061",
        "group_num": [
            "recPoBtfcxxgn3ecm"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 210,
        "2023": 211,
        "id": "recNDe47yeAoEiUqF",
        "row": 24405,
        "state": "",
        "group": "",
        "group_num": [
            "rec3WLs8S2JwsiyIP"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1999,
        "id": "recNdsf2F69markRg",
        "row": 24392,
        "state": "",
        "group": "",
        "group_num": [
            "recHodxzy1wiNJyHw"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 444,
        "id": "recNlYP91MxmQ2uxj",
        "row": 24373,
        "state": "",
        "group": "",
        "group_num": [
            "recdTmcXU4vRPkYge"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "2023": 3000,
        "id": "receqbjZeo4VYhsfs",
        "row": 24449,
        "state": "",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recfxUqmlaAFjqnLr",
        "row": 24434,
        "state": "",
        "group": "",
        "group_num": [
            "recmXbQVSnsBqeYMC"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1543,
        "id": "rechxpbsUtbsogO39",
        "row": 24427,
        "state": "",
        "group": "76411289",
        "group_num": [
            "rec8wQTDSZezyyvXj"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rechHNfcWaq3msVDt",
        "row": 24438,
        "state": "",
        "group": "76411481",
        "group_num": [
            "recE6njBkJuqNOGhv"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2410,
        "id": "rechf6h1g7kYL1Cv2",
        "row": 24431,
        "state": "",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "id": "reci5rjQnTuJakk9c",
        "row": 24447,
        "state": "",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2223,
        "2023": 1895,
        "id": "reckjdcLvmBwwhUjR",
        "row": 24423,
        "state": "",
        "group": "76411049",
        "group_num": [
            "recqrfDrWWxmuT44M"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 108,
        "id": "recm9hk5NBMsQlRua",
        "row": 24432,
        "state": "",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recPNs0kfk5sWpVDX",
        "row": 24429,
        "state": "",
        "group": "",
        "group_num": [
            "recqLWJR8OdiQW3zy"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recRLqrhtddDoVfzC",
        "row": 24413,
        "state": "",
        "group": "",
        "group_num": [
            "reclVNK4pIpRPoBrj"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1884,
        "2024": 3159,
        "id": "recRL4NYxMQUxGqQS",
        "row": 24433,
        "state": "",
        "group": "76411406",
        "group_num": [
            "recrKtufK2zYDwyNZ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recUs48KHMdyuSB35",
        "row": 24412,
        "state": "",
        "group": "",
        "group_num": [
            "reclVNK4pIpRPoBrj"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 128,
        "2023": 113,
        "2024": 59,
        "id": "recUw8JevpgWGSYpk",
        "row": 24435,
        "state": "",
        "group": "76411449",
        "group_num": [
            "recIFHFnhpXznbVO3"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 297,
        "2024": 310,
        "id": "recUHxHRX1ZdvxURy",
        "row": 24424,
        "state": "",
        "group": "76411049",
        "group_num": [
            "recqrfDrWWxmuT44M"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1238,
        "2023": 1200,
        "2024": 938,
        "id": "recVGPeivEZE9yLvP",
        "row": 24411,
        "state": "",
        "group": "",
        "group_num": [
            "rec9CRn9cLFG6BMyL"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1503,
        "2023": 1515,
        "id": "recWkUanktwTXgqNK",
        "row": 24425,
        "state": "",
        "group": "76411049",
        "group_num": [
            "recqrfDrWWxmuT44M"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1353,
        "2023": 1964,
        "id": "recWogZo3afshxX33",
        "row": 24443,
        "state": "",
        "group": "76411627",
        "group_num": [
            "recstVQ5hKBkDvgnN"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2996,
        "id": "recZFwjl8QLjXj1OZ",
        "row": 24439,
        "state": "",
        "group": "76411481",
        "group_num": [
            "recE6njBkJuqNOGhv"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 211,
        "id": "rec1toOFsCHMSaWS7",
        "row": 24419,
        "state": "",
        "group": "",
        "group_num": [
            "rec6uEGKfPKUZA20E"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2186,
        "id": "rec1vvZpj94nT0iHr",
        "row": 24414,
        "state": "",
        "group": "",
        "group_num": [
            "recTnFBU2ysdd5zGU"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1150,
        "id": "rec2PnE5LdD1EStJi",
        "row": 24441,
        "state": "",
        "group": "",
        "group_num": [
            "rec83OSELO2QJusxN"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 68,
        "id": "rec4sbhF4YKMSx2Mw",
        "row": 24420,
        "state": "",
        "group": "",
        "group_num": [
            "rec6uEGKfPKUZA20E"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3360,
        "id": "rec4sLSQ7C43jg7nW",
        "row": 24442,
        "state": "",
        "group": "76411550",
        "group_num": [
            "recAyiyyXinsGHbPL"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "rec4yJSjJiHnYk4jl",
        "row": 24417,
        "state": "",
        "group": "76410922",
        "group_num": [
            "recFon01a5UHSnzjm"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "rec53JEgC6GJq5kYY",
        "row": 24444,
        "state": "",
        "group": "76411650",
        "group_num": [
            "recN83gVtsH5OkwlR"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "id": "rec8k1yewd4OS9OIE",
        "row": 24450,
        "state": "",
        "group": "76411747",
        "group_num": [
            "recibtjxpprBSyvtB"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "id": "rec9zs08IpLd3E9HC",
        "row": 24415,
        "state": "",
        "group": "",
        "group_num": [
            "recMYWVuaLTUT83JQ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2035,
        "id": "recbdN9Y6Xte0oGAN",
        "row": 24428,
        "state": "",
        "group": "76411289",
        "group_num": [
            "rec8wQTDSZezyyvXj"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2660,
        "2024": 2660,
        "id": "recd27qrpbDbHOWPU",
        "row": 24452,
        "state": "",
        "group": "",
        "group_num": [
            "recidEKDTAxl2801p"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3055,
        "id": "recoSeQFYXBKt5e7v",
        "row": 24454,
        "state": "",
        "group": "76411829",
        "group_num": [
            "recfBgJzTIarmnMHD"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recoE5kvdVuiIJ5sG",
        "row": 24416,
        "state": "",
        "group": "76410922",
        "group_num": [
            "recFon01a5UHSnzjm"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "recoP2LVRKFhLekPP",
        "row": 24437,
        "state": "",
        "group": "76411465",
        "group_num": [
            "rec9bKLFLmos6Dt5U"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3364,
        "id": "recqgCOw8psWWPXjD",
        "row": 24455,
        "state": "",
        "group": "76411829",
        "group_num": [
            "recfBgJzTIarmnMHD"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 211,
        "id": "recuaYJuXPiY2qvcE",
        "row": 24421,
        "state": "",
        "group": "",
        "group_num": [
            "rec6uEGKfPKUZA20E"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 774,
        "2024": 355,
        "id": "recugFW1FSQrUX2BQ",
        "row": 24430,
        "state": "",
        "group": "",
        "group_num": [
            "recqLWJR8OdiQW3zy"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2438,
        "id": "recvuy8YqgjvCKsLK",
        "row": 24445,
        "state": "",
        "group": "76411658",
        "group_num": [
            "recrEia0fqXAQdbB5"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1542,
        "id": "recvQ1nkqtLttUgVf",
        "row": 24440,
        "state": "",
        "group": "",
        "group_num": [
            "recA3Stdib4BwvmCM"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recxe8jYdKoYYU2Fn",
        "row": 24426,
        "state": "",
        "group": "",
        "group_num": [
            "recjOXZ0FKW96lWeN"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "id": "reczBwm8P297QGcjq",
        "row": 24448,
        "state": "",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "id": "recBWwWyA18AKYpWB",
        "row": 24408,
        "state": "",
        "group": "76410761",
        "group_num": [
            "recWZTP39YuUNMyCW"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3800,
        "id": "recCSjIur2LN7CPkM",
        "row": 24446,
        "state": "",
        "group": "76411714",
        "group_num": [
            "recwpcXBM0XCbcdwm"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 2750,
        "id": "recEVC5fCfALEZ96d",
        "row": 24456,
        "state": "",
        "group": "76411829",
        "group_num": [
            "recfBgJzTIarmnMHD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2204,
        "id": "recJuaP8UAKN3jhFj",
        "row": 24451,
        "state": "",
        "group": "",
        "group_num": [
            "recdUuOsEAmSqYoWs"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1088,
        "id": "recJAN0dJ5e6mXBk1",
        "row": 24409,
        "state": "",
        "group": "76410761",
        "group_num": [
            "recWZTP39YuUNMyCW"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recJ9cHvdCPqPiVKK",
        "row": 24418,
        "state": "",
        "group": "76410922",
        "group_num": [
            "recFon01a5UHSnzjm"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1132,
        "2023": 750,
        "id": "recLK8gf2spOFxTmc",
        "row": 24436,
        "state": "",
        "group": "76411449",
        "group_num": [
            "recIFHFnhpXznbVO3"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1560,
        "id": "recMTzREzf9rWoOzy",
        "row": 24453,
        "state": "",
        "group": "",
        "group_num": [
            "recwtYuo9RUNF2o3K"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 280,
        "id": "recMDypbXPi6044xY",
        "row": 24422,
        "state": "",
        "group": "",
        "group_num": [
            "recjhQh6j580uT2lS"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2747,
        "id": "recNNTN3QUUT6p6K6",
        "row": 24410,
        "state": "",
        "group": "76410761",
        "group_num": [
            "recWZTP39YuUNMyCW"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2747,
        "2023": 2151,
        "id": "recN2qP8Z9UTONHQn",
        "row": 24407,
        "state": "",
        "group": "76410761",
        "group_num": [
            "recWZTP39YuUNMyCW"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 675,
        "id": "receExJIbrRpUFVcw",
        "row": 24457,
        "state": "",
        "group": "",
        "group_num": [
            "receyVy2UBsD0oTwE"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1523,
        "id": "rececg3f7HVWWuY7L",
        "row": 24475,
        "state": "",
        "group": "76412388",
        "group_num": [
            "recn5KFgqn1goPucr"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1503,
        "2023": 1503,
        "id": "receqIptGLPa9ceGr",
        "row": 24499,
        "state": "",
        "group": "",
        "group_num": [
            "recWLcDBx2P90HJ1k"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1096,
        "2023": 1582,
        "id": "recfA8r5nkda2TEPC",
        "row": 24470,
        "state": "",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 630,
        "2023": 613,
        "id": "recg2MRyUl0JD6KFX",
        "row": 24482,
        "state": "",
        "group": "",
        "group_num": [
            "recODX81dsHpwrqiF"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recgkEVVqH2HlmB41",
        "row": 24468,
        "state": "",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "recha4UXCwc4Qbd8Y",
        "row": 24497,
        "state": "",
        "group": "",
        "group_num": [
            "recWLcDBx2P90HJ1k"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1184,
        "id": "recjpVGL6p1h4Ddk8",
        "row": 24485,
        "state": "",
        "group": "",
        "group_num": [
            "rec4BrxJqOfcfv4Wu"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1615,
        "id": "reclZnnTlIi1u1A2f",
        "row": 24489,
        "state": "",
        "group": "76412652",
        "group_num": [
            "recqKSlWZsjVj73bG"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recnZ37BTxAPkcu77",
        "row": 24460,
        "state": "",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recOvjd5J6xGhyoey",
        "row": 24480,
        "state": "",
        "group": "76412526",
        "group_num": [
            "recWAMUSdSSv6XbMI"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "2023": 3000,
        "id": "recOrjB1t2bEyBujP",
        "row": 24471,
        "state": "",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 835,
        "id": "recSX3eBnOsHRSM7w",
        "row": 24486,
        "state": "",
        "group": "",
        "group_num": [
            "recDHnifR0p2SyroT"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1305,
        "2024": 855,
        "id": "recTLCDSBx0lhRfB7",
        "row": 24506,
        "state": "",
        "group": "76412955",
        "group_num": [
            "reclrf7iKatfZULFp"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3720,
        "id": "recV1zSaPYBHlBJIf",
        "row": 24476,
        "state": "",
        "group": "76412431",
        "group_num": [
            "recTumNUZEomaix9C"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recW01YQnx2W5agdn",
        "row": 24474,
        "state": "",
        "group": "76412271",
        "group_num": [
            "recJTqRID1MEYRTTa"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2666,
        "id": "recXBsJwei6LWyOSu",
        "row": 24463,
        "state": "",
        "group": "76411925",
        "group_num": [
            "recDUtgIv1ejw4MwF"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "2024": 310,
        "id": "recYy4kJDQLL5gvAS",
        "row": 24498,
        "state": "",
        "group": "",
        "group_num": [
            "recWLcDBx2P90HJ1k"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 731,
        "id": "rec0AXScu8sPSH2pD",
        "row": 24487,
        "state": "",
        "group": "",
        "group_num": [
            "recDHnifR0p2SyroT"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3118,
        "id": "rec06uqtiAbQ26mWu",
        "row": 24504,
        "state": "",
        "group": "",
        "group_num": [
            "recg8LlvwqCEnyXHM"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2979,
        "id": "rec1rv72XqexV4Wjz",
        "row": 24501,
        "state": "",
        "group": "",
        "group_num": [
            "recB7ajrYH1b5scxx"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "rec247J94OsKPikia",
        "row": 24473,
        "state": "",
        "group": "",
        "group_num": [
            "recEOgduqZyCng5O0"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 980,
        "id": "rec4HHe6XcM5nlK0o",
        "row": 24481,
        "state": "",
        "group": "",
        "group_num": [
            "recODX81dsHpwrqiF"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 218,
        "id": "rec4l0xrnvq2q8W0i",
        "row": 24494,
        "state": "",
        "group": "",
        "group_num": [
            "rec3i35gF43yLAeV8"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1820,
        "id": "rec5CgO8X26hFHVYV",
        "row": 24484,
        "state": "",
        "group": "",
        "group_num": [
            "rec4BrxJqOfcfv4Wu"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2350,
        "2024": 2350,
        "id": "rec6yDdSrNnVEgrKS",
        "row": 24479,
        "state": "",
        "group": "76412526",
        "group_num": [
            "recWAMUSdSSv6XbMI"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "rec62hoY075AsAMRN",
        "row": 24464,
        "state": "",
        "group": "76412015",
        "group_num": [
            "recEYJdJnlfo4X5Nu"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec9LrrFSwZww5F75",
        "row": 24503,
        "state": "",
        "group": "76412885",
        "group_num": [
            "recdLc1XqzgHLWrq1"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "id": "rec9iYcf3iau4k5Rg",
        "row": 24491,
        "state": "",
        "group": "",
        "group_num": [
            "recxVjndJ6rieZ49c"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2146,
        "id": "recbcB6DYJVjAGeEO",
        "row": 24458,
        "state": "",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1159,
        "id": "recdPbKxiTUJSvcXn",
        "row": 24496,
        "state": "",
        "group": "",
        "group_num": [
            "recyyVdkEHNRF2Cud"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 195,
        "2023": 195,
        "id": "recojWDhbH7a6W48W",
        "row": 24490,
        "state": "",
        "group": "76412652",
        "group_num": [
            "recqKSlWZsjVj73bG"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 58,
        "id": "recpVjbt8y6osjBer",
        "row": 24492,
        "state": "",
        "group": "",
        "group_num": [
            "recxVjndJ6rieZ49c"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 240,
        "id": "recpOIbJgwBlJQMMf",
        "row": 24495,
        "state": "",
        "group": "",
        "group_num": [
            "rec3i35gF43yLAeV8"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "id": "recqm7C7JADFuPImr",
        "row": 24462,
        "state": "",
        "group": "76411920",
        "group_num": [
            "recDVtNVLMw5AkF58"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2747,
        "id": "recrEixEfvi2Oot1Q",
        "row": 24488,
        "state": "",
        "group": "",
        "group_num": [
            "rec1rMdlqyv50gixf"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 96,
        "id": "rectkuQyUsWGOAxL4",
        "row": 24469,
        "state": "",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1312,
        "id": "recyQNeKmnkfFw2oB",
        "row": 24459,
        "state": "",
        "group": "76411912",
        "group_num": [
            "recPvLlW9uZDAsxlH"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2456,
        "id": "reczYAwot84JCnfx9",
        "row": 24505,
        "state": "",
        "group": "",
        "group_num": [
            "recg8LlvwqCEnyXHM"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "recGIihhdCNWUmZBi",
        "row": 24466,
        "state": "",
        "group": "76412023",
        "group_num": [
            "recyiVYcpDokRBXkl"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1840,
        "id": "recIO5yK0spt1HytQ",
        "row": 24478,
        "state": "",
        "group": "76412505",
        "group_num": [
            "recrFJaduPKagxgHL"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "2023": 2416,
        "id": "recIqq4dpGN1LycFw",
        "row": 24461,
        "state": "",
        "group": "76411920",
        "group_num": [
            "recDVtNVLMw5AkF58"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2470,
        "id": "recKXwNlewlNbw0vd",
        "row": 24483,
        "state": "",
        "group": "",
        "group_num": [
            "rec4BrxJqOfcfv4Wu"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1440,
        "id": "recK1I9FSClpXrJkr",
        "row": 24493,
        "state": "",
        "group": "",
        "group_num": [
            "recxVjndJ6rieZ49c"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "id": "recKwnAX8J4Oyo9Ny",
        "row": 24465,
        "state": "",
        "group": "76412015",
        "group_num": [
            "recEYJdJnlfo4X5Nu"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1887,
        "2023": 2375,
        "id": "recKAUZ2auvUOIR2D",
        "row": 24467,
        "state": "",
        "group": "76412150",
        "group_num": [
            "recMMSFaxjca4ApVz"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4800,
        "2023": 2051,
        "id": "recKJZVnpQLgtCCtY",
        "row": 24477,
        "state": "",
        "group": "76412447",
        "group_num": [
            "recUyTxgPBDXSLD2X"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1698,
        "id": "recKbfUApc2qLLZ7J",
        "row": 24472,
        "state": "",
        "group": "",
        "group_num": [
            "recEOgduqZyCng5O0"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2185,
        "id": "recMp84k9st0pVpq3",
        "row": 24500,
        "state": "",
        "group": "",
        "group_num": [
            "recBSbDzfAxI807mk"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recNNaWhl9c9jFWyS",
        "row": 24502,
        "state": "",
        "group": "76412885",
        "group_num": [
            "recdLc1XqzgHLWrq1"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1279,
        "2023": 1417,
        "id": "recfTF0SYLEA3lDLE",
        "row": 24542,
        "state": "",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "recffpgZUVe6Bdl1h",
        "row": 24516,
        "state": "",
        "group": "76413116",
        "group_num": [
            "recmqGxpb1QFrIS2Z"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 562,
        "id": "recfriOKy8uJroNcY",
        "row": 24511,
        "state": "",
        "group": "",
        "group_num": [
            "recVCuNM3F2lMZMq7"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "rechfPDZ7anwfFUrx",
        "row": 24526,
        "state": "",
        "group": "76413389",
        "group_num": [
            "recdeMvGC3AHcwEZR"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1910,
        "id": "recjUeAugFlgYtvUB",
        "row": 24507,
        "state": "",
        "group": "",
        "group_num": [
            "recc5bvhDRJyVnbXn"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recjyXw0g9u4l2o1B",
        "row": 24532,
        "state": "",
        "group": "76413489",
        "group_num": [
            "recw4yftNbkrcocKx"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 37,
        "id": "recPXGUPe5BuVY4VU",
        "row": 24544,
        "state": "",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "recRvXe0dXkgSPH2V",
        "row": 24554,
        "state": "",
        "group": "",
        "group_num": [
            "recIBtfAHLrLXuNqb"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 229,
        "2023": 301,
        "id": "recUwkOMFTp3n0wik",
        "row": 24510,
        "state": "",
        "group": "",
        "group_num": [
            "recVCuNM3F2lMZMq7"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recVsknntAgcbRwXl",
        "row": 24539,
        "state": "",
        "group": "76413548",
        "group_num": [
            "recQky9k8y70oLyYA"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "2023": 800,
        "id": "rec0CVJiyFZSt9pxl",
        "row": 24529,
        "state": "",
        "group": "76413489",
        "group_num": [
            "recw4yftNbkrcocKx"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2333,
        "id": "rec0enKClFIEkdgSH",
        "row": 24512,
        "state": "",
        "group": "",
        "group_num": [
            "recmqy8CXtCaUQuZw"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5100,
        "2023": 1355,
        "id": "rec2eP9Xdx1Azn2rx",
        "row": 24541,
        "state": "",
        "group": "76413549",
        "group_num": [
            "rec7KqctrvdkO4uOQ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2100,
        "id": "rec5IX9eAOfq1wBmq",
        "row": 24518,
        "state": "",
        "group": "",
        "group_num": [
            "recCqcIbbhNH4uD2R"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "2023": 39,
        "2024": 39,
        "id": "rec53RMSn5ry1zGKW",
        "row": 24535,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "id": "rec8QFNjVZFE1NEBg",
        "row": 24508,
        "state": "",
        "group": "",
        "group_num": [
            "recc5bvhDRJyVnbXn"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec9e8nNeUwj4yY8n",
        "row": 24547,
        "state": "",
        "group": "76413553",
        "group_num": [
            "recgPWdJ7EqhoT6vK"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recatPsYaQ6spQhYW",
        "row": 24525,
        "state": "",
        "group": "76413389",
        "group_num": [
            "recdeMvGC3AHcwEZR"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "recbwYS1I9u2Oxc3x",
        "row": 24549,
        "state": "",
        "group": "76413588",
        "group_num": [
            "recYp0o4cX4g4a3hl"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2224,
        "id": "recbx87p5Is20XMFJ",
        "row": 24548,
        "state": "",
        "group": "76413588",
        "group_num": [
            "recYp0o4cX4g4a3hl"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "2023": 266,
        "id": "recbpczIvxVKiS6ut",
        "row": 24553,
        "state": "",
        "group": "",
        "group_num": [
            "recIBtfAHLrLXuNqb"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3543,
        "id": "reco3qoDgxQ8INoXA",
        "row": 24540,
        "state": "",
        "group": "76413549",
        "group_num": [
            "rec7KqctrvdkO4uOQ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "recodEzEqYbbLOUse",
        "row": 24522,
        "state": "",
        "group": "76413268",
        "group_num": [
            "recD3S0t8eeHz9U2y"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 717,
        "2023": 1464,
        "id": "recofvJFghFmZ3SGt",
        "row": 24543,
        "state": "",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2000,
        "id": "recqD3M9usTSgtoeV",
        "row": 24550,
        "state": "",
        "group": "76413592",
        "group_num": [
            "recW2nDchefSHCWjb"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1492,
        "2023": 500,
        "id": "recqrSKIXxeJlv9MJ",
        "row": 24531,
        "state": "",
        "group": "76413489",
        "group_num": [
            "recw4yftNbkrcocKx"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "id": "recrJWnGwbgDyDytJ",
        "row": 24524,
        "state": "",
        "group": "",
        "group_num": [
            "recTpV0gZQfCsIxVH"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2304,
        "id": "recrgUwABRD8YILIP",
        "row": 24519,
        "state": "",
        "group": "76413249",
        "group_num": [
            "recLpJ4nSHhfwwYz8"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 562,
        "id": "rectBsIUqw070j3ZK",
        "row": 24509,
        "state": "",
        "group": "",
        "group_num": [
            "recVCuNM3F2lMZMq7"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "2024": 355,
        "id": "rectHL5OoWmjOMBMb",
        "row": 24513,
        "state": "",
        "group": "",
        "group_num": [
            "recVOOh1XDexg0gg1"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "recuEFC5zjhRGMLvi",
        "row": 24552,
        "state": "",
        "group": "",
        "group_num": [
            "recIBtfAHLrLXuNqb"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "2023": 686,
        "2024": 1905,
        "id": "recuohHhGHxJYgPO7",
        "row": 24533,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1079,
        "2024": 2175,
        "id": "recvRoFUa5pj9qWmZ",
        "row": 24536,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2177,
        "id": "recwMK6c5QtmgYz4k",
        "row": 24521,
        "state": "",
        "group": "76413268",
        "group_num": [
            "recD3S0t8eeHz9U2y"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "id": "recwp1jyG1xrzCQjf",
        "row": 24555,
        "state": "",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3375,
        "id": "recxuDjwfjD027kE1",
        "row": 24515,
        "state": "",
        "group": "76413116",
        "group_num": [
            "recmqGxpb1QFrIS2Z"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "reczvdhMN3npVhEOj",
        "row": 24520,
        "state": "",
        "group": "76413249",
        "group_num": [
            "recLpJ4nSHhfwwYz8"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3754,
        "2023": 1648,
        "id": "reczj1NhNd29x7vwY",
        "row": 24534,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2191,
        "2023": 2250,
        "id": "recBdST8DgyHJw4Bs",
        "row": 24530,
        "state": "",
        "group": "76413489",
        "group_num": [
            "recw4yftNbkrcocKx"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 3282,
        "id": "recBi5N9V1LDy2CCZ",
        "row": 24537,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "recD0IJytr9r0h0wv",
        "row": 24556,
        "state": "",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "2023": 554,
        "id": "recDu9Q0LMQ45bwzh",
        "row": 24545,
        "state": "",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2276,
        "id": "recDlrpwPeRS2ZPht",
        "row": 24546,
        "state": "",
        "group": "76413550",
        "group_num": [
            "recWbH3Z8zCg8vprD"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recENqEAn6e0qa1Uf",
        "row": 24551,
        "state": "",
        "group": "76413592",
        "group_num": [
            "recW2nDchefSHCWjb"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recEnlSBkCJi6EBlG",
        "row": 24517,
        "state": "",
        "group": "76413120",
        "group_num": [
            "recXVOeaoAiJtK72n"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1521,
        "id": "recHA9KLkGRyxjVfn",
        "row": 24523,
        "state": "",
        "group": "76413280",
        "group_num": [
            "recowaTauGvUYP8Bt"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2968,
        "id": "recIFbAzknqR1mgot",
        "row": 24514,
        "state": "",
        "group": "",
        "group_num": [
            "recKFecHmQzqKL2qH"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1942,
        "id": "recJIRcPzS8VLHD6J",
        "row": 24528,
        "state": "",
        "group": "",
        "group_num": [
            "recMQyWpBBldWZGHi"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 947,
        "2023": 2000,
        "id": "recLK1p0pwt0ACilr",
        "row": 24538,
        "state": "",
        "group": "",
        "group_num": [
            "recJawTBjePB0vEOR"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 594,
        "id": "recNLK2SVUhMiL0pR",
        "row": 24527,
        "state": "",
        "group": "",
        "group_num": [
            "rec8JDVeUzBirBOqx"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "rece52fdxBVAKDR2D",
        "row": 24590,
        "state": "",
        "group": "",
        "group_num": [
            "recJkNZLDaZL3wHrq"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1364,
        "2023": 1455,
        "id": "recfrSJsXlrDM4dpe",
        "row": 24602,
        "state": "",
        "group": "",
        "group_num": [
            "recN2SFc8kRMudKbw"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1190,
        "id": "recgXeGUtHjJzAs1h",
        "row": 24577,
        "state": "",
        "group": "",
        "group_num": [
            "recJRb4KAnve8q27M"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3003,
        "id": "recgKrTkKeSf13M8a",
        "row": 24560,
        "state": "",
        "group": "76413662",
        "group_num": [
            "recgB88dz1o9g5o9s"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3193,
        "2023": 2324,
        "id": "recg6CTOHNF21umBx",
        "row": 24564,
        "state": "",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1625,
        "2023": 1500,
        "id": "rech9odKrHCKl0Svc",
        "row": 24565,
        "state": "",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 78,
        "id": "recinG77zUnIWvs09",
        "row": 24569,
        "state": "",
        "group": "",
        "group_num": [
            "recYM3BZ06LWV9SjW"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1493,
        "2024": 1498,
        "id": "recjSWqJWmosOL0bg",
        "row": 24580,
        "state": "",
        "group": "",
        "group_num": [
            "rec3FerqJBGclQZb1"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2364,
        "id": "recllngyK8Y54Faaz",
        "row": 24575,
        "state": "",
        "group": "76413838",
        "group_num": [
            "recVynnm5GqXEZ1Py"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "2023": 475,
        "id": "recOypZp2N0gz8fuM",
        "row": 24587,
        "state": "",
        "group": "",
        "group_num": [
            "reca4kc50xmfJ0QKq"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "id": "recPZfBXTt9iVEkud",
        "row": 24599,
        "state": "",
        "group": "",
        "group_num": [
            "rec0fT9tiHUNIrpuF"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 3000,
        "id": "recPREAW2bRNK6hDc",
        "row": 24597,
        "state": "",
        "group": "76414072",
        "group_num": [
            "recVAtCoF1XF9ltsU"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1238,
        "id": "recP3NySnIE17N074",
        "row": 24600,
        "state": "",
        "group": "",
        "group_num": [
            "recPb6HFErHW3Kxlp"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "2023": 475,
        "id": "recQ7BBfHLyJ8a2Ci",
        "row": 24562,
        "state": "",
        "group": "",
        "group_num": [
            "recmtVOj3mMfFwb1L"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recS3szkJUBDWtd0E",
        "row": 24601,
        "state": "",
        "group": "",
        "group_num": [
            "recN2SFc8kRMudKbw"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1297,
        "id": "recTQxX1CvwEuKVa8",
        "row": 24578,
        "state": "",
        "group": "76413907",
        "group_num": [
            "rec6eckE4q4fNKNaa"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 690,
        "2024": 900,
        "id": "recU9G6SKlxE0SjMf",
        "row": 24589,
        "state": "",
        "group": "",
        "group_num": [
            "reca4kc50xmfJ0QKq"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 625,
        "2023": 326,
        "id": "rec0BOJZ9F9A9jCd7",
        "row": 24557,
        "state": "",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 47,
        "id": "rec09qWd16f22TFnN",
        "row": 24598,
        "state": "",
        "group": "",
        "group_num": [
            "rec0fT9tiHUNIrpuF"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec2BufPwzdKT2mze",
        "row": 24605,
        "state": "",
        "group": "",
        "group_num": [
            "reczbfBPPoAFhG1yf"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "rec3TBAzC6cn2tu96",
        "row": 24570,
        "state": "",
        "group": "",
        "group_num": [
            "recYM3BZ06LWV9SjW"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "2023": 2747,
        "id": "rec3mP7PjIQbHGInl",
        "row": 24579,
        "state": "",
        "group": "76413907",
        "group_num": [
            "rec6eckE4q4fNKNaa"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "rec4ezWOgP0pWZczO",
        "row": 24591,
        "state": "",
        "group": "",
        "group_num": [
            "recJkNZLDaZL3wHrq"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1414,
        "2023": 1507,
        "2024": 325,
        "id": "rec9Gvnz0PaPCi0vh",
        "row": 24567,
        "state": "",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recbKL7cfMoyYVpCF",
        "row": 24592,
        "state": "",
        "group": "76414047",
        "group_num": [
            "recrHe3okjpfaLYZc"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2198,
        "id": "recbo0nk3tLUcrTQU",
        "row": 24585,
        "state": "",
        "group": "",
        "group_num": [
            "reccLDcfV9rFkUpAB"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 399,
        "id": "recc0ThsbbenChpKt",
        "row": 24581,
        "state": "",
        "group": "",
        "group_num": [
            "rec3FerqJBGclQZb1"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "reccMAXgCkXDORVQd",
        "row": 24584,
        "state": "",
        "group": "",
        "group_num": [
            "recOMTGxaFwX4D4th"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "2024": 1500,
        "id": "recdXfiwWuYv8IuHK",
        "row": 24596,
        "state": "",
        "group": "76414072",
        "group_num": [
            "recVAtCoF1XF9ltsU"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1260,
        "id": "recdCbVJ1kMs8J4i8",
        "row": 24571,
        "state": "",
        "group": "",
        "group_num": [
            "recEPfHqTp760SJcF"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "id": "recowf23MGdjn07O1",
        "row": 24606,
        "state": "",
        "group": "",
        "group_num": [
            "recCijTkHBBTSB5XC"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recokwpeoLeCiDWA3",
        "row": 24573,
        "state": "",
        "group": "",
        "group_num": [
            "recYVQTLTHxofFapi"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "recql8xUkCRk1Fgu7",
        "row": 24572,
        "state": "",
        "group": "",
        "group_num": [
            "recEPfHqTp760SJcF"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recrOHRMBaQDvKDJe",
        "row": 24593,
        "state": "",
        "group": "76414047",
        "group_num": [
            "recrHe3okjpfaLYZc"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1365,
        "2024": 1365,
        "id": "recrQi5tsxS2aI5Qg",
        "row": 24588,
        "state": "",
        "group": "",
        "group_num": [
            "reca4kc50xmfJ0QKq"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1044,
        "id": "recsUNlu06HXy8qRt",
        "row": 24583,
        "state": "",
        "group": "",
        "group_num": [
            "recOMTGxaFwX4D4th"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 554,
        "id": "rectHwaOy4SMP6iO9",
        "row": 24574,
        "state": "",
        "group": "",
        "group_num": [
            "recW8dALfoKEGFwff"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "recvDEYKQC3VEb1Nx",
        "row": 24559,
        "state": "",
        "group": "76413662",
        "group_num": [
            "recgB88dz1o9g5o9s"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1868,
        "id": "recwj2pbxLHQBa2N2",
        "row": 24582,
        "state": "",
        "group": "76413917",
        "group_num": [
            "recIiAunBdhZHn1lm"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 391,
        "id": "recxhPMd9c7HP2Zv7",
        "row": 24604,
        "state": "",
        "group": "76414102",
        "group_num": [
            "rec5lKcy1MUnFlTOV"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 42,
        "2023": 41,
        "id": "recyHAIckS4A9Hlcl",
        "row": 24566,
        "state": "",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2880,
        "id": "recDqbcLA5WJrFhYR",
        "row": 24586,
        "state": "",
        "group": "76414011",
        "group_num": [
            "recKxZQqRZh4MHtIL"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2375,
        "id": "recGMLkvDihXph7bX",
        "row": 24595,
        "state": "",
        "group": "76414072",
        "group_num": [
            "recVAtCoF1XF9ltsU"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3788,
        "2023": 2973,
        "id": "recGcVh8yjN9An0UT",
        "row": 24568,
        "state": "",
        "group": "76413696",
        "group_num": [
            "recqGR4DTF4BFpeYG"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "recHQqfHMj6arUtMu",
        "row": 24561,
        "state": "",
        "group": "76413662",
        "group_num": [
            "recgB88dz1o9g5o9s"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5029,
        "id": "recHmPUBkpsZbefuL",
        "row": 24558,
        "state": "",
        "group": "76413623",
        "group_num": [
            "recaBbUGQZ3i44ArS"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recHobZLic2OdM06p",
        "row": 24576,
        "state": "",
        "group": "76413838",
        "group_num": [
            "recVynnm5GqXEZ1Py"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1300,
        "id": "recKP5c4Zfngwd7Y9",
        "row": 24603,
        "state": "",
        "group": "76414102",
        "group_num": [
            "rec5lKcy1MUnFlTOV"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 690,
        "id": "recLxJVBzcPyZEzVJ",
        "row": 24563,
        "state": "",
        "group": "",
        "group_num": [
            "recmtVOj3mMfFwb1L"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recMOKC6ei34pcdwF",
        "row": 24594,
        "state": "",
        "group": "",
        "group_num": [
            "recx5TWFGoCW4ZWIE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1000,
        "id": "recethmuw1HU0zAtT",
        "row": 24639,
        "state": "",
        "group": "76414583",
        "group_num": [
            "rec1A9UwuRzH1zXX6"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rechHMvZGOwewcCWi",
        "row": 24611,
        "state": "",
        "group": "76414165",
        "group_num": [
            "recPZiBoDyI4n3Yhe"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "id": "rechfl3iycmeqLXfm",
        "row": 24646,
        "state": "",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "rechjUYWKv325GmVb",
        "row": 24609,
        "state": "",
        "group": "76414159",
        "group_num": [
            "recWwVrDuqjWVaAzE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2103,
        "id": "reci1gtLGcD2bPt5p",
        "row": 24641,
        "state": "",
        "group": "",
        "group_num": [
            "recDk0hbuqT7rZG6t"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1251,
        "id": "reciarPeovxiw5u6a",
        "row": 24633,
        "state": "",
        "group": "76414536",
        "group_num": [
            "recqzmuWyDrfm3tWV"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "2023": 150,
        "id": "recjZyiCUHgeRp4m5",
        "row": 24608,
        "state": "",
        "group": "76414141",
        "group_num": [
            "recpRGPrjmGMDMJQe"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1772,
        "id": "recjeL58k0uWY0jwQ",
        "row": 24652,
        "state": "",
        "group": "",
        "group_num": [
            "recPBA6ayIaRjp5R6"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "reckedJH503a4Ftss",
        "row": 24645,
        "state": "",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recmoUz33IV8mruH6",
        "row": 24653,
        "state": "",
        "group": "",
        "group_num": [
            "recPBA6ayIaRjp5R6"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2867,
        "id": "recnL4YWTM3MktfwX",
        "row": 24634,
        "state": "",
        "group": "76414536",
        "group_num": [
            "recqzmuWyDrfm3tWV"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1160,
        "id": "recnQ0mmrnpZL1BPf",
        "row": 24638,
        "state": "",
        "group": "76414583",
        "group_num": [
            "rec1A9UwuRzH1zXX6"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4500,
        "id": "recOCA3oLk7EASpkC",
        "row": 24636,
        "state": "",
        "group": "76414571",
        "group_num": [
            "recgok2g5duxFRyEZ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "2024": 206,
        "id": "recO8VDItVHwvY2ZL",
        "row": 24654,
        "state": "",
        "group": "",
        "group_num": [
            "recPBA6ayIaRjp5R6"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3325,
        "id": "recTpGHXshJDTOmf0",
        "row": 24637,
        "state": "",
        "group": "76414583",
        "group_num": [
            "rec1A9UwuRzH1zXX6"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recUy0J3dT5UWnNG4",
        "row": 24628,
        "state": "",
        "group": "",
        "group_num": [
            "recBAamn2FBQfgQ4r"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recVMLmB0iwDM37W9",
        "row": 24630,
        "state": "",
        "group": "",
        "group_num": [
            "recBAamn2FBQfgQ4r"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recWuthimCfKlSP3q",
        "row": 24620,
        "state": "",
        "group": "",
        "group_num": [
            "reclW5w2DHgogXIVT"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "id": "recWaDVkg35ACrZhI",
        "row": 24622,
        "state": "",
        "group": "76414351",
        "group_num": [
            "recp7nKPttDb1eAnC"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 266,
        "id": "recYxceGY3PyJo2Vg",
        "row": 24629,
        "state": "",
        "group": "",
        "group_num": [
            "recBAamn2FBQfgQ4r"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2503,
        "id": "recYEQRoqNLLDOfVn",
        "row": 24615,
        "state": "",
        "group": "76414217",
        "group_num": [
            "recy5m2jAD7hb8qRo"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "2023": 3375,
        "id": "rec2AoRAqwAUcPBst",
        "row": 24625,
        "state": "",
        "group": "76414452",
        "group_num": [
            "rec2HCi3Fg6PN47MV"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec3iW6vSkmFxme0z",
        "row": 24656,
        "state": "",
        "group": "76414769",
        "group_num": [
            "recw54WliTeyp7ElK"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "rec5WCbRRxsIPGimO",
        "row": 24655,
        "state": "",
        "group": "76414769",
        "group_num": [
            "recw54WliTeyp7ElK"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2300,
        "id": "rec5NehcWvHQ7Nj4j",
        "row": 24607,
        "state": "",
        "group": "76414138",
        "group_num": [
            "recZP3jszTidcH7fk"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3359,
        "id": "rec6zKCxQeud7waQy",
        "row": 24621,
        "state": "",
        "group": "76414351",
        "group_num": [
            "recp7nKPttDb1eAnC"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1229,
        "id": "rec7iLEm8ZRSSdkco",
        "row": 24623,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Kt6eZp9A8OmuZ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1503,
        "2024": 231,
        "id": "rec8t1O2FweJLweqV",
        "row": 24642,
        "state": "",
        "group": "",
        "group_num": [
            "recDk0hbuqT7rZG6t"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1467,
        "id": "recaYr7ygQqMQ5hTi",
        "row": 24616,
        "state": "",
        "group": "76414217",
        "group_num": [
            "recy5m2jAD7hb8qRo"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3217,
        "id": "recaqTG4XH0owHeTG",
        "row": 24614,
        "state": "",
        "group": "",
        "group_num": [
            "recOxp27PaBjkHamL"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1992,
        "id": "recc7E5LAjgxjHSfS",
        "row": 24643,
        "state": "",
        "group": "",
        "group_num": [
            "recSS2XnCphREr48O"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 299,
        "id": "recdojQpJcV1hHy1b",
        "row": 24650,
        "state": "",
        "group": "",
        "group_num": [
            "rectHGAXV1ftDkzxj"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1794,
        "id": "recoFTfQruIhBnw13",
        "row": 24627,
        "state": "",
        "group": "",
        "group_num": [
            "recjzbZTrfG7jblhb"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1530,
        "id": "recp5VbIOnJzsSKli",
        "row": 24647,
        "state": "",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recsuftQeaPdOyFS7",
        "row": 24610,
        "state": "",
        "group": "76414159",
        "group_num": [
            "recWwVrDuqjWVaAzE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1500,
        "id": "recuzAD26jdKiUsMT",
        "row": 24618,
        "state": "",
        "group": "",
        "group_num": [
            "rec8ASqGkudnBCK5w"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1447,
        "id": "recAOz58xngJHe2ev",
        "row": 24644,
        "state": "",
        "group": "",
        "group_num": [
            "recSS2XnCphREr48O"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 224,
        "id": "recCH76SYJh9Z0seV",
        "row": 24619,
        "state": "",
        "group": "",
        "group_num": [
            "rec8ASqGkudnBCK5w"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "2024": 219,
        "id": "recCPVDSjhrTBWajA",
        "row": 24624,
        "state": "",
        "group": "",
        "group_num": [
            "rec3Kt6eZp9A8OmuZ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1259,
        "2023": 475,
        "id": "recCcAj7bOutIwMJ7",
        "row": 24613,
        "state": "",
        "group": "",
        "group_num": [
            "recOxp27PaBjkHamL"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "id": "recDhFIaEjw2IHsvz",
        "row": 24648,
        "state": "",
        "group": "76414649",
        "group_num": [
            "recUOTIYBZKQYcoHB"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recERGTwAcmFP17Rl",
        "row": 24612,
        "state": "",
        "group": "76414165",
        "group_num": [
            "recPZiBoDyI4n3Yhe"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1078,
        "id": "recFw0d76oFSbXCFY",
        "row": 24635,
        "state": "",
        "group": "76414571",
        "group_num": [
            "recgok2g5duxFRyEZ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recHV6i6jHjvpCBi8",
        "row": 24649,
        "state": "",
        "group": "",
        "group_num": [
            "rec1WvC5K1sGec6WA"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2657,
        "id": "recKuEA6l7PU9ZWvY",
        "row": 24640,
        "state": "",
        "group": "76414583",
        "group_num": [
            "rec1A9UwuRzH1zXX6"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 856,
        "2023": 1125,
        "id": "recKHfoED2fY7b3ae",
        "row": 24651,
        "state": "",
        "group": "",
        "group_num": [
            "rectHGAXV1ftDkzxj"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2174,
        "2024": 2292,
        "id": "recLTME9DUtr2WA9F",
        "row": 24631,
        "state": "",
        "group": "76414496",
        "group_num": [
            "recFiHJVQnnleUaG1"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recLe6jFyxbdx4R4n",
        "row": 24617,
        "state": "",
        "group": "76414217",
        "group_num": [
            "recy5m2jAD7hb8qRo"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1809,
        "2024": 1920,
        "id": "recM1D2uaYHw6GoBX",
        "row": 24632,
        "state": "",
        "group": "76414536",
        "group_num": [
            "recqzmuWyDrfm3tWV"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recM4Ye0TINGrxd7U",
        "row": 24626,
        "state": "",
        "group": "76414452",
        "group_num": [
            "rec2HCi3Fg6PN47MV"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 211,
        "id": "receL4ojDKt9aO4Hp",
        "row": 24688,
        "state": "",
        "group": "",
        "group_num": [
            "rec5wk8UdxE0qqMBx"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2718,
        "2023": 2747,
        "id": "rece5LefYswLD8ZVM",
        "row": 24702,
        "state": "",
        "group": "",
        "group_num": [
            "recUBdAHOFGlVoykg"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 68,
        "2024": 675,
        "id": "recf68psKkB9rgOTc",
        "row": 24658,
        "state": "",
        "group": "",
        "group_num": [
            "recyzAldIoIzbdkSr"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 923,
        "2024": 211,
        "id": "recf9YaPKETpFdTiv",
        "row": 24689,
        "state": "",
        "group": "",
        "group_num": [
            "rec5wk8UdxE0qqMBx"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 467,
        "id": "recgxpJqHI1Ez8Q3d",
        "row": 24691,
        "state": "",
        "group": "",
        "group_num": [
            "recbov2C3CORzkeQ0"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2600,
        "2023": 2979,
        "2024": 3000,
        "id": "recgOuwdfRnRdBvfW",
        "row": 24664,
        "state": "",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "reciAC32jg49BjT55",
        "row": 24678,
        "state": "",
        "group": "",
        "group_num": [
            "rectZc1nnRQZ0NzAG"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2300,
        "2024": 3000,
        "id": "reciHKc1HOaQRirFE",
        "row": 24687,
        "state": "",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 797,
        "id": "recil8EQbzAku9f0t",
        "row": 24674,
        "state": "",
        "group": "",
        "group_num": [
            "rec0O4S6uh06ff7RX"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2344,
        "id": "recil1Ptsu1ubcV0q",
        "row": 24660,
        "state": "",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "reckdvpEqfc5nbk5J",
        "row": 24694,
        "state": "",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2675,
        "2023": 1485,
        "2024": 499,
        "id": "recOEwT16Fe70JLCQ",
        "row": 24661,
        "state": "",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1754,
        "id": "recRw3LDZojEqznW9",
        "row": 24673,
        "state": "",
        "group": "",
        "group_num": [
            "recee4109r3Gi0uaB"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "id": "recSvFN16wqJoVR55",
        "row": 24677,
        "state": "",
        "group": "",
        "group_num": [
            "rec0O4S6uh06ff7RX"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1523,
        "id": "recTLu9yBn442Axuo",
        "row": 24701,
        "state": "",
        "group": "76415119",
        "group_num": [
            "rece5886E8qHib6ol"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 48,
        "id": "recT2a33Tl9UWSJLQ",
        "row": 24662,
        "state": "",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 966,
        "id": "recXbmLKw1SDPKmBP",
        "row": 24680,
        "state": "",
        "group": "",
        "group_num": [
            "recGNLEB3ze9v2tFX"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1877,
        "2023": 1877,
        "id": "recYVmQhge3w3eI2Y",
        "row": 24703,
        "state": "",
        "group": "",
        "group_num": [
            "recUBdAHOFGlVoykg"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 253,
        "id": "recYwa1RbGYGFFNan",
        "row": 24676,
        "state": "",
        "group": "",
        "group_num": [
            "rec0O4S6uh06ff7RX"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 219,
        "id": "recZDVOaNB9rz7pmU",
        "row": 24666,
        "state": "",
        "group": "76414872",
        "group_num": [
            "rec00WIFxr7VgKp1D"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2351,
        "id": "recZkEDGqkPIjfDGt",
        "row": 24706,
        "state": "",
        "group": "76415194",
        "group_num": [
            "recbBDtAkl3Dm3Afy"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 384,
        "id": "rec1AaHObztJFe9Tw",
        "row": 24685,
        "state": "",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 985,
        "id": "rec3sC4xJsompauiO",
        "row": 24681,
        "state": "",
        "group": "",
        "group_num": [
            "recGNLEB3ze9v2tFX"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2094,
        "id": "rec3fyajaI6fnV0fR",
        "row": 24693,
        "state": "",
        "group": "",
        "group_num": [
            "recuNNX3eLYIW8etQ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "rec4uxtGJNG3skAV4",
        "row": 24669,
        "state": "",
        "group": "76414886",
        "group_num": [
            "rec257uphBr6MP4yE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2270,
        "id": "rec6t5K3S8npNvEkm",
        "row": 24667,
        "state": "",
        "group": "76414872",
        "group_num": [
            "rec00WIFxr7VgKp1D"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "rec7JAyXmJWP4CwGh",
        "row": 24684,
        "state": "",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 77,
        "2023": 22,
        "id": "rec7L4sgMSoifyuD2",
        "row": 24690,
        "state": "",
        "group": "",
        "group_num": [
            "reciAfCwhfj9Jn0C1"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "rec788L6FlLhBZXZS",
        "row": 24668,
        "state": "",
        "group": "76414875",
        "group_num": [
            "rec0xVQBKYCbd8DD3"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "rec8NLcmXXwjDwiAi",
        "row": 24675,
        "state": "",
        "group": "",
        "group_num": [
            "rec0O4S6uh06ff7RX"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "recbVTlJDvvTms37N",
        "row": 24698,
        "state": "",
        "group": "",
        "group_num": [
            "recHCpmCOFh6CVbqj"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2655,
        "id": "recbAoXb1a9FpCdDg",
        "row": 24671,
        "state": "",
        "group": "76414886",
        "group_num": [
            "rec257uphBr6MP4yE"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2042,
        "2023": 2470,
        "id": "recdCGSDK7Q2ZmsT7",
        "row": 24672,
        "state": "",
        "group": "76414886",
        "group_num": [
            "rec257uphBr6MP4yE"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1808,
        "id": "reco7wzFKrNk0SVML",
        "row": 24663,
        "state": "",
        "group": "76414858",
        "group_num": [
            "rec6rF0Fu3lM7zzSA"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1411,
        "id": "recpDcA7rk7UBG1XM",
        "row": 24682,
        "state": "",
        "group": "76414988",
        "group_num": [
            "recRZdZde1v3hEM4z"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "id": "recuWp5Pw0yZzdKRr",
        "row": 24699,
        "state": "",
        "group": "",
        "group_num": [
            "recoxoHVRimeCtoDS"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1321,
        "id": "recuRV1by4bXa5w99",
        "row": 24665,
        "state": "",
        "group": "76414872",
        "group_num": [
            "rec00WIFxr7VgKp1D"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1250,
        "id": "recwuhepG7DyEZd9u",
        "row": 24696,
        "state": "",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 943,
        "2023": 835,
        "id": "recwk07kHnJV6Ypxz",
        "row": 24686,
        "state": "",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 2138,
        "id": "reczQyu3KrXXvz1fd",
        "row": 24683,
        "state": "",
        "group": "76414990",
        "group_num": [
            "rec9O3J5hW9vLc7cE"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2100,
        "id": "recA1OjRjegCPTdlP",
        "row": 24705,
        "state": "",
        "group": "76415179",
        "group_num": [
            "recfDYlHLHSHznBXX"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recA9J4qCJ9dfx5Us",
        "row": 24692,
        "state": "",
        "group": "",
        "group_num": [
            "reckT7U2D1jrHsOcu"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "2023": 3000,
        "id": "recCb9MFgpBmHu6EA",
        "row": 24697,
        "state": "",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2823,
        "id": "recFPPjN3s2WnpwQq",
        "row": 24657,
        "state": "",
        "group": "",
        "group_num": [
            "recfO3e89SwZrzsEj"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1500,
        "id": "recGSbbqEdUeFAxRj",
        "row": 24695,
        "state": "",
        "group": "76415103",
        "group_num": [
            "recluPCIGc1tmzu9m"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3342,
        "id": "recHPMOv1mUrCecg0",
        "row": 24670,
        "state": "",
        "group": "76414886",
        "group_num": [
            "rec257uphBr6MP4yE"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recL17gOuBPhlNkFz",
        "row": 24679,
        "state": "",
        "group": "",
        "group_num": [
            "rectZc1nnRQZ0NzAG"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "2023": 224,
        "id": "recLwPdBwjqoFKQh5",
        "row": 24700,
        "state": "",
        "group": "76415119",
        "group_num": [
            "rece5886E8qHib6ol"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3563,
        "id": "recLxcd6wjJvsO1tn",
        "row": 24704,
        "state": "",
        "group": "76415162",
        "group_num": [
            "recZfJ3xkp8H3uDSc"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1600,
        "id": "recMkdHQXF9LhmvlM",
        "row": 24659,
        "state": "",
        "group": "",
        "group_num": [
            "recyzAldIoIzbdkSr"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3375,
        "id": "receL0hADE0X005d4",
        "row": 24741,
        "state": "",
        "group": "",
        "group_num": [
            "recmYk9i4iPQnQFnW"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1358,
        "id": "recf1OJvMPRNnPdRG",
        "row": 24747,
        "state": "",
        "group": "",
        "group_num": [
            "recXzW8ueoNuwUWL6"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1552,
        "id": "recfzAg2tzUFIEB2B",
        "row": 24722,
        "state": "",
        "group": "",
        "group_num": [
            "rec944h9Ks1aYGQZZ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4500,
        "id": "reciTWM7Bmr9eFiiD",
        "row": 24708,
        "state": "",
        "group": "",
        "group_num": [
            "rec8PGyhptnOYGmuO"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 351,
        "id": "reciJkCg4jb5TpCf9",
        "row": 24735,
        "state": "",
        "group": "76415637",
        "group_num": [
            "recmmQLqwU4gZOk7u"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 108,
        "id": "recim2Adf2q4b6eVK",
        "row": 24718,
        "state": "",
        "group": "",
        "group_num": [
            "recXPO7EtP5WN3oR9"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 220,
        "id": "recjwLKk0ppkzwIQB",
        "row": 24724,
        "state": "",
        "group": "",
        "group_num": [
            "recW14sB5qURhXKp9"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3845,
        "2023": 3666,
        "id": "reclRK9b35sugJqWp",
        "row": 24716,
        "state": "",
        "group": "",
        "group_num": [
            "recXPO7EtP5WN3oR9"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3845,
        "2023": 2747,
        "id": "recmj1gUTiCWx5gyH",
        "row": 24719,
        "state": "",
        "group": "",
        "group_num": [
            "recXPO7EtP5WN3oR9"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 446,
        "2024": 446,
        "id": "recOhZAsFAk8vz45q",
        "row": 24723,
        "state": "",
        "group": "",
        "group_num": [
            "recW14sB5qURhXKp9"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3865,
        "id": "recOlFFuPR7xGalFJ",
        "row": 24745,
        "state": "",
        "group": "",
        "group_num": [
            "recwCCSOz5BFBf9u1"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recOmoRT7HqC5nPsa",
        "row": 24721,
        "state": "",
        "group": "",
        "group_num": [
            "rec944h9Ks1aYGQZZ"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recQ5i0WWz6O5bh3N",
        "row": 24733,
        "state": "",
        "group": "",
        "group_num": [
            "rec3JGRfGy7EB00LH"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 828,
        "2024": 567,
        "id": "recR8wOzSaZQEY2SX",
        "row": 24748,
        "state": "",
        "group": "",
        "group_num": [
            "recXzW8ueoNuwUWL6"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "recRfFNiP4cqdAx4t",
        "row": 24726,
        "state": "",
        "group": "",
        "group_num": [
            "recyFq4GdAYCY2U1i"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 266,
        "id": "recTRva8HgHcEAg4S",
        "row": 24731,
        "state": "",
        "group": "",
        "group_num": [
            "recxSsSGTnaj3clol"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3055,
        "id": "recVjgS030JnCTzCm",
        "row": 24725,
        "state": "",
        "group": "",
        "group_num": [
            "recyFq4GdAYCY2U1i"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2693,
        "id": "recX1nT43bPGLjRNg",
        "row": 24709,
        "state": "",
        "group": "",
        "group_num": [
            "rec9mP4i5D6gaJyu3"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "rec5g04TBRkHWcXNV",
        "row": 24750,
        "state": "",
        "group": "",
        "group_num": [
            "recMkhklLuc2aoyHv"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 300,
        "id": "rec6VoLTzUWlLagCU",
        "row": 24730,
        "state": "",
        "group": "",
        "group_num": [
            "rechmAsb5oBB8Qx5d"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2500,
        "id": "rec6vmjYrLCL8LqPh",
        "row": 24710,
        "state": "",
        "group": "",
        "group_num": [
            "rec9mP4i5D6gaJyu3"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3799,
        "id": "rec78Ar9FV7aI9ctX",
        "row": 24743,
        "state": "",
        "group": "",
        "group_num": [
            "recmYk9i4iPQnQFnW"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3003,
        "id": "rec7bYR8460K5cixd",
        "row": 24746,
        "state": "",
        "group": "",
        "group_num": [
            "recwCCSOz5BFBf9u1"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2037,
        "id": "rec89UnzoQteEh0qu",
        "row": 24720,
        "state": "",
        "group": "",
        "group_num": [
            "rec944h9Ks1aYGQZZ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recamCD6Sushy2GTU",
        "row": 24712,
        "state": "",
        "group": "",
        "group_num": [
            "recHuBuECIjrFuikO"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1464,
        "id": "recrzt2f3JyFI2FWY",
        "row": 24734,
        "state": "",
        "group": "76415637",
        "group_num": [
            "recmmQLqwU4gZOk7u"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recs50gW1PA2Y4MzG",
        "row": 24713,
        "state": "",
        "group": "",
        "group_num": [
            "recnPk4HcutSY88rm"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "rectn38bOHWasJxs5",
        "row": 24744,
        "state": "",
        "group": "",
        "group_num": [
            "recFc4rPp2QbDnnYJ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1376,
        "id": "recuH5KGiI42QU2p5",
        "row": 24738,
        "state": "",
        "group": "",
        "group_num": [
            "recT1WrC1m6mlwb7x"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recujoGQteCWEeMx8",
        "row": 24729,
        "state": "",
        "group": "",
        "group_num": [
            "recckbLofUXPeGlCI"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3003,
        "id": "recvzT9hBOoa5AYnd",
        "row": 24751,
        "state": "",
        "group": "",
        "group_num": [
            "recMkhklLuc2aoyHv"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recyEqzSVwu95JHPL",
        "row": 24711,
        "state": "",
        "group": "",
        "group_num": [
            "recHuBuECIjrFuikO"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recyRSzOIKmphGXtT",
        "row": 24756,
        "state": "",
        "group": "",
        "group_num": [
            "recNqltMzjSkK3pHQ"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3003,
        "2023": 3003,
        "id": "recy7fBeiDQFWeCRE",
        "row": 24717,
        "state": "",
        "group": "",
        "group_num": [
            "recXPO7EtP5WN3oR9"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "reczxuomi6m3fPAsH",
        "row": 24752,
        "state": "",
        "group": "",
        "group_num": [
            "recMkhklLuc2aoyHv"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "reczQKE0mzI8KLrRz",
        "row": 24715,
        "state": "",
        "group": "",
        "group_num": [
            "recaZDwKb7Tl0pmTq"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recAjizm8mQcm882O",
        "row": 24754,
        "state": "",
        "group": "",
        "group_num": [
            "recxZKJ2ngiPi0NnC"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 1153,
        "id": "recDzrSNLPbjDN7a1",
        "row": 24727,
        "state": "",
        "group": "",
        "group_num": [
            "recyFq4GdAYCY2U1i"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 148,
        "id": "recD37r6hh2fHQlbN",
        "row": 24728,
        "state": "",
        "group": "",
        "group_num": [
            "recDsGO1VHnfV6Ajq"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1420,
        "id": "recEZtDQDmKdwRfuI",
        "row": 24749,
        "state": "",
        "group": "",
        "group_num": [
            "recXzW8ueoNuwUWL6"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 240,
        "2024": 216,
        "id": "recHxpY5GCOX49rqZ",
        "row": 24736,
        "state": "",
        "group": "76415637",
        "group_num": [
            "recmmQLqwU4gZOk7u"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2655,
        "id": "recH6fDUO0SpDf7uc",
        "row": 24742,
        "state": "",
        "group": "",
        "group_num": [
            "recmYk9i4iPQnQFnW"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recJzNmVNziqNoxJb",
        "row": 24714,
        "state": "",
        "group": "",
        "group_num": [
            "recaZDwKb7Tl0pmTq"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recJmoaY5M6Wvu9zE",
        "row": 24740,
        "state": "",
        "group": "",
        "group_num": [
            "recmYk9i4iPQnQFnW"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2929,
        "id": "recKJjiPcmVX5GXfX",
        "row": 24707,
        "state": "",
        "group": "76415194",
        "group_num": [
            "recbBDtAkl3Dm3Afy"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recK9MtuFGKKv9ufS",
        "row": 24755,
        "state": "",
        "group": "",
        "group_num": [
            "recxZKJ2ngiPi0NnC"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 220,
        "id": "recLvZpIhaBGdaJis",
        "row": 24737,
        "state": "",
        "group": "",
        "group_num": [
            "rec79kDJ4xdg2EowO"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2957,
        "id": "recLrbnOCupzfyH2h",
        "row": 24739,
        "state": "",
        "group": "",
        "group_num": [
            "recYiszLoFGY15ea6"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2176,
        "id": "recMELqU0ggwwFekI",
        "row": 24732,
        "state": "",
        "group": "",
        "group_num": [
            "recokDn0QsywDDnit"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1945,
        "id": "recNDDBee8GILTG4b",
        "row": 24753,
        "state": "",
        "group": "",
        "group_num": [
            "rec8uUCEhNBjf6pMm"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recfP0W57Dth72vCM",
        "row": 24773,
        "state": "",
        "group": "76580065",
        "group_num": [
            "recYYr8T9T54YtQru"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4250,
        "id": "reciatU4NJ5XXzmjD",
        "row": 24777,
        "state": "",
        "group": "",
        "group_num": [
            "rec2X5YoFckehvoVp"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "recjUvmDL1IdEbOIl",
        "row": 24774,
        "state": "",
        "group": "",
        "group_num": [
            "recDKFr5RoWzQbu6I"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2025,
        "id": "reck38X1oB7xjdfXx",
        "row": 24763,
        "state": "",
        "group": "",
        "group_num": [
            "recrJNa0OIiVgc3Lp"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recmHvFMVNxg0j4w8",
        "row": 24772,
        "state": "",
        "group": "76580065",
        "group_num": [
            "recYYr8T9T54YtQru"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4774,
        "id": "recVEQ1ejkkYDaXNP",
        "row": 24764,
        "state": "",
        "group": "",
        "group_num": [
            "recTuu4c56yoKXkTM"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 115,
        "id": "rec25VqVDvs4Yhzo8",
        "row": 24780,
        "state": "",
        "group": "",
        "group_num": [
            "rece01ihLCzZgWgX3"
        ],
        "procedure": "OP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1998,
        "2024": 2400,
        "id": "rec3YRJsLcFUEDhvE",
        "row": 24758,
        "state": "",
        "group": "",
        "group_num": [
            "rec2EMas074htvw3c"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "rec3kGmYhJ71eUqiA",
        "row": 24785,
        "state": "",
        "group": "",
        "group_num": [
            "recR0uyVRLSsCAMaY"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec89jJDAzt8mvjFX",
        "row": 24779,
        "state": "",
        "group": "",
        "group_num": [
            "recVfM5yZJPWiaVKu"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2625,
        "id": "rec8fiEEys31hSGXj",
        "row": 24762,
        "state": "",
        "group": "",
        "group_num": [
            "recrJNa0OIiVgc3Lp"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1089,
        "2024": 231,
        "id": "recdVBHNTAbKEmajb",
        "row": 24770,
        "state": "",
        "group": "",
        "group_num": [
            "recfttn9oTqoXQlYQ"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "recot8Q5dSlP6XWkS",
        "row": 24782,
        "state": "",
        "group": "",
        "group_num": [
            "recLooRiLVukBdC4r"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recpTKjWCdo49Iq7z",
        "row": 24757,
        "state": "",
        "group": "",
        "group_num": [
            "recNqltMzjSkK3pHQ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2313,
        "id": "recpONBAZgD6hmd4O",
        "row": 24765,
        "state": "",
        "group": "",
        "group_num": [
            "recTuu4c56yoKXkTM"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recpfa7Lhj0XqMJYT",
        "row": 24766,
        "state": "",
        "group": "",
        "group_num": [
            "recoL2UXAL1BWzbqH"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1877,
        "id": "recrNxWfhCm2NJP96",
        "row": 24760,
        "state": "",
        "group": "76510041",
        "group_num": [
            "recT7LpW2PSDd7EVk"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1088,
        "id": "recsaVjR2JOmZmzTa",
        "row": 24761,
        "state": "",
        "group": "76510041",
        "group_num": [
            "recT7LpW2PSDd7EVk"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2396,
        "2023": 1844,
        "2024": 1838,
        "id": "rect1lNjjKxNVy28E",
        "row": 24771,
        "state": "",
        "group": "",
        "group_num": [
            "recfttn9oTqoXQlYQ"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "id": "recvkBcDKhSxWhcpc",
        "row": 24775,
        "state": "",
        "group": "",
        "group_num": [
            "recDKFr5RoWzQbu6I"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2250,
        "2024": 1750,
        "id": "recxJ3yFlTxgZabRv",
        "row": 24769,
        "state": "",
        "group": "",
        "group_num": [
            "recfttn9oTqoXQlYQ"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1950,
        "id": "recyZQudNcNMbIK0b",
        "row": 24776,
        "state": "",
        "group": "",
        "group_num": [
            "rec8HUvv4gIijSICu"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recBaZCXV5WjtCxNH",
        "row": 24783,
        "state": "",
        "group": "",
        "group_num": [
            "recLooRiLVukBdC4r"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "2024": 3000,
        "id": "recCIH7dapK29050k",
        "row": 24759,
        "state": "",
        "group": "76416215",
        "group_num": [
            "reclT1hD74mQc3ESG"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3735,
        "id": "recCle2BlR67ritFd",
        "row": 24767,
        "state": "",
        "group": "",
        "group_num": [
            "recoL2UXAL1BWzbqH"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 211,
        "id": "recGWEi0pOLoRRbR1",
        "row": 24781,
        "state": "",
        "group": "",
        "group_num": [
            "rece01ihLCzZgWgX3"
        ],
        "procedure": "IOP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 665,
        "id": "recHs3qc5oZ0jY8X4",
        "row": 24786,
        "state": "",
        "group": "MCW",
        "group_num": [
            "recqoCOMCD5lcsu16"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recIKlXAsdwzu0Q62",
        "row": 24768,
        "state": "",
        "group": "",
        "group_num": [
            "recFlajWHTnz2ysTe"
        ],
        "procedure": "Detox",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recKO2F98sZSLW8KQ",
        "row": 24778,
        "state": "",
        "group": "",
        "group_num": [
            "recVfM5yZJPWiaVKu"
        ],
        "procedure": "RTC",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1167,
        "id": "recMidencbAvn1ICk",
        "row": 24784,
        "state": "",
        "group": "",
        "group_num": [
            "recHMXDSa8pYZLNhs"
        ],
        "procedure": "PHP",
        "provider": "UMR"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "2023": 1019,
        "id": "receyq38JAJjMwBpF",
        "row": 24787,
        "state": "",
        "group": "131192M025",
        "group_num": [
            "recW8cDbkr0EjtwUh"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "id": "reciAVyduLbqSIDOH",
        "row": 24793,
        "state": "",
        "group": "131192M177",
        "group_num": [
            "reckG0cq7WUl75svn"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1101,
        "id": "recOOmzFog6Xhy2uw",
        "row": 24803,
        "state": "",
        "group": "",
        "group_num": [
            "rec6QpKuLiyyKr0dQ"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "id": "recQHVZ0yfDKLJrBf",
        "row": 24801,
        "state": "",
        "group": "131192M273",
        "group_num": [
            "recMM9MAV1RyzdVnA"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "2023": 1020,
        "id": "recQJLWTgb4xj1IrF",
        "row": 24789,
        "state": "",
        "group": "",
        "group_num": [
            "recqux8638stl0zMi"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 190,
        "id": "recX7xtUCWaJ333WI",
        "row": 24802,
        "state": "",
        "group": "131192M273",
        "group_num": [
            "recMM9MAV1RyzdVnA"
        ],
        "procedure": "IOP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 427,
        "id": "recZQrdq7raLr9UbU",
        "row": 24804,
        "state": "",
        "group": "",
        "group_num": [
            "rec6QpKuLiyyKr0dQ"
        ],
        "procedure": "PHP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 190,
        "id": "rec6MlLw7ibCOX6NG",
        "row": 24794,
        "state": "",
        "group": "131192M177",
        "group_num": [
            "reckG0cq7WUl75svn"
        ],
        "procedure": "PHP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1101,
        "id": "recptXkvoWUiAv2nM",
        "row": 24799,
        "state": "",
        "group": "",
        "group_num": [
            "recLgn1yGoKn3icDO"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 900,
        "id": "recsqOrG2i3lzt73x",
        "row": 24792,
        "state": "",
        "group": "131192M038",
        "group_num": [
            "recIDDzb9GUjEsBWz"
        ],
        "procedure": "Detox",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "id": "rectQWf0qxrs66LNI",
        "row": 24798,
        "state": "",
        "group": "",
        "group_num": [
            "recLOC4SVvz6R1zHd"
        ],
        "procedure": "Detox",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1101,
        "id": "recwvbz76R8Oh4N9v",
        "row": 24797,
        "state": "",
        "group": "",
        "group_num": [
            "recLOC4SVvz6R1zHd"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 207,
        "2023": 179,
        "id": "recBIChMfVihC5DEd",
        "row": 24800,
        "state": "",
        "group": "",
        "group_num": [
            "recLgn1yGoKn3icDO"
        ],
        "procedure": "PHP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recCQ0I836fmEOjzD",
        "row": 24795,
        "state": "",
        "group": "131192M177",
        "group_num": [
            "reckG0cq7WUl75svn"
        ],
        "procedure": "OP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1067,
        "2023": 1067,
        "id": "recDFFth4qnvFFNTV",
        "row": 24788,
        "state": "",
        "group": "131192M025",
        "group_num": [
            "recW8cDbkr0EjtwUh"
        ],
        "procedure": "Detox",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 265,
        "2024": 500,
        "id": "recJTA5fSfNvtoGaI",
        "row": 24791,
        "state": "",
        "group": "131192M038",
        "group_num": [
            "recIDDzb9GUjEsBWz"
        ],
        "procedure": "IOP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 190,
        "id": "recJGcrY8Em62vqm2",
        "row": 24796,
        "state": "",
        "group": "131192M177",
        "group_num": [
            "reckG0cq7WUl75svn"
        ],
        "procedure": "IOP",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 640,
        "2023": 700,
        "id": "recJpce1eiL5jAsDK",
        "row": 24790,
        "state": "",
        "group": "131192M038",
        "group_num": [
            "recIDDzb9GUjEsBWz"
        ],
        "procedure": "RTC",
        "provider": "UNICARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "id": "recW3dbxn5mlapDuY",
        "row": 24805,
        "state": "",
        "group": "0F4615",
        "group_num": [
            "recYhBz0mT3YYsq7k"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 808,
        "id": "recDuFsXiqFbBuNbK",
        "row": 24806,
        "state": "",
        "group": "0F4615",
        "group_num": [
            "recYhBz0mT3YYsq7k"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 21,
        "id": "recfdgjxigumlTgoj",
        "row": 24832,
        "state": "",
        "group": "",
        "group_num": [
            "rec7TMcZsFysxCyq9"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 151,
        "id": "rechDOnxCQcodI9Ju",
        "row": 24816,
        "state": "",
        "group": "1F0963",
        "group_num": [
            "rectr7OIaNuoP5P0H"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "id": "recjGoj6eNyKhbbqF",
        "row": 24812,
        "state": "",
        "group": "",
        "group_num": [
            "recQuQrmCSvfulRjv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recjdY8QPFsNyG091",
        "row": 24851,
        "state": "",
        "group": "3Q3234",
        "group_num": [
            "recyly1fPk9kZlUmk"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 503,
        "id": "reclFRwxf9XRs2X20",
        "row": 24854,
        "state": "",
        "group": "3Y2052",
        "group_num": [
            "recwMKcVyGlfC8nkd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 632,
        "2024": 632,
        "id": "reclIXxYfVYADy1h4",
        "row": 24809,
        "state": "",
        "group": "",
        "group_num": [
            "recLnAdvF2aeNtJW3"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 895,
        "id": "recnNU7FoXq3yQMrw",
        "row": 24838,
        "state": "",
        "group": "",
        "group_num": [
            "recD4VJpjQ6eQ2Hhg"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 22,
        "id": "recORofMz5lBTtEZZ",
        "row": 24856,
        "state": "",
        "group": "",
        "group_num": [
            "recfoUwaAJdA7cr0Y"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "recP5x2SzKjxfF2tU",
        "row": 24843,
        "state": "",
        "group": "",
        "group_num": [
            "recQE1bwcE95Abcc5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 571,
        "id": "recRNuifp1bKH5oev",
        "row": 24847,
        "state": "",
        "group": "",
        "group_num": [
            "recryG4umZFp1DkRU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 261,
        "id": "recRjCnazKs2DO2zk",
        "row": 24826,
        "state": "",
        "group": "",
        "group_num": [
            "rec6fa0mHvSeXUaDR"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "recSubKmmyczPRqfD",
        "row": 24831,
        "state": "",
        "group": "1Y4498",
        "group_num": [
            "recGf26NJlH82t1zb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recThUOwewBPOLeG2",
        "row": 24819,
        "state": "",
        "group": "",
        "group_num": [
            "recolwBLtCcPftClQ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 357,
        "id": "recTj8k5Lt2bl4ZT2",
        "row": 24855,
        "state": "",
        "group": "3Y2052",
        "group_num": [
            "recwMKcVyGlfC8nkd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recUHiYBkRJ1ArHYY",
        "row": 24839,
        "state": "",
        "group": "2Q3831",
        "group_num": [
            "recXUgZ1hLnZBUTXC"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 209,
        "2024": 248,
        "id": "recWjefxWr7FsxaoD",
        "row": 24834,
        "state": "",
        "group": "2F9604",
        "group_num": [
            "recjXK0Ys05LXS9QL"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "id": "recYWMhF6IA5wuKDB",
        "row": 24818,
        "state": "",
        "group": "",
        "group_num": [
            "recvhf1gzj9yKekND"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1354,
        "id": "recZnCjpkjDX75QAC",
        "row": 24845,
        "state": "",
        "group": "",
        "group_num": [
            "recAgGbAK88MvvEaY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 425,
        "id": "rec1wrnCJgIaOFFCz",
        "row": 24824,
        "state": "",
        "group": "",
        "group_num": [
            "recNIh8sKGjYcoPNF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 715,
        "id": "rec278QwNwmY0jM04",
        "row": 24849,
        "state": "",
        "group": "",
        "group_num": [
            "recnFxXX6X842Dw8z"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "id": "rec4RLHLhPIw1GRI4",
        "row": 24842,
        "state": "",
        "group": "",
        "group_num": [
            "recQE1bwcE95Abcc5"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "2023": 21,
        "id": "rec56R9SZUXMu9Din",
        "row": 24815,
        "state": "",
        "group": "1F0963",
        "group_num": [
            "rectr7OIaNuoP5P0H"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 79,
        "id": "rec5bfsV2QuzfmD88",
        "row": 24837,
        "state": "",
        "group": "2L2775",
        "group_num": [
            "recXEhXboA8J18YRe"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 239,
        "id": "rec5jDmf2VpYaTveY",
        "row": 24827,
        "state": "",
        "group": "1W7077",
        "group_num": [
            "recomjZRQbJ8B0WVe"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "rec5lAiEEXNsPkY2C",
        "row": 24841,
        "state": "",
        "group": "",
        "group_num": [
            "rec4GXvL393QTiKdo"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "id": "rec6GRpHpOLvZSsWq",
        "row": 24840,
        "state": "",
        "group": "",
        "group_num": [
            "recEXbk2h9H4CdULj"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 183,
        "id": "rec7E1iFAh5pDDlEn",
        "row": 24848,
        "state": "",
        "group": "",
        "group_num": [
            "recQ3laCgQmt6IaIQ"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 292,
        "id": "rec7fOH65sQEv36PY",
        "row": 24810,
        "state": "",
        "group": "",
        "group_num": [
            "recLnAdvF2aeNtJW3"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 293,
        "id": "recaGFTySAaRlDhi6",
        "row": 24814,
        "state": "",
        "group": "1F0963",
        "group_num": [
            "rectr7OIaNuoP5P0H"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recamT2hm463Fm2Qu",
        "row": 24820,
        "state": "",
        "group": "",
        "group_num": [
            "rec0ukqUcw0i6VcoJ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 264,
        "2023": 264,
        "id": "recdFmIVswiScp9Hk",
        "row": 24828,
        "state": "",
        "group": "1X3785",
        "group_num": [
            "recqv5FlwlC7uuRUt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 28,
        "id": "recpxXTpuFhPQ9quM",
        "row": 24808,
        "state": "",
        "group": "",
        "group_num": [
            "reclstsXQPtBQbOiJ"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recpKrMzTJ4fPsPMV",
        "row": 24807,
        "state": "",
        "group": "0F8112",
        "group_num": [
            "recB1C0sHUGYQv7lG"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "recqg2ohPn62RBSMV",
        "row": 24822,
        "state": "",
        "group": "",
        "group_num": [
            "reci2mlEXtkaPFrpb"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 42,
        "id": "recuIs8JLNbvTx7vg",
        "row": 24846,
        "state": "",
        "group": "",
        "group_num": [
            "rec9ghJD98w2Ys4qA"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recv4POf0juLyWawZ",
        "row": 24811,
        "state": "",
        "group": "",
        "group_num": [
            "recQuQrmCSvfulRjv"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "recvi27EKcrpCzugc",
        "row": 24829,
        "state": "",
        "group": "",
        "group_num": [
            "rec2g6nuCzMMlaABx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1066,
        "id": "recwd94TyVjh2meUM",
        "row": 24850,
        "state": "",
        "group": "",
        "group_num": [
            "recnFxXX6X842Dw8z"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 927,
        "id": "recxOoHnbpiFJlOid",
        "row": 24836,
        "state": "",
        "group": "2K3784",
        "group_num": [
            "recrMqlpY1L4Lu9Ji"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 984,
        "id": "recAYiUF6lT6UXOtt",
        "row": 24823,
        "state": "",
        "group": "",
        "group_num": [
            "recNIh8sKGjYcoPNF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 295,
        "id": "recA7L6uPGsIxhHFu",
        "row": 24852,
        "state": "",
        "group": "3Q3234",
        "group_num": [
            "recyly1fPk9kZlUmk"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 834,
        "id": "recCB1SgDMidSHlRe",
        "row": 24844,
        "state": "",
        "group": "",
        "group_num": [
            "recAgGbAK88MvvEaY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 927,
        "id": "recDtIOzBjNa62FN1",
        "row": 24853,
        "state": "",
        "group": "3Q3234",
        "group_num": [
            "recyly1fPk9kZlUmk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "id": "recEovfAhMKT8RTZ6",
        "row": 24835,
        "state": "",
        "group": "2K3784",
        "group_num": [
            "recrMqlpY1L4Lu9Ji"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 571,
        "id": "recGhQFvPwFgIlj7O",
        "row": 24813,
        "state": "",
        "group": "",
        "group_num": [
            "recxBC8p7Ahe9Le6W"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 923,
        "2023": 923,
        "id": "recIAxb8RDQKHvFEv",
        "row": 24830,
        "state": "",
        "group": "",
        "group_num": [
            "recmPLqgQnziTFLwr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 873,
        "id": "recJAyr7iHvshbBwT",
        "row": 24817,
        "state": "",
        "group": "",
        "group_num": [
            "rec1GU2ofcw8uIrnF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1445,
        "id": "recJoNljXYgIPVhX9",
        "row": 24825,
        "state": "",
        "group": "",
        "group_num": [
            "recNIh8sKGjYcoPNF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 706,
        "id": "recKGd0tQafWTJ5n2",
        "row": 24821,
        "state": "",
        "group": "",
        "group_num": [
            "reci2mlEXtkaPFrpb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 811,
        "id": "recKGfxG5HByhYbtx",
        "row": 24833,
        "state": "",
        "group": "",
        "group_num": [
            "recMTvDqSaaeoHajb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "id": "rece03LCgsVBB8ITG",
        "row": 24885,
        "state": "",
        "group": "5Q5022",
        "group_num": [
            "recqMCCbx6ZczRKDU"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "id": "recez8suDds5hCqho",
        "row": 24860,
        "state": "",
        "group": "4Q9727",
        "group_num": [
            "recHzb0VHBxxyKwnF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 331,
        "id": "receE5gTtXhevTe4L",
        "row": 24901,
        "state": "",
        "group": "",
        "group_num": [
            "rectwEGTMK8cPEdxg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 573,
        "id": "receMw0kotkzQ50g3",
        "row": 24889,
        "state": "",
        "group": "",
        "group_num": [
            "recU8gmg4xrVgdcUd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "id": "rece7he7e4fqJEHbC",
        "row": 24870,
        "state": "",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 164,
        "id": "recgvMks9dDLhu4lJ",
        "row": 24892,
        "state": "",
        "group": "",
        "group_num": [
            "reclSiq9bX89vEAaC"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 281,
        "id": "reciuBPpJIcJvNLtV",
        "row": 24890,
        "state": "",
        "group": "",
        "group_num": [
            "recU8gmg4xrVgdcUd"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "reciMqIt1zqhlZkNZ",
        "row": 24900,
        "state": "",
        "group": "",
        "group_num": [
            "recWm3RbJkB0hkugf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 491,
        "id": "recjhOtKpTI0T0agh",
        "row": 24863,
        "state": "",
        "group": "",
        "group_num": [
            "recCyRbXjsBrEtxqu"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 551,
        "id": "reckwLrFEBzDsU8jB",
        "row": 24875,
        "state": "",
        "group": "",
        "group_num": [
            "recxTt11XGgV3M9fy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "id": "reckAufpPXPRmmUhL",
        "row": 24898,
        "state": "",
        "group": "",
        "group_num": [
            "reckodSuQcTllBFNG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "reck3V8TwqEq9dunc",
        "row": 24895,
        "state": "",
        "group": "",
        "group_num": [
            "recfQSQZ0LWIuCoB2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "reclVcRvVzQWRsQqs",
        "row": 24877,
        "state": "",
        "group": "5F3433",
        "group_num": [
            "rec6eofyVWDxW77Jw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 571,
        "id": "recmpbJ4qnA7h3GXl",
        "row": 24873,
        "state": "",
        "group": "",
        "group_num": [
            "recGaw7vd4Dv8KzVF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 278,
        "id": "recO6dMc0n6xxLTgm",
        "row": 24884,
        "state": "",
        "group": "5Q5022",
        "group_num": [
            "recqMCCbx6ZczRKDU"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 90,
        "id": "recRjpPDSvOl2BmkL",
        "row": 24879,
        "state": "",
        "group": "5F4148",
        "group_num": [
            "recMhLDVeEt1TAdWz"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 281,
        "id": "recUA1QgOo41djUMI",
        "row": 24874,
        "state": "",
        "group": "",
        "group_num": [
            "recGaw7vd4Dv8KzVF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 939,
        "id": "recXuFh4ieZMQLsAq",
        "row": 24872,
        "state": "",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 524,
        "id": "recXkYmC0urEy6GlP",
        "row": 24887,
        "state": "",
        "group": "6F5527",
        "group_num": [
            "recIADS4BIDUCihEr"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 139,
        "id": "rec0Wa4GdwxXXPCiG",
        "row": 24866,
        "state": "",
        "group": "",
        "group_num": [
            "rechxlq4d5fr3NInM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 542,
        "id": "rec1g5pbDwr4VBZE5",
        "row": 24904,
        "state": "",
        "group": "",
        "group_num": [
            "recO29gSbOMEgYqWB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "id": "rec5NYPOsfIpPrXRz",
        "row": 24886,
        "state": "",
        "group": "",
        "group_num": [
            "rec21dahPKLQF9ZhG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 836,
        "id": "rec6uuBvE65Gy8S9A",
        "row": 24888,
        "state": "",
        "group": "6F5527",
        "group_num": [
            "recIADS4BIDUCihEr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 260,
        "id": "rec6rxdEt9Z1i9tB2",
        "row": 24864,
        "state": "",
        "group": "",
        "group_num": [
            "rechxlq4d5fr3NInM"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 274,
        "id": "rec7qFJPCZ7UYvmQb",
        "row": 24897,
        "state": "",
        "group": "",
        "group_num": [
            "reckodSuQcTllBFNG"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "rec8HdOkrgBgL3jyF",
        "row": 24902,
        "state": "",
        "group": "",
        "group_num": [
            "rectwEGTMK8cPEdxg"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "id": "rec9ZpgxU8kELQe03",
        "row": 24878,
        "state": "",
        "group": "5F3433",
        "group_num": [
            "rec6eofyVWDxW77Jw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 867,
        "id": "recb2tmy5nCotgI3k",
        "row": 24876,
        "state": "",
        "group": "",
        "group_num": [
            "recxTt11XGgV3M9fy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "recbiuaf0DGbuTO44",
        "row": 24881,
        "state": "",
        "group": "5L5050",
        "group_num": [
            "rec167XUbyD5feACB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 355,
        "id": "recozIQ4kjdMQU7yx",
        "row": 24859,
        "state": "",
        "group": "",
        "group_num": [
            "recNp6uGtta5vNXbj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "id": "reco3FGMUYdj9wtM5",
        "row": 24893,
        "state": "",
        "group": "",
        "group_num": [
            "rec7HRY1Xbk7gJNVs"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1150,
        "id": "recoate3C16xvJphW",
        "row": 24896,
        "state": "",
        "group": "",
        "group_num": [
            "reckodSuQcTllBFNG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "recpWSNHio2YrVq64",
        "row": 24899,
        "state": "",
        "group": "",
        "group_num": [
            "recbxAFQRqoc3Q5pO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recqSL8ClZoI79722",
        "row": 24891,
        "state": "",
        "group": "",
        "group_num": [
            "reclSiq9bX89vEAaC"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "2024": 149,
        "id": "recr9FF58yqeV5Noj",
        "row": 24857,
        "state": "",
        "group": "",
        "group_num": [
            "recfoUwaAJdA7cr0Y"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 925,
        "id": "recsLy52TVBDIBGK3",
        "row": 24906,
        "state": "",
        "group": "",
        "group_num": [
            "recjIutkqRFEMJdiL"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 512,
        "2024": 512,
        "id": "recu3i8XwRv4w1w0i",
        "row": 24880,
        "state": "",
        "group": "5L3966",
        "group_num": [
            "recZnLI3CM0uwmpSw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recv4BlC2wGpckKmR",
        "row": 24905,
        "state": "",
        "group": "",
        "group_num": [
            "recjIutkqRFEMJdiL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 555,
        "id": "recxW0m6LOLGuyCpd",
        "row": 24867,
        "state": "",
        "group": "4Y5713",
        "group_num": [
            "recQx7T2XafxNn6ij"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recxoqq2930zcqpYA",
        "row": 24894,
        "state": "",
        "group": "6S2268",
        "group_num": [
            "recWoebcgMOwbl4Rp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 892,
        "id": "reczRMXCPE8OMl9Ta",
        "row": 24882,
        "state": "",
        "group": "5P1291",
        "group_num": [
            "recrCo4FidCg4JyEe"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 872,
        "id": "recBKYJEym5DtBIhH",
        "row": 24868,
        "state": "",
        "group": "4Y5713",
        "group_num": [
            "recQx7T2XafxNn6ij"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 556,
        "id": "recCds26Iw9I4rV2s",
        "row": 24862,
        "state": "",
        "group": "",
        "group_num": [
            "reciXh8KvKeLfL0b5"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "id": "recClILifvKRJhNbz",
        "row": 24865,
        "state": "",
        "group": "",
        "group_num": [
            "rechxlq4d5fr3NInM"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 611,
        "id": "recDEj0Xlx3MHelCl",
        "row": 24869,
        "state": "",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 154,
        "id": "recE0iySomodjoQxi",
        "row": 24871,
        "state": "",
        "group": "4Y7690",
        "group_num": [
            "rec4cXfCSID0CYQqd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 524,
        "id": "recHlgP9Xt7ynJ5Ty",
        "row": 24861,
        "state": "",
        "group": "4W5784",
        "group_num": [
            "reckQUXk9kSjNptYm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 836,
        "id": "recNFbHYdlQXyonpW",
        "row": 24883,
        "state": "",
        "group": "5P1291",
        "group_num": [
            "recrCo4FidCg4JyEe"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "recNLJkOxd1FQAVRm",
        "row": 24858,
        "state": "",
        "group": "",
        "group_num": [
            "reclUmNPklhPKy0uy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 175,
        "id": "recNNr5B0GBcFFbAJ",
        "row": 24903,
        "state": "",
        "group": "",
        "group_num": [
            "rectwEGTMK8cPEdxg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 21,
        "id": "receN0bEHeQth2ZRV",
        "row": 24946,
        "state": "",
        "group": "",
        "group_num": [
            "recUBFEFD4jHkc8ol"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 524,
        "id": "recfIh783gaXHXhMf",
        "row": 24908,
        "state": "",
        "group": "7L3961",
        "group_num": [
            "recPxK3l7dpwNAZwY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 847,
        "id": "recgHAgOXNOrtqy87",
        "row": 24951,
        "state": "",
        "group": "",
        "group_num": [
            "recRTE92DsbVcken8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 262,
        "id": "recg6gHOqoNdRTylS",
        "row": 24924,
        "state": "",
        "group": "",
        "group_num": [
            "recvP8INmNKGKAkyB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1483,
        "id": "rech46JLRwYA4JI0a",
        "row": 24953,
        "state": "",
        "group": "32",
        "group_num": [
            "receBAB5aTOAo2vJf"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 24,
        "id": "reci02g4JciAR440N",
        "row": 24920,
        "state": "",
        "group": "",
        "group_num": [
            "rec2xrUqFDmImX3lP"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "id": "reciFMuMkLyjXLgUB",
        "row": 24937,
        "state": "",
        "group": "9L8730",
        "group_num": [
            "receDWgvn3q7EzXAn"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 466,
        "id": "recjh8ulH2Qscd9ir",
        "row": 24911,
        "state": "",
        "group": "7Q7140",
        "group_num": [
            "recuKSo0HrcOpygQP"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 266,
        "id": "reck6kjeRoH3auuFF",
        "row": 24912,
        "state": "",
        "group": "7S2832",
        "group_num": [
            "recWM7jvKygHR5n1p"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1764,
        "id": "recOofjgMRm3UfrwK",
        "row": 24954,
        "state": "",
        "group": "32",
        "group_num": [
            "receBAB5aTOAo2vJf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 246,
        "id": "recRIz0EpcbAKZQx5",
        "row": 24944,
        "state": "",
        "group": "9R2349",
        "group_num": [
            "recthqYU0dHiFaMGO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 603,
        "id": "recR2eS3RBPdcSQn7",
        "row": 24929,
        "state": "",
        "group": "",
        "group_num": [
            "recoiWPKhFAWPWQCQ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "id": "recRmHJzzPIayhkiM",
        "row": 24938,
        "state": "",
        "group": "9M1047",
        "group_num": [
            "recK20FVSHfvHz3J1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 836,
        "id": "recWLankoNaEIKJ8B",
        "row": 24909,
        "state": "",
        "group": "7L3961",
        "group_num": [
            "recPxK3l7dpwNAZwY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "id": "recXB29RPhP4gGdj9",
        "row": 24928,
        "state": "",
        "group": "8X5451",
        "group_num": [
            "rec21S149sUfxlS1t"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 867,
        "id": "rec0tVvUZIpE7QsyP",
        "row": 24933,
        "state": "",
        "group": "9L4485",
        "group_num": [
            "recoIGDkJ01ytp5Gz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "rec0dCncRS5wBpbWq",
        "row": 24940,
        "state": "",
        "group": "9M1047",
        "group_num": [
            "recK20FVSHfvHz3J1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 138,
        "id": "rec1SYH1SO0oEptLL",
        "row": 24942,
        "state": "",
        "group": "",
        "group_num": [
            "recACZfYGtLHl8gSr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "rec2uB9hmBnKuGBO0",
        "row": 24931,
        "state": "",
        "group": "",
        "group_num": [
            "rec7y5TCMZXzFUYqD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 339,
        "id": "rec5samiMrg3mxlBW",
        "row": 24934,
        "state": "",
        "group": "",
        "group_num": [
            "recSTlmKMxqyxPYC1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 294,
        "id": "rec5vZUJ8GQ2Lq1w2",
        "row": 24926,
        "state": "",
        "group": "8X5451",
        "group_num": [
            "rec21S149sUfxlS1t"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3120,
        "id": "rec6F1Yg5JGClVV7a",
        "row": 24956,
        "state": "",
        "group": "00832",
        "group_num": [
            "recYcPK7htW5EbvPl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "id": "rec7HA2tBmcVfqJcB",
        "row": 24936,
        "state": "",
        "group": "",
        "group_num": [
            "recSTlmKMxqyxPYC1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 544,
        "id": "rec9UCaPGhchB1vxv",
        "row": 24914,
        "state": "",
        "group": "",
        "group_num": [
            "recDDWp9KIvu8MKW8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 557,
        "id": "recdWzfejLisCqhgj",
        "row": 24952,
        "state": "",
        "group": "",
        "group_num": [
            "recFFJoPbdpcMGY4O"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 23,
        "id": "recdpfU90D8Ema8BV",
        "row": 24935,
        "state": "",
        "group": "",
        "group_num": [
            "recSTlmKMxqyxPYC1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 856,
        "id": "recpWAOprdVYCAU3w",
        "row": 24941,
        "state": "",
        "group": "",
        "group_num": [
            "rect11hZPOZSipgiy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1170,
        "id": "recqpBkzDFYuXFS0x",
        "row": 24916,
        "state": "",
        "group": "7X0265",
        "group_num": [
            "recU8SIm3UWcQHtq9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recrx39Heghe0PSjo",
        "row": 24927,
        "state": "",
        "group": "8X5451",
        "group_num": [
            "rec21S149sUfxlS1t"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1140,
        "id": "recsGStfOhkKc2XTX",
        "row": 24955,
        "state": "",
        "group": "00832",
        "group_num": [
            "recYcPK7htW5EbvPl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recusFrQm5JKhNs45",
        "row": 24948,
        "state": "",
        "group": "",
        "group_num": [
            "rectWjXfcgXBVQ9Dv"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 139,
        "id": "recwpJgoLuwpLxBR1",
        "row": 24921,
        "state": "",
        "group": "",
        "group_num": [
            "rec2xrUqFDmImX3lP"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 11,
        "id": "recxUj14nLUZKb5NZ",
        "row": 24949,
        "state": "",
        "group": "",
        "group_num": [
            "rec8IPyMBPBrFqkui"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "id": "recxZyBlZrw3Etsf1",
        "row": 24932,
        "state": "",
        "group": "",
        "group_num": [
            "rec4UTI7lP6qNdw14"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 488,
        "id": "recxawNqsf7VgsqPx",
        "row": 24943,
        "state": "",
        "group": "9R2349",
        "group_num": [
            "recthqYU0dHiFaMGO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "recALvkMewujKXuKo",
        "row": 24925,
        "state": "",
        "group": "",
        "group_num": [
            "recvP8INmNKGKAkyB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2800,
        "id": "recCsFmW98cps1bpP",
        "row": 24945,
        "state": "",
        "group": "",
        "group_num": [
            "recyWPYPKYAN1GquK"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "recCyC3UaJMWtEBRu",
        "row": 24950,
        "state": "",
        "group": "9X5383",
        "group_num": [
            "recqofVK0WGRITw65"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 581,
        "id": "recD4uj0JFgNkLVC8",
        "row": 24918,
        "state": "",
        "group": "",
        "group_num": [
            "reczN5GTymQRTttGi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recJulP2wRhKQlNBS",
        "row": 24947,
        "state": "",
        "group": "",
        "group_num": [
            "recUBFEFD4jHkc8ol"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recLXVJGr9iJxZLiw",
        "row": 24919,
        "state": "",
        "group": "",
        "group_num": [
            "reczN5GTymQRTttGi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 281,
        "id": "recLtATdnzAwas8ZA",
        "row": 24930,
        "state": "",
        "group": "",
        "group_num": [
            "recoiWPKhFAWPWQCQ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 734,
        "id": "recLwRsmsgjiOGh6d",
        "row": 24915,
        "state": "",
        "group": "7X0265",
        "group_num": [
            "recU8SIm3UWcQHtq9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 480,
        "id": "recLKpRyHTQLsXL8E",
        "row": 24922,
        "state": "",
        "group": "8W0308",
        "group_num": [
            "recrU4TpTR92UGVtd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recL2hfE3Jrfz0i1T",
        "row": 24910,
        "state": "",
        "group": "",
        "group_num": [
            "rec6ovISbjqMgjlNw"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "2024": 355,
        "id": "recMVQGE1jLGuIi3m",
        "row": 24913,
        "state": "",
        "group": "",
        "group_num": [
            "recxLH9R4SMyq26r0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "2023": 146,
        "id": "recMvQLPf61Tmj9rt",
        "row": 24923,
        "state": "",
        "group": "",
        "group_num": [
            "recSDwaury8NFyXMT"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 276,
        "id": "recN57GrOp2gdDahR",
        "row": 24939,
        "state": "",
        "group": "9M1047",
        "group_num": [
            "recK20FVSHfvHz3J1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "id": "recNlQMV0Q5c4TflK",
        "row": 24917,
        "state": "",
        "group": "7Y2959",
        "group_num": [
            "recbhcoK4Xrt47Wqd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recNqgrlaBtyxdqEc",
        "row": 24907,
        "state": "",
        "group": "",
        "group_num": [
            "rec2TLPf8Q6nHFWUZ"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3360,
        "2023": 3840,
        "id": "recfBZWnOhttB6uH8",
        "row": 24973,
        "state": "",
        "group": "15457",
        "group_num": [
            "recErZfnfE7ZopeRw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 791,
        "2023": 157,
        "id": "recfRMzT5kdNahhtC",
        "row": 25000,
        "state": "",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1377,
        "id": "recgjPF55GTo5wEy7",
        "row": 24958,
        "state": "",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1080,
        "id": "rechbC5ktq5LDddhX",
        "row": 24962,
        "state": "",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1541,
        "id": "recj3tcu6qmTWWBBe",
        "row": 24969,
        "state": "",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 792,
        "2023": 792,
        "id": "reclmTmzugpxfPMoh",
        "row": 24964,
        "state": "",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "id": "recm3u29yVVLK6kkP",
        "row": 24959,
        "state": "",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 613,
        "id": "recmaV4aAYVDARM3c",
        "row": 25001,
        "state": "",
        "group": "",
        "group_num": [
            "recLnGsHN6IuOvKmu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "recnxZidit64VNrP2",
        "row": 24989,
        "state": "",
        "group": "",
        "group_num": [
            "recVbVz3lbdmDxtAK"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1104,
        "id": "recRENOqtQeWag91I",
        "row": 24994,
        "state": "",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 32,
        "2023": 29,
        "id": "recSss6fp6jPNW2TY",
        "row": 25005,
        "state": "",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 322,
        "id": "recS9srYPmImmuL1V",
        "row": 24984,
        "state": "",
        "group": "80860",
        "group_num": [
            "rec8wKjxXIi9jYIki",
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1280,
        "id": "recT6jjtdv7VwOFxo",
        "row": 24975,
        "state": "",
        "group": "",
        "group_num": [
            "recfWYGoSEPnxuVYa"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4008,
        "2024": 1463,
        "id": "recU8R8HDpXb8FRj2",
        "row": 24970,
        "state": "",
        "group": "15445",
        "group_num": [
            "recndWZXjmZAE0pDp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "recWWRkTVWSBhQNk0",
        "row": 25006,
        "state": "",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 779,
        "id": "recW4wkNd49GInJOP",
        "row": 24995,
        "state": "",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 548,
        "id": "rec07OVtiSkEpw8E4",
        "row": 24998,
        "state": "",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1166,
        "id": "rec15GUn0f1pLk1m9",
        "row": 24961,
        "state": "",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "rec3GMV3dD6s3o8zv",
        "row": 24983,
        "state": "",
        "group": "80860",
        "group_num": [
            "rec8wKjxXIi9jYIki",
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2979,
        "2023": 3845,
        "id": "rec3lKh4SzXifzRrt",
        "row": 24993,
        "state": "",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "2023": 326,
        "id": "rec4vcxihdnpGkCG2",
        "row": 24966,
        "state": "",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "rec4IdJPtl7s1vsgW",
        "row": 24957,
        "state": "",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "rec4OiCxyiI6ZFiAA",
        "row": 24988,
        "state": "",
        "group": "",
        "group_num": [
            "recVbVz3lbdmDxtAK"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 928,
        "id": "rec4cnoyi8fK9faMI",
        "row": 24967,
        "state": "",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "rec5TRBdCLr49YDlh",
        "row": 24976,
        "state": "",
        "group": "",
        "group_num": [
            "recfWYGoSEPnxuVYa"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3300,
        "id": "rec5couBh5RMwpXze",
        "row": 24968,
        "state": "",
        "group": "15388",
        "group_num": [
            "recNzDrFbikErKH5a"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 603,
        "2023": 576,
        "2024": 640,
        "id": "rec9Y6ZFdeFydHpu1",
        "row": 25003,
        "state": "",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 587,
        "2023": 577,
        "id": "recbLktyyPaRvz4Lo",
        "row": 24965,
        "state": "",
        "group": "15296",
        "group_num": [
            "reck4G0f8r5V6TP1C"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1978,
        "id": "recbNi32tLqB7gPSh",
        "row": 24971,
        "state": "",
        "group": "15447",
        "group_num": [
            "recZXq55QAYDTfCQD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 27,
        "id": "reccJcTuNHe2baqKN",
        "row": 24990,
        "state": "",
        "group": "",
        "group_num": [
            "recaYtSm6VYBjwHBu"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3700,
        "id": "recoVEi8vWNHsKvMm",
        "row": 24974,
        "state": "",
        "group": "",
        "group_num": [
            "recfWYGoSEPnxuVYa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "id": "recqYb3TAQBiHltS6",
        "row": 24987,
        "state": "",
        "group": "",
        "group_num": [
            "reckMyOpx2F9p4OYE"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "recsGVOvvu9iAvgwO",
        "row": 24986,
        "state": "",
        "group": "",
        "group_num": [
            "reckMyOpx2F9p4OYE"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 324,
        "2023": 277,
        "id": "rectNOWJl847ecYx6",
        "row": 25004,
        "state": "",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 3325,
        "id": "recwFMSE4RiYcSjlX",
        "row": 24979,
        "state": "",
        "group": "80440",
        "group_num": [
            "recfANQzqc9G9NyyF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recwNqqGlWXNA48cG",
        "row": 25002,
        "state": "",
        "group": "",
        "group_num": [
            "recLnGsHN6IuOvKmu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "2023": 1600,
        "id": "recx3ofRRJUdKRy71",
        "row": 24985,
        "state": "",
        "group": "",
        "group_num": [
            "reckMyOpx2F9p4OYE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3627,
        "id": "reczFlX4iMBX4TLWH",
        "row": 24996,
        "state": "",
        "group": "023000",
        "group_num": [
            "rec6hr8ORIMbK0hMl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recza8jKFynaf9cFH",
        "row": 24977,
        "state": "",
        "group": "",
        "group_num": [
            "recOtAb7J1BDJV2oF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2010,
        "id": "recDKPJLpNdxizbD0",
        "row": 24991,
        "state": "",
        "group": "",
        "group_num": [
            "recFMZdnjmOFtrAjd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2915,
        "id": "recEDWMA2iblZ79rC",
        "row": 24960,
        "state": "",
        "group": "10530",
        "group_num": [
            "recxCCS6ShVIiJ5A4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1240,
        "id": "recErmhYVcNMMZbyz",
        "row": 24992,
        "state": "",
        "group": "",
        "group_num": [
            "recFMZdnjmOFtrAjd"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2660,
        "id": "recErLkLzbZAdq6nI",
        "row": 24972,
        "state": "",
        "group": "15457",
        "group_num": [
            "recErZfnfE7ZopeRw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "id": "recFCXLvBaFsXcebu",
        "row": 24981,
        "state": "",
        "group": "80860",
        "group_num": [
            "rec8wKjxXIi9jYIki"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 614,
        "id": "recFlvRL8933hfPRU",
        "row": 24997,
        "state": "",
        "group": "",
        "group_num": [
            "recjCBxDF39keS3LP"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "recGQGGaapIkahW0Q",
        "row": 24982,
        "state": "",
        "group": "80860",
        "group_num": [
            "rec8wKjxXIi9jYIki"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 113,
        "id": "recGrJr3kdbQSLJvL",
        "row": 24963,
        "state": "",
        "group": "12130",
        "group_num": [
            "recygvsLOomXQ6C9j"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 213,
        "id": "recJOMmaLE8VqU9FN",
        "row": 24978,
        "state": "",
        "group": "",
        "group_num": [
            "recQccH2hYPAisH1p"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 90,
        "id": "recJQW0aJpFp23Ym3",
        "row": 24999,
        "state": "",
        "group": "108000",
        "group_num": [
            "rec9lPc7XqDGJ2sa2"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2188,
        "id": "recMPz7LD5JsfZEDL",
        "row": 24980,
        "state": "",
        "group": "80440",
        "group_num": [
            "recfANQzqc9G9NyyF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 298,
        "id": "recfuXTyP2DEYBrzL",
        "row": 25037,
        "state": "",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 6000,
        "id": "recg1VnuXIG8z0ihi",
        "row": 25010,
        "state": "",
        "group": "174189PDA7",
        "group_num": [
            "recq2OsV8SZByG4mk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 6000,
        "id": "recg6ED62ZlcSyZXF",
        "row": 25019,
        "state": "",
        "group": "182232",
        "group_num": [
            "reciYdWmLGxc7r7dB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 176,
        "2023": 433,
        "2024": 266,
        "id": "recg7gS0DoxzT0fNr",
        "row": 25047,
        "state": "",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 301,
        "2023": 334,
        "id": "rechXm6mA1uIAaIyc",
        "row": 25021,
        "state": "",
        "group": "",
        "group_num": [
            "rec3mtXYPfsPq4ruv"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 267,
        "id": "reclLMwu8NnipiSSg",
        "row": 25033,
        "state": "",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 162,
        "2023": 178,
        "2024": 188,
        "id": "recllMSQqXvcsR5FY",
        "row": 25023,
        "state": "",
        "group": "",
        "group_num": [
            "rec3mtXYPfsPq4ruv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recnxtsWXa8OWYyZT",
        "row": 25014,
        "state": "",
        "group": "182019",
        "group_num": [
            "recF9SecLWXiZkNbZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "id": "recnivKrYmA8JpB9u",
        "row": 25015,
        "state": "",
        "group": "182019",
        "group_num": [
            "recF9SecLWXiZkNbZ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 63,
        "id": "recOS32cj4coxUDdl",
        "row": 25054,
        "state": "",
        "group": "196819",
        "group_num": [
            "recKJN0MJSKGJKYNW"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 252,
        "2023": 286,
        "id": "recOw9G6eieprCFQ2",
        "row": 25045,
        "state": "",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "2024": 22,
        "id": "recOIx7i91UesYln1",
        "row": 25046,
        "state": "",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "id": "recON55iKNedgnl83",
        "row": 25042,
        "state": "",
        "group": "191690",
        "group_num": [
            "rec3yY2185u4qq3kY"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4178,
        "id": "recSDDNeuibHiBQSd",
        "row": 25053,
        "state": "",
        "group": "196819",
        "group_num": [
            "recKJN0MJSKGJKYNW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 196,
        "id": "recSqfqersUnYGuAl",
        "row": 25013,
        "state": "",
        "group": "",
        "group_num": [
            "recNh0Iqt4qlkYX8X"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 765,
        "id": "recTEP2yze5ihhlDa",
        "row": 25020,
        "state": "",
        "group": "",
        "group_num": [
            "rec3mtXYPfsPq4ruv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "2023": 911,
        "id": "recURyg1ZlGXnGeF1",
        "row": 25035,
        "state": "",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recUkRUeNFh4fUdoq",
        "row": 25009,
        "state": "",
        "group": "",
        "group_num": [
            "rec3TH0yyF42APWMu"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 197,
        "id": "recWZWZSeh4U4Y3Pu",
        "row": 25034,
        "state": "",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 726,
        "id": "recWMgJKBrRSUTiGm",
        "row": 25050,
        "state": "",
        "group": "192988",
        "group_num": [
            "recUKj31TUzBcXRWq"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "id": "recWcT44nbvk4jf66",
        "row": 25011,
        "state": "",
        "group": "",
        "group_num": [
            "recn2GWrEkQwBwiDX"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "2024": 2400,
        "id": "recXZcu7l2ISfNS5x",
        "row": 25028,
        "state": "",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 500,
        "id": "recXarSGVNVFzY6lP",
        "row": 25043,
        "state": "",
        "group": "191690",
        "group_num": [
            "rec3yY2185u4qq3kY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 243,
        "id": "recYqkn8HOCzTXoRG",
        "row": 25017,
        "state": "",
        "group": "182019",
        "group_num": [
            "recF9SecLWXiZkNbZ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3375,
        "id": "recZC5CjWbYgTUtpH",
        "row": 25055,
        "state": "",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4507,
        "2023": 2400,
        "id": "rec15v1RwHEomU7TW",
        "row": 25031,
        "state": "",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 239,
        "2023": 240,
        "2024": 240,
        "id": "rec217RNLR3v3AVz2",
        "row": 25016,
        "state": "",
        "group": "182019",
        "group_num": [
            "recF9SecLWXiZkNbZ"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 930,
        "2023": 822,
        "2024": 995,
        "id": "rec3ONP5pMgX8vyJG",
        "row": 25007,
        "state": "",
        "group": "168504",
        "group_num": [
            "reccurwdvZLgHfe2u"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "id": "rec40w2L7MfBZfMGJ",
        "row": 25038,
        "state": "",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2217,
        "id": "rec7Ti1b6XGhKKHBM",
        "row": 25018,
        "state": "",
        "group": "182019",
        "group_num": [
            "recF9SecLWXiZkNbZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1113,
        "2023": 1008,
        "id": "rec7diQjLbxv3TRWj",
        "row": 25027,
        "state": "",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "id": "recoicM6IVh6BYAYT",
        "row": 25012,
        "state": "",
        "group": "",
        "group_num": [
            "recn2GWrEkQwBwiDX"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "2024": 1920,
        "id": "recpkCk56pc258XmH",
        "row": 25024,
        "state": "",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 759,
        "2023": 973,
        "2024": 995,
        "id": "recqrL33ePnQ9jrZm",
        "row": 25048,
        "state": "",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2498,
        "2023": 954,
        "id": "recsMUoHgsBl6z9F1",
        "row": 25039,
        "state": "",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1559,
        "2023": 448,
        "id": "recuCxaLqST874RYP",
        "row": 25032,
        "state": "",
        "group": "185002",
        "group_num": [
            "reclV9pWIktvynfYb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 564,
        "2023": 618,
        "2024": 630,
        "id": "recuPklL3vOYw8zNW",
        "row": 25044,
        "state": "",
        "group": "192086",
        "group_num": [
            "recjEGPaG7Ir4WLH9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2756,
        "2023": 3562,
        "id": "recwXtFJ9bmbIpT9n",
        "row": 25049,
        "state": "",
        "group": "192988",
        "group_num": [
            "recUKj31TUzBcXRWq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 513,
        "id": "recwfuc1dWE2wd9E7",
        "row": 25030,
        "state": "",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "recxeMRSeAkph9nRq",
        "row": 25036,
        "state": "",
        "group": "186359",
        "group_num": [
            "rec0rjRJWLtbZ6fur"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "id": "reczztLRc1IDS3REw",
        "row": 25056,
        "state": "",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "reczmLOGkaJ2UjRwr",
        "row": 25022,
        "state": "",
        "group": "",
        "group_num": [
            "rec3mtXYPfsPq4ruv"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 90,
        "2023": 100,
        "id": "recAJSh5OrlX7fQZd",
        "row": 25026,
        "state": "",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3109,
        "id": "recBO2Cc0XTiPRCVU",
        "row": 25051,
        "state": "",
        "group": "192988",
        "group_num": [
            "recUKj31TUzBcXRWq"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2130,
        "2023": 2103,
        "2024": 1900,
        "id": "recCp0crUwvs0efo8",
        "row": 25029,
        "state": "",
        "group": "184514",
        "group_num": [
            "rectkkzz7kYrwU1Xu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 354,
        "2023": 367,
        "id": "recETgm12b0mo1GT9",
        "row": 25041,
        "state": "",
        "group": "189897",
        "group_num": [
            "reck9JAVPbC7v6txG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 94,
        "2023": 178,
        "id": "recEYZnVgRDTGlKmA",
        "row": 25040,
        "state": "",
        "group": "189897",
        "group_num": [
            "reck9JAVPbC7v6txG"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1576,
        "id": "recFmcepM3ODGfgnQ",
        "row": 25052,
        "state": "",
        "group": "193843",
        "group_num": [
            "rec68rRFYyFJaCRRC"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1188,
        "2023": 1328,
        "2024": 1350,
        "id": "recKXIfSNZoon6f4h",
        "row": 25025,
        "state": "",
        "group": "183644",
        "group_num": [
            "recrG33XIwSUto24s"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 25,
        "id": "recLR1NTwcyYpBBQZ",
        "row": 25008,
        "state": "",
        "group": "",
        "group_num": [
            "rec3TH0yyF42APWMu"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2655,
        "2023": 2655,
        "id": "recedyA3oTbqp0dVU",
        "row": 25057,
        "state": "",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2695,
        "id": "recfXsxitM50rjJle",
        "row": 25067,
        "state": "",
        "group": "204781",
        "group_num": [
            "recI4MaSQ9VLASTK5"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "reciqaTNys7Nhlon5",
        "row": 25092,
        "state": "",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "2023": 179,
        "2024": 178,
        "id": "recjHXMtxHqyv0eUq",
        "row": 25071,
        "state": "",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 874,
        "id": "reck2qJEwwhPu18vT",
        "row": 25068,
        "state": "",
        "group": "204781",
        "group_num": [
            "recI4MaSQ9VLASTK5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 275,
        "2023": 222,
        "id": "reclysFL9qTDW2xKI",
        "row": 25072,
        "state": "",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 941,
        "id": "recm94xq3F7jYtoYP",
        "row": 25082,
        "state": "",
        "group": "",
        "group_num": [
            "recPG6RmirERZVSnH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recO3OOJTyIb8jsGG",
        "row": 25066,
        "state": "",
        "group": "203922",
        "group_num": [
            "rec8URZAGYuXXlDUz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3423,
        "2023": 3157,
        "2024": 2449,
        "id": "recOgh5bsJpfSt1rI",
        "row": 25103,
        "state": "",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1800,
        "id": "recOh0hJH8XPjq1wv",
        "row": 25058,
        "state": "",
        "group": "197944",
        "group_num": [
            "recDuiUeEw7I2Ls7k"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recPB73BcnP8DRJk0",
        "row": 25080,
        "state": "",
        "group": "",
        "group_num": [
            "recQd43H1pZp3B2Kq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1523,
        "id": "recRtCdou9BeYLQqw",
        "row": 25063,
        "state": "",
        "group": "202970",
        "group_num": [
            "rechEfq7mBTffspFQ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 950,
        "2023": 947,
        "id": "recRFnVmEBo1jkCxt",
        "row": 25086,
        "state": "",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 145,
        "id": "recTTV898dUYTpUd8",
        "row": 25093,
        "state": "",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 554,
        "2023": 703,
        "id": "recTH1LmWW7thVSLI",
        "row": 25095,
        "state": "",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "id": "recVbcAc8szVA5BxF",
        "row": 25084,
        "state": "",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 177,
        "id": "recVbMtcUIEjEjS7g",
        "row": 25085,
        "state": "",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2957,
        "2023": 3417,
        "id": "rec0qAeDxkpzlvVKs",
        "row": 25069,
        "state": "",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "id": "rec4RJG4tw05s1zET",
        "row": 25074,
        "state": "",
        "group": "215825",
        "group_num": [
            "recdiC6OcXWIXYYr0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "rec7td8yzqK37RCoD",
        "row": 25064,
        "state": "",
        "group": "202970",
        "group_num": [
            "rechEfq7mBTffspFQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1875,
        "2023": 186,
        "id": "rec8YIxao9PqzKd9O",
        "row": 25088,
        "state": "",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4500,
        "2023": 2700,
        "id": "rec8LcIHvmDU5noQN",
        "row": 25090,
        "state": "",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 936,
        "id": "recaH7PWTiMf2nwBY",
        "row": 25094,
        "state": "",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 551,
        "id": "reccRk9VMtyBEKPyw",
        "row": 25070,
        "state": "",
        "group": "213902",
        "group_num": [
            "recyib24P1HQ2cWxz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "reccgqn9dkI236iud",
        "row": 25062,
        "state": "",
        "group": "202970",
        "group_num": [
            "rechEfq7mBTffspFQ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2843,
        "2023": 3969,
        "2024": 3442,
        "id": "reccgUn2jmWstcqos",
        "row": 25100,
        "state": "",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "id": "recdvjeqggzKrPJ45",
        "row": 25105,
        "state": "",
        "group": "228733",
        "group_num": [
            "recD7slkTdkq7NBkI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 465,
        "id": "reco15lnsGReAZbV7",
        "row": 25096,
        "state": "",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 134,
        "2023": 237,
        "2024": 188,
        "id": "recozasdwN5xD9Qsz",
        "row": 25098,
        "state": "",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2304,
        "id": "recoJWXSP4tCze7qe",
        "row": 25061,
        "state": "",
        "group": "",
        "group_num": [
            "recUijGQ6rkuRn7wM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 872,
        "id": "reco8RQ3lAwhT6GcC",
        "row": 25099,
        "state": "",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 336,
        "2023": 234,
        "id": "recojNB8HWXmrHRmF",
        "row": 25102,
        "state": "",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1893,
        "2023": 2310,
        "id": "recpiOzP4m65PiqpR",
        "row": 25075,
        "state": "",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 807,
        "id": "recquRIwALHNdDyuR",
        "row": 25059,
        "state": "",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 100,
        "2024": 103,
        "id": "recsjnxCtOHIWPEoj",
        "row": 25097,
        "state": "",
        "group": "228222",
        "group_num": [
            "recL9fSCUVIYZbpEx"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recvwIeDFFJZGgLvk",
        "row": 25065,
        "state": "",
        "group": "203922",
        "group_num": [
            "rec8URZAGYuXXlDUz"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 706,
        "id": "recxw46IiaNhzIRcc",
        "row": 25081,
        "state": "",
        "group": "",
        "group_num": [
            "recQd43H1pZp3B2Kq"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1029,
        "2023": 1029,
        "id": "recyiDm01WV1m4PWu",
        "row": 25106,
        "state": "",
        "group": "",
        "group_num": [
            "recJQvj2AKosjCa9P"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1075,
        "2023": 947,
        "id": "reczJ5b3hncaCIx80",
        "row": 25076,
        "state": "",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1200,
        "id": "recz54o1cc0d5c8Ow",
        "row": 25079,
        "state": "",
        "group": "",
        "group_num": [
            "recHNnhnM6ZqZCEJg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5493,
        "id": "recA8Zfsm1dUcLuhH",
        "row": 25104,
        "state": "",
        "group": "",
        "group_num": [
            "rec4vaMqcUYiiGqUy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 578,
        "id": "recBj4RIT5xXKg58Y",
        "row": 25083,
        "state": "",
        "group": "222244",
        "group_num": [
            "recEUo6v8HTTg5u1I"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "2024": 647,
        "id": "recCD2WKbjmyvHoAR",
        "row": 25101,
        "state": "",
        "group": "228485",
        "group_num": [
            "recTYPucH2SnyqbIi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2155,
        "id": "recCnmj6yfo8tJz1J",
        "row": 25077,
        "state": "",
        "group": "216280",
        "group_num": [
            "recLWtsLi2amZVUi0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1140,
        "id": "recDSZXqPIyODqX1K",
        "row": 25073,
        "state": "",
        "group": "214279",
        "group_num": [
            "recOoKuixyvEOIso1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recDkpu3bADGoEAR3",
        "row": 25089,
        "state": "",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2714,
        "id": "recFnyPzjQjHGVV2B",
        "row": 25087,
        "state": "",
        "group": "226310",
        "group_num": [
            "recaRuAmESdS2ozzS"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 485,
        "2023": 546,
        "id": "recG7geTbP1R8aKop",
        "row": 25091,
        "state": "",
        "group": "227943",
        "group_num": [
            "recsTY8Xo9OF9iScY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1680,
        "2024": 1680,
        "id": "recHIHPJkfhpQ1tyW",
        "row": 25078,
        "state": "",
        "group": "",
        "group_num": [
            "recHNnhnM6ZqZCEJg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1168,
        "id": "recICKDvM8z21i6q3",
        "row": 25060,
        "state": "",
        "group": "199409",
        "group_num": [
            "recKKXjG5QamWLyDQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 988,
        "id": "receT4XJ2hYTdPnoa",
        "row": 25109,
        "state": "",
        "group": "",
        "group_num": [
            "recpZuAWlaS3mzSg0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1700,
        "id": "rece3Wo1VvgIUxYLb",
        "row": 25126,
        "state": "",
        "group": "",
        "group_num": [
            "recj00yPvkJJSNqOZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 140,
        "id": "recf1cVeXOVQ15dmp",
        "row": 25130,
        "state": "",
        "group": "",
        "group_num": [
            "recPfaOIgZzyRK0p3"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "id": "recf7mVxuJ993hnNQ",
        "row": 25151,
        "state": "",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "id": "recgAOIdb0UMgYaz0",
        "row": 25152,
        "state": "",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 258,
        "id": "recgg3gIOE15ywEPn",
        "row": 25129,
        "state": "",
        "group": "",
        "group_num": [
            "recPfaOIgZzyRK0p3"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "reclefbU0Pp34sPHB",
        "row": 25148,
        "state": "",
        "group": "",
        "group_num": [
            "rec08qfy7yEqkGMnD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 890,
        "id": "recmEM5plpLUYlXBj",
        "row": 25137,
        "state": "",
        "group": "",
        "group_num": [
            "recwe6wtsaoJTHm26"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 660,
        "id": "recmGhTUtbyyzGB8R",
        "row": 25110,
        "state": "",
        "group": "",
        "group_num": [
            "recpZuAWlaS3mzSg0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2820,
        "id": "recmiPeHWg3TMkddn",
        "row": 25114,
        "state": "",
        "group": "",
        "group_num": [
            "reczGcHyk2vIgcHGa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 127,
        "2023": 127,
        "id": "recmoKFN8Olf7fJUC",
        "row": 25124,
        "state": "",
        "group": "",
        "group_num": [
            "rechGtrxzB1e4muqr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 216,
        "2023": 216,
        "id": "recnTbdSErQJGEB95",
        "row": 25113,
        "state": "",
        "group": "",
        "group_num": [
            "reczGcHyk2vIgcHGa"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1826,
        "2023": 2459,
        "id": "recnq734nGhJVC3vL",
        "row": 25119,
        "state": "",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1050,
        "id": "recOvP8H1YuKRrbO0",
        "row": 25116,
        "state": "",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 767,
        "2023": 956,
        "id": "recOxDyj5e9DUG5RD",
        "row": 25108,
        "state": "",
        "group": "",
        "group_num": [
            "recudctzBNj11GMNo"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 626,
        "id": "recObYSIRV2BcY3eB",
        "row": 25132,
        "state": "",
        "group": "",
        "group_num": [
            "recCjzVnWrbkWhqdu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 21,
        "id": "recQjWKQR6ZVbWfqV",
        "row": 25131,
        "state": "",
        "group": "",
        "group_num": [
            "rec5cCATVfI2PRUfp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1008,
        "id": "recR8dhnsxvQwk6Vg",
        "row": 25112,
        "state": "",
        "group": "",
        "group_num": [
            "reczGcHyk2vIgcHGa"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 265,
        "id": "recSHgSQAguhDy1y4",
        "row": 25138,
        "state": "",
        "group": "",
        "group_num": [
            "rec9L10PrMANamnzC"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 69,
        "id": "recVAN4PXmbp5e7KT",
        "row": 25147,
        "state": "",
        "group": "700228",
        "group_num": [
            "rec12geA2GEGsdeIP"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "id": "recWDMCEpcPmHYo9p",
        "row": 25141,
        "state": "",
        "group": "",
        "group_num": [
            "recAxas9HC2MHXr0Y"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1038,
        "2023": 724,
        "id": "recYeP0hlZPCTzw7Z",
        "row": 25118,
        "state": "",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 981,
        "id": "rec3poQgOWmus2bz8",
        "row": 25111,
        "state": "",
        "group": "",
        "group_num": [
            "reczGcHyk2vIgcHGa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1942,
        "id": "rec4ypPlCUzxbiKFu",
        "row": 25107,
        "state": "",
        "group": "",
        "group_num": [
            "recudctzBNj11GMNo"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4004,
        "id": "rec6UsCREmlNxMMYx",
        "row": 25144,
        "state": "",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "id": "rec8HRDYFEiYYy8bS",
        "row": 25140,
        "state": "",
        "group": "587767",
        "group_num": [
            "recRuo6SJcSFamQLj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 446,
        "id": "rec90Mo0w2hiLn5Fz",
        "row": 25154,
        "state": "",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recaX6rdiXepHRndR",
        "row": 25150,
        "state": "",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recanudZqJ5CPrf0L",
        "row": 25134,
        "state": "",
        "group": "517145",
        "group_num": [
            "recwWNaEK0B6ffJVp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 25,
        "2024": 20,
        "id": "recoF4alfxZ5FMK0D",
        "row": 25127,
        "state": "",
        "group": "",
        "group_num": [
            "receHjYnpj98W4ahj"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1483,
        "id": "recoOh82El4ygCcv3",
        "row": 25125,
        "state": "",
        "group": "",
        "group_num": [
            "recj00yPvkJJSNqOZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 138,
        "id": "recpR9jNXaVT64cnC",
        "row": 25128,
        "state": "",
        "group": "",
        "group_num": [
            "receHjYnpj98W4ahj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 783,
        "id": "recrFg2FeqwT4wjlH",
        "row": 25122,
        "state": "",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1102,
        "id": "recriefewCbxa3KuO",
        "row": 25145,
        "state": "",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 49,
        "2023": 26,
        "id": "recvxZyw3ejnUJg6h",
        "row": 25117,
        "state": "",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recvIzxMq0PqNXRjC",
        "row": 25139,
        "state": "",
        "group": "587767",
        "group_num": [
            "recRuo6SJcSFamQLj"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "2024": 725,
        "id": "recvj2qR9Zzs0mJ9T",
        "row": 25142,
        "state": "",
        "group": "",
        "group_num": [
            "recLGZSZuu5NdBARf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 228,
        "id": "recw1Kd9izz9yuwO9",
        "row": 25155,
        "state": "",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "recyKMgwgqT2imsQc",
        "row": 25143,
        "state": "",
        "group": "",
        "group_num": [
            "recLGZSZuu5NdBARf"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 953,
        "id": "recBR3lGQMiruRgJf",
        "row": 25153,
        "state": "",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3577,
        "id": "recCL41j2ZxGBIsbg",
        "row": 25146,
        "state": "",
        "group": "690100",
        "group_num": [
            "recfNYxu2reWdndhr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 560,
        "id": "recFV039Hc16VMAug",
        "row": 25136,
        "state": "",
        "group": "",
        "group_num": [
            "recwe6wtsaoJTHm26"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "recF4t1QWncbZqDuc",
        "row": 25135,
        "state": "",
        "group": "517145",
        "group_num": [
            "recwWNaEK0B6ffJVp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2335,
        "2023": 1966,
        "id": "recHLHXngk5rcRow1",
        "row": 25115,
        "state": "",
        "group": "301000",
        "group_num": [
            "recj5by0ZZDO9Tvpa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recHdT2iry8NXrhX7",
        "row": 25133,
        "state": "",
        "group": "",
        "group_num": [
            "recCjzVnWrbkWhqdu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1483,
        "id": "recIMewbhhvtuUKXS",
        "row": 25156,
        "state": "",
        "group": "700563",
        "group_num": [
            "rec6VKeZWHl7O86WB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2468,
        "id": "recIQz0eLnwdo9VBm",
        "row": 25123,
        "state": "",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 746,
        "id": "recJunSpvbqBnVIOA",
        "row": 25121,
        "state": "",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 595,
        "2023": 625,
        "id": "recJ4qLkq6nJzA6ki",
        "row": 25149,
        "state": "",
        "group": "700406",
        "group_num": [
            "recQRqDcdWR5ffTqI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2277,
        "id": "recNKCSECwHHymyrF",
        "row": 25120,
        "state": "",
        "group": "304000",
        "group_num": [
            "recifjZBVcMUDsVM8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1712,
        "2023": 1800,
        "id": "receVhVwjtpWn4ngw",
        "row": 25177,
        "state": "",
        "group": "701444",
        "group_num": [
            "recvpOiH5igDgQfbp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "receZy3cTZCyyWQyW",
        "row": 25194,
        "state": "",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 910,
        "id": "rece1HrFq7SD0PjyS",
        "row": 25200,
        "state": "",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 152,
        "id": "recectrSIAgtMwVXl",
        "row": 25202,
        "state": "",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 870,
        "id": "recgwubrXONiVXMEn",
        "row": 25176,
        "state": "",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 31,
        "2023": 30,
        "id": "recg2FDeUWoyE6jEK",
        "row": 25182,
        "state": "",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 889,
        "id": "rechYDecwN1UBW0Ej",
        "row": 25171,
        "state": "",
        "group": "701368",
        "group_num": [
            "rec8dQEDIEbOc4hi2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 168,
        "2023": 309,
        "2024": 355,
        "id": "reciej03RUxpu2gCG",
        "row": 25183,
        "state": "",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "reclsT0Tt3kdgZF7X",
        "row": 25204,
        "state": "",
        "group": "",
        "group_num": [
            "recQt76EsiI72V9ur"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3313,
        "id": "reclvy6nYxP2kpjYN",
        "row": 25198,
        "state": "",
        "group": "702649",
        "group_num": [
            "recoQe2CWU4zCsctB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 979,
        "id": "reclxNjW87GpqiJdB",
        "row": 25164,
        "state": "",
        "group": "701254",
        "group_num": [
            "recDv7zzG1yVALFPB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "recl5NskCbOwXoaep",
        "row": 25166,
        "state": "",
        "group": "",
        "group_num": [
            "recesL8KRLE1HKvYH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1109,
        "id": "recmxHuqZyrR7Yfgs",
        "row": 25189,
        "state": "",
        "group": "702525",
        "group_num": [
            "recTYpUOUxIW7Dhbv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recmJtzmkDYonZn2O",
        "row": 25174,
        "state": "",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3175,
        "id": "recmPDjFdPWyegEYP",
        "row": 25160,
        "state": "",
        "group": "",
        "group_num": [
            "rect1V4t0fCmj27Jt"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recOzxy4SwpX6OOur",
        "row": 25192,
        "state": "",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 148,
        "id": "recOfPzPvxCAO03KH",
        "row": 25193,
        "state": "",
        "group": "702561",
        "group_num": [
            "recXXnpeo8JyglgAm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recRWYHRmmbXYom7e",
        "row": 25170,
        "state": "",
        "group": "701368",
        "group_num": [
            "rec8dQEDIEbOc4hi2"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 136,
        "id": "recSYUQaLS4DGfTCS",
        "row": 25158,
        "state": "",
        "group": "",
        "group_num": [
            "recvS2TuH9K4BFYAY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 316,
        "2023": 300,
        "id": "recUt5aeJk5KGmaTG",
        "row": 25181,
        "state": "",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5418,
        "id": "recUALxfeiTNpXMi7",
        "row": 25179,
        "state": "",
        "group": "701529",
        "group_num": [
            "recA0owFFGwhz7u2q"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 611,
        "id": "recVZ2toztbESOOyQ",
        "row": 25205,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VbAxLYytOhmCf"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 531,
        "2023": 551,
        "id": "recZjk0jQoUJ2W4b1",
        "row": 25169,
        "state": "",
        "group": "701368",
        "group_num": [
            "rec8dQEDIEbOc4hi2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 590,
        "id": "rec0Lr9blV5An8AD3",
        "row": 25190,
        "state": "",
        "group": "",
        "group_num": [
            "rec7ndL8zY6dmikH1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 291,
        "id": "rec83XOV2Gt52yA76",
        "row": 25185,
        "state": "",
        "group": "",
        "group_num": [
            "recMysasGBa06SA12"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2661,
        "id": "recaWQCWBEIKoP22t",
        "row": 25157,
        "state": "",
        "group": "",
        "group_num": [
            "recz30uYSjmf7TBMw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "recaNncLxK2XfaO7p",
        "row": 25201,
        "state": "",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recb56JrKNzIvvXSo",
        "row": 25163,
        "state": "",
        "group": "701254",
        "group_num": [
            "recDv7zzG1yVALFPB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 156,
        "id": "recdegk5rE36mF9Is",
        "row": 25175,
        "state": "",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "recoExcRivv8Yq7yp",
        "row": 25206,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VbAxLYytOhmCf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 41,
        "id": "recoqpmsYVdmutubS",
        "row": 25168,
        "state": "",
        "group": "",
        "group_num": [
            "recesL8KRLE1HKvYH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1026,
        "id": "recpJfYGuEycIkIFZ",
        "row": 25203,
        "state": "",
        "group": "702778",
        "group_num": [
            "recCcLxQsNV3KKXCW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 555,
        "id": "recpeUsFgxIAv57Cp",
        "row": 25191,
        "state": "",
        "group": "",
        "group_num": [
            "rec7ndL8zY6dmikH1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "id": "recqU1HtAlKJu4JU7",
        "row": 25197,
        "state": "",
        "group": "",
        "group_num": [
            "rec9iDkLu5VXVV1bq"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4220,
        "2023": 3000,
        "id": "recqVnFnNH5SBSN8T",
        "row": 25199,
        "state": "",
        "group": "702649",
        "group_num": [
            "recoQe2CWU4zCsctB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 303,
        "2023": 289,
        "id": "recrFnccIGrUTxBp0",
        "row": 25173,
        "state": "",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3620,
        "id": "recsv4zLFCM7koU10",
        "row": 25159,
        "state": "",
        "group": "",
        "group_num": [
            "rect1V4t0fCmj27Jt"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 322,
        "id": "recvJxzBGatPiQuM6",
        "row": 25167,
        "state": "",
        "group": "",
        "group_num": [
            "recesL8KRLE1HKvYH"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 501,
        "2023": 870,
        "id": "recwVtAIdp90iZlc4",
        "row": 25172,
        "state": "",
        "group": "701439",
        "group_num": [
            "rec5j3SY67xaxoAjM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 344,
        "id": "recy8lkjj8xBGeB9c",
        "row": 25178,
        "state": "",
        "group": "701529",
        "group_num": [
            "recA0owFFGwhz7u2q"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 919,
        "2023": 979,
        "id": "reczHk8loZg6b5mB8",
        "row": 25184,
        "state": "",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 587,
        "2023": 611,
        "2024": 564,
        "id": "recBLVAuXfBC2H5bL",
        "row": 25180,
        "state": "",
        "group": "701648",
        "group_num": [
            "rec4x2UVW8DiOClxW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "id": "recDOMy1wSwvVOIla",
        "row": 25196,
        "state": "",
        "group": "",
        "group_num": [
            "rec9iDkLu5VXVV1bq"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "id": "recE31cdhKbaAJpWD",
        "row": 25161,
        "state": "",
        "group": "",
        "group_num": [
            "recXk7ocd3F5ExoDz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recFhveb7Oem7L5Ai",
        "row": 25195,
        "state": "",
        "group": "",
        "group_num": [
            "rec9iDkLu5VXVV1bq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 626,
        "id": "recFq0Cg8JY5yBrNR",
        "row": 25162,
        "state": "",
        "group": "701254",
        "group_num": [
            "recDv7zzG1yVALFPB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "id": "recGSkbrX19SsMMoH",
        "row": 25165,
        "state": "",
        "group": "701274",
        "group_num": [
            "recPSY5SNGzz1BfG1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 800,
        "id": "recGJviGs823a7EZg",
        "row": 25188,
        "state": "",
        "group": "702525",
        "group_num": [
            "recTYpUOUxIW7Dhbv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 984,
        "id": "recMrsR2cc04dH0kE",
        "row": 25186,
        "state": "",
        "group": "",
        "group_num": [
            "recMysasGBa06SA12"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 979,
        "id": "recNqCRSveZD6L1Nt",
        "row": 25187,
        "state": "",
        "group": "",
        "group_num": [
            "recRT1uOxxAKo9OQf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1318,
        "2023": 1922,
        "id": "recetiD1XE9OXBHl0",
        "row": 25228,
        "state": "",
        "group": "703997",
        "group_num": [
            "recRpKB6qrtJD9U5T"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "rechMcQajoQdPZTpd",
        "row": 25222,
        "state": "",
        "group": "",
        "group_num": [
            "recLuCRfJ6g3EAfkV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 930,
        "2023": 995,
        "2024": 995,
        "id": "rech4GiRmpAs3Ix5T",
        "row": 25243,
        "state": "",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 203,
        "2023": 355,
        "2024": 355,
        "id": "reclL1iIOKu3iFik8",
        "row": 25242,
        "state": "",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 979,
        "id": "recmcBkfDWxhVvhn5",
        "row": 25207,
        "state": "",
        "group": "",
        "group_num": [
            "rec7VbAxLYytOhmCf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "recmcQXFbC9v02wmt",
        "row": 25211,
        "state": "",
        "group": "",
        "group_num": [
            "recZ4HXncTISN4vg9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4020,
        "id": "recnrDK9KtdKnB17j",
        "row": 25218,
        "state": "",
        "group": "703597",
        "group_num": [
            "rec7TUVPGN8lT2wJU"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "2023": 1222,
        "id": "recPEVdHIeYfNtHBy",
        "row": 25247,
        "state": "",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 941,
        "2024": 919,
        "id": "recQMUCB4dD6e2xPx",
        "row": 25224,
        "state": "",
        "group": "703981",
        "group_num": [
            "reclZgMNn165cBlxp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recRACNmSCw7sPstH",
        "row": 25209,
        "state": "",
        "group": "",
        "group_num": [
            "recEzo8vw4u0iMjpD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 954,
        "id": "recRAshbOsP36UtDV",
        "row": 25256,
        "state": "",
        "group": "705298",
        "group_num": [
            "recGW41bo8XWBFfcz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2000,
        "id": "recSOERrS4QnvU47u",
        "row": 25233,
        "state": "",
        "group": "704203",
        "group_num": [
            "rec9NHfQbXuz2JrWy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recUxWxGAQ51uMzFJ",
        "row": 25253,
        "state": "",
        "group": "705298",
        "group_num": [
            "recGW41bo8XWBFfcz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 792,
        "2023": 934,
        "id": "recVby0OzN2t6cQSk",
        "row": 25232,
        "state": "",
        "group": "",
        "group_num": [
            "recxEhI4dLw4aDmz6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "rec0r3zAbDxWmzmFm",
        "row": 25221,
        "state": "",
        "group": "",
        "group_num": [
            "rectX3RyvmXJdhVOu"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 635,
        "id": "rec1BhyiRejm3rV4I",
        "row": 25240,
        "state": "",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 582,
        "2023": 680,
        "id": "rec1BHRPMZGY9KVPo",
        "row": 25246,
        "state": "",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 606,
        "id": "rec4VEiFiCEDjfd1P",
        "row": 25252,
        "state": "",
        "group": "705298",
        "group_num": [
            "recGW41bo8XWBFfcz"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 584,
        "id": "rec6n3DMlnlKEqZZg",
        "row": 25214,
        "state": "",
        "group": "",
        "group_num": [
            "recjFyze75cvEK8TN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "2023": 3183,
        "id": "rec8CGCu0vhUtQSbO",
        "row": 25217,
        "state": "",
        "group": "703597",
        "group_num": [
            "rec7TUVPGN8lT2wJU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "rec8kMmzcvDm20vel",
        "row": 25219,
        "state": "",
        "group": "",
        "group_num": [
            "rectX3RyvmXJdhVOu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 941,
        "2023": 979,
        "id": "rec9bHNUIflbtQ4do",
        "row": 25210,
        "state": "",
        "group": "",
        "group_num": [
            "recEzo8vw4u0iMjpD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 980,
        "id": "rec9pbSWzrbLHAEDX",
        "row": 25236,
        "state": "",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 629,
        "2024": 629,
        "id": "recaIZ0P6wCamwhIO",
        "row": 25248,
        "state": "",
        "group": "",
        "group_num": [
            "recfa0lGxbzYOEmsv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 338,
        "id": "recb9BRLd2o0DbNb0",
        "row": 25212,
        "state": "",
        "group": "",
        "group_num": [
            "recZ4HXncTISN4vg9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 202,
        "id": "recc8I8b6Ta6xVX7Y",
        "row": 25213,
        "state": "",
        "group": "",
        "group_num": [
            "recZ4HXncTISN4vg9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3329,
        "id": "reccj0rODqqj5ffaL",
        "row": 25239,
        "state": "",
        "group": "",
        "group_num": [
            "recAxjlDbPW3cURkD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 941,
        "id": "recp4GYlaii9T0fww",
        "row": 25215,
        "state": "",
        "group": "",
        "group_num": [
            "recjFyze75cvEK8TN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recpn2Y73a78dkxYM",
        "row": 25254,
        "state": "",
        "group": "705298",
        "group_num": [
            "recGW41bo8XWBFfcz"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 242,
        "id": "recrwBG0kcS2sQ96f",
        "row": 25235,
        "state": "",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "2024": 577,
        "id": "recryZs84vmGeUUKS",
        "row": 25223,
        "state": "",
        "group": "703981",
        "group_num": [
            "reclZgMNn165cBlxp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "2023": 626,
        "id": "recs5l4g563FXUgFz",
        "row": 25208,
        "state": "",
        "group": "",
        "group_num": [
            "recEzo8vw4u0iMjpD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "recuseprHdmbO1c5b",
        "row": 25227,
        "state": "",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recuCvsqlZjQKDwne",
        "row": 25249,
        "state": "",
        "group": "",
        "group_num": [
            "recfa0lGxbzYOEmsv"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recxnag2n1pjQQQTq",
        "row": 25216,
        "state": "",
        "group": "",
        "group_num": [
            "rec9HiuMmUvqYCNUJ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 276,
        "2024": 277,
        "id": "recyg743ZEGvsg3W5",
        "row": 25226,
        "state": "",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 139,
        "id": "reczB8Esb8Bj4jU64",
        "row": 25241,
        "state": "",
        "group": "704534",
        "group_num": [
            "recQxIn1ksKPiZew7"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 23,
        "id": "recAcJ2V8ZBxpFpHO",
        "row": 25250,
        "state": "",
        "group": "704781",
        "group_num": [
            "rec092luKJuFFE4qv"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "2024": 290,
        "id": "recEF3HussAFv2AHg",
        "row": 25238,
        "state": "",
        "group": "",
        "group_num": [
            "recAxjlDbPW3cURkD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2013,
        "id": "recFRPkoBbnYqc6YW",
        "row": 25244,
        "state": "",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3875,
        "2023": 1988,
        "id": "recF59DrofbEJacxz",
        "row": 25237,
        "state": "",
        "group": "",
        "group_num": [
            "recAxjlDbPW3cURkD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1046,
        "2023": 396,
        "id": "recGWm0jo2tmk8iZI",
        "row": 25231,
        "state": "",
        "group": "",
        "group_num": [
            "recxEhI4dLw4aDmz6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 606,
        "2023": 640,
        "id": "recGXW6BFS2B3YV0m",
        "row": 25234,
        "state": "",
        "group": "704354",
        "group_num": [
            "rechQ57U2An8ttIzE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1020,
        "id": "recH5wST8ZkwTDsiY",
        "row": 25245,
        "state": "",
        "group": "704660",
        "group_num": [
            "recFPkd4tAgYWX6Zm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 149,
        "id": "recJESf0fJG4IWLql",
        "row": 25255,
        "state": "",
        "group": "705298",
        "group_num": [
            "recGW41bo8XWBFfcz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1721,
        "2023": 857,
        "id": "recKmfZDKuDWep6zH",
        "row": 25230,
        "state": "",
        "group": "",
        "group_num": [
            "recxEhI4dLw4aDmz6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2134,
        "2023": 984,
        "id": "recLtFAGCWOudODOe",
        "row": 25229,
        "state": "",
        "group": "703997",
        "group_num": [
            "recRpKB6qrtJD9U5T"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "recL6bDoNBuj5Oi97",
        "row": 25251,
        "state": "",
        "group": "704781",
        "group_num": [
            "rec092luKJuFFE4qv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 566,
        "id": "recLiuYgAZTMtikhK",
        "row": 25225,
        "state": "",
        "group": "703995",
        "group_num": [
            "recxa5XEmK0JnkJC8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 21,
        "2024": 21,
        "id": "recM0yaopGBNChhLN",
        "row": 25220,
        "state": "",
        "group": "",
        "group_num": [
            "rectX3RyvmXJdhVOu"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recmNd1MskD2GJyQS",
        "row": 25257,
        "state": "",
        "group": "705362",
        "group_num": [
            "recxpjk3pM0tUpaak"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 110,
        "2023": 110,
        "id": "recPPA0daQ3MSjdRp",
        "row": 25297,
        "state": "",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "recPiqoM33UDkzyD0",
        "row": 25303,
        "state": "",
        "group": "",
        "group_num": [
            "receflqg8AymmULNN"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 842,
        "2023": 791,
        "2024": 630,
        "id": "recPp6waDfkHRSpkf",
        "row": 25264,
        "state": "",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recQHVoom8SzlRrvS",
        "row": 25300,
        "state": "",
        "group": "708809",
        "group_num": [
            "rec0Kqg9EXryBMzyK"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "id": "recRAgiBS6malS5Io",
        "row": 25299,
        "state": "",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1565,
        "2024": 1565,
        "id": "recRKjxIFZVeRWyOU",
        "row": 25292,
        "state": "",
        "group": "",
        "group_num": [
            "rec81E1HsQ5dyqJMa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "2023": 613,
        "id": "recSww0RCVOEexJCV",
        "row": 25286,
        "state": "",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1109,
        "id": "recSlceRdPDxm9WS8",
        "row": 25304,
        "state": "",
        "group": "",
        "group_num": [
            "receflqg8AymmULNN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2447,
        "2024": 2447,
        "id": "recTX8c4G0YuUEkTt",
        "row": 25293,
        "state": "",
        "group": "",
        "group_num": [
            "rec81E1HsQ5dyqJMa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 128,
        "id": "recU2xJm2Pai7dqeh",
        "row": 25262,
        "state": "",
        "group": "705437",
        "group_num": [
            "recvyR6whAm3Ky518"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recVysZugrniWyOgf",
        "row": 25263,
        "state": "",
        "group": "705437",
        "group_num": [
            "recvyR6whAm3Ky518"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1523,
        "id": "recVcriuLXHtTeTFD",
        "row": 25273,
        "state": "",
        "group": "706662",
        "group_num": [
            "rec0TUXNlOK9M4Rm0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "id": "recZqUar0f3O8EYxo",
        "row": 25306,
        "state": "",
        "group": "709078",
        "group_num": [
            "recRyhSLfG0a4AXeZ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1380,
        "id": "rec5FcjboQiZyOko5",
        "row": 25294,
        "state": "",
        "group": "708691",
        "group_num": [
            "recy0jZVlY8vGWlpv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 276,
        "id": "rec696kY5HjJKAPJc",
        "row": 25301,
        "state": "",
        "group": "708809",
        "group_num": [
            "rec0Kqg9EXryBMzyK"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2478,
        "2023": 954,
        "2024": 984,
        "id": "rec8QPSL9i0sEkU6N",
        "row": 25268,
        "state": "",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 990,
        "2023": 990,
        "id": "reccsqw6lirilzYcH",
        "row": 25283,
        "state": "",
        "group": "",
        "group_num": [
            "recgEyMJE8rC15qmR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 100,
        "id": "recdO58yCLZ4yMbk0",
        "row": 25271,
        "state": "",
        "group": "",
        "group_num": [
            "recncfbGtTRBfdzMf"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "reconNmEWG69fQ0eO",
        "row": 25280,
        "state": "",
        "group": "707278",
        "group_num": [
            "recvcGBB2UBc6ZQtY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 57,
        "id": "recqAqnKpo5rii5ga",
        "row": 25259,
        "state": "",
        "group": "705362",
        "group_num": [
            "recxpjk3pM0tUpaak"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recrpiNznGKm9nvim",
        "row": 25281,
        "state": "",
        "group": "",
        "group_num": [
            "recgEyMJE8rC15qmR"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "recsT3MZQW2XyPiVi",
        "row": 25266,
        "state": "",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 626,
        "id": "recsAMRGQcJ6u2xGX",
        "row": 25278,
        "state": "",
        "group": "",
        "group_num": [
            "recRN5ZeTlxYkm9je"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 276,
        "id": "recttZ5rnmtn3DJvC",
        "row": 25287,
        "state": "",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "id": "rectvY5ejhbDdbqsJ",
        "row": 25285,
        "state": "",
        "group": "",
        "group_num": [
            "recqqh3GB1ORJrojv"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 53,
        "id": "recxxqYT6MAvGQaia",
        "row": 25272,
        "state": "",
        "group": "706662",
        "group_num": [
            "rec0TUXNlOK9M4Rm0"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "id": "recytHmM1fqkygc0k",
        "row": 25302,
        "state": "",
        "group": "",
        "group_num": [
            "receflqg8AymmULNN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 810,
        "id": "recyOcZnM6RruOvD0",
        "row": 25270,
        "state": "",
        "group": "706254",
        "group_num": [
            "recr7C8GZ3HbxaTF2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1475,
        "id": "reczds9c8FazQQYbV",
        "row": 25265,
        "state": "",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4629,
        "id": "recAZ9PyJVhoM2yqU",
        "row": 25261,
        "state": "",
        "group": "705362",
        "group_num": [
            "recxpjk3pM0tUpaak"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "id": "recAysa5YYPftBRvO",
        "row": 25289,
        "state": "",
        "group": "",
        "group_num": [
            "recUiWSFHLazyuUsO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "recAMrFOaCz4ZwnAR",
        "row": 25284,
        "state": "",
        "group": "",
        "group_num": [
            "recqqh3GB1ORJrojv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "2023": 1350,
        "id": "recAh432nU82VVbzM",
        "row": 25282,
        "state": "",
        "group": "",
        "group_num": [
            "recgEyMJE8rC15qmR"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 660,
        "id": "recBCm7nKi0wP4O7X",
        "row": 25269,
        "state": "",
        "group": "706254",
        "group_num": [
            "recr7C8GZ3HbxaTF2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "recBR0QhqAfl3xiyL",
        "row": 25290,
        "state": "",
        "group": "",
        "group_num": [
            "recUiWSFHLazyuUsO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 984,
        "id": "recCP1N5bORrZJFKa",
        "row": 25276,
        "state": "",
        "group": "",
        "group_num": [
            "reckmnpxYLzCcimoq"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4078,
        "id": "recDUMbM2SDbVJCi8",
        "row": 25305,
        "state": "",
        "group": "709078",
        "group_num": [
            "recRyhSLfG0a4AXeZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 941,
        "2023": 1052,
        "id": "recDinCfolM78npLt",
        "row": 25288,
        "state": "",
        "group": "708493",
        "group_num": [
            "reclK6za7viplH7ls"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1024,
        "2023": 345,
        "id": "recDrKphIPSAQHIRM",
        "row": 25258,
        "state": "",
        "group": "705362",
        "group_num": [
            "recxpjk3pM0tUpaak"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recEsIDBAhE5xBBU8",
        "row": 25274,
        "state": "",
        "group": "706662",
        "group_num": [
            "rec0TUXNlOK9M4Rm0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 347,
        "id": "recEvDnM8ZwZMrZV6",
        "row": 25298,
        "state": "",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 1920,
        "id": "recGIvc91N1z7E3TK",
        "row": 25295,
        "state": "",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recHhtM8U43T0jNw9",
        "row": 25291,
        "state": "",
        "group": "",
        "group_num": [
            "recUiWSFHLazyuUsO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 694,
        "id": "recIj6e6ueBfv3Ijr",
        "row": 25267,
        "state": "",
        "group": "705963",
        "group_num": [
            "recb5F0kvRXuICv66"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 630,
        "id": "recInYikEY0Ort5c9",
        "row": 25275,
        "state": "",
        "group": "",
        "group_num": [
            "reckmnpxYLzCcimoq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1523,
        "id": "recJEUhzMJ1P5cZFt",
        "row": 25277,
        "state": "",
        "group": "706944",
        "group_num": [
            "recZAghWw67jHR9Jr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 269,
        "id": "recKSH1y93YVEjO0h",
        "row": 25296,
        "state": "",
        "group": "708744",
        "group_num": [
            "recxEF5kVHrBT4Ovo"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 188,
        "id": "recNwEagRMmL6aJbe",
        "row": 25260,
        "state": "",
        "group": "705362",
        "group_num": [
            "recxpjk3pM0tUpaak"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "id": "recNdTvXKrMMCZd8F",
        "row": 25279,
        "state": "",
        "group": "707278",
        "group_num": [
            "recvcGBB2UBc6ZQtY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1109,
        "id": "recezknmrNA0RTstp",
        "row": 25348,
        "state": "",
        "group": "",
        "group_num": [
            "recr3aazC2YXyM74R"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2092,
        "2023": 2095,
        "id": "recfhPpOrzQtz5ImG",
        "row": 25320,
        "state": "",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4239,
        "id": "reche1086KzS1fwu7",
        "row": 25307,
        "state": "",
        "group": "709078",
        "group_num": [
            "recRyhSLfG0a4AXeZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "id": "reciAu6HDcXYD7GYw",
        "row": 25339,
        "state": "",
        "group": "",
        "group_num": [
            "recNPE8Ci9L6hqoG5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "reckzCpGnWf9juGQo",
        "row": 25344,
        "state": "",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 919,
        "id": "recmXL4nIaN93GtBc",
        "row": 25354,
        "state": "",
        "group": "713531",
        "group_num": [
            "recRl9O8dnKIMvH2V"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 606,
        "2023": 715,
        "id": "recPNu7BGd4FPCZ2a",
        "row": 25327,
        "state": "",
        "group": "",
        "group_num": [
            "recYkgsvqAKtcozZs"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1869,
        "id": "recPrf9XV0wlJWhyp",
        "row": 25351,
        "state": "",
        "group": "",
        "group_num": [
            "recHy48qXzVmhdfMa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "2024": 158,
        "id": "recTleLSrpejmPNSw",
        "row": 25334,
        "state": "",
        "group": "",
        "group_num": [
            "reck1KZLPoYauSuUg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "recVx1tufhO2PnqD8",
        "row": 25310,
        "state": "",
        "group": "709335",
        "group_num": [
            "rec6koBcfPiJT6ZmM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 284,
        "id": "recV4t52rz3Sh162k",
        "row": 25335,
        "state": "",
        "group": "",
        "group_num": [
            "rec4eYb2FstNM3xcC"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2138,
        "id": "recVpB7qLlkOu9Jco",
        "row": 25315,
        "state": "",
        "group": "709876",
        "group_num": [
            "recl41ddjMpWQJCUL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 653,
        "id": "recWn2aEj6vRMrdeR",
        "row": 25325,
        "state": "",
        "group": "710325",
        "group_num": [
            "recepp33M9tiXnOIu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 507,
        "id": "recYLPx7UgtErEnvb",
        "row": 25309,
        "state": "",
        "group": "709335",
        "group_num": [
            "rec6koBcfPiJT6ZmM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 215,
        "id": "recZsObFqsSoZLSW3",
        "row": 25341,
        "state": "",
        "group": "",
        "group_num": [
            "recNPE8Ci9L6hqoG5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 665,
        "id": "rec0Y6G5qzrXPAHL3",
        "row": 25340,
        "state": "",
        "group": "",
        "group_num": [
            "recNPE8Ci9L6hqoG5"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1807,
        "id": "rec06JVteIQWQf9dZ",
        "row": 25316,
        "state": "",
        "group": "709876",
        "group_num": [
            "recl41ddjMpWQJCUL"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 984,
        "id": "rec1liZaqb7rqgpS2",
        "row": 25314,
        "state": "",
        "group": "709729",
        "group_num": [
            "rectBvJgycUnYpKG7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "id": "rec2wJP8wQ6s8Furm",
        "row": 25319,
        "state": "",
        "group": "",
        "group_num": [
            "rec45wb497vVmlpZ4"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 808,
        "id": "rec3T1axEiJVDAaJW",
        "row": 25311,
        "state": "",
        "group": "709335",
        "group_num": [
            "rec6koBcfPiJT6ZmM"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 286,
        "id": "rec3ITCaPHNLGxAB3",
        "row": 25329,
        "state": "",
        "group": "711014",
        "group_num": [
            "recBgWWuMVvMYGYmH"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1074,
        "id": "rec4sxi1HFUVKekQM",
        "row": 25345,
        "state": "",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "rec4dt50Dj2u7nCLO",
        "row": 25321,
        "state": "",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "rec5WgrA97Fjfneze",
        "row": 25333,
        "state": "",
        "group": "",
        "group_num": [
            "reck1KZLPoYauSuUg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "rec5Dgeh41Avuxt11",
        "row": 25347,
        "state": "",
        "group": "",
        "group_num": [
            "recr3aazC2YXyM74R"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "rec68xdZpnAKpc20g",
        "row": 25308,
        "state": "",
        "group": "709155",
        "group_num": [
            "recBJrC4dGMqA0eBF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 577,
        "id": "rec9SPVox9xApNwhz",
        "row": 25353,
        "state": "",
        "group": "713531",
        "group_num": [
            "recRl9O8dnKIMvH2V"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 33,
        "id": "rec9gY0nS2gPb6iJO",
        "row": 25312,
        "state": "",
        "group": "",
        "group_num": [
            "rec8RXmOrDRbwsVSN"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2857,
        "id": "rec9qam1UXThqDqqB",
        "row": 25350,
        "state": "",
        "group": "712863",
        "group_num": [
            "recpWkLEWnQ4Rxsx8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1842,
        "id": "recahUZuMIPY90wmT",
        "row": 25349,
        "state": "",
        "group": "712863",
        "group_num": [
            "recpWkLEWnQ4Rxsx8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 232,
        "id": "recbM4b4cy4UJQD66",
        "row": 25356,
        "state": "",
        "group": "",
        "group_num": [
            "recYCetoRUWZpMt8H"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 630,
        "id": "recclbueA1JF8I6dS",
        "row": 25346,
        "state": "",
        "group": "",
        "group_num": [
            "recr3aazC2YXyM74R"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 516,
        "id": "recdi7sPQiJFcju8H",
        "row": 25328,
        "state": "",
        "group": "",
        "group_num": [
            "recsF9n63AAlF7lT6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 574,
        "2023": 288,
        "2024": 564,
        "id": "recow0K7jhMi7bgmL",
        "row": 25336,
        "state": "",
        "group": "712525",
        "group_num": [
            "recAQuubnfqRgnTNb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 146,
        "id": "recoE6AShRSnjjkuu",
        "row": 25330,
        "state": "",
        "group": "711014",
        "group_num": [
            "recBgWWuMVvMYGYmH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 142,
        "2024": 175,
        "id": "recoekE5QJSX4uhMr",
        "row": 25337,
        "state": "",
        "group": "712525",
        "group_num": [
            "recAQuubnfqRgnTNb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "recp5bbbs4xN3X7cl",
        "row": 25332,
        "state": "",
        "group": "",
        "group_num": [
            "rec5JhF2nbdQYsbYc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1400,
        "id": "recqB510LGTn2cfot",
        "row": 25323,
        "state": "",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2794,
        "id": "recs3YNT7PQpG7drV",
        "row": 25324,
        "state": "",
        "group": "710325",
        "group_num": [
            "recepp33M9tiXnOIu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "rectzSAeaCq2Ku05r",
        "row": 25338,
        "state": "",
        "group": "712525",
        "group_num": [
            "recAQuubnfqRgnTNb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1870,
        "2023": 290,
        "id": "recudDcKwoiESAYJi",
        "row": 25343,
        "state": "",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5157,
        "id": "recwMvtqhOAYAG15f",
        "row": 25342,
        "state": "",
        "group": "712667",
        "group_num": [
            "recfYIv7cNiCDE0z2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 640,
        "id": "recxC49JakAfsG7T8",
        "row": 25331,
        "state": "",
        "group": "",
        "group_num": [
            "rec5JhF2nbdQYsbYc"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "reczktUCxclzrAtXE",
        "row": 25326,
        "state": "",
        "group": "710325",
        "group_num": [
            "recepp33M9tiXnOIu"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 371,
        "id": "reczpH5EuGcutTmTN",
        "row": 25322,
        "state": "",
        "group": "710264",
        "group_num": [
            "recgORD3z1qZsY2xH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 626,
        "id": "recDtD0lWUJD8mWKr",
        "row": 25317,
        "state": "",
        "group": "",
        "group_num": [
            "recbZHz8QaK4TOOVX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "id": "recFxBkUw5uuA5kbX",
        "row": 25313,
        "state": "",
        "group": "",
        "group_num": [
            "rec8RXmOrDRbwsVSN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 259,
        "id": "recIykcSWPGQ9gTiX",
        "row": 25355,
        "state": "",
        "group": "",
        "group_num": [
            "reckaFCPWNxwoq2DS"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "2024": 290,
        "id": "recJlBffiJCDSY2jC",
        "row": 25318,
        "state": "",
        "group": "",
        "group_num": [
            "recbZHz8QaK4TOOVX"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2174,
        "id": "recMCXb9M7OsanC1W",
        "row": 25352,
        "state": "",
        "group": "",
        "group_num": [
            "recHy48qXzVmhdfMa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1070,
        "id": "recfhilBujKZxHogF",
        "row": 25405,
        "state": "",
        "group": "",
        "group_num": [
            "rec5Cp9qB2U8AqaRD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 567,
        "id": "rechQiAghaHgipSXs",
        "row": 25378,
        "state": "",
        "group": "715414",
        "group_num": [
            "rec5z7Uw8Xk9dDj0l"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "rechbsiWfkgRUfuUh",
        "row": 25368,
        "state": "",
        "group": "",
        "group_num": [
            "rect9zwe1yOZsiBLA"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 271,
        "id": "recja6mgpQzJmo2Nd",
        "row": 25372,
        "state": "",
        "group": "715014",
        "group_num": [
            "recrzTZ2zeSHUlhYu"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 202,
        "2024": 299,
        "id": "reclSsuTyRgKRB6zQ",
        "row": 25392,
        "state": "",
        "group": "",
        "group_num": [
            "reckZuHZm87e6jnFO"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "recQYK87b9jAExufp",
        "row": 25398,
        "state": "",
        "group": "717257",
        "group_num": [
            "recdNIuxVKDK6fnBL"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1994,
        "2023": 2175,
        "2024": 1800,
        "id": "recQygRE7zuYZFKKb",
        "row": 25361,
        "state": "",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "2023": 640,
        "id": "recQDbxdRlmr21Lpm",
        "row": 25387,
        "state": "",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 46,
        "id": "recRPcRPJgHkEDOR2",
        "row": 25401,
        "state": "",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recSZSGK0zUCESRdF",
        "row": 25367,
        "state": "",
        "group": "",
        "group_num": [
            "rect9zwe1yOZsiBLA"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 130,
        "2023": 412,
        "id": "recSbgxypTUZbZw7n",
        "row": 25357,
        "state": "",
        "group": "",
        "group_num": [
            "recYCetoRUWZpMt8H"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 598,
        "id": "recTrWoxNlrtlPxYt",
        "row": 25366,
        "state": "",
        "group": "",
        "group_num": [
            "recDhAwyECG1ChGQv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 553,
        "id": "recUQQwMRQCuyORTC",
        "row": 25393,
        "state": "",
        "group": "717168",
        "group_num": [
            "recRg9TfgSUgMKk2n"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 191,
        "2023": 191,
        "id": "recW3cDHjLr6RNGJ1",
        "row": 25374,
        "state": "",
        "group": "715165",
        "group_num": [
            "recoRamj91oTxEJ9A"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 611,
        "id": "recYEpPfXdCNCOQWX",
        "row": 25369,
        "state": "",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2385,
        "2023": 5493,
        "id": "recY5jBVp3llBXonY",
        "row": 25359,
        "state": "",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5493,
        "id": "recYnvPOP34J7cHQI",
        "row": 25362,
        "state": "",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 146,
        "id": "rec1841gZs3kT9qab",
        "row": 25389,
        "state": "",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 284,
        "id": "rec1oMpw67nPL2vQH",
        "row": 25388,
        "state": "",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 860,
        "id": "rec2wYOwHWNyWAwuk",
        "row": 25391,
        "state": "",
        "group": "",
        "group_num": [
            "reckZuHZm87e6jnFO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 194,
        "2023": 168,
        "2024": 164,
        "id": "rec258GAVlUfoxiRN",
        "row": 25383,
        "state": "",
        "group": "",
        "group_num": [
            "rechqviP598dtYlQO"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 348,
        "2023": 166,
        "2024": 175,
        "id": "rec4TndLhXdxQubiJ",
        "row": 25402,
        "state": "",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4857,
        "2023": 588,
        "2024": 608,
        "id": "rec4tYf9GdUsclnFG",
        "row": 25406,
        "state": "",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2008,
        "2023": 954,
        "id": "rec425ehqFhXEsRh0",
        "row": 25403,
        "state": "",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 931,
        "2023": 995,
        "id": "rec6b3mTnJodqEFGm",
        "row": 25390,
        "state": "",
        "group": "716758",
        "group_num": [
            "rec711swGHzLIANd1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 889,
        "id": "recbIk2WNrAoIH5N5",
        "row": 25394,
        "state": "",
        "group": "717168",
        "group_num": [
            "recRg9TfgSUgMKk2n"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 683,
        "id": "reccZGIr5PNbl7Dt7",
        "row": 25358,
        "state": "",
        "group": "",
        "group_num": [
            "recYCetoRUWZpMt8H"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1035,
        "id": "recoZJ2bQRQGG2lFK",
        "row": 25385,
        "state": "",
        "group": "",
        "group_num": [
            "rec8kDml03BMOEmSG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1343,
        "id": "rectXlFprcZDHC0e0",
        "row": 25360,
        "state": "",
        "group": "714081",
        "group_num": [
            "recIbhQyuHjIc6dn6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1221,
        "id": "recwKnbmC4XFR1ARp",
        "row": 25375,
        "state": "",
        "group": "715165",
        "group_num": [
            "recoRamj91oTxEJ9A"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "recw307GU2KbWQRZx",
        "row": 25377,
        "state": "",
        "group": "715316",
        "group_num": [
            "recTqHf9tJrfVrMRR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 377,
        "id": "recwe8pIUAf60J6dt",
        "row": 25400,
        "state": "",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 984,
        "id": "recxw6Mh60bNIrpEJ",
        "row": 25373,
        "state": "",
        "group": "715014",
        "group_num": [
            "recrzTZ2zeSHUlhYu"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recy05xjyrpFubjzU",
        "row": 25386,
        "state": "",
        "group": "",
        "group_num": [
            "recp3LtnbSa7Y5Yun"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1051,
        "id": "recyhIm4ocoCyzydZ",
        "row": 25384,
        "state": "",
        "group": "",
        "group_num": [
            "rechqviP598dtYlQO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 309,
        "id": "recB0qZMCOaWEWtL8",
        "row": 25382,
        "state": "",
        "group": "",
        "group_num": [
            "rechqviP598dtYlQO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 660,
        "2024": 695,
        "id": "recB4KyYEWGC60wOA",
        "row": 25395,
        "state": "",
        "group": "717257",
        "group_num": [
            "recdNIuxVKDK6fnBL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recDweCTczPqzDX5q",
        "row": 25370,
        "state": "",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 551,
        "2023": 613,
        "id": "recDy3JxC2wHntFz8",
        "row": 25363,
        "state": "",
        "group": "714273",
        "group_num": [
            "recPfLHDEUv2J71E3"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "2024": 322,
        "id": "recEFwgsv1RGao1Qe",
        "row": 25396,
        "state": "",
        "group": "717257",
        "group_num": [
            "recdNIuxVKDK6fnBL"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recEg7l1WX0hoWcEq",
        "row": 25376,
        "state": "",
        "group": "715316",
        "group_num": [
            "recTqHf9tJrfVrMRR"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "id": "recFqiPOVR6hUfBIQ",
        "row": 25371,
        "state": "",
        "group": "714817",
        "group_num": [
            "recYwVZ6GzD2tKIfo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 620,
        "id": "recGXMIQySR66yC0s",
        "row": 25381,
        "state": "",
        "group": "",
        "group_num": [
            "rechqviP598dtYlQO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 861,
        "2023": 979,
        "id": "recGO8NqEeVFcY5CG",
        "row": 25365,
        "state": "",
        "group": "714273",
        "group_num": [
            "recPfLHDEUv2J71E3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "2023": 290,
        "id": "recHRUBW4MXuK2wk0",
        "row": 25364,
        "state": "",
        "group": "714273",
        "group_num": [
            "recPfLHDEUv2J71E3"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 430,
        "id": "recI9Zf3wY8zIt8bR",
        "row": 25379,
        "state": "",
        "group": "",
        "group_num": [
            "recvEkDqVMtag51xb"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 177,
        "id": "recIklpOJ3TnyhIRf",
        "row": 25397,
        "state": "",
        "group": "717257",
        "group_num": [
            "recdNIuxVKDK6fnBL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 540,
        "2023": 557,
        "id": "recKy4dCWnU3TRBTH",
        "row": 25399,
        "state": "",
        "group": "717263",
        "group_num": [
            "recwN774Lo6BrrT79"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recN2U63DLlnMaRDc",
        "row": 25380,
        "state": "",
        "group": "715632",
        "group_num": [
            "recMV0eWNZ9SL9s4Z"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 677,
        "id": "recN4Nai2GZhEfhwB",
        "row": 25404,
        "state": "",
        "group": "",
        "group_num": [
            "rec5Cp9qB2U8AqaRD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 594,
        "id": "recflUxd6VYfjzHcp",
        "row": 25446,
        "state": "",
        "group": "",
        "group_num": [
            "recg2TaTBYVs6ZuVF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1502,
        "2023": 1593,
        "id": "recgvPMHW3escU2cD",
        "row": 25441,
        "state": "",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 905,
        "id": "recgj5DosIy0hgJN1",
        "row": 25448,
        "state": "",
        "group": "",
        "group_num": [
            "recg2TaTBYVs6ZuVF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4051,
        "id": "rechjBCAueFHTfWmF",
        "row": 25432,
        "state": "",
        "group": "",
        "group_num": [
            "recIGy4XGcS9djkNn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 889,
        "id": "reciAtFzZA5ifS8oE",
        "row": 25425,
        "state": "",
        "group": "729636",
        "group_num": [
            "recUY3h7NhQH4DJj7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1672,
        "id": "reclZT5Vq9RDchMWS",
        "row": 25445,
        "state": "",
        "group": "",
        "group_num": [
            "reczbL278gw2iI9SX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 693,
        "id": "reclOEUv50t6cxGA1",
        "row": 25456,
        "state": "",
        "group": "",
        "group_num": [
            "recLkp6V2zwPfRBgt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 222,
        "id": "recnzAZ5yiUHGNdX6",
        "row": 25416,
        "state": "",
        "group": "",
        "group_num": [
            "recDZUcpiS7qmhc1Z"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1750,
        "2023": 2296,
        "id": "recOYlar0nOrYj0R9",
        "row": 25414,
        "state": "",
        "group": "717461",
        "group_num": [
            "rec6tjvgNuVTNXpYl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2517,
        "id": "recQ7pJblKExWSIcu",
        "row": 25439,
        "state": "",
        "group": "730109",
        "group_num": [
            "recaydJHy3MQdFuie"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 317,
        "id": "recRI3Xk5aLTmn43s",
        "row": 25447,
        "state": "",
        "group": "",
        "group_num": [
            "recg2TaTBYVs6ZuVF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 800,
        "id": "recSWst6pDWk92oEE",
        "row": 25436,
        "state": "",
        "group": "",
        "group_num": [
            "recmZQRmWuqSh9dDX"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4902,
        "2023": 867,
        "2024": 979,
        "id": "recSbzW0yVEnI1Qbp",
        "row": 25410,
        "state": "",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "id": "recUvZ80gSCqvcL8p",
        "row": 25440,
        "state": "",
        "group": "",
        "group_num": [
            "rec4NBxum8Xk5YxAh"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 77,
        "id": "recW8gZoKP8rCuVRf",
        "row": 25413,
        "state": "",
        "group": "717461",
        "group_num": [
            "rec6tjvgNuVTNXpYl"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2060,
        "id": "recXJehVxawCsNS80",
        "row": 25421,
        "state": "",
        "group": "717823",
        "group_num": [
            "recHUq7xY2OGQqSMw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 168,
        "id": "recYU19ErjZAhIwCn",
        "row": 25419,
        "state": "",
        "group": "717649",
        "group_num": [
            "recYn1sovse6Fk6px"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 56,
        "id": "recY9T7sEj9WVkcYr",
        "row": 25408,
        "state": "",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 513,
        "2023": 723,
        "id": "rec0kW61otWaHWaJb",
        "row": 25435,
        "state": "",
        "group": "",
        "group_num": [
            "recmZQRmWuqSh9dDX"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 431,
        "id": "rec2Qzpw0Q79hCTfd",
        "row": 25415,
        "state": "",
        "group": "",
        "group_num": [
            "recDZUcpiS7qmhc1Z"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 227,
        "id": "rec2nOU7MxzodklKh",
        "row": 25453,
        "state": "",
        "group": "730578",
        "group_num": [
            "recYtbfUnt4OFyI9h"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 3623,
        "id": "rec3ZDICW71Pt3zdw",
        "row": 25434,
        "state": "",
        "group": "",
        "group_num": [
            "recmZQRmWuqSh9dDX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "id": "rec4qiqHaSCK2LqYu",
        "row": 25418,
        "state": "",
        "group": "717649",
        "group_num": [
            "recYn1sovse6Fk6px"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "rec53PUGUZAIyz093",
        "row": 25438,
        "state": "",
        "group": "730109",
        "group_num": [
            "recaydJHy3MQdFuie"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "rec6wJVqCcJNKgFIi",
        "row": 25424,
        "state": "",
        "group": "729636",
        "group_num": [
            "recUY3h7NhQH4DJj7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 538,
        "id": "rec6nIK4jZLD8tPz6",
        "row": 25426,
        "state": "",
        "group": "",
        "group_num": [
            "recUwHMTQi3Yuo1eo"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1140,
        "2023": 304,
        "id": "rec79eZlZwn0NJ1dB",
        "row": 25407,
        "state": "",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "2024": 979,
        "id": "rec8R2GQjC4HQpbuJ",
        "row": 25450,
        "state": "",
        "group": "",
        "group_num": [
            "recS2hr6BGQ9HHieq"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "rec8eGP4vDuiUcdKf",
        "row": 25430,
        "state": "",
        "group": "729803",
        "group_num": [
            "recyLlfMRyZEkA6gt"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1109,
        "id": "rec9TvHTbybe3G7Yq",
        "row": 25420,
        "state": "",
        "group": "717649",
        "group_num": [
            "recYn1sovse6Fk6px"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 917,
        "2023": 176,
        "2024": 177,
        "id": "recaX1SQFzO9L9cQq",
        "row": 25409,
        "state": "",
        "group": "717297",
        "group_num": [
            "rec8qAb4VDJEivGBV"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 277,
        "id": "recbyN3K7xjnJMjfj",
        "row": 25437,
        "state": "",
        "group": "",
        "group_num": [
            "recmZQRmWuqSh9dDX"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 174,
        "id": "recdvRTnx1WUi1h91",
        "row": 25429,
        "state": "",
        "group": "729784",
        "group_num": [
            "recsYJ3IqajgSsdY3"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 62,
        "id": "recdJjLEVZXlJ9OZ8",
        "row": 25423,
        "state": "",
        "group": "",
        "group_num": [
            "recWTj9CXYjALGpjx"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 64,
        "id": "recpPHlmy6bntICXX",
        "row": 25443,
        "state": "",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1895,
        "id": "recqRWxXyCvS02QkC",
        "row": 25422,
        "state": "",
        "group": "717823",
        "group_num": [
            "recHUq7xY2OGQqSMw"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 889,
        "id": "recq6d4DYk5NKTuva",
        "row": 25442,
        "state": "",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2425,
        "2023": 2781,
        "id": "recrluGlneEJdJV7h",
        "row": 25444,
        "state": "",
        "group": "730152",
        "group_num": [
            "recKD9A1feAgxTMp6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "rectUalqkIjG8bQHD",
        "row": 25452,
        "state": "",
        "group": "",
        "group_num": [
            "recASEqigQ5gmzhav"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 339,
        "2023": 339,
        "id": "recuftEbDHju1bBdt",
        "row": 25428,
        "state": "",
        "group": "729784",
        "group_num": [
            "recsYJ3IqajgSsdY3"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3869,
        "id": "recvgfmfvDomkbX6T",
        "row": 25433,
        "state": "",
        "group": "",
        "group_num": [
            "recIGy4XGcS9djkNn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 322,
        "2024": 322,
        "id": "recwjfz57X6NeuuSF",
        "row": 25431,
        "state": "",
        "group": "729803",
        "group_num": [
            "recyLlfMRyZEkA6gt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "2023": 596,
        "id": "recy2DCCuFepIRwie",
        "row": 25449,
        "state": "",
        "group": "",
        "group_num": [
            "recS2hr6BGQ9HHieq"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 532,
        "id": "reczNAZ4ZCUTnvveC",
        "row": 25451,
        "state": "",
        "group": "",
        "group_num": [
            "recASEqigQ5gmzhav"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "recCMFFOeHpEJgr82",
        "row": 25412,
        "state": "",
        "group": "717340",
        "group_num": [
            "rec3vXd2pSHt9LLQv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 159,
        "id": "recCnxau9eDm8p98y",
        "row": 25411,
        "state": "",
        "group": "717340",
        "group_num": [
            "rec3vXd2pSHt9LLQv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3937,
        "id": "recILAKxvAEuKU1zA",
        "row": 25455,
        "state": "",
        "group": "",
        "group_num": [
            "recLkp6V2zwPfRBgt"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1324,
        "2023": 611,
        "id": "recJAfcAwj16CJy8v",
        "row": 25417,
        "state": "",
        "group": "717591",
        "group_num": [
            "rec8Wzp2SZIudUgsZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recJLeTlkPToQIKh4",
        "row": 25454,
        "state": "",
        "group": "730583",
        "group_num": [
            "reccv2MtKbea2jwRg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1670,
        "id": "recJd3BPdYCxBcdmD",
        "row": 25427,
        "state": "",
        "group": "",
        "group_num": [
            "recUwHMTQi3Yuo1eo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1036,
        "id": "receNogDdnkWN6mGj",
        "row": 25484,
        "state": "",
        "group": "",
        "group_num": [
            "recHsCdAYgLOyL3Bp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 238,
        "id": "reci212GHaCqKf3DX",
        "row": 25483,
        "state": "",
        "group": "",
        "group_num": [
            "recHsCdAYgLOyL3Bp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1140,
        "id": "recj3D9jz5JrWoBnd",
        "row": 25495,
        "state": "",
        "group": "751992",
        "group_num": [
            "recmpITkZo2AB0BIB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 207,
        "2023": 207,
        "id": "recnMzXScvlgaF0Pm",
        "row": 25477,
        "state": "",
        "group": "",
        "group_num": [
            "rec7QWjPhAKi2hORF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 82,
        "id": "recOU91sHPU9TcX3Z",
        "row": 25486,
        "state": "",
        "group": "744407",
        "group_num": [
            "recCbOWpAmfh4fYPl"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 211,
        "id": "recOANopV9gYIM5LF",
        "row": 25464,
        "state": "",
        "group": "742026",
        "group_num": [
            "recY4idpL3VQxfoQg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 650,
        "id": "recPQcoxxLAxXgRTN",
        "row": 25478,
        "state": "",
        "group": "",
        "group_num": [
            "recIkcLOEb8tUBGGJ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 35,
        "id": "recP7lavFiWPRq27P",
        "row": 25459,
        "state": "",
        "group": "730920",
        "group_num": [
            "recWoLw1GtURirAeH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 596,
        "id": "recPodIvMQvl40qr9",
        "row": 25496,
        "state": "",
        "group": "",
        "group_num": [
            "rec8EJRJMjArii4r2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 1406,
        "id": "recSPqfoOPvAyhQXC",
        "row": 25472,
        "state": "",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1170,
        "id": "recTEr7UWHjFZjJRE",
        "row": 25473,
        "state": "",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 734,
        "id": "recUk1dR62XajQRER",
        "row": 25470,
        "state": "",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 596,
        "id": "recW3ojFbV4KOXvIC",
        "row": 25466,
        "state": "",
        "group": "",
        "group_num": [
            "recNbzhkxSHXYnAcZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1441,
        "2023": 1200,
        "2024": 1200,
        "id": "recW9dgaAFbIVD3J1",
        "row": 25491,
        "state": "",
        "group": "744644",
        "group_num": [
            "rec9YUVxWvxVZm3hN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 284,
        "id": "recXYNQH2jAWlBdRj",
        "row": 25458,
        "state": "",
        "group": "730920",
        "group_num": [
            "recWoLw1GtURirAeH"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 976,
        "id": "recYaeWDjUUhyodk4",
        "row": 25475,
        "state": "",
        "group": "742864",
        "group_num": [
            "recp0x0OuJ9L6EcF0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2563,
        "2023": 3845,
        "id": "recZxIEk2jGkmYWqj",
        "row": 25479,
        "state": "",
        "group": "",
        "group_num": [
            "recoVP6tWAhHrpmfY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "rec1ZTPy3AWCrh9sL",
        "row": 25500,
        "state": "",
        "group": "",
        "group_num": [
            "rec5vfENV7CKF84CJ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3479,
        "id": "rec2fPMWUr3x0wceP",
        "row": 25480,
        "state": "",
        "group": "",
        "group_num": [
            "recoVP6tWAhHrpmfY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 39,
        "id": "rec3XmEfAwY6HAoUB",
        "row": 25471,
        "state": "",
        "group": "742781",
        "group_num": [
            "recrgp0jcT1OyFN5j"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4143,
        "2023": 2207,
        "id": "rec4y4Kg5sU4LYGmM",
        "row": 25462,
        "state": "",
        "group": "742026",
        "group_num": [
            "recY4idpL3VQxfoQg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 143,
        "2024": 128,
        "id": "rec5jvbM8GgQ1MLkQ",
        "row": 25490,
        "state": "",
        "group": "744644",
        "group_num": [
            "rec9YUVxWvxVZm3hN"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1916,
        "id": "rec6xxxzFd4FnSiMo",
        "row": 25498,
        "state": "",
        "group": "752302",
        "group_num": [
            "rec9QGgHKio6uyaqZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 175,
        "2024": 175,
        "id": "rec7SL9fxPt3lo1aC",
        "row": 25494,
        "state": "",
        "group": "751992",
        "group_num": [
            "recmpITkZo2AB0BIB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2247,
        "id": "rec7KaV58yVWdjYgj",
        "row": 25505,
        "state": "",
        "group": "",
        "group_num": [
            "recxaGjePk9eH8F75"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3800,
        "id": "recabAfqnxK3G1USx",
        "row": 25474,
        "state": "",
        "group": "742856",
        "group_num": [
            "recb7RggkXuytATDU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2160,
        "2023": 1680,
        "2024": 1680,
        "id": "recdCzBoXunncAlkK",
        "row": 25489,
        "state": "",
        "group": "744644",
        "group_num": [
            "rec9YUVxWvxVZm3hN"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 365,
        "2023": 959,
        "id": "recoChVj4qmGsS3xx",
        "row": 25485,
        "state": "",
        "group": "744274",
        "group_num": [
            "recVu937iapmeWGLB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 33,
        "id": "recqSvVk3WDgFN3Rg",
        "row": 25476,
        "state": "",
        "group": "",
        "group_num": [
            "rec7QWjPhAKi2hORF"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3855,
        "id": "recrHTmcK6GdAKNwV",
        "row": 25457,
        "state": "",
        "group": "",
        "group_num": [
            "recLkp6V2zwPfRBgt"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 289,
        "id": "recsKKQarzK8W0zwe",
        "row": 25506,
        "state": "",
        "group": "",
        "group_num": [
            "recf8mp4YoXKE9cnP"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "2023": 567,
        "id": "recsauf9FiBY2hMGV",
        "row": 25467,
        "state": "",
        "group": "742373",
        "group_num": [
            "recAcWITQb6bv5QYm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rectygMb3mq5PJWti",
        "row": 25488,
        "state": "",
        "group": "744407",
        "group_num": [
            "recCbOWpAmfh4fYPl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2363,
        "id": "rectfzleNXtDcx1rG",
        "row": 25465,
        "state": "",
        "group": "742026",
        "group_num": [
            "recY4idpL3VQxfoQg"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 318,
        "id": "recuHw3f3lxXmDBor",
        "row": 25493,
        "state": "",
        "group": "751992",
        "group_num": [
            "recmpITkZo2AB0BIB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 292,
        "id": "recwCNeXwEdiDWflH",
        "row": 25502,
        "state": "",
        "group": "752490",
        "group_num": [
            "recyO9eVQngdrS7ix"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 545,
        "id": "recy3R9u3XJlo30WY",
        "row": 25463,
        "state": "",
        "group": "742026",
        "group_num": [
            "recY4idpL3VQxfoQg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1043,
        "2023": 897,
        "id": "reczyCRBQoPO7nyAy",
        "row": 25469,
        "state": "",
        "group": "742373",
        "group_num": [
            "recAcWITQb6bv5QYm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 276,
        "id": "reczQvxQYMPPsNtZX",
        "row": 25468,
        "state": "",
        "group": "742373",
        "group_num": [
            "recAcWITQb6bv5QYm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "recDtR2zWam2KT2Lt",
        "row": 25497,
        "state": "",
        "group": "",
        "group_num": [
            "rec8EJRJMjArii4r2"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 238,
        "id": "recFOZUoFvC0JAeDZ",
        "row": 25460,
        "state": "",
        "group": "730920",
        "group_num": [
            "recWoLw1GtURirAeH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 315,
        "id": "recFbo0N9YBRTtCRl",
        "row": 25481,
        "state": "",
        "group": "",
        "group_num": [
            "recHsCdAYgLOyL3Bp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4551,
        "id": "recITBStdFzGyn0wZ",
        "row": 25499,
        "state": "",
        "group": "752302",
        "group_num": [
            "rec9QGgHKio6uyaqZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recIeNQFmR0ONcsCQ",
        "row": 25501,
        "state": "",
        "group": "752490",
        "group_num": [
            "recyO9eVQngdrS7ix"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 348,
        "id": "recLGKAgJdyB2iBqP",
        "row": 25504,
        "state": "",
        "group": "752490",
        "group_num": [
            "recyO9eVQngdrS7ix"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1131,
        "id": "recLh9HtImnA77NY0",
        "row": 25461,
        "state": "",
        "group": "730992",
        "group_num": [
            "recSletlMIiNYBI0N"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 405,
        "id": "recMWCN7kXJEtqhPR",
        "row": 25503,
        "state": "",
        "group": "752490",
        "group_num": [
            "recyO9eVQngdrS7ix"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 775,
        "id": "recM1hug9vSBa6HD6",
        "row": 25487,
        "state": "",
        "group": "744407",
        "group_num": [
            "recCbOWpAmfh4fYPl"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 375,
        "id": "recMNxFhjildPZXmj",
        "row": 25482,
        "state": "",
        "group": "",
        "group_num": [
            "recHsCdAYgLOyL3Bp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 657,
        "2023": 659,
        "id": "recNHSFqwOGWtHWB8",
        "row": 25492,
        "state": "",
        "group": "751992",
        "group_num": [
            "recmpITkZo2AB0BIB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "id": "rece3MbWDMUVJKzSn",
        "row": 25514,
        "state": "",
        "group": "754336",
        "group_num": [
            "recbD41yyZEgASIcZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4176,
        "id": "rece97mrRgXvdXrRU",
        "row": 25518,
        "state": "",
        "group": "",
        "group_num": [
            "rec9tORa3jcMALKUx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "2023": 3818,
        "2024": 1900,
        "id": "recfYCE5Nm0kWbaHh",
        "row": 25552,
        "state": "",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "recg7GunIjMgTIMZJ",
        "row": 25529,
        "state": "",
        "group": "755730",
        "group_num": [
            "rec3dL4u0hwRDtwSm"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2601,
        "id": "rechVLpxA7VTWPK69",
        "row": 25510,
        "state": "",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 147,
        "id": "rech26b1Njpz8WEfP",
        "row": 25544,
        "state": "",
        "group": "",
        "group_num": [
            "rec0GcGr1PTi0Uv4X"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 344,
        "2023": 349,
        "id": "recjyYnG5HKqGlk6o",
        "row": 25551,
        "state": "",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1900,
        "id": "recjA4z7eghgb27lo",
        "row": 25536,
        "state": "",
        "group": "813234",
        "group_num": [
            "recK3uiAadt6InoTl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1656,
        "id": "reckdOHp8LiO0WPgN",
        "row": 25541,
        "state": "",
        "group": "901673",
        "group_num": [
            "recCiF2U1EMBwjCwO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 30,
        "id": "recPLDpmF6IJbRkVa",
        "row": 25527,
        "state": "",
        "group": "755730",
        "group_num": [
            "rec3dL4u0hwRDtwSm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "recP3KInPx7FFyTL1",
        "row": 25521,
        "state": "",
        "group": "",
        "group_num": [
            "recudjZjYKOVMDWLG"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recQUJCeHAnNmNKBm",
        "row": 25516,
        "state": "",
        "group": "754471",
        "group_num": [
            "recPkH5yRLDfgNYTn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 159,
        "id": "recSKUWij0AXoVdZQ",
        "row": 25528,
        "state": "",
        "group": "755730",
        "group_num": [
            "rec3dL4u0hwRDtwSm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1896,
        "id": "recTUfgkrvb00s4pZ",
        "row": 25520,
        "state": "",
        "group": "",
        "group_num": [
            "recudjZjYKOVMDWLG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "2023": 558,
        "id": "recUMmYBy74m5FO7D",
        "row": 25553,
        "state": "",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recUpn2CfW0kEzcE5",
        "row": 25533,
        "state": "",
        "group": "",
        "group_num": [
            "recO9y7XbykcwxdSa"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 174,
        "id": "recUqp48qPw2dbHak",
        "row": 25554,
        "state": "",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1100,
        "id": "recWZpHxwE62DaiQZ",
        "row": 25540,
        "state": "",
        "group": "",
        "group_num": [
            "recgKVR0JXkAes1mp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 62,
        "id": "recWzwlGAoH09S4mS",
        "row": 25507,
        "state": "",
        "group": "",
        "group_num": [
            "recZZ9PhtFusIwL7g"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 979,
        "id": "rec129rWfNaZd7VyA",
        "row": 25556,
        "state": "",
        "group": "",
        "group_num": [
            "rec1b639DJ6Hh91m8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "rec15hnpjE2PIy1VH",
        "row": 25538,
        "state": "",
        "group": "",
        "group_num": [
            "reczLAnFQoHk9EGv7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 134,
        "id": "rec2QYlyk7hebFHDj",
        "row": 25530,
        "state": "",
        "group": "755829",
        "group_num": [
            "recEaYNO706Cf0uRW"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 611,
        "id": "rec293fDkwOpLqGzT",
        "row": 25513,
        "state": "",
        "group": "754336",
        "group_num": [
            "recbD41yyZEgASIcZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4088,
        "id": "rec3QP2OGd80dqK3T",
        "row": 25546,
        "state": "",
        "group": "902670",
        "group_num": [
            "rec9vi7MquqHiE7QH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 307,
        "id": "rec32kNOTiHAp79XV",
        "row": 25543,
        "state": "",
        "group": "",
        "group_num": [
            "rec0GcGr1PTi0Uv4X"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 276,
        "id": "rec8LdiWQde86BYp5",
        "row": 25523,
        "state": "",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "rec8RQ7JIkX9Iij05",
        "row": 25532,
        "state": "",
        "group": "",
        "group_num": [
            "recL7HdKkKF2Tmtl8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "2024": 29,
        "id": "rec8dWGZwVE2U7hhj",
        "row": 25534,
        "state": "",
        "group": "",
        "group_num": [
            "recO9y7XbykcwxdSa"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2400,
        "id": "recahQu0peZ9AfdHP",
        "row": 25555,
        "state": "",
        "group": "902765",
        "group_num": [
            "recoPnytC7ty38BZi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1419,
        "id": "recdQTGRVmhODxfJ8",
        "row": 25539,
        "state": "",
        "group": "",
        "group_num": [
            "recmlFurxhyCzpQsa"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recoXI2ZAOvOR4X7s",
        "row": 25525,
        "state": "",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3962,
        "id": "recow2tRpkaS9H2ih",
        "row": 25542,
        "state": "",
        "group": "",
        "group_num": [
            "recaWpLLvefBWchRS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 80,
        "id": "recpvYFQGOmNeGUm9",
        "row": 25508,
        "state": "",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "id": "recrqfhCTs4ti9oSv",
        "row": 25545,
        "state": "",
        "group": "902633",
        "group_num": [
            "recjHTOBllTyzhnYG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "id": "rectdTUPSmpMxS5ko",
        "row": 25550,
        "state": "",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 99,
        "id": "recuJh12KPUG0OCfB",
        "row": 25517,
        "state": "",
        "group": "755320",
        "group_num": [
            "recscFxM6UB0sGBWw"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recuKr0NMrAvy7FYL",
        "row": 25515,
        "state": "",
        "group": "754471",
        "group_num": [
            "recPkH5yRLDfgNYTn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 550,
        "id": "recuQrK6KtzQnHWj7",
        "row": 25548,
        "state": "",
        "group": "",
        "group_num": [
            "recF4I8DvmpliDk0d"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recvqKtRhqZqQrcyP",
        "row": 25512,
        "state": "",
        "group": "",
        "group_num": [
            "recPFDSoCDSRATrf2"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2135,
        "2023": 0,
        "id": "recxpcOe9CKSG5LL7",
        "row": 25522,
        "state": "",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4234,
        "id": "reczmL5xRtKobb3ib",
        "row": 25549,
        "state": "",
        "group": "902762",
        "group_num": [
            "recTrGF1J2PdN64wG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1832,
        "2023": 920,
        "id": "recAEeKtNxpk78EWz",
        "row": 25526,
        "state": "",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "id": "recDZRqfwlYKakzfW",
        "row": 25519,
        "state": "",
        "group": "",
        "group_num": [
            "rec9tORa3jcMALKUx"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 487,
        "id": "recGuZ2j3AnalN8zw",
        "row": 25509,
        "state": "",
        "group": "752713",
        "group_num": [
            "rec8l6BaY2YXZmb8l"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "2024": 158,
        "id": "recGu36IbmXiGhzpo",
        "row": 25535,
        "state": "",
        "group": "",
        "group_num": [
            "recO9y7XbykcwxdSa"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2466,
        "id": "recJZhNlvnL4nnroJ",
        "row": 25537,
        "state": "",
        "group": "813234",
        "group_num": [
            "recK3uiAadt6InoTl"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recMVUMq7KhiXPB0U",
        "row": 25547,
        "state": "",
        "group": "",
        "group_num": [
            "recF4I8DvmpliDk0d"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 244,
        "id": "recMKyBPsrcLMDNaY",
        "row": 25531,
        "state": "",
        "group": "",
        "group_num": [
            "recf4DfUXIoiFiOb2"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2063,
        "id": "recNGMJuiA9aZpqZI",
        "row": 25511,
        "state": "",
        "group": "752936",
        "group_num": [
            "rec83fxPYpEtAHa8p"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recNj9PUlxrZZwOid",
        "row": 25524,
        "state": "",
        "group": "755560",
        "group_num": [
            "recKGUj6EJr73dZq1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1707,
        "id": "recfudtGrSgovcJqs",
        "row": 25583,
        "state": "",
        "group": "",
        "group_num": [
            "rec0SyIkfLb2hJpfF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recgShUXnL3pjE2aQ",
        "row": 25600,
        "state": "",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2380,
        "id": "recgyyvZlYoaZXrZW",
        "row": 25557,
        "state": "",
        "group": "902849",
        "group_num": [
            "recZ8HeZXU1lJYkOB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "recgyLSCUnQLT3DJT",
        "row": 25599,
        "state": "",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "2023": 322,
        "id": "rechYLtpMHw5sU78B",
        "row": 25596,
        "state": "",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4208,
        "2023": 2882,
        "id": "recjLjenMxiamnLZN",
        "row": 25605,
        "state": "",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1109,
        "id": "reckoqQ4DVl6WAsUs",
        "row": 25581,
        "state": "",
        "group": "",
        "group_num": [
            "recsFu1mW8sn8pD7u"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "2023": 626,
        "id": "reclGRLRff4NDTAMy",
        "row": 25601,
        "state": "",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 146,
        "id": "recOfEczWhW2ydYX3",
        "row": 25565,
        "state": "",
        "group": "",
        "group_num": [
            "rec6S9CnNYEv5xv1l"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 895,
        "id": "recOmqnRUS4PlRlIB",
        "row": 25566,
        "state": "",
        "group": "",
        "group_num": [
            "rec6S9CnNYEv5xv1l"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "2023": 537,
        "id": "recPYwuxaJ5MyDPmO",
        "row": 25606,
        "state": "",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 511,
        "2023": 703,
        "id": "recS2EYEt4vc8LNUr",
        "row": 25558,
        "state": "",
        "group": "902849",
        "group_num": [
            "recZ8HeZXU1lJYkOB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1951,
        "id": "recTeOEKw4oP8SHX2",
        "row": 25597,
        "state": "",
        "group": "",
        "group_num": [
            "recgYUTHMUn3l072k"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 2375,
        "id": "recYZkQulmywDCxVF",
        "row": 25598,
        "state": "",
        "group": "905212",
        "group_num": [
            "recvs8wJ1873fjB6c"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 322,
        "id": "recYw9Iij1RxfNtoN",
        "row": 25584,
        "state": "",
        "group": "",
        "group_num": [
            "rec81X0ofEFIn23Em"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "2023": 290,
        "id": "rec3jSiVwYysBjRDx",
        "row": 25602,
        "state": "",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 628,
        "id": "rec6smTtilhiQkiuH",
        "row": 25572,
        "state": "",
        "group": "",
        "group_num": [
            "recqGCh0aEU4b98CB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 22,
        "id": "rec6BDc2PkL6U7dMe",
        "row": 25577,
        "state": "",
        "group": "",
        "group_num": [
            "rec9JFz85Y3t5gTnt"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 26,
        "id": "rec7zTNWHQHHN5bN3",
        "row": 25564,
        "state": "",
        "group": "",
        "group_num": [
            "rec6S9CnNYEv5xv1l"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "id": "rec9O3PW0gx9qtdyz",
        "row": 25592,
        "state": "",
        "group": "905005",
        "group_num": [
            "recNH43sQ20RXWv1N"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "2023": 27,
        "id": "rec9chrVL2JaWAvd0",
        "row": 25595,
        "state": "",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1084,
        "id": "recaySfV5v82954TX",
        "row": 25561,
        "state": "",
        "group": "",
        "group_num": [
            "receCngqNQE3ejpg5"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 145,
        "id": "recaf1BTWqtYgzAED",
        "row": 25586,
        "state": "",
        "group": "",
        "group_num": [
            "recoS5PLJFio3QSUG"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "2023": 628,
        "id": "recdF8NIpm2aB91Nj",
        "row": 25579,
        "state": "",
        "group": "",
        "group_num": [
            "recsFu1mW8sn8pD7u"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1109,
        "id": "recd5NkC89spn3rZ5",
        "row": 25574,
        "state": "",
        "group": "",
        "group_num": [
            "recqGCh0aEU4b98CB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "id": "recryg0nza0YVsDgu",
        "row": 25587,
        "state": "",
        "group": "904975",
        "group_num": [
            "recLifBjYCOu6uJP8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recrEImDfkZsT021Y",
        "row": 25582,
        "state": "",
        "group": "",
        "group_num": [
            "recvBnZYQj3Ss9Fvn"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recriSDpUdGxfzzgz",
        "row": 25571,
        "state": "",
        "group": "903509",
        "group_num": [
            "recFxuB25h9vXQNcc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recsJdFJeY8eyUUvb",
        "row": 25588,
        "state": "",
        "group": "904975",
        "group_num": [
            "recLifBjYCOu6uJP8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 439,
        "2024": 439,
        "id": "rectBaZhqRB8KsiRD",
        "row": 25568,
        "state": "",
        "group": "",
        "group_num": [
            "recdSrQUIqz99Cn8C"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1950,
        "id": "recux0Zmj37MXmyS7",
        "row": 25567,
        "state": "",
        "group": "",
        "group_num": [
            "recdSrQUIqz99Cn8C"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1365,
        "id": "recuozvUu98vikJri",
        "row": 25569,
        "state": "",
        "group": "",
        "group_num": [
            "recdSrQUIqz99Cn8C"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 309,
        "id": "recvsi4LU6qN5vE6v",
        "row": 25580,
        "state": "",
        "group": "",
        "group_num": [
            "recsFu1mW8sn8pD7u"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "recvvBWXzsQN2RPx9",
        "row": 25585,
        "state": "",
        "group": "",
        "group_num": [
            "recoS5PLJFio3QSUG"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 165,
        "id": "recv5Iy0WwfNHNCVa",
        "row": 25578,
        "state": "",
        "group": "",
        "group_num": [
            "rec9JFz85Y3t5gTnt"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 573,
        "id": "recwGSu0PM9U1s60V",
        "row": 25562,
        "state": "",
        "group": "",
        "group_num": [
            "rec6S9CnNYEv5xv1l"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 443,
        "id": "recw90JtZBYsjz6UZ",
        "row": 25560,
        "state": "",
        "group": "",
        "group_num": [
            "recFcLCc5XtH6MDhM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "recxJlRw45MDq3Qqw",
        "row": 25590,
        "state": "",
        "group": "905000",
        "group_num": [
            "rechIzi3wtVYkD7P0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recypVdcCji7A2DFD",
        "row": 25576,
        "state": "",
        "group": "903918",
        "group_num": [
            "rec27WV3dOJVqylbi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 309,
        "id": "reczNju2MbI0IXC2h",
        "row": 25573,
        "state": "",
        "group": "",
        "group_num": [
            "recqGCh0aEU4b98CB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 949,
        "id": "recznXmeKaWS9DlSi",
        "row": 25589,
        "state": "",
        "group": "904975",
        "group_num": [
            "recLifBjYCOu6uJP8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 935,
        "2023": 951,
        "id": "recFazRKzbMT3gJ4R",
        "row": 25604,
        "state": "",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 264,
        "id": "recHVOsAqzRtFn7gG",
        "row": 25563,
        "state": "",
        "group": "",
        "group_num": [
            "rec6S9CnNYEv5xv1l"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 500,
        "id": "recHwiJLouxlvyjuP",
        "row": 25559,
        "state": "",
        "group": "",
        "group_num": [
            "recFcLCc5XtH6MDhM"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recJxyG6AZDVJZADE",
        "row": 25570,
        "state": "",
        "group": "903509",
        "group_num": [
            "recFxuB25h9vXQNcc"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 339,
        "id": "recJ3FxldyAUGj9LJ",
        "row": 25594,
        "state": "",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recLtHTgooAAuJrT9",
        "row": 25575,
        "state": "",
        "group": "903918",
        "group_num": [
            "rec27WV3dOJVqylbi"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "recLcUQOjuNHyu2e4",
        "row": 25603,
        "state": "",
        "group": "905509",
        "group_num": [
            "recaCN5m6K5XrOWZb"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4750,
        "id": "recLi618ssAZwFF7Y",
        "row": 25591,
        "state": "",
        "group": "905005",
        "group_num": [
            "recNH43sQ20RXWv1N"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 587,
        "2023": 582,
        "2024": 605,
        "id": "recMBMdMt0DXSsNRU",
        "row": 25593,
        "state": "",
        "group": "905050",
        "group_num": [
            "recrpkHhj7KVQMY62"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 38,
        "id": "receHTEKCO4wccFXJ",
        "row": 25628,
        "state": "",
        "group": "905985",
        "group_num": [
            "recubIHPK6jMZ57wB"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 640,
        "id": "recjoYZLcumy1zvx0",
        "row": 25640,
        "state": "",
        "group": "",
        "group_num": [
            "recmsdygBdL9mBJvI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1211,
        "id": "reclwF9ybbXR9Aemd",
        "row": 25632,
        "state": "",
        "group": "906166",
        "group_num": [
            "recO7veGhCZcjCqKV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 446,
        "id": "recl9kAQnHZAAN0Za",
        "row": 25655,
        "state": "",
        "group": "",
        "group_num": [
            "recAXSu5eY4fSOhHb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 148,
        "2023": 158,
        "id": "recQ8ofXmd8jJssMa",
        "row": 25613,
        "state": "",
        "group": "",
        "group_num": [
            "recrQjeqMDAcAslgP"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 963,
        "id": "recQaw85sKuWkmrDj",
        "row": 25616,
        "state": "",
        "group": "905566",
        "group_num": [
            "recTM3SLJzUOBa3RB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 328,
        "id": "recRUTwsUBZTDsdV1",
        "row": 25634,
        "state": "",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 955,
        "2023": 987,
        "id": "recRhrli5bAQhpLJO",
        "row": 25636,
        "state": "",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 95,
        "id": "recTKjk6akSpqwb9o",
        "row": 25644,
        "state": "",
        "group": "907728",
        "group_num": [
            "recqdoIPvdXWQwMI6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 919,
        "id": "recTM2xYuiQ9xEYy6",
        "row": 25617,
        "state": "",
        "group": "905610",
        "group_num": [
            "rec9iIgiZtZ5uPeAs"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 411,
        "id": "recWxZvV1J3AcgE6H",
        "row": 25646,
        "state": "",
        "group": "907728",
        "group_num": [
            "recqdoIPvdXWQwMI6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "id": "recYZwiLygpQ0NxOt",
        "row": 25627,
        "state": "",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "id": "recYO3iyyC803reI8",
        "row": 25626,
        "state": "",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 510,
        "id": "recY4HwDMN5Z14Lss",
        "row": 25645,
        "state": "",
        "group": "907728",
        "group_num": [
            "recqdoIPvdXWQwMI6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 759,
        "id": "rec04JJjmHkzokobD",
        "row": 25618,
        "state": "",
        "group": "905754",
        "group_num": [
            "rechSDY0XkC7qY5an"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "rec05897sbgP7cdH4",
        "row": 25642,
        "state": "",
        "group": "907073",
        "group_num": [
            "recvSD7qUU72vHDJe"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "rec2jInqvzxUFSigl",
        "row": 25647,
        "state": "",
        "group": "",
        "group_num": [
            "recIgzFoJkH5bRBxo"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4279,
        "2023": 4325,
        "id": "rec36Jx2KMK2fXUfM",
        "row": 25656,
        "state": "",
        "group": "909006",
        "group_num": [
            "recdGM4oiCZI8w11w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 829,
        "2024": 829,
        "id": "rec5SGCpdVOaHf51M",
        "row": 25624,
        "state": "",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 345,
        "id": "rec6YiueMfp7NN7Tb",
        "row": 25622,
        "state": "",
        "group": "",
        "group_num": [
            "recbvJxEMhDspNN1a"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 657,
        "id": "rec6MlSedYZpkh9U6",
        "row": 25633,
        "state": "",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 400,
        "id": "rec9cazIZJXAS8clz",
        "row": 25643,
        "state": "",
        "group": "",
        "group_num": [
            "recPyuMkzpOyIBvhl"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 91,
        "id": "recavPlxwwEH4INFG",
        "row": 25638,
        "state": "",
        "group": "",
        "group_num": [
            "recWr6rZpSyCU1kC3"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1198,
        "2023": 1027,
        "id": "recahdazHVJISy6GR",
        "row": 25619,
        "state": "",
        "group": "905754",
        "group_num": [
            "rechSDY0XkC7qY5an"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 626,
        "id": "recbjUv2zkr1OE6vp",
        "row": 25620,
        "state": "",
        "group": "",
        "group_num": [
            "recq4kImJvh3IrLkQ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 872,
        "id": "reccED4cD9U0z6LJZ",
        "row": 25650,
        "state": "",
        "group": "908168",
        "group_num": [
            "rec1TfjrQ9rCCnaYF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 290,
        "2023": 345,
        "2024": 345,
        "id": "reco4CgNqf1VHK3CO",
        "row": 25625,
        "state": "",
        "group": "905940",
        "group_num": [
            "recT6e1zEZrAbAQEP"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 738,
        "id": "recoh5A2iXY1ZoDbK",
        "row": 25637,
        "state": "",
        "group": "",
        "group_num": [
            "recPHLIatslztdqDj"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3777,
        "2023": 2802,
        "id": "recpXhKBxGzMcJpxk",
        "row": 25608,
        "state": "",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 383,
        "id": "recpkWCXIqTF3OP6P",
        "row": 25631,
        "state": "",
        "group": "906166",
        "group_num": [
            "recO7veGhCZcjCqKV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 255,
        "id": "recr6JxJVUomjVnAa",
        "row": 25607,
        "state": "",
        "group": "905531",
        "group_num": [
            "recQnLe57qh87fqw0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 853,
        "id": "rectyUlnvqRWS7UKL",
        "row": 25641,
        "state": "",
        "group": "",
        "group_num": [
            "recmsdygBdL9mBJvI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 775,
        "id": "rect4MQ2qiKhX7Pdy",
        "row": 25630,
        "state": "",
        "group": "906166",
        "group_num": [
            "recO7veGhCZcjCqKV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4394,
        "id": "rectlNcyaYRwRveLc",
        "row": 25648,
        "state": "",
        "group": "",
        "group_num": [
            "recIgzFoJkH5bRBxo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 21,
        "id": "recubKKpPdp1Gl2VH",
        "row": 25649,
        "state": "",
        "group": "",
        "group_num": [
            "recwpkf8VCqnLcr5p"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "2024": 158,
        "id": "recws4l2PND21t3gr",
        "row": 25610,
        "state": "",
        "group": "",
        "group_num": [
            "rec1hL36xE6IkyuMT"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recyX5l3fQl22cRXM",
        "row": 25653,
        "state": "",
        "group": "",
        "group_num": [
            "rec7D8y9t7KiUluvI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recy92TGm6UbZ8MTm",
        "row": 25652,
        "state": "",
        "group": "",
        "group_num": [
            "recmKlrT7r7XO8aZr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "2023": 69,
        "2024": 29,
        "id": "recBYqKwhu9NSc9V3",
        "row": 25612,
        "state": "",
        "group": "",
        "group_num": [
            "recrQjeqMDAcAslgP"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 247,
        "id": "recDWb4PddZeTHpvd",
        "row": 25629,
        "state": "",
        "group": "906040",
        "group_num": [
            "recoRhup26tBBg0Xg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "2023": 290,
        "id": "recDEpiiD1pCAy6tc",
        "row": 25611,
        "state": "",
        "group": "",
        "group_num": [
            "recrQjeqMDAcAslgP"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "2023": 355,
        "2024": 355,
        "id": "recDMyyTMEsCu71sI",
        "row": 25635,
        "state": "",
        "group": "906193",
        "group_num": [
            "recD6gPCjiekrEOQj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 979,
        "id": "recDNLMoeXw3odFc6",
        "row": 25621,
        "state": "",
        "group": "",
        "group_num": [
            "recq4kImJvh3IrLkQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1466,
        "id": "recE26a6ZLpL9Su4j",
        "row": 25651,
        "state": "",
        "group": "",
        "group_num": [
            "recmKlrT7r7XO8aZr"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recIOd44Vkgz2YdVl",
        "row": 25609,
        "state": "",
        "group": "",
        "group_num": [
            "rec1hL36xE6IkyuMT"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 613,
        "id": "recJ4cNrWnKQkspuA",
        "row": 25614,
        "state": "",
        "group": "905566",
        "group_num": [
            "recTM3SLJzUOBa3RB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "2024": 355,
        "id": "recJcvytCGCqjYVwJ",
        "row": 25639,
        "state": "",
        "group": "",
        "group_num": [
            "reckYrYWNGkfYNf4Z"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 29,
        "id": "recLrEsi00lNwjuHo",
        "row": 25654,
        "state": "",
        "group": "",
        "group_num": [
            "recAXSu5eY4fSOhHb"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 146,
        "2023": 146,
        "id": "recMvfbE8KD3FFLQm",
        "row": 25615,
        "state": "",
        "group": "905566",
        "group_num": [
            "recTM3SLJzUOBa3RB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 287,
        "id": "recMH6hW9kSV3MzxF",
        "row": 25623,
        "state": "",
        "group": "",
        "group_num": [
            "rech3tAAMR5sgI0rf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 328,
        "id": "rece5QNYh34j35pzS",
        "row": 25665,
        "state": "",
        "group": "909268",
        "group_num": [
            "rec27TYtQKQW15RmY"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "recfvv0Ldwqac48s7",
        "row": 25691,
        "state": "",
        "group": "",
        "group_num": [
            "recrMkw1AuRRkbdVQ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recffx50CyrHvk0fx",
        "row": 25675,
        "state": "",
        "group": "",
        "group_num": [
            "rechOQ4pnmpEHx9Og"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1096,
        "id": "recgQfdXHhwGDXpQG",
        "row": 25682,
        "state": "",
        "group": "911528",
        "group_num": [
            "reck7pmbAMZzGATwI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1099,
        "id": "recigdAfSExoSZiah",
        "row": 25680,
        "state": "",
        "group": "911364",
        "group_num": [
            "recBEe2SuKHTWqovW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 122,
        "id": "recksdTEGWqgCLpmm",
        "row": 25676,
        "state": "",
        "group": "",
        "group_num": [
            "recpOqN6dAsJahrXT"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1627,
        "id": "reckLLEtnmanNWgip",
        "row": 25659,
        "state": "",
        "group": "909166",
        "group_num": [
            "recXgZCi84UdHUHVD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "recmJFcNOFKf11II4",
        "row": 25704,
        "state": "",
        "group": "",
        "group_num": [
            "recRIsEIythnVYR1i"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 571,
        "id": "recnoR9eqNhC5iLjW",
        "row": 25670,
        "state": "",
        "group": "909481",
        "group_num": [
            "recReX4cwwvYRgncf"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 895,
        "id": "recTUqRCOXCKGN5Tq",
        "row": 25683,
        "state": "",
        "group": "",
        "group_num": [
            "recP7POp2V60N3bAi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3762,
        "id": "recU66uDbz53os2jL",
        "row": 25703,
        "state": "",
        "group": "",
        "group_num": [
            "recRIsEIythnVYR1i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 290,
        "id": "recVRUnhIa7bfhAWo",
        "row": 25666,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Mngkw8AbYqhQm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "id": "recWgi9a23PVDYig6",
        "row": 25684,
        "state": "",
        "group": "911860",
        "group_num": [
            "recL91IoY3IFosaf6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 251,
        "id": "recY1YaI1ZFLSoCiD",
        "row": 25688,
        "state": "",
        "group": "",
        "group_num": [
            "rechCCxKrY57MxAia"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4403,
        "id": "recZsv3AuaXyiclvG",
        "row": 25657,
        "state": "",
        "group": "909006",
        "group_num": [
            "recdGM4oiCZI8w11w"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 639,
        "2023": 639,
        "id": "recZzbH7RCHm3VkC0",
        "row": 25664,
        "state": "",
        "group": "909268",
        "group_num": [
            "rec27TYtQKQW15RmY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 149,
        "id": "recZATimEweLCO1mA",
        "row": 25677,
        "state": "",
        "group": "",
        "group_num": [
            "recbnXtrT1TJf6Aje"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 544,
        "id": "rec1yCzBX7ivGf1tl",
        "row": 25692,
        "state": "",
        "group": "",
        "group_num": [
            "recFRyZSZRStzqYGm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1920,
        "id": "rec1KWYnej2QXGpIZ",
        "row": 25705,
        "state": "",
        "group": "914680",
        "group_num": [
            "recsXyoBjT9G19Ekv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "id": "rec2mMqdyGyZ8HttN",
        "row": 25701,
        "state": "",
        "group": "",
        "group_num": [
            "reclVvuEO90PbaRlc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 734,
        "id": "rec37OrWBr6ed6eM8",
        "row": 25696,
        "state": "",
        "group": "914108",
        "group_num": [
            "reckXzXuVPg6K75FH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "rec5z4VgzStPI4Bmo",
        "row": 25698,
        "state": "",
        "group": "914240",
        "group_num": [
            "recJgGPcix0GCnqr5"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 542,
        "id": "rec6y4UwRahP40Poa",
        "row": 25694,
        "state": "",
        "group": "",
        "group_num": [
            "recT7buOjcfOGJjR0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 984,
        "id": "rec8Y9sSKS4XziYMn",
        "row": 25669,
        "state": "",
        "group": "",
        "group_num": [
            "recrAAb5eGQo15KMX"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "rec9zmKsjdWYZprLJ",
        "row": 25672,
        "state": "",
        "group": "",
        "group_num": [
            "rec1T4ubbQtIA2j8m"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "id": "reccKUhoNskdGnlfE",
        "row": 25685,
        "state": "",
        "group": "912620",
        "group_num": [
            "recsEheC27t2eSp2H"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recouFxbqjlgtGkaR",
        "row": 25662,
        "state": "",
        "group": "",
        "group_num": [
            "recepTcAoM6b9zEOw"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2242,
        "id": "recoHrbLrha0oDktt",
        "row": 25663,
        "state": "",
        "group": "909262",
        "group_num": [
            "rec03iyodc6xerJrN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1200,
        "2023": 575,
        "id": "recpYnyr90Godyjhk",
        "row": 25660,
        "state": "",
        "group": "909166",
        "group_num": [
            "recXgZCi84UdHUHVD"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 972,
        "id": "recq27i6NM6Sj5USD",
        "row": 25679,
        "state": "",
        "group": "911335",
        "group_num": [
            "rec2ho0CU0wXGgwNp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 331,
        "id": "recr4drwCxrE7xH6P",
        "row": 25673,
        "state": "",
        "group": "",
        "group_num": [
            "recv78Tqt6shQUiG8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 307,
        "id": "recrc67PYjj5OKCn2",
        "row": 25699,
        "state": "",
        "group": "914240",
        "group_num": [
            "recJgGPcix0GCnqr5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 21,
        "2024": 21,
        "id": "recreeA03m36bJAo7",
        "row": 25667,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Mngkw8AbYqhQm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "id": "rectMHPHzYoTUNsD8",
        "row": 25678,
        "state": "",
        "group": "911335",
        "group_num": [
            "rec2ho0CU0wXGgwNp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 511,
        "id": "rect4VfMm2OgPsewm",
        "row": 25706,
        "state": "",
        "group": "914680",
        "group_num": [
            "recsXyoBjT9G19Ekv"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 136,
        "id": "recwLCH4qfibE8Tff",
        "row": 25690,
        "state": "",
        "group": "",
        "group_num": [
            "rechCCxKrY57MxAia"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recyUkrUxluwJZJ2O",
        "row": 25697,
        "state": "",
        "group": "914109",
        "group_num": [
            "recETXIXHvW3Q5Uix"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2533,
        "2023": 904,
        "id": "recyOzCt6Bxe3om7o",
        "row": 25671,
        "state": "",
        "group": "909481",
        "group_num": [
            "recReX4cwwvYRgncf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 895,
        "id": "recAPVmOZZzYuZzJJ",
        "row": 25674,
        "state": "",
        "group": "",
        "group_num": [
            "recF9xkQP3VHf53sV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 856,
        "id": "recB0Jin4osgY6sMQ",
        "row": 25695,
        "state": "",
        "group": "",
        "group_num": [
            "recT7buOjcfOGJjR0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recC0Chut6qRW8Coz",
        "row": 25702,
        "state": "",
        "group": "",
        "group_num": [
            "rec8cRc4C0jERXvGV"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 581,
        "id": "recCpsAQThX5ts9OX",
        "row": 25661,
        "state": "",
        "group": "",
        "group_num": [
            "recepTcAoM6b9zEOw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 525,
        "id": "recEHruv3Kuge1iJB",
        "row": 25687,
        "state": "",
        "group": "",
        "group_num": [
            "rechCCxKrY57MxAia"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 203,
        "2024": 158,
        "id": "recFbPVs3xDQowldf",
        "row": 25668,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Mngkw8AbYqhQm"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1048,
        "id": "recGWR6DEeZaWk7dU",
        "row": 25658,
        "state": "",
        "group": "",
        "group_num": [
            "recg5L00J3ii6tZdm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 596,
        "id": "recHGr37Vq51U4oRz",
        "row": 25681,
        "state": "",
        "group": "911528",
        "group_num": [
            "reck7pmbAMZzGATwI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 650,
        "id": "recJ03RmMJ5qpB169",
        "row": 25686,
        "state": "",
        "group": "",
        "group_num": [
            "rectZaTYJnwOUJhLe"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 675,
        "id": "recKb5Cig1BLamWP0",
        "row": 25700,
        "state": "",
        "group": "914355",
        "group_num": [
            "recDLpHj56m2VEJpf"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "id": "recMAEBKKlwJThE87",
        "row": 25689,
        "state": "",
        "group": "",
        "group_num": [
            "rechCCxKrY57MxAia"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 262,
        "2023": 262,
        "id": "recMGR6lSmbJLZI9E",
        "row": 25693,
        "state": "",
        "group": "",
        "group_num": [
            "recFRyZSZRStzqYGm"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 2625,
        "id": "recf2K3SWr5n1HlKe",
        "row": 25714,
        "state": "",
        "group": "914756",
        "group_num": [
            "rectbavPNjZQn0meZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 55,
        "id": "recgzmUrTh9Qmw5NX",
        "row": 25726,
        "state": "",
        "group": "915259",
        "group_num": [
            "recZWcY20QZ839que"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "rech5BbBS3XaCORIU",
        "row": 25737,
        "state": "",
        "group": "",
        "group_num": [
            "rec4Agy1kpk5K3JnX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 23,
        "id": "reciMUWGw7ocCKjCg",
        "row": 25727,
        "state": "",
        "group": "915275",
        "group_num": [
            "recCZWvyVhAcWh5jX"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1596,
        "id": "recOqk4FNYeVEtaGE",
        "row": 25755,
        "state": "",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "recP9mCsHfcinlJ4q",
        "row": 25731,
        "state": "",
        "group": "915574",
        "group_num": [
            "recJTKNMbxLVNrFLk"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 634,
        "id": "recRLWNGI2EVDHASO",
        "row": 25745,
        "state": "",
        "group": "",
        "group_num": [
            "recSecWkQmUfxK2rJ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 30,
        "id": "recSWOhgyn7QfXNz8",
        "row": 25721,
        "state": "",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 307,
        "id": "recSMV9qVjM6kybVC",
        "row": 25746,
        "state": "",
        "group": "",
        "group_num": [
            "recSecWkQmUfxK2rJ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 1017,
        "id": "recSrmaFrO1gRGc7C",
        "row": 25744,
        "state": "",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 80,
        "2023": 74,
        "id": "recVLSrzsfBqxwCWq",
        "row": 25718,
        "state": "",
        "group": "915152",
        "group_num": [
            "recUdjT0I2WAbtp6x"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1939,
        "2023": 2085,
        "id": "recWxiFy0aOSDFXAZ",
        "row": 25711,
        "state": "",
        "group": "914756",
        "group_num": [
            "rectbavPNjZQn0meZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 665,
        "2023": 608,
        "id": "recWzQ6RJetlnL9Zu",
        "row": 25712,
        "state": "",
        "group": "914756",
        "group_num": [
            "rectbavPNjZQn0meZ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 24,
        "id": "rec03fc6a7cUu4X8P",
        "row": 25730,
        "state": "",
        "group": "",
        "group_num": [
            "reclCFD1r361dMMbE"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3845,
        "id": "rec0gtn2oFaZcAoCL",
        "row": 25738,
        "state": "",
        "group": "",
        "group_num": [
            "rec4Agy1kpk5K3JnX"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 269,
        "id": "rec1GadqLBBQs0VJ3",
        "row": 25740,
        "state": "",
        "group": "",
        "group_num": [
            "recr2IvS3XBJEw9n5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 369,
        "id": "rec4fERbr4wEObSlS",
        "row": 25749,
        "state": "",
        "group": "",
        "group_num": [
            "recduN5X0EeKqWMqp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1325,
        "id": "rec6R6hYjI2hfQFtp",
        "row": 25750,
        "state": "",
        "group": "",
        "group_num": [
            "recduN5X0EeKqWMqp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 972,
        "id": "rec7kjrWBbITgMRJ2",
        "row": 25732,
        "state": "",
        "group": "915574",
        "group_num": [
            "recJTKNMbxLVNrFLk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 460,
        "id": "recaAUiePx4iir488",
        "row": 25708,
        "state": "",
        "group": "",
        "group_num": [
            "rec3UZKp86y9mqD2q"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1223,
        "2023": 2580,
        "id": "recbLrPY9noJc3X3f",
        "row": 25725,
        "state": "",
        "group": "915259",
        "group_num": [
            "recZWcY20QZ839que"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "reccXGFbFlDdNtVwN",
        "row": 25753,
        "state": "",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 797,
        "id": "reccBJCjtetM9DQkJ",
        "row": 25748,
        "state": "",
        "group": "",
        "group_num": [
            "recduN5X0EeKqWMqp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 959,
        "2023": 984,
        "id": "recc71krnp6p16q3b",
        "row": 25719,
        "state": "",
        "group": "915187",
        "group_num": [
            "recXDUmfiGcpk7sNN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "recdWdedl77wdANf5",
        "row": 25754,
        "state": "",
        "group": "918792",
        "group_num": [
            "recuNxheHGkUtNTjW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3745,
        "id": "recdCPzmUY9glkpGl",
        "row": 25707,
        "state": "",
        "group": "914680",
        "group_num": [
            "recsXyoBjT9G19Ekv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 150,
        "id": "recqdzuFMuu5WHbZa",
        "row": 25722,
        "state": "",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4558,
        "id": "recsH2tstKKUQ9udM",
        "row": 25747,
        "state": "",
        "group": "",
        "group_num": [
            "recmvZIVy7au4kF8p"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 264,
        "id": "recvgN0NtuvHd3kQ4",
        "row": 25717,
        "state": "",
        "group": "",
        "group_num": [
            "recFgyyQKNVQFPxxT"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2165,
        "2023": 3488,
        "id": "recx72ur15Cxo6TEb",
        "row": 25724,
        "state": "",
        "group": "915259",
        "group_num": [
            "recZWcY20QZ839que"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 134,
        "2024": 145,
        "id": "recxkgCxuCgVijRJb",
        "row": 25742,
        "state": "",
        "group": "",
        "group_num": [
            "recr2IvS3XBJEw9n5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1317,
        "id": "recyvPbKfIIcSGmbM",
        "row": 25733,
        "state": "",
        "group": "",
        "group_num": [
            "recvfZTvDugKIzcUB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 289,
        "id": "reczgMy6kMrL4dYWq",
        "row": 25739,
        "state": "",
        "group": "",
        "group_num": [
            "recyznPB7FO0EjbLZ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 139,
        "id": "recAnqIlmP1LEl9Ph",
        "row": 25710,
        "state": "",
        "group": "",
        "group_num": [
            "rec3UZKp86y9mqD2q"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1096,
        "id": "recCFaDBWM86FA7Qo",
        "row": 25728,
        "state": "",
        "group": "",
        "group_num": [
            "recrj75mpqlbZNsD8"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "recDsKb0iJQHKwkmz",
        "row": 25735,
        "state": "",
        "group": "915651",
        "group_num": [
            "recrpog9Rxye8aip0"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "2023": 990,
        "id": "recE1T2LcihFHPl2v",
        "row": 25743,
        "state": "",
        "group": "917105",
        "group_num": [
            "recT0NDGL3IKEQ2SD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1596,
        "2023": 510,
        "id": "recErPVtzqfPgDeDf",
        "row": 25720,
        "state": "",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 521,
        "id": "recHxKospdwuWcU73",
        "row": 25715,
        "state": "",
        "group": "914816",
        "group_num": [
            "recDVQsqfLLOoVoMu"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 180,
        "id": "recHCfcrjYixiK85i",
        "row": 25716,
        "state": "",
        "group": "914816",
        "group_num": [
            "recDVQsqfLLOoVoMu"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "id": "recHMGuLwXFqqP50r",
        "row": 25729,
        "state": "",
        "group": "",
        "group_num": [
            "recrj75mpqlbZNsD8"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 21,
        "id": "recIVKcmPD4kVQ1UZ",
        "row": 25741,
        "state": "",
        "group": "",
        "group_num": [
            "recr2IvS3XBJEw9n5"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 281,
        "id": "recI5XfuRa4cgmyUV",
        "row": 25709,
        "state": "",
        "group": "",
        "group_num": [
            "rec3UZKp86y9mqD2q"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 192,
        "id": "recJOn7Cdyhpb727U",
        "row": 25751,
        "state": "",
        "group": "",
        "group_num": [
            "recduN5X0EeKqWMqp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 889,
        "id": "recKW4B6CDOIPbErc",
        "row": 25723,
        "state": "",
        "group": "915206",
        "group_num": [
            "recltvuyeEenX071X"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 355,
        "id": "recLuaY7Rv9f4mPcd",
        "row": 25756,
        "state": "",
        "group": "",
        "group_num": [
            "recWgMejkF2gKMJdg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1228,
        "id": "recLA46w2kjcZX8A4",
        "row": 25752,
        "state": "",
        "group": "",
        "group_num": [
            "recduN5X0EeKqWMqp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 145,
        "id": "recLh6AEUYyv8Rtw9",
        "row": 25736,
        "state": "",
        "group": "915651",
        "group_num": [
            "recrpog9Rxye8aip0"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 332,
        "id": "recMYkJyBBnsvs08S",
        "row": 25713,
        "state": "",
        "group": "914756",
        "group_num": [
            "rectbavPNjZQn0meZ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 230,
        "id": "recNsORmnxytNA1DW",
        "row": 25734,
        "state": "",
        "group": "",
        "group_num": [
            "recjd2Km0czx25xRR"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "id": "recgCLDr9LeANY5iu",
        "row": 25786,
        "state": "",
        "group": "",
        "group_num": [
            "recNBXrTqh3pXX4RR"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 871,
        "id": "recit7Xx3C5HycPQj",
        "row": 25759,
        "state": "",
        "group": "919054",
        "group_num": [
            "recB83oKcdvwi96qj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 261,
        "id": "recivC810Uhv3A7Fy",
        "row": 25806,
        "state": "",
        "group": "",
        "group_num": [
            "recqRHdmJnEo6G13B"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 980,
        "id": "reciRhwIAoQhi9nsb",
        "row": 25801,
        "state": "",
        "group": "",
        "group_num": [
            "recf33dA94qmzqkPr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1793,
        "id": "recie89Jf2Pk7PGTO",
        "row": 25758,
        "state": "",
        "group": "919054",
        "group_num": [
            "recB83oKcdvwi96qj"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 570,
        "id": "recljUaWvbCHRhn1W",
        "row": 25774,
        "state": "",
        "group": "921107",
        "group_num": [
            "recz2dsYAPZP8sU4w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "recnsr6C81LMCtIrH",
        "row": 25796,
        "state": "",
        "group": "922403",
        "group_num": [
            "rec5ETXW7LoJil2rz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 287,
        "id": "recOuTPcWIju0HCgZ",
        "row": 25773,
        "state": "",
        "group": "",
        "group_num": [
            "recfh9bhIPlXLCmkK"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 746,
        "id": "recPguLGj4xnS0Ynl",
        "row": 25763,
        "state": "",
        "group": "",
        "group_num": [
            "recIkTZ5jZdYLdX0y"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "id": "recRCARw4F93k7MF9",
        "row": 25770,
        "state": "",
        "group": "920859",
        "group_num": [
            "recVPYA2aLn6vcTB7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 895,
        "id": "recSrYalmOdP7YHWx",
        "row": 25802,
        "state": "",
        "group": "",
        "group_num": [
            "rec5j0WBKfZIXRcyp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 163,
        "id": "recVAA1i0A3Esbcxu",
        "row": 25769,
        "state": "",
        "group": "",
        "group_num": [
            "rec5a3ZjSJry5r624"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 171,
        "id": "recW1dgqg8vICMH8c",
        "row": 25787,
        "state": "",
        "group": "",
        "group_num": [
            "recNBXrTqh3pXX4RR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 557,
        "id": "recXNXFBMFs6eGgsL",
        "row": 25757,
        "state": "",
        "group": "",
        "group_num": [
            "rec1Ikf4MWAvGuj8n"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 628,
        "id": "rec0CMVVpOzB88jSO",
        "row": 25799,
        "state": "",
        "group": "",
        "group_num": [
            "recf33dA94qmzqkPr"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 268,
        "id": "rec0Qcgs499IHeLvr",
        "row": 25793,
        "state": "",
        "group": "",
        "group_num": [
            "recXxoAfM2qr8dh49"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 293,
        "id": "rec1e6xlStIbeEZDT",
        "row": 25800,
        "state": "",
        "group": "",
        "group_num": [
            "recf33dA94qmzqkPr"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1073,
        "id": "rec2Ny2UOnF6wlUlm",
        "row": 25766,
        "state": "",
        "group": "",
        "group_num": [
            "recIkTZ5jZdYLdX0y"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 945,
        "id": "rec3deP4a1oAPBUkj",
        "row": 25778,
        "state": "",
        "group": "",
        "group_num": [
            "rec3dPNn68SA9y1CN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2500,
        "id": "rec5H3XWZ7zmN7ISS",
        "row": 25767,
        "state": "",
        "group": "920106",
        "group_num": [
            "recQmIhKRJK3sWEcP"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "rec5a83riV3PxEjos",
        "row": 25772,
        "state": "",
        "group": "",
        "group_num": [
            "rec68NyEQZw8Yxb2l"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 786,
        "id": "rec6SDsYu7RWDrG4C",
        "row": 25784,
        "state": "",
        "group": "",
        "group_num": [
            "rec5MJiB1X5HkxSsv"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1077,
        "id": "rec6i6piDTawMVJwr",
        "row": 25782,
        "state": "",
        "group": "",
        "group_num": [
            "recIeTzzcJNg0CZP5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2013,
        "2023": 1894,
        "id": "rec8zWdwq6s1T4RDI",
        "row": 25781,
        "state": "",
        "group": "",
        "group_num": [
            "recIeTzzcJNg0CZP5"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 546,
        "id": "rec9HPFzW4yxoVvzP",
        "row": 25792,
        "state": "",
        "group": "",
        "group_num": [
            "recXxoAfM2qr8dh49"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 261,
        "id": "reca1PmJSA74k1UFz",
        "row": 25804,
        "state": "",
        "group": "",
        "group_num": [
            "recAodqcAQY896G7q"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1109,
        "id": "recbnxMAR0zRAJRjH",
        "row": 25790,
        "state": "",
        "group": "",
        "group_num": [
            "rectfYmxHKhoTlbiT"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 157,
        "id": "recc1iB1heNRqSRAD",
        "row": 25765,
        "state": "",
        "group": "",
        "group_num": [
            "recIkTZ5jZdYLdX0y"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 872,
        "id": "reccgsg9vpkWy1QFl",
        "row": 25798,
        "state": "",
        "group": "922623",
        "group_num": [
            "recnXWeOjp4rql1G6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 953,
        "id": "recdVVrZc8fsEMARM",
        "row": 25761,
        "state": "",
        "group": "",
        "group_num": [
            "rec1EYbBNUrB8OI1I"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 478,
        "id": "recdWffH4OLeHCjhj",
        "row": 25760,
        "state": "",
        "group": "919054",
        "group_num": [
            "recB83oKcdvwi96qj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 553,
        "id": "recd2uLjHlVyQCkaV",
        "row": 25797,
        "state": "",
        "group": "922623",
        "group_num": [
            "recnXWeOjp4rql1G6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 542,
        "id": "recpIJc6pSxVkcAwl",
        "row": 25803,
        "state": "",
        "group": "",
        "group_num": [
            "recAodqcAQY896G7q"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 489,
        "id": "recpiUMJsyGVSLFFu",
        "row": 25762,
        "state": "",
        "group": "",
        "group_num": [
            "rec1EYbBNUrB8OI1I"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 276,
        "id": "recrxYFE5PfOajmai",
        "row": 25775,
        "state": "",
        "group": "921107",
        "group_num": [
            "recz2dsYAPZP8sU4w"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 264,
        "id": "recsSweldTEouv5TL",
        "row": 25768,
        "state": "",
        "group": "",
        "group_num": [
            "rec5a3ZjSJry5r624"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 399,
        "id": "recuFKN0P7fSfmZTE",
        "row": 25764,
        "state": "",
        "group": "",
        "group_num": [
            "recIkTZ5jZdYLdX0y"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3221,
        "id": "recvuZDeLPMF7yDx4",
        "row": 25776,
        "state": "",
        "group": "",
        "group_num": [
            "recimoM3EMzZHJKTY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 571,
        "id": "recvEnjOzDzjPSbrR",
        "row": 25777,
        "state": "",
        "group": "",
        "group_num": [
            "recwqQ63R5U48wTet"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 927,
        "id": "recvoHaFGfv06Xnqv",
        "row": 25780,
        "state": "",
        "group": "921378",
        "group_num": [
            "recjClfU9ElbzD0bB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 628,
        "2023": 628,
        "id": "recw1fOxJsMm0vNJQ",
        "row": 25788,
        "state": "",
        "group": "",
        "group_num": [
            "rectfYmxHKhoTlbiT"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 145,
        "id": "reczbUDsvybAnxBw2",
        "row": 25794,
        "state": "",
        "group": "",
        "group_num": [
            "recXxoAfM2qr8dh49"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 331,
        "id": "recAKU5eLSKV5fdow",
        "row": 25785,
        "state": "",
        "group": "",
        "group_num": [
            "recNBXrTqh3pXX4RR"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 518,
        "id": "recFKyX9GWYYApCjm",
        "row": 25805,
        "state": "",
        "group": "923344",
        "group_num": [
            "recKXoz70QExViTa3"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 538,
        "id": "recFMmiX6ENBthzDr",
        "row": 25791,
        "state": "",
        "group": "",
        "group_num": [
            "reczKeeYOhIazg3cw"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2192,
        "id": "recG0Re0NOLG8TSPW",
        "row": 25783,
        "state": "",
        "group": "",
        "group_num": [
            "rec5MJiB1X5HkxSsv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1658,
        "id": "recKV8QmBJVdK1yT6",
        "row": 25779,
        "state": "",
        "group": "",
        "group_num": [
            "rec3dPNn68SA9y1CN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 63,
        "id": "recKENJCZHFBxzNSK",
        "row": 25771,
        "state": "",
        "group": "",
        "group_num": [
            "rec68NyEQZw8Yxb2l"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "recMjaaPU1TQr4K7N",
        "row": 25795,
        "state": "",
        "group": "922403",
        "group_num": [
            "rec5ETXW7LoJil2rz"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 309,
        "id": "recNsV5Er8mSYmKo6",
        "row": 25789,
        "state": "",
        "group": "",
        "group_num": [
            "rectfYmxHKhoTlbiT"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 155,
        "id": "recgcUW6EmpG6Kqjh",
        "row": 25844,
        "state": "",
        "group": "925681",
        "group_num": [
            "recJolFGVw04jwKe3"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 257,
        "id": "recisx31AV5hUPeHI",
        "row": 25808,
        "state": "",
        "group": "",
        "group_num": [
            "rec0m9K3gUy2RXkfp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 145,
        "id": "recinyPaxi3PkzJSc",
        "row": 25816,
        "state": "",
        "group": "",
        "group_num": [
            "recTqB9HFty5hkEb3"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "id": "recjHlizLUTyI3nJV",
        "row": 25839,
        "state": "",
        "group": "",
        "group_num": [
            "rec3AgJ5y1wrH9BK0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recj4TAfNxXDgvb4t",
        "row": 25827,
        "state": "",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 939,
        "id": "recnP6H3VAymv0kdr",
        "row": 25836,
        "state": "",
        "group": "925108",
        "group_num": [
            "rec7UFuaPRohdnrSE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 297,
        "id": "recn8AHzh54fXBgsU",
        "row": 25847,
        "state": "",
        "group": "",
        "group_num": [
            "rec6Lq9bN702VxxRr"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 995,
        "id": "recnrZYWFvyVbceya",
        "row": 25840,
        "state": "",
        "group": "",
        "group_num": [
            "recmkfKjrre13go5H"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 282,
        "id": "recR0SzoM7vr2M6t4",
        "row": 25815,
        "state": "",
        "group": "",
        "group_num": [
            "recTqB9HFty5hkEb3"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 697,
        "id": "recSHZmGWzXsVUlw0",
        "row": 25813,
        "state": "",
        "group": "923974",
        "group_num": [
            "recK25HQra2vcNbfx"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 575,
        "id": "recUxqtsRQrxomF9n",
        "row": 25824,
        "state": "",
        "group": "",
        "group_num": [
            "receymSVb9uiqtGCl"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 598,
        "id": "recUyH4WbYXhkSIcE",
        "row": 25837,
        "state": "",
        "group": "",
        "group_num": [
            "recS4KsM5tEk47ym1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 704,
        "id": "recUGM6qQ3p7IwRAO",
        "row": 25818,
        "state": "",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 25,
        "id": "recUPyTz3QmircLoI",
        "row": 25843,
        "state": "",
        "group": "925681",
        "group_num": [
            "recJolFGVw04jwKe3"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 242,
        "id": "recV90lnu9fNZCIY7",
        "row": 25856,
        "state": "",
        "group": "926950",
        "group_num": [
            "recJeEXW9E4DlL7kz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 346,
        "id": "recVqoqJ7ckj23F4W",
        "row": 25823,
        "state": "",
        "group": "",
        "group_num": [
            "rec9bxvqVxqgsaymc"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1221,
        "2023": 1158,
        "id": "recWONGhtiSZz9ib2",
        "row": 25821,
        "state": "",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 26,
        "2023": 20,
        "id": "recWfZ3RRRnXLQ09b",
        "row": 25822,
        "state": "",
        "group": "",
        "group_num": [
            "rec9bxvqVxqgsaymc"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 164,
        "id": "recXfMrrYeqJlKbuc",
        "row": 25842,
        "state": "",
        "group": "",
        "group_num": [
            "recpnunBsAglq10HY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recXnQ3xqoccgMGSb",
        "row": 25817,
        "state": "",
        "group": "",
        "group_num": [
            "recTqB9HFty5hkEb3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recYBnnw0RCsnw0jw",
        "row": 25812,
        "state": "",
        "group": "923974",
        "group_num": [
            "recK25HQra2vcNbfx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 502,
        "id": "recYENrEDSvFMDcgG",
        "row": 25807,
        "state": "",
        "group": "",
        "group_num": [
            "rec0m9K3gUy2RXkfp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 555,
        "id": "recZHAGVrgIWcBNTM",
        "row": 25809,
        "state": "",
        "group": "",
        "group_num": [
            "recXLGhFptk2WRmZ6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 872,
        "id": "recZaAtXANtmFkpol",
        "row": 25810,
        "state": "",
        "group": "",
        "group_num": [
            "recXLGhFptk2WRmZ6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recZlNFipeDfUy7jE",
        "row": 25835,
        "state": "",
        "group": "925108",
        "group_num": [
            "rec7UFuaPRohdnrSE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 279,
        "id": "rec8edCyGMqGFXRY6",
        "row": 25829,
        "state": "",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "rec9ztha835iH43jR",
        "row": 25814,
        "state": "",
        "group": "",
        "group_num": [
            "recTqB9HFty5hkEb3"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 281,
        "id": "rec9ir53InMqYEmZh",
        "row": 25851,
        "state": "",
        "group": "",
        "group_num": [
            "recS3JNUpZBlPderU"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2376,
        "id": "recc5WQdyIYqerxac",
        "row": 25826,
        "state": "",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 587,
        "id": "recdZtH72RWUb06gX",
        "row": 25846,
        "state": "",
        "group": "",
        "group_num": [
            "rec6Lq9bN702VxxRr"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 204,
        "id": "recdguHn0sf1rNkbR",
        "row": 25820,
        "state": "",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 131,
        "id": "recpYBZQ3gm2XPsbu",
        "row": 25855,
        "state": "",
        "group": "",
        "group_num": [
            "recqyelS1CUiHLpun"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 153,
        "id": "recsANR02HDQEq08F",
        "row": 25848,
        "state": "",
        "group": "",
        "group_num": [
            "rec6Lq9bN702VxxRr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "id": "recuh2NmO8055h6Dj",
        "row": 25845,
        "state": "",
        "group": "925681",
        "group_num": [
            "recJolFGVw04jwKe3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1011,
        "id": "recwBhVpvNUE6q1g2",
        "row": 25853,
        "state": "",
        "group": "926724",
        "group_num": [
            "recNlxKSXPAS6NM7R"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recwp7HTv4vnIAcGR",
        "row": 25831,
        "state": "",
        "group": "924851",
        "group_num": [
            "rec9QeG2dOm8DLKU7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 37,
        "id": "recxdgnmIhmGb1SZ1",
        "row": 25819,
        "state": "",
        "group": "924422",
        "group_num": [
            "rectCqutsjWDoVM7w"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2230,
        "id": "recyBaDE5ukTIi3Bf",
        "row": 25830,
        "state": "",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1150,
        "id": "recAYtFGFwGrjlaVg",
        "row": 25834,
        "state": "",
        "group": "",
        "group_num": [
            "rec1UyU58gBmrij4s"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 544,
        "id": "recBOq7uWpPtSNDzT",
        "row": 25849,
        "state": "",
        "group": "",
        "group_num": [
            "reczv0dk2jaZRs1qd"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 0,
        "id": "recBb5FBvQRE9WOOY",
        "row": 25828,
        "state": "",
        "group": "924787",
        "group_num": [
            "recxUK95CFBweHTug"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 954,
        "id": "recD5nY4ykSvsBL8A",
        "row": 25841,
        "state": "",
        "group": "925342",
        "group_num": [
            "recTG7Kj2TvbqWORo"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 591,
        "id": "recEWDibBbqBdWaBj",
        "row": 25838,
        "state": "",
        "group": "",
        "group_num": [
            "rec3AgJ5y1wrH9BK0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 21,
        "id": "recFrceCs3jpKwkLA",
        "row": 25854,
        "state": "",
        "group": "",
        "group_num": [
            "recqyelS1CUiHLpun"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 918,
        "2023": 995,
        "id": "recGTsGxJjfLG6imh",
        "row": 25833,
        "state": "",
        "group": "925027",
        "group_num": [
            "recSUKEugzVOYQCkA"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 611,
        "id": "recH2KKg1oeHkDwXB",
        "row": 25832,
        "state": "",
        "group": "925027",
        "group_num": [
            "recSUKEugzVOYQCkA"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 591,
        "id": "recIw7g5I6wOEyiCm",
        "row": 25850,
        "state": "",
        "group": "",
        "group_num": [
            "recS3JNUpZBlPderU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 30,
        "id": "recIdvQTbQkggbJfw",
        "row": 25825,
        "state": "",
        "group": "924755",
        "group_num": [
            "rec3udQymRdeoSh8q"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 141,
        "id": "recJRv8ASuSB5OnZB",
        "row": 25811,
        "state": "",
        "group": "",
        "group_num": [
            "recoKNbgIGCAE9z7F"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1036,
        "id": "recK0D61szyLuNUH8",
        "row": 25852,
        "state": "",
        "group": "",
        "group_num": [
            "recS3JNUpZBlPderU"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 158,
        "id": "recfIE2xAFU7JbXdx",
        "row": 25860,
        "state": "",
        "group": "",
        "group_num": [
            "rec4sOCtGlgEQQ92l"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recf5Z9RhCaEYy120",
        "row": 25900,
        "state": "",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1350,
        "2023": 1350,
        "id": "recffEfpEcIZ9uGNS",
        "row": 25898,
        "state": "",
        "group": "0908220",
        "group_num": [
            "recw69a5sFsAM7g8w"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 954,
        "id": "recgnJQsHNS67IOdD",
        "row": 25862,
        "state": "",
        "group": "",
        "group_num": [
            "recJmhncFpVwjAHde"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 400,
        "id": "rechUcgs4PWUTaMqI",
        "row": 25875,
        "state": "",
        "group": "928305",
        "group_num": [
            "recw3BmnUFhBqh392"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 86,
        "id": "rechKNsAEE8EFmwGE",
        "row": 25887,
        "state": "",
        "group": "",
        "group_num": [
            "recZ0vdyCbVPFd01q"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1358,
        "id": "reclZQTBADBMWu1Sw",
        "row": 25897,
        "state": "",
        "group": "0908220",
        "group_num": [
            "recw69a5sFsAM7g8w"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "id": "recmAXGO2QxKqWJWR",
        "row": 25865,
        "state": "",
        "group": "927712",
        "group_num": [
            "reck0BnbPw0bYgruv"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 984,
        "id": "recmOdNaZmdACMB0D",
        "row": 25878,
        "state": "",
        "group": "",
        "group_num": [
            "rec9gFjlVby4CkVkh"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 282,
        "id": "recn5nlxr1WsH3jNu",
        "row": 25893,
        "state": "",
        "group": "",
        "group_num": [
            "recxjdbgvcU0dXbT5"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 385,
        "id": "recOpjoZtAandTRet",
        "row": 25883,
        "state": "",
        "group": "",
        "group_num": [
            "recKQo0oiaIcTXwdF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 554,
        "id": "recSwPCI80nrxhVNl",
        "row": 25889,
        "state": "",
        "group": "",
        "group_num": [
            "recDZwC3BcAwT1LSr"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "2023": 3000,
        "id": "recSjhKa2PIZ2XFeD",
        "row": 25902,
        "state": "",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "recTPl8UPCqBhw6nG",
        "row": 25867,
        "state": "",
        "group": "927712",
        "group_num": [
            "reck0BnbPw0bYgruv"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1401,
        "id": "recUnCgAC5bHUn6iE",
        "row": 25906,
        "state": "",
        "group": "1103835",
        "group_num": [
            "recoywgCZHLm0JA3s"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 322,
        "id": "recVXKuIvjkDswqv1",
        "row": 25886,
        "state": "",
        "group": "",
        "group_num": [
            "rec4vkVxxJv9klPYp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1200,
        "id": "recYyUep8Vxpl375d",
        "row": 25879,
        "state": "",
        "group": "",
        "group_num": [
            "reclmtwo7wt1s6ekM"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 961,
        "id": "recZlZqwec6hsqTcd",
        "row": 25861,
        "state": "",
        "group": "",
        "group_num": [
            "rec4sOCtGlgEQQ92l"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 866,
        "id": "rec0JkQowNkZbhTWP",
        "row": 25904,
        "state": "",
        "group": "1103835",
        "group_num": [
            "recoywgCZHLm0JA3s"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 630,
        "id": "rec04SVR0dNGzeTVR",
        "row": 25877,
        "state": "",
        "group": "",
        "group_num": [
            "rec9gFjlVby4CkVkh"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rec1F66qNmoJipxl4",
        "row": 25892,
        "state": "",
        "group": "",
        "group_num": [
            "recxjdbgvcU0dXbT5"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 326,
        "id": "rec4zQKk4w0OmtkcZ",
        "row": 25868,
        "state": "",
        "group": "",
        "group_num": [
            "recjZnZhll4InHoS6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec7E6DpjBLmbFapo",
        "row": 25903,
        "state": "",
        "group": "1102837CONNECTICARE2440329OPTUM",
        "group_num": [
            "rec1yblQQfelQtYUr"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 551,
        "2023": 551,
        "id": "rec8zJYEY8jer4JDR",
        "row": 25863,
        "state": "",
        "group": "927611",
        "group_num": [
            "recYFnPMjH84mie8v"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 478,
        "id": "reccsst2KI4KaSjEc",
        "row": 25884,
        "state": "",
        "group": "929444",
        "group_num": [
            "recjnWUeMFTWxqVFF"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "reccGeblcl9W92xhZ",
        "row": 25880,
        "state": "",
        "group": "",
        "group_num": [
            "rechqOw3m6ka3qsBW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "2024": 355,
        "id": "reccakbCuMH4PHrbT",
        "row": 25882,
        "state": "",
        "group": "",
        "group_num": [
            "recrxevML41Ws40Dr"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recoLjVzLuTS9Xsp2",
        "row": 25864,
        "state": "",
        "group": "",
        "group_num": [
            "recRB9SpdJpkveDJ2"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 550,
        "id": "recp20eRb2zfKANbZ",
        "row": 25894,
        "state": "",
        "group": "",
        "group_num": [
            "recxjdbgvcU0dXbT5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2100,
        "id": "recqpvbamUz3WtGLC",
        "row": 25896,
        "state": "",
        "group": "0908220",
        "group_num": [
            "recw69a5sFsAM7g8w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 347,
        "id": "recr1iq5Es4MJsaN2",
        "row": 25866,
        "state": "",
        "group": "927712",
        "group_num": [
            "reck0BnbPw0bYgruv"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 960,
        "2024": 936,
        "id": "recv9QSkrnknJthkK",
        "row": 25870,
        "state": "",
        "group": "",
        "group_num": [
            "recjZnZhll4InHoS6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 461,
        "id": "recvdl2qkdLNBe66z",
        "row": 25905,
        "state": "",
        "group": "1103835",
        "group_num": [
            "recoywgCZHLm0JA3s"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 155,
        "id": "recxTyAbXkiBz8COt",
        "row": 25876,
        "state": "",
        "group": "928305",
        "group_num": [
            "recw3BmnUFhBqh392"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recx0uJwNDgAc0BsD",
        "row": 25901,
        "state": "",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1120,
        "id": "recxjEsK3z9kE2g5I",
        "row": 25874,
        "state": "",
        "group": "928305",
        "group_num": [
            "recw3BmnUFhBqh392"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3076,
        "id": "recxrE2VAJRjbiBHd",
        "row": 25895,
        "state": "",
        "group": "",
        "group_num": [
            "recRZuiXOvfl7oNWm"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 177,
        "id": "reczZscYVHZ2w1nos",
        "row": 25869,
        "state": "",
        "group": "",
        "group_num": [
            "recjZnZhll4InHoS6"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "recBxczSzPWspLxSJ",
        "row": 25899,
        "state": "",
        "group": "1102837",
        "group_num": [
            "rec8GqwoPTaANF3c4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 289,
        "id": "recC77A5BJoieZrHz",
        "row": 25858,
        "state": "",
        "group": "",
        "group_num": [
            "rec4sOCtGlgEQQ92l"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "recHtzMBchUiyGIq3",
        "row": 25888,
        "state": "",
        "group": "",
        "group_num": [
            "rec40zHjoRo3BpP1H"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1920,
        "id": "recHCqQBENBfMkxvY",
        "row": 25873,
        "state": "",
        "group": "",
        "group_num": [
            "recfCL7jH6M0N2vim"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 571,
        "id": "recJD410P7KQvac67",
        "row": 25871,
        "state": "",
        "group": "",
        "group_num": [
            "recCVMGXMs64CoqGO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 281,
        "id": "recJ8vJ9vGc4lf8tm",
        "row": 25872,
        "state": "",
        "group": "",
        "group_num": [
            "recCVMGXMs64CoqGO"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 594,
        "id": "recKQNSIvFqvAI6nl",
        "row": 25857,
        "state": "",
        "group": "",
        "group_num": [
            "rec4sOCtGlgEQQ92l"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 29,
        "id": "recLaPfPGihhF0yq6",
        "row": 25859,
        "state": "",
        "group": "",
        "group_num": [
            "rec4sOCtGlgEQQ92l"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3056,
        "id": "recMIGHOT0wMqtTAg",
        "row": 25891,
        "state": "",
        "group": "",
        "group_num": [
            "recfXRqflPrQmk4Qe"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1020,
        "id": "recNZd1DtNRTjx8Bo",
        "row": 25881,
        "state": "",
        "group": "929225",
        "group_num": [
            "recNKOP83DVGKL4KV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1222,
        "id": "recNunOxeAF39kuL5",
        "row": 25890,
        "state": "",
        "group": "",
        "group_num": [
            "recylcZVwKfFhKG1i"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 695,
        "id": "recNAsJtl0gf6lX7a",
        "row": 25885,
        "state": "",
        "group": "",
        "group_num": [
            "rec4vkVxxJv9klPYp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 134,
        "id": "recehX59Nwh0N94gF",
        "row": 25947,
        "state": "",
        "group": "",
        "group_num": [
            "reciSt5GBQDXEEOEz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 257,
        "id": "recfWwawR8C8HGrZr",
        "row": 25942,
        "state": "",
        "group": "",
        "group_num": [
            "recb68B1pkHJRFhSc"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recfbhttcdpm25wC3",
        "row": 25935,
        "state": "",
        "group": "",
        "group_num": [
            "recabhMpTLcXRUmpJ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 853,
        "id": "recfguV48UjKBTC6O",
        "row": 25929,
        "state": "",
        "group": "1427704",
        "group_num": [
            "receI4ZtjOOr58py5"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 66,
        "id": "rechvfBzj8B4bWfGs",
        "row": 25952,
        "state": "",
        "group": "",
        "group_num": [
            "recPaS2lUFHkcpLKt"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "2024": 650,
        "id": "reciGSCHZqNPbuPKG",
        "row": 25954,
        "state": "",
        "group": "",
        "group_num": [
            "rec4ZJLrEMwWA9SDM"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 538,
        "id": "reckEkZrKtYAh4idk",
        "row": 25949,
        "state": "",
        "group": "",
        "group_num": [
            "recIl7SGmpVOfb7OX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 242,
        "id": "reclCMZ2xBoYjTxo7",
        "row": 25927,
        "state": "",
        "group": "",
        "group_num": [
            "recLOQwhz1BGF3i9j"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "2023": 776,
        "id": "recmDjQNYH08RyXnS",
        "row": 25908,
        "state": "",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1032,
        "2023": 791,
        "id": "recmIAn5Ij6wlrhK8",
        "row": 25926,
        "state": "",
        "group": "1418151",
        "group_num": [
            "recNJrWdufGUJ1Kno"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recOtt9EimOXzB6V8",
        "row": 25916,
        "state": "",
        "group": "1150079",
        "group_num": [
            "recRKffjH29Wni8DY"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 222,
        "id": "recQ4HLDCAcT1hq2I",
        "row": 25928,
        "state": "",
        "group": "1427704",
        "group_num": [
            "receI4ZtjOOr58py5"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 441,
        "id": "recQeu7JQ5KYXUEfh",
        "row": 25933,
        "state": "",
        "group": "",
        "group_num": [
            "recqm5kgJWDXkxZ7w"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "recSAlIGs9axhT8lH",
        "row": 25941,
        "state": "",
        "group": "",
        "group_num": [
            "recTIza3YcGunrEP0"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "id": "recTK51CmqhB0RHCv",
        "row": 25913,
        "state": "",
        "group": "",
        "group_num": [
            "recVsg6pOVS9wKltg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "recUIagHrEDlKWzEv",
        "row": 25950,
        "state": "",
        "group": "",
        "group_num": [
            "recIl7SGmpVOfb7OX"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 501,
        "id": "recXzeAXefX1YYla9",
        "row": 25930,
        "state": "",
        "group": "1431330",
        "group_num": [
            "recdSsNYRI8ewbT42"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 450,
        "id": "recXaw9WkqKvAHgtP",
        "row": 25920,
        "state": "",
        "group": "",
        "group_num": [
            "recJsCMPz7m8QsF8M"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "recXdGIP7Syo7PSx1",
        "row": 25938,
        "state": "",
        "group": "",
        "group_num": [
            "reciDN76O7lS60eF8"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 873,
        "id": "recXfDTyOoZhSVOAI",
        "row": 25956,
        "state": "",
        "group": "",
        "group_num": [
            "rec1uXHQ29lx6OAp7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 216,
        "id": "recYeKW2ifN7sTkej",
        "row": 25924,
        "state": "",
        "group": "",
        "group_num": [
            "recZrrfoLh0ElmEsq"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 453,
        "id": "recZkhcTCto6j7sVn",
        "row": 25951,
        "state": "",
        "group": "",
        "group_num": [
            "recPaS2lUFHkcpLKt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 315,
        "id": "rec1dh1y4qZslXyWP",
        "row": 25940,
        "state": "",
        "group": "",
        "group_num": [
            "recFnxKGR7Bi4AcEt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 284,
        "id": "rec1noGocRHCW3TUs",
        "row": 25909,
        "state": "",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 786,
        "id": "rec6z4CuFzo57pXYi",
        "row": 25923,
        "state": "",
        "group": "",
        "group_num": [
            "recZrrfoLh0ElmEsq"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "rec8FeL0v7lAluGzs",
        "row": 25939,
        "state": "",
        "group": "",
        "group_num": [
            "recVs3xySePZb2soB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 1542,
        "id": "rec8I9ViLfZKjwOxK",
        "row": 25910,
        "state": "",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 279,
        "id": "rec9S7DioLGhl5CMf",
        "row": 25945,
        "state": "",
        "group": "",
        "group_num": [
            "recMndJi2jP6GPaeT"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "rec9xPe9pxGAgCyFC",
        "row": 25912,
        "state": "",
        "group": "",
        "group_num": [
            "recVsg6pOVS9wKltg"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 1125,
        "id": "recpLavg8IwZnCTNT",
        "row": 25911,
        "state": "",
        "group": "",
        "group_num": [
            "recVsg6pOVS9wKltg"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recp4186qOpwAHjkJ",
        "row": 25918,
        "state": "",
        "group": "1150079",
        "group_num": [
            "recRKffjH29Wni8DY"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 156,
        "id": "recq31yDeC6kkTU6a",
        "row": 25953,
        "state": "",
        "group": "",
        "group_num": [
            "recPaS2lUFHkcpLKt"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 874,
        "id": "rectYt0J3RDw4hqyB",
        "row": 25955,
        "state": "",
        "group": "",
        "group_num": [
            "receizGZss33BOL8y"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 198,
        "id": "rect892wVbeIkw76J",
        "row": 25922,
        "state": "",
        "group": "1270366",
        "group_num": [
            "recVmZcnClMa1xeEb"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "id": "rectaSEdjvmNwVKkF",
        "row": 25936,
        "state": "",
        "group": "",
        "group_num": [
            "recatIm24wnksgXIM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "id": "recuz8lmbo5xGn3Mu",
        "row": 25917,
        "state": "",
        "group": "1150079",
        "group_num": [
            "recRKffjH29Wni8DY"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 791,
        "id": "recuhxTjNtBzvTAmQ",
        "row": 25931,
        "state": "",
        "group": "1431330",
        "group_num": [
            "recdSsNYRI8ewbT42"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1643,
        "2023": 1038,
        "2024": 960,
        "id": "recvZ7kkPKgvSSU0S",
        "row": 25907,
        "state": "",
        "group": "1150001",
        "group_num": [
            "reckGMZh58iPFvjrI"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 576,
        "id": "recxEDNiZD7DGkEo4",
        "row": 25919,
        "state": "",
        "group": "",
        "group_num": [
            "recJsCMPz7m8QsF8M"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 141,
        "id": "recyzBfCRiQlwppDv",
        "row": 25948,
        "state": "",
        "group": "",
        "group_num": [
            "recYPJWYRKXFghdeV"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 576,
        "id": "recAVGqbpCZ7SkiM7",
        "row": 25944,
        "state": "",
        "group": "",
        "group_num": [
            "recMndJi2jP6GPaeT"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 581,
        "id": "recBkqYBfJz29g7vu",
        "row": 25934,
        "state": "",
        "group": "",
        "group_num": [
            "recabhMpTLcXRUmpJ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 132,
        "id": "recE3VbS5zzk7leKe",
        "row": 25943,
        "state": "",
        "group": "",
        "group_num": [
            "recb68B1pkHJRFhSc"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recGflhs21VGuNO2I",
        "row": 25915,
        "state": "",
        "group": "1150079",
        "group_num": [
            "recRKffjH29Wni8DY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 538,
        "id": "recHB6coB3oFspyRM",
        "row": 25937,
        "state": "",
        "group": "",
        "group_num": [
            "reciDN76O7lS60eF8"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1922,
        "id": "recIeGJZQ6zitDZlK",
        "row": 25932,
        "state": "",
        "group": "",
        "group_num": [
            "recqm5kgJWDXkxZ7w"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 120,
        "id": "recKt2mPu8DuZYFBu",
        "row": 25921,
        "state": "",
        "group": "1270366",
        "group_num": [
            "recVmZcnClMa1xeEb"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 504,
        "id": "recKjukMbApbYpDtj",
        "row": 25925,
        "state": "",
        "group": "1418151",
        "group_num": [
            "recNJrWdufGUJ1Kno"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "2023": 1401,
        "id": "recMB02OUx6C8q0t0",
        "row": 25914,
        "state": "",
        "group": "",
        "group_num": [
            "recVsg6pOVS9wKltg"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 143,
        "id": "recN5JXR5SnlC4Hcm",
        "row": 25946,
        "state": "",
        "group": "",
        "group_num": [
            "recMndJi2jP6GPaeT"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "receUzbCSz7O2eyNk",
        "row": 25968,
        "state": "",
        "group": "",
        "group_num": [
            "recMooqjr0d10nunk"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 600,
        "2023": 1403,
        "id": "recfZub0wR3Gd8i7k",
        "row": 25964,
        "state": "",
        "group": "1479279",
        "group_num": [
            "recxo2rtUcyJzRbqL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 22,
        "id": "recfOi7vOcoXCCswX",
        "row": 25986,
        "state": "",
        "group": "",
        "group_num": [
            "receJX0i8d6vm1Wb9"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 51,
        "id": "recgFjiel6fsIOI7e",
        "row": 25960,
        "state": "",
        "group": "",
        "group_num": [
            "recQLLvvhff4DP2Fp"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 325,
        "2024": 325,
        "id": "reciBIvZBOnkrzx3N",
        "row": 25992,
        "state": "",
        "group": "",
        "group_num": [
            "recYEJI91MdtMLRdz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 20,
        "id": "recjcwNDnnavQV4s3",
        "row": 25982,
        "state": "",
        "group": "",
        "group_num": [
            "recHWtg5T522Ifbel"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1154,
        "id": "reck1t9DXGkZ1HFWk",
        "row": 26001,
        "state": "",
        "group": "2437320",
        "group_num": [
            "rec3fGnCHfl2UaTVy"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 853,
        "id": "reckHOjNiEbiLPIgf",
        "row": 25978,
        "state": "",
        "group": "",
        "group_num": [
            "recAjWB2R9vzSlZtx"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 260,
        "2024": 260,
        "id": "reckIxNeJlWGeSiWN",
        "row": 25979,
        "state": "",
        "group": "",
        "group_num": [
            "recJR2ILiPugF02Bn"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 132,
        "id": "recka5e0SuVYE1XRL",
        "row": 25961,
        "state": "",
        "group": "",
        "group_num": [
            "recQLLvvhff4DP2Fp"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 820,
        "id": "recmJ6qxmF8oNkiOd",
        "row": 26006,
        "state": "",
        "group": "2437587",
        "group_num": [
            "recERzRiaabUoOxGD"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 750,
        "id": "recm7poJQezs4lhC3",
        "row": 25989,
        "state": "",
        "group": "2255182",
        "group_num": [
            "rec4NAl31tW59ncWt"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 16,
        "id": "recnBvFEjjWBd1GYh",
        "row": 25975,
        "state": "",
        "group": "",
        "group_num": [
            "recptN23IfPN7kpQm"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "2024": 890,
        "id": "recO0gQdpibgbaOuU",
        "row": 25980,
        "state": "",
        "group": "",
        "group_num": [
            "recn8p144dWSYcfsc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 553,
        "id": "recRyg4rUEEUrPQeU",
        "row": 25965,
        "state": "",
        "group": "",
        "group_num": [
            "recSpvr280PvmaJvh"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3000,
        "id": "recSN4okRfM6nRuuT",
        "row": 26005,
        "state": "",
        "group": "2437522",
        "group_num": [
            "rec9il0JYk2hEolPW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 569,
        "id": "recVhvW14ETWOlHyk",
        "row": 25969,
        "state": "",
        "group": "",
        "group_num": [
            "recOILK8DE0WMnCZn"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 853,
        "id": "rec0LxUReXTki07qF",
        "row": 25967,
        "state": "",
        "group": "",
        "group_num": [
            "rec30tEcN06K1TSGB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1360,
        "id": "rec0oLxbHCgYL8IUB",
        "row": 25995,
        "state": "",
        "group": "2358232",
        "group_num": [
            "recZbTneMgGpOoz8N"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 239,
        "id": "rec42k5CjipxW4QhZ",
        "row": 25962,
        "state": "",
        "group": "",
        "group_num": [
            "recdENk2U51ssenLS"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 291,
        "id": "rec5UEWzV7B06mIyV",
        "row": 25985,
        "state": "",
        "group": "",
        "group_num": [
            "receJX0i8d6vm1Wb9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 257,
        "id": "rec5ZPVkant5T0FKf",
        "row": 25959,
        "state": "",
        "group": "",
        "group_num": [
            "recQLLvvhff4DP2Fp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 480,
        "id": "rec5wAovOvFPstKm0",
        "row": 25996,
        "state": "",
        "group": "2358232",
        "group_num": [
            "recZbTneMgGpOoz8N"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 569,
        "id": "rec5338qwgsQlDV0P",
        "row": 25973,
        "state": "",
        "group": "",
        "group_num": [
            "recQE2dDg1E13dtq6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1915,
        "id": "rec7ZhsNL0qsKCFHP",
        "row": 25976,
        "state": "",
        "group": "",
        "group_num": [
            "recZy35zSNmqMajy4"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4200,
        "id": "rec9IFsQzfriwHmiu",
        "row": 25994,
        "state": "",
        "group": "2314817",
        "group_num": [
            "rec63f7FwR4vbFxNf"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 314,
        "id": "recbnkXawo5ggvoji",
        "row": 25981,
        "state": "",
        "group": "",
        "group_num": [
            "recFIgHs7BhV6WwXz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 908,
        "id": "reccHW1kaMtTlL1qc",
        "row": 25997,
        "state": "",
        "group": "2358232",
        "group_num": [
            "recZbTneMgGpOoz8N"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 440,
        "id": "recoR3j1bP8LrnQp8",
        "row": 25991,
        "state": "",
        "group": "",
        "group_num": [
            "recYEJI91MdtMLRdz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recpoBus0lIsChtf8",
        "row": 26003,
        "state": "",
        "group": "2437522",
        "group_num": [
            "rec9il0JYk2hEolPW"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "recrJEEqe4bcJ8iN7",
        "row": 26004,
        "state": "",
        "group": "2437522",
        "group_num": [
            "rec9il0JYk2hEolPW"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "recsHUJzf9aw4VzQQ",
        "row": 25984,
        "state": "",
        "group": "",
        "group_num": [
            "recEgpBLSnuH41c4A"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 873,
        "id": "rectUUflrR6ngppXE",
        "row": 25970,
        "state": "",
        "group": "",
        "group_num": [
            "rech5PHaXGIstp7KW"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1710,
        "id": "rectII1IniU39U3qm",
        "row": 25993,
        "state": "",
        "group": "2314817",
        "group_num": [
            "rec63f7FwR4vbFxNf"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 873,
        "id": "recvVNLkib4tVWyjr",
        "row": 25983,
        "state": "",
        "group": "",
        "group_num": [
            "recxzSQzgLqh9utoQ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1500,
        "id": "recxuvDmRhQi78cKM",
        "row": 25990,
        "state": "",
        "group": "",
        "group_num": [
            "recylLXwbte8UYEWk"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1663,
        "2023": 892,
        "id": "recxe8A4a7SSiX1mS",
        "row": 25999,
        "state": "",
        "group": "2437320",
        "group_num": [
            "rec3fGnCHfl2UaTVy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recxiZ4ZGk9hXlGF9",
        "row": 25958,
        "state": "",
        "group": "",
        "group_num": [
            "recY9xblBesyvHadO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2375,
        "id": "recyvwTj2Za2CgYjN",
        "row": 26002,
        "state": "",
        "group": "2437320",
        "group_num": [
            "rec3fGnCHfl2UaTVy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1379,
        "id": "reczNrUUthli4BE60",
        "row": 26000,
        "state": "",
        "group": "2437320",
        "group_num": [
            "rec3fGnCHfl2UaTVy"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 905,
        "id": "recAPm1bkOSGu5rov",
        "row": 25972,
        "state": "",
        "group": "",
        "group_num": [
            "recEAKWZrSkhkJlFa"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1707,
        "2024": 1707,
        "id": "recCuMcDkLsTqKC0V",
        "row": 25988,
        "state": "",
        "group": "2245220",
        "group_num": [
            "recGgTMbEoKX6apxe"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 853,
        "id": "recClNTHoMDlc8ewn",
        "row": 25974,
        "state": "",
        "group": "",
        "group_num": [
            "recB1tnxsIRKw2Rkc"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "2024": 141,
        "id": "recFsxFuyunYXrGDw",
        "row": 25971,
        "state": "",
        "group": "",
        "group_num": [
            "recI4SNlwGdexxy3h"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 853,
        "id": "recG4ZwTtHJIBe2lH",
        "row": 25966,
        "state": "",
        "group": "",
        "group_num": [
            "recSpvr280PvmaJvh"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 158,
        "id": "recGr5QnabuQ2wlUi",
        "row": 25987,
        "state": "",
        "group": "",
        "group_num": [
            "receJX0i8d6vm1Wb9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 543,
        "id": "recKksmlyNLTBTC7r",
        "row": 25977,
        "state": "",
        "group": "",
        "group_num": [
            "recZy35zSNmqMajy4"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 581,
        "2024": 581,
        "id": "recLvJddujEH7dA6m",
        "row": 25957,
        "state": "",
        "group": "",
        "group_num": [
            "recY9xblBesyvHadO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 150,
        "2023": 150,
        "id": "recL2VID0NTnDw3mc",
        "row": 25998,
        "state": "",
        "group": "2421265",
        "group_num": [
            "recYdqyVXhPlxAbOR"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 835,
        "id": "recNk2O8yFzGKIAkM",
        "row": 25963,
        "state": "",
        "group": "",
        "group_num": [
            "recdENk2U51ssenLS"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 577,
        "id": "receFSp9tMpO6syCb",
        "row": 26014,
        "state": "",
        "group": "2501351",
        "group_num": [
            "recyASR4UQEf3E8MK"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3720,
        "id": "recgyKwzon6GvOwd6",
        "row": 26042,
        "state": "",
        "group": "78800001",
        "group_num": [
            "recE52xTrhoPcdIuN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1561,
        "id": "recgaLOb0rr7XeAIf",
        "row": 26049,
        "state": "",
        "group": "",
        "group_num": [
            "recDKFr5RoWzQbu6I"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "rechEJfBUnGPcW3TV",
        "row": 26043,
        "state": "",
        "group": "",
        "group_num": [
            "rec9Xm3sV87lGweOj"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3720,
        "id": "rechN5JKFpk2KjmVx",
        "row": 26027,
        "state": "",
        "group": "72005953AM",
        "group_num": [
            "reccpfwHZkqku0YNy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3754,
        "2023": 1250,
        "id": "recivWz87xV4MEVna",
        "row": 26029,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recjVv0Yi0bmCCm0y",
        "row": 26011,
        "state": "",
        "group": "",
        "group_num": [
            "recsPtYNQY9ldGuaN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2240,
        "2023": 1854,
        "2024": 2260,
        "id": "reckAPSraOWdAw5Z6",
        "row": 26036,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1314,
        "2024": 1764,
        "id": "recmuCJnpZtsSdi9w",
        "row": 26017,
        "state": "",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1875,
        "id": "recmGsrXU13NM2B3r",
        "row": 26034,
        "state": "",
        "group": "",
        "group_num": [
            "recdjWK8uTcKJ5nsX"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2614,
        "2023": 2750,
        "id": "recOKxLSiBbLFxL1j",
        "row": 26028,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 300,
        "2023": 398,
        "id": "recSfHZjtAN6kVYw3",
        "row": 26031,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2400,
        "2023": 3500,
        "id": "recTcquhAUiy9JsAn",
        "row": 26032,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 914,
        "id": "recU9TOw78AycBO7E",
        "row": 26008,
        "state": "",
        "group": "",
        "group_num": [
            "recj8ozfsaZUzwS9N"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2350,
        "id": "recY0nPurfFz8joDP",
        "row": 26040,
        "state": "",
        "group": "",
        "group_num": [
            "rece3qEc5xVIrHKaG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 757,
        "id": "recZgGz26bYnEjd9r",
        "row": 26041,
        "state": "",
        "group": "78800001",
        "group_num": [
            "recE52xTrhoPcdIuN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1561,
        "id": "rec0KhGRbjNtP1aXj",
        "row": 26050,
        "state": "",
        "group": "",
        "group_num": [
            "recDKFr5RoWzQbu6I"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2681,
        "id": "rec0KW2G4ZIgORNoI",
        "row": 26052,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2555,
        "id": "rec0oArI8zSnAb7xM",
        "row": 26056,
        "state": "",
        "group": "202158931",
        "group_num": [
            "rec2hgqbVqYU9xmOX"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 919,
        "id": "rec1DANmKaA8K0C35",
        "row": 26015,
        "state": "",
        "group": "2501351",
        "group_num": [
            "recyASR4UQEf3E8MK"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1442,
        "2023": 1116,
        "id": "rec3tzbZjMKaWixeX",
        "row": 26039,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 587,
        "2023": 239,
        "id": "rec3gbNrh3KB6dTEN",
        "row": 26023,
        "state": "",
        "group": "",
        "group_num": [
            "recFxEzJdcLXqCYQZ"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1135,
        "2023": 321,
        "id": "rec46GlKOiH2q83xT",
        "row": 26022,
        "state": "",
        "group": "",
        "group_num": [
            "recFxEzJdcLXqCYQZ"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1584,
        "2023": 1098,
        "2024": 1483,
        "id": "rec4ffovv8XxwnZBc",
        "row": 26016,
        "state": "",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2830,
        "id": "rec5EPR76J7Gq1jBM",
        "row": 26024,
        "state": "",
        "group": "",
        "group_num": [
            "recFxEzJdcLXqCYQZ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2148,
        "2024": 2362,
        "id": "rec6ctqVjYjT28K1N",
        "row": 26018,
        "state": "",
        "group": "3331233",
        "group_num": [
            "recSAS0nh4nGxCsbT"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2133,
        "2023": 1252,
        "2024": 1980,
        "id": "rec7nJRZ0OHLWXxUK",
        "row": 26037,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "2023": 323,
        "id": "rec8hdA4BnQRXniMq",
        "row": 26038,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1165,
        "2023": 1357,
        "id": "rec9plsSSGghlcvHN",
        "row": 26054,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2393,
        "id": "recoxJZ0SUmhwAr19",
        "row": 26048,
        "state": "",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "recoPTErAUZs3Dpff",
        "row": 26012,
        "state": "",
        "group": "",
        "group_num": [
            "recsPtYNQY9ldGuaN"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recpBN2iVrhDD6CiB",
        "row": 26010,
        "state": "",
        "group": "",
        "group_num": [
            "recujmQu98uVK93oU"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1853,
        "id": "recrLAKKC9GZAipzl",
        "row": 26026,
        "state": "",
        "group": "72005953AM",
        "group_num": [
            "reccpfwHZkqku0YNy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 135,
        "id": "recuXprsT8AjQxRP3",
        "row": 26013,
        "state": "",
        "group": "",
        "group_num": [
            "recF46z0T6SRGZiPT"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4430,
        "id": "recwtrZFytzRls2YO",
        "row": 26019,
        "state": "",
        "group": "3335320",
        "group_num": [
            "rec18VSD7okHRdJe3"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1722,
        "2023": 512,
        "id": "recwzJBepLhXtYbP6",
        "row": 26021,
        "state": "",
        "group": "",
        "group_num": [
            "recFxEzJdcLXqCYQZ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3264,
        "id": "reczTej4lQSPUTm0g",
        "row": 26047,
        "state": "",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 835,
        "id": "recCHzwEEfjRhiygw",
        "row": 26046,
        "state": "",
        "group": "",
        "group_num": [
            "reciZ91vuYWnZaa2x"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 100,
        "id": "recExRIk3BvPLUxxf",
        "row": 26044,
        "state": "",
        "group": "",
        "group_num": [
            "rec9Xm3sV87lGweOj"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 560,
        "id": "recFCWXoeoc7ZrOQv",
        "row": 26033,
        "state": "",
        "group": "78300003",
        "group_num": [
            "recNZuQWVfR0GS2z4"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 880,
        "id": "recFmzvXdXAi5UfhS",
        "row": 26025,
        "state": "",
        "group": "",
        "group_num": [
            "recuBSZqjgukqDFsA"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2538,
        "id": "recGlcvXD3Qki61aC",
        "row": 26055,
        "state": "",
        "group": "",
        "group_num": [
            "recoU6xRQcIptjWot"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5413,
        "id": "recHNG0pUndQODTGl",
        "row": 26020,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 97,
        "2023": 115,
        "2024": 100,
        "id": "recIAumqM7YLVG1Vt",
        "row": 26053,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 461,
        "id": "recJooI9gvlT8qQUO",
        "row": 26007,
        "state": "",
        "group": "2437587",
        "group_num": [
            "recERzRiaabUoOxGD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 600,
        "2024": 600,
        "id": "recKDgTX2nPMCSktY",
        "row": 26030,
        "state": "",
        "group": "",
        "group_num": [
            "recnldtqjxJQTvpml"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 190,
        "2023": 1050,
        "id": "recLC9DDSndazryvJ",
        "row": 26035,
        "state": "",
        "group": "",
        "group_num": [
            "recn9FDNr5cQdePhN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 825,
        "id": "recMxyyFyIaFdE0th",
        "row": 26009,
        "state": "",
        "group": "",
        "group_num": [
            "recjfrru4r3qkjbEg"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1475,
        "id": "recM9sCsKnZurmgp7",
        "row": 26045,
        "state": "",
        "group": "",
        "group_num": [
            "reciZ91vuYWnZaa2x"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 170,
        "id": "recNYrQqBeRfgYr2t",
        "row": 26051,
        "state": "",
        "group": "78800218",
        "group_num": [
            "recFD5IwtXx8XPkjt"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4107,
        "id": "recfaiXuYNCkOVkTy",
        "row": 26082,
        "state": "",
        "group": "",
        "group_num": [
            "recBVrDfNmFiYr8QG"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1350,
        "id": "rechSOUNUj7E51SPS",
        "row": 26063,
        "state": "",
        "group": "",
        "group_num": [
            "recYRy6E7gyHgtFPV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1666,
        "id": "rechKv0gelt2p3b5W",
        "row": 26077,
        "state": "",
        "group": "016059205000122",
        "group_num": [
            "recuiW9xcEM2rak2T"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 1600,
        "id": "rechixdk0qtdIchgU",
        "row": 26092,
        "state": "",
        "group": "",
        "group_num": [
            "recvqQ0Km2f2W8TUy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "rechqiu7UKcTHGCYM",
        "row": 26087,
        "state": "",
        "group": "CC000007",
        "group_num": [
            "recpqLgYHMcRfYBFE"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1073,
        "id": "recmPSMLVr43RtmSD",
        "row": 26070,
        "state": "",
        "group": "",
        "group_num": [
            "rec7TcNHJ31ZdRiFx"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "recnY9vFE2nKhj9W2",
        "row": 26065,
        "state": "",
        "group": "",
        "group_num": [
            "recoFwA0if7cSbGaH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "recnsARRJGvtYquUy",
        "row": 26085,
        "state": "",
        "group": "CC000006",
        "group_num": [
            "rectnIl5hjy3q4juJ"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1400,
        "id": "recOANqMuHn8YCFtF",
        "row": 26083,
        "state": "",
        "group": "",
        "group_num": [
            "rec2qtFpag5Ibs9Pi"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1625,
        "2023": 1022,
        "2024": 895,
        "id": "recPTQ839kxaJ1om5",
        "row": 26095,
        "state": "",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 543,
        "id": "recPo50mEvO2WKzvS",
        "row": 26104,
        "state": "",
        "group": "CC000220",
        "group_num": [
            "recswTwJD8LNqZzY9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 978,
        "id": "recR5rGJxYqU5qgym",
        "row": 26103,
        "state": "",
        "group": "CC000220",
        "group_num": [
            "recswTwJD8LNqZzY9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1025,
        "id": "recSEszCQIOSNU6k4",
        "row": 26091,
        "state": "",
        "group": "",
        "group_num": [
            "recrEmfzcdpyVvMut"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1250,
        "2023": 285,
        "id": "recSaZj81Umq0SlKp",
        "row": 26102,
        "state": "",
        "group": "",
        "group_num": [
            "recggqJihbzsaB5rz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 407,
        "id": "recVBmZXzx46usTWo",
        "row": 26067,
        "state": "",
        "group": "",
        "group_num": [
            "recoT7m4muGY4UJql"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1658,
        "id": "recVciSfyzfwgvkt5",
        "row": 26106,
        "state": "",
        "group": "CC000220",
        "group_num": [
            "recswTwJD8LNqZzY9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3845,
        "2023": 1900,
        "id": "recZL9HroQD7Arrrm",
        "row": 26080,
        "state": "",
        "group": "",
        "group_num": [
            "recBVrDfNmFiYr8QG"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 3000,
        "id": "rec0FvNDjmCXCshO6",
        "row": 26088,
        "state": "",
        "group": "CC000007",
        "group_num": [
            "recpqLgYHMcRfYBFE"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 830,
        "2023": 830,
        "id": "rec1bhxh73AeBmnXo",
        "row": 26069,
        "state": "",
        "group": "202200046401",
        "group_num": [
            "recKpZ3PWZskw18UK"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2375,
        "id": "rec2D9uuH4378DEbv",
        "row": 26084,
        "state": "",
        "group": "CC000006",
        "group_num": [
            "rectnIl5hjy3q4juJ"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 285,
        "id": "rec2mxgkMapk5hNZp",
        "row": 26105,
        "state": "",
        "group": "CC000220",
        "group_num": [
            "recswTwJD8LNqZzY9"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1423,
        "id": "rec3vWasiVRd6u1Vh",
        "row": 26068,
        "state": "",
        "group": "202200046401",
        "group_num": [
            "recKpZ3PWZskw18UK"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 355,
        "2024": 355,
        "id": "rec3RPhpQnCAhuH1U",
        "row": 26076,
        "state": "",
        "group": "",
        "group_num": [
            "recYu6FeAjjd3Qx4c"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2400,
        "id": "rec4XVzwR0i6W5B61",
        "row": 26064,
        "state": "",
        "group": "",
        "group_num": [
            "recYRy6E7gyHgtFPV"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1130,
        "id": "rec40kuyJh9PCFBw2",
        "row": 26081,
        "state": "",
        "group": "",
        "group_num": [
            "recBVrDfNmFiYr8QG"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 347,
        "id": "rec5cyA1es2Pbac81",
        "row": 26079,
        "state": "",
        "group": "047917803600001",
        "group_num": [
            "rec5bVwrt6UWgJQUd"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 29,
        "id": "rec9davxGWlYW9HvK",
        "row": 26097,
        "state": "",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "rec9exzd3IcHFtUN9",
        "row": 26086,
        "state": "",
        "group": "CC000007",
        "group_num": [
            "recpqLgYHMcRfYBFE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1183,
        "id": "recazfgHxW2J3FlSa",
        "row": 26089,
        "state": "",
        "group": "",
        "group_num": [
            "recnglYRvM27Bu58D"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2617,
        "id": "recaLg6lco779KuwA",
        "row": 26078,
        "state": "",
        "group": "016059205000122",
        "group_num": [
            "recuiW9xcEM2rak2T"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2871,
        "2023": 1512,
        "2024": 1370,
        "id": "recaqQIkJ44bhaWji",
        "row": 26099,
        "state": "",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1314,
        "2023": 532,
        "id": "reccGOQIwaXP1G89P",
        "row": 26096,
        "state": "",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4865,
        "id": "recdRQLsA9wfIyShX",
        "row": 26057,
        "state": "",
        "group": "202158931",
        "group_num": [
            "rec2hgqbVqYU9xmOX"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1340,
        "2023": 479,
        "id": "recph0mslJCF6Zm8a",
        "row": 26100,
        "state": "",
        "group": "",
        "group_num": [
            "recggqJihbzsaB5rz"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2233,
        "2023": 2233,
        "id": "recqA3zQyrF84NcME",
        "row": 26073,
        "state": "",
        "group": "644773836506",
        "group_num": [
            "rec1Nh7hhpa29oENF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1920,
        "id": "recrWqCzWGEtCITgs",
        "row": 26062,
        "state": "",
        "group": "",
        "group_num": [
            "recYRy6E7gyHgtFPV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2625,
        "id": "recrlLy33LQjgALVz",
        "row": 26058,
        "state": "",
        "group": "",
        "group_num": [
            "recFl3ljwGg4UT83p"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2820,
        "id": "recu1yuH0iKXOhHoz",
        "row": 26074,
        "state": "",
        "group": "644773836506",
        "group_num": [
            "rec1Nh7hhpa29oENF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 943,
        "2023": 510,
        "id": "recuNAD8KJkF8NYcC",
        "row": 26098,
        "state": "",
        "group": "CC000218",
        "group_num": [
            "rec7POHEJem7Wb0sN"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "2024": 650,
        "id": "recu9qTsEl8H2AtBK",
        "row": 26090,
        "state": "",
        "group": "",
        "group_num": [
            "recrEmfzcdpyVvMut"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "recvhW3LD5QY6H0Aw",
        "row": 26094,
        "state": "",
        "group": "",
        "group_num": [
            "recvqQ0Km2f2W8TUy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 43,
        "2023": 42,
        "id": "recwH2QhRfP4Vc3ee",
        "row": 26101,
        "state": "",
        "group": "",
        "group_num": [
            "recggqJihbzsaB5rz"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1125,
        "id": "reczMgz6pQxEPH8hq",
        "row": 26093,
        "state": "",
        "group": "",
        "group_num": [
            "recvqQ0Km2f2W8TUy"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 751,
        "id": "recDr305B9YPKqyi2",
        "row": 26071,
        "state": "",
        "group": "",
        "group_num": [
            "reccBeLK7LbM4qLww"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 40,
        "id": "recE7JTOH0nawu98X",
        "row": 26066,
        "state": "",
        "group": "",
        "group_num": [
            "recoT7m4muGY4UJql"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 291,
        "id": "recE8JCFFyngeLLrc",
        "row": 26060,
        "state": "",
        "group": "",
        "group_num": [
            "recE9SMeDJ1liM1LB"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 725,
        "id": "recGuqj5Q4LUUbmTo",
        "row": 26075,
        "state": "",
        "group": "",
        "group_num": [
            "recx1VGok9AVYf4mk"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2975,
        "id": "recHSkOWP251oxhSG",
        "row": 26059,
        "state": "",
        "group": "",
        "group_num": [
            "recFl3ljwGg4UT83p"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 158,
        "id": "recNsdbeS1Kb1a2CA",
        "row": 26061,
        "state": "",
        "group": "",
        "group_num": [
            "recE9SMeDJ1liM1LB"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4800,
        "id": "recNjAOPT9O1rdueQ",
        "row": 26072,
        "state": "",
        "group": "",
        "group_num": [
            "recFplFMaKEy9VCTB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 302,
        "id": "recekfCgHdbKOLdin",
        "row": 26149,
        "state": "",
        "group": "",
        "group_num": [
            "recMY8C5ln7RaSjGM"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 945,
        "id": "recfU86qXY93b7PMr",
        "row": 26112,
        "state": "",
        "group": "",
        "group_num": [
            "rec5GKPhZgauPplh1"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1710,
        "id": "recfqdZHI8Fsj8rZ8",
        "row": 26127,
        "state": "",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 856,
        "2023": 979,
        "id": "reck6C8lXpkCzWtl1",
        "row": 26140,
        "state": "",
        "group": "",
        "group_num": [
            "recY4iEdRzmQa43sy"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 440,
        "id": "recPCrGXzl3QU1E6t",
        "row": 26143,
        "state": "",
        "group": "",
        "group_num": [
            "rec6LpT8yd5GQfZMD"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2495,
        "id": "recQaga4XCYb2aQ7j",
        "row": 26155,
        "state": "",
        "group": "VALMONT",
        "group_num": [
            "recP6oUTvZPbY59rF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1289,
        "id": "recQeOlHv5uFg2e1a",
        "row": 26135,
        "state": "",
        "group": "HP105192",
        "group_num": [
            "recsw1JTptqOLWiOL"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3100,
        "id": "recRArGsnFdFKIfRA",
        "row": 26122,
        "state": "",
        "group": "",
        "group_num": [
            "reczpDc8BGFTdXeTp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "id": "recSdtAh1FZHrphnK",
        "row": 26148,
        "state": "",
        "group": "",
        "group_num": [
            "recMY8C5ln7RaSjGM"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recTKbCKa6MMiw0Cq",
        "row": 26109,
        "state": "",
        "group": "",
        "group_num": [
            "rec14TA6orh0wmXA6"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4525,
        "id": "recT7RoDG1D37xB0R",
        "row": 26152,
        "state": "",
        "group": "",
        "group_num": [
            "recDY0QeYFQLTBUlk"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recVkmdxigaTYOcMV",
        "row": 26111,
        "state": "",
        "group": "",
        "group_num": [
            "recOGkWieJ0on9Vn0"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "id": "recWGKc11RgFFJwcf",
        "row": 26114,
        "state": "",
        "group": "",
        "group_num": [
            "recLV7G1OSzxCBUiB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "recXoNIFGYiY51rw4",
        "row": 26121,
        "state": "",
        "group": "",
        "group_num": [
            "reczpDc8BGFTdXeTp"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 420,
        "id": "recYW1aXnv4idqjlu",
        "row": 26156,
        "state": "",
        "group": "",
        "group_num": [
            "recH0TSfpKk73wUnx"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1375,
        "2023": 1375,
        "id": "recZFOJtY6lK7T6aT",
        "row": 26130,
        "state": "",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1020,
        "id": "rec0tPOcolo5UGfMc",
        "row": 26134,
        "state": "",
        "group": "HP105192",
        "group_num": [
            "recsw1JTptqOLWiOL"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1309,
        "id": "rec1R1BTvf3XhaoVz",
        "row": 26145,
        "state": "",
        "group": "",
        "group_num": [
            "recZ6HJC5ARTR1sZ6"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 113,
        "id": "rec2BKMqFgDTTd6lD",
        "row": 26129,
        "state": "",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1125,
        "id": "rec2f3CeJ28rFdCv2",
        "row": 26110,
        "state": "",
        "group": "",
        "group_num": [
            "rec14TA6orh0wmXA6"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 320,
        "id": "rec4Ns3YOBo7hjStx",
        "row": 26117,
        "state": "",
        "group": "FLDSNP",
        "group_num": [
            "recnzaT2pGwVsMgMI"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 125,
        "id": "rec6vkwvNUZth5vQr",
        "row": 26141,
        "state": "",
        "group": "",
        "group_num": [
            "recNWfKe3J8OgkVVq"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1473,
        "id": "rec7vjVQ7PQMyBoSw",
        "row": 26133,
        "state": "",
        "group": "HP105192",
        "group_num": [
            "recsw1JTptqOLWiOL"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "rec8lgdgLgfV7WLCk",
        "row": 26131,
        "state": "",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2247,
        "2024": 1502,
        "id": "rec9xBXU7DY9fWnxw",
        "row": 26118,
        "state": "",
        "group": "G1025077",
        "group_num": [
            "recCOOSHkCpJu8WEV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 753,
        "id": "recaLslbrhhZ4BsIp",
        "row": 26150,
        "state": "",
        "group": "",
        "group_num": [
            "recBhwlkwVpgHRYoE"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1178,
        "id": "recoXFbEPgrp7KdtD",
        "row": 26120,
        "state": "",
        "group": "",
        "group_num": [
            "reczpDc8BGFTdXeTp"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 325,
        "id": "recqFsDqDXFS0hEag",
        "row": 26144,
        "state": "",
        "group": "",
        "group_num": [
            "rec6LpT8yd5GQfZMD"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 100,
        "2023": 100,
        "id": "recqma1g24dTVwpH9",
        "row": 26146,
        "state": "",
        "group": "OH007082",
        "group_num": [
            "recGBFRdNvqqtVLx1"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 650,
        "id": "recs6Q4LTvMTUpRVx",
        "row": 26138,
        "state": "",
        "group": "",
        "group_num": [
            "recMOLPKz8GBDJ59s"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 251,
        "id": "recsk4GziqlsYK7kE",
        "row": 26136,
        "state": "",
        "group": "",
        "group_num": [
            "recqPmEHDIaIJswWz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1744,
        "id": "rectM5VKkkTFPJmHi",
        "row": 26123,
        "state": "",
        "group": "",
        "group_num": [
            "recLwm8yae568wLnY"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "2023": 325,
        "id": "rectOvZPpYIr3f5H2",
        "row": 26147,
        "state": "",
        "group": "",
        "group_num": [
            "recHZT4EUsVkwDVYo"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3310,
        "id": "recvex3ce5PEaflqr",
        "row": 26132,
        "state": "",
        "group": "",
        "group_num": [
            "rec8mKudXckzIIubp"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1560,
        "id": "recwJU02SQlxtyXiT",
        "row": 26128,
        "state": "",
        "group": "HP043598",
        "group_num": [
            "recb97anmiynm0j6h"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1658,
        "id": "recyyzTXOAUr3NZvU",
        "row": 26113,
        "state": "",
        "group": "",
        "group_num": [
            "rec5GKPhZgauPplh1"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1368,
        "id": "recyySNjpnThvjjw9",
        "row": 26154,
        "state": "",
        "group": "",
        "group_num": [
            "recT0sVlU7fPPlq8Z"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1089,
        "id": "reczmgKb4MUM2ENzp",
        "row": 26137,
        "state": "",
        "group": "",
        "group_num": [
            "recZJjjx7tGN7iulB"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 270,
        "id": "recBVeRj6QJQ7g2iZ",
        "row": 26115,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 979,
        "id": "recDQ3Fts1Y9Gi84B",
        "row": 26116,
        "state": "",
        "group": "CNV",
        "group_num": [
            "recnWRlP7gZWJm4Lz"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "id": "recF15Hyshl5Ftztk",
        "row": 26125,
        "state": "",
        "group": "HP019741",
        "group_num": [
            "rec2FldzE7Lh9Mya9"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 626,
        "id": "recFIzo2yTtJDDrAE",
        "row": 26139,
        "state": "",
        "group": "",
        "group_num": [
            "recY4iEdRzmQa43sy"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2250,
        "id": "recFdI6lvUWbfvmgh",
        "row": 26108,
        "state": "",
        "group": "",
        "group_num": [
            "recwcFzWord0U2t0t"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 365,
        "id": "recGyKh9S91GrFoDY",
        "row": 26119,
        "state": "",
        "group": "G1025077",
        "group_num": [
            "recCOOSHkCpJu8WEV"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1568,
        "id": "recGz4sCYrd0S9mpg",
        "row": 26124,
        "state": "",
        "group": "HP019741",
        "group_num": [
            "rec2FldzE7Lh9Mya9"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 729,
        "id": "recGf4TbjeYMdGm0f",
        "row": 26142,
        "state": "",
        "group": "",
        "group_num": [
            "recNWfKe3J8OgkVVq"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2760,
        "id": "recHYlLAD7BEuD0c1",
        "row": 26126,
        "state": "",
        "group": "HP019741",
        "group_num": [
            "rec2FldzE7Lh9Mya9"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 47,
        "id": "recLwkvxN8TMkaHFd",
        "row": 26153,
        "state": "",
        "group": "",
        "group_num": [
            "recT0sVlU7fPPlq8Z"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 379,
        "2024": 314,
        "id": "recMyFkKqz4xNjzlM",
        "row": 26151,
        "state": "",
        "group": "",
        "group_num": [
            "recBhwlkwVpgHRYoE"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1600,
        "2023": 1600,
        "id": "recMRvcl0V5DFpLXE",
        "row": 26107,
        "state": "",
        "group": "",
        "group_num": [
            "recwcFzWord0U2t0t"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2507,
        "id": "recLSi7pCuQudiYno",
        "row": 26157,
        "state": "",
        "group": "",
        "group_num": [
            "recP5dXmSP9Oo6SXB"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2700,
        "id": "receWgtEEzKcS2Ops",
        "row": 26189,
        "state": "",
        "group": "",
        "group_num": [
            "recoOWfxNMrYVzbBL"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 5130,
        "id": "recepjrFtOrpyuj8d",
        "row": 26168,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 437,
        "id": "recO0Fhudsv6Am1s8",
        "row": 26187,
        "state": "",
        "group": "G1026047",
        "group_num": [
            "recAHipUCPgFma5Hb"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4200,
        "id": "recOrpB2SP4DWG1uN",
        "row": 26176,
        "state": "",
        "group": "78800205",
        "group_num": [
            "recGoXFcn6qKSIf1U"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3325,
        "id": "recQqX4ObpZQGVxz2",
        "row": 26175,
        "state": "",
        "group": "78800205",
        "group_num": [
            "recGoXFcn6qKSIf1U"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4038,
        "2023": 4088,
        "id": "recRJeOeI2Hsg1cMh",
        "row": 26180,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 671,
        "2023": 0,
        "id": "recSiV0zKquZpPU2v",
        "row": 26178,
        "state": "",
        "group": "78800218",
        "group_num": [
            "recFD5IwtXx8XPkjt"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4038,
        "2024": 4038,
        "id": "recXApmmvgiUeF0oM",
        "row": 26164,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 690,
        "id": "recX2X8wDfie2JxMu",
        "row": 26174,
        "state": "",
        "group": "",
        "group_num": [
            "reckeQcrnh2w5labO"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 475,
        "id": "recYUY6a5V2cuYkcG",
        "row": 26179,
        "state": "",
        "group": "",
        "group_num": [
            "rec4Jbcwtpa2UPV4k"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 4272,
        "id": "rec2EiBjpG5cC0tCP",
        "row": 26169,
        "state": "",
        "group": "",
        "group_num": [
            "recUbR2MRu9eQoAup"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2950,
        "id": "rec2NA8YBXqQSDEmM",
        "row": 26172,
        "state": "",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 483,
        "id": "rec6t95GmlGTkAudC",
        "row": 26186,
        "state": "",
        "group": "78800335",
        "group_num": [
            "recqBX742RYZ8R9MF"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 100,
        "id": "rec7iFQ59pfkOZVU8",
        "row": 26181,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5493,
        "id": "rec9IYd4QEJUVWfWb",
        "row": 26163,
        "state": "",
        "group": "10000007",
        "group_num": [
            "recDCRGP8CVH4cGZh"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 475,
        "id": "rec9LhOZg49byIBvk",
        "row": 26173,
        "state": "",
        "group": "",
        "group_num": [
            "reckeQcrnh2w5labO"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1958,
        "id": "recaJolUnnfhSNxr4",
        "row": 26182,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2662,
        "id": "recdsatLee3m8JktG",
        "row": 26167,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 110,
        "id": "recp4vz0UKXduQToz",
        "row": 26160,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 101,
        "2023": 90,
        "id": "recqcutQqwBAQvosS",
        "row": 26166,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 387,
        "2024": 2121,
        "id": "recrMDSD7m62Zy7Dp",
        "row": 26184,
        "state": "",
        "group": "78800335",
        "group_num": [
            "recqBX742RYZ8R9MF"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 832,
        "id": "recsTyW1ybN5kHO9z",
        "row": 26188,
        "state": "",
        "group": "G1026047",
        "group_num": [
            "recAHipUCPgFma5Hb"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 831,
        "2023": 4486,
        "2024": 4394,
        "id": "reczI8yLKF9obCQsJ",
        "row": 26158,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 84,
        "id": "recz9BTtAE3qf8SE9",
        "row": 26171,
        "state": "",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "OP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 3750,
        "id": "recFtd9zO1cGHmaB4",
        "row": 26170,
        "state": "",
        "group": "78800182",
        "group_num": [
            "recfjbBs9ESmAxF67"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 850,
        "id": "recFaU9Ru3L8iqHRv",
        "row": 26185,
        "state": "",
        "group": "78800335",
        "group_num": [
            "recqBX742RYZ8R9MF"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 690,
        "2023": 3903,
        "2024": 1099,
        "id": "recGsQybjNZ2YHG42",
        "row": 26162,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2998,
        "2024": 2550,
        "id": "recHlvbOVl01dBxRm",
        "row": 26165,
        "state": "",
        "group": "78360001",
        "group_num": [
            "recdFuwjX9jxAKTb7"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 108,
        "2023": 1884,
        "id": "recITkHUbXso0Uyg1",
        "row": 26161,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "IOP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 690,
        "id": "recKOcARUsyHVreBG",
        "row": 26177,
        "state": "",
        "group": "",
        "group_num": [
            "recVrVDgrM6wHPjT7"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 478,
        "id": "recL0r1AmOgyqaEU1",
        "row": 26159,
        "state": "",
        "group": "10000001",
        "group_num": [
            "recz5WGTGGn2DA27i"
        ],
        "procedure": "PHP",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 5100,
        "2023": 5100,
        "id": "recM37HFXEwusiuyF",
        "row": 26183,
        "state": "",
        "group": "78800277",
        "group_num": [
            "recONYRT2yT3D4FaH"
        ],
        "procedure": "Detox",
        "provider": "UNITED HEALTH CARE SHARED SERVICES"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1019,
        "id": "recSgq29kAaw0dtGh",
        "row": 26190,
        "state": "",
        "group": "",
        "group_num": [
            "recBHCUkMVVV8obfV"
        ],
        "procedure": "RTC",
        "provider": "UNITED HEALTH PLUS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 850,
        "id": "recvmYQRUsMrA41Uh",
        "row": 26192,
        "state": "",
        "group": "00130566",
        "group_num": [
            "recTM0aL6yPHonxNH"
        ],
        "procedure": "PHP",
        "provider": "UNIVERA  HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1100,
        "id": "recNAN1ysOK4DDrCW",
        "row": 26191,
        "state": "",
        "group": "00130566",
        "group_num": [
            "recTM0aL6yPHonxNH"
        ],
        "procedure": "RTC",
        "provider": "UNIVERA  HEALTH CARE"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4860,
        "id": "recXs7afD4EVw2E8D",
        "row": 26193,
        "state": "",
        "group": "1641810629",
        "group_num": [
            "recwFDu9sIwZYwn55"
        ],
        "procedure": "Detox",
        "provider": "UNIVERSITY OF UTAH"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 150,
        "id": "receNRQFIWPHDqM3w",
        "row": 26199,
        "state": "",
        "group": "",
        "group_num": [
            "recC3aEUOLvtf4GO7"
        ],
        "procedure": "IOP",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "id": "rechUbDpnGvzoa6uz",
        "row": 26194,
        "state": "",
        "group": "006938304",
        "group_num": [
            "recsNv77YssmvKR7m"
        ],
        "procedure": "RTC",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 800,
        "2024": 1588,
        "id": "recmbMMDyztFDjaFF",
        "row": 26201,
        "state": "",
        "group": "022381300",
        "group_num": [
            "recGIkSdP9LH2NeiS"
        ],
        "procedure": "RTC",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 2810,
        "2023": 1025,
        "id": "recTbuoM3huh84i2r",
        "row": 26202,
        "state": "",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "RTC",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 686,
        "id": "recVflRRbhrZRQU4n",
        "row": 26195,
        "state": "",
        "group": "006938304",
        "group_num": [
            "recsNv77YssmvKR7m"
        ],
        "procedure": "Detox",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2173,
        "id": "rec5hNZubuMMEnKKK",
        "row": 26197,
        "state": "",
        "group": "018084300",
        "group_num": [
            "reckt8RfrqFEt4Aqm"
        ],
        "procedure": "Detox",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1267,
        "id": "reco2LSj3uhuIfCmU",
        "row": 26204,
        "state": "",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "Detox",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 249,
        "id": "recuT08WAUMFMQMaB",
        "row": 26203,
        "state": "",
        "group": "OX0025306",
        "group_num": [
            "recuRLqGSWNTArBrk"
        ],
        "procedure": "PHP",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 900,
        "id": "recyFuQAestbB3YGO",
        "row": 26200,
        "state": "",
        "group": "021884301",
        "group_num": [
            "recOAWrUYjFEAuj7m"
        ],
        "procedure": "RTC",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 700,
        "id": "recGGrwf5dCNn87DL",
        "row": 26198,
        "state": "",
        "group": "",
        "group_num": [
            "recC3aEUOLvtf4GO7"
        ],
        "procedure": "PHP",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 2188,
        "id": "recHhKOudtNJN07Lc",
        "row": 26196,
        "state": "",
        "group": "018084300",
        "group_num": [
            "reckt8RfrqFEt4Aqm"
        ],
        "procedure": "RTC",
        "provider": "UPMC"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 845,
        "id": "recg6c8DExC80sfaX",
        "row": 26205,
        "state": "",
        "group": "",
        "group_num": [
            "recpFw5Rwmg5isoGG"
        ],
        "procedure": "RTC",
        "provider": "VALENZ"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 808,
        "id": "recnUPVyKwIuakSoh",
        "row": 26206,
        "state": "",
        "group": "",
        "group_num": [
            "recpFw5Rwmg5isoGG"
        ],
        "procedure": "Detox",
        "provider": "VALENZ"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 839,
        "id": "rec65xpKH0MQt2Tcp",
        "row": 26208,
        "state": "",
        "group": "",
        "group_num": [
            "reci3K3aO6NKIjUhw"
        ],
        "procedure": "Detox",
        "provider": "VARIPRO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1000,
        "2023": 801,
        "id": "recEMInWoBs7AkOKt",
        "row": 26207,
        "state": "",
        "group": "",
        "group_num": [
            "reci3K3aO6NKIjUhw"
        ],
        "procedure": "RTC",
        "provider": "VARIPRO"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 148,
        "id": "recLIjkLMIcMhtcGv",
        "row": 26209,
        "state": "",
        "group": "",
        "group_num": [
            "recZUxh2ut9x6HmVH"
        ],
        "procedure": "IOP",
        "provider": "WEA TRUST"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1286,
        "2023": 1270,
        "2024": 1647,
        "id": "recYX87WMva2cUb09",
        "row": 26215,
        "state": "",
        "group": "2022PRU",
        "group_num": [
            "reciaIBeQ0VQn2R8l"
        ],
        "procedure": "RTC",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1162,
        "id": "rec1yJY2kJzaDoFrx",
        "row": 26213,
        "state": "",
        "group": "",
        "group_num": [
            "recq7FLqvSWAJytvd"
        ],
        "procedure": "Detox",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1564,
        "id": "rec4qLXKiirI3ImQY",
        "row": 26210,
        "state": "",
        "group": "",
        "group_num": [
            "recFif75z59sAa5iO"
        ],
        "procedure": "RTC",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 280,
        "id": "recreIzItWd9xqoaj",
        "row": 26214,
        "state": "",
        "group": "",
        "group_num": [
            "rec30eLJLSD2MONyK"
        ],
        "procedure": "PHP",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 365,
        "id": "recJZ8xf99IHIGbfe",
        "row": 26212,
        "state": "",
        "group": "",
        "group_num": [
            "recq7FLqvSWAJytvd"
        ],
        "procedure": "PHP",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1148,
        "id": "recKo59ta02xVqpAY",
        "row": 26211,
        "state": "",
        "group": "",
        "group_num": [
            "recq7FLqvSWAJytvd"
        ],
        "procedure": "RTC",
        "provider": "WEB TPA"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1022,
        "id": "recgyPhoH6yxoM9P4",
        "row": 20228,
        "state": "",
        "group": "888540010",
        "group_num": [
            "rec2YCgRnywk4GXUO"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 4395,
        "id": "reckrpTzPe0Kesf7T",
        "row": 20232,
        "state": "",
        "group": "",
        "group_num": [
            "reca8c1sA4WLR8TWU"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 833,
        "id": "recULyswRxTOxXiV9",
        "row": 20233,
        "state": "",
        "group": "36956",
        "group_num": [
            "recJPb3hYIT6i6oYU"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 23,
        "id": "recXI6hVVG3XOJDDN",
        "row": 20229,
        "state": "",
        "group": "",
        "group_num": [
            "recNcnledrIbLyzlm"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 212,
        "id": "rec5DtzX3RYwhJheQ",
        "row": 20231,
        "state": "",
        "group": "",
        "group_num": [
            "recNcnledrIbLyzlm"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 101,
        "id": "recJSrcVUFtt1TqKZ",
        "row": 20230,
        "state": "",
        "group": "",
        "group_num": [
            "recNcnledrIbLyzlm"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 116,
        "id": "rec9271tbSFOMvZj7",
        "row": 20235,
        "state": "",
        "group": "",
        "group_num": [
            "recZlPlRbnrX4BUuH"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "id": "reccBRCARvTSeMjws",
        "row": 20240,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 473,
        "id": "recroU3VEFOzVwTgM",
        "row": 20237,
        "state": "",
        "group": "",
        "group_num": [
            "recZlPlRbnrX4BUuH"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 333,
        "id": "recJ5rFtVUq95U8He",
        "row": 20238,
        "state": "",
        "group": "6045521000",
        "group_num": [
            "recHBaW6NNbVXE8E8"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 288,
        "2024": 288,
        "id": "recKqPQhpXR0T2uLs",
        "row": 20236,
        "state": "",
        "group": "",
        "group_num": [
            "recZlPlRbnrX4BUuH"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1013,
        "id": "recLUaxhrrWWwLOls",
        "row": 20234,
        "state": "",
        "group": "",
        "group_num": [
            "rec0Y5cNqkoZFDRhW"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 116,
        "id": "recMgZ6B0ulWL6l3X",
        "row": 20239,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF IA ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 248,
        "id": "rece1fdQgAgFc9Cff",
        "row": 20251,
        "state": "",
        "group": "",
        "group_num": [
            "recqvBbttPNKsCJeM"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 414,
        "2023": 414,
        "id": "recgRgyoAWJ1SivpE",
        "row": 20243,
        "state": "",
        "group": "814130000",
        "group_num": [
            "recYDUVtQNZ7lciK9"
        ],
        "procedure": "IOP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recRKYhgFNCBWRPRn",
        "row": 20256,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recReXOHpyWkXEBOJ",
        "row": 20250,
        "state": "",
        "group": "824570000",
        "group_num": [
            "reciuB8WfWemwMZJZ"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 687,
        "id": "recVm3fUDk9HV5isn",
        "row": 20247,
        "state": "",
        "group": "",
        "group_num": [
            "recYVUCkbZBAXUFXb"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 350,
        "id": "rec4B5iWzb9b75Hyn",
        "row": 20245,
        "state": "",
        "group": "",
        "group_num": [
            "recx6aKH2mlSlmeg9"
        ],
        "procedure": "IOP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 44,
        "id": "rec4J3lzU2OR1qdEd",
        "row": 20254,
        "state": "",
        "group": "",
        "group_num": [
            "recJcQ8J2yukjczeu"
        ],
        "procedure": "OP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 310,
        "id": "reccIMDxyjNVlalUs",
        "row": 20246,
        "state": "",
        "group": "",
        "group_num": [
            "recLkP3Pv2apVe4Xh"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 151,
        "id": "reccfYLRJ4JzoTjLw",
        "row": 20252,
        "state": "",
        "group": "",
        "group_num": [
            "recqvBbttPNKsCJeM"
        ],
        "procedure": "IOP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 414,
        "id": "rect6gbUKwdjvfMVe",
        "row": 20253,
        "state": "",
        "group": "",
        "group_num": [
            "recJcQ8J2yukjczeu"
        ],
        "procedure": "IOP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 476,
        "id": "rect9Vd6HHb9VZ4NB",
        "row": 20241,
        "state": "",
        "group": "813950001",
        "group_num": [
            "recBhngXh3msh9prQ"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recwZQoEivX9deHM2",
        "row": 20255,
        "state": "",
        "group": "--",
        "group_num": [
            "rec7IGqHUVMFys7Vx"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 1013,
        "id": "recxLv7N6FhN8zGEH",
        "row": 20248,
        "state": "",
        "group": "",
        "group_num": [
            "recYVUCkbZBAXUFXb"
        ],
        "procedure": "Detox",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 770,
        "id": "recJyoQgOeTIC1aQl",
        "row": 20244,
        "state": "",
        "group": "",
        "group_num": [
            "recx6aKH2mlSlmeg9"
        ],
        "procedure": "PHP",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recMSlRTQ7dJqDKCW",
        "row": 20249,
        "state": "",
        "group": "824570000",
        "group_num": [
            "reciuB8WfWemwMZJZ"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 952,
        "id": "recMSLBZFVSztCkSY",
        "row": 20242,
        "state": "",
        "group": "813950001",
        "group_num": [
            "recBhngXh3msh9prQ"
        ],
        "procedure": "RTC",
        "provider": "WELLMARK BCBS OF SD ()"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1388,
        "id": "recVmSyJupfmCyShl",
        "row": 26218,
        "state": "",
        "group": "WM260",
        "group_num": [
            "rechiSAPu0qxCnn8I"
        ],
        "procedure": "Detox",
        "provider": "WELLNET"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 1099,
        "id": "recEJs2GepFpVp1vg",
        "row": 26217,
        "state": "",
        "group": "WM260",
        "group_num": [
            "rechiSAPu0qxCnn8I"
        ],
        "procedure": "RTC",
        "provider": "WELLNET"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 2841,
        "id": "recIS0h7Uy4H1K8B2",
        "row": 26216,
        "state": "",
        "group": "503157",
        "group_num": [
            "recWUcCSpBE962ilh"
        ],
        "procedure": "RTC",
        "provider": "WELLNET"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "2024": 141,
        "id": "recfa0R0UN9SNhMyQ",
        "row": 26223,
        "state": "",
        "group": "",
        "group_num": [
            "recHf054n6DZxva7T"
        ],
        "procedure": "PHP",
        "provider": "WPS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2024": 141,
        "id": "rec39cArUvQzVG18q",
        "row": 26224,
        "state": "",
        "group": "",
        "group_num": [
            "recHf054n6DZxva7T"
        ],
        "procedure": "IOP",
        "provider": "WPS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 141,
        "2024": 141,
        "id": "recv2kB7XJqc06C30",
        "row": 26219,
        "state": "",
        "group": "",
        "group_num": [
            "rec8sAriJcodHVfQb"
        ],
        "procedure": "PHP",
        "provider": "WPS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 211,
        "id": "recyMtpAWVYtKV9bb",
        "row": 26222,
        "state": "",
        "group": "",
        "group_num": [
            "reclP2i5QMT9xVXmr"
        ],
        "procedure": "IOP",
        "provider": "WPS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 208,
        "id": "reczgMtQUEWeRbkFl",
        "row": 26220,
        "state": "",
        "group": "",
        "group_num": [
            "reclP2i5QMT9xVXmr"
        ],
        "procedure": "PHP",
        "provider": "WPS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2022": 113,
        "id": "recHDDFtypIRmqklC",
        "row": 26221,
        "state": "",
        "group": "",
        "group_num": [
            "reclP2i5QMT9xVXmr"
        ],
        "procedure": "OP",
        "provider": "WPS"
    },
    {
        "2020": 0,
        "2021": 0,
        "2023": 1163,
        "id": "recKCURrDOrnsUupa",
        "row": 26225,
        "state": "",
        "group": "",
        "group_num": [
            "recQh94yWH725M541"
        ],
        "procedure": "RTC",
        "provider": "ZENITH AMERICAN SOLUTIONS"
    }
]

