import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import useAuth from "../../contexts/AuthContext";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "../../services/api";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Header({ title }) {
    const auth = useSelector((state) => state.auth);
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false)
    const [alertType, setAlertType] = React.useState('error')
    const [toastMessage, setToastmessage] = React.useState("")
    const [notes, setNotes] = React.useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const containerClass = {
        display: 'block',
        textAlign: 'center',
        paddingBottom: '30px',
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleSubmit = async () => {
        if (!notes) {
            setShowToast(true)
            setToastmessage("Please provide details about the issue you're experiencing.");
            setAlertType('error')
            return;
        }
        const user = auth.user;
        const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            message: notes,
            page: "https://app.trunk8.io" + location.pathname,
        }

        await API.post('/report-issue', payload).then(res => {
            setShowToast(true)
            setToastmessage("The issue has been reported successfully.");
            setAlertType('success')
            setOpen(false)
        }).catch(err => {
            console.log("err", err)
            setShowToast(true)
            setToastmessage("Something went wrong. Please try again later.");
            setAlertType('error')
            setOpen(false)
        })
    }

    return (
        <>

            <Snackbar
                open={showToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                onClose={() => setShowToast(false)}
            >
                <Alert
                    onClose={() => setShowToast(false)}
                    severity={alertType}
                    variant="filled"
                    sx={{ width: '100%', color: '#fff' }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>

            <div style={containerClass}>
                Found an issue? <a style={{ color: "#1fe567" }} href="#" onClick={(e) => {
                    e.preventDefault()
                    handleOpen();
                }}> Report here</a>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Please write a message here. We'll reply within 24 hours.
                    </Typography>
                    <TextField
                        label="Notes"
                        multiline
                        rows={6}
                        sx={{ mt: 4 }}
                        defaultValue="Default Value"
                        value={notes}
                        fullWidth
                        required
                        onChange={(e) => { setNotes(e.target.value) }}
                    />
                    <Box sx={{ marginTop: "20px" }}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            sx={{ marginRight: "10px", float: 'right' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
