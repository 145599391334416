import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { US_STATES, DataSets } from "../../data";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization, editOrganization } from "../../store/admin";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddOrgModal({
  isOpen,
  handleClose,
  createModal,
  data,
  handleDataChange,
}) {
  const adminStore = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (createModal) {
      dispatch(createOrganization(data));
    } else {
      dispatch(editOrganization(data));
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {createModal ? "Add a new organization" : "Edit organization"}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="companyName"
            label="Company Name"
            variant="standard"
            required
            value={data.companyName}
            onChange={(e) => handleDataChange(e, "companyName")}
          />
          <p style={{ marginBottom: "0px" }}>Primary User</p>
          <Box sx={{ marginBottom: "20px" }}>
            <TextField
              margin="dense"
              label="First Name"
              variant="standard"
              name="firstName"
              sx={{ marginRight: "20px" }}
              required
              value={data.primaryUser.firstName}
              onChange={(e) => handleDataChange(e, "primary")}
            />
            <TextField
              margin="dense"
              label="Last Name"
              variant="standard"
              name="lastName"
              required
              value={data.primaryUser.lastName}
              onChange={(e) => handleDataChange(e, "primary")}
            />
          </Box>
          <TextField
            margin="dense"
            label="Email"
            type="email"
            name="email"
            variant="standard"
            required
            fullWidth
            value={data.primaryUser.email}
            onChange={(e) => handleDataChange(e, "primary")}
          />
          <Box sx={{ marginTop: "20px" }}>
            <p>States</p>
            <Select
              multiple
              label="States"
              value={data.state}
              onChange={(e) => handleDataChange(e, "state")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              fullWidth
              required
            >
              {US_STATES.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={data.state.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <p>Dataset</p>
            <Select
              multiple
              label="Dataset"
              value={data.dataset}
              onChange={(e) => handleDataChange(e, "dataset")}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              fullWidth
              required
            >
              {DataSets.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={data.dataset.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TextField
            margin="dense"
            label="Notes"
            type="notes"
            name="notes"
            variant="standard"
            required
            fullWidth
            maxRows={4}
            minRows={3}
            multiline
            value={data.notes}
            onChange={(e) => handleDataChange(e, "notes")}
          />
          <Box sx={{ marginTop: "20px" }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={adminStore.isLoading}
              disabled={adminStore.isLoading}
              sx={{ marginRight: "10px" }}
            >
              Save
            </LoadingButton>
            <Button onClick={handleClose}>Cancel</Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddOrgModal;
