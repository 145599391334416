import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Typography,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Paper,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Button
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import useAuth from "../../contexts/AuthContext";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginContainer: {
    maxWidth: "450px",
    "& .MuiGrid-root": {
      width: "auto",
    },
  },
  button: {
    textDecoration: "none",
  },
  forgotPassLink: {
    color: "#000000",
    border: "none",
    background: "none",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

function Login() {
  const styles = useStyles();
  const { onLogin } = useAuth();
  const auth = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  return (
    <div className={styles.root}>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          onLogin(userData);
        }}
        zIndex="2"
      >
        <Paper>
          <Grid
            container
            item
            justifyContent="center"
            className={styles.loginContainer}
            p={{ xs: "32px" }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" mt={1} mb={3}>
                Welcome
              </Typography>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                required
                variant="outlined"
                value={userData.email}
                name="email"
                type="email"
                label="Email"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                required
                variant="outlined"
                name="password"
                value={userData.password}
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                onChange={handleChange}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            {auth.error && (
              <Grid item xs={12}>
                <p style={{ color: "red" }}>{auth.error}</p>
              </Grid>
            )}
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              {/* <LoadingButton
                type="submit"
                variant="contained"
                sx={{ width: 85 }}
                loading={auth.isLoading}
              > */}
              <Button disableElevation disabled={auth.isLoading} fullWidth size="large" type="submit" variant="contained" color="primary">
                Login
              </Button>
              {/* </LoadingButton> */}
              {/* <Link
              to="/signup"
              style={{ color: "white" }}
            >
              Don't have an account? Sign Up
            </Link> */}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default Login;
