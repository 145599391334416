import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API } from "../services/api";

const initialState = {
  isLoading: false,
  organizations: [],
  users: [],
  error: null,
};

const isLoading = (action) => {
  return [
    "admin/orgs/pending",
    "admin/createOrg/pending",
    "admin/removeOrg/pending",
    "admin/editOrg/pending",
    "admin/users/pending",
    "admin/createUser/pending",
    "admin/editUser/pending",
    "admin/removeUser/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "admin/orgs/fulfilled",
    "admin/orgs/rejected",
    "admin/createOrg/fulfilled",
    "admin/createOrg/rejected",
    "admin/removeOrg/fulfilled",
    "admin/removeOrg/rejected",
    "admin/editOrg/fulfilled",
    "admin/editOrg/rejected",
    "admin/users/fulfilled",
    "admin/users/rejected",
    "admin/createUser/fulfilled",
    "admin/createUser/rejected",
    "admin/editUser/fulfilled",
    "admin/editUser/rejected",
    "admin/removeUser/fulfilled",
    "admin/removeUser/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "admin/orgs/rejected",
    "admin/createOrg/rejected",
    "admin/removeOrg/rejected",
    "admin/editOrg/rejected",
    "admin/users/rejected",
    "admin/createUser/rejected",
    "admin/editUser/rejected",
    "admin/removeUser/rejected",
  ].includes(action.type);
};

export const getOrganizations = createAsyncThunk(
  "admin/orgs",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.get(`admin/orgs`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "admin/users",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.get(`admin/users`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "admin/editUser",
  async (values, { rejectWithValue }) => {
    try {
      const { data: users } = await API.put(`admin/users/${values.id}`, values);
      return users;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "admin/createUser",
  async (values, { rejectWithValue }) => {
    try {
      const { data: users } = await API.post(`admin/users`, values);
      return users;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const removeUser = createAsyncThunk(
  "admin/removeUser",
  async (values, { rejectWithValue }) => {
    try {
      const { data: users } = await API.delete(
        `admin/users/${values.id}`,
        values
      );
      return users;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const createOrganization = createAsyncThunk(
  "admin/createOrg",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`admin/org`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const removeOrganization = createAsyncThunk(
  "admin/removeOrg",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(`admin/orgs/${values.id}`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const editOrganization = createAsyncThunk(
  "admin/editOrg",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.put(`admin/orgs/${values.id}`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(editOrganization.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(removeOrganization.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload.message;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default adminSlice.reducer;
