import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import useAuth from "../../contexts/AuthContext";

const drawerWidth = 240;

export default function Header({ title }) {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { onLogout } = useAuth();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
  };
  const titles = [
    {
      path: "/admin",
      name: "Dashboard",
    },
    {
      name: "Organizations",
      path: "/orgs",
    },
    {
      name: "Users",
      path: "/users",
    },
    {
      name: "Views",
      path: "/views",
    },
  ];
  let headerTitle = useMemo(() => {
    if (auth.user && auth.user.role === "admin") {
      return titles.find((item) => {
        if (location.pathname.includes(item.path)) {
          return true;
        }
        return false;
      })?.name;
    }

    return title || "Reimbursement Calculator";
  }, [location]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width:
          auth?.user?.role === "admin"
            ? `calc(100% - ${drawerWidth}px)`
            : "100%",
        ml: auth?.user?.role === "admin" ? `${drawerWidth}px` : "0px",
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {headerTitle}
        </Typography>
        {auth && auth.user && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                handleClose();
                navigate("dashboard");
              }}>Basic</MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                navigate("extended");
              }}>Extended</MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                navigate("my-account");
              }}>My account</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
