import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NotEnoughPermissoin from "./pages/NotEnoughPermission";
import Dashboard from "./pages/Dashboard";
import Extended from "./pages/Extended";
import AdminDashboard from "./pages/AdminDashboard";
import AdminOrgs from "./pages/AdminOrgs";
import AdminUsers from "./pages/AdminUsers";
import AdminViews from "./pages/AdminViews";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/Layout";
import AuthGuard from "./components/AuthGuard";
import MyAccount from "./pages/Account";
import Verify from "./pages/Verify"

import { AuthProvider } from "./contexts/AuthContext";
import store from "./store";
import ROLES from "./roles";

import "./App.scss";

export default function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route index element={<Navigate to="/login" />} />
          </Route>

          <Route element={<Unauthorized />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="verify" element={<Verify />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />}>
                <Route element={<AuthGuard roles={[ROLES.ADMIN]} />}>
                  <Route index element={<Navigate to="admin" />} />
                  <Route path="admin" element={<AdminDashboard />} />
                  <Route path="orgs" element={<AdminOrgs />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="views" element={<AdminViews />} />
                </Route>
              </Route>
              <Route path="extended" element={<Extended />} />
              <Route
                path="not-enough-permission"
                element={<NotEnoughPermissoin />}
              />
            </Route>
            <Route element={<Layout title="My Account" />}>
              <Route path="my-account" element={<MyAccount />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </Provider>
  );
}
